<template>
  <section class="messages">
    <!-- <div class="messages_head">
      <BaseButton>Back To Profile</BaseButton>
    </div> -->

    <div class="messages_body">
      <Messenger clientMode />
    </div>
  </section>
</template>

<script>
import Messenger from '@/views/messenger/Messenger.vue';

export default {
  components: {
    Messenger,
  },
  created() {
    if (!this.client) {
      this.$router.push({
        name: 'ClientAuth',
        query: { source: this.$router.currentRoute.value.fullPath },
      });
    }
  },
  computed: {
    client() {
      if (!this.$store.state.booking.activeClient) return;

      return this.$store.state.booking.activeClient;
    },
  },
  watch: {
    client(val) {
      if (!val) {
        this.$router.push({
          name: 'ClientAuth',
          query: { source: this.$router.currentRoute.value.fullPath },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.messages {
  margin-top: 32px;
  height: 100%;

  //   &_head {
  //     text-align: right;
  //     margin-bottom: 32px;
  //   }
}
</style>
