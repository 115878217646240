import axios from 'axios';
import moment from 'moment-timezone';

import schedule from './defaults/schedule.default';

import { useToast } from 'vue-toastification';
const toast = useToast();

export default {
  namespaced: true,
  state: {
    staff: [],
  },
  mutations: {
    addStaff(state, payload) {
      state.staff.push(payload);
    },
    editStaff(state, payload) {
      const index = state.staff.findIndex((staff) => staff._id === payload._id);

      state.staff[index] = payload;
    },
    deleteStaff(state, payload) {
      const index = state.staff.findIndex((staff) => staff._id === payload._id);

      state.staff.splice(index, 1);
    },
  },
  actions: {
    async createStaff({ rootState, commit }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/staff`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        // Commit add
        commit('addStaff', { ...payload, ...response.data.staff, schedule });

        return response.data.staff;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async editStaff({ rootState, state, commit }, payload) {
      try {
        let currentShift = payload.currentShift;

        if (payload.toggledWorking) {
          // Clock in (create shift)
          if (payload.working) {
            if (payload.trackHourly) {
              if (payload.currentShift) {
                throw Error('Error clocking in team member');
              }

              const response = await axios.get(
                `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/staff/${payload.staffId}/clockIn?clockedInBy=${payload.clockedInBy}`,
                {
                  headers: {
                    Authorization: `Bearer ${rootState.auth.token}`,
                  },
                }
              );

              currentShift = response.data.shift._id;
            }
          } else {
            // Clock out (edit shift)
            if (payload.trackHourly) {
              if (!currentShift) return;

              await axios.get(
                `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/staff/${payload.staffId}/clockOut`,
                {
                  headers: {
                    Authorization: `Bearer ${rootState.auth.token}`,
                  },
                }
              );

              currentShift = null;

              payload.available = false;
              payload.additionalTurnsLogs = [];
            }
          }

          // Custom staff turn order
          if (payload.onCalendar) {
            const response = await axios.put(
              `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/staff/customStaffTurnOrder?staffId=${payload.staffId}`,
              {
                clockIn: payload.working,
              },
              {
                headers: {
                  Authorization: `Bearer ${rootState.auth.token}`,
                },
              }
            );

            rootState.sockets.socket.emit('updateStaffTurnOrder', {
              staff: response.data.customStaffTurnOrder,
              salonId: rootState.auth.salon._id,
            });

            rootState.auth.salon.customStaffTurnOrder =
              response.data.customStaffTurnOrder;
          }
        }
        // For sorting order
        if (payload.toggledWorking && payload.working)
          payload.clockedInAt = new Date(Date.now());

        let query = '?';
        // Vacation time appointment check
        if (payload.vacationTimeStart && payload.vacationTimeEnd) {
          query += `vacationTimeStart=${payload.vacationTimeStart}&vacationTimeEnd=${payload.vacationTimeEnd}&`;
        }

        await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/staff/${payload._id}${query}`,
          { ...payload, currentShift },
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        let staffIndex = state.staff.findIndex(
          (staff) => staff._id.toString() === payload._id.toString()
        );

        if (staffIndex === -1) {
          state.staff[staffIndex] = payload;
        }

        rootState.sockets.socket.emit('updateStaff', {
          staff: state.staff,
          salonId: rootState.auth.salon._id,
        });

        // Commit add
        commit('editStaff', {
          ...payload,
          currentShift,
          toggledWorking: false,
        });
      } catch (error) {
        console.log(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          throw Error(error.response.data.error);
        }

        throw Error(error.message);
      }
    },
    async deleteStaff({ rootState, commit }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/staff/${payload._id}`,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        // Commit add
        commit('deleteStaff', payload);
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async updatePassword({ rootState }, payload) {
      try {
        await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/staff/${rootState.auth.user._id}/password`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async alertMember({ rootState }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/text`,
          {
            to: payload.to,
            body: rootState.auth.salon.adminSettings.alertMessage,
          },
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );
      } catch (error) {
        throw Error(error.reponse.data.error);
      }
    },

    async toggleAvailability({ dispatch, rootState, state }, payload) {
      try {
        const staff = state.staff.find(
          (staff) => staff._id.toString() === payload._id.toString()
        );

        if (!staff) throw Error('Staff not found');

        await dispatch(
          'staff/editStaff',
          {
            ...staff,
            available: !staff.available,
          },
          { root: true }
        );

        staff.available = !staff.available;
        rootState.tickets.changeCount++;

        rootState.sockets.socket.emit('updateStaff', {
          staff: state.staff,
          salonId: rootState.auth.salon._id,
        });

        return staff.available
          ? `${staff.firstName} marked available`
          : `${staff.firstName} marked unavailable`;
      } catch (error) {
        throw Error(error.reponse.data.error);
      }
    },

    async inviteStaffs({ rootState }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/staff/invite`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        toast.success('Invitations sent');
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async joinSalon({ rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/staff/${rootState.auth.user._id}/join`,
          {
            salonId: payload,
          },
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.auth.user = response.data.staff;
        return response.data.staff;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async leaveSalon({ rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/staff/${rootState.auth.user._id}/leave`,
          {
            salonId: payload,
          },
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.auth.user = response.data.staff;
        return response.data.staff;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async createGroup({ rootState }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/groups`,
          { name: payload },
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        return response.data.groups;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async editGroup({ rootState }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/groups/${payload.oldName}`,
          { name: payload.newName },
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        return response.data.groups;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async deleteGroup({ rootState }, payload) {
      try {
        const response = await axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/groups/${payload}`,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        return response.data.groups;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async editStaffAccount({ rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/staff/${payload._id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.auth.user = response.data.staff;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    getStaffCurrentSchedule(_, payload) {
      let schedule = JSON.parse(JSON.stringify(payload.staff.schedule.default));
      schedule = schedule.sort((a, b) => a.day - b.day);

      payload.staff.schedule.exceptions.forEach((exception) => {
        if (
          moment(exception.date).isBetween(
            moment(payload.startDate).startOf('day'),
            moment(payload.endDate).endOf('day'),
            undefined,
            '[]'
          )
        ) {
          schedule[moment(exception.date).day()].blocks = exception.blocks;
          schedule[moment(exception.date).day()].isException = true;
        }
      });

      return schedule;
    },

    getStaffScheduleForDay({ rootState }, payload) {
      let schedule = JSON.parse(JSON.stringify(payload.staff.schedule.default));
      const dateSchedule = schedule[moment(payload.date).day()];

      let timezone;

      if (rootState.booking.salon) {
        timezone = rootState.booking.salon.details.timezone;
      } else {
        timezone = rootState.auth.salon.details.timezone;
      }

      payload.staff.schedule.exceptions.forEach((exception) => {
        if (
          moment(exception.date)
            .add(1, 'second')
            .tz(timezone)
            .isBetween(
              moment(payload.date).startOf('day'),
              moment(payload.date).endOf('day')
            )
        ) {
          dateSchedule.blocks = exception.blocks;
          dateSchedule.isException = true;
        }
      });

      return dateSchedule;
    },

    getCommissionEarned(_, payload) {
      try {
        const { item, feeFirst } = payload;

        const staff = item.staff;

        if (!staff)
          return {
            commissionEarned: 0,
            flatComServEarned: 0,
            flatComProdEarned: 0,
          };

        let commissionEarned = 0;
        let flatComServEarned = 0;
        let flatComProdEarned = 0;

        let customCommission;
        const ccIndex = staff.customCommissions.findIndex(
          (cat) => cat.categoryId === item.item.categoryId
        );
        if (ccIndex !== -1) {
          customCommission = staff.customCommissions[ccIndex].commission;
        }

        if (item.item.duration) {
          if (item.item.isCommissioned) {
            if (item.item.customItemPriceForCommission) {
              commissionEarned =
                (item.item.customItemPriceForCommission -
                  (item.item.discountAmount || 0) +
                  (item.item.ownerDiscountAmount || 0) -
                  (feeFirst
                    ? item.item.fees && !item.staff.exemptFromServiceFees
                      ? item.item.fees.supply + item.item.fees.other
                      : 0
                    : 0)) *
                item.quantity *
                ((customCommission || item.staff.commission) * 0.01);
            } else if (item.item.commissionType === 'flat') {
              // commissionEarned += item.quantity * item.item.commissionAmount;
              flatComServEarned += item.quantity * item.item.commissionAmount;
            } else if (item.item.commissionType === 'percent') {
              commissionEarned =
                (item.item.price -
                  (item.item.discountAmount || 0) +
                  (item.item.ownerDiscountAmount || 0) -
                  (item.item.cashDiscountAdditionalProcessingFee || 0) -
                  (feeFirst
                    ? item.item.fees
                      ? item.item.fees.supply + item.item.fees.other
                      : 0
                    : 0)) *
                item.quantity *
                ((customCommission || item.staff.commission) * 0.01);
            }
          }
        } else {
          if (item.item.commissionType === 'flat') {
            // commissionEarned += item.quantity * item.item.commissionAmount;
            flatComProdEarned += item.quantity * item.item.commissionAmount;
          } else if (item.item.commissionType === 'percent') {
            commissionEarned =
              (item.item.price -
                (item.item.discountAmount || 0) +
                (item.item.ownerDiscountAmount || 0) -
                (item.item.cashDiscountAdditionalProcessingFee || 0)) *
              item.quantity *
              (item.item.commissionAmount * 0.01);
          }
        }

        if (!feeFirst) {
          if (item.item.fees && !item.staff.exemptFromServiceFees) {
            commissionEarned -=
              ((item.item.fees.supply || 0) + (item.item.fees.other || 0)) *
              item.quantity;
          }
        }

        return {
          commissionEarned,
          flatComServEarned,
          flatComProdEarned,
        };
      } catch (error) {
        throw Error(error.message);
      }
    },
  },
  getters: {
    bookingStaff(state) {
      return state.staff.filter((staff) => staff.onBooking);
    },
  },
};
