<template>
  <div class="endpoint_head">
    <h2>Create gift card</h2>

    <div class="route">
      <i
        @click="
          $router.push('/documentation/api/giftcards#create');
          $toast.info('Set URL to endpoint');
        "
        :style="{ color: 'var(--clr-secondary)', cursor: 'pointer' }"
        class="fas fa-hashtag"
      ></i>
      <p class="tag tag-blue">POST</p>
      <p class="url">{{ apiUrl }}<span>/giftcards</span></p>
    </div>

    <div class="description">
      <p>Creates a gift card</p>
    </div>
  </div>

  <div class="endpoint_body">
    <div class="request">
      <h3>Request</h3>

      <!-- Headers -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Headers</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">Authorization</p>
              <p class="examples">Examples: <span>Bearer {Token}</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
          <p>application/json</p>
        </div>

        <ul>
          <!-- salonId -->
          <li>
            <div class="col value">
              <p class="bold">salonId</p>
              <p class="description">
                String - ID of salon adding gift card to
              </p>
              <p class="examples">
                Examples: <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6f</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- code -->
          <li>
            <div class="col value">
              <p class="bold">code</p>
              <p class="description">String - Gift card code</p>
              <p class="examples">Examples: <span>99887766</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- amount -->
          <li>
            <div class="col value">
              <p class="bold">amount</p>
              <p class="description">Number - Gift card amount ($)</p>
              <p class="examples">Examples: <span>100</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- expiration -->
          <li>
            <div class="col value">
              <p class="bold">expiration</p>
              <p class="description">Date</p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- client -->
          <li>
            <div class="col value">
              <p class="bold">client</p>
              <p class="description">
                Object - { firstName, lastName, profileImage, clientId }
              </p>
              <p class="examples">
                Examples:
                <span
                  >{ "firstName": "John", "lastName": "Doe", "profileImage": "",
                  "clientId": "5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6f"}</span
                >
              </p>
            </div>

            <!-- billing -->
          </li>

          <li>
            <div class="col value">
              <p class="bold">billing</p>
              <p class="description">
                Object - { date, amount, status ("default" or "refunded"), type
                ("cash" or "card"), ref, processor }
              </p>
              <p class="examples">
                Examples:
                <span
                  >{ "date": "2023-07-24T21:13:17.843Z", "amount": 100,
                  "status": "default", "type": "card", "ref": "123456789",
                  "processor": "dejavoo" }</span
                >
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="response">
      <h3>Response</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">category</p>
              <p class="examples">
                Examples:
                <span
                  >{ "title": "API", "color": "#99cc8f", "onKiosk": true, "_id":
                  "64aefa1a1cdc59044b2a3688", "items": [] }</span
                >
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="reqResExamples">
      <h3>Examples</h3>

      <!-- Request -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Request</h4>
          <p>application/json</p>
        </div>

        <ul>
          <li>
            <p>{ "title": "API", "color": "#99cc8f" }</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    apiUrl() {
      return process.env.VUE_APP_RASERVA_BACKEND;
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoint {
  .tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px;
    border-radius: 5px;
    color: white;

    &-blue {
      background-color: var(--clr-secondary);
    }
  }

  &_head {
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }

    .route {
      padding: 8px 16px;
      border-radius: 5px;
      background-color: var(--clr-white-2);
      display: flex;
      align-items: center;
      gap: 16px;

      .url {
        color: var(--clr-gray);

        span {
          color: var(--clr-primary);
          font-weight: 700;
        }
      }
    }

    .description {
      margin-top: 32px;
    }
  }

  &_body {
    .request,
    .response,
    .reqResExamples {
      margin-top: 32px;
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &_section {
      &:not(:first-child) {
        margin-top: 32px;
      }

      .head {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 20px;
          margin-bottom: 16px;
          color: var(--clr-secondary);
        }

        p {
          font-size: 14px;
          color: var(--clr-gray);
        }
      }

      ul {
        .subitem {
          margin-left: 32px;
        }

        li {
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          &:not(:first-child) {
            margin-top: 16px;
          }

          .value {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .bold {
            font-weight: 700;
          }
          .required {
            color: orangered;
          }

          .description {
            margin: 4px 0;
            color: var(--clr-gray);
          }

          .examples {
            span {
              background-color: var(--clr-white-2);
              padding: 4px 8px;
              border-radius: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
