<template>
  <section class="ticketItem" :class="{ error: item.error }">
    <!-- Approval -->
    <div v-if="item.approval" class="approved">
      <i class="fas fa-check"></i>
      <p>{{ item.approval }}</p>
      <i @click="newItem.approval = null" class="fas fa-times"></i>
    </div>

    <i class="fas fa-times removeItemAction" @click="$emit('remove')"></i>
    <i
      v-if="
        ticketStatus !== 'in-progress' &&
        ticketStatus !== 'waiting' &&
        item.quantity > 0
      "
      class="fas fa-undo-alt"
      @click="$emit('refund')"
    ></i>
    <div class="ticketItem_head">
      <div class="col head-title">
        <div v-if="quickSuggestions.length" class="col">
          <BaseButton
            @click="showQuickSuggestions = true"
            mode="secondary-outline small"
          >
            <i class="fas fa-bolt"></i>
          </BaseButton>
        </div>
        <div class="col">
          <p class="title" :class="{ red: item.quantity < 0 }">
            {{ item.item.title }}
            <span class="loyaltyRedemption" v-if="item.item.isLoyaltyRedemption"
              ><i class="fas fa-check"></i> Loyalty Redemption</span
            >
          </p>
          <p v-if="!classicView" class="subline">
            {{ itemSubtitle(item.item) }}
          </p>
          <p v-else-if="item.staff" class="subline">
            With: {{ `${item.staff.firstName} ${item.staff.lastName}` }}
          </p>
        </div>
      </div>
      <div class="col">
        <p
          class="price"
          :class="{
            strike: newItem.item.discountAmount || newItem.item.prepaid,
          }"
        >
          ${{ (item.item.price * item.quantity).toFixed(2) }}
        </p>
        <p v-if="newItem.item.discountAmount">
          ${{
            +(
              (item.item.basePrice -
                newItem.item.discountAmount +
                (usesCashDiscount
                  ? item.item.cashDiscountAdditionalProcessingFee || 0
                  : 0)) *
              item.quantity
            ).toFixed(2)
          }}
        </p>
        <p v-else-if="newItem.item.prepaid">$0.00</p>
      </div>
    </div>

    <div
      v-if="!classicView || (classicView && showForm)"
      class="ticketItem_form"
      :class="{ vertical: classicView }"
    >
      <BaseInput
        label="Quantity"
        inputType="number"
        step="1"
        :value="newItem.quantity"
        :disabled="
          newItem.item.packageId ||
          newItem.item.clientPackageId ||
          newItem.item.isLoyaltyRedemption
        "
        @input="
          newItem.quantity = +$event;
          $emit('update', newItem);
        "
      />
      <BaseInput
        v-if="item.item.priceType === 'duration'"
        label="Duration"
        inputType="number"
        :value="+item.item.duration.toFixed(0)"
        @input="
          updateDuration(+$event);
          $emit('update', newItem);
        "
        placeholder="15 mins"
      />
      <BaseInput
        :label="item.item.priceType === 'duration' ? 'Price per min' : 'Price'"
        inputType="number"
        :value="
          item.item.basePrice
            ? +item.item.basePrice.toFixed(2)
            : +item.item.price.toFixed(2)
        "
        :disabled="
          !canEditPrices ||
          item.item.priceType === 'duration' ||
          item.item.isLoyaltyRedemption
        "
        @input="
          updateItem(+$event);
          $emit('update', newItem);
        "
        placeholder="USD"
      />
      <BaseSelect
        label="Staff"
        placeholder="Select staff"
        :value="item.staff ? item.staff.staffId : null"
        :options="staffForSelect"
        @input="
          selectStaff($event);
          $emit('update', newItem);
        "
      />
      <BaseSelect
        v-if="canAddDiscount"
        label="Discount"
        :value="discountValue"
        :options="discountOptions"
        :disabled="item.item.isLoyaltyRedemption"
        @input="
          selectDiscount($event);
          $emit('update', newItem);
        "
      />
      <BaseInput
        v-if="newItem.item.duration && (managerMode || canEditTurns)"
        label="Turns"
        inputType="number"
        step="0.5"
        :value="newItem.item.turns"
        @input="
          newItem.item.turns = +$event;
          $emit('update', newItem);
        "
      />
      <div
        v-if="useRequestedServiceTurnsValue && (canSetRequested || managerMode)"
        class="requested"
      >
        <i
          v-if="newItem.item.requested"
          class="fas fa-heart"
          @click="toggleRequested"
        ></i>
        <i v-else class="far fa-heart" @click="toggleRequested"></i>
      </div>
    </div>

    <div class="classicViewStaffInput" v-if="classicView && !showForm">
      <BaseSelect
        label="Staff"
        placeholder="Select staff"
        :value="item.staff ? item.staff.staffId : null"
        :options="staffForSelect"
        @input="
          selectStaff($event);
          $emit('update', newItem);
        "
      />

      <div
        v-if="useRequestedServiceTurnsValue && (canSetRequested || managerMode)"
        class="requested"
      >
        <i
          v-if="newItem.item.requested"
          class="fas fa-heart"
          @click="toggleRequested"
        ></i>
        <i v-else class="far fa-heart" @click="toggleRequested"></i>
      </div>
    </div>

    <div v-if="newItem.item.packageId" class="packageDetails">
      <p class="link" @click="selectPackage(newItem.item.packageId)">
        Show package details or use package
      </p>
    </div>

    <p
      v-if="classicView && !showForm"
      @click="showForm = true"
      class="link mt-m"
    >
      Show Details
    </p>
    <p
      v-else-if="classicView && showForm"
      @click="showForm = false"
      class="link mt-m"
    >
      Hide Details
    </p>
  </section>

  <Modal
    title="Quick Suggestions"
    :show="showQuickSuggestions"
    @close="showQuickSuggestions = false"
    maxWidth="500px"
  >
    <div class="quickSuggestions">
      <BaseButton
        @click="addQuickSuggestionToTicket(suggestion)"
        v-for="suggestion in quickSuggestions"
        :key="suggestion._id"
        >{{ suggestion.title }} - ${{ suggestion.price.toFixed(2) }}</BaseButton
      >
    </div>
  </Modal>
</template>

<script>
export default {
  emits: ['remove', 'update', 'refund', 'selectPackage', 'addQuickSuggestion'],
  props: {
    item: {
      type: Object,
      required: true,
    },
    ticketStatus: {
      type: String,
      required: true,
    },
    classicView: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.init();
  },
  watch: {
    item() {
      this.init();
    },
  },
  data() {
    return {
      loading: false,
      newItem: null,
      staff: [],

      discountOptions: [],

      canEditPrices: false,
      canAddDiscount: false,
      canSetRequested: false,

      showItemSettings: false,

      canEditTurns: false,
      showForm: false,

      showQuickSuggestions: false,
    };
  },
  computed: {
    quickSuggestions() {
      const suggestions = [];

      if (!this.newItem.item.suggestedServices) return suggestions;

      this.newItem.item.suggestedServices.forEach((suggestion) => {
        const service = this.$store.getters['items/services'].find(
          (service) => {
            return service._id.toString() === suggestion.toString();
          }
        );

        if (!service) return;

        suggestions.push(JSON.parse(JSON.stringify(service)));
      });

      return suggestions;
    },
    staffForSelect() {
      let staffs = JSON.parse(JSON.stringify(this.staff));

      staffs.sort((a, b) => a.firstName.localeCompare(b.firstName));

      staffs = staffs
        .filter((staff) => !staff.isDeleted)
        .map((staff) => {
          return {
            option: `${staff.firstName} ${staff.lastName}`,
            value: staff.staffId,
          };
        });

      staffs.unshift({
        option: 'No staff',
        value: null,
      });

      return staffs;
    },
    discounts() {
      return this.$store.state.items.discounts;
    },
    discountValue() {
      return this.item.item.prepaid
        ? 'raservaraservedprepaid'
        : this.item.item.discount
        ? this.item.item.discount._id
        : null;
    },
    useRequestedServiceTurnsValue() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.adminSettings
        .useRequestedServiceTurnsValue;
    },
    requestedServiceTurnsValue() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.adminSettings
        .requestedServiceTurnsValue;
    },
    managerMode() {
      return this.$store.state.auth.managerMode;
    },
    usesCashDiscount() {
      return this.$store.state.auth.salon.payments.useCashDiscount;
    },
  },
  methods: {
    async init() {
      this.newItem = this.item;
      this.staff = this.$store.state.staff.staff;

      if (!this.newItem.item.baseTurns) {
        this.newItem.item.baseTurns = this.newItem.item.turns;
      }

      this.canEditPrices = await this.$store.dispatch(
        'auth/activeUserHasPermission',
        'tickets/editPricesAtCheckout'
      );

      this.canAddDiscount = await this.$store.dispatch(
        'auth/activeUserHasPermission',
        'tickets/addDiscountsAtCheckout'
      );

      this.canSetRequested = await this.$store.dispatch(
        'auth/activeUserHasPermission',
        'tickets/setRequestedAtCheckout'
      );

      this.canEditTurns = await this.$store.dispatch(
        'auth/activeUserHasPermission',
        'tickets/editTurnsOnItems'
      );

      this.setDiscountOptions();
    },
    updateItem(price) {
      this.newItem.item.basePrice = price;

      if (this.$store.state.auth.salon.payments.useCashDiscount) {
        this.newItem.item.cashDiscountAdditionalProcessingFee =
          this.newItem.item.basePrice *
          (this.$store.state.auth.salon.payments
            .cashDiscountAdditionalProcessingFee *
            0.01);
      }

      this.newItem.item.price =
        this.newItem.item.basePrice *
          (this.newItem.item.priceType === 'duration'
            ? this.newItem.item.duration
            : 1) +
        (this.newItem.item.cashDiscountAdditionalProcessingFee || 0);
    },
    updateDuration(duration) {
      if (this.newItem.item.priceType !== 'duration') return;

      this.newItem.item.duration = duration;

      if (this.$store.state.auth.salon.payments.useCashDiscount) {
        this.newItem.item.cashDiscountAdditionalProcessingFee =
          this.newItem.item.basePrice *
          duration *
          (this.$store.state.auth.salon.payments
            .cashDiscountAdditionalProcessingFee *
            0.01);
      }

      this.newItem.item.price =
        this.newItem.item.basePrice * duration +
          this.newItem.item.cashDiscountAdditionalProcessingFee || 0;
    },
    toggleRequested() {
      if (!this.newItem.item.requested) this.newItem.item.requested = false;
      this.newItem.item.requested = !this.newItem.item.requested;

      if (this.newItem.item.requested) {
        this.newItem.item.turns = this.requestedServiceTurnsValue;
      } else {
        this.newItem.item.turns = this.newItem.item.baseTurns;
      }
    },
    async setDiscountOptions() {
      const options = [
        {
          option: 'No discount',
          value: null,
        },
      ];

      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'tickets/setPrepaidAtCheckout'
        )
      ) {
        options.push({
          option: 'Prepaid',
          value: 'raservaraservedprepaid',
        });
      }

      const role = this.$store.state.auth.managerMode
        ? 'high'
        : this.$store.state.auth.tempUser
        ? this.$store.state.auth.tempUser.role
        : this.$store.state.auth.loggedInSalonStaff.role;

      this.discounts.forEach((discount) => {
        if (discount.minimumRole === 'low') {
          if (role === 'basic') return;
        } else if (discount.minimumRole === 'medium') {
          if (role === 'basic') return;
          if (role === 'low') return;
        } else if (discount.minimumRole === 'high') {
          if (role === 'basic') return;
          if (role === 'low') return;
          if (role === 'medium') return;
        }

        options.push({
          option: discount.title,
          value: discount._id,
        });
      });

      this.discountOptions = options;
    },
    itemSubtitle(item) {
      if (item.duration) {
        return `${item.duration} mins`;
      }

      return `${item.stock} in stock`;
    },
    selectStaff(_id) {
      this.newItem.staff = this.staff.find((staff) => staff.staffId === _id);
    },
    selectDiscount(_id) {
      this.newItem.item.prepaid = false;
      this.newItem.item.discount = null;
      this.newItem.item.discountAmount = null;
      this.newItem.item.ownerDiscountAmount = null;

      if (_id === 'No discount') {
        this.newItem.item.discount = null;
        this.newItem.item.discountAmount = null;

        if (this.$store.state.auth.salon.payments.useCashDiscount) {
          this.newItem.item.cashDiscountAdditionalProcessingFee =
            this.newItem.item.basePrice *
            (this.$store.state.auth.salon.payments
              .cashDiscountAdditionalProcessingFee *
              0.01);
        }

        return;
      } else if (_id === 'raservaraservedprepaid') {
        this.newItem.item.prepaid = true;

        if (this.$store.state.auth.salon.payments.useCashDiscount) {
          this.newItem.item.cashDiscountAdditionalProcessingFee =
            this.newItem.item.basePrice *
            (this.$store.state.auth.salon.payments
              .cashDiscountAdditionalProcessingFee *
              0.01);
        }

        return;
      }

      const discount = this.discounts.find((discount) => discount._id === _id);

      this.newItem.item.discount = discount;

      if (!this.newItem.item.basePrice) {
        if (this.newItem.item.duration) {
          // Service
          const existingItem = JSON.parse(
            JSON.stringify(
              this.$store.getters['items/services'].find(
                (service) => service._id === this.newItem.item._id
              )
            )
          );

          this.newItem.item.basePrice = existingItem.price;
        } else {
          // Product
          const existingItem = JSON.parse(
            JSON.stringify(
              this.$store.getters['items/products'].find(
                (service) => service._id === this.newItem.item._id
              )
            )
          );

          this.newItem.item.basePrice = existingItem.price;
        }
      }

      if (discount.type === 'fixed') {
        this.newItem.item.discountAmount = discount.amount;

        if (discount.ownerPercent) {
          this.newItem.item.ownerDiscountAmount =
            discount.amount * (discount.ownerPercent * 0.01);
        }
      } else {
        this.newItem.item.discountAmount = +(
          this.newItem.item.basePrice *
          this.newItem.quantity *
          (discount.amount * 0.01)
        ).toFixed(2);

        if (discount.ownerPercent) {
          this.newItem.item.ownerDiscountAmount =
            this.newItem.item.discountAmount * (discount.ownerPercent * 0.01);
        }
      }

      if (this.newItem.item.cashDiscount) {
        this.newItem.item.cashDiscountAdditionalProcessingFee = 0;
        return;
      }

      this.newItem.item.cashDiscountAdditionalProcessingFee =
        (this.newItem.item.basePrice - this.newItem.item.discountAmount) *
        (this.$store.state.auth.salon.payments
          .cashDiscountAdditionalProcessingFee *
          0.01);
    },

    selectPackage(packageId) {
      this.$emit('selectPackage', packageId);
    },

    addQuickSuggestionToTicket(suggestion) {
      if (this.loading) return;

      this.loading = true;
      this.$emit('addQuickSuggestion', {
        ...suggestion,
        useThisStaff: this.newItem.staff,
      });

      this.showQuickSuggestions = false;

      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  border-color: var(--clr-danger) !important;
}
.mt-m {
  margin-top: 24px !important;
}

.link {
  font-size: 14px;
  margin-top: 8px;
  color: var(--clr-link);
  cursor: pointer;
}
.approved {
  position: absolute;
  top: -12px;
  left: 10px;
  background-color: var(--clr-success-light);
  color: var(--clr-success-dark);
  border: 1px solid var(--clr-success);
  border-radius: 500px;
  display: flex;
  align-items: flex-end;
  gap: 8px;
  font-size: 12px;
  padding: 5px 16px;

  .fa-times {
    margin-left: 12px;
    cursor: pointer;
  }
}

.loyaltyRedemption {
  margin-left: 16px;
  background-color: var(--clr-secondary-white);
  color: var(--clr-secondary-dark);
  border: 1px solid var(--clr-secondary);
  border-radius: 500px;
  gap: 8px;
  font-size: 12px;
  padding: 5px 16px;

  i {
    margin-right: 8px;
  }
}
.ticketItem {
  background-color: white;
  padding: 32px;
  border-radius: 10px;
  border: 1px solid var(--clr-light);
  position: relative;
  transition: border-color 0.2s;

  .removeItemAction {
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--clr-danger);
    cursor: pointer;
  }
  .fa-cog {
    font-size: 14px;
    position: absolute;
    top: 12px;
    right: 40px;
    cursor: pointer;
    color: var(--clr-link);
  }
  .fa-undo-alt {
    font-size: 14px;
    position: absolute;
    top: 12px;
    right: 40px;
    cursor: pointer;
    color: var(--clr-link);
  }

  &_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    .head-title {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .red {
      color: var(--clr-danger);
    }

    .strike {
      text-decoration: line-through;
    }

    .title,
    .price {
      font-weight: 700;
    }

    .subline {
      font-size: 14px;
      color: var(--clr-gray);
    }
  }

  &_form {
    margin-top: 32px;
    display: flex;
    gap: 16px;
  }

  .vertical {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  .requested {
    display: flex;
    align-items: flex-end;

    i {
      font-size: 24px;
      cursor: pointer;
    }

    .fas {
      color: var(--clr-danger);
    }
    .far {
      color: var(--clr-light-2);
    }
  }
}

.packageDetails {
  margin-top: 16px;
}

.classicViewStaffInput {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 32px;

  .parent {
    flex-grow: 1;
  }
}

.quickSuggestions {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

// Tablet
@media (max-width: 900px) {
  .approved {
    max-width: 250px;
    top: -19px;
  }
  .ticketItem_form {
    flex-direction: column;
  }
}
</style>
