<template>
  <section v-if="member" class="salons">
    <div class="salons_head">
      <h2>
        Welcome,
        <router-link :to="{ name: 'StaffProfile' }"
          ><span class="link">{{ memberName }}</span></router-link
        >!
      </h2>
      <p>Select a salon to continue.</p>
    </div>
    <ul>
      <li
        v-for="salon in member.salons"
        :key="salon"
        @click="getSalon(salon.salonId)"
      >
        <div v-if="isAdmin(salon.adminId)" class="col">
          <i class="fas fa-crown"></i>
        </div>
        <div class="col">
          <p v-if="salon.altName" class="light small">"{{ salon.altName }}"</p>
          <p>{{ salon.name }}</p>
          <p class="date">Joined: {{ formatDate(salon.joined_at) }}</p>
        </div>
      </li>

      <router-link :to="{ name: 'SalonRegister' }">
        <li class="create">
          <i class="fas fa-plus"></i>
          <div class="text">
            <p>Create a salon</p>
            <p class="date">
              Creating a salon will require payment information
            </p>
          </div>
        </li>
      </router-link>
    </ul>
  </section>
</template>

<script>
export default {
  computed: {
    member() {
      return this.$store.state.auth.user;
    },
    memberName() {
      return `${this.member.firstName} ${this.member.lastName}`;
    },
  },
  created() {
    this.$store.state.auth.salon = null;
  },
  methods: {
    isAdmin(adminId) {
      return this.$store.state.auth.user._id === adminId;
    },
    async getSalon(id) {
      try {
        await this.$store.dispatch('auth/selectSalon', id);

        this.$router.push({ name: 'Calendar' });
      } catch (error) {
        console.log(error);
        this.$toast.error(error.message);

        if (
          error.message === 'Subscribe to continue' ||
          error.message === 'Subscription inactive'
        ) {
          this.$router.push({ name: 'StaffProfile ' });
        }
      }
    },
    formatDate(date) {
      return this.$moment(date).format('LL');
    },
  },
};
</script>

<style lang="scss" scoped>
.salons {
  padding: 64px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  &_head {
    text-align: center;

    p {
      margin-top: 6px;
    }
    .link {
      color: var(--clr-link);
      cursor: pointer;
    }
  }

  .small {
    font-size: 12px;
    color: var(--clr-gray);
  }

  ul {
    width: 100%;
    max-width: 600px;

    li {
      display: flex;
      gap: 24px;
      align-items: center;
      padding: 16px 24px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      transition: box-shadow 0.2s ease;
      cursor: pointer;

      .fa-crown {
        color: var(--clr-secondary);
        font-size: 24px;
      }

      &:hover {
        box-shadow: var(--shadow);
      }

      .date {
        margin-top: 6px;
        font-size: 12px;
        color: var(--clr-gray);
      }

      &:not(:first-child) {
        margin-top: 16px;
      }
    }

    .create {
      margin-top: 16px;
      border-color: var(--clr-light-2);
      display: flex;
      align-items: center;
      gap: 32px;
      font-weight: bold;

      i {
        color: var(--clr-link);
      }
    }
  }
}
</style>
