<template>
  <section class="endpoints">
    <div class="endpoints_router">
      <p class="bold">On this page:</p>

      <ul>
        <li class="link" @click="scrollIntoView('staffLogin')">Staff login</li>
        <li class="link" @click="scrollIntoView('staffRegister')">
          Staff register
        </li>
        <li class="link" @click="scrollIntoView('clientLogin')">
          Client login
        </li>
        <li class="link" @click="scrollIntoView('clientRegister')">
          Client register
        </li>
        <li class="link" @click="scrollIntoView('staffRecoverGet')">
          Get staff recovery code
        </li>
        <li class="link" @click="scrollIntoView('staffRecoverPost')">
          Reset staff password
        </li>
        <li class="link" @click="scrollIntoView('clientRecoverGet')">
          Get client recovery code
        </li>
        <li class="link" @click="scrollIntoView('clientRecoverPost')">
          Reset client password
        </li>
      </ul>
    </div>

    <!-- Staff Login -->
    <div class="endpoint" id="staffLogin" ref="staffLogin">
      <DocsAPIAuthStaffLogin />
    </div>

    <!-- Staff register -->
    <div class="endpoint" id="staffRegister" ref="staffRegister">
      <DocsAPIAuthStaffRegister />
    </div>

    <!-- Client login -->
    <div class="endpoint" id="clientLogin" ref="clientLogin">
      <DocsAPIAuthClientLogin />
    </div>

    <!-- Client register -->
    <div class="endpoint" id="clientRegister" ref="clientRegister">
      <DocsAPIAuthClientRegister />
    </div>

    <!-- Staff recovery get -->
    <div class="endpoint" id="staffRecoverGet" ref="staffRecoverGet">
      <DocsAPIAuthStaffRecoveryGet />
    </div>

    <!-- Staff recovery post -->
    <div class="endpoint" id="staffRecoverPost" ref="staffRecoverPost">
      <DocsAPIAuthStaffRecoveryPost />
    </div>

    <!-- Client recovery get -->
    <div class="endpoint" id="clientRecoverGet" ref="clientRecoverGet">
      <DocsAPIAuthClientRecoveryGet />
    </div>

    <!-- Client recovery post -->
    <div class="endpoint" id="clientRecoverPost" ref="clientRecoverPost">
      <DocsAPIAuthClientRecoveryPost />
    </div>
  </section>
</template>

<script>
import DocsAPIAuthStaffLogin from './DocsAPIAuthStaffLogin.vue';
import DocsAPIAuthStaffRegister from './DocsAPIAuthStaffRegister.vue';
import DocsAPIAuthStaffRecoveryGet from './DocsAPIAuthStaffRecoveryGet.vue';
import DocsAPIAuthStaffRecoveryPost from './DocsAPIAuthStaffRecoveryPost.vue';
import DocsAPIAuthClientLogin from './DocsAPIAuthClientLogin.vue';
import DocsAPIAuthClientRegister from './DocsAPIAuthClientRegister.vue';
import DocsAPIAuthClientRecoveryGet from './DocsAPIAuthClientRecoveryGet.vue';
import DocsAPIAuthClientRecoveryPost from './DocsAPIAuthClientRecoveryPost.vue';

export default {
  components: {
    DocsAPIAuthStaffLogin,
    DocsAPIAuthStaffRegister,
    DocsAPIAuthStaffRecoveryGet,
    DocsAPIAuthStaffRecoveryPost,
    DocsAPIAuthClientLogin,
    DocsAPIAuthClientRegister,
    DocsAPIAuthClientRecoveryGet,
    DocsAPIAuthClientRecoveryPost,
  },
  methods: {
    scrollIntoView(element) {
      this.$refs[element].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoints {
  &_router {
    margin-bottom: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-white-2);

    ul {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 32px;

      .link {
        cursor: pointer;
        color: var(--clr-link);
      }
    }
  }

  .endpoint {
    &:not(:first-child) {
      margin-top: 64px;
    }
  }
}
</style>
