import axios from 'axios';

export default {
  namespaced: true,
  state: {
    clients: [],
    clientsToMerge: [],
  },
  mutations: {
    addClient(state, payload) {
      state.clients.push(payload);
    },
    editClient(state, payload) {
      const index = state.clients.findIndex(
        (client) => client._id === payload._id
      );

      state.clients[index] = { ...state.clients[index], ...payload };
    },
    deleteClient(state, payload) {
      const index = state.clients.findIndex(
        (client) => client._id === payload._id
      );

      state.clients.splice(index, 1);
    },
  },
  actions: {
    async createClient({ rootState, commit }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/clients`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        commit('addClient', response.data.client);
        return response.data.client;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async createGlobalClient({ commit }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/clients`,
          payload
        );

        commit('addClient', response.data.client);
        return response.data.client;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async searchClients({ rootState }, payload) {
      let query = '';

      if (payload) {
        const { name, email, phone, global, page = 1, count = 25 } = payload;

        query += `page=${page}&count=${count}`;

        if (name || email || phone) {
          query += `&name=${name}&phone=${phone}&email=${email}`;
        }

        if (global) {
          query += '&global=true';
        }
      }

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/clients?${query}`,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        return response.data.clients;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async editClient({ commit, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/clients/${payload._id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        commit('editClient', payload);
        return response.data.client;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async deleteClient({ commit, rootState }, payload) {
      try {
        const response = await axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/clients/${payload._id}`,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        commit('deleteClient', payload);
        return response.data.client;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async getClientById({ rootState }, payload) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/clients/${payload}`,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        return response.data.client;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async mergeClients({ state, rootState }) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/clients/merge`,
          {
            clients: state.clientsToMerge,
          },
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        state.clientsToMerge = [];

        return response.data.client;
      } catch (e) {
        throw Error(e.response.data.error);
      }
    },
  },
  getters: {},
};
