<template>
  <section class="methods">
    <BaseSelect
      label="Payment Method"
      :options="paymentMethodsOptions"
      :value="selectedPaymentMethod"
      @input="
        selectedPaymentMethod = $event;
        $emit('select', selectedPaymentMethod);
      "
    />

    <BaseButton
      :disabled="loading"
      type="button"
      mode="secondary-outline"
      @click="stripePortal"
      ><i v-if="loading" class="fas fa-spinner"></i> Add New Payment
      Method</BaseButton
    >
  </section>
</template>

<script>
export default {
  emits: ['select'],
  props: {
    returnUrl: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    clientGroup: {
      type: String,
      default: null,
    },
    subject: {
      type: String,
      default: null,
    },
    head: {
      type: String,
      default: null,
    },
    body: {
      type: String,
      default: null,
    },
    foot: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
  },
  created() {
    this.getPaymentMethods();
  },
  computed: {
    paymentMethodsOptions() {
      return this.paymentMethods.map((method) => {
        return {
          value: method.id,
          option: `${method.card.brand} ****${method.card.last4}`,
        };
      });
    },
  },
  data() {
    return {
      paymentMethods: [],
      selectedPaymentMethod: null,
      loading: false,
    };
  },
  methods: {
    async getPaymentMethods() {
      const response = await this.$axios.get(
        `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/paymentMethods?accountId=${this.$store.state.auth.salon.billing.stripe.customerId}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.auth.token}`,
          },
        }
      );

      this.paymentMethods = response.data.paymentMethods;

      if (this.paymentMethods.length) {
        this.selectedPaymentMethod = this.paymentMethods[0].id;
        this.$emit('select', this.selectedPaymentMethod);
      }
    },

    async stripePortal() {
      this.loading = true;

      // Manage billing
      const response = await this.$axios.post(
        `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/portalSession`,
        {
          stripeCustomerId:
            this.$store.state.auth.salon.billing.stripe.customerId,
          data: {
            title: this.title,
            message: this.message,
            clientGroup: this.clientGroup,
            subject: this.subject,
            head: this.head,
            body: this.body,
            foot: this.foot,
            image: this.image,
          },
          returnUrl: this.returnUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.auth.token}`,
          },
        }
      );

      window.location.href = response.data.url;
    },
  },
};
</script>

<style lang="scss" scoped>
.methods {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 16px;

  .parent {
    flex-grow: 1;
  }
}
</style>
