<template>
  <section class="pageNotFound">
    <div class="pageNotFound_text">
      <h1>Oops!</h1>
      <h3>404 - PAGE NOT FOUND</h3>
      <p>Sorry, the page you are looking for does not exist.</p>

      <!-- <router-link :to="{ name: 'Landing' }">
        <button>Are you lost?</button>
      </router-link> -->
    </div>
  </section>
</template>

<style lang="scss" scoped>
.pageNotFound {
  height: 100vh;
  background-color: #e5e5f7;
  opacity: 0.8;
  background: repeating-linear-gradient(
    45deg,
    #d8daff,
    #d8daff 5px,
    #e5e5f7 5px,
    #e5e5f7 25px
  );
  display: grid;
  place-items: center;

  &_text {
    text-align: center;
    margin-top: 32px;
  }

  h1 {
    font-size: 128px;
  }

  p,
  h3 {
    margin-top: 16px;
  }

  button {
    margin-top: 32px;
    padding: 8px 32px;
    border: none;
    border-radius: 15px;
    background-color: var(--clr-secondary);
    color: var(--clr-white);
    font-size: 18px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      transform: translateY(-2px);
      background-color: var(--clr-secondary-dark);
      box-shadow: var(--shadow);
    }
  }
}
</style>
