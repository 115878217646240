<template>
  <section class="purchase">
    <div class="purchase_head">
      <h2>Purchase A Gift Card</h2>
      <p v-if="min && max">
        This establishment allows gift cards between ${{ min.toFixed(2) }} and
        ${{ max.toFixed(2) }}.
      </p>
    </div>

    <Spinner v-if="loading" fullscreen />

    <div v-if="!client" class="noClient">
      <div class="alert alert-warning">
        <p>
          You must be logged in to purchase a gift card.
          <span @click="clientLogin">Log in here.</span>
        </p>
      </div>
    </div>

    <div v-else-if="client && !paid" class="client">
      <div class="purchase_amount">
        <BaseInput
          label="Amount ($)"
          placeholder="$"
          :value="amount"
          @input="amount = +$event"
          :validators="validators"
          :disabled="loading || dejavooPaymentFormLink"
        />
      </div>
      <div class="purchase_body">
        <!-- Charge Anywhere -->
        <form
          v-if="
            !paid &&
            amount >= min &&
            amount <= max &&
            processor === 'chargeanywhere'
          "
          class="form"
        >
          <iframe
            :src="`/capf.html?mid=${billing.chargeanywhere.pfmid}&tid=${
              billing.chargeanywhere.pftid
            }&amount=${+amount.toFixed(2)}&forceAmount=true`"
            width="100%"
            height="400"
            frameborder="0"
            ref="CAIframe"
          ></iframe>
        </form>

        <!-- Dejavoo -->
        <form
          v-if="
            !paid && amount >= min && amount <= max && processor === 'dejavoo'
          "
          class="form dejavoo"
        >
          <div class="form_actions">
            <!-- DejaVoo Virtual Terminal -->
            <iframe
              v-if="dejavooPaymentFormLink"
              :src="dejavooPaymentFormLink"
              width="100%"
              height="750"
              frameborder="0"
              ref="CAIframe"
            ></iframe>

            <BaseButton
              v-if="!dejavooPaymentFormLink"
              type="button"
              @click="getDejavooPaymentFormLink"
              >Select Amount</BaseButton
            >
          </div>
        </form>
      </div>
    </div>

    <div v-else class="paid">
      <div class="alert alert-success">
        <p>
          Your gift card for
          <span class="bold">${{ amount.toFixed(2) }}</span> with code
          <span class="bold">{{ code }}</span> has been purchased! View your
          gift cards <span @click="clientLogin">here</span>.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    // CA TOKEN
    window.addEventListener('message', this.eventListener);
  },
  async unmounted() {
    window.removeEventListener('message', this.eventListener);
  },
  computed: {
    client() {
      return this.$store.state.booking.activeClient;
    },
    salon() {
      if (!this.$store.state.booking.salon) return;

      return this.$store.state.booking.salon;
    },
    settings() {
      return this.salon.bookingSettings;
    },
    min() {
      return this.settings.onlineGiftCardMin;
    },
    max() {
      return this.settings.onlineGiftCardMax;
    },
    billing() {
      return this.salon.billing;
    },
    processor() {
      return this.salon.payments.processor;
    },
    validators() {
      return {
        min: this.min,
        max: this.max,
        required: true,
      };
    },
  },
  data() {
    return {
      loading: false,
      paid: false,
      amount: 50,
      code: '',
      dejavooPaymentFormLink: null,
    };
  },
  methods: {
    async eventListener(event) {
      // CA Event
      if (event.data.ResponseCode) {
        const customData = event.data;
        const parsed = JSON.parse(JSON.stringify(customData, null, 2));
        if (parsed.TokenNumber) {
          const payment = {
            type: 'card',
            amount: +parsed.AuthorizedAmount,
            ref: `${parsed.GatewayReferenceNumber || parsed.ReferenceNumber}:${
              parsed.ApprovalCode
            }`,
            processor: 'chargeanywhere',
          };

          try {
            this.fulfillOrder(payment);

            this.paid = true;
            this.loading = false;
          } catch (error) {
            console.log(error);
            this.loading = false;
          }
        } else {
          // Have to do it this way or get error:
          // "Assigning src to self"
          const src = this.$refs.CAIframe.src;
          this.$refs.CAIframe.src = src;
          this.$toast.error(`Error; ${parsed.ResponseText} - Please try again`);
          this.loading = false;
        }
      }
      // Raserva Event
      if (event.origin === process.env.VUE_APP_ORIGIN) {
        if (!event.data.processor) {
          this.loading = true;
        } else if (event.data.processor === 'dejavoo') {
          const customData = event.data;

          const payment = {
            type: 'card',
            amount: +this.amount,
            ref: customData.RRN,
            processor: 'dejavoo',
          };

          try {
            this.fulfillOrder(payment);

            this.paid = true;
            this.loading = false;
          } catch (error) {
            console.log(error);
            this.loading = false;
          }
        }
      }
    },

    clientLogin() {
      this.$router.push({
        name: 'ClientAuth',
        query: { source: this.$route.fullPath },
      });
    },

    async getDejavooPaymentFormLink() {
      let transactionType;

      if (this.amount) {
        transactionType = 1;
      } else {
        transactionType = 2;
      }

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/dejavoo/getPaymentFormLink`,
          {
            salonId: this.$store.state.auth.salon._id,
            amount: +(this.amount * 100).toFixed(0),
            returnUrl: `${process.env.VUE_APP_ORIGIN}/djvpfreturn.html`,
            transactionType,
          }
        );

        this.dejavooPaymentFormLink = response.data.link;
      } catch (e) {
        console.log(e);
      }
    },

    async fulfillOrder(payment) {
      const timestamp = this.$moment().format('X');

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/giftcards/online`,
          {
            salonId: this.$store.state.booking.salon._id,
            client: this.client,
            billing: payment,
            code: `RA-${timestamp}`,
            amount: payment.amount,
            purchasedOnline: true,
          }
        );

        this.code = response.data.giftcard.code;

        this.client.giftcards.push({
          ...response.data.giftcard,
          salonId: { details: { shopName: this.salon.details.shopName } },
        });
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.purchase {
  padding: 32px;
  border: 1px solid var(--clr-light);
  border-radius: 5px;

  &_head {
    p {
      margin-top: 8px;
      color: var(--clr-gray);
    }
  }

  .client,
  .noClient {
    margin-top: 32px;
  }
}

.dejavoo {
  .form_actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
  }
}

.alert {
  padding: 12px;
  font-size: 14px;
  border-radius: 5px;

  .bold {
    text-decoration: none;
    font-weight: 700;
    cursor: default;
  }
  span {
    text-decoration: underline;
    cursor: pointer;
  }

  &-success {
    background-color: #c3f7c7;
    color: #04530b;
    border-color: #acf7b2;
    margin-top: 16px;
  }
  &-warning {
    background-color: var(--clr-warning-light);
    border-color: var(--clr-warning);
  }
}
</style>
