<template>
  <section class="book">
    <div class="book_details">
      <BookDetails />
    </div>
    <div class="book_router">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import BookDetails from '@/components/booking/BookDetails.vue';

export default {
  components: {
    BookDetails,
  },
  unmounted() {
    this.$store.dispatch('booking/clearData');
  },
};
</script>

<style lang="scss" scoped>
.book {
  margin-top: 64px;
  display: grid;
  align-items: flex-start;
  grid-template-columns: auto 1fr;
  gap: 128px;

  &_details {
    width: 300px;
    max-width: 100%;
    position: sticky;
    top: 24px;
  }

  &_router {
    padding-bottom: 120px;
  }
}

// Tablet
@media (max-width: 900px) {
  .book {
    grid-template-columns: 1fr;
    gap: 64px;
    margin-top: 32px;

    &_details {
      justify-self: center;
      width: 100%;
      position: inherit;
    }
  }
}
</style>
