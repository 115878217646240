<template>
  <section class="packages">
    <div class="packages_head">
      <h2>Packages</h2>
      <p>
        Offer services in bulk at a discounted rate with packages. Package
        payments can be set to recurring using subscriptions.
      </p>
    </div>

    <div class="packages_actions">
      <BaseButton @click="showPackageForm = true">Create Package</BaseButton>
    </div>

    <table v-if="packages && packages.length">
      <thead>
        <tr>
          <th>Title</th>
          <th>Description</th>
          <th>Price</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="pack in packages"
          :key="pack._id"
          @click="selectedPackage = pack"
        >
          <td>{{ pack.title }}</td>
          <td>{{ pack.description }}</td>
          <td>{{ pack.price.toFixed(2) }}</td>
        </tr>
      </tbody>
    </table>

    <div class="packages_none" v-else>
      <h1>There are no packages to display</h1>
      <p>Get started by creating a new package.</p>
    </div>
  </section>

  <Modal
    :show="showPackageForm || selectedPackage"
    @close="
      showPackageForm = false;
      selectedPackage = null;
    "
    title="Package Form"
  >
    <div class="packagesForm">
      <PackageForm
        :package="selectedPackage"
        @submitted="
          showPackageForm = false;
          selectedPackage = null;
        "
      />
    </div>
  </Modal>
</template>

<script>
import PackageForm from '@/components/items/PackageForm.vue';

export default {
  components: {
    PackageForm,
  },
  data() {
    return {
      showPackageForm: false,
      selectedPackage: null,
    };
  },
  computed: {
    packages() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.packages;
    },
  },
};
</script>

<style lang="scss" scoped>
.packages {
  width: 100%;
  max-width: 800px;

  &_head {
    h2 {
      font-size: 28px;
    }
    p {
      margin-top: 8px;
      color: var(--clr-gray);
    }
  }
  &_actions {
    margin-top: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-extra-light);
    display: flex;
    justify-content: flex-end;
  }
  &_none {
    text-align: center;
    padding: 16px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;
    margin-top: 32px;

    p {
      margin-top: 5px;
    }
  }

  table {
    margin-top: 32px;
  }
}

.packagesForm {
  padding: 32px;
}
</style>
