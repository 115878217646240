<template>
  <div class="endpoint_head">
    <h2>Charge via token</h2>

    <div class="route">
      <i
        @click="
          $router.push('/documentation/api/dejavoo#chargeViaToken');
          $toast.info('Set URL to endpoint');
        "
        :style="{ color: 'var(--clr-secondary)', cursor: 'pointer' }"
        class="fas fa-hashtag"
      ></i>
      <p class="tag tag-blue">POST</p>
      <p class="url">{{ apiUrl }}<span>/dejavoo/chargeViaToken</span></p>
    </div>

    <div class="description">
      <p>Charge via token</p>
    </div>
  </div>

  <div class="endpoint_body">
    <div class="request">
      <h3>Request</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
          <p>application/json</p>
        </div>

        <ul>
          <!-- Salon ID -->
          <li>
            <div class="col value">
              <p class="bold">salonID</p>
              <p class="description">String/ID of the salon</p>
              <p class="examples">
                Examples: <span>1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- Amount -->
          <li>
            <div class="col value">
              <p class="bold">amount</p>
              <p class="description">
                (USD) Amount to be charged from customer card / account. USD
                amount to be multiplied by 100
              </p>
              <p class="examples">Examples: <span>1000 = $10.00</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- Card token -->
          <li>
            <div class="col value">
              <p class="bold">cardToken</p>
              <p class="description">
                Card tokenization value/string to capture the said amount to the
                given tokenized card
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="response">
      <h3>Response</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">data</p>
              <p class="examples">
                Examples:
                <span
                  >{ "iposhpresponse": { "responseCode": "200",
                  "responseMessage": "Success", "errResponseCode": "",
                  "errResponseMessage": "", "transactionReferenceId":
                  "HqXoEXgPc2", "transactionType": "1", "transactionId":
                  "28772703927069122320230703220628", "amount": "1000",
                  "responseApprovalCode": "TAS046", "rrn": "318422501763",
                  "transactionNumber": "2", "batchNumber": "471", "totalAmount":
                  "1000", "customFee": "", "localTax": "", "stateTax": "" }
                  }</span
                >
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="reqResExamples">
      <h3>Examples</h3>

      <!-- Request -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Request</h4>
          <p>application/json</p>
        </div>

        <ul>
          <li>
            <p>
              { "salonId": "622ec883ec2552e0d48ed268", "amount": 2400,
              "cardToken": "123456789" }
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    apiUrl() {
      return process.env.VUE_APP_RASERVA_BACKEND;
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoint {
  .tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px;
    border-radius: 5px;
    color: white;

    &-blue {
      background-color: var(--clr-secondary);
    }
  }

  &_head {
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }

    .route {
      padding: 8px 16px;
      border-radius: 5px;
      background-color: var(--clr-white-2);
      display: flex;
      align-items: center;
      gap: 16px;

      .url {
        color: var(--clr-gray);

        span {
          color: var(--clr-primary);
          font-weight: 700;
        }
      }
    }

    .description {
      margin-top: 32px;
    }
  }

  &_body {
    .request,
    .response,
    .reqResExamples {
      margin-top: 32px;
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &_section {
      &:not(:first-child) {
        margin-top: 32px;
      }

      .head {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 20px;
          margin-bottom: 16px;
          color: var(--clr-secondary);
        }

        p {
          font-size: 14px;
          color: var(--clr-gray);
        }
      }

      ul {
        .subitem {
          margin-left: 32px;
        }

        li {
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          &:not(:first-child) {
            margin-top: 16px;
          }

          .value {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .bold {
            font-weight: 700;
          }
          .required {
            color: orangered;
          }

          .description {
            margin: 4px 0;
            color: var(--clr-gray);
          }

          .examples {
            span {
              background-color: var(--clr-white-2);
              padding: 4px 8px;
              border-radius: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
