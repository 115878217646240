<template>
  <section class="working">
    <div class="working_calendar">
      <h3>Calendar Staff:</h3>
      <ul>
        <li v-for="staff in calendarStaff" :key="staff._id">
          <div class="col">
            <div class="staffInfo">
              <div class="avatar">
                <Avatar
                  mode="large rounded"
                  :name="staff.firstName"
                  :image="staff.profileImage"
                />

                <!-- <div
                  class="available"
                  :class="{
                    'avail-n': !staff.available,
                    'avail-y': staff.available,
                  }"
                ></div> -->
              </div>
              <div class="text">
                <p class="bold">{{ `${staff.firstName} ${staff.lastName}` }}</p>
                <p v-if="staff.clockedInAt" class="small mt-s">
                  Last Clock In:
                  <span class="bold">{{ formatDate(staff.clockedInAt) }}</span>
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="working_nonCalendar">
      <h3>Non-Calendar Staff:</h3>
      <ul>
        <li v-for="staff in nonCalendarStaff" :key="staff._id">
          <div class="col">
            <div class="staffInfo">
              <div class="avatar">
                <Avatar
                  mode="large rounded"
                  :name="staff.firstName"
                  :image="staff.profileImage"
                />

                <!-- <div
                  class="available"
                  :class="{
                    'avail-n': !staff.available,
                    'avail-y': staff.available,
                  }"
                ></div> -->
              </div>
              <div class="text">
                <p class="bold">{{ `${staff.firstName} ${staff.lastName}` }}</p>
                <p v-if="staff.clockedInAt" class="small mt-s">
                  Last Clock In:
                  <span class="bold">{{ formatDate(staff.clockedInAt) }}</span>
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  created() {
    this.newStaff = JSON.parse(JSON.stringify(this.$store.state.staff.staff));
  },
  data() {
    return {
      newStaff: [],
    };
  },
  computed: {
    calendarStaff() {
      return this.newStaff
        .filter((member) => {
          return member.onCalendar && member.working;
        })
        .sort((a, b) => new Date(a.clockedInAt) - new Date(b.clockedInAt));
    },
    nonCalendarStaff() {
      return this.newStaff
        .filter((member) => !member.onCalendar && member.working)
        .sort((a, b) => new Date(a.clockedInAt) - new Date(b.clockedInAt));
    },
  },
  methods: {
    formatDate(date) {
      return this.$moment(date).format('LLL');
    },
  },
};
</script>

<style lang="scss" scoped>
.working {
  padding: 32px;
  display: flex;
  align-items: flex-start;
  gap: 32px;

  &_calendar,
  &_nonCalendar {
    padding: 32px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;

    ul {
      li {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        .staffInfo {
          margin-top: 16px;
          display: flex;
          align-items: center;
          gap: 16px;
        }
        .toggleAvail {
          i {
            font-size: 32px;
            cursor: pointer;
          }

          .fa-toggle-on {
            color: var(--clr-secondary);
          }
          .fa-toggle-off {
            color: var(--clr-light);
          }
        }
      }
    }

    .small {
      font-size: 13px;
    }
    .bold {
      font-weight: 700;
    }
    .mt-s {
      margin-top: 5px;
    }
    .none {
      margin-top: 16px;
      padding: 8px 16px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      background-color: var(--clr-white);
    }

    .avatar {
      position: relative;

      .available {
        position: absolute;
        bottom: 5px;
        border: 1px solid white;
        right: 0;
        height: 15px;
        width: 15px;
        border-radius: 50%;
      }
      .working {
        position: absolute;
        bottom: -4px;
        border: 1px solid white;
        left: 0;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        background-color: var(--clr-secondary);
        font-size: 12px;
        color: white;
        display: grid;
        place-items: center;
      }
      .avail-y {
        background-color: var(--clr-success);
      }
      .avail-n {
        background-color: var(--clr-danger);
      }
    }
  }
}
</style>
