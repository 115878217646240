<template>
  <ul class="salons">
    <li @click="navToSalon(salon._id)" v-for="salon in salons" :key="salon._id">
      <Avatar
        :image="salon.profileImage"
        mode="rounded large"
        :name="salon.details.shopName"
        showName
      />
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    salons: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    navToSalon(salonId) {
      this.$router.push({
        name: 'SalonPage',
        params: {
          id: salonId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.salons {
  display: flex;
  gap: 64px;
  flex-wrap: wrap;
}
</style>
