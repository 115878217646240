<template>
  <section class="image">
    <div
      v-if="imageUrl || fileSource"
      class="image_image"
      @click="openSelect"
      :style="{
        height: size,
        width: size,
        border: sourceIsPlaceholder ? '3px solid var(--clr-gray)' : 'none',
      }"
    >
      <!-- Default -->
      <div
        v-if="!sourceIsPlaceholder"
        class="justSource"
        :style="{ height: size, width: size }"
      >
        <img :src="imageUrl || fileSource" />
      </div>
      <!-- Placeholder -->
      <div
        v-else-if="!imageUrl && sourceIsPlaceholder"
        class="sourceIsPlaceholder"
        :style="{ height: size, width: size }"
      >
        <img :style="{ opacity: '25%' }" :src="imageUrl || fileSource" />
        <i class="fas fa-file-upload"></i>
      </div>
      <!-- Placeholder But Uploaded Image  -->
      <div
        v-else-if="imageUrl && sourceIsPlaceholder"
        :style="{ height: size, width: size }"
      >
        <img :src="imageUrl || fileSource" />
      </div>
    </div>

    <div
      v-else
      class="image_none"
      @click="openSelect"
      :style="{ height: size, width: size }"
    >
      <i class="fas fa-file-upload"></i>
    </div>

    <p @click="openSelect" class="max">Click to upload | Max file size 2MB</p>

    <div class="image_fileSelector">
      <input
        type="file"
        @change="select"
        accept="image/png, image/jpeg, image/jpg"
        ref="selector"
      />
    </div>
  </section>
</template>

<script>
export default {
  emits: ['select'],
  props: {
    fileSource: {
      type: String,
    },
    size: {
      type: String,
      default: '150px',
    },
    sourceIsPlaceholder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newFile: '',
      imageUrl: '',
    };
  },
  methods: {
    openSelect() {
      this.$refs.selector.click();
    },
    async select(event) {
      if (event.target.files[0].size > 2097152) {
        this.$toast.warning('File size too large');
        return;
      }

      this.newFile = event.target.files[0];
      this.imageUrl = URL.createObjectURL(this.newFile);

      const fd = new FormData();

      fd.append('image', this.newFile, this.newFile.name);

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/upload/image`,
          fd
        );

        this.$emit('select', response.data.url);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  display: grid;
  place-items: center;

  .sourceIsPlaceholder {
    position: relative;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 32px;
      color: var(--clr-gray);
    }
  }

  &_image {
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      box-shadow: var(--shadow);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &_none {
    border-radius: 50%;
    background-color: var(--clr-link-muted);
    display: grid;
    place-items: center;
    cursor: pointer;

    i {
      font-size: 32px;
      color: var(--clr-white);
    }
  }
  .max {
    cursor: pointer;
    font-size: 12px;
    color: var(--clr-gray);
    margin-top: 8px;
  }

  &_fileSelector {
    opacity: 0;
    height: 1px;

    input {
      width: 1px;
      height: 1px;
    }
  }
}
</style>
