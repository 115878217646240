<template>
  <section class="auth">
    <div class="auth_bookers section">
      <h2 class="title">Login for bookers</h2>
      <ClientLoginForm @registered="login" />
    </div>
    <div class="auth_professional section">
      <h2 class="title">Login for professionals</h2>
      <p>
        To access your partner account please navigate to the
        <router-link :to="{ name: 'StaffLogin' }"
          ><span class="link">professionals login page</span></router-link
        >
      </p>
    </div>
  </section>
</template>

<script>
import ClientLoginForm from '@/components/clients/ClientLoginForm.vue';

export default {
  components: {
    ClientLoginForm,
  },
  created() {
    if (this.$store.state.booking.activeClient) {
      if (this.$route.query.source) {
        this.$router.push(this.$route.query.source);
      } else {
        this.$router.push({ name: 'ClientProfile' });
      }
    }

    setTimeout(() => {
      if (this.$store.state.booking.activeClient) {
        if (this.$route.query.source) {
          this.$router.push(this.$route.query.source);
        } else {
          this.$router.push({ name: 'ClientProfile' });
        }
      }
    }, 500);
  },
  methods: {
    login() {
      if (this.$route.query.source) {
        this.$router.push(this.$route.query.source);
      } else {
        this.$router.push({
          name: 'ClientProfile',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.auth {
  margin: auto;
  align-items: center;
  gap: 32px;
  margin-top: 64px;
  width: 100%;
  max-width: 600px;

  .section {
    padding: 32px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;
    width: 100%;

    &:not(:first-child) {
      margin-top: 32px;
    }
  }

  &_bookers {
    .title {
      margin-bottom: 32px;
    }
  }

  &_professional {
    text-align: center;

    p {
      margin-top: 16px;
    }

    .link {
      cursor: pointer;
      color: var(--clr-link);
      display: block;
      transition: text-decoration 0.2s;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
