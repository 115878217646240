<template>
  <div class="actions" :class="{ mobile }">
    <!-- Guided Checkout -->
    <BaseButton
      v-if="!hideGuidedCheckout"
      @click="$emit('showGuidedCheckout')"
      mode="secondary-outline"
      >Guided Checkout</BaseButton
    >

    <!-- Add Singular Item -->
    <BaseButton v-if="!hideAddItems" @click="$emit('addItem')"
      >Add Item</BaseButton
    >

    <!-- Add Multiple Items -->
    <BaseButton v-if="!hideAddItems" @click="$emit('addMultipleItems')"
      >Add Multiple Items</BaseButton
    >

    <!-- Start Tip Logic -->
    <BaseButton :disabled="tipLoading" @click="$emit('addTip')">
      <i v-if="tipLoading" class="fas fa-spinner"></i>
      Add Tip</BaseButton
    >

    <!-- Approve Items -->
    <BaseButton
      v-if="
        ticket.items.length &&
        (ticketHasStaffOwnItems || (loggedInStaffIsAdmin && !tempUser))
      "
      mode="secondary"
      :disabled="tipLoading"
      @click="$emit('approveItems')"
    >
      Approve
      {{
        managerMode
          ? 'All'
          : tempUser
          ? 'My'
          : loggedInStaffIsAdmin
          ? 'All'
          : ''
      }}
      Items</BaseButton
    >

    <!-- Cash Discounts -->
    <div v-if="usesCashDiscount && total" class="cashDiscount">
      <!-- Add Cash Discount -->
      <BaseButton
        v-if="!ticketHasCashDiscount"
        mode="primary-outline"
        @click="$emit('addCashDiscountToItems')"
        >Add Cash Discount</BaseButton
      >

      <!-- Remove Cash Discount -->
      <BaseButton
        v-else
        mode="danger-outline"
        @click="$emit('removeCashDiscountToItems')"
        >Remove Cash Discount</BaseButton
      >
    </div>

    <!-- Delete Ticket -->
    <BaseButton
      mode="danger-outline"
      v-if="ticket._id && notCompletedOrRefunded"
      @click="$emit('deleteTicket')"
      >Delete Ticket</BaseButton
    >
  </div>
</template>

<script>
export default {
  emits: [
    'showGuidedCheckout',
    'addItem',
    'addMultipleItems',
    'addTip',
    'approveItems',
    'addCashDiscountToItems',
    'removeCashDiscountToItems',
    'deleteTicket',
  ],
  props: {
    mobile: {
      type: Boolean,
    },
    ticket: {
      type: Object,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    tipLoading: {
      type: Boolean,
    },
    hideGuidedCheckout: {
      type: Boolean,
    },
    hideAddItems: {
      type: Boolean,
    },
  },
  computed: {
    managerMode() {
      return this.$store.state.auth.managerMode;
    },
    tempUser() {
      return this.$store.state.auth.tempUser;
    },
    loggedInStaffIsAdmin() {
      return this.$store.getters['auth/loggedInStaffIsAdmin'];
    },
    ticketHasCashDiscount() {
      return this.ticket.items.some((item) => item.item.cashDiscount);
    },
    usesCashDiscount() {
      return this.$store.state.auth.salon.payments.useCashDiscount;
    },
    notCompletedOrRefunded() {
      return (
        this.ticket.status !== 'completed' && this.ticket.status !== 'refunded'
      );
    },
    ticketHasStaffOwnItems() {
      if (!this.tempUser) {
        const self = this.$store.state.auth.loggedInSalonStaff;

        if (
          this.ticket.items.some((item) => {
            if (item.staff) {
              return item.staff.staffId === self.staffId;
            }
          })
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          this.ticket.items.some((item) => {
            if (item.staff) {
              return item.staff.staffId === this.tempUser.staffId;
            }
          })
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  gap: 16px;
}
.mobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}
</style>
