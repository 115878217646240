<template>
  <teleport v-if="!hideBackdrop" to="body">
    <Backdrop @click="$emit('hide')" />
  </teleport>

  <div class="datepicker" :style="{ width: propWidth }">
    <div class="col">
      <div class="datepicker_head">
        <div class="holder" @click="previousMonth">
          <i class="fas fa-angle-left"></i>
        </div>
        <div class="datepicker_head_text">
          <p>{{ selectedMonth }}</p>
        </div>
        <div class="holder" @click="nextMonth">
          <i class="fas fa-angle-right"></i>
        </div>
      </div>
      <div class="datepicker_body">
        <div class="datepicker_body_days">
          <div class="holder holder-day">
            <p>Su</p>
          </div>
          <div class="holder holder-day">
            <p>Mo</p>
          </div>
          <div class="holder holder-day">
            <p>Tu</p>
          </div>
          <div class="holder holder-day">
            <p>We</p>
          </div>
          <div class="holder holder-day">
            <p>Th</p>
          </div>
          <div class="holder holder-day">
            <p>Fr</p>
          </div>
          <div class="holder holder-day">
            <p>Sa</p>
          </div>
        </div>
        <div class="datepicker_body_dates">
          <div
            class="holder"
            v-for="date in dates"
            :key="date"
            :class="{
              selected: isInSelectedDateRange(date.date),
              light: !date.isSameMonth,
              disabled:
                (upcomingOnly && !isUpcoming(date.date)) ||
                isDateGreaterThanMax(date.date) ||
                isDateInDisabledDates(date.date),
            }"
            @click="
              checkIfDateIsInDifferentMonth(date.date);
              isDateInDisabledDates(date.date)
                ? noTimeslotsOnDate(date.date)
                : selectDate(date.date);
            "
          >
            <p>{{ date.dateOfMonth }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showWeeksOut" class="col">
      <ul class="weeks">
        <li @click="selectWeeksOut(i)" v-for="i in 6" :key="i">
          In {{ i }} weeks
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['selectDate', 'hide'],
  props: {
    selectedDate: {
      type: Date,
      default: new Date(Date.now()),
    },
    selectedTimeOption: {
      type: String,
      default: 'Day',
    },
    hideBackdrop: {
      type: Boolean,
      default: false,
    },
    upcomingOnly: {
      type: Boolean,
      default: false,
    },
    showWeeksOut: {
      type: Boolean,
      default: false,
    },
    propWidth: {
      type: String,
      default: '500px',
    },
    maxSelectableDateInNumberOfDays: {
      type: Number,
      default: null,
    },
    disabledDates: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selectedMonth() {
      return this.$moment(this.visibleDate).format('MMMM YYYY');
    },
  },
  data() {
    return {
      dates: [],
      visibleDate: {
        type: Date,
      },
      zIndex: null,
    };
  },
  created() {
    this.visibleDate = this.selectedDate;
    this.setDates();
    this.zIndex = +this.$store.getters['styles/modalZIndex'];
  },
  watch: {
    selectedDate() {
      this.setDates();
    },
  },
  methods: {
    setDates() {
      this.dates.length = 0;

      const monthStartDate = this.$moment(this.visibleDate).startOf('month');
      const monthStartIndex = monthStartDate.day();

      let monthEndDate = this.$moment(this.visibleDate).endOf('month');
      const monthEndIndex = monthEndDate.day();
      const monthEndDateIndex = monthEndDate.date();

      for (let i = monthStartIndex; i > 0; i--) {
        const date = this.$moment(monthStartDate).subtract(i, 'days');

        this.dates.push({
          date,
          dateOfMonth: date.date(),
          isSameMonth: false,
        });
      }

      for (let i = 0; i < monthEndDateIndex; i++) {
        const date = this.$moment(monthStartDate).add(i, 'days');

        this.dates.push({
          date,
          dateOfMonth: date.date(),
          isSameMonth: true,
        });
      }

      for (let i = 1; i < 7 - monthEndIndex; i++) {
        const date = this.$moment(monthEndDate).add(i, 'days');

        this.dates.push({
          date,
          dateOfMonth: date.date(),
          isSameMonth: false,
        });
      }
    },

    checkIfDateIsInDifferentMonth(date) {
      if (!date.isSame(this.selectedDate, 'month')) {
        // Different month
        if (date.isAfter(this.selectedDate)) {
          this.nextMonth();
        } else {
          this.previousMonth();
        }
      }
    },

    nextMonth() {
      // this.$emit('selectDate', this.$moment(this.selectedDate).add(1, 'month'));
      this.visibleDate = this.$moment(this.visibleDate).add(1, 'month');
      this.setDates();
    },
    previousMonth() {
      if (
        this.upcomingOnly &&
        this.$moment(this.visibleDate).isSame(this.$moment(), 'month')
      )
        return;
      // this.$emit('selectDate', this.$moment(this.selectedDate).subtract(1, 'month'));
      this.visibleDate = this.$moment(this.visibleDate).subtract(1, 'month');
      this.setDates();
    },

    selectWeeksOut(weeks) {
      this.$emit(
        'selectDate',
        this.$moment(this.selectedDate).add(weeks, 'weeks')
      );
    },

    isInSelectedDateRange(date) {
      if (this.selectedTimeOption === 'Day') {
        if (this.$moment(this.selectedDate).isSame(date, 'date')) {
          return true;
        }

        return false;
      }

      const rangeStart = this.$moment(this.selectedDate).startOf('week');
      const rangeEnd = this.$moment(this.selectedDate).endOf('week');

      if (this.$moment(date).isBetween(rangeStart, rangeEnd, 'date', '[]')) {
        return true;
      }

      return false;
    },

    isDateGreaterThanMax(date) {
      if (!this.maxSelectableDateInNumberOfDays) return false;

      if (
        this.$moment(date).isAfter(
          this.$moment().add(this.maxSelectableDateInNumberOfDays, 'days'),
          'date'
        )
      ) {
        return true;
      }

      return false;
    },

    isUpcoming(date) {
      if (this.$moment(date).isSameOrAfter(this.$moment(), 'date')) {
        return true;
      }

      return false;
    },

    isDateInDisabledDates(date) {
      if (this.disabledDates.length === 0) return false;

      for (const disabledDate of this.disabledDates) {
        if (this.$moment(date).isSame(disabledDate, 'date')) {
          return true;
        }
      }

      return false;
    },

    noTimeslotsOnDate(date) {
      this.$toast.warning(
        `No timeslots available on ${this.$moment(date).format(
          'dddd, MMMM Do'
        )}`
      );
    },

    selectDate(date) {
      this.$emit('selectDate', date);
    },
  },
};
</script>

<style lang="scss" scoped>
.datepicker {
  // width: 500px;
  max-width: calc(100vw - 30px);
  padding: 16px;
  border: 1px solid var(--clr-light);
  box-shadow: var(--shadow);
  background-color: white;
  display: flex;

  .col {
    flex-grow: 1;
  }

  .selected {
    background-color: var(--clr-primary) !important;
    color: var(--clr-white) !important;
  }

  .light {
    color: var(--clr-light);

    &:hover {
      background-color: var(--clr-white) !important;
    }
  }

  .disabled {
    color: var(--clr-gray);
  }
  .holder {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: grid;
    place-items: center;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      background-color: var(--clr-light);
    }

    &-day {
      cursor: default;

      &:hover {
        background-color: inherit;
      }
    }
  }

  &_head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-weight: 700;
    }
  }

  &_body {
    margin-top: 16px;

    &_days,
    &_dates {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      justify-items: center;
      gap: 5px;
    }

    &_days {
      p {
        font-weight: 700;
      }
    }

    &_dates {
      margin-top: 5px;
    }
  }
}

.weeks {
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  li {
    padding: 8px 16px;
    text-align: center;
    border: 1px solid var(--clr-light);
    cursor: pointer;

    &:hover {
      background-color: var(--clr-white-2);
    }
  }
}
</style>
