<template>
  <!-- <div v-if="showAnnouncement" class="announcement">
    <p>
      <span>New!</span> Quick and easy support with our support ticket section.
      Click the <i class="fas fa-question"></i> on your navigation bar to get
      started.
    </p>

    <i class="fas fa-times" @click="showAnnouncement = false"></i>
  </div> -->

  <header class="header">
    <div class="header_logo-salon">
      <router-link
        :to="activeSalon ? { name: 'Calendar' } : { name: 'SalonSelect' }"
      >
        <div class="header_logo">
          <TheLogo />
        </div>
      </router-link>

      <div v-if="salon" class="header_logo-salon-activeSalon">
        <p class="shopName">{{ salon.details.shopName }}</p>
        <p class="altName">{{ salon.details.altName }}</p>
      </div>
    </div>
    <div class="header_actions">
      <div class="salon_actions">
        <div
          v-if="tempUser"
          class="header_actions_action tempUserName"
          @click="showTempUserTooltip = true"
        >
          <p class="light small">Temp User</p>
          <p class="theName">
            {{ `${tempUser.firstName} ${tempUser.lastName}` }}
          </p>
        </div>

        <!-- Temp User -->
        <div
          id="tempUserAction"
          v-if="activeSalon && (canUseTempUserMode || tempUser)"
          class="header_actions_action icon"
          @click="!tempUser ? (showTempUserModal = true) : turnOffTempUser()"
        >
          <i v-if="tempUser" class="fas fa-user green"></i>
          <i v-else class="far fa-user"></i>
        </div>
        <!-- Manager Mode -->
        <div
          id="managerModeAction"
          v-if="activeSalon"
          @click="!managerMode ? (showAdminModal = true) : turnOffManagerMode()"
          class="header_actions_action icon"
        >
          <i v-if="!managerMode" class="fas fa-lock"></i>
          <i v-else class="fas fa-unlock green"></i>
        </div>
        <!-- End Day -->
        <div
          id="endDayAction"
          v-show="activeSalon && canEndDay"
          @click="confirmEndDay = true"
          class="header_actions_action icon"
        >
          <i class="fas fa-moon"></i>
        </div>
        <!-- Kiosk -->
        <div
          id="kioskAction"
          v-if="activeSalon"
          class="header_actions_action icon"
          @click="showConfirmKiosk = true"
        >
          <i class="fas fa-tablet"></i>
        </div>
        <!-- Clock In -->
        <div
          id="clockInAction"
          v-if="activeSalon && (canClockOthersIn || canClockSelfIn)"
          class="header_actions_action icon"
          @click="
            allDashboardTicketsAreFromOtherDaysThanToday
              ? (askIfShouldEndDay = true)
              : canClockOthersIn
              ? (confirmToggleOtherWorking = true)
              : (confirmToggleWorking = true)
          "
        >
          <i class="fas fa-user-clock"></i>
        </div>
        <!-- Cancellation Requests -->
        <div
          id="cancelRequestsAction"
          v-if="activeSalon"
          class="header_actions_action icon notifications"
          @click="showCancellationRequests = true"
        >
          <i class="fas fa-comment-slash color-red"></i>
          <div
            v-if="
              salon &&
              salon.cancellationRequests &&
              salon.cancellationRequests.length
            "
            class="number animationScale"
          >
            <p>{{ salon.cancellationRequests.length }}</p>
          </div>
        </div>
        <!-- Salon Notifications -->
        <div
          id="salonNotificationsAction"
          v-if="activeSalon && canViewSalonNotifications"
          class="header_actions_action icon notifications"
          @click="showSalonNotifications = true"
        >
          <i class="fas fa-globe"></i>
          <div
            v-if="salon && salon.notifications && salon.notifications.length"
            class="number animationScale"
          >
            <p>{{ salon.notifications.length }}</p>
          </div>
        </div>
        <!-- Notifications -->
        <div
          id="notificationsAction"
          class="header_actions_action icon notifications"
          @click="showNotifications = true"
        >
          <i class="fas fa-bell"></i>
          <div
            v-if="user && user.notifications && user.notifications.length"
            class="number animationScale"
          >
            <p>{{ user.notifications.length }}</p>
          </div>
        </div>
      </div>
      <div class="header_actions_action user">
        <Avatar
          :name="userFullName"
          :image="user ? user.profileImage : null"
          @click="showUserDropdown = true"
        />

        <Tooltip
          mode="bottom right"
          width="250px"
          v-if="showUserDropdown"
          @hide="showUserDropdown = false"
        >
          <ul>
            <router-link :to="{ name: 'StaffProfile' }">
              <li class="welcome">
                <p class="subtext">Welcome,</p>
                <p class="userName">{{ userFullName }}</p>
              </li>
            </router-link>
            <router-link :to="{ name: 'Docs' }">
              <li>
                <p>Guides</p>
              </li>
            </router-link>
            <li @click="switchSalons">
              <p>Switch Salons</p>
            </li>
            <li
              v-if="
                salon && canEditOwnCapableServices && !managerMode && !tempUser
              "
              @click="showCapableServices = true"
            >
              <p>My Services</p>
            </li>
            <li @click="logout">
              <p>Log out</p>
            </li>
          </ul>
        </Tooltip>
      </div>
    </div>
    <div
      class="header_mobile icon mobile"
      @click="showMobileMenu = !showMobileMenu"
    >
      <i class="fas fa-bars"></i>
    </div>
  </header>

  <SidePanel :show="showMobileMenu" @close="showMobileMenu = false">
    <ul class="mobileMenu">
      <router-link :to="{ name: 'StaffProfile' }">
        <li class="sidebar_nav_items_item" @click="showMobileMenu = false">
          <i class="fas fa-user"></i>
          <p>Profile</p>
        </li>
      </router-link>
      <li
        class="sidebar_nav_items_item"
        @click="
          showMobileMenu = false;
          switchSalons();
        "
      >
        <i class="fas fa-building"></i>
        <p>Switch Salons</p>
      </li>
      <router-link v-if="canViewSettings" :to="{ name: 'Settings' }">
        <li class="sidebar_nav_items_item" @click="showMobileMenu = false">
          <i class="fas fa-cog"></i>
          <p>Settings</p>
        </li>
      </router-link>
      <router-link v-if="canViewHome" :to="{ name: 'Dashboard' }">
        <li class="sidebar_nav_items_item" @click="showMobileMenu = false">
          <i class="fas fa-igloo"></i>
          <p>Home</p>
        </li>
      </router-link>
      <router-link :to="{ name: 'Calendar' }">
        <li class="sidebar_nav_items_item" @click="showMobileMenu = false">
          <i class="fas fa-calendar"></i>
          <p>Calendar</p>
        </li>
      </router-link>
      <router-link v-if="canViewSales" :to="{ name: 'Sales' }">
        <li class="sidebar_nav_items_item" @click="showMobileMenu = false">
          <i class="fas fa-tag"></i>
          <p>Sales</p>
        </li>
      </router-link>
      <router-link v-if="canViewClients" :to="{ name: 'Clients' }">
        <li class="sidebar_nav_items_item" @click="showMobileMenu = false">
          <i class="fas fa-smile"></i>
          <p>Clients</p>
        </li>
      </router-link>
      <router-link :to="{ name: 'Team' }">
        <li class="sidebar_nav_items_item" @click="showMobileMenu = false">
          <i class="fas fa-users"></i>
          <p>Team</p>
        </li>
      </router-link>
      <router-link v-if="canViewGiftcards" :to="{ name: 'Giftcards' }">
        <li class="sidebar_nav_items_item" @click="showMobileMenu = false">
          <i class="fas fa-gift"></i>
          <p>Gift Cards</p>
        </li>
      </router-link>
      <router-link v-if="canViewServicesOrProducts" :to="{ name: 'Items' }">
        <li class="sidebar_nav_items_item" @click="showMobileMenu = false">
          <i class="fas fa-hand-sparkles"></i>
          <p>Items</p>
        </li>
      </router-link>
      <router-link v-if="canViewMessenger" :to="{ name: 'Messenger' }">
        <li class="sidebar_nav_items_item" @click="showMobileMenu = false">
          <i class="fas fa-comment-alt"></i>
          <p>Messenger</p>
        </li>
      </router-link>
      <router-link :to="{ name: 'Kiosk' }">
        <li
          v-if="activeSalon"
          class="header_actions_action icon"
          @click="showConfirmKiosk = true"
        >
          <i class="fas fa-tablet"></i>
          <p>Kiosk</p>
        </li>
      </router-link>
      <li
        class="sidebar_nav_items_item"
        @click="
          showMobileMenu = false;
          showNotifications = true;
        "
      >
        <i class="fas fa-bell"></i>
        <p>Notifications</p>
      </li>
      <li
        class="sidebar_nav_items_item"
        @click="
          confirmToggleWorking = true;
          showMobileMenu = false;
        "
      >
        <i class="fas fa-user-clock"></i>
        <p>Clock In/Out</p>
      </li>
      <li
        class="sidebar_nav_items_item"
        v-if="salon && canEditOwnCapableServices && !managerMode && !tempUser"
        @click="showCapableServices = true"
      >
        <i class="fas fa-hand-sparkles"></i>
        <p>My Services</p>
      </li>
      <li class="red" @click="logout">
        <i class="fas fa-sign-out-alt"></i>
        <p>Log out</p>
      </li>
    </ul>
  </SidePanel>

  <SidePanel :show="showNotifications" @close="showNotifications = false">
    <div class="notificationsContainer">
      <Notifications :propNotifications="notifications" />
    </div>
  </SidePanel>

  <SidePanel
    :show="showSalonNotifications"
    @close="showSalonNotifications = false"
  >
    <div class="notificationsContainer">
      <Notifications
        salonNotifications
        :propNotifications="salonNotifications"
      />
    </div>
  </SidePanel>

  <SidePanel
    :show="showCancellationRequests"
    @close="showCancellationRequests = false"
  >
    <div class="notificationsContainer">
      <Notifications
        cancellationRequests
        :propNotifications="cancellationRequests"
      />
    </div>
  </SidePanel>

  <Confirm
    v-if="confirmToggleWorking"
    :title="confirmTitle"
    :text="confirmText"
    @confirm="toggleWorking"
    @deny="confirmToggleWorking = false"
  ></Confirm>

  <Confirm
    v-if="confirmClockOutAll"
    :title="confirmClockOutAllTitle"
    :text="confirmClockOutText"
    @confirm="clockOutAll"
    @deny="confirmClockOutAll = false"
  ></Confirm>

  <Modal
    title="Toggle Manager Mode"
    :show="showAdminModal"
    @close="showAdminModal = false"
  >
    <div class="clockOtherIn">
      <form @submit.prevent="turnOnManagerMode" class="form">
        <BaseInput
          label="Manager Code"
          inputType="password"
          placeholder="0001"
          @input="adminAccountCode = $event"
        />
        <div class="form_actions">
          <BaseButton>Turn On Manager Mode</BaseButton>
        </div>
      </form>
    </div>
  </Modal>

  <Modal
    title="Temporary User Mode"
    :show="showTempUserModal"
    @close="showTempUserModal = false"
  >
    <div class="clockOtherIn">
      <form @submit.prevent="turnOnTempUser" class="form">
        <BaseInput
          label="Account Code"
          inputType="password"
          placeholder="0001"
          @input="tempUserCode = $event"
        />
        <div class="form_actions">
          <BaseButton>Submit</BaseButton>
        </div>
      </form>
    </div>
  </Modal>

  <Modal
    title="Select services"
    maxWidth="600px"
    zIndex="999999"
    :show="showCapableServices"
    @close="showCapableServices = false"
  >
    <StaffCapableServices
      :staffServices="loggedInStaff.services"
      @submit="submitCapableServices($event)"
    />
  </Modal>

  <Modal
    title="Toggle Staff Working Status"
    :show="confirmToggleOtherWorking"
    @close="confirmToggleOtherWorking = false"
  >
    <div class="clockOtherIn">
      <form @submit.prevent="clockStaffIn" class="form">
        <BaseSelect
          v-if="$store.state.auth.managerMode"
          label="Staff"
          :options="staffOptions"
          :value="selectedStaff"
          @input="selectedStaff = $event"
        />
        <BaseInput
          v-else
          label="Staff Account Code"
          inputType="password"
          placeholder="0001"
          @input="clockStaffInCode = $event"
        />
        <div class="form_actions">
          <BaseButton
            v-if="$store.state.auth.managerMode"
            @click="confirmClockOutAll = true"
            type="button"
            mode="primary-outline"
            >Clock Out All</BaseButton
          >
          <BaseButton :disabled="loading"
            ><i v-if="loading" class="fas fa-spinner"></i> Toggle</BaseButton
          >
        </div>
      </form>
    </div>
  </Modal>

  <Modal
    title="End Day"
    :show="askIfShouldEndDay"
    @close="askIfShouldEndDay = false"
  >
    <div class="askToEndDay">
      <h2>WARNING!</h2>
      <p>
        All current dashboard tickets are from a day other than today, this may
        mean the previous day was not ended. By pressing the RED button below
        you will end the day and can proceed to clock in/out.
      </p>

      <div class="alert alert-danger">
        <p>
          Select the red button below to end the previous day, if you do not
          wish to end the previous day select the neutral button.
        </p>
      </div>

      <div class="actions">
        <BaseButton
          @click="
            askIfShouldEndDay = false;
            canClockOthersIn
              ? (confirmToggleOtherWorking = true)
              : (confirmToggleWorking = true);
          "
          mode="primary-outline"
          >Continue without ending day</BaseButton
        >
        <BaseButton @click="endDayAndClockIn" mode="danger">End day</BaseButton>
      </div>
    </div>
  </Modal>

  <Confirm
    v-if="showConfirmKiosk"
    title="Navigate to kiosk"
    text="Are you sure you wish to navigate to kiosk?"
    @confirm="navigateToKiosk"
    @deny="showConfirmKiosk = false"
  />

  <Confirm
    v-if="confirmEndDay"
    title="End day"
    text="Are you sure you wish to end the day? This will clock out all staff and clear all daily tickets."
    @confirm="endDay"
    @deny="confirmEndDay = false"
  />

  <div @click="showMobileMenu = !showMobileMenu" class="mobilePopupMenu">
    <i class="fas fa-bars"></i>
  </div>
</template>

<script>
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/perspective.css';

import Notifications from '@/components/dashboard/Notifications.vue';
import StaffCapableServices from '@/components/staff/StaffCapableServices.vue';

export default {
  components: {
    Notifications,
    StaffCapableServices,
  },
  created() {
    if (!this.user) return;
    this.init();
    this.socketInit();
  },
  mounted() {
    // timeout 1 second
    setTimeout(() => {
      this.actions.forEach((action) => {
        tippy(`#${action.id}`, {
          content: action.content,
          arrow: true,
          animation: 'perspective',
        });
      });
    }, 1000);
  },
  watch: {
    socket() {
      this.socketInit();
    },
    loggedInStaff() {
      this.init();
    },
    managerMode(val) {
      this.init();

      if (!val) {
        const name =
          this.$store.state.auth.salon.adminSettings.defaultMainScreen ===
          'calendar'
            ? 'Calendar'
            : 'ClassicView';

        setTimeout(() => {
          this.$router.push({
            name,
          });
        }, 0);
      }
    },
    tempUser(val) {
      this.init();

      if (!val) {
        const name =
          this.$store.state.auth.salon.adminSettings.defaultMainScreen ===
          'calendar'
            ? 'Calendar'
            : 'ClassicView';

        setTimeout(() => {
          this.$router.push({
            name,
          });
        }, 0);
      }
    },
  },
  data() {
    return {
      showAnnouncement: true,

      confirmToggleWorking: false,
      confirmToggleOtherWorking: false,
      confirmClockOutAll: false,
      confirmEndDay: false,

      askIfShouldEndDay: false,

      clockStaffInCode: '',
      adminAccountCode: '',
      tempUserCode: '',

      showUserDropdown: false,
      showMobileMenu: false,
      showNotifications: false,
      showSalonNotifications: false,
      showCancellationRequests: false,
      canSearchClients: false,

      showConfirmKiosk: false,
      showAdminModal: false,
      showTempUserModal: false,

      canViewHome: false,
      canViewClients: false,
      canViewSales: false,
      canViewServicesOrProducts: false,
      canViewSettings: false,
      canViewMessenger: false,
      canViewGiftcards: false,
      canClockSelfIn: false,
      canClockOthersIn: false,
      canUseTempUserMode: false,
      canViewSalonNotifications: false,
      canEditOwnCapableServices: false,
      canEndDay: false,

      showCapableServices: false,

      selectedStaff: null,
      loading: false,
    };
  },
  computed: {
    actions() {
      return [
        {
          id: 'tempUserAction',
          content: 'Temp User',
        },
        {
          id: 'managerModeAction',
          content: 'Manager Mode',
        },
        {
          id: 'endDayAction',
          content: 'End day',
        },
        {
          id: 'kioskAction',
          content: 'Kiosk',
        },
        {
          id: 'clockInAction',
          content: 'Clock In/Out',
        },
        {
          id: 'cancelRequestsAction',
          content: 'Cancel Requests',
        },
        {
          id: 'salonNotificationsAction',
          content: 'Salon Notifications',
        },
        {
          id: 'notificationsAction',
          content: 'Notifications',
        },
      ];
    },
    notifications() {
      if (!this.$store.state.auth.user) return;

      return this.$store.state.auth.user.notifications;
    },
    salonNotifications() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.notifications;
    },
    cancellationRequests() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.cancellationRequests;
    },
    tempUser() {
      return this.$store.state.auth.tempUser;
    },
    managerMode() {
      return this.$store.state.auth.managerMode;
    },
    isWorking() {
      return this.$store.state.auth.loggedInSalonStaff.working;
    },
    socket() {
      return this.$store.state.sockets.socket;
    },
    confirmTitle() {
      if (this.isWorking) {
        return 'Clock Out';
      } else {
        return 'Clock In';
      }
    },
    confirmText() {
      if (this.isWorking) {
        return 'Are you sure you wish to clock out?';
      } else {
        return 'Are you sure you wish to clock in?';
      }
    },
    confirmClockOutAllTitle() {
      return 'Clock Out All Working Staff';
    },
    confirmClockOutText() {
      return 'Are you sure you wish to clock out all working staff? This action can not be undone.';
    },
    activeSalon() {
      return this.$store.state.auth.salon && this.$store.state.auth.subActive;
    },
    salon() {
      return this.$store.state.auth.salon;
    },
    user() {
      return this.$store.state.auth.user;
    },
    loggedInStaff() {
      return this.$store.state.auth.loggedInSalonStaff;
    },
    userFullName() {
      return this.$store.getters['auth/userFullName'];
    },
    staffOptions() {
      const options = [];

      this.$store.state.staff.staff.forEach((staff) =>
        options.push({
          option: `${staff.firstName} ${staff.lastName}`,
          value: staff._id,
        })
      );

      return options;
    },
    allDashboardTicketsAreFromOtherDaysThanToday() {
      if (!this.$store.state.tickets.tickets.length) return false;

      return this.$store.state.tickets.tickets.every((ticket) => {
        return !this.$moment(ticket.created_at).isSame(this.$moment(), 'day');
      });
    },
  },
  methods: {
    async socketInit() {
      if (!this.$store.state.sockets.socket) return;

      this.$store.state.sockets.socket.on(
        'newCancellationRequest',
        async () => {
          try {
            const response = await this.$axios.get(
              `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/appointments/cancelRequest`,
              {
                headers: {
                  Authorization: `Bearer ${this.$store.state.auth.token}`,
                },
              }
            );

            this.$store.state.auth.salon.cancellationRequests =
              response.data.requests;
          } catch (error) {
            this.$toast.error(error.message);
          }
        }
      );
    },
    async init() {
      try {
        this.canSearchClients = await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'bookings/viewClients'
        );

        this.canViewHome = await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'bookings/viewHome'
        );

        this.canViewClients = await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'bookings/viewClients'
        );

        this.canViewGiftcards = await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'other/viewGiftcards'
        );

        this.canClockSelfIn = await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'staff/canClockSelfIn'
        );

        this.canClockOthersIn = this.managerMode
          ? true
          : await this.$store.dispatch(
              'auth/activeUserHasPermission',
              'staff/canClockOthersIn'
            );

        this.canUseTempUserMode = this.managerMode
          ? true
          : await this.$store.dispatch(
              'auth/activeUserHasPermission',
              'staff/canUseTempUser'
            );

        this.canViewSalonNotifications = this.managerMode
          ? true
          : await this.$store.dispatch(
              'auth/activeUserHasPermission',
              'staff/canViewSalonNotifications'
            );

        this.canEditOwnCapableServices = this.managerMode
          ? true
          : await this.$store.dispatch(
              'auth/activeUserHasPermission',
              'staff/canEditOwnCapableServices'
            );

        this.canViewMessenger = this.managerMode
          ? true
          : await this.$store.dispatch(
              'auth/activeUserHasPermission',
              'staff/canViewMessenger'
            );

        this.canEndDay = this.managerMode
          ? true
          : await this.$store.dispatch(
              'auth/activeUserHasPermission',
              'other/endDay'
            );

        this.canViewSales =
          (await this.$store.dispatch(
            'auth/activeUserHasPermission',
            'tickets/viewOwnReports'
          )) ||
          (await this.$store.dispatch(
            'auth/activeUserHasPermission',
            'tickets/viewOthersReports'
          )) ||
          (await this.$store.dispatch(
            'auth/activeUserHasPermission',
            'tickets/viewSummary'
          ));

        this.canViewServicesOrProducts =
          (await this.$store.dispatch(
            'auth/activeUserHasPermission',
            'other/viewServices'
          )) ||
          (await this.$store.dispatch(
            'auth/activeUserHasPermission',
            'other/viewProducts'
          ));

        if (this.$store.state.auth.salon) {
          this.canViewSettings =
            this.$store.state.auth.salon.adminId ===
            this.$store.getters['auth/userId'];
        }
      } catch (e) {
        console.log(e.message);
      }
    },
    async toggleWorking() {
      try {
        const response = this.$store.dispatch('auth/toggleWorking');
        this.confirmToggleWorking = false;

        this.$toast.success(response);
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    switchSalons() {
      this.$store.dispatch('auth/nullSalon');
      this.$router.push({
        name: 'SalonSelect',
      });
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.push({
        name: 'Index',
      });
    },
    async endDay() {
      await this.clockOutAll();
      await this.$store.dispatch('tickets/clearTickets');

      this.$toast.success('Day ended');
      this.confirmEndDay = false;
    },

    async endDayAndClockIn() {
      await this.endDay();

      this.askIfShouldEndDay = false;

      this.canClockOthersIn
        ? (this.confirmToggleOtherWorking = true)
        : (this.confirmToggleWorking = true);
    },

    turnOnTempUser() {
      if (!this.tempUserCode) return;

      const staff = this.$store.state.salon.salon.staff.find(
        (staff) => staff.accountCode === this.tempUserCode
      );

      if (!staff) this.$toast.error('Staff account not found');

      this.$toast.info('Remember to turn off temporary user when finished!');
      this.$store.state.auth.tempUser = staff;
      this.showTempUserModal = false;
    },

    turnOffTempUser() {
      this.$store.state.auth.tempUser = null;
    },

    turnOnManagerMode() {
      if (!this.adminAccountCode) return;

      if (
        this.adminAccountCode !==
        this.$store.state.auth.salon.adminSettings.managerCode
      ) {
        this.$toast.error('Incorrect manager code');
        return;
      }

      this.$toast.info('Remember to turn off manager mode when finished!');
      this.$store.state.auth.managerMode = true;
      this.showAdminModal = false;
    },

    turnOffManagerMode() {
      this.$store.state.auth.managerMode = false;
    },

    async clockOutAll() {
      try {
        await this.$store.dispatch('auth/clockOutAll');

        this.confirmToggleOtherWorking = false;
        this.confirmClockOutAll = false;

        const response = await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/staff/customStaffTurnOrder`,
          {
            setOrder: true,
            customStaffTurnOrder: [],
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$store.state.auth.salon.customStaffTurnOrder =
          response.data.customStaffTurnOrder;

        this.$toast.success('All working staff clocked out');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async submitCapableServices(services) {
      try {
        await this.$store.dispatch('staff/editStaff', {
          ...this.loggedInStaff,
          services,
        });
        this.$toast.success('Capable services updated');
        this.showCapableServices = false;
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    navigateToKiosk() {
      this.$router.push({ name: 'Kiosk' });
    },

    async clockStaffIn() {
      if (!this.$store.state.auth.managerMode) {
        // By clock in code
        if (!this.clockStaffInCode) return;

        this.loading = true;

        try {
          const response = await this.$store.dispatch(
            'auth/toggleWorking',
            this.clockStaffInCode
          );

          this.confirmToggleOtherWorking = false;
          this.$toast.success(response);
        } catch (error) {
          this.$toast.error(error.message);
        }

        this.loading = false;
      } else {
        // By staff select
        const staff = this.$store.state.staff.staff.find(
          (staff) => staff._id === this.selectedStaff
        );

        if (!staff) {
          this.$toast.error('Could not find staff');
          return;
        }

        this.loading = true;

        try {
          const response = await this.$store.dispatch(
            'auth/toggleWorking',
            staff.accountCode
          );

          this.confirmToggleOtherWorking = false;
          this.$toast.success(response);
        } catch (error) {
          this.$toast.error(error.message);
        }

        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.announcement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 48px;
  background-color: var(--clr-secondary);
  color: var(--clr-white);

  span {
    font-weight: 700;
  }

  .fa-times {
    cursor: pointer;
  }
}
.header {
  padding: 8px 24px;
  min-height: 65px;
  border-bottom: 1px solid var(--clr-light);
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_logo-salon {
    display: flex;
    align-items: center;
    gap: 32px;

    &-activeSalon {
      .shopName {
        font-size: 16px;
        font-weight: 700;
      }
      .altName {
        font-size: 12px;
        color: var(--clr-gray);
      }
    }
  }

  .green {
    color: var(--clr-success);
  }

  &_logo {
    h1 {
      text-transform: uppercase;
      letter-spacing: 5px;
    }
  }

  &_actions {
    display: flex;
    align-items: center;
    gap: 16px;

    .tempUserName {
      text-align: right;

      .small {
        font-size: 10px;
      }
      .light {
        color: var(--clr-gray);
      }
      .theName {
        font-size: 12px;
        font-weight: 700;
      }
    }

    .salon_actions {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .icon {
      height: 42px;
      width: 42px;
      display: grid;
      place-items: center;
      border-radius: 50%;
      transition: background-color 0.2s;
      cursor: pointer;
      color: var(--clr-gray);

      &:hover {
        background-color: var(--clr-light);
      }
    }

    .user {
      position: relative;

      ul {
        border: 1px solid var(--clr-light);
        border-radius: 5px;
        background-color: white;

        .welcome {
          text-align: right;
          cursor: pointer;
          border-bottom: 1px solid var(--clr-light);

          .subtext {
            font-size: 12px;
          }
          .userName {
            color: var(--clr-link);
          }
        }

        li {
          padding: 16px 32px;
          cursor: pointer;
          transition: background-color 0.2s;

          &:hover {
            background-color: var(--clr-white);
          }
        }
      }
    }

    &_action {
      i {
        font-size: 18px;
      }
    }

    .notifications {
      position: relative;

      .number {
        position: absolute;
        top: 0;
        right: 0;
        height: 20px;
        width: 20px;
        border: 1px solid var(--clr-white);
        border-radius: 50%;
        display: grid;
        place-items: center;
        background-color: var(--clr-danger);
        color: var(--clr-white);
        font-size: 10px;
        font-weight: 700;
      }
    }
  }
}

.mobile {
  display: none;
}

.mobileMenu {
  height: 100%;
  padding: 64px 0;

  .red {
    color: var(--clr-danger) !important;

    i {
      color: var(--clr-danger) !important;
    }
  }

  .router-link-active {
    li {
      background-color: var(--clr-white-2) !important;
    }
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    i {
      color: var(--clr-secondary);
    }

    &:hover {
      background-color: var(--clr-white);
    }
  }
}

.notificationsContainer {
  padding: 32px;
}

.clockOtherIn {
  padding: 32px;

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_actions {
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }
  }
}

.askToEndDay {
  padding: 32px;

  h2 {
    margin-bottom: 16px;
  }

  .alert {
    margin-top: 16px;
  }

  .actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}

.alert {
  padding: 12px;
  font-size: 14px;
  border-radius: 5px;

  &-danger {
    background-color: var(--clr-danger-light);
    border-color: var(--clr-danger);
  }
}

.mobilePopupMenu {
  display: none;
  place-items: center;
  position: fixed;
  bottom: 100px;
  right: 20px;
  background-color: var(--clr-secondary);
  color: var(--clr-white);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  z-index: 3;
  box-shadow: var(--shadow);
}

.animationScale {
  animation: scale 5s infinite;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  60% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

// Mobile
@media (max-width: 600px) {
  .mobile {
    display: block;
  }
  .header {
    &_actions {
      display: none;
    }
  }
  .mobilePopupMenu {
    display: grid;
  }
}
</style>
