<template>
  <section class="configs">
    <div class="configs_head">
      <h3>
        Configurations
        <!-- <i class="fas fa-plus" @click="showConfigForm = true"></i> -->
      </h3>
      <p>A Configuration is a collection of Reader Settings.</p>
    </div>
    <div class="configs_list">
      <Spinner v-if="loading" />

      <div v-else class="configs_list_body">
        <ul v-if="configs.length">
          <li
            v-for="config in configs"
            :key="config.id"
            :class="{ default: config.is_account_default }"
          >
            <div class="col">
              <h4>{{ config.id }}</h4>
              <p v-if="config.is_account_default">Default config</p>
            </div>
            <div class="col">
              <i class="fas fa-edit" @click="selectedConfig = config"></i>
            </div>
          </li>
        </ul>

        <div v-else class="none">
          <h3>No configurations created</h3>
          <p>Please create a config to get started</p>
        </div>
      </div>
    </div>
  </section>

  <Modal
    title="Configuration Form"
    :show="showConfigForm || selectedConfig"
    @close="
      showConfigForm = false;
      selectedConfig = null;
    "
  >
    <div class="configForm">
      <ConfigForm
        :accountId="accountId"
        :config="selectedConfig"
        @created="createdConfig($event)"
        @edited="editedConfig($event)"
        @deleted="deletedConfig($event)"
      />
    </div>
  </Modal>
</template>

<script>
import ConfigForm from '@/components/stripe/ConfigForm.vue';

export default {
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  components: {
    ConfigForm,
  },
  created() {
    this.init();
  },
  watch: {
    accountId() {
      this.init();
    },
  },
  data() {
    return {
      loading: false,
      configs: [],

      showConfigForm: false,
      selectedConfig: null,
    };
  },
  methods: {
    async init() {
      this.loading = true;

      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/configs?accountId=${this.accountId}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.configs = response.data.configs.data;
        // this.$store.commit('stripe/setConfigs', this.configs);
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
    createdConfig(config) {
      this.configs.push(config);
      this.showConfigForm = false;
      this.selectedConfig = null;
      this.$toast.success('Config created');
    },
    editedConfig(config) {
      const configIndex = this.configs.findIndex(
        (read) => read.id === config.id
      );

      if (configIndex === -1) return;

      this.configs[configIndex] = config;
      this.showConfigForm = false;
      this.selectedConfig = null;
      this.$toast.success('Config edited');
    },
    deletedConfig(config) {
      const configIndex = this.configs.findIndex(
        (read) => read.id === config.id
      );

      if (configIndex === -1) return;

      this.configs.splice(configIndex, 1);
      this.showConfigForm = false;
      this.selectedConfig = null;
      this.$toast.success('Config deleted');
    },
  },
};
</script>

<style lang="scss" scoped>
.none {
  text-align: center;
  padding: 16px;
  border: 1px solid var(--clr-light);
  border-radius: 5px;

  p {
    margin-top: 5px;
  }
}
.spinner {
  margin-top: 32px;
}
.configs {
  border: 1px solid var(--clr-light);
  border-radius: 5px;
  padding: 16px;

  &_head {
    i {
      margin-left: 32px;
      color: var(--clr-link);
      cursor: pointer;
    }
    p {
      margin-top: 8px;
    }
  }
  &_list {
    &_head,
    &_body {
      margin-top: 16px;
    }

    &_body {
      ul {
        li {
          &:not(:first-child) {
            margin-top: 16px;
          }
          &:hover {
            background-color: var(--clr-white);
          }

          border-radius: 5px;
          padding: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            font-size: 12px;
            margin-top: 5px;
          }
          i {
            font-size: 20px;
            color: var(--clr-link);
            cursor: pointer;
            transition: color 0.2s ease;

            &:not(:first-child) {
              margin-left: 32px;
            }
            &:hover {
              color: var(--clr-link-muted);
            }
          }
        }

        .default {
          background-color: var(--clr-white);
          border: 1px solid var(--clr-white-2);
        }
      }
    }
  }
}

.configForm {
  padding: 32px;
}
</style>
