<template>
  <section class="welcome inputFontLarge">
    <div class="welcome_head">
      <h2>
        Welcome<span v-if="selectedClient"
          >,
          {{ `${selectedClient.firstName} ${selectedClient.lastName}` }}</span
        >!
      </h2>
      <p v-if="selectedClient" @click="selectedClient = null" class="red">
        (Not you? Press here)
      </p>
      <p v-if="settings && !settings.note">
        Please fill out the form below to get started.
      </p>
      <p v-else-if="settings && settings.note">{{ settings.note }}</p>

      <div
        v-if="!newClientMode"
        @click="newClientMode = true"
        class="newClient"
      >
        <p>New Client? Click here!</p>
      </div>
      <p v-else @click="newClientMode = false" class="back link">
        <i class="fas fa-long-arrow-alt-left"></i> Return
      </p>
    </div>
    <div class="welcome_body">
      <form @submit.prevent="submit" class="form">
        <div v-if="newClientMode" class="newClient">
          <!-- Full name -->
          <BaseInput
            v-if="settings && settings.useFullName"
            label="Name (Optional)"
            placeholder="John Smith"
            :value="name"
            @input="name = $event"
            :inputFontSize="settings ? `${settings.inputFontSize}px` : '16px'"
          />

          <div class="col-2">
            <!-- First name -->
            <BaseInput
              v-if="settings && !settings.useFullName && settings.useFirstName"
              label="First Name"
              placeholder="John"
              :value="firstName"
              @input="firstName = $event"
              :inputFontSize="settings ? `${settings.inputFontSize}px` : '16px'"
            />
            <!-- Last name -->
            <BaseInput
              v-if="settings && !settings.useFullName && settings.useLastName"
              label="Last Name"
              placeholder="Smith"
              :value="lastName"
              @input="lastName = $event"
              :inputFontSize="settings ? `${settings.inputFontSize}px` : '16px'"
            />
          </div>

          <div class="col-2">
            <!-- Email -->
            <BaseInput
              v-if="settings && settings.useEmail"
              label="Email"
              placeholder="john@smith.com"
              :value="email"
              @input="email = $event"
              :inputFontSize="settings ? `${settings.inputFontSize}px` : '16px'"
            />

            <!-- DOB -->
            <BaseInput
              v-if="settings && settings.useDOB"
              inputType="date"
              label="Date Of Birth"
              placeholder="Enter client's DOB"
              :value="inputDateOfBirth"
              @input="inputDateOfBirth = $event"
              :inputFontSize="settings ? `${settings.inputFontSize}px` : '16px'"
            />
          </div>
        </div>

        <!-- Phone Number -->
        <BasePhoneInput
          label="Phone Number *"
          placeholder="Enter phone number here.."
          :value="phone"
          :clear="clearCount"
          @input="inputPhoneNumber($event)"
          :inputFontSize="settings ? `${settings.inputFontSize}px` : '16px'"
        />

        <Spinner v-if="loading" fullscreen />

        <div v-if="clients.length && !selectedClient" class="clients">
          <h3>Please select yourself if possible</h3>
          <ul>
            <li
              v-for="client in clients"
              :key="client._id"
              @click="selectedClient = client"
            >
              <p>{{ `${client.firstName} ${client.lastName}` }}</p>
            </li>
          </ul>
        </div>

        <!-- Single Select -->
        <!-- <BaseSelect
          :inputFontSize="settings ? `${settings.inputFontSize}px` : '16px'"
          label="Type of Service *"
          :options="serviceCategoryOptions"
          :value="selectedCategory"
          placeholder="Select Service Type"
          subtitle="Select which kind of service you would like today"
          @input="selectedCategory = $event"
        /> -->

        <!-- Multi Select -->
        <div class="multiSelect">
          <div class="multiSelect_head">
            <h3>What kind of service are you looking for?</h3>
            <p>Select all that apply</p>
          </div>

          <ul>
            <li
              v-for="cat in kioskCategories"
              :key="cat._id"
              @click="selectCategory(cat)"
              :class="{ selected: selectedCategories.includes(cat) }"
            >
              {{ cat.title }}
            </li>
          </ul>
        </div>

        <BaseTextarea
          label="Notes (Optional)"
          :value="note"
          @input="note = $event"
        />

        <div v-if="newClientMode" class="marketingCheckbox">
          <input
            id="marketing"
            type="checkbox"
            :checked="marketing"
            @input="marketing = !marketing"
          />
          <label for="marketing"
            >I agree to recieve promotional offers and special via email and/or
            text messaging</label
          >
        </div>

        <div class="form_actions">
          <BaseButton :disabled="!formValid">Continue</BaseButton>
        </div>
      </form>
    </div>
  </section>

  <Modal title="Thank you!" :show="confirmed" @close="confirmed = false">
    <div class="confirmed">
      <h2>You information has been received!</h2>
      <p>We will be with you as soon as possible.</p>
      <p class="light small">
        * This screen will automatically update in a few seconds
      </p>
    </div>
  </Modal>
</template>

<script>
export default {
  computed: {
    settings() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.kioskSettings;
    },
    formValid() {
      if (this.selectedCategories.length < 1) return false;
      if (this.phone.length < 10) return false;

      return true;
    },
    kioskCategories() {
      return this.$store.state.items.services.filter((category) => {
        return category.onKiosk;
      });
    },
    serviceCategoryOptions() {
      const options = [];

      this.kioskCategories.forEach((category) => {
        options.push({
          option: category.title,
          value: category._id,
        });
      });

      return options;
    },
  },
  data() {
    return {
      name: '',

      firstName: '',
      lastName: '',
      email: '',
      marketing: true,
      inputDateOfBirth: null,

      phone: '',
      note: '',
      selectedCategory: null,
      selectedCategories: [],
      confirmed: false,

      clients: [],
      selectedClient: null,
      loading: false,

      clearCount: 0,
      newClientMode: false,
    };
  },
  methods: {
    async inputPhoneNumber(phone) {
      if (phone.length === 10 && this.phone.length === 10) return;

      this.phone = phone;

      if (phone.length === 10) {
        this.loading = true;

        try {
          this.clients = await this.$store.dispatch('clients/searchClients', {
            phone,
          });
        } catch (error) {
          this.$toast.error(error.message);
        }

        this.loading = false;
      }
    },
    selectCategory(category) {
      if (!this.selectedCategories.find((c) => c._id === category._id)) {
        this.selectedCategories.push(category);
      } else {
        this.selectedCategories = this.selectedCategories.filter(
          (c) => c._id !== category._id
        );
      }
    },
    setDateOfBirth(date) {
      return {
        month: this.$moment(date).month(),
        date: this.$moment(date).date(),
        year: this.$moment(date).year(),
      };
    },
    async submit() {
      // const category = this.$store.state.items.services.find(
      //   (category) => category._id === this.selectedCategory
      // );

      // if (!category) return;

      if (this.selectedCategories.length < 1) {
        this.$toast.error('Please select type of service(s)');
        return;
      }

      let categoriesString = this.selectedCategories
        .map((category) => {
          return category.title;
        })
        .join(', ');

      let dateOfBirth;
      if (this.inputDateOfBirth)
        dateOfBirth = this.setDateOfBirth(this.inputDateOfBirth);

      if (!this.selectedClient) {
        if (this.clients.length === 1) {
          this.selectedClient = this.clients[0];
        } else if (this.clients.length > 1) {
          this.$toast.error('Please select yourself in the list above');
          return;
        }
      }

      try {
        let title;
        let firstName;
        let lastName;

        if (this.settings.useFullName) {
          title = this.name;

          firstName = this.name.split(' ')[0];
          lastName = this.name.split(' ')[this.name.split(' ').length - 1];
        } else {
          title = `${this.firstName} ${this.lastName}`;

          firstName = this.firstName;
          lastName = this.lastName;
        }

        if (!firstName) firstName = 'Walk-in';
        if (title === ' ') title = null;

        await this.$store.dispatch('tickets/createTicket', {
          title,
          client: this.selectedClient,
          kioskClient: {
            firstName,
            lastName,
            phoneNumber: this.phone,
            email: this.email,
            notifications: {
              email: this.marketing,
              text: this.marketing,
              marketing: this.marketing,
            },
            dateOfBirth,
          },
          note: `Type of service: ${categoriesString} | Note: ${this.note}`,
          status: 'waiting',
        });

        this.confirmed = true;

        // Reset
        this.name = '';
        this.firstName = '';
        this.lastName = '';
        this.inputDateOfBirth = null;
        this.email = '';
        this.note = '';
        this.phone = '';
        this.clearCount++;
        this.clients = [];
        this.selectedClient = null;
        this.selectedCategory = null;
        this.selectedCategories = [];
        this.newClientMode = false;

        setTimeout(() => {
          this.confirmed = false;
        }, 5000);
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome {
  padding: 0 16px;
  width: 100%;
  max-width: 800px;

  &_head {
    text-align: center;

    h2 {
      font-size: 32px;
    }
    p {
      margin-top: 8px;
    }
    .red {
      color: var(--clr-danger);
      cursor: pointer;
      font-size: 14px;
    }

    .back {
      color: var(--clr-danger);
      cursor: pointer;

      i {
        margin-right: 12px;
      }
    }

    .newClient {
      margin-top: 32px;
      padding: 16px 32px;
      text-align: center;
      border-radius: 5px;
      background-color: var(--clr-secondary);
      color: var(--clr-white);

      &:hover {
        cursor: pointer;
      }

      p {
        margin-top: 0;
        font-weight: 700;
      }
    }
  }

  &_body {
    // Cant use gap to support old browsers
    // (Some salon ipads are out dated)
    .form {
      max-width: 100%;
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      // gap: 16px;

      & > * {
        margin-top: 16px;
      }
      .newClient {
        display: flex;
        flex-direction: column;
        // gap: 16px;

        & > * {
          margin-top: 16px;
        }

        .col-2 {
          display: grid;
          grid-template-columns: 1fr 1fr;
          // gap: 32px;

          & > *:not(:first-child) {
            margin-left: 32px;
          }
        }
      }

      .multiSelect {
        border: 1px solid var(--clr-light);
        border-radius: 5px;
        padding: 24px;

        &_head {
          margin-bottom: 24px;

          p {
            color: var(--clr-gray);
            margin-top: 8px;
          }
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 16px;

          li {
            padding: 8px 24px;
            border-radius: 5px;
            background-color: var(--clr-light);
            font-size: 24px;
            cursor: pointer;
          }
          .selected {
            background-color: var(--clr-secondary);
            color: var(--clr-white);
          }
        }
      }

      &_actions {
        margin-top: 32px;
        display: flex;
        justify-content: flex-end;
        gap: 16px;

        button {
          width: 100%;
        }
      }
    }

    .marketingCheckbox {
      display: flex;
      align-items: center;
      // gap: 16px;
      flex-wrap: wrap;

      & > *:not(:first-child) {
        margin-left: 24px;
      }
    }

    .clients {
      text-align: center;
      padding: 16px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;

      h3 {
        font-size: 24px;
      }

      ul {
        li {
          margin-top: 16px;
          color: var(--clr-link);
          cursor: pointer;
          text-decoration: underline;
          font-size: 18px;
        }
      }
    }
  }
}

.confirmed {
  padding: 32px;
  text-align: center;

  p {
    margin-top: 16px;
  }

  .small {
    font-size: 12px;
  }
  .light {
    color: var(--clr-gray);
  }
}
</style>
