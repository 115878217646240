<template>
  <form @submit.prevent="submit" class="form">
    <div class="form_section">
      <div class="form_section_head">
        <h3>Basic Info</h3>
      </div>
      <div class="form_section_body">
        <BaseInput
          label="Title"
          @input="newProduct.title = $event"
          :value="newProduct.title"
          :validators="validators.title"
        />
        <BaseTextarea
          label="Short Description"
          @input="newProduct.shortDescription = $event"
          :value="newProduct.shortDescription"
          :validators="validators.shortDescription"
        />
        <BaseTextarea
          label="Product Description"
          @input="newProduct.longDescription = $event"
          :value="newProduct.longDescription"
          :validators="validators.longDescription"
        />
        <BaseSelect
          label="Category"
          :options="categoriesForSelect"
          @input="newProduct.category = $event"
          :value="newProduct.category"
        />
        <ImageSelect
          :fileSource="newProduct.image"
          @select="newProduct.image = $event"
        />
      </div>
    </div>
    <div class="form_section">
      <div class="form_section_head">
        <h3>Pricing</h3>
      </div>
      <div class="form_section_body">
        <div class="col-2">
          <BaseInput
            inputType="number"
            label="Retail Price"
            placeholder="USD"
            @input="newProduct.price = +$event"
            :value="newProduct.price"
            :validators="validators.price"
          />
          <!-- <BaseInput
            inputType="number"
            label="Sale Price"
            placeholder="USD"
            @input="newProduct.salePrice = +$event"
            :value="newProduct.salePrice"
            :validators="validators.salePrice"
          /> -->
        </div>
        <BaseInput
          label="Tax Rate (%)"
          inputType="number"
          placeholder="%"
          subtitle="Added to price at checkout"
          @input="newProduct.taxRate = +$event"
          :value="newProduct.taxRate"
          :validators="validators.taxRate"
        />

        <div v-if="salon && salon.bookingSettings.onlineShop" class="toggle">
          <Toggle
            :checked="newProduct.onlineShop"
            @toggle="newProduct.onlineShop = !newProduct.onlineShop"
          />
          <div class="toggle_text">
            <p class="toggle_text_label">Sell Online</p>
          </div>
        </div>

        <div
          v-if="
            salon && salon.bookingSettings.onlineShop && newProduct.onlineShop
          "
          class="toggle"
        >
          <Toggle
            :checked="newProduct.featured"
            @toggle="newProduct.featured = !newProduct.featured"
          />
          <div class="toggle_text">
            <p class="toggle_text_label">Featured</p>
            <p class="toggle_text_subtext">
              Will display first on your online shop and be sorted into
              "featured" category.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="form_section">
      <div class="form_section_head">
        <h3>Commission</h3>
      </div>
      <div class="form_section_body">
        <div class="col-2">
          <BaseSelect
            label="Commission Type"
            :options="commissionTypeOptions"
            @input="newProduct.commissionType = $event"
            :value="newProduct.commissionType"
          />
          <BaseInput
            inputType="number"
            label="Commission Amount"
            placeholder="0.00"
            @input="newProduct.commissionAmount = +$event"
            :value="newProduct.commissionAmount"
            :validators="validators.commissionAmount"
          />
        </div>
      </div>
    </div>
    <div class="form_section">
      <div class="form_section_head">
        <h3>Inventory</h3>
      </div>
      <div class="form_section_body">
        <BaseInput
          inputType="number"
          label="Stock Quantity"
          placeholder="0"
          @input="newProduct.stock = +$event"
          :value="newProduct.stock"
        />
        <BaseSelect
          label="Distributor"
          :options="distributorOptions"
          :value="newProduct.distributor"
          @input="newProduct.distributor = $event"
        />
        <p
          v-if="newProduct.distributor"
          @click="selectDistrib(newProduct.distributor)"
          class="link small"
        >
          View Distributor
        </p>
      </div>
    </div>
    <div class="form_actions">
      <BaseButton
        v-if="newProduct._id"
        mode="danger-outline"
        type="button"
        @click="showConfirmDelete = true"
        >Delete Product</BaseButton
      >
      <BaseButton type="submit" :disabled="!formValid || loading"
        ><i v-if="loading" class="fas fa-spinner"></i> Submit</BaseButton
      >
    </div>
  </form>

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Delete product"
    text="Are you sure you wish to delete this product? This action can not be undone."
    @confirm="deleteProduct"
    @deny="showConfirmDelete = false"
  />

  <Modal
    title="Distributor Form"
    :show="selectedDistrib"
    @close="selectedDistrib = null"
  >
    <DistribForm :distrib="selectedDistrib" />
  </Modal>
</template>

<script>
import ImageSelect from '@/components/components/ImageSelect.vue';
import DistribForm from '@/components/items/DistribForm.vue';

export default {
  emits: ['submitted', 'deleted'],
  components: {
    ImageSelect,
    DistribForm,
  },
  props: {
    product: {
      type: Object,
    },
  },
  created() {
    if (this.salon) {
      this.newProduct.taxRate = this.salon.payments.defaultTaxRate;
    }
    if (this.product)
      this.newProduct = JSON.parse(
        JSON.stringify({ ...this.newProduct, ...this.product })
      );
  },
  data() {
    return {
      showConfirmDelete: false,
      loading: false,

      newProduct: {
        image: '',
        title: '',
        shortDescription: '',
        longDescription: '',
        category: null,
        price: 0,
        salePrice: 0,
        stock: 0,
        taxRate: 0,
        commissionType: 'flat',
        commissionAmount: 0,
        onlineShop: false,
        featured: false,
        distributor: '',
      },

      selectedDistrib: null,
    };
  },
  watch: {
    salon(val) {
      this.newProduct.taxRate = val.payments.defaultTaxRate;
    },
  },
  computed: {
    salon() {
      return this.$store.state.auth.salon;
    },
    distributors() {
      if (!this.salon) return [];

      return this.salon.distributors;
    },
    distributorOptions() {
      return this.distributors.map((distributor) => ({
        value: distributor._id,
        option: distributor.name,
      }));
    },
    formValid() {
      if (
        !this.newProduct.title ||
        (!this.newProduct.price && this.newProduct.price !== 0) ||
        (!this.newProduct.taxRate && this.newProduct.taxRate !== 0) ||
        (!this.newProduct.commissionAmount &&
          this.newProduct.commissionAmount !== 0)
      )
        return false;
      return true;
    },
    validators() {
      return {
        title: {
          required: true,
          minLength: 2,
          maxLength: 75,
        },
        shortDescription: {
          minLength: 2,
          maxLength: 100,
        },
        longDescription: {
          minLength: 2,
          maxLength: 500,
        },
        price: {
          required: true,
          min: 0,
        },
        salePrice: {
          min: 0,
        },
        taxRate: {
          required: true,
          min: 0,
          max: 100,
        },
        commissionAmount: {
          required: true,
          min: 0,
        },
      };
    },
    categories() {
      return this.$store.state.items.products;
    },
    categoriesForSelect() {
      return this.categories.map((category) => {
        return {
          option: category.title,
          value: category._id,
        };
      });
    },
    commissionTypeOptions() {
      return [
        {
          option: 'Flat',
          value: 'flat',
        },
        {
          option: 'Percent',
          value: 'percent',
        },
      ];
    },
  },
  methods: {
    async submit() {
      this.loading = true;

      try {
        if (this.newProduct._id) {
          await this.$store.dispatch('items/editProduct', this.newProduct);
          this.submitted();
          return;
        }

        await this.$store.dispatch('items/createProduct', this.newProduct);
        this.submitted();
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
    submitted() {
      this.$toast.success('Product updated');
      this.$emit('submitted');
    },

    deleteProduct() {
      this.$store.dispatch('items/deleteProduct', this.newProduct);
      this.$toast.success('Product deleted');

      this.$emit('deleted', this.newProduct._id);
    },

    selectDistrib(distribId) {
      this.selectedDistrib = this.distributors.find(
        (distrib) => distrib._id === distribId
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  &_section {
    padding: 32px;
    border: 1px solid var(--clr-light-2);
    border-radius: 5px;

    &:not(:first-child) {
      margin-top: 16px;
    }

    &_head {
      margin-bottom: 16px;

      p {
        font-size: 14px;
        margin-top: 5px;
        color: var(--clr-gray);
      }
    }

    .col-2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }
    .col-3 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
    }
    .col-4 {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;
    }

    &_body {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;

      .link {
        color: var(--clr-link);
        cursor: pointer;
      }
      .small {
        font-size: 12px;
      }
    }
  }

  &_actions {
    margin-top: 16px;
    text-align: right;

    button:not(:first-child) {
      margin-left: 16px;
    }
  }
}

.toggle {
  display: flex;
  align-items: center;
  gap: 32px;
  flex-wrap: wrap;
  padding: 16px 0;

  &_text {
    &_label {
      font-size: 18px;
    }
    &_subtext {
      margin-top: 5px;
      font-size: 14px;
    }
  }
}

// Tablet
@media (max-width: 900px) {
  .form {
    &_section {
      .col-2,
      .col-3,
      .col-4 {
        grid-template-columns: 1fr;
      }
    }
  }
}
</style>
