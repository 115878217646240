<template>
  <div class="parent">
    <div class="label" v-if="label">
      <label>{{ label }}</label>
    </div>
    <div
      class="input"
      :class="{
        error: errors.length,
        valid: validators && !errors.length && touched,
      }"
    >
      <input
        v-bind="$attrs"
        v-mask="['(###) ###-####']"
        type="tel"
        :value="value"
        :masked="false"
        @input="updateValue"
        @blur="validators ? validate($event) : null"
        @focus="touched = true"
        :step="stepResult"
        ref="input"
      />
      <i @click="$emit('icon-click')" v-if="icon" :class="icon"></i>
      <slot></slot>
    </div>
    <p v-if="subtitle" class="subtitle">{{ subtitle }}</p>
    <div v-if="errors.length" class="errors">
      <p v-for="error in errors" :key="error">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
  directives: { mask },
  emits: ['input', 'icon-click'],
  mounted() {
    this.$refs.input.style.fontSize = this.inputFontSize;
  },
  props: {
    clear: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    value: [String, Number],
    icon: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
    },
    validators: {
      type: Object,
      required: false,
    },
    step: {
      type: Number,
      required: false,
    },
    inputFontSize: {
      type: String,
      default: '16px',
    },
  },
  data() {
    return {
      touched: false,
      errors: [],
    };
  },
  computed: {
    stepResult() {
      if (this.inputType === 'number') {
        return this.step || 0.01;
      } else {
        return null;
      }
    },
  },
  watch: {
    clear() {
      return (this.$refs.input.value = '');
    },
    inputFontSize(val) {
      this.$refs.input.style.fontSize = val;
    },
  },
  methods: {
    updateValue(event) {
      let value = event.target.value.match(/\d+/g).join('');
      this.$emit('input', value);
    },
    getValue() {
      return this.$refs.input.value;
    },
    validate(event) {
      const value = event.target.value;
      const { minLength, maxLength, min, max, required, email } =
        this.validators;

      if (required) {
        const errorIndex = this.errors.findIndex((error) =>
          error.includes('Field required')
        );

        if (!value) {
          if (errorIndex === -1) {
            this.errors.push('Field required');
          }
        } else {
          if (errorIndex !== -1) {
            this.errors.splice(errorIndex, 1);
          }
        }
      } else if (!value) {
        this.errors = [];
        return;
      }

      if (email) {
        const errorIndex = this.errors.findIndex((error) =>
          error.includes('valid email')
        );

        if (!value.match(/^\S+@\S+\.\S+$/)) {
          if (errorIndex === -1) {
            this.errors.push('Must be a valid email');
          }
        } else {
          if (errorIndex !== -1) {
            this.errors.splice(errorIndex, 1);
          }
        }
      }
      if (minLength) {
        const errorIndex = this.errors.findIndex((error) =>
          error.includes('Minimum length')
        );

        if (value.length < minLength) {
          if (errorIndex === -1) {
            this.errors.push(`Minimum length is ${minLength}`);
          }
        } else {
          if (errorIndex !== -1) {
            this.errors.splice(errorIndex, 1);
          }
        }
      }
      if (maxLength) {
        const errorIndex = this.errors.findIndex((error) =>
          error.includes('Maximum length')
        );

        if (value.length > maxLength) {
          if (errorIndex === -1) {
            this.errors.push(`Maximum length is ${maxLength}`);
          }
        } else {
          if (errorIndex !== -1) {
            this.errors.splice(errorIndex, 1);
          }
        }
      }
      if (min || min === 0) {
        const errorIndex = this.errors.findIndex((error) =>
          error.includes('Minimum is')
        );

        if (+value < min) {
          if (errorIndex === -1) {
            this.errors.push(`Minimum is ${min}`);
          }
        } else {
          if (errorIndex !== -1) {
            this.errors.splice(errorIndex, 1);
          }
        }
      }
      if (max || max === 0) {
        const errorIndex = this.errors.findIndex((error) =>
          error.includes('Maximum is')
        );

        if (+value > max) {
          if (errorIndex === -1) {
            this.errors.push(`Maximum is ${max}`);
          }
        } else {
          if (errorIndex !== -1) {
            this.errors.splice(errorIndex, 1);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  margin-bottom: 5px;
  color: var(--clr-gray-2);
  font-size: 14px;
  font-weight: 700;
}

.input {
  display: flex;
  align-items: center;
  line-height: 24px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid var(--clr-light);
  //   padding-right: 16px;
  transition: border-color 0.2s;
  overflow: hidden;

  &:hover {
    border-color: var(--clr-gray);
  }
}
.error {
  border-color: var(--clr-danger) !important;
}
.valid {
  border-color: var(--clr-success) !important;
}

.subtitle {
  margin-top: 5px;
  font-size: 13px;
  color: var(--clr-gray);
}

.errors {
  margin-top: 5px;
  font-size: 12px;
  color: var(--clr-danger);
  text-align: right;
}

input {
  padding: 10px 16px;
  height: 100%;
  width: 100%;
  border: none;
  font-size: 16px;
  font-family: inherit;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: var(--clr-light) !important;
  }
}

i {
  font-size: 14px;
  color: var(--clr-gray);
  cursor: pointer;
  padding: 0 16px;
}
</style>
