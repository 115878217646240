<template>
  <section class="list">
    <div class="list_head">
      <h2>Integrations</h2>
      <p class="subtext">Third party integrations to expand Raserva</p>
    </div>

    <ul class="list_body">
      <router-link :to="{ name: 'IntegrationsHighLevel' }">
        <li>
          <div class="head">
            <h2 class="title">HighLevel</h2>
            <p class="type">Marketing integration</p>

            <p class="description">
              HighLevel is the first-ever all-in-one platform that will give you
              the tools, support and resources you need to succeed with your
              agency.
            </p>
          </div>
        </li>
      </router-link>
    </ul>
  </section>
</template>

<style lang="scss" scoped>
.list {
  margin: auto;
  margin-top: 64px;
  padding: 0 16px;
  width: 100%;
  max-width: 1000px;

  &_head {
    h2 {
      font-size: 28px;
    }
    .subtext {
      margin-top: 6px;
    }
  }

  &_body {
    margin-top: 64px;
    display: flex;
    gap: 16px;

    li {
      max-width: 300px;
      padding: 32px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      }

      .type {
        margin-top: 8px;
        color: var(--clr-gray);
      }

      .description {
        font-size: 14px;
        margin-top: 16px;
        line-height: 20px;
      }
    }
  }
}
</style>
