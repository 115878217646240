<template>
  <section class="pricing">
    <div class="container">
      <div class="pricing_head">
        <h2>Pricing</h2>
        <p>Transparent pricing, always.</p>
      </div>
      <div class="pricing_body">
        <PricingDetails />
      </div>
    </div>
  </section>
</template>

<script>
import PricingDetails from '@/components/landing/PricingDetails.vue';

export default {
  components: {
    PricingDetails,
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 1400px;
  padding: 0 16px;
  margin: auto;
}

.pricing {
  padding: 64px 0;

  &_head {
    text-align: center;

    h2 {
      font-size: 36px;
    }
    p {
      font-size: 18px;
      margin-top: 8px;
    }
  }
  &_body {
    margin-top: 32px;
  }
}
</style>
