<template>
  <section class="checkout">
    <!-- Default Screen -->
    <div v-if="displayedScreen === 'default'" class="checkout_default">
      <!-- Parent Categories -->
      <ul class="checkout_default_parents">
        <li
          @click="
            selectedParentCategory = parentCategory;
            selectedCategory = null;
          "
          :class="{
            selected: selectedParentCategory?._id === parentCategory._id,
          }"
          v-for="parentCategory in serviceParentCategories"
          :key="parentCategory._id"
        >
          {{ parentCategory.title.substring(0, 10) }}
        </li>
      </ul>

      <!-- Parent categories categories -->
      <div
        v-if="selectedParentCategory"
        class="checkout_default_categories-items"
      >
        <div class="checkout_default_categories-items_categories">
          <h3>{{ selectedParentCategory.title }} Categories</h3>

          <ul>
            <li
              @click="selectedCategory = category"
              v-for="category in selectedParentCategory.categories"
              :key="category._id"
              :class="{ selected: selectedCategory?._id === category._id }"
            >
              {{ category.title.substring(0, 20)
              }}<span v-if="category.title.length > 20">..</span>
            </li>
          </ul>
        </div>

        <div
          v-if="selectedCategory"
          class="checkout_default_categories-items_items"
        >
          <h3>{{ selectedCategory.title }} items</h3>

          <ul>
            <li
              @click="$emit('selectItem', item)"
              v-for="item in selectedCategory.items"
              :key="item._id"
            >
              {{ item.title.substring(0, 20)
              }}<span v-if="item.title.length > 20">..</span>
            </li>
          </ul>
        </div>
      </div>

      <!-- Ticket items -->
      <div v-if="ticket" class="checkout_default_ticket-items">
        <h3>Items on Ticket</h3>

        <div
          class="checkout_default_ticket-items_item"
          v-for="(item, index) in ticket.items"
          :key="item._id"
        >
          <TicketItem
            classicView
            v-bind:item="item"
            :ticketStatus="ticket.status"
            @update="$emit('updateItem', { index, item: $event })"
            @remove="$emit('removeItem', index)"
            @refund="$emit('refundItem', item)"
            @selectPackage="$emit('selectPackage', $event)"
            @addQuickSuggestion="$emit('selectItem', $event)"
          />
        </div>
      </div>

      <div class="checkout_default_summary-actions">
        <div class="checkout_default_summary-actions_summary">
          <h3>Ticket Summary</h3>

          <div class="checkout_default_summary-actions_summary_component">
            <TicketSummary
              :ticket="ticket"
              @removePayment="$emit('removePayment', $event)"
              @selectPayment="$emit('selectPayment', $event)"
              @removeTip="$emit('removeTip', $event)"
            />
          </div>
        </div>

        <div class="checkout_default_summary-actions_actions">
          <BaseButton
            mode="secondary-outline"
            @click="$emit('verifySave', { one: false })"
            >Save & Exit</BaseButton
          >
          <BaseButton @click="displayedScreen = 'payments'"
            >Continue To Payment</BaseButton
          >
        </div>
      </div>
    </div>

    <!-- Payments -->
    <div v-else-if="displayedScreen === 'payments'" class="checkout_payments">
      <BaseButton @click="displayedScreen = 'default'" mode="danger-outline"
        ><i class="fas fa-chevron-left mr-s"></i> Back</BaseButton
      >

      <!-- Payment Options -->
      <div class="checkout_payments_paymentOptions">
        <TicketPaymentOptions
          :ticket="ticket"
          :checkoutOnlyMode="checkoutOnlyMode"
          large
          @addPayment="$emit('addPayment', $event)"
          @otherPaymentMethod="$emit('selectedOtherPaymentMethod')"
          @updatePaymentAmount="$emit('updatePaymentAmount', +$event)"
          @verifySave="$emit('verifySave', $event)"
          :amount="paymentAmount"
          :balance="balance"
        />
      </div>

      <!-- Ticket Items -->
      <div class="checkout_payments_items">
        <div
          class="checkout_payments_items_item"
          v-for="(item, index) in ticket.items"
          :key="item._id"
        >
          <TicketItem
            classicView
            v-bind:item="item"
            :ticketStatus="ticket.status"
            @update="$emit('updateItem', { index, item: $event })"
            @remove="$emit('removeItem', index)"
            @refund="$emit('refundItem', item)"
            @selectPackage="$emit('selectPackage', $event)"
            @addQuickSuggestion="$emit('selectItem', $event)"
          />
        </div>
      </div>

      <!-- Summary / Actions -->
      <div class="checkout_payments_summary-actions">
        <!-- Summary -->
        <div class="checkout_payments_summary-actions_summary">
          <TicketSummary
            :ticket="ticket"
            @removePayment="$emit('removePayment', $event)"
            @selectPayment="$emit('selectPayment', $event)"
            @removeTip="$emit('removeTip', $event)"
            @providedChange="$emit('providedChange')"
          />
        </div>

        <!-- Actions -->
        <div class="checkout_payments_summary-actions_actions">
          <TicketActions
            mobile
            hideGuidedCheckout
            hideAddItems
            :ticket="ticket"
            :total="total"
            :tipLoading="tipLoading"
            @showGuidedCheckout="showGuidedCheckout = true"
            @addItem="
              selectItemPage = '';
              dontCloseModalOnAddItem = false;
              showAddItem = true;
            "
            @addMultipleItems="
              selectItemPage = '';
              dontCloseModalOnAddItem = true;
              showAddItem = true;
            "
            @addTip="$emit('addTip')"
            @approveItems="$emit('approveItems')"
            @addCashDiscountToItems="$emit('addCashDiscountToItems')"
            @removeCashDiscountToItems="$emit('removeCashDiscountToItems')"
            @deleteTicket="$emit('deleteTicket')"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TicketItem from '@/components/tickets/TicketItem.vue';
import TicketSummary from '@/components/tickets/TicketSummary.vue';
import TicketPaymentOptions from '@/components/tickets/TicketPaymentOptions.vue';
import TicketActions from '@/components/tickets/TicketActions.vue';

export default {
  emits: [
    'updateItem',
    'removeItem',
    'refundItem',
    'selectPackage',
    'addQuickSuggestion',
    'selectItem',
    'removePayment',
    'selectPayment',
    'removeTip',
    'addPayment',
    'selectedOtherPaymentMethod',
    'updatePaymentAmount',
    'addTip',
    'approveItems',
    'addCashDiscountToItems',
    'removeCashDiscountToItems',
    'deleteTicket',
    'verifySave',
    'providedChange',
  ],
  components: {
    TicketItem,
    TicketSummary,
    TicketPaymentOptions,
    TicketActions,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    paymentAmount: {
      type: Number,
      required: false,
    },
    balance: {
      type: Number,
      required: true,
    },
    checkoutOnlyMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  created() {
    this.init();
  },
  computed: {
    serviceCategories() {
      return this.$store.state.items.services;
    },
    productCategories() {
      return this.$store.state.items.products;
    },
    usesCashDiscount() {
      return this.$store.state.auth.salon.payments.useCashDiscount;
    },
    cardSurcharge() {
      if (!this.usesCashDiscount) return 0;

      if (!this.ticket.items.length) {
        return 0;
      }

      let total = 0;

      this.ticket.items.forEach((item) => {
        if (item.item.prepaid) return;

        total +=
          (item.item.cashDiscountAdditionalProcessingFee || 0) * item.quantity;
      });

      return total;
    },
    subtotal() {
      if (!this.ticket.items.length) {
        return 0;
      }

      let total = 0;

      this.ticket.items.forEach((item) => {
        if (item.item.prepaid) return;

        if (item.item.basePrice) {
          total +=
            (item.item.basePrice - (item.item.discountAmount || 0)) *
            item.quantity *
            (item.item.priceType === 'duration' ? item.item.duration : 1);
        } else {
          total +=
            (item.item.price -
              (item.item.discountAmount || 0) -
              (item.item.cashDiscountAdditionalProcessingFee || 0)) *
            item.quantity *
            (item.item.priceType === 'duration' ? item.item.duration : 1);
        }
      });

      return total;
    },
    tax() {
      if (!this.ticket.items.length) {
        return 0;
      }

      let tax = 0;
      this.ticket.items.forEach((item) => {
        if (item.item.prepaid) return;

        tax +=
          (item.item.price - (item.item.discountAmount || 0)) *
          (item.item.taxRate * 0.01) *
          item.quantity;
      });

      return tax;
    },
    total() {
      // We have to add this small amount because 0.075 rounded in Javascript is 0.07 (like wtf dude)
      return this.subtotal + this.tax + this.cardSurcharge + 0.000000001;
    },
  },
  data() {
    return {
      serviceParentCategories: [],

      selectedParentCategory: null,
      selectedCategory: null,

      displayedScreen: 'default',
    };
  },
  methods: {
    init() {
      this.serviceParentCategories = JSON.parse(
        JSON.stringify([
          ...this.$store.state.auth.salon.serviceParentCategories,
          {
            title: 'Other',
            _id: 'other',
          },
          {
            title: 'Products',
            _id: 'products',
          },
        ])
      ).sort((a, b) => a.favorite - b.favorite);

      this.serviceParentCategories.forEach((cat) => (cat.categories = []));

      this.serviceCategories.forEach((category) => {
        if (category.parentCategory) {
          const parentCat = this.serviceParentCategories.find(
            (parentCategory) => parentCategory._id === category.parentCategory
          );

          if (parentCat) {
            parentCat.categories.push(category);
          }
        } else {
          const parentCat = this.serviceParentCategories.find(
            (parentCategory) => parentCategory._id === 'other'
          );

          if (parentCat) {
            parentCat.categories.push(category);
          }
        }
      });

      this.productCategories.forEach((category) => {
        this.serviceParentCategories[
          this.serviceParentCategories.length - 1
        ].categories.push(category);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mr-s {
  margin-right: 8px;
}
.checkout {
  &_default {
    display: flex;
    gap: 32px;

    h3 {
      text-align: center;
    }

    &_parents,
    &_categories-items,
    &_ticket-items,
    &_summary {
      max-height: 70vh;
      overflow: auto;
    }

    &_parents {
      max-width: 300px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .selected {
        background-color: var(--clr-primary);
        color: var(--clr-white);
      }

      li {
        padding: 16px 32px;
        border: 1px solid var(--clr-primary);
        border-radius: 5px;
        display: grid;
        place-items: center;
        font-size: 36px;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          background-color: var(--clr-primary);
          color: var(--clr-white);
        }
      }
    }

    &_categories-items {
      display: flex;
      //   flex-direction: column;
      gap: 32px;

      &_categories,
      &_items {
        padding: 16px;
        border-radius: 5px;
        background-color: var(--clr-light);
        text-align: center;
        overflow: auto;

        ul {
          margin-top: 16px;
          background-color: white;
          border-radius: 5px;
          overflow: hidden;

          .selected {
            background-color: var(--clr-secondary) !important;
            color: var(--clr-white) !important;
            font-weight: 700;
          }

          li {
            border-bottom: 1px solid var(--clr-light);
            padding: 16px 32px;
            cursor: pointer;
            overflow: hidden;

            &:last-child {
              border-bottom: none;
            }

            &:hover {
              background-color: var(--clr-white);
            }
          }
        }
      }
    }

    &_ticket-items {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 16px;
      padding: 16px;
      border-radius: 5px;
      background-color: var(--clr-light);
    }

    &_summary-actions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &_summary {
        flex-grow: 1;
        background-color: var(--clr-light);
        border-radius: 5px;
        padding: 8px 24px;

        &_component {
          margin-top: 16px;
          background-color: white;
          padding: 8px 24px;
        }
      }

      &_actions {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 16px;
        width: 100%;

        button {
          width: 100%;
        }
      }
    }
  }

  &_payments {
    display: flex;
    justify-content: space-between;
    gap: 32px;

    &_items {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &_paymentOptions {
      flex-grow: 1;
    }

    &_summary-actions {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }
}
</style>
