import axios from 'axios';

export default {
  namespaced: true,
  state: {
    tickets: [],
    changeCount: 0,
    combineTickets: [],
    groupTickets: [],
    chargeanywherePaymentId: null,
  },
  mutations: {
    addTicket(state, payload) {
      state.tickets.push(payload);
      state.changeCount++;
    },
    editTicket(state, payload) {
      const index = state.tickets.findIndex(
        (ticket) => ticket._id === payload._id
      );

      if (index === -1) return;

      state.tickets[index] = payload;
      state.changeCount++;
    },
    deleteTicket(state, payload) {
      let index = state.tickets.findIndex(
        (ticket) => ticket._id === payload._id
      );

      state.tickets.splice(index, 1);
      state.changeCount++;
    },
  },
  actions: {
    async createTicket({ rootState, commit }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/tickets`,
          {
            ...payload,
            salonId: rootState.auth.salon._id,
          },
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.sockets.socket.emit('ticketCreated', response.data.ticket);
        commit('addTicket', response.data.ticket);

        return response.data.ticket;
      } catch (error) {
        console.log(error.message);
        throw Error(error.response?.data?.error);
      }
    },
    async editTicket({ commit, rootState }, payload) {
      try {
        if (
          payload.status === 'waiting' &&
          payload.items.find((item) => item.staff)
        ) {
          payload.status = 'in-progress';
        }

        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/tickets/${payload._id}${
            payload.query ? `?${payload.query}` : ''
          }`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.sockets.socket.emit('ticketUpdated', response.data.ticket);
        commit('editTicket', payload);

        return response.data.ticket;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async combineTickets({ state, dispatch }) {
      try {
        if (state.combineTickets.length < 2) return;

        if (
          state.combineTickets.includes(
            (ticket) =>
              ticket.status === 'completed' || ticket.status === 'refunded'
          )
        ) {
          return;
        }

        let client;
        let newTicket;

        state.combineTickets.forEach((ticket) => {
          if (ticket.client && !client) {
            client = JSON.parse(JSON.stringify(ticket.client));
          }

          if (!newTicket) {
            newTicket = JSON.parse(JSON.stringify(ticket));
            return;
          }

          newTicket.items.push(...ticket.items);
          newTicket.tips.push(...ticket.tips);
          newTicket.payments.push(...ticket.payments);
        });

        newTicket._id = undefined;
        newTicket.client = client;

        for (let i = 0; i < state.combineTickets.length; i++) {
          await dispatch('deleteTicket', state.combineTickets[i]);
        }

        await dispatch('createTicket', newTicket);

        state.combineTickets = [];
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async groupTickets({ state, rootState }, payload) {
      if (state.groupTickets.length < 2) return;

      const ticketIds = [];

      state.groupTickets.forEach((ticket) => {
        ticketIds.push(ticket._id);
      });

      try {
        await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/tickets/group`,
          {
            ticketIds,
            name: payload,
            salonId: rootState.auth.salon._id,
          },
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        state.groupTickets.forEach((ticket) => {
          const index = state.tickets.findIndex(
            (preticket) => preticket._id === ticket._id
          );

          if (index === -1) return;

          state.tickets[index].groupName = payload;
        });

        state.groupTickets = [];
      } catch (error) {
        console.log(error);
        throw Error(error.response.data.error);
      }
    },

    async checkoutTicket({ commit, rootState, dispatch }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/tickets/${payload._id}/checkout`,
          { ...payload, salonId: rootState.auth.salon._id },
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        response.data.ticket.payments.forEach((payment) => {
          if (payment.type === 'other') {
            dispatch(
              'auth/addAction',
              {
                action: `used other payment method (${
                  payment.otherType || 'unspecified'
                }) on ticket #${response.data.ticket.number}`,
                staff: payment.staff,
                type: 'ticket',
              },
              { root: true }
            );
          }
        });

        response.data.ticket.items.forEach((item) => {
          if (!item.staff) return;

          const staff = rootState.staff.staff.find(
            (staff) => staff._id === item.staff._id
          );

          if (!staff) return;

          if (!staff.available) {
            dispatch('staff/toggleAvailability', staff, { root: true });
          }
        });

        if (response.data.created) {
          commit('addTicket', response.data.ticket);
          rootState.sockets.socket.emit('ticketCreated', response.data.ticket);
        } else {
          commit('editTicket', payload);
          rootState.sockets.socket.emit('ticketUpdated', response.data.ticket);
        }

        // Products
        if (payload.items.some((item) => !item.item.duration)) {
          dispatch('items/getProducts', null, { root: true });
        }
      } catch (error) {
        console.log(error);
        throw Error(error.response.data.error);
      }
    },

    async recalculateTicketItems({ rootState, rootGetters }, ticket) {
      const deepCopiedTicket = JSON.parse(JSON.stringify(ticket));

      try {
        const newItems = [];

        deepCopiedTicket.items.forEach((item) => {
          let theNewItem;

          if (item.item.duration) {
            const newItem = JSON.parse(
              JSON.stringify(
                rootGetters['items/services'].find((service) => {
                  return service._id === item.item._id;
                })
              )
            );

            if (!newItem) {
              theNewItem = item;
            } else {
              newItem.basePrice = newItem.price;

              // Cash discount
              if (rootState.auth.salon.payments.useCashDiscount) {
                newItem.cashDiscountAdditionalProcessingFee =
                  newItem.price *
                  (rootState.auth.salon.payments
                    .cashDiscountAdditionalProcessingFee *
                    0.01);

                newItem.price +=
                  newItem.price *
                  (rootState.auth.salon.payments
                    .cashDiscountAdditionalProcessingFee *
                    0.01);
              }
              // Requested
              if (item.item.requested) {
                newItem.requested = true;

                if (
                  rootState.auth.salon.adminSettings
                    .useRequestedServiceTurnsValue
                ) {
                  newItem.turns =
                    rootState.auth.salon.adminSettings
                      .requestedServiceTurnsValue || 0;
                }
              }

              theNewItem = {
                ...item,
                item: newItem,
              };
            }
          } else {
            const newItem = rootGetters['items/products'].find(
              (product) => product._id === item.item._id
            );

            if (!newItem) {
              theNewItem = item;
            } else {
              newItem.basePrice = newItem.price;

              theNewItem = {
                ...item,
                item: newItem,
              };
            }
          }

          const newStaff = rootState.auth.salon.staff.find(
            (staff) => staff._id === item.staff._id
          );

          if (newStaff) {
            theNewItem.staff = newStaff;
          }

          newItems.push(theNewItem);
        });

        return newItems;
      } catch (e) {
        console.log(e);
      }
    },

    async savePayments({ state, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/tickets/${payload._id}/payments`,
          { payments: payload.payments, salonId: rootState.auth.salon._id },
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        const index = state.tickets.findIndex(
          (ticket) => ticket._id === response.data.ticket._id
        );

        if (index !== -1) {
          state.tickets[index].payments = response.data.ticket.payments;
        }
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async deleteTicket({ commit, dispatch, rootState }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/tickets/${payload._id}`,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        if (payload.checkout_at) {
          let paymentsTotal = 0;

          payload.payments.forEach(
            (payment) => (paymentsTotal += payment.amount)
          );

          await dispatch(
            'auth/addAction',
            {
              action: `deleted ticket ${payload.number} with ${
                payload.payments.length
              } payments for $${paymentsTotal.toFixed(2)}`,
              staff: rootState.auth.loggedInSalonStaff,
              type: 'ticket',
            },
            { root: true }
          );
        }

        rootState.sockets.socket.emit('ticketDeleted', payload);
        commit('deleteTicket', payload);
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async deleteDashboardTicket({ commit, rootState }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/tickets/${payload._id}`,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.sockets.socket.emit('ticketDeleted', payload);
        commit('deleteTicket', payload);
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async clearTickets({ rootState, state }) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/tickets/`
        );

        rootState.sockets.socket.emit('clearTickets', rootState.auth.salon._id);
        state.tickets = [];
        state.changeCount++;
      } catch (error) {
        throw Error(error.respones.data.error);
      }
    },
    async getTicketsInDateRange({ rootState }, payload) {
      try {
        let queries = `?start=${payload.start}&end=${payload.end}`;

        if (payload.number) queries += `&number=${payload.number}`;

        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/tickets${queries}`
        );

        return response.data.tickets;
      } catch (error) {
        console.log(error);
        throw Error(error.response.data.error);
      }
    },

    async sendInvoice({ rootState }, payload) {
      try {
        await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/tickets/sendInvoice?email=${payload.email}&ticketId=${payload.ticketId}`
        );
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
  },
  getters: {
    completed(state) {
      return state.tickets.filter((ticket) => ticket.status === 'completed');
    },
    waiting(state) {
      return state.tickets.filter((ticket) => ticket.status === 'waiting');
    },
    inProgress(state) {
      return state.tickets.filter((ticket) => ticket.status === 'in-progress');
    },
    byGroup: (state) => (groupName) => {
      return state.tickets.filter((ticket) => ticket.groupName === groupName);
    },
    search: (state) => (query) => {
      query = query.toLowerCase();

      return state.tickets.filter((ticket) => {
        if (
          ticket.items.length &&
          ticket.items.find((item) => {
            return item.item.title.toLowerCase().includes(query);
          })
        ) {
          return true;
        } else if (
          ticket.items.length &&
          ticket.items.find((item) => {
            if (item.staff) {
              return `${item.staff.firstName} ${item.staff.lastName}`
                .toLowerCase()
                .includes(query);
            }
          })
        ) {
          return true;
        } else if (ticket.client) {
          return (
            ticket.client.phoneNumber.includes(query) ||
            `${ticket.client.firstName} ${ticket.client.lastName}`
              .toLowerCase()
              .includes(query)
          );
        } else {
          if (!ticket.title) {
            return 'Walk-in'.toLowerCase().includes(query);
          }

          return ticket.title.toLowerCase().includes(query);
        }
      });

      // return state.tickets.filter((ticket) => {
      //   if (
      //     ticket.items.find((item) => {
      //       return item.item.title.toLowerCase().includes(query);
      //     })
      //   ) {
      //     return true;
      //   } else if (
      //     ticket.items.length &&
      //     ticket.items.find((item) => {
      //       if (item.staff) {
      //         return `${item.staff.firstName} ${item.staff.lastName}`
      //           .toLowerCase()
      //           .includes(query);
      //       }
      //     })
      //   ) {
      //     return true;
      //   } else if (ticket.client) {
      //     return (
      //       ticket.client.phoneNumber.includes(query) ||
      //       `${ticket.client.firstName} ${ticket.client.lastName}`
      //         .toLowerCase()
      //         .includes(query)
      //     );
      //   } else {
      //     if (!ticket.title) {
      //       return 'Walk-in'.toLowerCase().includes(query);
      //     }

      //     return ticket.title.toLowerCase().includes(query);
      //   }
      // });
    },
  },
};
