<template>
  <form @submit.prevent="submit" class="form">
    <BaseInput
      label="Team Name"
      :value="newTeam.name"
      @input="newTeam.name = $event"
    />

    <p @click="showTeamUsers = !showTeamUsers" class="link">
      {{ showTeamUsers ? 'Hide' : 'Show' }} users
    </p>

    <!-- Users -->
    <ul class="users" v-if="showTeamUsers">
      <li v-for="user in users" :key="user.id">
        <div class="col">
          <p>
            Name:
            <span class="bold">{{ user.firstName }} {{ user.lastName }}</span>
          </p>
          <p>
            Email: <span class="bold">{{ user.email }}</span>
          </p>
        </div>

        <div class="toggle">
          <Toggle :checked="isUserInTeam(user)" @toggle="toggleUser(user)" />
        </div>
      </li>
    </ul>

    <!-- Calendar Config -->
    <section class="calendarConfig">
      <h2>Calendar Config</h2>

      <BaseInput
        label="Calendar Name"
        :value="newTeam.calendarConfig.calendarName"
        @input="newTeam.calendarConfig.calendarName = $event"
      />

      <BaseTextarea
        label="Description"
        :value="newTeam.calendarConfig.description"
        @input="newTeam.calendarConfig.description = $event"
      />

      <BaseInput
        label="Slug"
        :value="newTeam.calendarConfig.slug"
        @input="newTeam.calendarConfig.slug = $event"
      />

      <div class="toggle">
        <Toggle
          :checked="newTeam.calendarConfig.shouldAssignContactToTeamMember"
          @toggle="
            newTeam.calendarConfig.shouldAssignContactToTeamMember =
              !newTeam.calendarConfig.shouldAssignContactToTeamMember
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Should assign contact to team member</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="
            newTeam.calendarConfig.shouldSkipAssigningContactForExisting
          "
          @toggle="
            newTeam.calendarConfig.shouldSkipAssigningContactForExisting =
              !newTeam.calendarConfig.shouldSkipAssigningContactForExisting
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">
            Should skip assigning contact for existing
          </p>
        </div>
      </div>
    </section>

    <div class="form_actions">
      <BaseButton
        v-if="newTeam.id"
        @click="showConfirmDelete = true"
        type="button"
        mode="danger"
        :disabled="loading"
        ><i v-if="loading" class="fa fa-spinner"></i> Delete</BaseButton
      >
      <BaseButton :disabled="loading"
        ><i v-if="loading" class="fa fa-spinner"></i> Submit</BaseButton
      >
    </div>
  </form>

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Delete team"
    text="Are you sure you wish to delete this team? This action can not be undone"
    @confirm="deleteTeam"
    @deny="showConfirmDelete = false"
  />
</template>

<script>
export default {
  emits: ['submit', 'removeTeam'],
  props: {
    team: {
      type: Object,
      required: false,
    },
  },
  created() {
    this.init();

    if (this.team) {
      this.newTeam = JSON.parse(JSON.stringify(this.team));

      this.newTeam.userIds = this.newTeam.members.map((u) => u.id);
    }
  },
  data() {
    return {
      loading: false,
      showConfirmDelete: false,

      users: [],
      showTeamUsers: false,

      newTeam: {
        name: '',
        userIds: [],
        calendarConfig: {
          calendarName: '',
          description: '',
          slug: '',
          shouldAssignContactToTeamMember: false,
          shouldSkipAssigningContactForExisting: false,
        },
      },
    };
  },
  methods: {
    async deleteTeam() {
      this.loading = true;

      try {
        await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/highLevel/teams/${this.newTeam.id}?salonId=${this.$store.state.auth.salon._id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$emit('removeTeam');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    async init() {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/highLevel/users?salonId=${this.$store.state.auth.salon._id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.users = response.data.users;
      } catch (err) {
        console.log(err);
      }
    },

    isUserInTeam(user) {
      return this.newTeam.userIds.includes(user.id);
    },

    toggleUser(user) {
      if (this.isUserInTeam(user)) {
        this.newTeam.userIds = this.newTeam.userIds.filter(
          (id) => id !== user.id
        );
        this.$emit('removeUser', user);
      } else {
        this.newTeam.userIds.push(user.id);
      }
    },

    async submit() {
      this.loading = true;

      try {
        if (this.newTeam.id) {
          // Update
          const response = await this.$axios.put(
            `${process.env.VUE_APP_RASERVA_BACKEND}/highLevel/teams/${this.team.id}?salonId=${this.$store.state.auth.salon._id}`,
            this.newTeam,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );

          this.$emit('submit', response.data.team);
        } else {
          // Create
          const response = await this.$axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/highLevel/teams?salonId=${this.$store.state.auth.salon._id}`,
            this.newTeam,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );

          this.$emit('submit', response.data.team);
        }
      } catch (err) {
        console.log(err);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }

  .toggle {
    display: flex;
    align-items: center;
    gap: 32px;
    flex-wrap: wrap;
    padding: 16px 0;

    &_text {
      &_label {
        font-size: 18px;
      }
      &_subtext {
        margin-top: 5px;
        font-size: 14px;
      }
    }
  }

  .link {
    text-align: right;
    margin: 16px 0;
    color: var(--clr-link);
    cursor: pointer;
  }

  .users {
    margin-bottom: 16px;
    width: 100%;

    li {
      width: 100%;
      padding: 16px;
      border-radius: 5px;
      background-color: var(--clr-white);
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;

      &:not(:first-child) {
        margin-top: 16px;
      }

      .bold {
        font-weight: 700;
      }
    }
  }

  .calendarConfig {
    padding: 32px;
    border-radius: 5px;
    border: 1px solid var(--clr-light);
    display: flex;
    flex-direction: column;
    gap: 16px;

    h2 {
      margin-bottom: 16px;
    }
  }
}
</style>
