<template>
  <section class="discounts">
    <div class="discounts_head">
      <h2>Discounts</h2>
    </div>
    <div class="discounts_actions">
      <div class="col"></div>
      <div class="col">
        <BaseButton @click="showDiscountForm = true"
          >Create Discount</BaseButton
        >
      </div>
    </div>

    <table class="discounts_list">
      <thead>
        <tr>
          <th>Title</th>
          <th>Created At</th>
          <th>Value</th>
        </tr>
      </thead>

      <tbody>
        <tr
          @click="selectedDiscount = discount"
          v-for="discount in discounts"
          :key="discount._id"
        >
          <td>{{ discount.title }}</td>
          <td>{{ formatDate(discount.created_at) }}</td>
          <td class="col">
            <p>
              <span v-if="discount.type === 'fixed'">$</span>{{ discount.amount
              }}<span v-if="discount.type === 'percent'">%</span>
              Off
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </section>

  <Modal
    title="Discount form"
    :show="showDiscountForm || selectedDiscount"
    @close="
      showDiscountForm = false;
      selectedDiscount = null;
    "
  >
    <div class="discountForm">
      <DiscountForm
        :discount="selectedDiscount"
        @submitted="
          showDiscountForm = false;
          selectedDiscount = null;
        "
      />
    </div>
  </Modal>
</template>

<script>
import DiscountForm from '@/components/discounts/DiscountForm.vue';

export default {
  components: {
    DiscountForm,
  },
  data() {
    return {
      showDiscountForm: false,
      selectedDiscount: null,
    };
  },
  computed: {
    discounts() {
      return this.$store.state.items.discounts;
    },
  },
  methods: {
    formatDate(date) {
      return this.$moment(date).format('LL');
    },
  },
};
</script>

<style lang="scss" scoped>
.discounts {
  width: 100%;
  max-width: 800px;

  &_head {
    h2 {
      font-size: 28px;
    }
  }

  &_actions {
    margin-top: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-extra-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
  }
}

table {
  margin-top: 32px;
}

.discountForm {
  padding: 32px;
}
</style>
