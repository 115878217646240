<template>
  <section class="select">
    <div class="select_search">
      <BaseInput
        placeholder="Search any item"
        icon="fas fa-search"
        @input="filterItems($event)"
      />
    </div>
    <div class="select_list">
      <!-- Default -->
      <ul v-if="displayedList === 'default'">
        <li @click="displayedList = 'products'">
          <div class="col title">
            <p>Products</p>
          </div>
          <i class="fas fa-angle-right"></i>
        </li>
        <li @click="displayedList = 'services'">
          <div class="col title">
            <p>Services</p>
          </div>
          <i class="fas fa-angle-right"></i>
        </li>
        <li @click="displayedList = 'custom'">
          <div class="col title">
            <p>Custom Item</p>
          </div>
          <i class="fas fa-angle-right"></i>
        </li>
        <li
          v-if="client && packages && packages.length"
          @click="displayedList = 'packages'"
        >
          <div class="col title">
            <p>Packages</p>
          </div>
          <i class="fas fa-angle-right"></i>
        </li>
        <li
          v-if="clientThisSalonsPackages.length"
          @click="displayedList = 'clientPackages'"
        >
          <div class="col title">
            <p>Clients Packages</p>
          </div>
          <i class="fas fa-angle-right"></i>
        </li>
        <li v-if="client && loyaltyEnabled" @click="displayedList = 'loyalty'">
          <div class="col title">
            <p>Loyalty</p>
          </div>
          <i class="fas fa-angle-right"></i>
        </li>
      </ul>

      <!-- Custom -->
      <ul v-if="displayedList === 'custom'">
        <li class="customItemForm">
          <CustomItemForm @add="addCustomItem($event)" />
        </li>
      </ul>

      <!-- Back -->
      <ul v-if="displayedList !== 'default'">
        <li class="back" @click="displayedList = 'default'">
          <i class="fas fa-angle-left"></i>
          <p>Go Back</p>
        </li>
      </ul>

      <!-- Products -->
      <ul v-if="displayedList === 'products'">
        <li
          v-for="category in productCategories"
          :key="category._id"
          @click="selectCategory(category._id)"
        >
          <div class="col title">
            <p>{{ category.title }}</p>
            <p class="subtitle">{{ category.items.length }} items</p>
          </div>
          <div class="col">
            <i class="fas fa-angle-right"></i>
          </div>
        </li>
      </ul>

      <!-- Services -->
      <ul v-if="displayedList === 'services'">
        <li
          v-for="category in serviceCategories"
          :key="category._id"
          @click="selectCategory(category._id)"
        >
          <div class="col title">
            <p>{{ category.title }}</p>
            <p class="subtitle">{{ category.items.length }} items</p>
          </div>
          <div class="col">
            <i class="fas fa-angle-right"></i>
          </div>
        </li>
      </ul>

      <!-- Items -->
      <ul v-if="displayedList === 'items'">
        <div v-if="displayedItems.length" class="items">
          <li
            v-for="item in displayedItems"
            :key="item.title"
            :class="{ withLevels: item.hasPricingLevels }"
            @click="
              item.hasPricingLevels
                ? null
                : $emit('selectItem', {
                    ...item,
                    categoryId: selectedCategoryId,
                  })
            "
          >
            <div class="row">
              <div class="col title">
                <p>{{ item.title }}</p>
                <p class="subtitle">{{ itemSubtitle(item) }}</p>
              </div>
              <div v-if="!item.hasPricingLevels" class="col">
                <p class="title">${{ item.price }}</p>
              </div>
            </div>

            <!-- Levels -->
            <div v-if="item.hasPricingLevels" class="levels row">
              <div
                v-for="(level, index) in item.levels"
                :key="level._id"
                class="levels_level"
                @click="selectPriceLevel(item, index)"
              >
                <p>Level {{ index + 1 }}: ${{ level.price.toFixed(2) }}</p>
              </div>
            </div>
          </li>
        </div>
        <div v-else class="empty">
          <li>
            <div class="col title">
              <p>No results found..</p>
              <p class="subtitle">Try another search</p>
            </div>
            <div class="col">
              <i class="fas fa-search-minus"></i>
            </div>
          </li>
        </div>
      </ul>

      <!-- Packages -->
      <ul v-if="displayedList === 'packages'">
        <li v-for="pack in packages" :key="pack._id" @click="addPackage(pack)">
          <div class="col title">
            <p>{{ pack.title }}</p>
            <p class="subtitle">{{ pack.description }}</p>
          </div>
          <div class="col">
            <p class="title">${{ pack.price }}</p>
          </div>
        </li>
      </ul>

      <!-- Client Packages -->
      <ul v-if="displayedList === 'clientPackages'">
        <div
          class="package"
          v-for="pack in clientThisSalonsPackages"
          :key="pack._id"
        >
          <li
            v-for="item in pack.items"
            :key="item._id"
            @click="selectPackageItem(item, pack)"
          >
            <div class="col title">
              <p>{{ item.item.title }}</p>
              <p class="subtitle">
                Started: {{ item.startingQuantity }} | Remaining:
                {{ item.quantity }}
              </p>
            </div>
            <div class="col">
              <p class="title">${{ item.item.price }}</p>
            </div>
          </li>
        </div>
      </ul>

      <!-- Loyalty -->
      <ul v-if="displayedList === 'loyalty'">
        <li
          v-for="redemption in redemptions"
          :key="redemption._id"
          :class="{
            disabled: !clientLoyalty || clientLoyalty.points < redemption.cost,
          }"
          @click="
            clientLoyalty.points < redemption.cost
              ? null
              : redeemLoyaltyItem(redemption)
          "
        >
          <div class="col title">
            <p>{{ redemption.item.title }}</p>
            <p v-if="clientLoyalty" class="subtitle">
              Client has {{ clientLoyalty.points }} points
            </p>
          </div>
          <div class="col">
            <p class="title">{{ redemption.cost }} Points</p>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import CustomItemForm from '@/components/tickets/CustomItemForm.vue';

export default {
  emits: ['selectItem', 'addPackage', 'addPackageItem'],
  props: {
    client: {
      type: Object,
      required: false,
    },
    page: {
      type: String,
      required: false,
    },
  },
  components: {
    CustomItemForm,
  },
  mounted() {
    if (this.page) this.displayedList = this.page;

    if (this.client && this.client.loyalty) {
      this.clientLoyalty = this.client.loyalty.find(
        (l) =>
          l.salonId.toString() === this.$store.state.auth.salon._id.toString()
      );
    }
  },
  data() {
    return {
      displayedList: 'default',
      displayedItems: [],
      selectedCategoryId: '',

      clientLoyalty: null,
    };
  },
  computed: {
    loyaltyEnabled() {
      return this.$store.state.auth.salon.loyalty.enabled;
    },
    redemptions() {
      return this.$store.state.auth.salon.loyalty.redemptions;
    },
    serviceCategories() {
      return JSON.parse(JSON.stringify(this.$store.state.items.services));
    },
    productCategories() {
      return JSON.parse(JSON.stringify(this.$store.state.items.products));
    },
    packages() {
      if (!this.$store.state.auth.salon) return;

      return JSON.parse(JSON.stringify(this.$store.state.auth.salon.packages));
    },
    clientThisSalonsPackages() {
      let arr = [];

      if (this.client && this.client.packages) {
        this.client.packages.forEach((pack) => {
          if (pack.salon.salonId === this.$store.state.auth.salon._id)
            arr.push(pack);
        });
      }

      return arr;
    },
  },
  methods: {
    selectCategory(id) {
      this.displayedList = 'items';
      this.selectedCategoryId = id;

      const productCategoryIndex = this.productCategories.findIndex(
        (category) => category._id === id
      );

      if (productCategoryIndex !== -1) {
        this.displayedItems =
          this.productCategories[productCategoryIndex].items;
        return;
      }

      const serviceCategoryIndex = this.serviceCategories.findIndex(
        (category) => category._id === id
      );

      if (serviceCategoryIndex !== -1) {
        this.displayedItems =
          this.serviceCategories[serviceCategoryIndex].items;
        return;
      }
    },
    itemSubtitle(item) {
      if (item.duration) {
        return `${item.duration} mins`;
      }

      return `${item.stock} in stock`;
    },
    filterItems(filter) {
      if (!filter) {
        this.displayedList = 'default';
        return;
      }

      const filteredItems = [];

      // Filter services
      this.serviceCategories.forEach((category) => {
        category.items.forEach((item) => {
          if (item.title.toLowerCase().includes(filter.toLowerCase())) {
            filteredItems.push(item);
          }
        });
      });
      // Filter products
      this.productCategories.forEach((category) => {
        category.items.forEach((item) => {
          if (item.title.toLowerCase().includes(filter.toLowerCase())) {
            filteredItems.push(item);
          }
        });
      });

      this.displayedItems = JSON.parse(JSON.stringify(filteredItems));
      this.displayedList = 'items';
    },
    addCustomItem(item) {
      this.$emit('selectItem', item);
    },
    selectPriceLevel(item, levelIndex) {
      this.$emit('selectItem', {
        ...item,
        price: item.levels[levelIndex].price,
        commissionType: item.levels[levelIndex].commissionType,
        commissionAmount: item.levels[levelIndex].commissionAmount,
      });
    },
    redeemLoyaltyItem(redemption) {
      this.$emit('selectItem', {
        ...redemption.item,
        prepaid: true,
        isLoyaltyRedemption: true,
        loyaltyRedemptionCost: redemption.cost,
      });
    },
    addPackage(pack) {
      const packageId = Math.random().toString().split('.')[1];

      pack.items.forEach((item) => (item.startingQuantity = item.quantity));

      this.$emit('addPackage', { ...pack, packageId });

      this.$emit('selectItem', {
        title: pack.title,
        isCommissioned: false,
        duration: null,
        stock: 1,
        commissionType: 'percent',
        commissionAmount: 0,
        priceType: 'fixed',
        price: pack.price,
        taxRate: 0,
        fees: {
          supply: 0,
          other: 0,
        },
        packageId,
        useThisStaff: 'nostaff',
      });
    },
    selectPackageItem(item, pack) {
      if (item.quantity <= 0) {
        this.$toast.error('Package quantity has been used up');
        return;
      }

      this.$emit('addPackageItem', {
        clientPackageId: pack.packageId || pack._id,
        packageItemId: item._id,
      });

      let customItemPriceForCommission;

      if (item.useItemCustomCommission) {
        customItemPriceForCommission = item.itemCustomCommission;
      }

      this.$emit('selectItem', {
        ...item.item,
        prepaid: true,
        clientPackageId: pack._id,
        packageItemId: item._id,
        customItemPriceForCommission,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  &_search {
    padding: 32px;
    background-color: var(--clr-extra-light);
    border-bottom: 1px solid var(--clr-light);
  }

  &_list {
    ul {
      .back {
        color: var(--clr-danger);
      }

      .customItemForm,
      .form {
        display: block;

        .parent:not(:first-child) {
          margin-top: 16px;
        }

        .toggle {
          display: flex;
          align-items: center;
          gap: 32px;
          flex-wrap: wrap;
          padding: 16px 0;

          &_text {
            &_label {
              font-size: 18px;
            }
            &_subtext {
              margin-top: 5px;
              font-size: 14px;
            }
          }
        }

        &_actions {
          margin-top: 16px;
          display: flex;
          justify-content: flex-end;
          gap: 16px;
        }
      }

      .withLevels {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .levels {
          // display: flex;
          // justify-content: space-between;
          // align-items: center;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 16px;
          margin-top: 16px;
          color: var(--clr-white);

          &_level {
            text-align: center;
            background-color: var(--clr-secondary);
            padding: 8px;
            width: 100%;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 0.7px;
          }
        }
      }

      .disabled {
        background-color: var(--clr-white-2);
        color: var(--clr-gray) !important;
        cursor: not-allowed;
      }

      li {
        padding: 24px 32px;
        border-bottom: 1px solid var(--clr-light);
        transition: background-color 0.2s;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .row {
          flex-grow: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &:hover {
          background-color: var(--clr-extra-light);
        }

        .title {
          font-weight: 700;
          font-size: 17px;
        }

        .subtitle {
          font-weight: 400;
          font-size: 14px;
          color: var(--clr-gray);
          margin-top: 5px;
        }
      }
    }
  }
}
</style>
