<template>
  <div class="parent" :class="{ small: small }">
    <div class="label" v-if="label">
      <label>{{ label }}</label>
    </div>
    <div class="input">
      <select v-bind="$attrs" :value="value" @input="updateValue" ref="input">
        <option v-if="placeholder" value="" disabled selected>
          {{ placeholder }}
        </option>
        <option
          v-for="option in options"
          :key="option"
          :selected="option === value"
          :value="option.value"
        >
          {{ option.option }}
        </option>
      </select>
      <i v-if="icon" :class="icon"></i>
    </div>
    <p
      v-if="subtitle"
      @click="$emit('subtitleClick')"
      class="subtitle"
      :class="{ link: subtitleLink }"
    >
      {{ subtitle }}
    </p>
  </div>
</template>

<script>
export default {
  emits: ['input', 'subtitleClick'],
  mounted() {
    this.$refs.input.style.fontSize = this.inputFontSize;
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    value: [String, Number],
    placeholder: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
    },
    subtitleLink: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    inputFontSize: {
      type: String,
      default: '16px',
    },
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value);
    },
  },
  watch: {
    inputFontSize(val) {
      this.$refs.input.style.fontSize = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  margin-bottom: 5px;
  color: var(--clr-gray-2);
  font-size: 14px;
  font-weight: 700;
}

.small {
  select {
    font-size: 14px;
  }
}

.link {
  cursor: pointer;
  color: var(--clr-link) !important;
}

.input {
  display: flex;
  align-items: center;
  line-height: 24px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid var(--clr-light);
  padding-right: 16px;
  transition: border-color 0.2s;
  overflow: hidden;

  select {
    background-color: white !important;
  }

  &:hover {
    border-color: var(--clr-gray);
  }
}

.subtitle {
  margin-top: 5px;
  font-size: 13px;
  color: var(--clr-gray);
}

select {
  padding: 10px 16px;
  height: 100%;
  width: 100%;
  border: none;
  font-size: 16px;
  font-family: inherit;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: var(--clr-light) !important;
  }
}

i {
  font-size: 14px;
  cursor: pointer;
}
</style>
