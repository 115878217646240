<template>
  <section class="members">
    <div class="members_head">
      <h2>Team members</h2>
    </div>
    <div class="members_actions">
      <div class="col">
        <BaseInput
          placeholder="Search by name or title"
          icon="fas fa-search"
          @input="filterStaff($event)"
        />
      </div>
      <div class="col buttons">
        <BaseButton
          v-if="deletedMembers.length"
          mode="danger-outline"
          @click="showDeletedMembers = !showDeletedMembers"
          >{{ showDeletedMembers ? 'Hide' : 'Show' }} Deleted</BaseButton
        >
        <BaseButton
          @click="
            selectedStaff = null;
            showMemberForm = true;
          "
          >Add Member</BaseButton
        >
        <BaseButton @click="showInviteForm = true">Invite</BaseButton>
      </div>
    </div>
    <div class="members_list">
      <StaffList
        :staff="staff"
        @select="selectStaff($event)"
        @changeOrder="changeOrder($event)"
        @showDeleted="showDeletedMembers = true"
      />
    </div>
  </section>

  <Modal
    title="Member form"
    fullScreen
    :show="showMemberForm"
    @close="$refs.memberForm.checkForUnsavedChanges()"
  >
    <div class="memberForm">
      <MemberForm
        :member="selectedStaff"
        @submitted="formSubmitted($event)"
        @deleted="deleteMember($event)"
        @exit="showMemberForm = false"
        ref="memberForm"
      />
    </div>
  </Modal>

  <Modal
    title="Invite Members"
    :show="showInviteForm"
    @close="showInviteForm = false"
  >
    <div class="inviteForm">
      <StaffInviteForm @sent="showInviteForm = false" />
    </div>
  </Modal>
</template>

<script>
import StaffList from '@/components/staff/StaffList.vue';
import StaffInviteForm from '@/components/staff/StaffInviteForm.vue';
import MemberForm from '@/views/team/MemberForm.vue';

export default {
  components: {
    StaffList,
    StaffInviteForm,
    MemberForm,
  },
  created() {
    this.init();
  },
  watch: {
    loggedInStaff() {
      this.init();
    },
    showDeletedMembers() {
      this.filterStaff('');
    },
  },
  computed: {
    loggedInStaff() {
      return this.$store.state.auth.loggedInSalonStaff;
    },
    deletedMembers() {
      const deletedMembers = [];

      this.allStaff.forEach((staff) =>
        staff.isDeleted ? deletedMembers.push(staff) : null
      );

      return deletedMembers;
    },
  },
  data() {
    return {
      allStaff: [],
      staff: [],
      selectedStaff: null,

      showMemberForm: false,
      showInviteForm: false,

      showDeletedMembers: false,
    };
  },
  methods: {
    init() {
      this.staff = this.$store.state.staff.staff;
      this.allStaff = JSON.parse(JSON.stringify(this.$store.state.staff.staff));
      this.filterStaff('');
    },
    selectStaff(_id) {
      this.selectedStaff = this.staff.find((staff) => staff._id === _id);
      this.showMemberForm = true;
    },
    filterStaff(search) {
      let staff = [];

      staff = this.allStaff.filter((staff) => {
        return `${staff.firstName} ${staff.lastName}`
          .toLowerCase()
          .includes(search.toLowerCase());
      });

      if (!this.showDeletedMembers) {
        staff = staff.filter((staff) => !staff.isDeleted);
      }

      this.staff = staff;
    },
    formSubmitted(staff) {
      this.showMemberForm = false;

      if (staff) {
        const index = this.allStaff.findIndex(
          (prestaff) => prestaff.staffId === staff.staffId
        );

        if (index !== -1) this.allStaff[index] = staff;

        this.init();
      }
    },
    async changeOrder(data) {
      if (this.staff.length !== this.allStaff.length) {
        this.$toast.error('Can not edit order when staff is filtered');
        return;
      }

      if (data.from < data.to) data.to--;

      const element = this.allStaff.splice(data.from, 1)[0];

      this.allStaff.splice(data.to, 0, element);

      this.staff = JSON.parse(JSON.stringify(this.allStaff));

      try {
        const response = await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/staff/order`,
          this.allStaff,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$store.state.auth.salon.staff = response.data.staff;
        this.$store.state.salon.salon.staff = response.data.staff;
        this.$store.state.staff.staff = response.data.staff;
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    deleteMember(id) {
      const index = this.allStaff.findIndex(
        (staff) => staff._id.toString() === id
      );

      if (index !== -1) this.allStaff.splice(index, 1);
      this.staff = JSON.parse(JSON.stringify(this.allStaff));

      this.showDeletedMembers = false;
      this.staff = this.staff.filter((staff) => !staff.isDeleted);

      this.showMemberForm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.members {
  width: 100%;
  max-width: 800px;

  &_head {
    h2 {
      font-size: 28px;
    }
  }

  &_actions {
    margin-top: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-extra-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    .buttons {
      display: flex;
      gap: 16px;
    }
  }

  &_list {
    margin-top: 32px;
  }
}

.memberForm {
  width: 1000px;
  max-width: 100%;
  margin: 32px auto;
}

.inviteForm {
  padding: 32px;
}

// Tablet
@media (max-width: 900px) {
  .members {
    &_actions {
      flex-direction: column;
      gap: 16px;

      .col {
        width: 100%;
      }

      .buttons {
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }
}
</style>
