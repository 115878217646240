<template>
  <section class="recovery">
    <div class="recovery_head">
      <h2>Recover your account</h2>
      <p>Enter the information below to recover your account</p>
    </div>
    <div class="recovery_body">
      <form @submit.prevent="submit" class="form">
        <BaseInput
          label="Email"
          :value="email"
          @input="email = $event"
          :disabled="step > 1"
        />
        <BaseInput
          v-if="step > 1"
          label="Recovery Code"
          :value="recoveryCode"
          @input="recoveryCode = $event"
        />
        <BaseInput
          v-if="step > 1"
          inputType="password"
          label="New Password"
          :value="newPassword"
          @input="newPassword = $event"
        />
        <BaseInput
          v-if="step > 1"
          inputType="password"
          label="Confirm Password"
          :value="confirmPassword"
          @input="confirmPassword = $event"
        />
        <div class="form_actions">
          <BaseButton :disabled="loading"
            ><i v-if="loading" class="fas fa-spinner"></i> Submit</BaseButton
          >
        </div>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  emits: ['reset'],
  props: {
    accountType: {
      type: String,
      default: 'user',
    },
  },
  data() {
    return {
      loading: false,
      step: 1,

      email: '',
      recoveryCode: '',
      newPassword: '',
      confirmPassword: '',
    };
  },
  methods: {
    async submit() {
      try {
        if (this.step === 1) {
          await this.$axios.get(
            `${process.env.VUE_APP_RASERVA_BACKEND}/auth/${
              this.accountType === 'user' ? 'staff' : 'client'
            }/recover?email=${this.email}`
          );

          this.$toast.success('Recovery code sent to email');
          this.step++;
          return;
        }

        if (this.newPassword !== this.confirmPassword) {
          this.$toast.error('Passwords do not match');
          return;
        }

        if (this.newPassword.length < 6) {
          this.$toast.error('Password must be at least 6 characters long');
          return;
        }

        await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/auth/${
            this.accountType === 'user' ? 'staff' : 'client'
          }/recover?email=${this.email}`,
          {
            email: this.email,
            recoveryCode: this.recoveryCode,
            password: this.newPassword,
          }
        );

        this.$toast.success('Password reset successfully');
        this.$emit('reset');
      } catch (error) {
        this.$toast.error(error.response.data.error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.recovery {
  &_head {
    p {
      margin-top: 8px;
    }
  }
  &_body {
    margin-top: 32px;

    .form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &_actions {
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        flex-wrap: wrap;
      }
    }
  }
}
</style>
