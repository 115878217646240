<template>
  <section class="endpoints">
    <div class="endpoints_router">
      <p class="bold">On this page:</p>

      <ul>
        <li class="link" @click="scrollIntoView('create')">Create client</li>
        <li class="link" @click="scrollIntoView('get')">Get client</li>
        <li class="link" @click="scrollIntoView('getAll')">
          Get salons client
        </li>
        <li class="link" @click="scrollIntoView('update')">Update client</li>
        <li class="link" @click="scrollIntoView('delete')">Delete client</li>
      </ul>
    </div>

    <!-- client create -->
    <div class="endpoint" id="create" ref="create">
      <DocsAPIClientsCreate />
    </div>

    <!-- client get -->
    <div class="endpoint" id="get" ref="get">
      <DocsAPIClientsGet />
    </div>

    <!-- client get all -->
    <div class="endpoint" id="getAll" ref="getAll">
      <DocsAPIClientsGetAll />
    </div>

    <!-- client update -->
    <div class="endpoint" id="update" ref="update">
      <DocsAPIClientsUpdate />
    </div>

    <!-- client delete -->
    <div class="endpoint" id="delete" ref="delete">
      <DocsAPIClientsDelete />
    </div>
  </section>
</template>

<script>
import DocsAPIClientsCreate from './DocsAPIClientsCreate.vue';
import DocsAPIClientsGet from './DocsAPIClientsGet.vue';
import DocsAPIClientsGetAll from './DocsAPIClientsGetAll.vue';
import DocsAPIClientsUpdate from './DocsAPIClientsUpdate.vue';
import DocsAPIClientsDelete from './DocsAPIClientsDelete.vue';

export default {
  components: {
    DocsAPIClientsCreate,
    DocsAPIClientsGet,
    DocsAPIClientsGetAll,
    DocsAPIClientsUpdate,
    DocsAPIClientsDelete,
  },
  methods: {
    scrollIntoView(element) {
      this.$refs[element].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoints {
  &_router {
    margin-bottom: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-white-2);

    ul {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 32px;

      .link {
        cursor: pointer;
        color: var(--clr-link);
      }
    }
  }

  .endpoint {
    &:not(:first-child) {
      margin-top: 64px;
    }
  }
}
</style>
