<template>
  <div class="avatar">
    <div class="avatar_image" :class="mode">
      <img v-if="image" :src="image" :alt="title" />
      <p v-else>{{ nameChar }}</p>
    </div>
    <p v-if="showName" class="name">
      {{ name }}
      <span class="extraText" v-if="extraText">{{ extraText }}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: 'rounded',
    },
    image: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    showName: {
      type: Boolean,
      default: false,
    },
    extraText: {
      type: String,
      required: false,
    },
  },
  computed: {
    nameChar() {
      if (!this.name) return;

      return this.name[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  display: flex;
  flex-direction: column;
  align-items: center;

  &_image {
    height: 42px;
    width: 42px;
    background-color: #ee82ee;
    display: grid;
    place-items: center;
    color: #833683;
    font-weight: 700;
    font-size: 20px;
    cursor: pointer;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transform: translate3d(0, 0, 1px);
    }
  }

  .extraText {
    color: var(--clr-gray);
  }

  .name {
    text-align: center;
    font-size: 12px;
    margin-top: 5px;
  }
}

.large {
  height: 64px;
  width: 64px;
}
.extra-large {
  height: 120px;
  width: 120px;

  p {
    font-size: 36px;
  }
}

.rounded {
  border-radius: 50%;
}

.border {
  border: 5px solid transparent;

  &-success {
    border-color: var(--clr-success);
  }
  &-danger {
    border-color: var(--clr-danger);
  }
}
</style>
