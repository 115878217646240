<template>
  <section class="home">
    <section class="col calendar">
      <div class="calendar_actions">
        <div class="calendar_actions_filter-sort">
          <DateSelection
            :propSelectedDate="selectedDate.toDate()"
            :propSelectedDates="selectedDates"
            :propSelectedTimeOption="selectedTimeOption"
            :showWeeksOut="true || rescheduleMode"
            @selectDate="setSelectedDate($event)"
            @selectToday="selectToday"
            @setSelectedDate="selectedDate = $event"
            @setSelectedDates="selectedDates = $event"
            @updateCalendar="updateCalendar"
            showDayOfWeek
          />
          <div class="scale">
            <div
              class="scale-action"
              @click="scale < 3 ? (scale += 0.5) : null"
              :class="{ disabled: scale >= 3 }"
            >
              <i class="fas fa-search-plus"></i>
            </div>
            <div
              class="scale-action"
              @click="scale > 0.5 ? (scale -= 0.5) : null"
              :class="{ disabled: scale <= 0.5 }"
            >
              <i class="fas fa-search-minus"></i>
            </div>
          </div>
        </div>
        <div class="calendar_actions_dates">
          <div
            v-if="selectedAppFilterOption !== 'All' && !showMoreFilterOptions"
            class="alert alert-warning"
          >
            <p>
              Appointments filtered:
              <span class="bold">{{ selectedAppFilterOption }}</span>
            </p>
          </div>
          <i
            @click="showMoreFilterOptions = true"
            v-if="!showMoreFilterOptions"
            class="fas fa-expand-alt resize"
          ></i>
          <i
            @click="showMoreFilterOptions = false"
            v-if="showMoreFilterOptions"
            class="fas fa-compress-alt resize"
          ></i>
          <BaseSelect
            small
            :options="filterOptions"
            :value="selectedFilterOption"
            @input="selectedFilterOption = $event"
          />
          <BaseSelect
            v-if="showMoreFilterOptions"
            small
            :options="filterSecondaryOptions"
            :value="selectedFilterSecondaryOption"
            @input="selectFilterSecondaryOption($event)"
          />
          <BaseSelect
            v-if="showMoreFilterOptions"
            small
            :options="sortOptions"
            :value="selectedSortOption"
            @input="selectSortOption($event)"
          />
          <BaseSelect
            v-if="showMoreFilterOptions"
            small
            :options="appFilterOptions"
            :value="selectedAppFilterOption"
            @input="selectAppFilterOption($event)"
          />
          <BaseSelect
            v-if="showMoreFilterOptions"
            small
            :options="calendarTimeOptions"
            :value="selectedTimeOption"
            @input="selectTimeOption($event)"
          />
          <ServiceSelect
            v-if="showMoreFilterOptions"
            :label="false"
            @select="selectService($event)"
          />
          <div class="calendar_actions_options">
            <div class="add_button">
              <BaseButton
                mode="primary-outline"
                @click="showMoreOptionsDropdown = !showMoreOptionsDropdown"
                >Options<i class="fas fa-angle-down"></i>
              </BaseButton>

              <Tooltip
                v-if="showMoreOptionsDropdown"
                mode="bottom left"
                @hide="showMoreOptionsDropdown = false"
              >
                <ul class="addDropdown">
                  <li
                    @click="
                      filterWithEvents = !filterWithEvents;
                      showMoreOptionsDropdown = false;
                    "
                  >
                    <input
                      type="checkbox"
                      name="hasEvents"
                      id="hasEvents"
                      :checked="filterWithEvents"
                    />
                    <label for="hasEvents">Filter with events</label>
                  </li>
                  <li
                    @click="
                      filterCancellationRequests = !filterCancellationRequests;
                      showMoreOptionsDropdown = false;
                    "
                  >
                    <input
                      type="checkbox"
                      name="filterCancellationRequests"
                      id="filterCancellationRequests"
                      :checked="filterCancellationRequests"
                    />
                    <label for="filterCancellationRequests"
                      >Hide Requested Cancellations</label
                    >
                  </li>
                  <!-- <li
                      @click="
                        filterIsScheduled = !filterIsScheduled;
                        showMoreOptionsDropdown = false;
                      "
                    >
                      <input
                        type="checkbox"
                        name="isScheduled"
                        id="isScheduled"
                        :checked="filterIsScheduled"
                      />
                      <label for="isScheduled">Filter is scheduled</label>
                    </li> -->
                  <li
                    @click="
                      showIsolationModal = true;
                      showMoreOptionsDropdown = false;
                    "
                  >
                    <p>Filter Schedules By Staffs</p>
                  </li>
                  <li
                    @click="
                      showRotationModal = true;
                      showMoreOptionsDropdown = false;
                    "
                  >
                    <p>View Rotation</p>
                  </li>
                  <li
                    @click="
                      showWorkingModal = true;
                      showMoreOptionsDropdown = false;
                    "
                  >
                    <p>View Working</p>
                  </li>
                  <li
                    @click="
                      showSearchFutureAppsModal = true;
                      showMoreOptionsDropdown = false;
                    "
                  >
                    <p>Search Future Appointments</p>
                  </li>
                  <li
                    @click="
                      showMoreFilterOptions = !showMoreFilterOptions;
                      showMoreOptionsDropdown = false;
                    "
                  >
                    <p>
                      Show
                      {{ showMoreFilterOptions ? 'Less' : 'More' }} Filter
                      Options
                    </p>
                  </li>
                </ul>
              </Tooltip>
            </div>

            <div class="add_button">
              <BaseButton @click="showAddDropdown = !showAddDropdown"
                >Create<i class="fas fa-angle-down"></i>
              </BaseButton>

              <Tooltip
                v-if="showAddDropdown"
                mode="bottom right"
                @hide="showAddDropdown = false"
              >
                <ul class="addDropdown">
                  <li
                    v-if="canBookAppointment"
                    @click="
                      minAppModal = false;
                      selectedAppointment = null;
                      showAddAppointmentModal = true;
                      showAddDropdown = false;
                    "
                  >
                    New Appointment
                  </li>
                  <li
                    @click="
                      showAddBlockedTime = true;
                      showAddDropdown = false;
                    "
                  >
                    New Blocked Time
                  </li>
                  <li
                    @click="
                      minTicketModal = false;
                      selectedTicket = null;
                      showAddTicketModal = true;
                      showAddDropdown = false;
                    "
                  >
                    New Ticket
                  </li>
                </ul>
              </Tooltip>
            </div>

            <BaseButton
              v-if="staffListOverride.length"
              mode="danger-outline"
              @click="clearStaffListOverride"
              >Clear Staff Isolation
            </BaseButton>
          </div>
        </div>
      </div>
      <div class="calendar_calendar">
        <VuCalendar
          :propDateStart="getSelectedStartDateForCalendar"
          :propDateEnd="getSelectedEndDateForCalendar"
          :staffs="staffForCalendar"
          :staffListOverride="staffListOverride"
          :staffFilter="selectedFilterOption"
          :appointments="appointments"
          :filter="selectedAppFilterOption"
          :sort="selectedSortOption"
          :filterWithEvents="filterWithEvents"
          :filterCancellationRequests="filterCancellationRequests"
          :filterIsScheduled="filterIsScheduled"
          :canBookAppointment="canBookAppointment"
          :scale="scale"
          ref="VuCalendar"
          :style="{ display: staffForCalendar.length ? 'flex' : 'none' }"
          @selectAppointment="selectAppointment($event)"
          @selectTimeslot="selectTimeslot($event)"
          @selectStaff="viewStaffOptions($event)"
          @hoverApp="hoveredApp = $event"
          @unhoverApp="hoveredApp = null"
        />
        <div class="calendar_calendar_none" v-if="!staffForCalendar.length">
          <div class="calendar_calendar_none_action">
            <h2>No staff to display!</h2>
            <p>Perhaps you need to clock in?</p>
            <BaseButton @click="toggleWorking">Clock In</BaseButton>
          </div>
        </div>
      </div>
    </section>
    <section class="col tickets">
      <CalendarTicketsSection
        @selectTicket="selectTicket($event)"
        @selectService="selectService($event)"
        @rebookAppointmentFromTicket="rebookAppointmentFromTicket($event)"
        ref="ticketsSection"
      />
    </section>
  </section>

  <!-- Minimizers -->
  <div
    class="minApp"
    @click="minAppModal = false"
    v-if="minAppModal && selectedAppointment"
  >
    <i class="fas fa-expand"></i>
  </div>
  <div
    class="minApp"
    @click="minTicketModal = false"
    v-if="minTicketModal && selectedTicket"
  >
    <i class="fas fa-expand"></i>
  </div>

  <Modal
    :title="
      selectedAppointment &&
      selectedAppointment.services[0] &&
      selectedAppointment.services[0].service
        ? 'Edit Appointment'
        : 'Create Appointment'
    "
    @close="closeAppointmentForm"
    :show="showAddAppointmentModal && !minAppModal"
    fullScreen
  >
    <template v-slot:header>
      <BaseInput
        :value="appTitle"
        @input="$refs.appModal.newAppointment.title = $event"
        placeholder="Title..."
      />
      <p
        @click="
          selectedAppointment = $refs.appModal.newAppointment;
          minAppModal = true;
        "
      >
        <i class="fas fa-minus"></i>
      </p>
    </template>

    <AppointmentForm
      ref="appModal"
      :selectedDate="
        selectedAppointment ? selectedAppointment.date : selectedDate.toDate()
      "
      :appointment="selectedAppointment"
      @created="appointmentUpdated($event, 'Created')"
      @edited="appointmentUpdated($event, 'Updated')"
      @deleted="appointmentUpdated($event, 'Deleted')"
      @checkout="
        showAddAppointmentModal = false;
        selectTicket($event);
      "
      @reschedule="
        selectedAppointment = $refs.appModal.newAppointment;
        minAppModal = true;
        rescheduleMode = true;
      "
    />
  </Modal>

  <Modal
    :title="selectedTicket ? 'Edit Ticket' : 'Create Ticket'"
    @close="closeTicketModal"
    v-if="showAddTicketModal"
    :show="showAddTicketModal && !minTicketModal"
    fullScreen
  >
    <template v-slot:header>
      <p
        @click="
          selectedTicket = $refs.ticketModal.newTicket;
          minTicketModal = true;
        "
      >
        <i class="fas fa-minus"></i>
      </p>
    </template>

    <TicketForm
      ref="ticketModal"
      :ticket="selectedTicket"
      :preSelectedStaff="selectedStaff"
      @created="ticketUpdated($event, 'Created')"
      @edited="ticketUpdated($event, 'Updated')"
      @checkedOut="ticketUpdated($event, 'Checked Out')"
      @deleted="ticketUpdated($event, 'Deleted')"
      @close="showAddTicketModal = false"
      @rebook="rebookAppointmentFromTicket($event)"
    />
  </Modal>

  <Modal
    title="New Blocked Time"
    @close="showAddBlockedTime = false"
    v-if="showAddBlockedTime"
    :show="showAddBlockedTime"
    maxWidth="600px"
  >
    <BlockedTimeForm
      :date="selectedDate"
      @submit="updateCalendar"
      @exit="showAddBlockedTime = false"
    />
  </Modal>

  <Modal
    title="Staff Options"
    @close="showCalendarStaffOptions = false"
    v-if="showCalendarStaffOptions && selectedStaff"
    :show="showCalendarStaffOptions"
    maxWidth="300px"
  >
    <StaffOptions
      :staff="selectedStaff"
      @showAddTicket="showAddTicketModal = true"
      @deselectStaff="selectedStaff = null"
      @selectFilterOption="selectFilterOption($event)"
      @updateCalendar="updateCalendar"
    />
  </Modal>

  <Modal
    title="Search Future Appointments"
    :show="showSearchFutureAppsModal"
    @close="showSearchFutureAppsModal = false"
  >
    <SearchFutureApps @select="selectFutureApp($event)" />
  </Modal>

  <Modal
    title="Rotation Order"
    @close="showRotationModal = false"
    :show="showRotationModal"
    maxWidth="500px"
  >
    <RotationOrder
      :staff="staffForCalendar"
      :filter="selectedFilterSecondaryOption"
    />
  </Modal>

  <Modal
    title="Isolate Staff Schedules"
    @close="showIsolationModal = false"
    :show="showIsolationModal"
  >
    <div class="isolationList">
      <IsolationSelection
        :staffs="staff"
        @selected="filterStaffsByStaffList($event)"
      />
    </div>
  </Modal>

  <Modal
    title="Selected Time-slot Options"
    @close="showCreateNewOrRescheduleModal = false"
    :show="showCreateNewOrRescheduleModal"
    maxWidth="400px"
  >
    <div class="createOrReschedule">
      <BaseButton
        @click="
          rescheduleMode = true;
          selectTimeslot(rescheduleData);
          showCreateNewOrRescheduleModal = false;
        "
        >Reschedule Selected Appointment</BaseButton
      >
      <BaseButton
        @click="
          selectTimeslot(rescheduleData, true);
          showCreateNewOrRescheduleModal = false;
        "
        mode="primary-outline"
        >New Appointment</BaseButton
      >
    </div>
  </Modal>

  <Modal
    title="Working Team"
    @close="showWorkingModal = false"
    :show="showWorkingModal"
  >
    <WorkingTeamMembers />
  </Modal>

  <Confirm
    v-if="showConfirmIgnoreDeposit"
    delete
    title="Appointment Deposit"
    :text="appDepositText"
    @confirm="
      showConfirmIgnoreDeposit = false;
      showAddAppointmentModal = false;
    "
    @deny="
      showConfirmIgnoreDeposit = false;
      showAddAppointmentModal = true;
    "
  />

  <SidePanel
    :show="hoveredApp && screenWidth > 768"
    @close="hoveredApp = null"
    hideBackdrop
    width="370px"
  >
    <EventDetails :event="hoveredApp" />
  </SidePanel>

  <Spinner fullscreen v-if="globalLoading"></Spinner>
</template>

<script>
import moment from 'moment-timezone';

import DateSelection from '@/components/components/DateSelection.vue';
import AppointmentForm from '@/views/dashboard/AppointmentForm.vue';
import TicketForm from '@/views/dashboard/TicketForm.vue';
import BlockedTimeForm from '@/components/appointments/BlockedTimeForm.vue';
import ServiceSelect from '@/components/items/ServiceSelect.vue';
import RotationOrder from '@/components/dashboard/RotationOrder.vue';
import WorkingTeamMembers from '@/components/dashboard/WorkingTeamMembers.vue';
import StaffOptions from '@/views/calendar/StaffOptions.vue';
import SearchFutureApps from '@/views/calendar/SearchFutureApps.vue';
import EventDetails from '@/components/appointments/EventDetails.vue';
import IsolationSelection from '@/components/dashboard/IsolationSelection.vue';

import VuCalendar from '@/components/VuEvents/VuCalendar.vue';
import CalendarTicketsSection from './CalendarTicketsSection.vue';

export default {
  components: {
    DateSelection,
    AppointmentForm,
    TicketForm,
    BlockedTimeForm,
    VuCalendar,
    ServiceSelect,
    RotationOrder,
    WorkingTeamMembers,
    StaffOptions,
    CalendarTicketsSection,
    EventDetails,
    SearchFutureApps,
    IsolationSelection,
  },
  async created() {
    this.init();

    if (this.socket) {
      this.socketInit();
    }

    this.getStorage();
  },
  data() {
    return {
      globalLoading: false,
      filterOptions: [],
      filterSecondaryOptions: [],

      minAppModal: false,
      minTicketModal: false,

      calendarTimeOptions: [
        { value: 'Day', option: 'Day' },
        { value: 'Week', option: 'Week' },
      ],
      sortOptions: [
        { value: 'Turns', option: 'Turns' },
        { value: 'Appointments', option: 'Appointments' },
        { value: 'Custom', option: 'Custom' },
        // { value: 'Groups', option: 'Groups' },
      ],
      appFilterOptions: [
        { value: 'All', option: 'All' },
        { value: 'New', option: 'New' },
        { value: 'Confirmed', option: 'Confirmed' },
        { value: 'Arrived', option: 'Arrived' },
      ],

      showMoreFilterOptions: false,
      showSearchFutureAppsModal: false,

      selectedTimeOption: 'Day',
      selectedFilterOption: 'Working Staff',
      selectedFilterSecondaryOption: 'All Staff',
      selectedSortOption: 'Groups',
      selectedAppFilterOption: 'All',

      staffListOverride: [],
      tickets: [],

      selectedDate: moment(),
      selectedDates: {
        start: moment().startOf('week'),
        end: moment().startOf('week').add(1, 'week').subtract(1, 'day'),
      },

      selectedAppointment: null,
      rescheduleMode: false,

      selectedTicket: null,
      selectedServiceId: null,

      selectedStaff: null,
      showCalendarStaffOptions: false,

      showAddDropdown: false,
      showMoreOptionsDropdown: false,

      filterWithEvents: false,
      filterCancellationRequests: false,
      filterIsScheduled: false,

      showAddAppointmentModal: false,
      showAddTicketModal: false,
      showAddBlockedTime: false,
      showConfirmIgnoreDeposit: false,

      showCreateNewOrRescheduleModal: false,
      rescheduleData: null,

      showRotationModal: false,
      showWorkingModal: false,
      showIsolationModal: false,

      canBookAppointment: false,

      alertLoading: false,

      hoveredApp: null,
      scale: 1,
    };
  },
  computed: {
    screenWidth() {
      return screen.width;
    },
    appTitle() {
      return this.selectedAppointment
        ? this.selectedAppointment.title || ''
        : '';
    },
    salon() {
      return this.$store.state.auth.salon;
    },
    notCheckedOutTicketWithPayment() {
      return this.tickets.some(
        (ticket) => ticket.status !== 'completed' && ticket.payments.length
      );
    },
    loggedInStaffIsAdmin() {
      return this.$store.getters['auth/loggedInStaffIsAdmin'];
    },
    managerMode() {
      return this.$store.state.auth.managerMode;
    },
    tempUser() {
      return this.$store.state.auth.tempUser;
    },
    appDepositText() {
      return 'A deposit was collected on this appointment, are you sure you wish to ignore this deposit? If the deposit was not refunded you will not be able to do so from Raserva.';
    },
    staff() {
      return this.$store.state.staff.staff;
    },
    // tickets() {
    //   return this.$store.state.tickets.tickets;
    // },
    ticketChangeCount() {
      return this.$store.state.tickets.changeCount;
    },
    appointments() {
      return this.$store.state.appointments.appointments;
    },
    socket() {
      return this.$store.state.sockets.socket;
    },
    staffOptions() {
      const staff = JSON.parse(JSON.stringify(this.$store.state.staff.staff));

      return staff
        .filter((s) => !s.isDeleted)
        .sort((a, b) => a.firstName.localeCompare(b.firstName))
        .map((staff) => {
          return {
            value: staff.staffId,
            option: `${staff.firstName} ${staff.lastName}`,
          };
        });
    },
    getSelectedStartDateForCalendar() {
      if (this.selectedTimeOption === 'Day') {
        return this.selectedDate.toDate();
      }

      return this.selectedDates.start.toDate();
    },
    getSelectedEndDateForCalendar() {
      if (this.selectedTimeOption === 'Day') {
        return this.selectedDate.toDate();
      }

      return this.selectedDates.end.toDate();
    },
    staffForCalendar() {
      if (this.staffListOverride && this.staffListOverride.length) {
        return this.staffListOverride;
      }

      let staffs = [];

      this.$store.state.staff.staff.forEach(
        (staff) => (staff.ifNoAppointmentsHide = false)
      );

      if (this.selectedFilterOption === 'Working Staff') {
        staffs = this.staff.filter(
          (staff) => staff.working && staff.onCalendar
        );
      } else if (this.selectedFilterOption === 'Scheduled Staff') {
        staffs = [];

        this.$store.state.staff.staff.forEach((staff) => {
          const exception = staff.schedule.exceptions.find((exception) => {
            return moment(exception.date).isSame(this.selectedDate, 'date');
          });

          if (
            (exception && exception.blocks.length) ||
            staff.schedule.default[moment(this.selectedDate).day()].blocks
              .length
          ) {
            staffs.push(staff);
          }

          if (exception && !exception.blocks.length) {
            staff.ifNoAppointmentsHide = true;
          }
        });

        // TODO: look into alt solution, maybe can find a better way
        this.$store.state.appointments.appointments.forEach((appointment) => {
          appointment.services.forEach((service) => {
            if (!service.staff) return;

            const staff = this.$store.state.staff.staff.find(
              (staff) => staff._id === service.staff._id
            );

            if (staff) {
              const staffIndex = staffs.findIndex(
                (preStaff) => preStaff._id === staff._id
              );

              if (staffIndex === -1) {
                staffs.push({ ...staff, hasEvents: true });
              }
            }
          });
        });

        staffs = staffs.filter(
          (staff) =>
            (staff.onCalendar && !staff.ifNoAppointmentsHide) || staff.hasEvents
        );
      } else if (this.selectedFilterOption === 'All Staff') {
        // return working staff
        staffs = this.staff.filter((staff) => staff.onCalendar);
      } else if (this.selectedFilterOption === 'Non-Calendar Staff') {
        staffs = this.staff.filter((staff) => !staff.onCalendar);
      } else {
        const staff = this.staff.find((staff) => {
          return staff.staffId === this.selectedFilterOption;
        });

        if (staff) staffs = [staff];
      }

      // Secondary filter
      if (this.selectedFilterSecondaryOption !== 'All Staff') {
        staffs = staffs.filter((staff) =>
          staff.groups.find(
            (group) => group === this.selectedFilterSecondaryOption
          )
        );
      }

      return staffs;
    },
    loggedInStaff() {
      return this.$store.state.auth.loggedInSalonStaff;
    },
    staffCalendarChange() {
      return this.$store.getters['sockets/calendarChange'];
    },
    stateTickets() {
      return this.$store.state.tickets.tickets;
    },
  },
  watch: {
    managerMode() {
      this.setPermissions();
    },
    tempUser() {
      this.setPermissions();
    },
    salon() {
      if (!this.salon) return;

      this.selectedFilterOption =
        this.salon.adminSettings.defaultCalendarFilter;
    },
    socket() {
      this.socketInit();
    },
    loggedInStaff() {
      this.init();
    },
    staffCalendarChange() {
      this.updateCalendar();
    },
    ticketChangeCount() {
      this.filterTickets();
    },
    stateTickets() {
      this.filterTickets();
    },
    selectedFilterOption(val) {
      this.selectFilterOption(val);
    },
    filterCancellationRequests(val) {
      if (val) {
        localStorage.setItem('filterCancellationRequests', true);
      } else {
        localStorage.removeItem('filterCancellationRequests');
      }
    },
    scale(val) {
      localStorage.setItem('calendarScale', val);
    },
  },
  methods: {
    socketInit() {
      this.$store.state.sockets.socket.on('updateStaff', (data) => {
        this.$store.state.staff.staff = data;
        this.updateCalendar();
      });

      this.$store.state.sockets.socket.on('ticketCreated', (data) => {
        if (!this.tickets.find((ticket) => ticket._id === data._id)) {
          this.tickets.push(data);
        }
      });

      this.$store.state.sockets.socket.on('appointmentCreated', (data) => {
        if (this.selectedTimeOption === 'Day') {
          if (moment(this.selectedDate).isSame(data.date, 'date')) {
            this.updateCalendar();
            this.$toast.info(`Calendar updated`);
          }
        } else {
          if (
            moment(data.date).isBetween(
              this.selectedDates.start,
              this.selectedDates.end,
              undefined,
              '[]'
            )
          ) {
            this.updateCalendar();
            this.$toast.info(`Calendar updated`);
          }
        }
      });
    },
    async init() {
      if (!this.$store.state.auth.salon) return;

      this.scale = +localStorage.getItem('calendarScale') || 1;

      await this.$store.dispatch('appointments/getAppointmentsBetweenDates', {
        start: moment(moment(this.selectedDate).startOf('day')).tz(
          this.$store.state.auth.salon.details.timezone,
          true
        ),
        end: moment(moment(this.selectedDate).endOf('day')).tz(
          this.$store.state.auth.salon.details.timezone,
          true
        ),
        salonId: this.$store.state.auth.salon._id,
      });

      this.addStaffToOptions();
      this.addGroupsToSecondaryFilterOptions();
      this.setPermissions();
      this.filterTickets();

      this.filterCancellationRequests = localStorage.getItem(
        'filterCancellationRequests'
      )
        ? true
        : false;
    },

    async setPermissions() {
      this.canBookAppointment = await this.$store.dispatch(
        'auth/activeUserHasPermission',
        'bookings/bookAppointments'
      );
    },

    filterTickets() {
      this.$refs.ticketsSection.filterTickets();
    },

    filterStaffsByStaffList(staffList) {
      staffList.forEach((s) => {
        const staff = this.staff.find((staff) => {
          return staff.staffId === s;
        });

        if (staff) {
          this.staffListOverride.push(staff);
        }
      });

      this.showIsolationModal = false;
      this.$refs.VuCalendar.updateEvents(true);
    },
    clearStaffListOverride() {
      this.staffListOverride = [];
      this.$refs.VuCalendar.updateEvents(true);
    },

    selectFutureApp(data) {
      this.setSelectedDate(data.date);

      this.globalLoading = true;

      setTimeout(() => {
        this.selectAppointment(data._id);
        this.globalLoading = false;
      }, 2000);

      this.showSearchFutureAppsModal = false;
    },

    getStorage() {
      // const staffFilter = localStorage.getItem('staffFilter');
      const staffSecondaryFilter = localStorage.getItem('staffFilterSecondary');
      const staffSort = localStorage.getItem('staffSort');
      const appointmentStatus = localStorage.getItem('appointmentStatus');

      // staffFilter
      //   ? (this.selectedFilterOption = staffFilter)
      //   : (this.selectedFilterOption = 'Working Staff');

      if (this.salon) {
        this.selectedFilterOption =
          this.salon.adminSettings.defaultCalendarFilter;
      }

      staffSecondaryFilter
        ? (this.selectedFilterSecondaryOption = staffSecondaryFilter)
        : (this.selectedFilterSecondaryOption = 'All Staff');

      staffSort
        ? (this.selectedSortOption = staffSort)
        : (this.selectedSortOption = 'Groups');

      appointmentStatus
        ? (this.selectedAppFilterOption = appointmentStatus)
        : (this.selectedAppFilterOption = 'All');

      if (this.$route.query.staffId) {
        this.selectedFilterOption = this.$route.query.staffId;
      }
    },

    closeTicketModal() {
      if (
        this.$refs.ticketModal.newTicket.items.length &&
        !this.$refs.ticketModal.newTicket._id
      ) {
        this.$refs.ticketModal.save();
      }

      this.showAddTicketModal = false;
    },

    closeAppointmentForm() {
      if (!this.$refs.appModal) return;

      const appointment = this.$refs.appModal.newAppointment;

      if (!appointment._id && appointment.billing.deposits.length) {
        this.showConfirmIgnoreDeposit = true;
      } else {
        this.showAddAppointmentModal = false;
      }

      this.selectedAppointment = null;
    },

    rebookAppointmentFromTicket(ticket) {
      const services = [];
      let lastStart = moment();

      for (let i = 0; i < ticket.items.length; i++) {
        const item = ticket.items[i];

        if (item.item.duration) {
          services.push({
            start: lastStart.format('HH:mm'),
            service: item.item,
            duration: item.item.duration,
            staff: item.staff,
          });

          lastStart = moment(lastStart).add(item.item.duration, 'minutes');
        }
      }

      this.selectedAppointment = {
        services,
        note: '',
        date: moment().toDate(),
        client: ticket.client,
        status: 'new',
      };

      this.rescheduleMode = true;
      this.minAppModal = true;
      this.showAddAppointmentModal = true;
    },

    toggleWorking() {
      this.$store.dispatch('auth/toggleWorking');
    },

    selectService(serviceId) {
      if (!serviceId) return;
      if (typeof serviceId === 'object') serviceId = serviceId._id;

      this.staffForCalendar.forEach((staff) => {
        if (staff.services.find((service) => service === serviceId)) {
          staff.canDoSelectedService = true;
        }
      });

      setTimeout(() => {
        this.staffForCalendar.forEach((staff) => {
          staff.canDoSelectedService = false;
        });

        this.updateCalendar();
      }, 3000);

      this.updateCalendar();
    },

    selectTimeOption(option) {
      this.selectedTimeOption = option;

      if (option === 'Day') {
        this.addStaffToOptions();
      }

      if (option !== 'Day') {
        this.filterOptions = [];
        this.addStaffToOptions();

        if (
          this.selectedFilterOption === 'Working Staff' ||
          this.selectedFilterOption === 'Scheduled Staff' ||
          this.selectedFilterOption === 'Non-Calendar Staff' ||
          this.selectedFilterOption === 'All Staff'
        ) {
          this.selectedFilterOption = this.staff[0].staffId;
        }
      }

      this.updateCalendar();
    },

    viewStaffOptions(staff) {
      if (staff.deleted) return;
      this.selectedStaff = staff;
      this.showCalendarStaffOptions = true;
    },

    async selectFilterOption(option) {
      if (!option) return;

      this.selectedFilterOption = option;

      localStorage.setItem('staffFilter', option);

      if (!this.$store.state.appointments.appointments.length) {
        if (this.$store.state.auth.salon) {
          await this.$store.dispatch(
            'appointments/getAppointmentsBetweenDates',
            {
              start: moment(moment(this.selectedDate).startOf('day')).tz(
                this.$store.state.auth.salon.details.timezone,
                true
              ),
              end: moment(moment(this.selectedDate).endOf('day')).tz(
                this.$store.state.auth.salon.details.timezone,
                true
              ),
              salonId: this.$store.state.auth.salon._id,
            }
          );
        }
      }

      if (
        option === 'Working Staff' ||
        option === 'Scheduled Staff' ||
        option === 'All Staff'
      ) {
        this.selectedTimeOption = 'Day';
      }

      this.selectedStaff = null;
      this.showCalendarStaffOptions = false;

      this.updateCalendar();
    },
    async selectFilterSecondaryOption(option) {
      this.selectedFilterSecondaryOption = option;

      localStorage.setItem('staffFilterSecondary', option);

      if (!this.$store.state.appointments.appointments.length) {
        await this.$store.dispatch('appointments/getAppointmentsBetweenDates', {
          start: moment(moment(this.selectedDate).startOf('day')).tz(
            this.$store.state.auth.salon.details.timezone,
            true
          ),
          end: moment(moment(this.selectedDate).endOf('day')).tz(
            this.$store.state.auth.salon.details.timezone,
            true
          ),
          salonId: this.$store.state.auth.salon._id,
        });
      }

      this.selectedTimeOption = 'Day';

      this.updateCalendar();
    },
    selectSortOption(option) {
      this.selectedSortOption = option;

      localStorage.setItem('staffSort', option);

      this.updateCalendar();
    },
    selectAppFilterOption(option) {
      this.selectedAppFilterOption = option;

      localStorage.setItem('appointmentStatus', option);

      this.updateCalendar();
    },
    setSelectedDate(date) {
      date = moment(date);

      if (this.selectedTimeOption === 'Day') {
        this.selectedDate = date;
      }

      if (this.selectedTimeOption === 'Week') {
        this.selectedDates = {
          start: moment(date)
            .tz(this.$store.state.auth.salon.details.timezone)
            .startOf('week'),
          end: moment(date)
            .tz(this.$store.state.auth.salon.details.timezone)
            .endOf('week'),
        };
      }

      this.updateCalendar();
    },
    selectToday() {
      this.selectedTimeOption = 'Day';

      this.addStaffToOptions();
    },
    selectTicket(ticket) {
      this.selectedTicket = ticket;
      this.showAddTicketModal = true;
    },
    selectAppointment(appointmentId) {
      this.selectedAppointment =
        this.$store.state.appointments.appointments.find((app) => {
          return app._id == appointmentId;
        });

      this.showAddAppointmentModal = true;
    },
    selectTimeslot(data, skipRescheduleCheck) {
      if (this.rescheduleMode) {
        this.rescheduleAppointment(data, this.rescheduleData);
        this.rescheduleData = null;
        return;
      }
      if (this.selectedAppointment && !skipRescheduleCheck) {
        this.rescheduleData = data;
        this.showCreateNewOrRescheduleModal = true;
        return;
      }
      if (skipRescheduleCheck) {
        this.minAppModal = false;
      }

      this.selectedAppointment = {
        _id: null,
        services: [
          {
            start: data.time,
            service: null,
            duration: null,
            staff: {
              _id: data.staff._id,
              commission: data.staff.commission,
              accountCode: data.staff.accountCode,
              email: data.staff.email,
              firstName: data.staff.firstName,
              lastName: data.staff.lastName,
              phoneNumber: data.staff.phoneNumber,
              profileImage: data.staff.profileImage,
              splitCheck: data.staff.splitCheck,
              splitOther: data.staff.splitOther,
              staffId: data.staff.staffId,
              title: data.staff.title,
              trackHourly: data.staff.trackHourly,
            },
          },
        ],
        note: '',
        date: data.date.toDate(),
        client: null,
        status: 'new',
      };
      this.showAddAppointmentModal = true;
    },
    async rescheduleAppointment(timeslotData, dontInstantEdit) {
      try {
        const [newHour, newMinute] = timeslotData.time.split(':');
        const newStartTime = moment().hour(newHour).minute(newMinute);
        let servicesDuration = 0;

        this.selectedAppointment.date = moment(timeslotData.date)
          .hour(newHour)
          .minute(newMinute)
          .toDate();

        this.selectedAppointment.services.forEach((item) => {
          const [hour, minute] = item.start.split(':');
          const originalStart = moment().hour(hour).minute(minute);

          item.start = moment(originalStart)
            .add(
              moment(newStartTime).diff(moment(originalStart), 'minutes'),
              'minutes'
            )
            .add(servicesDuration, 'minutes')
            .format('kk:mm');

          servicesDuration += item.service.duration;

          item.staff = timeslotData.staff;
        });

        if (dontInstantEdit) {
          this.rescheduleMode = false;
          this.minAppModal = false;
          return;
        }

        // Edit
        if (this.selectedAppointment._id) {
          await this.$store.dispatch(
            'appointments/editAppointment',
            this.selectedAppointment
          );

          this.$refs.VuCalendar.updateEvents(true);

          this.$toast.success('Appointment rescheduled');
        } else {
          // Create
          const startHour =
            +this.selectedAppointment.services[0].start.split(':')[0];
          const startMinute =
            +this.selectedAppointment.services[0].start.split(':')[1];

          await this.$store.dispatch('appointments/createAppointment', {
            ...this.selectedAppointment,
            startTimeText: `${moment(this.selectedAppointment.date)
              .hour(startHour)
              .minute(startMinute)
              .format('LLL zz')}${moment().tz(moment.tz.guess()).format('z')}`,
          });

          this.$refs.VuCalendar.updateEvents(true);

          this.$toast.success('Ticket checked out and new appointment booked');
        }
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.rescheduleMode = false;
      this.showAddAppointmentModal = false;
      this.minAppModal = false;
      this.selectedAppointment = null;
    },
    async addStaffToOptions() {
      this.filterOptions = [];

      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'bookings/othersCalendar'
        )
      ) {
        this.filterOptions.push(
          { value: 'Working Staff', option: 'Working Staff' },
          { value: 'Scheduled Staff', option: 'Scheduled Staff' },
          { value: 'Non-Calendar Staff', option: 'Non-Calendar Staff' },
          { value: 'All Staff', option: 'All Staff' },
          { value: null, option: '' },
          ...this.staffOptions
        );

        return;
      }

      const staff = this.loggedInStaff;

      if (!staff) return;

      this.filterOptions.push({
        value: staff.staffId,
        option: `${staff.firstName} ${staff.lastName}`,
      });
      this.selectedFilterOption = staff.staffId;
    },
    async addGroupsToSecondaryFilterOptions() {
      const filterSecondaryOptions = [
        { value: 'All Staff', option: 'All Staff' },
      ];

      this.$store.state.auth.salon.groups.forEach((group) => {
        filterSecondaryOptions.push({
          value: group,
          option: group,
        });
      });

      this.filterSecondaryOptions = filterSecondaryOptions;
    },

    async appointmentUpdated(appointment, action) {
      if (action === 'Created') {
        await this.$store.dispatch(
          'appointments/createAppointment',
          appointment
        );
      } else if (action === 'Updated') {
        await this.$store.dispatch('appointments/editAppointment', appointment);
      }

      this.$toast.success(`Appointment ${action}`);
      if (!appointment.dontNavigateAway) {
        this.showAddAppointmentModal = false;
      }

      if (!this.$refs.VuCalendar) return;

      this.selectedAppointment = null;
      this.rescheduleData = null;
      this.updateCalendar();
    },
    async ticketUpdated(ticket, action) {
      try {
        if (action === 'Created') {
          await this.$store.dispatch('tickets/createTicket', ticket);
        } else if (action === 'Updated') {
          await this.$store.dispatch('tickets/editTicket', ticket);
        } else if (action === 'Checked Out') {
          await this.$store.dispatch('tickets/checkoutTicket', ticket);
        }

        this.$toast.success(`Ticket ${action}`);

        if (!ticket.dontClose) {
          this.showAddTicketModal = false;
        }
      } catch (e) {
        this.$toast.error(e.message);
      }
    },

    async updateCalendar() {
      if (!this.$refs.VuCalendar) return;

      if (this.selectedTimeOption === 'Day') {
        await this.$store.dispatch('appointments/getAppointmentsBetweenDates', {
          start: moment(moment(this.selectedDate).startOf('day')).tz(
            this.$store.state.auth.salon.details.timezone,
            true
          ),
          end: moment(moment(this.selectedDate).endOf('day')).tz(
            this.$store.state.auth.salon.details.timezone,
            true
          ),
          salonId: this.$store.state.auth.salon._id,
        });

        this.$refs.VuCalendar.dateStart = this.selectedDate;
        this.$refs.VuCalendar.dateEnd = this.selectedDate;
      }

      if (this.selectedTimeOption === 'Week') {
        await this.$store.dispatch('appointments/getAppointmentsBetweenDates', {
          start: moment(moment(this.selectedDates.start).startOf('day')).tz(
            this.$store.state.auth.salon.details.timezone,
            true
          ),
          end: moment(moment(this.selectedDates.end).endOf('day')).tz(
            this.$store.state.auth.salon.details.timezone,
            true
          ),
          salonId: this.$store.state.auth.salon._id,
        });

        this.$refs.VuCalendar.dateStart = this.selectedDates.start;
        this.$refs.VuCalendar.dateEnd = this.selectedDates.end;
      }

      this.$refs.VuCalendar.updateEvents();
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  max-height: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
  padding: 16px;

  .alert {
    padding: 12px;
    font-size: 14px;
    border-radius: 5px;

    .bold {
      text-decoration: none;
      font-weight: 700;
      cursor: default;
    }

    &-warning {
      background-color: var(--clr-warning-light);
      border-color: var(--clr-warning);
    }
  }

  .col {
    overflow-y: auto;
  }

  .cal {
    max-height: 100%;
  }

  .staff {
    padding: 16px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
  }

  .calendar {
    display: flex;
    flex-direction: column;
    height: 100%;

    &_actions {
      display: flex;
      // flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 16px;
      z-index: 4;

      .col {
        display: flex;
        gap: 16px;
      }

      p {
        padding: 0 10px;
      }

      &_filter-sort {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;

        .scale {
          display: flex;
          gap: 16px;
          font-size: 16px;

          .disabled {
            background-color: var(--clr-light);
            border-color: var(--clr-light-2);
            color: var(--clr-gray);
            cursor: not-allowed;
          }

          &-action {
            padding: 12px;
            border: 1px solid var(--clr-light);
            border-radius: 5px;
            display: grid;
            place-items: center;
            cursor: pointer;
          }
        }
      }

      &_dates {
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        gap: 16px;

        .resize {
          padding: 12px;
          border: 1px solid var(--clr-light);
          border-radius: 5px;
          display: grid;
          place-items: center;
          cursor: pointer;
        }
      }

      &_options {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 16px;

        .add_button {
          position: relative;

          button {
            i {
              margin-left: 16px;
            }
          }
        }

        .addDropdown {
          color: var(--clr-black);
          background-color: white;
          border: 1px solid var(--clr-light);
          border-radius: 5px;

          li {
            padding: 12px 16px;
            font-weight: 400;
            text-align: left;
            font-size: 15px;
            transition: background-color 0.2s;
            cursor: pointer;

            &:hover {
              background-color: var(--clr-extra-light);
            }

            label {
              margin-left: 16px;
            }

            &:not(:last-child) {
              border-bottom: 1px solid var(--clr-light);
            }
          }
        }
      }
    }

    &_calendar {
      max-height: 95vh;
      flex-grow: 1;
      margin-top: 16px;
      overflow-y: auto;
      border: 1px solid var(--clr-light);

      &_none {
        height: 100%;
        width: 100%;
        display: grid;
        place-items: center;

        &_action {
          text-align: center;

          button {
            margin-top: 32px;
          }
        }
      }
    }
  }
}

.fa-minus {
  cursor: pointer;
}
.minApp {
  z-index: 10;
  position: fixed;
  bottom: 30px;
  left: 30px;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: var(--clr-secondary);
  color: white;
  display: grid;
  place-items: center;
  box-shadow: var(--shadow);
  cursor: pointer;
  font-size: 24px;
  font-weight: 700;
}

.isolationList,
.createOrReschedule {
  padding: 32px;
}

.createOrReschedule {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

// Tablet
@media (max-width: 900px) {
  .home {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;

    .calendar {
      &_actions {
        &_dates,
        &_filter-sort,
        &_options {
          width: 100%;
          flex-direction: column;
          align-items: stretch;
        }

        &_filter-sort {
          .scale {
            width: 100%;

            &-action {
              flex-grow: 1;
            }
          }
        }

        .col {
          width: 100%;
          flex-direction: column;
        }

        .add_button {
          button {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
