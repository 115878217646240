<template>
  <teleport v-if="!hideBackdrop" to="body">
    <Backdrop @click="$emit('hide')" />
  </teleport>

  <div
    class="dropdown"
    :class="mode"
    :style="{
      minWidth: width,
      left: calcRight,
      zIndex: zIndex,
      top: height ? `-${height + 15}px` : '',
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  emits: ['hide'],
  props: {
    mode: {
      type: String,
      default: 'bottom left',
    },
    width: {
      type: String,
      default: '200px',
    },
    height: {
      type: Number,
    },
    hideBackdrop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      zIndex: null,
    };
  },
  created() {
    this.zIndex = +this.$store.getters['styles/modalZIndex'];
  },
  computed: {
    calcRight() {
      if (this.mode.toLowerCase().includes('right')) {
        return `calc(100% - ${this.width})`;
      }

      return '';
    },
  },
};
</script>

<style>
.dropdown {
  position: absolute;
  max-width: calc(100vw - 10px);
  box-shadow: var(--shadow);
  /* z-index: 1; */
}

.bottom {
  top: calc(100% + 10px);
}

.center {
  left: 50%;
  transform: translateX(-50%);
}

.left {
  left: 0;
}

.right {
  right: 0;
}
</style>
