<template>
  <li class="category">
    <div
      class="dropzone"
      v-show="catDragging"
      @drop="catDrop($event)"
      @dragenter.prevent
      @dragover.prevent
    ></div>
    <div class="category_head">
      <div
        class="main"
        draggable="true"
        @dragstart="catDragStart($event, category)"
        @dragend="catDragEnd"
      >
        <i class="fas fa-bars"></i>
        <h3>{{ category.title }}</h3>
      </div>
      <div class="options">
        <div class="elipses" @click="showCategoryOptions = true">
          <i class="fas fa-ellipsis-h"></i>
        </div>

        <Tooltip
          v-if="showCategoryOptions"
          mode="bottom right"
          @hide="showCategoryOptions = false"
        >
          <ul class="categoryDropdown">
            <li
              @click="
                $emit('addItemToCategory', category);
                showCategoryOptions = false;
              "
            >
              Add New Item
            </li>
            <li
              @click="
                $emit('selectCategory', category._id);
                showCategoryOptions = false;
              "
            >
              Edit Category
            </li>
            <li class="text-red" @click="deleteCategory">Delete Category</li>
          </ul>
        </Tooltip>
      </div>
    </div>
    <div class="category_items" v-show="showItems">
      <div
        class="category_items_item"
        v-for="(item, index) in category.items"
        :key="index"
        draggable="true"
        @dragstart="dragStart($event, item, index)"
        @dragend="dragEnd"
      >
        <div
          class="dropzone"
          v-show="dragging && dragIndex !== index && dragIndex !== index - 1"
          @drop="drop($event, index)"
          @dragenter.prevent
          @dragover.prevent
        ></div>
        <div class="listItem">
          <i class="fas fa-bars"></i>
          <ServiceListItem
            v-if="item.duration"
            :service="item"
            @click="selectItem(item)"
          />
          <ProductListItem v-else :product="item" @click="selectItem(item)" />
        </div>
        <div
          class="dropzone"
          v-show="
            dragging &&
            index === category.items.length - 1 &&
            dragIndex !== category.items.length - 1
          "
          @drop="drop($event, index + 1)"
          @dragenter.prevent
          @dragover.prevent
        ></div>
      </div>
    </div>
  </li>
</template>

<script>
import ServiceListItem from '@/components/items/ServiceListItem.vue';
import ProductListItem from '@/components/items/ProductListItem.vue';

export default {
  emits: [
    'selectItem',
    'selectCategory',
    'addServiceToCategory',
    'deleteCategory',
    'moveItem',
  ],
  components: {
    ServiceListItem,
    ProductListItem,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    showItems: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    catDragging() {
      return this.$store.state.items.catDragging;
    },
  },
  data() {
    return {
      showCategoryOptions: false,

      dragging: false,
      dragIndex: null,
    };
  },
  methods: {
    selectItem(item) {
      item.category = this.category._id;
      this.$emit('selectItem', item);
    },
    deleteCategory() {
      if (this.category.items.length) {
        this.$toast.error(
          'All items must be removed to delete existing category'
        );
        return;
      }

      this.$emit('deleteCategory', this.category);
    },

    // Item D&D
    dragStart(event, service, index) {
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('type', 'service');
      event.dataTransfer.setData('id', service._id);

      // For some reason we have to do Vue things in set timeout?
      setTimeout(() => {
        this.dragging = true;
        this.dragIndex = index;
      }, 10);
    },
    dragEnd() {
      this.dragging = false;
      this.dragIndex = null;
    },
    drop(event, index) {
      if (event.dataTransfer.getData('type') !== 'service') return;

      // Do stuffs
      this.$emit('moveItem', {
        id: event.dataTransfer.getData('id'),
        newPosition: index,
      });
    },

    // Categroy D&D
    catDragStart(event, category) {
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('type', 'category');
      event.dataTransfer.setData('id', category._id);

      // For some reason we have to do Vue things in set timeout?
      setTimeout(() => {
        this.$store.state.items.catDragging = true;
      }, 10);
    },
    catDragEnd() {
      this.$store.state.items.catDragging = false;
    },
    catDrop(event) {
      if (event.dataTransfer.getData('type') !== 'category') return;

      // Do stuffs
      this.$emit('moveCategory', {
        id: event.dataTransfer.getData('id'),
        newPositionId: this.category._id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  &:not(:first-child) {
    margin-top: 16px;
  }

  .dropzone {
    width: 100%;
    height: 16px;
    border: 1px dashed var(--clr-gray);
    margin-top: 8px;
  }

  &_head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .main {
      display: flex;
      align-items: center;
      gap: 16px;

      i {
        font-size: 12px;
        color: var(--clr-gray);
        cursor: grab;
      }
    }

    h3 {
      font-size: 24px;
    }

    .options {
      position: relative;

      .elipses {
        display: grid;
        place-items: center;
        height: 40px;
        width: 40px;
        border: 1px solid transparent;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color, border-color 0.2s ease;

        &:hover {
          background-color: var(--clr-extra-light);
          border-color: var(--clr-light);
        }
      }

      .categoryDropdown {
        color: var(--clr-black);
        background-color: white;
        border: 1px solid var(--clr-light);
        border-radius: 5px;

        li {
          padding: 12px 16px;
          font-weight: 400;
          text-align: left;
          font-size: 15px;
          transition: background-color 0.2s;
          cursor: pointer;

          &:hover {
            background-color: var(--clr-extra-light);
          }
        }
      }
    }
  }

  &_items {
    margin-top: 16px;

    &_item {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .listItem {
        display: flex;
        align-items: center;
        gap: 16px;

        i {
          font-size: 12px;
          color: var(--clr-light-2);
          cursor: grab;
        }

        .service,
        .product {
          flex-grow: 1;

          &:not(:first-child) {
            margin-top: 5px;
          }
        }
      }
    }
  }
}
</style>
