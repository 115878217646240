<template>
  <section class="terminal">
    <div class="terminal_head">
      <h2>Virtual Terminal</h2>
      <p>
        Accept payments virtually when card is not present. Virtual terminal
        charge data is not stored on Raserva.
      </p>
    </div>
    <div class="terminal_body">
      <CardConnectVirtTerm />
    </div>
  </section>
</template>

<script>
import CardConnectVirtTerm from '@/views/virtTerm/CardConnectVirtTerm.vue';

export default {
  components: {
    CardConnectVirtTerm,
  },
};
</script>

<style lang="scss" scoped>
.terminal {
  padding: 32px;
  max-width: 600px;
  margin: auto;

  &_head {
    h2 {
      font-size: 28px;
    }
    p {
      margin-top: 8px;
      color: var(--clr-gray);
    }
  }
  &_body {
    margin-top: 32px;
  }
}
</style>
