<template>
  <form @submit.prevent="submitPayment" class="form">
    <BaseInput
      v-if="!showAdditionalOptions"
      label="Amount"
      :value="amount"
      @input="total = +$event"
    />

    <!-- Additional terminals -->
    <BaseSelect
      v-if="caInfo.additionalTerminals.length"
      label="Terminal"
      :value="selectedTerminal"
      :options="allTerminalOptions"
      @input="selectTerminal($event)"
    />

    <div
      class="additional"
      @click="showAdditionalOptions = !showAdditionalOptions"
    >
      <i v-if="!showAdditionalOptions" class="fas fa-caret-down"></i>
      <i v-else class="fas fa-caret-up"></i>
      <p>Use Virtual Terminal</p>
      <!-- <p>{{ showAdditionalOptions ? 'Hide' : 'Show' }} additional options</p> -->
    </div>

    <div v-if="showAdditionalOptions" class="additionalOptions">
      <!-- ChargeAnywhere Virtual Terminal -->
      <iframe
        :src="`/capf.html?mid=${caInfo.pfmid}&tid=${caInfo.pftid}&amount=${total}`"
        width="100%"
        height="400"
        frameborder="0"
        ref="CAIframe"
      ></iframe>
    </div>

    <div class="form_actions" v-if="!showAdditionalOptions">
      <BaseButton
        v-if="paymentUUID && !loading"
        type="button"
        mode="primary-outline"
        @click="queryTransaction"
        :disabled="queryLoading"
        ><i v-if="queryLoading" class="fas fa-spinner"></i> Query
        Transaction</BaseButton
      >
      <BaseButton
        v-if="loading && paymentUUID"
        type="button"
        mode="danger-outline"
        @click="cancel"
        :disabled="cancelLoading"
      >
        <i v-if="cancelLoading" class="fas fa-spinner"></i>
        Cancel Charge</BaseButton
      >
      <BaseButton
        v-if="!loading"
        @click="confirmNoCollection = true"
        type="button"
        mode="primary-outline"
        :disabled="!total || loading"
      >
        Charge Without Payment
      </BaseButton>
      <BaseButton :disabled="!total || loading">
        <i v-if="loading" class="fas fa-spinner"></i>
        Charge
      </BaseButton>
    </div>

    <div v-if="paymentUUID && !loading" class="CA_queryTransaction">
      <p>
        <span class="bold">IMPORTANT!</span> If terminal transaction is still in
        progress, please wait until customer has completed terminal transaction
        and select "Query Transaction".
      </p>
    </div>
  </form>

  <Confirm
    v-if="confirmNoCollection"
    zIndex="100000000001"
    title="Not collecting payment"
    text="Are you sure you wish to record this data without collecting payment?"
    @confirm="submit"
    @deny="confirmNoCollection = false"
  />

  <Confirm
    v-if="confirmNewPaymentWhileOutstandingPaymentID"
    zIndex="100000000001"
    title="Outstanding Payment ID"
    text="Are you sure you wish to run a new charge? This will get rid of the existing payment ID and you will not be able to query the previous transaction."
    @confirm="
      paymentUUID = null;
      submitPayment();
    "
    @deny="confirmNewPaymentWhileOutstandingPaymentID = false"
  />
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

import BaseInput from '../base/BaseInput.vue';

export default {
  emits: ['submit'],
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      required: false,
    },
    tax: {
      type: Number,
      required: false,
    },
    client_email: {
      type: String,
      required: false,
    },
  },
  created() {
    this.init();
  },
  async mounted() {
    // CA TOKEN
    window.addEventListener('message', this.eventListener);

    this.selectedTerminal =
      localStorage.getItem('caTerminal') || this.caInfo.tid;
  },
  async unmounted() {
    window.removeEventListener('message', this.eventListener);

    if (this.loading) await this.cancel();

    clearInterval(this.queryInterval);
  },
  computed: {
    allTerminalOptions() {
      const terminals = [];

      terminals.push({
        option: this.caInfo.tid,
        value: this.caInfo.tid,
      });

      this.caInfo.additionalTerminals.forEach((terminal) => {
        terminals.push({
          option: terminal.tid,
          value: terminal.tid,
        });
      });

      return terminals;
    },
    caInfo() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.billing.chargeanywhere;
    },
  },
  data() {
    return {
      total: null,
      loading: false,
      cancelLoading: false,
      queryLoading: false,
      confirmNoCollection: false,
      paymentUUID: null,
      showAdditionalOptions: false,

      confirmNewPaymentWhileOutstandingPaymentID: false,
      queryInterval: null,

      selectedTerminal: null,
    };
  },
  watch: {
    paymentUUID(val) {
      this.$store.state.tickets.chargeanywherePaymentId = val;
    },
  },
  methods: {
    init() {
      this.total = +this.amount;
    },

    eventListener(event) {
      // CA Event
      if (event.data.ResponseCode) {
        const customData = event.data;
        const parsed = JSON.parse(JSON.stringify(customData, null, 2));

        if (parsed.TokenNumber) {
          this.submit(
            `${parsed.GatewayReferenceNumber || parsed.ReferenceNumber}:${
              parsed.ApprovalCode
            }`,
            parsed.AuthorizedAmount,
            0,
            parsed.TokenNumber
          );
        } else {
          // Have to do it this way or get error:
          // "Assigning src to self"
          const src = this.$refs.CAIframe.src;
          this.$refs.CAIframe.src = src;

          this.$toast.error(`Error; ${parsed.ResponseText} - Please try again`);
        }
      }
    },

    async cancel() {
      this.cancelLoading = true;

      const terminal = this.getSelectedTerminal();

      try {
        await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/chargeanywhere/clearTransaction`,
          {
            Version: '1.0',
            MerchantId: this.$store.state.auth.salon.billing.chargeanywhere.mid,
            TerminalId: terminal.tid,
            Identification: terminal.identification,
            UniqueId: this.paymentUUID,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        clearInterval(this.queryInterval);

        this.$toast.warning('Transaction cleared');
        this.loading = false;
      } catch (error) {
        clearInterval(this.queryInterval);

        this.$toast.error(error.response.data.error);
        this.loading = false;
      }

      this.cancelLoading = false;
    },

    async submitPayment() {
      if (this.loading) return;

      this.confirmNewPaymentWhileOutstandingPaymentID = false;

      if (this.paymentUUID) {
        this.confirmNewPaymentWhileOutstandingPaymentID = true;
        await this.cancel();
        return;
      }

      this.loading = true;
      this.paymentUUID = uuidv4();

      const terminal = this.getSelectedTerminal();

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/chargeanywhere/processTransaction`,
          {
            Version: '1.0',
            MerchantId: this.$store.state.auth.salon.billing.chargeanywhere.mid,
            TerminalId: terminal.tid,
            Identification: terminal.identification,
            UniqueId: this.paymentUUID,
            TenderType: '2',
            TransactionType: '1',
            SaleAmount: this.total,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        if (response.data.id) {
          this.queryInterval = setInterval(() => {
            this.queryJob(response.data.id);
          }, 1000 * 6);
        }
      } catch (error) {
        this.$toast.error(error.message);
        this.loading = false;
      }
    },

    getSelectedTerminal() {
      if (this.selectedTerminal === this.caInfo.tid) {
        return {
          tid: this.caInfo.tid,
          identification: this.caInfo.identification,
        };
      }

      const terminal = this.caInfo.additionalTerminals.find((terminal) => {
        return terminal.tid === this.selectedTerminal;
      });

      return terminal;
    },

    selectTerminal(terminalId) {
      localStorage.setItem('caTerminal', terminalId);
    },

    async queryJob(id) {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/chargeanywhere/queryJob?id=${id}&salonId=${this.$store.state.auth.salon._id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        if (response.data.state !== 'active') {
          clearInterval(this.queryInterval);
        }

        const data = response.data.data;

        if (!data) {
          if (response.data.reason) {
            this.loading = false;
            this.$toast.error(response.data.reason);
          } else if (response.data.state === 'completed') {
            this.loading = false;
            console.log(response.data);
            this.$toast.error('Error please try again');
          }

          return;
        }

        console.log(data);

        if (
          data.ResponseText === 'APPROVED' ||
          data.ResponseCode === '000' ||
          data.ResponseCode === '010'
        ) {
          this.$store.state.tickets.chargeanywherePaymentId = null;

          this.submit(
            `${data.GatewayReferenceNumber}:${data.ApprovalCode}`,
            data.AuthorizedAmount,
            data.TipAmount,
            data.TokenNumber
          );
        } else {
          this.queryTransaction();
        }

        this.loading = false;
      } catch (error) {
        await this.queryTransaction();

        if (!error.response) {
          this.$toast.error(error.message);
          this.loading = false;
          return;
        }

        clearInterval(this.queryInterval);
        this.$toast.error(error.response.data.error);
        this.loading = false;
      }
    },

    async queryTransaction() {
      this.queryLoading = true;

      const terminal = this.getSelectedTerminal();

      try {
        // Query transaction (double check)
        const queryResponse = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/chargeanywhere/queryTransaction`,
          {
            Version: '1.0',
            MerchantId: this.$store.state.auth.salon.billing.chargeanywhere.mid,
            TerminalId: terminal.tid,
            Identification: terminal.identification,
            UniqueId: this.paymentUUID,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        const queryData = queryResponse.data.data;

        if (
          queryData.ResponseText === 'APPROVED' ||
          queryData.ResponseCode === '000' ||
          queryData.ResponseCode === '010'
        ) {
          this.$store.state.tickets.chargeanywherePaymentId = null;

          this.submit(
            `${queryData.GatewayReferenceNumber}:${queryData.ApprovalCode}`,
            queryData.AuthorizedAmount,
            queryData.TipAmount,
            this.paymentUUID
          );
        } else {
          this.$toast.error(
            queryData.ResponseText || queryData.TransactionResult || 'Not found'
          );
        }
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.queryLoading = false;
    },

    submit(ref, amount, tipAmount, token) {
      this.$emit('submit', {
        ref,
        amount: amount ? amount : +this.total.toFixed(2),
        tipAmount,
        token,
      });
    },
  },
  components: { BaseInput },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .additional {
    display: flex;
    gap: 16px;
    align-items: center;
    color: var(--clr-link);
    cursor: pointer;
  }

  .toggle {
    display: flex;
    align-items: center;
    gap: 32px;
    flex-wrap: wrap;
    padding: 16px 0;

    &_text {
      &_label {
        font-size: 18px;
      }
      &_subtext {
        margin-top: 5px;
        font-size: 14px;
      }
    }
  }

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }

  .CA_queryTransaction {
    margin-top: 16px;
    padding: 16px;
    border-radius: 5px;
    border: 1px solid var(--clr-warning);
    background-color: var(--clr-warning-light);
    line-height: 24px;

    .bold {
      font-weight: 700;
    }
  }
}
// Tablet
@media (max-width: 900px) {
  .form_actions {
    flex-direction: column;
  }
}
</style>
