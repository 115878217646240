<template>
  <div class="endpoint_head">
    <h2>Update client</h2>

    <div class="route">
      <i
        @click="
          $router.push('/documentation/api/clients#update');
          $toast.info('Set URL to endpoint');
        "
        :style="{ color: 'var(--clr-secondary)', cursor: 'pointer' }"
        class="fas fa-hashtag"
      ></i>
      <p class="tag tag-blue">PUT</p>
      <p class="url">{{ apiUrl }}<span>/clients/:id</span></p>
    </div>

    <div class="description">
      <p>
        Update a client, DO NOT let a staff account update a global client (A
        client created from the
        <span class="tag tag-blue">POST</span>
        <span :style="{ fontWeight: 700, marginLeft: '8px' }">/clients</span>
        endpoint).
      </p>
    </div>
  </div>

  <div class="endpoint_body">
    <div class="request">
      <h3>Request</h3>

      <!-- Path params -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Path Parameters</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">ID</p>
              <p class="examples">
                Examples: <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6f</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
          <p>application/json</p>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">salonIdToUpdateFutureAppointments</p>
              <p class="description">
                ID of a salon - Use this to edit future appointments that this
                client is associated with (For example, if this is not included
                and you edit the clients name, the name will not be updated on
                future appointments)
              </p>
              <p class="examples">
                Examples: <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6f</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <li>
            <div class="col value">
              <p class="bold">firstName</p>
              <p class="description">String</p>
              <p class="examples">Examples: <span>John</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <li>
            <div class="col value">
              <p class="bold">firstName</p>
              <p class="description">String</p>
              <p class="examples">Examples: <span>John</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <li>
            <div class="col value">
              <p class="bold">lastName</p>
              <p class="description">String</p>
              <p class="examples">Examples: <span>Doe</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <li>
            <div class="col value">
              <p class="bold">phoneNumber</p>
              <p class="description">String</p>
              <p class="examples">Examples: <span>5551112222</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <li>
            <div class="col value">
              <p class="bold">email</p>
              <p class="description">String</p>
              <p class="examples">Examples: <span>client@email.com</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- Profile Image -->
          <li>
            <div class="col value">
              <p class="bold">profileImage</p>
              <p class="description">String (URL of an image)</p>
              <p class="examples">
                Examples:
                <span>https://www.images.google.com/ryanreynolds</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- Gender -->
          <li>
            <div class="col value">
              <p class="bold">gender</p>
              <p class="description">
                String - Either 'male', 'female', 'other', 'non-binary', or null
              </p>
              <p class="examples">
                Examples:
                <span>female</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- Date of birth -->
          <li>
            <div class="col value">
              <p class="bold">dateOfBirth</p>
              <p class="description">
                Object <br /><br />
                <span class="bold">month</span> - Number (0 = January)<br />
                <span class="bold">date</span> - Number <br />
                <span class="bold">year</span> - Number
              </p>
              <p class="examples">
                Examples:
                <span>{ month: 0, date: 15, year: 1990 }</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- Notifications -->
          <li>
            <div class="col value">
              <p class="bold">notifications</p>
              <p class="description">
                Object - All default to false (regulations)<br /><br />
                <span class="bold">email</span> - Boolean <br />
                <span class="bold">text</span> - Boolean <br />
                <span class="bold">marketing</span> - Boolean
              </p>
              <p class="examples">
                Examples:
                <span>{ email: true, text: true, marketing: true }</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="response">
      <h3>Response</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">client</p>
              <p class="examples">
                Examples:
                <span
                  >{ "dateOfBirth": { "month": 0, "date": 15, "year": 1990 },
                  "notifications": { "email": true, "text": true, "marketing":
                  true }, "_id": "649f1a6ab9324a46842de397", "firstName":
                  "John", "lastName": "Edit", "phoneNumber": "5551112222",
                  "email": "john@doe.com", "gender": "male", "globalClient":
                  false, "salons": [ "622ec883ec2552e0d48ed267" ],
                  "blacklistedSalons": [], "messages": [], "appointmentNoShows":
                  [], "tickets": [], "giftcards": [], "subscriptions": [],
                  "notes": [], "formulas": [], "loyalty": [], "packages": [],
                  "familyAndFriends": [], "paymentMethods": [], "__v": 0 }</span
                >
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="reqResExamples">
      <h3>Examples</h3>

      <!-- Request -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Request</h4>
          <p>application/json</p>
        </div>

        <ul>
          <li>
            <p>{ "lastName": "Edit" }</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    apiUrl() {
      return process.env.VUE_APP_RASERVA_BACKEND;
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoint {
  .tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px;
    border-radius: 5px;
    color: white;

    &-blue {
      background-color: var(--clr-secondary);
    }
  }

  &_head {
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }

    .route {
      padding: 8px 16px;
      border-radius: 5px;
      background-color: var(--clr-white-2);
      display: flex;
      align-items: center;
      gap: 16px;

      .url {
        color: var(--clr-gray);

        span {
          color: var(--clr-primary);
          font-weight: 700;
        }
      }
    }

    .description {
      margin-top: 32px;
    }
  }

  &_body {
    .request,
    .response,
    .reqResExamples {
      margin-top: 32px;
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &_section {
      &:not(:first-child) {
        margin-top: 32px;
      }

      .head {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 20px;
          margin-bottom: 16px;
          color: var(--clr-secondary);
        }

        p {
          font-size: 14px;
          color: var(--clr-gray);
        }
      }

      ul {
        .subitem {
          margin-left: 32px;
        }

        li {
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          &:not(:first-child) {
            margin-top: 16px;
          }

          .value {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .bold {
            font-weight: 700;
          }
          .required {
            color: orangered;
          }

          .description {
            margin: 4px 0;
            color: var(--clr-gray);
          }

          .examples {
            span {
              background-color: var(--clr-white-2);
              padding: 4px 8px;
              border-radius: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
