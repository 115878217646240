<template>
  <div v-if="subscriptions.length === 0" class="none">
    <h3>No subscriptions to display!</h3>
    <p>Consider creating a subscription.</p>
  </div>

  <ul v-else class="subscriptions">
    <table>
      <thead>
        <tr>
          <th>Client</th>
          <th>Plan</th>
          <th>Scheduled</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="tbody"
          v-for="sub in subscriptions"
          :key="sub._id"
          @click="$emit('select', sub)"
        >
          <td>{{ `${sub.client.firstName} ${sub.client.lastName}` }}</td>
          <td>{{ sub.package.title }}</td>
          <td><i v-if="sub.scheduled" class="fas fa-check"></i></td>
          <td class="statusTD">
            <div
              class="status"
              :class="{
                green: sub.status === 'active' || sub.status === 'scheduled',
                yellow: sub.status === 'past due',
                red: sub.status === 'unpaid' || sub.status === 'canceled',
              }"
            ></div>
            {{ sub.status }}
          </td>
        </tr>
      </tbody>
    </table>
  </ul>
</template>

<script>
export default {
  emits: ['select'],
  props: {
    subscriptions: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.none {
  padding: 16px;
  border-radius: 5px;
  border: 1px solid var(--clr-light);
  text-align: center;

  p {
    margin-top: 8px;
  }
}

.subscriptions {
  table {
    tbody {
      td {
        .status {
          height: 15px;
          width: 15px;
          border-radius: 50%;
        }

        .green {
          background-color: var(--clr-success);
        }
        .yellow {
          background-color: var(--clr-warning);
        }
        .red {
          background-color: var(--clr-danger);
        }

        &:first-child {
          padding-left: 24px;
        }

        &:last-child {
          padding-right: 24px;
        }

        p {
          display: inline;
          padding: 5px 16px;
          border-radius: 50px;

          i {
            font-size: 10px;
            margin-right: 8px;
          }
        }
      }

      .statusTD {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;
      }
    }
  }
}
</style>
