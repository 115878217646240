<template>
  <section class="adminSettings">
    <div class="adminSettings_head">
      <h2>Admin Settings</h2>
    </div>
    <div class="adminSettings_body">
      <form @submit.prevent="submit" class="form">
        <div class="form_body">
          <BaseInput
            :validators="validators.code"
            inputType="password"
            label="Manager Code"
            subtitle="Used to temporarily unlock most actions and other use cases. Any value is accepted i.e. '1234' or 'secret'."
            :value="newSettings.managerCode"
            @input="newSettings.managerCode = $event"
          />
          <div class="toggle">
            <Toggle
              :checked="newSettings.useRequestedServiceTurnsValue"
              @toggle="
                newSettings.useRequestedServiceTurnsValue =
                  !newSettings.useRequestedServiceTurnsValue
              "
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Use Requested Services Turn Value</p>
              <p class="toggle_text_subtext">
                With this option selected, if a service is set to requested to a
                team member, the turn value will default to the value set in the
                next field.
              </p>
            </div>
          </div>
          <div
            v-if="newSettings.useRequestedServiceTurnsValue"
            class="turnValue"
          >
            <BaseInput
              inputType="number"
              step="0.5"
              label="Requested Service Turn Value"
              :value="newSettings.requestedServiceTurnsValue"
              @input="newSettings.requestedServiceTurnsValue = $event"
            />
          </div>
          <div class="toggle">
            <Toggle
              :checked="newSettings.enableAlerts"
              @toggle="newSettings.enableAlerts = !newSettings.enableAlerts"
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Enable Alerts</p>
              <p class="toggle_text_subtext">
                With this option selected, team members will be able to be
                alerted via text message. The cost of each text message sent is
                approximately $0.01.
              </p>
            </div>
          </div>
          <BaseTextarea
            v-if="newSettings.enableAlerts"
            label="Alert Message"
            subtitle="Max Length 100"
            :value="newSettings.alertMessage"
            @input="newSettings.alertMessage = $event"
          />

          <div class="toggle">
            <Toggle
              :checked="newSettings.takeFeeFirstForCommission"
              @toggle="
                newSettings.takeFeeFirstForCommission =
                  !newSettings.takeFeeFirstForCommission
              "
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Take Fee Off First</p>
              <p class="toggle_text_subtext">
                Take the fees off of the service total first before calculating
                team member commission. i.e. $20 service with $5 fee on a team
                member with 60% commission will be calculate as such: 20 - 5 =
                15 * .6 = 9. The default is to take the fee after which will
                calculate the commission as such: 20 * .6 = 12 - 5 = 7.
              </p>
            </div>
          </div>

          <div class="toggle">
            <Toggle
              :checked="newSettings.defaultTrackHourly"
              @toggle="
                newSettings.defaultTrackHourly = !newSettings.defaultTrackHourly
              "
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Default Track Hourly</p>
              <p class="toggle_text_subtext">
                Default "track hourly" on the add team member form to enabled
              </p>
            </div>
          </div>

          <div class="toggle">
            <Toggle
              :checked="newSettings.recalculateTicketAtCreation"
              @toggle="
                newSettings.recalculateTicketAtCreation =
                  !newSettings.recalculateTicketAtCreation
              "
            />
            <div class="toggle_text">
              <p class="toggle_text_label">
                Recalculate ticket items at creation from appointment
              </p>
              <p class="toggle_text_subtext">
                Recalculate tickets at time of appointment arrival. For example,
                if a service has fees at the time the client booked an
                appointment, but the service fees have since changed, use the
                updated fees. This also applies to technician commissions.
              </p>
            </div>
          </div>

          <BaseSelect
            label="Default Calendar Staff Filter"
            :options="filterOptions"
            :value="newSettings.defaultCalendarFilter"
            @input="newSettings.defaultCalendarFilter = $event"
          />

          <BaseSelect
            label="Default Home Screen"
            :options="homeScreenOptions"
            :value="newSettings.defaultMainScreen"
            @input="newSettings.defaultMainScreen = $event"
          />

          <!-- Overtime -->
          <div class="toggle">
            <Toggle
              :checked="newSettings.overtimeEnabled"
              @toggle="
                newSettings.overtimeEnabled = !newSettings.overtimeEnabled
              "
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Overtime Enabled</p>
            </div>
          </div>

          <BaseInput
            v-if="newSettings.overtimeEnabled"
            inputType="number"
            step="1"
            label="Overtime Start"
            subtitle="The time in hours that overtime will start. i.e. 40 hours"
            :value="+newSettings.overtimeStart"
            @input="newSettings.overtimeStart = +$event"
          />

          <BaseInput
            v-if="newSettings.overtimeEnabled"
            inputType="number"
            step="0.5"
            label="Overtime Rate"
            subtitle="The rate in dollars per hour that overtime will be calculated at. i.e. 1.5x Base Pay"
            :value="+newSettings.overtimeRate"
            @input="newSettings.overtimeRate = +$event"
          />

          <!-- Package Emails -->
          <div class="toggle">
            <Toggle
              :checked="newSettings.enablePackageEmails"
              @toggle="
                newSettings.enablePackageEmails =
                  !newSettings.enablePackageEmails
              "
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Package Emails Enabled</p>
              <p class="toggle_text_subtext">
                Send an email to clients upon service package sale and usage,
                standard email sending charges apply.
              </p>
            </div>
          </div>
        </div>
        <div class="form_actions">
          <BaseButton :disabled="loading || !formValid"
            ><i v-if="loading" class="fas fa-spinner"></i> Submit</BaseButton
          >
        </div>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  created() {
    if (!this.salon) return;
    this.init();
  },
  data() {
    return {
      loading: false,

      newSettings: {
        managerCode: '',
        useRequestedServiceTurnsValue: false,
        requestedServiceTurnsValue: 0,
        enableAlerts: false,
        alertMessage: 'You are being alerted!',
        takeFeeFirstForCommission: false,
        defaultTrackHourly: false,
        recalculateTicketAtCreation: false,
        defaultMainScreen: 'calendar',
        overtimeEnabled: false,
        overtimeStart: 40,
        overtimeRate: 1.5,
        enablePackageEmails: false,
      },
    };
  },
  computed: {
    formValid() {
      if (!this.newSettings.managerCode) return false;
      return true;
    },
    salon() {
      return this.$store.state.salon.salon;
    },
    validators() {
      return {
        code: {
          maxLength: 100,
        },
      };
    },
    filterOptions() {
      return [
        {
          option: 'Working Staff',
          value: 'Working Staff',
        },
        {
          option: 'Scheduled Staff',
          value: 'Scheduled Staff',
        },
        {
          option: 'All Staff',
          value: 'All Staff',
        },
      ];
    },
    homeScreenOptions() {
      return [
        {
          option: 'Calendar',
          value: 'calendar',
        },
        {
          option: 'Classic',
          value: 'classic',
        },
      ];
    },
  },
  watch: {
    salon() {
      this.init();
    },
  },
  methods: {
    init() {
      if (!this.salon) return;

      this.newSettings = JSON.parse(JSON.stringify(this.salon.adminSettings));
    },
    async submit() {
      this.loading = true;

      try {
        await this.$store.dispatch(
          'salon/updateAdminSettings',
          this.newSettings
        );

        this.$store.state.auth.salon.adminSettings.managerCode =
          this.newSettings.managerCode;

        this.$toast.success('Salon info updated');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.adminSettings {
  max-width: 1200px;

  &_head {
    h2 {
      font-size: 28px;
    }

    p {
      margin-top: 6px;
    }
  }

  &_body {
    margin-top: 32px;

    .form {
      &_body {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .toggle {
          display: flex;
          align-items: center;
          gap: 32px;
          flex-wrap: wrap;
          padding: 16px 0;

          &_text {
            &_label {
              font-size: 18px;
            }
            &_subtext {
              margin-top: 5px;
              font-size: 14px;
            }
          }
        }
      }
      &_actions {
        margin-top: 32px;
        display: flex;
        justify-content: flex-end;
        gap: 16px;
      }
    }
  }
}
</style>
