<template>
  <section class="giftcardDetails">
    <div class="giftcardDetails_head">
      <div class="avatar">
        <Avatar
          mode="large rounded"
          :name="giftcard.client ? giftcard.client.firstName : 'No Client'"
        />
        <div class="avatar_text">
          <h2>{{ clientName }}</h2>
          <p v-if="giftcard.client" class="small">
            {{ giftcard.client.phoneNumber }}
          </p>
          <p v-if="giftcard.client" class="small">
            {{ giftcard.client.email }}
          </p>
          <p v-else>No Client</p>
        </div>
      </div>
    </div>
    <div class="giftcardDetails_actions" v-if="canEditGiftcards">
      <div class="action" @click="showGiftCardModal = true">
        <i class="fas fa-edit"></i>
        <p>Edit</p>
      </div>
      <div class="action red" @click="showConfirmDelete = true">
        <i class="fas fa-trash"></i>
        <p>Delete</p>
      </div>
    </div>
    <p>Transaction History:</p>
    <div
      v-if="giftcard.transactions.length"
      class="giftcardDetails_transactions"
    >
      <ul>
        <li v-for="transaction in giftcard.transactions" :key="transaction._id">
          <div class="group">
            <p>Update by:</p>
            <p class="bold">{{ transaction.update_by.name }}</p>
          </div>
          <div class="group">
            <p>On:</p>
            <p class="bold">{{ formatDate(transaction.date) }}</p>
          </div>

          <div class="group">
            <p>Amount Change:</p>
            <p class="bold">From: {{ `$${transaction.from.toFixed(2)}` }}</p>
            <p class="bold">To: {{ `$${transaction.to.toFixed(2)}` }}</p>
          </div>
          <div class="group">
            <p>Ticket:</p>
            <p class="bold">
              {{ transaction.ticket ? transaction.ticket : 'Manual update' }}
            </p>
          </div>
        </li>
      </ul>
    </div>
    <div v-else class="none">
      <h3>No data to display</h3>
      <p>This gift card has no transaction history</p>
    </div>
  </section>

  <Modal
    title="Gift card form"
    :show="showGiftCardModal"
    @close="showGiftCardModal = false"
  >
    <div class="giftcard-form">
      <GiftcardForm
        :giftcard="giftcard"
        @submitted="
          $emit('updated', $event);
          showGiftCardModal = false;
        "
      />
    </div>
  </Modal>

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Delete gift card"
    text="Are you sure you wish to delete this gift card? This action can not be undone."
    @confirm="removegiftcard"
    @deny="showConfirmDelete = false"
  />
</template>

<script>
import GiftcardForm from '@/views/giftcards/GiftcardForm.vue';

export default {
  emits: ['deleted'],
  components: {
    GiftcardForm,
  },
  computed: {
    clientName() {
      if (!this.giftcard.client) return;

      return `${this.giftcard.client.firstName} ${this.giftcard.client.lastName}`;
    },
  },
  props: {
    giftcard: {
      type: Object,
      required: true,
    },
  },
  async created() {
    this.canEditGiftcards = await this.$store.dispatch(
      'auth/activeUserHasPermission',
      'other/editGiftcards'
    );
  },
  data() {
    return {
      showGiftCardModal: false,
      showConfirmDelete: false,
      canEditGiftcards: false,
    };
  },
  methods: {
    async removegiftcard() {
      try {
        await this.$store.dispatch('giftcards/deleteGiftcard', this.giftcard);

        this.$emit('deleted');
        this.$toast.success('Gift card deleted');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    formatDate(date) {
      return this.$moment(date).format('LLL');
    },
  },
};
</script>

<style lang="scss" scoped>
.giftcardDetails {
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;

  &_head {
    .avatar {
      display: flex;
      align-items: center;
      gap: 32px;

      &_text {
        h2 {
          margin-bottom: 5px;
        }

        .small {
          font-size: 12px;
          color: var(--clr-link);
        }
      }
    }
  }

  &_actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;

    .red {
      color: var(--clr-danger);
      border-color: var(--clr-danger-light) !important;

      &:hover {
        border-color: var(--clr-danger) !important;
      }

      i {
        color: var(--clr-danger) !important;
      }
    }
    .green {
      color: var(--clr-success);
      border-color: var(--clr-success-light) !important;

      &:hover {
        border-color: var(--clr-success) !important;
      }

      i {
        color: var(--clr-success) !important;
      }
    }

    .action {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      padding: 8px;
      width: 90px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      transition: border-color 0.2s ease;
      cursor: pointer;

      i {
        color: var(--clr-gray);
        font-size: 24px;
      }

      &:hover {
        border-color: var(--clr-gray);
      }
    }
  }

  &_transactions {
    ul {
      border: 1px solid var(--clr-light);
      border-radius: 5px;

      li {
        display: flex;
        flex-direction: column;
        gap: 16px;
        font-size: 11px;
        padding: 16px;
        color: var(--clr-gray);

        .col {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        .right {
          text-align: right;
        }

        .bold {
          font-weight: 700;
          color: #111;
          font-size: 16px;
        }

        &:not(:first-child) {
          border-top: 1px solid var(--clr-light);
        }
      }
    }
  }
}

.none {
  text-align: center;
  padding: 16px;
  border: 1px solid var(--clr-light);
  border-radius: 5px;

  p {
    margin-top: 8px;
  }
}

.giftcard-form {
  padding: 32px;
}
</style>
