<template>
  <section class="ticketForm">
    <Spinner v-if="loading" fullscreen />

    <div
      v-if="!mobileMode || (mobileMode && mobileDisplay === 'items')"
      class="items"
    >
      <div v-if="canEditDate" class="date">
        <div @click="toggleDatePicker" class="dd">
          <h2>{{ selectedDateString }}</h2>
          <i class="fas fa-angle-down"></i>
        </div>

        <div class="datepicker">
          <DatePicker
            v-if="showDatePicker"
            :selectedDate="newTicket.checkout_at"
            @selectDate="selectDate"
            @hide="showDatePicker = false"
          />
        </div>
      </div>

      <div class="items_body">
        <div class="items_body_tickets" v-if="newTicket.items.length">
          <div
            class="items_body_tickets_item"
            v-for="(item, index) in newTicket.items"
            :key="item._id"
          >
            <TicketItem
              v-bind:item="item"
              :ticketStatus="newTicket.status"
              @update="updateItem(index, $event)"
              @remove="removeItem(index)"
              @refund="refundItem(item)"
              @selectPackage="selectPackage($event)"
              @addQuickSuggestion="selectItem($event)"
            />
          </div>
        </div>
        <div v-else class="items_body_empty">
          <i class="fas fa-cart-plus"></i>
          <h3>This ticket is empty. Add an item to get started</h3>
        </div>

        <TicketActions
          :ticket="newTicket"
          :total="total"
          :tipLoading="tipLoading"
          @showGuidedCheckout="showGuidedCheckout = true"
          @addItem="
            selectItemPage = '';
            dontCloseModalOnAddItem = false;
            showAddItem = true;
          "
          @addMultipleItems="
            selectItemPage = '';
            dontCloseModalOnAddItem = true;
            showAddItem = true;
          "
          @addTip="startTipLogic"
          @approveItems="approveItems"
          @addCashDiscountToItems="addCashDiscountToItems"
          @removeCashDiscountToItems="removeCashDiscountToItems"
          @deleteTicket="showConfirmDelete = true"
        />

        <!-- Note -->
        <BaseTextarea
          class="ticket_notes"
          label="Ticket notes"
          placeholder="Ticket note (only visible to staff)"
          :value="newTicket.note"
          :validators="validators.note"
          @input="newTicket.note = $event"
          rows="3"
        />

        <TicketSummary
          :ticket="newTicket"
          @removePayment="removePayment($event)"
          @selectPayment="selectedPayment = $event"
          @removeTip="removeTip($event)"
          @providedChange="providedChange"
        />
      </div>
    </div>
    <div
      v-if="!mobileMode || (mobileMode && mobileDisplay === 'client')"
      class="client"
    >
      <div class="client_search">
        <div class="client_search_input">
          <form @submit.prevent="searchClients" class="form">
            <BaseInput
              placeholder="Search Client"
              icon="fas fa-search"
              @input="clientSearchValue = $event"
            />
          </form>
        </div>
        <div
          class="client_search_create"
          v-if="!newTicket.client"
          @click="setTabToCreateClient()"
        >
          <p><i class="fas fa-plus"></i>Create New Client</p>
        </div>

        <!-- Pending Client -->
        <div
          v-if="
            !newTicket.client &&
            newTicket.kioskClient &&
            (newTicket.kioskClient.firstName ||
              newTicket.kioskClient.lastName ||
              newTicket.kioskClient.email ||
              newTicket.kioskClient.phone)
          "
          class="alert alert-info pending"
        >
          <p>Client information awaiting creation</p>
        </div>

        <div class="client_search_dynamic">
          <keep-alive>
            <component
              v-bind:is="currentTabComponent"
              :client="
                addFamilyFriendMode
                  ? null
                  : newTicket.client ||
                    newTicket.kioskClient ||
                    clientFromSearch
              "
              :clients="clients"
              :familyFriendParent="
                addFamilyFriendMode ? newTicket.client : null
              "
              basic
              @created="setClient($event)"
              @familyFriendCreated="familyFriendCreated($event)"
              @removeClient="setClient(null)"
              @editClient="setClient($event, true)"
              @addFamilyFriend="
                addFamilyFriendMode = true;
                setTabToCreateClient(true);
              "
              @setFamilyFriend="setFamilyFriend($event)"
              @selectClient="setClient($event)"
              @viewPackages="viewPackages"
            ></component>
          </keep-alive>
        </div>
        <div
          v-if="
            newTicket._id &&
            caPFSetup &&
            !clientPaymentSent &&
            newTicket.client &&
            newTicket.client.phoneNumber &&
            newTicket.status !== 'completed' &&
            newTicket.status !== 'refunded'
          "
          class="clientPaymentForm"
        >
          <BaseButton
            :disabled="clientPaymentLoading"
            @click="sendClientPaymentForm"
            mode="secondary"
            ><i v-if="clientPaymentLoading" class="fas fa-spinner"></i> Send
            Client Payment Form</BaseButton
          >
        </div>
      </div>
    </div>
    <div
      class="payment"
      v-if="
        (!mobileMode || (mobileMode && mobileDisplay === 'pay')) &&
        (newTicket.items.length || newTicket.tips.length) &&
        canCheckout
      "
    >
      <TicketPaymentOptions
        :ticket="newTicket"
        :checkoutOnlyMode="checkoutOnlyMode"
        :loading="loading"
        @addPayment="addPayment($event)"
        @selectedOtherPaymentMethod="selectOtherPaymentMethod"
        @updatePaymentAmount="updatePaymentAmount(+$event)"
        :amount="paymentAmount"
        :balance="balance"
        @verifySave="verifySave($event.one, $event.two, $event.three)"
      />
    </div>
    <div
      v-else-if="
        !mobileMode ||
        (mobileMode && mobileDisplay === 'pay' && newTicket.items.length)
      "
      class="justSave"
    >
      <transition name="checkout">
        <div class="payment_save">
          <BaseButton
            v-if="!checkoutOnlyMode"
            :disabled="loading"
            mode="secondary-outline"
            @click="verifySave(false)"
            ><i v-if="loading" class="fas fa-spinner"></i> Save</BaseButton
          >
        </div>
      </transition>
    </div>
  </section>

  <div class="mobile">
    <div @click="showMobileMenu = !showMobileMenu" class="float">
      <i class="fas fa-bars"></i>
    </div>

    <ul v-if="showMobileMenu" class="menu">
      <li @click="mobileDisplay = 'items'">
        <i class="fas fa-hand-sparkles"></i>
      </li>
      <li @click="mobileDisplay = 'client'">
        <i class="fas fa-user"></i>
      </li>
      <li @click="mobileDisplay = 'pay'">
        <i class="fas fa-cash-register"></i>
      </li>
    </ul>
  </div>

  <!-- <ul v-if="mobileMode" class="mobileNavBar">
    <li @click="mobileDisplay = 'items'">
      <i class="fas fa-hand-sparkles"></i>
    </li>
    <li @click="mobileDisplay = 'client'">
      <i class="fas fa-user"></i>
    </li>
    <li @click="mobileDisplay = 'pay'">
      <i class="fas fa-cash-register"></i>
    </li>
  </ul> -->

  <Modal
    :show="selectedPayment"
    title="Payment options"
    @close="selectedPayment = null"
    :zIndex="100010"
  >
    <div class="paymentOptions">
      <PaymentOptions
        @refunded="refundPayment($event)"
        :payment="selectedPayment"
        @close="selectedPayment = null"
        @edit="editPayment($event)"
      />
    </div>
  </Modal>

  <Modal
    :show="showChargeGiftcardModal"
    title="Charge gift card"
    @close="showChargeGiftcardModal = false"
    :zIndex="100010"
  >
    <div class="chargeGiftcard">
      <ChargeGiftcard
        :client="newTicket.client ? newTicket.client : null"
        :amount="paymentToBeChargedAmount"
        :ticketNumber="newTicket.number"
        @charged="paymentCharged($event)"
      />
    </div>
  </Modal>

  <Confirm
    v-if="showConfirmExitCharge"
    title="Exit Charge Form"
    text="There is an outstanding payment ID, this may mean a payment has been collected without Raserva retrieving this data. Please exit and press 'Query Transaction' if you are unsure. Otherwise confirm to exit."
    @confirm="confirmExitCharge"
    @deny="showConfirmExitCharge = false"
    :zIndex="100015"
  />

  <Modal
    :show="showChargePaymentModal"
    title="Charge payment"
    @close="closeChargePayment"
    :zIndex="100010"
  >
    <div class="chargePayment">
      <ChargePayment
        :amount="paymentToBeChargedAmount"
        :items="newTicket.items"
        :tax="tax"
        :client_email="newTicket.client ? newTicket.client.email : null"
        @charged="paymentCharged($event)"
        @close="closeChargePayment"
      />
    </div>
  </Modal>

  <Modal
    :show="showAddItem"
    :maxWidth="classicView ? '100%' : '550px'"
    :minHeight="classicView ? '' : '90vh'"
    maxHeight="90vh"
    title="Add Item"
    @close="showAddItem = false"
    :zIndex="100010"
  >
    <template v-if="classicView" v-slot:header>
      <BaseInput
        placeholder="Search.."
        @input="$refs.classicPOSItems.search = $event"
      />
      <BaseButton @click="showAddItem = false" mode="primary"
        >Continue</BaseButton
      >
    </template>

    <SelectItem
      v-if="!classicView"
      :client="newTicket.client"
      @selectItem="selectItem($event, dontCloseModalOnAddItem)"
      @addPackage="addPackage($event)"
      @addPackageItem="addPackageItem($event)"
      :page="selectItemPage"
    />
    <ClassicPOSItems
      v-else
      ref="classicPOSItems"
      @close="showAddItem = false"
      @selectItem="selectItem($event, true)"
      @removeItem="removeItem($event)"
      :items="newTicket.items"
    />
  </Modal>

  <Modal
    :show="showAddTip"
    :maxWidth="!showFinalTipStep ? '850px' : '450px'"
    @close="showAddTip = false"
    title="Add Tip"
    :zIndex="100010"
  >
    <AddTip
      v-if="showFinalTipStep"
      :total="total"
      :providedAmount="tipAmount"
      :suggestedStaff="ticketFirstStaff"
      :ticketItems="newTicket.items"
      @submit="submitTip($event)"
    />
    <CardconnectAddTip
      v-if="salonProcessor === 'cardconnect' && !showFinalTipStep"
      :total="subtotal"
      @submit="
        tipAmount = +$event;
        showFinalTipStep = true;
      "
    />
  </Modal>

  <Modal
    title="Other payment type"
    :show="showSelectOtherPaymentOptions"
    @close="showSelectOtherPaymentOptions = false"
    maxWidth="300px"
    zIndex="999999"
  >
    <form
      @submit.prevent="selectOtherPaymentOption"
      class="form otherPaymentTypeForm"
    >
      <BaseSelect
        :options="otherPaymentOptions"
        :value="selectedOtherPaymentOption"
        @input="selectedOtherPaymentOption = $event"
      />
      <BaseTextarea
        label="Note"
        @input="selectedOtherPaymentNote = $event"
        placeholder="Optional note"
      />

      <div class="form_actions">
        <BaseButton>Submit</BaseButton>
      </div>
    </form>
  </Modal>

  <GetStaffAccount
    v-if="showGetStaffAccount"
    @submitted="retrievedStaffAccount($event)"
    @exit="showGetStaffAccount = false"
  />

  <GetStaffAccount
    v-if="showGetStaffCodeForUnapprovedItems"
    @submitted="submitCodeForUnapprovedItem($event)"
    @exit="showGetStaffCodeForUnapprovedItems = false"
  />

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Delete ticket"
    text="Are you sure you wish to delete this ticket? This action can not be undone."
    @confirm="deleteTicket"
    @deny="showConfirmDelete = false"
  />

  <Modal
    title="Package Details"
    :show="selectedPackage"
    @close="selectedPackage = null"
  >
    <div class="packageDetails">
      <ul>
        <li v-for="item in selectedPackage.items" :key="item._id">
          <div class="itemDetails">
            <h2>{{ item.item.title }}</h2>
            <p class="light">${{ item.item.price }}</p>
          </div>
          <BaseButton @click="addItemFromPackage(item)"
            >Add Item to Ticket</BaseButton
          >
        </li>
      </ul>
    </div>
  </Modal>

  <Modal
    title="Cleaning Log Items"
    :show="showCleaningLogModal"
    @close="showCleaningLogModal = null"
    maxWidth="600px"
  >
    <div class="cleaningLogItems">
      <ul>
        <li v-for="(item, index) in cleaningLogItems" :key="index">
          <div class="col">
            <h3>{{ item.item.title }}</h3>
            <p v-if="item.staff" class="light">
              {{ `${item.staff.firstName} ${item.staff.lastName}` }}
            </p>
          </div>
          <div class="col">
            <BaseInput label="Chair Number" @input="item.chair = $event" />
          </div>
        </li>
      </ul>

      <div class="cleaningLogItems_note">
        <BaseTextarea
          label="Note"
          @input="cleaningLogNote = $event"
          placeholder="Optional note"
        />
      </div>

      <div class="cleaningLogItems_actions">
        <BaseButton
          @click="submitCleaningLogs('pending')"
          mode="primary-outline"
          >Pending</BaseButton
        >
        <BaseButton @click="submitCleaningLogs('completed')"
          >Completed</BaseButton
        >
      </div>
    </div>
  </Modal>

  <Modal
    title="Guided Checkout"
    :show="showGuidedCheckout"
    @close="showGuidedCheckout = null"
    maxWidth="1800px"
  >
    <div class="guidedCheckout">
      <GuidedCheckout
        :ticket="newTicket"
        :amount="paymentAmount"
        :balance="balance"
        :checkoutOnlyMode="checkoutOnlyMode"
        :loading="loading"
        @updateItem="updateItem($event.index, $event.item)"
        @removeItem="removeItem($event)"
        @refundItem="refundItem($event)"
        @selectPackage="selectPackage($event)"
        @addQuickSuggestion="selectItem($event)"
        @selectItem="selectItem($event)"
        @removePayment="removePayment($event)"
        @selectPayment="selectedPayment = $event"
        @removeTip="removeTip($event)"
        @addPayment="addPayment($event)"
        @selectedOtherPaymentMethod="selectOtherPaymentMethod"
        @updatePaymentAmount="updatePaymentAmount(+$event)"
        @addTip="startTipLogic"
        @approveItems="approveItems"
        @addCashDiscountToItems="addCashDiscountToItems"
        @removeCashDiscountToItems="removeCashDiscountToItems"
        @deleteTicket="showConfirmDelete = true"
        @verifySave="verifySave($event.one, $event.two, $event.three)"
        @providedChange="providedChange"
      />
    </div>
  </Modal>
</template>

<script>
import TicketItem from '@/components/tickets/TicketItem.vue';
import SelectItem from '@/components/tickets/SelectItem.vue';
import AddTip from '@/components/tickets/AddTip.vue';
import ClientForm from '@/components/clients/ClientForm.vue';
import ClientInfo from '@/components/clients/ClientInfo.vue';
import ClientList from '@/components/clients/ClientList.vue';
import PaymentOptions from '@/components/tickets/PaymentOptions.vue';
import ChargePayment from '@/components/tickets/ChargePayment.vue';
import ChargeGiftcard from '@/components/tickets/ChargeGiftcard.vue';
import CardconnectAddTip from '@/components/tickets/CardconnectAddTip.vue';
import GetStaffAccount from '@/components/staff/GetStaffAccount.vue';
import ClassicPOSItems from '@/views/classic/pos/ClassicPOSItems.vue';
import DatePicker from '@/components/components/DatePicker.vue';
import GuidedCheckout from '@/components/tickets/GuidedCheckout.vue';
import TicketSummary from '@/components/tickets/TicketSummary.vue';
import TicketPaymentOptions from '@/components/tickets/TicketPaymentOptions.vue';
import TicketActions from '@/components/tickets/TicketActions.vue';

export default {
  emits: ['created', 'edited', 'checkedOut', 'deleted', 'close', 'rebook'],
  components: {
    TicketItem,
    SelectItem,
    AddTip,
    PaymentOptions,
    ChargePayment,
    ChargeGiftcard,
    CardconnectAddTip,
    GetStaffAccount,
    ClassicPOSItems,
    DatePicker,
    GuidedCheckout,
    TicketSummary,
    TicketPaymentOptions,
    TicketActions,
  },
  props: {
    ticket: {
      type: Object,
    },
    preSelectedStaff: {
      type: Object,
    },
    classicView: {
      type: Boolean,
      default: false,
    },
    canEditDate: {
      type: Boolean,
      default: false,
    },
    checkoutOnlyMode: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    if (screen.width <= 900) {
      this.mobileMode = true;
    }

    if (this.ticket) {
      this.newTicket = JSON.parse(
        JSON.stringify({
          ...this.newTicket,
          ...this.ticket,
        })
      );

      if (this.ticket.client) this.currentTabComponent = ClientInfo;
    }

    if (this.classicView) this.showAddItem = true;

    this.canCheckout = await this.$store.dispatch(
      'auth/activeUserHasPermission',
      'tickets/checkOutTickets'
    );
  },
  async unmounted() {
    if (
      !this.newTicket._id &&
      this.newTicket.payments.length &&
      !this.checkedOut &&
      !this.saved
    ) {
      try {
        this.save();
      } catch (error) {
        this.$toast.error(error.message);
      }
    }
  },
  data() {
    return {
      canCheckout: false,
      showMobileMenu: false,
      showGuidedCheckout: false,

      currentTabComponent: null,
      showAddItem: false,
      showAddTip: false,
      showConfirmDelete: false,
      newTicket: {
        created_at: new Date(Date.now()),
        status: 'in-progress',
        client: null,
        items: [],
        tips: [],
        payments: [],
        note: '',
      },

      selectedPackage: null,

      showGetStaffAccount: false,
      showSelectOtherPaymentOptions: false,
      selectedOtherPaymentOption: 'groupon',
      selectedOtherPaymentNote: '',
      staffAccountForOtherPaymentMethod: null,
      cashDiscounted: false,
      showDatePicker: false,

      showGetStaffCodeForUnapprovedItems: false,

      addFamilyFriendMode: false,

      checkedOut: false,
      saved: false,
      clientSearchValue: '',
      clients: [],

      loading: false,
      tipLoading: false,

      clientPaymentLoading: false,
      clientPaymentSent: false,

      showFinalTipStep: false,
      tipAmount: null,

      paymentAmount: null,
      selectedPayment: null,
      showChargePaymentModal: false,
      showChargeGiftcardModal: false,
      showConfirmExitCharge: false,

      clientFromSearch: {
        phoneNumber: null,
      },

      mobileMode: false,
      mobileDisplay: 'items',

      cleaningLogItems: [],
      cleaningLogNote: '',
      showCleaningLogModal: false,

      selectItemPage: '',
      dontCloseModalOnAddItem: false,
    };
  },
  computed: {
    selectedDateString() {
      return this.newTicket.checkout_at
        ? this.$moment(this.newTicket.checkout_at).format('dddd[,] ll')
        : this.$moment().format('dddd[,] ll');
    },
    validators() {
      return {
        note: {
          maxLength: 500,
        },
      };
    },
    ticketHasStaffOwnItems() {
      if (!this.tempUser) {
        const self = this.$store.state.auth.loggedInSalonStaff;

        if (
          this.newTicket.items.some((item) => {
            if (item.staff) {
              return item.staff.staffId === self.staffId;
            }
          })
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          this.newTicket.items.some((item) => {
            if (item.staff) {
              return item.staff.staffId === this.tempUser.staffId;
            }
          })
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    managerMode() {
      return this.$store.state.auth.managerMode;
    },
    tempUser() {
      return this.$store.state.auth.tempUser;
    },
    loggedInStaffIsAdmin() {
      return this.$store.getters['auth/loggedInStaffIsAdmin'];
    },
    cashDiscountAdditionalProcessingFee() {
      return this.$store.state.auth.salon.payments
        .cashDiscountAdditionalProcessingFee;
    },
    usesCashDiscount() {
      return this.$store.state.auth.salon.payments.useCashDiscount;
    },
    ticketHasCashDiscount() {
      return this.newTicket.items.some((item) => item.item.cashDiscount);
    },
    salonProcessor() {
      return this.$store.state.auth.salon.payments.processor;
    },
    cardSurcharge() {
      if (!this.usesCashDiscount) return 0;

      if (!this.newTicket.items.length) {
        return 0;
      }

      let total = 0;

      this.newTicket.items.forEach((item) => {
        if (item.item.prepaid) return;

        total +=
          (item.item.cashDiscountAdditionalProcessingFee || 0) * item.quantity;
      });

      // this.newTicket.tips.forEach((tip) => {
      //   total +=
      //     tip.amount *
      //     (this.$store.state.auth.salon.payments
      //       .cashDiscountAdditionalProcessingFee *
      //       0.01);
      // });

      return total;
    },
    subtotal() {
      if (!this.newTicket.items.length) {
        return 0;
      }

      let total = 0;

      this.newTicket.items.forEach((item) => {
        if (item.item.prepaid) return;

        if (item.item.basePrice) {
          total +=
            (item.item.basePrice - (item.item.discountAmount || 0)) *
            item.quantity *
            (item.item.priceType === 'duration' ? item.item.duration : 1);
        } else {
          total +=
            (item.item.price -
              (item.item.discountAmount || 0) -
              (item.item.cashDiscountAdditionalProcessingFee || 0)) *
            item.quantity *
            (item.item.priceType === 'duration' ? item.item.duration : 1);
        }
      });

      return total;
    },
    total() {
      // We have to add this small amount because 0.075 rounded in Javascript is 0.07 (like wtf dude)
      return this.subtotal + this.tax + this.cardSurcharge + 0.000000001;
    },
    tax() {
      if (!this.newTicket.items.length) {
        return 0;
      }

      let tax = 0;
      this.newTicket.items.forEach((item) => {
        if (item.item.prepaid) return;

        tax +=
          (item.item.price - (item.item.discountAmount || 0)) *
          (item.item.taxRate * 0.01) *
          item.quantity;
      });

      return tax;
    },
    balance() {
      let balance = this.total;

      // Add
      this.newTicket.tips.forEach((tip) => (balance += tip.amount));

      // Subtract
      this.newTicket.payments.forEach((payment) => (balance -= payment.amount));

      return +balance.toFixed(2);
    },
    ticketFirstStaff() {
      if (this.newTicket.items.length > 0) {
        return this.newTicket.items[0].staff;
      } else {
        return null;
      }
    },
    notCompletedOrRefunded() {
      return (
        this.newTicket.status !== 'completed' &&
        this.newTicket.status !== 'refunded'
      );
    },
    caPFSetup() {
      if (!this.$store.state.auth.salon) return;
      if (!this.$store.state.auth.salon.billing.chargeanywhere) return;

      return (
        this.$store.state.auth.salon.billing.chargeanywhere.pfmid &&
        this.$store.state.auth.salon.billing.chargeanywhere.pftid
      );
    },
    otherPaymentOptions() {
      return [
        {
          option: 'Groupon',
          value: 'groupon',
        },
        {
          option: 'Classpass',
          value: 'classpass',
        },
        {
          option: 'Other',
          value: 'other',
        },
      ];
    },
    autoCheckout() {
      if (!this.$store.state.auth.salon) return;
      return this.$store.state.auth.salon.payments.autoCheckout;
    },
  },
  methods: {
    addItemFromPackage(item) {
      this.selectedPackage.items.find((i) => i._id === item._id).quantity--;

      this.selectItem({ ...item.item, prepaid: true });

      this.selectedPackage = null;
    },
    selectPackage(packageId) {
      this.selectedPackage = this.newTicket.packages.find(
        (p) => p.packageId === packageId
      );
    },
    closeChargePayment() {
      if (this.$store.state.tickets.chargeanywherePaymentId) {
        this.showConfirmExitCharge = true;
      } else {
        this.showChargePaymentModal = false;
      }
    },
    confirmExitCharge() {
      this.showChargePaymentModal = false;
      this.showConfirmExitCharge = false;
      this.$store.state.tickets.chargeanywherePaymentId = null;
    },
    startTipLogic() {
      if (
        this.salonProcessor === 'stripe' ||
        this.salonProcessor === 'chargeanywhere' ||
        this.salonProcessor === 'dejavoo'
      ) {
        this.showFinalTipStep = true;
      }

      this.showAddTip = true;
    },
    providedChange() {
      if (this.balance >= 0) return;

      if (!this.newTicket.payments.some((payment) => payment.type === 'cash')) {
        this.$toast.error(
          'Could not find a cash payment to provide change for'
        );
        return;
      }

      const cashPayment = this.newTicket.payments.find(
        (payment) => payment.type === 'cash'
      );

      cashPayment.amount += this.balance;
    },
    formatDate(date) {
      return this.$moment(date).format('LL');
    },
    viewPackages() {
      this.selectItemPage = 'clientPackages';
      this.showAddItem = true;
    },
    submitCodeForUnapprovedItem(staff) {
      try {
        this.submitCodeForUnapprovedItem = false;

        this.checkout();

        this.$store.dispatch('auth/addAction', {
          staff: `${staff.firstName} ${staff.lastName}`,
          action: `checked out an unapproved ticket on date ${this.formatDate(
            this.newTicket.date
          )}${
            this.newTicket.client
              ? ` with client ${this.newTicket.client.firstName} ${this.newTicket.client.lastName}`
              : ''
          }`,
          type: 'ticket',
          metadata: this.newTicket,
        });
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    retrievedStaffAccount(staff) {
      this.showGetStaffAccount = false;
      this.staffAccountForOtherPaymentMethod = staff;
      this.showSelectOtherPaymentOptions = true;
    },
    selectOtherPaymentOption() {
      this.addPayment(
        'other',
        `${this.staffAccountForOtherPaymentMethod.firstName} ${this.staffAccountForOtherPaymentMethod.lastName}`,
        this.selectedOtherPaymentOption,
        this.selectedOtherPaymentNote
      );

      this.showSelectOtherPaymentOptions = false;
    },
    async selectOtherPaymentMethod() {
      if (
        !this.managerMode &&
        !this.loggedInStaffIsAdmin &&
        (await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'requireCode/useOtherPaymentMethod'
        ))
      ) {
        this.showGetStaffAccount = true;
      } else {
        this.staffAccountForOtherPaymentMethod = this.$store.state.auth.user;
        this.showSelectOtherPaymentOptions = true;

        // this.addPayment('other', `${staff.firstName} ${staff.lastName}`);
      }
    },
    selectDate(date) {
      const oldDate = this.$moment();

      const newDateWithOldDateHourAndMinute = this.$moment(date)
        .minute(oldDate.minute())
        .hour(oldDate.hour());

      this.newTicket.checkout_at = newDateWithOldDateHourAndMinute;

      this.showDatePicker = false;
    },
    toggleDatePicker() {
      this.showDatePicker = !this.showDatePicker;
    },
    currencyToString(amount) {
      if (typeof amount === 'string') return amount;

      return `$${amount.toFixed(2)}`;
    },
    setTabToCreateClient(override) {
      if (override) {
        this.currentTabComponent = ClientForm;
        return;
      }

      if (this.currentTabComponent) {
        if (this.newTicket.client) {
          this.currentTabComponent = ClientInfo;
          return;
        }

        this.currentTabComponent = null;
        return;
      }

      if (this.clientSearchValue) {
        this.clientFromSearch.phoneNumber = this.clientSearchValue;
      }

      this.currentTabComponent = ClientForm;
    },

    async setFamilyFriend(familyFriend) {
      try {
        const client = await this.$store.dispatch(
          'clients/getClientById',
          familyFriend.clientId
        );

        this.newTicket.client = client;
      } catch (err) {
        console.log(err);
      }
    },

    async submitCleaningLogs(status) {
      try {
        if (this.cleaningLogItems.some((i) => !i.chair)) {
          this.$toast.error('Please select a chair for each cleaning log item');
          return;
        }

        for (let i = 0; i < this.cleaningLogItems.length; i++) {
          const item = this.cleaningLogItems[i];

          await this.$axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/cleaningLogs`,
            {
              salonId: this.$store.state.auth.salon._id,
              staff: item.staff,
              chair: item.chair,
              client: this.newTicket.client,
              status,
              note: this.cleaningLogNote,
            },
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );

          this.newTicket.items.forEach((i) => {
            if (i.item.requiresCleaningLog) {
              i.item.cleaningLogCreated = true;
            }
          });
        }

        this.verifySave(true);
      } catch (err) {
        console.log(err);
      }
    },

    addCashDiscountToItems() {
      this.cashDiscounted = true;

      this.newTicket.items.forEach((item) => {
        item.item.cashDiscount = true;

        item.item.cashDiscountAdditionalProcessingFee = 0;

        if (typeof item.item.basePrice !== 'number') {
          const index = this.$store.getters[
            'items/productsAndServices'
          ].findIndex((preitem) => preitem._id === item.item._id);

          if (index !== -1) {
            item.item.basePrice =
              this.$store.getters['items/productsAndServices'][index].price;
          }
        }

        item.item.price =
          item.item.basePrice *
          (item.item.priceType === 'duration' ? item.item.duration : 1);
      });

      this.paymentAmount = this.balance;
    },

    removeCashDiscountToItems() {
      this.cashDiscounted = false;

      this.newTicket.items.forEach((item) => {
        item.item.cashDiscount = false;

        item.item.cashDiscountAdditionalProcessingFee =
          ((item.item.basePrice || item.item.price) -
            (item.item.discountAmount || 0)) *
          (this.$store.state.auth.salon.payments
            .cashDiscountAdditionalProcessingFee *
            0.01) *
          (item.item.priceType === 'duration' ? item.item.duration : 1);

        item.item.price += item.item.cashDiscountAdditionalProcessingFee;
      });

      this.paymentAmount = this.balance;
    },

    async sendClientPaymentForm() {
      this.clientPaymentLoading = true;

      try {
        await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.newTicket.salonId}/text`,
          {
            to: this.newTicket.client.phoneNumber,
            body: `Please follow this link to pay for your visit with ${this.$store.state.auth.salon.details.shopName}: https://www.raserva.com/tickets/${this.newTicket._id}`,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$toast.success('Payment form sent to clients phone');
        this.clientPaymentSent = true;
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.clientPaymentLoading = false;
    },

    setClient(client, saveTicket) {
      this.newTicket.client = client;

      if (client === null) {
        this.currentTabComponent = null;
        return;
      }

      if (saveTicket) {
        this.verifySave(false, true);
      }

      this.currentTabComponent = ClientInfo;
    },

    async familyFriendCreated(client) {
      try {
        const familyFriend = {
          clientId: client._id,
          firstName: client.firstName,
          lastName: client.lastName,
        };

        if (!this.newTicket.client.familyAndFriends) {
          this.newTicket.client.familyAndFriends = [];
        }

        this.newTicket.client.familyAndFriends.push(familyFriend);

        const newClient = await this.$store.dispatch(
          'clients/editClient',
          this.newTicket.client
        );

        this.newTicket.client = newClient;

        await this.verifySave(false, true);

        this.addFamilyFriendMode = false;
        this.currentTabComponent = ClientInfo;
      } catch (err) {
        console.log(err);
      }
    },

    async searchClients() {
      this.clients = await this.$store.dispatch('clients/searchClients', {
        name: this.clientSearchValue,
        email: this.clientSearchValue,
        phone: this.clientSearchValue,
      });
      this.currentTabComponent = ClientList;
    },

    approveItems() {
      if (this.managerMode) {
        this.newTicket.items.forEach((item) => {
          if (item.staff) {
            item.approval = `Manager approved on ${this.$moment().format(
              'lll'
            )}`;
          }
        });
      } else if (this.tempUser) {
        this.newTicket.items.forEach((item) => {
          if (item.staff) {
            if (item.staff.staffId === this.tempUser.staffId)
              item.approval = `${this.tempUser.firstName} ${
                this.tempUser.lastName
              } approved on ${this.$moment().format('lll')}`;
          }
        });
      } else if (this.loggedInStaffIsAdmin) {
        const adminName = this.$store.getters['auth/userFullName'];

        this.newTicket.items.forEach((item) => {
          if (item.staff) {
            item.approval = `${adminName} approved on ${this.$moment().format(
              'lll'
            )}`;
          }
        });
      } else {
        const self = this.$store.state.auth.loggedInSalonStaff;

        this.newTicket.items.forEach((item) => {
          if (item.staff) {
            if (item.staff.staffId === self.staffId)
              item.approval = `${self.firstName} ${
                self.lastName
              } approved on ${this.$moment().format('lll')}`;
          }
        });
      }
    },

    selectItem(preItem, dontHideModal) {
      if (!dontHideModal) {
        this.showAddItem = false;
      }

      let staff;

      if (preItem.useThisStaff && preItem.useThisStaff === 'nostaff') {
        staff = null;
      } else if (preItem.useThisStaff && preItem.useThisStaff !== 'nostaff') {
        staff = preItem.useThisStaff;
      } else if (this.preSelectedStaff) {
        staff = this.preSelectedStaff;
      } else {
        if (
          this.newTicket.items.length > 0 &&
          this.newTicket.items[this.newTicket.items.length - 1].staff
        ) {
          staff = JSON.parse(
            JSON.stringify(
              this.newTicket.items[this.newTicket.items.length - 1].staff
            )
          );
        } else {
          staff = this.$store.state.auth.loggedInSalonStaff;
        }
      }

      // Loyalty
      if (preItem.isLoyaltyRedemption) {
        const index = this.newTicket.client.loyalty.findIndex(
          (l) =>
            l.salonId.toString() === this.$store.state.auth.salon._id.toString()
        );

        if (index !== -1) {
          this.newTicket.client.loyalty[index].points -=
            preItem.loyaltyRedemptionCost;
        }
      }

      // Remove "Quick Assign"
      if (this.newTicket.items.length === 1) {
        const index = this.newTicket.items.findIndex(
          (item) => item.item.title === 'Quick Assign'
        );

        if (index !== -1) {
          this.newTicket.items.splice(index, 1);
        }
      }

      const item = JSON.parse(JSON.stringify(preItem));

      if (this.usesCashDiscount) {
        item.basePrice = item.price;

        item.cashDiscountAdditionalProcessingFee =
          item.price *
          (this.$store.state.auth.salon.payments
            .cashDiscountAdditionalProcessingFee *
            0.01) *
          (item.priceType === 'duration' ? item.duration : 1);

        item.price +=
          item.price *
          (this.$store.state.auth.salon.payments
            .cashDiscountAdditionalProcessingFee *
            0.01);
      }

      if (item.priceType === 'duration') {
        item.price = item.price * item.duration;
      }

      const newItem = {
        id: Math.random().toString(),
        item,
        staff,
        quantity: 1,
      };

      this.newTicket.items.push(newItem);
      this.$toast.success('Item added to ticket');

      this.sortItems();
    },

    sortItems() {
      // this.newTicket.items = this.newTicket.items.sort((a, b) => {
      //   // Sort by item.staff.firstName
      //   if (a.staff.firstName < b.staff.firstName) return -1;
      // });
      // this.$toast.info('Item list sorted');
    },

    addPackage(pack) {
      if (!this.newTicket.packages) this.newTicket.packages = [];

      this.newTicket.packages.push(pack);
    },

    addPackageItem(data) {
      // First try packageId if nothing then clientPackageID
      const packIndex = this.newTicket.client.packages.findIndex(
        (pack) => pack.packageId === data.clientPackageId
      );

      if (packIndex !== -1) {
        const itemIndex = this.newTicket.client.packages[
          packIndex
        ].items.findIndex((item) => item._id === data.packageItemId);

        if (itemIndex !== -1) {
          this.newTicket.client.packages[packIndex].items[itemIndex].quantity--;
        }
      } else {
        const packageIndex = this.newTicket.client.packages.findIndex(
          (pack) => pack._id === data.clientPackageId
        );

        if (packageIndex !== -1) {
          const itemIndex = this.newTicket.client.packages[
            packageIndex
          ].items.findIndex((item) => item._id === data.packageItemId);

          if (itemIndex !== -1) {
            this.newTicket.client.packages[packageIndex].items[itemIndex]
              .quantity--;
          }
        }
      }
    },

    updateItem(index, item) {
      this.newTicket.items[index] = item;
      this.sortItems();
    },
    removeItem(index) {
      // Remove package from ticket
      if (this.newTicket.items[index].item.packageId) {
        const packageIndex = this.newTicket.packages.findIndex(
          (pack) =>
            pack.packageId === this.newTicket.items[index].item.packageId
        );

        if (packageIndex !== -1) {
          this.newTicket.packages.splice(packageIndex, 1);
        }
      }
      // Re-add quantity to clients package item
      if (this.newTicket.items[index].item.clientPackageId) {
        const packageIndex = this.newTicket.client.packages.findIndex(
          (pack) =>
            pack._id === this.newTicket.items[index].item.clientPackageId
        );

        if (packageIndex !== -1) {
          const itemIndex = this.newTicket.client.packages[
            packageIndex
          ].items.findIndex(
            (item) =>
              item._id === this.newTicket.items[index].item.packageItemId
          );

          if (itemIndex !== -1) {
            this.newTicket.client.packages[packageIndex].items[itemIndex]
              .quantity++;
          }
        }
      }
      // Re-add loyalty points
      if (this.newTicket.items[index].item.isLoyaltyRedemption) {
        const index = this.newTicket.client.loyalty.findIndex(
          (l) =>
            l.salonId.toString() === this.$store.state.auth.salon._id.toString()
        );

        if (index !== -1) {
          this.newTicket.client.loyalty[index].points +=
            this.newTicket.items[index].item.loyaltyRedemptionCost;
        }
      }

      this.newTicket.items.splice(index, 1);

      if (this.newTicket.status !== 'completed') {
        if (this.newTicket.items.length === 0) {
          this.newTicket.payments = [];
        }
      }
    },
    refundItem(item) {
      this.newTicket.items.push({
        ...item,
        quantity: item.quantity * -1,
      });
    },
    isNotCardOrNoProcessor(payment) {
      if (
        payment.type !== 'card' ||
        (payment.type === 'card' && payment.processor === null)
      )
        return true;
    },

    updatePaymentAmount(amount) {
      this.paymentAmount = amount;
    },

    addPayment(type, staff, otherType, note) {
      const amount = this.paymentAmount || this.balance;

      // Update surcharge amount if split payment?
      if (
        !this.cashDiscounted &&
        type !== 'card' &&
        this.usesCashDiscount &&
        amount !== this.total &&
        amount < this.balance
      ) {
        const subtotal = +this.subtotal.toFixed(2);

        const newCashDiscountAdditionalProcessingFee =
          +this.cashDiscountAdditionalProcessingFee.toFixed(2) *
          ((+this.balance.toFixed(2) -
            +this.cardSurcharge.toFixed(2) -
            +amount.toFixed(2)) /
            100);

        this.newTicket.items.forEach((item) => {
          // Percentage of total
          const percentageOfTotal = (item.item.basePrice / subtotal) * 100;

          item.item.cashDiscountAdditionalProcessingFee =
            newCashDiscountAdditionalProcessingFee * (percentageOfTotal / 100);
        });
      }

      if (!amount) return;

      if (type === 'card') {
        this.paymentToBeChargedAmount = amount;
        this.showChargePaymentModal = true;
        return;
      }

      if (type === 'gift') {
        this.paymentToBeChargedAmount = amount;
        this.showChargeGiftcardModal = true;
        return;
      }

      this.newTicket.payments.push({
        date: new Date(Date.now()),
        type,
        otherType,
        note,
        amount,
        status: 'default',
        ref: null,
        processor: null,
        staff,
      });

      if (this.autoCheckout) {
        if (this.balance <= 0) this.verifySave(true);
      }

      this.paymentAmount = this.balance;
    },

    async refundPayment(refundPayment) {
      try {
        const index = this.newTicket.payments.findIndex(
          (payment) => payment._id === this.selectedPayment._id
        );

        if (index === -1) throw Error('Can not find payment');

        const payment = this.newTicket.payments[index];

        const amount = refundPayment.refundAmount
          ? +refundPayment.refundAmount
          : +refundPayment.amount;

        if (+payment.amount !== +amount) {
          this.newTicket.payments.push({
            ...refundPayment,
            amount,
            status: 'refunded',
          });
        } else {
          payment.status = 'refunded';
        }

        await this.$store.dispatch('tickets/savePayments', this.newTicket);

        this.selectedPayment = null;
        this.$toast.success('Payment marked as refunded');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async editPayment(newPayment) {
      try {
        const index = this.newTicket.payments.findIndex(
          (payment) => payment._id === newPayment._id
        );

        if (index === -1) return;

        this.newTicket.payments[index] = newPayment;

        await this.$store.dispatch('tickets/savePayments', this.newTicket);

        this.selectedPayment = null;
        this.$toast.success('Payment updated');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async paymentCharged(payment) {
      if (payment.payment.ref) {
        if (
          this.newTicket.payments.find(
            (prevPayment) => prevPayment.ref === payment.payment.ref
          )
        )
          return;
      }

      this.$toast.success('Payment charged');

      if (payment.payment && typeof payment.payment.amount === 'string') {
        payment.payment.amount = +payment.payment.amount;
      } else if (!payment.payment && typeof payment.amount === 'string') {
        payment.amount = +payment.amount;
      }

      this.paymentToBeChargedAmount = null;
      this.showChargePaymentModal = false;
      this.showChargeGiftcardModal = false;

      this.newTicket.payments.push(payment.payment ? payment.payment : payment);

      if (
        (payment.payment.processor === 'chargeanywhere' ||
          payment.payment.processor === 'dejavoo' ||
          payment.payment.processor === 'stripe') &&
        +payment.tipAmount > 0
      ) {
        this.tipAmount = +payment.tipAmount * 100;
        this.showFinalTipStep = true;
        this.showAddTip = true;
      }

      if (this.newTicket._id) {
        try {
          await this.$store.dispatch('tickets/savePayments', this.newTicket);
        } catch (error) {
          this.$toast.error(error.message);
        }
      }

      if (this.autoCheckout) {
        if (this.balance <= 0) this.verifySave(true);
      }

      this.paymentAmount = this.balance;
    },

    removePayment(index) {
      // Update surcharge amount if split payment?
      if (
        !this.cashDiscounted &&
        this.newTicket.payments[index].type !== 'card' &&
        this.usesCashDiscount
      ) {
        this.newTicket.items.forEach((item) => {
          // Subtract percentage of remaining balance from cashDiscountAdditoanProcfee
          const newRatio = +(
            (this.balance + this.newTicket.payments[index].amount) /
            this.total
          ).toFixed(2);

          if (!item.item.basePrice) {
            if (typeof item.item.basePrice !== 'number') {
              const index = this.$store.getters[
                'items/productsAndServices'
              ].findIndex((preitem) => preitem._id === item.item._id);

              if (index !== -1) {
                item.item.basePrice =
                  this.$store.getters['items/productsAndServices'][index].price;
              }
            }
          }

          item.item.cashDiscountAdditionalProcessingFee =
            item.item.basePrice *
            (this.cashDiscountAdditionalProcessingFee * 0.01) *
            newRatio;

          item.item.price =
            item.item.basePrice + item.item.cashDiscountAdditionalProcessingFee;
        });
      }

      this.newTicket.payments.splice(index, 1);
    },
    submitTip(tip) {
      if (tip.staff === 'nostaffidwilleverbethisevenly') {
        this.tipAllEvenly(tip);
        this.showAddTip = false;
        return;
      } else if (tip.staff === 'nostaffidwilleverbethispercent') {
        this.tipAllPercent(tip);
        this.showAddTip = false;
        return;
      }

      this.newTicket.tips.push(tip);
      this.showAddTip = false;
    },
    tipAllEvenly(tip) {
      const staffOnTicket = [];
      let lockedAmount = 0;

      // Get all staff on ticket
      this.newTicket.items.forEach((item) => {
        if (!item.staff) return;

        if (!staffOnTicket.find((staff) => staff._id === item.staff._id)) {
          staffOnTicket.push(item.staff);
        }
      });

      if (tip.staffLocks) {
        tip.staffLocks.forEach((staff) => {
          const index = staffOnTicket.findIndex(
            (s) => s._id === staff.staff._id
          );

          if (index !== -1) staffOnTicket.splice(index, 1);

          this.newTicket.tips.push({
            amount: staff.amount,
            staff: staff.staff,
          });

          lockedAmount += staff.amount;
        });
      }

      staffOnTicket.forEach((staff) => {
        this.newTicket.tips.push({
          amount: (tip.amount - lockedAmount) / staffOnTicket.length,
          staff,
        });
      });
    },
    tipAllPercent(tip) {
      const staffOnTicket = [];
      let lockedAmount = 0;
      let lockedSubTotal = 0;
      let itemsWithNoStaffTotal = 0;

      // Get all staff on ticket
      this.newTicket.items.forEach((item) => {
        if (!item.staff) {
          itemsWithNoStaffTotal += item.item.price * item.quantity;
          return;
        }

        const index = staffOnTicket.findIndex(
          (staff) => staff.staff._id === item.staff._id
        );

        if (index === -1) {
          staffOnTicket.push({
            staff: item.staff,
            total:
              ((item.item.basePrice || item.item.price || 0) -
                (item.item.discountAmount || 0) +
                (item.item.cashDiscountAdditionalProcessingFee || 0)) *
              (item.quantity || 0),
            percentOfTotal: null,
          });
        } else {
          staffOnTicket[index].total +=
            ((item.item.basePrice || item.item.price || 0) -
              (item.item.discountAmount || 0) +
              (item.item.cashDiscountAdditionalProcessingFee || 0)) *
            (item.quantity || 0);
        }
      });

      if (tip.staffLocks) {
        tip.staffLocks.forEach((staff) => {
          const index = staffOnTicket.findIndex((s) => {
            return s.staff._id === staff.staff._id;
          });

          lockedSubTotal += staffOnTicket[index].total;

          if (index !== -1) staffOnTicket.splice(index, 1);

          this.newTicket.tips.push({
            amount: staff.amount,
            staff: staff.staff,
          });

          lockedAmount += staff.amount;
        });
      }

      // Get percent of ticket total for each staff
      staffOnTicket.forEach((staff) => {
        staff.percentOfTotal =
          (staff.total /
            (this.subtotal +
              this.cardSurcharge -
              itemsWithNoStaffTotal -
              lockedSubTotal)) *
          100;
      });

      staffOnTicket.forEach((staff) => {
        this.newTicket.tips.push({
          amount: (staff.percentOfTotal / 100) * (tip.amount - lockedAmount),
          staff: staff.staff,
        });
      });
    },
    removeTip(index) {
      this.newTicket.tips.splice(index, 1);
    },

    deleteTicket() {
      this.loading = true;

      try {
        this.$store.dispatch('tickets/deleteTicket', this.newTicket);

        this.$emit('deleted', this.newTicket);
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    checkout() {
      this.newTicket.status = 'completed';
      this.checkedOut = true;
      this.$emit('checkedOut', this.newTicket);
    },

    cleaningLogCheck() {
      const items = [];

      this.newTicket.items.forEach((item) => {
        if (item.item.requiresCleaningLog && !item.item.cleaningLogCreated) {
          items.push(item);
        }
      });

      this.cleaningLogItems = items;

      return items;
    },

    async verifySave(checkout, dontClose, rebook) {
      // let errorItem = false;

      this.newTicket.items.forEach((item) => {
        item.status = 'default';

        if (
          this.newTicket.status === 'waiting' &&
          item.item.duration &&
          item.staff
        ) {
          this.newTicket.status = 'in-progress';
        }
      });

      if (checkout) {
        const cleaningItems = this.cleaningLogCheck();

        if (cleaningItems.length) {
          this.showCleaningLogModal = true;
          return;
        }

        if (
          !this.managerMode &&
          (!this.loggedInStaffIsAdmin || this.tempUser) &&
          this.newTicket.items.some((item) => !item.approval)
        ) {
          if (
            await this.$store.dispatch(
              'auth/activeUserHasPermission',
              'requireCode/checkoutUnapprovedTickets'
            )
          ) {
            // Get account code
            this.showGetStaffCodeForUnapprovedItems = true;
            return;
          }
        }

        this.checkout();
      } else {
        this.save(dontClose);
      }

      if (rebook) {
        this.$emit('rebook', this.newTicket);
      }
    },
    save(dontClose) {
      this.saved = true;

      if (!this.newTicket._id) {
        this.$emit('created', { ...this.newTicket, dontClose });
        return;
      }

      this.$emit('edited', { ...this.newTicket, dontClose });
    },
  },
};
</script>

<style lang="scss" scoped>
.ticketForm {
  height: 100%;
  display: grid;
  grid-template-areas:
    'items client'
    'items payment';
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr auto;
  gap: 1px;
  background-color: var(--clr-light);

  .date {
    padding: 32px;
    .dd {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  .datepicker {
    z-index: 2;
    position: absolute;
    top: 75px;
    left: 75px;
  }

  .items {
    grid-area: items;
    overflow-y: auto;
    background-color: var(--clr-white-2);
    position: relative;

    &_body {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      padding: 32px;

      &_empty {
        text-align: center;
        padding: 32px;

        h3 {
          font-weight: 400;
        }

        i {
          margin-bottom: 24px;
          font-size: 48px;
          color: var(--clr-light);
        }
      }

      &_tickets {
        &_item {
          &:not(:first-child) {
            margin-top: 16px;
          }
        }
      }

      .actions {
        display: flex;
        gap: 16px;
      }

      .ticket_notes {
        width: 100%;
        max-width: 750px;
      }

      .summary {
        ul {
          width: 400px;
          max-width: 80vw;

          .tips,
          .payments {
            display: flex;
            flex-direction: column;

            .tips_tip,
            .payments_payment {
              position: relative;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;

              .col {
                display: flex;
                align-items: center;
                gap: 16px;

                .menu {
                  position: relative;

                  &-icon {
                    color: var(--clr-link);
                    cursor: pointer;
                  }
                }
              }

              &:not(:first-child) {
                margin-top: 16px;
              }
            }

            .fa-times {
              position: absolute;
              top: 3px;
              right: -25px;
              cursor: pointer;
              color: var(--clr-danger);
            }
          }

          .payments {
            .red {
              color: var(--clr-danger) !important;
            }

            .type {
              span {
                color: var(--clr-gray);
              }
            }
          }

          .tips {
            color: var(--clr-link);
            cursor: pointer;
          }

          .balance {
            font-weight: 700;
            font-size: 20px;
          }

          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;

            &:not(:first-child) {
              border-top: 1px solid var(--clr-light);
            }
          }
        }
      }
    }
  }

  .client {
    grid-area: client;
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;

    .pending {
      margin: 16px;
      margin-bottom: 0px;
    }
    .alert {
      padding: 12px;
      font-size: 14px;
      border-radius: 5px;

      &-info {
        background-color: #c3edf7;
        color: #044653;
        border-color: #aceaf7;
      }
    }

    &_search {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      &_input,
      &_create {
        padding: 24px;
        border-bottom: 1px solid var(--clr-light);
      }

      &_dynamic {
        padding: 24px;
        flex-grow: 1;
        overflow-y: auto;
      }

      .clientPaymentForm {
        text-align: center;
        padding: 12px 24px;
        border-top: 1px solid var(--clr-light);
      }

      &_create {
        display: grid;
        place-items: center;
        cursor: pointer;
        color: var(--clr-link);

        p {
          font-size: 14px;
        }

        i {
          margin-right: 16px;
        }
      }
    }
  }

  .payment,
  .justSave {
    padding: 16px;
    grid-area: payment;
    background-color: white;

    &_methods {
      margin-top: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }

    &_checkout,
    &_save {
      margin-top: 16px;

      button {
        width: 100%;
      }
    }

    &_checkout {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .justSave {
    .payment_save {
      margin-top: 0;
    }
  }
}

.paymentOptions,
.chargePayment,
.chargeGiftcard,
.guidedCheckout {
  padding: 32px;
}

.otherPaymentTypeForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_actions {
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }
  }
}

.mobile {
  display: none;
}

.packageDetails {
  padding: 32px;

  .light {
    color: var(--clr-gray);
  }

  ul {
    li {
      &:not(:first-child) {
        margin-top: 32px;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.cleaningLogItems {
  padding: 32px;

  .light {
    color: var(--clr-gray);
  }

  ul {
    li {
      &:not(:first-child) {
        margin-top: 32px;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &_note {
    margin-top: 32px;
  }

  &_actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
  }
}
// Mobile
@media (max-width: 600px) {
  .ticketForm {
    .items {
      &_body {
        .actions {
          width: 100%;

          button {
            width: 100%;
          }
        }
      }
    }
  }
}

// Tablet
@media (max-width: 900px) {
  .ticketForm {
    display: block;

    // grid-template-areas:
    //   'items'
    //   'client'
    //   'payment';
    // grid-template-rows: auto 1fr auto;

    .items,
    .client,
    .payment {
      height: 100%;
      min-height: 200px;
      width: 100%;
    }

    .items {
      &_body {
        .actions {
          flex-direction: column;
        }
      }
    }
  }

  .mobile {
    display: block;
    position: absolute;
    bottom: 250px;
    right: 20px;

    .float {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      box-shadow: var(--shadow);
      border: 1px solid var(--clr-light);
      background-color: var(--clr-white);
      display: grid;
      place-items: center;
      color: var(--clr-secondary);
    }

    .menu {
      padding: 24px 0;
      display: flex;
      border-radius: 100px;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      width: 50px;
      margin-top: 8px;
      box-shadow: var(--shadow);
      border: 1px solid var(--clr-light);
      background-color: var(--clr-white);
      color: var(--clr-secondary);
    }
  }

  .mobileNavBar {
    display: block;
    position: absolute;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--clr-secondary-dark);
    color: var(--clr-white);
    bottom: 0;
    left: 0;

    li {
      display: grid;
      place-items: center;
      padding: 16px 32px;
    }
  }
}
</style>
