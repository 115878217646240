<template>
  <section class="schedule">
    <div class="schedule_actions">
      <div class="col">
        <BaseSelect
          :options="staffOptions"
          :value="selectedStaff"
          @input="selectStaff($event)"
        />
      </div>
      <div class="col actions">
        <BaseButton @click="showVacationModal = true" mode="secondary"
          >Vacation</BaseButton
        >
        <DateSelection
          propSelectedTimeOption="Week"
          :propSelectedDates="selectedDates"
          right
          staticMode
          showDayOfWeek
          @setSelectedDates="setSelectedDates($event)"
        />
      </div>
    </div>
    <div class="schedule_times">
      <table class="schedule_times_head">
        <thead>
          <tr>
            <th>Staff</th>
            <th v-for="date in dates" :key="date">
              {{ formatDate(date) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="staff in displayedStaffs" :key="staff">
            <td class="staffCol">
              <Avatar :name="staff.firstName" :image="staff.profileImage" />
              <div class="text">
                <p v-if="staff.title" class="light small">{{ staff.title }}</p>
                <p class="staff-name">
                  {{ `${staff.firstName} ${staff.lastName}` }}
                </p>
                <p class="total-hours">{{ getTotalHours(staff) }}</p>
              </div>
            </td>
            <td v-for="staffDate in staff.currentSchedule" :key="staffDate">
              <div
                class="block"
                :class="{ exception: staffDate.isException }"
                v-for="block in staffDate.blocks"
                :key="block"
                @click="
                  selectBlock(
                    staffDate.blocks,
                    staff,
                    staffDate.day,
                    staffDate.isException
                  )
                "
              >
                <p>
                  {{ formatHours(block.start) }} -
                  {{ formatHours(block.end) }}
                </p>
              </div>
              <div
                v-if="!staffDate.blocks.length"
                class="add"
                @click="
                  selectBlock([], staff, staffDate.day, staffDate.isException)
                "
              >
                <i class="fas fa-plus"></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>

  <Modal :show="showShiftForm" @close="hideShiftForm" maxWidth="550px">
    <ShiftForm
      v-bind="selectedBlockData"
      @copyToAll="copyToAll($event)"
      @submit="submitShiftForm($event)"
    />
  </Modal>

  <Modal
    title="Set Vacation Time"
    :show="showVacationModal"
    @close="showVacationModal = false"
    maxWidth="550px"
  >
    <form @submit.prevent="submitVacationTime" class="form vacationForm">
      <BaseSelect
        label="Team Member"
        :options="onlyStaffOptions"
        @input="vacation.staff = $event"
      />
      <BaseInput
        :label="vacation.staff === 'all' ? 'Date' : 'Start Date'"
        inputType="date"
        @input="vacation.start = $event"
      />
      <BaseInput
        v-if="vacation.staff !== 'all'"
        label="End Date"
        inputType="date"
        @input="vacation.end = $event"
      />
      <div class="form_actions">
        <BaseButton :disabled="!vacationFormValid">Submit</BaseButton>
      </div>
    </form>
  </Modal>
</template>

<script>
import DateSelection from '@/components/components/DateSelection.vue';
import ShiftForm from '@/components/appointments/ShiftForm.vue';

export default {
  props: {
    // staffs: {
    //   type: Array,
    //   required: true,
    // },
  },
  components: {
    DateSelection,
    ShiftForm,
  },
  created() {
    this.init();
  },
  watch: {
    selectedDates() {
      this.setDates();
    },
  },
  data() {
    return {
      staffs: this.$store.state.staff.staff.filter((staff) => !staff.isDeleted),
      selectedDates: {
        start: this.$moment().startOf('week'),
        end: this.$moment().endOf('week'),
      },
      staffOptions: [],
      onlyStaffOptions: [],
      displayedStaffs: [],
      dates: [],
      selectedStaff: 'all',
      selectedBlockData: null,
      showShiftForm: false,
      showVacationModal: false,

      vacation: {
        staff: null,
        start: null,
        end: null,
      },
    };
  },
  computed: {
    // staffs() {
    //   return this.$store.state.staff.staff;
    // },

    vacationFormValid() {
      if (this.vacation.staff === 'all' && this.vacation.start) return true;

      if (!this.vacation.staff || !this.vacation.start || !this.vacation.end)
        return false;

      return true;
    },

    salon() {
      return this.$store.state.auth.salon;
    },

    getUniqueDaysBetweenDates() {
      return (
        this.$moment(this.selectedDates.end).diff(
          this.$moment(this.selectedDates.start),
          'days'
        ) + 1
      );
    },

    staffForSelect() {
      return this.$store.state.staff.staff
        .filter((staff) => !staff.isDeleted)
        .map((staff) => {
          return {
            option: `${staff.firstName} ${staff.lastName}`,
            value: staff.staffId,
          };
        });
    },
  },
  methods: {
    async init() {
      await this.setStaffOptions();

      this.selectedStaff = this.staffOptions[0].value;

      this.setDisplayedStaff();

      this.setDates();
    },
    async setStaffOptions() {
      const options = [];
      const onlyStaffOptions = [];

      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'staff/viewOthersWorkingHours'
        )
      ) {
        options.push(...this.staffForSelect);

        onlyStaffOptions.push({
          option: 'All Team Members',
          value: 'all',
        });
        onlyStaffOptions.push(...this.staffForSelect);
      } else {
        options.push({
          option: `${this.$store.state.auth.loggedInSalonStaff.firstName} ${this.$store.state.auth.loggedInSalonStaff.lastName}`,
          value: this.$store.state.auth.loggedInSalonStaff._id,
        });
        onlyStaffOptions.push({
          option: `${this.$store.state.auth.loggedInSalonStaff.firstName} ${this.$store.state.auth.loggedInSalonStaff.lastName}`,
          value: this.$store.state.auth.loggedInSalonStaff._id,
        });
      }

      if (options.length > 1) {
        options.unshift({
          option: 'All Staff',
          value: 'all',
        });
      }

      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'staff/viewOthersWorkingHours'
        )
      ) {
        this.salon.groups.forEach((group) => {
          options.splice(1, 0, {
            option: group,
            value: `group-${group}`,
          });
        });
      }

      this.staffOptions = options;
      this.onlyStaffOptions = onlyStaffOptions;
    },
    async selectStaff(staffId) {
      this.selectedStaff = staffId;

      if (staffId === 'all') {
        this.displayedStaffs = [...this.staffs];
        return;
      }

      if (staffId.includes('group-')) {
        this.displayedStaffs = [
          ...this.staffs.filter((staff) =>
            staff.groups.find((group) => group === staffId.split('-')[1])
          ),
        ];
        return;
      }

      this.setDisplayedStaff(this.selectedStaff);
    },
    async setDisplayedStaff(staff) {
      if (staff) {
        this.displayedStaffs = [
          this.staffs.find((prestaff) => {
            return prestaff._id === staff || prestaff.staffId === staff;
          }),
        ];

        return;
      }

      const staffs = [];

      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'staff/viewOthersWorkingHours'
        )
      ) {
        staffs.push(...this.staffs);
      } else {
        staffs.push(this.$store.state.auth.loggedInSalonStaff);
      }

      this.displayedStaffs = staffs;
    },
    formatDate(date) {
      return this.$moment(date).format('ddd D MMM');
    },
    formatHours(block) {
      if (!block) return;

      const hour = +block.split(':')[0];
      const minute = +block.split(':')[1];

      return this.$moment().hour(hour).minute(minute).format('h:mma');
    },
    getTotalHours(staff) {
      if (!staff) return;

      let totalMinutes = 0;

      staff.schedule.default.forEach((day) => {
        day.blocks.forEach((block) => {
          const startHour = +block.start.split(':')[0];
          const startMinute = +block.start.split(':')[1];

          const endHour = +block.end.split(':')[0];
          const endMinute = +block.end.split(':')[1];

          const start = this.$moment().hour(startHour).minute(startMinute);
          const end = this.$moment().hour(endHour).minute(endMinute);

          totalMinutes += end.diff(start, 'minutes');
        });
      });

      let str = `${Math.floor(totalMinutes / 60)}h`;

      if (totalMinutes % 60 > 0) {
        str += ` ${totalMinutes % 60}m`;
      }

      return str;
    },
    async selectBlock(blocks, staff, dayIndex, exception) {
      if (staff.staffId !== this.$store.state.auth.user._id) {
        // Not self
        if (
          !(await this.$store.dispatch(
            'auth/activeUserHasPermission',
            'staff/editOthersWorkingHours'
          ))
        )
          return;
      } else {
        // Is self
        if (
          !(await this.$store.dispatch(
            'auth/activeUserHasPermission',
            'staff/editOwnWorkingHours'
          ))
        )
          return;
      }

      const date = this.$moment(this.selectedDates.start).add(dayIndex, 'days');

      this.selectedBlockData = {
        blocks,
        staff,
        date,
        dayIndex,
        exception,
      };
      this.showShiftForm = true;
    },
    hideShiftForm() {
      this.selectedBlockData = null;
      this.showShiftForm = false;
    },
    async copyToAll(newBlocks) {
      const staff = this.staffs.find(
        (staff) =>
          staff._id === this.selectedBlockData.staff._id ||
          staff.staffId === this.selectedBlockData.staff.staffId
      );

      if (!staff) return;

      staff.schedule.default.forEach((day) => {
        day.blocks = newBlocks;
      });

      try {
        await this.$store.dispatch('staff/editStaff', staff);

        this.hideShiftForm();
        this.init();

        this.$toast.success('Schedule updated');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    async setVacationOnAllStaff() {
      try {
        if (!this.vacation.start) {
          this.$toast.warning('Please select a date');
          return;
        }

        const date = this.$moment(this.vacation.start);

        for (let i = 0; i < this.staffs.length - 1; i++) {
          const staff = this.staffs[i];

          // Set exception dates
          const existingExceptionIndex = staff.schedule.exceptions.findIndex(
            (exception) => this.$moment(exception.date).isSame(date, 'day')
          );
          if (existingExceptionIndex === -1) {
            staff.schedule.exceptions.push({
              date,
              blocks: [],
            });
          } else {
            staff.schedule.exceptions[existingExceptionIndex].blocks = [];
          }

          await this.$store.dispatch('staff/editStaff', staff);
        }

        this.showVacationModal = false;
        this.init();

        this.$toast.success('Vacation time! Schedule updated.');
      } catch (err) {
        console.log(err);
      }
    },
    async submitVacationTime() {
      if (this.vacation.staff === 'all') {
        this.setVacationOnAllStaff();
        return;
      }

      if (!this.vacation.start || !this.vacation.end) {
        this.$toast.warning('Please select a start and end date');
        return;
      }

      const staff = this.staffs.find(
        (staff) =>
          staff._id === this.vacation.staff ||
          staff.staffId === this.vacation.staff
      );

      if (!staff) return;

      const startDate = this.$moment(this.vacation.start);
      const endDate = this.$moment(this.vacation.end);

      if (startDate.isAfter(endDate)) {
        this.$toast.error('Start date can not be before end date');
        return;
      }

      const daysBetween = endDate.diff(startDate, 'days');

      for (let i = 0; i <= daysBetween; i++) {
        const date = this.$moment(startDate).add(i, 'days');

        // Set exception dates
        const existingExceptionIndex = staff.schedule.exceptions.findIndex(
          (exception) => this.$moment(exception.date).isSame(date, 'day')
        );
        if (existingExceptionIndex === -1) {
          staff.schedule.exceptions.push({
            date,
            blocks: [],
          });
        } else {
          staff.schedule.exceptions[existingExceptionIndex].blocks = [];
        }
      }

      try {
        await this.$store.dispatch('staff/editStaff', {
          ...staff,
          vacationTimeStart: startDate,
          vacationTimeEnd: endDate,
        });

        this.showVacationModal = false;
        this.init();

        this.$toast.success('Vacation time! Schedule updated.');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    async submitShiftForm(newBlocks) {
      const staff = this.staffs.find(
        (staff) => staff._id === this.selectedBlockData.staff._id
      );

      if (!staff) return;

      if (newBlocks.repeat === 'weekly') {
        const existingExceptionIndex = staff.schedule.exceptions.findIndex(
          (exception) =>
            this.$moment(exception.date).isSame(
              this.selectedBlockData.date,
              'day'
            )
        );

        if (existingExceptionIndex !== -1) {
          staff.schedule.exceptions.splice(existingExceptionIndex, 1);
        }

        staff.schedule.default[this.selectedBlockData.dayIndex].blocks =
          newBlocks.blocks;
      } else {
        const existingExceptionIndex = staff.schedule.exceptions.findIndex(
          (exception) =>
            this.$moment(exception.date).isSame(
              this.selectedBlockData.date,
              'day'
            )
        );
        if (existingExceptionIndex === -1) {
          staff.schedule.exceptions.push({
            date: this.selectedBlockData.date,
            blocks: newBlocks.blocks,
          });
        } else {
          staff.schedule.exceptions[existingExceptionIndex].blocks =
            newBlocks.blocks;
        }
      }

      try {
        await this.$store.dispatch('staff/editStaff', {
          ...staff,
          // logReasons: [
          //   `${this.$store.state.auth.salon.details.shopName}${
          //     this.$store.state.auth.salon.details.altName
          //       ? ` ${this.$store.state.auth.salon.details.altName}`
          //       : ''
          //   } edited schedule for ${staff.firstName} ${staff.lastName}`,
          // ],
        });

        this.hideShiftForm();
        this.init();

        this.$toast.success('Schedule updated');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    setSelectedDates(dates) {
      this.selectedDates.start = dates.start;
      this.selectedDates.end = dates.end;
      this.setDates();
    },
    setDates() {
      this.dates = [];

      for (let i = 0; i < this.getUniqueDaysBetweenDates; i++) {
        this.dates.push(this.$moment(this.selectedDates.start).add(i, 'days'));
      }

      this.staffs.forEach((staff) => this.setStaffCurrentSchedule(staff));
    },
    async setStaffCurrentSchedule(staff) {
      staff.currentSchedule = await this.$store.dispatch(
        'staff/getStaffCurrentSchedule',
        {
          staff,
          startDate: this.selectedDates.start,
          endDate: this.selectedDates.end,
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule {
  &_actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;

    .actions {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
    }
  }

  .light {
    color: var(--clr-gray);
  }
  .small {
    font-size: 12px;
  }

  &_times {
    margin-top: 32px;
    overflow-x: auto;
    max-height: 700px;
    overflow-y: scroll;

    table {
      width: 100%;
      font-size: 14px;

      thead {
        position: sticky;
        top: 0;
        box-shadow: var(--shadow-small);
        background-color: white;

        tr {
          color: var(--clr-gray);

          th {
            padding: 8px 16px;
          }
        }
      }

      tbody {
        tr {
          vertical-align: top;

          .staffCol {
            display: flex;
            gap: 16px;
            align-items: center;
          }

          td {
            padding: 3px;

            &:first-child {
              padding: 16px;
            }

            .staff-name {
              font-weight: 700;
            }

            .total-hours {
              font-size: 12px;
              margin-top: 5px;
            }

            .exception {
              background-color: var(--clr-secondary-white) !important;
            }

            .block {
              padding: 8px 16px;
              background-color: var(--clr-blue-light);
              border-radius: 5px;
              transition: background-color 0.2s ease;
              cursor: pointer;

              &:not(:first-child) {
                margin-top: 2px;
              }

              &:hover {
                background-color: var(--clr-blue-light-2);
              }
            }

            .add {
              margin-top: 8px;
              display: grid;
              place-items: center;
              cursor: pointer;

              i {
                color: var(--clr-link);
              }
            }
          }
        }
      }
    }
  }
}

.vacationForm {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .form_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
