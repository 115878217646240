<template>
  <section class="register">
    <div class="container">
      <div class="register_head">
        <h2>Welcome To Raserva!</h2>
        <p>Enter your information below to get started.</p>
      </div>

      <div class="register_form">
        <MemberRegister />
      </div>

      <div class="alert alert-info">
        <p>
          <span class="bold">Attention!</span> This is for professionals only,
          if you are attempting to book an appointment please
          <router-link :to="{ name: 'ClientAuth' }">
            <span class="link">click here</span> </router-link
          >.
        </p>
      </div>

      <div class="clientLogin">
        <h2>Attempting to book an appointment?</h2>
        <p>
          Please
          <router-link :to="{ name: 'ClientAuth' }">
            <span class="link">click here</span>
          </router-link>
          to continue to client log in
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import MemberRegister from '@/components/auth/MemberRegister.vue';

export default {
  components: {
    MemberRegister,
  },
  created() {
    if (this.$route.query.invite) {
      this.$store.state.auth.invite = this.$route.query.invite;
    }
  },
};
</script>

<style lang="scss" scoped>
.register {
  padding: 64px 0;

  .container {
    max-width: 1400px;
    margin: auto;
  }

  &_head {
    text-align: center;

    p {
      margin-top: 8px;
    }
  }

  &_form {
    max-width: 600px;
    margin: auto;
    margin-top: 32px;
  }
  .alert {
    max-width: 600px;
    margin: auto;
    margin-top: 32px;
    padding: 12px;
    font-size: 14px;
    border-radius: 5px;

    .bold {
      font-weight: 700;
    }

    a {
      text-decoration: underline;
    }

    &-info {
      background-color: #c3edf7;
      color: #044653;
      border-color: #aceaf7;
    }
  }

  .clientLogin {
    max-width: 600px;
    margin: auto;
    margin-top: 32px;
    text-align: center;
    padding: 16px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;

    p {
      margin-top: 16px;
    }
    .link {
      color: var(--clr-link);
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
