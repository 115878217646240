<template>
  <section class="accounts">
    <div class="accounts_head">
      <h2>Staff</h2>
      <p>View/manage staff accounts</p>
    </div>

    <form @submit.prevent="search" class="accounts_actions">
      <div class="filters">
        <BaseInput
          placeholder="Email, Name, or Id.."
          @input="searchValue = $event"
        />
      </div>
      <div class="buttons">
        <BaseButton :disabled="loading"
          ><i v-if="loading" class="fas fa-spinner"></i> Search</BaseButton
        >
      </div>
    </form>

    <div v-if="!accounts.length" class="none">
      <h3>Please search accounts</h3>
      <p>No accounts found</p>
    </div>

    <ul v-else>
      <li
        @click="selectedAccount = account"
        v-for="account in accounts"
        :key="account._id"
      >
        <div class="col">
          <p>{{ `${account.firstName} ${account.lastName}` }}</p>
          <p class="light small mt-s">{{ account.email }}</p>
        </div>

        <div class="col right">
          <p>Salons: {{ account.salons.length }}</p>
          <p class="light small mt-s">ID: {{ account._id }}</p>
        </div>
      </li>
    </ul>

    <Modal
      title="Account Options"
      :show="selectedAccount"
      @close="selectedAccount = null"
      maxWidth="500px"
    >
      <div class="selectedAccount">
        <div class="text">
          <Avatar
            :image="selectedAccount.profileImage"
            showName
            mode="large rounded"
            :name="`${selectedAccount.firstName} ${selectedAccount.lastName}`"
          />

          <div class="info">
            <p>
              ID: <span class="bold">{{ selectedAccount._id }}</span>
            </p>
            <p>
              Email: <span class="bold">{{ selectedAccount.email }}</span>
            </p>
            <p>
              Phone: <span class="bold">{{ selectedAccount.phoneNumber }}</span>
            </p>
            <p>
              Salons:
              <span
                v-for="salon in selectedAccount.salons"
                :key="salon._id"
                class="bold"
                >{{
                  `${salon.name}${salon.altName ? ` - ${salon.altName}` : ''}`
                }},
              </span>
            </p>
          </div>
        </div>

        <ul>
          <li><BaseButton @click="autoLogin">Log in as staff</BaseButton></li>
          <li v-if="activeUserHasPerm('staffAccounts:edit')">
            <BaseButton
              mode="primary-outline"
              @click="showUpdatePasswordForm = true"
              >Update Password</BaseButton
            >
          </li>
        </ul>
      </div>
    </Modal>

    <Modal
      title="Update Password"
      :show="showUpdatePasswordForm"
      @close="showUpdatePasswordForm = false"
      maxWidth="500px"
    >
      <div class="updatePassword">
        <form @submit.prevent="updatePassword" class="form">
          <BaseInput
            inputType="password"
            label="New Password"
            @input="newPassword = $event"
          />
          <BaseInput
            inputType="password"
            label="Confirm Password"
            @input="confirmPassword = $event"
          />

          <div class="form_actions">
            <BaseButton>Submit</BaseButton>
          </div>
        </form>
      </div>
    </Modal>
  </section>
</template>

<script>
export default {
  data() {
    return {
      selectedAccount: null,
      searchValue: '',
      accounts: [],
      loading: false,
      showUpdatePasswordForm: false,
      newPassword: '',
      confirmPassword: '',
    };
  },
  computed: {
    admin() {
      return this.$store.state.admin.admin;
    },
  },
  methods: {
    async search() {
      this.loading = true;

      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin/staff?query=${this.searchValue}`
        );

        this.accounts = response.data.staffs;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    activeUserHasPerm(perm) {
      if (!this.admin) return false;

      return this.admin.permissions.includes(perm);
    },

    async autoLogin() {
      try {
        await this.$store.dispatch(
          'admin/autoLogin',
          this.selectedAccount.email
        );

        this.$router.push({ name: 'SalonSelect' });
      } catch (err) {
        console.log(err);
      }
    },

    async updatePassword() {
      try {
        if (this.newPassword.length < 5) {
          this.$toast.error('Password must be at least 5 characters');
          return;
        }

        if (this.newPassword !== this.confirmPassword) {
          this.$toast.error('Passwords do not match');
          return;
        }

        await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin/staff/${this.selectedAccount._id}/password`,
          {
            admin: true,
            newPassword: this.newPassword,
          }
        );

        this.newPassword = '';
        this.confirmPassword = '';

        this.showUpdatePasswordForm = false;
        this.$toast.success('Password updated');
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.accounts {
  margin: 32px auto;
  max-width: 800px;

  .light {
    color: var(--clr-gray);
  }
  .small {
    font-size: 14px;
  }
  .mt-s {
    margin-top: 4px;
  }

  &_head {
    margin-bottom: 32px;

    h2 {
      font-size: 32px;
    }
    p {
      margin-top: 5px;
      color: var(--clr-gray);
    }
  }

  &_actions {
    padding: 16px;
    background-color: var(--clr-light);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filters,
    .buttons {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  .none {
    padding: 16px;
    border-radius: 5px;
    border: 1px solid var(--clr-light);
    text-align: center;
    margin-top: 32px;

    p {
      margin-top: 8px;
      color: var(--clr-gray);
    }
  }

  ul {
    margin-top: 32px;

    li {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        background-color: var(--clr-white);
      }

      &:not(:first-child) {
        margin-top: 16px;
      }

      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);

      .right {
        text-align: right;
      }
    }
  }
}

.selectedAccount {
  padding: 32px;

  .bold {
    font-weight: 700;
  }
  .text {
    text-align: center;
  }

  .info {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  ul {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    li,
    button {
      width: 100%;
    }
  }
}

.updatePassword {
  padding: 32px;

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_actions {
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }
  }
}

// Mobile
@media (max-width: 600px) {
  .accounts {
    padding: 16px;

    &_head {
      text-align: center;
    }

    ul {
      li {
        flex-direction: column;
        gap: 8px;
        text-align: center;
        .right {
          text-align: center;
        }
      }
    }
  }
}
</style>
