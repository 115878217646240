<template>
  <section class="header">
    <div class="col inputs">
      <BaseInput
        placeholder="Search tickets.."
        @input="
          $emit('search', $event);
          searchValue = $event;
        "
        :value="searchValue"
      />
      <BaseSelect
        :options="sortOptions"
        :value="sortValue"
        @input="updateSortValue($event)"
      />
      <div
        v-if="sortValue === 'custom' && canEditRotationOrder"
        class="button"
        @click="editRotationOrder"
      >
        <i class="fas fa-cog"></i>
      </div>
      <!-- <div class="button">
        <i class="fas fa-users"></i>
      </div> -->
    </div>

    <div class="col stats">
      <div class="stat">
        <i class="far fa-check-circle"></i>
        <p>{{ incompleteTickets.length }} in process</p>
      </div>
      <div class="stat">
        <i class="fas fa-check-circle"></i>
        <p>{{ completedTickets.length }} completed</p>
      </div>
      <div class="stat">
        <i class="fas fa-users"></i>
        <p>{{ workingStaffCount }} working</p>
      </div>
    </div>

    <div class="col actions">
      <BaseButton
        v-if="combineTickets.length"
        :disabled="combineTickets.length === 1"
        @click="performCombineTickets"
        mode="small primary"
        >Combine {{ combineTickets.length }} Tickets</BaseButton
      >
      <BaseButton
        mode="small primary"
        @click="
          hideCompleted = !hideCompleted;
          $emit('toggleCompleted', hideCompleted);
        "
        >{{ hideCompleted ? 'Show' : 'Hide' }} Completed</BaseButton
      >
    </div>
  </section>

  <div v-if="hideCompleted" class="alert alert-warning">
    <p>Completed tickets are currently not displayed (turn count adjusted)</p>
  </div>
  <div v-if="searchValue" class="alert alert-warning">
    <p>Tickets are filtered by a search value (turn count adjusted)</p>
  </div>

  <GetStaffAccount
    v-if="showGetStaffFromCode"
    @submitted="checkForEditRotationPermission($event)"
    @exit="showGetStaffFromCode = false"
  />
</template>

<script>
import GetStaffAccount from '@/components/staff/GetStaffAccount.vue';

export default {
  props: {
    workingStaffCount: {
      type: Number,
      default: 0,
    },
    tickets: {
      type: Array,
      required: true,
    },
  },
  emits: ['toggleCompleted', 'search', 'updateSortValue', 'showMoreOptions'],
  components: {
    GetStaffAccount,
  },
  created() {
    this.init();
    this.sortValue = localStorage.getItem('classicSortValue') || 'clockin';
  },
  watch: {
    tempUser(val) {
      this.init();

      if (!val) {
        const name =
          this.$store.state.auth.salon.adminSettings.defaultMainScreen ===
          'calendar'
            ? 'Calendar'
            : 'ClassicView';

        setTimeout(() => {
          this.$router.push({
            name,
          });
        }, 0);
      }
    },

    loggedInSalonStaff() {
      this.init();
    },
  },
  computed: {
    managerMode() {
      return this.$store.state.auth.managerMode;
    },
    tempUser() {
      return this.$store.state.auth.tempUser;
    },
    loggedInSalonStaff() {
      return this.$store.state.auth.loggedInSalonStaff;
    },
    isAdmin() {
      return (
        !this.$store.state.auth.tempUser &&
        this.$store.state.auth.loggedInSalonStaff?.staffId ===
          this.$store.state.auth.salon.adminId
      );
    },
    completedTickets() {
      return this.tickets.filter((t) => t.status === 'completed');
    },
    incompleteTickets() {
      return this.tickets.filter(
        (t) => t.status === 'in-progress' || t.status === 'waiting'
      );
    },
    combineTickets() {
      return this.$store.state.tickets.combineTickets;
    },
    sortOptions() {
      return [
        {
          option: 'Clock in',
          value: 'clockIn',
        },
        {
          option: 'Custom',
          value: 'custom',
        },
      ];
    },
  },
  data() {
    return {
      hideCompleted: false,
      searchValue: '',
      sortValue: 'clockIn',
      canEditRotationOrder: false,
      showGetStaffFromCode: false,
    };
  },
  methods: {
    async init() {
      this.canEditRotationOrder =
        this.managerMode || this.isAdmin
          ? true
          : await this.$store.dispatch(
              'auth/activeUserHasPermission',
              'staff/canEditRotationOrder'
            );
    },

    async performCombineTickets() {
      try {
        await this.$store.dispatch('tickets/combineTickets');

        this.$toast.success('Tickets combined');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    updateSortValue(value) {
      this.sortValue = value;

      localStorage.setItem('classicSortValue', value);

      this.$emit('updateSortValue', this.sortValue);
    },

    async editRotationOrder() {
      if (this.isAdmin) {
        this.$emit('showMoreOptions');
        return;
      }

      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'requireCode/editRotationOrder'
        )
      ) {
        this.showGetStaffFromCode = true;
      } else {
        this.$emit('showMoreOptions');
      }
    },

    async checkForEditRotationPermission(staff) {
      this.$store.state.auth.tempUser = staff;

      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'staff/canEditRotationOrder'
        )
      ) {
        this.$emit('showMoreOptions');
        this.showGetStaffFromCode = false;
      } else {
        this.$toast.error('You do not have permission to edit rotation order');
        this.showGetStaffFromCode = false;
      }

      this.$store.state.auth.tempUser = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .inputs {
    display: flex;
    align-items: stretch;
    gap: 8px;
    height: 100%;

    .button {
      padding: 0px 16px;
      border: 1px solid var(--clr-gray);
      border-radius: 5px;
      display: grid;
      place-items: center;
      cursor: pointer;
      color: var(--clr-gray);
    }
  }

  .stats {
    display: flex;
    gap: 32px;
    .stat {
      display: flex;
      align-items: center;
      gap: 8px;

      i {
        color: var(--clr-secondary);
      }
    }
  }
  .actions {
    display: flex;
    gap: 16px;
  }
}

.alert {
  margin: 0 16px;
  padding: 12px;
  font-size: 14px;
  border-radius: 5px;

  &-warning {
    background-color: var(--clr-warning-light);
    border-color: var(--clr-warning);
  }
}

// Tablet
@media (max-width: 900px) {
  .header {
    flex-direction: column;
    gap: 16px;

    .stats {
      justify-content: center;
      gap: 16px;
    }
  }
}

// Mobile
@media (max-width: 600px) {
  .header {
    .col {
      width: 100%;
    }
    button {
      width: 100%;
    }
  }
}
</style>
