<template>
  <section class="actions">
    <div class="actions_head">
      <h2>Recent Actions</h2>
    </div>

    <div class="actions_actions">
      <BaseSelect
        :options="actionOptions"
        :value="selectedFilter"
        @input="filter($event)"
      />
    </div>

    <div class="actions_body">
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          <tr
            @click="selectAction(action)"
            v-for="action in displayedActions"
            :key="action._id"
          >
            <td>{{ formatDate(action.date) }}</td>
            <td>{{ capitalizeFirstLetter(action.type) }}</td>
            <td>{{ action.text }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>

  <Modal :show="selectedAction" title="Metadata" @close="selectedAction = null">
    <div class="metadata">
      <BaseTextarea :value="selectedActionMetadataFormatted" disabled />
    </div>
  </Modal>
</template>

<script>
export default {
  created() {
    this.init();
  },
  data() {
    return {
      displayedActions: [],
      selectedFilter: 'all',
      selectedAction: null,
    };
  },
  watch: {
    salon() {
      this.init();
    },
  },
  computed: {
    selectedActionMetadataFormatted() {
      return `${JSON.stringify(this.selectedAction.metadata, null, 4)}`;
    },
    salon() {
      return this.$store.state.auth.salon;
    },
    actions() {
      if (!this.$store.state.auth.salon) return;

      let actions = this.$store.state.auth.salon.actions;

      if (actions) {
        actions = actions.sort((a, b) => new Date(b.date) - new Date(a.date));
      }

      return actions;
    },
    actionOptions() {
      return [
        {
          value: 'all',
          option: 'All',
        },
        {
          value: 'appointment',
          option: 'Appointments',
        },
        {
          value: 'ticket',
          option: 'Tickets',
        },
        {
          value: 'giftcard',
          option: 'Gift cards',
        },
      ];
    },
  },
  methods: {
    init() {
      if (!this.$store.state.auth.salon) return;

      this.displayedActions = JSON.parse(JSON.stringify(this.actions));
    },
    formatDate(date) {
      return this.$moment(date).format('LLL');
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    filter(filter) {
      this.selectedFilter = filter;

      if (!this.actions) return;

      const parsedActions = JSON.parse(JSON.stringify(this.actions));

      if (this.selectedFilter === 'all') {
        this.displayedActions = parsedActions;
        return;
      }

      this.displayedActions = parsedActions.filter((action) => {
        return action.type === this.selectedFilter;
      });
    },
    selectAction(action) {
      if (!action.metadata) return;

      this.selectedAction = action;
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  width: 100%;
  max-width: 800px;

  &_head {
    h2 {
      font-size: 28px;
    }
  }

  &_actions {
    margin-top: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-extra-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
  }

  &_body {
    margin-top: 32px;

    .action {
      &:not(:first-child) {
        margin-top: 16px;
      }

      padding: 16px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;

      .date {
        font-size: 12px;
        color: var(--clr-gray);
        margin-bottom: 8px;
      }
    }
  }
}

.metadata {
  padding: 32px;
}
</style>
