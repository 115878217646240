<template>
  <section class="booking">
    <div class="booking_head">
      <h2>Booking Marketing</h2>
      <p>
        Provide discounts and other offers to your client when they go to book
        online!
      </p>
    </div>

    <div class="booking_actions">
      <BaseButton @click="showOfferModal = true">Add Offer Scenario</BaseButton>
    </div>

    <div v-if="offers.length" class="booking_body">
      <Table
        :headers="offerHeaders"
        :data="offerData"
        @rowClick="selectedOffer = offers[$event]"
      />
    </div>

    <div v-if="!offers.length" class="none">
      <h3>No offers found</h3>
      <p>Create an offer to get started</p>
    </div>
  </section>

  <Modal
    title="Offer Scenario Form"
    :show="showOfferModal || selectedOffer"
    @close="
      showOfferModal = false;
      selectedOffer = null;
    "
  >
    <div class="scenarioForm">
      <BookingMarketingScenarioForm
        :propOffer="selectedOffer"
        @submit="offerFormSubmitted($event)"
        @delete="deleteSelectedOffer"
      />
    </div>
  </Modal>
</template>

<script>
import BookingMarketingScenarioForm from '@/views/marketing/BookingMarketingScenarioForm.vue';

export default {
  components: { BookingMarketingScenarioForm },
  created() {
    this.init();
  },
  computed: {
    discounts() {
      return this.$store.state.auth.salon.discounts;
    },
    offerHeaders() {
      return ['Scenario', 'On Page', 'Discount', 'Enabled'];
    },
    offerData() {
      return this.offers.map((offer) => {
        const scenario = offer.scenario.replace(/([A-Z])/g, ' $1').trim();
        const onPage = offer.onPage.replace(/([A-Z])/g, ' $1').trim();

        return {
          scenario,
          onPage,
          discount: this.getDiscountTitle(offer.discount),
          enabled: offer.enabled,
        };
      });
    },
  },
  data() {
    return {
      showOfferModal: false,
      selectedOffer: null,
      offers: [],
    };
  },
  methods: {
    init() {
      if (!this.$store.state.auth.salon) return;

      this.offers = this.$store.state.auth.salon.bookingMarketingOffers;
    },

    async offerFormSubmitted(offer) {
      try {
        if (this.selectedOffer) {
          // Edit
          const response = await this.$axios.put(
            `${process.env.VUE_APP_RASERVA_BACKEND}/bookingMarketingOffers/${this.selectedOffer._id}?salonId=${this.$store.state.auth.salon._id}`,
            offer
          );

          this.offers = this.offers.map((offer) => {
            if (offer._id === response.data.offer._id) {
              return response.data.offer;
            }

            return offer;
          });
        } else {
          // Create
          const response = await this.$axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/bookingMarketingOffers?salonId=${this.$store.state.auth.salon._id}`,
            offer
          );

          this.offers.push(response.data.offer);
        }

        this.selectedOffer = null;
        this.showOfferModal = false;

        this.$toast.success('Offer saved successfully!');
      } catch (error) {
        console.log(error);
      }
    },

    async deleteSelectedOffer() {
      try {
        await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/bookingMarketingOffers/${this.selectedOffer._id}?salonId=${this.$store.state.auth.salon._id}`
        );

        this.offers = this.offers.filter(
          (offer) => offer._id !== this.selectedOffer._id
        );

        this.$store.state.auth.salon.bookingMarketingOffers = this.offers;

        this.selectedOffer = null;
        this.showOfferModal = false;

        this.$toast.success('Offer deleted successfully!');
      } catch (error) {
        console.log(error);
      }
    },

    getDiscountTitle(discountId) {
      const discount = this.discounts.find((d) => d._id === discountId);
      return discount ? discount.title : 'None';
    },
  },
};
</script>

<style lang="scss" scoped>
.booking {
  &_head {
    h2 {
      font-size: 28px;
    }
    p {
      margin-top: 8px;
    }
  }

  &_actions {
    margin-top: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-extra-light);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 32px;
  }

  &_body {
    margin-top: 32px;
  }

  .none {
    padding: 16px;
    border-radius: 5px;
    border: 1px solid var(--clr-light);
    text-align: center;
    margin-top: 32px;

    p {
      margin-top: 8px;
      color: var(--clr-gray);
    }
  }
}

.scenarioForm {
  padding: 32px;
}
</style>
