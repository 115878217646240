<template>
  <div class="categories">
    <ul>
      <li
        v-for="cat in categoriesFirstFour"
        v-show="cat.items.length"
        :key="cat._id"
        @click="
          cat._id !== 'more' ? $emit('select', cat._id) : (showAllCats = true)
        "
        :class="{
          selected:
            (!showAllCats && selectedCategoryId === cat._id) ||
            (cat.title === 'More' &&
              (showAllCats || selectedCategoryIdNotInFirstFour)),
        }"
      >
        <p>
          {{ cat.title }} <span class="small">({{ cat.items.length }})</span>
        </p>
      </li>
    </ul>

    <div class="gradient"></div>
  </div>

  <Modal
    title="All Categories"
    :show="showAllCats"
    maxWidth="500px"
    @close="
      showAllCats = false;
      $emit('select', categories[0]._id);
    "
  >
    <ul class="allCats">
      <li
        @click="
          $emit('select', cat._id);
          showAllCats = false;
        "
        v-for="cat in categories"
        :key="cat._id"
      >
        <p>{{ cat.title }}</p>
        <i class="fas fa-chevron-right"></i>
      </li></ul
  ></Modal>
</template>

<script>
export default {
  emits: ['select'],
  props: {
    selectedCategoryId: {
      type: String,
    },
  },
  computed: {
    categories() {
      if (!this.$store.state.booking.salon) return [];

      const categories = JSON.parse(
        JSON.stringify(this.$store.state.booking.salon.services)
      );

      return categories.filter((c) => {
        return c.items.some((i) => i.onBooking);
      });
    },
    categoriesFirstFour() {
      const firstFour = this.categories.slice(0, 4);

      firstFour.push({
        items: [],
        title: 'More',
        _id: 'more',
      });

      firstFour[firstFour.length - 1].items = [
        ...this.categories.slice(4, this.categories.length),
      ];

      return firstFour;
    },
    selectedCategoryIdNotInFirstFour() {
      return (
        this.selectedCategoryId &&
        !this.categoriesFirstFour.find(
          (cat) => cat._id === this.selectedCategoryId
        )
      );
    },
  },
  data() {
    return {
      showAllCats: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.categories {
  position: relative;
  background-color: white;
  border-radius: 5px;
  box-shadow: var(--shadow);
  padding: 16px;
  overflow: hidden;
  max-width: 92vw;

  ul {
    display: flex;
    align-items: center;
    gap: 8px;
    overflow-x: auto;
    width: 90vw;
    max-width: 950px;

    .selected {
      font-weight: 700;
      // border: 3px solid var(--clr-secondary);
      background-color: var(--clr-primary);
      color: var(--clr-white);
    }

    li {
      padding: 8px 24px;
      border-radius: 500px;
      cursor: pointer;
      overflow-x: visible;
      white-space: nowrap;

      .small {
        font-size: 12px;
      }
    }
  }

  // .gradient {
  //   position: absolute;
  //   height: 100%;
  //   width: 100px;
  //   top: 0;
  //   right: 0;
  //   background: linear-gradient(
  //     90deg,
  //     rgba(255, 255, 255, 0) 0%,
  //     var(--clr-white) 100%
  //   );
  // }
}

.allCats {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  li {
    padding: 16px;
    border-radius: 5px;
    box-shadow: var(--shadow);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--clr-white);

    i {
      color: var(--clr-gray-2);
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}

// Tablet
// @media (max-width: 900px) {
//   ul {
//     flex-direction: column;

//     li {
//       width: 100%;
//     }
//   }
// }

// /* Hide scrollbar for Chrome, Safari and Opera */
// ul::-webkit-scrollbar {
//   display: none;
// }

// /* Hide scrollbar for IE, Edge and Firefox */
// ul {
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */
// }
</style>
