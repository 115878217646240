<template>
  <section class="profile" v-if="user">
    <Spinner v-if="loading" fullscreen />
    <div class="container">
      <div class="profile_head">
        <h2>My Profile</h2>
        <p class="subtext">
          Welcome back, {{ user.firstName + ' ' + user.lastName }}! View and
          manage your Raserva staff settings and active salons.
        </p>
        <div class="stripeAccountMessages"></div>
        <!-- <div v-if="!detailsSubmitted" class="processing">
          <i class="fas fa-exclamation-triangle"></i>
          <p>
            You are not yet set up to accept payments. Proceed with onboarding
            <span class="link" @click="createStripeAccountOrGetLink"
              >here.</span
            >
          </p>
        </div>
        <div v-if="chargesEnabled" class="chargesEnabled">
          <i class="fas fa-check"></i>
          <p>You're set up to collect payments</p>
        </div>
        <div v-if="!chargesEnabled && detailsSubmitted" class="processing">
          <i class="fas fa-clipboard-check"></i>
          <p>
            Stripe may require more information. Please log in to your Stripe
            dashboard and follow their steps.
          </p>
        </div> -->
      </div>
      <div class="profile_body">
        <div class="col">
          <form @submit.prevent="editStaff" class="form">
            <div class="image-name">
              <div class="image-col">
                <ImageSelect
                  size="100px"
                  @select="newUser.profileImage = $event"
                  :fileSource="newUser.profileImage"
                />
              </div>
              <div class="name-col">
                <BaseInput
                  label="First name"
                  :value="user.firstName"
                  @input="newUser.firstName = $event"
                />
                <BaseInput
                  label="Last name"
                  :value="user.lastName"
                  @input="newUser.lastName = $event"
                />
              </div>
            </div>
            <BasePhoneInput
              label="Phone number"
              :value="user.phoneNumber"
              inputType="number"
              @input="newUser.phoneNumber = $event"
            />
            <BaseInput
              label="Email"
              :value="user.email"
              @input="newUser.email = $event"
            />
            <!-- <BaseSelect
              label="Default Location"
              :options="locationOptions"
              :value="user.defaultLocationId"
              @input="newUser.defaultLocationId = $event"
            /> -->
            <div class="actions">
              <BaseButton :disabled="staffInfoFormLoading">
                <i v-if="staffInfoFormLoading" class="fas fa-spinner"></i>
                Submit</BaseButton
              >
            </div>
          </form>
          <form @submit.prevent="updatePassword" class="form">
            <BaseInput
              inputType="password"
              label="Current Password"
              @input="password = $event"
            />
            <BaseInput
              :inputType="showNewPassword ? 'text' : 'password'"
              label="New Password"
              :value="newPassword"
              @input="newPassword = $event"
              @icon-click="showNewPassword = !showNewPassword"
              icon="fas fa-eye"
            />
            <BaseInput
              :inputType="showConfirmPassword ? 'text' : 'password'"
              label="Confirm Password"
              :value="confirmPassword"
              @input="confirmPassword = $event"
              @icon-click="showConfirmPassword = !showConfirmPassword"
              icon="fas fa-eye"
            />
            <div class="actions">
              <BaseButton :disabled="passwordFormLoading">
                <i v-if="passwordFormLoading" class="fas fa-spinner"></i>
                Submit</BaseButton
              >
            </div>
          </form>
        </div>
        <div class="col">
          <div class="salons">
            <div class="salons_head">
              <h3>Your Salons</h3>
            </div>
            <div class="salons_salons">
              <ul v-if="user.salons.length || user.invites.length">
                <li v-for="salon in user.salons" :key="salon._id">
                  <div class="text" @click="getSalon(salon.salonId)">
                    <p class="bold link">
                      {{
                        `${salon.name}${
                          salon.altName ? ` - ${salon.altName}` : ''
                        }`
                      }}
                    </p>
                    <p class="light small">
                      Joined: {{ formatDate(salon.joined_at) }}
                    </p>
                    <p
                      v-if="salon.adminId === user._id"
                      class="bold light small"
                    >
                      You are the admin of this salon
                    </p>
                  </div>
                  <div class="buttons">
                    <div
                      v-if="salon.adminId === user._id"
                      class="actions admin"
                    >
                      <BaseButton
                        mode="primary-outline"
                        @click="stripePortal(salon.salonId)"
                        >Manage Billing</BaseButton
                      >
                    </div>
                    <div v-else class="actions">
                      <BaseButton
                        mode="danger-outline small"
                        @click="salonToLeave = salon"
                        >Leave</BaseButton
                      >
                    </div>
                    <div class="access">
                      <p
                        @click="allowEdits(salon)"
                        v-if="!salonCanEditStaffInfo(salon)"
                        class="allow"
                      >
                        Allow Edits
                      </p>
                      <p @click="disallowEdits(salon)" v-else class="disallow">
                        Disallow Edits
                      </p>
                    </div>
                  </div>
                </li>
                <li v-for="(salon, index) in user.invites" :key="salon._id">
                  <div class="text">
                    <p class="bold">{{ salon.name }}</p>
                    <p class="light small">
                      {{ formatDate(salon.invited_at) }}
                    </p>
                  </div>
                  <div class="actions">
                    <BaseButton
                      :disabled="inviteLoading"
                      mode="primary-outline small"
                      @click="joinSalon(salon)"
                      ><i v-if="inviteLoading" class="fas fa-spinner"></i>
                      Accept</BaseButton
                    >
                    <BaseButton
                      :disabled="inviteLoading"
                      mode="danger-outline small"
                      @click="declineSalon(index)"
                      ><i v-if="inviteLoading" class="fas fa-spinner"></i>
                      Decline</BaseButton
                    >
                  </div>
                </li>
              </ul>
              <div v-else class="none">
                <h3>You have no salons!</h3>
                <p>
                  Have a salon owner invite you to theirs or
                  <router-link :to="{ name: 'SalonRegister' }">
                    <span class="link">create your own!</span>
                  </router-link>
                </p>
              </div>
            </div>
          </div>
          <div
            class="stripe"
            v-if="user.paymentProcessing.processor === 'stripe'"
          >
            <div class="accounts">
              <div class="accounts_select">
                <BaseSelect
                  label="Stripe Account"
                  :options="stripeAccountOptions"
                  @input="selectStripeAccount($event)"
                  :value="selectedStripeAccount"
                />

                <BaseButton
                  v-if="selectedStripeAccount"
                  mode="primary-outline"
                  @click="editSelectedStripeAccount = true"
                  ><i class="fas fa-edit"></i
                ></BaseButton>
                <BaseButton @click="showConfirmCreateStripeAccount = true"
                  >Create New</BaseButton
                >
              </div>
            </div>
            <div v-if="stripeAccounts.length" class="locations">
              <StripeLocations :accountId="selectedStripeAccount" />
            </div>
            <div v-if="stripeAccounts.length" class="readers">
              <StripeReaders :accountId="selectedStripeAccount" />
            </div>
            <div v-if="stripeAccounts.length" class="configs">
              <StripeConfigs :accountId="selectedStripeAccount" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Confirm
    v-if="salonToLeave"
    delete
    title="Leave salon"
    text="Are you sure you wish to leave this salon? This action can not be undone."
    @confirm="leaveSalon"
    @deny="salonToLeave = null"
  />

  <Confirm
    v-if="showConfirmCreateStripeAccount"
    title="New Stripe Account"
    text="Are you sure you want to link another stripe account?"
    @confirm="createStripeAccount"
    @deny="showConfirmCreateStripeAccount = false"
    :loading="stripeAccountLoading"
  />

  <Modal
    title="Edit Stripe Account"
    :show="editSelectedStripeAccount"
    @close="editSelectedStripeAccount = false"
  >
    <form @submit.prevent="editStripeAccount" class="editStripeAccount">
      <BaseInput
        label="Name"
        @input="selectedStripeAccountObject.name = $event"
        :value="selectedStripeAccountObject.name"
      />

      <div class="actions">
        <BaseButton>Submit</BaseButton>
      </div>
    </form>
  </Modal>
</template>

<script>
import StripeLocations from '@/components/stripe/StripeLocations';
import StripeReaders from '@/components/stripe/StripeReaders';
import StripeConfigs from '@/components/stripe/StripeConfigs';

import ImageSelect from '@/components/components/ImageSelect';

export default {
  created() {
    if (!this.user) return;

    this.init();

    if (this.$route.query.info) {
      this.$toast.warning(this.$route.query.info);
    }
  },
  components: {
    StripeLocations,
    StripeReaders,
    StripeConfigs,
    ImageSelect,
  },
  computed: {
    stripeAccountOptions() {
      const accounts = [];

      this.stripeAccounts.forEach((account) => {
        accounts.push({
          option: account.name || account.id,
          value: account.id,
        });
      });

      return accounts;
    },
    user() {
      return this.$store.state.auth.user;
    },
    stripeAccounts() {
      return this.user.paymentProcessing.stripe.accounts;
    },
    locationOptions() {
      const options = [];

      this.$store.state.stripe.locations.forEach((location) => {
        options.push({
          option: location.display_name,
          value: location.id,
        });
      });

      return options;
    },
  },
  watch: {
    user() {
      this.init();
    },
  },
  data() {
    return {
      newUser: null,
      salonToLeave: null,
      password: '',
      newPassword: '',
      confirmPassword: '',
      showNewPassword: false,
      showConfirmPassword: false,

      loading: false,
      inviteLoading: false,
      passwordFormLoading: false,
      staffInfoFormLoading: false,

      detailsSubmitted: false,
      chargesEnabled: false,

      selectedStripeAccount: null,
      selectedStripeAccountObject: null,
      showConfirmCreateStripeAccount: false,
      stripeAccountLoading: false,
      editSelectedStripeAccount: false,
    };
  },
  methods: {
    async init() {
      if (!this.user) return;

      this.newUser = JSON.parse(JSON.stringify(this.user));

      if (this.user.paymentProcessing.stripe.accounts.length) {
        this.selectedStripeAccount =
          this.user.paymentProcessing.stripe.accounts[0].id;

        this.selectedStripeAccountObject =
          this.user.paymentProcessing.stripe.accounts[0];
      }

      if (this.$route.query.stripe_error) {
        this.createAccountLink(this.selectedStripeAccount);
      }

      if (this.$route.query.stripe_message || this.selectedStripeAccount) {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/account/${this.selectedStripeAccount}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        if (response.data.account) {
          this.detailsSubmitted = response.data.account.details_submitted;
          this.chargesEnabled = response.data.account.charges_enabled;

          // if (!this.chargesEnabled && this.detailsSubmitted) {
          //   this.$toast.info('Your onboarding information is being reviewed');
          // } else if (!this.chargesEnabled && !this.detailsSubmitted) {
          //   this.$toast.info(
          //     'You have not finished the onboarding process.You may do so at any time on this page'
          //   );
          // }
        }

        if (localStorage.getItem('staffProfileStripeAccount')) {
          this.selectedStripeAccount = localStorage.getItem(
            'staffProfileStripeAccount'
          );

          this.selectedStripeAccountObject = this.stripeAccounts.find(
            (a) => a.id === this.selectedStripeAccount
          );
        }
      }
    },

    formatDate(date) {
      return this.$moment(date).format('LL');
    },

    selectStripeAccount(account) {
      this.selectedStripeAccount = account;

      this.selectedStripeAccountObject = this.stripeAccounts.find(
        (a) => a.id === account
      );

      localStorage.setItem('staffProfileStripeAccount', account);
    },

    async editStaff() {
      this.staffInfoFormLoading = true;

      try {
        await this.$store.dispatch('staff/editStaffAccount', this.newUser);
        this.$toast.success('Account info updated');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.staffInfoFormLoading = false;
    },

    async updatePassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.$toast.error('Passwords do not match');
        return;
      }

      this.passwordFormLoading = true;

      try {
        await this.$store.dispatch('staff/updatePassword', {
          password: this.password,
          newPassword: this.newPassword,
        });
        this.$toast.success('Password updated');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.passwordFormLoading = false;
    },

    async getSalon(id) {
      try {
        await this.$store.dispatch('auth/selectSalon', id);
        this.$router.push({ name: 'Calendar' });
      } catch (error) {
        if (error.message === 'Subscribe to continue') {
          this.$toast.error(
            'Subscribe to continue by clicking on Manage Billing'
          );
          return;
        }

        this.$toast.error(error.message);
      }
    },

    async joinSalon(salon) {
      this.inviteLoading = true;

      try {
        this.user = await this.$store.dispatch(
          'staff/joinSalon',
          salon.salonId
        );
        this.$toast.success('You are now a part of a new salon!');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.inviteLoading = false;
    },

    async leaveSalon() {
      this.inviteLoading = true;

      try {
        this.user = await this.$store.dispatch(
          'staff/leaveSalon',
          this.salonToLeave.salonId
        );
        this.$toast.success('You have left a salon');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.salonToLeave = null;
      this.inviteLoading = false;
    },

    async declineSalon(index) {
      this.user.invites.splice(index, 1);
      await this.$store.dispatch('staff/editStaffAccount', this.user);
    },

    createStripeAccountOrGetLink() {
      if (this.selectedStripeAccount) {
        this.createAccountLink(this.selectedStripeAccount);
      } else {
        this.createStripeAccount();
      }
    },

    async createStripeAccount() {
      this.loading = true;
      this.stripeAccountLoading = true;

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/account`,
          {
            staffId: this.user._id,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );
        this.createAccountLink(response.data.account.id);
      } catch (error) {
        this.loading = false;
        this.$toast.error(error.response.data.error);
      }

      this.stripeAccountLoading = false;
    },

    async createAccountLink(accountId) {
      this.loading = true;
      try {
        const link = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/accountLink`,
          {
            accountId,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );
        this.loading = false;
        window.location.href = link.data.accountLink.url;
      } catch (error) {
        this.loading = false;
        this.$toast.error(error.response.data.error);
      }
    },

    async stripePortal(salonId) {
      const salon = await this.$store.dispatch('auth/getSalon', salonId);

      if (salon.billing.stripe && salon.billing.stripe.customerId) {
        // Manage billing
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/subscriptions/portalSession`,
          {
            stripeCustomerId: salon.billing.stripe.customerId,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        window.location.href = response.data.url;
      } else {
        // Create subscription
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/subscriptions/checkoutSession`,
          {
            priceId: process.env.VUE_APP_STRIPESEAT_PRICEID,
            quantity: 1,
            customer_email: this.$store.state.auth.user.email,
            salonDetails: { address: {} },
            salonId,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        window.location.href = response.data.session.url;
      }
    },

    salonCanEditStaffInfo(salon) {
      return this.user.salonsThatCanEditStaffInfo.find(
        (presalon) => presalon === salon.salonId
      );
    },

    async allowEdits(salon) {
      try {
        await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/staff/${this.user._id}/edits/allow/${salon.salonId}`
        );

        this.$toast.success('Salon can now edit your account info');
        this.user.salonsThatCanEditStaffInfo.push(salon.salonId);
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async disallowEdits(salon) {
      try {
        await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/staff/${this.user._id}/edits/disallow/${salon.salonId}`
        );

        this.$toast.success('Salon can no longer edit your account info');

        const index = this.user.salonsThatCanEditStaffInfo.findIndex(
          (presalon) => presalon === salon.salonId.toString()
        );

        if (index === -1) return;

        this.user.salonsThatCanEditStaffInfo.splice(index, 1);
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async editStripeAccount() {
      try {
        const response = await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/staff/${this.$store.state.auth.user._id}/stripeAccount/${this.selectedStripeAccountObject.id}/name`,
          {
            name: this.selectedStripeAccountObject.name,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.selectedStripeAccountObject = response.data.account;

        this.stripeAccounts.find(
          (account) => account._id === this.selectedStripeAccountObject._id
        ).name = this.selectedStripeAccountObject.name;

        this.editSelectedStripeAccount = false;
        this.$toast.success('Account name updated');
      } catch (err) {
        this.$toast.error(err.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 1400px;
  margin: auto;
  padding: 0 16px;
}

.bold {
  font-weight: 700;
}
.small {
  font-size: 14px;
}
.light {
  color: var(--clr-gray);
}
.link {
  color: var(--clr-link);
  cursor: pointer;
}

.profile {
  margin: 64px auto;

  &_head {
    h2 {
      font-size: 28px;
    }
    .subtext {
      margin-top: 8px;
    }

    .processing,
    .chargesEnabled {
      margin-top: 16px;
      padding: 16px;
      border: 1px solid;
      border-radius: 5px;
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .processing {
      border-color: var(--clr-secondary);

      i {
        color: var(--clr-secondary);
      }
    }
    .chargesEnabled {
      border-color: var(--clr-success);

      i {
        color: var(--clr-success);
      }
    }
  }

  &_body {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: flex-start;
    margin-top: 32px;
    gap: 128px;

    .form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .image-name {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 2fr;
        gap: 32px;

        .name-col {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
      }

      .col-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 32px;
      }

      .actions {
        text-align: right;
      }
    }

    .main-actions {
      padding: 16px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      text-align: right;

      button:not(:first-child) {
        margin-left: 16px;
      }
    }

    .salons {
      padding: 16px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;

      &_salons {
        margin-top: 16px;

        ul {
          li {
            border-radius: 5px;
            padding: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:hover {
              background-color: var(--clr-white);
            }

            &:not(:first-child) {
              margin-top: 16px;
            }
            .text {
              cursor: pointer;

              p {
                margin-top: 5px;
              }
            }

            .actions {
              display: flex;
              gap: 16px;
              text-align: right;
            }
            .access {
              margin-top: 12px;
              text-align: right;
              cursor: pointer;

              .allow {
                color: var(--clr-secondary);
              }
              .disallow {
                color: var(--clr-danger);
              }
            }
          }
        }

        .none {
          text-align: center;

          p {
            margin-top: 8px;
          }
        }
      }
    }

    .accounts,
    .locations,
    .configs,
    .readers {
      margin-top: 32px;

      &_select {
        .parent {
          flex-grow: 1;
        }

        display: flex;
        align-items: flex-end;
        gap: 16px;
      }
    }
  }
}

.editStripeAccount {
  padding: 32px;

  .actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}

// Tablet
@media (max-width: 900px) {
  .profile {
    &_body {
      grid-template-columns: 1fr;
    }
  }
}
</style>
