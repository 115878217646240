<template>
  <section class="analytics">
    <div class="analytics_head">
      <div class="col">
        <h2>Analytics</h2>
        <p>View {{ selectedAnalyticType }} analytics</p>
      </div>

      <div @click="refreshAnalytics" class="col">
        <span class="link"><i class="fas fa-sync-alt"></i></span>
      </div>
    </div>

    <ul class="analytics_tabs">
      <li
        @click="selectedAnalyticType = 'booking'"
        :class="{ selected: selectedAnalyticType === 'booking' }"
      >
        Bookings
      </li>
      <li
        @click="selectedAnalyticType = 'otherOne'"
        :class="{ selected: selectedAnalyticType === 'otherOne' }"
      >
        Other One
      </li>
      <li
        @click="selectedAnalyticType = 'otherTwo'"
        :class="{ selected: selectedAnalyticType === 'otherTwo' }"
      >
        Other Two
      </li>
    </ul>

    <form @submit.prevent="search" class="analytics_actions">
      <div class="filters">
        <BaseInput
          placeholder="Salon ID, Client ID, Appointment ID"
          :value="searchValue"
          @input="searchValue = $event"
        />
        <BaseButton
          v-if="searchValue"
          @click="searchValue = ''"
          mode="danger-outline"
          ><i class="fas fa-backspace"></i
        ></BaseButton>
      </div>
      <div class="buttons">
        <BaseButton
          type="button"
          @click="showConfirmDeleteAll = true"
          mode="danger"
          :disabled="loading"
          ><i class="fas fa-exclamation-triangle"></i
          ><i v-if="loading" class="fas fa-spinner"></i> Clear
          Analytics</BaseButton
        >

        <BaseButton @click="acknowledgeAll" :disabled="loading"
          ><i v-if="loading" class="fas fa-spinner"></i> Acknowledge
          All</BaseButton
        >
      </div>
    </form>

    <section v-if="selectedAnalyticType === 'booking'" class="booking">
      <AdminBookingAnalytics
        :loading="loading"
        :searchValue="searchValue"
        @updateSearchValue="searchValue = $event"
        ref="currentAnalyticComponent"
      />
    </section>
  </section>

  <Confirm
    v-if="showConfirmDeleteAll"
    delete
    title="Clear analytics"
    text="DANGER! Are you sure you wish to clear all analytics? IF YOU ARE NOT RYAN SURVILO PROBABLY DONT DO THIS."
    @confirm="clearAnalytics"
    @deny="showConfirmDeleteAll = false"
  />
</template>

<script>
import AdminBookingAnalytics from '@/components/admin/AdminBookingAnalytics.vue';

export default {
  created() {
    if (this.$route.query.search) {
      this.searchValue = this.$route.query.search;
    }
  },
  components: {
    AdminBookingAnalytics,
  },
  computed: {},
  data() {
    return {
      loading: false,
      showConfirmDeleteAll: false,
      searchValue: '',
      selectedAnalyticType: 'booking',
    };
  },
  methods: {
    async clearAnalytics() {
      try {
        await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/analytics?type=${this.selectedAnalyticType}`
        );

        this.$toast.success('Analytics cleared!');

        this.showConfirmDeleteAll = false;

        this.$refs.currentAnalyticComponent.clearAnalytics();
      } catch (err) {
        console.log(err.message);
      }
    },

    async acknowledgeAll() {
      this.loading = true;

      try {
        await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/analytics/acknowledgeAll?type=${this.selectedAnalyticType}`
        );

        this.$toast.success('All logs acknowledged!');

        this.$refs.currentAnalyticComponent.markAllAsAcknowledged();
      } catch (e) {
        console.log(e.message);
      }

      this.loading = false;
    },

    async refreshAnalytics() {
      this.loading = true;

      await this.$refs.currentAnalyticComponent.init();

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics {
  margin: 32px auto;
  max-width: 800px;

  .light {
    color: var(--clr-gray);
  }

  .bold {
    font-weight: 700;
  }

  .small {
    font-size: 14px;
  }
  .mt-s {
    margin-top: 4px;
  }

  .text-green {
    color: var(--clr-success);
  }
  .text-yellow {
    color: var(--clr-warning-dark);
  }

  .link {
    color: var(--clr-link);
    cursor: pointer;
  }

  &_head {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 32px;
    }

    p {
      margin-top: 5px;
      color: var(--clr-gray);
    }

    i {
      font-size: 24px;
    }
  }

  &_tabs {
    margin-bottom: 32px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .selected {
      background-color: var(--clr-secondary);
      color: var(--clr-white);
      border: none;
    }

    li {
      text-align: center;
      padding: 8px 0;
      border: 1px solid var(--clr-light);
      cursor: pointer;

      &:not(:last-child) {
        border-right: none;
      }
    }
  }

  &_actions {
    padding: 16px;
    background-color: var(--clr-light);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filters,
    .buttons {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .danger {
      i {
        margin-right: 16px;
      }
    }
  }
}

// Mobile
@media (max-width: 600px) {
  .analytics {
    padding: 16px;

    &_tabs {
      grid-template-columns: 1fr;

      li {
        border: 1px solid var(--clr-light);

        &:not(:last-child) {
          border-right: 1px solid var(--clr-light);
          border-bottom: none;
        }
      }
    }

    &_actions {
      flex-direction: column;
      gap: 16px;

      .filters,
      .buttons {
        width: 100%;
        flex-direction: column;
        gap: 16px;

        .search,
        button,
        .parent {
          width: 100%;
        }
      }
    }
  }
}
</style>
