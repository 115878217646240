<template>
  <section class="docs">
    <div class="docs_sidebar">
      <SubSidebar :routes="routes" title="Guides" />
    </div>
    <div class="docs_router">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import SubSidebar from '@/components/components/SubSidebar.vue';

export default {
  components: {
    SubSidebar,
  },
  data() {
    return {
      routes: [
        {
          name: 'Getting Started',
          linkName: 'DocsGettingStarted',
        },
        {
          name: 'Team Members',
          linkName: 'DocsTeamMembers',
        },
        {
          name: 'Services',
          linkName: 'DocsServices',
        },
        {
          name: 'Products',
          linkName: 'DocsProducts',
        },
        {
          name: 'Appointments',
          linkName: 'DocsAppointments',
        },
        {
          name: 'Settings',
          linkName: 'DocsSettings',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.docs {
  display: flex;
  min-height: 100vh;

  &_router {
    overflow-y: auto;
    flex-grow: 1;
    padding: 32px;
    display: flex;
    justify-content: center;
  }
}

// Tablet
@media (max-width: 900px) {
  .docs {
    &_router {
      padding: 16px;
    }
  }
}
</style>
