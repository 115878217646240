// TODO: Split this code like god damn dude wtf is this shit
import { createRouter, createWebHistory } from 'vue-router';

import Landing from '../views/landing/Landing.vue';
import Index from '../views/landing/Index.vue';
import Pricing from '../views/landing/Pricing.vue';

import Dashboard from '../views/dashboard/Dashboard.vue';
import DashHome from '../views/dashboard/DashHome.vue';
import Calendar from '../views/calendar/Calendar.vue';

import ClassicView from '../views/classic/ClassicView.vue';

import Booking from '../views/booking/Booking.vue';
import SalonPage from '../views/booking/SalonPage.vue';
import SalonBook from '../views/booking/SalonBook.vue';
import BookServices from '../views/booking/BookServices.vue';
import BookMembers from '../views/booking/BookMembers.vue';
import BookDate from '../views/booking/BookDate.vue';
import BookConfirm from '../views/booking/BookConfirm.vue';
import BookAuth from '../views/booking/BookAuth.vue';
import ClientAuth from '../views/booking/ClientAuth.vue';
import ClientProfile from '../views/booking/ClientProfile.vue';
import ClientMessages from '../views/booking/ClientMessages.vue';
import CreateReview from '../views/booking/CreateReview.vue';
import OnlineShopCheckout from '../views/booking/OnlineShopCheckout.vue';

import Giftcards from '../views/giftcards/Giftcards.vue';
import Support from '../views/support/Support.vue';

import Kiosk from '../views/kiosk/Kiosk.vue';

import Team from '../views/team/Team.vue';
import TeamMembers from '../views/team/TeamMembers.vue';
import WorkingHours from '../views/team/WorkingHours.vue';
import RecordedActions from '../views/team/RecordedActions.vue';

import Items from '../views/items/Items.vue';
import Services from '../views/items/Services.vue';
import Products from '../views/items/Products.vue';
import Discounts from '../views/items/Discounts.vue';
import Packages from '../views/items/Packages.vue';
import Distributors from '../views/items/Distributors.vue';
import Loyalty from '../views/items/Loyalty.vue';

import Subscriptions from '../views/subscriptions/Subscriptions.vue';

import Sales from '../views/sales/Sales.vue';
import Summary from '../views/sales/Summary.vue';
import StaffSales from '../views/sales/StaffSales.vue';
import SalesTickets from '../views/sales/SalesTickets.vue';
import SalesHourly from '../views/sales/SalesHourly.vue';
import SalesByCategory from '../views/sales/SalesByCategory.vue';
import SalesByService from '../views/sales/SalesByService.vue';
import SalesByProduct from '../views/sales/SalesByProduct.vue';
import SalesVisuals from '../views/sales/SalesVisuals.vue';
import CleaningLogs from '../views/sales/CleaningLogs.vue';

import Clients from '../views/clients/Clients.vue';
import ClientsList from '../views/clients/ClientsList.vue';

import Messenger from '../views/messenger/Messenger.vue';
import Orders from '../views/orders/Orders.vue';

import Integrations from '../views/integrations/Integrations.vue';
import IntegrationsList from '../views/integrations/IntegrationsList.vue';
import IntegrationsHighLevel from '../views/integrations/IntegrationsHighLevel.vue';

import Campaigns from '../views/marketing/Campaigns.vue';
import Marketing from '../views/marketing/Marketing.vue';
import AutoCampaigns from '../views/marketing/AutoCampaigns.vue';
import EmailBlasts from '../views/marketing/EmailBlasts.vue';
import TextMarketing from '../views/marketing/TextMarketing.vue';
import ReviewReviews from '../views/marketing/ReviewReviews.vue';
import BookingMarketing from '../views/marketing/BookingMarketing.vue';

import TicketInvoicePage from '../views/tickets/TicketInvoicePage.vue';
import GiftcardPage from '../views/giftcards/GiftcardPage.vue';
import ConfirmedAppointment from '../views/booking/ConfirmedAppointment.vue';

import MemberSalonSelect from '../views/auth/MemberSalonSelect.vue';
import CreateSalon from '../views/auth/CreateSalon.vue';

import Settings from '../views/settings/Settings.vue';
import SettingsList from '../views/settings/SettingsList.vue';
import MemberPermissions from '../views/settings/MemberPermissions.vue';
import SalonInfo from '../views/settings/SalonInfo.vue';
import SalonSchedule from '../views/settings/SalonSchedule.vue';
import BookingSettings from '../views/settings/BookingSettings.vue';
import BookingGallerySetup from '../views/settings/BookingGallerySetup.vue';
import AdminSettings from '../views/settings/AdminSettings.vue';
import PaymentSettings from '../views/settings/PaymentSettings.vue';
import KioskSettings from '../views/settings/KioskSettings.vue';
import SecretsSettings from '../views/settings/SecretsSettings.vue';

import StaffRegister from '../views/auth/StaffRegister.vue';
import StaffLogin from '../views/auth/StaffLogin.vue';
import StaffProfile from '../views/auth/StaffProfile.vue';

import VirtTerm from '../views/virtTerm/VirtTerm.vue';

import Docs from '../views/docs/Docs.vue';
import DocsGettingStarted from '../views/docs/DocsGettingStarted.vue';
import DocsTeamMembers from '../views/docs/DocsTeamMembers.vue';
import DocsServices from '../views/docs/DocsServices.vue';
import DocsProducts from '../views/docs/DocsProducts.vue';
import DocsAppointments from '../views/docs/DocsAppointments.vue';
import DocsSettings from '../views/docs/DocsSettings.vue';

import DocsAPI from '../views/docs/API/DocsAPI.vue';
import DocsAPIAuth from '../views/docs/API/auth/DocsAPIAuth.vue';
import DocsAPIAppointments from '../views/docs/API/appointments/DocsAPIAppointments.vue';
import DocsAPITickets from '../views/docs/API/tickets/DocsAPITickets.vue';
import DocsAPIClients from '../views/docs/API/clients/DocsAPIClients.vue';
import DocsAPIServices from '../views/docs/API/services/DocsAPIServices.vue';
import DocsAPIDejavoo from '../views/docs/API/dejavoo/DocsAPIDejavoo.vue';
import DocsAPIStaff from '../views/docs/API/staff/DocsAPIStaff.vue';
import DocsAPISalons from '../views/docs/API/salons/DocsAPISalons.vue';
import DocsAPISettings from '../views/docs/API/settings/DocsAPISettings.vue';
import DocsAPIGiftcards from '../views/docs/API/giftcards/DocsAPIGiftcards.vue';

import DocsAPIGuideCreatingSalon from '../views/docs/API/guides/DocsAPIGuideCreatingSalon.vue';

import AdminHome from '../views/admin/AdminHome.vue';
import AdminLogin from '../views/admin/AdminLogin.vue';
import AdminPanel from '../views/admin/AdminPanel.vue';
import AdminTeam from '../views/admin/AdminTeam.vue';
import AdminUsers from '../views/admin/AdminUsers.vue';
import AdminLogs from '../views/admin/AdminLogs.vue';
import AdminUserDetailed from '../views/admin/AdminUserDetailed.vue';
import AdminSupport from '../views/admin/AdminSupport.vue';
import AdminStaffAccounts from '../views/admin/AdminStaffAccounts.vue';
import AdminAnalytics from '../views/admin/AdminAnalytics.vue';
import AdminTickets from '../views/admin/AdminTickets.vue';

import PageNotFound from '../views/landing/404.vue';

import authStore from '../store/auth';
import bookingStore from '../store/booking';

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing,
    meta: {
      title: 'Top Salon Booking Software',
    },
    children: [
      {
        path: '/',
        name: 'Index',
        component: Index,
      },
      {
        path: '/register',
        name: 'StaffRegister',
        component: StaffRegister,
      },
      {
        path: '/login',
        name: 'StaffLogin',
        component: StaffLogin,
      },
      {
        path: '/pricing',
        name: 'Pricing',
        component: Pricing,
      },
      {
        path: '/documentation',
        name: 'Docs',
        component: Docs,
        redirect: { name: 'DocsGettingStarted' },
        children: [
          {
            path: 'start',
            name: 'DocsGettingStarted',
            component: DocsGettingStarted,
          },
          {
            path: 'team',
            name: 'DocsTeamMembers',
            component: DocsTeamMembers,
          },
          {
            path: 'services',
            name: 'DocsServices',
            component: DocsServices,
          },
          {
            path: 'products',
            name: 'DocsProducts',
            component: DocsProducts,
          },
          {
            path: 'appointments',
            name: 'DocsAppointments',
            component: DocsAppointments,
          },
          {
            path: 'appointments',
            name: 'DocsSettings',
            component: DocsSettings,
          },
          {
            path: 'api',
            name: 'DocsAPI',
            component: DocsAPI,
            redirect: { name: 'DocsAPIAuth' },
            children: [
              {
                path: 'authentication',
                name: 'DocsAPIAuth',
                component: DocsAPIAuth,
              },
              {
                path: 'appointments',
                name: 'DocsAPIAppointments',
                component: DocsAPIAppointments,
              },
              {
                path: 'tickets',
                name: 'DocsAPITickets',
                component: DocsAPITickets,
              },
              {
                path: 'clients',
                name: 'DocsAPIClients',
                component: DocsAPIClients,
              },
              {
                path: 'services',
                name: 'DocsAPIServices',
                component: DocsAPIServices,
              },
              {
                path: 'dejavoo',
                name: 'DocsAPIDejavoo',
                component: DocsAPIDejavoo,
              },
              {
                path: 'creating-salon',
                name: 'DocsAPIGuideCreatingSalon',
                component: DocsAPIGuideCreatingSalon,
              },
              {
                path: 'staff',
                name: 'DocsAPIStaff',
                component: DocsAPIStaff,
              },
              {
                path: 'salons',
                name: 'DocsAPISalons',
                component: DocsAPISalons,
              },
              {
                path: 'settings',
                name: 'DocsAPISettings',
                component: DocsAPISettings,
              },
              {
                path: 'giftcards',
                name: 'DocsAPIGiftcards',
                component: DocsAPIGiftcards,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/salons',
    name: 'Booking',
    component: Booking,
    meta: {
      title: 'Book With Salons, Spas, and More Instantly',
    },
    children: [
      {
        path: 'auth',
        name: 'ClientAuth',
        component: ClientAuth,
      },
      {
        path: 'client/profile',
        name: 'ClientProfile',
        component: ClientProfile,
      },
      {
        path: 'client/profile/messages',
        name: 'ClientMessages',
        component: ClientMessages,
      },
      {
        path: ':id',
        name: 'SalonPage',
        component: SalonPage,
      },
      {
        path: ':id/review',
        name: 'CreateReview',
        component: CreateReview,
      },
      {
        path: ':id/checkout',
        name: 'OnlineShopCheckout',
        component: OnlineShopCheckout,
      },
      {
        path: ':id/book',
        name: 'SalonBook',
        component: SalonBook,
        meta: {
          requiresSalon: true,
        },
        redirect: { name: 'BookServices' },
        children: [
          {
            path: 'services',
            name: 'BookServices',
            component: BookServices,
          },
          {
            path: 'members',
            name: 'BookMembers',
            component: BookMembers,
          },
          {
            path: 'date',
            name: 'BookDate',
            component: BookDate,
          },
          {
            path: 'confirm',
            name: 'BookConfirm',
            component: BookConfirm,
          },
          {
            path: 'auth',
            name: 'BookAuth',
            component: BookAuth,
          },
        ],
      },
    ],
  },
  {
    path: '/tickets/:id',
    name: 'TicketInvoicePage',
    component: TicketInvoicePage,
  },
  {
    path: '/giftcards/:id',
    name: 'GiftcardPage',
    component: GiftcardPage,
  },
  {
    path: '/a/:id',
    name: 'ConfirmedAppointment',
    component: ConfirmedAppointment,
  },
  {
    path: '/kiosk',
    name: 'Kiosk',
    component: Kiosk,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    redirect: { name: 'Dashboard' },
    meta: {
      requiresAuth: true,
      title: 'Dashboard',
    },
    children: [
      {
        path: 'home',
        name: 'Dashboard',
        component: DashHome,
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar,
      },
      {
        path: 'classic',
        name: 'ClassicView',
        component: ClassicView,
      },
      {
        path: 'team',
        name: 'Team',
        component: Team,
        // redirect: { name: 'TeamMembers' },
        children: [
          {
            path: 'members',
            name: 'TeamMembers',
            component: TeamMembers,
          },
          {
            path: 'schedule',
            name: 'Schedule',
            component: WorkingHours,
          },
          {
            path: 'actions',
            name: 'Actions',
            component: RecordedActions,
          },
        ],
      },
      {
        path: 'items',
        name: 'Items',
        component: Items,
        // redirect: { name: 'Services' },
        children: [
          {
            path: 'services',
            name: 'Services',
            component: Services,
          },
          {
            path: 'products',
            name: 'Products',
            component: Products,
          },
          {
            path: 'discounts',
            name: 'Discounts',
            component: Discounts,
          },
          {
            path: 'packages',
            name: 'Packages',
            component: Packages,
          },
          {
            path: 'subscriptions',
            name: 'Subscriptions',
            component: Subscriptions,
          },
          {
            path: 'distributors',
            name: 'Distributors',
            component: Distributors,
          },
          {
            path: 'loyalty',
            name: 'Loyalty',
            component: Loyalty,
          },
        ],
      },
      {
        path: 'giftcards',
        name: 'Giftcards',
        component: Giftcards,
      },
      {
        path: 'support',
        name: 'Support',
        component: Support,
      },
      {
        path: 'sales',
        name: 'Sales',
        component: Sales,
        children: [
          {
            path: 'summary',
            name: 'Summary',
            component: Summary,
          },
          {
            path: 'staff',
            name: 'StaffSales',
            component: StaffSales,
          },
          {
            path: 'tickets',
            name: 'SalesTickets',
            component: SalesTickets,
          },
          {
            path: 'shifts',
            name: 'SalesHourly',
            component: SalesHourly,
          },
          {
            path: 'category',
            name: 'SalesByCategory',
            component: SalesByCategory,
          },
          {
            path: 'service',
            name: 'SalesByService',
            component: SalesByService,
          },
          {
            path: 'product',
            name: 'SalesByProduct',
            component: SalesByProduct,
          },
          {
            path: 'reports',
            name: 'SalesVisuals',
            component: SalesVisuals,
          },
          {
            path: 'cleaning',
            name: 'CleaningLogs',
            component: CleaningLogs,
          },
        ],
      },
      {
        path: 'marketing',
        name: 'Marketing',
        redirect: { name: 'AutoCampaigns' },
        component: Marketing,
        children: [
          {
            path: 'auto',
            name: 'AutoCampaigns',
            component: AutoCampaigns,
          },
          {
            path: 'email',
            name: 'EmailBlasts',
            component: EmailBlasts,
          },
          {
            path: 'texts',
            name: 'TextMarketing',
            component: TextMarketing,
          },
          {
            path: 'reviews',
            name: 'ReviewReviews',
            component: ReviewReviews,
          },
          {
            path: 'booking',
            name: 'BookingMarketing',
            component: BookingMarketing,
          },
          {
            path: 'campaigns',
            name: 'Campaigns',
            component: Campaigns,
          },
        ],
      },
      {
        path: 'integrations',
        name: 'Integrations',
        component: Integrations,
        redirect: { name: 'IntegrationsList' },
        children: [
          {
            path: 'list',
            name: 'IntegrationsList',
            component: IntegrationsList,
            meta: {
              inactiveAllowed: true,
            },
          },
          {
            path: 'highlevel',
            name: 'IntegrationsHighLevel',
            component: IntegrationsHighLevel,
          },
        ],
      },
      {
        path: 'clients',
        name: 'Clients',
        component: Clients,
        redirect: { name: 'ClientsList' },
        children: [
          {
            path: 'list',
            name: 'ClientsList',
            component: ClientsList,
          },
        ],
      },
      {
        path: 'messenger',
        name: 'Messenger',
        component: Messenger,
      },
      {
        path: 'orders',
        name: 'Orders',
        component: Orders,
      },
      {
        path: 'terminal',
        name: 'VirtTerm',
        component: VirtTerm,
      },
      {
        path: 'settings',
        name: 'Settings',
        component: Settings,
        redirect: { name: 'SettingsList' },
        children: [
          {
            path: 'list',
            name: 'SettingsList',
            component: SettingsList,
            meta: {
              inactiveAllowed: true,
            },
          },
          {
            path: 'permissions',
            name: 'MemberPermissions',
            component: MemberPermissions,
          },
          {
            path: 'salon',
            name: 'SalonInfo',
            component: SalonInfo,
          },
          {
            path: 'schedule',
            name: 'SalonSchedule',
            component: SalonSchedule,
          },
          {
            path: 'booking',
            name: 'BookingSettings',
            component: BookingSettings,
          },
          {
            path: 'booking/gallery',
            name: 'BookingGallerySetup',
            component: BookingGallerySetup,
          },
          {
            path: 'admin',
            name: 'AdminSettings',
            component: AdminSettings,
          },
          {
            path: 'payments',
            name: 'PaymentSettings',
            component: PaymentSettings,
          },
          {
            path: 'kiosk',
            name: 'KioskSettings',
            component: KioskSettings,
          },
          {
            path: 'secrets',
            name: 'SecretsSettings',
            component: SecretsSettings,
          },
        ],
      },
      {
        path: 'salon',
        name: 'SalonSelect',
        component: MemberSalonSelect,
        meta: {
          inactiveAllowed: true,
        },
      },
      {
        path: 'salon/register',
        name: 'SalonRegister',
        component: CreateSalon,
        meta: {
          inactiveAllowed: true,
        },
      },
      {
        path: 'profile',
        name: 'StaffProfile',
        component: StaffProfile,
        meta: {
          inactiveAllowed: true,
        },
      },
    ],
  },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: AdminLogin,
  },
  {
    path: '/admin',
    name: 'AdminPanel',
    component: AdminPanel,
    redirect: { name: 'AdminHome' },
    children: [
      {
        path: '',
        name: 'AdminHome',
        component: AdminHome,
      },
      {
        path: 'team',
        name: 'AdminTeam',
        component: AdminTeam,
      },
      {
        path: 'users',
        name: 'AdminUsers',
        component: AdminUsers,
      },
      {
        path: 'users/:id',
        name: 'AdminUserDetailed',
        component: AdminUserDetailed,
      },
      {
        path: 'logs',
        name: 'AdminLogs',
        component: AdminLogs,
      },
      {
        path: 'support',
        name: 'AdminSupport',
        component: AdminSupport,
      },
      {
        path: 'staffs',
        name: 'AdminStaffAccounts',
        component: AdminStaffAccounts,
      },
      {
        path: 'analytics',
        name: 'AdminAnalytics',
        component: AdminAnalytics,
      },
      {
        path: 'tickets',
        name: 'AdminTickets',
        component: AdminTickets,
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _, next) => {
  if (to.meta.title) {
    document.title = `Raserva - ${to.meta.title}`;
  }

  if (to.meta.requiresAuth && !authStore.state.token) {
    next({
      name: 'StaffLogin',
      query: {
        error: 'Must be logged in to enter dashboard',
      },
    });
  } else if (to.meta.requiresSalon && !bookingStore.state.salon) {
    next({
      name: 'SalonPage',
      params: {
        id: to.params.id,
      },
    });
  } else {
    next();
  }
});

export default router;
