<template>
  <form @submit.prevent="submit" class="form">
    <div class="form_head">
      <!-- <h2>Location</h2> -->
    </div>
    <div class="form_body">
      <BaseInput
        label="Display Name"
        placeholder="My First Store"
        :value="newLocation.display_name"
        @input="newLocation.display_name = $event"
      />
      <BaseInput
        label="Street"
        placeholder="1234 Main Street"
        :value="newLocation.address.line1"
        @input="newLocation.address.line1 = $event"
      />
      <BaseInput
        label="City"
        placeholder="San Fransico"
        :value="newLocation.address.city"
        @input="newLocation.address.city = $event"
      />
      <BaseInput
        label="State"
        placeholder="California"
        :value="newLocation.address.state"
        @input="newLocation.address.state = $event"
      />
      <BaseInput
        label="Postal"
        placeholder="94111"
        :value="newLocation.address.postal_code"
        @input="newLocation.address.postal_code = $event"
      />
      <div class="form_actions">
        <BaseButton
          v-if="newLocation.id"
          @click="showConfirmDelete = true"
          type="button"
          :disabled="!formValid || loading"
          mode="danger-outline"
          ><i v-if="loading" class="fas fa-spinner"></i> Delete
          Location</BaseButton
        >
        <BaseButton :disabled="loading">
          <i v-if="loading" class="fas fa-spinner"></i>
          {{ newLocation.id ? 'Edit' : 'Create' }} Location</BaseButton
        >
      </div>
    </div>
  </form>

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Delete location"
    text="Are you sure you wish to delete this location? This action can not be undone."
    @confirm="deleteLocation"
    @deny="showConfirmDelete = false"
  />
</template>

<script>
export default {
  emits: ['created', 'edited', 'deleted'],
  props: {
    accountId: {
      type: String,
      required: true,
    },
    location: {
      type: Object,
    },
  },
  created() {
    if (this.location)
      this.newLocation = JSON.parse(
        JSON.stringify({ ...this.newLocation, ...this.location })
      );
  },
  computed: {
    formValid() {
      if (
        !this.newLocation.display_name ||
        !this.newLocation.address.line1 ||
        !this.newLocation.address.city ||
        !this.newLocation.address.state ||
        !this.newLocation.address.country ||
        !this.newLocation.address.postal_code
      )
        return false;

      return true;
    },
  },
  data() {
    return {
      newLocation: {
        display_name: '',
        address: {
          line1: '',
          city: '',
          state: '',
          country: 'US',
          postal_code: '',
        },
      },

      showConfirmDelete: false,
      loading: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;

      if (this.newLocation.id) {
        try {
          // Edit
          const response = await this.$axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/location/${this.newLocation.id}?accountId=${this.accountId}`,
            {
              id: this.newLocation.id,
              location: {
                display_name: this.newLocation.display_name,
                address: {
                  line1: this.newLocation.address.line1,
                  city: this.newLocation.address.city,
                  state: this.newLocation.address.state,
                  country: this.newLocation.address.country,
                  postal_code: this.newLocation.address.postal_code,
                },
              },
            },
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );

          this.$emit('edited', response.data.location);
        } catch (error) {
          this.$toast.error(error.message);
        }
      } else {
        try {
          // Create
          const response = await this.$axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/location?accountId=${this.accountId}`,
            this.newLocation,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );

          this.$emit('created', response.data.location);
        } catch (error) {
          this.$toast.error(error.message);
        }
      }

      this.loading = false;
    },
    async deleteLocation() {
      this.loading = true;

      try {
        await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/location/${this.newLocation.id}?accountId=${this.accountId}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$emit('deleted', this.newLocation);
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  &_body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &_actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
