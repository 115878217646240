<template>
  <p ref="tag" :style="{ backgroundColor: backgroundColor, color: color }">
    {{ text }} <i v-if="icon" :class="icon"></i>
  </p>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '#0068ff',
    },
    backgroundColor: {
      type: String,
      default: '#c0d8fb',
    },
    icon: {
      type: String,
      default: '',
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.clickable) {
      // Add clickable class
      this.$refs.tag.classList.add('clickable');
    }
  },
};
</script>

<style lang="scss" scoped>
.clickable {
  cursor: pointer;
}

p {
  display: inline-block;
  padding: 3px 8px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

i {
  margin-left: 8px;
}
</style>
