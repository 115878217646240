import axios from 'axios';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async create({ rootState }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/packages`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.auth.salon.packages.push(response.data.package);
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async edit({ rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/packages/${payload._id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        const index = rootState.auth.salon.packages.findIndex(
          (p) => p._id.toString() === payload._id.toString()
        );

        if (index !== -1) {
          rootState.auth.salon.packages[index] = response.data.package;
        }
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async delete({ rootState }, payload) {
      try {
        const response = await axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/packages/${payload._id}`,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.auth.salon.packages = response.data.packages;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
  },
};
