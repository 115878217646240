<template>
  <section class="summary">
    <div class="summary_head">
      <h2>Summary</h2>

      <div
        v-if="!loggedInStaffIsAdmin && !managerMode"
        class="alert alert-info"
      >
        <p>Turn on manager mode to view more data!</p>
      </div>
    </div>
    <div class="summary_drb">
      <DateRangeBar
        :start="start"
        :end="end"
        @search="search($event.starting, $event.ending)"
      >
        <div class="printButton">
          <BaseButton mode="primary-outline" @click="showPrintable = true"
            >Print</BaseButton
          >
        </div>
      </DateRangeBar>
    </div>

    <Spinner v-if="loading" />

    <div v-else class="summary_tables">
      <div class="summary_tables_table">
        <Table
          v-if="managerMode || loggedInStaffIsAdmin"
          title="Earnings"
          :headers="earningsHeaders"
          :data="earningsData"
          :boldRows="[4]"
        />
        <Table
          title="Transactions"
          :headers="transactionHeaders"
          :data="transactionData"
          :boldRows="[4]"
          @rowClick="selectTransactionRow($event)"
        />
        <Table
          title="Gift cards"
          :headers="giftcardHeaders"
          :data="giftcardData"
          @rowClick="selectGiftCardRow($event)"
        />
        <Table
          title="Appointments"
          :headers="appointmentHeaders"
          :data="appointmentData"
        />
      </div>
      <div class="summary_tables_table">
        <Table
          title="Ticket payments"
          :headers="paymentsHeaders"
          :data="paymentsData"
          :boldRows="[4]"
          @rowClick="selectPaymentRow($event)"
        />
        <Table
          v-if="usesCashDiscount"
          title="Cash discount"
          :headers="cashDiscountHeaders"
          :data="cashDiscountData"
          :boldRows="[4]"
        />
        <Table
          title="Total payments"
          :headers="totalPaymentsHeaders"
          :data="totalPaymentsData"
          :boldRows="[3]"
        />
      </div>
    </div>
  </section>

  <div v-if="showPrintable" class="printable" id="summaryPrint">
    <div class="head">
      <p class="bold">{{ `${formatDate(start)} - ${formatDate(end)}` }}</p>
    </div>

    <!-- Transaction Data -->
    <p>Transactions</p>

    <table>
      <tr>
        <td></td>
        <td>Completed</td>
        <td>Refunded</td>
        <td>Amount</td>
      </tr>
      <!-- Services -->
      <tr>
        <td>Services</td>
        <td>{{ this.transactionData[0].salesQty }}</td>
        <td>{{ this.transactionData[0].refundQty }}</td>
        <td>{{ this.transactionData[0].gross }}</td>
      </tr>
      <!-- Products -->
      <tr>
        <td>Products</td>
        <td>{{ this.transactionData[1].salesQty }}</td>
        <td>{{ this.transactionData[1].refundQty }}</td>
        <td>{{ this.transactionData[1].gross }}</td>
      </tr>
      <!-- Prepaids -->
      <tr>
        <td>Prepaids</td>
        <td>{{ this.transactionData[2].salesQty }}</td>
        <td>{{ this.transactionData[2].refundQty }}</td>
        <td>{{ this.transactionData[2].gross }}</td>
      </tr>
      <!-- Loyalty -->
      <tr>
        <td>Loyalty Redemptions</td>
        <td>{{ this.transactionData[3].salesQty }}</td>
        <td>{{ this.transactionData[3].refundQty }}</td>
        <td>{{ this.transactionData[3].gross }}</td>
      </tr>
      <!-- Total -->
      <tr class="bold">
        <td>Total</td>
        <td>{{ this.transactionData[4].salesQty }}</td>
        <td>{{ this.transactionData[4].refundQty }}</td>
        <td>{{ this.transactionData[4].gross }}</td>
      </tr>
    </table>

    <!-- Payments Data -->
    <p>Payments</p>

    <table>
      <tr>
        <td></td>
        <td>Collected</td>
        <td>Refunded</td>
      </tr>
      <!-- Cash -->
      <tr>
        <td>Cash</td>
        <td>{{ this.paymentsData[0].collected }}</td>
        <td>{{ this.paymentsData[0].refunded }}</td>
      </tr>
      <!-- Card -->
      <tr>
        <td>Card</td>
        <td>{{ this.paymentsData[1].collected }}</td>
        <td>{{ this.paymentsData[1].refunded }}</td>
      </tr>
      <!-- Gift -->
      <tr>
        <td>Gift</td>
        <td>{{ this.paymentsData[2].collected }}</td>
        <td>{{ this.paymentsData[2].refunded }}</td>
      </tr>
      <!-- Other -->
      <tr>
        <td>Other</td>
        <td>{{ this.paymentsData[3].collected }}</td>
        <td>{{ this.paymentsData[3].refunded }}</td>
      </tr>
      <!-- Total -->
      <tr class="bold">
        <td>Total</td>
        <td>{{ this.paymentsData[4].collected }}</td>
        <td>{{ this.paymentsData[4].refunded }}</td>
      </tr>
    </table>

    <!-- Gift cards -->
    <p>Gift Cards</p>

    <table>
      <tr>
        <td></td>
        <td>Amount</td>
      </tr>
      <!-- Generated -->
      <tr>
        <td>Generated</td>
        <td>{{ this.giftcardData[0].amount }}</td>
      </tr>
      <!-- Collected -->
      <tr>
        <td>Collected</td>
        <td>{{ this.giftcardData[1].amount }}</td>
      </tr>
      <!-- Refunded -->
      <tr>
        <td>Refunded</td>
        <td>{{ this.giftcardData[2].amount }}</td>
      </tr>
    </table>

    <p>Have a good day - Raserva</p>
  </div>

  <Modal
    title="Transaction Details"
    :show="showTransactionDetails"
    @close="showTransactionDetails = false"
  >
    <div class="transactionDetailsTable">
      <Table
        :headers="transactionDetailsHeaders"
        :data="selectedTransactionDetails"
        @rowClick="selectTicket(selectedTransactionDetails[$event].ticket)"
      />
    </div>
  </Modal>

  <Modal
    title="Payment Details"
    :show="showPaymentDetails"
    @close="showPaymentDetails = false"
  >
    <div class="transactionDetailsTable">
      <Table
        :headers="paymentDetailsHeaders"
        :data="selectedPaymentDetails"
        @rowClick="selectTicket(selectedPaymentDetails[$event].ticket)"
      />
    </div>
  </Modal>

  <Modal
    title="Gift Card Details"
    :show="showGiftcardDetails"
    @close="showGiftcardDetails = false"
  >
    <div class="transactionDetailsTable">
      <Table
        :headers="giftcardDetailsHeaders"
        :data="selectedGiftcardDetails"
        @rowClick="
          selectedGiftcardDetails[$event].ticket
            ? selectTicket(selectedGiftcardDetails[$event].ticket)
            : null
        "
      />
    </div>
  </Modal>
</template>

<script>
import printJS from 'print-js';

import DateRangeBar from '@/components/components/DateRangeBar.vue';

export default {
  components: {
    DateRangeBar,
  },
  created() {
    if (!this.loggedInStaff) return;

    if (this.$route.query.start)
      this.start = this.$moment(this.$route.query.start).startOf('day');
    if (this.$route.query.end)
      this.end = this.$moment(this.$route.query.end).endOf('day');

    this.init();
  },
  watch: {
    loggedInStaff() {
      this.init();
    },
    showPrintable(val) {
      if (val) {
        setTimeout(() => {
          this.print();
        }, 0);
      }

      setTimeout(() => {
        this.showPrintable = false;
      }, 1000);
    },
  },
  computed: {
    managerMode() {
      return this.$store.state.auth.managerMode;
    },
    loggedInStaffIsAdmin() {
      if (!this.$store.state.auth.user || !this.$store.state.auth.salon)
        return false;

      return (
        this.$store.state.auth.user._id === this.$store.state.auth.salon.adminId
      );
    },
    usesCashDiscount() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.payments.useCashDiscount;
    },
    loggedInStaff() {
      return this.$store.state.auth.loggedInSalonStaff;
    },
  },
  data() {
    return {
      start: null,
      end: null,
      loading: false,

      showPrintable: false,

      // Transactions
      transactionHeaders: [
        'Item type',
        'Sales qty',
        'Refund qty',
        'Gross total',
      ],
      transactionData: null,

      // Earnings
      earningsHeaders: ['Source', 'Amount'],
      earningsData: null,

      // Payments
      paymentsHeaders: ['Payment type', 'Payments collected', 'Refunds paid'],
      paymentsData: null,

      // Gift Cards
      giftcardHeaders: ['Type', 'Amount'],
      giftcardData: null,

      // Appointments
      appointmentHeaders: ['Title', 'Value'],
      appointmentData: null,

      // Cash Discounts
      cashDiscountHeaders: ['Type', 'Amount'],
      cashDiscountData: null,

      // Total Payments
      totalPaymentsHeaders: ['Type', 'Amount'],
      totalPaymentsData: null,

      // Detailed Objects
      transactionsDetailed: {
        services: [],
        products: [],
        prepaids: [],
        loyalty: [],
      },
      showTransactionDetails: false,
      transactionDetailsHeaders: ['Ticket', 'Item', 'Price', 'Date'],
      selectedTransactionDetails: null,

      paymentDetailed: {
        cash: [],
        card: [],
        gift: [],
        other: [],
      },
      showPaymentDetails: false,
      paymentDetailsHeaders: ['Ticket', 'Processor', 'Amount', 'Note', 'Date'],
      selectedPaymentDetails: null,

      giftcardDetailed: {
        generated: [],
        collected: [],
        refunded: [],
      },
      showGiftcardDetails: false,
      giftcardDetailsHeaders: ['Code', 'Amount', 'Staff', 'Date'],
      selectedGiftcardDetails: null,
    };
  },
  methods: {
    init() {
      this.resetSummaries();

      this.search(
        this.start || this.$moment().startOf('day'),
        this.end || this.$moment().endOf('day')
      );
    },

    // async search(start, end) {
    //   this.start = start;
    //   this.end = end;

    //   this.loading = true;

    //   try {
    //     // Shifts
    //     const response = await this.$axios.get(
    //       `${process.env.VUE_APP_RASERVA_BACKEND}/shifts?salonId=${this.$store.state.auth.salon._id}&start=${this.start}&end=${this.end}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${this.$store.state.auth.token}`,
    //         },
    //       }
    //     );
    //     const shifts = response.data.shifts;

    //     // Tickets
    //     const tickets = await this.$store.dispatch(
    //       'tickets/getTicketsInDateRange',
    //       { start, end }
    //     );

    //     this.setSummary({ tickets, shifts });

    //     // Gift cards
    //     const giftcards = await this.$store.dispatch('giftcards/getGiftcards', {
    //       limit: 10000000,
    //       start: this.$moment(start).startOf('day').toDate(),
    //       end: this.$moment(end).endOf('day').toDate(),
    //     });

    //     this.setSummaryGiftcards({ giftcards });

    //     // Appointments
    //     const appointments = await this.$store.dispatch(
    //       'appointments/getAppointmentsBetweenDates',
    //       {
    //         salonId: this.$store.state.auth.salon._id,
    //         start: this.$moment(this.$moment(start).startOf('day')).tz(
    //           this.$store.state.auth.salon.details.timezone,
    //           true
    //         ),
    //         end: this.$moment(this.$moment(end).endOf('day')).tz(
    //           this.$store.state.auth.salon.details.timezone,
    //           true
    //         ),
    //         returnAppointments: true,
    //       }
    //     );

    //     this.setSummaryAppointments({ appointments });
    //   } catch (error) {
    //     this.$toast.error(error.message);
    //   }

    //   // Formatting
    //   this.formatTotals();

    //   this.loading = false;
    // },

    async search(start, end) {
      this.start = start;
      this.end = end;

      this.loading = true;

      const days =
        this.$moment(this.end).diff(this.$moment(this.start), 'days') || 1;

      if (days > 93) {
        this.$toast.error('You can only view a maximum of 93 days at a time.');
        this.loading = false;
        return;
      }

      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/summary?start=${this.start}&end=${this.end}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        const { appointments, tickets, giftcards, shifts } = response.data;

        await this.setSummary({ tickets, shifts });
        await this.setSummaryGiftcards({ giftcards });
        await this.setSummaryAppointments({ appointments });
      } catch (error) {
        this.$toast.error(error.message);
      }

      // Formatting
      this.formatTotals();

      this.loading = false;
    },

    async setSummaryAppointments(data) {
      // Reset
      this.appointmentData = [
        {
          title: 'Appointments',
          value: 0,
        },
        {
          title: 'Deposits Collected',
          value: 0,
        },
        {
          title: 'No-shows',
          value: 0,
        },
        {
          title: 'No-shows Collected',
          value: 0,
        },
      ];

      // Appointments
      data.appointments.forEach((appointment) => {
        this.appointmentData[0].value++;

        appointment.billing.deposits.forEach((deposit) => {
          this.appointmentData[1].value += +deposit.amount;

          // Total Payments
          this.totalPaymentsData[1].amount += +deposit.amount;
          this.totalPaymentsData[3].amount += +deposit.amount;
        });

        if (appointment.billing.noShowCharged) {
          this.appointmentData[2].value++;

          if (appointment.billing.noShowChargedAmount) {
            this.appointmentData[3].value +=
              appointment.billing.noShowChargedAmount || 0;
          } else {
            let apppointmentServicesTotal = 0;

            appointment.services.forEach((service) => {
              if (service.service) {
                apppointmentServicesTotal += service.service.price || 0;
              }
            });

            const amount = +(
              apppointmentServicesTotal *
              (this.$store.state.auth.salon.bookingSettings.noShowFeePercent *
                0.01)
            ).toFixed(2);

            this.appointmentData[3].value += amount;
          }
        }
      });

      // Format
      this.appointmentData[1].value = `$${this.appointmentData[1].value.toFixed(
        2
      )}`;
      this.appointmentData[3].value = `$${this.appointmentData[3].value.toFixed(
        2
      )}`;
    },

    async setSummaryGiftcards(data) {
      // Reset
      this.giftcardData = [
        {
          type: 'Generated',
          amount: 0,
        },
        {
          type: 'Collected',
          amount: 0,
        },
        {
          type: 'Refunded',
          amount: 0,
        },
      ];

      // Gift Cards
      data.giftcards.forEach((giftcard) => {
        if (giftcard.transactions.length) {
          this.giftcardData[0].amount += giftcard.transactions[0].from;
        } else {
          this.giftcardData[0].amount += giftcard.amount;
        }

        if (giftcard.billing.amount) {
          if (giftcard.billing.status === 'default') {
            this.giftcardData[1].amount += giftcard.billing.amount;

            // Total Payments
            this.totalPaymentsData[2].amount += giftcard.billing.amount;
            this.totalPaymentsData[3].amount += giftcard.billing.amount;
          } else {
            this.giftcardData[2].amount += giftcard.billing.amount;
          }
        }

        this.addToGiftCardDetailedFromGiftCard(giftcard);
      });

      // Format
      this.giftcardData[0].amount = `$${this.giftcardData[0].amount.toFixed(
        2
      )}`;
      this.giftcardData[1].amount = `$${this.giftcardData[1].amount.toFixed(
        2
      )}`;
      this.giftcardData[2].amount = `$${this.giftcardData[2].amount.toFixed(
        2
      )}`;
    },

    addToGiftCardDetailedFromGiftCard(giftcard) {
      if (giftcard.transactions.length) {
        this.giftcardDetailed.generated.push({
          code: giftcard.code,
          amount: `$${giftcard.amount.toFixed(2)}`,
          staff: giftcard.created_by ? giftcard.created_by.name : '',
          date: this.$moment(giftcard.created_at).format('lll'),
        });
      } else {
        this.giftcardDetailed.generated.push({
          code: giftcard.code,
          amount: `$${giftcard.amount.toFixed(2)}`,
          staff: giftcard.created_by ? giftcard.created_by.name : '',
          date: this.$moment(giftcard.created_at).format('lll'),
        });
      }

      if (giftcard.billing.amount) {
        if (giftcard.billing.status === 'default') {
          this.giftcardDetailed.collected.push({
            code: giftcard.code,
            amount: `$${giftcard.amount.toFixed(2)}`,
            staff: giftcard.created_by ? giftcard.created_by.name : '',
            date: this.$moment(giftcard.created_at).format('lll'),
          });
        } else {
          this.giftcardDetailed.refunded.push({
            code: giftcard.code,
            amount: `$${giftcard.amount.toFixed(2)}`,
            staff: giftcard.created_by ? giftcard.created_by.name : '',
            date: this.$moment(giftcard.created_at).format('lll'),
          });
        }
      }
    },

    async setSummary(data) {
      // Reset
      this.resetSummaries();

      // Tickets
      for (let i = 0; i < data.tickets.length; i++) {
        const ticket = data.tickets[i];

        this.addToTransactionSummaryFromTicket(ticket);
        this.addToTransactionDetailedFromTicket(ticket);
        this.addToPaymentDetailedFromTicket(ticket);
        await this.getCommissionPaid(ticket);

        // Tips for earnings
        ticket.tips.forEach((tip) => {
          this.earningsData[3].amount += tip.amount;
        });
      }

      // Shifts
      for (let i = 0; i < data.shifts.length; i++) {
        const shift = data.shifts[i];

        const minutes = this.$moment(shift.clockOut).diff(
          this.$moment(shift.clockIn),
          'minutes'
        );

        const earnings = shift.rate * (minutes / 60);

        this.earningsData[2].amount += earnings;
      }

      // Earnings
      this.earningsData[0].amount = this.totalPaymentsData[3].amount;

      this.earningsData[4].amount =
        this.earningsData[0].amount -
        this.earningsData[1].amount -
        this.earningsData[2].amount -
        this.earningsData[3].amount;
    },

    async getCommissionPaid(ticket) {
      if (ticket.status !== 'completed' && ticket.status !== 'refunded') return;

      const feeFirst =
        this.$store.state.auth.salon.adminSettings.takeFeeFirstForCommission;

      for (let i = 0; i < ticket.items.length; i++) {
        const item = ticket.items[i];

        let { commissionEarned, flatComServEarned, flatComProdEarned } =
          await this.$store.dispatch('staff/getCommissionEarned', {
            item,
            feeFirst,
          });

        this.earningsData[1].amount +=
          commissionEarned + flatComServEarned + flatComProdEarned;
      }
    },

    addToTransactionSummaryFromTicket(ticket) {
      if (ticket.status !== 'completed' && ticket.status !== 'refunded') return;

      const services = this.transactionData[0];
      const products = this.transactionData[1];
      const prepaids = this.transactionData[2];
      const loyaltyRedemptions = this.transactionData[3];
      const itemsTotal = this.transactionData[4];

      const cash = this.paymentsData[0];
      const card = this.paymentsData[1];
      const gift = this.paymentsData[2];
      const other = this.paymentsData[3];
      const paymentsTotal = this.paymentsData[4];

      // const basePrices = this.cashDiscountData[0];
      const additionalFees = this.cashDiscountData[0];

      // Items
      ticket.items.forEach((item) => {
        if (item.quantity > 0) {
          // Loyalty Redemption
          if (item.item.isLoyaltyRedemption) {
            loyaltyRedemptions.salesQty += item.quantity;
            loyaltyRedemptions.gross +=
              item.item.price - (item.item.discountAmount || 0);
          }

          if (item.item.duration) {
            // Service
            services.salesQty += item.quantity;
            services.gross +=
              item.item.price -
              (item.item.discountAmount || 0) +
              item.item.price * (item.item.taxRate * 0.01) * item.quantity;
          } else {
            // Product
            products.salesQty += item.quantity;
            products.gross +=
              item.item.price -
              (item.item.discountAmount || 0) +
              item.item.price * (item.item.taxRate * 0.01) * item.quantity;
          }

          // Prepaid
          if (item.item.prepaid && !item.item.isLoyaltyRedemption) {
            prepaids.salesQty += item.quantity;
            prepaids.gross +=
              item.item.price -
              (item.item.discountAmount || 0) +
              item.item.price * (item.item.taxRate * 0.01) * item.quantity;
          }

          // Cash Discount
          if (!item.item.prepaid) {
            if (!item.item.cashDiscount) {
              // basePrices.amount +=
              //   item.item.price -
              //   (item.item.cashDiscountAdditionalProcessingFee || 0);

              additionalFees.amount +=
                item.item.cashDiscountAdditionalProcessingFee || 0;
            }

            // Total
            itemsTotal.salesQty += item.quantity;
            itemsTotal.gross +=
              item.item.price -
              (item.item.discountAmount || 0) +
              item.item.price * (item.item.taxRate * 0.01) * item.quantity;
          }
        } else {
          if (item.item.isLoyaltyRedemption) {
            loyaltyRedemptions.refundQty += item.quantity;
            loyaltyRedemptions.gross +=
              item.item.price - (item.item.discountAmount || 0);
          }

          if (item.item.duration) {
            // Service
            services.refundQty += item.quantity;
            services.gross +=
              (item.item.price - (item.item.discountAmount || 0)) *
              item.quantity;
          } else {
            // Product
            products.refundQty += item.quantity;
            products.gross +=
              (item.item.price - (item.item.discountAmount || 0)) *
              item.quantity;
          }

          // Total
          if (!item.item.prepaid) {
            itemsTotal.refundQty += item.quantity;
            itemsTotal.gross +=
              (item.item.price - (item.item.discountAmount || 0)) *
              item.quantity;
          }
        }
      });

      // Payments
      ticket.payments.forEach((payment) => {
        let target;

        if (payment.status === 'default') {
          target = 'collected';
        } else if (payment.status === 'refunded') {
          target = 'refunded';
        }

        if (payment.type === 'cash') {
          cash[target] += payment.amount;
        } else if (payment.type === 'card') {
          card[target] += payment.amount;
        } else if (payment.type === 'gift') {
          gift[target] += payment.amount;
        } else if (payment.type === 'other') {
          other[target] += payment.amount;
        }

        // Total
        paymentsTotal[target] += payment.amount;

        // Total Payments
        if (payment.status === 'default') {
          this.totalPaymentsData[0].amount += payment.amount;
          this.totalPaymentsData[3].amount += payment.amount;
        } else if (payment.status === 'refunded') {
          this.totalPaymentsData[0].amount -= payment.amount;
          this.totalPaymentsData[3].amount -= payment.amount;
        }
      });

      // Tips
      // ticket.tips.forEach((tip) => {
      //   tips.salesQty++;
      //   tips.gross += tip.amount;
      //   itemsTotal.gross += tip.amount;
      // });
    },

    formatTotals() {
      try {
        // Transactions
        this.transactionData[0].gross = `$${this.transactionData[0].gross.toFixed(
          2
        )}`;
        this.transactionData[1].gross = `$${this.transactionData[1].gross.toFixed(
          2
        )}`;
        this.transactionData[2].gross = `$${this.transactionData[2].gross.toFixed(
          2
        )}`;
        this.transactionData[3].gross = `$${this.transactionData[3].gross.toFixed(
          2
        )}`;
        this.transactionData[4].gross = `$${this.transactionData[4].gross.toFixed(
          2
        )}`;

        // Earnings
        this.earningsData[0].amount = `$${this.earningsData[0].amount.toFixed(
          2
        )}`;
        this.earningsData[1].amount = `$${this.earningsData[1].amount.toFixed(
          2
        )}`;
        this.earningsData[2].amount = `$${this.earningsData[2].amount.toFixed(
          2
        )}`;
        this.earningsData[3].amount = `$${this.earningsData[3].amount.toFixed(
          2
        )}`;
        this.earningsData[4].amount = `$${this.earningsData[4].amount.toFixed(
          2
        )}`;

        this.paymentsData.forEach((paymentType) => {
          paymentType.collected = `$${paymentType.collected.toFixed(2)}`;
          paymentType.refunded = `$${paymentType.refunded.toFixed(2)}`;
        });

        this.cashDiscountData.forEach((paymentType) => {
          paymentType.amount = `$${paymentType.amount.toFixed(2)}`;
        });

        this.totalPaymentsData.forEach((paymentType) => {
          paymentType.amount = `$${paymentType.amount.toFixed(2)}`;
        });
      } catch (e) {
        console.log(e);
      }
    },

    resetSummaries() {
      this.transactionData = [
        {
          type: 'Services',
          salesQty: 0,
          refundQty: 0,
          gross: 0,
        },
        {
          type: 'Products',
          salesQty: 0,
          refundQty: 0,
          gross: 0,
        },
        {
          type: 'Prepaids',
          salesQty: 0,
          refundQty: 0,
          gross: 0,
        },
        {
          type: 'Loyalty Redemptions',
          salesQty: 0,
          refundQty: 0,
          gross: 0,
        },
        {
          type: 'Total Sales',
          salesQty: 0,
          refundQty: 0,
          gross: 0,
        },
      ];

      this.earningsData = [
        {
          source: 'Payments Collected',
          amount: 0,
        },
        {
          source: 'Commission Paid',
          amount: 0,
        },
        {
          source: 'Hourly Paid',
          amount: 0,
        },
        {
          source: 'Tips Paid',
          amount: 0,
        },
        {
          source: 'Total Earnings',
          amount: 0,
        },
      ];

      this.paymentsData = [
        {
          type: 'Cash',
          collected: 0,
          refunded: 0,
        },
        {
          type: 'Card',
          collected: 0,
          refunded: 0,
        },
        {
          type: 'Gift Card',
          collected: 0,
          refunded: 0,
        },
        {
          type: 'Other',
          collected: 0,
          refunded: 0,
        },
        {
          type: 'Total Collected',
          collected: 0,
          refunded: 0,
        },
      ];

      this.totalPaymentsData = [
        {
          type: 'Tickets',
          amount: 0,
        },
        {
          type: 'Deposits',
          amount: 0,
        },
        {
          type: 'Gift cards',
          amount: 0,
        },
        {
          type: 'Total Collected',
          amount: 0,
        },
      ];

      this.cashDiscountData = [
        // {
        //   type: 'Base Prices',
        //   amount: 0,
        // },
        {
          type: 'Adjustments Collected',
          amount: 0,
        },
      ];

      this.transactionsDetailed = {
        services: [],
        products: [],
        prepaids: [],
        loyalty: [],
      };

      this.paymentDetailed = {
        cash: [],
        card: [],
        gift: [],
        other: [],
      };
    },

    selectPaymentRow(index) {
      if (index === 0) {
        this.selectedPaymentDetails = this.paymentDetailed.cash;
      } else if (index === 1) {
        this.selectedPaymentDetails = this.paymentDetailed.card;
      } else if (index === 2) {
        this.selectedPaymentDetails = this.paymentDetailed.gift;
      } else if (index === 3) {
        this.selectedPaymentDetails = this.paymentDetailed.other;
      } else {
        this.selectedPaymentDetails = null;
        this.showPaymentDetails = false;
        return;
      }

      this.showPaymentDetails = true;
    },

    selectGiftCardRow(index) {
      if (index === 0) {
        this.selectedGiftcardDetails = this.giftcardDetailed.generated;
      } else if (index === 1) {
        this.selectedGiftcardDetails = this.giftcardDetailed.collected;
      } else if (index === 2) {
        this.selectedGiftcardDetails = this.giftcardDetailed.refunded;
      } else {
        this.selectedGiftcardDetails = null;
        this.showGiftcardDetails = false;
        return;
      }

      this.showGiftcardDetails = true;
    },

    selectTransactionRow(index) {
      if (index === 0) {
        this.selectedTransactionDetails = this.transactionsDetailed.services;
      } else if (index === 1) {
        this.selectedTransactionDetails = this.transactionsDetailed.products;
      } else if (index === 2) {
        this.selectedTransactionDetails = this.transactionsDetailed.prepaids;
      } else if (index === 3) {
        this.selectedTransactionDetails = this.transactionsDetailed.loyalty;
      } else {
        this.selectedTransactionDetails = null;
        this.showTransactionDetails = false;
        return;
      }

      this.showTransactionDetails = true;
    },

    async selectTicket(number) {
      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'tickets/viewTickets'
        )
      ) {
        this.$router.push({
          name: 'SalesTickets',
          query: {
            start: this.$moment(this.start).startOf('day').toDate(),
            end: this.$moment(this.end).endOf('day').toDate(),
            number,
            ref: `/dashboard/sales/summary?start=${this.start}&end=${this.end}`,
          },
        });
      }
    },

    addToPaymentDetailedFromTicket(ticket) {
      ticket.payments.forEach((payment) => {
        if (payment.type === 'cash') {
          this.paymentDetailed.cash.push({
            ticket: ticket.number,
            processor: payment.processor,
            amount: `$${payment.amount}`,
            note: payment.isDeposit ? 'Deposit' : '',
            date: this.$moment(payment.date).format('LLL'),
          });
        } else if (payment.type === 'card') {
          this.paymentDetailed.card.push({
            ticket: ticket.number,
            processor: payment.processor,
            amount: `$${payment.amount}`,
            note: payment.isDeposit
              ? 'Deposit'
              : ticket.onlineOrder
              ? 'Online order'
              : '',
            date: this.$moment(payment.date).format('LLL'),
          });
        } else if (payment.type === 'gift') {
          this.paymentDetailed.gift.push({
            ticket: ticket.number,
            processor: payment.processor,
            amount: `$${payment.amount}`,
            note: payment.isDeposit ? 'Deposit' : '',
            date: this.$moment(payment.date).format('LLL'),
          });
        } else if (payment.type === 'other') {
          this.paymentDetailed.other.push({
            ticket: ticket.number,
            processor: payment.processor,
            amount: `$${payment.amount}`,
            note: `${
              payment.isDeposit
                ? 'Deposit'
                : '' + payment.otherType
                ? ` ${payment.otherType}`
                : ''
            }${payment.note ? ` - ${payment.note}` : ''}`,
            date: this.$moment(payment.date).format('LLL'),
          });
        }
      });
    },

    addToTransactionDetailedFromTicket(ticket) {
      ticket.items.forEach((item) => {
        // Prepaid
        if (item.item.prepaid && !item.item.isLoyaltyRedemption) {
          this.transactionsDetailed.prepaids.push({
            ticket: ticket.number,
            item: item.item.title,
            price: `$${(
              (item.item.price - (item.item.discountAmount || 0)) *
              item.quantity
            ).toFixed(2)}`,
            date: this.$moment(ticket.checkout_at).format('LLL'),
          });
        }
        // Loyalty Redemption
        if (item.item.isLoyaltyRedemption) {
          this.transactionsDetailed.loyalty.push({
            ticket: ticket.number,
            item: item.item.title,
            price: `$${(
              (item.item.price - (item.item.discountAmount || 0)) *
              item.quantity
            ).toFixed(2)}`,
            date: this.$moment(ticket.checkout_at).format('LLL'),
          });
        }

        if (item.item.duration) {
          // Service
          this.transactionsDetailed.services.push({
            ticket: ticket.number,
            item: item.item.title,
            price: `$${(
              (item.item.price - (item.item.discountAmount || 0)) *
              item.quantity
            ).toFixed(2)}`,
            date: this.$moment(ticket.checkout_at).format('LLL'),
          });
        } else {
          // Product
          this.transactionsDetailed.products.push({
            ticket: ticket.number,
            item: item.item.title,
            price: `$${(
              (item.item.price - (item.item.discountAmount || 0)) *
              item.quantity
            ).toFixed(2)}`,
            date: this.$moment(ticket.checkout_at).format('LLL'),
          });
        }
      });
    },

    print() {
      printJS({
        printable: 'summaryPrint',
        type: 'html',
        style: `
          * { font-weight: 600 }
          p { text-align: center; }
          .bold { font-weight: 700; }
          table { border-collapse: collapse; margin-top: 16px; width: 100%; }
          table td { border: 1px solid #666666; }
          `,
      });
    },

    formatDate(date) {
      return this.$moment(date).format('LL');
    },
  },
};
</script>

<style lang="scss" scoped>
.summary {
  width: 100%;

  &_head {
    display: flex;
    align-items: center;
    gap: 32px;

    h2 {
      font-size: 28px;
    }

    .alert {
      padding: 12px;
      font-size: 14px;
      border-radius: 5px;

      &-info {
        background-color: #c3edf7;
        color: #044653;
        border-color: #aceaf7;
      }
    }
  }

  &_drb {
    margin-top: 32px;
  }

  &_tables {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
    flex-wrap: wrap;

    &_table {
      flex-grow: 1;
    }
  }
}

.transactionDetailsTable {
  margin: 32px;
  border: 1px solid var(--clr-light);
}

.printButton {
  display: flex;
  align-items: flex-end;
}

.printable {
  // width: 100%;

  table {
    width: 100%;
    border: 1px solid #666666;
    border-collapse: collapse;
    width: 100%;
  }
}
</style>
