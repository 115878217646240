<template>
  <Spinner v-if="loading" />

  <section v-else class="calendars">
    <div class="calendars_head">
      <div class="col">
        <h2>Calendars</h2>
      </div>

      <div class="col actions">
        <BaseButton @click="showCalendarForm = true" class="small"
          >Create</BaseButton
        >
      </div>
    </div>

    <div class="calendars_body">
      <div v-if="!calendars?.length" class="none">
        <p>No calendars to display</p>
      </div>

      <ul v-else>
        <li v-for="calendar in calendars" :key="calendar.id">
          <div class="col">
            <p>
              Name:
              <span class="bold">{{ calendar.name }}</span>
            </p>
            <p>
              Description: <span class="bold">{{ calendar.description }}</span>
            </p>
            <p>
              ID: <span class="bold">{{ calendar.id }}</span>
            </p>
          </div>

          <div class="col">
            <BaseButton
              @click="selectedCalendar = calendar"
              mode="primary-outline small"
              >Edit</BaseButton
            >
          </div>
        </li>
      </ul>
    </div>
  </section>

  <Modal
    title="Calendar Form"
    :show="showCalendarForm || selectedCalendar"
    @close="
      showCalendarForm = false;
      selectedCalendar = null;
    "
  >
    <div class="userForm">
      <HighLevelCalendarForm
        :calendar="selectedCalendar"
        @submit="formSubmitted($event)"
        @removeCalendar="removeCalendar()"
      />
    </div>
  </Modal>
</template>

<script>
import HighLevelCalendarForm from '@/components/integrations/highLevel/highLevelCalendarForm.vue';

export default {
  components: {
    HighLevelCalendarForm,
  },
  created() {
    this.init();
  },
  data() {
    return {
      loading: false,
      calendars: [],

      showCalendarForm: false,
      selectedCalendar: null,
    };
  },
  methods: {
    async init() {
      this.loading = true;

      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/highLevel/calendars?salonId=${this.$store.state.auth.salon._id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.calendars = response.data.calendars;
      } catch (err) {
        console.log(err);
      }

      this.loading = false;
    },

    formSubmitted(calendar) {
      const index = this.calendars.findIndex((u) => u.id === calendar.id);

      if (index === -1) {
        this.calendars.push(calendar);
      } else {
        this.calendars[index] = calendar;
      }

      this.showCalendarForm = false;
      this.selectedCalendar = null;
    },

    removeCalendar() {
      const index = this.calendars.findIndex(
        (u) => u.id === this.selectedCalendar.id
      );

      if (index !== -1) {
        this.calendars.splice(index, 1);
      }

      this.showCalendarForm = false;
      this.selectedCalendar = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.calendars {
  &_head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 28px;
    }

    .actions {
      display: flex;
      gap: 16px;
    }
  }

  &_body {
    margin-top: 32px;
    display: flex;
    gap: 16px;

    ul {
      width: 100%;

      li {
        width: 100%;
        padding: 16px;
        border-radius: 5px;
        background-color: var(--clr-white);
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        &:not(:first-child) {
          margin-top: 16px;
        }

        .bold {
          font-weight: 700;
        }
      }
    }
  }
}

.userForm {
  padding: 32px;
}
</style>
