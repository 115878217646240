<template>
  <div class="ticket" :style="{ borderColor }">
    <p class="bold">
      {{ ticketName }}
    </p>
    <p class="light small">
      {{ ticket.firstItem || firstServiceTitle
      }}<span v-if="ticket.requested"><i class="fas fa-heart"></i></span>
    </p>
    <p class="light small">Turns: {{ turns }}</p>

    <div
      v-if="ticket.groupColor"
      class="highlight"
      :style="{ backgroundColor: ticket.groupColor }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    staffId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ticketName() {
      return (
        this.ticket.title ||
        (this.ticket.client ? this.ticket.client.firstName : 'Walk-in')
      );
    },
    firstServiceTitle() {
      return this.ticket.items[0] && this.ticket.items[0].item
        ? this.ticket.items[0].item.title.substring(0, 15)
        : '';
    },
    borderColor() {
      return this.ticket.status === 'completed'
        ? 'var(--clr-success)'
        : 'var(--clr-danger)';
    },
    turns() {
      let turns = 0;

      this.ticket.items.forEach((item) => {
        if (item.staff && item.staff.staffId === this.staffId) {
          turns += item.item.turns || 0;
        }
      });

      return turns;
    },
  },
};
</script>

<style lang="scss" scoped>
.small {
  font-size: 12px;
}
.light {
  color: var(--clr-gray);
}
.bold {
  font-weight: 700;
}
.ticket {
  position: relative;
  text-align: center;
  background-color: var(--clr-white);
  padding: 16px;
  border-radius: 5px;
  box-shadow: var(--shadow);
  border-left: 5px solid transparent;
  cursor: pointer;
  font-size: 14px;
  // min-width: 125px;
  // max-width: 250px;
  width: 125px;
  overflow: hidden;
}

.fa-heart {
  color: var(--clr-danger);
  margin-left: 4px;
}

.highlight {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
}
</style>
