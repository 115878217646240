<template>
  <div class="endpoint_head">
    <h2>Create ticket</h2>

    <div class="route">
      <i
        @click="
          $router.push('/documentation/api/tickets#create');
          $toast.info('Set URL to endpoint');
        "
        :style="{ color: 'var(--clr-secondary)', cursor: 'pointer' }"
        class="fas fa-hashtag"
      ></i>
      <p class="tag tag-blue">POST</p>
      <p class="url">{{ apiUrl }}<span>/tickets</span></p>
    </div>

    <div class="description">
      <p>Creates a ticket</p>
    </div>
  </div>

  <div class="endpoint_body">
    <div class="request">
      <h3>Request</h3>

      <!-- Headers -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Headers</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">Authorization</p>
              <p class="examples">Examples: <span>Bearer {Token}</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
          <p>application/json</p>
        </div>

        <ul>
          <!-- Salon ID -->
          <li>
            <div class="col value">
              <p class="bold">salonId</p>
              <p class="description">String/ID of the salon</p>
              <p class="examples">
                Examples: <span>1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- Items -->
          <li>
            <div class="col value">
              <p class="bold">items</p>
              <p class="description">
                Array of services and products on ticket
              </p>
              <p class="examples">
                Examples:
                <span
                  >[ { status: "default", quantity: 1, staff: {StaffMinObject},
                  item: {ServiceObject OR ProductObject} } ]</span
                >
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- Service Object -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">{ServiceObject}</p>
              <p class="description">
                ** This is not a part of the request, this is an example of the
                service object sent in the "items" array
              </p>
              <p class="examples">
                Examples:
                <span
                  >{ "title": "Service", "color": "#a6c7ea", "isCommissioned":
                  "false", "price": 100, "taxRate": 0, "duration": 20,
                  "categoryId": "622ec883ec2552e0d48ed212" }</span
                >
              </p>
            </div>

            <div class="col"></div>
          </li>

          <!-- Product Object -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">{ProductObject}</p>
              <p class="description">
                ** This is not a part of the request, this is an example of the
                product object sent in the "items" array
              </p>
              <p class="examples">
                Examples:
                <span
                  >{ "title": "Product", "price": 100, "taxRate": 7,
                  "commissionType": "flat", "commissionAmount": 5 }</span
                >
              </p>
            </div>

            <div class="col"></div>
          </li>

          <!-- Staff Min Object -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">{StaffMinObject}</p>
              <p class="description">
                ** This is not a part of the request, this is an example of the
                staff min object sent in the "services" array
              </p>
              <!-- Above sentence in -->
              <p class="examples">
                Examples:
                <span
                  >{ "staffId": "622ec119ec2552e0d48ed192", "firstName": "John",
                  "lastName": "Doe", "email": "john@doe.com", "phoneNumber":
                  "5551112222", "commission": 60 }</span
                >
              </p>
            </div>

            <div class="col"></div>
          </li>

          <!-- Appointment ID -->
          <li>
            <div class="col value">
              <p class="bold">appointmentId</p>
              <p class="description">String/ID of an associated appointment</p>
              <p class="examples">
                Examples: <span>1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- Status -->
          <li>
            <div class="col value">
              <p class="bold">status</p>
              <p class="description">
                Status of ticket (Default: 'in-progress')
              </p>
              <p class="examples">
                Examples:
                <span>['in-progress', 'waiting', 'completed', 'refunded']</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- Note -->
          <li>
            <div class="col value">
              <p class="bold">note</p>
              <p class="description">Note on ticket</p>
              <p class="examples">
                Examples: <span>Client came with mother</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- Client -->
          <li>
            <div class="col value">
              <p class="bold">client</p>
              <p class="description">
                Client associated with ticket (must already exist)
              </p>
              <p class="examples">
                Examples:
                <span
                  >"client": { "_id": "6238b0baa691db3afc07e534", "firstName":
                  "John", "lastName": "Doe", "phoneNumber": "5551112222",
                  "email": "john@doe.com" }</span
                >
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- Title -->
          <li>
            <div class="col value">
              <p class="bold">title</p>
              <p class="description">
                Title for ticket (If client is attached to ticket, client name
                overrides this on dashboard)
              </p>
              <p class="examples">
                Examples:
                <span>Green shirt girl</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="response">
      <h3>Response</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">ticket</p>
              <p class="examples">
                Examples:
                <span
                  >{ "salonId": "622ec883ec2552e0d48ed289", "number": 772,
                  "status": "in-progress", "kioskClient": { "notifications": {
                  "email": true, "text": true, "marketing": true } }, "items": [
                  { "status": "default", "quantity": 1, "staff": { "staffId":
                  "622ec119ec2552e0d48ed123", "firstName": "John", "lastName":
                  "Doe", "email": "john@doe.com", "phoneNumber": "5551112222",
                  "commission": 60, "exemptFromServiceFees": false, "_id":
                  "649dc0a76831aace66a659b3", "customCommissions": [] }, "item":
                  { "title": "Service", "color": "#a6c7ea", "isCommissioned":
                  "false", "taxRate": 0, "price": 100, "duration": 20,
                  "categoryId": "622ec883ec2552e0d48ed213" }, "_id":
                  "649dc0a76831aace66a659b2" } ], "packages": [], "reviewed":
                  false, "onlineOrder": false, "_id":
                  "649dc0a76831aace66a659b1", "created_at":
                  "2023-06-29T17:34:31.378Z", "updated_at":
                  "2023-06-29T17:34:31.378Z", "tips": [], "payments": [], "__v":
                  0 }</span
                >
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="reqResExamples">
      <h3>Examples</h3>

      <!-- Request -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Request</h4>
          <p>application/json</p>
        </div>

        <ul>
          <li>
            <p>
              { "salonId": "622ec883ec2552e0d48ed289", "items": [ { "quantity":
              1, "staff": { "staffId": "622ec119ec2552e0d48ed123", "firstName":
              "John", "lastName": "Doe", "email": "john@doe.com", "phoneNumber":
              "5551112222", "commission": 60 }, "item": { "title": "Service",
              "color": "#a6c7ea", "isCommissioned": "false", "taxRate": 0,
              "price": 100, "duration": 20, "categoryId":
              "622ec883ec2552e0d48ed213" } } ] }
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    apiUrl() {
      return process.env.VUE_APP_RASERVA_BACKEND;
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoint {
  .tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px;
    border-radius: 5px;
    color: white;

    &-blue {
      background-color: var(--clr-secondary);
    }
  }

  &_head {
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }

    .route {
      padding: 8px 16px;
      border-radius: 5px;
      background-color: var(--clr-white-2);
      display: flex;
      align-items: center;
      gap: 16px;

      .url {
        color: var(--clr-gray);

        span {
          color: var(--clr-primary);
          font-weight: 700;
        }
      }
    }

    .description {
      margin-top: 32px;
    }
  }

  &_body {
    .request,
    .response,
    .reqResExamples {
      margin-top: 32px;
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &_section {
      &:not(:first-child) {
        margin-top: 32px;
      }

      .head {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 20px;
          margin-bottom: 16px;
          color: var(--clr-secondary);
        }

        p {
          font-size: 14px;
          color: var(--clr-gray);
        }
      }

      ul {
        .subitem {
          margin-left: 32px;
        }

        li {
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          &:not(:first-child) {
            margin-top: 16px;
          }

          .value {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .bold {
            font-weight: 700;
          }
          .required {
            color: orangered;
          }

          .description {
            margin: 4px 0;
            color: var(--clr-gray);
          }

          .examples {
            span {
              background-color: var(--clr-white-2);
              padding: 4px 8px;
              border-radius: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
