<template>
  <div class="staff" :class="{ deleted: staff.isDeleted }">
    <div class="col ava-name">
      <Avatar
        :name="staff.firstName"
        :image="staff.profileImage"
        mode="rounded large"
      />
      <div class="name">
        <p>{{ getStaffName }}</p>
        <p class="title">{{ staff.title }}</p>
      </div>
    </div>
    <div class="col contact">
      <p>{{ staff.email }}</p>
      <p>{{ staff.phoneNumber }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    staff: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getStaffName() {
      return `${this.staff.firstName} ${this.staff.lastName}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.deleted {
  border-color: var(--clr-danger) !important;
  color: var(--clr-danger) !important;
}
.staff {
  padding: 16px;
  border: 1px solid var(--clr-light);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.2s;
  gap: 16px;

  &:hover {
    border-color: var(--clr-gray);
  }

  .ava-name {
    display: flex;
    align-items: center;
    gap: 16px;

    .name {
      .title {
        margin-top: 5px;
        font-weight: 400;
        font-size: 14px;
        color: var(--clr-gray);
      }
    }

    p {
      font-weight: 700;
      font-size: 18px;
    }
  }
  .contact {
    text-align: right;

    p {
      color: var(--clr-link);

      &:not(:first-child) {
        margin-top: 5px;
      }
    }
  }
}

// Tablet
@media (max-width: 900px) {
  .staff {
    flex-direction: column;

    .ava-name {
      flex-direction: column;
    }

    .contact {
      text-align: center;
    }
  }
}
</style>
