<template>
  <section class="create">
    <div class="create_head">
      <h2>Creating a salon</h2>
      <p class="description">
        Follow the instructions below to create a salon. A salon requires a
        subscription, in the following steps we will be creating the salon,
        creating a Stripe checkout session to collect payment information and
        start the subscription. A staff account will only have access to this
        salon if the subscription is active.
      </p>
    </div>

    <div class="create_body">
      <!-- CREATE SALON DOCUMENT -->
      <div class="section">
        <h3>1) Create the salon document</h3>
        <p class="description">
          This following steps require the salon to already exist
        </p>

        <div class="endpoint_head">
          <div class="route">
            <p class="tag tag-blue">POST</p>
            <p class="url">{{ apiUrl }}<span>/salons</span></p>
          </div>

          <div class="description">
            <p>Creates a salon</p>
          </div>
        </div>

        <div class="endpoint_body">
          <div class="request">
            <h3>Request</h3>

            <!-- Headers -->
            <div class="endpoint_body_section path">
              <div class="head">
                <h4>Headers</h4>
              </div>

              <ul>
                <li>
                  <div class="col value">
                    <p class="bold">Authorization</p>
                    <p class="examples">
                      Examples: <span>Bearer {Token}</span>
                    </p>
                  </div>

                  <div class="col">
                    <p class="required">required</p>
                  </div>
                </li>
              </ul>
            </div>

            <!-- Body -->
            <div class="endpoint_body_section path">
              <div class="head">
                <h4>Body</h4>
                <p>application/json</p>
              </div>

              <ul>
                <!-- Admin ID -->
                <li>
                  <div class="col value">
                    <p class="bold">adminId</p>
                    <p class="description">
                      ID of Raserva staff account that will be the "admin" of
                      the salon
                    </p>
                    <p class="examples">
                      Examples:
                      <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6f</span>
                    </p>
                  </div>

                  <div class="col">
                    <p class="required">required</p>
                  </div>
                </li>

                <!-- Details -->
                <li>
                  <div class="col value">
                    <p class="bold">details</p>
                    <p class="description">
                      Object <br /><br />
                      <span class="bold">shopName</span> - Name of the salon
                      <br />
                      <span class="bold">shortDescription</span> - Short
                      description of the salon to be shown on booking page<br />
                      <span class="bold">address</span> - OBJECT - Address of
                      the salon - street, city, state, postal
                      <br />
                      <span class="bold">timezone</span> - Timezone of the salon
                    </p>
                    <p class="examples">
                      Examples:
                      <span
                        >{ "shopName": "Hair Cuttery", "shortDescription": "We
                        cut hair!", "address": { "street": "123 Street St",
                        "city": "Orlando", "state": "FL", "postal": "12345" },
                        "timezone": "America/New_York" }</span
                      >
                    </p>
                  </div>

                  <div class="col">
                    <p class="required">required</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="response">
            <h3>Response</h3>

            <!-- Body -->
            <div class="endpoint_body_section path">
              <div class="head">
                <h4>Body</h4>
              </div>

              <ul>
                <li>
                  <div class="col value">
                    <p class="bold">salon</p>
                    <p class="examples">
                      Examples:
                      <span>OBJECT</span>
                    </p>
                  </div>

                  <div class="col">
                    <p class="required">required</p>
                  </div>
                </li>

                <li>
                  <div class="col value">
                    <p class="bold">token</p>
                    <p class="description">
                      Will return a new token to use which lets the API know
                      this staff member is now a part of this new salon
                    </p>
                    <p class="examples">
                      Examples:
                      <span>eyJhbGc..</span>
                    </p>
                  </div>

                  <div class="col">
                    <p class="required">required</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="reqResExamples">
            <h3>Examples</h3>

            <!-- Request -->
            <div class="endpoint_body_section path">
              <div class="head">
                <h4>Request</h4>
                <p>application/json</p>
              </div>

              <ul>
                <li>
                  <p>
                    { "adminId": "622ec119ec2552e0d48ed198", "details": {
                    "shopName": "Hair Cuttery", "shortDescription": "We cut
                    hair!", "address": { "street": "123 Street St", "city":
                    "Orlando", "state": "FL", "postal": "12345" }, "timezone":
                    "America/New_York" } }
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- CREATE STRIPE PORTAL -->
      <div class="section">
        <h3>2) Create the Stripe checkout session</h3>
        <p class="description">
          We use Stripe to handle subscriptions, so we need to create a Stripe
          checkout session to initiate the Stripe process
        </p>

        <div class="endpoint_head">
          <div class="route">
            <p class="tag tag-blue">POST</p>
            <p class="url">
              {{ apiUrl }}<span>/subscriptions/checkoutSession</span>
            </p>
          </div>

          <div class="description">
            <p>Creates a Stripe checkout session</p>
          </div>
        </div>

        <div class="endpoint_body">
          <div class="request">
            <h3>Request</h3>

            <!-- Headers -->
            <div class="endpoint_body_section path">
              <div class="head">
                <h4>Headers</h4>
              </div>

              <ul>
                <li>
                  <div class="col value">
                    <p class="bold">Authorization</p>
                    <p class="examples">
                      Examples: <span>Bearer {Token}</span>
                    </p>
                  </div>

                  <div class="col">
                    <p class="required">required</p>
                  </div>
                </li>
              </ul>
            </div>

            <!-- Body -->
            <div class="endpoint_body_section path">
              <div class="head">
                <h4>Body</h4>
                <p>application/json</p>
              </div>

              <ul>
                <!-- Stripe Seat Price ID -->
                <li>
                  <div class="col value">
                    <p class="bold">priceId</p>
                    <p class="description">You must use this value</p>
                    <p class="examples">
                      Examples:
                      <span>{{ priceId }}</span>
                    </p>
                  </div>

                  <div class="col">
                    <p class="required">required</p>
                  </div>
                </li>

                <!-- Quantity (Seats) -->
                <li>
                  <div class="col value">
                    <p class="bold">quantity</p>
                    <p class="description">
                      Number of team members in salon (1 through 5, if more than
                      5 team members just enter 5)
                    </p>
                    <p class="examples">
                      Examples:
                      <span>1</span>
                    </p>
                  </div>

                  <div class="col">
                    <p class="required">required</p>
                  </div>
                </li>

                <!-- Customer email -->
                <li>
                  <div class="col value">
                    <p class="bold">customer_email</p>
                    <p class="description">
                      Email of staff account creating the salon
                    </p>
                    <p class="examples">
                      Examples:
                      <span>staff@email.com</span>
                    </p>
                  </div>

                  <div class="col">
                    <p class="required">required</p>
                  </div>
                </li>

                <!-- Salon ID -->
                <li>
                  <div class="col value">
                    <p class="bold">salonId</p>
                    <p class="description">ID of the newly created salon</p>
                    <p class="examples">
                      Examples:
                      <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6f</span>
                    </p>
                  </div>

                  <div class="col">
                    <p class="required">required</p>
                  </div>
                </li>

                <!-- Salon details -->
                <li>
                  <div class="col value">
                    <p class="bold">salonDetails</p>
                    <p class="description">
                      Object - Details of the salon being created (Same
                      information as the create salon endpoint, used to prefill
                      the information on redirect back in case checkout error -
                      Will be returned in the return URL (success & cancel) as
                      queries like such:
                      `&shopName=${shopName}&shortDescription=${shortDescription}&street=${street}&city=${city}&state=${state}&postal=${postal}&timezone=${timezone}`)
                    </p>
                    <p class="examples">
                      Examples:
                      <span
                        >{ "shopName": "Hair Cuttery", "shortDescription": "We
                        cut hair!", "address": { "street": "123 Street St",
                        "city": "Orlando", "state": "FL", "postal": "12345" },
                        "timezone": "America/New_York" }</span
                      >
                    </p>
                  </div>

                  <div class="col">
                    <p class="required">required</p>
                  </div>
                </li>

                <!-- Trial -->
                <li>
                  <div class="col value">
                    <p class="bold">trial</p>
                    <p class="description">
                      If set to "true" salon will have a 1 month free trial
                    </p>
                    <p class="examples">
                      Examples:
                      <span>true</span>
                    </p>
                  </div>

                  <div class="col">
                    <!-- <p class="required">required</p> -->
                  </div>
                </li>

                <!-- Success URL -->
                <li>
                  <div class="col value">
                    <p class="bold">success_url</p>
                    <p class="description">
                      Url to redirect upon success where {CHECKOUT_SESSION_ID}
                      is the ID of the checkout session
                    </p>
                    <p class="examples">
                      Examples:
                      <span
                        >https://www.raserva.com/dashboard/salon/register?session_id={CHECKOUT_SESSION_ID}</span
                      >
                    </p>
                  </div>

                  <div class="col">
                    <p class="required">required</p>
                  </div>
                </li>

                <!-- Cancel URL -->
                <li>
                  <div class="col value">
                    <p class="bold">cancel_url</p>
                    <p class="description">Url to redirect upon cancel</p>
                    <p class="examples">
                      Examples:
                      <span
                        >https://www.raserva.com/dashboard/salon/register?stripe_error=Subscription+error</span
                      >
                    </p>
                  </div>

                  <div class="col">
                    <p class="required">required</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="response">
            <h3>Response</h3>

            <!-- Body -->
            <div class="endpoint_body_section path">
              <div class="head">
                <h4>Body</h4>
              </div>

              <ul>
                <li>
                  <div class="col value">
                    <p class="bold">session</p>
                    <p class="examples">
                      Examples:
                      <span
                        >{ "id":
                        "cs_test_b1UVhyVNSYmqsEpyWTm4Dljq3vU9CnubmrdEQjYFdxWLkJdmUrmfyY3Iwl",
                        "object": "checkout.session", "after_expiration": null,
                        "allow_promotion_codes": true, "amount_subtotal": 1999,
                        "amount_total": 1999, "automatic_tax": { "enabled":
                        false, "status": null }, "billing_address_collection":
                        "auto", "cancel_url":
                        "http://localhost:3000/dashboard/salon/register?stripe_error=Subscription+error",
                        "client_reference_id": "649f318d56267551d2d6375s",
                        "consent": null, "consent_collection": null, "created":
                        1688156523, "currency": "usd", "currency_conversion":
                        null, "custom_fields": [], "custom_text": {
                        "shipping_address": null, "submit": null }, "customer":
                        null, "customer_creation": "always", "customer_details":
                        { "address": null, "email": "john@doe.com", "name":
                        null, "phone": null, "tax_exempt": "none", "tax_ids":
                        null }, "customer_email": "john@doe.com", "expires_at":
                        1688242923, "invoice": null, "invoice_creation": null,
                        "livemode": false, "locale": null, "metadata": {},
                        "mode": "subscription", "payment_intent": null,
                        "payment_link": null, "payment_method_collection":
                        "always", "payment_method_options": null,
                        "payment_method_types": [ "card" ], "payment_status":
                        "unpaid", "phone_number_collection": { "enabled": false
                        }, "recovered_from": null, "setup_intent": null,
                        "shipping": null, "shipping_address_collection": null,
                        "shipping_options": [], "shipping_rate": null, "status":
                        "open", "submit_type": null, "subscription": null,
                        "success_url":
                        "http://localhost:3000/dashboard/salon/register?session_id={CHECKOUT_SESSION_ID}",
                        "total_details": { "amount_discount": 0,
                        "amount_shipping": 0, "amount_tax": 0 }, "url": "" }
                      </span>
                    </p>
                  </div>

                  <div class="col">
                    <p class="required">required</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="reqResExamples">
            <h3>Examples</h3>

            <!-- Request -->
            <div class="endpoint_body_section path">
              <div class="head">
                <h4>Request</h4>
                <p>application/json</p>
              </div>

              <ul>
                <li>
                  <p>
                    { "priceId": "price_1KQ02FDdQDiL7UkfSp9G86mp", "quantity":
                    1, "customer_email": "john@doe.com", "salonId":
                    "649f318d56267551d2d6375f", "salonDetails": { "shopName":
                    "Hair Cuttery", "shortDescription": "We cut hair!",
                    "address": { "street": "123 Street St", "city": "Orlando",
                    "state": "FL", "postal": "12345" }, "timezone":
                    "America/New_York" }, "success_url":
                    "http://localhost:3000/dashboard/salon/register?session_id={CHECKOUT_SESSION_ID}",
                    "cancel_url":
                    "http://localhost:3000/dashboard/salon/register?stripe_error=Subscription+error"
                    }
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- REDIRECT -->
      <div class="section">
        <h3>3) Redirect to the session URL</h3>
        <p class="description">
          The endpoint above in step 2 will return a "session" object, here you
          can use the "session.url" to redirect the user to the checkout page
          (I.e. window.location.href = session.url). The user will then be able
          to enter their payment information and complete the checkout process.
        </p>
      </div>

      <!-- Final -->
      <div class="section">
        <h3>4) Final steps</h3>
        <p class="description">
          If the checkout session is completed, the user will be redirected to
          your "success_url" from step 2. Here you can give it a little bit of
          time for the Stripe webhook to be sent to us. Once we receive the
          webhook, we will then set the "billing.currentPeriodEnd" field to the
          end of their current billing period date. Once this field is set, you
          can re-grab the salon via a GET request to /salons/:salonId and check
          the "billing.currentPeriodEnd" to determine whether or not to allow
          the salon to access their dashboard.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    apiUrl() {
      return process.env.VUE_APP_RASERVA_BACKEND;
    },
    priceId() {
      return process.env.VUE_APP_STRIPESEAT_PRICEID;
    },
  },
};
</script>

<style lang="scss" scoped>
.create {
  &_head {
    h2 {
      font-size: 36px;
    }
    .description {
      margin-top: 8px;
      color: var(--clr-gray);
    }
  }

  &_body {
    margin-top: 64px;
    .description {
      margin-top: 8px;
      color: var(--clr-gray);
    }
  }

  .section {
    &:not(:first-child) {
      margin-top: 64px;
    }
  }
}

.tag {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 8px;
  border-radius: 5px;
  color: white;

  &-blue {
    background-color: var(--clr-secondary);
  }
}
.endpoint {
  &_head {
    margin-top: 32px;

    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }

    .route {
      padding: 8px 16px;
      border-radius: 5px;
      background-color: var(--clr-white-2);
      display: flex;
      align-items: center;
      gap: 16px;

      .url {
        color: var(--clr-gray);

        span {
          color: var(--clr-primary);
          font-weight: 700;
        }
      }
    }

    .description {
      margin-top: 32px;
    }
  }

  &_body {
    .request,
    .response,
    .reqResExamples {
      margin-top: 32px;
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &_section {
      &:not(:first-child) {
        margin-top: 32px;
      }

      .head {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 20px;
          margin-bottom: 16px;
          color: var(--clr-secondary);
        }

        p {
          font-size: 14px;
          color: var(--clr-gray);
        }
      }

      ul {
        .subitem {
          margin-left: 32px;
        }

        li {
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          &:not(:first-child) {
            margin-top: 16px;
          }

          .value {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .bold {
            font-weight: 700;
          }
          .required {
            color: orangered;
          }

          .description {
            margin: 4px 0;
            color: var(--clr-gray);
          }

          .examples {
            span {
              background-color: var(--clr-white-2);
              padding: 4px 8px;
              border-radius: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
