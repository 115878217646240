<template>
  <section class="vuevents" ref="container">
    <div class="vuevents_times">
      <div class="vuevents_times_head"></div>
      <div
        v-for="i1 in 24"
        :key="i1"
        class="vuevents_times_time"
        :style="{ height: 100 * scale + 'px' }"
      >
        <p class="time">{{ formatIndexToTime(i1 - 1) }}</p>
        <p class="meridian">{{ formatIndexToMeridian(i1 - 1) }}</p>
      </div>
    </div>
    <div class="vuevents_columns">
      <div
        class="vuevents_columns_nowIndicator"
        ref="nowIndicator"
        :style="{ top: nowIndicatorTopPosition }"
      >
        <p>{{ currentTime }}</p>
      </div>
      <div
        v-for="(day, dateIndex) in calendarDates"
        :key="dateIndex"
        class="vuevents_columns_column"
      >
        <div
          class="staff"
          v-for="(staff, staffIndex) in day.staff"
          :key="staffIndex"
          :title="staff.title"
        >
          <div class="vuevents_columns_column_head">
            <div
              class="scale"
              :class="{ isCapable: staff.canDoSelectedService }"
            >
              <!-- <p
                v-if="staff.group && !staff.canDoSelectedService"
                class="group"
                :title="staff.group"
              >
                {{ staff.group.slice(0, 5) }}
              </p> -->
              <p v-if="staff.additionalTurns !== 0" class="group">
                Additional Turns
              </p>

              <div class="avatar">
                <Avatar
                  @click="$emit('selectStaff', staff)"
                  mode="large rounded"
                  :name="staff.firstName"
                  :image="staff.profileImage"
                  :showName="calendarDates.length === 1"
                  :extraText="
                    staff.turns || staff.additionalTurns
                      ? `(${(staff.turns || 0) + (staff.additionalTurns || 0)})`
                      : null
                  "
                />

                <div
                  class="available"
                  :class="{
                    'avail-n': !staff.available,
                    'avail-y': staff.available,
                  }"
                ></div>
                <div v-if="staff.working" class="working">
                  <i class="far fa-clock"></i>
                </div>
              </div>
              <p class="week-dates" v-if="calendarDates.length !== 1">
                {{ formatDateForHead(day.date) }}
              </p>
              <p class="title" v-else-if="staff.title">
                {{ staff.title.substring(0, 7)
                }}<span v-if="staff.title.length > 7">..</span>
              </p>
              <p v-if="staff.next" class="next">(Next)</p>
            </div>
          </div>
          <div
            class="vuevents_columns_column_timeslots"
            :class="{ deleted: staff.deleted }"
          >
            <div
              v-for="i4 in 24"
              :key="i4"
              class="vuevents_columns_column_timeslots_timeslot"
              :style="{ height: 100 * scale + 'px' }"
            >
              <div
                v-for="i5 in 4"
                :key="i5"
                class="vuevents_columns_column_timeslots_timeslot_sub-timeslot"
                @click="
                  canBookAppointment
                    ? newAppointmentFromTimeslot(
                        dateIndex,
                        staffIndex,
                        i4 - 1,
                        i5 - 1
                      )
                    : null
                "
                @drop="appDrop($event, dateIndex, staffIndex, i4 - 1, i5 - 1)"
                @dragenter.prevent
                @dragover.prevent
                :style="{ height: 25 * scale + 'px' }"
              >
                <div class="time">
                  {{ formatIndexesToTime(i4 - 1, i5 - 1) }}
                </div>
              </div>
            </div>

            <div class="vuevents_columns_column_timeslots_offtimes">
              <div
                v-for="offtime in staff.blocks"
                :key="offtime"
                class="vuevents_offtime"
                :style="{
                  top: offtime.top,
                  height: offtime.height,
                }"
              ></div>
            </div>

            <div class="vuevents_columns_column_timeslots_events">
              <div
                v-for="event in staff.events"
                :key="event.id"
                :id="event.id"
                @click="selectAppointment(event)"
                @mouseenter="$emit('hoverApp', event)"
                @mouseleave="$emit('unhoverApp')"
                class="vuevents_event"
                draggable="true"
                @dragstart="appDragStart($event, event)"
                :style="{
                  top: event.top,
                  left: event.left,
                  width: event.width,
                  height: event.height,
                  backgroundColor: event.service.service.color,
                }"
              >
                <div class="vuevents_event_head">
                  <p class="times">{{ event.start }} - {{ event.end }}</p>
                  <p class="title">{{ event.title }}</p>
                  <div class="icons">
                    <i
                      class="fas fa-cloud"
                      v-if="event.appointment.bookedOnline"
                    ></i>
                    <i
                      class="fas fa-badge-dollar"
                      v-if="event.appointment.billing.deposits.length"
                    ></i>
                    <i
                      class="fas fa-star"
                      v-if="event.appointment.status === 'new'"
                    ></i>
                    <i
                      class="fas fa-check"
                      v-if="event.appointment.status === 'confirmed'"
                    ></i>
                    <i
                      class="fas fa-check-double"
                      v-if="event.appointment.status === 'arrived'"
                    ></i>
                    <i
                      class="fas fa-eye-slash"
                      v-if="event.appointment.status === 'no show'"
                    ></i>
                    <i
                      v-if="event.appointment.status === 'cancelled'"
                      class="fas fa-ban color-red"
                    ></i>
                    <i
                      class="fas fa-ban text-red"
                      v-if="isClientBlacklisted(event.appointment.client)"
                    ></i>
                    <i
                      class="fas fa-comment-slash color-red"
                      v-if="event.appointment.requestedCancellation"
                    ></i>
                    <i v-if="event.service.requested" class="fas fa-heart"></i>
                    <i
                      v-if="event.appointment.repeat.repeatId"
                      class="fas fa-sync-alt"
                    ></i>
                    <i
                      v-if="
                        event.appointment.note || event.appointment.client?.note
                      "
                      class="fas fa-comment-dots"
                    ></i>
                    <i
                      v-if="event.appointment.files?.photos?.length"
                      class="fas fa-images"
                    ></i>
                    <i
                      v-if="event.appointment.ticket?.status === 'completed'"
                      class="fas fa-check-square text-green"
                    ></i>
                  </div>
                </div>
                <div class="vuevents_event_body">
                  <p v-if="!event.appointment.isBlockedTime">
                    <span>{{ event.service.service.title }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  emits: [
    'selectAppointment',
    'selectTimeslot',
    'selectStaff',
    'hoverApp',
    'unhoverApp',
  ],
  props: {
    propDateStart: {
      type: Date,
      required: true,
    },
    propDateEnd: {
      type: Date,
      required: true,
    },
    staffs: {
      type: Array,
      required: true,
    },
    staffListOverride: {
      type: Array,
    },
    staffFilter: {
      type: String,
      required: true,
    },
    filterWithEvents: {
      type: Boolean,
      default: false,
    },
    filterCancellationRequests: {
      type: Boolean,
      default: false,
    },
    filterIsScheduled: {
      type: Boolean,
      default: false,
    },
    // appointments: {
    //   type: Array,
    //   default: function () {
    //     return [];
    //   },
    // },
    filter: {
      type: String,
      required: true,
    },
    sort: {
      type: String,
      required: true,
    },
    canBookAppointment: {
      type: Boolean,
      default: false,
    },
    scale: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      dateStart: null,
      dateEnd: null,
      calendarDates: [],

      minutePixelHeight: 1.6666,
      currentTime: this.$moment().format('LT'),
      nowIndicatorTopPosition:
        this.$moment().diff(this.$moment().startOf('day'), 'minutes') *
          (this.minutePixelHeight * this.scale) +
        133 +
        'px',
      handle: setInterval(() => {
        this.nowIndicatorTopPosition =
          this.$moment().diff(this.$moment().startOf('day'), 'minutes') *
            (this.minutePixelHeight * this.scale) +
          133 +
          'px';

        this.currentTime = this.$moment().format('LT');
      }, 1000),
    };
  },
  beforeUnmount() {
    clearInterval(this.handle);
  },
  created() {
    this.dateStart = this.propDateStart;
    this.dateEnd = this.propDateEnd;

    if (this.staffs.length) {
      this.performUpdates();
    } else {
      setTimeout(() => this.performUpdates(), 500);
    }
  },
  mounted() {
    // Kinda hacky but it works if load is fast enough
    setTimeout(() => {
      this.$refs.nowIndicator.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }, 1000);
  },
  watch: {
    // staffs() {
    //   this.performUpdates();
    // },
    // appointments() {
    //   this.performUpdates();
    // },
    ticketChangeCount() {
      this.performUpdates();
    },
    appointments() {
      this.performUpdates();
    },
    filterWithEvents() {
      this.performUpdates();
    },
    filterIsScheduled() {
      this.performUpdates();
    },
    filterCancellationRequests() {
      this.performUpdates();
    },
    scale() {
      this.updateEvents(false, true);
    },
  },
  computed: {
    appointments() {
      return this.$store.state.appointments.appointments;
    },
    getUniqueDaysBetweenDates() {
      return (
        this.$moment(this.dateEnd).diff(this.$moment(this.dateStart), 'days') +
        1
      );
    },
    // nowIndicatorTopPosition() {
    //   return (
    //     this.$moment().diff(this.$moment().startOf('day'), 'minutes') *
    //       (this.minutePixelHeight * this.scale) +
    //     133 +
    //     'px'
    //   );
    // },
    tickets() {
      return this.$store.state.tickets.tickets;
    },
    ticketChangeCount() {
      return this.$store.state.tickets.changeCount;
    },
  },
  methods: {
    isClientBlacklisted(client) {
      if (!client) return false;
      if (!client.blacklistedSalons) return false;

      return client.blacklistedSalons.includes(
        this.$store.state.auth.salon._id.toString()
      );
    },

    async selectAppointment(event) {
      const domEvent = document.getElementById(event.id);

      if (!domEvent) return;

      const roundedEventHeight = Math.round(+event.height.split('px')[0]);

      if (domEvent.offsetHeight !== roundedEventHeight) {
        const appointment = this.appointments.find(
          (appointment) => appointment._id === event.appointment._id
        );

        if (!appointment) return;

        const newAppointment = JSON.parse(JSON.stringify(appointment));

        const serviceIndex = newAppointment.services.findIndex(
          (service) => service._id === event.service._id
        );

        if (serviceIndex === -1) return;

        newAppointment.services[serviceIndex].duration =
          Math.round(
            domEvent.offsetHeight / (this.minutePixelHeight * this.scale) / 5
          ) * 5;

        try {
          await this.$store.dispatch(
            'appointments/editAppointment',
            newAppointment
          );

          this.updateEvents();
          this.$toast.success('Duration updated');
        } catch (error) {
          this.$toast.error(error.message);
        }
      } else {
        this.$emit('selectAppointment', event.appointment._id);
      }
    },
    performUpdates() {
      this.staffs.forEach((staff) => (staff.turns = 0));

      this.tieAppointmentToTicket();

      this.updateEvents();
    },
    tieAppointmentToTicket() {
      this.appointments.forEach((appointment) => {
        const ticket = this.tickets.find((ticket) => {
          return ticket.appointmentId === appointment._id;
        });

        if (ticket) {
          appointment.ticket = ticket;
        }
      });
    },
    setCalendarDates() {
      this.resetCalendarDates();

      for (let i = 0; i < this.getUniqueDaysBetweenDates; i++) {
        this.calendarDates.push({
          date: this.$moment(this.dateStart).add(i, 'days'),
          staff: JSON.parse(JSON.stringify(this.staffs)),
        });
      }
    },
    sortStaff() {
      this.calendarDates.forEach((date) => {
        // Sort by clock in time first if not custom
        if (this.sort !== 'Custom') {
          date.staff = date.staff.sort((a, b) => {
            return this.$moment(b.clockedInAt || this.$moment()).isBefore(
              a.clockedInAt || this.$moment()
            )
              ? 1
              : -1;
          });
        }

        if (this.sort === 'Groups') {
          // Sort by groups
          date.staff = date.staff.sort((a, b) => {
            if (a.group < b.group) {
              return -1;
            }
            if (a.group > b.group) {
              return 1;
            }
            return 0;
          });
        } else if (this.sort === 'Appointments') {
          date.staff = date.staff.sort((a, b) => {
            return a.events.length - b.events.length;
          });
        } else if (this.sort === 'Turns') {
          this.tickets.forEach((ticket) => {
            ticket.items.forEach((item) => {
              if (item.staff) {
                const index = date.staff.findIndex(
                  (staff) => staff.staffId === item.staff.staffId
                );

                if (index !== -1) {
                  if (!date.staff[index].turns) {
                    date.staff[index].turns = 0;
                  }

                  date.staff[index].turns += item.item.turns || 0;
                }
              }
            });
          });

          date.staff = date.staff.sort((a, b) => {
            return (
              (a.turns || 0) +
              (a.additionalTurns || 0) -
              ((b.turns || 0) + (b.additionalTurns || 0))
            );
          });
        }
      });
    },
    resetCalendarDates() {
      this.calendarDates = [];
    },
    formatIndexToTime(index) {
      return this.$moment(this.propDateStart)
        .startOf('day')
        .add(index, 'hours')
        .format('h:mm');
    },
    formatIndexesToTime(index, index2) {
      return this.$moment(this.dateStart)
        .startOf('day')
        .add(index, 'hours')
        .add(index2 * 15, 'minutes')
        .format('h:mm');
    },
    formatIndexToMeridian(index) {
      return this.$moment().startOf('day').add(index, 'hours').format('A');
    },
    formatDateForHead(date) {
      return this.$moment(date).format('D dddd');
    },
    newAppointmentFromTimeslot(dateIndex, staffIndex, hourIndex, minuteIndex) {
      if (this.calendarDates[dateIndex].staff[staffIndex].deleted) return;

      const date = this.calendarDates[dateIndex].date;
      const staff = this.calendarDates[dateIndex].staff[staffIndex];

      /////////////////////////////////////////////////////////////////////////
      //////////////// BEFORE DAY LIGHT SAVING TIME FIX ///////////////////////
      /////////////////////////////////////////////////////////////////////////

      // const hour = `${hourIndex < 10 ? `0${hourIndex}` : hourIndex}`;
      // const minute = `${
      //   minuteIndex < 1 ? `0${minuteIndex * 15}` : minuteIndex * 15
      // }`;

      // const time = `${hour}:${minute}`;

      // this.$emit('selectTimeslot', {
      //   date,
      //   staff,
      //   time,
      // });

      ////////////////////////////////////////////////////////////////////////
      //////////////// AFTER DAY LIGHT SAVING TIME FIX ///////////////////////
      ////////////////////////////////////////////////////////////////////////
      this.$emit('selectTimeslot', {
        date,
        staff,
        time: this.$moment(this.dateStart)
          .startOf('day')
          .add(hourIndex, 'hours')
          .add(minuteIndex * 15, 'minutes')
          .format('kk:mm'),
      });
    },
    async setCurrentSchedules() {
      // Override List
      if (this.staffListOverride && this.staffListOverride.length) {
        for (let staff of this.staffListOverride) {
          staff.currentSchedule = await this.$store.dispatch(
            'staff/getStaffCurrentSchedule',
            {
              staff: JSON.parse(JSON.stringify(staff)),
              startDate: this.dateStart,
              endDate: this.dateEnd,
            }
          );

          this.setStaffBlocks(staff);
        }

        return;
      }

      // Default
      for (let staff of this.staffs) {
        staff.currentSchedule = await this.$store.dispatch(
          'staff/getStaffCurrentSchedule',
          {
            staff: JSON.parse(JSON.stringify(staff)),
            startDate: this.dateStart,
            endDate: this.dateEnd,
          }
        );

        this.setStaffBlocks(staff);
      }
    },
    setStaffBlocks(staff) {
      staff.blocks = [];

      this.calendarDates.forEach((date) => {
        const calendarStaff = date.staff.find(
          (prestaff) =>
            prestaff._id === staff._id || prestaff.staffId === staff.staffId
        );

        if (!calendarStaff) return;
        if (!calendarStaff.blocks) calendarStaff.blocks = [];

        const currentDay =
          staff.currentSchedule[this.$moment(date.date.toDate()).day()];

        if (!currentDay.blocks.length) {
          calendarStaff.blocks.push({
            start: '00:00',
            end: '23:59',
          });
        } else {
          calendarStaff.blocks.length = 0;

          for (let i = 0; i < currentDay.blocks.length; i++) {
            if (i === 0) {
              calendarStaff.blocks.push({
                start: '00:00',
                end: currentDay.blocks[i].start,
              });
            }
            if (i !== currentDay.blocks.length - 1) {
              calendarStaff.blocks.push({
                start: currentDay.blocks[i].end,
                end: currentDay.blocks[i + 1].start,
              });
            }
            if (i === currentDay.blocks.length - 1) {
              calendarStaff.blocks.push({
                start: currentDay.blocks[i].end,
                end: '23:59',
              });
            }
          }
        }

        calendarStaff.blocks.forEach((block) => {
          this.setStylingOnBlock(block);
        });
      });
    },
    setStylingOnBlock(block) {
      const blockStart = this.$moment()
        .hour(block.start.split(':')[0])
        .minute(block.start.split(':')[1]);
      const blockEnd = this.$moment()
        .hour(block.end.split(':')[0])
        .minute(block.end.split(':')[1]);

      // Height
      const height = this.$moment(blockEnd).diff(blockStart, 'minutes');

      // Top
      const top = this.$moment(blockStart).diff(
        this.$moment(blockStart).startOf('day'),
        'minutes'
      );

      block.top = `${top * (this.minutePixelHeight * this.scale)}px`;
      block.height = `${height * (this.minutePixelHeight * this.scale)}px`;
    },
    setLeftAndWidthsOnEvents() {
      this.calendarDates.forEach((date) => {
        date.staff.forEach((staff) => {
          const sortedEvents = this.sortStaffEvents(staff);

          const groups = this.getStaffEventGroups(sortedEvents);

          groups.forEach((group) => {
            group.forEach((event, index) => {
              event.width = 100 / group.length + '%';
              event.left = (100 / group.length) * index + '%';

              this.findActualEventAndReplace(event);
            });
          });
        });
      });
    },
    findActualEventAndReplace(event) {
      this.calendarDates.forEach((date) => {
        date.staff.forEach((staff) => {
          staff.events.forEach((realEvent) => {
            if (event.id === realEvent.id) {
              realEvent.left = event.left;
              realEvent.width = event.width;
            }
          });
        });
      });
    },
    sortStaffEvents(staff) {
      return staff.events.sort((a, b) => {
        const aStart = this.$moment()
          .hour(a.service.start.split(':')[0])
          .minute(a.service.start.split(':')[1])
          .toDate();
        const bStart = this.$moment()
          .hour(b.service.start.split(':')[0])
          .minute(b.service.start.split(':')[1])
          .toDate();

        return aStart - bStart;
      });
    },
    getStaffEventGroups(sortedEvents) {
      const groups = [];
      const group = [];

      sortedEvents.forEach((event, index) => {
        if (index === 0) {
          group.push(event);

          if (!sortedEvents[index + 1]) {
            groups.push(JSON.parse(JSON.stringify(group)));
            group.length = 0;
          }

          return;
        }

        const [hour, minute] = sortedEvents[index].service.start.split(':');
        const eventStart = this.$moment().hour(hour).minute(minute);
        const eventEnd = this.$moment(eventStart).add(
          event.service.duration,
          'minutes'
        );

        const [prevHour, prevMinute] =
          sortedEvents[index - 1].service.start.split(':');
        const prevEventStart = this.$moment().hour(prevHour).minute(prevMinute);
        const prevEventEnd = prevEventStart.add(
          sortedEvents[index - 1].service.duration,
          'minutes'
        );

        if (!sortedEvents[index + 1]) {
          if (sortedEvents[index - 1]) {
            if (prevEventEnd.isAfter(eventStart)) {
              group.push(event);
            }
          }

          groups.push(JSON.parse(JSON.stringify(group)));
          group.length = 0;
          return;
        }

        const [nextHour, nextMinute] =
          sortedEvents[index + 1].service.start.split(':');
        const nextEventStart = this.$moment().hour(nextHour).minute(nextMinute);

        if (eventEnd.isAfter(nextEventStart)) {
          if (index !== 0) group.push(event);
        } else if (prevEventEnd.isAfter(eventStart)) {
          group.push(event);
        } else {
          groups.push(JSON.parse(JSON.stringify(group)));
          group.length = 0;
        }
      });

      return groups;
    },

    // D&D
    appDragStart(event, appointment) {
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('id', appointment.appointment._id);
      event.dataTransfer.setData('itemId', appointment.service._id);
    },
    async appDrop(event, dateIndex, staffIndex, hourIndex, minuteIndex) {
      const date = this.calendarDates[dateIndex].date;
      const staff = this.calendarDates[dateIndex].staff[staffIndex];

      const hour = `${hourIndex < 10 ? `0${hourIndex}` : hourIndex}`;
      const minute = `${
        minuteIndex < 1 ? `0${minuteIndex * 15}` : minuteIndex * 15
      }`;

      const time = `${hour}:${minute}`;

      const appointment = this.appointments.find(
        (appointment) => appointment._id === event.dataTransfer.getData('id')
      );

      if (!appointment) return;

      const newAppointment = JSON.parse(JSON.stringify(appointment));

      const oldDate = this.$moment(appointment.date);
      newAppointment.date = this.$moment(date)
        .minute(oldDate.minute())
        .hour(oldDate.hour());

      const serviceIndex = newAppointment.services.findIndex(
        (service) => service._id === event.dataTransfer.getData('itemId')
      );

      if (serviceIndex === -1) return;

      newAppointment.services[serviceIndex].staff = staff;
      newAppointment.services[serviceIndex].start = time;

      try {
        await this.$store.dispatch(
          'appointments/editAppointment',
          newAppointment
        );

        this.updateEvents();
        this.$toast.success('Appointment updated');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async updateEvents(getAppointments, scrollToNow) {
      if (getAppointments) {
        await this.$store.dispatch('appointments/getAppointmentsBetweenDates', {
          start: this.$moment(
            this.$moment(this.propDateStart).startOf('day')
          ).tz(this.$store.state.auth.salon.details.timezone, true),
          end: this.$moment(this.$moment(this.propDateEnd).endOf('day')).tz(
            this.$store.state.auth.salon.details.timezone,
            true
          ),
          salonId: this.$store.state.auth.salon._id,
        });
      }

      this.setCalendarDates();
      this.setCurrentSchedules();

      this.calendarDates.forEach((date) => {
        date.staff.forEach((staff) => (staff.events = []));
      });

      this.appointments.forEach((appointment) => {
        if (
          this.filter !== 'All' &&
          appointment.status !== this.filter.toLowerCase()
        )
          return;

        if (
          this.filterCancellationRequests &&
          appointment.requestedCancellation
        )
          return;

        appointment.services.forEach((service) => {
          if (!service.service) return;

          const serviceStart = this.$moment(appointment.date)
            .hour(service.start.split(':')[0])
            .minute(service.start.split(':')[1]);

          ///////////////////////////
          // Styling (Positioning) //
          ///////////////////////////

          // Top
          const topDuration = this.$moment.duration(
            this.$moment(serviceStart).diff(
              this.$moment(serviceStart).startOf('day')
            )
          );
          const top = topDuration.asMinutes();
          // Height
          const heightDuration = this.$moment.duration(
            this.$moment(
              this.$moment(serviceStart).add(
                service.duration + service.service.extraBlockedTime,
                'minutes'
              )
            ).diff(this.$moment(serviceStart))
          );
          const height = heightDuration.asMinutes();

          const mockId = Math.floor(
            Math.random() * Math.floor(Math.random() * Date.now())
          );

          const event = {
            id: mockId,
            start: this.$moment(serviceStart).format('h:mm'),
            end: this.$moment(serviceStart)
              .add(
                service.duration + service.service.extraBlockedTime,
                'minutes'
              )
              .format('h:mm'),
            service,
            appointment,
            title: appointment.isBlockedTime
              ? 'Blocked Time'
              : appointment.title
              ? appointment.title
              : appointment.client
              ? `${appointment.client.firstName} ${
                  appointment.client.lastName || ''
                }`
              : 'Walk-In',
            top: `${top * (this.minutePixelHeight * this.scale)}px`,
            height: `${height * (this.minutePixelHeight * this.scale)}px`,
            left: '0',
            width: '100%',
          };

          const dateIndex = this.calendarDates.findIndex((date) => {
            return this.$moment(
              this.$moment.tz(
                date.date,
                this.$store.state.auth.salon.details.timezone
              )
            ).isSame(appointment.date, 'day');
          });

          if (dateIndex === -1) return;

          const date = this.calendarDates[dateIndex];

          if (!service.staff) return;

          let staffIndex = date.staff.findIndex(
            (staff) =>
              staff._id === service.staff._id ||
              staff.staffId === service.staff.staffId
          );

          if (staffIndex === -1) {
            if (this.staffFilter !== 'All Staff') return;

            if (this.staffListOverride && !this.staffListOverride.length) {
              // Deleted member/Not found member (Add to date)
              date.staff.push({ ...service.staff, deleted: true });
              staffIndex = date.staff.length - 1;
            }
          }

          const staff = date.staff[staffIndex];

          if (!staff.events) {
            staff.events = [];
          }

          staff.events.push(event);
        });
      });

      // Count test
      // console.log(this.appointments.length);
      // let events = [];
      // this.calendarDates.forEach((d) => {
      //   d.staff.forEach((s) => {
      //     s.events.forEach((e) => {
      //       if (!events.find((ev) => ev.appointment._id === e.appointment._id))
      //         events.push(e);
      //     });
      //   });
      // });
      // console.log(events.length);

      this.setLeftAndWidthsOnEvents();
      this.sortStaff();
      this.filterStaff();
      if (this.sort === 'Turns') this.setNext();

      if (scrollToNow) {
        setTimeout(() => {
          this.$refs.nowIndicator.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 0);
      }
    },

    filterStaff() {
      if (this.filterWithEvents) {
        this.calendarDates.forEach((date) => {
          date.staff = date.staff.filter((staff) => {
            return staff.events.length;
          });
        });
      }
      if (this.filterIsScheduled) {
        this.calendarDates.forEach((date) => {
          date.staff = date.staff.filter((staff) => {
            return staff.blocks.length;
          });
        });
      }
    },

    setNext() {
      // Reset
      this.calendarDates.forEach((date) => {
        for (let i = 0; i < date.staff.length; i++) {
          date.staff[i].next = false;
        }
      });

      this.calendarDates.forEach((date) => {
        for (let i = 0; i < date.staff.length; i++) {
          const staff = date.staff[i];

          if (staff.available && staff.working) {
            staff.next = true;
            break;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.vuevents {
  --clr-blue-light: #eaf3f8;
  --clr-light: #ecf0f3;
  --clr-light-2: #d3d6da;
  --clr-danger: rgb(241, 62, 62);

  width: 100%;
  display: flex;

  .text-green {
    color: var(--clr-success);
  }

  &_times {
    &_head {
      height: 132px;
      position: sticky;
      top: 0;
      background-color: white;
    }
    &_time {
      height: 100px;
      padding: 0 16px;
      text-align: right;

      .time {
        font-size: 14px;
        font-weight: 700;
      }
      .meridian {
        margin-top: -5px;
        font-size: 12px;
      }
    }
  }

  &_columns {
    display: flex;
    width: 100%;
    position: relative;

    &_nowIndicator {
      z-index: 3;
      position: absolute;
      height: 2px;
      width: 100%;
      background-color: var(--clr-secondary);
      // top: 133px;
      left: 0;

      p {
        padding: 4px 8px;
        font-size: 12px;
        display: inline-block;
        color: white;
        background-color: var(--clr-secondary);
      }
    }

    &_column {
      flex-grow: 1;
      display: flex;

      .scale {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .isCapable {
        animation: scale 1s infinite;
      }

      @keyframes scale {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.1);
        }
        100% {
          transform: scale(1);
        }
      }

      .avatar {
        position: relative;

        .available {
          position: absolute;
          bottom: 25px;
          border: 1px solid white;
          right: 0;
          height: 15px;
          width: 15px;
          border-radius: 50%;
        }
        .working {
          position: absolute;
          bottom: 15px;
          border: 1px solid white;
          left: 0;
          height: 25px;
          width: 25px;
          border-radius: 50%;
          background-color: var(--clr-secondary);
          font-size: 12px;
          color: white;
          display: grid;
          place-items: center;
        }
        .avail-y {
          background-color: var(--clr-success);
        }
        .avail-n {
          background-color: var(--clr-danger);
        }
      }

      .group {
        position: absolute;
        z-index: 100;
        // top: 5px;
        right: 50%;
        transform: translate(50%, -50%);
        color: var(--clr-white);
        font-size: 11px;
        padding: 2px 12px;
        border: 1px solid white;
        border-radius: 50px;
        background-color: var(--clr-secondary);
      }

      .staff {
        flex-grow: 1;
      }

      &:first-child {
        .vuevents_columns_column_timeslots {
          border-left: 1px solid var(--clr-light);
        }
      }
      &:not(:last-child) {
        .vuevents_columns_column_timeslots {
          border-right: 1px solid var(--clr-light);
        }
      }

      &_head {
        z-index: 3;
        position: sticky;
        top: 0;
        height: 132px;
        padding: 16px 0;
        display: grid;
        place-items: center;
        background-color: white;

        .week-dates {
          margin-top: 5px;
        }
        .title {
          font-size: 11px;
          color: var(--clr-gray);
        }
        .next {
          font-size: 11px;
          font-weight: 700;
          color: var(--clr-secondary);
        }

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 6px;
          bottom: -6px;
          background: linear-gradient(180deg, transparent 0%, #e5e6e7 100%);
          transform: rotate(-180deg);
        }
      }

      .deleted {
        background-color: var(--clr-danger-light) !important;
      }

      &_timeslots {
        position: relative;

        &_timeslot {
          border-top: 1px solid var(--clr-light-2);
          height: 100px;

          &:not(:last-child) {
            border-right: 1px solid #f1f1f1;
          }

          &_sub-timeslot {
            transition: background-color 0.2s ease;
            height: 25px;

            .time {
              display: none;
              padding-left: 8px;
              color: var(--clr-gray);
            }

            &:not(:first-child) {
              border-top: 1px solid var(--clr-light);
            }

            &:hover {
              background-color: var(--clr-blue-light);
            }
          }

          &_sub-timeslot:hover .time {
            display: block;
          }
        }

        &_events,
        &_offtimes {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  &_offtime {
    z-index: 2;
    position: absolute;
    width: 100%;
    background: repeating-linear-gradient(
      45deg,
      var(--clr-white),
      var(--clr-white) 10px,
      var(--clr-light) 10px,
      var(--clr-light) 20px
    );
  }

  &_event {
    z-index: 2;
    position: absolute;
    border-radius: 5px;
    padding: 4px;
    border: 1px solid var(--clr-white);
    cursor: pointer;
    overflow: hidden;
    resize: vertical;

    &_head {
      display: flex;
      align-items: flex-end;
      gap: 5px;
      flex-wrap: wrap;
      position: relative;

      .times {
        font-size: 11px;
      }

      .title {
        font-size: 14px;
        font-weight: 700;
      }

      .icons {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 11px;

        .color-red {
          color: var(--clr-danger);
        }

        i:not(:first-child) {
          margin-left: 3px;
        }

        .fa-heart {
          color: var(--clr-danger);
        }
      }
    }

    &_body {
      span {
        font-size: 12px;
        letter-spacing: 0.5px;
      }
    }
  }
}
</style>
