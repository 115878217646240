<template>
  <section class="payments">
    <div class="payments_amount" :class="{ large }">
      <BaseInput
        inputType="number"
        placeholder="Payment amount"
        icon="fas fa-dollar-sign"
        :value="balance"
        @input="$emit('updatePaymentAmount', +$event)"
        :inputFontSize="large ? '48px' : '16px'"
      />
    </div>

    <div class="payments_methods" :class="{ large }">
      <BaseButton @click="$emit('addPayment', 'cash')">Cash</BaseButton>
      <BaseButton @click="$emit('addPayment', 'card')">Card</BaseButton>
      <BaseButton @click="$emit('addPayment', 'gift')">Gift Card</BaseButton>
      <BaseButton @click="$emit('selectedOtherPaymentMethod')"
        >Other</BaseButton
      >
    </div>

    <div class="payments_actions">
      <transition name="checkout" v-if="notCompletedOrRefunded">
        <div class="payments_actions_checkout" v-if="balance <= 0">
          <BaseButton
            :disabled="loading"
            mode="secondary"
            @click="$emit('verifySave', { one: true })"
            ><i v-if="loading" class="fas fa-spinner"></i> Checkout</BaseButton
          >
          <BaseButton
            v-if="!checkoutOnlyMode"
            :disabled="loading"
            mode="secondary-outline"
            @click="$emit('verifySave', { one: true, two: false, three: true })"
            ><i v-if="loading" class="fas fa-spinner"></i> Checkout &
            Rebook</BaseButton
          >
        </div>
      </transition>
      <transition name="checkout">
        <div class="payments_actions_save">
          <BaseButton
            v-if="!checkoutOnlyMode"
            :disabled="loading"
            mode="secondary-outline"
            @click="$emit('verifySave', { one: false })"
            ><i v-if="loading" class="fas fa-spinner"></i> Save &
            Exit</BaseButton
          >
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
export default {
  emits: [
    'addPayment',
    'selectedOtherPaymentMethod',
    'updatePaymentAmount',
    'verifySave',
  ],
  props: {
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    amount: {
      type: Number,
      required: false,
    },
    balance: {
      type: Number,
      required: true,
    },
    ticket: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    checkoutOnlyMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    notCompletedOrRefunded() {
      return (
        this.ticket.status !== 'completed' && this.ticket.status !== 'refunded'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.payments {
  &_methods {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  &_actions {
    &_checkout,
    &_save {
      margin-top: 16px;

      button {
        width: 100%;
      }
    }

    &_checkout {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}

.large {
  button {
    height: 150px;
    font-size: 48px;
  }
}

.checkout-enter-active,
.checkout-leave-active {
  transition: opacity 0.3s ease;
}

.checkout-enter-from,
.checkout-leave-to {
  opacity: 0;
}
</style>
