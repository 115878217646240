<template>
  <section class="kiosk">
    <div class="kiosk_header">
      <div class="container">
        <TheLogo />
      </div>
    </div>
    <div class="kiosk_router">
      <KioskWelcome></KioskWelcome>
    </div>
    <!-- <div class="kiosk_footer">
      <KioskFooter :showContinue="false" />
    </div> -->
  </section>
</template>

<script>
import TheLogo from '@/components/components/TheLogo.vue';
import KioskWelcome from '@/views/kiosk/KioskWelcome.vue';
// import KioskFooter from '@/views/kiosk/KioskFooter.vue';

export default {
  components: {
    TheLogo,
    KioskWelcome,
  },
};
</script>

<style lang="scss" scoped>
.kiosk {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .container {
    max-width: 1400px;
    margin: auto;
    padding: 0 16px;
  }

  &_header {
    padding: 16px 0;
    border-bottom: 1px solid var(--clr-light);
  }

  &_router {
    width: 100%;
    display: grid;
    place-items: center;
    background-color: var(--clr-white);
    flex-grow: 1;
  }
}
</style>
