<template>
  <div class="service" :class="{ selected: selected, disabled: disabled }">
    <div class="main">
      <div class="details">
        <p class="bold">{{ service.title }}</p>
        <p class="description">
          {{ service.description }}
        </p>

        <!-- By Consultation -->
        <div
          v-if="service.displayByConsultationOnBooking"
          class="byConsultation"
        >
          <p class="gray">Priced by Consultation</p>
        </div>
        <!-- All Other Price Types -->
        <div v-else>
          <p v-if="!showDualPriceText" class="gray">
            <span v-if="service.priceType === 'from'">Starting at </span>
            <span :class="{ crossed: service.salePrice }"
              >${{ getServicePrice(service) }}</span
            >
            <span class="small" v-if="showCashPriceText">
              (Cash Price{{
                service.priceType === 'duration' ? '/Per minute' : ''
              }})</span
            >
            <span class="sale" v-if="service.salePrice">
              ${{ service.salePrice }}</span
            >
            -
            <span class="bold"
              >{{
                service.duration + service.extraProcessingTime
              }}
              minutes</span
            >
          </p>

          <p v-else class="gray">
            <span v-if="service.priceType === 'from'">Starting at </span>
            <span :class="{ crossed: service.salePrice }"
              ><span>Cash -</span> ${{ getServicePrice(service, true) }}</span
            >
            <span :class="{ crossed: service.salePrice }">
              | <span>Other -</span> ${{ getServicePrice(service) }}</span
            >
            <span class="small" v-if="showCashPriceText">
              (Cash Price{{
                service.priceType === 'duration' ? '/Per minute' : ''
              }})</span
            >
            <span class="sale" v-if="service.salePrice">
              ${{ service.salePrice }} (Sale Price)</span
            >
            -
            <span class="bold"
              >{{
                service.duration + service.extraProcessingTime
              }}
              minutes</span
            >
          </p>
        </div>
      </div>

      <div class="book" v-if="!service.disabled">
        <!-- <div v-if="selected" class="quantity">
          <i class="fas fa-plus"></i>
        </div> -->

        <p v-if="!selectable" class="addServiceButton">
          <i class="fas fa-plus"></i>
        </p>

        <input
          v-else
          type="checkbox"
          :checked="selected"
          :disabled="disabled"
        />
      </div>
    </div>

    <div v-if="service.disabled" class="alert alert-warning mt-s">
      <p>
        This item is disabled as no staff member can perform this service,
        please contact the establishment to book.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    service: {
      type: Object,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showCashPriceText() {
      if (!this.$store.state.booking.salon) return;

      return (
        this.$store.state.booking.salon.payments.useCashDiscount &&
        this.$store.state.booking.salon.payments.displayCashPrices
      );
    },
    showDualPriceText() {
      if (!this.$store.state.booking.salon) return;

      return (
        this.$store.state.booking.salon.payments.useCashDiscount &&
        this.$store.state.booking.salon.payments.displayDualPrices
      );
    },
  },
  methods: {
    getServicePrice(service, forceCashPrice) {
      let price;
      if (
        this.$store.state.booking.salon.payments.useCashDiscount &&
        !forceCashPrice
      ) {
        price = service.price;

        if (!this.$store.state.booking.salon.payments.displayCashPrices) {
          price +=
            service.price *
            (this.$store.state.booking.salon.payments
              .cashDiscountAdditionalProcessingFee *
              0.01);
        }
      } else {
        price = service.price;
      }

      return price.toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.service {
  .main {
    padding: 24px;
    // border: 1px solid var(--clr-light);
    // border-radius: 5px;
    display: flex;
    gap: 32px;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.2s;
    // background-color: white;
  }

  .details {
    max-width: 80%;
  }

  &:hover {
    border-color: var(--clr-link);
  }

  .bold {
    font-weight: 700;
  }
  .link {
    color: var(--clr-link);
  }
  .small {
    font-size: 12px;
  }

  .description {
    font-size: 14px;
  }

  .addServiceButton {
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 10px;
    border: 1px solid var(--clr-light);
    background-color: var(--clr-light);
    color: var(--clr-primary);
    transition: all 0.5s;

    &:hover {
      background-color: var(--clr-gray);
    }
  }

  .gray {
    color: var(--clr-gray);
  }

  .crossed {
    text-decoration: line-through;
  }

  .sale {
    font-weight: 700;
    color: var(--clr-link);
  }

  p {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  input[type='checkbox'] {
    height: 20px;
    width: 20px;
  }
}

.book {
  display: flex;
  align-items: center;
  gap: 16px;
  .quantity {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    background-color: var(--clr-link);
    color: white;
    font-size: 14px;
    font-weight: 700;
  }
}

.selected {
  border-color: var(--clr-link) !important;
}

.disabled {
  cursor: not-allowed !important;
  opacity: 1 !important;
  background-color: var(--clr-light) !important;
  color: var(--clr-gray) !important;

  &:hover {
    background-color: var(--clr-light) !important;
  }
}

.alert {
  padding: 12px;
  font-size: 14px;

  &-warning {
    background-color: var(--clr-warning-light);
    border: 1px solid;
    border-color: var(--clr-warning);
  }
}

// Tablet
@media (max-width: 900px) {
  .service {
    flex-direction: column;
  }
}
</style>
