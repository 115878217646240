<template>
  <section class="gallery">
    <div class="gallery_head">
      <h2>Booking Gallery</h2>
      <p>Show off your team members work on your online booking page.</p>
    </div>
    <div class="gallery_body">
      <div v-if="gallery" class="gallery_body_gallery">
        <div v-for="index in 12" :key="index" class="image-holder">
          <i
            @click="removeImage(index - 1)"
            v-if="gallery[index - 1]"
            class="fas fa-times"
          ></i>

          <ImageSelect
            :fileSource="gallery.length ? gallery[index - 1] : null"
            @select="selectImage($event, index - 1)"
          />

          <div v-if="gallery[index - 1]" class="actions">
            <i
              v-if="index !== 1"
              @click="moveLeft(index - 1)"
              class="fas fa-caret-left"
            ></i>

            <div v-else>&nbsp;</div>

            <i
              v-if="index !== 12 && gallery[index]"
              @click="moveRight(index - 1)"
              class="fas fa-caret-right"
            ></i>

            <div v-else>&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ImageSelect from '@/components/components/ImageSelect.vue';

export default {
  created() {
    this.init();
  },
  components: {
    ImageSelect,
  },
  computed: {
    salon() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon;
    },
  },
  watch: {
    salon() {
      this.init();
    },
  },
  data() {
    return {
      gallery: [],
    };
  },
  methods: {
    init() {
      if (!this.salon) return;

      this.gallery = this.salon.bookingGallery;
    },
    selectImage(file, index) {
      this.gallery[index] = file;
      this.save();
    },
    removeImage(index) {
      this.gallery.splice(index, 1);
      this.save();
    },
    async save() {
      try {
        await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/booking/gallery`,
          this.gallery,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$toast.success('Gallery updated');
      } catch (err) {
        this.$toast.error(err.message);
      }
    },
    moveLeft(index) {
      const temp = this.gallery[index];
      this.gallery[index] = this.gallery[index - 1];
      this.gallery[index - 1] = temp;

      this.save();
    },

    moveRight(index) {
      const temp = this.gallery[index];
      this.gallery[index] = this.gallery[index + 1];
      this.gallery[index + 1] = temp;

      this.save();
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  &_head {
    h2 {
      font-size: 28px;
    }

    p {
      margin-top: 6px;
    }
  }

  &_body {
    margin-top: 32px;

    &_gallery {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 32px;

      .image-holder {
        padding: 32px;
        border: 1px solid var(--clr-white-2);
        position: relative;

        .fa-times {
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
        }

        .actions {
          margin-top: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          i {
            display: grid;
            place-items: center;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            background-color: var(--clr-secondary);
            color: var(--clr-white);
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
