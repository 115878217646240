<template>
  <section class="endpoints">
    <div class="endpoints_router">
      <p class="bold">On this page:</p>

      <ul>
        <li class="link" @click="scrollIntoView('create')">Create ticket</li>
        <li class="link" @click="scrollIntoView('get')">Get ticket</li>
        <li class="link" @click="scrollIntoView('getAll')">
          Get salons tickets
        </li>
        <li class="link" @click="scrollIntoView('getByNumber')">
          Get salons ticket by number
        </li>
        <li class="link" @click="scrollIntoView('update')">Update ticket</li>
        <li class="link" @click="scrollIntoView('updatePayments')">
          Update ticket payments
        </li>
        <li class="link" @click="scrollIntoView('delete')">Delete ticket</li>
        <li class="link" @click="scrollIntoView('checkout')">
          Checkout ticket
        </li>
      </ul>
    </div>

    <!-- Ticket create -->
    <div class="endpoint" id="create" ref="create">
      <DocsAPITicketsCreate />
    </div>

    <!-- Ticket get -->
    <div class="endpoint" id="get" ref="get">
      <DocsAPITicketsGet />
    </div>

    <!-- Ticket get all -->
    <div class="endpoint" id="getAll" ref="getAll">
      <DocsAPITicketsGetAll />
    </div>

    <!-- Ticket get by number -->
    <div class="endpoint" id="getByNumber" ref="getByNumber">
      <DocsAPITicketsGetByNumber />
    </div>

    <!-- Ticket update -->
    <div class="endpoint" id="update" ref="update">
      <DocsAPITicketsUpdate />
    </div>

    <!-- Ticket update payments -->
    <div class="endpoint" id="updatePayments" ref="updatePayments">
      <DocsAPITicketsUpdatePayments />
    </div>

    <!-- Ticket delete -->
    <div class="endpoint" id="delete" ref="delete">
      <DocsAPITicketsDelete />
    </div>

    <!-- Ticket checkout -->
    <div class="endpoint" id="checkout" ref="checkout">
      <DocsAPITicketsCheckout />
    </div>
  </section>
</template>

<script>
import DocsAPITicketsCreate from './DocsAPITicketsCreate.vue';
import DocsAPITicketsGet from './DocsAPITicketsGet.vue';
import DocsAPITicketsGetAll from './DocsAPITicketsGetAll.vue';
import DocsAPITicketsGetByNumber from './DocsAPITicketsGetByNumber.vue';
import DocsAPITicketsUpdate from './DocsAPITicketsUpdate.vue';
import DocsAPITicketsUpdatePayments from './DocsAPITicketsUpdatePayments.vue';
import DocsAPITicketsDelete from './DocsAPITicketsDelete.vue';
import DocsAPITicketsCheckout from './DocsAPITicketsCheckout.vue';

export default {
  components: {
    DocsAPITicketsCreate,
    DocsAPITicketsGet,
    DocsAPITicketsGetAll,
    DocsAPITicketsGetByNumber,
    DocsAPITicketsUpdate,
    DocsAPITicketsUpdatePayments,
    DocsAPITicketsDelete,
    DocsAPITicketsCheckout,
  },
  methods: {
    scrollIntoView(element) {
      this.$refs[element].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoints {
  &_router {
    margin-bottom: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-white-2);

    ul {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 32px;

      .link {
        cursor: pointer;
        color: var(--clr-link);
      }
    }
  }

  .endpoint {
    &:not(:first-child) {
      margin-top: 64px;
    }
  }
}
</style>
