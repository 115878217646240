<template>
  <section class="loyalty">
    <div class="loyalty_head">
      <h2>Loyalty Points</h2>
      <p>
        Reward clients with loyalty points earned per dollar spent at checkout,
        which can be redeemed for services/products at your discretion.
      </p>
    </div>

    <div class="loyalty_body">
      <form @submit.prevent="submit" class="form">
        <div class="toggle">
          <Toggle
            :checked="loyalty.enabled"
            @toggle="loyalty.enabled = !loyalty.enabled"
          />
          <div class="toggle_text">
            <p class="toggle_text_label">Enably Loyalty Points</p>
            <p class="toggle_text_subtext">
              Reward clients with loyalty points, earned per dollar spent at
              checkout.
            </p>
          </div>
        </div>

        <BaseInput
          v-if="loyalty.enabled"
          type="number"
          label="Loyalty Points Per Dollar Spent"
          :value="loyalty.pointsPerDollarSpent"
          @input="loyalty.pointsPerDollarSpent = +$event"
        />

        <div class="toggle" v-if="loyalty.enabled">
          <Toggle
            :checked="loyalty.sendLoyaltyEmail"
            @toggle="loyalty.sendLoyaltyEmail = !loyalty.sendLoyaltyEmail"
          />
          <div class="toggle_text">
            <p class="toggle_text_label">Send Loyalty Points Earned Email</p>
            <p class="toggle_text_subtext">
              After checkout, send an email to client letting them know how many
              loyalty points they earned. Standard email rates apply.
            </p>
          </div>
        </div>

        <div class="form_actions">
          <BaseButton>Save</BaseButton>
        </div>
      </form>

      <!-- Redemptions -->
      <div v-if="loyalty.enabled" class="loyalty_body_redemptions">
        <div class="head">
          <h2>Redemptions</h2>
          <BaseButton
            @click="showRedemptionForm = true"
            mode="primary-outline small"
            >Add New</BaseButton
          >
        </div>
        <p>
          Clients can redeem their loyalty points for services/products at your
          discretion.
        </p>

        <div v-if="redemptions.length" class="loyalty_body_redemptions_list">
          <div class="loyalty_body_redemptions_list_item">
            <div class="loyalty_body_redemptions_list_item_head">
              <h3>Item</h3>
              <p>Points</p>
            </div>

            <div class="loyalty_body_redemptions_list_item_body">
              <div
                v-for="redemption in redemptions"
                :key="redemption._id"
                class="loyalty_body_redemptions_list_item_body_item"
              >
                <p>{{ redemption.item.title }}</p>
                <p>
                  {{ redemption.cost }}
                  <i
                    @click="selectedRedemption = redemption"
                    class="fas fa-edit"
                  ></i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="none">
          <h3>No redemptions to display</h3>
          <p>Select "Add New" to get started</p>
        </div>
      </div>
    </div>
  </section>

  <Modal
    title="Loyalty Redemption Form"
    :show="showRedemptionForm || selectedRedemption"
    @close="
      showRedemptionForm = false;
      selectedRedemption = null;
    "
  >
    <div class="redemptionForm">
      <LoyaltyRedemptionForm
        :redemption="selectedRedemption"
        @submit="submitRedemptionForm($event)"
        @delete="deleteRedemption"
      />
    </div>
  </Modal>
</template>

<script>
import LoyaltyRedemptionForm from '@/components/items/LoyaltyRedemptionForm.vue';

export default {
  components: {
    LoyaltyRedemptionForm,
  },
  created() {
    if (!this.salon) return;
    this.init();
  },
  computed: {
    salon() {
      return this.$store.state.auth.salon;
    },
  },
  data() {
    return {
      loyalty: {
        enabled: false,
        pointsPerDollarSpent: 1,
        sendLoyaltyEmail: false,
      },

      redemptions: [],

      showRedemptionForm: false,
      selectedRedemption: null,
    };
  },
  methods: {
    init() {
      if (!this.salon) return;

      this.loyalty = JSON.parse(JSON.stringify(this.salon.loyalty));
      this.redemptions = this.$store.state.auth.salon.loyalty.redemptions;
    },

    async submit() {
      try {
        await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/loyalty`,
          this.loyalty,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$toast.success('Loyalty settings updated successfully');
      } catch (e) {
        console.log(e);
        this.$toast.error(e.message);
      }
    },

    async submitRedemptionForm(redemption) {
      try {
        if (redemption._id) {
          // Edit

          const response = await this.$axios.put(
            `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/loyalty/redemptions/${redemption._id}`,
            redemption,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );

          const index = this.redemptions.findIndex(
            (r) => r._id === redemption._id
          );

          this.redemptions.splice(index, 1, response.data.redemption);
          this.$toast.success('Redemption updated');
        } else {
          // Create

          const response = await this.$axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/loyalty/redemptions`,
            redemption,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );

          this.redemptions.push(response.data.redemption);
          this.$toast.success('Redemption created');
        }

        this.showRedemptionForm = false;
        this.selectedRedemption = null;
      } catch (e) {
        console.log(e);
        this.$toast.error(e.message);
      }
    },

    async deleteRedemption() {
      if (!this.selectedRedemption) return;

      try {
        await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/loyalty/redemptions/${this.selectedRedemption._id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        const index = this.redemptions.findIndex(
          (r) => r._id === this.selectedRedemption._id
        );

        this.redemptions.splice(index, 1);
        this.$toast.success('Redemption deleted');
      } catch (e) {
        console.log(e);
        this.$toast.error(e.message);
      }

      this.showRedemptionForm = false;
      this.selectedRedemption = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.loyalty {
  width: 100%;
  max-width: 800px;

  &_head {
    h2 {
      font-size: 28px;
    }
    p {
      margin-top: 8px;
      color: var(--clr-gray);
    }
  }

  &_body {
    margin-top: 32px;

    .form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &_actions {
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;
      }
    }

    .toggle {
      display: flex;
      align-items: center;
      gap: 32px;
      padding: 16px 0;

      &_text {
        &_label {
          font-size: 18px;
        }
        &_subtext {
          margin-top: 5px;
          font-size: 14px;
        }
      }
    }

    &_redemptions {
      margin-top: 64px;

      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      h2 {
        font-size: 28px;
      }
      p {
        margin-top: 8px;
        color: var(--clr-gray);
      }

      &_list {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        gap: 32px;

        &_item {
          display: flex;
          flex-direction: column;
          gap: 16px;

          &_head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;

            h3 {
              font-size: 18px;
            }
            p {
              font-size: 14px;
            }
          }

          &_body {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &_item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 16px;

              p {
                font-size: 14px;
              }
              i {
                margin-left: 8px;
                cursor: pointer;
                color: var(--clr-link);
              }
            }
          }
        }
      }
    }
  }
}

.none {
  margin-top: 32px;
  text-align: center;
  padding: 16px;
  border-radius: 5px;
  border: 1px solid var(--clr-light);

  p {
    color: var(--clr-gray);
    margin-top: 5px;
  }
}

.redemptionForm {
  padding: 32px;
  min-height: 75vh;
}
</style>
