<template>
  <aside class="sidebar">
    <nav class="sidebar_nav">
      <ul class="sidebar_nav_items">
        <!-- Home -->
        <router-link v-if="canViewHome" :to="{ name: 'Dashboard' }">
          <li id="homeLink" class="sidebar_nav_items_item">
            <i class="fas fa-igloo"></i>
          </li>
        </router-link>
        <!-- Calendar -->
        <router-link :to="{ name: 'Calendar' }">
          <li id="calendarLink" class="sidebar_nav_items_item" title="Calendar">
            <i class="fas fa-calendar"></i>
          </li>
        </router-link>
        <!-- Classic -->
        <router-link :to="{ name: 'ClassicView' }">
          <li id="classicLink" class="sidebar_nav_items_item" title="Classic">
            <i class="fas fa-columns"></i>
          </li>
        </router-link>
        <!-- Sales -->
        <router-link v-if="canViewSales" :to="{ name: 'Sales' }">
          <li id="salesLink" class="sidebar_nav_items_item" title="Sales">
            <i class="fas fa-tag"></i>
          </li>
        </router-link>
        <!-- Clients -->
        <router-link v-if="canViewClients" :to="{ name: 'Clients' }">
          <li id="clientsLink" class="sidebar_nav_items_item" title="Clients">
            <i class="fas fa-smile"></i>
          </li>
        </router-link>
        <!-- Team -->
        <router-link :to="{ name: 'Team' }">
          <li id="teamLink" class="sidebar_nav_items_item" title="Team">
            <i class="fas fa-users"></i>
          </li>
        </router-link>
        <!-- Gift Cards -->
        <router-link v-if="canViewGiftcards" :to="{ name: 'Giftcards' }">
          <li
            id="giftcardsLink"
            class="sidebar_nav_items_item"
            title="Gift Cards"
          >
            <i class="fas fa-gift"></i>
          </li>
        </router-link>
        <!-- Marketing -->
        <router-link v-if="canViewMarketing" :to="{ name: 'Marketing' }">
          <li
            id="marketingLink"
            class="sidebar_nav_items_item"
            title="Marketing"
          >
            <i class="fas fa-bullhorn"></i>
          </li>
        </router-link>
        <!-- Integrations -->
        <!-- <router-link :to="{ name: 'Integrations' }">
          <li
            id="integrationsLink"
            class="sidebar_nav_items_item"
            title="Integrations"
          >
            <i class="fas fa-globe-americas"></i>
          </li>
        </router-link> -->
        <!-- Items -->
        <router-link
          id="itemsLink"
          v-if="canViewServicesOrProducts"
          :to="{ name: 'Items' }"
        >
          <li class="sidebar_nav_items_item" title="Items">
            <i class="fas fa-hand-sparkles"></i>
          </li>
        </router-link>
        <!-- Messenger -->
        <router-link v-if="canViewMessenger" :to="{ name: 'Messenger' }">
          <li
            id="messengerLink"
            class="sidebar_nav_items_item"
            title="Messenger"
          >
            <i class="fas fa-comment-alt"></i>
            <div v-if="unreadChatMessages" class="messages-number">
              <p>{{ unreadChatMessages }}</p>
            </div>
          </li>
        </router-link>
        <!-- Orders -->
        <router-link v-if="canViewOrders" :to="{ name: 'Orders' }">
          <li id="ordersLink" class="sidebar_nav_items_item" title="Orders">
            <i class="fas fa-shopping-bag"></i>
          </li>
        </router-link>
        <!-- Support -->
        <router-link :to="{ name: 'Support' }">
          <li id="supportLink" class="sidebar_nav_items_item" title="Support">
            <i class="fas fa-question-circle"></i>
          </li>
        </router-link>

        <!-- <router-link :to="{ name: 'VirtTerm' }">
          <li class="sidebar_nav_items_item" title="Virtual Terminal">
            <i class="fas fa-laptop-medical"></i>
          </li>
        </router-link> -->

        <!-- Settings -->
        <router-link v-if="canViewSettings" :to="{ name: 'Settings' }">
          <li id="settingsLink" class="sidebar_nav_items_item" title="Settings">
            <i class="fas fa-cog"></i>
          </li>
        </router-link>
      </ul>
    </nav>
  </aside>
</template>

<script>
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/perspective.css';

export default {
  created() {
    this.init();
  },
  mounted() {
    this.initTooltips();
  },
  data() {
    return {
      canViewHome: false,
      canViewClients: false,
      canViewSales: false,
      canViewServicesOrProducts: false,
      canViewMessenger: false,
      canViewOrders: false,
      canViewSettings: false,
      canViewGiftcards: false,
      canViewMarketing: false,
    };
  },
  watch: {
    managerMode() {
      this.init();
    },
    tempUser() {
      this.init();
    },
  },
  computed: {
    managerMode() {
      return this.$store.state.auth.managerMode;
    },
    tempUser() {
      return this.$store.state.auth.tempUser;
    },
    salon() {
      return this.$store.state.auth.salon;
    },
    chats() {
      return this.$store.state.messenger.chats;
    },
    unreadChatMessages() {
      let count = 0;

      this.chats.forEach((chat) => {
        const lastMessage = chat.messages[chat.messages.length - 1];

        if (!lastMessage.read) {
          if (
            lastMessage.author.authorId.toString() !==
            this.$store.state.auth.salon._id.toString()
          ) {
            count++;
          }
        }
      });

      return count;
    },
    actions() {
      return [
        {
          id: 'homeLink',
          content: 'Home',
        },
        {
          id: 'calendarLink',
          content: 'Calendar',
        },
        {
          id: 'classicLink',
          content: 'Classic View',
        },
        {
          id: 'salesLink',
          content: 'Sales',
        },
        {
          id: 'clientsLink',
          content: 'Clients',
        },
        {
          id: 'teamLink',
          content: 'Team',
        },
        {
          id: 'giftcardsLink',
          content: 'Gift Cards',
        },
        {
          id: 'marketingLink',
          content: 'Marketing',
        },
        // {
        //   id: 'integrationsLink',
        //   content: 'Integrations',
        // },
        {
          id: 'itemsLink',
          content: 'Items',
        },
        {
          id: 'messengerLink',
          content: 'Messenger',
        },
        {
          id: 'ordersLink',
          content: 'Orders',
        },
        {
          id: 'supportLink',
          content: 'Support',
        },
        {
          id: 'settingsLink',
          content: 'Settings',
        },
      ];
    },
  },
  methods: {
    async init() {
      this.canViewHome = await this.$store.dispatch(
        'auth/activeUserHasPermission',
        'bookings/viewHome'
      );

      this.canViewClients = await this.$store.dispatch(
        'auth/activeUserHasPermission',
        'bookings/viewClients'
      );

      this.canViewGiftcards = await this.$store.dispatch(
        'auth/activeUserHasPermission',
        'other/viewGiftcards'
      );

      this.canViewMarketing = await this.$store.dispatch(
        'auth/activeUserHasPermission',
        'other/viewMarketing'
      );

      this.canViewMessenger = await this.$store.dispatch(
        'auth/activeUserHasPermission',
        'other/viewMessenger'
      );

      this.canViewOrders = await this.$store.dispatch(
        'auth/activeUserHasPermission',
        'other/viewOrders'
      );

      this.canViewSales =
        (await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'tickets/viewOwnReports'
        )) ||
        (await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'tickets/viewOthersReports'
        )) ||
        (await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'tickets/viewSummary'
        ));

      this.canViewServicesOrProducts =
        (await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'other/viewServices'
        )) ||
        (await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'other/viewProducts'
        ));

      this.canViewSettings =
        this.$store.state.auth.salon.adminId ===
          this.$store.getters['auth/userId'] ||
        this.$store.getters['auth/loggedInStaffRoleIsAdmin'];

      // Messages
      const response = await this.$axios.get(
        `${process.env.VUE_APP_RASERVA_BACKEND}/messages?salonId=${this.salon._id}`
      );

      this.$store.state.messenger.chats = response.data.messages;
    },
    initTooltips() {
      // timeout 1 second
      setTimeout(() => {
        this.actions.forEach((action) => {
          tippy(`#${action.id}`, {
            content: action.content,
            arrow: true,
            placement: 'right',
            offset: [0, 20],
            animation: 'perspective',
          });
        });
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  height: 100%;
  padding: 12px;
  overflow-y: auto;
  // background-color: var(--clr-white);
  background-color: white;
  border-right: 1px solid var(--clr-light);

  &_nav {
    &_items {
      .router-link-active {
        li {
          background-color: var(--clr-secondary) !important;
          color: var(--clr-white);
        }
      }

      .item_tooltip {
        color: var(--clr-primary);
      }

      &_item {
        display: grid;
        place-items: center;
        height: 48px;
        width: 48px;
        margin-bottom: 12px;
        border-radius: 5px;
        transition: background-color 0.2s;
        cursor: pointer;
        color: var(--clr-gray);
        position: relative;

        i {
          font-size: 24px;
        }

        &:hover {
          background-color: var(--clr-gray);
          color: var(--clr-white);
        }

        .messages-number {
          height: 20px;
          width: 20px;
          background-color: var(--clr-danger);
          border-radius: 50%;
          color: white;
          position: absolute;
          top: -5px;
          right: -5px;
          font-size: 10px;
          font-weight: 700;
          display: grid;
          place-items: center;
        }
      }
    }
  }
}
</style>
