<template>
  <div class="tickets">
    <div class="tickets_search">
      <div class="tickets_search_input">
        <form @submit.prevent>
          <BaseInput
            placeholder="Search.."
            icon="fas fa-search"
            @input="searchValue = $event"
          />
        </form>
      </div>
      <BaseButton
        v-if="managerMode && !combineTickets.length && !groupTickets.length"
        mode="danger-outline small"
        @click="showConfirmClearTickets = true"
        ><i class="far fa-trash-alt"></i
      ></BaseButton>
      <BaseButton
        v-else-if="combineTickets.length"
        mode="primary-outline small"
        @click="performCombineTickets"
      >
        <i class="fas fa-object-group"></i>
      </BaseButton>
      <BaseButton
        v-else-if="groupTickets.length"
        mode="primary-outline small"
        @click="showGroupNameModal = true"
      >
        <i class="fas fa-layer-group"></i>
      </BaseButton>
    </div>
    <div class="tickets_filter-sort">
      <BaseSelect
        :options="ticketFilterOptions"
        :value="selectedTicketFilterOption"
        @input="filterTickets($event)"
      />
      <BaseSelect
        :options="ticketSortOptions"
        :value="selectedTicketSortOption"
        @input="sortTickets($event)"
      />
    </div>
    <div class="tickets_tickets">
      <div
        class="tickets_tickets_none"
        v-if="!tickets.length || !tickets.length"
      >
        <h1>
          <span v-if="!tickets.length">Time to start the day!</span>
          <span v-else>No filtered results!</span>
        </h1>
        <p>Create a ticket to get started.</p>
      </div>
      <DetailedTicketCard
        v-for="ticket in tickets"
        :key="ticket"
        :ticket="ticket"
        @selectTicket="selectTicket($event)"
        @selectService="selectService($event)"
        @filterByGroup="filterTickets(ticket.groupName)"
        @rebookAppointmentFromTicket="
          $emit('rebookAppointmentFromTicket', $event)
        "
      />
    </div>
  </div>

  <Confirm
    v-if="showConfirmClearTickets"
    delete
    title="Clear tickets"
    :text="clearTicketsText"
    @confirm="clearTicketsPrecheck"
    @deny="showConfirmClearTickets = false"
  />

  <Confirm
    v-if="showDoubleConfirmClearTickets"
    delete
    title="Final Warning"
    :text="clearTicketsDoubleConfirmationText"
    @confirm="clearTickets"
    @deny="showDoubleConfirmClearTickets = false"
  />

  <Modal
    title="Group Name"
    :show="showGroupNameModal"
    @close="showGroupNameModal = false"
  >
    <form @submit.prevent="performGroupTickets" class="groupName">
      <BaseInput
        label="Group Name"
        :value="groupName"
        @input="groupName = $event"
      />
      <div class="form_actions">
        <BaseButton>Create Group</BaseButton>
      </div>
    </form>
  </Modal>
</template>

<script>
import DetailedTicketCard from '@/components/dashboard/DetailedTicketCard.vue';

export default {
  emits: ['selectTicket', 'selectService', 'rebookAppointmentFromTicket'],
  components: {
    DetailedTicketCard,
  },
  created() {
    this.init();

    if (this.socket) {
      this.socketInit();
    }

    this.addGroupsToFilter();

    this.$store.state.tickets.combineTickets = [];
    this.$store.state.tickets.groupTickets = [];
  },
  watch: {
    tickets() {
      this.addGroupsToFilter();

      // if (this.searchValue) this.searchTickets(this.searchValue);
    },
    searchValue(val) {
      this.searchTickets(val);
    },
  },
  computed: {
    socket() {
      return this.$store.state.sockets.socket;
    },
    inProgressTickets() {
      return this.tickets.filter((ticket) => ticket.status === 'in-progress');
    },
    waitingTickets() {
      return this.tickets.filter((ticket) => ticket.status === 'waiting');
    },
    managerMode() {
      return this.$store.state.auth.managerMode;
    },
    clearTicketsDoubleConfirmationText() {
      let text = 'FINAL WARNING! This will clear all tickets.';

      if (this.notCheckedOutTicketWithPayment) {
        text +=
          ' IMPORTANT! There are tickets that are not checked out with payment methods attached.';
      }

      if (
        (this.inProgressTickets && this.inProgressTickets.length) ||
        (this.waitingTickets && this.waitingTickets.length)
      ) {
        text +=
          ' IMPORTANT! There are tickets that are in progress and will be deleted.';
      }

      return text;
    },
    clearTicketsText() {
      let text =
        'Are you sure you wish to clear all tickets? This action can not be undone.';

      if (this.notCheckedOutTicketWithPayment) {
        text +=
          ' IMPORTANT! There are tickets that are not checked out with payment methods attached.';
      }

      if (
        (this.inProgressTickets && this.inProgressTickets.length) ||
        (this.waitingTickets && this.waitingTickets.length)
      ) {
        text +=
          ' IMPORTANT! There are tickets that are in progress and will be deleted.';
      }

      return text;
    },
    combineTickets() {
      return this.$store.state.tickets.combineTickets;
    },
    groupTickets() {
      return this.$store.state.tickets.groupTickets;
    },
    ticketGroups() {
      const groups = [];

      this.tickets.forEach((ticket) => {
        if (ticket.groupName) {
          const group = groups.find((g) => g.name === ticket.groupName);

          if (group) {
            group.tickets.push(ticket);
          } else {
            groups.push({
              name: ticket.groupName,
              tickets: [ticket],
            });
          }
        }
      });

      return groups;
    },
  },
  data() {
    return {
      tickets: [],
      showConfirmClearTickets: false,
      showDoubleConfirmClearTickets: false,
      searchValue: '',

      groupName: '',
      showGroupNameModal: false,

      selectedTicketFilterOption: 'all',
      selectedTicketSortOption: 'oldest',

      ticketFilterOptions: [
        { value: 'all', option: 'All' },
        { value: 'waiting', option: 'Waiting' },
        { value: 'in-progress', option: 'In Progress' },
        { value: 'completed', option: 'Completed' },
      ],
      ticketSortOptions: [
        { value: 'newest', option: 'Newest' },
        { value: 'oldest', option: 'Oldest' },
      ],
    };
  },
  methods: {
    init() {
      const ticketSort = localStorage.getItem('ticketSort');
      const ticketFilter = localStorage.getItem('ticketFilter');

      ticketSort
        ? (this.selectedTicketSortOption = ticketSort)
        : (this.selectedTicketSortOption = 'all');

      ticketFilter
        ? (this.selectedTicketFilterOption = ticketFilter)
        : (this.selectedTicketFilterOption = 'oldest');

      this.filterTickets();
    },

    socketInit() {
      this.$store.state.sockets.socket.on('clearTickets', () => {
        this.tickets = [];
      });
    },

    addGroupsToFilter() {
      this.ticketFilterOptions = [
        { value: 'all', option: 'All' },
        { value: 'waiting', option: 'Waiting' },
        { value: 'in-progress', option: 'In Progress' },
        { value: 'completed', option: 'Completed' },
      ];

      if (this.ticketGroups.length) {
        this.ticketFilterOptions.push({ value: null, option: '' });
      }

      this.ticketGroups.forEach((group) => {
        this.ticketFilterOptions.push({
          value: group.id,
          option: group.name,
        });
      });
    },

    searchTickets(query) {
      if (!query) {
        this.tickets = this.$store.state.tickets.tickets;
        return;
      }

      this.tickets = this.$store.getters['tickets/search'](query);

      this.filterTickets(this.selectedTicketFilterOption, true);
    },

    async performCombineTickets() {
      try {
        await this.$store.dispatch('tickets/combineTickets');

        this.$toast.success('Tickets combined');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async performGroupTickets() {
      try {
        if (!this.groupName.length) {
          this.$toast.error('Please enter a group name');
          return;
        }

        await this.$store.dispatch('tickets/groupTickets', this.groupName);

        this.showGroupNameModal = false;
        this.groupName = '';

        this.tickets = JSON.parse(JSON.stringify(this.tickets));
        this.$toast.success('Tickets grouped');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    clearTicketsPrecheck() {
      if (
        this.notCheckedOutTicketWithPayment ||
        (this.inProgressTickets && this.inProgressTickets.length) ||
        (this.waitingTickets && this.waitingTickets.length)
      ) {
        this.showDoubleConfirmClearTickets = true;
        return;
      }

      this.clearTickets();
    },

    async clearTickets() {
      try {
        await this.$store.dispatch('tickets/clearTickets');

        this.tickets = [];

        this.showConfirmClearTickets = false;
        this.showDoubleConfirmClearTickets = false;
        this.$toast.success('Tickets cleared');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    selectTicket(ticket) {
      this.$emit('selectTicket', ticket);
    },
    selectService(service) {
      this.$emit('selectService', service);
    },

    filterTickets(filter, useCurrentTickets) {
      if (filter) {
        this.selectedTicketFilterOption = filter;
      } else {
        filter = this.selectedTicketFilterOption;
      }

      localStorage.setItem('ticketFilter', filter);

      if (!useCurrentTickets) {
        if (filter === 'completed') {
          this.tickets = this.$store.getters['tickets/completed'];
        } else if (filter === 'waiting') {
          this.tickets = this.$store.getters['tickets/waiting'];
        } else if (filter === 'in-progress') {
          this.tickets = this.$store.getters['tickets/inProgress'];
        } else if (filter !== 'all') {
          this.tickets = this.$store.getters['tickets/byGroup'](filter);
        } else {
          this.tickets = this.$store.state.tickets.tickets;
        }
      } else {
        if (filter === 'completed') {
          this.tickets = this.tickets.filter(
            (ticket) => ticket.status === 'completed'
          );
        } else if (filter === 'waiting') {
          this.tickets = this.tickets.filter(
            (ticket) => ticket.status === 'waiting'
          );
        } else if (filter === 'in-progress') {
          this.tickets = this.tickets.filter(
            (ticket) => ticket.status === 'in-progress'
          );
        }
      }

      this.sortTickets(this.selectedTicketSortOption);
    },
    sortTickets(sort) {
      this.selectedTicketSortOption = sort;

      localStorage.setItem('ticketSort', sort);

      if (sort === 'newest') {
        this.tickets = this.tickets.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        return;
      }

      this.tickets = this.tickets.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.tickets {
  height: 100%;
  padding: 16px;
  border-radius: 5px;
  background-color: var(--clr-light);
  max-width: 350px;
  overflow-y: auto;

  &_search {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 10px;

    &_input {
      width: 100%;
    }

    &_add {
      display: grid;
      place-items: center;
      cursor: pointer;
    }
  }

  &_filter-sort {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 16px;

    .parent {
      flex-grow: 1;
    }
  }

  &_clear {
    margin-bottom: 16px;

    button {
      width: 100%;
    }
  }

  &_tickets {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_none {
      text-align: center;
    }
  }
}

.groupName {
  padding: 32px;

  .form_actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}

// Tablet
@media (max-width: 900px) {
  .tickets {
    width: 100%;
    max-width: 100%;
    grid-row: 1;

    &_tickets {
      max-width: 100%;
      overflow-x: auto;
      flex-direction: row;
      align-items: flex-start;
    }
  }
}
</style>
