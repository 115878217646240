Doc
<template>
  <section class="endpoints">
    <div class="endpoints_router">
      <p class="bold">On this page:</p>

      <ul>
        <li class="link" @click="scrollIntoView('prerequisites')">
          Prerequisites
        </li>
        <li class="link" @click="scrollIntoView('getData')">
          Get dejavoo data
        </li>
        <li class="link" @click="scrollIntoView('process')">
          Process transaction
        </li>
        <li class="link" @click="scrollIntoView('queryJob')">Query job</li>
        <li class="link" @click="scrollIntoView('voidRefund')">Void/Refund</li>
        <li class="link" @click="scrollIntoView('getPaymentFormLink')">
          Get payment form link
        </li>
        <li class="link" @click="scrollIntoView('chargeViaToken')">
          Charge via token
        </li>
      </ul>
    </div>

    <!-- Prerequisites -->
    <div class="endpoint" id="prerequisites" ref="prerequisites">
      <DocsAPIDejavooPrereq />
    </div>

    <!-- Get dejavoo data -->
    <div class="endpoint" id="getData" ref="getData">
      <DocsAPIDejavooGet />
    </div>

    <!-- Process transaction -->
    <div class="endpoint" id="process" ref="process">
      <DocsAPIDejavooProcess />
    </div>

    <!-- Query job -->
    <div class="endpoint" id="queryJob" ref="queryJob">
      <DocsAPIDejavooQueryJob />
    </div>

    <!-- Void/Refund -->
    <div class="endpoint" id="voidRefund" ref="voidRefund">
      <DocsAPIDejavooVoidRefund />
    </div>

    <!-- Get payment form link -->
    <div class="endpoint" id="getPaymentFormLink" ref="getPaymentFormLink">
      <DocsAPIDejavooGetPaymentFormLink />
    </div>

    <!-- Charge via token -->
    <div class="endpoint" id="chargeViaToken" ref="chargeViaToken">
      <DocsAPIDejavooChargeViaToken />
    </div>
  </section>
</template>

<script>
import DocsAPIDejavooProcess from './DocsAPIDejavooProcess.vue';
import DocsAPIDejavooQueryJob from './DocsAPIDejavooQueryJob.vue';
import DocsAPIDejavooVoidRefund from './DocsAPIDejavooVoidRefund.vue';
import DocsAPIDejavooGetPaymentFormLink from './DocsAPIDejavooGetPaymentFormLink.vue';
import DocsAPIDejavooChargeViaToken from './DocsAPIDejavooChargeViaToken.vue';
import DocsAPIDejavooPrereq from './DocsAPIDejavooPrereq.vue';
import DocsAPIDejavooGet from './DocsAPIDejavooGet.vue';

export default {
  components: {
    DocsAPIDejavooProcess,
    DocsAPIDejavooQueryJob,
    DocsAPIDejavooVoidRefund,
    DocsAPIDejavooGetPaymentFormLink,
    DocsAPIDejavooChargeViaToken,
    DocsAPIDejavooPrereq,
    DocsAPIDejavooGet,
  },
  methods: {
    scrollIntoView(element) {
      this.$refs[element].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoints {
  &_router {
    margin-bottom: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-white-2);

    ul {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 32px;

      .link {
        cursor: pointer;
        color: var(--clr-link);
      }
    }
  }

  .endpoint {
    &:not(:first-child) {
      margin-top: 64px;
    }
  }
}
</style>
