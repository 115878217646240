<template>
  <div class="services">
    <h2 class="page-title" ref="services">Select one or more services</h2>

    <div class="slider">
      <BookingCategoriesSlider
        @select="filterByCategory($event)"
        :selectedCategoryId="categoryFilter"
      />
    </div>

    <div class="search">
      <BaseInput
        placeholder="Search services.."
        icon="fas fa-search"
        @input="
          filter = $event;
          categoryFilter = '';
        "
      />
    </div>

    <div
      class="services_categories"
      v-show="category.items.length"
      v-for="category in serviceCategories"
      :key="category"
    >
      <h2>{{ category.title }}</h2>
      <div class="services_categories_services">
        <div v-for="service in category.items" :key="service" class="service">
          <BookingServiceItem
            selectable
            :service="service"
            :disabled="
              service.disabled ||
              (!isServiceSelected(service) && selectedServices.length >= limit)
            "
            :selected="isServiceSelected(service)"
            @click="service.disabled ? null : selectService(service)"
          />
        </div>
        <div v-if="!category.items.length" class="none">
          <h3>No items to display</h3>
          <p>Search value filtered with no results</p>
        </div>
      </div>
    </div>

    <div v-if="!serviceCategories.length" class="none">
      <h3>
        The selected team member can not perform any services in this group.
      </h3>
      <p>Please select another team member, or a new group to continue.</p>
    </div>
  </div>

  <BookingContinue :disabled="!selectedServices.length" @continue="nextStep" />
</template>

<script>
import BookingServiceItem from '@/components/booking/BookingServiceItem.vue';
import BookingContinue from '@/components/booking/BookingContinue.vue';
import BookingCategoriesSlider from '@/components/booking/BookingCategoriesSlider.vue';

export default {
  components: {
    BookingServiceItem,
    BookingContinue,
    BookingCategoriesSlider,
  },
  mounted() {
    if (this.$store.state.booking.confirmed) {
      this.$toast.success('Booking was confirmed!');

      this.$router.push({
        name: 'BookConfirm',
        params: { id: this.salonId },
      });

      return;
    }

    if (!this.$store.state.booking.currentBookingTracking) {
      this.$store.dispatch('booking/createBookingTracking', 'Select Services');
    } else {
      this.$store.dispatch('booking/updateBookingTracking', 'Select Services');
    }

    if (this.$refs.services) {
      this.$refs.services.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  },
  computed: {
    salonId() {
      return this.$store.state.booking.salon._id;
    },
    staffs() {
      return this.$store.state.booking.salon.staff;
    },
    serviceCategories() {
      let categories = [];

      const serviceCategories = this.$store.getters['booking/calendarServices'];

      if (!this.selectedStaff) {
        categories = serviceCategories.filter(
          (category) => category.items.length !== 0
        );
      } else {
        serviceCategories.forEach((category) => {
          if (
            category.items.find((item) =>
              this.selectedStaff.services.find(
                (service) => service === item._id
              )
            )
          ) {
            categories.push(category);
          }
        });

        categories.forEach((category) => {
          category.items = category.items.filter((item) =>
            this.selectedStaff.services.find((service) => service === item._id)
          );
        });

        categories = categories.filter(
          (category) => category.items.length !== 0
        );
      }

      if (this.filter) {
        categories.forEach((category) => {
          category.items = category.items.filter((item) =>
            item.title.toLowerCase().includes(this.filter.toLowerCase())
          );
        });
      }

      if (this.categoryFilter) {
        categories = categories.filter(
          (category) => category._id === this.categoryFilter
        );
      }

      // Set disabled field if no staff can perform a service
      categories.forEach((category) => {
        category.items.forEach((item) => {
          item.disabled = !this.staffs.find((staff) =>
            staff.services.find((service) => service === item._id)
          );
        });
      });

      return categories;
    },
    selectedServices() {
      return this.$store.state.booking.selectedServices;
    },
    selectedStaff() {
      return this.$store.state.booking.selectedStaff;
    },
    isDateAndTimeSelected() {
      return (
        this.$store.state.booking.selectedDate &&
        this.$store.state.booking.selectedTime
      );
    },
  },
  data() {
    return {
      filter: '',
      categoryFilter: '',
      limit: 5,
    };
  },
  methods: {
    filterByCategory(categoryId) {
      if (this.categoryFilter === categoryId) {
        this.categoryFilter = '';
      } else {
        this.categoryFilter = categoryId;
      }

      setTimeout(() => {
        this.$refs.services.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 100);
    },
    selectService(service) {
      this.$store.dispatch('booking/selectService', service);
    },
    isServiceSelected(service) {
      // Maybe find a better way to do this?
      return !!this.selectedServices.find(
        (preService) => preService._id === service._id
      );
    },
    nextStep() {
      if (!this.selectedStaff) {
        this.$router.push({
          name: 'BookMembers',
          params: { id: this.salonId },
        });
      } else {
        this.$router.push({ name: 'BookDate', params: { id: this.salonId } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.services {
  .mt-s {
    margin-top: 8px;
  }
  .alert {
    padding: 12px;
    font-size: 14px;
    border-radius: 5px;
    margin-bottom: 32px;

    &-warning {
      background-color: var(--clr-warning-light);
      border: 1px solid;
      border-color: var(--clr-warning);
    }
  }
  .page-title {
    text-align: center;
    margin-left: 0;
  }
  h2 {
    font-size: 24px;
    margin-left: 16px;
  }

  .search {
    margin-top: 16px;
  }

  .slider {
    position: sticky;
    top: 10px;
    margin-top: 16px;
    max-width: 92vw;
  }

  .none {
    margin-top: 16px;
    text-align: center;
    padding: 16px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;

    p {
      margin-top: 8px;
    }
  }

  &_categories {
    margin-top: 32px;

    &_services {
      border-radius: 25px;
      box-shadow: var(--shadow-large);
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      background-color: white;
      overflow: hidden;

      .service {
        &:hover {
          background-color: var(--clr-white);
        }

        &:not(:last-child) {
          border-bottom: 1px solid var(--clr-light);
        }
      }
    }
  }
}
</style>
