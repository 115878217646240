<template>
  <section class="login">
    <form @submit.prevent="submit" class="form">
      <div v-if="twoFactorCodeSent" class="alert alert-info">
        <p>
          A text message was sent to your phone, please enter the code in the
          field below to continue, this code is valid for 15 minutes.
        </p>
      </div>

      <BasePhoneInput
        label="Phone Number"
        :disabled="twoFactorCodeSent"
        @input="phoneNumber = $event"
        :value="phoneNumber"
      />

      <BaseInput
        v-if="twoFactorCodeSent"
        label="Code"
        @input="twoFactorCode = $event"
      />

      <div class="form_actions">
        <BaseButton :disabled="loading || !formValid"
          ><i v-if="loading" class="fa fa-spinner"></i> Submit</BaseButton
        >
      </div>
    </form>
  </section>
</template>

<script>
export default {
  emits: ['success'],
  computed: {
    formValid() {
      return this.phoneNumber.length > 9;
    },
  },
  data() {
    return {
      loading: false,
      phoneNumber: '',
      twoFactorCode: '',
      twoFactorCodeSent: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;

      if (!this.twoFactorCodeSent) {
        // Get client and set 2fa code
        try {
          const response = await this.$axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/clients/phoneLoginCode`,
            {
              phoneNumber: this.phoneNumber,
            }
          );

          if (response.data.success) {
            this.twoFactorCodeSent = true;
          } else {
            this.$toast.error('Account not found');
          }
        } catch (e) {
          this.$toast.error('Account not found');
        }
      } else {
        // Check 2fa code
        try {
          const response = await this.$axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/clients/phoneLoginCodeCheck`,
            {
              phoneNumber: this.phoneNumber,
              loginCode: this.twoFactorCode,
            }
          );

          if (response.data.client) {
            this.$store.commit('booking/setActiveClient', response.data.client);

            this.$emit('success');
          } else {
            this.$toast.error('Error with 2FA code');
          }
        } catch (e) {
          this.$toast.error('Error with 2FA code');
        }
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  padding: 12px;
  font-size: 14px;
  border-radius: 5px;

  &-info {
    background-color: #c3edf7;
    color: #044653;
    border-color: #aceaf7;
  }
}
.login {
  padding: 32px;

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_actions {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }
  }
}
</style>
