<template>
  <form @submit.prevent="submit" class="form">
    <BaseSelect
      label="Scenario"
      :options="scenarioOptions"
      @input="offer.scenario = $event"
      :value="offer.scenario"
    />

    <BaseSelect
      label="On Page"
      :options="onPageOptions"
      @input="offer.onPage = $event"
      :value="offer.onPage"
    />

    <BaseSelect
      label="Discount"
      :options="discountOptions"
      @input="offer.discount = $event"
      :value="offer.discount"
    />

    <BaseTextarea
      label="Message To Client"
      placeholder="Additional text here.."
      @input="offer.message = $event"
      :value="offer.message"
    />

    <div class="toggle">
      <Toggle
        class="toggle_toggle"
        :checked="offer.enabled"
        @toggle="offer.enabled = !offer.enabled"
      />
      <div class="toggle_text">
        <p class="toggle_text_label">Enabled</p>
      </div>
    </div>

    <div class="form_actions">
      <BaseButton
        v-if="offer._id"
        mode="danger-outline"
        type="button"
        @click="$emit('delete')"
        >Delete</BaseButton
      >
      <BaseButton>Submit</BaseButton>
    </div>
  </form>
</template>

<script>
export default {
  emits: ['submit', 'delete'],
  props: {
    propOffer: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    if (this.propOffer) this.offer = JSON.parse(JSON.stringify(this.propOffer));
  },
  computed: {
    scenarioOptions() {
      return [
        {
          option: 'First Time Clients',
          value: 'firstTimeClient',
        },
        {
          option: 'Returning Clients',
          value: 'returningClient',
        },
        {
          option: 'All Clients (Every Time)',
          value: 'everyClient',
        },
      ];
    },
    onPageOptions() {
      return [
        {
          option: 'Landing Page',
          value: 'landing',
        },
        {
          option: 'Select Services',
          value: 'selectServices',
        },
        {
          option: 'Select Team Member',
          value: 'selectMember',
        },
        {
          option: 'Confirmation Page',
          value: 'confirmation',
        },
      ];
    },
    discountOptions() {
      const discounts = this.$store.state.auth.salon.discounts;

      const options = [];

      for (const discount of discounts) {
        options.push({
          option: discount.title,
          value: discount._id,
        });
      }

      return options;
    },
  },
  data() {
    return {
      offer: {
        scenario: 'firstTimeClient',
        onPage: 'landing',
        discount: '',
        message: '',
        enabled: true,
      },
    };
  },
  methods: {
    submit() {
      this.$emit('submit', this.offer);
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}

.toggle {
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 16px 0;

  .link {
    color: var(--clr-link);
    cursor: pointer;
  }

  &_toggle {
    min-width: 50px;
  }

  &_text {
    &_label {
      font-size: 18px;
    }
    &_subtext {
      margin-top: 5px;
      font-size: 14px;
    }
  }
}
</style>
