<template>
  <section v-if="chat" class="chat">
    <div class="chat_head">
      <div class="user">
        <Avatar :name="otherUser.name" :image="otherUser.profileImage" />
        <h2>{{ otherUser.name }}</h2>
      </div>
      <div v-if="!clientMode" class="actions">
        <!-- <i @click="showClientDetails = true" class="fas fa-cog"></i> -->
        <i @click="showClientDetails = true" class="fas fa-user"></i>
      </div>
    </div>

    <div class="chat_body">
      <ul v-if="chat.messages.length">
        <li
          v-for="(message, index) in chat.messages"
          :key="message._id"
          :class="{ isSelf: message.author.authorId === userId }"
        >
          <div class="user">
            <Avatar
              :name="message.author.name"
              :image="message.author.profileImage"
            />
          </div>
          <div class="message">
            <div class="group">
              <i
                v-if="message.author.authorId === userId && !message.deleted"
                @click="deleteMessage(index)"
                class="fas fa-trash"
              ></i>
              <p v-if="message.deleted" class="message_message italic">
                Message deleted
              </p>
              <p v-else class="message_message">{{ message.message }}</p>
            </div>
            <p class="light small">
              {{ formatDate(message.date) }}
              <span
                v-if="
                  !clientMode &&
                  message.read &&
                  message.author.authorId === userId
                "
                >- SEEN BY CLIENT</span
              >
            </p>
          </div>
        </li>
      </ul>
      <div v-else class="none">
        <div class="text">
          <h2>No messages to show.</h2>
          <p>Why not say hello!</p>
        </div>
      </div>

      <div class="newMessage">
        <form @submit.prevent="sendMessage" class="form">
          <BaseInput
            placeholder="Aa"
            :value="newMessage"
            @input="newMessage = $event"
          />
        </form>
        <i @click="sendMessage" class="fas fa-paper-plane"></i>
      </div>
    </div>
  </section>

  <div v-else class="none">
    <div class="text">
      <h2>Please Select A Chat</h2>
      <p>Select a chat to the left to view</p>
    </div>
  </div>

  <SidePanel
    :show="showClientDetails"
    :zIndex="10000000"
    @close="showClientDetails = false"
  >
    <div class="clientDetails">
      <ClientDetails
        hideActions
        :client="chat.client"
        :reference="`/dashboard/messenger?messageId=${this.chat._id}`"
      />
    </div>
  </SidePanel>
</template>

<script>
import ClientDetails from '@/components/clients/ClientDetails.vue';

export default {
  emits: ['send', 'deleteMessage', 'markRead', 'setChat'],
  components: {
    ClientDetails,
  },
  props: {
    chat: {
      type: Object,
    },
    clientMode: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.init();
    this.socketInit();
  },
  unmounted() {
    if (this.chat) {
      this.$store.dispatch('sockets/leave', this.chat._id);
    }
  },
  watch: {
    chat() {
      this.init();
      this.socketInit();
    },
  },
  computed: {
    userId() {
      if (this.clientMode) {
        if (!this.$store.state.booking.activeClient) return;
        return this.$store.state.booking.activeClient._id;
      } else {
        if (!this.$store.state.auth.salon) return;
        return this.$store.state.auth.salon._id;
      }
    },
    otherUser() {
      if (this.clientMode) {
        return this.chat.salon;
      } else {
        return this.chat.client;
      }
    },
  },
  data() {
    return {
      newMessage: '',
      showClientDetails: false,
    };
  },
  methods: {
    init() {
      if (this.chat) {
        this.$store.dispatch('sockets/join', this.chat._id);
      }

      if (this.chat && this.chat.messages && this.chat.messages.length) {
        const lastMessage = this.chat.messages[this.chat.messages.length - 1];

        if (!lastMessage.read) {
          if (
            lastMessage.author.authorId.toString() !==
            (this.clientMode
              ? this.$store.state.booking.activeClient._id.toString()
              : this.$store.state.auth.salon._id.toString())
          ) {
            this.$emit('markRead');
          }
        }
      }
    },
    socketInit() {
      if (!this.$store.state.sockets.socket) return;

      if (this.clientMode) {
        this.$store.state.sockets.socket.on('newMessage', (chat) => {
          this.$emit('setChat', chat);
        });
      }
    },
    formatDate(date) {
      return this.$moment(date).format('lll');
    },
    sendMessage() {
      if (!this.newMessage.length) return;

      this.$emit('send', this.newMessage);
      this.newMessage = '';
    },
    deleteMessage(index) {
      this.$emit('deleteMessage', index);
    },
  },
};
</script>

<style lang="scss" scoped>
.chat {
  height: 100%;
  background-color: var(--clr-white-2);
  display: flex;
  flex-direction: column;

  &_head {
    padding: 16px 32px;
    background-color: var(--clr-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .user {
      display: flex;
      gap: 16px;
      align-items: center;

      h2 {
        font-weight: 400;
      }
    }

    .actions {
      i {
        color: var(--clr-gray);
        font-size: 24px;
        cursor: pointer;
      }
    }
  }

  &_body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;

    ul {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 32px;
      overflow-y: scroll;

      li {
        display: flex;
        align-items: flex-start;
        gap: 16px;

        .message {
          .group {
            display: flex;
            align-items: center;
            gap: 16px;

            i {
              color: var(--clr-light-2);
              cursor: pointer;
              transition: color 0.2s;

              &:hover {
                color: var(--clr-gray);
              }
            }
          }

          &_message {
            padding: 12px 24px;
            border-radius: 500px;
            background-color: white;
          }

          .small {
            text-align: right;
            margin-top: 5px;
          }
          .italic {
            font-style: italic;
            background-color: var(--clr-gray) !important;
            color: white !important;
          }
        }
      }

      .isSelf {
        flex-direction: row-reverse;

        .message {
          .group {
            justify-content: flex-end;
          }

          &_message {
            background-color: var(--clr-secondary);
            color: var(--clr-white);
            text-align: right;
          }
        }
      }
    }

    .newMessage {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 32px;

      .form {
        flex-grow: 1;
      }

      i {
        color: var(--clr-secondary);
        font-size: 24px;
        cursor: pointer;
      }
    }
  }
}

.light {
  color: var(--clr-gray);
}
.small {
  font-size: 10px;
}

.none {
  padding: 16px;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: var(--clr-white-2);

  .text {
    text-align: center;

    h2 {
      font-size: 32px;
    }
    p {
      margin-top: 5px;
      color: var(--clr-gray);
    }
  }
}

.clientDetails {
  padding: 16px;
}
</style>
