<template>
  <div class="slot" :class="mode">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: "shadow p-s",
    },
  },
};
</script>

<style>
.slot {
  background-color: white;
  border-radius: 5px;
}

.shadow {
  box-shadow: var(--shadow);
}

.p-s {
  padding: 8px;
}
</style>
