<template>
  <form @submit.prevent="submit" class="form">
    <CardconnectTokenizerVue @tokenized="setTokenData($event)" />

    <div class="form">
      <BaseInput
        label="Amount"
        inputType="number"
        @input="amount = $event"
        :value="amount"
      />
      <BaseInput
        label="CVV"
        inputType="number"
        @input="cvv = $event"
        :value="cvv"
      />
      <BaseInput
        label="Postal"
        inputType="number"
        @input="postal = $event"
        :value="postal"
      />

      <div class="form_actions">
        <BaseButton :disabled="!formValid || loading"
          ><i v-if="loading" class="fas fa-spinner"></i> Submit</BaseButton
        >
      </div>
    </div>
  </form>
</template>

<script>
import CardconnectTokenizerVue from '@/components/booking/CardconnectTokenizer.vue';

export default {
  emits: ['submit'],
  components: {
    CardconnectTokenizerVue,
  },
  props: {
    preAmount: {
      type: Number,
    },
  },
  created() {
    if (this.preAmount) this.amount = this.preAmount;
  },
  data() {
    return {
      token: null,
      expiry: null,
      amount: null,
      cvv: null,
      postal: null,

      loading: false,
    };
  },
  computed: {
    formValid() {
      if (
        !this.token ||
        !this.expiry ||
        !this.amount ||
        !this.cvv ||
        !this.postal
      )
        return false;

      return true;
    },
  },
  methods: {
    setTokenData(token) {
      this.token = token.token;
      this.expiry = token.expiry;
    },
    async submit() {
      this.loading = true;

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/cardpointe/auth?merchantId=${this.$store.state.auth.salon.billing.cardconnect.mid}`,
          {
            amount: +this.amount * 100,
            expiry: this.expiry,
            account: this.token,
            postal: this.postal,
            cvv2: this.cvv,
            capture: 'Y',
          }
        );

        const data = response.data.data;

        if (data.respstat === 'A') {
          this.$toast.success(data.resptext);

          this.amount = null;
          this.cvv = null;
          this.postal = null;

          this.$emit('submit', data);
        } else if (data.respstat === 'B') {
          this.$toast.warning(data.resptext);
        } else {
          this.$toast.error(data.resptext);
        }
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: grid;
  gap: 16px;

  .light {
    margin-top: 16px;
    font-size: 14px;
    color: var(--clr-gray);
  }

  .col-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
  }

  &_actions {
    margin-top: 16px;

    .light {
      color: var(--clr-gray);
      font-size: 14px;
      margin-top: 8px;
    }
    button {
      width: 100%;
    }
  }
}
</style>
