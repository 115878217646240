<template>
  <section class="doc">
    <div class="doc_head">
      <h2>Settings</h2>
      <p class="light small">
        In this guide we will go over how to update your account settings.
      </p>
    </div>
    <div class="doc_body">
      <p class="section">
        The settings page can be navigated to by selecting the
        <i class="fas fa-cog mx"></i> icon on the sidebar.
      </p>
      <div class="section">
        <h4>Salon Info</h4>
        <p class="section">
          Salon info contains basic standard information on your establishment
          such as name, description, address, etc. One of the more important
          settings here that is crucial to verify is correct is timezone.
        </p>
      </div>
      <div class="section">
        <h4>Schedule</h4>
        <p class="section">
          From the schedule settings page you may set your establishments
          working hours. Days can be marked off completely by using the checkbox
          found on the left of the day. Days can also be split into multiple
          time sections by using the <i class="fas fa-plus mx"></i> icon found
          on the right side.
        </p>
      </div>
      <div class="section">
        <h4>Payments</h4>
        <p class="section">
          Raserva offers a variety of partnered payment processing solutions.
          You will have to enter unique data depending on which option your
          establishment is using. Standard across all solutions is your salons
          default tax rate, which will be applied to all new services and/or
          products (but may be adjusted individually on each service/product
          page), and enabling cash discount. If cash discount is enabled, you
          will then be prompted to enter in your non-cash additional processing
          fee.
        </p>
      </div>
      <div class="section">
        <h4>Permissions</h4>
        <p class="section">
          <span class="bold">Important!</span> Updating your salons permissions
          is a crucial step of the salon setup process. If these settings are
          not appropriate to your needs, team members may be able to access and
          update data that is confidential. Please go through and update
          permission levels accordingly. <br />
          <br />
          Every team member has a "Role" you may update to your liking, in your
          permission settings you are able to select which roles may perform
          which actions. Here you will find a section titled "Require Code",
          this will require a staff code to be entered in to perform given
          action. Actions that require codes are recorded in the "Actions"
          section under the "Team" tab.
        </p>
      </div>
      <div class="section">
        <h4>Booking Settings</h4>
        <p class="section">
          With booking settings you can provide the experience you want to your
          clients when they are booking online. Options include allowing same
          day bookings, minimum book time in hours, maximum book time in days,
          and more.
        </p>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.doc {
  padding-bottom: 64px;

  p {
    line-height: 24px;
  }
  .light {
    margin-top: 8px;
    color: var(--clr-gray);
  }
  .small {
    font-size: 14px;
  }
  .link {
    text-decoration: underline;
    color: var(--clr-link);
    cursor: pointer;
  }
  .mx {
    margin: 0px 8px 0px 8px;
  }
  .italic {
    font-style: italic;
  }
  .bold {
    font-weight: 700;
  }

  &_head {
    h2 {
      font-size: 32px;
    }
  }

  &_body {
    margin-top: 32px;

    .section:not(:first-child) {
      margin-top: 16px;
    }

    .next {
      margin-top: 32px;

      h3 {
        margin-bottom: 16px;
      }

      li:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
}
</style>
