<template>
  <form @submit.prevent="submit" class="form">
    <BaseInput
      label="Title"
      @input="newCategory.title = $event"
      :value="newCategory.title"
    />

    <div class="toggle">
      <Toggle
        :checked="newCategory.favorite"
        @toggle="newCategory.favorite = !newCategory.favorite"
      />
      <div class="toggle_text">
        <p class="toggle_text_label">Favorite</p>
        <p class="toggle_text_subtext">Displays this category before others</p>
      </div>
    </div>

    <div class="form_actions">
      <BaseButton
        v-if="newCategory._id"
        @click="deleteCategory"
        type="button"
        mode="danger-outline"
        :disabled="loading"
        ><i v-if="loading" class="fas fa-spinner"></i> Delete</BaseButton
      >
      <BaseButton :disabled="loading"
        ><i v-if="loading" class="fas fa-spinner"></i> Submit</BaseButton
      >
    </div>
  </form>
</template>

<script>
export default {
  emits: ['update'],
  props: {
    category: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    if (this.category)
      this.newCategory = JSON.parse(JSON.stringify(this.category));
  },
  data() {
    return {
      loading: false,

      newCategory: {
        title: '',
        favorite: false,
      },
    };
  },
  methods: {
    async deleteCategory() {
      // Delete
      const response = await this.$axios.delete(
        `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/serviceParentCategory/${this.newCategory._id}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.auth.token}`,
          },
        }
      );

      this.$toast.success('Category deleted');

      this.$emit('update', response.data.serviceParentCategories);
    },

    async submit() {
      this.loading = true;

      try {
        if (this.newCategory._id) {
          // Edit
          const response = await this.$axios.put(
            `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/serviceParentCategory/${this.newCategory._id}`,
            this.newCategory,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );

          this.$toast.success('Category updated successfully');

          this.$emit('update', response.data.serviceParentCategories);
        } else {
          // Create
          const response = await this.$axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/serviceParentCategory`,
            this.newCategory,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );

          this.$toast.success('Category added successfully');

          this.$emit('update', response.data.serviceParentCategories);
        }
      } catch (err) {
        console.log(err);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle {
  display: flex;
  align-items: center;
  gap: 32px;
  flex-wrap: wrap;
  padding: 16px 0;

  &_text {
    &_label {
      font-size: 18px;
    }
    &_subtext {
      margin-top: 5px;
      font-size: 14px;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &_actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
