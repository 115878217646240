<template>
  <section v-if="admin" class="home">
    <div class="home_content">
      <div class="head-text">
        <h1>Welcome, {{ `${admin.firstName} ${admin.lastName}` }}!</h1>
        <p>
          “It's the questions we can't answer that teach us the most. They teach
          us how to think. If you give a man an answer, all he gains is a little
          fact. But give him a question and he'll look for his own answers.”
          <br />
          - Patrick Rothfuss
        </p>
      </div>

      <ul>
        <router-link
          v-if="activeUserHasPerm('team')"
          :to="{ name: 'AdminTeam' }"
        >
          <li>
            <div class="text">
              <h2>Team</h2>
              <p>View/manage admin team members</p>
            </div>

            <i class="fas fa-users"></i>
          </li>
        </router-link>

        <router-link
          v-if="activeUserHasPerm('users')"
          :to="{ name: 'AdminUsers' }"
        >
          <li>
            <div class="text">
              <h2>Salons</h2>
              <p>View/manage salons</p>
            </div>

            <i class="fas fa-building"></i>
          </li>
        </router-link>

        <router-link
          v-if="activeUserHasPerm('staffAccounts')"
          :to="{ name: 'AdminStaffAccounts' }"
        >
          <li>
            <div class="text">
              <h2>Staff</h2>
              <p>View/manage staff accounts</p>
            </div>

            <i class="fas fa-users"></i>
          </li>
        </router-link>

        <router-link
          v-if="activeUserHasPerm('tickets')"
          :to="{ name: 'AdminTickets' }"
        >
          <li>
            <div class="text">
              <h2>Tickets</h2>
              <p>View/edit salons tickets</p>
            </div>

            <i class="fas fa-receipt"></i>
          </li>
        </router-link>

        <router-link
          v-if="activeUserHasPerm('logs')"
          :to="{ name: 'AdminLogs' }"
        >
          <li>
            <div class="text">
              <h2>Logs</h2>
              <p>View logs</p>
            </div>

            <i class="fas fa-table"></i>
          </li>
        </router-link>

        <router-link
          v-if="activeUserHasPerm('analytics')"
          :to="{ name: 'AdminAnalytics' }"
        >
          <li>
            <div class="text">
              <h2>Analytics</h2>
              <p>View analytics</p>
            </div>

            <i class="fas fa-database"></i>
          </li>
        </router-link>

        <router-link
          v-if="activeUserHasPerm('support')"
          :to="{ name: 'AdminSupport' }"
        >
          <li>
            <div class="text">
              <h2>Support</h2>
              <p>Respond to support tickets</p>
            </div>

            <i class="fas fa-ticket-alt"></i>
          </li>
        </router-link>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    admin() {
      return this.$store.state.admin.admin;
    },
  },
  methods: {
    activeUserHasPerm(perm) {
      if (!this.admin) return false;

      return this.admin.permissions.includes(perm);
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  min-height: 100%;
  margin: 0 auto;
  padding-top: 120px;
  background-color: var(--clr-white);

  .head-text {
    text-align: center;
    margin-bottom: 48px;

    h1 {
      text-align: center;
      font-size: 32px;
    }

    p {
      margin: 0 auto;
      margin-top: 16px;
      color: var(--clr-gray);
      max-width: 600px;
      line-height: 24px;
    }
  }

  ul {
    max-width: 1000px;
    margin: auto;
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
    justify-content: center;

    li {
      height: 100%;
      text-align: center;
      padding: 16px;
      border-radius: 5px;
      max-width: 250px;
      box-shadow: var(--shadow);
      cursor: pointer;
      background-color: white;
      width: 200px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h2 {
        font-size: 32px;
        font-weight: 400;
      }

      p {
        color: var(--clr-gray);
        margin-top: 8px;
      }

      i {
        font-size: 72px;
        margin-top: 16px;
        color: var(--clr-secondary);
      }
    }
  }
}

// Mobile
@media (max-width: 600px) {
  .home {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}
</style>
