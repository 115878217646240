<template>
  <section class="details" v-if="fullClient">
    <div class="details_head">
      <div class="avatar">
        <Avatar mode="large rounded" :name="fullClient.firstName" />
        <div class="avatar_text">
          <h2>{{ clientName }}</h2>
          <p v-if="showClientContactInfo" class="small">
            {{ fullClient.phoneNumber }}
          </p>
          <p v-if="showClientContactInfo" class="small">
            {{ fullClient.email }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="fullClient.globalClient" class="raservaAccount">
      <p>
        Raserva account clients may not be edited by salons, you can however
        view client history with your salon.
      </p>
    </div>
    <div v-if="!hideActions" class="details_actions">
      <div
        v-if="!fullClient.globalClient"
        class="action"
        @click="$emit('edit')"
      >
        <i class="fas fa-edit"></i>
        <p>Edit</p>
      </div>
      <div class="action" @click="$emit('book')">
        <i class="fas fa-calendar-plus"></i>
        <p>Book</p>
      </div>
      <div class="action" @click="$emit('sell')">
        <i class="fas fa-cart-plus"></i>
        <p>Sell</p>
      </div>
      <div
        class="moreDropdownParent"
        @click="showMoreOptions = !showMoreOptions"
      >
        <div class="action">
          <i class="fas fa-ellipsis-h"></i>
          <p>More</p>
        </div>
        <Tooltip
          v-if="showMoreOptions"
          mode="bottom right"
          @hide="showMoreOptions = false"
        >
          <ul class="moreDropdown">
            <li @click="showNotes = true">View Notes</li>
            <li v-if="canViewFormulas" @click="showFormulas = true">
              View Formulas
            </li>
            <li v-if="!fullClient.globalClient" @click="addClientToMergeList">
              Merge
            </li>
            <li class="danger" v-if="isBlacklisted" @click="unblacklistClient">
              Un-Blacklist Client
            </li>
            <li class="danger" v-else @click="blacklistClient">
              Blacklist Client
            </li>
          </ul>
        </Tooltip>
      </div>
    </div>
    <div class="details_stats">
      <div class="stat">
        <p class="bold">{{ ticketsTotal }}</p>
        <p class="gray">Total sales</p>
      </div>
      <div class="stat">
        <p class="bold">{{ fullClient.tickets.length }}</p>
        <p class="gray">Total visits</p>
      </div>
      <div class="stat">
        <p class="bold">{{ fullClient.tickets.length }}</p>
        <p class="gray">Completed</p>
      </div>
      <div class="stat">
        <p class="bold">{{ fullClient.appointmentNoShows.length }}</p>
        <p class="gray">No-show</p>
      </div>
      <div v-if="clientLoyalty" class="stat">
        <p class="bold">{{ clientLoyalty.points }}</p>
        <p class="gray">Loyalty points</p>
      </div>
    </div>
    <div class="details_flex">
      <p
        v-if="fullClient.tickets && fullClient.tickets.length"
        class="bold small flexTitle"
      >
        Tickets
      </p>
      <div
        v-for="ticket in fullClient.tickets"
        :key="ticket._id"
        class="ticketParent"
      >
        <div class="ticketParent_ticket" @click="selectTicket(ticket)">
          <p class="bold">Ticket for {{ getTicketTotal(ticket) }}</p>
          <p class="small light">{{ formatDate(ticket.checkout_at, true) }}</p>
        </div>

        <div
          v-if="!hideActions"
          class="action"
          @click="recreateTicketAsAppointment(ticket)"
        >
          <i class="fas fa-calendar-plus"></i>
          <p>Rebook</p>
        </div>
      </div>

      <p v-if="clientThisSalonsPackages.length" class="bold small flexTitle">
        Packages
      </p>
      <div
        class="package"
        v-for="pack in clientThisSalonsPackages"
        :key="pack._id"
        @click="selectedPackage = pack"
      >
        <p class="bold">{{ pack.title }}</p>
        <p class="small light">Items: {{ pack.items.length }}</p>
      </div>
    </div>
  </section>

  <Modal
    :show="selectedPackage"
    @close="selectedPackage = null"
    title="Package Form"
  >
    <div class="packagesForm">
      <PackageForm
        :client="fullClient"
        :package="selectedPackage"
        @submitted="selectedPackage = null"
      />
    </div>
  </Modal>

  <Modal title="Note logs" :show="showNotes" @close="showNotes = false">
    <div class="noteLogs">
      <ClientNoteLogs :client="client" />
    </div>
  </Modal>

  <Modal
    title="Ticket Details"
    :show="showTicketDetails"
    @close="showTicketDetails = false"
    zIndex="9999999999999999999999999999999999"
  >
    <div class="noteLogs">
      <TicketDetails
        :ticket="selectedTicket"
        @hide="selectedTicket = null"
        hideActions
      />
      <div class="actions">
        <BaseButton @click="selectTicket(selectedTicket, true)"
          >View ticket</BaseButton
        >
      </div>
    </div>
  </Modal>

  <Modal
    title="Formula logs"
    :show="showFormulas"
    @close="showFormulas = false"
  >
    <div class="noteLogs">
      <ClientFormulas :client="client" />
    </div>
  </Modal>
</template>

<script>
import PackageForm from '@/components/items/PackageForm.vue';
import ClientNoteLogs from '@/components/clients/ClientNoteLogs.vue';
import ClientFormulas from '@/components/clients/ClientFormulas.vue';
import TicketDetails from '@/components/tickets/TicketDetails.vue';

export default {
  emits: ['book', 'sell', 'edit'],
  components: {
    PackageForm,
    ClientNoteLogs,
    ClientFormulas,
    TicketDetails,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    reference: {
      type: String,
      required: false,
    },
  },
  created() {
    this.init();
  },
  data() {
    return {
      fullClient: null,
      selectedPackage: null,
      showClientContactInfo: false,
      showMoreOptions: false,
      showNotes: false,
      showFormulas: false,
      canViewFormulas: false,

      selectedTicket: null,
      showTicketDetails: false,

      clientLoyalty: null,
    };
  },
  computed: {
    loyaltyEnabled() {
      return this.$store.state.auth.salon.loyalty.enabled;
    },
    clientName() {
      return `${this.fullClient.firstName} ${this.fullClient.lastName || ''}`;
    },
    clientThisSalonsPackages() {
      let arr = [];

      if (this.fullClient && this.fullClient.packages) {
        this.fullClient.packages.forEach((pack) => {
          if (pack.salon.salonId === this.$store.state.auth.salon._id)
            arr.push(pack);
        });
      }

      return arr;
    },
    ticketsTotal() {
      if (!this.fullClient) return;

      let total = 0;

      this.fullClient.tickets.forEach((ticket) => {
        ticket.items.forEach((item) => {
          total += item.item.price * item.quantity;
        });
      });

      return `$${total.toFixed(2)}`;
    },
    isBlacklisted() {
      if (!this.fullClient.blacklistedSalons) return false;

      return this.fullClient.blacklistedSalons.includes(
        this.$store.state.auth.salon._id
      );
    },
  },
  methods: {
    async init() {
      try {
        this.fullClient = await this.$store.dispatch(
          'clients/getClientById',
          this.client.clientId || this.client._id
        );

        this.fullClient.tickets = this.fullClient.tickets.filter(
          (ticket) => ticket.salonId === this.$store.state.auth.salon._id
        );

        this.showClientContactInfo = await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'staff/viewClientContactInfo'
        );

        if (
          await this.$store.dispatch(
            'auth/activeUserHasPermission',
            'staff/canViewFormulas'
          )
        ) {
          this.canViewFormulas = true;
        }
      } catch (error) {
        console.log(error);
      }

      if (this.loyaltyEnabled) {
        this.clientLoyalty = this.client.loyalty.find(
          (l) =>
            l.salonId.toString() === this.$store.state.auth.salon._id.toString()
        );
      }
    },
    getTicketTotal(ticket) {
      let total = 0;

      ticket.items.forEach(
        (item) => (total += item.item.price * item.quantity)
      );

      return `$${total.toFixed(2)}`;
    },
    formatDate(date, long) {
      if (long) return this.$moment(date).format('LLL');

      return this.$moment(date).format('LL');
    },
    addClientToMergeList() {
      this.$store.state.clients.clientsToMerge.push(this.fullClient);
    },
    async selectTicket(ticket, navigate) {
      if (navigate) {
        this.$router.push({
          name: 'SalesTickets',
          query: {
            start: this.$moment(ticket.checkout_at).startOf('day').toDate(),
            end: this.$moment(ticket.checkout_at).endOf('day').toDate(),
            number: ticket.number,
            ref:
              this.reference ||
              `/dashboard/clients/list?name=${this.fullClient.firstName} ${this.fullClient.lastName}`,
            bypassFirst: true,
          },
        });
      } else {
        this.selectedTicket = ticket;
        this.showTicketDetails = true;
      }
    },
    recreateTicketAsAppointment(ticket) {
      const appointment = {
        services: [],
      };

      ticket.items.forEach((item) => {
        if (!item.item.duration) return;

        let start = this.$moment().hour(12).minute(0);

        if (appointment.services.length) {
          start = start.add(
            appointment.services[appointment.services.length - 1].service
              .duration,
            'minutes'
          );
        }

        start = start.format('kk:mm');

        appointment.services.push({
          start,
          service: item.item,
          duration: item.item.duration,
          staff: item.staff,
          requested: true,
        });
      });

      this.$emit('book', appointment);
    },

    blacklistClient() {
      const index = this.fullClient.blacklistedSalons.findIndex(
        (id) => id === this.$store.state.auth.salon._id
      );

      if (index !== -1) return;

      this.fullClient.blacklistedSalons.push(this.$store.state.auth.salon._id);

      this.editClient();
    },
    unblacklistClient() {
      const index = this.fullClient.blacklistedSalons.findIndex(
        (id) => id === this.$store.state.auth.salon._id
      );

      if (index === -1) return;

      this.fullClient.blacklistedSalons.splice(index, 1);

      this.editClient();
    },
    async editClient() {
      try {
        await this.$store.dispatch('clients/editClient', {
          ...this.fullClient,
          salonIdToUpdateFutureAppointments: this.$store.state.auth.salon._id,
        });

        this.$emit('clientUpdated', this.fullClient);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;

  &_head {
    .avatar {
      display: flex;
      align-items: center;
      gap: 32px;

      &_text {
        h2 {
          margin-bottom: 5px;
        }

        .small {
          font-size: 12px;
          color: var(--clr-link);
        }
      }
    }
  }

  .moreDropdownParent {
    position: relative;
  }
  .moreDropdown {
    color: var(--clr-black);
    background-color: white;
    border: 1px solid var(--clr-light);
    border-radius: 5px;

    li {
      padding: 12px 16px;
      font-weight: 400;
      text-align: left;
      font-size: 15px;
      transition: background-color 0.2s;
      cursor: pointer;

      &:hover {
        background-color: var(--clr-extra-light);
      }
    }

    .danger {
      color: var(--clr-danger);
    }
  }

  .raservaAccount {
    padding: 16px;
    border: 1px solid var(--clr-secondary);
    border-radius: 5px;
    background-color: var(--clr-secondary-white);
    color: var(--clr-secondary-dark);
  }

  &_actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;

    .action {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      padding: 8px;
      width: 80px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      transition: border-color 0.2s ease;
      cursor: pointer;

      i {
        color: var(--clr-gray);
        font-size: 24px;
      }

      &:hover {
        border-color: var(--clr-gray);
      }
    }
  }
  &_stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;

    .stat {
      .bold {
        font-weight: 700;
      }
    }
  }
  .ticketParent {
    display: flex;
    gap: 16px;
    margin-top: 16px;

    &_ticket {
      flex-grow: 1;
      padding: 16px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      transition: border-color 0.2s ease;
      cursor: pointer;

      &:not(:first-child) {
        margin-top: 16px;
      }

      &:hover {
        border-color: var(--clr-light-2);
      }
      .light {
        color: var(--clr-gray);
      }
    }

    .action {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      padding: 8px;
      width: 80px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      transition: border-color 0.2s ease;
      cursor: pointer;

      i {
        color: var(--clr-gray);
        font-size: 24px;
      }

      &:hover {
        border-color: var(--clr-gray);
      }
    }
  }

  &_flex {
    flex-grow: 1;
    // border: 1px solid var(--clr-light);
    // border-radius: 5px;

    .bold {
      font-weight: 700;
    }
    .small {
      margin-top: 5px;
      font-size: 14px;
    }
    .flexTitle {
      text-align: center;
      margin-top: 16px;
    }

    .ticket_ticket {
      display: flex;
      gap: 32px;
    }

    .ticket,
    .package {
      padding: 16px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      transition: border-color 0.2s ease;
      cursor: pointer;

      &:not(:first-child) {
        margin-top: 16px;
      }

      &:hover {
        border-color: var(--clr-light-2);
      }
      .light {
        color: var(--clr-gray);
      }
    }
  }
}

.noteLogs {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .actions {
    text-align: right;
  }
}

.packagesForm {
  padding: 32px;
}
</style>
