<template>
  <div class="announcement mobile">
    <router-link
      v-if="activeClient"
      :to="{
        name: 'ClientProfile',
        query: { returnSrc: this.$router.currentRoute.value.fullPath },
      }"
    >
      <p>My Profile <i class="fas fa-hand-point-right ml-s"></i></p>
    </router-link>
  </div>
  <div v-if="activeClient && !activeClientIsOnMarketing" class="announcement">
    <p>
      <span @click="enableMarketing" class="link">Click here</span> to enable
      marketing notifications to receive discounts, coupons, and more!
    </p>
  </div>

  <header class="header">
    <div class="col logo-search">
      <TheLogo />

      <!-- <div class="search">
        <BaseInput placeholder="Search for a venue" icon="fas fa-search" />
      </div> -->

      <router-link
        v-if="activeClient"
        :to="{
          name: 'ClientProfile',
          query: { returnSrc: this.$router.currentRoute.value.fullPath },
        }"
        class="desktop"
      >
        <BaseButton mode="primary-outline">My Profile</BaseButton>
      </router-link>
    </div>
    <div class="col client-info">
      <router-link
        v-if="!activeClient"
        :to="{
          name: 'ClientAuth',
          query: { source: this.$router.currentRoute.value.fullPath },
        }"
      >
        <BaseButton mode="primary-outline small">Sign in</BaseButton>
      </router-link>
      <BaseButton
        v-if="returnSrc"
        @click="goToReturnSrc"
        mode="danger-outline small"
        ><i class="fas fa-backward"></i> Back</BaseButton
      >

      <div v-if="activeClient" class="client">
        <div class="welcome" @click="showClientDropdown = true">
          <p class="small">Welcome,</p>
          <p class="link">
            {{ activeClient.firstName }} {{ activeClient.lastName }}
          </p>
        </div>
        <Avatar
          :name="activeClient.firstName"
          @click="showClientDropdown = true"
        />

        <Tooltip
          mode="bottom right"
          width="250px"
          v-if="showClientDropdown"
          @hide="showClientDropdown = false"
        >
          <ul>
            <router-link :to="{ name: 'ClientProfile' }">
              <li>
                <p>Profile</p>
              </li>
            </router-link>
            <li @click="logout">
              <p>Log out</p>
            </li>
          </ul>
        </Tooltip>
      </div>
    </div>
  </header>

  <div v-if="currentBookingTracking" class="sendReport">
    <div v-if="showSendBugReportCTA" class="sendReport-cta">
      <div
        @click="
          showSendBugReportCTA = false;
          showSendBugReportCTAClosed = true;
        "
        class="sendReport-cta-exit"
      >
        <i class="fas fa-times"></i>
      </div>

      <p @click="showSendBugReport = !showSendBugReport">
        Having issues? Report here.
      </p>
    </div>

    <div
      @click="showSendBugReport = !showSendBugReport"
      class="sendReport-button"
    >
      <i class="fas fa-bug"></i>
    </div>
  </div>

  <Modal
    title="Send Bug Report"
    :show="showSendBugReport === true"
    @close="showSendBugReport = false"
  >
    <form @submit.prevent="sendBugReport" class="bugReport">
      <h2>Having issues? Please describe your problem below:</h2>

      <BaseTextarea
        placeholder="Enter issue here.."
        @input="bugReportText = $event"
      />

      <div class="bugReport_actions">
        <BaseButton
          type="button"
          @click="showSendBugReport = false"
          mode="danger-outline"
          >Cancel</BaseButton
        >
        <BaseButton mode="primary" :disabled="bugReportText.length < 1"
          >Send Report</BaseButton
        >
      </div>
    </form>
  </Modal>

  <Modal
    title="Special Offer!"
    maxWidth="800px"
    :show="offerToShow && !closedOffer"
    @close="
      offerToShow = null
      // closedOffer = true;
    "
  >
    <div class="offerModal">
      <MarketingOffer
        :offer="offerToShow"
        @yes="applyOffer"
        @no="
          offerToShow = null;
          closedOffer = true;
        "
      />
    </div>
  </Modal>
</template>

<script>
import MarketingOffer from '@/components/booking/MarketingOffer.vue';

export default {
  components: {
    MarketingOffer,
  },
  computed: {
    currentBookingTracking() {
      return this.$store.state.booking.currentBookingTracking;
    },
    activeClient() {
      return this.$store.state.booking.activeClient;
    },
    activeClientIsOnMarketing() {
      if (!this.activeClient) return false;

      return (
        this.activeClient.notifications.marketing &&
        this.activeClient.notifications.email &&
        this.activeClient.notifications.text
      );
    },
    returnSrc() {
      return this.$router.currentRoute.value.query.returnSrc;
    },
    currentSteps() {
      return this.$store.state.booking.currentBookingTracking;
    },
    salon() {
      return this.$store.state.booking.salon;
    },
    coupon() {
      return this.$store.state.booking.coupon;
    },
    offers() {
      if (!this.salon) return [];

      return this.salon.bookingMarketingOffers.filter((offer) => offer.enabled);
    },
  },
  watch: {
    currentSteps(val) {
      if (val?.steps?.length >= 10) {
        if (!this.showSendBugReportCTAClosed) {
          this.showSendBugReportCTA = true;
        }
      }
    },
    $route(to) {
      if (!this.offers.length) return;
      if (this.coupon) return;

      const selectServicesOffer = this.offers.find(
        (offer) => offer.onPage === 'selectServices'
      );
      const selectMemberOffer = this.offers.find(
        (offer) => offer.onPage === 'selectMember'
      );
      const confirmationOffer = this.offers.find(
        (offer) => offer.onPage === 'confirmation'
      );

      if (to.fullPath.includes('services')) {
        setTimeout(() => {
          this.checkOfferRules(selectServicesOffer);
        }, 500);
      } else if (to.fullPath.includes('members')) {
        setTimeout(() => {
          this.checkOfferRules(selectMemberOffer);
        }, 500);
      } else if (to.fullPath.includes('confirm')) {
        setTimeout(() => {
          this.checkOfferRules(confirmationOffer);
        }, 500);
      }
    },
  },
  data() {
    return {
      showClientDropdown: false,
      showSendBugReport: false,
      showSendBugReportCTA: false,
      showSendBugReportCTAClosed: false,
      bugReportText: '',

      offerToShow: null,
      closedOffer: false,
    };
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/clientLogout');
      this.$router.push({ name: 'ClientAuth' });
    },

    async enableMarketing() {
      try {
        const client = await this.$store.dispatch('clients/editClient', {
          _id: this.activeClient._id,
          notifications: {
            email: true,
            text: true,
            marketing: true,
          },
        });

        this.$store.state.booking.activeClient.notifications.email =
          client.notifications.email;
        this.$store.state.booking.activeClient.notifications.text =
          client.notifications.text;
        this.$store.state.booking.activeClient.notifications.marketing =
          client.notifications.marketing;

        this.$toast.success('Marketing notifications enabled!');
      } catch (e) {
        console.log(e);
      }
    },

    goToReturnSrc() {
      this.$store.dispatch('booking/setDataToSavedData');
      this.$router.push(this.returnSrc);
    },

    sendBugReport() {
      try {
        if (this.bugReportText.length < 1)
          return this.$toast.error('Please enter a bug report');
        if (this.bugReportText.length > 1000)
          return this.$toast.error('Max character of 1000 limit exceeded');

        this.$store.dispatch('booking/sendBugReport', {
          text: this.bugReportText,
        });

        this.$toast.success('Bug report submitted!');

        this.showSendBugReport = false;
        this.bugReportText = '';
      } catch (err) {
        this.$toast.error(err.message);
      }
    },

    applyOffer() {
      this.$store.state.booking.isSalonDiscount = true;
      this.$store.state.booking.coupon = this.offerToShow.discount;
      this.offerToShow = null;

      this.$toast.success('Offer applied!');
    },

    checkOfferRules(offer) {
      // If offer is null, return
      if (!offer) return;

      // If offer required client and no client, return
      if (
        (offer.scenario === 'firstTimeClient' ||
          offer.scenario === 'returningClient') &&
        !this.activeClient
      )
        return;

      // If offer required client to be first time client and they are returning, return
      if (
        offer.scenario === 'firstTimeClient' &&
        this.activeClient.tickets.find(
          (t) => t.salonId.toString() === this.salon._id.toString()
        )
      )
        return;

      // If offer is only for returning clients and they are a first time client, return
      if (
        offer.scenario === 'returningClient' &&
        !this.activeClient.tickets.find(
          (t) => t.salonId.toString() === this.salon._id.toString()
        )
      )
        return;

      // If all rules are met, show offer
      this.offerToShow = offer;
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  display: none;
}

.ml-s {
  margin-left: 8px;
}

.danger-outline {
  i {
    margin-right: 8px;
  }
}
.announcement {
  text-align: center;
  background-color: var(--clr-secondary);
  color: white;
  font-weight: 700;
  padding: 8px 0;

  .link {
    text-decoration: underline;
    cursor: pointer;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 65px;
  padding: 8px 24px;
  border-bottom: 1px solid var(--clr-light);
  background-color: white;

  .logo-search {
    display: flex;
    align-items: center;
    gap: 32px;

    h1 {
      font-size: 24px;
      text-transform: uppercase;
      letter-spacing: 5px;
    }
  }
  .client-info {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .client {
    display: flex;
    align-items: center;
    gap: 16px;
    text-align: right;
    position: relative;

    .small {
      font-size: 12px;
    }
    .link {
      color: var(--clr-link);
      cursor: pointer;
    }

    ul {
      background-color: white;

      li {
        padding: 16px 32px;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
          background-color: var(--clr-white);
        }
      }
    }
  }
}

.sendReport {
  position: fixed;
  bottom: 100px;
  right: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &-cta {
    background-color: var(--clr-white);
    color: var(--clr-danger);
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--clr-danger);
    box-shadow: var(--shadow-large);
    display: flex;
    align-items: center;
    gap: 16px;
    z-index: 1000;
    margin-bottom: 16px;

    &-exit {
      font-size: 12px;
      position: absolute;
      top: -10px;
      right: -10px;
      height: 25px;
      width: 25px;
      padding: 8px;
      border-radius: 50%;
      background-color: var(--clr-danger);
      color: var(--clr-white);
      display: grid;
      place-items: center;
      box-shadow: var(--shadow);
    }
  }

  &-button {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: var(--clr-danger);
    color: var(--clr-white);
    display: grid;
    place-items: center;
    box-shadow: var(--shadow);
  }
}

.bugReport {
  padding: 32px;

  h2 {
    margin-bottom: 16px;
  }

  &_actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}

.offerModal {
  padding: 32px;

  .message {
    font-size: 24px;
  }

  &_actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}

// Tablet
@media (max-width: 900px) {
  .logo-search {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    gap: 8px !important;
  }
  .search {
    display: none;
  }
  .client {
    .welcome {
      display: none;
    }
  }
}

// Mobile
@media (max-width: 600px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
</style>
