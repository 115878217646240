<template>
  <section class="newClient" v-if="salon">
    <div class="visual">
      <p class="vary">* Email may differ slightly visually</p>
      <div class="newClient_visual">
        <div class="email_shop">
          <p class="name">{{ salon.details.shopName }}</p>
          <p class="address">
            {{
              `${salon.details.address.street}, ${salon.details.address.city}`
            }}
          </p>
        </div>
        <div class="email_image">
          <img src="@/assets/email/welcome.jpg" alt="" />
        </div>
        <div class="email_body">
          <p class="headText">{{ campaign.headText }}</p>
          <p class="bodyText">{{ campaign.bodyText }}</p>
        </div>
        <div class="email_discount">
          <p class="discount" v-if="campaign.discount">
            <span v-if="campaign.discount.type === 'fixed'">$</span
            >{{ campaign.discount.amount
            }}<span v-if="campaign.discount.type === 'percent'">%</span> Off
          </p>
          <p class="discount" v-else>[Select a discount]</p>
          <p class="next">For your next appointment!</p>
          <BaseButton mode="secondary">BOOK NOW</BaseButton>
        </div>
      </div>
    </div>
    <div class="newClient_details">
      <form @submit.prevent="submit" class="form">
        <div class="toggle">
          <Toggle
            :checked="campaign.enabled"
            @toggle="campaign.enabled = !campaign.enabled"
          />
          <div class="toggle_text">
            <p class="toggle_text_label">Campaign Enabled</p>
            <p @click="showPricing = true" class="toggle_text_subtext link">
              View pricing
            </p>
          </div>
        </div>
        <BaseInput
          label="Email subject"
          :value="campaign.subject"
          @input="campaign.subject = $event"
        />
        <BaseInput
          label="Head text"
          :value="campaign.headText"
          @input="campaign.headText = $event"
        />
        <BaseTextarea
          label="Body text"
          :value="campaign.bodyText"
          @input="campaign.bodyText = $event"
        />
        <BaseSelect
          label="Discount"
          :options="discountOptions"
          :value="campaign.discount ? campaign.discount.discountId : null"
          @input="selectDiscount($event)"
          subtitle="To create or edit a discount navigate to Items > Discounts"
        />
        <div class="form_actions">
          <BaseButton :disabled="!formValid">Submit</BaseButton>
        </div>
      </form>
    </div>
  </section>

  <Modal :show="showPricing" @close="showPricing = false">
    <MarketingPrices />
  </Modal>
</template>

<script>
import MarketingPrices from '@/components/discounts/MarketingPrices.vue';

export default {
  emits: ['updated'],
  components: {
    MarketingPrices,
  },
  created() {
    this.campaign = this.$store.state.auth.salon.marketing.campaigns.newClient;
  },
  computed: {
    formValid() {
      if (
        !this.campaign.headText ||
        !this.campaign.bodyText ||
        !this.campaign.subject ||
        !this.campaign.discount
      )
        return false;

      return true;
    },
    salon() {
      return this.$store.state.auth.salon;
    },
    discountOptions() {
      const options = [];

      this.salon.discounts.forEach((discount) => {
        options.push({
          option: discount.title,
          value: discount._id,
        });
      });

      return options;
    },
  },
  data() {
    return {
      showPricing: false,

      campaign: {
        enabled: false,
        subject: '',
        headText: '',
        bodyText: '',
        discount: null,
      },
    };
  },
  methods: {
    selectDiscount(discountId) {
      this.campaign.discount = this.salon.discounts.find(
        (discount) => discount._id === discountId
      );
    },
    async submit() {
      try {
        await this.$store.dispatch('salon/updateNewClientCampaign', {
          ...this.campaign,
          discount: {
            title: this.campaign.discount.title,
            discountId: this.campaign.discount.discountId
              ? this.campaign.discount.discountId
              : this.campaign.discount._id,
            type: this.campaign.discount.type,
            amount: +this.campaign.discount.amount,
          },
        });

        this.$toast.success('Campaign updated');
        this.$emit('updated');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.newClient {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 64px;

  .toggle {
    display: flex;
    align-items: center;
    gap: 32px;
    flex-wrap: wrap;
    padding: 16px 0;

    &_text {
      &_label {
        font-size: 18px;
      }
      &_subtext {
        margin-top: 5px;
        font-size: 14px;
      }

      .link {
        color: var(--clr-link);
        cursor: pointer;
      }
    }
  }

  .vary {
    font-size: 14px;
    margin-bottom: 16px;
  }

  &_visual {
    max-width: 600px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;
    padding: 32px;
    box-shadow: var(--shadow-large);

    .email {
      &_shop {
        .name {
          font-size: 24px;
        }
        .address {
          color: #a7a4a4;
          font-size: 14px;
          margin-top: 5px;
        }
      }

      &_image {
        display: grid;
        place-items: center;
        margin: 48px 0;

        img {
          height: 125px;
        }
      }

      &_body {
        .headText {
          font-size: 36px;
        }
        .bodyText {
          margin-top: 32px;
          font-size: 18px;
          color: #969594;
          line-height: 24px;
        }
      }

      &_discount {
        margin-top: 32px;
        border-top: 1px solid var(--clr-light);
        padding-top: 32px;
        text-align: center;

        .discount {
          font-size: 36px;
        }

        .next {
          margin-top: 5px;
        }

        button {
          margin-top: 32px;
        }
      }
    }
  }

  &_details {
    .form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &_actions {
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;
        gap: 16px;
      }
    }
  }
}

// Tablet
@media (max-width: 900px) {
  .newClient {
    grid-template-columns: 1fr;
  }
}
</style>
