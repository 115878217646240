<template>
  <form @submit.prevent="submitPayment" class="form">
    <BaseInput
      v-if="!showAdditionalOptions"
      label="Amount"
      :value="amount"
      @input="total = +$event"
    />

    <BaseSelect
      label="Terminal"
      :value="selectedTerminal?.registerID"
      :options="allTerminalOptions"
      @input="selectTerminal($event)"
    />

    <BaseSelect
      label="Receipt Option"
      :value="receiptOption"
      @input="receiptOption = $event"
      :options="receiptOptions"
    />

    <!-- Additional options -->
    <div
      class="additional"
      @click="
        !dejavooPaymentFormLink
          ? getDejavooPaymentFormLink()
          : (dejavooPaymentFormLink = null);
        showAdditionalOptions = !showAdditionalOptions;
      "
    >
      <i v-if="!showAdditionalOptions" class="fas fa-caret-down"></i>
      <i v-else class="fas fa-caret-up"></i>
      <p>Use Virtual Terminal</p>
      <!-- <p>{{ showAdditionalOptions ? 'Hide' : 'Show' }} additional options</p> -->
    </div>

    <div v-if="showAdditionalOptions" class="additionalOptions">
      <!-- DejaVoo Virtual Terminal -->
      <iframe
        v-if="dejavooPaymentFormLink"
        :src="dejavooPaymentFormLink"
        width="100%"
        height="400"
        frameborder="0"
        ref="CAIframe"
      ></iframe>

      <Spinner v-else />
    </div>

    <div v-if="!showAdditionalOptions" class="form_actions">
      <!-- Query -->
      <BaseButton
        v-if="paymentUUID && !loading"
        type="button"
        mode="primary-outline"
        @click="queryTransaction"
        :disabled="queryLoading"
        ><i v-if="queryLoading" class="fas fa-spinner"></i> Query
        Transaction</BaseButton
      >

      <!-- Cancel -->
      <!-- <BaseButton
        v-if="loading && paymentUUID"
        type="button"
        mode="danger-outline"
        @click="cancel"
        :disabled="cancelLoading"
      >
        <i v-if="cancelLoading" class="fas fa-spinner"></i>
        Cancel Charge</BaseButton
      > -->

      <!-- No payment -->
      <BaseButton
        v-if="!loading"
        @click="confirmNoCollection = true"
        type="button"
        mode="primary-outline"
        :disabled="!total || loading"
      >
        Charge Without Payment
      </BaseButton>

      <!-- Charge -->
      <BaseButton :disabled="!total || loading">
        <i v-if="loading" class="fas fa-spinner"></i>
        Charge
      </BaseButton>
    </div>
  </form>

  <Confirm
    v-if="confirmNoCollection"
    zIndex="100000000001"
    title="Not collecting payment"
    text="Are you sure you wish to record this data without collecting payment?"
    @confirm="submit"
    @deny="confirmNoCollection = false"
  />
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import convertXML from 'xml-js';

export default {
  emits: ['submit'],
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      required: false,
    },
    tax: {
      type: Number,
      required: false,
    },
    client_email: {
      type: String,
      required: false,
    },
  },
  created() {
    this.init();
  },
  async mounted() {
    const registerID = localStorage.getItem('dejavooRegisterID');

    if (registerID) {
      this.selectedTerminal = this.dejavooInfo.terminals.find(
        (t) => t.registerID === registerID
      );
    } else {
      this.selectedTerminal = this.dejavooInfo.terminals[0];
    }

    window.addEventListener('message', this.eventListener);
  },
  async unmounted() {
    window.removeEventListener('message', this.eventListener);
  },
  computed: {
    allTerminalOptions() {
      const terminals = [];

      this.dejavooInfo.terminals.forEach((terminal) => {
        terminals.push({
          option: terminal.registerID,
          value: terminal.registerID,
        });
      });

      return terminals;
    },
    dejavooInfo() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.billing.dejavoo;
    },
    receiptOptions() {
      return [
        {
          option: 'None',
          value: 'No',
        },
        {
          option: 'Merchant',
          value: 'Merchant',
        },
        {
          option: 'Customer',
          value: 'Customer',
        },
        {
          option: 'Both',
          value: 'Both',
        },
      ];
    },
  },
  data() {
    return {
      total: null,
      loading: false,
      cancelLoading: false,
      queryLoading: false,
      confirmNoCollection: false,
      paymentUUID: null,
      receiptOption: 'Both',

      confirmNewPaymentWhileOutstandingPaymentID: false,
      queryInterval: null,

      selectedTerminal: null,
      showAdditionalOptions: false,

      dejavooPaymentFormLink: null,
    };
  },
  methods: {
    init() {
      this.total = +this.amount;
      this.receiptOption =
        this.$store.state.auth.salon.billing.dejavoo.receiptOption;
    },

    eventListener(event) {
      // Raserva Event
      if (event.origin === process.env.VUE_APP_ORIGIN) {
        const customData = event.data;

        if (customData.denied) {
          this.$toast.error('Payment denied');
          return;
        }

        this.submit(
          customData.RRN,
          this.total,
          0,
          customData.responseCardToken
        );
      }
    },

    selectTerminal(registerID) {
      localStorage.setItem('dejavooRegisterID', registerID);

      this.selectedTerminal = this.dejavooInfo.terminals.find(
        (t) => t.registerID === registerID
      );
    },

    async getDejavooPaymentFormLink() {
      let transactionType;

      if (this.total) {
        transactionType = 1;
      } else {
        transactionType = 2;
      }

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/dejavoo/getPaymentFormLink`,
          {
            salonId: this.$store.state.auth.salon._id,
            amount: +(this.total * 100).toFixed(0),
            returnUrl: `${process.env.VUE_APP_ORIGIN}/djvpfreturn.html`,
            transactionType,
          }
        );

        this.dejavooPaymentFormLink = response.data.link;
      } catch (e) {
        console.log(e);
      }
    },

    async cancel() {
      this.cancelLoading = true;

      // TODO: Can't find documentation for clearing a transaction on dejavoo docs

      try {
        await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/dejavoo/clearTransaction`,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        clearInterval(this.queryInterval);

        this.$toast.warning('Transaction cleared');
        this.loading = false;
      } catch (error) {
        clearInterval(this.queryInterval);

        this.$toast.error(error.response.data.error);
        this.loading = false;
      }

      this.cancelLoading = false;
    },

    async submitPayment() {
      if (this.loading) return;

      this.confirmNewPaymentWhileOutstandingPaymentID = false;

      if (this.paymentUUID) {
        this.confirmNewPaymentWhileOutstandingPaymentID = true;
        await this.cancel();
        return;
      }

      this.loading = true;
      this.paymentUUID = uuidv4();

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/dejavoo/processTransaction`,
          {
            PaymentType: 'Credit',
            TransType: 'Sale',
            Amount: this.total.toFixed(2),
            RefId: this.paymentUUID,
            AuthKey: this.selectedTerminal.authKey,
            RegisterId: this.selectedTerminal.registerID,
            PrintReceipt: this.receiptOption,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        if (response.data.id) {
          this.queryInterval = setInterval(() => {
            this.queryJob(response.data.id);
          }, 1000 * 6);
        }
      } catch (error) {
        this.$toast.error(error.message);
        this.loading = false;
      }
    },

    async queryJob(id) {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/dejavoo/queryJob?id=${id}&salonId=${this.$store.state.auth.salon._id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        if (response.data.state !== 'active') {
          clearInterval(this.queryInterval);
        }

        const data = convertXML.xml2json(response.data.data, {
          compact: true,
          spaces: 4,
        });

        if (!response.data.data) {
          if (response.data.reason) {
            this.loading = false;
            this.$toast.error(response.data.reason);
          } else if (response.data.state === 'completed') {
            this.loading = false;
            console.log(response.data);
            this.$toast.error('Error please try again');
          }

          return;
        }

        const dataObj = JSON.parse(data);

        if (!dataObj || !dataObj.xmp) return;

        const dataObjResp = dataObj.xmp.response;

        const extData = dataObjResp.ExtData?._text.split(',');
        const extDataObj = {};

        extData.forEach((data) => {
          const splitData = data.split('=');
          extDataObj[splitData[0]] = splitData[1];
        });

        if (
          dataObjResp.ResultCode?._text === '0' ||
          dataObjResp.Message?._text === 'Approved'
        ) {
          this.paymentUUID = null;

          this.submit(
            extDataObj.RRN,
            extDataObj.TotalAmt,
            extDataObj.Tip,
            'TOKEN_GOES_HERE'
          );

          this.$toast.success(dataObjResp.Message._text);
        } else {
          this.queryTransaction();
        }

        this.loading = false;
      } catch (error) {
        console.log(error);

        await this.queryTransaction();

        if (!error.response) {
          this.$toast.error(error.message);
          this.loading = false;
          return;
        }

        clearInterval(this.queryInterval);
        this.$toast.error(error.response.data.error);
        this.loading = false;
      }
    },

    async queryTransaction() {
      this.queryLoading = true;

      try {
        // Query transaction (double check)
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/dejavoo/queryTransaction`,
          {
            AuthKey: this.selectedTerminal.authKey,
            PaymentType: 'Credit',
            RegisterID: this.selectedTerminal.registerID,
            TransType: 'Status',
            RefId: this.paymentUUID,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        const data = convertXML.xml2json(response.data.data, {
          compact: true,
          spaces: 4,
        });

        const dataObj = JSON.parse(data);

        if (!dataObj || !dataObj.xmp) return;

        const dataObjResp = dataObj.xmp.response;

        const extData = dataObjResp.ExtData?._text.split(',');
        const extDataObj = {};

        extData.forEach((data) => {
          const splitData = data.split('=');
          extDataObj[splitData[0]] = splitData[1];
        });

        if (
          dataObjResp.ResultCode?._text === '0' ||
          dataObjResp.Message?._text === 'Approved'
        ) {
          this.paymentUUID = null;

          console.log(extDataObj.RRN, extDataObj.TotalAmt, extDataObj.Tip);

          this.submit(
            extDataObj.RRN,
            extDataObj.TotalAmt,
            extDataObj.Tip,
            'TOKEN_GOES_HERE'
          );

          this.$toast.success(dataObjResp.Message._text);
        } else {
          this.$toast.error(data.RespMSG || data.Message || 'Not found');
        }
      } catch (error) {
        console.log(error);
        this.$toast.error(error.message);
      }

      this.queryLoading = false;
    },

    submit(ref, amount, tipAmount, token) {
      this.$emit('submit', {
        ref,
        amount: amount ? amount : +this.total.toFixed(2),
        tipAmount,
        token,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .additional {
    display: flex;
    gap: 16px;
    align-items: center;
    color: var(--clr-link);
    cursor: pointer;
  }

  .toggle {
    display: flex;
    align-items: center;
    gap: 32px;
    flex-wrap: wrap;
    padding: 16px 0;

    &_text {
      &_label {
        font-size: 18px;
      }
      &_subtext {
        margin-top: 5px;
        font-size: 14px;
      }
    }
  }

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }

  .CA_queryTransaction {
    margin-top: 16px;
    padding: 16px;
    border-radius: 5px;
    border: 1px solid var(--clr-warning);
    background-color: var(--clr-warning-light);
    line-height: 24px;

    .bold {
      font-weight: 700;
    }
  }
}
// Tablet
@media (max-width: 900px) {
  .form_actions {
    flex-direction: column;
  }
}
</style>
