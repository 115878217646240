<template>
  <section class="opt">
    <p>
      Opting in to text marketing will add $2 per number per month to your
      monthly bill (not including cost per message sent). U.S. compliance states
      that a phone number can only send 1 text message per second, if you are
      sending to a very high volume you may wish to purchase additional phone
      numbers. You will be able to purchase additional phone numbers at $2 per
      number per month.
    </p>

    <form
      @submit.prevent="!subAccountSid ? createSubAccount() : listPhoneNumbers()"
      class="form"
    >
      <BaseInput
        label="Three Digit Area Code"
        subtitle="Local numbers have more engagement"
        @input="areaCode = $event"
      />
      <BaseInput
        label="Amount of Phone Numbers"
        subtitle="Maximum of 50, recommended 10 to start"
        @input="limit = +$event"
        :value="+limit"
      />
      <BaseButton :disabled="!formValid || loading"
        ><i v-if="loading" class="fas fa-spinner"></i> Get Started</BaseButton
      >
    </form>

    <p class="eta">
      With <span class="bold">{{ limit }}</span> phone numbers you will be able
      to send <span class="bold">{{ limit }}</span> messages per second. To a
      receipient list of 5,000 clients a SMS blast will take approximately
      <span class="bold">{{ Math.ceil(5000 / limit) }}</span> seconds to send,
      or around
      <span class="bold">{{ Math.ceil(5000 / limit / 60) }}</span> minutes.
    </p>
  </section>

  <Confirm
    v-if="showConfirm"
    title="Opt-in to Text Marketing"
    :text="`Are you sure you wish to opt-in to text messsage marketing? This will add $${(
      2 * +limit
    ).toFixed(2)} to your monthly bill (not including cost per message sent).`"
    @confirm="purchaseNumbers"
    @deny="showConfirm = false"
  />
</template>

<script>
export default {
  data() {
    return {
      areaCode: '',
      phoneNumbers: [],
      loading: false,
      showConfirm: false,
      limit: 10,
    };
  },
  computed: {
    formValid() {
      if (this.areaCode.length !== 3) return false;
      if (this.limit < 1 || this.limit > 50) return false;

      return true;
    },
    subAccountSid() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.marketing.texts.twilioSubAccountSID;
    },
    subAccountPhoneNumber() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.marketing.texts.twilioPhoneNumber;
    },
  },
  methods: {
    async createSubAccount() {
      this.loading = true;

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/twilio/subaccount`,
          {
            areaCode: this.areaCode,
            salonId: this.$store.state.auth.salon._id,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$store.state.auth.salon.marketing.texts.twilioSubAccountSID =
          response.data.subAccountSID;

        this.listPhoneNumbers();
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
    async listPhoneNumbers() {
      this.loading = true;

      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/twilio/phoneNumbers?areaCode=${this.areaCode}&limit=${this.limit}`
        );

        this.phoneNumbers = response.data.phoneNumbers;
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;

      if (!this.phoneNumbers.length)
        return this.$toast.error(
          'No phone numbers available with that area code'
        );

      this.showConfirm = true;
    },
    async selectPhoneNumber(number) {
      this.loading = true;

      try {
        const response = await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/twilio/phoneNumbers`,
          {
            salonId: this.$store.state.auth.salon._id,
            phoneNumber: number.phoneNumber,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$store.state.auth.salon.marketing.texts.twilioPhoneNumber =
          response.data.phoneNumber;
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    async purchaseNumbers() {
      this.showConfirm = false;
      this.loading = true;

      try {
        const response = await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/twilio/phoneNumbers`,
          {
            salonId: this.$store.state.auth.salon._id,
            phoneNumbers: this.phoneNumbers.map((p) => p.phoneNumber),
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$store.state.auth.salon.marketing.texts.twilioPhoneNumbers.push(
          ...response.data.phoneNumbers
        );

        const serviceResponse = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/twilio/service`,
          {
            salonId: this.$store.state.auth.salon._id,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$store.state.auth.salon.marketing.texts.twilioServiceSID =
          serviceResponse.data.service.sid;
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.opt {
  p {
    line-height: 24px;
  }
  button {
    margin-top: 16px;
  }
  .form {
    margin-top: 32px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 32px;
  }
  .phoneNumbers {
    margin-top: 64px;

    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
      margin-top: 32px;

      li {
        text-align: center;
        cursor: pointer;
        padding: 12px 24px;
        border: 1px solid var(--clr-light);
        border-radius: 5px;

        &:hover {
          background-color: var(--clr-white);
        }
      }
    }
  }

  .eta {
    margin-top: 32px;
  }
}
</style>
