import axios from 'axios';

export default {
  namespaced: true,
  state: {
    appointments: [],
  },
  mutations: {
    addAppointment(state, payload) {
      state.appointments.push(payload);
    },
    editAppointment(state, payload) {
      let index = state.appointments.findIndex(
        (appointment) => appointment._id === payload._id
      );

      state.appointments[index] = payload;
    },
    deleteAppointment(state, payload) {
      let index = state.appointments.findIndex(
        (appointment) => appointment._id === payload._id
      );

      if (index === -1) return;

      state.appointments.splice(index, 1);
    },
  },
  actions: {
    async createAppointment({ rootState, commit }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/appointments`,
          {
            ...payload,
            salonId: payload.fromBooking
              ? rootState.booking.salon._id
              : rootState.auth.salon
              ? rootState.auth.salon._id
              : rootState.booking.salon._id,
          },
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.sockets.socket.emit(
          'appointmentCreated',
          response.data.appointment
        );

        commit('addAppointment', response.data.appointment);

        return response.data.appointment;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async getAppointmentsBetweenDates({ state }, payload) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${payload.salonId}/appointments?start=${payload.start}&end=${payload.end}`
        );

        if (!payload.returnAppointments) {
          state.appointments = response.data.appointments;
        } else {
          return response.data.appointments;
        }
      } catch (error) {
        console.log(error);
        throw Error(error.response.data.error);
      }
    },

    async searchFutureAppointments({ rootState }, payload) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/appointments/future?search=${payload}`
        );

        return response.data.appointments;
      } catch (error) {
        console.log(error);
        throw Error(error.response.data.error);
      }
    },

    async editAppointment({ commit, dispatch, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/appointments/${payload._id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        let ticket;

        if (payload.status === 'arrived') {
          if (
            !rootState.tickets.tickets.find((ticket) =>
              ticket.appointmentId
                ? ticket.appointmentId.toString() === payload._id.toString()
                : false
            )
          ) {
            ticket = await dispatch(
              'createTicketFromAppointment',
              response.data.appointment
            );
          }
        }

        rootState.sockets.socket.emit('appointmentCreated', {
          ...response.data.appointment,
          skipNotification: true,
        });
        commit('editAppointment', response.data.appointment);
        return { appointment: response.data.appointment, ticket };
      } catch (error) {
        console.log(error);
        throw Error(error.response.data.error);
      }
    },

    async requestCancellation(_, payload) {
      console.log(payload);
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/appointments/${payload._id}/requestCancellation`
        );

        return { appointment: response.data.appointment };
      } catch (error) {
        console.log(error);
        throw Error(error.response.data.error);
      }
    },

    async deleteAppointment({ commit, rootState }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/appointments/${payload._id}`,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.sockets.socket.emit('appointmentCreated', {
          ...payload,
          skipNotification: true,
        });
        rootState.tickets.changeCount++;
        commit('deleteAppointment', payload);
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async deleteStandingAppointments({ rootState }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/appointments/standing/${payload.repeat.repeatId}`,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async createTicketFromAppointment({ dispatch, rootState }, payload) {
      const deepCopiedPayload = JSON.parse(JSON.stringify(payload));

      const ticket = {
        created_at: new Date(Date.now()),
        appointmentId: deepCopiedPayload._id,
        status: 'in-progress',
        client: deepCopiedPayload.client,
        items: [],
        tips: [],
        payments: [],
      };

      let discount;
      let discountAmount;

      if (deepCopiedPayload.discountId) {
        discount = rootState.auth.salon.discounts.find(
          (discount) => discount._id === deepCopiedPayload.discountId
        );
      } else if (deepCopiedPayload.coupon) {
        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/coupons/${deepCopiedPayload.coupon}`
        );

        if (response.data.coupon) {
          discount = rootState.auth.salon.discounts.find(
            (discount) => discount._id === response.data.coupon.discountId
          );
        }
      }

      deepCopiedPayload.services.forEach((service) => {
        if (discount) {
          if (discount.type === 'fixed') {
            discountAmount = discount.amount;
          } else {
            discountAmount = +(
              service.service.price *
              (discount.amount * 0.01)
            ).toFixed(2);
          }
        }

        service.service.basePrice = service.service.price;

        if (rootState.auth.salon.payments.useCashDiscount) {
          service.service.cashDiscountAdditionalProcessingFee =
            service.service.price *
            (rootState.auth.salon.payments.cashDiscountAdditionalProcessingFee *
              0.01);

          service.service.price +=
            service.service.price *
            (rootState.auth.salon.payments.cashDiscountAdditionalProcessingFee *
              0.01);
        }

        if (service.requested) {
          service.service.requested = true;

          if (
            rootState.auth.salon.adminSettings.useRequestedServiceTurnsValue
          ) {
            service.service.turns =
              rootState.auth.salon.adminSettings.requestedServiceTurnsValue ||
              0;
          }
        }

        ticket.items.push({
          item: { ...service.service, discount, discountAmount },
          quantity: 1,
          staff: service.staff,
        });
      });

      if (rootState.auth.salon.adminSettings.recalculateTicketAtCreation) {
        ticket.items = await dispatch(
          'tickets/recalculateTicketItems',
          ticket,
          {
            root: true,
          }
        );
      }

      if (discount && discountAmount) {
        ticket.items.forEach((item) => {
          item.item.discount = discount;
          item.item.discountAmount = discountAmount;
        });
      }

      deepCopiedPayload.billing.deposits.forEach((deposit) =>
        ticket.payments.push({ ...deposit, isDeposit: true })
      );

      const dbTicket = await dispatch('tickets/createTicket', ticket, {
        root: true,
      });
      return dbTicket;
    },
  },
  getters: {
    new(state) {
      return state.appointments.filter(
        (appointment) => appointment.status === 'new'
      );
    },
    confirmed(state) {
      return state.appointments.filter(
        (appointment) => appointment.status === 'confirmed'
      );
    },
    arrived(state) {
      return state.appointments.filter(
        (appointment) => appointment.status === 'arrived'
      );
    },
  },
};
