<template>
  <section class="endpoints">
    <div class="endpoints_router">
      <p class="bold">On this page:</p>

      <ul>
        <li class="link" @click="scrollIntoView('create')">
          Create service category
        </li>
        <li class="link" @click="scrollIntoView('get')">
          Get service category
        </li>
        <li class="link" @click="scrollIntoView('getAll')">
          Get service categories
        </li>
        <li class="link" @click="scrollIntoView('update')">
          Update service category
        </li>
        <li class="link" @click="scrollIntoView('updateAll')">
          Update all service categories
        </li>
        <li class="link" @click="scrollIntoView('delete')">
          Delete service category
        </li>
        <li class="link" @click="scrollIntoView('addService')">
          Add service to category
        </li>
        <li class="link" @click="scrollIntoView('getService')">
          Get service in category
        </li>
        <li class="link" @click="scrollIntoView('getServices')">
          Get services in category
        </li>
        <li class="link" @click="scrollIntoView('updateService')">
          Edit service in category
        </li>
        <li class="link" @click="scrollIntoView('deleteService')">
          Delete service in category
        </li>
      </ul>
    </div>

    <!-- Category create -->
    <div class="endpoint" id="create" ref="create">
      <DocsAPIServicesCreate />
    </div>

    <!-- Category get one -->
    <div class="endpoint" id="get" ref="get">
      <DocsAPIServicesGetCategory />
    </div>

    <!-- Category get all -->
    <div class="endpoint" id="getAll" ref="getAll">
      <DocsAPIServicesGetAll />
    </div>

    <!-- Category update one -->
    <div class="endpoint" id="update" ref="update">
      <DocsAPIServicesUpdateOne />
    </div>

    <!-- Category update all -->
    <div class="endpoint" id="updateAll" ref="updateAll">
      <DocsAPIServicesUpdateAll />
    </div>

    <!-- Category delete one -->
    <div class="endpoint" id="delete" ref="delete">
      <DocsAPIServicesDeleteOne />
    </div>

    <!-- Category add service -->
    <div class="endpoint" id="addService" ref="addService">
      <DocsAPIServicesAddItem />
    </div>

    <!-- Category get service -->
    <div class="endpoint" id="getService" ref="getService">
      <DocsAPIServicesGetItemInCategory />
    </div>

    <!-- Category get services -->
    <div class="endpoint" id="getServices" ref="getServices">
      <DocsAPIServicesGetCategoryItems />
    </div>

    <!-- Category edit service -->
    <div class="endpoint" id="updateService" ref="updateService">
      <DocsAPIServicesUpdateItem />
    </div>

    <!-- Category delete service -->
    <div class="endpoint" id="deleteService" ref="deleteService">
      <DocsAPIServicesDeleteItem />
    </div>
  </section>
</template>

<script>
import DocsAPIServicesCreate from './DocsAPIServicesCreate.vue';
import DocsAPIServicesGetAll from './DocsAPIServicesGetAll.vue';
import DocsAPIServicesUpdateAll from './DocsAPIServicesUpdateAll.vue';
import DocsAPIServicesUpdateOne from './DocsAPIServicesUpdateOne.vue';
import DocsAPIServicesDeleteOne from './DocsAPIServicesDeleteOne.vue';
import DocsAPIServicesAddItem from './DocsAPIServicesAddItem.vue';
import DocsAPIServicesUpdateItem from './DocsAPIServicesUpdateItem.vue';
import DocsAPIServicesDeleteItem from './DocsAPIServicesDeleteItem.vue';
import DocsAPIServicesGetCategoryItems from './DocsAPIServicesGetCategoryItems.vue';
import DocsAPIServicesGetItemInCategory from './DocsAPIServicesGetItemInCategory.vue';
import DocsAPIServicesGetCategory from './DocsAPIServicesGetCategory.vue';

export default {
  components: {
    DocsAPIServicesCreate,
    DocsAPIServicesGetAll,
    DocsAPIServicesUpdateAll,
    DocsAPIServicesUpdateOne,
    DocsAPIServicesDeleteOne,
    DocsAPIServicesAddItem,
    DocsAPIServicesUpdateItem,
    DocsAPIServicesDeleteItem,
    DocsAPIServicesGetCategoryItems,
    DocsAPIServicesGetItemInCategory,
    DocsAPIServicesGetCategory,
  },
  methods: {
    scrollIntoView(element) {
      this.$refs[element].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoints {
  &_router {
    margin-bottom: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-white-2);

    ul {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 32px;

      .link {
        cursor: pointer;
        color: var(--clr-link);
      }
    }
  }

  .endpoint {
    &:not(:first-child) {
      margin-top: 64px;
    }
  }
}
</style>
