<template>
  <section class="hours">
    <i class="fas fa-clock"></i>
    <div class="days">
      <div
        v-for="day in schedule"
        :key="day.day"
        class="days_day"
        :class="{ today: isToday(day.day) }"
      >
        <p class="day">{{ formatDayFromDayOfWeek(day.day) }}</p>
        <div class="blocks" v-if="day.blocks.length">
          <div class="block" v-for="block in day.blocks" :key="block">
            <p>{{ formatBlock(block.start) }} - {{ formatBlock(block.end) }}</p>
          </div>
        </div>
        <div v-else class="none">Closed</div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    schedule: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDayFromDayOfWeek(day) {
      return this.$moment().day(day).format('ddd');
    },
    formatBlock(block) {
      const blockHour = block.split(':')[0];
      const blockMinute = block.split(':')[1];

      return this.$moment().hour(blockHour).minute(blockMinute).format('LT');
    },
    isToday(day) {
      return this.$moment().isSame(this.$moment().day(day), 'day');
    },
  },
};
</script>

<style lang="scss" scoped>
.hours {
  display: flex;
  gap: 16px;
  margin-top: 16px;

  i {
    color: var(--clr-light);
    margin-right: 16px;
    min-width: 20px;
  }

  .none {
    color: var(--clr-gray);
  }
  .today {
    font-weight: 700;
  }

  .days {
    &_day {
      &:not(:first-child) {
        margin-top: 24px;
      }

      .day {
        min-width: 45px;
      }
      display: flex;
      gap: 16px;
    }
  }
}
</style>
