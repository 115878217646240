<template>
  <section class="services">
    <div class="services_head">
      <h2>Services</h2>
    </div>
    <div class="services_actions">
      <div class="col icons">
        <div class="icon" @click="showItems = true">
          <i class="fas fa-expand"></i>
        </div>
        <div class="icon" @click="showItems = false">
          <i class="fas fa-compress"></i>
        </div>
        <BaseInput
          placeholder="Search..."
          :value="searchValue"
          @input="filterServices($event)"
          icon="fas fa-search"
        />
      </div>
      <div class="col buttons">
        <div class="add_button">
          <BaseButton @click="showAddDropdown = true"
            >Add <i class="fas fa-angle-down"></i
          ></BaseButton>

          <Tooltip
            v-if="showAddDropdown"
            mode="bottom right"
            @hide="showAddDropdown = false"
          >
            <ul class="addDropdown">
              <li
                v-if="categories.length"
                @click="
                  showAddDropdown = false;
                  selectedService = null;
                  showServiceForm = true;
                "
              >
                New Service
              </li>
              <li
                @click="
                  showAddDropdown = false;
                  selectedCategory = null;
                  showCategoryForm = true;
                "
              >
                New Category
              </li>
              <li
                @click="
                  showAddDropdown = false;
                  selectedParentCategory = null;
                  showParentCategoryForm = true;
                "
              >
                New Parent Category
              </li>
            </ul>
          </Tooltip>
        </div>
      </div>
    </div>

    <div
      v-if="servicesOnBookingThatCanNotBePerformed.length"
      class="alert alert-warning mt-s"
    >
      <p>
        You have services on your booking page that no staff member can peform:
        <span
          class="bold"
          v-for="(service, index) in servicesOnBookingThatCanNotBePerformed"
          :key="index"
          >{{ service
          }}<span
            v-if="index !== servicesOnBookingThatCanNotBePerformed.length - 1"
            >,
          </span></span
        >
      </p>
    </div>

    <table v-if="parentCategories.length" class="parentCategoryTable">
      <thead>
        <tr>
          <th></th>
          <th>Parent Category</th>
          <th>Categories</th>
          <th>Favorite</th>
        </tr>
      </thead>

      <tbody>
        <tr
          @click="selectedParentCategory = parent"
          v-for="(parent, index) in parentCategories"
          :key="parent._id"
        >
          <td class="actions">
            <i
              v-if="index !== 0"
              @click="
                $event.stopPropagation();
                changeOrder(true, index);
              "
              class="fas fa-chevron-up"
            ></i>
            <i
              v-if="index !== parentCategories.length - 1"
              @click="
                $event.stopPropagation();
                changeOrder(false, index);
              "
              class="fas fa-chevron-down"
            ></i>
          </td>
          <td>{{ parent.title }}</td>
          <td>3</td>
          <td><i v-if="parent.favorite" class="fas fa-check"></i></td>
        </tr>
      </tbody>
    </table>

    <div class="services_list">
      <ServicesList
        :categories="categories"
        :showItems="showItems"
        @selectItem="selectService($event)"
        @selectCategory="selectCategory($event)"
        @addItemToCategory="addItemToCategory($event)"
      />
    </div>
  </section>

  <Modal
    :title="serviceFormTitle"
    fullScreen
    :show="showServiceForm"
    @close="showServiceForm = false"
  >
    <div class="serviceForm">
      <ServiceForm
        :service="selectedService"
        @submitted="
          showServiceForm = false;
          init();
        "
        @deleted="serviceDeleted($event)"
      />
    </div>
  </Modal>

  <Modal
    :title="categoryFormTitle"
    :show="showCategoryForm"
    @close="showCategoryForm = false"
    maxWidth="500px"
  >
    <div class="categoryForm">
      <CategoryForm
        :category="selectedCategory"
        @editCategory="editCategory($event)"
        @createCategory="createCategory($event)"
        @deleteCategory="deleteCategory($event)"
      />
    </div>
  </Modal>

  <Modal
    :title="parentCategoryFormTitle"
    :show="showParentCategoryForm || selectedParentCategory"
    @close="
      showParentCategoryForm = false;
      selectedParentCategory = null;
    "
    maxWidth="500px"
  >
    <div class="categoryForm">
      <ParentCategoryForm
        :category="selectedParentCategory"
        @update="updateParentCategories($event)"
      />
    </div>
  </Modal>
</template>

<script>
import ServicesList from '@/components/items/ServicesList.vue';
import ServiceForm from '@/components/items/ServiceForm.vue';
import CategoryForm from '@/components/items/CategoryForm.vue';
import ParentCategoryForm from '@/components/items/ParentCategoryForm.vue';

export default {
  components: {
    ServicesList,
    ServiceForm,
    CategoryForm,
    ParentCategoryForm,
  },
  created() {
    this.init();
  },
  data() {
    return {
      showAddDropdown: false,

      selectedService: null,
      selectedCategory: null,
      selectedParentCategory: null,

      showServiceForm: false,
      showCategoryForm: false,
      showParentCategoryForm: false,

      showItems: true,

      searchValue: '',

      categories: [],
    };
  },
  computed: {
    salon() {
      return this.$store.state.auth.salon;
    },
    staffs() {
      return this.$store.state.auth.salon.staff;
    },
    categoryFormTitle() {
      return this.selectedCategory ? 'Edit Category' : 'New Category';
    },
    parentCategoryFormTitle() {
      return this.selectedParentCategory
        ? 'Edit Parent Category'
        : 'New Parent Category';
    },
    serviceFormTitle() {
      return this.selectedService && this.selectedService._id
        ? 'Edit Service'
        : 'New Service';
    },
    // categories() {
    //   return this.$store.state.items.services;
    // },
    categoryChange() {
      return this.$store.state.items.categoryChangeCount;
    },
    servicesOnBookingThatCanNotBePerformed() {
      if (!this.categories.length || !this.staffs.length) return [];

      const servicesOnBookingThatCanNotBePerformed = [];

      this.categories.forEach((category) => {
        category.items.forEach((item) => {
          if (!item.onBooking) return;

          if (
            !this.staffs.find((staff) =>
              staff.services.find((service) => service === item._id)
            )
          ) {
            servicesOnBookingThatCanNotBePerformed.push(item.title);
          }
        });
      });

      return servicesOnBookingThatCanNotBePerformed;
    },
    parentCategories() {
      return this.salon.serviceParentCategories;
    },
  },
  watch: {
    categoryChange() {
      this.filterServices(this.searchValue);
    },
    salon() {
      this.init();
    },
  },
  methods: {
    async changeOrder(up, index) {
      const categories = JSON.parse(JSON.stringify(this.parentCategories));

      if (up) {
        if (index === 0) return;

        const temp = categories[index - 1];
        categories[index - 1] = categories[index];
        categories[index] = temp;
      } else {
        if (index === categories.length - 1) return;

        const temp = categories[index + 1];
        categories[index + 1] = categories[index];
        categories[index] = temp;
      }

      try {
        const response = await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/serviceParentCategory`,
          categories,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$store.state.auth.salon.serviceParentCategories =
          response.data.serviceParentCategories;
      } catch (error) {
        this.$toast.error(error.message);
        return;
      }

      this.$toast.success('Order changed successfully');
    },
    init() {
      this.searchValue = '';
      this.filterServices(this.searchValue);
    },
    filterServices(search) {
      this.searchValue = search;
      this.categories = this.$store.getters['items/searchServices'](search);
    },
    selectService(service) {
      this.selectedService = service;
      this.showServiceForm = true;
    },
    selectCategory(_id) {
      this.selectedCategory = this.categories.find((category) => {
        return category._id === _id;
      });

      this.showCategoryForm = true;
    },
    addItemToCategory(category) {
      this.selectedService = {
        color: category.color,
        category: category._id,
        fees: {
          supply: 0,
          other: 0,
        },
      };
      this.showServiceForm = true;
    },

    createCategory(category) {
      try {
        this.$store.dispatch('items/createServiceCategory', category);
        this.submittedCategory();
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    editCategory(category) {
      try {
        this.$store.dispatch('items/editServiceCategory', category);
        this.submittedCategory();
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    deleteCategory(category) {
      try {
        this.$store.dispatch('items/deleteServiceCategory', category);
        this.$toast.success('Category deleted');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    submittedCategory() {
      this.showCategoryForm = false;
      this.$toast.success('Category updated');
    },

    serviceDeleted(serviceId) {
      for (let i = 0; i < this.categories.length; i++) {
        const category = this.categories[i];
        const index = category.items.findIndex((service) => {
          return service._id === serviceId;
        });
        if (index > -1) {
          category.items.splice(index, 1);
          break;
        }
      }

      this.showServiceForm = false;
    },
    updateParentCategories(data) {
      this.showParentCategoryForm = false;
      this.selectedParentCategory = null;

      this.$store.state.auth.salon.serviceParentCategories = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.services {
  width: 100%;
  max-width: 800px;
  .mt-s {
    margin-top: 8px;
  }
  .bold {
    font-weight: 700;
  }

  .alert {
    padding: 12px;
    font-size: 14px;
    border-radius: 5px;
    margin-bottom: 32px;

    &-warning {
      background-color: var(--clr-warning-light);
      border: 1px solid;
      border-color: var(--clr-warning);
    }
  }

  &_head {
    h2 {
      font-size: 28px;
    }
  }

  &_actions {
    margin-top: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-extra-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    .icons {
      display: flex;
      gap: 16px;

      .icon {
        background-color: var(--clr-white);
        padding: 8px 16px;
        border-radius: 5px;
        border: 1px solid var(--clr-light);
        cursor: pointer;
      }
    }

    .buttons {
      display: flex;
      gap: 16px;

      .add_button {
        position: relative;

        button {
          i {
            margin-left: 16px;
          }
        }

        .addDropdown {
          color: var(--clr-black);
          background-color: white;
          border: 1px solid var(--clr-light);
          border-radius: 5px;

          li {
            padding: 12px 16px;
            font-weight: 400;
            text-align: left;
            font-size: 15px;
            transition: background-color 0.2s;
            cursor: pointer;

            &:hover {
              background-color: var(--clr-extra-light);
            }
          }
        }
      }
    }
  }

  .parentCategoryTable {
    margin-top: 32px;

    .actions {
      display: flex;
      align-items: center;
      gap: 32px;
    }
  }

  &_list {
    margin-top: 32px;
  }
}

.serviceForm {
  width: 1000px;
  max-width: 100%;
  margin: 32px auto;
  padding: 0 16px;
}
.categoryForm {
  padding: 32px;
}

// Tablet
@media (max-width: 900px) {
  .services {
    &_actions {
      flex-direction: column;
      gap: 16px;

      .col {
        width: 100%;
      }

      .buttons {
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }
}
</style>
