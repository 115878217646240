<template>
  <section class="endpoints">
    <div class="endpoints_router">
      <p class="bold">On this page:</p>

      <ul>
        <li class="link" @click="scrollIntoView('create')">Create staff</li>
        <li class="link" @click="scrollIntoView('get')">Get staff</li>
        <li class="link" @click="scrollIntoView('getAll')">
          Get salons staffs
        </li>
        <li class="link" @click="scrollIntoView('update')">Update staff</li>
        <li class="link" @click="scrollIntoView('getSchedule')">
          Get staff schedule
        </li>
        <li class="link" @click="scrollIntoView('updateSchedule')">
          Update staff schedule
        </li>
        <li class="link" @click="scrollIntoView('delete')">Delete staff</li>
        <li class="link" @click="scrollIntoView('clockIn')">Clock in staff</li>
        <li class="link" @click="scrollIntoView('clockOut')">
          Clock out staff
        </li>
        <li class="link" @click="scrollIntoView('clockOutAll')">
          Clock out all staff
        </li>
        <li class="link" @click="scrollIntoView('invite')">
          Invite staff to salon
        </li>
      </ul>
    </div>

    <!-- Staff create -->
    <div class="endpoint" id="create" ref="create">
      <DocsAPIStaffCreate />
    </div>

    <!-- Staff get -->
    <div class="endpoint" id="get" ref="get">
      <DocsAPIStaffGet />
    </div>

    <!-- Staff get all -->
    <div class="endpoint" id="getAll" ref="getAll">
      <DocsAPIStaffGetAll />
    </div>

    <!-- Staff update -->
    <div class="endpoint" id="update" ref="update">
      <DocsAPIStaffUpdate />
    </div>

    <!-- Staff update schedule -->
    <div class="endpoint" id="getSchedule" ref="getSchedule">
      <DocsAPIStaffGetSchedule />
    </div>

    <!-- Staff update schedule -->
    <div class="endpoint" id="updateSchedule" ref="updateSchedule">
      <DocsAPIStaffUpdateSchedule />
    </div>

    <!-- Staff delete -->
    <div class="endpoint" id="delete" ref="delete">
      <DocsAPIStaffDelete />
    </div>

    <!-- Staff Clock In -->
    <div class="endpoint" id="clockIn" ref="clockIn">
      <DocsAPIStaffClockIn />
    </div>

    <!-- Staff Clock Out -->
    <div class="endpoint" id="clockOut" ref="clockOut">
      <DocsAPIStaffClockOut />
    </div>

    <!-- Staff Clock Out All -->
    <div class="endpoint" id="clockOutAll" ref="clockOutAll">
      <DocsAPIStaffClockOutAll />
    </div>

    <!-- Staff Invites -->
    <div class="endpoint" id="invite" ref="invite">
      <DocsAPIStaffInvite />
    </div>
  </section>
</template>

<script>
import DocsAPIStaffCreate from './DocsAPIStaffCreate.vue';
import DocsAPIStaffGet from './DocsAPIStaffGet.vue';
import DocsAPIStaffGetAll from './DocsAPIStaffGetAll.vue';
import DocsAPIStaffUpdate from './DocsAPIStaffUpdate.vue';
import DocsAPIStaffGetSchedule from './DocsAPIStaffGetSchedule.vue';
import DocsAPIStaffUpdateSchedule from './DocsAPIStaffUpdateSchedule.vue';
import DocsAPIStaffDelete from './DocsAPIStaffDelete.vue';
import DocsAPIStaffClockIn from './DocsAPIStaffClockIn.vue';
import DocsAPIStaffClockOut from './DocsAPIStaffClockOut.vue';
import DocsAPIStaffClockOutAll from './DocsAPIStaffClockOutAll.vue';
import DocsAPIStaffInvite from './DocsAPIStaffInvite.vue';

export default {
  components: {
    DocsAPIStaffCreate,
    DocsAPIStaffGet,
    DocsAPIStaffGetAll,
    DocsAPIStaffUpdate,
    DocsAPIStaffGetSchedule,
    DocsAPIStaffUpdateSchedule,
    DocsAPIStaffDelete,
    DocsAPIStaffClockIn,
    DocsAPIStaffClockOut,
    DocsAPIStaffClockOutAll,
    DocsAPIStaffInvite,
  },
  methods: {
    scrollIntoView(element) {
      this.$refs[element].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoints {
  &_router {
    margin-bottom: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-white-2);

    ul {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 32px;

      .link {
        cursor: pointer;
        color: var(--clr-link);
      }
    }
  }

  .endpoint {
    &:not(:first-child) {
      margin-top: 64px;
    }
  }
}
</style>
