<template>
  <form v-if="!checkedEmail" @submit.prevent="submitEmail" class="form">
    <div class="form_body">
      <BaseInput
        inputType="email"
        placeholder="Enter your email address"
        :value="staff.email"
        :validators="validators.email"
        @input="staff.email = $event"
      />
    </div>
    <div class="form_actions">
      <BaseButton :disabled="!emailFormValid">Continue</BaseButton>
    </div>
  </form>
  <form
    v-if="checkedEmail && !emailInUse"
    @submit.prevent="submit"
    class="form"
  >
    <p class="subtext">
      You're almost there! Create your new account for
      <span class="bold">{{ staff.email }}</span> by completing this form.
    </p>
    <div class="form_body">
      <BaseInput
        label="First name"
        placeholder="Enter your first name"
        :value="staff.firstName"
        :validators="validators.name"
        @input="staff.firstName = $event"
      />
      <BaseInput
        label="Last name"
        placeholder="Enter your last name"
        :value="staff.lastName"
        :validators="validators.name"
        @input="staff.lastName = $event"
      />
      <BaseInput
        label="Password"
        :inputType="passwordInput"
        :value="staff.password"
        placeholder="Enter a password"
        @input="staff.password = $event"
        icon="fas fa-eye"
        :validators="validators.password"
        @iconClick="togglePasswordInput"
      />
      <BasePhoneInput
        label="Phone number"
        placeholder="Enter your phone number"
        :value="staff.phoneNumber"
        :validators="validators.phone"
        @input="staff.phoneNumber = $event"
      />
    </div>
    <div class="form_actions">
      <BaseButton :disabled="!formValid">Create account</BaseButton>
    </div>
  </form>
</template>

<script>
export default {
  computed: {
    validators() {
      return {
        email: {
          email: true,
          required: true,
        },
        name: {
          minLength: 2,
          required: true,
        },
        password: {
          minLength: 6,
          required: true,
        },
        phone: {
          required: true,
        },
      };
    },
    formValid() {
      if (this.staff.email.length < 5) return false;
      if (this.staff.firstName.length < 2) return false;
      if (this.staff.lastName.length < 2) return false;
      if (this.staff.password.length < 6) return false;
      if (this.staff.phoneNumber.length < 9) return false;

      return true;
    },
    emailFormValid() {
      if (this.staff.email.length < 5) return false;

      return true;
    },
  },
  data() {
    return {
      checkedEmail: false,
      emailInUse: false,

      passwordInput: 'password',

      staff: {
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        phoneNumber: '',
      },
    };
  },
  methods: {
    togglePasswordInput() {
      if (this.passwordInput === 'password') {
        this.passwordInput = 'text';
      } else {
        this.passwordInput = 'password';
      }
    },

    async submitEmail() {
      if (
        await this.$store.dispatch(
          'auth/checkForExistingStaffAccount',
          this.staff.email
        )
      ) {
        this.checkedEmail = true;
        this.emailInUse = true;

        this.$router.push({
          name: 'StaffLogin',
          query: {
            email: this.staff.email,
          },
        });

        return;
      }

      this.checkedEmail = true;
      this.emailInUse = false;
    },

    async submit() {
      try {
        await this.$store.dispatch('auth/createStaffAccount', this.staff);

        this.$router.push({
          name: 'SalonSelect',
        });
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  padding: 0 16px;

  .subtext {
    margin-bottom: 32px;

    .bold {
      font-weight: 700;
    }
  }

  &_body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &_actions {
    margin-top: 32px;

    button {
      width: 100%;
    }
  }
}
</style>
