<template>
  <div class="endpoint_head">
    <h2>Update permissions</h2>

    <div class="route">
      <i
        @click="
          $router.push('/documentation/api/settings#updatePermissions');
          $toast.info('Set URL to endpoint');
        "
        :style="{ color: 'var(--clr-secondary)', cursor: 'pointer' }"
        class="fas fa-hashtag"
      ></i>
      <p class="tag tag-blue">PUT</p>
      <p class="url">
        {{ apiUrl }}<span>/salons/:id/settings/permissions</span>
      </p>
    </div>

    <div class="description">
      <p>Update a salons permissions</p>
    </div>
  </div>

  <div class="endpoint_body">
    <div class="request">
      <h3>Request</h3>

      <!-- Headers -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Headers</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">Authorization</p>
              <p class="examples">Examples: <span>Bearer {Token}</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Path params -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Path Parameters</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">ID</p>
              <p class="description">ID of salon</p>
              <p class="examples">
                Examples: <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6f</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
          <p>application/json</p>
        </div>

        <div class="alert alert-info">
          <p>
            All sub-objects have the following fields: "title" (string),
            "disabled" (boolean), "basic" (boolean), "low" (boolean), "medium"
            (boolean), "high" (boolean). Staff members on a salon have the
            "role" field, if a staff member "role" is set to "medium" and the
            permissions "medium" field is set to "true", they can perform the
            action. <br />
            <br />
            <span class="bold">Example: </span><br />
            <br />
            { "bookings": { "title": "Bookings", "permissions": { "ownCalendar":
            { "title": "Access Own Calendar", "disabled": false, "basic": false,
            "low": true, "medium": true, "high": true } } } }
          </p>
        </div>

        <ul>
          <!-- bookings -->
          <li>
            <div class="col value">
              <p class="bold">bookings</p>
              <p class="description">
                Object - "permissions" field holds the following bookings
                permissions
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- bookings - ownCalendar -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">ownCalendar</p>
              <p class="description">Object - Access Own Calendar</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- bookings - othersCalendar -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">othersCalendar</p>
              <p class="description">Object - Access others calendars</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- bookings - bookAppointments -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">bookAppointments</p>
              <p class="description">Object - Can book appointments</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- bookings - viewHome -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewHome</p>
              <p class="description">Object - Home</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- bookings - viewClients -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewClients</p>
              <p class="description">Object - Clients</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- tickets -->
          <li>
            <div class="col value">
              <p class="bold">tickets</p>
              <p class="description">
                Object - "permissions" field holds the following tickets
                permissions
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- tickets - editDashboardTickets -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">editDashboardTickets</p>
              <p class="description">Object - Tickets</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- tickets - checkOutTickets -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">checkOutTickets</p>
              <p class="description">Object - Can check out tickets</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- tickets - editPricesAtCheckout -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">editPricesAtCheckout</p>
              <p class="description">Object - Can edit prices at checkout</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- tickets - addDiscountsAtCheckout -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">addDiscountsAtCheckout</p>
              <p class="description">Object - Can add discounts</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- tickets - setPrepaidAtCheckout -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">setPrepaidAtCheckout</p>
              <p class="description">Object - Can set items as prepaid</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- tickets - setRequestedAtCheckout -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">setRequestedAtCheckout</p>
              <p class="description">Object - Can set items as requested</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- tickets - viewSummary -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewSummary</p>
              <p class="description">Object - Summary</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- tickets - viewTickets -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewTickets</p>
              <p class="description">Object - View Tickets</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- tickets - editTickets -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">editTickets</p>
              <p class="description">Object - Edit Tickets</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- tickets - viewOwnReports -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewOwnReports</p>
              <p class="description">Object - View own staff reports</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- tickets - viewOthersReports -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewOthersReports</p>
              <p class="description">Object - View other staff reports</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- tickets - codeToViewOthersReports -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">codeToViewOthersReports</p>
              <p class="description">Object - Require code to view others</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- tickets - editShifts -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">editShifts</p>
              <p class="description">Object - Edit team member shifts</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- tickets - editTurnsOnItems -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">editTurnsOnItems</p>
              <p class="description">Object - Edit turns on items</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- tickets - editAdditionalTurns -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">editAdditionalTurns</p>
              <p class="description">Object - Edit additional turn count</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- staff -->
          <li>
            <div class="col value">
              <p class="bold">staff</p>
              <p class="description">
                Object - "permissions" field holds the following staff
                permissions
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- staff - viewOwnWorkingHours -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewOwnWorkingHours</p>
              <p class="description">Object - View own working hours</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- staff - editOwnWorkingHours -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">editOwnWorkingHours</p>
              <p class="description">Object - Edit own working hours</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- staff - canClockSelfIn -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">canClockSelfIn</p>
              <p class="description">Object - Can clock self in</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- staff - canClockOthersIn -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">canClockOthersIn</p>
              <p class="description">Object - Can clock others in</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- staff - viewOthersWorkingHours -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewOthersWorkingHours</p>
              <p class="description">Object - View others working hours</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- staff - editOthersWorkingHours -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">editOthersWorkingHours</p>
              <p class="description">Object - Edit others working hours</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- staff - viewStaffMembers -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewStaffMembers</p>
              <p class="description">Object - View/edit staff members</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- staff - viewClientContactInfo -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewClientContactInfo</p>
              <p class="description">Object - View client contact info</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- staff - canUseTempUser -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">canUseTempUser</p>
              <p class="description">Object - Can Use Temp User Mode</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- staff - canViewSalonNotifications -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">canViewSalonNotifications</p>
              <p class="description">Object - Can view salon notifications</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- staff - canEditOwnCapableServices -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">canEditOwnCapableServices</p>
              <p class="description">Object - Can edit own capable services</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- staff - canViewFormulas -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">canViewFormulas</p>
              <p class="description">Object - View/edit formulas</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- staff - canViewMessenger -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">canViewMessenger</p>
              <p class="description">Object - View/send messages</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- staff - canEditRotationOrder -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">canEditRotationOrder</p>
              <p class="description">Object - Can edit rotation order</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- requireCode -->
          <li>
            <div class="col value">
              <p class="bold">requireCode</p>
              <p class="description">
                Object - "permissions" field holds the following requireCode
                permissions
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- requireCode - generateGiftCard -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">generateGiftCard</p>
              <p class="description">Object - Generate Gift Card</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- requireCode - useOtherPaymentMethod -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">useOtherPaymentMethod</p>
              <p class="description">Object - Use Other Payment Method</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- requireCode - editAdditionalTurns -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">editAdditionalTurns</p>
              <p class="description">Object - Edit additional turn count</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- requireCode - deleteAppointments -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">deleteAppointments</p>
              <p class="description">Object - Delete Appointments</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- requireCode - checkoutUnapprovedTickets -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">checkoutUnapprovedTickets</p>
              <p class="description">Object - Checkout Unapproved Tickets</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- requireCode - editRotationOrder -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">editRotationOrder</p>
              <p class="description">Object - Edit rotation order</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- other -->
          <li>
            <div class="col value">
              <p class="bold">other</p>
              <p class="description">
                Object - "permissions" field holds the following other
                permissions
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- other - viewProducts -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewProducts</p>
              <p class="description">Object - View/edit products</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- other - viewServices -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewServices</p>
              <p class="description">Object - View/edit services</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- other - viewDiscounts -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewDiscounts</p>
              <p class="description">Object - View/edit discounts</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- other - viewPackages -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewPackages</p>
              <p class="description">Object - View/edit packages</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- other - viewMarketing -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewMarketing</p>
              <p class="description">Object - View/edit marketing</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- other - viewSubscriptions -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewSubscriptions</p>
              <p class="description">Object - View/edit subscriptions</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- other - viewMessenger -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewMessenger</p>
              <p class="description">Object - View/edit messages</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- other - viewOrders -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewOrders</p>
              <p class="description">Object - View/edit orders</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- other - viewActions -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewActions</p>
              <p class="description">Object - View/edit actions</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- other - viewGiftcards -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewGiftcards</p>
              <p class="description">Object - View gift cards</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- other - viewLoyalty -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">viewLoyalty</p>
              <p class="description">Object - View loyalty point options</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- other - editGiftcards -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">editGiftcards</p>
              <p class="description">Object - Edit/generate gift cards</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- other - endDay -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">endDay</p>
              <p class="description">Object - Can End Day</p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="response">
      <h3>Response</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">permissions</p>
              <p class="examples">
                Examples:
                <span
                  >{ "bookings": { "permissions": { "ownCalendar": { "basic":
                  false, "title": "Access Own Calendar", "disabled": true,
                  "low": true, "medium": true, "high": true }, "othersCalendar":
                  { "title": "Access others calendars", "disabled": false,
                  "basic": true, "low": true, "medium": true, "high": true },
                  "bookAppointments": { "title": "Can book appointments",
                  "disabled": false, "basic": false, "low": true, "medium":
                  true, "high": true }, "viewHome": { "title": "Home",
                  "disabled": false, "basic": false, "low": false, "medium":
                  false, "high": true }, "viewClients": { "title": "Clients",
                  "disabled": false, "basic": false, "low": true, "medium":
                  true, "high": true } }, "title": "Bookings" }, "tickets": {
                  "title": "Tickets", "permissions": { "editDashboardTickets": {
                  "title": "Can edit dashboard tickets", "disabled": false,
                  "basic": false, "low": true, "medium": true, "high": true },
                  "checkOutTickets": { "title": "Can check out tickets",
                  "disabled": false, "basic": true, "low": true, "medium": true,
                  "high": true }, "editPricesAtCheckout": { "title": "Can edit
                  prices at checkout", "disabled": false, "basic": false, "low":
                  false, "medium": true, "high": true },
                  "addDiscountsAtCheckout": { "title": "Can add discounts",
                  "disabled": false, "basic": false, "low": false, "medium":
                  true, "high": true }, "setPrepaidAtCheckout": { "title": "Can
                  set items as prepaid", "disabled": false, "basic": false,
                  "low": false, "medium": true, "high": true },
                  "setRequestedAtCheckout": { "title": "Can set items as
                  requested", "disabled": false, "basic": false, "low": false,
                  "medium": true, "high": true }, "viewSummary": { "title":
                  "Summary", "disabled": false, "basic": false, "low": true,
                  "medium": false, "high": true }, "viewTickets": { "title":
                  "View Tickets", "disabled": false, "basic": false, "low":
                  true, "medium": true, "high": true }, "editTickets": {
                  "title": "Edit Tickets", "disabled": false, "basic": false,
                  "low": false, "medium": false, "high": true },
                  "viewOwnReports": { "title": "View own staff reports",
                  "disabled": false, "basic": false, "low": true, "medium":
                  true, "high": true }, "viewOthersReports": { "title": "View
                  other staff reports", "disabled": false, "basic": true, "low":
                  true, "medium": true, "high": true },
                  "codeToViewOthersReports": { "title": "Require code to view
                  others", "disabled": false, "basic": false, "low": true,
                  "medium": true, "high": false }, "editShifts": { "title":
                  "Edit team member shifts", "disabled": false, "basic": false,
                  "low": false, "medium": false, "high": true },
                  "editTurnsOnItems": { "title": "Edit turns on items",
                  "disabled": false, "basic": false, "low": false, "medium":
                  false, "high": true }, "editAdditionalTurns": { "title": "Edit
                  additional turn count", "disabled": false, "basic": false,
                  "low": false, "medium": true, "high": true } } }, "staff": {
                  "title": "Staff", "permissions": { "viewOwnWorkingHours": {
                  "title": "View own working hours", "disabled": true, "basic":
                  true, "low": true, "medium": true, "high": true },
                  "editOwnWorkingHours": { "title": "Edit own working hours",
                  "disabled": false, "basic": false, "low": false, "medium":
                  true, "high": true }, "canClockSelfIn": { "title": "Can clock
                  self in", "disabled": false, "basic": false, "low": false,
                  "medium": true, "high": true }, "canClockOthersIn": { "title":
                  "Can clock others in", "disabled": false, "basic": false,
                  "low": false, "medium": false, "high": true },
                  "viewOthersWorkingHours": { "title": "View others working
                  hours", "disabled": false, "basic": false, "low": false,
                  "medium": true, "high": true }, "editOthersWorkingHours": {
                  "title": "Edit others working hours", "disabled": false,
                  "basic": false, "low": false, "medium": false, "high": true },
                  "viewStaffMembers": { "title": "View/edit staff members",
                  "disabled": false, "basic": false, "low": true, "medium":
                  true, "high": true }, "viewClientContactInfo": { "title":
                  "View client contact info", "disabled": false, "basic": false,
                  "low": false, "medium": true, "high": true },
                  "canUseTempUser": { "title": "Can Use Temp User Mode",
                  "disabled": false, "basic": false, "low": true, "medium":
                  true, "high": true }, "canViewSalonNotifications": { "title":
                  "Can view salon notifications", "disabled": false, "basic":
                  false, "low": false, "medium": true, "high": true },
                  "canEditOwnCapableServices": { "title": "Can edit own capable
                  services", "disabled": false, "basic": false, "low": true,
                  "medium": true, "high": true }, "canViewFormulas": { "title":
                  "View/edit formulas", "disabled": false, "basic": true, "low":
                  true, "medium": true, "high": true }, "canViewMessenger": {
                  "title": "View/send messages", "disabled": false, "basic":
                  false, "low": false, "medium": true, "high": true },
                  "canEditRotationOrder": { "title": "Can edit rotation order",
                  "disabled": false, "basic": true, "low": false, "medium":
                  false, "high": true } } }, "requireCode": { "title": "Require
                  Code", "permissions": { "generateGiftCard": { "title":
                  "Generate Gift Card", "disabled": false, "basic": false,
                  "low": false, "medium": false, "high": false },
                  "useOtherPaymentMethod": { "title": "Use Other Payment
                  Method", "disabled": false, "basic": true, "low": true,
                  "medium": true, "high": false }, "editAdditionalTurns": {
                  "title": "Edit additional turn count", "disabled": false,
                  "basic": true, "low": true, "medium": true, "high": false },
                  "deleteAppointments": { "title": "Delete Appointments",
                  "disabled": false, "basic": true, "low": true, "medium": true,
                  "high": false }, "checkoutUnapprovedTickets": { "title":
                  "Checkout Unapproved Tickets", "disabled": false, "basic":
                  true, "low": true, "medium": true, "high": false },
                  "editRotationOrder": { "title": "Edit rotation order",
                  "disabled": false, "basic": true, "low": true, "medium": true,
                  "high": false } } }, "other": { "title": "Other",
                  "permissions": { "viewProducts": { "title": "View/edit
                  products", "disabled": false, "basic": false, "low": false,
                  "medium": false, "high": true }, "viewServices": { "title":
                  "View/edit services", "disabled": false, "basic": false,
                  "low": false, "medium": false, "high": true },
                  "viewDiscounts": { "title": "View/edit discounts", "disabled":
                  false, "basic": false, "low": false, "medium": false, "high":
                  true }, "viewPackages": { "title": "View/edit packages",
                  "disabled": false, "basic": false, "low": false, "medium":
                  false, "high": true }, "viewMarketing": { "title": "View/edit
                  marketing", "disabled": false, "basic": false, "low": false,
                  "medium": false, "high": true }, "viewSubscriptions": {
                  "title": "View/edit subscriptions", "disabled": false,
                  "basic": false, "low": false, "medium": true, "high": true },
                  "viewMessenger": { "title": "View/edit messages", "disabled":
                  false, "basic": false, "low": true, "medium": true, "high":
                  true }, "viewOrders": { "title": "View/edit orders",
                  "disabled": false, "basic": false, "low": true, "medium":
                  true, "high": true }, "viewActions": { "title": "View/edit
                  actions", "disabled": false, "basic": false, "low": false,
                  "medium": true, "high": true }, "viewGiftcards": { "title":
                  "View gift cards", "disabled": false, "basic": false, "low":
                  false, "medium": true, "high": true }, "viewLoyalty": {
                  "title": "View loyalty point options", "disabled": false,
                  "basic": false, "low": false, "medium": false, "high": true },
                  "editGiftcards": { "title": "Edit/generate gift cards",
                  "disabled": false, "basic": false, "low": false, "medium":
                  true, "high": true }, "endDay": { "title": "Can End Day",
                  "disabled": false, "basic": false, "low": false, "medium":
                  true, "high": true } } }, "_id": "63c3067a9735496e5960a2b5" }
                </span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="reqResExamples">
      <h3>Examples</h3>

      <!-- Request -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Request</h4>
          <p>application/json</p>
        </div>

        <ul>
          <li>
            <p>
              { "bookings": { "permissions": { "ownCalendar": { "basic": false }
              } } }
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    apiUrl() {
      return process.env.VUE_APP_RASERVA_BACKEND;
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  padding: 12px;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 16px;

  &-info {
    background-color: #c3edf7;
    color: #044653;
    border-color: #aceaf7;

    .bold {
      font-weight: 700;
    }
  }
}
.endpoint {
  .tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px;
    border-radius: 5px;
    color: white;

    &-blue {
      background-color: var(--clr-secondary);
    }
  }

  &_head {
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }

    .route {
      padding: 8px 16px;
      border-radius: 5px;
      background-color: var(--clr-white-2);
      display: flex;
      align-items: center;
      gap: 16px;

      .url {
        color: var(--clr-gray);

        span {
          color: var(--clr-primary);
          font-weight: 700;
        }
      }
    }

    .description {
      margin-top: 32px;
    }
  }

  &_body {
    .request,
    .response,
    .reqResExamples {
      margin-top: 32px;
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &_section {
      &:not(:first-child) {
        margin-top: 32px;
      }

      .head {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 20px;
          margin-bottom: 16px;
          color: var(--clr-secondary);
        }

        p {
          font-size: 14px;
          color: var(--clr-gray);
        }
      }

      ul {
        .subitem {
          margin-left: 32px;
        }

        li {
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          &:not(:first-child) {
            margin-top: 16px;
          }

          .value {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .bold {
            font-weight: 700;
          }
          .required {
            color: orangered;
          }

          .description {
            margin: 4px 0;
            color: var(--clr-gray);
          }

          .examples {
            span {
              background-color: var(--clr-white-2);
              padding: 4px 8px;
              border-radius: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
