<template>
  <section class="clientSearch">
    <div class="clientSearch_head">
      <form @submit.prevent="submit" class="form">
        <BaseInput
          placeholder="Search by name, email, phone.."
          icon="fas fa-search"
          @icon-click="submit"
          @input="searchValue = $event"
        />
      </form>
    </div>
    <div class="clientSearch_body">
      <div v-if="!clients.length" class="none">
        <h3>No data to display</h3>
        <p>Try another search query</p>
      </div>
      <div v-else class="clients">
        <div
          @click="$emit('selected', client)"
          v-for="client in clients"
          :key="client._id"
          class="client"
        >
          <div class="col">
            <p class="light" v-if="client.globalClient">Raserva account</p>
            <h3>{{ `${client.firstName} ${client.lastName}` }}</h3>
          </div>
          <div class="col right">
            <p class="link">{{ client.email }}</p>
            <p class="link">{{ client.phoneNumber }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  emits: ['selected'],
  data() {
    return {
      searchValue: '',
      clients: [],
    };
  },
  methods: {
    async submit() {
      if (this.searchValue.length < 2) {
        this.$toast.warning('Please search more characters');
        return;
      }

      try {
        this.clients = await this.$store.dispatch('clients/searchClients', {
          name: this.searchValue,
          phone: this.searchValue,
          email: this.searchValue,
          global: true,
        });
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.clientSearch {
  &_body {
    margin-top: 32px;

    .link {
      color: var(--clr-link);
    }
    .light {
      font-size: 14px;
      color: var(--clr-gray);
    }

    .none {
      text-align: center;
      padding: 16px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;

      p {
        margin-top: 8px;
      }
    }

    .client {
      padding: 16px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      transition: border-color 0.2s ease;

      &:not(:first-child) {
        margin-top: 16px;
      }

      &:hover {
        border-color: var(--clr-gray);
      }

      h3 {
        font-size: 17px;
      }

      .right {
        text-align: right;
      }
    }
  }
}
</style>
