<template>
  <section class="rotation">
    <div class="rotation_head">
      <BaseSelect
        label="Group Filter"
        :options="filterSecondaryOptions"
        :value="filter"
        @input="filterStaff($event)"
      />
    </div>

    <!-- Avail -->
    <div class="rotation_avail">
      <p class="bold small">Available:</p>

      <!-- None -->
      <div
        v-if="availableStaffSorted && !availableStaffSorted.length"
        class="none"
      >
        <p>No staff to display</p>
      </div>

      <ul>
        <li
          v-for="staff in availableStaffSorted"
          :key="staff._id"
          @click="$emit('selectStaff', staff)"
        >
          <div class="col">
            <div class="staffInfo">
              <div class="avatar">
                <Avatar
                  @click="$emit('selectStaff', staff)"
                  mode="large rounded"
                  :name="staff.firstName"
                  :image="staff.profileImage"
                  :extraText="
                    staff.turns || staff.additionalTurns
                      ? `(${(staff.turns || 0) + (staff.additionalTurns || 0)})`
                      : null
                  "
                />

                <div
                  class="available"
                  :class="{
                    'avail-n': !staff.available,
                    'avail-y': staff.available,
                  }"
                ></div>
                <div v-if="staff.working" class="working">
                  <i class="far fa-clock"></i>
                </div>
              </div>
              <div class="text">
                <p class="bold">{{ `${staff.firstName} ${staff.lastName}` }}</p>

                <p v-if="filter !== 'Non Calendar'" class="small mt-s">
                  Turns:
                  <span class="bold">{{
                    (staff.turns || 0) + (staff.additionalTurns || 0)
                  }}</span>
                </p>
                <p v-else class="small mt-s">
                  Clock In:
                  <span class="bold">{{ formatDate(staff.clockedInAt) }}</span>
                </p>

                <p v-if="!classicView && staff.clockedInAt" class="small mt-s">
                  Last Clock In:
                  <span class="bold">{{ formatDate(staff.clockedInAt) }}</span>
                </p>
              </div>
            </div>
          </div>
          <div
            v-if="!classicView"
            class="col toggleAvail"
            @click="toggleAvailability(staff)"
          >
            <i class="fas fa-toggle-on"></i>
          </div>
        </li>
      </ul>
    </div>

    <!-- Unavail -->
    <div v-if="!classicView" class="rotation_avail">
      <p class="bold small">Unavailable:</p>

      <!-- None -->
      <div v-if="unavailableStaff && !unavailableStaff.length" class="none">
        <p>No staff to display</p>
      </div>

      <ul>
        <li v-for="staff in unavailableStaff" :key="staff._id">
          <div class="col">
            <div class="staffInfo">
              <div class="avatar">
                <Avatar
                  @click="$emit('selectStaff', staff)"
                  mode="large rounded"
                  :name="staff.firstName"
                  :image="staff.profileImage"
                  :extraText="
                    staff.turns || staff.additionalTurns
                      ? `(${(staff.turns || 0) + (staff.additionalTurns || 0)})`
                      : null
                  "
                />

                <div
                  class="available"
                  :class="{
                    'avail-n': !staff.available,
                    'avail-y': staff.available,
                  }"
                ></div>
                <div v-if="staff.working" class="working">
                  <i class="far fa-clock"></i>
                </div>
              </div>
              <div class="text">
                <p class="bold">{{ `${staff.firstName} ${staff.lastName}` }}</p>
                <p class="small mt-s">
                  Turns:
                  <span class="bold">{{
                    (staff.turns || 0) + (staff.additionalTurns || 0)
                  }}</span>
                </p>
                <p v-if="staff.clockedInAt" class="small mt-s">
                  Last Clock In:
                  <span class="bold">{{ formatDate(staff.clockedInAt) }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="col toggleAvail" @click="toggleAvailability(staff)">
            <i class="fas fa-toggle-off"></i>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    filter: {
      type: String,
      default: 'All Staff',
    },
    staff: {
      type: Array,
      required: true,
    },
    classicView: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectStaff', 'changeFilter'],
  watch: {
    staff() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  computed: {
    tickets() {
      return this.$store.state.tickets.tickets;
    },
    availableStaff() {
      const staff = JSON.parse(JSON.stringify(this.staffs));

      this.tickets.forEach((ticket) => {
        ticket.items.forEach((item) => {
          if (!item.item.duration) return;

          if (item.staff) {
            const index = staff.findIndex(
              (staff) => staff.staffId === item.staff.staffId
            );

            if (index !== -1) {
              if (!staff[index].turns) {
                staff[index].turns = 0;
              }

              staff[index].turns += item.item.turns || 0;
            }
          }
        });
      });

      return staff.filter((staff) => staff.working && staff.available);
    },
    unavailableStaff() {
      const staff = JSON.parse(JSON.stringify(this.staffs));

      this.tickets.forEach((ticket) => {
        ticket.items.forEach((item) => {
          if (item.staff) {
            const index = staff.findIndex(
              (staff) => staff.staffId === item.staff.staffId
            );

            if (index !== -1) {
              if (!staff[index].turns) {
                staff[index].turns = 0;
              }

              staff[index].turns += item.item.turns;
            }
          }
        });
      });

      return staff.filter((staff) => !staff.working || !staff.available);
    },
    availableStaffSorted() {
      let staff = JSON.parse(JSON.stringify(this.availableStaff));

      staff = staff.sort((a, b) => {
        return this.$moment(b.clockedInAt || this.$moment()).isBefore(
          a.clockedInAt || this.$moment()
        )
          ? 1
          : -1;
      });

      staff = staff.sort((a, b) => {
        return (
          (a.turns || 0) +
          (a.additionalTurns || 0) -
          ((b.turns || 0) + (b.additionalTurns || 0))
        );
      });

      return staff;
    },
  },
  data() {
    return {
      staffs: [],
      filterSecondaryOptions: [],
    };
  },
  methods: {
    async init() {
      this.staffs = JSON.parse(JSON.stringify(this.staff));

      await this.addGroupsToSecondaryFilterOptions();

      this.filterStaff(this.filter);
    },

    async addGroupsToSecondaryFilterOptions() {
      if (!this.$store.state.auth.salon) return;

      const filterSecondaryOptions = [
        { value: 'All Staff', option: 'All Staff' },
        { value: 'Non Calendar', option: 'Non Calendar' },
      ];

      this.$store.state.auth.salon.groups.forEach((group) => {
        filterSecondaryOptions.push({
          value: group,
          option: group,
        });
      });

      this.filterSecondaryOptions = filterSecondaryOptions;
    },

    filterStaff(filter) {
      this.$emit('changeFilter', filter);

      if (filter === 'All Staff') {
        this.staffs = JSON.parse(JSON.stringify(this.staff));
      } else if (filter === 'Non Calendar') {
        this.staffs = JSON.parse(
          JSON.stringify(
            this.$store.state.auth.salon.staff.filter(
              (staff) => staff.working && !staff.onCalendar
            )
          )
        );
      } else {
        this.staffs = this.staff.filter((staff) =>
          staff.groups.find((group) => group === filter)
        );
      }
    },

    formatDate(date) {
      return this.$moment(date).format('LLL');
    },

    async toggleAvailability(staff) {
      try {
        const response = await this.$store.dispatch(
          'staff/toggleAvailability',
          staff
        );

        this.$toast.success(response);
        this.init();
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rotation {
  padding: 32px;

  &_avail {
    margin-top: 32px;

    ul {
      li {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        .staffInfo {
          margin-top: 16px;
          display: flex;
          align-items: center;
          gap: 16px;
        }
        .toggleAvail {
          i {
            font-size: 32px;
            cursor: pointer;
          }

          .fa-toggle-on {
            color: var(--clr-secondary);
          }
          .fa-toggle-off {
            color: var(--clr-light);
          }
        }
      }
    }
  }
}

.small {
  font-size: 13px;
}
.bold {
  font-weight: 700;
}
.mt-s {
  margin-top: 5px;
}
.none {
  margin-top: 16px;
  padding: 8px 16px;
  border: 1px solid var(--clr-light);
  border-radius: 5px;
  background-color: var(--clr-white);
}

.avatar {
  position: relative;

  .available {
    position: absolute;
    bottom: 5px;
    border: 1px solid white;
    right: 0;
    height: 15px;
    width: 15px;
    border-radius: 50%;
  }
  .working {
    position: absolute;
    bottom: -4px;
    border: 1px solid white;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: var(--clr-secondary);
    font-size: 12px;
    color: white;
    display: grid;
    place-items: center;
  }
  .avail-y {
    background-color: var(--clr-success);
  }
  .avail-n {
    background-color: var(--clr-danger);
  }
}
</style>
