<template>
  <Spinner v-if="loading" />

  <section v-else class="teams">
    <div class="teams_head">
      <div class="col">
        <h2>Teams</h2>
      </div>

      <div class="col actions">
        <BaseButton @click="showTeamForm = true" class="small"
          >Create</BaseButton
        >
      </div>
    </div>

    <div class="teams_body">
      <div v-if="!teams.length" class="none">
        <p>No teams to display</p>
      </div>

      <ul v-else>
        <li v-for="team in teams" :key="team.id">
          <div class="col">
            <p>
              Name:
              <span class="bold">{{ team.name }}</span>
            </p>
            <p>
              Members: <span class="bold">{{ team.members.length }}</span>
            </p>
          </div>

          <div class="col">
            <BaseButton
              @click="selectedTeam = team"
              mode="primary-outline small"
              >Edit</BaseButton
            >
          </div>
        </li>
      </ul>
    </div>
  </section>

  <Modal
    title="Team Form"
    :show="showTeamForm || selectedTeam"
    @close="
      showTeamForm = false;
      selectedTeam = null;
    "
  >
    <div class="teamForm">
      <HighLevelTeamForm
        :team="selectedTeam"
        @submit="formSubmitted($event)"
        @removeTeam="removeTeam"
      />
    </div>
  </Modal>
</template>

<script>
import HighLevelTeamForm from '@/components/integrations/highLevel/highLevelTeamForm.vue';

export default {
  components: {
    HighLevelTeamForm,
  },
  created() {
    this.init();
  },
  data() {
    return {
      loading: false,
      teams: [],

      showTeamForm: false,
      selectedTeam: null,
    };
  },
  methods: {
    async init() {
      this.loading = true;

      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/highLevel/teams?salonId=${this.$store.state.auth.salon._id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.teams = response.data.teams;
      } catch (err) {
        console.log(err);
      }

      this.loading = false;
    },

    formSubmitted(team) {
      const index = this.teams.findIndex((t) => t.id === team.id);

      if (index === -1) {
        this.teams.push(team);
      } else {
        this.teams[index] = team;
      }

      this.showTeamForm = false;
      this.selectedTeam = null;
    },

    removeTeam() {
      const index = this.teams.findIndex((t) => t.id === this.selectedTeam.id);

      if (index !== -1) {
        this.teams.splice(index, 1);
      }

      this.showTeamForm = false;
      this.selectedTeam = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.teams {
  &_head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 28px;
    }

    .actions {
      display: flex;
      gap: 16px;
    }
  }

  &_body {
    margin-top: 32px;
    display: flex;
    gap: 16px;

    ul {
      width: 100%;

      li {
        width: 100%;
        padding: 16px;
        border-radius: 5px;
        background-color: var(--clr-white);
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        &:not(:first-child) {
          margin-top: 16px;
        }

        .bold {
          font-weight: 700;
        }
      }
    }
  }
}

.teamForm {
  padding: 32px;
}
</style>
