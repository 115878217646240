<template>
  <section class="clientsList">
    <div class="clientsList_head">
      <h2>Clients list</h2>
    </div>

    <div class="clientsList_actions">
      <div class="col inputs">
        <form @submit.prevent="searchClients" class="form">
          <BaseInput
            placeholder="Search by name, email or number"
            icon="fas fa-search"
            @input="searchValue = $event"
            :value="searchValue"
            @icon-click="searchClients"
          />
        </form>
        <BaseSelect
          :options="sortOptions"
          :value="selectedSortOption"
          @input="changeSort($event)"
        />
      </div>
      <div class="col buttons">
        <BaseButton
          @click="
            selectedClient = null;
            showClientForm = true;
          "
          >Add Client</BaseButton
        >
      </div>
    </div>

    <Pagination
      :limit="25"
      :page="page"
      :data="clients"
      @nextPage="nextPage"
      @prevPage="prevPage"
    />

    <div class="clientsList_list">
      <table>
        <thead>
          <tr>
            <th>Client</th>
            <th class="hide-tablet">Raserva Account</th>
            <th class="hide-tablet">Email</th>
            <th class="hide-tablet">Phone</th>
            <th class="tablet"></th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(client, index) in clients"
            :key="index"
            @click="selectClient(client)"
          >
            <td class="client">
              <Avatar :name="client.firstName" mode="rounded" />
              <p>{{ `${client.firstName} ${client.lastName || ''}` }}</p>
            </td>
            <td class="hide-tablet">
              <i v-if="client.globalClient" class="fas fa-check"></i>
            </td>
            <td class="hide-tablet">{{ client.email }}</td>
            <td class="hide-tablet">{{ client.phoneNumber }}</td>
            <td class="tablet"><i class="fas fa-ellipsis-h"></i></td>
          </tr>
        </tbody>
      </table>
    </div>

    <Pagination
      :limit="25"
      :page="page"
      :data="clients"
      @nextPage="nextPage"
      @prevPage="prevPage"
    />
  </section>

  <Modal
    title="Client Form"
    fullScreen
    :show="showClientForm"
    @close="showClientForm = false"
  >
    <div class="clientForm">
      <ClientForm
        :client="selectedClient"
        @created="
          clients.push($event);
          showClientForm = false;
        "
        @updated="
          updatedClient($event);
          showClientForm = false;
        "
        @deleted="
          deletedClient($event);
          showClientForm = false;
        "
      />
    </div>
  </Modal>

  <Modal
    title="New Appointment"
    @close="showAddAppointmentModal = false"
    :show="showAddAppointmentModal"
    fullScreen
  >
    <AppointmentForm
      :appointment="appointment"
      @created="appointmentCreated($event, 'Created')"
    />
  </Modal>

  <Modal
    title="New Ticket"
    @close="showAddTicketModal = false"
    :show="showAddTicketModal"
    fullScreen
  >
    <TicketForm
      :ticket="ticket"
      @created="ticketCreated($event, 'Created')"
      @checkedOut="checkoutTicket($event)"
    />
  </Modal>

  <SidePanel :show="selectedClient" @close="selectedClient = null">
    <div class="clientDetails">
      <ClientDetails
        :client="selectedClient"
        @book="bookAppointment(selectedClient, $event)"
        @sell="createTicket(selectedClient)"
        @edit="editClient(selectedClient)"
        @clientUpdated="searchClients"
      />
    </div>
  </SidePanel>

  <!-- Merge List -->
  <div v-if="clientsToMerge.length" class="merge">
    <div @click="clientsToMerge.length = 0" class="merge-exit">
      <i class="fas fa-times"></i>
    </div>

    <div class="merge_head">
      <h2>Merge Clients</h2>
      <p>
        The first client selected will be the main client; Name, number, and
        other basic info will be pulled from this client.
      </p>
    </div>

    <ul>
      <li v-for="(client, index) in clientsToMerge" :key="client._id">
        <p>{{ `${client.firstName} ${client.lastName}` }}</p>
        <i @click="clientsToMerge.splice(index, 1)" class="fas fa-times"></i>
      </li>
    </ul>

    <div class="merge_actions">
      <BaseButton
        :disabled="clientsToMerge.length <= 1"
        @click="showConfirmMerge = true"
        >Merge</BaseButton
      >
    </div>
  </div>

  <Confirm
    v-if="showConfirmMerge"
    title="Merge Clients"
    text="Are you sure you wish to merge these clients? This action can not be undone."
    @confirm="mergeClients"
    @deny="showConfirmMerge = false"
  />
</template>

<script>
import ClientForm from '@/components/clients/ClientForm.vue';
import ClientDetails from '@/components/clients/ClientDetails.vue';

import AppointmentForm from '@/views/dashboard/AppointmentForm.vue';
import TicketForm from '@/views/dashboard/TicketForm.vue';

export default {
  components: {
    ClientForm,
    ClientDetails,
    AppointmentForm,
    TicketForm,
  },
  created() {
    if (!this.$route.query.name) {
      this.init();
    } else {
      this.searchValue = this.$route.query.name;
      this.searchClients();
    }
  },
  watch: {
    loggedInStaff() {
      this.init();
    },
  },
  computed: {
    clientsToMerge() {
      return this.$store.state.clients.clientsToMerge;
    },
    loggedInStaff() {
      return this.$store.state.auth.loggedInSalonStaff;
    },
    sortOptions() {
      return [
        {
          option: 'First Name (A-Z)',
          value: 'firstAsc',
        },
        {
          option: 'First Name (Z-A)',
          value: 'firstDesc',
        },
        {
          option: 'Last Name (A-Z)',
          value: 'lastAsc',
        },
        {
          option: 'Last Name (Z-A)',
          value: 'lastDesc',
        },
      ];
    },
    objectWithClient() {
      return { client: this.client };
    },
  },
  data() {
    return {
      clients: [],
      selectedClient: null,

      appointment: {},
      ticket: {},

      searchValue: '',
      selectedSortOption: 'firstAsc',

      showClientForm: false,
      showAddAppointmentModal: false,
      showAddTicketModal: false,

      page: 1,

      showConfirmMerge: false,
    };
  },
  methods: {
    async init() {
      this.clients = await this.$store.dispatch('clients/searchClients');
    },
    selectClient(client) {
      this.selectedClient = this.clients.find(
        (preClient) => preClient._id === client._id
      );
    },
    bookAppointment(client, appointment) {
      this.selectedClient = null;

      this.appointment = { ...appointment, client };

      this.showAddAppointmentModal = true;
    },
    createTicket(client) {
      this.selectedClient = null;

      this.ticket = { client };

      this.showAddTicketModal = true;
    },
    async checkoutTicket(ticket) {
      try {
        await this.$store.dispatch('tickets/checkoutTicket', ticket);
        this.showAddTicketModal = false;

        this.$toast.success('Ticket checked out');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    editClient(client) {
      this.selectedClient = client;
      this.showClientForm = true;
    },
    async prevPage() {
      this.page--;

      this.clients = await this.$store.dispatch('clients/searchClients', {
        name: this.searchValue,
        phone: this.searchValue,
        email: this.searchValue,
        page: this.page,
      });
    },
    async nextPage() {
      this.page++;

      this.clients = await this.$store.dispatch('clients/searchClients', {
        name: this.searchValue,
        phone: this.searchValue,
        email: this.searchValue,
        page: this.page,
      });
    },
    async searchClients() {
      if (this.searchValue.length === 1) {
        this.$toast.warning('Search value must be at least 2 characters');
        return;
      }

      if (!this.searchValue) this.page = 1;

      this.clients = await this.$store.dispatch('clients/searchClients', {
        name: this.searchValue,
        phone: this.searchValue,
        email: this.searchValue,
        page: this.page,
      });

      this.changeSort(this.selectedSortOption);
    },
    async appointmentCreated(appointment, action) {
      await this.$store.dispatch('appointments/createAppointment', appointment);

      this.$toast.success(`Appointment ${action}`);
      this.showAddAppointmentModal = false;
    },
    async ticketCreated(ticket, action) {
      await this.$store.dispatch('tickets/createTicket', ticket);

      this.$toast.success(`Ticket ${action}`);
      this.showAddTicketModal = false;
    },
    updatedClient(client) {
      const index = this.clients.findIndex(
        (preClient) => preClient._id === client._id
      );

      if (index === -1) return;

      this.clients[index] = client;
    },
    deletedClient(id) {
      const index = this.clients.findIndex(
        (preClient) => preClient._id.toString() === id
      );

      if (index === -1) return;

      this.clients.splice(index, 1);
    },
    changeSort(sortOption) {
      this.selectedSortOption = sortOption;

      this.clients = this.clients.sort((a, b) => {
        if (this.selectedSortOption === 'firstAsc') {
          return a.firstName < b.firstName
            ? -1
            : a.firstName > b.firstName
            ? 1
            : 0;
        } else if (this.selectedSortOption === 'firstDesc') {
          return a.firstName > b.firstName
            ? -1
            : a.firstName < b.firstName
            ? 1
            : 0;
        } else if (this.selectedSortOption === 'lastAsc') {
          return a.lastName < b.lastName ? -1 : a.lastName > b.lastName ? 1 : 0;
        } else {
          return a.lastName > b.lastName ? -1 : a.lastName < b.lastName ? 1 : 0;
        }
      });
    },
    async mergeClients() {
      if (this.clientsToMerge.length <= 1) {
        this.$toast.warning('You must select at least 2 clients to merge');
        return;
      }

      try {
        const client = await this.$store.dispatch('clients/mergeClients');

        this.$toast.success('Clients merged');

        this.searchValue = `${client.firstName} ${client.lastName}`;

        this.clients = await this.$store.dispatch('clients/searchClients', {
          name: this.searchValue,
        });
      } catch (e) {
        this.$toast.error(e.message);
      }

      this.showConfirmMerge = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.clientsList {
  width: 100%;
  max-width: 1000px;
  position: relative;

  &_head {
    h2 {
      font-size: 28px;
    }
  }

  &_actions {
    margin-top: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-extra-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 32px;

    .buttons,
    .inputs {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
  }

  &_list {
    overflow-x: auto;
  }
}

.clientDetails {
  overflow-y: auto;
  height: 100%;
  padding: 32px;
}

.clientForm {
  width: 1000px;
  max-width: 100%;
  margin: 32px auto;
}

.merge {
  max-width: 400px;
  padding: 32px;
  background-color: white;
  border-radius: 5px;
  box-shadow: var(--shadow);
  position: absolute;
  bottom: 10%;
  left: 10%;

  &-exit {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: var(--clr-danger);
    display: grid;
    place-items: center;
    color: white;
  }

  &_head {
    text-align: center;

    h2 {
      font-size: 28px;
    }
    p {
      margin-top: 8px;
      color: var(--clr-gray);
    }
  }

  ul {
    margin-top: 32px;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 32px;
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);

      .fa-times {
        color: var(--clr-danger);
        cursor: pointer;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  &_actions {
    margin-top: 32px;

    button {
      width: 100%;
    }
  }
}

table {
  .client {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

// Tablet
@media (max-width: 900px) {
  .clientsList {
    form {
      width: 100%;
    }

    &_actions {
      .buttons,
      .inputs {
        width: 100%;

        button,
        .parent {
          width: 100%;
        }
      }
    }
  }
}
</style>
