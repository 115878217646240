<template>
  <form @submit.prevent="connectToTerminal" class="form">
    <BaseSelect
      label="Terminal"
      :value="selectedTerminal"
      @input="selectedTerminal = $event"
      :options="terminalOptions"
    />

    <div
      class="additional"
      @click="showAdditionalOptions = !showAdditionalOptions"
    >
      <i v-if="!showAdditionalOptions" class="fas fa-caret-down"></i>
      <i v-else class="fas fa-caret-up"></i>
      <p>{{ showAdditionalOptions ? 'Hide' : 'Show' }} additional options</p>
    </div>

    <div v-if="showAdditionalOptions" class="additionalOptions">
      <div class="toggle">
        <Toggle :checked="force" @toggle="force = !force" />
        <div class="toggle_text">
          <p class="toggle_text_label">Force</p>
          <p class="toggle_text_subtext">
            Existing sessions will be destroyed and all in-flight operations on
            the terminal will be cancelled
          </p>
        </div>
      </div>
      <div class="toggle">
        <Toggle
          :checked="includeCustomTipAmount"
          @toggle="includeCustomTipAmount = !includeCustomTipAmount"
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Include Custom Amount</p>
          <p class="toggle_text_subtext">
            Determines if a custom tip button is displayed on the terminal
          </p>
        </div>
      </div>
      <BaseTextarea
        label="Tip Prompt"
        :value="tipPrompt"
        @input="tipPrompt = $event"
      />
      <div class="col-3">
        <BaseInput
          inputType="number"
          label="Preset One"
          :value="amount1"
          @input="amount1 = +$event"
        />
        <BaseInput
          inputType="number"
          label="Preset Two"
          :value="amount2"
          @input="amount2 = +$event"
        />
        <BaseInput
          inputType="number"
          label="Preset Three"
          :value="amount3"
          @input="amount3 = +$event"
        />
      </div>
    </div>

    <div class="form_actions">
      <BaseButton type="button" mode="primary-outline" @click="bypassTerminal"
        >Bypass Terminal</BaseButton
      >
      <BaseButton
        v-if="loading"
        @click="cancel"
        mode="danger"
        type="button"
        :disabled="cancelLoading"
      >
        <i v-if="cancelLoading" class="fas fa-spinner"></i>
        Cancel Request
      </BaseButton>
      <BaseButton :disabled="loading">
        <i v-if="loading" class="fas fa-spinner"></i>
        Send To Terminal</BaseButton
      >
    </div>
  </form>
</template>

<script>
export default {
  emits: ['submit'],
  props: {
    total: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.init();
  },
  async unmounted() {
    if (!this.connected) return;

    if (this.loading) await this.cancel();

    await this.disconnectFromTerminal();
  },
  computed: {
    terminalOptions() {
      const terminals = [];

      this.terminals.forEach((terminal) => {
        terminals.push({
          option: terminal,
          value: terminal,
        });
      });
      return terminals;
    },
  },
  data() {
    return {
      terminals: [],
      selectedTerminal: '',
      connected: false,
      session: null,
      force: false,

      loading: false,
      cancelLoading: false,

      showAdditionalOptions: false,

      tipPrompt: 'Add Tip?',
      includeCustomTipAmount: true,
      amount1: 20,
      amount2: 25,
      amount3: 30,
    };
  },
  methods: {
    init() {
      this.getTerminals();
    },

    async getTerminals() {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/listTerminals?salonId=${this.$store.state.auth.salon._id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.terminals = response.data.terminals;
        this.selectedTerminal = this.terminals[0];
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          this.$toast.error(error.response.data.error);
        } else {
          this.$toast.error(error.message);
        }
      }
    },

    bypassTerminal() {
      this.$emit('submit', 0);
    },

    async connectToTerminal() {
      this.loading = true;

      if (this.connected && this.session) {
        this.sendAmountToTerminal();
        return;
      }

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/connect?merchantId=${this.$store.state.auth.salon.billing.cardconnect.mid}`,
          {
            hsn: this.selectedTerminal,
            force: this.force,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.session = response.data.session;
        this.connected = true;

        this.sendAmountToTerminal();
      } catch (error) {
        this.loading = false;

        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          this.$toast.error(error.response.data.error);
        } else {
          this.$toast.error(error.message);
        }
      }
    },

    async sendAmountToTerminal() {
      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/tip?merchantId=${this.$store.state.auth.salon.billing.cardconnect.mid}`,
          {
            hsn: this.selectedTerminal,
            prompt: this.tipPrompt,
            includeCustomTipAmount: this.includeCustomTipAmount,
            session: this.session,
            tip1: this.amount1,
            tip2: this.amount2,
            tip3: this.amount3,
            amount: this.total,
            confirmAmount: this.confirmAmount,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$emit('submit', +response.data.data.tip);
      } catch (error) {
        console.log(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          this.$toast.error(error.response.data.error);
        } else {
          this.$toast.error(error.message);
        }
      }

      this.loading = false;
    },

    async cancel() {
      this.cancelLoading = true;

      try {
        await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/cancel?merchantId=${this.$store.state.auth.salon.billing.cardconnect.mid}`,
          {
            hsn: this.selectedTerminal,
            session: this.session,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.loading = false;
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          this.$toast.error(error.response.data.error);
        } else {
          this.$toast.error(error.message);
        }
      }

      this.cancelLoading = false;
    },

    async disconnectFromTerminal() {
      try {
        await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/disconnect?merchantId=${this.$store.state.auth.salon.billing.cardconnect.mid}`,
          {
            hsn: this.selectedTerminal,
            session: this.session,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          this.$toast.error(error.response.data.error);
        } else {
          this.$toast.error(error.message);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .additionalOptions {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .col-3 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
    }
  }

  .additional {
    display: flex;
    gap: 16px;
    align-items: center;
    color: var(--clr-link);
    cursor: pointer;
  }

  .toggle {
    display: flex;
    align-items: center;
    gap: 32px;
    flex-wrap: wrap;
    padding: 16px 0;

    &_text {
      &_label {
        font-size: 18px;
      }
      &_subtext {
        margin-top: 5px;
        font-size: 14px;
      }
    }
  }

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
