<template>
  <section class="set">
    <div class="set_sidebar">
      <SubSidebar :routes="routes" title="Settings" />
    </div>
    <div class="set_router">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import SubSidebar from '@/components/components/SubSidebar.vue';

export default {
  components: {
    SubSidebar,
  },
  created() {
    if (this.$route.query.info) {
      this.$toast.info(this.$route.query.info);
    }
  },
  data() {
    return {
      routes: [
        {
          name: 'All Settings',
          linkName: 'SettingsList',
        },
        {
          name: 'Permissions',
          linkName: 'MemberPermissions',
        },
        {
          name: 'Salon Info',
          linkName: 'SalonInfo',
        },
        {
          name: 'Schedule',
          linkName: 'SalonSchedule',
        },
        {
          name: 'Booking',
          linkName: 'BookingSettings',
        },
        {
          name: 'Payments',
          linkName: 'PaymentSettings',
        },
        {
          name: 'Admin',
          linkName: 'AdminSettings',
        },
        {
          name: 'Kiosk',
          linkName: 'KioskSettings',
        },
        {
          name: 'Secrets',
          linkName: 'SecretsSettings',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.set {
  display: flex;
  height: 100%;

  &_router {
    overflow-y: auto;
    flex-grow: 1;
    padding: 32px;
    display: flex;
    justify-content: center;
  }
}
</style>
