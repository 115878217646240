<template>
  <Spinner fullscreen v-if="!user" />

  <section v-else class="user">
    <div class="user_head">
      <h1>
        {{ user.details.shopName
        }}<span v-if="user.details.altName"> - {{ user.details.altName }}</span>
      </h1>
      <p v-if="user.details.address.street" class="address">
        {{
          `${user.details.address.street}, ${user.details.address.city}, ${user.details.address.state}`
        }}
      </p>
    </div>

    <div class="user_actions">
      <BaseButton @click="showSendNotificationModal = true"
        ><i class="fas fa-paper-plane"></i> Send Notification</BaseButton
      >
      <BaseButton @click="showExportModal = true"
        ><i class="fas fa-file-export"></i> Export</BaseButton
      >
      <BaseButton
        v-if="adminManages.includes('all')"
        @click="showManagementModal = true"
        ><i class="fas fa-user-shield"></i> Admin Management</BaseButton
      >
      <BaseButton @click="showPermissionsModal = true"
        ><i class="fas fa-cog"></i> Permissions</BaseButton
      >
    </div>

    <div class="user_details">
      <!-- Basic info -->
      <div class="section">
        <div class="section_head">
          <p class="title">Salon Info</p>
        </div>

        <div class="section_data">
          <p>
            Processor: <span class="bold">{{ user.payments.processor }}</span>
          </p>

          <!-- Charge anywhere -->
          <div
            v-if="user.payments.processor === 'chargeanywhere'"
            class="group chargeanywhere"
          >
            <p>
              Merchant ID:
              <span class="bold">{{ user.billing.chargeanywhere.mid }}</span>
            </p>
            <p>
              Terminal ID:
              <span class="bold">{{ user.billing.chargeanywhere.tid }}</span>
            </p>
            <p>
              Payment form MID:
              <span class="bold">{{ user.billing.chargeanywhere.pfmid }}</span>
            </p>
            <p>
              Payment form TID:
              <span class="bold">{{ user.billing.chargeanywhere.pftid }}</span>
            </p>
            <p>
              Identification:
              <span class="bold">{{
                user.billing.chargeanywhere.identification
              }}</span>
            </p>
          </div>

          <p>
            Timezone: <span class="bold">{{ user.details.timezone }}</span>
          </p>
        </div>
      </div>

      <!-- Data quantities -->
      <div class="section">
        <div class="section_head">
          <p class="title">Data Quantities</p>
        </div>

        <div class="section_data">
          <p>
            Service categories:
            <span class="bold">{{ user.services.length }}</span>
          </p>
          <p>
            Services:
            <span class="bold">{{ services.length }}</span>
          </p>
          <p>
            Product categories:
            <span class="bold">{{ user.products.length }}</span>
          </p>
          <p>
            Products:
            <span class="bold">{{ products.length }}</span>
          </p>
          <p>
            Staff: <span class="bold">{{ user.staff.length }}</span>
          </p>
          <p>
            Discounts: <span class="bold">{{ user.discounts.length }}</span>
          </p>
          <p>
            Packages: <span class="bold">{{ user.packages.length }}</span>
          </p>
          <p>
            Distributors:
            <span class="bold">{{ user.distributors.length }}</span>
          </p>
        </div>
      </div>

      <!-- Marketing/etc -->
      <div class="section">
        <div class="section_head">
          <p class="title">Texts/Emails (Resets on subscription renewal)</p>
          <i @click="showMarketingForm = true" class="fas fa-edit"></i>
        </div>

        <div class="section_data">
          <p>
            Emails sent:
            <span class="bold">{{ user.marketing.emails.sent }}</span>
          </p>
          <p>
            Emails cost:
            <span class="bold">{{ user.marketing.emails.cost }}</span>
          </p>
          <p>
            Texts sent:
            <span class="bold">{{ user.marketing.texts.sent }}</span>
          </p>
          <p>
            Texts cost:
            <span class="bold">{{ user.marketing.texts.cost }}</span>
          </p>
          <p>
            Alerts sent:
            <span class="bold">{{ user.marketing.texts.alertsSent }}</span>
          </p>
          <p>
            Alerts cost:
            <span class="bold">{{ user.marketing.texts.alertsCost }}</span>
          </p>
          <p>
            App Confirmations sent:
            <span class="bold">{{
              user.marketing.texts.appointmentConfirmationsSent
            }}</span>
          </p>
          <p>
            App Confirmations cost:
            <span class="bold">{{
              user.marketing.texts.appointmentConfirmationsCost
            }}</span>
          </p>
        </div>
      </div>

      <!-- Billing Cycles -->
      <div class="section">
        <div class="section_head">
          <p class="title">Billing Cycles</p>
        </div>

        <div class="section_data">
          <p v-for="cycle in user.billing.cycles" :key="cycle._id">
            {{ cycle.month }}:
            <span class="bold">{{ cycle.seats }}</span> seats,
            <span class="bold">{{ cycle.emailsSent }}</span> emails sent,
            <span class="bold">{{ cycle.textsSent }}</span> texts sent,
            <span class="bold">{{ cycle.alertsSent }}</span> alerts sent,
            <span class="bold">{{ cycle.subscriptionFees }}</span> subscription
            fees
          </p>
        </div>
      </div>
    </div>
  </section>

  <!-- Modals -->
  <Modal
    title="Edit Marketing Data"
    :show="showMarketingForm"
    @close="showMarketingForm = false"
  >
    <form @submit.prevent="submitMarketingForm" class="form">
      <BaseInput
        label="Emails Cost"
        type="number"
        :value="emailCost"
        @input="emailCost = +$event"
      />

      <BaseInput
        label="Texts Cost"
        type="number"
        :value="textCost"
        @input="textCost = +$event"
      />

      <div class="form_actions">
        <BaseButton color="primary">Submit</BaseButton>
      </div>
    </form>
  </Modal>

  <Modal
    title="Edit Permissions"
    :show="showPermissionsModal"
    @close="showPermissionsModal = false"
  >
    <div class="updateAdmin">
      <Spinner v-if="loading" fullscreen />

      <div class="form">
        <BaseInput
          placeholder="Name, Id, or Stripe Id.."
          @input="accountSearch = $event"
        />
      </div>

      <div v-if="!users.length" class="none">
        <h3>Please search users</h3>
        <p>No users found</p>
      </div>

      <ul class="users_list" v-else>
        <li
          v-for="user in filteredUsers"
          :key="user._id"
          @click="
            selectedSwapPermissionsAccount = user;
            confirmEditPermissions = true;
          "
        >
          <div class="text">
            <div class="name-status">
              <div
                class="status"
                :class="{
                  active: user.billing
                    ? isDatePast(user.billing.currentPeriodEnd)
                      ? false
                      : true
                    : false,
                }"
              ></div>

              <p>
                {{ user.details.shopName
                }}<span v-if="user.details.altName">
                  - {{ user.details.altName }}</span
                >
              </p>
            </div>

            <p v-if="user.details.address.street" class="address">
              {{
                `${user.details.address.street}, ${user.details.address.city}, ${user.details.address.state}`
              }}
            </p>
          </div>

          <div class="admin">
            <div class="text">
              <p class="bold small">Admin</p>
              <p>{{ `${user.adminId.firstName} ${user.adminId.lastName}` }}</p>
            </div>

            <Avatar
              :image="user.adminId.profileImage"
              :name="user.adminId.firstName"
            />
          </div>
        </li>
      </ul>
    </div>
  </Modal>

  <Modal
    title="Export Data"
    :show="showExportModal"
    @close="showExportModal = false"
    maxWidth="250px"
  >
    <div class="export">
      <ul>
        <li>
          <BaseButton @click="exportData('staff')"
            ><i v-if="loading" class="fas fa-spinner"></i>Staff</BaseButton
          >
        </li>
        <li>
          <BaseButton @click="exportData('services')"
            ><i v-if="loading" class="fas fa-spinner"></i>Services</BaseButton
          >
        </li>
        <li>
          <BaseButton @click="exportData('products')"
            ><i v-if="loading" class="fas fa-spinner"></i>Products</BaseButton
          >
        </li>
        <li>
          <BaseButton @click="exportData('clients')"
            ><i v-if="loading" class="fas fa-spinner"></i>Clients</BaseButton
          >
        </li>
      </ul>
    </div></Modal
  >

  <Modal
    title="Admin Management"
    :show="showManagementModal"
    @close="showManagementModal = false"
    maxWidth="600px"
  >
    <div class="management">
      <ul class="toggle_list">
        <li v-for="accountType in accountTypes" :key="accountType">
          <div class="toggle">
            <Toggle
              :checked="isManagedBy(accountType)"
              @toggle="toggleIsManagedBy(accountType)"
            />
            <div class="toggle_text">
              <p class="toggle_text_label">{{ accountType }}</p>
            </div>
          </div>
        </li>
      </ul>

      <div class="management_actions">
        <BaseButton @click="updateManagedBy">Submit</BaseButton>
      </div>
    </div></Modal
  >

  <Modal
    title="Send Notification"
    :show="showSendNotificationModal"
    @close="showSendNotificationModal = false"
    maxWidth="600px"
  >
    <form @submit.prevent="sendNotification" class="form">
      <BaseSelect
        label="Type"
        :options="[
          { option: 'Message', value: 'message' },
          { option: 'Appointment', value: 'appointment' },
        ]"
        :value="notification.type"
        @input="notification.type = $event"
      />

      <BaseTextarea
        label="Message"
        type="textarea"
        :value="notification.message"
        @input="notification.message = $event"
      />

      <BaseInput
        label="Link (Optional)"
        :value="notification.link"
        @input="notification.link = $event"
        subtitle="Make sure it's a Raserva link, just the ending, example: /dashboard/marketing/auto"
      />

      <div class="form_actions">
        <BaseButton color="primary">Submit</BaseButton>
      </div>
    </form>
  </Modal>

  <Confirm
    v-if="confirmEditPermissions"
    title=""
    :text="`Are you sure you wish to update this salons permisions to match the newly selected salons permissions? This action can not be undone.`"
    @confirm="updatePermissions"
    @deny="confirmEditPermissions = false"
  />
</template>

<script>
export default {
  async created() {
    try {
      const response = await this.$axios.get(
        `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$route.params.id}`
      );

      this.user = response.data.salon;

      this.emailCost = this.user.marketing.emails.cost;
      this.textCost = this.user.marketing.texts.cost;
      this.managedBy = JSON.parse(JSON.stringify(this.user.managedBy));

      let query = "";

      this.adminManages.forEach((account) => (query += `${account}=true`));

      this.users = await this.$store.dispatch("admin/getUsers", query);
    } catch (err) {
      console.error(err);
    }
  },
  computed: {
    admin() {
      return this.$store.state.admin.admin;
    },
    adminManages() {
      return this.admin.manages;
    },
    services() {
      const services = [];

      this.user.services.forEach((cat) => {
        cat.items.forEach((item) => {
          services.push(item);
        });
      });

      return services;
    },
    products() {
      const products = [];

      this.user.products.forEach((cat) => {
        cat.items.forEach((item) => {
          products.push(item);
        });
      });

      return products;
    },
    filteredUsers() {
      let users = JSON.parse(JSON.stringify(this.users));

      if (this.accountSearch) {
        users = users.filter((user) => {
          return (
            `${user.details.shopName} ${user.details.altName}`
              .toLowerCase()
              .includes(this.accountSearch.toLowerCase()) ||
            user._id.toLowerCase().includes(this.accountSearch.toLowerCase()) ||
            (user.billing.stripe.customerId || "")
              .toLowerCase()
              .includes(this.accountSearch.toLowerCase())
          );
        });
      }

      return users;
    },
    accountTypes() {
      return ["epsg", "dejavoo"];
    },
  },
  data() {
    return {
      user: null,
      showMarketingForm: false,
      emailCost: null,
      textCost: null,

      showPermissionsModal: false,
      showExportModal: false,
      showManagementModal: false,
      showSendNotificationModal: false,

      notification: {
        type: "message",
        message: "",
        link: "",
      },

      loading: false,
      accountSearch: "",
      users: [],
      selectedSwapPermissionsAccount: null,
      confirmEditPermissions: false,

      managedBy: [],
    };
  },
  methods: {
    activeUserHasPerm(perm) {
      if (!this.admin) return false;

      return this.admin.permissions.includes(perm);
    },

    isManagedBy(accountType) {
      return this.user.managedBy.includes(accountType);
    },
    toggleIsManagedBy(accountType) {
      console.log(accountType);
      if (this.isManagedBy(accountType)) {
        this.managedBy = this.user.managedBy.filter((a) => a !== accountType);
      } else {
        this.managedBy.push(accountType);
      }
    },

    async submitMarketingForm() {
      if (!this.activeUserHasPerm("users:edit:marketingCosts")) {
        return this.$toast.error(
          "You do not have permission to edit marketing costs"
        );
      }
      try {
        await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin/salon/marketing`,
          {
            emailCost: this.emailCost,
            textCost: this.textCost,
            salonId: this.user._id,
          }
        );

        this.$toast.success("Marketing data updated successfully");

        this.user.marketing.emails.cost = this.emailCost;
        this.user.marketing.texts.cost = this.textCost;

        this.showMarketingForm = false;
      } catch (e) {
        this.$toast.error(e.message);
      }
    },

    async updatePermissions() {
      try {
        await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin/salon/permissions`,
          {
            permissions: this.selectedSwapPermissionsAccount.permissions,
            salonId: this.user._id,
          }
        );

        this.user.permissions = JSON.parse(
          JSON.stringify(this.selectedSwapPermissionsAccount.permissions)
        );

        this.confirmEditPermissions = false;
        this.showPermissionsModal = false;

        this.$toast.success("Permissions updated successfully");
      } catch (e) {
        this.$toast.error(e.message);
      }
    },

    async updateManagedBy() {
      try {
        await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin/salon/managedBy`,
          {
            managedBy: this.managedBy,
            salonId: this.user._id,
          }
        );

        this.user.managedBy = JSON.parse(JSON.stringify(this.managedBy));

        this.showManagementModal = false;

        this.$toast.success("Management updated successfully");
      } catch (e) {
        this.$toast.error(e.message);
      }
    },

    isDatePast(date) {
      return this.$moment(date).isBefore(this.$moment());
    },

    async sendNotification() {
      try {
        if (!this.notification.message) {
          return this.$toast.error("Please enter a message");
        }
        if (!this.notification.type) {
          return this.$toast.error("Please select a type");
        }

        await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin/salons/${this.user._id}/notifications`,
          this.notification
        );

        this.$toast.success("Notification sent");
        this.showSendNotificationModal = false;
      } catch (error) {
        console.error(error);
      }
    },

    async exportData(type) {
      this.loading = true;

      let csvContent = "";
      let title = "";

      if (type === "staff") {
        // Export staff
        title = "raserva_staff_export";

        csvContent +=
          "First Name,Last Name,Title,Email,Phone,Birthday,Commission,Hourly Rate\n";

        const staffs = [];

        for (let i = 0; i < this.user.staff.length; i++) {
          const staff = this.user.staff[i];

          staffs.push({
            firstName: `"${staff.firstName?.replace(/['"]+/g, "")}"`,
            lastName: `"${staff.lastName?.replace(/['"]+/g, "")}"`,
            title: `"${staff.title?.replace(/['"]+/g, "")}"`,
            email: `"${staff.email?.replace(/['"]+/g, "")}"`,
            phone: staff.phoneNumber,
            birthday: staff.birthday,
            commission: staff.commission,
            hourlyRate: staff.hourlyRate,
          });
        }

        staffs.forEach((staff) => {
          csvContent += `${Object.values(staff).join(",")}\n`;
        });
      } else if (type === "services") {
        // Export services
        title = "raserva_service_export";

        csvContent +=
          "Category,Title,Price Type,Price,Duration,Description,Commissioned,Commission Type,Commission Amount\n";

        const services = [];

        for (let i = 0; i < this.user.services.length; i++) {
          const category = this.user.services[i];

          for (let j = 0; j < category.items.length; j++) {
            const service = category.items[j];

            services.push({
              category: `"${category.title?.replace(/['"]+/g, "")}"`,
              title: `"${service.title?.replace(/['"]+/g, "")}"`,
              price_type: service.priceType,
              price: service.price,
              duration: service.duration,
              description: `"${service.description?.replace(/['"]+/g, "")}"`,
              commissioned: service.isCommissioned,
              commission_type: service.commissionType,
              commission_amount:
                service.commissionType !== "percent"
                  ? service.commissionAmount
                  : null,
            });
          }
        }

        services.forEach((service) => {
          csvContent += `${Object.values(service).join(",")}\n`;
        });
      } else if (type === "products") {
        // Export products
        title = "raserva_product_export";

        csvContent +=
          "Category,Title,Price,Short Description,Long Description,Tax Rate,Stock,Commission Type,Commission Amount\n";

        const products = [];

        for (let i = 0; i < this.user.products.length; i++) {
          const category = this.user.products[i];

          for (let j = 0; j < category.items.length; j++) {
            const product = category.items[j];

            products.push({
              category: `"${category.title?.replace(/['"]+/g, "")}"`,
              title: `"${product.title?.replace(/['"]+/g, "")}"`,
              price: product.price,
              short_description: `"${product.shortDescription?.replace(
                /['"]+/g,
                ""
              )}"`,
              long_description: `"${product.longDescription?.replace(
                /['"]+/g,
                ""
              )}"`,
              tax_rate: product.taxRate,
              stock: product.stock,
              commission_type: product.commissionType,
              commission_amount: product.commissionAmount,
            });
          }
        }

        products.forEach((product) => {
          csvContent += `${Object.values(product).join(",")}\n`;
        });
      } else if (type === "clients") {
        // Export Clients
        title = "raserva_client_export";

        csvContent +=
          "First Name,Last Name,Phone Number,Email,Gender,DOB Year,DOB Month,DOB Day\n";

        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin/salons/${this.user._id}/clientsForExport`
        );

        const db_clients = response.data.clients;
        const clients = [];

        for (let i = 0; i < db_clients.length; i++) {
          const client = db_clients[i];

          clients.push({
            firstName: `"${client.firstName?.replace(/['"]+/g, "")}"`,
            lastName: `"${client.lastName?.replace(/['"]+/g, "")}"`,
            phoneNumber: client.phoneNumber,
            email: `"${client.email?.replace(/['"]+/g, "")}"`,
            gender: client.gender,
            dob_year: client.dateOfBirth?.year,
            dob_month: client.dateOfBirth?.month
              ? client.dateOfBirth?.month + 1
              : null,
            dob_day: client.dateOfBirth?.date,
          });
        }

        clients.forEach((product) => {
          csvContent += `${Object.values(product).join(",")}\n`;
        });
      }

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, `${title}.csv`);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", `${title}.csv`);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.user {
  max-width: 1000px;
  margin: auto;
  margin-top: 32px;

  &_head {
    p {
      margin-top: 5px;
      color: var(--clr-gray);
    }
  }

  &_actions {
    margin-top: 32px;
    padding: 16px;
    background-color: var(--clr-light);
    border-radius: 5px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    button {
      i {
        margin-right: 16px;
      }
    }
  }

  &_details {
    .section {
      margin-top: 32px;

      &_head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        i {
          cursor: pointer;
          color: var(--clr-secondary);
        }
      }
      .title {
        font-size: 14px;
        color: var(--clr-gray);
      }

      .section_data {
        padding: 16px;
        border: 1px solid var(--clr-light);
        border-radius: 5px;
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .group {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        span {
          font-weight: 700;
        }
      }
    }
  }
}

.form {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}

.updateAdmin {
  padding: 32px;

  .form {
    padding: 0;
  }

  .none {
    padding: 16px;
    border-radius: 5px;
    border: 1px solid var(--clr-light);
    text-align: center;
    margin-top: 32px;

    p {
      margin-top: 8px;
      color: var(--clr-gray);
    }
  }

  .users_list {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    li {
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .text {
        .name-status {
          display: flex;
          align-items: center;
          gap: 8px;
        }
        .status {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background-color: var(--clr-danger);
        }

        .active {
          background-color: var(--clr-success) !important;
        }
        .address {
          font-size: 14px;
          color: var(--clr-gray);
          margin-top: 5px;
        }
      }

      .admin {
        display: flex;
        align-items: center;
        gap: 16px;

        .text {
          text-align: right;

          .bold {
            font-weight: 600;
          }

          .small {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.export {
  padding: 32px;

  ul {
    display: flex;
    flex-direction: column;
    gap: 16px;

    button {
      width: 100%;

      i {
        margin-right: 16px;
      }
    }
  }
}

.management {
  padding: 32px;
  .toggle_list {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;

    .toggle {
      display: flex;
      align-items: center;
      gap: 32px;
      flex-wrap: wrap;
      padding: 16px 0;

      &_text {
        &_label {
          font-size: 18px;
        }
        &_subtext {
          margin-top: 5px;
          font-size: 14px;
        }
      }
    }
  }

  &_actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}

// Mobile
@media (max-width: 600px) {
  .user {
    padding: 16px;

    &_head {
      text-align: center;
    }
  }
}
</style>
