<template>
  <section class="clientList">
    <ul v-if="clients.length">
      <li
        v-for="(client, index) in clients"
        :key="index"
        @click="$emit('selectClient', client)"
      >
        <ClientListItem :client="client" :basic="basic" />
      </li>
    </ul>
    <div v-else class="none">
      <h3>No clients to display!</h3>
      <p>Consider creating a client.</p>
    </div>
  </section>
</template>

<script>
import ClientListItem from '@/components/clients/ClientListItem.vue';

export default {
  emits: ['selectClient'],
  components: {
    ClientListItem,
  },
  props: {
    clients: {
      type: Array,
      required: true,
      default: () => [],
    },
    basic: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.clientList {
  ul {
    li {
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }

  .none {
    border: 1px solid var(--clr-light);
    border-radius: 5px;
    padding: 16px;
    text-align: center;

    p {
      margin-top: 8px;
    }
  }
}
</style>
