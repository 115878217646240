<template>
  <section class="doc">
    <div class="doc_head">
      <h2>Appointments</h2>
      <p class="light small">
        In this guide we will go over appointment creation, options, and more.
      </p>
    </div>
    <div class="doc_body">
      <p class="section">
        Appointments are displayed on your calendar as rectangular, colored
        boxes. Appointments will fall under the chosen time slot and under the
        selected team member performing the service(s) on the appointment.
        Appointments can have multiple services, as well as multiple team
        members. In the appointment box you will have the following data; start
        time, end time, client name/appointment title, appointment status,
        service title, and useful icons determining whether the appointment was
        booked online, team member was requested by the client, and more.
      </p>
      <p class="section">
        Your calendar, which holds appointments and tickets, can be navigated to
        by selecting the
        <i class="fas fa-calendar mx"></i> icon on the sidebar. Please have the
        calendar screen available for reference while viewing this guide.
      </p>
      <div class="section">
        <h4>1) Creating An Appointment</h4>
        <p class="section">
          To create an appointment, navigate to your desired day using the date
          selection bar, located above the calendar, and then either select a
          time by clicking a timeslot on the calendar, or select "New
          Appointment" on the "Create" dropdown button. If a desired time was
          selected, then you will arrive at the "Create Appointment" screen,
          with your selected time slot pre-filled into the first service on the
          appointment. If you selected "New Appointment", from the "Create"
          button, then you will need to manually specify the starting time of
          the first service on the appointment.
        </p>

        <p class="section">
          Once you've specified your starting time on your appointment's first
          service, it's time to add your client to the appointment. At the right
          side of the screen, you will find the ability to "Search Client" or
          select <i class="fas fa-plus mx"></i> Create New Client, to create a
          client from scratch. By design, we recommend you to always begin by
          searching for a client's phone number in the "Search Client" field. If
          the client is not in your records, the system will return a message
          saying "No clients to display!" From here, you can select
          <i class="fas fa-plus mx"></i> Create New Client to access a field
          where you may enter a client's information. If you've used this
          workflow, the phone number used in your initial search will populate
          in the information field that you will use when creating this client's
          new profile. The minimum requirement for a new client is "First name"
          and "Mobile Number." Other data may be entered if needed, such as
          email, date of birth, and gender. Additional info may be updated on
          the client's profile at a later date by editing the client from the
          clients tab. Once you're satisfied that the minimum amount of
          information was filled into your client form, you can select "Submit"
          to simultaneously create the client and add them to your new
          appointment.
        </p>

        <p class="section">
          Now that you assigned a client on the appointment, you can move on to
          specifying the requested services and which professional(s) will be
          assigned to serve the guest on that day. Next to the "Start Time"
          section, you will find a section for "Service" and a section for
          "Staff", below it.
        </p>

        <p class="section">
          When adding a service to the appointment, you can either select the
          <i class="fas fa-angle-down fa-lg"></i> icon to reveal a dropdown menu
          of all the salon's services (ordered by category), or you can select
          any place on the "Select a service" box and begin typing the desired
          service. When you find the service you're looking to add, select it
          from the drop-down menu and it will automatically be added to the
          first appointment item. Now you are ready to assign the appropriate
          (or requested) team member for the service, and complete your
          appointment.
        </p>

        <p class="section">
          Turning our attention to the section titled "Staff", we can select the
          <i class="fas fa-angle-down fa-lg"></i> icon or interact with any
          place on the field to reveal a drop-down menu with all team members
          that are capable of performing services. We would select the desired
          team member to assign them to the appointment. You should consider
          that some team members require more time to complete a service than
          otherwise specified on the service (by default). You can easily add or
          subtract duration time from an appointment according to the team
          member's requirements. Next to the team member, you may select the
          <i class="fas fa-heart mx"></i> icon if this service was requested to
          be with this team member. To add multiple services to the appointment,
          select the "Add Service" button and complete the above process.<br />
          <br />
          When you are ready to complete this booking, select the "Save" button
          at the bottom right of the screen to complete your appointment!
        </p>
      </div>
      <div class="section">
        <h4>2) Managing An Appointment</h4>
        <p class="section">
          To edit an appointment, locate and select the appointment on the
          calendar. This form will be nearly identical to the appointment
          creation form, and the values can be adjusted in the same way. You
          will have more data available to you here, such as when the
          appointment was booked, deposit management, and the ability to delete
          the appointment. <br /><br />You can search for a future appointment
          on the "Calendar" screen by finding the button titled "Options",
          located next to the "Create" button. Select the "Options" button and
          choose "Search Future Appointments" from the drop-down menu. Searching
          a client by their First name, Last name, or Phone number, will reveal
          all of their future appointments. You can select your desired
          appointment from that list and continue the editing process.

          <br /><br />
          Once you arrive on the editing screen, you can also update the status
          of the appointment, as the day of the appointment comes closer. There
          are many status types for each appointment available to help you
          better manage the appointment. <br /><br />
          1) "New" is the default status type assigned to every appointment.
          Consider this your "homebase" appointment status. <br /><br />
          2) "Confirmed" is assigned as the day of the appointment comes closer.
          Users will either manually assign this status to an appointment after
          follow-up communication with a guest is made. <br />Fun fact! Raserva
          provides automatic SMS messages requesting your guests to confirm the
          appointment from a link that they receive. If they confirm the
          appointment, this status will automatically apply to the appointment!
          <br /><br />
          3) "Arrived" is the status type used when the guest arrives on
          location. This will create a "Ticket" with the status of
          "In-Progress", on your POS module. This streamlines the
          appointment-to-checkout process by quickly creating a service ticket
          and adding the client and services linked to the appointment to the
          assigned team members, all with the click of a button. <br /><br />
          4) "No Show" is a status that helps you track the customers who
          "no-call, no-show" or simply cancel without appropriate notice, per
          your establishment's policies. If your establishment requires
          pre-authenticated credit cards on file for the purpose of charging
          cancellation fees, you can choose to enforse that charge from a pop-up
          message that appears immediately after you mark a client with this
          status.<br /><br />
          5) "Cancelled" is used so that you can quickly mark an appointment as
          cancelled, while keeping the guest's information on-screen for the
          purpose of contacting them for rescheduling. This status opens this
          time slot to online bookings from other guests who would like to fill
          this newly available time slot.<br /><br />
          Be sure to always select "Save" when managing any appointment to
          ensure you do not lose any updates that you apply to each booking.
        </p>
      </div>

      <ul class="section next">
        <li>
          <h3>Suggested Next Steps:</h3>
        </li>
        <router-link :to="{ name: 'DocsTeamMembers' }">
          <li>
            <p class="link">Adding Team Members</p>
          </li>
        </router-link>
        <router-link :to="{ name: 'DocsServices' }">
          <li>
            <p class="link">Adding Services</p>
          </li>
        </router-link>
        <router-link :to="{ name: 'DocsProducts' }">
          <li>
            <p class="link">Adding Products</p>
          </li>
        </router-link>
        <router-link :to="{ name: 'DocsSettings' }">
          <li>
            <p class="link">Updating Settings</p>
          </li>
        </router-link>
      </ul>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.doc {
  padding-bottom: 64px;

  p {
    line-height: 24px;
  }
  .light {
    margin-top: 8px;
    color: var(--clr-gray);
  }
  .small {
    font-size: 14px;
  }
  .link {
    text-decoration: underline;
    color: var(--clr-link);
    cursor: pointer;
  }
  .mx {
    margin: 0px 8px 0px 8px;
  }
  .italic {
    font-style: italic;
  }
  .bold {
    font-weight: 700;
  }

  &_head {
    h2 {
      font-size: 32px;
    }
  }

  &_body {
    margin-top: 32px;

    .section:not(:first-child) {
      margin-top: 16px;
    }

    .next {
      margin-top: 32px;

      h3 {
        margin-bottom: 16px;
      }

      li:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
}
</style>
