<template>
  <div class="client" :class="{ blacklisted: isBlacklisted }" v-if="client">
    <div class="col ava-name">
      <Avatar :name="client.firstName" mode="rounded large" />
      <div class="text">
        <Tag
          class="tag"
          v-if="isBlacklisted"
          text="Blacklisted"
          backgroundColor="#fbb1b1"
          color="#b91212"
        />
        <p v-if="client.globalClient" class="small">Raserva Account</p>
        <p>{{ getClientName }}</p>
        <p v-if="basic && showClientContactInfo" class="small">
          {{ client.email }}
        </p>
        <p v-if="basic && showClientContactInfo" class="small">
          {{ client.phoneNumber }}
        </p>
      </div>
    </div>
    <div class="col contact" v-if="!basic && showClientContactInfo">
      <p>{{ client.email }}</p>
      <p>{{ client.phoneNumber }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    client: {
      type: Object,
      required: true,
    },
    basic: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.init();
  },
  data() {
    return {
      showClientContactInfo: false,
    };
  },
  methods: {
    async init() {
      this.showClientContactInfo = await this.$store.dispatch(
        'auth/activeUserHasPermission',
        'staff/viewClientContactInfo'
      );
    },
  },
  computed: {
    getClientName() {
      return `${this.client.firstName} ${this.client.lastName || ''}`;
    },
    isBlacklisted() {
      if (!this.client.blacklistedSalons) return false;

      return this.client.blacklistedSalons.includes(
        this.$store.state.auth.salon._id
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.blacklisted {
  border-color: var(--clr-danger) !important;
}
.client {
  padding: 16px;
  border: 1px solid var(--clr-light);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.2s;
  gap: 16px;

  &:hover {
    border-color: var(--clr-gray);
  }

  .ava-name {
    display: flex;
    align-items: center;
    gap: 16px;

    .tag {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 8px;
    }

    .small {
      font-size: 14px;
      color: var(--clr-gray);
      font-weight: 400;
    }

    p {
      font-weight: 700;
      font-size: 18px;
    }
  }
  .contact {
    text-align: right;

    p {
      color: var(--clr-link);

      &:not(:first-child) {
        margin-top: 5px;
      }
    }
  }
}

// Tablet
@media (max-width: 900px) {
  .staff {
    flex-direction: column;

    .ava-name {
      flex-direction: column;
    }

    .contact {
      text-align: center;
    }
  }
}
</style>
