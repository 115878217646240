<template>
  <section class="emailBlast" v-if="salon">
    <div class="visual">
      <p class="vary">* Email may differ slightly visually</p>
      <div class="emailBlast_visual">
        <div class="email_shop">
          <p class="name">{{ salon.details.shopName }}</p>
          <p class="address">
            {{
              `${salon.details.address.street}, ${salon.details.address.city}`
            }}
          </p>
        </div>
        <div v-if="blast.imageSrc" class="email_image">
          <img :src="blast.imageSrc" alt="" />
        </div>
        <div class="email_body">
          <p class="headText">{{ blast.headText }}</p>
          <p class="bodyText">{{ blast.bodyText }}</p>
        </div>
        <div class="email_actions">
          <BaseButton mode="secondary">Book Now</BaseButton>
        </div>
        <div v-if="blast.footText" class="email_foot">
          <p>{{ blast.footText }}</p>
        </div>
      </div>
    </div>
    <div class="emailBlast_details">
      <form @submit.prevent="showConfirm = true" class="form">
        <div class="alert alert-info">
          <p>
            Email blasts will send to all clients with marketing and email
            notifications enabled, you will be charged immediately.
            <span @click="showPricing = true">Click here to view pricing.</span>
          </p>
        </div>
        <BaseInput
          label="Email subject"
          :value="blast.subject"
          @input="blast.subject = $event"
          :validators="{ maxLength: 100 }"
        />
        <ImageSelect
          size="100px"
          @select="blast.imageSrc = $event"
          :fileSource="blast.imageSrc"
        />
        <BaseInput
          label="Head text"
          :value="blast.headText"
          @input="blast.headText = $event"
          :validators="{ maxLength: 100 }"
        />
        <BaseTextarea
          label="Body text"
          :value="blast.bodyText"
          @input="blast.bodyText = $event"
          :validators="{ maxLength: 1500 }"
        />
        <BaseTextarea
          label="Foot text (Optional)"
          :value="blast.footText"
          @input="blast.footText = $event"
          :validators="{ maxLength: 100 }"
        />
        <StripePaymentMethods
          @select="selectedPaymentMethodId = $event"
          returnUrl="dashboard/marketing/email"
          :subject="blast.subject"
          :head="blast.headText"
          :body="blast.bodyText"
          :foot="blast.footText"
          :image="blast.imageSrc"
        />
        <div class="form_actions">
          <div v-if="cost !== null" class="alert alert-info">
            <p>
              Sending to
              <span class="bold">{{ clientsLength }}</span> clients at a total
              cost of
              <span class="bold">{{ formatCurrency(cost) }}</span>
            </p>
          </div>

          <BaseButton
            mode="primary-outline"
            type="button"
            @click="showTestModal = true"
            :disabled="loading"
            ><i v-if="loading" class="fas fa-spinner"></i>Send Test</BaseButton
          >

          <BaseButton
            v-if="!cost"
            @click="calculateCost"
            mode="primary-outline"
            type="button"
          >
            <i v-if="clientsLoading" class="fas fa-spinner"></i>
            Calculate Cost</BaseButton
          >

          <BaseButton :disabled="!formValid">Send Email Blast</BaseButton>
        </div>
      </form>
    </div>
  </section>

  <Modal
    title="Test Email Blast"
    :show="showTestModal"
    @close="showTestModal = false"
  >
    <form @submit.prevent="sendTest" class="form testForm">
      <BaseInput
        inputType="email"
        label="To Email Address"
        subtitle="This email will be sent to the email address provided."
        @input="testEmail = $event"
      />

      <div class="form_actions">
        <BaseButton :disabled="testLoading"
          ><i v-if="testLoading" class="fas fa-spinner"></i> Send
          Test</BaseButton
        >
      </div>
    </form>
  </Modal>

  <Spinner v-if="loading" fullscreen />

  <Confirm
    v-if="showConfirm"
    title="Send email blast"
    text="Are you sure you wish to send this email blast? The card on file will be charged appropriately. Cancel this confirmation and select 'Calculate Cost' to view cost. Minimum charge is $0.50, if total is less than this, you will be charged the minimum."
    @confirm="send"
    @deny="showConfirm = false"
  />

  <Modal :show="showPricing" @close="showPricing = false">
    <MarketingPrices />
  </Modal>
</template>

<script>
import MarketingPrices from '@/components/discounts/MarketingPrices.vue';
import ImageSelect from '@/components/components/ImageSelect.vue';
import StripePaymentMethods from '@/components/marketing/StripePaymentMethods.vue';

export default {
  emits: ['updated'],
  components: {
    MarketingPrices,
    ImageSelect,
    StripePaymentMethods,
  },
  created() {
    // Queries
    if (this.$route.query.subject)
      this.blast.subject = this.$route.query.subject;
    if (this.$route.query.head) this.blast.headText = this.$route.query.head;
    if (this.$route.query.body) this.blast.bodyText = this.$route.query.body;
    if (this.$route.query.foot) this.blast.footText = this.$route.query.foot;
    if (this.$route.query.image) this.blast.imageSrc = this.$route.query.image;
  },
  computed: {
    formValid() {
      if (!this.blast.headText || !this.blast.bodyText || !this.blast.subject)
        return false;

      return true;
    },
    salon() {
      return this.$store.state.auth.salon;
    },
  },
  data() {
    return {
      showPricing: false,
      showConfirm: false,

      loading: false,
      clientsLoading: false,

      clientsLength: null,
      cost: null,

      blast: {
        subject: 'Attention Grabber!',
        imageSrc: '',
        headText: 'Short and sweet summary!',
        bodyText:
          'A paragaph or two of text explaining the point of the email blast, preferably something that will make the client want to click the button below to book!',
        footText: '* Available for a short time!',
      },

      showTestModal: false,
      testEmail: '',
      testLoading: false,

      selectedPaymentMethodId: null,
    };
  },
  methods: {
    async send() {
      if (
        this.blast.bodyText.length > 1500 ||
        this.blast.subject.length > 100 ||
        this.blast.headText.length > 100 ||
        this.blast.footText.length > 100
      ) {
        this.$toast.error('Text exceeds limit');
        return;
      }

      this.showConfirm = false;
      this.loading = true;

      try {
        await this.$store.dispatch('marketing/sendEmailBlast', {
          ...this.blast,
          payment_method: this.selectedPaymentMethodId,
        });

        this.$toast.success('Email blast sent!');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    async calculateCost() {
      this.clientsLoading = true;

      try {
        const response = await this.$store.dispatch(
          'marketing/calculateEmailBlastCost'
        );

        this.clientsLength = response.clientsLength;
        this.cost = response.cost;
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.clientsLoading = false;
    },

    async sendTest() {
      try {
        this.testLoading = true;

        await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/marketing/emailBlastTest`,
          {
            ...this.blast,
            email: this.testEmail,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.showTestModal = false;
        this.testEmail = '';

        this.$toast.success('Test email sent successfully');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.testLoading = false;
    },

    formatCurrency(amount) {
      if (!amount) return;

      return `$${amount.toFixed(2)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.emailBlast {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 64px;

  .alert {
    padding: 12px;
    font-size: 14px;
    border-radius: 5px;

    .bold {
      text-decoration: none;
      font-weight: 700;
      cursor: default;
    }
    span {
      text-decoration: underline;
      cursor: pointer;
    }

    &-info {
      background-color: #c3edf7;
      color: #044653;
      border-color: #aceaf7;
    }
  }

  .toggle {
    display: flex;
    align-items: center;
    gap: 32px;
    flex-wrap: wrap;
    padding: 16px 0;

    &_text {
      &_label {
        font-size: 18px;
      }
      &_subtext {
        margin-top: 5px;
        font-size: 14px;
      }

      .link {
        color: var(--clr-link);
        cursor: pointer;
      }
    }
  }

  .vary {
    font-size: 14px;
    margin-bottom: 16px;
  }

  &_visual {
    max-width: 600px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;
    padding: 32px;
    box-shadow: var(--shadow-large);
    align-self: flex-start;

    .email {
      &_shop {
        margin-bottom: 32px;

        .name {
          font-size: 24px;
        }
        .address {
          color: #a7a4a4;
          font-size: 14px;
          margin-top: 5px;
        }
      }

      &_image {
        display: grid;
        place-items: center;
        margin-bottom: 32px;

        img {
          max-width: 100%;
        }
      }

      &_body {
        .headText {
          font-size: 36px;
        }
        .bodyText {
          margin-top: 32px;
          font-size: 18px;
          color: #969594;
          line-height: 24px;
        }
      }

      &_actions {
        text-align: center;

        button {
          background-color: #333;
          margin-top: 32px;
        }
      }

      &_foot {
        text-align: center;
        margin-top: 32px;
        font-size: 14px;
      }
    }
  }

  &_details {
    .form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .image {
        place-items: start;
      }

      &_actions {
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;
        gap: 16px;
      }
    }
  }
}

.testForm {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;

  .form_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}

// Tablet
@media (max-width: 900px) {
  .emailBlast {
    grid-template-columns: 1fr;
  }
}
</style>
