<template>
  <div class="endpoint_head">
    <h2>Get permissions</h2>

    <div class="route">
      <i
        @click="
          $router.push('/documentation/api/settings#getPermissions');
          $toast.info('Set URL to endpoint');
        "
        :style="{ color: 'var(--clr-secondary)', cursor: 'pointer' }"
        class="fas fa-hashtag"
      ></i>
      <p class="tag tag-blue">GET</p>
      <p class="url">
        {{ apiUrl }}<span>/salons/:id/settings/permissions</span>
      </p>
    </div>

    <div class="description">
      <p>Get a salons permissions settings</p>
    </div>
  </div>

  <div class="endpoint_body">
    <div class="request">
      <h3>Request</h3>

      <!-- Headers -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Headers</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">Authorization</p>
              <p class="examples">Examples: <span>Bearer {Token}</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Path params -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Path Parameters</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">ID</p>
              <p class="description">ID of salon</p>
              <p class="examples">
                Examples: <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6f</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="response">
      <h3>Response</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">permissions</p>
              <p class="examples">
                Examples:
                <span
                  >{ "bookings": { "permissions": { "ownCalendar": { "title":
                  "Access own calendar", "disabled": true, "basic": true, "low":
                  true, "medium": true, "high": true }, "othersCalendar": {
                  "title": "Access others calendars", "disabled": false,
                  "basic": false, "low": true, "medium": true, "high": true },
                  "bookAppointments": { "title": "Can book appointments",
                  "disabled": false, "basic": false, "low": true, "medium":
                  true, "high": true }, "viewHome": { "title": "Home",
                  "disabled": false, "basic": false, "low": false, "medium":
                  true, "high": true }, "viewClients": { "title": "Clients",
                  "disabled": false, "basic": true, "low": true, "medium": true,
                  "high": true } }, "title": "Bookings" }, "tickets": {
                  "permissions": { "editDashboardTickets": { "title": "Can edit
                  dashboard tickets", "disabled": false, "basic": false, "low":
                  true, "medium": true, "high": true }, "checkOutTickets": {
                  "title": "Can check out tickets", "disabled": false, "basic":
                  true, "low": true, "medium": true, "high": true },
                  "editPricesAtCheckout": { "title": "Can edit prices at
                  checkout", "disabled": false, "basic": false, "low": false,
                  "medium": true, "high": true }, "addDiscountsAtCheckout": {
                  "title": "Can add discounts", "disabled": false, "basic":
                  false, "low": false, "medium": true, "high": true },
                  "setPrepaidAtCheckout": { "title": "Can set items as prepaid",
                  "disabled": false, "basic": false, "low": false, "medium":
                  true, "high": true }, "setRequestedAtCheckout": { "title":
                  "Can set items as requested", "disabled": false, "basic":
                  false, "low": false, "medium": true, "high": true },
                  "viewSummary": { "title": "Summary", "disabled": false,
                  "basic": false, "low": true, "medium": false, "high": true },
                  "viewTickets": { "title": "View Tickets", "disabled": false,
                  "basic": false, "low": true, "medium": true, "high": true },
                  "editTickets": { "title": "Edit Tickets", "disabled": false,
                  "basic": false, "low": false, "medium": false, "high": true },
                  "viewOwnReports": { "title": "View own staff reports",
                  "disabled": false, "basic": false, "low": true, "medium":
                  true, "high": true }, "viewOthersReports": { "title": "View
                  other staff reports", "disabled": false, "basic": true, "low":
                  true, "medium": true, "high": true },
                  "codeToViewOthersReports": { "title": "Require code to view
                  others", "disabled": false, "basic": false, "low": true,
                  "medium": true, "high": false }, "editShifts": { "title":
                  "Edit team member shifts", "disabled": false, "basic": false,
                  "low": false, "medium": false, "high": true },
                  "editTurnsOnItems": { "title": "Edit turns on items",
                  "disabled": false, "basic": false, "low": false, "medium":
                  false, "high": true }, "editAdditionalTurns": { "title": "Edit
                  additional turn count", "disabled": false, "basic": false,
                  "low": false, "medium": true, "high": true } }, "title":
                  "Tickets" }, "staff": { "permissions": {
                  "viewOwnWorkingHours": { "title": "View own working hours",
                  "disabled": true, "basic": true, "low": true, "medium": true,
                  "high": true }, "editOwnWorkingHours": { "title": "Edit own
                  working hours", "disabled": false, "basic": false, "low":
                  false, "medium": true, "high": true }, "canClockSelfIn": {
                  "title": "Can clock self in", "disabled": false, "basic":
                  false, "low": false, "medium": true, "high": true },
                  "canClockOthersIn": { "title": "Can clock others in",
                  "disabled": false, "basic": false, "low": false, "medium":
                  false, "high": true }, "viewOthersWorkingHours": { "title":
                  "View others working hours", "disabled": false, "basic":
                  false, "low": false, "medium": true, "high": true },
                  "editOthersWorkingHours": { "title": "Edit others working
                  hours", "disabled": false, "basic": false, "low": false,
                  "medium": false, "high": true }, "viewStaffMembers": {
                  "title": "View/edit staff members", "disabled": false,
                  "basic": false, "low": true, "medium": true, "high": true },
                  "viewClientContactInfo": { "title": "View client contact
                  info", "disabled": false, "basic": false, "low": false,
                  "medium": true, "high": true }, "canUseTempUser": { "title":
                  "Can Use Temp User Mode", "disabled": false, "basic": false,
                  "low": true, "medium": true, "high": true },
                  "canViewSalonNotifications": { "title": "Can view salon
                  notifications", "disabled": false, "basic": false, "low":
                  false, "medium": true, "high": true },
                  "canEditOwnCapableServices": { "title": "Can edit own capable
                  services", "disabled": false, "basic": false, "low": true,
                  "medium": true, "high": true }, "canViewFormulas": { "title":
                  "View/edit formulas", "disabled": false, "basic": true, "low":
                  true, "medium": true, "high": true }, "canViewMessenger": {
                  "title": "View/send messages", "disabled": false, "basic":
                  false, "low": false, "medium": true, "high": true },
                  "canEditRotationOrder": { "title": "Can edit rotation order",
                  "disabled": false, "basic": true, "low": false, "medium":
                  false, "high": true } }, "title": "Staff" }, "requireCode": {
                  "permissions": { "generateGiftCard": { "title": "Generate Gift
                  Card", "disabled": false, "basic": false, "low": false,
                  "medium": false, "high": false }, "useOtherPaymentMethod": {
                  "title": "Use Other Payment Method", "disabled": false,
                  "basic": true, "low": true, "medium": true, "high": false },
                  "editAdditionalTurns": { "title": "Edit additional turn
                  count", "disabled": false, "basic": true, "low": true,
                  "medium": true, "high": false }, "deleteAppointments": {
                  "title": "Delete Appointments", "disabled": false, "basic":
                  true, "low": true, "medium": true, "high": false },
                  "checkoutUnapprovedTickets": { "title": "Checkout Unapproved
                  Tickets", "disabled": false, "basic": true, "low": true,
                  "medium": true, "high": false }, "editRotationOrder": {
                  "title": "Edit rotation order", "disabled": false, "basic":
                  true, "low": true, "medium": true, "high": false } }, "title":
                  "Require Code" }, "other": { "permissions": { "viewProducts":
                  { "title": "View/edit products", "disabled": false, "basic":
                  false, "low": false, "medium": false, "high": true },
                  "viewServices": { "title": "View/edit services", "disabled":
                  false, "basic": false, "low": false, "medium": false, "high":
                  true }, "viewDiscounts": { "title": "View/edit discounts",
                  "disabled": false, "basic": false, "low": false, "medium":
                  false, "high": true }, "viewPackages": { "title": "View/edit
                  packages", "disabled": false, "basic": false, "low": false,
                  "medium": false, "high": true }, "viewMarketing": { "title":
                  "View/edit marketing", "disabled": false, "basic": false,
                  "low": false, "medium": false, "high": true },
                  "viewSubscriptions": { "title": "View/edit subscriptions",
                  "disabled": false, "basic": false, "low": false, "medium":
                  true, "high": true }, "viewMessenger": { "title": "View/edit
                  messages", "disabled": false, "basic": false, "low": true,
                  "medium": true, "high": true }, "viewOrders": { "title":
                  "View/edit orders", "disabled": false, "basic": false, "low":
                  true, "medium": true, "high": true }, "viewActions": {
                  "title": "View/edit actions", "disabled": false, "basic":
                  false, "low": false, "medium": true, "high": true },
                  "viewGiftcards": { "title": "View gift cards", "disabled":
                  false, "basic": false, "low": false, "medium": true, "high":
                  true }, "viewLoyalty": { "title": "View loyalty point
                  options", "disabled": false, "basic": false, "low": false,
                  "medium": false, "high": true }, "editGiftcards": { "title":
                  "Edit/generate gift cards", "disabled": false, "basic": false,
                  "low": false, "medium": true, "high": true }, "endDay": {
                  "title": "Can End Day", "disabled": false, "basic": false,
                  "low": false, "medium": true, "high": true } }, "title":
                  "Other" }, "_id": "63c3067a9735496e5960a2b5" }</span
                >
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    apiUrl() {
      return process.env.VUE_APP_RASERVA_BACKEND;
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoint {
  .tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px;
    border-radius: 5px;
    color: white;

    &-blue {
      background-color: var(--clr-secondary);
    }
  }

  &_head {
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }

    .route {
      padding: 8px 16px;
      border-radius: 5px;
      background-color: var(--clr-white-2);
      display: flex;
      align-items: center;
      gap: 16px;

      .url {
        color: var(--clr-gray);

        span {
          color: var(--clr-primary);
          font-weight: 700;
        }
      }
    }

    .description {
      margin-top: 32px;
    }
  }

  &_body {
    .request,
    .response,
    .reqResExamples {
      margin-top: 32px;
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &_section {
      &:not(:first-child) {
        margin-top: 32px;
      }

      .head {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 20px;
          margin-bottom: 16px;
          color: var(--clr-secondary);
        }

        p {
          font-size: 14px;
          color: var(--clr-gray);
        }
      }

      ul {
        .subitem {
          margin-left: 32px;
        }

        li {
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          &:not(:first-child) {
            margin-top: 16px;
          }

          .value {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .bold {
            font-weight: 700;
          }
          .required {
            color: orangered;
          }

          .description {
            margin: 4px 0;
            color: var(--clr-gray);
          }

          .examples {
            span {
              background-color: var(--clr-white-2);
              padding: 4px 8px;
              border-radius: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
