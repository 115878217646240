export default {
  namespaced: true,
  state: {
    modalZIndex: 5,
  },
  mutations: {
    incrementZIndex(state) {
      state.modalZIndex += 2;
    },
  },
  actions: {
    incrementZIndex({ commit }) {
      commit('incrementZIndex');
    },
  },
  getters: {
    modalZIndex(state) {
      return state.modalZIndex;
    },
  },
};
