<template>
  <section class="blacklisted" v-if="isBlacklisted">
    <h1>You have been blocked from this establishment.</h1>
    <p>
      You may not book an appointment. If this is a mistake or you are unsure
      why, please reach out to this establishment for more info.
    </p>
  </section>

  <section class="confirm" v-else-if="!confirmed">
    <div class="confirm_head" ref="confirm">
      <!-- Default -->
      <div class="default" v-if="salonProcessor === 'stripe'">
        <h2>Please review your information!</h2>
        <p>Additionally you may leave a note along with your appointment.</p>
      </div>

      <!-- Cart -->
      <section v-if="cart.length && !orderFulfilled" class="cart">
        <OnlineShopCartSummary @update="cartUpdated" />
      </section>

      <!-- Cardconnect -->
      <div class="cardconnect" v-if="salonProcessor === 'cardconnect'">
        <h2>Please enter your billing information.</h2>
        <p>
          <span v-if="!total">You will not be charged now, </span>Payment
          information is required for booking.
        </p>

        <div class="cardconnectTokenizer">
          <CardconnectTokenizer @tokenized="setTokenData($event)" />

          <div class="form">
            <BaseInput
              label="CVV"
              inputType="number"
              @input="cardconnectCVV = $event"
            />
            <BaseInput
              label="Postal"
              inputType="number"
              @input="cardconnectPostal = $event"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Dejavoo -->
    <div
      v-if="
        salonProcessor === 'dejavoo' &&
        (djvPaymentFormsSetup ||
          requireCardOnSelectServices ||
          requireDeposit ||
          cart.length) &&
        !dejavooToken
      "
      class="dejavooTokenizer"
    >
      <div class="head">
        <h2>Please enter your billing information.</h2>
        <p>
          <span
            v-if="!total && !requireDeposit && !itemsThatRequireDepositTotal"
            >You will not be charged now, </span
          >Payment information is required for booking.
        </p>

        <p v-if="requireDeposit">
          This salon requires a
          {{ $store.state.booking.salon.bookingSettings.depositPercent }}% (${{
            requiresDepositAmount.toFixed(2)
          }}) deposit to book this appointment.
        </p>
        <p v-else-if="itemsThatRequireDepositTotal">
          This salon requires a ${{ itemsThatRequireDepositTotal.toFixed(2) }}
          deposit to book this appointment.
        </p>
      </div>

      <!-- <ul
        v-if="savedPaymentMethods && savedPaymentMethods.length"
        class="savedPaymentMethods"
      >
        <h3>Use a saved payment method</h3>

        <li v-for="method in savedPaymentMethods" :key="method._id">
          <p>
            <span v-if="method.lastFour" class="bold">****{{ method.lastFour }}</span>
            <span v-if="method.expiry" class="light ml-s">{{ method.expiry }}</span>
          </p>
          <BaseButton
            @click="chargeanywhereToken = method.token"
            mode="primary small"
            >Use Card</BaseButton
          >
        </li>

        <h3 class="mt-m">Or use a new payment method..</h3>
      </ul> -->

      <iframe
        v-if="!orderFulfilled && !cartInvalid && dejavooPaymentFormLink"
        :src="dejavooPaymentFormLink"
        width="100%"
        height="750"
        frameborder="0"
        ref="DJVIframe"
      ></iframe>

      <div v-if="cartInvalid" class="alert alert-warning">
        <p>
          This appointment may contain both products and services. Please remove
          all products to book appointment, then seperately purchase all
          products.
        </p>
      </div>
    </div>

    <!-- Charge Anywhere -->
    <div
      v-if="
        salonProcessor === 'chargeanywhere' &&
        (caPaymentFormsSetup ||
          requireCardOnSelectServices ||
          requireDeposit ||
          cart.length) &&
        !chargeanywhereToken
      "
      class="chargeanywhereTokenizer"
    >
      <div class="head">
        <h2>Please enter your billing information.</h2>
        <p>
          <span
            v-if="!total && !requireDeposit && !itemsThatRequireDepositTotal"
            >You will not be charged now, </span
          >Payment information is required for booking.
        </p>

        <p v-if="requireDeposit">
          This salon requires a
          {{ $store.state.booking.salon.bookingSettings.depositPercent }}% (${{
            requiresDepositAmount.toFixed(2)
          }}) deposit to book this appointment.
        </p>
        <p v-else-if="itemsThatRequireDepositTotal">
          This salon requires a ${{ itemsThatRequireDepositTotal.toFixed(2) }}
          deposit to book this appointment.
        </p>
      </div>

      <ul
        v-if="savedPaymentMethods && savedPaymentMethods.length"
        class="savedPaymentMethods"
      >
        <h3>Use a saved payment method</h3>

        <li v-for="method in savedPaymentMethods" :key="method._id">
          <p>
            <span v-if="method.lastFour" class="bold"
              >****{{ method.lastFour }}</span
            >
            <span v-if="method.expiry" class="light ml-s">{{
              method.expiry
            }}</span>
          </p>
          <BaseButton
            @click="chargeanywhereToken = method.token"
            mode="primary small"
            >Use Card</BaseButton
          >
        </li>

        <h3 class="mt-m">Or use a new payment method..</h3>
      </ul>

      <iframe
        v-if="!orderFulfilled && !cartInvalid"
        :src="caPFsrc"
        width="100%"
        height="400"
        frameborder="0"
        ref="CAIframe"
      ></iframe>

      <div v-if="cartInvalid" class="alert alert-warning">
        <p>
          This appointment may contain both products and services. Please remove
          all products to book appointment, then seperately purchase all
          products.
        </p>
      </div>
    </div>

    <!-- Confirmation -->
    <form
      v-if="showConfirmationButton"
      @submit.prevent="bookAppointment"
      class="confirm_form"
    >
      <!-- Photo Uploads -->
      <div v-if="bookingSettings.allowPhotoUploads" class="photoUploads">
        <div v-if="bookingSettings.photoUploadMessage" class="alert alert-info">
          <p>{{ bookingSettings.photoUploadMessage }}</p>
        </div>

        <ul>
          <li
            v-for="i in Math.min(
              fileUploads.length + 1,
              bookingSettings.photoUploadLimit
            )"
            :key="i"
          >
            <ImageSelect
              @select="fileUploads.push($event)"
              :fileSource="bookingSettings.photoUploadPlaceholder"
              sourceIsPlaceholder
            />
          </li>
        </ul>
      </div>

      <BaseInput
        label="Promotion Code"
        :value="coupon"
        @input="setCoupon($event)"
        subtitle="If this was pre-filled, do not change the value or your coupon may not be applied."
      />
      <BaseTextarea
        label="Notes"
        placeholder="Appointment notes (optional)"
        @input="updateAppointmentNote($event)"
      />
      <div class="form_actions" ref="confirmButton">
        <BaseButton :disabled="loading || !formValid"
          ><i v-if="loading" class="fas fa-spinner"></i> Book
          appointment</BaseButton
        >
        <p class="light">
          By booking this appointment you agree to receive automated
          transactional text messages and/or emails from this merchant.
        </p>

        <p class="light">
          By booking this appointment you agree to this establishment's
          <span
            @click="
              bookingSettings.termsOfServiceLink ? navToTermsOfService() : null
            "
            :class="bookingSettings.termsOfServiceLink ? 'link' : ''"
            >terms of service</span
          >
          and
          <span
            @click="
              bookingSettings.useQuickCancellationPolicy &&
              bookingSettings.cancellationPolicyText
                ? (showQuickCancellationPolicyModal = true)
                : bookingSettings.cancellationPolicyLink
                ? navToCancellationPolicy()
                : null
            "
            :class="
              bookingSettings.cancellationPolicyLink ||
              (bookingSettings.useQuickCancellationPolicy &&
                bookingSettings.cancellationPolicyText)
                ? 'link'
                : ''
            "
            >cancellation policy</span
          >.
        </p>
      </div>
    </form>
  </section>

  <section v-else class="confirmed">
    <h2 class="page-title">
      Thank you! Your appointment has successfully booked.
    </h2>

    <p>
      You will receive a confirmation email shortly. If you have any questions
      or concerns, please contact the salon directly.
    </p>

    <p class="confirmation-salon-details">
      <span>Establishment:</span> {{ salonName }}
    </p>
    <p class="confirmation-salon-details">
      <span>Location:</span> {{ salonLocation }}
    </p>
    <p v-if="salonPhone" class="confirmation-salon-details">
      <span>Phone:</span> {{ salonPhone }}
    </p>
  </section>

  <Modal
    title="Cancellation Policy"
    :show="showQuickCancellationPolicyModal"
    @close="showQuickCancellationPolicyModal = false"
  >
    <div class="quickCancellationPolicy">
      <p>{{ bookingSettings.cancellationPolicyText }}</p>

      <div class="actions">
        <BaseButton @click="showQuickCancellationPolicyModal = false"
          >Agree</BaseButton
        >
      </div>
    </div></Modal
  >
</template>

<script>
import CardconnectTokenizer from '@/components/booking/CardconnectTokenizer.vue';
import OnlineShopCartSummary from '@/components/booking/OnlineShopCartSummary.vue';
import ImageSelect from '@/components/components/ImageSelect.vue';

export default {
  components: {
    CardconnectTokenizer,
    OnlineShopCartSummary,
    ImageSelect,
  },
  mounted() {
    this.$store.dispatch(
      'booking/updateBookingTracking',
      'Confirmation Required'
    );

    // If ChargeAnywhere payment collection update booking tracking
    if (
      this.salonProcessor === 'chargeanywhere' &&
      (this.caPaymentFormsSetup || this.requireCardOnSelectServices) &&
      !this.chargeanywhereToken
    ) {
      this.$store.dispatch(
        'booking/updateBookingTracking',
        'Charge Anywhere Payment Information Required'
      );
    }
    // If Dejavoo payment collection update booking tracking
    if (
      this.salonProcessor === 'dejavoo' &&
      (this.djvPaymentFormsSetup || this.requireCardOnSelectServices) &&
      !this.dejavooToken
    ) {
      this.$store.dispatch(
        'booking/updateBookingTracking',
        'Dejavoo Payment Information Required'
      );
    }

    if (this.isBlacklisted) {
      this.$store.dispatch('booking/updateBookingTracking', 'Blacklisted');
    }

    if (
      this.salonProcessor === 'dejavoo' &&
      (this.djvPaymentFormsSetup ||
        this.requireCardOnSelectServices ||
        this.requireDeposit ||
        this.cart.length)
    )
      this.getDejavooPaymentFormLink();

    if (this.$refs.confirm) {
      this.$refs.confirm.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }

    // CA TOKEN
    window.addEventListener('message', this.eventListener);

    // Check for saved payment method
    const client = this.$store.state.booking.activeClient;

    if (client) {
      if (!client.paymentMethods) return;

      this.savedPaymentMethods = client.paymentMethods.filter(
        (method) =>
          method.salonId === this.$store.state.booking.salon._id &&
          method.processor === this.salonProcessor
      );
    }
  },
  unmounted() {
    window.removeEventListener('message', this.eventListener);
  },
  computed: {
    isBlacklisted() {
      const client = this.$store.state.booking.activeClient;

      if (!client.blacklistedSalons) return false;

      return client.blacklistedSalons.includes(
        this.$store.state.booking.salon._id
      );
    },
    bookingSettings() {
      return this.$store.state.booking.salon.bookingSettings;
    },
    fileUploads() {
      return this.$store.state.booking.uploads;
    },
    caPFsrc() {
      let src = `/capf.html?mid=${this.caInfo.pfmid}&tid=${this.caInfo.pftid}`;
      let amount = 0;

      if (
        this.total ||
        this.requireDeposit ||
        this.itemsThatRequireDepositTotal
      ) {
        if (this.total) {
          amount = +this.total.toFixed(2);
        } else if (this.requireDeposit) {
          amount = +(
            this.$store.getters['booking/getAppointmentTotal'] *
            (this.$store.state.booking.salon.bookingSettings.depositPercent *
              0.01)
          ).toFixed(2);
        } else if (this.itemsThatRequireDepositTotal) {
          amount = +this.itemsThatRequireDepositTotal.toFixed(2);
        }

        src += `&amount=${amount}&forceAmount=true`;
      }

      return src;
    },

    cartAllProducts() {
      if (!this.cart.length) return false;

      return this.cart.every((item) => !item.duration);
    },
    cartInvalid() {
      if (this.requireDeposit && this.cart && this.cart.length) return true;

      if (this.cart && this.cart.length && this.upFrontFee) {
        return this.cart.some((item) => !item.duration);
      }

      return false;
    },
    requireCardOnSelectServices() {
      return this.$store.state.booking.selectedServices.some(
        (service) => service.requireCardInfoToBook
      );
    },
    itemsThatRequireDeposit() {
      return this.$store.state.booking.selectedServices.filter(
        (service) => service.requireDeposit
      );
    },
    itemsThatRequireDepositTotal() {
      let amount = 0;

      this.itemsThatRequireDeposit.forEach((service) => {
        amount += service.depositAmount;
      });

      return amount;
    },
    requireDeposit() {
      if (this.$store.state.booking.salon.bookingSettings.collectDeposit)
        return true;

      return this.itemsThatRequireDeposit.length;
    },
    requiresDepositAmount() {
      return +(
        this.$store.getters['booking/getAppointmentTotal'] *
        (this.$store.state.booking.salon.bookingSettings.depositPercent * 0.01)
      );
    },
    upFrontFee() {
      if (!this.$store.state.booking.salon.bookingSettings.requireDeposit)
        return 0;

      return this.$store.state.booking.salon.bookingSettings.depositPercent;
    },

    formValid() {
      if (
        this.salonProcessor === 'cardconnect' &&
        (!this.cardconnectToken ||
          !this.cardconnectCVV ||
          !this.cardconnectPostal)
      )
        return false;

      if (
        this.salonProcessor === 'chargeanywhere' &&
        this.caPaymentFormsSetup &&
        !this.chargeanywhereToken
      )
        return false;

      return true;
    },
    showConfirmationButton() {
      // Charge Anywhere
      if (this.salonProcessor === 'chargeanywhere') {
        if (
          (this.caPaymentFormsSetup ||
            this.requireCardOnSelectServices ||
            this.requireDeposit) &&
          !this.chargeanywhereToken
        )
          return false;

        if (this.cart.length && !this.orderFulfilled) return false;
      }

      // Dejavoo
      if (this.salonProcessor === 'dejavoo') {
        if (
          (this.djvPaymentFormsSetup ||
            this.requireCardOnSelectServices ||
            this.requireDeposit) &&
          !this.dejavooToken
        )
          return false;

        if (this.cart.length && !this.orderFulfilled) return false;
      }

      return true;
    },
    collectPaymentInfo() {
      return this.$store.state.booking.salon.bookingSettings.collectPaymentInfo;
    },
    caInfo() {
      return this.$store.state.booking.salon.billing.chargeanywhere;
    },
    caPaymentFormsSetup() {
      if (!this.$store.state.booking.salon.billing.chargeanywhere) return;
      if (!this.$store.state.booking.salon.bookingSettings.collectPaymentInfo)
        return;

      return (
        this.$store.state.booking.salon.billing.chargeanywhere.pfmid &&
        this.$store.state.booking.salon.billing.chargeanywhere.pftid
      );
    },
    djvPaymentFormsSetup() {
      if (!this.$store.state.booking.salon.billing.dejavoo) return;
      if (!this.$store.state.booking.salon.bookingSettings.collectPaymentInfo)
        return;

      return this.$store.state.booking.salon.billing.dejavoo.merchantId;
    },
    salonProcessor() {
      return this.$store.state.booking.salon.payments.processor;
    },
    salonName() {
      return `${this.$store.state.booking.salon.details.shopName}`;
    },
    salonLocation() {
      return `${this.$store.state.booking.salon.details.address.street}, ${this.$store.state.booking.salon.details.address.city}, ${this.$store.state.booking.salon.details.address.state} ${this.$store.state.booking.salon.details.address.postal}`;
    },
    salonPhone() {
      return this.$store.state.booking.salon.details.phone;
    },
    coupon() {
      return this.$store.state.booking.coupon;
    },
    confirmed() {
      return this.$store.state.booking.confirmed;
    },
    cardFormValid() {
      if (
        this.card.number.length < 12 ||
        this.card.expiration.length < 3 ||
        this.card.cvv.length < 3 ||
        this.card.postal.length < 5
      )
        return false;

      return true;
    },

    // Online Shop
    cart() {
      return this.$store.state.booking.cart;
    },
    additionalProcessingFee() {
      return (
        this.$store.state.booking.salon.payments
          .cashDiscountAdditionalProcessingFee || 0
      );
    },
    subtotal() {
      let total = 0;
      this.cart.forEach((item) => {
        total +=
          (item.price + item.price * (this.additionalProcessingFee * 0.01)) *
          (item.duration ? this.upFrontFee * 0.01 : 1);
      });
      return total;
    },
    tax() {
      let total = 0;
      this.cart.forEach(
        (item) => (total += item.price * (item.taxRate * 0.01))
      );
      return total;
    },
    total() {
      return this.subtotal + this.tax;
    },
  },
  data() {
    return {
      loading: false,
      orderFulfilled: false,
      showQuickCancellationPolicyModal: false,

      card: {
        number: '',
        expiration: '',
        cvv: '',
        postal: '',
      },

      chargeanywhereToken: null,
      chargeanywhereCard: null,
      chargeanywhereExpiry: null,
      chargeanywhereCVV: null,

      cardconnectToken: null,
      cardconnectExpiry: null,
      cardconnectCVV: null,
      cardconnectPostal: null,

      dejavooPaymentFormLink: null,
      dejavooToken: null,

      savedPaymentMethods: [],
    };
  },
  watch: {
    cardconnectToken(val) {
      this.$store.state.booking.cardconnectToken = val;
    },
    cardconnectExpiry(val) {
      this.$store.state.booking.cardconnectExpiry = val;
    },
    cardconnectCVV(val) {
      this.$store.state.booking.cardconnectCVV = val;
    },
    cardconnectPostal(val) {
      this.$store.state.booking.cardconnectPostal = val;
    },
    chargeanywhereToken(val) {
      this.$store.state.booking.chargeanywhereToken = val;
    },
    chargeanywhereExpiry(val) {
      this.$store.state.booking.chargeanywhereExpiry = val;
    },
    chargeanywhereCVV(val) {
      this.$store.state.booking.chargeanywhereCVV = val;
    },
  },
  methods: {
    eventListener(event) {
      // CA Event
      if (event.data.ResponseCode) {
        const customData = event.data;
        const parsed = JSON.parse(JSON.stringify(customData, null, 2));

        if (parsed.TokenNumber) {
          this.chargeanywhereToken = parsed.TokenNumber;

          const payment = {
            type: 'card',
            amount: +parsed.AuthorizedAmount,
            ref: `${parsed.GatewayReferenceNumber || parsed.ReferenceNumber}:${
              parsed.ApprovalCode
            }`,
            processor: 'chargeanywhere',
          };

          // Fulfill order
          if (this.total || +parsed.AuthorizedAmount) {
            if (this.cartAllProducts) {
              this.fulfillOrder(payment);
            } else {
              this.$store.state.booking.upFrontPayments.push(payment);
            }
          }

          this.$store.dispatch(
            'booking/updateBookingTracking',
            'Charge Anywhere Payment Information Collected'
          );

          // Scroll to confirm button
          setTimeout(() => {
            this.$refs.confirmButton.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }, 500);

          this.$toast.success('Card successful, please confirm booking.');
        } else {
          // Have to do it this way or get error:
          // "Assigning src to self"
          const src = this.$refs.CAIframe.src;
          this.$refs.CAIframe.src = src;

          this.$toast.error('Error; Please try again');
        }
      }

      // Raserva Event
      if (event.origin === process.env.VUE_APP_ORIGIN) {
        if (!event.data.processor) {
          const customData = event.data;
          const parsed = JSON.parse(JSON.stringify(customData, null, 2));

          this.chargeanywhereCVV = parsed.cvv;
          this.chargeanywhereExpiry = `${parsed.expMonth}/${parsed.expYear}`;

          this.$store.state.booking.chargeanywhereSaveCard = parsed.save;
          this.$store.state.booking.chargeanywhereLastFour = parsed.lastFour;
        } else if (event.data.processor === 'dejavoo') {
          if (event.data.denied) {
            this.$toast.error('Error; Please try again');
            this.dejavooPaymentFormLink = null;
            this.getDejavooPaymentFormLink();

            return;
          }

          // Fulfill order
          if (this.total || event.data.amount) {
            const payment = {
              type: 'card',
              amount: +(event.data.amount / 100).toFixed(2),
              ref: event.data.RRN,
              processor: 'dejavoo',
            };

            if (this.cartAllProducts) {
              this.fulfillOrder(payment);
            } else {
              this.$store.state.booking.upFrontPayments.push(payment);
            }
          }

          this.$store.state.booking.dejavooToken = event.data.responseCardToken;
          this.dejavooToken = event.data.responseCardToken;

          this.$store.dispatch(
            'booking/updateBookingTracking',
            'Dejavoo Payment Information Collected'
          );

          // Scroll to confirm button
          setTimeout(() => {
            this.$refs.confirmButton.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }, 500);

          this.$toast.success('Card successful, please confirm booking.');
        }
      }
    },
    async fulfillOrder(payment) {
      try {
        await this.$store.dispatch('booking/fulfillOrder', payment);

        this.$toast.success('Order fulfilled, please confirm booking.');
        this.orderFulfilled = true;
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    cartUpdated() {
      if (this.salonProcessor === 'dejavoo') {
        this.getDejavooPaymentFormLink();
      }
    },
    setTokenData(token) {
      this.cardconnectToken = token.token;
      this.cardconnectExpiry = token.expiry;
    },
    setCoupon(code) {
      this.$store.state.booking.coupon = code;
    },
    updateAppointmentNote(note) {
      this.$store.state.booking.appointmentNote = note;
    },
    async bookAppointment() {
      this.loading = true;

      try {
        await this.$store.dispatch('booking/bookAppointment');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    navToTermsOfService() {
      window.open(this.bookingSettings.termsOfServiceLink, '_blank');
    },
    navToCancellationPolicy() {
      window.open(this.bookingSettings.cancellationPolicyLink, '_blank');
    },

    async getDejavooPaymentFormLink() {
      this.dejavooPaymentFormLink = null;

      let transactionType;
      let amount = 0;

      if (
        this.total ||
        this.requireDeposit ||
        this.itemsThatRequireDepositTotal
      ) {
        transactionType = 1;
      } else {
        transactionType = 2;
      }

      if (this.total) {
        amount = +(this.total * 100).toFixed(0);
      } else if (this.requireDeposit) {
        amount = +(
          this.$store.getters['booking/getAppointmentTotal'] *
          (this.$store.state.booking.salon.bookingSettings.depositPercent *
            0.01) *
          100
        ).toFixed(2);
      } else if (this.itemsThatRequireDepositTotal) {
        amount = +(this.itemsThatRequireDepositTotal * 100).toFixed(2);
      }

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/dejavoo/getPaymentFormLink`,
          {
            salonId: this.$store.state.booking.salon._id,
            amount,
            returnUrl: `${process.env.VUE_APP_ORIGIN}/djvpfreturn.html`,
            transactionType,
          }
        );

        this.dejavooPaymentFormLink = response.data.link;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  color: var(--clr-link);
  cursor: pointer;
  text-decoration: underline;
}
.confirm,
.confirmed {
  width: 100%;
  max-width: 600px;
  margin: auto;

  &_head {
    text-align: center;
    margin-left: 0;

    p {
      margin-top: 5px;
    }
  }

  &_form {
    margin-top: 32px;
  }

  &_form,
  .form {
    display: grid;
    gap: 16px;

    .light {
      margin-top: 16px;
      font-size: 14px;
      color: var(--clr-gray);
    }

    .col-2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }
    .col-3 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
    }

    &_actions {
      .light {
        color: var(--clr-gray);
        font-size: 14px;
        margin-top: 8px;
      }
      button {
        width: 100%;
      }
    }
  }
}

.alert {
  padding: 12px;
  font-size: 14px;
  border-radius: 5px;

  &-warning {
    background-color: var(--clr-warning-light);
    border-color: var(--clr-warning);
  }

  &-info {
    background-color: #c3edf7;
    color: #044653;
    border-color: #aceaf7;
  }
}

.savedPaymentMethods {
  h3 {
    margin-bottom: 24px;
  }

  .mt-m {
    margin-top: 24px;
  }
  .ml-s {
    margin-left: 16px;
  }

  .bold {
    font-weight: 700;
  }
  .light {
    color: var(--clr-gray);
  }

  li {
    &:not(:first-child) {
      margin-top: 16px;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: var(--clr-secondary-white);
    border: 1px solid var(--clr-secondary);
    border-radius: 5px;
  }
}

.cardconnectTokenizer {
  margin-top: 16px;
}

.chargeanywhereTokenizer,
.dejavooTokenizer {
  .head {
    text-align: center;
    margin-bottom: 32px;

    p {
      margin-top: 8px;
    }
  }
}

.cart {
  margin-bottom: 64px;
}

.photoUploads {
  margin-bottom: 32px;

  .alert {
    margin-bottom: 32px;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
  }
}

.confirmed {
  padding: 32px;
  border: 1px solid var(--clr-light);
  border-radius: 5px;
  background-color: white;
  box-shadow: var(--shadow);

  h2 {
    color: var(--clr-success);
    text-align: center;
    margin-bottom: 32px;
  }

  p {
    margin-top: 16px;
  }

  .confirmation-salon-details {
    span {
      font-weight: 700;
    }
  }
}

.blacklisted {
  padding: 16px;
  border: 1px solid var(--clr-danger);
  border-radius: 5px;
  text-align: center;

  h1 {
    font-size: 24px;
  }

  p {
    margin-top: 8px;
  }
}

.quickCancellationPolicy {
  padding: 32px;

  .actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
