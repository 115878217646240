<template>
  <section class="newAppointment">
    <div
      v-if="!mobileMode || (mobileMode && mobileDisplay === 'appointment')"
      class="services"
    >
      <div class="services_body">
        <p class="bookedAt" v-if="newAppointment.created_at">
          Booked: {{ formatDate(newAppointment.created_at, true) }}
        </p>

        <div class="services_body_head">
          <div class="services_body_head_date">
            <div @click="toggleDatePicker" class="dd">
              <h2>{{ selectedDateString }}</h2>
              <i class="fas fa-angle-down"></i>
            </div>

            <div
              v-if="!newAppointment.repeat.repeatId && !newAppointment._id"
              @click="showRepeatForm = !showRepeatForm"
              class="repeat"
            >
              <i class="fas fa-sync-alt"></i>
              <p>Repeat</p>
            </div>
            <div
              v-else-if="newAppointment.repeat.repeatId"
              @click="showDoesRepeatForm = !showDoesRepeatForm"
              class="repeat"
            >
              <i class="fas fa-check"></i>
              <p>
                Repeats (Appointment #{{
                  newAppointment.repeat.appointmentIncrement || 1
                }})
              </p>
            </div>
          </div>
          <div class="datepicker">
            <DatePicker
              v-if="showDatePicker"
              :selectedDate="newAppointment.date"
              @selectDate="selectDate"
              @hide="showDatePicker = false"
              showWeeksOut
            />
          </div>
        </div>

        <!-- Standing -->
        <div v-if="showRepeatForm" class="standing">
          <BaseSelect
            label="Frequency"
            :options="repeatOptions"
            :value="newAppointment.repeat?.repeatText"
            @input="setRepeat($event)"
          />
          <BaseSelect
            v-if="newAppointment.repeat?.repeats === true"
            label="Ends"
            :options="repeatTimesOptions"
            :value="newAppointment.repeat?.repeatTimes"
            @input="newAppointment.repeat.repeatTimes = +$event"
          />
        </div>
        <!-- Repeating Options -->
        <div v-if="showDoesRepeatForm" class="repeats">
          <BaseButton @click="deleteStandingAppointments" mode="danger"
            >Delete future appointments</BaseButton
          >
        </div>

        <div class="services_body_services">
          <AppointmentService
            v-for="(service, index) in newAppointment.services"
            :service="service"
            :key="service"
            :canRemove="multipleServices"
            @remove="removeService(index)"
            @setValue="setService(index, $event)"
            @clearError="clearError(index)"
          />
        </div>
        <div class="services_body_actions">
          <BaseTextarea
            label="Appointment notes"
            placeholder="Appointment note (only visible to staff)"
            :value="newAppointment.note"
            :validators="validators.note"
            @input="newAppointment.note = $event"
            rows="3"
          />
          <div class="services_body_actions_buttons">
            <BaseButton :disabled="!lastServiceValidated" @click="addService"
              >Add Service</BaseButton
            >
            <BaseButton
              v-if="
                processor === 'cardconnect' || processor === 'chargeanywhere'
              "
              :disabled="
                newAppointment.billing &&
                newAppointment.billing.deposits &&
                newAppointment.billing.deposits.length
              "
              mode="primary-outline"
              @click="showDepositForm = true"
              >Add Deposit</BaseButton
            >
            <BaseButton
              v-if="
                newAppointment.billing &&
                newAppointment.billing.deposits &&
                newAppointment.billing.deposits.length
              "
              :disabled="refundLoading"
              mode="danger-outline"
              @click="showConfirmRefundDeposit = true"
              ><i v-if="refundLoading" class="fas fa-spinner"></i>Refund
              Deposit</BaseButton
            >
            <BaseButton
              v-if="newAppointment._id"
              mode="danger-outline"
              @click="showConfirmDelete = true"
              >Delete Appointment</BaseButton
            >
            <i
              v-if="
                newAppointment.originalData &&
                newAppointment.originalData.services &&
                newAppointment.originalData.services.length
              "
              @click="showOriginalData = true"
              class="fas fa-ellipsis-h"
            ></i>
          </div>
        </div>
        <!-- File Uploads -->
        <div v-if="newAppointment.files?.photos?.length" class="uploads">
          <ul>
            <li
              v-for="(photo, index) in newAppointment.files.photos"
              :key="index"
            >
              <img @click="selectedImage = photo" :src="photo" />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="client">
      <div
        class="client_status"
        v-if="
          newAppointment._id &&
          (!mobileMode || (mobileMode && mobileDisplay === 'client'))
        "
      >
        <div class="client_status_button">
          <BaseButton
            mode="primary-outline"
            @click="showStatusDropdown = !showStatusDropdown"
            >{{ newAppointment.status }} <i class="fas fa-angle-down"></i
          ></BaseButton>

          <Tooltip mode="bottom center" v-if="showStatusDropdown">
            <ul>
              <li
                @click="
                  newAppointment.status = 'new';
                  showStatusDropdown = false;
                "
              >
                New
              </li>
              <li
                @click="
                  newAppointment.status = 'confirmed';
                  showStatusDropdown = false;
                "
              >
                Confirmed
              </li>
              <li
                @click="
                  newAppointment.status = 'arrived';
                  showStatusDropdown = false;
                "
              >
                Arrived
              </li>
              <li @click="setStatusToNoShow">No Show</li>
              <li
                @click="
                  newAppointment.status = 'cancelled';
                  showStatusDropdown = false;
                "
              >
                Cancelled
              </li>
            </ul>
          </Tooltip>
        </div>
      </div>
      <div
        v-if="!mobileMode || (mobileMode && mobileDisplay === 'client')"
        class="client_search"
      >
        <div class="client_search_input">
          <form @submit.prevent="searchClients" class="form">
            <BaseInput
              placeholder="Search Client"
              icon="fas fa-search"
              @input="clientSearchValue = $event"
            />
          </form>
        </div>
        <div
          class="client_search_create"
          v-if="!newAppointment.client"
          @click="setTabToCreateClient()"
        >
          <p><i class="fas fa-plus"></i>Create New Client</p>
        </div>
        <div class="client_search_dynamic">
          <Spinner v-if="clientsLoading" />
          <keep-alive>
            <component
              v-bind:is="currentTabComponent"
              :client="newAppointment.client || clientFromSearch"
              :clients="clients"
              basic
              @created="setClient($event)"
              @removeClient="setClient(null)"
              @selectClient="setClient($event)"
              @editClient="setClient($event)"
            ></component>
          </keep-alive>
        </div>
      </div>
      <div
        v-if="!mobileMode || (mobileMode && mobileDisplay === 'pay')"
        class="client_submit"
      >
        <h3>Total: {{ servicesTotal }} ({{ servicesDuration }}mins)</h3>
        <div class="client_submit_actions">
          <!-- <BaseButton @click="checkout" :disabled="loading"
            ><i v-if="loading" class="fas fa-spinner"></i> Checkout</BaseButton
          > -->

          <div class="parent">
            <BaseButton
              @click="showMoreOptions = !showMoreOptions"
              mode="primary-outline"
              >More Options</BaseButton
            >

            <Tooltip
              v-if="showMoreOptions"
              :mode="mobileMode ? '' : 'top center'"
              :height="mobileMode ? '' : 250"
            >
              <ul class="moreOptionsTooltip">
                <li
                  @click="
                    showMoreOptions = false;
                    checkout();
                  "
                >
                  Checkout
                </li>
                <li
                  v-if="newAppointment._id"
                  @click="
                    showMoreOptions = false;
                    reschedule();
                  "
                >
                  Reschedule
                </li>
                <li
                  v-if="newAppointment._id"
                  @click="
                    showMoreOptions = false;
                    sendConfirmation();
                  "
                >
                  Send Confirmation
                </li>
                <li
                  v-if="newAppointment._id && newAppointment.shortId"
                  @click="
                    showMoreOptions = false;
                    sendConfirmationRequest();
                  "
                >
                  Send Confirm Request
                </li>
              </ul>
            </Tooltip>
          </div>

          <BaseButton @click="saveAppointment(false)" :disabled="loading"
            ><i v-if="loading" class="fas fa-spinner"></i> Save
          </BaseButton>
        </div>
      </div>
    </div>
  </section>

  <!-- <ul class="mobileNavBar">
    <li @click="mobileDisplay = 'appointment'">
      <i class="fas fa-calendar"></i>
    </li>
    <li @click="mobileDisplay = 'client'">
      <i class="fas fa-user"></i>
    </li>
    <li @click="mobileDisplay = 'pay'">
      <i class="fas fa-cash-register"></i>
    </li>
  </ul> -->

  <div class="mobile">
    <div @click="showMobileMenu = !showMobileMenu" class="float">
      <i class="fas fa-bars"></i>
    </div>

    <ul v-if="showMobileMenu" class="menu">
      <li @click="mobileDisplay = 'appointment'">
        <i class="fas fa-calendar"></i>
      </li>
      <li @click="mobileDisplay = 'client'">
        <i class="fas fa-user"></i>
      </li>
      <li @click="mobileDisplay = 'pay'">
        <i class="fas fa-cash-register"></i>
      </li>
    </ul>
  </div>

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Delete appointment"
    text="Are you sure you wish to delete this appointment? This action can not be undone."
    @confirm="deleteAppointment"
    @deny="showConfirmDelete = false"
  />

  <Confirm
    v-if="showConfirmRefundDeposit"
    title="Refund deposit"
    text="Are you sure you wish to refund this deposit? This action can not be undone."
    @confirm="refundDeposit"
    @deny="showConfirmRefundDeposit = false"
  />

  <Confirm
    v-if="showConfirmNoShowCharge"
    title="Charge for no show"
    text="Do you wish to attempt to charge the card on file for this appointment for a no show?"
    :loading="chargeLoading"
    @confirm="chargeNoShow"
    @deny="showConfirmNoShowCharge = false"
  />

  <Modal
    :show="showDepositForm"
    @close="showDepositForm = false"
    title="Virtual Terminal"
  >
    <div v-if="processor === 'cardconnect'" class="cardConnectVertTermModal">
      <CardConnectVirtTerm @submit="addDepositCardConnect($event)" />
    </div>
    <div
      v-if="processor === 'chargeanywhere'"
      class="chargeAnywhereVertTermModal"
    >
      <!-- ChargeAnywhere Virtual Terminal -->
      <iframe
        :src="`/capf.html?mid=${caInfo.pfmid}&tid=${caInfo.pftid}&amount=${0}`"
        width="100%"
        height="400"
        frameborder="0"
        ref="CAIframe"
      ></iframe>
    </div>
  </Modal>

  <Modal
    :show="showGetStaffAccountForDeleteAppointment"
    title="Account Code"
    @close="showGetStaffAccountForDeleteAppointment = false"
  >
    <form
      @submit.prevent="submitStaffAccountForDeleteAppointment"
      class="form deleteAppointmentStaffCode"
    >
      <BaseInput
        label="Account Code"
        inputType="password"
        @input="staffAccountForDeleteAppointment = $event"
      />
      <div class="form_actions">
        <BaseButton>Submit</BaseButton>
      </div>
    </form>
  </Modal>

  <Modal
    :show="showOriginalData"
    @close="showOriginalData = false"
    :title="`Original Booking Data - ${newAppointment._id}`"
  >
    <ul class="originalData">
      <li
        v-for="service in newAppointment.originalData.services"
        :key="service._id"
      >
        <p class="start">{{ formatTimeString(service.start) }}</p>
        <p class="service">
          {{ service.service.title }} with
          {{ `${service.staff.firstName} ${service.staff.lastName}` }} lasting
          {{ service.service.duration }} minutes
        </p>
      </li>
    </ul>
  </Modal>

  <Modal
    :show="selectedImage"
    @close="selectedImage = null"
    title="Gallery Photo"
  >
    <img :src="selectedImage" />
  </Modal>
</template>

<script>
import AppointmentService from '@/components/appointments/AppointmentService.vue';
import DatePicker from '@/components/components/DatePicker.vue';
import ClientForm from '@/components/clients/ClientForm.vue';
import ClientInfo from '@/components/clients/ClientInfo.vue';
import ClientList from '@/components/clients/ClientList.vue';

import CardConnectVirtTerm from '@/views/virtTerm/CardConnectVirtTerm.vue';

export default {
  emits: ['created', 'edited', 'deleted', 'checkout', 'reschedule'],
  components: {
    AppointmentService,
    DatePicker,
    ClientForm,
    ClientInfo,
    CardConnectVirtTerm,
  },
  props: {
    appointment: {
      type: Object,
    },
    selectedDate: {
      type: Date,
      default: new Date(Date.now()),
    },
  },
  data() {
    return {
      newAppointment: {
        services: [],
        note: '',
        title: '',
        date: null,
        client: null,
        status: 'new',
        billing: {
          deposits: [],
        },
        repeat: {
          repeats: false,
          amount: null,
          increment: null,
          repeatTimes: 2,
          repeatText: 'thismeansdontrepeat',
          appointmentIncrement: 0,
          repeatId: null,
        },
      },

      showMobileMenu: false,
      showRepeatForm: false,
      showDoesRepeatForm: false,

      loading: false,

      showMoreOptions: false,
      showDatePicker: false,
      showStatusDropdown: false,
      showConfirmDelete: false,
      currentTabComponent: null,
      showConfirmNoShowCharge: false,
      showDepositForm: false,
      showOriginalData: false,

      showGetStaffAccountForDeleteAppointment: false,
      staffAccountForDeleteAppointment: '',

      showConfirmRefundDeposit: false,
      refundLoading: false,

      clientsLoading: false,
      chargeLoading: false,

      clientSearchValue: '',
      clients: [],

      clientFromSearch: {
        phoneNumber: null,
      },

      mobileMode: false,
      mobileDisplay: 'appointment',

      selectedImage: null,
    };
  },
  computed: {
    validators() {
      return {
        note: {
          maxLength: 500,
        },
      };
    },
    selectedDateString() {
      return this.$moment(this.newAppointment.date).format('dddd[,] ll');
    },
    multipleServices() {
      return this.newAppointment.services.length > 1 ? true : false;
    },
    processor() {
      return this.$store.state.auth.salon.payments.processor;
    },
    caInfo() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.billing.chargeanywhere;
    },
    lastServiceValidated() {
      const lastService =
        this.newAppointment.services[this.newAppointment.services.length - 1];

      if (
        lastService.start &&
        lastService.service &&
        lastService.duration &&
        lastService.staff
      ) {
        return true;
      }

      return false;
    },
    servicesTotal() {
      // Refactor this to use .reduce?
      let total = 0;

      this.newAppointment.services.forEach((service) => {
        if (service.service) {
          total += service.service.price || 0;
        }
      });

      return total ? `$${total.toFixed(2)}` : 'Free';
    },
    servicesTotalNumber() {
      let total = 0;

      this.newAppointment.services.forEach((service) => {
        if (service.service) {
          total += service.service.price || 0;
        }
      });

      return +total;
    },
    servicesDuration() {
      // Refactor this to use .reduce?
      let duration = 0;

      this.newAppointment.services.forEach((service) => {
        duration += +service.duration || 0;
      });

      return duration;
    },
    repeatTimesOptions() {
      return [
        {
          option: 'After 2 times',
          value: 2,
        },
        {
          option: 'After 3 times',
          value: 3,
        },
        {
          option: 'After 4 times',
          value: 4,
        },
        {
          option: 'After 5 times',
          value: 5,
        },
        {
          option: 'After 6 times',
          value: 6,
        },
        {
          option: 'After 7 times',
          value: 7,
        },
        {
          option: 'After 8 times',
          value: 8,
        },
        {
          option: 'After 9 times',
          value: 9,
        },
        {
          option: 'After 10 times',
          value: 10,
        },
        {
          option: 'After 11 times',
          value: 11,
        },
        {
          option: 'After 12 times',
          value: 12,
        },
        {
          option: 'After 13 times',
          value: 13,
        },
        {
          option: 'After 14 times',
          value: 14,
        },
        {
          option: 'After 15 times',
          value: 15,
        },
        {
          option: 'After 20 times',
          value: 20,
        },
        {
          option: 'After 25 times',
          value: 25,
        },
        {
          option: 'After 30 times',
          value: 30,
        },
      ];
    },
    repeatOptions() {
      return [
        {
          option: `Doesn't repeat`,
          value: 'thismeansdontrepeat',
        },
        {
          option: 'Daily',
          value: '1 days',
        },
        {
          option: 'Every 2 days',
          value: '2 days',
        },
        {
          option: 'Every 3 days',
          value: '3 days',
        },
        {
          option: 'Every 4 days',
          value: '4 days',
        },
        {
          option: 'Every 5 days',
          value: '5 days',
        },
        {
          option: 'Every 6 days',
          value: '6 days',
        },
        {
          option: 'Weekly',
          value: '1 weeks',
        },
        {
          option: 'Every 2 weeks',
          value: '2 weeks',
        },
        {
          option: 'Every 3 weeks',
          value: '3 weeks',
        },
        {
          option: 'Every 4 weeks',
          value: '4 weeks',
        },
        {
          option: 'Every 5 weeks',
          value: '5 weeks',
        },
        {
          option: 'Every 6 weeks',
          value: '6 weeks',
        },
        {
          option: 'Every 7 weeks',
          value: '7 weeks',
        },
        {
          option: 'Every 8 weeks',
          value: '8 weeks',
        },
        {
          option: 'Every 9 weeks',
          value: '9 weeks',
        },
        {
          option: 'Every 10 weeks',
          value: '10 weeks',
        },
        {
          option: 'Monthly',
          value: '1 months',
        },
        {
          option: 'Every 2 months',
          value: '2 months',
        },
        {
          option: 'Every 3 months',
          value: '3 months',
        },
        {
          option: 'Every 4 months',
          value: '4 months',
        },
        {
          option: 'Every 5 months',
          value: '5 months',
        },
        {
          option: 'Every 6 months',
          value: '6 months',
        },
      ];
    },
  },
  created() {
    if (screen.width <= 900) {
      this.mobileMode = true;
    }

    if (this.appointment) {
      this.newAppointment = JSON.parse(
        JSON.stringify({
          ...this.newAppointment,
          ...this.appointment,
        })
      );

      if (this.newAppointment.client) this.currentTabComponent = ClientInfo;
    }

    this.newAppointment.date = this.$moment(this.selectedDate).toDate();

    // this.newAppointment.date = this.$moment()
    //   .dayOfYear(this.$moment(this.selectedDate).dayOfYear())
    //   .year(this.$moment(this.selectedDate).year())
    //   .toDate();

    if (this.newAppointment.services.length === 0) {
      this.addService();
    }
  },
  async mounted() {
    // CA TOKEN
    window.addEventListener('message', this.eventListener);
  },
  unmounted() {
    window.removeEventListener('message', this.eventListener);
  },
  methods: {
    eventListener(event) {
      if (event.data.ResponseCode) {
        const customData = event.data;
        const parsed = JSON.parse(JSON.stringify(customData, null, 2));

        if (
          parsed.ResponseText === 'APPROVED' ||
          parsed.ResponseCode === '000' ||
          parsed.ResponseCode === '010'
        ) {
          this.addDepositChargeAnywhere({
            ref: `${parsed.ReferenceNumber}:${parsed.ApprovalCode}`,
            amount: parsed.AuthorizedAmount,
            processor: 'chargeanywhere',
          });
        } else {
          // Have to do it this way or get error:
          // "Assigning src to self"
          const src = this.$refs.CAIframe.src;
          this.$refs.CAIframe.src = src;

          this.$toast.error(`Error; ${parsed.ResponseText} - Please try again`);
        }
      }
    },
    formatTimeString(time) {
      const [hour, minute] = time.split(':');

      return this.$moment(this.newAppointment.originalData.date)
        .hour(hour)
        .minute(minute)
        .format('LLL');
    },

    addService() {
      const service = {
        start: null,
        service: null,
        duration: null,
        staff: null,
      };

      if (this.newAppointment.services.length > 0) {
        const lastService =
          this.newAppointment.services[this.newAppointment.services.length - 1];
        const lastServiceStartTime = this.$moment(
          this.$moment(this.newAppointment.date)
            .hour(lastService.start.split(':')[0])
            .minute(lastService.start.split(':')[1])
        );

        let newServiceStartTime = lastServiceStartTime.add(
          lastService.duration,
          'minutes'
        );

        if (lastService.service.extraProcessingTime)
          newServiceStartTime.add(
            lastService.service.extraProcessingTime,
            'minutes'
          );
        if (lastService.service.extraBlockedTime)
          newServiceStartTime.add(
            lastService.service.extraBlockedTime,
            'minutes'
          );

        service.start = newServiceStartTime.format('HH:mm');
      }

      this.newAppointment.services.push(service);
    },
    toggleDatePicker() {
      this.showDatePicker = !this.showDatePicker;
    },
    removeService(index) {
      this.newAppointment.services.splice(index, 1);
    },
    setService(index, service) {
      this.newAppointment.services[index] = service;
    },
    clearError(index) {
      this.newAppointment.services[index].error = false;
    },

    selectDate(date) {
      const oldDate = this.$moment(this.newAppointment.date);

      const newDateWithOldDateHourAndMinute = this.$moment(date)
        .minute(oldDate.minute())
        .hour(oldDate.hour());

      this.showDatePicker = false;
      this.newAppointment.date = newDateWithOldDateHourAndMinute;
    },

    formatDate(date, short) {
      if (!short) {
        return this.$moment(date).format('LL');
      } else {
        return this.$moment(date).format('LLL');
      }
    },

    setTabToCreateClient() {
      if (this.currentTabComponent) {
        if (this.newAppointment.client) {
          this.currentTabComponent = ClientInfo;
          return;
        }

        this.currentTabComponent = null;
        return;
      }

      if (this.clientSearchValue) {
        this.clientFromSearch.phoneNumber = this.clientSearchValue;
      }

      this.currentTabComponent = ClientForm;
    },

    setRepeat(repeatText) {
      if (repeatText === 'thismeansdontrepeat') {
        this.newAppointment.repeat = {
          repeats: false,
          amount: null,
          repeatTimes: 0,
          increment: null,
          repeatText,
        };

        return;
      }

      this.newAppointment.repeat = {
        repeats: true,
        amount: repeatText.split(' ')[0],
        repeatTimes: this.newAppointment.repeat.repeatTimes || 2,
        increment: repeatText.split(' ')[1],
        repeatText,
      };
    },

    setClient(client) {
      this.newAppointment.client = client;

      if (client === null) {
        this.currentTabComponent = null;
        return;
      }

      this.currentTabComponent = ClientInfo;
    },
    async searchClients() {
      this.clientsLoading = true;

      try {
        this.clients = await this.$store.dispatch('clients/searchClients', {
          name: this.clientSearchValue,
          email: this.clientSearchValue,
          phone: this.clientSearchValue,
        });

        this.currentTabComponent = ClientList;
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.clientsLoading = false;
    },

    async submitStaffAccountForDeleteAppointment() {
      const staff = this.$store.state.staff.staff.find(
        (staff) => staff.accountCode === this.staffAccountForDeleteAppointment
      );

      if (staff) {
        try {
          await this.deleteAppointment(true);

          await this.$store.dispatch('auth/addAction', {
            staff: `${staff.firstName} ${staff.lastName}`,
            action: `deleted an appointment for date ${this.formatDate(
              this.newAppointment.date
            )}${
              this.newAppointment.client
                ? ` with client ${this.newAppointment.client.firstName} ${this.newAppointment.client.lastName}`
                : ''
            }`,
            type: 'appointment',
            metadata: this.newAppointment,
          });
        } catch (error) {
          this.$toast.error(error.message);
        }
      } else {
        this.$toast.error('Staff account not found');
      }
    },

    async deleteStandingAppointments() {
      await this.$store.dispatch(
        'appointments/deleteStandingAppointments',
        this.newAppointment
      );

      this.$emit('deleted', this.newAppointment);
    },
    async deleteAppointment(skipCode) {
      if (!skipCode) {
        if (
          !this.$store.state.auth.tempUser &&
          this.$store.state.auth.loggedInSalonStaff.staffId !==
            this.$store.state.auth.salon.adminId
        ) {
          if (
            await this.$store.dispatch(
              'auth/activeUserHasPermission',
              'requireCode/deleteAppointments'
            )
          ) {
            this.showConfirmDelete = false;
            this.showGetStaffAccountForDeleteAppointment = true;
            return;
          }
        }
      }

      try {
        await this.$store.dispatch(
          'appointments/deleteAppointment',
          this.newAppointment
        );

        this.$emit('deleted', this.newAppointment);
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async refundDeposit() {
      this.refundLoading = true;
      this.showConfirmRefundDeposit = false;

      try {
        const refund = await this.$store.dispatch(
          'payments/refund',
          this.newAppointment.billing.deposits[0]
        );

        if (refund.refunded) {
          this.newAppointment.billing.deposits.splice(0, 1);
          this.$toast.success('Deposit refunded');
          this.$emit('edited', this.newAppointment);
        } else {
          this.$toast.error(refund.message);
        }
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.refundLoading = false;
    },

    setStatusToNoShow() {
      this.newAppointment.status = 'no show';
      this.showStatusDropdown = false;

      if (
        !this.newAppointment.billing.noShowCharged &&
        this.newAppointment.billing.cardconnect.token
      ) {
        this.showConfirmNoShowCharge = true;
      } else if (
        !this.newAppointment.billing.noShowCharged &&
        this.newAppointment.billing.chargeanywhere.token
      ) {
        this.showConfirmNoShowCharge = true;
      } else if (
        !this.newAppointment.billing.noShowCharged &&
        this.newAppointment.billing.dejavoo.token
      ) {
        this.showConfirmNoShowCharge = true;
      }
    },

    chargeNoShow() {
      if (this.newAppointment.billing.cardconnect.token) {
        this.cardconnectChargeNoShow();
      } else if (this.newAppointment.billing.chargeanywhere.token) {
        this.chargeanywhereChargeNoShow();
      } else if (this.newAppointment.billing.dejavoo.token) {
        this.dejavooChargeNoShow();
      }
    },

    async chargeanywhereChargeNoShow() {
      if (this.chargeLoading) return;

      const amount = +(
        this.servicesTotalNumber *
        (this.$store.state.auth.salon.bookingSettings.noShowFeePercent * 0.01)
      ).toFixed(2);

      this.chargeLoading = true;

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/chargeanywhere/chargeViaToken`,
          {
            salonId: this.$store.state.auth.salon._id,
            Amount: amount.toString(),
            TokenNumber: this.newAppointment.billing.chargeanywhere.token,
          }
        );

        const values = response.data.data.split('&');

        const resTextValue = values
          .find((value) => value.includes('ResponseText'))
          .split('=')[1];

        if (resTextValue === 'APPROVED') {
          this.newAppointment.billing.noShowCharged = true;
          this.newAppointment.billing.noShowChargedAmount = amount;
          this.$toast.success(`No show fee of $${amount} charged`);
          this.$emit('edited', this.newAppointment);
        } else {
          this.$toast.error(resTextValue);
        }
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.chargeLoading = false;
    },

    async dejavooChargeNoShow() {
      if (this.chargeLoading) return;

      const amount = +(
        this.servicesTotalNumber *
        (this.$store.state.auth.salon.bookingSettings.noShowFeePercent * 0.01) *
        100
      ).toFixed(0);

      this.chargeLoading = true;

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/dejavoo/chargeViaToken`,
          {
            salonId: this.$store.state.auth.salon._id,
            amount: amount.toString(),
            cardToken: this.newAppointment.billing.dejavoo.token,
          }
        );

        const data = response.data.data.iposhpresponse;

        if (data.responseCode === '200') {
          this.newAppointment.billing.noShowCharged = true;
          this.newAppointment.billing.noShowChargedAmount = +data.amount;
          this.$toast.success(`No show fee of $${data.amount / 100} charged`);
          this.$emit('edited', this.newAppointment);
        } else {
          this.$toast.error(data.responseMessage);
        }
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.chargeLoading = false;
    },

    async cardconnectChargeNoShow() {
      if (this.chargeLoading) return;

      const amount = +(
        this.servicesTotalNumber.toFixed(2) *
        (this.$store.state.auth.salon.bookingSettings.noShowFeePercent * 0.01) *
        100
      ).toFixed(0);

      this.chargeLoading = true;

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/cardpointe/auth?merchantId=${this.$store.state.auth.salon.billing.cardconnect.mid}`,
          {
            amount,
            expiry: this.newAppointment.billing.cardconnect.expiry,
            account: this.newAppointment.billing.cardconnect.token,
            postal: this.newAppointment.billing.cardconnect.postal,
            cvv2: this.newAppointment.billing.cardconnect.cvv2,
            capture: 'Y',
          }
        );

        if (response.data.data.respstat === 'A') {
          this.newAppointment.billing.noShowCharged = true;

          this.$emit('edited', this.newAppointment);
        } else if (response.data.data.respstat === 'B') {
          this.$toast.error(`Try again: ${response.data.data.resptext}`);
        } else if (response.data.data.respstat === 'C') {
          this.$toast.error(`Declined: ${response.data.data.resptext}`);
        }
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.chargeLoading = false;
    },

    addDepositCardConnect(data) {
      this.newAppointment.billing.deposits.push({
        date: new Date(Date.now()),
        amount: data.amount,
        ref: data.retref,
        processor: 'cardconnect',
      });

      this.$toast.success('Deposit collected');
      this.showDepositForm = false;
      this.$emit('edited', this.newAppointment);
    },
    addDepositChargeAnywhere(data) {
      this.newAppointment.billing.deposits.push({
        date: new Date(Date.now()),
        amount: data.amount,
        ref: data.ref,
        processor: 'chargeanywhere',
      });

      this.$toast.success('Deposit collected');
      this.showDepositForm = false;
      this.$emit('edited', this.newAppointment);
    },

    reschedule() {
      this.$emit('reschedule');
    },

    async sendConfirmation() {
      if (!this.newAppointment.client) {
        this.$toast.error('Client is required');
        return;
      }

      if (!this.newAppointment.client.phoneNumber) {
        this.$toast.error('Client phone number is required');
        return;
      }

      const [hour, minute] = this.newAppointment.services[0].start.split(':');

      const startTime = this.$moment(this.newAppointment.date)
        .hour(hour)
        .minute(minute);

      const startTimeText = `${this.$moment(startTime).format(
        'LLL zz'
      )}${this.$moment().tz(this.$moment.tz.guess()).format('z')}`;

      try {
        await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/appointments/sendText/confirmation`,
          {
            salonId: this.$store.state.auth.salon._id,
            shopName: this.$store.state.auth.salon.details.shopName,
            phoneNumber: this.newAppointment.client.phoneNumber,
            startTimeText,
          }
        );

        this.$toast.success('Confirmation sent');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async sendConfirmationRequest() {
      if (!this.newAppointment.client) {
        this.$toast.error('Client is required');
        return;
      }

      if (!this.newAppointment.client.phoneNumber) {
        this.$toast.error('Client phone number is required');
        return;
      }

      try {
        await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/appointments/sendText/confirmationRequest?appointmentId=${this.newAppointment._id}`
        );

        this.$toast.success('Confirm request sent');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async checkout() {
      this.newAppointment.status = 'arrived';

      if (
        this.$store.state.tickets.tickets.find((ticket) =>
          ticket.appointmentId
            ? ticket.appointmentId.toString() ===
              this.newAppointment._id.toString()
            : false
        )
      ) {
        this.$toast.error('This appointment already has an existing ticket');
        return;
      }

      const { ticket } = await this.$store.dispatch(
        'appointments/editAppointment',
        this.newAppointment
      );

      this.$emit('checkout', ticket);
    },

    saveAppointment(dontNavigateAway) {
      if (this.newAppointment.note.length > 500) {
        this.$toast.error('Note is longer than 500 characters');
        return;
      }

      const errorIndex = this.newAppointment.services.findIndex((service) => {
        return (
          !service.start ||
          !service.service ||
          !service.duration ||
          service.duration < 1 ||
          !service.staff
        );
      });

      // Success
      if (errorIndex === -1) {
        this.loading = true;

        const startHour = +this.newAppointment.services[0].start.split(':')[0];
        const startMinute =
          +this.newAppointment.services[0].start.split(':')[1];

        const startTimeText = this.$moment(this.newAppointment.date)
          .hour(startHour)
          .minute(startMinute)
          .format('LLL');

        this.newAppointment.startTimeText = startTimeText;

        if (!this.newAppointment._id) {
          this.$emit('created', {
            ...this.newAppointment,
            originalData: {
              date: this.newAppointment.date,
              services: this.newAppointment.services,
            },
          });
          this.setLoadingFalse();
          return;
        }

        this.$emit('edited', { ...this.newAppointment, dontNavigateAway });
        this.setLoadingFalse();
        return;
      }

      // Error
      this.newAppointment.services[errorIndex].error = true;
    },

    setLoadingFalse() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.newAppointment {
  max-height: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  .alert {
    padding: 12px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid;

    &-warning {
      background-color: var(--clr-warning-light);
      border-color: var(--clr-warning);
    }
  }

  .bookedAt {
    margin-bottom: 8px;
    font-size: 14px;
  }

  .services {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    background-color: var(--clr-white-2);

    &_body {
      padding: 16px;

      &_head {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 32px;

        &_date {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .dd,
          .repeat {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
          }

          .repeat {
            color: var(--clr-link);

            i {
              font-size: 14px;
              margin-right: 16px;
            }
          }
        }

        .datepicker {
          z-index: 2;
          position: absolute;
          top: calc(100% + 10px);
          left: 0;
        }

        h2 {
          font-size: 20px;
        }

        i {
          font-size: 20px;
          margin-left: 32px;
        }
      }

      .standing {
        padding: 32px;
        background-color: white;
        box-shadow: var(--shadow);
        border-radius: 8px;
        margin-bottom: 32px;
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      .repeats {
        margin-bottom: 32px;
      }

      &_services {
        .service {
          box-shadow: var(--shadow);

          &:not(:first-child) {
            margin-top: 32px;
          }
        }
      }

      &_actions {
        margin-top: 32px;

        &_buttons {
          margin-top: 16px;
          display: flex;
          align-items: center;
          gap: 16px;
          flex-wrap: wrap;

          .fa-ellipsis-h {
            cursor: pointer;
          }

          // button {
          //   margin: 16px 0;

          //   &:not(:first-child) {
          //     margin-left: 16px;
          //   }
          // }
        }
      }

      .uploads {
        margin-top: 32px;

        ul {
          display: flex;
          flex-wrap: wrap;
          gap: 16px;
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            width: 100px;
            height: 100px;
            border: 1px solid var(--clr-light);
            border-radius: 8px;
            padding: 8px;
            cursor: pointer;
            transition: background-color 0.2s;

            &:hover {
              background-color: white;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }

  .client {
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--clr-light);
    height: 100%;
    overflow-y: auto;

    &_status {
      padding: 16px 32px;
      border-bottom: 1px solid var(--clr-light);
      text-align: center;

      &_button {
        position: relative;

        button {
          width: 100%;

          i {
            margin-left: 32px;
          }
        }

        ul {
          background-color: white;
          border: 1px solid var(--clr-light);

          li {
            padding: 12px 16px;
            transition: background-color 0.2s;
            cursor: pointer;

            &:hover {
              background-color: var(--clr-white);
            }
          }
        }
      }
    }

    &_search {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      &_input,
      &_create {
        padding: 24px;
        border-bottom: 1px solid var(--clr-light);
      }

      &_dynamic {
        padding: 24px;
        flex-grow: 1;
        overflow-y: auto;
      }

      &_create {
        display: grid;
        place-items: center;
        cursor: pointer;
        color: var(--clr-link);

        p {
          font-size: 14px;
        }

        i {
          margin-right: 16px;
        }
      }
    }

    &_submit {
      padding: 32px;
      border-top: 1px solid var(--clr-light);
      text-align: center;

      &_actions {
        margin-top: 16px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 16px;

        .parent {
          position: relative;

          .moreOptionsTooltip {
            height: 250px;
            background-color: white;
            border: 1px solid var(--clr-light);
            border-radius: 5px;
            text-align: center;
            display: flex;
            flex-direction: column;

            li {
              padding: 16px 24px;
              flex-grow: 1;
              cursor: pointer;
              display: grid;
              place-items: center;

              &:hover {
                background-color: var(--clr-white-2);
              }
            }
          }
        }
      }
    }
  }
}

.cardConnectVertTermModal,
.chargeAnywhereVertTermModal,
.originalData,
.deleteAppointmentStaffCode {
  padding: 32px;
}

.deleteAppointmentStaffCode {
  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_actions {
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }
  }
}

.originalData {
  li {
    &:not(:first-child) {
      margin-top: 32px;
    }

    .start {
      font-size: 12px;
      color: var(--clr-gray);
      margin-bottom: 8px;
    }
  }
}

.mobileNavBar {
  display: none;
}

.mobile {
  display: none;
  position: absolute;
  bottom: 50px;
  right: 20px;

  .float {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    box-shadow: var(--shadow);
    border: 1px solid var(--clr-light);
    background-color: var(--clr-white);
    display: grid;
    place-items: center;
    color: var(--clr-secondary);
  }

  .menu {
    padding: 24px 0;
    display: flex;
    border-radius: 100px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 50px;
    margin-top: 8px;
    box-shadow: var(--shadow);
    border: 1px solid var(--clr-light);
    background-color: var(--clr-white);
    color: var(--clr-secondary);
  }
}

// Tablet
@media (max-width: 900px) {
  .newAppointment {
    flex-direction: column-reverse;

    .services {
      // margin-bottom: 60px;

      &_body {
        width: 100%;
      }
    }

    .client {
      flex-grow: 1;
      height: auto;
    }
  }

  .mobile {
    display: block;
  }

  .mobileNavBar {
    display: block;
    position: absolute;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--clr-secondary-dark);
    color: var(--clr-white);
    bottom: 0;
    left: 0;

    li {
      display: grid;
      place-items: center;
      padding: 16px 32px;
    }
  }
}
</style>
