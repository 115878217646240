<template>
  <section class="tickets">
    <!-- Head -->
    <div class="tickets_head">
      <h2>Salon Tickets</h2>
      <p>View/edit salons tickets</p>
    </div>

    <!-- Actions -->
    <form @submit.prevent="search" class="tickets_actions">
      <div class="filters">
        <BaseInput
          placeholder="Search by ticket ID"
          @input="searchValue = $event"
          :value="searchValue"
        />

        <BaseSelect
          :options="filterOptions"
          :value="selectedFilter"
          @input="selectedFilter = $event"
        />
      </div>

      <div class="buttons">
        <BaseButton>Search</BaseButton>
      </div>
    </form>

    <!-- Top Pagination -->
    <Pagination
      :limit="25"
      :page="page"
      :data="tickets"
      @nextPage="nextPage"
      @prevPage="prevPage"
    />

    <!-- List -->
    <div v-if="tickets.length" class="tickets_list">
      <Table
        :headers="ticketsHeaders"
        :data="ticketsData"
        @rowClick="selectedTicket = tickets[$event]"
      />
    </div>

    <!-- Bottom Pagination -->
    <Pagination
      :limit="25"
      :page="page"
      :data="tickets"
      @nextPage="nextPage"
      @prevPage="prevPage"
    />

    <!-- None -->
    <div v-if="!tickets.length" class="none">
      <h3>No tickets pulled</h3>
      <p>Run a search to continue</p>
    </div>
  </section>

  <Modal
    title="Selected Ticket"
    :show="selectedTicket"
    @close="selectedTicket = null"
  >
    <div class="selectedTicket">
      <AdminSelectedTicket :propTicket="selectedTicket" />
    </div>
  </Modal>
</template>

<script>
import AdminSelectedTicket from '@/components/admin/AdminSelectedTicket';

export default {
  components: {
    AdminSelectedTicket,
  },
  created() {
    if (this.$route.query.salonId)
      this.selectedFilter = this.$route.query.salonId;

    this.init();
  },
  computed: {
    ticketsHeaders() {
      return ['Salon', 'Creation Date', 'Ticket Number', 'Client', 'Status'];
    },
    ticketsData() {
      const data = [];

      this.tickets.forEach((ticket) => {
        data.push({
          salon: `${ticket.salonId.details.shopName}${
            ticket.salonId.details.altName
              ? ' ' + ticket.salonId.details.altName
              : ''
          }`,
          creationDate: this.$moment(ticket.created_at).format('LLL'),
          number: ticket.number,
          client: ticket.client
            ? `${ticket.client.firstName} ${ticket.client.lastName}`
            : '',
          status: ticket.status,
        });
      });

      return data;
    },
    filterOptions() {
      const options = [
        {
          option: 'All Salons',
          value: 'all',
        },
      ];

      this.salons.forEach((salon) => {
        options.push({
          option: `${salon.details.shopName}${
            salon.details.altName ? ' ' + salon.details.altName : ''
          }`,
          value: salon._id,
        });
      });

      return options;
    },
  },
  data() {
    return {
      salons: [],
      tickets: [],
      selectedTicket: null,
      searchValue: '',
      selectedFilter: 'all',
      page: 1,
    };
  },
  methods: {
    async init() {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin/salons?all=true`
        );

        this.salons = response.data.salons;

        this.search();
      } catch (e) {
        console.log(e);
      }
    },
    async search() {
      let url = `${process.env.VUE_APP_RASERVA_BACKEND}/admin/salons/tickets?page=${this.page}`;

      if (this.searchValue) {
        url += `&ticketId=${this.searchValue}`;
      }
      if (this.selectedFilter !== 'all') {
        url += `&salonId=${this.selectedFilter}`;
      }

      try {
        const response = await this.$axios.get(url);

        this.tickets = response.data.tickets;
      } catch (error) {
        console.log(error);
      }
    },
    async prevPage() {
      this.page--;

      this.search();
    },
    async nextPage() {
      this.page++;

      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.tickets {
  margin: 32px auto;
  max-width: 1200px;

  &_head {
    margin-bottom: 32px;

    h2 {
      font-size: 32px;
    }
    p {
      margin-top: 5px;
      color: var(--clr-gray);
    }
  }

  &_actions {
    padding: 16px;
    background-color: var(--clr-light);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filters,
    .buttons {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  .none {
    margin-top: 32px;
    padding: 16px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;
    text-align: center;

    p {
      margin-top: 8px;
    }
  }

  &_list {
    border: 1px solid var(--clr-light);
  }
}

.selectedTicket {
  padding: 32px;
}
</style>
