<template>
  <form @submit.prevent="submit" class="ticket">
    <div v-if="adminMode" class="ticket_actions">
      <!-- <div class="title">Admin Options</div> -->

      <div class="options">
        <BaseSelect
          :options="statusOptions"
          :value="newTicket.status"
          @input="newTicket.status = $event"
        />

        <div class="buttons">
          <BaseButton @click="showConfirmDelete = true" mode="danger-outline"
            >Delete Support Ticket</BaseButton
          >
          <BaseButton @click="save">Save</BaseButton>
        </div>
      </div>
    </div>

    <div class="ticket_title">
      <h1 v-if="newTicket._id">
        <span class="bold">Subject: </span><span>{{ ticket.title }}</span>
        <br />
        <span class="bold">Salon: </span>
        <span>{{
          `${ticket.author?.salon?.salonName} ${
            ticket.author?.salon?.salonAltName
              ? `(${ticket.author.salon.salonAltName})`
              : ''
          }`
        }}</span>
      </h1>

      <BaseInput
        v-else
        label="Title"
        @input="newTicket.title = $event"
        placeholder="Enter ticket subject.."
      />
    </div>

    <div class="ticket_newMessage">
      <BaseTextarea
        class="newMessageInput"
        label="Message"
        :placeholder="
          newTicket._id ? 'Reply..' : 'Describe your concern here..'
        "
        @input="message = $event"
        :value="message"
      />

      <div class="ticket_newMessage_actions">
        <BaseButton><i class="fas fa-paper-plane"></i> Submit</BaseButton>
      </div>
    </div>

    <ul class="ticket_messages">
      <li
        v-for="message in newTicket.messages"
        :key="message._id"
        class="message"
        :class="{ author: isMessageMine(message) }"
      >
        <div class="message_author">
          <p class="small bold name">{{ message.author.name }}</p>
          <p class="small date">{{ formatDate(message.date) }}</p>
        </div>

        <p class="message_message">
          {{ message.message }}
        </p>
      </li>
    </ul>
  </form>

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Delete ticket"
    text="Are you sure you wish to delete this ticket?"
    @confirm="deleteTicket"
    @deny="showConfirmDelete = false"
  />
</template>

<script>
export default {
  emits: ['created', 'edited', 'deleted'],
  props: {
    ticket: {
      type: Object,
      default: null,
    },
    adminMode: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.socketInit();

    if (this.ticket) {
      this.newTicket = JSON.parse(JSON.stringify(this.ticket));
      this.newTicket.messages.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

      this.$store.dispatch('sockets/join', this.newTicket._id);
    }
  },
  unmounted() {
    this.$store.dispatch('sockets/leave', this.newTicket._id);
  },
  computed: {
    statusOptions() {
      return [
        { value: 'new', option: 'New' },
        { value: 'open', option: 'Open' },
        { value: 'closed', option: 'Closed' },
      ];
    },
  },
  data() {
    return {
      newTicket: {
        title: '',
        status: '',
        author: {
          salon: {
            salonId: '',
            salonName: '',
            salonAltName: '',
          },
          name: '',
        },
        messages: [],
      },

      message: '',
      showConfirmDelete: false,
    };
  },
  methods: {
    socketInit() {
      if (!this.$store.state.sockets.socket)
        this.$store.dispatch('sockets/init');

      this.$store.state.sockets.socket.on('supportTicketUpdated', (data) => {
        if (data._id !== this.newTicket._id) return;

        this.newTicket = JSON.parse(JSON.stringify(data));
        this.newTicket.messages.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );

        this.$emit('edited', this.newTicket);
      });
    },
    formatDate(date) {
      return this.$moment(date).format('LLL');
    },
    isMessageMine(message) {
      if (this.adminMode && message.author.admin) return true;

      if (!this.$store.state.auth.user) return;

      return message.author.staffId === this.$store.state.auth.user._id;
    },
    async submit() {
      try {
        if (!this.message || !this.newTicket.title) return;

        if (!this.adminMode) {
          this.newTicket.messages.push({
            message: this.message,
            author: {
              staffId: this.$store.state.auth.user._id,
              name: `${this.$store.state.auth.user.firstName} ${this.$store.state.auth.user.lastName}`,
            },
          });
        } else {
          this.newTicket.messages.push({
            message: this.message,
            author: {
              admin: true,
              staffId: this.$store.state.admin.admin._id,
              name: `${this.$store.state.admin.admin.firstName} ${this.$store.state.admin.admin.lastName}`,
            },
          });
        }

        // Create
        if (!this.newTicket._id) {
          this.newTicket.status = 'new';

          this.newTicket.author.salon = {
            salonId: this.$store.state.auth.salon._id,
            salonName: this.$store.state.auth.salon.details.shopName,
            salonAltName: this.$store.state.auth.salon.details.altName,
          };

          this.newTicket.author.name = `${this.$store.state.auth.user.firstName} ${this.$store.state.auth.user.lastName}`;

          const response = await this.$axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/supportTickets`,
            this.newTicket
          );

          this.$store.dispatch('sockets/join', response.data.ticket._id);
          this.$emit('created', response.data.ticket);
          this.message = '';
          return;
        }

        // Edit
        this.newTicket.status = 'open';

        const response = await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/supportTickets/${this.newTicket._id}`,
          { ...this.newTicket, admin: this.adminMode }
        );

        this.$emit('edited', response.data.ticket);
        this.message = '';
      } catch (err) {
        console.log(err);
      }
    },
    async deleteTicket() {
      try {
        await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/supportTickets/${this.newTicket._id}`
        );

        this.$emit('deleted');

        this.$toast.success('Ticket deleted');
      } catch (e) {
        console.log(e);
      }
    },
    async save() {
      try {
        const response = await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/supportTickets/${this.newTicket._id}`,
          { ...this.newTicket, admin: this.adminMode }
        );

        this.$emit('edited', {
          ...response.data.ticket,
          closeTicketModal: true,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket {
  padding: 32px;

  .small {
    font-size: 14px;
  }
  .light {
    color: var(--clr-gray);
  }
  .bold {
    font-weight: 700;
  }
  .mt-s {
    margin-top: 5px;
  }

  &_actions {
    margin-bottom: 32px;
    // padding: 16px;
    border-radius: 5px;
    // border: 1px solid var(--clr-light);
    // box-shadow: var(--shadow);

    .title {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 0.75px;
      margin-bottom: 16px;
      text-align: center;
    }

    .options {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .buttons {
        display: flex;
        gap: 16px;
      }
    }
  }

  &_title {
    margin-bottom: 32px;

    h1 {
      font-weight: 400;
      font-size: 24px;
    }
  }

  &_messages {
    .author {
      .message_author {
        .name,
        .date {
          color: var(--clr-warning) !important;
        }
      }
    }

    .message {
      border-radius: 5px;
      border: 1px solid var(--clr-light);
      box-shadow: var(--shadow-small);
      overflow: hidden;

      &:not(:first-child) {
        margin-top: 16px;
      }

      &_author {
        padding: 16px;
        background-color: var(--clr-primary);
        display: flex;
        justify-content: space-between;

        .name {
          color: var(--clr-white);
          font-size: 16px;
          font-weight: 700;
        }
        .date {
          color: var(--clr-light-2);
        }
      }

      &_message {
        padding: 16px;
        line-height: 24px;
      }
    }
  }

  &_newMessage {
    margin-bottom: 32px;

    &_actions {
      margin-top: 16px;

      button {
        i {
          margin-right: 16px;
        }
      }
    }
  }
}

// Mobile
@media (max-width: 600px) {
  .ticket {
    &_actions {
      .options,
      .buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        button,
        .parent {
          width: 100%;
        }
      }
    }
  }
}
</style>
