<template>
  <section class="continue">
    <div class="col">
      <BaseButton v-if="showBack" mode="danger-outline" @click="$emit('back')"
        >Back</BaseButton
      >
    </div>
    <div class="col">
      <div v-if="services.length" class="cart">
        <i class="fas fa-shopping-cart"></i>
        <p>{{ services.length }} services selected</p>
      </div>
      <BaseButton
        v-if="!hideContinue"
        :disabled="disabled"
        @click="$emit('continue')"
        >Continue</BaseButton
      >
    </div>
  </section>
</template>

<script>
export default {
  emits: ['continue', 'back'],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    showBack: {
      type: Boolean,
      default: false,
    },
    hideContinue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    services() {
      return this.$store.state.booking.selectedServices;
    },
  },
};
</script>

<style lang="scss" scoped>
.continue {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 80px;
  width: 100%;
  padding: 16px;
  border-top: 1px solid var(--clr-light);
  background-color: white;
  display: flex;
  justify-content: space-between;

  .cart,
  .col {
    display: flex;
    gap: 16px;
    align-items: center;

    i {
      color: var(--clr-secondary);
    }
  }
}
</style>
