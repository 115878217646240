<template>
  <section class="settings">
    <div class="settings_head">
      <h2>Business settings</h2>
      <div v-if="!subActive" class="inactive">
        <i class="fas fa-exclamation-triangle"></i>
        <p>
          Your account is currently inactive, please update your billing
          information by selecting "Manage Billing" below.
        </p>
      </div>
    </div>
    <div class="settings_body">
      <div class="section">
        <div class="section_head">
          <h2>Account setup</h2>
        </div>
        <ul class="section_list">
          <router-link :to="{ name: 'SalonInfo' }">
            <li>
              <div class="text">
                <p class="title">Salon Info</p>
                <p class="description">
                  Manage information your clients see about your salon
                </p>
              </div>
              <i class="fas fa-angle-right"></i>
            </li>
          </router-link>
          <router-link :to="{ name: 'SalonSchedule' }">
            <li>
              <div class="text">
                <p class="title">Schedule</p>
                <p class="description">
                  Set your salon opening and closing times
                </p>
              </div>
              <i class="fas fa-angle-right"></i>
            </li>
          </router-link>
          <router-link :to="{ name: 'PaymentSettings' }">
            <li>
              <div class="text">
                <p class="title">Payment Settings</p>
                <p class="description">
                  Set rules that apply with your payments
                </p>
              </div>
              <i class="fas fa-angle-right"></i>
            </li>
          </router-link>
          <router-link :to="{ name: 'AdminSettings' }">
            <li>
              <div class="text">
                <p class="title">Admin Settings</p>
                <p class="description">Manage ease of use for your account</p>
              </div>
              <i class="fas fa-angle-right"></i>
            </li>
          </router-link>
          <router-link :to="{ name: 'SecretsSettings' }">
            <li>
              <div class="text">
                <p class="title">Secrets</p>
                <p class="description">Update secret values</p>
              </div>
              <i class="fas fa-angle-right"></i>
            </li>
          </router-link>
        </ul>
      </div>
      <div class="section">
        <div class="section_head">
          <h2>Team</h2>
        </div>
        <ul class="section_list">
          <router-link :to="{ name: 'MemberPermissions' }">
            <li>
              <div class="text">
                <p class="title">Permissions</p>
                <p class="description">
                  Configure level of access to Raserva for each of your team
                  members
                </p>
              </div>
              <i class="fas fa-angle-right"></i>
            </li>
          </router-link>
        </ul>
      </div>
      <div class="section">
        <div class="section_head">
          <h2>Billing</h2>
        </div>
        <ul class="section_list">
          <li @click="stripePortal">
            <div class="text">
              <p class="title">Manage Billing</p>
              <p class="description">
                Manage billing settings such as payment details and view
                invoices
              </p>
            </div>
            <i class="fas fa-angle-right"></i>
          </li>
        </ul>
      </div>
      <div class="section">
        <div class="section_head">
          <h2>Online Booking</h2>
        </div>
        <ul class="section_list">
          <router-link :to="{ name: 'SalonPage', params: { id: salonId } }">
            <li>
              <div class="text">
                <p class="title">View Booking Page</p>
                <p class="description">
                  Navigate to your personal booking page
                </p>
              </div>
              <i class="fas fa-angle-right"></i>
            </li>
          </router-link>
          <router-link :to="{ name: 'BookingSettings' }">
            <li>
              <div class="text">
                <p class="title">Booking Settings</p>
                <p class="description">
                  Provide a better experience to your clients by managing your
                  booking settings
                </p>
              </div>
              <i class="fas fa-angle-right"></i>
            </li>
          </router-link>
          <router-link :to="{ name: 'BookingGallerySetup' }">
            <li>
              <div class="text">
                <p class="title">Booking Gallery</p>
                <p class="description">
                  Show off your team members work on your online booking page
                </p>
              </div>
              <i class="fas fa-angle-right"></i>
            </li>
          </router-link>
        </ul>
      </div>
      <div class="section">
        <div class="section_head">
          <h2>Kiosk</h2>
        </div>
        <ul class="section_list">
          <router-link :to="{ name: 'KioskSettings' }">
            <li>
              <div class="text">
                <p class="title">Kiosk Settings</p>
                <p class="description">Update client requirements on Kiosk</p>
              </div>
              <i class="fas fa-angle-right"></i>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  created() {
    if (this.$route.query.message) {
      this.$toast.info(this.$route.query.message);
    }
  },
  computed: {
    salonId() {
      return this.$store.state.auth.salon._id;
    },
    subActive() {
      return this.$store.state.auth.subActive;
    },
  },
  methods: {
    async stripePortal() {
      const response = await this.$axios.post(
        `${process.env.VUE_APP_RASERVA_BACKEND}/subscriptions/portalSession`,
        {
          stripeCustomerId:
            this.$store.state.auth.salon.billing.stripe.customerId,
        },
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.auth.token}`,
          },
        }
      );

      window.location.href = response.data.url;
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  margin: auto;
  margin-top: 64px;
  padding: 0 16px;
  width: 100%;
  max-width: 1000px;

  &_head {
    h2 {
      font-size: 28px;
    }
    .subtext {
      margin-top: 6px;
    }
    .inactive {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-top: 16px;
      padding: 16px;
      border: 1px solid var(--clr-danger);
      border-radius: 5px;

      i {
        color: var(--clr-danger);
      }
    }
  }

  &_body {
    margin-top: 32px;
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    display: flex;
    flex-direction: column;
    gap: 32px;
    // align-items: start;

    .section {
      border: 1px solid var(--clr-light);
      border-radius: 5px;

      &_head {
        padding: 24px;
        background-color: var(--clr-white-2);
      }

      &_list {
        li {
          padding: 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 32px;
          cursor: pointer;
          transition: background-color 0.2s ease;

          .title {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 6px;
          }
          .description {
            color: var(--clr-gray);
          }

          &:hover {
            background-color: var(--clr-white);
          }
        }
      }
    }
  }
}

// Tablet
@media (max-width: 900px) {
  .settings {
    &_body {
      grid-template-columns: 1fr;
    }
  }
}
</style>
