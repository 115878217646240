<template>
  <section class="login">
    <div class="login_head">
      <h2>Welcome Back!</h2>
      <p>Enter your information below to continue.</p>
    </div>
    <div class="login_form">
      <MemberLogin />
    </div>
  </section>
</template>

<script>
import MemberLogin from '@/components/auth/MemberLogin.vue';

export default {
  components: {
    MemberLogin,
  },
  created() {
    if (this.$route.query.error) {
      this.$toast.error(this.$route.query.error);
    }
  },
};
</script>

<style lang="scss" scoped>
.login {
  padding: 64px 0;

  .container {
    max-width: 1400px;
    margin: auto;
  }

  &_head {
    text-align: center;

    p {
      margin-top: 8px;
    }
  }

  &_form {
    max-width: 600px;
    margin: auto;
    margin-top: 64px;
  }
}
</style>
