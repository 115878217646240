<template>
  <form @submit.prevent="submit" class="form">
    <div class="col-2">
      <BaseInput
        label="First Name"
        :value="member.firstName"
        @input="member.firstName = $event"
      />
      <BaseInput
        label="Last Name"
        :value="member.lastName"
        @input="member.lastName = $event"
      />
    </div>

    <BaseInput
      label="Email"
      :value="member.email"
      @input="member.email = $event"
    />

    <div v-if="!member._id" class="col-2">
      <BaseInput
        inputType="password"
        label="Password"
        @input="member.password = $event"
      />
      <BaseInput
        inputType="password"
        label="Confirm Password"
        @input="member.confirmPassword = $event"
      />
    </div>

    <div class="permissions">
      <h3>Permissions</h3>

      <ul class="toggle_list">
        <li v-for="perm in permissions" :key="perm">
          <div class="toggle">
            <Toggle :checked="isPermitted(perm)" @toggle="togglePerm(perm)" />
            <div class="toggle_text">
              <p class="toggle_text_label">{{ perm }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="manages">
      <h3>Manages</h3>

      <ul class="toggle_list">
        <li v-for="accountType in accountTypes" :key="accountType">
          <div class="toggle">
            <Toggle
              :checked="isManaging(accountType)"
              @toggle="toggleManageAccountType(accountType)"
            />
            <div class="toggle_text">
              <p class="toggle_text_label">{{ accountType }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="form_actions">
      <BaseButton>{{ member._id ? 'Edit' : 'Create' }} Member</BaseButton>
    </div>
  </form>
</template>

<script>
export default {
  emits: ['updated', 'created'],
  props: {
    propMember: {
      type: Object,
    },
  },
  created() {
    if (this.propMember)
      this.member = JSON.parse(JSON.stringify(this.propMember));
  },
  computed: {
    permissions() {
      return [
        'team',
        'team:create',
        'team:edit',
        'team:delete',
        'users',
        'users:create',
        'users:edit',
        'users:edit:marketingCosts',
        'users:delete',
        'staffAccounts',
        'staffAccounts:edit',
        'logs',
        'support',
        'analytics',
        'tickets',
      ];
    },
    accountTypes() {
      return ['all', 'epsg', 'dejavoo'];
    },
  },
  data() {
    return {
      member: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        permissions: ['team', 'users', 'logs'],
        manages: [],
      },
    };
  },
  methods: {
    isPermitted(perm) {
      return this.member.permissions.includes(perm);
    },
    isManaging(accountType) {
      return this.member.manages.includes(accountType);
    },
    togglePerm(perm) {
      if (this.isPermitted(perm)) {
        this.member.permissions = this.member.permissions.filter(
          (p) => p !== perm
        );
      } else {
        this.member.permissions.push(perm);
      }
    },
    toggleManageAccountType(accountType) {
      if (this.isManaging(accountType)) {
        this.member.manages = this.member.manages.filter(
          (a) => a !== accountType
        );
      } else {
        this.member.manages.push(accountType);
      }
    },
    async submit() {
      if (this.member._id) {
        // Edit
        const member = await this.$store.dispatch(
          'admin/updateOne',
          this.member
        );

        this.$emit('updated', member);
      } else {
        // Create
        if (this.member.password !== this.member.confirmPassword) {
          this.$toast.error('Passwords do not match');
          return;
        }

        const member = await this.$store.dispatch('admin/create', this.member);

        this.$emit('created', member);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .col-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
  }

  .permissions,
  .manages {
    margin-top: 16px;
  }
  .toggle_list {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;

    .toggle {
      display: flex;
      align-items: center;
      gap: 32px;
      flex-wrap: wrap;
      padding: 16px 0;

      &_text {
        &_label {
          font-size: 18px;
        }
        &_subtext {
          margin-top: 5px;
          font-size: 14px;
        }
      }
    }
  }

  &_actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
