module.exports = {
  default: [
    {
      day: 0,
      blocks: [],
    },
    {
      day: 1,
      blocks: [
        {
          start: '09:00',
          end: '17:00',
        },
      ],
    },
    {
      day: 2,
      blocks: [
        {
          start: '09:00',
          end: '17:00',
        },
      ],
    },
    {
      day: 3,
      blocks: [
        {
          start: '09:00',
          end: '17:00',
        },
      ],
    },
    {
      day: 4,
      blocks: [
        {
          start: '09:00',
          end: '17:00',
        },
      ],
    },
    {
      day: 5,
      blocks: [
        {
          start: '09:00',
          end: '17:00',
        },
      ],
    },
    {
      day: 6,
      blocks: [],
    },
  ],
  exceptions: [],
};
