<template>
  <section class="info">
    <div class="info_head">
      <h2>Salon Basic Info</h2>
      <p>Manage information your clients see about your salon.</p>
    </div>
    <div class="info_body">
      <form @submit.prevent="submit" class="form">
        <div class="form_body">
          <ImageSelect
            :fileSource="newSalon.profileImage"
            @select="newSalon.profileImage = $event"
          />
          <BaseInput
            label="Shop Name"
            :value="newSalon.details.shopName"
            @input="newSalon.details.shopName = $event"
            :validators="validators.shopName"
          />
          <BaseInput
            label="Alternative Name"
            :value="newSalon.details.altName"
            @input="newSalon.details.altName = $event"
            :validators="validators.altName"
            subtitle="Only visible to staff (used to distinguish salons)"
          />
          <BaseTextarea
            label="Short Description"
            :value="newSalon.details.shortDescription"
            @input="newSalon.details.shortDescription = $event"
            :validators="validators.shortDescription"
          />
          <BasePhoneInput
            label="Shop Phone Number"
            :value="newSalon.details.phone"
            @input="newSalon.details.phone = $event"
          />
          <BaseInput
            label="Street Address"
            :value="newSalon.details.address.street"
            @input="newSalon.details.address.street = $event"
            :validators="validators.min2Max500"
          />
          <BaseInput
            label="City"
            :value="newSalon.details.address.city"
            @input="newSalon.details.address.city = $event"
            :validators="validators.min2Max500"
          />
          <BaseInput
            label="State"
            :value="newSalon.details.address.state"
            @input="newSalon.details.address.state = $event"
            :validators="validators.min2Max500"
          />
          <BaseInput
            label="Postal"
            :value="newSalon.details.address.postal"
            @input="newSalon.details.address.postal = $event"
            :validators="validators.min2Max500"
          />
          <BaseSelect
            label="Timezone"
            :options="timezonesForSelect"
            :value="newSalon.details.timezone"
            subtitle="It's important this is accurate as this may impact the time of appointment a client will see"
            @input="newSalon.details.timezone = $event"
          />
        </div>
        <div class="form_actions">
          <BaseButton :disabled="!formValid || loading"
            ><i v-if="loading" class="fas fa-spinner"></i> Submit</BaseButton
          >
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import ImageSelect from '@/components/components/ImageSelect.vue';

export default {
  components: {
    ImageSelect,
  },
  computed: {
    formValid() {
      if (!this.newSalon.details.shopName) return false;
      return true;
    },
    validators() {
      return {
        shopName: {
          required: true,
          minLength: 2,
          maxLength: 100,
        },
        altName: {
          maxLength: 100,
        },
        shortDescription: {
          minLength: 2,
          maxLength: 500,
        },
        min2Max500: {
          required: true,
          minLength: 2,
          maxLength: 500,
        },
      };
    },
    salon() {
      return this.$store.state.salon.salon;
    },
    timezones() {
      return [
        'America/Adak',
        'America/Anchorage',
        'America/Boise',
        'America/Chicago',
        'America/Denver',
        'America/Detroit',
        'America/Indiana/Indianapolis',
        'America/Indiana/Knox',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Tell_City',
        'America/Indiana/Vevay',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Juneau',
        'America/Kentucky/Louisville',
        'America/Kentucky/Monticello',
        'America/Los_Angeles',
        'America/Menominee',
        'America/Metlakatla',
        'America/New_York',
        'America/Nome',
        'America/North_Dakota/Beulah',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/Phoenix',
        'America/Sitka',
        'America/Yakutat',
        'Pacific/Honolulu',
      ];
    },
    timezonesForSelect() {
      return this.timezones.map((timezone) => {
        return {
          option: timezone,
          value: timezone,
        };
      });
    },
  },
  watch: {
    salon() {
      this.init();
    },
  },
  created() {
    if (!this.salon) return;
    this.init();
  },
  data() {
    return {
      loading: false,

      newSalon: {
        profileImage: '',
        details: {
          shopName: null,
          altName: null,
          shortDescription: null,
          address: {
            street: null,
            city: null,
            state: null,
            postal: null,
          },
          phone: null,
        },
      },
    };
  },
  methods: {
    init() {
      if (!this.salon) return;

      this.newSalon.details = JSON.parse(JSON.stringify(this.salon.details));

      if (!this.salon.profileImage) return;

      this.newSalon.profileImage = JSON.parse(
        JSON.stringify(this.salon.profileImage)
      );
    },
    async submit() {
      this.loading = true;

      try {
        this.$store.dispatch('salon/updateProfileImage', {
          profileImage: this.newSalon.profileImage,
        });
        await this.$store.dispatch(
          'salon/updateDetails',
          this.newSalon.details
        );

        this.$toast.success('Salon info updated');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  &_head {
    h2 {
      font-size: 28px;
    }

    p {
      margin-top: 6px;
    }
  }

  &_body {
    margin-top: 32px;

    .form {
      &_body {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      &_actions {
        margin-top: 32px;
        display: flex;
        justify-content: flex-end;
        gap: 16px;
      }
    }
  }
}
</style>
