<template>
  <section class="endpoints">
    <div class="endpoints_router">
      <p class="bold">On this page:</p>

      <ul>
        <li class="link" @click="scrollIntoView('create')">
          Create appointment
        </li>
        <li class="link" @click="scrollIntoView('get')">Get appointment</li>
        <li class="link" @click="scrollIntoView('getAll')">
          Get salons appointments
        </li>
        <li class="link" @click="scrollIntoView('update')">
          Update appointment
        </li>
        <li class="link" @click="scrollIntoView('delete')">
          Delete appointment
        </li>
        <li class="link" @click="scrollIntoView('requestCancellation')">
          Request cancellation
        </li>
        <li class="link" @click="scrollIntoView('deleteStanding')">
          Delete standing appointment set
        </li>
      </ul>
    </div>

    <!-- Appointment create -->
    <div class="endpoint" id="create" ref="create">
      <DocsAPIAppointmentsCreate />
    </div>

    <!-- Appointment get -->
    <div class="endpoint" id="get" ref="get">
      <DocsAPIAppointmentsGet />
    </div>

    <!-- Appointment get all -->
    <div class="endpoint" id="getAll" ref="getAll">
      <DocsAPIAppointmentsGetAll />
    </div>

    <!-- Appointment update -->
    <div class="endpoint" id="update" ref="update">
      <DocsAPIAppointmentsUpdate />
    </div>

    <!-- Appointment delete -->
    <div class="endpoint" id="delete" ref="delete">
      <DocsAPIAppointmentsDelete />
    </div>

    <!-- Request cancellation -->
    <div class="endpoint" id="requestCancellation" ref="requestCancellation">
      <DocsAPIAppointmentsRequestCancellation />
    </div>

    <!-- Delete standing -->
    <div class="endpoint" id="deleteStanding" ref="deleteStanding">
      <DocsAPIAppointmentsDeleteStanding />
    </div>
  </section>
</template>

<script>
import DocsAPIAppointmentsCreate from './DocsAPIAppointmentsCreate.vue';
import DocsAPIAppointmentsGet from './DocsAPIAppointmentsGet.vue';
import DocsAPIAppointmentsGetAll from './DocsAPIAppointmentsGetAll.vue';
import DocsAPIAppointmentsUpdate from './DocsAPIAppointmentsUpdate.vue';
import DocsAPIAppointmentsDelete from './DocsAPIAppointmentsDelete.vue';
import DocsAPIAppointmentsRequestCancellation from './DocsAPIAppointmentsRequestCancellation.vue';
import DocsAPIAppointmentsDeleteStanding from './DocsAPIAppointmentsDeleteStanding.vue';

export default {
  components: {
    DocsAPIAppointmentsCreate,
    DocsAPIAppointmentsGet,
    DocsAPIAppointmentsGetAll,
    DocsAPIAppointmentsUpdate,
    DocsAPIAppointmentsDelete,
    DocsAPIAppointmentsRequestCancellation,
    DocsAPIAppointmentsDeleteStanding,
  },
  methods: {
    scrollIntoView(element) {
      this.$refs[element].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoints {
  &_router {
    margin-bottom: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-white-2);

    ul {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 32px;

      .link {
        cursor: pointer;
        color: var(--clr-link);
      }
    }
  }

  .endpoint {
    &:not(:first-child) {
      margin-top: 64px;
    }
  }
}
</style>
