<template>
  <teleport to="body">
    <transition
      name="modal"
      :class="{ fullScreen: fullScreen }"
      :style="{ zIndex: zIndex ? zIndex : zedIndex }"
    >
      <div class="modal" v-if="show">
        <Backdrop v-if="!fullScreen" mask @click="$emit('close')" />
        <div class="container">
          <div
            class="modal_content"
            :style="{
              maxWidth: maxWidth,
              minHeight: minHeight,
              maxHeight: maxHeight,
              zIndex: zIndex,
            }"
            v-if="show"
          >
            <div class="modal_content_head">
              <p v-if="fullScreen" class="fullScreenEmptySpace">&nbsp;</p>
              <p>{{ title }}</p>
              <div class="actions">
                <slot name="header"></slot>
                <i
                  class="fas fa-times"
                  @click="
                    $emit('close');
                    $emit('exitPressed');
                  "
                ></i>
              </div>
            </div>
            <div class="modal_content_body">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  emits: ['close', 'exitPressed'],
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    maxWidth: {
      type: String,
      default: '800px',
    },
    minHeight: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: String,
      default: '',
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    zIndex: {
      type: Number,
      required: false,
    },
  },
  computed: {
    zedIndex() {
      return +this.$store.getters['styles/modalZIndex'];
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleEsc);
  },
  unmounted() {
    window.removeEventListener('keydown', this.handleEsc);
  },
  methods: {
    handleEsc(event) {
      if (event.key === 'Escape') {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  // z-index: 1000;

  &_content {
    // padding: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    // height: 30rem;
    transform: translate(-50%, -50%);
    margin: auto;
    background-color: white;
    border-radius: 10px;
    width: 95%;
    display: flex;
    flex-direction: column;
    max-height: 95vh;

    &_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      border-bottom: 1px solid var(--clr-light);

      p {
        font-weight: 700;
      }

      i {
        cursor: pointer;
      }

      .actions {
        display: flex;
        align-items: center;
        gap: 32px;
      }
    }

    &_body {
      flex-grow: 1;
      overflow-y: auto;
    }
  }
}

.fullScreen {
  .modal_content {
    height: 100vh;
    max-height: 100vh;
    width: 100vw !important;
    max-width: 100vw !important;
    border-radius: 0;

    &_head {
      p {
        text-transform: inherit;
        font-size: 28px;
      }

      i {
        font-size: 24px;
      }
    }
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

// Tablet
@media (max-width: 900px) {
  .fullScreen {
    .modal_content {
      &_head {
        .fullScreenEmptySpace {
          display: none;
        }

        p {
          text-transform: inherit;
          font-size: 16px;
          font-weight: 700;
        }

        i {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
