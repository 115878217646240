<template>
  <section class="campaigns">
    <div class="campaigns_head">
      <h2>Auto campaigns</h2>
      <p>
        Manage enabled auto campaigns to personalize messages, amend discount
        offers and choose which clients to promote to. You will be charged
        monthly along with your subscription cost.
        <span @click="showPricing = true" class="link"
          >Click here to view pricing.</span
        >
      </p>
    </div>
    <div class="campaigns_body">
      <div class="campaigns_campaigns">
        <div class="campaign">
          <div class="icon">
            <i class="fas fa-birthday-cake"></i>
          </div>
          <div v-if="campaigns.birthday.enabled" class="enabled">
            <i class="fas fa-check"></i>
            <p>Enabled</p>
          </div>
          <div class="text">
            <h3>Birthday special</h3>
            <p>
              Surprise clients on their birthday, proven to boost retention and
              client loyalty. This campaign sends to clients near their
              birthday.
            </p>
          </div>
          <div class="actions">
            <BaseButton @click="selectCampaign('birthday')">Details</BaseButton>
          </div>
          <div class="track">
            <p>
              Redemptions: <span>{{ campaigns.birthday.redemptions }}</span>
            </p>
            <p>
              Est. Revenue:
              <span>{{ formatCurrency(campaigns.birthday.revenue) }}</span>
            </p>
          </div>
        </div>
        <div class="campaign">
          <div class="icon">
            <i class="fas fa-door-open"></i>
          </div>
          <div v-if="campaigns.newClient.enabled" class="enabled">
            <i class="fas fa-check"></i>
            <p>Enabled</p>
          </div>
          <div class="text">
            <h3>Welcome new clients</h3>
            <p>
              Turn new clients into regulars by encouraging them to book again
              with a special discount. This campaign automatically sends to
              clients the day after their first visit.
            </p>
          </div>
          <div class="actions">
            <BaseButton @click="selectCampaign('newClient')"
              >Details</BaseButton
            >
          </div>
          <div class="track">
            <p>
              Redemptions: <span>{{ campaigns.newClient.redemptions }}</span>
            </p>
            <p>
              Est. Revenue:
              <span>{{ formatCurrency(campaigns.newClient.revenue) }}</span>
            </p>
          </div>
        </div>
        <div class="campaign">
          <div class="icon">
            <i class="fas fa-star"></i>
          </div>
          <div v-if="campaigns.regulars.enabled" class="enabled">
            <i class="fas fa-check"></i>
            <p>Enabled</p>
          </div>
          <div class="text">
            <h3>Reward Regulars</h3>
            <p>
              Reward top clients with a special offer, a great way to keep your
              best clients engaged. This campaign automatically send to clients
              with 3 or more sales in the last 2 months.
            </p>
          </div>
          <div class="actions">
            <BaseButton @click="selectCampaign('regulars')">Details</BaseButton>
          </div>
          <div class="track">
            <p>
              Redemptions: <span>{{ campaigns.regulars.redemptions }}</span>
            </p>
            <p>
              Est. Revenue:
              <span>{{ formatCurrency(campaigns.regulars.revenue) }}</span>
            </p>
          </div>
        </div>
        <div class="campaign">
          <div class="icon">
            <i class="fas fa-sync"></i>
          </div>
          <div v-if="campaigns.winBack.enabled" class="enabled">
            <i class="fas fa-check"></i>
            <p>Enabled</p>
          </div>
          <div class="text">
            <h3>Win Back Clients</h3>
            <p>
              Encourage disengaged clients to return with a special offer. This
              campaign automatically send to clients with 3 or more sales in the
              last 6 months that have not come back in the last 2 months.
            </p>
          </div>
          <div class="actions">
            <BaseButton @click="selectCampaign('winBack')">Details</BaseButton>
          </div>
          <div class="track">
            <p>
              Redemptions: <span>{{ campaigns.winBack.redemptions }}</span>
            </p>
            <p>
              Est. Revenue:
              <span>{{ formatCurrency(campaigns.winBack.revenue) }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Modal :show="showPricing" @close="showPricing = false">
    <MarketingPrices />
  </Modal>

  <Modal
    fullScreen
    title="Auto Campaign"
    :show="selectedCampaign"
    @close="selectedCampaign = null"
  >
    <div class="campaignModal">
      <component
        v-bind:is="selectedCampaign"
        @updated="selectedCampaign = null"
      ></component>
    </div>
  </Modal>
</template>

<script>
import BirthdayCampaign from '@/views/marketing/BirthdayCampaign.vue';
import NewClientCampaign from '@/views/marketing/NewClientCampaign.vue';
import RegularsCampaign from '@/views/marketing/RegularsCampaign.vue';
import WinBackCampaign from '@/views/marketing/WinBackCampaign.vue';
import MarketingPrices from '@/components/discounts/MarketingPrices.vue';

export default {
  components: {
    BirthdayCampaign,
    NewClientCampaign,
    MarketingPrices,
  },
  computed: {
    campaigns() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.marketing.campaigns;
    },
  },
  data() {
    return {
      selectedCampaign: null,
      showPricing: false,
    };
  },
  methods: {
    selectCampaign(campaign) {
      if (campaign === 'birthday') {
        this.selectedCampaign = BirthdayCampaign;
      } else if (campaign === 'newClient') {
        this.selectedCampaign = NewClientCampaign;
      } else if (campaign === 'regulars') {
        this.selectedCampaign = RegularsCampaign;
      } else if (campaign === 'winBack') {
        this.selectedCampaign = WinBackCampaign;
      }
    },
    formatCurrency(amount) {
      return `$${amount.toFixed(2)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.campaigns {
  max-width: 1000px;

  &_head {
    h2 {
      font-size: 28px;
    }
    p {
      margin-top: 8px;
    }
  }

  .link {
    color: var(--clr-link);
    cursor: pointer;
  }

  &_campaigns {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;

    .campaign {
      padding: 32px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      position: relative;

      .enabled {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 4px 16px;
        border-radius: 50px;
        background-color: var(--clr-success);
        color: var(--clr-white);
        font-size: 10px;
        display: inline-block;
        align-items: center;
        margin-bottom: 8px;

        p {
          margin-left: 16px;
          display: inline;
          margin-top: 0 !important;
          color: var(--clr-white) !important;
        }
      }

      .icon {
        height: 65px;
        width: 65px;
        border-radius: 50%;
        background-color: var(--clr-secondary);
        display: grid;
        place-items: center;
        color: var(--clr-white);
        font-size: 24px;
      }

      .text {
        margin-top: 32px;

        p {
          margin-top: 16px;
          line-height: 24px;
          color: var(--clr-gray);
        }
      }

      .track {
        margin-top: 24px;
        font-size: 14px;
        color: var(--clr-gray);

        span {
          font-weight: 700;
        }

        p:not(:first-child) {
          margin-top: 5px;
        }
      }

      .actions {
        margin-top: 24px;
      }
    }
  }
}

.campaignModal {
  padding: 32px;
}

// Tablet
@media (max-width: 900px) {
  .campaigns {
    &_campaigns {
      grid-template-columns: 1fr;
    }
  }
}
</style>
