import io from 'socket.io-client';
import router from '../router/index.js';

import { useToast } from 'vue-toastification';
const toast = useToast();

export default {
  namespaced: true,
  state: {
    socket: null,
  },
  mutations: {},
  actions: {
    init({ state }) {
      state.socket = io(process.env.VUE_APP_RASERVA_BACKEND);
    },
    join({ state, dispatch }, payload) {
      if (!state.socket) {
        state.socket = io(process.env.VUE_APP_RASERVA_BACKEND);
      }

      state.socket.emit('join', payload);

      dispatch('setListeners');
    },

    leave({ state }, payload) {
      if (!state.socket) return;

      state.socket.emit('leave', payload);
    },

    setListeners({ state, rootState }) {
      state.socket.on('notification', (data) => {
        rootState.auth.user.notifications.push(data);

        if (
          router &&
          router.currentRoute &&
          router.currentRoute.fullPath &&
          router.currentRoute.fullPath.includes('kiosk')
        )
          return;

        toast.info('New notification');
      });

      state.socket.on('newMessage', (data) => {
        const index = rootState.messenger.chats.findIndex(
          (chat) => chat._id === data._id
        );

        if (index === -1) return;

        rootState.messenger.chats[index] = data;

        if (
          rootState.messenger.chats[index]._id ===
          rootState.messenger.selectedChat._id
        ) {
          rootState.messenger.selectedChat = data;
        }
      });

      state.socket.on('newChat', (data) => {
        rootState.messenger.chats.push(data);
      });

      state.socket.on('clearTickets', () => {
        rootState.tickets.tickets = [];

        if (
          router &&
          router.currentRoute &&
          router.currentRoute.fullPath &&
          router.currentRoute.fullPath.includes('kiosk')
        )
          return;

        toast.info('Tickets cleared');
      });

      state.socket.on('updateStaff', (data) => {
        rootState.staff.staff = data;

        // TODO: Commenting this out solves the issue of the navigation interuption but it was added for a reason.. need to figure out a solution
        // rootState.auth.loggedInSalonStaff = rootState.staff.staff.find(
        //   (staff) => staff.staffId === rootState.auth.user._id
        // );

        if (
          router &&
          router.currentRoute &&
          router.currentRoute.fullPath &&
          router.currentRoute.fullPath.includes('kiosk')
        )
          return;

        toast.info('Staff update');
      });

      state.socket.on('updateStaffTurnOrder', (data) => {
        rootState.auth.salon.customStaffTurnOrder = data;

        data.forEach((staffId) => {
          const index = rootState.auth.salon.staff.findIndex(
            (s) => s.staffId.toString() === staffId.toString()
          );

          if (index === -1) return;

          rootState.auth.salon.staff[index].working = true;
          rootState.auth.salon.staff[index].available = true;
        });

        if (
          router &&
          router.currentRoute &&
          router.currentRoute.fullPath &&
          router.currentRoute.fullPath.includes('kiosk')
        )
          return;

        toast.info('Staff update');
      });

      state.socket.on('ticketCreated', (data) => {
        if (
          !rootState.tickets.tickets.find((ticket) => ticket._id === data._id)
        ) {
          rootState.tickets.tickets.push(data);
          rootState.tickets.changeCount++;

          if (
            router &&
            router.currentRoute &&
            router.currentRoute.fullPath &&
            router.currentRoute.fullPath.includes('kiosk')
          )
            return;

          toast.info('Dashboard updated');
        }
      });

      state.socket.on('ticketUpdated', (data) => {
        const index = rootState.tickets.tickets.findIndex(
          (ticket) => ticket._id === data._id
        );

        if (index !== -1) {
          rootState.tickets.tickets[index] = data;
          rootState.tickets.changeCount++;

          if (
            router &&
            router.currentRoute &&
            router.currentRoute.fullPath &&
            router.currentRoute.fullPath.includes('kiosk')
          )
            return;

          toast.info('Dashboard updated');
        }
      });

      state.socket.on('ticketDeleted', (data) => {
        const index = rootState.tickets.tickets.findIndex(
          (ticket) => ticket._id === data._id
        );

        if (index !== -1) {
          rootState.tickets.tickets.splice(index, 1);
          rootState.tickets.changeCount++;

          if (
            router &&
            router.currentRoute &&
            router.currentRoute.fullPath &&
            router.currentRoute.fullPath.includes('kiosk')
          )
            return;

          toast.info('Dashboard updated');
        }
      });

      state.socket.on('salonNotification', (data) => {
        if (!rootState.auth.salon.notifications)
          rootState.auth.salon.notifications = [];

        rootState.auth.salon.notifications.unshift(data);
      });

      state.socket.on('itemsUpdated', (data) => {
        toast.info('Items updated');

        rootState.salon.services = data.services;
        rootState.salon.products = data.services;

        rootState.items.services = data.services;
        rootState.items.products = data.services;

        rootState.items.categoryChangeCount++;
      });
    },
  },
};
