<template>
  <section class="tokenizer">
    <form name="tokenform" id="tokenform">
      <iframe
        id="tokenFrame"
        name="tokenFrame"
        :src="tokenizerSrc"
        frameborder="0"
        scrolling="no"
        height="160"
        width="100%"
      ></iframe>
    </form>
  </section>
</template>

<script>
export default {
  emits: ['tokenized'],
  computed: {
    styles() {
      return '.error{border: 1px solid #f13e3e} input, select{font-size: 16px; margin: 8px 0; padding: 6px 16px; border: 1px solid #dee3e7; border-radius: 5px; line-height: 24px} input{width: 95%} select{width: 45%} label{font-family: sans-serif; color: #535353; font-size: 14px; font-weight: 500}';
    },
    tokenizerSrc() {
      return `https://boltgw.cardconnect.com/itoke/ajax-tokenizer.html?useexpiry=true&css=${encodeURIComponent(
        this.styles
      )}`;
    },
  },
  data() {
    return {
      token: null,
      expiry: null,
    };
  },
  mounted() {
    window.addEventListener('message', this.tokenEventListener);
  },
  unmounted() {
    window.removeEventListener('message', this.tokenEventListener);
  },
  methods: {
    tokenEventListener(event) {
      this.token = JSON.parse(event.data).message;
      this.expiry = JSON.parse(event.data).expiry;

      this.$emit('tokenized', { token: this.token, expiry: this.expiry });
    },
  },
};
</script>
