<template>
  <section class="endpoints">
    <div class="endpoints_router">
      <p class="bold">On this page:</p>

      <ul>
        <li class="link" @click="scrollIntoView('create')">Create gift card</li>
        <li class="link" @click="scrollIntoView('createOnline')">
          Purchase gift card online
        </li>
      </ul>
    </div>

    <!-- Gift card create -->
    <div class="endpoint" id="create" ref="create">
      <DocsAPIGiftcardsCreate />
    </div>

    <!-- Gift card create online -->
    <div class="endpoint" id="createOnline" ref="createOnline">
      <DocsAPIGiftcardsCreateOnline />
    </div>
  </section>
</template>

<script>
import DocsAPIGiftcardsCreate from '../giftcards/DocsAPIGiftcardsCreate.vue';
import DocsAPIGiftcardsCreateOnline from '../giftcards/DocsAPIGiftcardsCreateOnline.vue';

export default {
  components: {
    DocsAPIGiftcardsCreate,
    DocsAPIGiftcardsCreateOnline,
  },
  methods: {
    scrollIntoView(element) {
      this.$refs[element].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoints {
  &_router {
    margin-bottom: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-white-2);

    ul {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 32px;

      .link {
        cursor: pointer;
        color: var(--clr-link);
      }
    }
  }

  .endpoint {
    &:not(:first-child) {
      margin-top: 64px;
    }
  }
}
</style>
