<template>
  <section class="sales">
    <div class="sales_sidebar">
      <SubSidebar :routes="routes" title="Sales" />
    </div>
    <div class="sales_router">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import SubSidebar from '@/components/components/SubSidebar.vue';

export default {
  components: {
    SubSidebar,
  },
  created() {
    if (!this.loggedInStaff) return;

    this.init();
  },
  data() {
    return {
      routes: [],
    };
  },
  computed: {
    loggedInStaff() {
      return this.$store.state.auth.loggedInSalonStaff;
    },
    managerMode() {
      return this.$store.state.auth.managerMode;
    },
  },
  watch: {
    loggedInStaff() {
      this.init();
    },
    managerMode() {
      this.init();
    },
  },
  methods: {
    async init() {
      await this.setRoutes();
      if (!this.$route.query.bypassFirst) this.goToFirstRoute();
    },
    async setRoutes() {
      this.routes = [];

      // Summary
      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'tickets/viewSummary'
        )
      ) {
        this.routes.push({
          name: 'Summary',
          linkName: 'Summary',
        });

        this.routes.push({
          name: 'Reports',
          linkName: 'SalesVisuals',
        });
      }

      // Reports
      if (
        (await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'tickets/viewOwnReports'
        )) ||
        (await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'tickets/viewOthersReports'
        ))
      ) {
        this.routes.push({
          name: 'Staff',
          linkName: 'StaffSales',
        });
        this.routes.push({
          name: 'Shifts',
          linkName: 'SalesHourly',
        });
      }

      // Tickets
      // Summary
      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'tickets/viewTickets'
        )
      ) {
        this.routes.push({
          name: 'Tickets',
          linkName: 'SalesTickets',
        });
      }

      // By XXX
      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'tickets/viewSummary'
        )
      ) {
        this.routes.push({
          name: 'By Category',
          linkName: 'SalesByCategory',
        });
        this.routes.push({
          name: 'By Service',
          linkName: 'SalesByService',
        });
        this.routes.push({
          name: 'By Product',
          linkName: 'SalesByProduct',
        });
      }

      this.routes.push({
        name: 'Cleaning Logs',
        linkName: 'CleaningLogs',
      });
    },
    goToFirstRoute() {
      this.$router.push({
        name: this.routes[0].linkName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sales {
  display: flex;
  height: 100%;

  &_router {
    overflow-y: auto;
    flex-grow: 1;
    padding: 32px;
    display: flex;
    justify-content: center;
  }
}
</style>
