<template>
  <section class="groups">
    <form @submit.prevent="createGroup" class="form">
      <BaseInput
        label="Group Name"
        placeholder="Hair Stylists"
        @input="newGroupName = $event"
      />
      <div class="form_actions">
        <BaseButton :disabled="!groupFormValid">Create Group</BaseButton>
      </div>
    </form>
    <div class="groups_groups">
      <ul>
        <li v-for="(group, index) in groups" :key="index">
          <p>{{ group }}</p>
          <i @click="deleteGroup(group)" class="fas fa-trash"></i>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  emits: ['updated'],
  computed: {
    groups() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.groups;
    },
    groupFormValid() {
      if (this.newGroupName.length < 2) return false;
      return true;
    },
  },
  data() {
    return {
      newGroupName: '',
    };
  },
  methods: {
    async createGroup() {
      if (this.isValid(this.newGroupName)) {
        this.$toast.error('Group name can not include special characters');
        return;
      }

      try {
        const groups = await this.$store.dispatch(
          'staff/createGroup',
          this.newGroupName
        );

        this.$store.state.salon.salon.groups = groups;

        this.$emit('updated');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    isValid(str) {
      return /[~`!#$%^*+=\-[\]\\';,/{}|\\":<>?]/g.test(str);
    },

    async deleteGroup(name) {
      try {
        const groups = await this.$store.dispatch('staff/deleteGroup', name);

        this.$store.state.salon.salon.groups = groups;

        this.$emit('updated');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.groups {
  .form {
    &_actions {
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }
  }
  &_groups {
    margin-top: 32px;

    ul {
      li {
        &:not(:first-child) {
          margin-top: 16px;
        }

        padding: 16px;
        border: 1px solid var(--clr-light);
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .fa-trash {
          cursor: pointer;
          color: var(--clr-danger);
        }
      }
    }
  }
}
</style>
