<template>
  <ul class="checkout_cart">
    <h2>Order Summary</h2>

    <li v-for="product in cart" :key="product._id" class="card">
      <!-- Image -->
      <div v-if="product.image" class="card_image">
        <img :src="product.image" alt="Product Image" />
      </div>
      <div v-else class="card_image-none">
        <div class="text">
          <i class="fas fa-ban"></i>
          <p>No Image</p>
        </div>
      </div>

      <!-- Other Info -->
      <div class="card_other">
        <p class="bold">{{ product.title }}</p>
        <p class="small light mt-s">
          ${{
            (
              product.price +
              product.price * (this.additionalProcessingFee * 0.01)
            ).toFixed(2)
          }}
        </p>
        <p
          v-if="!product.duration"
          @click="removeItem(product._id)"
          class="small link"
        >
          Remove
        </p>
      </div>
    </li>

    <li class="total">
      <div class="line">
        <h3>Subtotal</h3>
        <h3>${{ subtotal.toFixed(2) }}</h3>
      </div>
      <div class="line">
        <h3>Tax</h3>
        <h3>${{ tax.toFixed(2) }}</h3>
      </div>
      <div class="line">
        <h2>Total</h2>
        <h2>${{ total.toFixed(2) }}</h2>
      </div>
    </li>

    <li v-if="upFrontFee" class="alert alert-info">
      <p>
        This establishment requires {{ upFrontFee }}% of services total up front
      </p>
    </li>
  </ul>
</template>

<script>
export default {
  emits: ['update'],
  computed: {
    additionalProcessingFee() {
      return (
        this.$store.state.booking.salon.payments
          .cashDiscountAdditionalProcessingFee || 0
      );
    },
    processor() {
      if (!this.$store.state.booking.salon) return;
      return this.$store.state.booking.salon.payments.processor;
    },
    billing() {
      if (!this.$store.state.booking.salon) return;
      return this.$store.state.booking.salon.billing;
    },
    upFrontFee() {
      if (
        !this.$store.state.booking.salon ||
        !this.$store.state.booking.salon.billing.chargeanywhere
      )
        return;
      return this.$store.state.booking.salon.billing.chargeanywhere
        .collectBookingPercentUpFront;
    },
    cart() {
      return this.$store.state.booking.cart;
    },
    subtotal() {
      let total = 0;
      this.cart.forEach((item) => {
        if (item.priceType === 'duration')
          item.price = item.price * item.duration;

        total +=
          (item.price + item.price * (this.additionalProcessingFee * 0.01)) *
          (item.duration ? this.upFrontFee * 0.01 : 1);
      });
      return total;
    },
    tax() {
      let total = 0;
      this.cart.forEach(
        (item) => (total += item.price * (item.taxRate * 0.01))
      );
      return total;
    },
    total() {
      return this.subtotal + this.tax;
    },
  },
  methods: {
    removeItem(itemId) {
      const index = this.cart.findIndex((item) => item._id === itemId);
      if (index === -1) return;
      this.cart.splice(index, 1);
      this.$emit('update');
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout_cart {
  padding: 32px;
  border: 1px solid var(--clr-light);
  border-radius: 5px;
  background-color: white;

  .bold {
    font-weight: 700;
  }
  .small {
    font-size: 14px;
  }
  .light {
    color: var(--clr-gray);
  }
  .link {
    cursor: pointer;
    color: var(--clr-link);
  }
  .mt-s {
    margin-top: 5px;
  }
  .mt-m {
    margin-top: 16px;
  }

  h2 {
    margin-bottom: 32px;
  }

  .card {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 32px;

    &:not(:first-child) {
      margin-top: 16px;
    }

    &_image {
      min-height: 100px;
      width: 100px;
      min-width: 100px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &_image-none {
      background-color: var(--clr-light);
      min-height: 100px;
      width: 100px;
      min-width: 100px;
      display: grid;
      place-items: center;
      text-align: center;
      color: var(--clr-gray);

      i {
        font-size: 44px;
        margin-bottom: 12px;
      }
    }

    &_other {
      text-align: right;

      .link {
        margin-top: 16px;
      }
    }
  }

  .alert {
    margin-top: 16px;
    padding: 12px;
    font-size: 14px;
    border-radius: 5px;

    &-info {
      background-color: #c3edf7;
      color: #044653;
      border-color: #aceaf7;
    }
  }

  .total {
    border-top: 1px solid var(--clr-light);
    margin-top: 32px;
    padding-top: 32px;

    .line {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:not(:first-child) {
        margin-top: 8px;
      }
    }

    h3 {
      font-weight: 400;
    }
    h2 {
      margin-bottom: 0;
    }
  }
}
</style>
