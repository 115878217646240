<template>
  <section class="secrets">
    <div class="secrets_head">
      <h2>Secrets</h2>
      <p>
        Secrets are secure data that you will not be able to see once submitted.
        You may update these values, but please do so only if you are fully
        aware of what you are doing.<br /><br />
        <span class="bold"
          >If a field is empty that does NOT mean the value is empty. All fields
          are empty after submitting for security reasons.</span
        >
      </p>
    </div>
    <div class="secrets_body">
      <!-- Charge Anywhere Payment Form -->
      <form @submit.prevent="updateChargeanywherePFSecret" class="form">
        <BaseInput
          label="Charge Anywhere PF Secret"
          @input="chargeanywherePFSecret = $event"
          :value="chargeanywherePFSecret"
        />
        <BaseButton :disabled="loading || !chargeanywherePFSecretValid"
          ><i v-if="loading" class="fas fa-spinner"></i> Submit</BaseButton
        >
      </form>

      <!-- Dejavoo Ecom Token -->
      <form @submit.prevent="updateDejavooEcomToken" class="form">
        <BaseInput
          label="Dejavoo Ecom Token"
          @input="dejavooEcomToken = $event"
          :value="dejavooEcomToken"
        />
        <BaseButton :disabled="loading || !dejavooEcomTokenValid"
          ><i v-if="loading" class="fas fa-spinner"></i> Submit</BaseButton
        >
      </form>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      loading: false,

      chargeanywherePFSecret: '',
      dejavooEcomToken: '',
      highLevelAPIKey: '',
    };
  },
  computed: {
    chargeanywherePFSecretValid() {
      return this.chargeanywherePFSecret?.length;
    },
    dejavooEcomTokenValid() {
      return this.dejavooEcomToken?.length;
    },
    highLevelAPIKeyValid() {
      return this.highLevelAPIKey?.length;
    },
  },
  methods: {
    async updateChargeanywherePFSecret() {
      this.loading = true;

      try {
        await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/secrets/chargeanywherePFSecret`,
          {
            value: this.chargeanywherePFSecret,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.chargeanywherePFSecret = null;

        this.$toast.success('Secret updated');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    async updateDejavooEcomToken() {
      this.loading = true;

      try {
        await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/secrets/dejavooEcomToken`,
          {
            value: this.dejavooEcomToken,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.dejavooEcomToken = null;

        this.$toast.success('Secret updated');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.secrets {
  max-width: 800px;

  .bold {
    font-weight: 700;
  }

  &_head {
    h2 {
      font-size: 32px;
    }
    p {
      margin-top: 8px;
    }
  }
  &_body {
    margin-top: 64px;

    .form {
      display: flex;
      align-items: flex-end;
      gap: 16px;

      &:not(:first-child) {
        margin-top: 32px;
      }

      .parent {
        flex-grow: 1;
      }
    }
  }
}

// Mobile
@media (max-width: 600px) {
  .form {
    flex-direction: column;
    align-items: stretch !important;
  }
}
</style>
