<template>
  <div class="endpoint_head">
    <h2>Process transaction</h2>

    <div class="route">
      <i
        @click="
          $router.push('/documentation/api/dejavoo#process');
          $toast.info('Set URL to endpoint');
        "
        :style="{ color: 'var(--clr-secondary)', cursor: 'pointer' }"
        class="fas fa-hashtag"
      ></i>
      <p class="tag tag-blue">POST</p>
      <p class="url">{{ apiUrl }}<span>/dejavoo/processTransaction</span></p>
    </div>

    <div class="description">
      <p>
        Sends transaction to Dejavoo terminal, returns an ID of a job that you
        can query periodically using the <span class="tag tag-blue">GET</span>
        <span :style="{ fontWeight: 700, marginLeft: '8px' }"
          >/dejavoo/queryJob</span
        >
        endpoint to see if the transaction is complete.
      </p>
    </div>
  </div>

  <div class="endpoint_body">
    <div class="request">
      <h3>Request</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
          <p>application/json</p>
        </div>

        <ul>
          <!-- Payment type -->
          <li>
            <div class="col value">
              <p class="bold">PaymentType</p>
              <p class="description">Identifies EDC type</p>
              <p class="examples">Examples: <span>Credit, Debit, EBT,</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- Trans Type -->
          <li>
            <div class="col value">
              <p class="bold">TransType</p>
              <p class="description">
                Identifies the type of transaction to be run
              </p>
              <p class="examples">
                Examples: <span>Sale, Return, Void, Auth, Capture</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- Amount -->
          <li>
            <div class="col value">
              <p class="bold">Amount</p>
              <p class="description">
                Dollar amount of the transaction (This is the total amount. If
                tip is added, Amount will include base + tip)
              </p>
              <p class="examples">Examples: <span>10.00 = $10.00</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- RefId -->
          <li>
            <div class="col value">
              <p class="bold">RefId</p>
              <p class="description">
                Alpha numeric reference field. Must be unique for the open batch
                scope. Set by the host system and, Mandatory for all
                deployments. Used for Tip or other transaction adjustment
                requests related to a given transaction, for example, Tip
                Adjustment or Void Transaction request must have a RefId value
                of an original transaction.
              </p>
              <p class="examples">
                Examples: <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6f</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- AuthKey -->
          <li>
            <div class="col value">
              <p class="bold">AuthKey</p>
              <p class="description">
                Specifies the Auth-Key, which authorizes Host system to use the
                SPIn™ Web Service
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- RegisterId -->
          <li>
            <div class="col value">
              <p class="bold">RegisterId</p>
              <p class="examples">
                Examples: <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6f</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- PrintReceipt -->
          <li>
            <div class="col value">
              <p class="bold">PrintReceipt</p>
              <p class="examples">
                Examples: <span>No, Merchant, Customer, Both (Default)</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="response">
      <h3>Response</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">id</p>
              <p class="description">
                ID of job to query periodically using the
                <span class="tag tag-blue">GET</span>
                <span :style="{ fontWeight: 700, marginLeft: '8px' }"
                  >/dejavoo/queryJob</span
                >
                endpoint
              </p>
              <p class="examples">Examples: <span>125</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="reqResExamples">
      <h3>Examples</h3>

      <!-- Request -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Request</h4>
          <p>application/json</p>
        </div>

        <ul>
          <li>
            <p>
              { "PaymentType": "Credit", "TransType": "Sale", "Amount": "24.00",
              "RefId": "ddff496c-9803-4049-8d51-750502eecc7b", "AuthKey":
              "Eehugew3Ql", "RegisterId": "2239005", "PrintReceipt": "Customer"
              }
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    apiUrl() {
      return process.env.VUE_APP_RASERVA_BACKEND;
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoint {
  .tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px;
    border-radius: 5px;
    color: white;

    &-blue {
      background-color: var(--clr-secondary);
    }
  }

  &_head {
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }

    .route {
      padding: 8px 16px;
      border-radius: 5px;
      background-color: var(--clr-white-2);
      display: flex;
      align-items: center;
      gap: 16px;

      .url {
        color: var(--clr-gray);

        span {
          color: var(--clr-primary);
          font-weight: 700;
        }
      }
    }

    .description {
      margin-top: 32px;
    }
  }

  &_body {
    .request,
    .response,
    .reqResExamples {
      margin-top: 32px;
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &_section {
      &:not(:first-child) {
        margin-top: 32px;
      }

      .head {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 20px;
          margin-bottom: 16px;
          color: var(--clr-secondary);
        }

        p {
          font-size: 14px;
          color: var(--clr-gray);
        }
      }

      ul {
        .subitem {
          margin-left: 32px;
        }

        li {
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          &:not(:first-child) {
            margin-top: 16px;
          }

          .value {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .bold {
            font-weight: 700;
          }
          .required {
            color: orangered;
          }

          .description {
            margin: 4px 0;
            color: var(--clr-gray);
          }

          .examples {
            span {
              background-color: var(--clr-white-2);
              padding: 4px 8px;
              border-radius: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
