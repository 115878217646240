<template>
  <section class="checkout">
    <Spinner v-if="loading" fullscreen />

    <div
      v-if="!activeClient"
      class="alert alert-info mt-m"
      style="flex-grow: 1"
    >
      <p>
        You must be logged in to make an online purchase,
        <span @click="clientLogin">click here</span>
        to login.
      </p>
    </div>

    <div v-else class="checkout_payment">
      <h2>Payment Information</h2>

      <div class="alert alert-info mt-m">
        <p>
          If you are booking an appointment with this order, please
          <router-link :to="{ name: 'BookServices' }"
            ><span>click here</span></router-link
          >. Otherwise to only make this purchase continue with the following
          form.
        </p>
      </div>

      <div v-if="paid" class="confirmed">
        <h1>Order confirmed!</h1>
        <p>
          Your order has been successfully placed, the establishment has
          received your order. This establishment may reach out to you if any
          need about this order.
        </p>
      </div>

      <!-- Chage Anywhere -->
      <form
        v-if="!paid && total > 0 && processor === 'chargeanywhere'"
        class="form"
      >
        <iframe
          :src="`/capf.html?mid=${billing.chargeanywhere.pfmid}&tid=${
            billing.chargeanywhere.pftid
          }&amount=${+total.toFixed(2)}&forceAmount=true`"
          width="100%"
          height="400"
          frameborder="0"
          ref="CAIframe"
        ></iframe>
      </form>

      <!-- Dejavoo -->
      <form
        v-if="
          !paid &&
          total > 0 &&
          processor === 'dejavoo' &&
          dejavooPaymentFormLink
        "
        class="form"
      >
        <iframe
          :src="dejavooPaymentFormLink"
          width="100%"
          height="750"
          frameborder="0"
          ref="DJVIframe"
        ></iframe>
      </form>
    </div>

    <OnlineShopCartSummary />
  </section>
</template>

<script>
import OnlineShopCartSummary from '@/components/booking/OnlineShopCartSummary.vue';

export default {
  components: { OnlineShopCartSummary },
  mounted() {
    // CA TOKEN
    window.addEventListener('message', this.eventListener);
  },
  async created() {
    if (this.processor === 'dejavoo') {
      await this.getDejavooPaymentFormLink();
    }
  },
  async unmounted() {
    window.removeEventListener('message', this.eventListener);
  },
  computed: {
    additionalProcessingFee() {
      return (
        this.$store.state.booking.salon.payments
          .cashDiscountAdditionalProcessingFee || 0
      );
    },
    activeClient() {
      return this.$store.state.booking.activeClient;
    },
    processor() {
      if (!this.$store.state.booking.salon) return;
      return this.$store.state.booking.salon.payments.processor;
    },
    billing() {
      if (!this.$store.state.booking.salon) return;
      return this.$store.state.booking.salon.billing;
    },
    cart() {
      return this.$store.state.booking.cart;
    },
    subtotal() {
      let total = 0;
      this.cart.forEach(
        (item) =>
          (total +=
            item.price + item.price * (this.additionalProcessingFee * 0.01))
      );
      return total;
    },
    tax() {
      let total = 0;
      this.cart.forEach(
        (item) => (total += item.price * (item.taxRate * 0.01))
      );
      return total;
    },
    total() {
      return this.subtotal + this.tax;
    },
  },
  data() {
    return {
      loading: false,
      paid: false,
      dejavooPaymentFormLink: null,
    };
  },
  methods: {
    removeItem(itemId) {
      const index = this.cart.findIndex((item) => item._id === itemId);
      if (index === -1) return;
      this.cart.splice(index, 1);
    },

    async getDejavooPaymentFormLink() {
      console.log('Getting Dejavoo Payment Link..');
      let transactionType = 1;
      let amount = +(this.total * 100).toFixed(0);

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/dejavoo/getPaymentFormLink`,
          {
            salonId: this.$store.state.booking.salon._id,
            amount,
            returnUrl: `${process.env.VUE_APP_ORIGIN}/djvpfreturn.html`,
            transactionType,
          }
        );

        this.dejavooPaymentFormLink = response.data.link;
      } catch (e) {
        console.log(e);
      }
    },

    async eventListener(event) {
      // CA Event
      if (event.data.ResponseCode) {
        const customData = event.data;
        const parsed = JSON.parse(JSON.stringify(customData, null, 2));
        if (parsed.TokenNumber) {
          const payment = {
            type: 'card',
            amount: +parsed.AuthorizedAmount,
            ref: `${parsed.GatewayReferenceNumber || parsed.ReferenceNumber}:${
              parsed.ApprovalCode
            }`,
            processor: 'chargeanywhere',
          };

          try {
            this.fulfillOrder(payment);

            this.paid = true;
            this.loading = false;
          } catch (error) {
            console.log(error);
            this.loading = false;
          }
        } else {
          // Have to do it this way or get error:
          // "Assigning src to self"
          const src = this.$refs.CAIframe.src;
          this.$refs.CAIframe.src = src;
          this.$toast.error(`Error; ${parsed.ResponseText} - Please try again`);
          this.loading = false;
        }
      }

      // Raserva Event
      if (event.origin === process.env.VUE_APP_ORIGIN) {
        this.loading = true;

        if (!event.data.processor) {
          // Charge Anywhere
        } else if (event.data.processor === 'dejavoo') {
          if (event.data.denied) {
            this.$toast.error('Error; Please try again');
            this.dejavooPaymentFormLink = null;
            this.getDejavooPaymentFormLink();

            return;
          }

          const payment = {
            type: 'card',
            amount: +(event.data.amount / 100).toFixed(2),
            ref: event.data.RRN,
            processor: 'dejavoo',
          };

          try {
            this.fulfillOrder(payment);

            this.paid = true;
            this.loading = false;
          } catch (error) {
            console.log(error);
            this.loading = false;
          }
        }
      }
    },

    clientLogin() {
      this.$router.push({
        name: 'ClientAuth',
        query: { source: this.$route.fullPath },
      });
    },

    async fulfillOrder(payment) {
      try {
        await this.$store.dispatch('booking/fulfillOrder', payment);

        this.$toast.success('Order fulfilled!');
        this.orderFulfilled = true;
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout {
  margin-top: 64px;
  display: flex;
  align-items: flex-start;
  gap: 64px;

  .bold {
    font-weight: 700;
  }
  .small {
    font-size: 14px;
  }
  .light {
    color: var(--clr-gray);
  }
  .link {
    cursor: pointer;
    color: var(--clr-link);
  }
  .mt-s {
    margin-top: 5px;
  }
  .mt-m {
    margin-top: 16px;
  }

  &_payment {
    flex-grow: 1;
  }
}

.alert {
  padding: 12px;
  font-size: 14px;
  border-radius: 5px;

  .bold {
    text-decoration: none;
    font-weight: 700;
    cursor: default;
  }
  span {
    text-decoration: underline;
    cursor: pointer;
  }

  &-info {
    background-color: #c3edf7;
    color: #044653;
    border-color: #aceaf7;
  }
  &-success {
    background-color: #c3f7c7;
    color: #04530b;
    border-color: #acf7b2;
    margin-bottom: 16px;
  }
}

.confirmed {
  margin-top: 32px;
  padding: 32px;
  border: 1px solid var(--clr-success);
  border-radius: 5px;
  text-align: center;

  p {
    margin-top: 16px;
  }
}

// Tablet
@media (max-width: 900px) {
  .checkout {
    flex-direction: column-reverse;
  }
}
</style>
