<template>
  <section class="support">
    <!-- Head -->
    <div class="support_head">
      <h2>Support Tickets</h2>
      <p>
        Get in touch with our team at Raserva to receive help with any
        questions, concerns, and/or issues you may be having. Typical response
        time is within 24 hours.
      </p>
    </div>

    <!-- Actions -->
    <div class="support_actions">
      <div class="col icons">
        <!-- <BaseInput
          placeholder="Search..."
          :value="searchValue"
          @input="filterServices($event)"
          icon="fas fa-search"
        /> -->
      </div>

      <div class="col buttons">
        <div class="add_button">
          <BaseButton
            @click="
              selectedTicket = null;
              showTicketForm = true;
            "
            >Open new ticket</BaseButton
          >
        </div>
      </div>
    </div>

    <!-- List -->
    <div v-if="tickets.length" class="support_tickets">
      <table>
        <thead>
          <tr>
            <th>Status</th>
            <th>Title</th>
            <th class="hide-tablet">Last Reply</th>
            <th class="tablet"></th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="ticket in tickets"
            :key="ticket._id"
            @click="selectedTicket = ticket"
          >
            <td
              :class="{
                'text-green': ticket.status === 'closed',
                'text-yellow': ticket.status === 'open',
              }"
            >
              {{ ticket.status }}
            </td>
            <td>{{ ticket.title }}</td>
            <td class="hide-tablet">
              {{ ticket.messages[ticket.messages.length - 1].author.name }} -
              {{ formatDate(ticket.messages[ticket.messages.length - 1].date) }}
            </td>
            <td class="tablet"><i class="fas fa-ellipsis-h"></i></td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- None -->
    <div v-else class="none">
      <h3>No support tickets created yet!</h3>
      <p>Please reach out to us for anything, we're happy to help.</p>
    </div>

    <!-- Form -->
    <Modal
      title="Support Ticket Form"
      maxWidth="90%"
      :show="showTicketForm || selectedTicket"
      @close="
        showTicketForm = false;
        selectedTicket = null;
      "
    >
      <SupportTicket
        :ticket="selectedTicket"
        @created="
          tickets.unshift($event);
          showTicketForm = false;
        "
        @edited="
          editTicket($event);
          showTicketForm = false;
        "
      />
    </Modal>
  </section>
</template>

<script>
import SupportTicket from '@/views/support/SupportTicket.vue';

export default {
  components: {
    SupportTicket,
  },
  created() {
    this.init();
  },
  computed: {
    salon() {
      return this.$store.state.auth.salon;
    },
  },
  watch: {
    salon() {
      this.init();
    },
  },
  data() {
    return {
      selectedTicket: null,
      showTicketForm: false,
      searchValue: '',

      tickets: [],
    };
  },
  methods: {
    formatDate(date) {
      return this.$moment(date).format('LLL');
    },

    async init() {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/supportTickets`
        );

        this.tickets = response.data.tickets.sort(
          (a, b) => new Date(b.last_reply) - new Date(a.last_reply)
        );
      } catch (err) {
        console.log(err);
      }
    },

    editTicket(ticket) {
      const index = this.tickets.findIndex((t) => t._id === ticket._id);
      this.tickets.splice(index, 1, ticket);
    },
  },
};
</script>

<style lang="scss" scoped>
.support {
  width: 100%;
  max-width: 800px;
  margin: auto;
  margin-top: 32px;
  padding: 0 16px;

  .text-green {
    color: var(--clr-success);
  }
  .text-yellow {
    color: var(--clr-warning);
  }

  &_head {
    h2 {
      font-size: 28px;
    }
    p {
      color: var(--clr-gray);
      margin-top: 8px;
      line-height: 24px;
    }
  }

  &_actions {
    margin-top: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-extra-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    .icons {
      display: flex;
      gap: 16px;
    }

    .buttons {
      display: flex;
      gap: 16px;
    }
  }

  &_tickets {
    margin-top: 32px;
  }

  .none {
    margin-top: 32px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;
    padding: 16px;
    text-align: center;

    p {
      margin-top: 8px;
    }
  }
}
</style>
