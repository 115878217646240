<template>
  <section class="messenger" :class="{ border: clientMode }">
    <div class="messenger_chats">
      <MessengerChats
        :chats="chats"
        :selectedChatId="selectedChat ? selectedChat._id : null"
        :clientMode="clientMode"
        @select="selectChat"
        @compose="composeMessage($event)"
        ref="messengerChats"
      />
    </div>
    <div class="messenger_selectedChat" ref="selectedChat">
      <MessengerSelectedChat
        :chat="selectedChat"
        :clientMode="clientMode"
        @send="newMessage($event)"
        @deleteMessage="removeMessage($event)"
        @markRead="markRead"
        @setChat="setSelectedChat($event)"
      />
    </div>
  </section>
</template>

<script>
import MessengerChats from '@/components/messenger/MessengerChats.vue';
import MessengerSelectedChat from '@/components/messenger/MessengerSelectedChat.vue';

export default {
  props: {
    clientMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MessengerChats,
    MessengerSelectedChat,
  },
  computed: {
    salon() {
      return this.$store.state.auth.salon;
    },
    chats() {
      return this.$store.state.messenger.chats;
    },
    selectedChat() {
      return this.$store.state.messenger.selectedChat;
    },
    activeClient() {
      return this.$store.state.booking.activeClient;
    },
  },
  created() {
    if (!this.clientMode && !this.salon) return;

    this.init();
  },
  watch: {
    salon() {
      this.init();
    },
    activeClient() {
      this.init();
    },
    selectedChat(val, oldval) {
      if (!this.$refs.selectedChat) return;

      this.$refs.selectedChat.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      if (oldval) {
        this.$store.dispatch('sockets/leave', oldval.salonId);
      }
      if (val) {
        this.$store.dispatch('sockets/join', val.salonId);
      }
    },
  },
  methods: {
    async init() {
      if (!this.clientMode && !this.salon) return;

      try {
        if (this.clientMode) {
          if (!this.$store.state.booking.activeClient) return;

          // Get client chats
          this.$store.state.messenger.chats =
            this.$store.state.booking.activeClient.messages;
        } else {
          // Get salon chats
          const response = await this.$axios.get(
            `${process.env.VUE_APP_RASERVA_BACKEND}/messages?salonId=${this.salon._id}`
          );

          this.$store.state.messenger.chats = response.data.messages;
        }

        // Select from query
        if (this.$route.query.messageId) {
          this.$store.state.messenger.selectedChat = this.chats.find(
            (chat) => chat._id === this.$route.query.messageId
          );
        }

        // New from query
        if (this.$route.query.newMessageTo) {
          this.$store.state.messenger.selectedChat = this.chats.find((chat) => {
            return chat.salonId === this.$route.query.newMessageTo;
          });

          if (!this.$store.state.messenger.selectedChat) {
            const client = this.$store.state.booking.activeClient;

            this.$store.state.messenger.selectedChat = {
              salonId: this.$route.query.newMessageTo,
              client: {
                clientId: client._id,
                name: `${client.firstName} ${client.lastName}`,
                email: client.email,
                phoneNumber: client.phoneNumber,
                profileImage: client.profileImage,
              },
              salon: {
                name: this.$route.query.salonName,
                profileImage: this.$route.query.salonImage,
              },
              messages: [],
            };
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    selectChat(chat) {
      if (this.$store.state.messenger.selectedChat) {
        if (this.$store.state.messenger.selectedChat._id !== chat._id) {
          this.$store.state.messenger.selectedChat = chat;
        }
      } else {
        this.$store.state.messenger.selectedChat = chat;
      }
    },

    newMessage(message) {
      const newMessage = {
        date: Date.now(),
        message,
        author: null,
        deleted: false,
      };

      if (this.clientMode) {
        newMessage.author = {
          authorId: this.$store.state.booking.activeClient._id,
          name: `${this.$store.state.booking.activeClient.firstName} ${this.$store.state.booking.activeClient.lastName}`,
        };
      } else {
        newMessage.author = {
          authorId: this.$store.state.auth.salon._id,
          name: this.$store.state.auth.salon.details.shopName,
        };
      }

      this.selectedChat.messages.push(newMessage);

      let newMessageToClient;

      if (!this.clientMode) {
        this.$store.state.sockets.socket.emit(
          'newChatMessageFromSalon',
          this.selectedChat
        );

        if (
          this.$store.state.auth.salon.bookingSettings.sendEmailToClientUponDM
        ) {
          newMessageToClient = true;
        }
      }

      this.saveChat(newMessageToClient);
    },

    setSelectedChat(chat) {
      this.$store.state.messenger.selectedChat = chat;

      const chatIndex = this.$store.state.messenger.chats.findIndex(
        (preChat) => preChat._id === chat._id
      );

      if (chatIndex === -1) return;

      this.$store.state.messenger.chats[chatIndex] = chat;
    },

    removeMessage(index) {
      this.selectedChat.messages[index].deleted = true;
      this.saveChat();
    },

    markRead() {
      this.selectedChat.messages[
        this.selectedChat.messages.length - 1
      ].read = true;
      this.saveChat();
    },

    composeMessage(client) {
      // Look for existing thread
      const chat = this.chats.find((chat) => {
        return chat.client.clientId === client._id;
      });

      if (chat) {
        this.$store.state.messenger.selectedChat = chat;
      } else {
        // No existing thread exist
        this.$store.state.messenger.selectedChat = {
          salonId: this.$store.state.auth.salon._id,
          client: {
            clientId: client._id,
            name: `${client.firstName} ${client.lastName}`,
            profileImage: client.profileImage,
            email: client.email,
            phoneNumber: client.phoneNumber,
          },
          salon: {
            name: this.$store.state.auth.salon.details.shopName,
            profileImage: this.$store.state.auth.salon.profileImage,
          },
          messages: [],
        };
      }
    },

    async saveChat(newMessageToClient) {
      if (this.selectedChat._id) {
        // Edit
        const response = await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/messages/${this.selectedChat._id}`,
          { ...this.selectedChat, newMessageToClient }
        );

        if (this.clientMode) {
          this.$store.state.sockets.socket.emit(
            'newMessage',
            response.data.message
          );
        }

        this.$store.state.messenger.selectedChat = response.data.message;
        this.$store.dispatch('messenger/editChat', response.data.message);
      } else {
        // Create
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/messages`,
          { ...this.selectedChat, newMessageToClient }
        );

        if (this.clientMode) {
          this.$store.state.sockets.socket.emit(
            'newChat',
            response.data.message
          );
        }

        this.$store.state.messenger.chats.push(response.data.message);
        this.$store.state.messenger.selectedChat = response.data.message;

        this.$refs.messengerChats.init();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.messenger {
  display: flex;
  height: 100%;

  &_chats {
    max-width: 400px;
    border-right: 1px solid var(--clr-light);
  }

  &_selectedChat {
    flex-grow: 1;
  }
}

.border {
  border: 1px solid var(--clr-light);
}

// Mobile
@media (max-width: 600px) {
  .messenger {
    flex-direction: column;
    gap: 16px;
  }
}
</style>
