<template>
  <div class="endpoint_head">
    <h2>Delete appointment</h2>

    <div class="route">
      <i
        @click="
          $router.push('/documentation/api/appointments#delete');
          $toast.info('Set URL to endpoint');
        "
        :style="{ color: 'var(--clr-secondary)', cursor: 'pointer' }"
        class="fas fa-hashtag"
      ></i>
      <p class="tag tag-blue">DELETE</p>
      <p class="url">{{ apiUrl }}<span>/appointments/:id</span></p>
    </div>

    <div class="description">
      <p>Delete an appointment by ID</p>
    </div>
  </div>

  <div class="endpoint_body">
    <div class="request">
      <h3>Request</h3>

      <!-- Headers -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Headers</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">Authorization</p>
              <p class="examples">Examples: <span>Bearer {Token}</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Path params -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Path Parameters</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">ID</p>
              <p class="examples">
                Examples: <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6f</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="response">
      <h3>Response</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">appointment</p>
              <p class="examples">
                Examples:
                <span
                  >{ "originalData": { "services": [] }, "billing": {
                  "noShowCharged": false, "deposits": [] }, "repeat": {
                  "repeats": false }, "files": { "photos": [] }, "_id":
                  "649c9a1a0af6cbc147d16ab6", "salonId":
                  "622ec883ec2552e0d48ed267", "date":
                  "2023-06-30T02:17:32.375Z", "services": [ { "start": "14:00",
                  "service": { "fees": { "supply": 0, "other": 0 }, "title":
                  "Test Item", "color": "#a6c7ea", "onBooking": false,
                  "categoryId": "622ec883ec2552e0d48ed275", "isCommissioned":
                  false, "commissionType": "percent", "commissionAmount": 0,
                  "priceType": "fixed", "displayByConsultationOnBooking": false,
                  "turns": 0, "price": 100, "taxRate": 0, "duration": 200,
                  "extraProcessingTime": 0, "extraBlockedTime": 0,
                  "hasPricingLevels": false, "requireCardInfoToBook": false,
                  "requiresCleaningLog": false, "suggestedServices": [], "_id":
                  "649c9a1a0af6cbc147d16ab8", "levels": [] }, "duration": 200,
                  "staff": { "staffId": "622ec119ec2552e0d48ed156", "firstName":
                  "John", "lastName": "Doe", "email": "john@doe.com",
                  "phoneNumber": "5551112222", "commission": 60,
                  "exemptFromServiceFees": false, "_id":
                  "649c9a1a0af6cbc147d16ab9", "customCommissions": [] },
                  "requested": false, "_id": "649c9a1a0af6cbc147d16ab7" } ],
                  "client": { "notifications": { "email": true, "text": true,
                  "marketing": true }, "firstName": "John", "lastName": "Doe",
                  "phoneNumber": "5551112222", "email": "john@doe.com",
                  "globalClient": false, "salons": [], "blacklistedSalons": [],
                  "messages": [], "appointmentNoShows": [], "tickets": [],
                  "giftcards": [], "subscriptions": [], "_id":
                  "6238b0baa691db3afc07e5b6", "notes": [], "formulas": [],
                  "loyalty": [], "packages": [], "familyAndFriends": [],
                  "paymentMethods": [] }, "requestedCancellation": false,
                  "reminderSent": false, "clientConfirmationSent": false,
                  "isBlockedTime": false, "status": "new", "created_at":
                  "2023-06-28T20:37:46.533Z", "updated_at":
                  "2023-06-28T20:37:46.533Z", "__v": 0 }</span
                >
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    apiUrl() {
      return process.env.VUE_APP_RASERVA_BACKEND;
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoint {
  .tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px;
    border-radius: 5px;
    color: white;

    &-blue {
      background-color: var(--clr-secondary);
    }
  }

  &_head {
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }

    .route {
      padding: 8px 16px;
      border-radius: 5px;
      background-color: var(--clr-white-2);
      display: flex;
      align-items: center;
      gap: 16px;

      .url {
        color: var(--clr-gray);

        span {
          color: var(--clr-primary);
          font-weight: 700;
        }
      }
    }

    .description {
      margin-top: 32px;
    }
  }

  &_body {
    .request,
    .response,
    .reqResExamples {
      margin-top: 32px;
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &_section {
      &:not(:first-child) {
        margin-top: 32px;
      }

      .head {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 20px;
          margin-bottom: 16px;
          color: var(--clr-secondary);
        }

        p {
          font-size: 14px;
          color: var(--clr-gray);
        }
      }

      ul {
        .subitem {
          margin-left: 32px;
        }

        li {
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          &:not(:first-child) {
            margin-top: 16px;
          }

          .value {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .bold {
            font-weight: 700;
          }
          .required {
            color: orangered;
          }

          .description {
            margin: 4px 0;
            color: var(--clr-gray);
          }

          .examples {
            span {
              background-color: var(--clr-white-2);
              padding: 4px 8px;
              border-radius: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
