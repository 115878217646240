<template>
  <section class="giftcards">
    <div class="giftcards_head">
      <h2>Gift cards</h2>
    </div>

    <div class="giftcards_actions">
      <div class="col">
        <form @submit.prevent="search" class="form">
          <BaseInput
            placeholder="Search by number.."
            icon="fas fa-search"
            @input="searchValue = $event"
            @icon-click="search"
          />
        </form>
      </div>
      <div class="col buttons">
        <BaseButton
          v-if="canGenerateGiftcards && !totalsData"
          @click="getGiftcardTotals"
          mode="primary-outline"
          :disabled="totalsLoading"
          ><i v-if="totalsLoading" class="fas fa-spinner"></i> Retrieve
          Totals</BaseButton
        >
        <BaseButton v-if="canGenerateGiftcards" @click="showFormLogic"
          >Generate</BaseButton
        >
      </div>
    </div>

    <div v-if="totalsData" class="totals">
      <div>
        <p class="small light">Total Gift Cards:</p>
        <p class="large">
          {{ totalsData.totalGiftCards }}
        </p>
      </div>
      <div>
        <p class="small light">Total Amount:</p>
        <p class="large">${{ totalsData.totalAmount.toFixed(2) }}</p>
      </div>
      <div>
        <p class="small light">Total Remaining:</p>
        <p class="large">${{ totalsData.totalRemaining.toFixed(2) }}</p>
      </div>
    </div>

    <Pagination
      :limit="25"
      :page="page"
      :data="giftcards"
      @nextPage="nextPage"
      @prevPage="prevPage"
    />

    <table v-if="giftcards.length">
      <thead>
        <tr>
          <th class="hide-tablet">Created At</th>
          <th class="hide-tablet">Generated By</th>
          <th class="hide-tablet">Payment</th>
          <th>Code</th>
          <th>Balance</th>
          <th class="tablet"></th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="giftcard in giftcards"
          :key="giftcard._id"
          @click="selectedGiftCard = giftcard"
        >
          <td class="hide-tablet">{{ formatDate(giftcard.created_at) }}</td>
          <td class="hide-tablet">{{ giftcard.created_by?.name }}</td>
          <td class="hide-tablet">{{ giftcard.billing.type || 'none' }}</td>
          <td>
            {{ giftcard.code }}
            <i
              @click="
                $event.stopPropagation();
                copyToClipboard(giftcard.code);
              "
              class="fas fa-clipboard ml-s text-gray text-small"
            ></i>
          </td>
          <td>{{ formatCurrency(giftcard.amount) }}</td>
          <td class="tablet"><i class="fas fa-ellipsis-h"></i></td>
        </tr>
      </tbody>
    </table>

    <div v-else class="giftcards_none">
      <h3>No data to display</h3>
      <p>Try generating a gift card to get started</p>
    </div>

    <Pagination
      :limit="25"
      :page="page"
      :data="giftcards"
      @nextPage="nextPage"
      @prevPage="prevPage"
    />
  </section>

  <SidePanel :show="selectedGiftCard" @close="selectedGiftCard = null">
    <div class="giftcard-details">
      <GiftcardDetails
        :giftcard="selectedGiftCard"
        @deleted="selectedGiftCard = null"
        @updated="selectedGiftCard = $event"
      />
    </div>
  </SidePanel>

  <Modal
    title="Gift card form"
    :show="showGiftCardForm"
    @close="showGiftCardForm = false"
  >
    <div class="giftcard-form">
      <GiftcardForm
        :staff="staffPerformingAction"
        :giftcard="selectedGiftCard"
        @submitted="showGiftCardForm = false"
      />
    </div>
  </Modal>

  <GetStaffAccount
    v-if="showGetStaffAccount"
    @submitted="retrievedStaffAccount($event)"
    @exit="showGetStaffAccount = false"
  />
</template>

<script>
import GiftcardForm from '@/views/giftcards/GiftcardForm.vue';
import GiftcardDetails from '@/views/giftcards/GiftcardDetails.vue';
import GetStaffAccount from '@/components/staff/GetStaffAccount.vue';

export default {
  components: {
    GiftcardForm,
    GiftcardDetails,
    GetStaffAccount,
  },
  created() {
    this.init();
  },
  computed: {
    loggedInStaff() {
      return this.$store.state.auth.loggedInSalonStaff;
    },
    giftcards() {
      return this.$store.state.giftcards.giftcards;
    },
    managerMode() {
      return this.$store.state.auth.managerMode;
    },
    loggedInStaffIsAdmin() {
      return this.$store.getters['auth/loggedInStaffIsAdmin'];
    },
  },
  watch: {
    loggedInStaff() {
      this.init();
    },
  },
  data() {
    return {
      showGiftCardForm: false,
      showGetStaffAccount: false,
      staffPerformingAction: null,
      selectedGiftCard: null,
      canGenerateGiftcards: false,
      searchValue: '',
      totalsData: null,
      totalsLoading: false,
      page: 1,
    };
  },
  methods: {
    async init() {
      this.getGiftcards();

      this.canGenerateGiftcards = await this.$store.dispatch(
        'auth/activeUserHasPermission',
        'other/editGiftcards'
      );
    },

    async showFormLogic() {
      if (
        !this.managerMode &&
        !this.loggedInStaffIsAdmin &&
        (await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'requireCode/generateGiftCard'
        ))
      ) {
        this.showGetStaffAccount = true;
      } else {
        this.showGiftCardForm = true;
      }
    },

    async getGiftcards() {
      try {
        const giftcards = await this.$store.dispatch(
          'giftcards/getGiftcards',
          {}
        );

        this.$store.state.giftcards.giftcards = giftcards;
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async getGiftcardTotals() {
      this.totalsLoading = true;

      try {
        this.totalsData = await this.$store.dispatch(
          'giftcards/getGiftcardTotals'
        );
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.totalsLoading = false;
    },

    retrievedStaffAccount(staff) {
      this.staffPerformingAction = staff;
      this.showGetStaffAccount = false;
      this.showGiftCardForm = true;
    },

    async prevPage() {
      this.page--;

      const giftcards = await this.$store.dispatch('giftcards/getGiftcards', {
        code: this.searchValue,
        page: this.page,
      });

      this.$store.state.giftcards.giftcards = giftcards;
    },
    async nextPage() {
      this.page++;

      const giftcards = await this.$store.dispatch('giftcards/getGiftcards', {
        code: this.searchValue,
        page: this.page,
      });

      this.$store.state.giftcards.giftcards = giftcards;
    },

    async search() {
      try {
        const giftcards = await this.$store.dispatch('giftcards/getGiftcards', {
          code: this.searchValue,
        });

        this.$store.state.giftcards.giftcards = giftcards;
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    formatDate(date) {
      return this.$moment(date).format('LLL');
    },
    formatCurrency(amount) {
      return `$${amount.toFixed(2)}`;
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.$toast.success('Copied to clipboard');
    },
  },
};
</script>

<style lang="scss" scoped>
.giftcards {
  width: 100%;
  max-width: 800px;
  margin: auto;
  margin-top: 32px;
  padding: 0 16px;

  .ml-s {
    margin-left: 12px;
  }
  .text-gray {
    color: var(--clr-gray);
  }
  .text-small {
    font-size: 14px;
  }

  &_head {
    h2 {
      font-size: 28px;
    }
  }

  &_actions {
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-extra-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    .buttons {
      display: flex;
      gap: 16px;
    }
  }

  .totals {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
    background-color: var(--clr-extra-light);
    margin-bottom: 32px;
    padding: 16px;
    border-radius: 5px;

    .small {
      font-size: 14px;
    }
    .light {
      color: var(--clr-gray);
    }
    .large {
      margin-top: 5px;
      font-size: 18px;
      font-weight: 700;
    }
  }

  &_list {
    .giftcardListItem:not(:first-child) {
      margin-top: 16px;
    }
  }

  &_none {
    padding: 16px;
    text-align: center;
    border: 1px solid var(--clr-light);
    border-radius: 5px;

    p {
      margin-top: 8px;
    }
  }
}

.giftcard-form,
.giftcard-details {
  padding: 32px;
}

// Mobile
@media (max-width: 600px) {
  .giftcards {
    &_actions {
      flex-direction: column;
      gap: 16px;

      .col,
      form,
      .input {
        width: 100%;
      }
      .buttons {
        width: 100%;
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }
}
</style>
