<template>
  <section class="team">
    <ul class="team_list">
      <p
        @click="
          selectedUser = null;
          createUser = true;
        "
        class="create-button"
      >
        Add Team Member
      </p>

      <li
        :class="{
          selected: selectedUser ? selectedUser._id === member._id : false,
        }"
        v-for="member in team"
        :key="member._id"
        @click="selectedUser = member"
      >
        <h3>{{ `${member.firstName} ${member.lastName}` }}</h3>
        <p class="light">{{ member.email }}</p>
      </li>
    </ul>

    <div v-if="selectedUser" class="team_selected">
      <AdminSelectedMember
        :member="selectedUser"
        @select="editSelectedUser = true"
        @deleted="deleteMember"
      />
    </div>

    <div v-else class="team_none">
      <div class="text">
        <h2>No team member selected</h2>
        <p>Select a member to get started</p>
      </div>
    </div>
  </section>

  <Modal
    title="Member Form"
    :show="createUser || editSelectedUser"
    @close="
      createUser = false;
      editSelectedUser = false;
    "
  >
    <AdminMemberForm
      :propMember="selectedUser"
      @updated="updateMember($event)"
      @created="createMember($event)"
    />
  </Modal>
</template>

<script>
import AdminMemberForm from './AdminMemberForm.vue';
import AdminSelectedMember from './AdminSelectedMember.vue';

export default {
  components: {
    AdminSelectedMember,
    AdminMemberForm,
  },
  async created() {
    this.team = await this.$store.dispatch('admin/getAll');
  },
  data() {
    return {
      team: [],
      selectedUser: null,

      createUser: false,
      editSelectedUser: false,
    };
  },
  methods: {
    updateMember(member) {
      this.team = this.team.map((m) => {
        if (m._id === member._id) return member;
        return m;
      });
      this.selectedUser = member;
      this.createUser = false;
      this.editSelectedUser = false;

      if (member._id === this.$store.state.admin.admin._id) {
        this.$store.state.admin.admin = member;
      }
    },
    createMember(member) {
      this.team.push(member);
      this.selectedUser = member;
      this.createUser = false;
      this.editSelectedUser = false;
    },
    deleteMember() {
      this.team = this.team.filter((m) => m._id !== this.selectedUser._id);
      this.selectedUser = null;
      this.createUser = false;
      this.editSelectedUser = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.team {
  min-height: 100%;
  padding: 32px;
  display: flex;
  gap: 64px;
  background-color: var(--clr-white);

  &_list {
    border: 1px solid var(--clr-light);
    border-radius: 5px;
    width: 100%;
    max-width: 250px;
    background-color: white;
    overflow: hidden;

    .create-button {
      width: 100%;
      text-align: center;
      padding: 16px;
      background-color: var(--clr-primary);
      color: var(--clr-white);
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
    }

    .selected {
      background-color: var(--clr-white);
    }

    li {
      padding: 16px 32px;
      cursor: pointer;

      &:hover {
        background-color: var(--clr-white);
      }

      h3 {
        font-weight: 400;
      }

      .light {
        color: var(--clr-gray);
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }

  &_selected,
  &_none {
    flex-grow: 1;
  }

  &_none {
    height: 100%;
    text-align: center;
    display: grid;
    place-items: center;

    p {
      color: var(--clr-gray);
      margin-top: 5px;
    }
  }
}

// Mobile
@media (max-width: 600px) {
  .team {
    flex-direction: column;
    gap: 32px;

    &_list {
      max-width: 100%;
    }
  }
}
</style>
