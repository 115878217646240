<template>
  <form @submit.prevent="submit" class="form">
    <BaseInput
      label="Code"
      :disabled="newGiftcard._id"
      :value="newGiftcard.code"
      @input="newGiftcard.code = $event"
    />

    <BaseInput
      label="Amount"
      :value="newGiftcard.amount"
      @input="newGiftcard.amount = +$event"
    />

    <BaseInput
      label="Expiration (Optional)"
      inputType="date"
      :value="giftcardExpiration"
      @input="newGiftcard.expiration = $event"
    />

    <div
      v-if="!newGiftcard._id || (newGiftcard._id && newGiftcard.client)"
      class="client"
      @click="newGiftcard._id ? null : (showClientSearch = true)"
    >
      <i class="fas fa-user"></i>
      <p v-if="newGiftcard.client">
        {{ `${newGiftcard.client.firstName} ${newGiftcard.client.lastName}` }}
      </p>
      <p v-else>Click here to select client (Optional)</p>
    </div>
    <BaseInput
      v-if="newGiftcard._id && newGiftcard.client"
      label="Client First Name"
      :value="newGiftcard.client.firstName"
      @input="newGiftcard.client.firstName = $event"
    />

    <BaseInput
      v-if="newGiftcard._id && newGiftcard.client"
      label="Client Last Name"
      :value="newGiftcard.client.lastName"
      @input="newGiftcard.client.lastName = $event"
    />

    <!-- Paid With Cash -->
    <div class="toggle" v-if="!newGiftcard._id && !newGiftcard.billing.ref">
      <Toggle
        :checked="newGiftcard.billing.type === 'cash'"
        @toggle="
          newGiftcard.billing.type === 'cash'
            ? (newGiftcard.billing.type = null)
            : (newGiftcard.billing.type = 'cash')
        "
      />
      <div class="toggle_text">
        <p class="toggle_text_label">Cash</p>
        <p class="toggle_text_subtext">Paid with cash</p>
      </div>
    </div>

    <!-- Add Non-Cash Fee -->
    <div
      class="toggle"
      v-if="!newGiftcard._id && !newGiftcard.billing.ref && usesCashDiscount"
    >
      <Toggle
        :checked="addNonCashFee"
        @toggle="addNonCashFee = !addNonCashFee"
      />
      <div class="toggle_text">
        <p class="toggle_text_label">Add Non-Cash Fee</p>
      </div>
    </div>

    <!-- Card Connect -->
    <div
      v-if="processor === 'cardconnect' && !newGiftcard._id"
      class="chargeForm"
    >
      <div
        class="additional"
        @click="showAdditionalOptions = !showAdditionalOptions"
      >
        <i v-if="!showAdditionalOptions" class="fas fa-caret-down"></i>
        <i v-else class="fas fa-caret-up"></i>
        <p>{{ showAdditionalOptions ? 'Hide' : 'Show' }} charge form</p>
      </div>

      <div v-if="showAdditionalOptions" class="chargeForm_form">
        <CardconnectChargeForm
          :amount="chargeAmount"
          @submit="submitPayment($event, 'cardconnect')"
        />
      </div>
    </div>

    <!-- ChargeAnywhere VT -->
    <div
      v-if="processor === 'chargeanywhere' && !newGiftcard._id"
      class="chargeForm"
    >
      <div
        class="additional"
        @click="showAdditionalOptions = !showAdditionalOptions"
      >
        <i v-if="!showAdditionalOptions" class="fas fa-caret-down"></i>
        <i v-else class="fas fa-caret-up"></i>
        <p>{{ showAdditionalOptions ? 'Hide' : 'Show' }} charge form</p>
      </div>

      <div v-if="showAdditionalOptions" class="chargeForm_form">
        <ChargeAnywhereChargeForm
          :amount="chargeAmount"
          @submit="submitPayment($event, 'chargeanywhere')"
        />
      </div>
    </div>

    <!-- Dejavoo VT -->
    <div v-if="processor === 'dejavoo' && !newGiftcard._id" class="chargeForm">
      <div
        class="additional"
        @click="
          newGiftcard.amount < 0.01
            ? dejavooVTerror()
            : !dejavooPaymentFormLink
            ? getDejavooPaymentFormLink()
            : (dejavooPaymentFormLink = null);
          newGiftcard.amount < 0.01
            ? null
            : (showAdditionalOptions = !showAdditionalOptions);
        "
      >
        <i v-if="!showAdditionalOptions" class="fas fa-caret-down"></i>
        <i v-else class="fas fa-caret-up"></i>
        <p>{{ showAdditionalOptions ? 'Hide' : 'Show' }} charge form</p>
      </div>

      <div
        v-if="showAdditionalOptions"
        class="additionalOptions"
        style="margin-top: 32px"
      >
        <DejavooChargeForm
          :amount="newGiftcard.amount"
          @submit="
            submitPayment(
              {
                amount: $event.amount,
                ref: $event.ref,
              },
              'dejavoo'
            )
          "
        />
      </div>
    </div>

    <div class="form_actions">
      <BaseButton
        v-if="
          newGiftcard._id &&
          newGiftcard.billing &&
          newGiftcard.billing.ref &&
          newGiftcard.billing.status === 'default'
        "
        @click="refundPayment"
        mode="danger"
        type="button"
        :disabled="loading"
        ><i v-if="loading" class="fas fa-spinner"></i>Refund Payment</BaseButton
      >
      <BaseButton
        v-if="newGiftcard._id"
        @click="showConfirmDelete = true"
        mode="danger-outline"
        type="button"
        :disabled="loading"
        ><i v-if="loading" class="fas fa-spinner"></i>Delete</BaseButton
      >
      <BaseButton
        v-if="!newGiftcard._id && newGiftcard.client"
        @click="newGiftcard.client = null"
        type="button"
        mode="danger-outline"
        >Remove Client</BaseButton
      >
      <BaseButton :disabled="!formValid || loading">
        <i v-if="loading" class="fas fa-spinner"></i>
        {{ newGiftcard._id ? 'Edit' : 'Generate' }}</BaseButton
      >
    </div>
  </form>

  <SidePanel
    :zIndex="10000000"
    :show="showClientSearch"
    @close="showClientSearch = false"
  >
    <div class="clientSearchForm">
      <p @click="showCreateClient = true" class="link">
        <i class="fas fa-plus"></i> Create New
      </p>
      <p class="info">
        Only Raserva account clients can be attached to a gift card.
      </p>
      <ClientSearch
        @selected="
          newGiftcard.client = $event;
          showClientSearch = false;
        "
      />
    </div>
  </SidePanel>

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Delete gift card"
    text="Are you sure you wish to delete this gift card? This action can not be undone."
    @confirm="removegiftcard"
    @deny="showConfirmDelete = false"
  />

  <Modal
    title="Create Client"
    zIndex="1000000000"
    :show="showCreateClient"
    @close="showCreateClient = false"
  >
    <div class="clientForm">
      <ClientForm basic @created="setClient($event)" />
    </div>
  </Modal>
</template>

<script>
import ClientSearch from '@/components/clients/ClientSearch.vue';
import ClientForm from '@/components/clients/ClientForm.vue';
import CardconnectChargeForm from '@/components/tickets/CardconnectChargeForm.vue';
import ChargeAnywhereChargeForm from '@/components/tickets/ChargeAnywhereChargeForm.vue';
import DejavooChargeForm from '@/components/tickets/DejavooChargeForm.vue';

export default {
  emits: ['submitted'],
  components: {
    ClientSearch,
    ClientForm,
    CardconnectChargeForm,
    ChargeAnywhereChargeForm,
    DejavooChargeForm,
  },
  props: {
    giftcard: {
      type: Object,
    },
    staff: {
      type: Object,
    },
  },
  created() {
    if (this.giftcard)
      this.newGiftcard = JSON.parse(JSON.stringify(this.giftcard));

    if (this.staff)
      this.newGiftcard.staff = JSON.parse(JSON.stringify(this.staff));
  },
  computed: {
    formValid() {
      if (!this.newGiftcard.code || !this.newGiftcard.amount) return false;
      return true;
    },
    giftcardExpiration() {
      if (!this.newGiftcard.expiration) return;

      return this.$moment(this.newGiftcard.expiration).format('YYYY-MM-DD');
    },
    processor() {
      return this.$store.state.auth.salon.payments.processor;
    },
    usesCashDiscount() {
      return this.$store.state.auth.salon.payments.useCashDiscount;
    },
    cashDiscountAdditionalProcessingFee() {
      return this.$store.state.auth.salon.payments
        .cashDiscountAdditionalProcessingFee;
    },
    chargeAmount() {
      if (!this.newGiftcard.amount) return 0;

      if (this.addNonCashFee) {
        return (
          this.newGiftcard.amount +
          (this.newGiftcard.amount * this.cashDiscountAdditionalProcessingFee) /
            100
        );
      }

      return this.newGiftcard.amount;
    },
  },
  data() {
    return {
      newGiftcard: {
        code: '',
        amount: 0,
        expiration: null,
        client: null,
        staff: null,
        billing: {},
      },

      loading: false,
      addNonCashFee: false,

      showConfirmDelete: false,
      showClientSearch: false,
      showCreateClient: false,

      showAdditionalOptions: false,

      dejavooPaymentFormLink: null,
      showDejavooVirtualTerminal: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;

      if (this.newGiftcard.expiration) {
        this.newGiftcard.expiration = this.$moment(
          this.newGiftcard.expiration
        ).endOf('day');
      }

      if (this.newGiftcard.billing.type === 'cash') {
        this.newGiftcard.billing.amount = this.newGiftcard.amount;
      }

      try {
        if (!this.newGiftcard._id) {
          const giftcard = await this.$store.dispatch(
            'giftcards/createGiftcard',
            this.newGiftcard
          );

          this.newGiftcard = giftcard;
          this.submitted();
        } else {
          const giftcard = await this.$store.dispatch(
            'giftcards/editGiftcard',
            this.newGiftcard
          );

          this.$toast.success('Gift card updated');
          this.newGiftcard = giftcard;
          this.submitted();
        }
      } catch (error) {
        console.log(error);
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
    submitted() {
      this.$emit('submitted', this.newGiftcard);

      if (this.newGiftcard && this.newGiftcard._id) {
        this.$toast.success('Gift card edited');
      } else {
        this.$toast.success('Gift card created');
      }
    },
    async removegiftcard() {
      try {
        await this.$store.dispatch(
          'giftcards/deleteGiftcard',
          this.newGiftcard
        );

        this.$emit('submitted');
        this.$toast.success('Gift card deleted');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    setClient(client) {
      this.newGiftcard.client = client;
      this.showCreateClient = false;
      this.showClientSearch = false;
    },

    dejavooVTerror() {
      this.$toast.error('Amount can not be $0.00');
    },

    async refundPayment() {
      this.loading = true;

      // Card Connect refund
      if (this.newGiftcard.billing.processor === 'cardconnect') {
        try {
          const response = await this.$axios.get(
            `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/cardpointe/inquire?merchantId=${this.$store.state.auth.salon.billing.cardconnect.mid}&retref=${this.newGiftcard.billing.ref}`
          );

          const data = response.data.data;

          if (data.voidable === 'Y') {
            // Void
            await this.$axios.post(
              `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/cardpointe/void?merchantId=${this.$store.state.auth.salon.billing.cardconnect.mid}`,
              {
                retref: this.newGiftcard.billing.ref,
              }
            );

            this.$toast.success('Payment voided');
            this.newGiftcard.billing.status = 'refunded';

            this.submit();
          } else if (data.refundable === 'Y') {
            // Refund
            await this.$axios.post(
              `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/cardpointe/refund?merchantId=${this.$store.state.auth.salon.billing.cardconnect.mid}`,
              {
                retref: this.payment.ref,
              }
            );

            this.$toast.success('Payment refunded');
            this.newGiftcard.billing.status = 'refunded';

            this.submit();
          } else {
            this.$toast.error('Can not void or refund payment');
          }
        } catch (error) {
          this.$toast.error(error.response.data.error);
        }
      } else if (this.newGiftcard.billing.processor === 'chargeanywhere') {
        const response = await this.$store.dispatch(
          'payments/refund',
          this.newGiftcard.billing
        );

        if (response.refunded) {
          this.$toast.success('Payment refunded');
          this.newGiftcard.billing.status = 'refunded';

          this.submit();
        }
      } else if (this.newGiftcard.billing.processor === 'dejavoo') {
        const response = await this.$store.dispatch(
          'payments/refund',
          this.newGiftcard.billing
        );

        if (response.refunded) {
          this.$toast.success('Payment refunded');
          this.newGiftcard.billing.status = 'refunded';

          this.submit();
        }
      }

      this.loading = false;
    },

    submitPayment(data, processor) {
      this.newGiftcard.billing = {
        amount: data.amount,
        ref: data.ref,
        type: 'card',
        processor,
      };

      this.$toast.success('Payment charged');
      this.showAdditionalOptions = false;
    },

    async getDejavooPaymentFormLink() {
      const transactionType = 1;

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/dejavoo/getPaymentFormLink`,
          {
            salonId: this.$store.state.auth.salon._id,
            amount: +(this.chargeAmount * 100).toFixed(0),
            returnUrl: `${process.env.VUE_APP_ORIGIN}/djvpfreturn.html`,
            transactionType,
          }
        );

        this.dejavooPaymentFormLink = response.data.link;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .client {
    padding: 16px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 32px;
    cursor: pointer;

    i {
      font-size: 24px;
      color: var(--clr-secondary);
    }
  }

  &_actions {
    margin-top: 16px;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
  }
}

.toggle {
  display: flex;
  align-items: center;
  gap: 32px;
  flex-wrap: wrap;
  padding: 16px 0;

  &_text {
    &_label {
      font-size: 18px;
    }
    &_subtext {
      margin-top: 5px;
      font-size: 14px;
    }
  }
}

.chargeForm {
  margin-top: 16px;

  .additional {
    display: flex;
    gap: 16px;
    align-items: center;
    color: var(--clr-link);
    cursor: pointer;
  }

  &_form {
    margin-top: 16px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;
    padding: 16px;
  }
}

.clientSearchForm {
  padding: 32px;

  .link {
    text-align: center;
    color: var(--clr-link);
    margin-bottom: 32px;
    cursor: pointer;
    padding: 8px 16px;
    border: 1px solid var(--clr-link);
    border-radius: 5px;

    i {
      margin-right: 16px;
    }
  }

  .info {
    text-align: center;
    margin-bottom: 16px;
    font-size: 14px;
    font-style: italic;
  }
}

.clientForm {
  padding: 32px;
}
</style>
