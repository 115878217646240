<template>
  <section class="schedule">
    <div class="schedule_head">
      <h2>Salon Hours</h2>
    </div>
    <div class="schedule_body">
      <div v-for="day in schedule.default" :key="day.day" class="day">
        <div class="date">
          <input
            type="checkbox"
            :checked="day.blocks.length"
            @input="toggleDay(day.day)"
          />
          <div class="text">
            <p>{{ getDayFromDayOfWeekNumber(day.day) }}</p>
            <p
              v-if="day.blocks.length"
              @click="copyToAllDays(day.blocks)"
              class="small link"
            >
              Copy to all working days
            </p>
          </div>
        </div>
        <div v-if="day.blocks.length" class="blocks">
          <div
            v-for="(block, index) in day.blocks"
            :key="block"
            class="blocks_block"
          >
            <BaseInput
              inputType="time"
              :value="block.start"
              @input="block.start = $event"
            />
            <p>-</p>
            <BaseInput
              inputType="time"
              :value="block.end"
              @input="block.end = $event"
            />
            <div class="icons">
              <i @click="addBlockToDay(day.day)" class="fas fa-plus"></i>
              <i
                v-if="day.blocks.length > 1"
                @click="day.blocks.splice(index, 1)"
                class="fas fa-times"
              ></i>
            </div>
          </div>
        </div>
        <div v-else class="closed">
          <p>Closed</p>
        </div>
      </div>
    </div>
    <div class="actions">
      <BaseButton @click="save" :disabled="loading"
        ><i v-if="loading" class="fas fa-spinner"></i> Save</BaseButton
      >
    </div>
  </section>
</template>

<script>
export default {
  created() {
    this.schedule = JSON.parse(
      JSON.stringify(this.$store.state.salon.salon.schedule)
    );
  },
  data() {
    return {
      schedule: {},
      loading: false,
    };
  },
  methods: {
    getDayFromDayOfWeekNumber(day) {
      return this.$moment().day(day).format('dddd');
    },
    toggleDay(day) {
      if (this.schedule.default[day].blocks.length) {
        this.schedule.default[day].blocks = [];
      } else {
        this.schedule.default[day].blocks = [
          {
            start: '09:00',
            end: '17:00',
          },
        ];
      }
    },
    addBlockToDay(day) {
      const prevBlockEndHour =
        +this.schedule.default[day].blocks[
          this.schedule.default[day].blocks.length - 1
        ].end.split(':')[0];
      const prevBlockEndMin =
        this.schedule.default[day].blocks[
          this.schedule.default[day].blocks.length - 1
        ].end.split(':')[1];

      this.schedule.default[day].blocks.push({
        start: `${
          prevBlockEndHour + 1 < 10
            ? '0' + (prevBlockEndHour + 1)
            : prevBlockEndHour + 1
        }:${prevBlockEndMin}`,
        end: `${
          prevBlockEndHour + 2 < 10
            ? '0' + (prevBlockEndHour + 2)
            : prevBlockEndHour + 2
        }:${prevBlockEndMin}`,
      });
    },
    copyToAllDays(blocks) {
      this.schedule.default.forEach((day) => {
        if (day.blocks.length) {
          day.blocks = JSON.parse(JSON.stringify(blocks));
        }
      });
    },
    async save() {
      this.loading = true;

      try {
        await this.$store.dispatch('salon/updateSchedule', this.schedule);
        this.$toast.success('Salon schedule updated');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule {
  &_head {
    h2 {
      font-size: 28px;
    }

    p {
      margin-top: 6px;
    }
  }

  &_body {
    margin-top: 32px;
    padding: 32px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;

    .day {
      display: flex;
      justify-content: space-between;
      gap: 64px;
      align-items: flex-start;
      min-width: 700px;

      &:not(:first-child) {
        margin-top: 32px;
      }
      &:not(:last-child) {
        padding-bottom: 32px;
        border-bottom: 1px solid var(--clr-light);
      }

      .date {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        align-items: center;

        p {
          font-size: 18px;
          font-weight: 700;
        }
        .small {
          margin-top: 8px;
          font-weight: 400;
          font-size: 12px;
        }
        .link {
          cursor: pointer;
          color: var(--clr-link);
        }
      }

      .blocks {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &_block {
          display: flex;
          justify-content: space-between;
          gap: 16px;
          align-items: center;

          i {
            cursor: pointer;
          }
          .fa-plus {
            color: var(--clr-link);
          }
          .fa-times {
            margin-left: 16px;
            color: var(--clr-danger);
          }
        }
      }

      .closed {
        color: var(--clr-gray);
        font-size: 18px;
      }
    }
  }

  .actions {
    margin-top: 32px;
    text-align: right;
  }
}

// Tablet
@media (max-width: 900px) {
  .schedule {
    &_body {
      .day {
        flex-direction: column;
        min-width: inherit;
      }
      .blocks {
        &_block {
          flex-direction: column;
        }
      }
    }
  }
}
</style>
