<template>
  <section class="users">
    <div class="users_head">
      <h2>Salons</h2>
      <p>View/manage salon accounts</p>
    </div>

    <div class="users_actions">
      <div class="filters">
        <BaseInput
          placeholder="Name, Id, or Stripe Id.."
          @input="searchValue = $event"
        />
        <BaseSelect
          :options="filterOptions"
          :value="selectedFilter"
          @input="selectedFilter = $event"
        />
      </div>

      <div class="buttons">
        <!-- <BaseButton>Search</BaseButton> -->
      </div>
    </div>

    <ul class="users_list">
      <li
        v-for="user in filteredUsers"
        :key="user._id"
        @click="selectedUser = user"
      >
        <div class="text">
          <div class="name-status">
            <div
              class="status"
              :class="{
                active: user.billing
                  ? isDatePast(user.billing.currentPeriodEnd)
                    ? false
                    : true
                  : false,
              }"
            ></div>

            <p>
              {{ user.details.shopName
              }}<span v-if="user.details.altName">
                - {{ user.details.altName }}</span
              >
            </p>
          </div>

          <p v-if="user.details.address.street" class="address">
            {{
              `${user.details.address.street}, ${user.details.address.city}, ${user.details.address.state}`
            }}
          </p>
        </div>

        <div class="admin">
          <div class="text">
            <p class="bold small">Admin</p>
            <p>{{ `${user.adminId.firstName} ${user.adminId.lastName}` }}</p>
          </div>

          <Avatar
            :image="user.adminId.profileImage"
            :name="user.adminId.firstName"
          />
        </div>
      </li>
    </ul>
  </section>

  <Modal
    title="User Options"
    :show="selectedUser"
    @close="selectedUser = null"
    maxWidth="500px"
  >
    <div class="selectedUser">
      <div class="text">
        <h2>{{ selectedUser.details.shopName }}</h2>

        <div class="info">
          <p v-if="selectedUser.billing">
            Next bill date:
            <span class="bold">{{
              selectedUser.billing.currentPeriodEnd
                ? formatDate(selectedUser.billing.currentPeriodEnd)
                : 'Not subscribed'
            }}</span>
          </p>
          <p>
            ID: <span class="bold">{{ selectedUser._id }}</span>
          </p>
          <p v-if="selectedUser.billing.stripe">
            Stripe ID:
            <span class="bold">
              {{ selectedUser.billing.stripe.customerId }}
            </span>
          </p>
        </div>
      </div>

      <ul>
        <li>
          <router-link
            :to="{
              name: 'AdminUserDetailed',
              params: { id: selectedUser._id },
            }"
            ><BaseButton>View Detailed</BaseButton></router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'SalonPage',
              params: { id: selectedUser._id },
            }"
            ><BaseButton>View Booking Page</BaseButton></router-link
          >
        </li>

        <li><BaseButton @click="viewLogs">View Logs</BaseButton></li>
        <li><BaseButton @click="viewTickets">View Tickets</BaseButton></li>
        <li><BaseButton @click="viewAnalytics">View Analytics</BaseButton></li>
        <li><BaseButton @click="autoLogin">Log In As Admin</BaseButton></li>

        <!-- Duplicated v-if because in future will have other danger options -->
        <div v-if="showDangerZone" class="danger-zone">
          <p>Danger Zone</p>

          <li>
            <BaseButton
              v-if="activeUserHasPerm('users:edit')"
              mode="danger-outline"
              @click="showChangeAccountAdmin = true"
              >Change Account Admin</BaseButton
            >
          </li>
          <li>
            <BaseButton
              v-if="activeUserHasPerm('users:delete')"
              mode="danger"
              @click="confirmDeleteUser = true"
              >Delete Salon Account</BaseButton
            >
          </li>
        </div>
      </ul>
    </div>
  </Modal>

  <Modal
    title="Update Account Admin"
    :show="showChangeAccountAdmin"
    @close="showChangeAccountAdmin = false"
    maxWidth="750px"
  >
    <div class="updateAdmin">
      <Spinner v-if="loading" fullscreen />

      <form @submit.prevent="searchStaffAccounts" class="form">
        <BaseInput
          placeholder="Email, Name, or Id.."
          @input="accountSearch = $event"
        />
      </form>

      <div v-if="!staffAccounts.length" class="none">
        <h3>Please search accounts</h3>
        <p>No accounts found</p>
      </div>

      <ul v-else>
        <li
          @click="
            selectedSwapAccount = account;
            confirmChangeAdmin = true;
          "
          v-for="account in staffAccounts"
          :key="account._id"
        >
          <div class="col">
            <p>{{ `${account.firstName} ${account.lastName}` }}</p>
            <p class="light small mt-s">{{ account.email }}</p>
          </div>

          <div class="col right">
            <p>Salons: {{ account.salons.length }}</p>
            <p class="light small mt-s">ID: {{ account._id }}</p>
          </div>
        </li>
      </ul>
    </div>
  </Modal>

  <Confirm
    v-if="confirmDeleteUser"
    delete
    title="Delete User"
    text="Are you sure you wish to delete this salon account? This action can not be undone. This will NOT cancel the Stripe subscription."
    @confirm="deleteUser"
    @deny="confirmDeleteUser = false"
  />

  <Confirm
    v-if="confirmChangeAdmin"
    title=""
    :text="`Are you sure you wish to update this salon admin to ${this.selectedSwapAccount.firstName} ${this.selectedSwapAccount.lastName}? This action can not be undone.`"
    @confirm="updateAccountAdmin"
    @deny="confirmChangeAdmin = false"
  />
</template>

<script>
export default {
  async created() {
    let query = '';

    this.adminManages.forEach((account) => (query += `${account}=true`));

    this.users = await this.$store.dispatch('admin/getUsers', query);
  },
  computed: {
    admin() {
      return this.$store.state.admin.admin;
    },
    adminManages() {
      if (!this.admin) return [];

      return this.admin.manages;
    },
    showDangerZone() {
      if (
        this.activeUserHasPerm('users:delete') ||
        this.activeUserHasPerm('users:edit')
      )
        return true;

      return false;
    },
    filterOptions() {
      return [
        { value: 'all', option: 'All' },
        { value: 'active', option: 'Active' },
        { value: 'inactive', option: 'Inactive' },
      ];
    },
    filteredUsers() {
      let users = JSON.parse(JSON.stringify(this.users));

      if (this.selectedFilter === 'active') {
        users = users.filter((user) =>
          user.billing ? !this.isDatePast(user.billing.currentPeriodEnd) : false
        );
      } else if (this.selectedFilter === 'inactive') {
        users = users.filter((user) =>
          user.billing ? this.isDatePast(user.billing.currentPeriodEnd) : true
        );
      }

      if (this.searchValue) {
        users = users.filter((user) => {
          return (
            `${user.details.shopName} ${user.details.altName}`
              .toLowerCase()
              .includes(this.searchValue.toLowerCase()) ||
            user._id.toLowerCase().includes(this.searchValue.toLowerCase()) ||
            (user.billing.stripe.customerId || '')
              .toLowerCase()
              .includes(this.searchValue.toLowerCase())
          );
        });
      }

      return users;
    },
  },
  data() {
    return {
      selectedFilter: 'all',
      searchValue: '',

      users: [],
      selectedUser: null,

      confirmDeleteUser: false,

      loading: false,
      showChangeAccountAdmin: false,
      accountSearch: '',
      staffAccounts: [],
      selectedSwapAccount: null,
      confirmChangeAdmin: false,
    };
  },
  methods: {
    activeUserHasPerm(perm) {
      if (!this.admin) return false;

      return this.admin.permissions.includes(perm);
    },

    formatDate(date) {
      return this.$moment(date).format('LL');
    },

    isDatePast(date) {
      return this.$moment(date).isBefore(this.$moment());
    },

    viewAnalytics() {
      if (!this.activeUserHasPerm('analytics')) {
        this.$toast.error('You do not have permission to view analytics');
        return;
      }

      this.$router.push({
        name: 'AdminAnalytics',
        query: {
          search: this.selectedUser._id,
        },
      });
    },

    viewTickets() {
      if (!this.activeUserHasPerm('tickets')) {
        this.$toast.error('You do not have permission to view tickets');
        return;
      }

      this.$router.push({
        name: 'AdminTickets',
        query: {
          salonId: this.selectedUser._id,
        },
      });
    },

    async autoLogin() {
      try {
        await this.$store.dispatch(
          'admin/autoLogin',
          this.selectedUser.adminId.email
        );

        this.$router.push({ name: 'SalonSelect' });
      } catch (err) {
        console.log(err);
      }
    },

    async viewLogs() {
      if (!this.activeUserHasPerm('logs')) {
        this.$toast.error('You do not have permission to view logs');
        return;
      }

      try {
        this.$router.push({
          name: 'AdminLogs',
          query: {
            id: this.selectedUser._id,
          },
        });
      } catch (err) {
        console.log(err);
      }
    },

    async searchStaffAccounts() {
      this.loading = true;

      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin/staff?query=${this.accountSearch}`
        );

        this.staffAccounts = response.data.staffs;
      } catch (e) {
        this.$toast.error(e.message);
      }

      this.loading = false;
    },

    async updateAccountAdmin() {
      try {
        await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin/salon/admin`,
          {
            salonId: this.selectedUser._id,
            adminId: this.selectedSwapAccount._id,
          }
        );

        this.confirmChangeAdmin = false;
        this.selectedSwapAccount = null;
        this.showChangeAccountAdmin = false;
        this.selectedUser = null;

        this.$toast.success('Salon admin updated');
      } catch (e) {
        this.$toast.error(e.message);
      }
    },

    async deleteUser() {
      try {
        await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin/salon/${this.selectedUser._id}`
        );

        this.users = this.users.filter(
          (user) => user._id !== this.selectedUser._id
        );

        this.$toast.success('User deleted');
        this.$toast.info(
          'REMEMBER: Stripe account associated with deleted account may still be active'
        );

        this.confirmDeleteUser = false;
        this.selectedUser = null;
      } catch (e) {
        this.$toast.error(e.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.users {
  margin: 32px auto;
  max-width: 800px;

  &_head {
    margin-bottom: 32px;

    h2 {
      font-size: 32px;
    }
    p {
      margin-top: 5px;
      color: var(--clr-gray);
    }
  }

  &_actions {
    padding: 16px;
    background-color: var(--clr-light);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filters,
    .buttons {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  &_list {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    li {
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .text {
        .name-status {
          display: flex;
          align-items: center;
          gap: 8px;
        }
        .status {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background-color: var(--clr-danger);
        }

        .active {
          background-color: var(--clr-success) !important;
        }
        .address {
          font-size: 14px;
          color: var(--clr-gray);
          margin-top: 5px;
        }
      }

      .admin {
        display: flex;
        align-items: center;
        gap: 16px;

        .text {
          text-align: right;

          .bold {
            font-weight: 600;
          }

          .small {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.selectedUser {
  padding: 32px;

  .bold {
    font-weight: 700;
  }
  .text {
    text-align: center;
  }

  .info {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  ul {
    margin-top: 32px;
    // display: flex;
    // flex-direction: column;
    // gap: 16px;

    li,
    button {
      width: 100%;
    }

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    .danger-zone {
      grid-column-start: 1;
      grid-column-end: 3;
      margin-top: 16px;
      padding: 16px;
      border: 1px solid var(--clr-danger);
      border-radius: 5px;

      p {
        text-align: center;
        color: var(--clr-danger) !important;
        margin-bottom: 16px;
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
      }

      button {
        margin-top: 16px;
      }
    }
  }
}

.updateAdmin {
  padding: 32px;

  .none {
    padding: 16px;
    border-radius: 5px;
    border: 1px solid var(--clr-light);
    text-align: center;
    margin-top: 32px;

    p {
      margin-top: 8px;
      color: var(--clr-gray);
    }
  }

  ul {
    margin-top: 32px;

    li {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        background-color: var(--clr-white);
      }

      &:not(:first-child) {
        margin-top: 16px;
      }

      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);

      .right {
        text-align: right;
      }
    }
  }
}

// Mobile
@media (max-width: 600px) {
  .users {
    padding: 16px;

    &_head {
      text-align: center;
    }

    &_list {
      li {
        flex-direction: column;
        gap: 16px;
      }
    }
  }

  .selectedUser {
    ul {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}
</style>
