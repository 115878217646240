<template>
  <form @submit.prevent="submit" class="form">
    <div class="alert alert-info">
      <p>Phone numbers cost $2.00 per number per month!</p>
    </div>

    <BaseInput
      label="Three Digit Area Code"
      subtitle="Local numbers have more engagement"
      @input="areaCode = $event"
    />
    <BaseInput
      label="Amount of Phone Numbers"
      subtitle="Maximum of 50"
      @input="limit = +$event"
      :value="+limit"
    />

    <div class="form_actions">
      <BaseButton :disabled="!formValid || loading"
        ><i v-if="loading" class="fas fa-spinner"></i> Purchase Phone Numbers -
        ${{ limit * 2 }}/month</BaseButton
      >
    </div>
  </form>
</template>

<script>
export default {
  emits: ['purchased'],
  computed: {
    formValid() {
      if (this.areaCode.length !== 3) return false;
      if (this.limit < 1 || this.limit > 50) return false;

      return true;
    },
  },
  data() {
    return {
      areaCode: '',
      limit: 1,
      loading: false,
      phoneNumbers: [],
    };
  },
  methods: {
    async submit() {
      this.loading = true;

      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/twilio/phoneNumbers?areaCode=${this.areaCode}&limit=${this.limit}`
        );

        this.phoneNumbers = response.data.phoneNumbers;

        if (!this.phoneNumbers.length)
          return this.$toast.error(
            'No phone numbers available with that area code'
          );

        const responseTwo = await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/twilio/phoneNumbers`,
          {
            salonId: this.$store.state.auth.salon._id,
            phoneNumbers: this.phoneNumbers.map((p) => p.phoneNumber),
            serviceSID:
              this.$store.state.auth.salon.marketing.texts.twilioServiceSID,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$store.state.auth.salon.marketing.texts.twilioPhoneNumbers.push(
          ...responseTwo.data.phoneNumbers
        );

        this.$emit('purchased');
        this.$toast.success('Phone numbers purchased!');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .alert {
    padding: 12px;
    font-size: 14px;
    border-radius: 5px;
    margin-bottom: 16px;

    &-info {
      background-color: #c3edf7;
      color: #044653;
      border-color: #aceaf7;
    }
  }

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
