<template>
  <section class="distrib">
    <div class="distrib_head">
      <h2>Distributors</h2>
      <p>Manage contact info for distributors</p>
    </div>

    <div class="distrib_actions">
      <BaseButton @click="showDistribForm = true">Add Distributor</BaseButton>
    </div>

    <!-- <div v-if="distribs.length" class="distrib_list">
      <div
        v-for="distrib in distribs"
        :key="distrib._id"
        class="distrib_list_distrib"
        @click="selectedDistrib = distrib"
      >
        <div class="info">
          <h3>{{ distrib.name }}</h3>
          <p>{{ distrib.phoneNumber }}</p>
        </div>

        <div class="icon">
          <i class="fas fa-caret-right"></i>
        </div>
      </div>
    </div> -->

    <table v-if="distribs.length">
      <thead>
        <tr>
          <th>Distributor</th>
          <th>Phone</th>
          <th>Actions</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="distrib in distribs"
          :key="distrib._id"
          @click="selectedDistrib = distrib"
        >
          <td>{{ distrib.name }}</td>
          <td>{{ distrib.phoneNumber }}</td>
          <td class="right">
            <i class="fas fa-caret-right icon"></i>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="distrib_none" v-else>
      <h1>There are no distributors to display</h1>
      <p>Get started by adding a new distributor.</p>
    </div>
  </section>

  <Modal
    title="Distributor Form"
    maxWidth="600px"
    :show="showDistribForm || selectedDistrib"
    @close="
      showDistribForm = false;
      selectedDistrib = null;
    "
  >
    <DistribForm
      :distrib="selectedDistrib"
      @close="
        showDistribForm = false;
        selectedDistrib = null;
      "
      @deleted="
        this.salon.distributors = this.salon.distributors.filter(
          (d) => d._id !== this.selectedDistrib._id
        );
        selectedDistrib = null;
        showDistribForm = false;
      "
    />
  </Modal>
</template>

<script>
import DistribForm from '@/components/items/DistribForm.vue';

export default {
  components: {
    DistribForm,
  },
  created() {
    if (this.$route.query._id) {
      this.selectedDistrib = this.distribs.find(
        (distrib) => distrib._id.toString() === this.$route.query._id
      );
    }
  },
  data() {
    return {
      showDistribForm: false,
      selectedDistrib: null,
    };
  },
  computed: {
    salon() {
      return this.$store.state.auth.salon;
    },
    distribs() {
      if (!this.salon) return [];

      return this.salon.distributors || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.distrib {
  width: 100%;
  max-width: 800px;

  &_head {
    h2 {
      font-size: 28px;
    }
    p {
      margin-top: 8px;
      color: var(--clr-gray);
    }
  }

  &_actions {
    margin-top: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-extra-light);
    display: flex;
    justify-content: flex-end;
  }

  &_none {
    text-align: center;
    padding: 16px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;
    margin-top: 32px;

    p {
      margin-top: 5px;
    }
  }

  table {
    margin-top: 32px;
    .right {
      display: flex;
      justify-content: flex-end;
    }

    .icon {
      text-align: right;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: var(--clr-secondary);
      color: var(--clr-white);
      display: grid;
      place-items: center;
      font-size: 14px;
    }
  }
}
</style>
