<template>
  <div class="endpoint_head">
    <h2>Client register</h2>

    <div class="route">
      <i
        @click="
          $router.push('/documentation/api/authentication#clientRegister');
          $toast.info('Set URL to endpoint');
        "
        :style="{ color: 'var(--clr-secondary)', cursor: 'pointer' }"
        class="fas fa-hashtag"
      ></i>
      <p class="tag tag-blue">POST</p>
      <p class="url">{{ apiUrl }}<span>/clients</span></p>
    </div>

    <div class="description">
      <p>Creates a global Raserva client account</p>
    </div>
  </div>

  <div class="endpoint_body">
    <div class="request">
      <h3>Request</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
          <p>application/json</p>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">firstName</p>
              <p class="description">String</p>
              <p class="examples">Examples: <span>John</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <li>
            <div class="col value">
              <p class="bold">lastName</p>
              <p class="description">String</p>
              <p class="examples">Examples: <span>Doe</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <li>
            <div class="col value">
              <p class="bold">phoneNumber</p>
              <p class="description">String (Numerical only, Must be unique)</p>
              <p class="examples">Examples: <span>5551112222</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <li>
            <div class="col value">
              <p class="bold">email</p>
              <p class="description">String (Must be unique)</p>
              <p class="examples">Examples: <span>unique@email.com</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <li>
            <div class="col value">
              <p class="bold">password</p>
              <p class="description">String</p>
              <p class="examples">Examples: <span>password</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- Profile Image -->
          <li>
            <div class="col value">
              <p class="bold">profileImage</p>
              <p class="description">String (URL of an image)</p>
              <p class="examples">
                Examples:
                <span>https://www.images.google.com/ryanreynolds</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- Gender -->
          <li>
            <div class="col value">
              <p class="bold">gender</p>
              <p class="description">
                String - Either 'male', 'female', 'other', 'non-binary', or null
              </p>
              <p class="examples">
                Examples:
                <span>female</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- Date of birth -->
          <li>
            <div class="col value">
              <p class="bold">dateOfBirth</p>
              <p class="description">
                Object <br /><br />
                <span class="bold">month</span> - Number (0 = January)<br />
                <span class="bold">date</span> - Number <br />
                <span class="bold">year</span> - Number
              </p>
              <p class="examples">
                Examples:
                <span>{ month: 0, date: 15, year: 1990 }</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- Notifications -->
          <li>
            <div class="col value">
              <p class="bold">notifications</p>
              <p class="description">
                Object - All default to false (regulations)<br /><br />
                <span class="bold">email</span> - Boolean <br />
                <span class="bold">text</span> - Boolean <br />
                <span class="bold">marketing</span> - Boolean
              </p>
              <p class="examples">
                Examples:
                <span>{ email: true, text: true, marketing: true }</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="response">
      <h3>Response</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
        </div>

        <ul>
          <!-- <li>
            <div class="col value">
              <p class="bold">token</p>
              <p class="description">Bearer token</p>
              <p class="examples">Examples: <span>eyJhbGc..</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li> -->

          <li>
            <div class="col value">
              <p class="bold">client</p>
              <p class="description">Client account object</p>
              <p class="examples">Examples: <span>OBJECT</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="reqResExamples">
      <h3>Examples</h3>

      <!-- Request -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Request</h4>
          <p>application/json</p>
        </div>

        <ul>
          <li>
            <p>
              { "firstName": "John", "lastName": "Doe", "phoneNumber":
              "5551112222", "email": "unique@email.com", "password": "password"
              }
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    apiUrl() {
      return process.env.VUE_APP_RASERVA_BACKEND;
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoint {
  .tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px;
    border-radius: 5px;
    color: white;

    &-blue {
      background-color: var(--clr-secondary);
    }
  }

  &_head {
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }

    .route {
      padding: 8px 16px;
      border-radius: 5px;
      background-color: var(--clr-white-2);
      display: flex;
      align-items: center;
      gap: 16px;

      .url {
        color: var(--clr-gray);

        span {
          color: var(--clr-primary);
          font-weight: 700;
        }
      }
    }

    .description {
      margin-top: 32px;
    }
  }

  &_body {
    .request,
    .response,
    .reqResExamples {
      margin-top: 32px;
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &_section {
      &:not(:first-child) {
        margin-top: 32px;
      }

      .head {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 20px;
          margin-bottom: 16px;
          color: var(--clr-secondary);
        }

        p {
          font-size: 14px;
          color: var(--clr-gray);
        }
      }

      ul {
        li {
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          &:not(:first-child) {
            margin-top: 16px;
          }

          .value {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .bold {
            font-weight: 700;
          }
          .required {
            color: orangered;
          }

          .description {
            margin: 4px 0;
            color: var(--clr-gray);
          }

          .examples {
            span {
              background-color: var(--clr-white-2);
              padding: 4px 8px;
              border-radius: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
