<template>
  <section class="login">
    <form @submit.prevent="submit" class="form">
      <div class="form_head">
        <h1>Admin Login</h1>
        <p class="light">
          Don't belong here?
          <router-link :to="{ name: 'StaffLogin' }"
            ><span>Click here.</span></router-link
          >
        </p>
      </div>

      <BaseInput label="Email" placeholder="Email" @input="email = $event" />
      <BaseInput
        inputType="password"
        label="Password"
        placeholder="Password"
        @input="password = $event"
      />
      <div class="form_actions">
        <BaseButton :disabled="loading || !formValid"
          ><i v-if="loading" class="fas fa-spinner"></i>Login</BaseButton
        >
      </div>
    </form>
  </section>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      email: '',
      password: '',
    };
  },
  computed: {
    formValid() {
      return this.email && this.password;
    },
  },
  methods: {
    async submit() {
      if (!this.email.length || !this.password.length) {
        return;
      }

      this.loading = true;

      try {
        await this.$store.dispatch('admin/login', {
          email: this.email,
          password: this.password,
        });

        this.$router.push({ name: 'AdminPanel' });
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: var(--clr-light);

  .form {
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: white;
    box-shadow: var(--shadow-large);
    border-radius: 5px;
    width: 500px;
    max-width: 90vw;

    &_head {
      text-align: center;
      margin-bottom: 16px;

      h1 {
        font-size: 32px;
        font-weight: 400;
      }

      span {
        color: var(--clr-link);
      }

      .light {
        color: var(--clr-gray);
        margin-top: 5px;
      }
    }

    &_actions {
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }
  }
}
</style>
