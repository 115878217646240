<template>
  <section class="readers">
    <div class="readers_head">
      <h3>
        Readers <i class="fas fa-plus" @click="showReaderForm = true"></i>
      </h3>
      <p>
        A Reader represents a physical device for accepting payment details.
      </p>
    </div>
    <div class="readers_list">
      <Spinner v-if="loading" />
      <div v-else class="readers_list_body">
        <ul v-if="readers.length">
          <li v-for="reader in readers" :key="reader.id">
            <div class="col">
              <h4>{{ reader.label }}</h4>
              <p>{{ reader.device_type }}</p>
            </div>
            <div class="col">
              <i class="fas fa-edit" @click="selectedReader = reader"></i>
            </div>
          </li>
        </ul>
        <div v-else class="none">
          <h3>No readers created</h3>
          <p>Please create a reader to get started</p>
        </div>
      </div>
    </div>
  </section>

  <Modal
    title="Reader Form"
    :show="showReaderForm || selectedReader"
    @close="
      showReaderForm = false;
      selectedReader = null;
    "
  >
    <div class="readerForm">
      <CreateReader
        :accountId="accountId"
        :reader="selectedReader"
        :location="selectedLocationId"
        @created="createdReader($event)"
        @edited="editedReader($event)"
        @deleted="deletedReader($event)"
      />
    </div>
  </Modal>
</template>

<script>
import CreateReader from '@/components/stripe/CreateReader.vue';

export default {
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  components: {
    CreateReader,
  },
  created() {
    this.init();
  },
  watch: {
    accountId() {
      this.init();
    },
  },
  data() {
    return {
      loading: false,
      readers: [],

      showReaderForm: false,
      selectedReader: null,
    };
  },
  methods: {
    async init() {
      this.loading = true;

      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/readers?accountId=${this.accountId}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.readers = response.data.readers.data;
        this.$store.commit('stripe/setReaders', this.readers);
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
    createdReader(reader) {
      this.readers.push(reader);
      this.showReaderForm = false;
      this.selectedReader = null;
      this.$toast.success('Reader created');
    },
    editedReader(reader) {
      const readerIndex = this.readers.findIndex(
        (read) => read.id === reader.id
      );

      if (readerIndex === -1) return;

      this.readers[readerIndex] = reader;
      this.showReaderForm = false;
      this.selectedReader = null;
      this.$toast.success('Reader edited');
    },
    deletedReader(reader) {
      const readerIndex = this.readers.findIndex(
        (read) => read.id === reader.id
      );

      if (readerIndex === -1) return;

      this.readers.splice(readerIndex, 1);
      this.showReaderForm = false;
      this.selectedReader = null;
      this.$toast.success('Reader deleted');
    },
  },
};
</script>

<style lang="scss" scoped>
.none {
  text-align: center;
  padding: 16px;
  border: 1px solid var(--clr-light);
  border-radius: 5px;

  p {
    margin-top: 5px;
  }
}
.spinner {
  margin-top: 32px;
}
.readers {
  border: 1px solid var(--clr-light);
  border-radius: 5px;
  padding: 16px;

  &_head {
    i {
      margin-left: 32px;
      color: var(--clr-link);
      cursor: pointer;
    }
    p {
      margin-top: 8px;
    }
  }
  &_list {
    &_head,
    &_body {
      margin-top: 16px;
    }

    &_body {
      ul {
        li {
          &:not(:first-child) {
            margin-top: 16px;
          }
          &:hover {
            background-color: var(--clr-white);
          }

          border-radius: 5px;
          padding: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            font-size: 12px;
            margin-top: 5px;
          }
          i {
            font-size: 20px;
            color: var(--clr-link);
            cursor: pointer;
            transition: color 0.2s ease;

            &:not(:first-child) {
              margin-left: 32px;
            }
            &:hover {
              color: var(--clr-link-muted);
            }
          }
        }
      }
    }
  }
}

.readerForm {
  padding: 32px;
}
</style>
