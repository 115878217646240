<template>
  <section class="services">
    <div class="services_search">
      <BaseInput
        placeholder="Search services"
        icon="fas fa-search"
        @input="searchServices($event)"
      />
    </div>
    <ul class="services_list">
      <li @click="selectAll">
        <div class="col checkbox">
          <input
            type="checkbox"
            :checked="newStaffServices.length === totalServices"
          />
          <div class="text">
            <p class="title">
              All services <span>{{ totalServices }}</span>
            </p>
          </div>
        </div>
      </li>
      <div
        class="category"
        v-for="category in displayedCategories"
        :key="category"
      >
        <li class="head">
          <div class="col checkbox">
            <input
              type="checkbox"
              :checked="isAllItemsInCategorySelected(category._id)"
              @click="selectAllInCategory(category._id)"
            />
            <div class="text">
              <p class="category-title">
                {{ category.title }} <span>{{ category.items.length }}</span>
              </p>
              <div v-if="!staffMode" class="category-commission">
                <BaseInput
                  type="number"
                  placeholder="Commission"
                  extraSmall
                  :value="category.customCommission"
                  @input="category.customCommission = +$event"
                />
              </div>
            </div>
          </div>
        </li>
        <li v-for="item in category.items" :key="item">
          <div class="col checkbox">
            <input
              type="checkbox"
              :checked="isCapable(item._id)"
              @click="toggleService(item._id)"
            />
            <div class="text">
              <p class="title">
                {{ item.title }}
              </p>
              <p class="light small">{{ item.duration }} mins</p>
            </div>
          </div>
          <div class="col price">
            <div v-if="item.levels.length && !staffMode" class="price_tier">
              <BaseInput
                inputType="number"
                extraSmall
                label="Level"
                step="1"
                :value="item.customLevel || staffDefaultServiceLevel"
                @input="item.customLevel = +$event"
                :placeholder="`Max: ${item.levels.length}`"
                :validators="{
                  min: 1,
                  max: item.levels.length,
                }"
                min="1"
                :max="item.levels.length"
              />
            </div>
            <div v-else></div>
            <div class="price_text">
              <p v-if="!item.levels.length" class="bold">${{ item.price }}</p>
              <p v-else class="bold">
                ${{
                  item.levels[item.customLevel - 1]
                    ? item.levels[item.customLevel - 1].price
                    : item.levels[0].price
                }}
              </p>
            </div>
          </div>
        </li>
      </div>
    </ul>
    <div v-if="submitable" class="services_actions">
      <BaseButton @click="submit"
        >Select {{ newStaffServices.length }} services</BaseButton
      >
    </div>
  </section>

  <Confirm
    v-if="showConfirmUnsavedChanges"
    title="Unsaved Changes"
    text="You have unsaved changes. Are you sure you wish to leave this page?"
    confirmTextOverride="Exit"
    thirdButton="Save Changes"
    @confirm="$emit('exit')"
    @deny="showConfirmUnsavedChanges = false"
    @thirdButtonClick="submit"
  />
</template>

<script>
export default {
  emits: ['submit', 'exit'],
  props: {
    staffServices: {
      type: Array,
      required: true,
    },
    staffCustomCommissions: {
      type: Array,
      required: false,
    },
    staffCustomLevels: {
      type: Array,
      required: false,
    },
    staffDefaultServiceLevel: {
      type: Number,
      default: 1,
    },
    staffMode: {
      type: Boolean,
      default: true,
    },
    submitable: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.newStaffServices = JSON.parse(JSON.stringify(this.staffServices));
    this.displayedCategories = JSON.parse(JSON.stringify(this.categories));

    if (this.staffCustomCommissions) {
      this.newStaffCustomCommissions = JSON.parse(
        JSON.stringify(this.staffCustomCommissions)
      );

      this.setCustomCommission();
    }

    if (this.staffCustomLevels) {
      this.newStaffCustomLevels = JSON.parse(
        JSON.stringify(this.staffCustomLevels)
      );

      this.setCustomLevels();
    }
  },
  computed: {
    categories() {
      return this.$store.state.items.services;
    },
    totalServices() {
      let count = 0;

      this.categories.forEach((category) => {
        count += category.items.length;
      });

      return count;
    },
  },
  data() {
    return {
      newStaffServices: [],
      newCustomCommissions: [],
      newStaffCustomLevels: [],
      displayedCategories: [],
      showConfirmUnsavedChanges: false,
    };
  },
  methods: {
    isCapable(serviceId) {
      return this.newStaffServices.find((service) => service === serviceId);
    },
    setCustomCommission() {
      this.staffCustomCommissions.forEach((category) => {
        const index = this.displayedCategories.findIndex((precat) => {
          return precat._id === category.categoryId;
        });

        if (index === -1) return;

        this.displayedCategories[index].customCommission = category.commission;
      });
    },
    setCustomLevels() {
      this.staffCustomLevels.forEach((service) => {
        for (let i = 0; i < this.displayedCategories.length; i++) {
          const index = this.displayedCategories[i].items.findIndex(
            (item) => item._id === service.serviceId
          );

          if (index === -1) continue;

          this.displayedCategories[i].items[index].customLevel = service.level;
          break;
        }
      });
    },
    isAllItemsInCategorySelected(categoryId) {
      const index = this.categories.findIndex(
        (category) => category._id === categoryId
      );

      if (index === -1) return;

      return this.categories[index].items.every((item) =>
        this.isCapable(item._id)
      );
    },
    toggleService(serviceId) {
      const index = this.newStaffServices.findIndex(
        (service) => service === serviceId
      );

      if (index === -1) {
        this.newStaffServices.push(serviceId);
      } else {
        this.newStaffServices.splice(index, 1);
      }
    },
    selectAllInCategory(categoryId) {
      const index = this.categories.findIndex(
        (category) => category._id === categoryId
      );

      if (index === -1) return;

      if (
        this.categories[index].items.find((item) => this.isCapable(item._id))
      ) {
        this.categories[index].items.forEach((item) => {
          const index = this.newStaffServices.findIndex(
            (service) => service === item._id
          );

          if (index !== -1) {
            this.newStaffServices.splice(index, 1);
          }
        });

        return;
      }

      this.categories[index].items.forEach((item) => {
        if (!this.isCapable(item._id)) {
          this.newStaffServices.push(item._id);
        }
      });
    },
    selectAll() {
      if (this.newStaffServices.length) {
        this.newStaffServices = [];
        return;
      }

      this.categories.forEach((category) => {
        category.items.forEach((item) => {
          this.newStaffServices.push(item._id);
        });
      });
    },
    checkForUnsavedChanges() {
      if (this.newStaffServices.length !== this.staffServices.length) {
        this.showConfirmUnsavedChanges = true;
      } else {
        this.$emit('exit');
      }
    },
    submit() {
      this.displayedCategories.forEach((cat) => {
        // Custom commissions
        if (cat.customCommission) {
          const index = this.newCustomCommissions.findIndex(
            (precat) => precat.categoryId === cat._id
          );

          if (index === -1) {
            this.newCustomCommissions.push({
              categoryId: cat._id,
              commission: cat.customCommission,
            });
          } else {
            this.newCustomCommissions[index].commission = cat.customCommission;
          }
        }

        // Custom levels
        cat.items.forEach((item) => {
          if (item.customLevel) {
            const index = this.newStaffCustomLevels.findIndex(
              (service) => service.serviceId === item._id
            );

            if (index === -1) {
              this.newStaffCustomLevels.push({
                serviceId: item._id,
                level: item.customLevel,
              });
            } else {
              this.newStaffCustomLevels[index].level = item.customLevel;
            }
          }
        });
      });

      this.$emit('submit', {
        services: this.newStaffServices,
        commissions: this.newCustomCommissions,
        levels: this.newStaffCustomLevels,
      });
    },
    searchServices(val) {
      if (!val) {
        this.clearFilteredServices();
        return;
      }

      const displayedCategories = JSON.parse(
        JSON.stringify(this.categories)
      ).filter((category) => {
        return category.items.some((service) => {
          return service.title.toLowerCase().includes(val.toLowerCase());
        });
      });

      displayedCategories.forEach((category) => {
        category.items = category.items.filter((item) => {
          return item.title.toLowerCase().includes(val.toLowerCase());
        });
      });

      this.displayedCategories = displayedCategories;
    },
    clearFilteredServices() {
      this.displayedCategories = JSON.parse(JSON.stringify(this.categories));
    },
  },
};
</script>

<style lang="scss" scoped>
.services {
  &_search {
    padding: 32px;
    border-bottom: 1px solid var(--clr-light);
  }
  &_list {
    max-height: 80vh;
    overflow-y: auto;

    .head {
      width: 100%;

      .text {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    li {
      padding: 16px 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: background-color 0.2s ease;
      border-bottom: 1px solid var(--clr-light);

      &:hover {
        background-color: var(--clr-white);
      }
      .small {
        margin-top: 5px;
        font-size: 14px;
      }
      .light {
        color: var(--clr-gray);
      }

      .price {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
        width: 100%;
        text-align: right;
      }

      .checkbox {
        display: flex;
        align-items: center;
        gap: 32px;
        width: 100%;

        .category-title {
          font-size: 20px;
          font-weight: 700;
        }
        span {
          margin-left: 16px;
          background-color: var(--clr-light);
          font-size: 14px;
          padding: 6px;
          border-radius: 50%;
          color: var(--clr-gray);
        }

        .title {
          font-size: 18px;
        }
      }

      .bold {
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
  &_actions {
    padding: 32px 16px;
    text-align: right;
  }
}
</style>
