<template>
  <section class="logs">
    <div class="logs_head">
      <h2>Logs</h2>
      <p>View/manage logs</p>
    </div>

    <div class="logs_actions">
      <div class="filters">
        <BaseInput
          placeholder="Search by salon ID.."
          :value="salonFilter"
          @input="salonFilter = $event"
        />
        <BaseSelect
          :options="filterOptions"
          :value="selectedFilter"
          @input="selectedFilter = $event"
        />
      </div>

      <div class="buttons">
        <BaseButton @click="showConfirmDelete = true" mode="danger"
          ><i class="fas fa-exclamation-triangle"></i> Delete All
          Logs</BaseButton
        >
        <BaseButton @click="getLogs">Search</BaseButton>
      </div>
    </div>

    <div v-if="!filteredLogs.length" class="none">
      <h1>No logs to display</h1>
      <p>That's probably a good thing?</p>
    </div>

    <ul v-else class="logs_list">
      <li v-for="log in filteredLogs" :key="log._id">
        <div class="icon">
          <i v-if="log.type === 'users'" class="fas fa-users"></i>
          <i v-else-if="log.type === 'payments'" class="fas fa-credit-card"></i>
          <i
            v-else-if="log.type === 'actions'"
            class="fas fa-exclamation-triangle"
          ></i>
        </div>
        <div class="text">
          <p class="small light">
            {{ formatDate(log.created_at) }}
          </p>
          <p>{{ log.message }}</p>
        </div>
      </li>
    </ul>
  </section>

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Clear logs"
    text="DANGER! Are you sure you wish to clear all logs? IF YOU ARE NOT RYAN SURVILO PROBABLY DONT DO THIS."
    @confirm="clearLogs"
    @deny="showConfirmDelete = false"
  />
</template>

<script>
export default {
  created() {
    if (this.$route.query.id) this.salonFilter = this.$route.query.id;

    this.getLogs();
  },
  computed: {
    filterOptions() {
      return [
        { value: 'all', option: 'All' },
        { value: 'users', option: 'Users' },
        { value: 'payments', option: 'Payments' },
        { value: 'actions', option: 'Actions' },
      ];
    },
    filteredLogs() {
      let logs = JSON.parse(JSON.stringify(this.logs));

      // Type
      if (this.selectedFilter === 'payments') {
        logs = logs.filter((log) => log.type === 'payments');
      } else if (this.selectedFilter === 'users') {
        logs = logs.filter((log) => log.type === 'users');
      } else if (this.selectedFilter === 'actions') {
        logs = logs.filter((log) => log.type === 'actions');
      }

      return logs;
    },
  },
  data() {
    return {
      logs: [],
      selectedFilter: 'all',
      salonFilter: '',
      showConfirmDelete: false,
    };
  },
  methods: {
    async getLogs() {
      let query = `${process.env.VUE_APP_RASERVA_BACKEND}/admin/logs`;

      if (this.salonFilter) query += `?salonId=${this.salonFilter}`;

      try {
        const response = await this.$axios.get(query);

        this.logs = response.data.logs;
      } catch (error) {
        console.log(error);
      }
    },
    formatDate(date) {
      return this.$moment(date).format('LLL');
    },
    async clearLogs() {
      try {
        await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin/logs/clear`
        );

        this.logs = [];

        this.showConfirmDelete = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logs {
  margin: 32px auto;
  max-width: 800px;

  &_head {
    margin-bottom: 32px;

    h2 {
      font-size: 32px;
    }
    p {
      margin-top: 5px;
      color: var(--clr-gray);
    }
  }

  &_actions {
    padding: 16px;
    background-color: var(--clr-light);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filters,
    .buttons {
      display: flex;
      align-items: center;
      gap: 16px;

      i {
        margin-right: 16px;
      }
    }
  }

  .none {
    margin-top: 32px;
    padding: 16px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;
    text-align: center;

    p {
      margin-top: 8px;
    }
  }

  &_list {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .small {
      font-size: 14px;
    }
    .light {
      color: var(--clr-gray);
    }

    li {
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
      display: flex;
      align-items: center;
      gap: 32px;

      .icon {
        color: var(--clr-secondary);
        font-size: 24px;
      }

      p.small {
        margin-bottom: 8px;
      }
    }
  }
}

// Mobile
@media (max-width: 600px) {
  .logs {
    padding: 16px;

    &_head {
      text-align: center;
    }

    &_actions {
      padding: 16px;
      background-color: var(--clr-light);
      display: flex;
      flex-direction: column;

      .filters,
      .buttons {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;

        i {
          margin-right: 16px;
        }

        .parent,
        button {
          width: 100%;
          flex-grow: 1;
        }
      }

      .buttons {
        margin-top: 16px;
        flex-direction: column-reverse;
      }
    }
  }
}
</style>
