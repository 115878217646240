<template>
  <form @submit.prevent="submit" class="invite">
    <div class="invite_member" v-for="(email, index) in emails" :key="index">
      <BaseInput
        label="Email"
        inputType="email"
        placeholder="mail@example.com"
        :value="email"
        :validators="validators.email"
        @input="emails[index] = $event"
      />
      <i
        class="fas fa-times"
        v-if="emails.length > 1"
        @click="removeEmail(index)"
      ></i>
    </div>
    <div class="invite_add" @click="addEmail">
      <i class="fas fa-plus"></i>
      <p>Add another email</p>
    </div>
    <div class="invite_actions">
      <BaseButton :disabled="!formValid">Send Invites</BaseButton>
    </div>
  </form>
</template>

<script>
export default {
  emits: ['sent'],
  data() {
    return {
      emails: [''],
    };
  },
  computed: {
    validators() {
      return {
        email: {
          required: true,
          email: true,
        },
      };
    },
    formValid() {
      if (!this.emails[0].length) return false;
      return true;
    },
  },
  methods: {
    addEmail() {
      if (!this.emails[this.emails.length - 1]) return;

      this.emails.push('');
    },
    removeEmail(index) {
      this.emails.splice(index, 1);
    },
    async submit() {
      try {
        await this.$store.dispatch('staff/inviteStaffs', this.emails);
        this.$emit('sent');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.invite {
  &_add,
  &_actions {
    margin-top: 16px;
  }

  &_add {
    color: var(--clr-link);
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 32px;
    cursor: pointer;
  }

  &_member {
    position: relative;

    &:not(:first-child) {
      margin-top: 16px;
    }

    i {
      position: absolute;
      top: 0;
      right: 0;
      color: var(--clr-gray);
      cursor: pointer;
    }
  }

  &_actions {
    text-align: right;
  }
}
</style>
