<template>
  <div class="campaigns">
    <div class="campaigns_head">
      <h2>Campaigns</h2>
      <p>Previous campaign records</p>
      <p class="subtext">
        * "Sent" status does not necessarily mean delivered, as text/emails are
        queued and sent over a period of time.
      </p>
    </div>

    <div class="campaigns_body">
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Recepients</th>
            <th>Sent On</th>
            <th>Channel</th>
            <th>Cost</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="campaign in campaigns"
            :key="campaign._id"
            @click="selectedCampaign = campaign"
          >
            <td>{{ campaign.title }}</td>
            <td>{{ campaign.recipients.length }} sent</td>
            <td>{{ formatDate(campaign.created_at) }}</td>
            <td>{{ campaign.type }}</td>
            <td>${{ campaign.cost.toFixed(2) }}</td>
            <td>
              <p
                class="badge"
                :class="campaign.status === 'sent' ? 'green' : 'blue'"
              >
                {{
                  campaign.status.charAt(0).toUpperCase() +
                  campaign.status.slice(1)
                }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <Modal
    :show="selectedCampaign"
    @close="selectedCampaign = null"
    title="Campaign Info"
  >
    <div class="selectedCampaign">
      <div class="row">
        <p class="bold">Message:</p>
        <p>{{ selectedCampaign.message }}</p>
      </div>

      <div class="row">
        <p class="bold">
          To ({{ selectedCampaign.recipients.length }} recipients):
        </p>

        <p>
          <span
            v-for="(recipient, index) in selectedCampaign.recipients"
            :key="index"
            >{{ recipient }},
          </span>
        </p>
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  created() {
    this.init();
  },
  data() {
    return {
      selectedCampaign: null,
      campaigns: [],
    };
  },
  methods: {
    async init() {
      const response = await this.$axios.get(
        `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/marketing/campaigns`
      );

      this.campaigns = response.data.campaigns;
    },
    formatDate(date) {
      return this.$moment(date).format('LL');
    },
  },
};
</script>

<style lang="scss" scoped>
.campaigns {
  max-width: 1000px;
  width: 100%;

  .badge {
    display: inline;
    font-size: 12px;
    padding: 4px 16px;
    border-radius: 50px;
  }
  .green {
    background-color: var(--clr-success);
    color: white;
  }
  .blue {
    background-color: var(--clr-secondary);
    color: white;
  }

  &_head {
    h2 {
      font-size: 28px;
    }
    p {
      margin-top: 8px;
    }
    .subtext {
      font-size: 14px;
      color: var(--clr-gray);
    }
  }

  &_body {
    widows: 100%;
    margin-top: 32px;
  }
}

.selectedCampaign {
  padding: 32px;

  .row {
    .bold {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 8px;
    }

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
}
</style>
