<template>
  <section class="staffSales">
    <div class="staffSales_head">
      <h2>Sales by staff</h2>
    </div>

    <div class="staffSales_drb">
      <DateRangeBar
        :start="start"
        :end="end"
        @search="search($event.starting, $event.ending)"
      >
        <BaseSelect
          label="Staff"
          :options="staffSelectOptions"
          :value="selectedStaff"
          @input="selectStaff($event)"
        />
        <!-- <div class="checkbox">
          <label for="datastaff">Display Hourly</label>
          <input
            type="checkbox"
            id="datastaff"
            :checked="displayHourly"
            @input="displayHourly = !displayHourly"
          />
        </div> -->
        <div v-if="selectedStaff !== 'all'" class="checkbox">
          <label for="invoiceCheck">Display Invoice</label>
          <input
            type="checkbox"
            id="invoiceCheck"
            :checked="displayInvoice"
            @input="displayInvoice = !displayInvoice"
          />
        </div>
        <div v-if="selectedStaff !== 'all' && displayInvoice" class="checkbox">
          <label for="compactMode">Detailed Invoice</label>
          <input
            type="checkbox"
            id="compactMode"
            :checked="invoiceDetailedMode"
            @input="invoiceDetailedMode = !invoiceDetailedMode"
          />
        </div>
        <div v-if="selectedStaff !== 'all'" class="checkbox">
          <label for="shiftCheck">Display Shifts Table</label>
          <input
            type="checkbox"
            id="shiftCheck"
            :checked="displayShiftTable"
            @input="displayShiftTable = !displayShiftTable"
          />
        </div>
        <div class="checkbox">
          <label for="revokedCheckbox">Display Revoked Members</label>
          <input
            type="checkbox"
            id="revokedCheckbox"
            :checked="displayRevokedMembers"
            @input="displayRevokedMembers = !displayRevokedMembers"
          />
        </div>
      </DateRangeBar>
    </div>

    <Spinner v-if="loading" />

    <!-- Shifts Table -->
    <div v-if="displayShiftTable" class="staffSales_tables_table mt-s">
      <Table
        v-if="selectedStaff !== 'all'"
        :headers="salesShiftsHeaders"
        :data="salesShiftsData"
        @rowClick="selectShift($event)"
      />
    </div>

    <div class="staffSales_tables" v-if="!selectedStaffEmpty">
      <!-- <div v-if="selectedStaff !== 'all' && salesDataWithoutId" class="simple">
        <p>
          Net Sales: <span>{{ salesDataWithoutId[0].netSales }}</span> Tips:
          <span>{{ salesDataWithoutId[0].tips }}</span>
        </p>
      </div> -->

      <div class="staffSales_tables_table">
        <Table
          :headers="salesHeaders"
          :data="salesDataWithoutId"
          :boldRows="[0]"
          :boldCols="[11, 18, 20]"
          @rowClick="selectStaff(salesData[$event]._id)"
        />
      </div>

      <!-- Invoice -->
      <div v-if="displayInvoice" class="staffSales_tables_invoices">
        <SalesStaffInvoiceAlt
          v-for="staff in salesDataWithoutId"
          :key="staff"
          :data="staff"
          :staff="selectedStaffObject"
          :start="start"
          :end="end"
          :detailedMode="invoiceDetailedMode"
          :tickets="tickets"
        />
      </div>
    </div>

    <!-- None -->
    <div
      v-if="
        itemsData &&
        !itemsData.length &&
        tipsData &&
        !tipsData.length &&
        shiftsData &&
        !shiftsData.length
      "
      class="staffSales_none"
    >
      <h4>{{ selectedStaffName || 'This staff' }} has no data to display!</h4>
      <p>Select another staff or search a new date range.</p>
    </div>

    <!-- Items -->
    <div
      class="staffSales_breakdown"
      v-if="selectedStaff !== 'all' && itemsData && itemsData.length"
    >
      <h2>Item Breakdown</h2>
      <div class="staffSales_breakdown_tables">
        <div class="staffSales_breakdown_tables_table">
          <Table
            :headers="itemsHeaders"
            :data="itemsData"
            @rowClick="viewTicket($event)"
          />
        </div>
      </div>
    </div>

    <!-- Tips -->
    <div
      class="staffSales_breakdown"
      v-if="selectedStaff !== 'all' && tipsData && tipsData.length"
    >
      <h2>Tip Breakdown</h2>
      <div class="staffSales_breakdown_tables">
        <div class="staffSales_breakdown_tables_table">
          <Table
            :headers="tipsHeaders"
            :data="tipsData"
            @rowClick="viewTicket($event, true)"
          />
        </div>
      </div>
    </div>

    <!-- Shifts -->
    <div
      class="hourly_breakdown"
      v-if="selectedStaff !== 'all' && shiftsData && shiftsData.length"
    >
      <h2>Shift Breakdown</h2>
      <div class="hourly_breakdown_tables">
        <div class="hourly_breakdown_tables_table">
          <Table
            :headers="shiftsHeaders"
            :data="shiftsData"
            @rowClick="selectShift($event)"
          />
        </div>
      </div>
    </div>

    <!-- Tickets -->
    <div
      class="tickets_breakdown"
      v-if="selectedStaff !== 'all' && selectedStaffTickets.length"
    >
      <h2>Tickets Breakdown</h2>

      <ul class="tickets_breakdown_tables">
        <li
          class="ticket"
          v-for="ticket in selectedStaffTickets"
          :key="ticket._id"
        >
          <p class="title">
            {{ ticket.number }} -
            {{
              ticket.client
                ? `${ticket.client.firstName} ${ticket.client.lastName}`
                : 'Walk-in'
            }}
          </p>
          <p class="small">{{ formatDate(ticket.checkout_at) }}</p>

          <hr />

          <div class="items">
            <div
              v-for="item in ticket.items.filter(
                (i) => i.staff?.staffId === selectedStaff
              )"
              :key="item._id"
              class="item"
            >
              <p>{{ item.quantity }} {{ item.item.title }}</p>
              <p class="bold">
                ${{
                  (
                    item.item.price -
                    (item.item.cashDiscountAdditionalProcessingFee || 0)
                  ).toFixed(2)
                }}
              </p>
            </div>
          </div>

          <hr />

          <ul class="totals">
            <li>
              <p>My Total:</p>
              <p class="bold">${{ getStaffsTicketTotal(ticket) }}</p>
            </li>
            <li>
              <p>My Tips:</p>
              <p class="bold">${{ getStaffsTicketTips(ticket) }}</p>
            </li>
            <li v-if="!loading">
              <p>My Commission:</p>
              <p class="bold">${{ getStaffsTicketCommission(ticket) }}</p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </section>

  <Confirm
    v-if="showYouDoNotHavePerm"
    title="Not permitted"
    text="You do not have permission to view all staff"
    @confirm="showYouDoNotHavePerm = false"
  />

  <Modal
    title="Enter Staff Account Code"
    :show="showStaffAccountCodeModal"
    @close="showStaffAccountCodeModal = false"
  >
    <form @submit.prevent="submitStaffAccountCode" class="accountCodeForm form">
      <BaseInput
        inputType="password"
        label="Account Code"
        @input="staffAccountCode = $event"
      />
      <div class="form_actions">
        <BaseButton>Submit</BaseButton>
      </div>
    </form>
  </Modal>

  <Modal title="Shift form" :show="selectedShift" @close="selectedShift = null">
    <div class="selectedShift">
      <ShiftForm :shift="selectedShift" @submitted="search" />
    </div>
  </Modal>

  <Modal
    title="Selected Ticket"
    :show="selectedTicket"
    @close="selectedTicket = null"
  >
    <div class="selectedTicket">
      <TicketDetails :ticket="selectedTicket" hideActions />

      <div class="selectedTicket_actions">
        <BaseButton @click="selectTicket()">View Ticket</BaseButton>
      </div>
    </div>
  </Modal>
</template>

<script>
import DateRangeBar from '@/components/components/DateRangeBar.vue';
// import SalesStaffInvoice from '@/views/sales/SalesStaffInvoice.vue';
import SalesStaffInvoiceAlt from '@/views/sales/SalesStaffInvoiceAlt.vue';
import ShiftForm from '@/components/staff/ShiftForm.vue';
import TicketDetails from '@/components/tickets/TicketDetails.vue';

export default {
  components: {
    DateRangeBar,
    // SalesStaffInvoice,
    SalesStaffInvoiceAlt,
    ShiftForm,
    TicketDetails,
  },
  async created() {
    if (this.$route.query.start)
      this.start = this.$moment(this.$route.query.start);
    if (this.$route.query.end) this.end = this.$moment(this.$route.query.end);

    this.selectedStaff = this.$store.getters['auth/activeUserStaffId'];
    await this.setStaffSelectOptions();

    if (this.$route.query.staff) {
      const staffToSelect = this.$store.state.staff.staff.find((s) => {
        return (
          s.staffId === this.$route.query.staff ||
          s._id === this.$route.query.staff
        );
      });

      this.selectedStaff = staffToSelect.staffId;
    }

    this.resetSales();
    this.search(this.start, this.end);
  },
  computed: {
    loggedInStaffIsAdmin() {
      return (
        this.$store.state.auth.loggedInSalonStaff.staffId ===
        this.$store.state.auth.salon.adminId
      );
    },
    staff() {
      return this.$store.state.staff.staff;
    },
    selectedStaffTickets() {
      if (!this.tickets) return [];

      return this.tickets.filter((ticket) => {
        return ticket.items.some(
          (i) => i.staff?.staffId === this.selectedStaff
        );
      });
    },
    salesDataWithoutId() {
      if (!this.salesData) return;

      return this.salesData.map(({ _id, staffId, ...rest }) => {
        //  Literally have to do something with _id or Vue complains
        //  that we're not doing anything with _id LMAO
        +_id++;
        if (staffId) staffId.toLowerCase();
        return rest;
      });
    },
    selectedStaffObject() {
      if (!this.selectedStaff) return null;

      const salonStaff = this.$store.state.auth.salon.staff.find(
        (staff) => staff.staffId === this.selectedStaff
      );

      if (salonStaff) {
        return salonStaff;
      } else {
        const deletedStaff = this.deletedMembers.find(
          (staff) => staff.staffId === this.selectedStaff
        );

        return deletedStaff;
      }
    },
    selectedStaffEmpty() {
      return (
        this.selectedStaff !== 'all' &&
        this.salesData &&
        this.salesData.length === 0
      );
    },
    selectedStaffName() {
      if (!this.selectedStaff) return '';

      const salonStaff = this.$store.state.auth.salon.staff.find(
        (staff) => staff._id === this.selectedStaff
      );

      if (!salonStaff) return '';

      return `${salonStaff.firstName} ${salonStaff.lastName}`;
    },
  },
  data() {
    return {
      // Sales
      salesHeaders: [
        'Staff',
        'Items Sold',
        'Gross Sales (Services)',
        'Gross Sales (Products)',
        'Discounts (Services)',
        'Discounts (Products)',
        'Refunds (Services)',
        'Refunds (Products)',
        'Net Sales (Services)',
        'Net Sales (Products)',
        'Tax',
        'Total Sales',
        'Fees',
        'Daily Fees',
        'Tips',
        'Fixed Commission (Services)',
        'Fixed Commission (Products)',
        'Percent Commission',
        'Total Commission',
        'Hourly',
        'Total Earnings',
      ],
      salesData: null,
      allSalesData: null,

      // Sales Shifts
      salesShiftsHeaders: [
        'Staff',
        'Shifts',
        'Days With Shifts',
        'Days With Tickets',
        'Total Hours',
        'Hourly + Tips',
        'Tip-Hourly Rate',
        // 'Tip-Hourly Earnings',
      ],
      salesShiftsData: [],
      selectedShift: null,

      // displayHourly: false,
      displayInvoice: false,
      displayShiftTable: false,
      invoiceDetailedMode: false,
      displayRevokedMembers: false,

      itemsHeaders: [
        'Ticket',
        'Date',
        'Client',
        'Item',
        'Quantity',
        'Price',
        'Discount',
        'Fees',
        'Commission',
        // 'Tip',
        'Status',
      ],
      itemsData: null,

      tipsHeaders: ['Ticket', 'Date', 'Client', 'Tip'],
      tipsData: null,

      selectedStaff: null,
      staffSelectOptions: [],
      tickets: [],

      shifts: [],
      shiftsHeaders: [
        'Staff',
        'Clock In By',
        'Shift Id',
        'Clock In',
        'Clock Out',
        'Hourly Rate',
        'Earnings',
      ],
      shiftsData: null,

      showStaffAccountCodeModal: false,
      staffAccountCode: '',

      loading: false,
      showYouDoNotHavePerm: false,

      start: this.$moment().startOf('day'),
      end: this.$moment().endOf('day'),

      deletedMembers: [],

      selectedTicket: null,
    };
  },
  watch: {
    // displayHourly() {
    //   this.setStaffSelectOptions();
    // },
    displayRevokedMembers() {
      this.setStaffSelectOptions();
    },
  },
  methods: {
    formatDate(date) {
      return this.$moment(date).format('lll');
    },
    async selectStaff(staff) {
      if (staff === 'all') this.displayInvoice = false;

      let staffId;
      if (staff !== 'all') {
        const theStaff = this.$store.state.auth.salon.staff.find(
          (s) => s._id === staff || s.staffId === staff
        );

        if (theStaff) {
          staffId = theStaff.staffId;
        }
      }

      this.selectedStaff = staffId || 'all';

      // Check require code permission
      if (
        staff !== this.$store.state.auth.loggedInSalonStaff.staffId &&
        !this.$store.state.auth.managerMode &&
        !this.loggedInStaffIsAdmin
      ) {
        if (
          await this.$store.dispatch(
            'auth/activeUserHasPermission',
            'tickets/codeToViewOthersReports'
          )
        ) {
          if (staff === 'all') {
            this.showYouDoNotHavePerm = true;
            return;
          }

          this.showStaffAccountCodeModal = true;
          return;
        }
      }

      this.setDisplayedStaff();
      await this.addItemDataToItemsData();
      await this.addTipDataToTipsData();
      this.addShiftToShiftData();
    },
    async setStaffSelectOptions() {
      const staff = [];
      const activeStaff = this.$store.state.auth.loggedInSalonStaff;

      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'tickets/viewOthersReports'
        )
      ) {
        staff.push({
          option: 'All',
          value: 'all',
        });

        this.$store.state.staff.staff
          .filter((s) => (this.displayRevokedMembers ? true : !s.isDeleted))
          .sort((a, b) => a.firstName.localeCompare(b.firstName))
          .forEach((prestaff) => {
            // if (!this.displayHourly && prestaff.trackHourly) return;

            staff.push({
              option: `${prestaff.firstName} ${prestaff.lastName}`,
              value: prestaff.staffId,
            });
          });
      } else {
        staff.push({
          option: `${activeStaff.firstName} ${activeStaff.lastName}`,
          value: activeStaff.staffId,
        });
      }

      this.staffSelectOptions = staff;
    },

    async submitStaffAccountCode() {
      const salonStaff = this.$store.state.auth.salon.staff.find(
        (staff) => staff.staffId === this.selectedStaff
      );

      if (!salonStaff) return;

      if (this.staffAccountCode !== salonStaff.accountCode) {
        this.$toast.error('Incorrect staff code');
        this.selectedStaff = this.$store.state.auth.loggedInSalonStaff._id;
      }

      this.setDisplayedStaff();
      await this.addItemDataToItemsData();
      await this.addTipDataToTipsData();

      this.showStaffAccountCodeModal = false;
      this.staffAccountCode = '';
    },

    async viewTicket(index, fromTips) {
      let ticket = null;

      if (fromTips) {
        ticket = this.tickets.find(
          (t) => t.number === this.tipsData[index].ticket
        );
      } else {
        ticket = this.tickets.find(
          (t) => t.number === this.itemsData[index].ticket
        );
      }

      this.selectedTicket = ticket;
    },

    async selectTicket() {
      this.$router.push({
        name: 'SalesTickets',
        query: {
          start: this.$moment(this.selectedTicket.checkout_at)
            .startOf('day')
            .toDate(),
          end: this.$moment(this.selectedTicket.checkout_at)
            .endOf('day')
            .toDate(),
          number: this.selectedTicket.number,
          ref: `/dashboard/sales/staff?staff=${this.selectedStaff}&start=${this.start}&end=${this.end}`,
        },
      });
    },

    async search(start, end) {
      if (!start) start = this.start;
      if (!end) end = this.end;

      this.loading = true;
      this.start = start;
      this.end = end;
      this.selectedShift = null;

      try {
        const tickets = await this.$store.dispatch(
          'tickets/getTicketsInDateRange',
          { start, end }
        );

        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/shifts?salonId=${this.$store.state.auth.salon._id}&start=${this.start}&end=${this.end}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.setSales({ tickets, shifts: response.data.shifts });
      } catch (error) {
        console.log(error);
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    async checkForDeletedTeamMembers(tickets) {
      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'tickets/viewOthersReports'
        )
      ) {
        tickets.forEach((ticket) => {
          ticket.items.forEach((item) => {
            if (item.staff) {
              const index = this.staffSelectOptions.findIndex(
                (staff) =>
                  staff.value === item.staff._id ||
                  staff.value === item.staff.staffId
              );

              if (index === -1) {
                this.deletedMembers.push(item.staff);

                this.staffSelectOptions.push({
                  option: `${item.staff.firstName} ${item.staff.lastName}`,
                  value: item.staff.staffId,
                });
              }
            }
          });
        });
      }
    },

    async setSales(data) {
      // Reset
      this.resetSales();

      // Check for deleted team member
      this.checkForDeletedTeamMembers(data.tickets);

      // Tickets
      this.tickets = data.tickets;

      for (let i = 0; i < this.tickets.length; i++) {
        const ticket = this.tickets[i];

        await this.addTicketDataToSalesData(ticket);
      }

      // Daily Fees
      this.addDailyFees();

      // Shifts
      this.shifts = data.shifts;

      this.shifts.forEach((shift) => {
        this.addShiftDataToSalesData(shift);
      });

      this.addShiftToShiftData();

      // Items
      await this.addItemDataToItemsData();

      // Tips
      await this.addTipDataToTipsData();

      // Set Totals
      this.setTotalsOnSalesData();

      // Formatting
      this.formatTotals();

      // Display correct staff(s)
      this.setDisplayedStaff();
    },

    addDailyFees() {
      this.staff.forEach((staff) => (staff.dailyTickets = []));

      this.tickets.forEach((ticket) => {
        const staffOnTicket = [];

        ticket.items.forEach((item) => {
          if (!item.staff) return;

          if (!staffOnTicket.find((staff) => staff === item.staff.staffId))
            staffOnTicket.push(item.staff.staffId);
        });

        staffOnTicket.forEach((staffId) => {
          const staffIndex = this.staff.findIndex(
            (staff) => staff.staffId === staffId
          );

          if (staffIndex === -1) return;

          if (!this.staff[staffIndex].dailyTickets)
            this.staff[staffIndex].dailyTickets = [];

          if (
            !this.staff[staffIndex].dailyTickets.find(
              (date) => date === this.$moment(ticket.checkout_at).format('ll')
            )
          )
            this.staff[staffIndex].dailyTickets.push(
              this.$moment(ticket.checkout_at).format('ll')
            );
        });
      });

      this.staff.forEach((staff) => {
        if (staff.dailyTickets && staff.dailyTickets.length) {
          const dataIndex = this.allSalesData.findIndex(
            (data) => data.staffId === staff.staffId
          );

          if (dataIndex === -1) return;

          this.allSalesData[dataIndex].dailyFees =
            staff.dailyTickets.length * staff.dailyFee;

          this.allSalesData[0].dailyFees +=
            staff.dailyTickets.length * staff.dailyFee;
        }
      });
    },

    addShiftDataToSalesData(shift) {
      const staff = this.$store.state.staff.staff.find((staff) => {
        return staff.staffId === shift.staffId;
      });

      if (!staff) return;

      let index = this.allSalesData.findIndex(
        (prestaff) =>
          prestaff._id === staff._id || prestaff.staffId === staff.staffId
      );

      if (index === -1) {
        this.allSalesData.push({
          _id: staff._id,
          staff: `${staff.firstName} ${staff.lastName}`,
          itemsSold: 0,
          grossSalesServices: 0,
          grossSalesProducts: 0,
          discountsServices: 0,
          discountsProducts: 0,
          refundsServices: 0,
          refundsProducts: 0,
          netSalesServices: 0,
          netSalesProducts: 0,
          tax: 0,
          totalSales: 0,
          fees: 0,
          dailyFees: 0,
          tips: 0,
          flatComServices: 0,
          flatComProducts: 0,
          commission: 0,
          totalCommission: 0,
          hourly: 0,
          totalEarnings: 0,
          uniqueDays: 0,
        });

        index = this.allSalesData.length - 1;
      }

      const minutes = this.$moment(shift.clockOut).diff(
        this.$moment(shift.clockIn),
        'minutes'
      );

      const earnings = shift.rate * (minutes / 60);

      this.allSalesData[index].hourly += earnings;
      this.allSalesData[0].hourly += earnings;
    },

    addShiftToShiftData() {
      if (!this.selectedStaffObject) return;

      this.shiftsData = [];
      this.salesShiftsData = [
        {
          staff: this.selectedStaffObject
            ? `${this.selectedStaffObject.firstName} ${this.selectedStaffObject.lastName}`
            : '',
          shifts: 0,
          uniqueDays: 0,
          daysWithTickets: 0,
          totalHours: 0,
          hourlyPlusTips: 0,
          tipHourlyRate: 0,
          // tipHourlyEarnings: 0,
        },
      ];

      this.staff.forEach((staff) => {
        staff.uniqueDates = [];
        staff.forShiftsTips = 0;
      });

      // Days with tickets
      const days = [];

      this.tickets.forEach((ticket) => {
        if (!this.selectedStaffObject) return;

        if (
          ticket.items.some(
            (i) => i.staff?.staffId === this.selectedStaffObject.staffId
          )
        ) {
          const date = this.$moment(ticket.checkout_at).format('LL');

          if (!days.includes(date)) days.push(date);
        }
      });

      this.salesShiftsData[0].daysWithTickets = days.length;

      const salesStaffObject = this.allSalesData.find(
        (data) => data.staffId === this.selectedStaffObject.staffId
      );

      if (salesStaffObject) {
        salesStaffObject.uniqueDays = days.length;
      }

      // Get tips
      this.selectedStaffObject.forShiftsTips = 0;

      this.tickets.forEach((ticket) => {
        ticket.tips.forEach((tip) => {
          if (tip.staff.staffId !== this.selectedStaff) return;

          this.selectedStaffObject.forShiftsTips += tip.amount;
        });
      });

      this.shifts.forEach((shift) => {
        this.addShiftDataToSalesShiftsData(shift);

        // const staff = this.$store.state.staff.staff.find((staff) => {
        //   return staff.staffId === shift.staffId;
        // });

        // if (!staff || staff.staffId !== this.selectedStaff) return;

        if (shift.staffId !== this.selectedStaffObject.staffId) return;

        const staff = JSON.parse(JSON.stringify(this.selectedStaffObject));

        const minutes = this.$moment(shift.clockOut).diff(
          this.$moment(shift.clockIn),
          'minutes'
        );

        this.shiftsData.push({
          staff: `${staff.firstName} ${staff.lastName}`,
          clockInBy: shift.clockedInBy,
          shiftId: shift._id,
          clockIn: shift.clockIn,
          clockOut: shift.clockOut,
          hourlyRate: shift.rate,
          earnings: shift.rate * (minutes / 60),
        });
      });

      this.salesShiftsData[0].hourlyPlusTips +=
        this.selectedStaffObject.forShiftsTips;

      this.salesShiftsData[0].tipHourlyRate = this.salesShiftsData[0].totalHours
        ? this.salesShiftsData[0].hourlyPlusTips /
          this.salesShiftsData[0].totalHours
        : 0;

      // this.salesShiftsData[0].tipHourlyEarnings =
      //   this.salesShiftsData[0].tipHourlyRate *
      //   this.salesShiftsData[0].totalHours;

      this.salesShiftsData.forEach((data) => {
        data.totalHours = data.totalHours.toFixed(2);
        data.hourlyPlusTips = `$${data.hourlyPlusTips.toFixed(2)}`;
        data.tipHourlyRate = `$${data.tipHourlyRate.toFixed(2)}`;
        // data.tipHourlyEarnings = `$${data.tipHourlyEarnings.toFixed(2)}`;
      });

      this.shiftsData.forEach((shift) => {
        shift.clockIn = this.$moment(shift.clockIn).format('LLL');
        shift.clockOut = this.$moment(shift.clockOut).format('LLL');
        shift.hourlyRate = `$${shift.hourlyRate.toFixed(2)}`;
        shift.earnings = `$${shift.earnings.toFixed(2)}`;
      });
    },

    addShiftDataToSalesShiftsData(shift) {
      // const staff = this.staff.find((staff) => {
      //   return staff.staffId === shift.staffId;
      // });

      // if (!staff || staff.staffId !== this.selectedStaff) return;

      if (shift.staffId !== this.selectedStaffObject.staffId) return;

      const staff = JSON.parse(JSON.stringify(this.selectedStaffObject));

      if (!staff.uniqueDates) staff.uniqueDates = [];

      // Unique days
      const dateIndex = staff.uniqueDates.findIndex(
        (date) => date === this.$moment(shift.clockOut).format('LL')
      );

      if (dateIndex === -1) {
        // First shift of day
        staff.uniqueDates.push(this.$moment(shift.clockOut).format('LL'));
        this.salesShiftsData[0].uniqueDays++;
      }

      this.salesShiftsData[0].shifts++;

      const minutes = this.$moment(shift.clockOut).diff(
        this.$moment(shift.clockIn),
        'minutes'
      );

      this.salesShiftsData[0].totalHours += minutes / 60;

      const earnings = shift.rate * (minutes / 60);

      this.salesShiftsData[0].hourlyPlusTips += earnings;
    },

    async selectShift(index) {
      if (
        !(await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'tickets/editShifts'
        ))
      )
        return;
      if (this.shiftsData[index].shiftId) {
        this.selectedShift = this.shifts.find((shift) => {
          return shift._id === this.shiftsData[index].shiftId;
        });
      }
    },

    async addTicketDataToSalesData(ticket) {
      if (ticket.status !== 'completed' && ticket.status !== 'refunded') return;

      const feeFirst =
        this.$store.state.auth.salon.adminSettings.takeFeeFirstForCommission;

      // Items
      for (let i = 0; i < ticket.items.length; i++) {
        const item = ticket.items[i];

        if (item.staff) {
          let index = this.allSalesData.findIndex((staff) => {
            return (
              staff._id === item.staff._id ||
              staff.staffId === item.staff.staffId
            );
          });

          if (index === -1) {
            this.allSalesData.push({
              _id: item.staff._id,
              staffId: item.staff.staffId,
              staff: `${item.staff.firstName} ${item.staff.lastName}`,
              itemsSold: 0,
              grossSalesServices: 0,
              grossSalesProducts: 0,
              discountsServices: 0,
              discountsProducts: 0,
              refundsServices: 0,
              refundsProducts: 0,
              netSalesServices: 0,
              netSalesProducts: 0,
              tax: 0,
              totalSales: 0,
              fees: 0,
              dailyFees: 0,
              tips: 0,
              flatComServices: 0,
              flatComProducts: 0,
              commission: 0,
              totalCommission: 0,
              hourly: 0,
              totalEarnings: 0,
              uniqueDays: 0,
            });

            index = this.allSalesData.length - 1;
          }

          // TODO: condense this, not DRY
          if (item.quantity > 0) {
            if (item.item.duration) {
              this.allSalesData[index].discountsServices +=
                (item.item.discountAmount || 0) * item.quantity;
              this.allSalesData[0].discountsServices +=
                (item.item.discountAmount || 0) * item.quantity;

              this.allSalesData[index].grossSalesServices +=
                (item.item.price -
                  (item.item.cashDiscountAdditionalProcessingFee || 0)) *
                item.quantity;
              this.allSalesData[0].grossSalesServices +=
                (item.item.price -
                  (item.item.cashDiscountAdditionalProcessingFee || 0)) *
                item.quantity;
            } else {
              this.allSalesData[index].discountsProducts +=
                (item.item.discountAmount || 0) * item.quantity;
              this.allSalesData[0].discountsProducts +=
                (item.item.discountAmount || 0) * item.quantity;

              this.allSalesData[index].grossSalesProducts +=
                (item.item.price -
                  (item.item.cashDiscountAdditionalProcessingFee || 0)) *
                item.quantity;
              this.allSalesData[0].grossSalesProducts +=
                (item.item.price -
                  (item.item.cashDiscountAdditionalProcessingFee || 0)) *
                item.quantity;
            }

            this.allSalesData[index].itemsSold += item.quantity;
            this.allSalesData[0].itemsSold += item.quantity;

            let { commissionEarned, flatComServEarned, flatComProdEarned } =
              await this.$store.dispatch('staff/getCommissionEarned', {
                item,
                feeFirst,
              });

            if (item.item.fees && !item.staff.exemptFromServiceFees) {
              this.allSalesData[index].fees +=
                (item.item.fees.supply + item.item.fees.other) * item.quantity;
              this.allSalesData[0].fees +=
                (item.item.fees.supply + item.item.fees.other) * item.quantity;
            }
            if (item.item.taxRate) {
              this.allSalesData[index].tax +=
                (item.item.price - (item.item.discountAmount || 0)) *
                item.quantity *
                (item.item.taxRate * 0.01);
              this.allSalesData[0].tax +=
                (item.item.price - (item.item.discountAmount || 0)) *
                item.quantity *
                (item.item.taxRate * 0.01);
            }

            this.allSalesData[index].commission += commissionEarned;
            this.allSalesData[index].flatComProducts += flatComProdEarned;
            this.allSalesData[index].flatComServices += flatComServEarned;
            this.allSalesData[index].totalCommission +=
              commissionEarned + flatComProdEarned + flatComServEarned;

            // All
            this.allSalesData[0].commission += commissionEarned;
            this.allSalesData[0].flatComProducts += flatComProdEarned;
            this.allSalesData[0].flatComServices += flatComServEarned;
            this.allSalesData[0].totalCommission +=
              commissionEarned + flatComProdEarned + flatComServEarned;
          } else if (item.quantity < 0) {
            // A refunded item
            let { commissionEarned, flatComServEarned, flatComProdEarned } =
              await this.$store.dispatch('staff/getCommissionEarned', {
                item,
                feeFirst,
              });

            if (item.item.fees) {
              this.allSalesData[index].fees -=
                item.item.fees.supply + item.item.fees.other;
              this.allSalesData[0].fees -=
                item.item.fees.supply + item.item.fees.other;
            }
            if (item.item.taxRate) {
              this.allSalesData[index].tax +=
                (item.item.price - (item.item.discountAmount || 0)) *
                item.quantity *
                (item.item.taxRate * 0.01);
              this.allSalesData[0].tax +=
                (item.item.price - (item.item.discountAmount || 0)) *
                item.quantity *
                (item.item.taxRate * 0.01);
            }

            if (item.item.duration) {
              this.allSalesData[index].refundsServices +=
                (item.item.price -
                  (item.item.cashDiscountAdditionalProcessingFee || 0) -
                  (item.item.discountAmount || 0)) *
                item.quantity;

              // All
              this.allSalesData[0].refundsServices +=
                (item.item.price -
                  (item.item.cashDiscountAdditionalProcessingFee || 0) -
                  (item.item.discountAmount || 0)) *
                item.quantity;
            } else {
              this.allSalesData[index].refundsProducts +=
                (item.item.price -
                  (item.item.cashDiscountAdditionalProcessingFee || 0) -
                  (item.item.discountAmount || 0)) *
                item.quantity;

              // All
              this.allSalesData[0].refundsProducts +=
                (item.item.price -
                  (item.item.cashDiscountAdditionalProcessingFee || 0) -
                  (item.item.discountAmount || 0)) *
                item.quantity;
            }

            this.allSalesData[index].commission += commissionEarned;
            this.allSalesData[index].flatComProducts += flatComProdEarned;
            this.allSalesData[index].flatComServices += flatComServEarned;
            this.allSalesData[index].totalCommission +=
              commissionEarned + flatComProdEarned + flatComServEarned;

            // All
            this.allSalesData[0].commission += commissionEarned;
            this.allSalesData[0].flatComProducts += flatComProdEarned;
            this.allSalesData[0].flatComServices += flatComServEarned;
            this.allSalesData[0].totalCommission +=
              commissionEarned + flatComProdEarned + flatComServEarned;
          }
        }
      }

      ticket.tips.forEach((tip) => {
        let index = this.allSalesData.findIndex(
          (staff) =>
            staff._id === tip.staff._id || staff.staffId === tip.staff.staffId
        );

        if (index === -1) {
          this.allSalesData.push({
            _id: tip.staff._id,
            staffId: tip.staff.staffId,
            staff: `${tip.staff.firstName} ${tip.staff.lastName}`,
            itemsSold: 0,
            grossSalesServices: 0,
            grossSalesProducts: 0,
            discountsServices: 0,
            discountsProducts: 0,
            refundsServices: 0,
            refundsProducts: 0,
            netSalesServices: 0,
            netSalesProducts: 0,
            tax: 0,
            totalSales: 0,
            fees: 0,
            dailyFees: 0,
            tips: 0,
            flatComServices: 0,
            flatComProducts: 0,
            commission: 0,
            totalCommission: 0,
            hourly: 0,
            totalEarnings: 0,
            uniqueDays: 0,
          });

          index = this.allSalesData.length - 1;
        }
        this.allSalesData[index].tips += tip.amount;

        // All
        this.allSalesData[0].tips += tip.amount;
      });
    },

    async addTipDataToTipsData() {
      this.tipsData = [];

      for (let i = 0; i < this.tickets.length; i++) {
        const ticket = this.tickets[i];

        for (let j = 0; j < ticket.tips.length; j++) {
          const tip = ticket.tips[j];

          if (
            tip.staff &&
            (tip.staff._id === this.selectedStaff ||
              tip.staff.staffId === this.selectedStaff)
          ) {
            this.tipsData.push({
              ticket: ticket.number,
              date: this.$moment(ticket.checkout_at).format('LLL'),
              client: ticket.client
                ? `${ticket.client.firstName} ${ticket.client.lastName || ''}`
                : 'Walk-in',
              tip: tip.amount,
            });
          }
        }
      }

      // Format
      this.tipsData.forEach((data) => {
        data.tip = `$${data.tip.toFixed(2)}`;
      });
    },

    async addItemDataToItemsData() {
      try {
        this.itemsData = [];

        const feeFirst =
          this.$store.state.auth.salon.adminSettings.takeFeeFirstForCommission;

        for (let i = 0; i < this.tickets.length; i++) {
          const ticket = this.tickets[i];

          if (ticket.status !== 'completed' && ticket.status !== 'refunded')
            continue;

          for (let j = 0; j < ticket.items.length; j++) {
            const item = ticket.items[j];

            if (
              item.staff &&
              (item.staff._id === this.selectedStaff ||
                item.staff.staffId === this.selectedStaff)
            ) {
              let status = item.status;

              // // Tips
              // let tip = 0;

              // // if (
              // //   j === 0 ||
              // //   (this.itemsData[j - 1] &&
              // //     this.itemsData[j - 1].ticket !== ticket.number)
              // // ) {
              // let tipIndex = ticket.tips.findIndex(
              //   (tip) => tip.staff.staffId === this.selectedStaff
              // );

              // if (tipIndex !== -1) {
              //   tip += ticket.tips[tipIndex].amount;
              // }

              // tip = `$${tip.toFixed(2)}`;
              // // } else {
              // //   tip = '--';
              // // }

              if (item.status === 'default') status = 'completed';
              if (item.quantity < 0) status = 'refunded';

              let { commissionEarned, flatComServEarned, flatComProdEarned } =
                await this.$store.dispatch('staff/getCommissionEarned', {
                  item,
                  feeFirst,
                });

              let fees = 0;

              if (item.item.fees && !item.staff.exemptFromServiceFees) {
                fees +=
                  (item.item.fees.supply + item.item.fees.other) *
                  item.quantity;
              }

              this.itemsData.push({
                ticket: ticket.number,
                date: this.$moment(ticket.checkout_at).format('LLL'),
                client: ticket.client
                  ? `${ticket.client.firstName} ${ticket.client.lastName || ''}`
                  : 'Walk-in',
                item:
                  item.item.title.length > 15
                    ? item.item.title.slice(0, 15) + '..'
                    : item.item.title,
                quantity: item.quantity,
                price:
                  (item.item.price -
                    (item.item.cashDiscountAdditionalProcessingFee || 0)) *
                  item.quantity,
                discount: (item.item.discountAmount || 0) * item.quantity,
                fees,
                commission:
                  commissionEarned + flatComServEarned + flatComProdEarned,
                // tip,
                status,
              });
            }
          }
        }

        this.itemsData.forEach((data) => {
          data.fees = `($${data.fees.toFixed(2)})`;
          data.discount = `($${data.discount.toFixed(2)})`;
          data.commission =
            data.quantity < 0
              ? `($${data.commission.toFixed(2)})`
              : `$${data.commission.toFixed(2)}`;
          data.price =
            data.quantity < 0
              ? `($${data.price.toFixed(2)})`
              : `$${data.price.toFixed(2)}`;
        });
      } catch (err) {
        console.log(err.message);
      }
    },

    setTotalsOnSalesData() {
      // const feeFirst =
      //   this.$store.state.auth.salon.adminSettings.takeFeeFirstForCommission;

      this.allSalesData.forEach((data) => {
        data.netSalesServices =
          data.grossSalesServices -
          data.discountsServices -
          Math.abs(data.refundsServices);

        data.netSalesProducts =
          data.grossSalesProducts -
          data.discountsProducts -
          Math.abs(data.refundsProducts);

        data.totalSales =
          data.netSalesServices + data.netSalesProducts + data.tax;

        data.totalEarnings =
          data.tips +
          data.commission -
          // (feeFirst ? 0 : data.fees) -
          data.dailyFees +
          data.flatComProducts +
          data.hourly +
          data.flatComServices;
      });
    },

    formatTotals() {
      this.allSalesData.forEach((data) => {
        data.grossSalesServices = `$${data.grossSalesServices.toFixed(2)}`;
        data.grossSalesProducts = `$${data.grossSalesProducts.toFixed(2)}`;
        data.discountsServices = `($${data.discountsServices.toFixed(2)})`;
        data.discountsProducts = `($${data.discountsProducts.toFixed(2)})`;
        data.refundsServices = `($${data.refundsServices.toFixed(2)})`;
        data.refundsProducts = `($${data.refundsProducts.toFixed(2)})`;
        data.netSalesServices = `$${data.netSalesServices.toFixed(2)}`;
        data.netSalesProducts = `$${data.netSalesProducts.toFixed(2)}`;
        data.tax = `$${data.tax.toFixed(2)}`;
        data.totalSales = `$${data.totalSales.toFixed(2)}`;
        data.fees = `($${data.fees.toFixed(2)})`;
        data.dailyFees = `($${data.dailyFees.toFixed(2)})`;
        data.tips = `$${data.tips.toFixed(2)}`;
        data.flatComServices = `$${data.flatComServices.toFixed(2)}`;
        data.flatComProducts = `$${data.flatComProducts.toFixed(2)}`;
        data.commission = `$${data.commission.toFixed(2)}`;
        data.totalCommission = `$${data.totalCommission.toFixed(2)}`;
        data.hourly = `$${data.hourly.toFixed(2)}`;
        data.totalEarnings = `$${data.totalEarnings.toFixed(2)}`;
      });
    },

    setDisplayedStaff() {
      if (this.selectedStaff === 'all') {
        this.salesData = this.allSalesData;
      } else {
        const salesData = [];

        const index = this.allSalesData.findIndex((data) => {
          return (
            data._id === this.selectedStaff ||
            data.staffId === this.selectedStaff
          );
        });

        if (index !== -1) {
          salesData.push(this.allSalesData[index]);
        }

        this.salesData = salesData;
      }
    },

    getStaffsTicketTotal(ticket) {
      const items = ticket.items.filter(
        (i) => i.staff?.staffId === this.selectedStaff
      );

      let total = 0;

      items.forEach((i) => {
        total += i.item.price * i.quantity;
      });

      return total.toFixed(2);
    },
    getStaffsTicketTips(ticket) {
      const tips = ticket.tips.filter(
        (t) => t.staff?.staffId === this.selectedStaff
      );

      let total = 0;

      tips.forEach((t) => {
        total += t.amount;
      });

      return total.toFixed(2);
    },
    getStaffsTicketCommission(ticket) {
      const feeFirst =
        this.$store.state.auth.salon.adminSettings.takeFeeFirstForCommission;

      const items = ticket.items.filter(
        (i) => i.staff?.staffId === this.selectedStaff
      );

      let total = 0;

      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        const staff = item.staff;

        if (!staff)
          return {
            commissionEarned: 0,
            flatComServEarned: 0,
            flatComProdEarned: 0,
          };

        let commissionEarned = 0;
        let flatComServEarned = 0;
        let flatComProdEarned = 0;

        let customCommission;
        const ccIndex = staff.customCommissions.findIndex(
          (cat) => cat.categoryId === item.item.categoryId
        );
        if (ccIndex !== -1) {
          customCommission = staff.customCommissions[ccIndex].commission;
        }

        if (item.item.duration) {
          if (item.item.isCommissioned) {
            if (item.item.customItemPriceForCommission) {
              commissionEarned =
                (item.item.customItemPriceForCommission -
                  (item.item.discountAmount || 0) +
                  (item.item.ownerDiscountAmount || 0) -
                  (feeFirst
                    ? item.item.fees && !item.staff.exemptFromServiceFees
                      ? item.item.fees.supply + item.item.fees.other
                      : 0
                    : 0)) *
                item.quantity *
                ((customCommission || item.staff.commission) * 0.01);
            } else if (item.item.commissionType === 'flat') {
              // commissionEarned += item.quantity * item.item.commissionAmount;
              flatComServEarned += item.quantity * item.item.commissionAmount;
            } else if (item.item.commissionType === 'percent') {
              commissionEarned =
                (item.item.price -
                  (item.item.discountAmount || 0) +
                  (item.item.ownerDiscountAmount || 0) -
                  (item.item.cashDiscountAdditionalProcessingFee || 0) -
                  (feeFirst
                    ? item.item.fees
                      ? item.item.fees.supply + item.item.fees.other
                      : 0
                    : 0)) *
                item.quantity *
                ((customCommission || item.staff.commission) * 0.01);
            }
          }
        } else {
          if (item.item.commissionType === 'flat') {
            // commissionEarned += item.quantity * item.item.commissionAmount;
            flatComProdEarned += item.quantity * item.item.commissionAmount;
          } else if (item.item.commissionType === 'percent') {
            commissionEarned =
              (item.item.price -
                (item.item.discountAmount || 0) +
                (item.item.ownerDiscountAmount || 0) -
                (item.item.cashDiscountAdditionalProcessingFee || 0)) *
              item.quantity *
              (item.item.commissionAmount * 0.01);
          }
        }

        if (!feeFirst) {
          if (item.item.fees && !item.staff.exemptFromServiceFees) {
            commissionEarned -=
              ((item.item.fees.supply || 0) + (item.item.fees.other || 0)) *
              item.quantity;
          }
        }

        total += commissionEarned + flatComServEarned + flatComProdEarned;
      }

      return total.toFixed(2);
    },

    resetSales() {
      this.itemsData = [];

      this.salesData = [
        {
          _id: 'All',
          staffId: 'All',
          staff: 'All Staff',
          itemsSold: 0,
          grossSalesServices: 0,
          grossSalesProducts: 0,
          discountsServices: 0,
          discountsProducts: 0,
          refundsServices: 0,
          refundsProducts: 0,
          netSalesServices: 0,
          netSalesProducts: 0,
          tax: 0,
          totalSales: 0,
          fees: 0,
          dailyFees: 0,
          tips: 0,
          flatComServices: 0,
          flatComProducts: 0,
          commission: 0,
          totalCommission: 0,
          hourly: 0,
          totalEarnings: 0,
          uniqueDays: 0,
        },
      ];

      this.allSalesData = [...this.salesData];
    },
  },
};
</script>

<style lang="scss" scoped>
.staffSales,
.hourly,
.tickets {
  width: 100%;

  .mt-s {
    margin-top: 32px;
  }

  &_head {
    h2 {
      font-size: 28px;
    }
  }

  &_drb {
    margin-top: 32px;

    .checkbox {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 5px;
        color: var(--clr-gray-2);
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  &_tables {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
    flex-wrap: wrap;

    .simple {
      padding: 12px 24px;
      border: 1px solid var(--clr-light);

      span {
        font-weight: 700;

        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }

    &_table {
      flex-grow: 1;
    }
  }

  &_none {
    padding: 32px;

    p {
      margin-top: 5px;
    }
  }

  &_breakdown {
    margin-top: 32px;

    h2 {
      font-size: 18px;
      margin-bottom: 16px;
    }

    &_tables {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 16px;
      flex-wrap: wrap;

      &_table {
        flex-grow: 1;
      }
    }
  }
}

.tickets {
  &_breakdown {
    &_tables {
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: stretch;

      .ticket {
        padding: 16px;
        border: 1px solid var(--clr-light);
        border-radius: 5px;
        min-width: 250px;
        // flex-grow: 1;
        box-shadow: var(--shadow);

        .title {
          font-weight: 700;
          margin-bottom: 8px;
        }
        .small {
          font-size: 14px;
        }
        .bold {
          font-weight: 700;
        }

        hr {
          margin: 16px 0;
        }

        .items {
          .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
          }
        }

        .totals {
          display: flex;
          flex-direction: column;
          gap: 4px;

          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
          }
        }
      }
    }
  }
}

.accountCodeForm,
.form {
  padding: 32px;

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}

.selectedTicket {
  padding: 32px;

  &_actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
