<template>
  <section class="hours">
    <div
      v-if="staffOnBookingButNoDefaultSchedule.length"
      class="alert alert-warning"
    >
      <p>
        Warning! Some staff members are on your online booking page but have no
        schedule set:<span
          v-for="(staff, index) in staffOnBookingButNoDefaultSchedule"
          :key="index"
        >
          {{ ' ' + staff
          }}<span v-if="index !== staffOnBookingButNoDefaultSchedule.length - 1"
            >,</span
          ></span
        >
      </p>
    </div>
    <div class="hours_head">
      <h2>Working Hours</h2>
    </div>
    <div class="hours_body">
      <ScheduleGrid :staffs="staff" />
    </div>
  </section>
</template>

<script>
import ScheduleGrid from '@/components/appointments/ScheduleGrid.vue';

export default {
  components: {
    ScheduleGrid,
  },
  created() {
    this.setStaff();
  },
  data() {
    return {
      staff: [],
      staffOnBookingButNoDefaultSchedule: [],
    };
  },
  watch: {
    staff() {
      this.setHints();
    },
  },
  methods: {
    async setStaff() {
      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'staff/viewOthersWorkingHours'
        )
      ) {
        this.staff = this.$store.state.staff.staff;
      } else {
        this.staff = [this.$store.state.auth.loggedInSalonStaff];
      }
    },

    setHints() {
      this.staff.forEach((staff) => {
        if (!staff) return;

        if (!staff.schedule.default.find((day) => day.blocks.length)) {
          if (staff.onBooking) {
            this.staffOnBookingButNoDefaultSchedule.push(
              `${staff.firstName} ${staff.lastName}`
            );
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.hours {
  width: 100%;

  .alert {
    padding: 12px;
    font-size: 14px;
    border-radius: 5px;
    margin-bottom: 16px;

    span {
      font-weight: 700;
    }

    &-warning {
      background-color: var(--clr-warning-light);
      border-color: var(--clr-warning);
    }
  }

  &_head {
    h2 {
      font-size: 28px;
    }
  }

  &_body {
    margin-top: 32px;
  }
}
</style>
