<template>
  <div class="salon" v-if="salonId">
    <div class="salon_details">
      <SalonLogo />
      <div class="salon_details_actions">
        <router-link :to="{ name: 'SalonBook', params: { id: salonId } }">
          <BaseButton
            :disabled="!serviceCategories.length || !bookingStaffs.length"
            >Book Now</BaseButton
          >
        </router-link>

        <BaseButton
          @click="showMoreActions = !showMoreActions"
          mode="primary-outline"
          v-if="
            allowDMs ||
            reviews.length ||
            (salon.bookingGallery && salon.bookingGallery.length > 0)
          "
        >
          {{ showMoreActions ? 'Less' : 'More' }} Options<i
            class="fas"
            :class="showMoreActions ? 'fa-chevron-up' : 'fa-chevron-down'"
          ></i
        ></BaseButton>

        <div v-if="showMoreActions" class="more">
          <router-link
            v-if="allowDMs"
            :to="{
              name: 'ClientMessages',
              query: {
                newMessageTo: salonId,
                salonName: salonDetails.shopName,
                salonImage: salon.profileImage,
              },
            }"
          >
            <BaseButton
              mode="primary-outline"
              class="messageButton"
              :disabled="!serviceCategories.length || !bookingStaffs.length"
              >Send A Message</BaseButton
            >
          </router-link>
          <BaseButton
            v-if="reviews.length"
            @click="scrollToReviews"
            mode="primary-outline"
            >Reviews</BaseButton
          >
          <BaseButton
            v-if="salon.bookingGallery && salon.bookingGallery.length"
            @click="scrollToGallery"
            mode="primary-outline"
            >Gallery</BaseButton
          >
        </div>
      </div>
      <div
        v-if="salonDetails.shortDescription"
        class="salon_details_description"
      >
        <p>
          {{ salonDetails.shortDescription }}
        </p>
      </div>
      <div class="salon_details_location-hours">
        <div v-if="salonDetails.phone" class="phone">
          <i class="fas fa-phone"></i>
          <a :href="`tel:${salonDetails.phone}`">
            {{ salonDetails.phone }}
          </a>
        </div>
        <div class="location">
          <i class="fas fa-map-marker-alt"></i>
          <p>
            {{ salonDetails.address.street }}, {{ salonDetails.address.city }},
            {{ salonDetails.address.state }} {{ salonDetails.address.postal }}
          </p>
        </div>
        <div class="hours">
          <SalonHours :schedule="salonSchedule" />
        </div>
      </div>
    </div>
    <div class="salon_booking">
      <div v-if="salon.bookingSettings.promotion" class="alert alert-info">
        <p>{{ salon.bookingSettings.promotion }}</p>
      </div>

      <!-- <h3>Book an appointment</h3> -->
      <div class="salon_booking_tabs">
        <p
          @click="selectedTab = 'services'"
          :class="{ selected: selectedTab === 'services' }"
        >
          Services
        </p>
        <p
          @click="selectedTab = 'staff'"
          :class="{ selected: selectedTab === 'staff' }"
        >
          Staff
        </p>
        <p
          v-if="
            (salon.payments.processor === 'chargeanywhere' ||
              salon.payments.processor === 'dejavoo') &&
            salon.bookingSettings.onlineShop
          "
          @click="selectedTab = 'shop'"
          :class="{ selected: selectedTab === 'shop' }"
        >
          Online Shop
        </p>
        <!-- <p
          v-if="
            (salon.payments.processor === 'chargeanywhere' ||
              salon.payments.processor === 'dejavoo') &&
            salon.bookingSettings.onlineGiftCardsEnabled
          "
          @click="selectedTab = 'giftcard'"
          :class="{ selected: selectedTab === 'giftcard' }"
        >
          Purchase A Gift Card
        </p> -->
      </div>
      <div class="salon_booking_services" v-if="selectedTab === 'services'">
        <h1 ref="bookingCategories">Services</h1>

        <div class="slider">
          <BookingCategoriesSlider
            ref="catSlider"
            @select="filterByCategory($event)"
            :selectedCategoryId="categoryFilter"
          />
        </div>

        <div class="search">
          <BaseInput
            placeholder="Search services.."
            icon="fas fa-search"
            @input="
              filter = $event;
              categoryFilter = '';
            "
          />
        </div>

        <div
          class="salon_booking_services_categories"
          v-show="category.items.length"
          v-for="category in serviceCategories"
          :key="category"
        >
          <h2>{{ category.title }}</h2>
          <div class="salon_booking_services_categories_services">
            <div
              class="service"
              v-for="service in category.items"
              :key="service"
            >
              <BookingServiceItem
                :service="service"
                :disabled="service.disabled"
                @click="service.disabled ? null : selectService(service)"
              />
            </div>
          </div>
        </div>
        <div v-if="!serviceCategories.length" class="none">
          <h3>This establishment has not yet set up their booking profile!</h3>
          <p>Please come back at a later time, or inform management.</p>
        </div>
      </div>
      <div class="salon_booking_staff" v-else-if="selectedTab === 'staff'">
        <BookingMemberItem
          v-for="staff in bookingStaffs"
          :key="staff"
          :staff="staff"
          @click="selectStaff(staff)"
        />
        <div v-if="!bookingStaffs.length" class="none">
          <h3>This establishment has not yet set up their booking profile!</h3>
          <p>Please come back at a later time, or inform management.</p>
        </div>
      </div>
      <div class="salon_booking_onlineShop" v-else-if="selectedTab === 'shop'">
        <div class="searchFilter">
          <BaseInput
            placeholder="Search.."
            icon="fas fa-search"
            :value="onlineShopSearchValue"
            @input="onlineShopSearchValue = $event"
          />
          <BaseSelect
            :options="onlineShopSelectOptions"
            :value="selectedOnlineShopFilter"
            @input="selectedOnlineShopFilter = $event"
          />
        </div>
        <div class="products">
          <OnlineShopProductCard
            v-for="product in filteredOnlineShopProducts"
            v-show="product.stock > 0"
            :key="product._id"
            class="product"
            :product="product"
            @add="addToCart(product)"
            @remove="removeFromCart(product)"
          />
          <div v-if="!filteredOnlineShopProducts.length" class="none">
            <h2>No products to display.</h2>
            <p>Please try searching with a different value.</p>
          </div>
        </div>
      </div>
      <div class="salon_booking_giftCards" v-else>
        <PurchaseGiftCard />
      </div>

      <!-- Reviews -->
      <section class="reviews" ref="reviews" v-if="reviews.length">
        <div class="reviews_head">
          <h3>Reviews</h3>
          <div class="reviews_head_summary">
            <i
              v-for="i in 5"
              :key="i"
              class="fa-star"
              :class="reviewsAverage >= i ? 'fas' : 'far'"
            ></i>
            <p class="light">{{ reviews.length }} Reviews</p>
          </div>
          <div class="reviews_head_verified">
            <div class="col">
              <i class="fas fa-user-shield"></i>
            </div>
            <div class="col">
              <p class="bold">Verified Reviews</p>
              <p class="small">
                All reviews are from actual clients following verified visits
              </p>
            </div>
          </div>
        </div>

        <ul class="reviews_reviews">
          <li
            v-for="review in displayedReviews"
            :key="review._id"
            class="reviews_reviews_review"
          >
            <div class="client">
              <p v-if="review.client" class="name">
                {{ `${review.client.firstName} ${review.client.lastName}` }}
              </p>
              <p v-else class="name">Anonymous</p>
              <p class="light small">{{ formatDate(review.created_at) }}</p>
            </div>
            <div class="rating">
              <i
                v-for="i in 5"
                :key="i"
                class="fa-star"
                :class="review.rating >= i ? 'fas' : 'far'"
              ></i>
            </div>
            <div class="review">
              {{ review.review }}
            </div>
          </li>
        </ul>

        <Pagination
          v-if="reviews.length > reviewsLimit"
          :limit="reviewsLimit"
          :page="reviewsPage"
          :data="reviews"
          @nextPage="reviewsPage !== maxReviewsPage ? reviewsPage++ : null"
          @prevPage="reviewsPage !== 1 ? reviewsPage-- : null"
        />
      </section>

      <!-- Gallery -->
      <div
        v-if="salon.bookingGallery && salon.bookingGallery.length"
        ref="gallery"
        class="gallery"
      >
        <BookingGallery
          :gallery="salon.bookingGallery"
          @select="selectedGalleryImage = $event"
        />
      </div>

      <!-- Selected Gallery Image -->
      <Modal
        :show="selectedGalleryImage"
        @close="selectedGalleryImage = null"
        title="Gallery Image"
      >
        <div class="selectedImage">
          <img :src="selectedGalleryImage" />
        </div>
      </Modal>

      <!-- Cart -->
      <router-link :to="{ name: 'OnlineShopCheckout' }">
        <div v-if="cart.length" class="cartFixed">
          <i class="fas fa-cart-plus"></i>
          <p>{{ cart.length }} item<span v-if="cart.length > 1">s</span></p>
        </div>
      </router-link>
    </div>
  </div>

  <Modal
    title="Special Offer!"
    maxWidth="800px"
    :show="offerToShow && !closedOffer"
    @close="
      offerToShow = null
      // closedOffer = true;
    "
  >
    <div class="offerModal">
      <MarketingOffer
        :offer="offerToShow"
        @yes="applyOffer"
        @no="
          offerToShow = null;
          closedOffer = true;
        "
      />
    </div>
  </Modal>
</template>

<script>
import BookingCategoriesSlider from '@/components/booking/BookingCategoriesSlider.vue';
import BookingServiceItem from '@/components/booking/BookingServiceItem.vue';
import BookingMemberItem from '@/components/booking/BookingMemberItem.vue';
import SalonLogo from '@/components/booking/SalonLogo.vue';
import SalonHours from '@/components/booking/SalonHours.vue';
import OnlineShopProductCard from '@/components/booking/OnlineShopProductCard.vue';
import PurchaseGiftCard from '@/components/booking/PurchaseGiftCard.vue';
import BookingGallery from '@/components/booking/BookingGallery.vue';
import MarketingOffer from '@/components/booking/MarketingOffer.vue';

export default {
  components: {
    BookingServiceItem,
    BookingMemberItem,
    SalonLogo,
    SalonHours,
    OnlineShopProductCard,
    PurchaseGiftCard,
    BookingCategoriesSlider,
    BookingGallery,
    MarketingOffer,
  },
  async created() {
    this.$store.state.booking.currentBookingTracking = null;

    this.$store.dispatch('sockets/join', this.$route.params.id);

    try {
      this.$store.state.booking.salon = await this.$store.dispatch(
        'auth/getSalon',
        this.$route.params.id
      );

      this.reviews = await this.$store.dispatch(
        'reviews/getReviews',
        this.$route.params.id
      );

      this.reviews = this.reviews.filter((review) => !review.hidden).reverse();

      this.getReviewsAvg();
    } catch (error) {
      this.$toast.error(error.message);

      if (!this.$store.state.booking.salon) {
        if (this.$store.state.booking.activeClient) {
          this.$router.push({ name: 'ClientProfile' });
        } else {
          this.$router.push({ name: 'ClientAuth' });
        }
      }
    }

    // Booking Marketing
    if (this.offers.length) {
      if (!this.coupon) {
        const landingOffer = this.offers.find(
          (offer) => offer.onPage === 'landing'
        );

        if (landingOffer) {
          setTimeout(() => {
            this.checkOfferRules(landingOffer);
          }, 5000);
        }
      }
    }

    // Queries
    if (this.$route.query.mrkref) {
      this.$store.state.booking.mrkref = this.$route.query.mrkref;
    }
    if (this.$route.query.cId) {
      this.$toast.info('Your coupon has been automatically applied');
      this.$store.state.booking.coupon = this.$route.query.cId;
    }
  },
  data() {
    return {
      selectedTab: 'services',
      reviews: [],
      reviewsAverage: 0,
      showMoreActions: false,

      selectedOnlineShopFilter: 'featured',
      onlineShopSearchValue: '',

      filter: '',
      categoryFilter: '',

      selectedGalleryImage: null,

      reviewsPage: 1,
      reviewsLimit: 5,

      offerToShow: null,
      closedOffer: false,
    };
  },
  computed: {
    salonId() {
      if (!this.$store.state.booking.salon) return;

      return this.$store.state.booking.salon._id;
    },
    salon() {
      if (!this.$store.state.booking.salon) return;

      return this.$store.state.booking.salon;
    },
    coupon() {
      return this.$store.state.booking.coupon;
    },
    offers() {
      if (!this.salon) return [];

      return this.salon.bookingMarketingOffers.filter((offer) => offer.enabled);
    },
    salonDetails() {
      if (!this.$store.state.booking.salon) return;

      return this.$store.state.booking.salon.details;
    },
    salonSchedule() {
      if (!this.$store.state.booking.salon) return;

      return this.$store.state.booking.salon.schedule.default;
    },
    serviceCategories() {
      let categories = [];

      const serviceCategories = this.$store.getters['booking/calendarServices'];

      if (!this.selectedStaff) {
        categories = serviceCategories.filter(
          (category) => category.items.length !== 0
        );
      } else {
        serviceCategories.forEach((category) => {
          if (
            category.items.find((item) =>
              this.selectedStaff.services.find(
                (service) => service === item._id
              )
            )
          ) {
            categories.push(category);
          }
        });

        categories.forEach((category) => {
          category.items = category.items.filter((item) =>
            this.selectedStaff.services.find((service) => service === item._id)
          );
        });

        categories = categories.filter(
          (category) => category.items.length !== 0
        );
      }

      if (this.filter) {
        categories.forEach((category) => {
          category.items = category.items.filter((item) =>
            item.title.toLowerCase().includes(this.filter.toLowerCase())
          );
        });
      }

      if (this.categoryFilter) {
        categories = categories.filter(
          (category) => category._id === this.categoryFilter
        );
      }

      // Set disabled field if no staff can perform a service
      categories.forEach((category) => {
        category.items.forEach((item) => {
          item.disabled = !this.staffs.find((staff) =>
            staff.services.find((service) => service === item._id)
          );
        });
      });

      return categories;
    },
    staffs() {
      if (!this.$store.state.booking.salon) return;

      return this.$store.state.booking.salon.staff;
    },
    bookingStaffs() {
      if (!this.$store.state.booking.salon) return;

      return this.staffs.filter((staff) => staff.onBooking);
    },
    allowDMs() {
      if (!this.$store.state.booking.salon) return;

      return this.$store.state.booking.salon.bookingSettings.allowClientsToDM;
    },
    cart() {
      return this.$store.state.booking.cart;
    },
    onlineShopProducts() {
      if (!this.$store.state.booking.salon) return [];

      const products = [];

      this.$store.state.booking.salon.products.forEach((category) => {
        category.items.forEach((product) => {
          product.onlineShop ? products.push(product) : null;
        });
      });

      return products;
    },
    filteredOnlineShopProducts() {
      let products = [];

      const allProducts = JSON.parse(JSON.stringify(this.onlineShopProducts));

      if (this.selectedOnlineShopFilter === 'all') {
        products = allProducts;
      } else if (this.selectedOnlineShopFilter === 'featured') {
        products = allProducts.filter((product) => product.featured);
      } else {
        // // TODO: Change product.title to group eventually
        // products = allProducts.filter(
        //   (product) => product._id === this.selectedOnlineShopFilter
        // );
      }

      if (this.onlineShopSearchValue) {
        products = products.filter((product) =>
          product.title
            .toLowerCase()
            .includes(this.onlineShopSearchValue.toLowerCase())
        );
      }

      return products;
    },
    onlineShopSelectOptions() {
      const groups = [
        {
          option: 'Featured',
          value: 'featured',
        },
        {
          option: 'All',
          value: 'all',
        },
      ];

      // this.onlineShopProducts.forEach((product) => {
      //   if (!groups.find((group) => group === product.title)) {
      //     groups.push({ option: product.title, value: product._id });
      //   }
      // });

      return groups;
    },
    displayedReviews() {
      return this.reviews.slice(
        this.reviewsPage * this.reviewsLimit - this.reviewsLimit,
        this.reviewsPage * this.reviewsLimit
      );
    },
    maxReviewsPage() {
      return Math.ceil(this.reviews.length / this.reviewsLimit);
    },
    activeClient() {
      return this.$store.state.booking.activeClient;
    },
  },
  methods: {
    filterByCategory(categoryId) {
      if (this.categoryFilter === categoryId) {
        this.categoryFilter = '';
      } else {
        this.categoryFilter = categoryId;
      }

      setTimeout(() => {
        this.$refs.bookingCategories.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 100);
    },
    async selectService(service) {
      await this.$store.dispatch('booking/selectService', service);

      this.$router.push({
        name: 'BookServices',
        params: { id: this.salonId },
      });

      this.$toast.info(
        'Service selected! Press continue to proceed or select more services.'
      );
    },
    async selectStaff(staff) {
      await this.$store.dispatch('booking/selectStaff', staff);

      this.$router.push({
        name: 'BookServices',
        params: { id: this.salonId },
      });
    },
    scrollToReviews() {
      this.$refs.reviews.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    },
    scrollToGallery() {
      this.$refs.gallery.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    },
    formatDate(date) {
      return this.$moment(date).format('LL');
    },
    getReviewsAvg() {
      let total = 0;

      this.reviews.forEach((review) => (total += review.rating));

      this.reviewsAverage = Math.ceil(total / this.reviews.length);
    },
    addToCart(product) {
      this.$store.state.booking.cart.push(product);
    },
    removeFromCart(product) {
      const index = this.$store.state.booking.cart.findIndex(
        (preproduct) => preproduct._id === product._id
      );

      if (index === -1) return;

      this.$store.state.booking.cart.splice(index, 1);
    },

    applyOffer() {
      this.$store.state.booking.isSalonDiscount = true;
      this.$store.state.booking.coupon = this.offerToShow.discount;
      this.offerToShow = null;

      this.$toast.success('Offer applied!');
    },

    checkOfferRules(offer) {
      // If offer is null, return
      if (!offer) return;

      // If offer required client and no client, return
      if (
        (offer.scenario === 'firstTimeClient' ||
          offer.scenario === 'returningClient') &&
        !this.activeClient
      )
        return;

      // If offer required client to be first time client and they are returning, return
      if (
        offer.scenario === 'firstTimeClient' &&
        this.activeClient.tickets.find(
          (t) => t.salonId.toString() === this.salon._id.toString()
        )
      )
        return;

      // If offer is only for returning clients and they are a first time client, return
      if (
        offer.scenario === 'returningClient' &&
        !this.activeClient.tickets.find(
          (t) => t.salonId.toString() === this.salon._id.toString()
        )
      )
        return;

      // If all rules are met, show offer
      this.offerToShow = offer;
    },
  },
};
</script>

<style lang="scss" scoped>
.salon {
  display: grid;
  align-items: flex-start;
  grid-template-columns: auto 1fr;
  gap: 32px;
  margin-top: 64px;

  h1 {
    font-size: 36px;
  }

  .mt-s {
    margin-top: 8px;
  }

  .alert {
    padding: 12px;
    font-size: 14px;
    border-radius: 5px;
    margin-bottom: 32px;

    &-info {
      background-color: #c3edf7;
      color: #044653;
      border-color: #aceaf7;
    }
    &-warning {
      background-color: var(--clr-warning-light);
      border: 1px solid;
      border-color: var(--clr-warning);
    }
  }

  &_details {
    max-width: 400px;
    position: sticky;
    top: 24px;

    &_actions {
      margin-top: 16px;

      .more {
        margin-top: 16px;
      }

      .messageButton {
        margin-top: 16px;
      }

      button {
        width: 100%;

        &:not(:first-child) {
          margin-top: 16px;
        }

        i {
          margin-left: 16px;
        }
      }
    }

    &_description {
      padding: 16px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      margin-top: 16px;

      p {
        line-height: 24px;
        color: var(--clr-gray);
      }
    }

    &_location-hours {
      margin-top: 32px;

      .location,
      .hours,
      .phone {
        display: flex;
        gap: 16px;

        i {
          color: var(--clr-light);
          margin-right: 16px;
          min-width: 20px;
        }

        .days {
          &_day {
            &:not(:first-child) {
              margin-top: 5px;
            }

            .day {
              min-width: 45px;
            }
            display: flex;
            gap: 16px;
          }
        }
      }

      .hours,
      .location {
        margin-top: 16px;
      }
    }
  }

  .inactive {
    padding: 16px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;
    text-align: center;
    margin-bottom: 32px;
    align-self: flex-start;

    p {
      margin-top: 8px;
    }
  }

  &_booking {
    &_tabs {
      margin-top: 16px;
      display: flex;
      gap: 32px;
      text-align: center;

      p {
        cursor: pointer;
      }

      .selected {
        color: var(--clr-link);
        border-bottom: 1px solid var(--clr-link);
      }
    }

    &_services,
    &_staff,
    &_onlineShop {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 32px;
    }

    &_onlineShop {
      .searchFilter {
        display: flex;
        gap: 16px;

        & > * {
          flex-grow: 1;
        }
      }

      .products {
        .product {
          &:not(:first-child) {
            margin-top: 16px;
          }
        }
      }
    }

    &_giftCards {
      margin-top: 32px;
    }

    .none {
      padding: 16px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      h3 {
        font-size: 20px;
      }

      p {
        margin-top: 8px;
      }
    }

    &_services {
      h2 {
        margin-left: 16px;
      }
      .slider {
        position: sticky;
        top: 5%;
      }

      &_categories {
        margin-top: 16px;

        &:not(:last-child) {
          margin-bottom: 48px;
        }

        &_services {
          border-radius: 25px;
          box-shadow: var(--shadow-large);
          display: flex;
          flex-direction: column;
          margin-top: 16px;
          background-color: white;
          overflow: hidden;

          .service {
            &:hover {
              background-color: var(--clr-white);
            }

            &:not(:last-child) {
              border-bottom: 1px solid var(--clr-light);
            }
          }
        }
      }
    }

    .reviews {
      margin-top: 64px;

      .bold {
        font-weight: 700;
      }
      .light {
        color: var(--clr-gray);
      }
      .small {
        font-size: 14px;
      }

      &_head {
        h3 {
          font-size: 36px;
        }

        &_summary,
        &_verified {
          margin-top: 16px;
        }

        &_summary {
          i {
            font-size: 24px;
            color: var(--clr-warning);

            &:not(:first-child) {
              margin-left: 5px;
            }
          }
          .light {
            margin-top: 8px;
          }
        }

        &_verified {
          padding: 24px 32px;
          border-radius: 5px;
          background-color: var(--clr-white-2);
          display: flex;
          align-items: center;
          gap: 24px;

          .bold {
            margin-bottom: 8px;
          }

          i {
            font-size: 18px;
            color: var(--clr-success);
          }
        }
      }
      &_reviews {
        margin-top: 32px;

        &_review {
          margin-bottom: 24px;

          &:not(:first-child) {
            padding-top: 24px;
            border-top: 1px solid var(--clr-light);
          }

          .client {
            .name {
              font-size: 18px;
            }
            .small {
              margin-top: 5px;
            }
          }
          .rating {
            margin-top: 16px;

            i {
              color: var(--clr-warning);

              &:not(:first-child) {
                margin-left: 5px;
              }
            }
          }
          .review {
            margin-top: 16px;
            line-height: 22px;
          }
        }
      }
    }

    .gallery {
      margin: 84px 0;
    }
  }
}

.selectedImage {
  display: grid;
  place-items: center;
}

.cartFixed {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 16px 24px;
  background-color: var(--clr-secondary);
  color: var(--clr-white);
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 500px;
  animation: scale 3s infinite;
  box-shadow: var(--shadow);
  cursor: pointer;
}

.offerModal {
  padding: 12px;

  .message {
    font-size: 24px;
  }

  &_actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

// Tablet
@media (max-width: 900px) {
  .salon {
    grid-template-columns: 1fr;
    margin-top: 32px;

    &_details {
      justify-self: center;
      position: inherit;
    }

    &_booking {
      &_tabs {
        flex-direction: column;
        gap: 0;
        text-align: left;

        p {
          padding: 16px;

          &:hover {
            background-color: var(--clr-light);
          }
        }
      }

      .selected {
        color: var(--clr-white);
        background-color: var(--clr-secondary);

        &:hover {
          background-color: var(--clr-secondary);
        }
      }
    }
  }
}
</style>
