<template>
  <div class="details">
    <div class="details_tags">
      <Tag
        v-if="isBlacklisted"
        text="Blacklisted"
        backgroundColor="#fbb1b1"
        color="#b91212"
      />
    </div>

    <div class="details_head">
      <Avatar
        mode="large rounded"
        :name="clientName"
        :image="client ? client.profileImage : null"
      />
      <div class="col">
        <h2>{{ clientName }}</h2>
      </div>
    </div>
    <div v-if="event.appointment.note" class="details_note">
      <p>
        "<span>{{ event.appointment.note }}</span
        >"
      </p>
    </div>
    <div class="details_body">
      <div class="vuevents_event_head">
        <div class="col text">
          <p class="times">{{ event.start }} - {{ event.end }}</p>
          <div class="badge">{{ event.appointment.status }}</div>
        </div>
        <div class="icons">
          <i class="fas fa-cloud" v-if="event.appointment.bookedOnline"></i>
          <i
            class="fas fa-badge-dollar"
            v-if="event.appointment.billing.deposits.length"
          ></i>
          <i class="fas fa-star" v-if="event.appointment.status === 'new'"></i>
          <i
            class="fas fa-check"
            v-if="event.appointment.status === 'confirmed'"
          ></i>
          <i
            class="fas fa-check-double"
            v-if="event.appointment.status === 'arrived'"
          ></i>
          <i
            class="fas fa-eye-slash"
            v-if="event.appointment.status === 'no show'"
          ></i>
          <i
            class="fas fa-comment-slash color-red"
            v-if="event.appointment.requestedCancellation"
          ></i>
          <i v-if="event.service.requested" class="fas fa-heart"></i>
        </div>
      </div>
    </div>
    <div class="details_items">
      <ul>
        <li v-for="service in event.appointment.services" :key="service._id">
          <div class="col">
            <p class="bold large">{{ service.service.title }}</p>
            <p v-if="service.staff" class="light">
              With {{ `${service.staff.firstName} ${service.staff.lastName}` }}
            </p>
          </div>
          <div class="col">
            <p class="bold large">${{ service.service.price.toFixed(2) }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    client() {
      return this.event.appointment.client;
    },
    clientName() {
      return this.client
        ? `${this.client.firstName} ${this.client.lastName || ''}`
        : 'No client';
    },
    isBlacklisted() {
      if (!this.client) return false;
      if (!this.client.blacklistedSalons) return false;

      return this.client.blacklistedSalons.includes(
        this.$store.state.auth.salon._id
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  padding: 32px;

  &_tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 16px;
  }
  &_head {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &_note {
    font-style: italic;
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid var(--clr-light);

    p {
      font-size: 48px;
      color: var(--clr-light);
      line-height: 24px;

      span {
        color: var(--clr-black);
        font-size: 16px;
      }
    }
  }
  &_body {
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid var(--clr-light);

    .vuevents_event_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      flex-wrap: wrap;
      position: relative;

      .text {
        display: flex;
        align-items: center;
        gap: 8px;

        .badge {
          background-color: var(--clr-secondary-white);
          border-radius: 500px;
          padding: 4px 16px;
        }
      }

      .icons {
        display: flex;
        align-items: center;
        gap: 16px;
        .color-red {
          color: var(--clr-danger);
        }

        .fa-heart {
          color: var(--clr-danger);
        }
      }
    }
  }
  &_items {
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid var(--clr-light);

    .large {
      font-size: 18px;
    }
    .bold {
      font-weight: 700;
    }
    .light {
      color: var(--clr-gray);
      margin-top: 5px;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 32px;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
</style>
