<template>
  <div class="endpoint_head">
    <h2>Get dejavoo data</h2>

    <div class="route">
      <i
        @click="
          $router.push('/documentation/api/dejavoo#getData');
          $toast.info('Set URL to endpoint');
        "
        :style="{ color: 'var(--clr-secondary)', cursor: 'pointer' }"
        class="fas fa-hashtag"
      ></i>
      <p class="tag tag-blue">GET</p>
      <p class="url">{{ apiUrl }}<span>/salons/:id/billing/dejavoo</span></p>
    </div>

    <div class="description">
      <p>Returns dejavoo info such as merchant ID, terminals, etc..</p>
    </div>
  </div>

  <div class="endpoint_body">
    <div class="request">
      <h3>Request</h3>

      <!-- Headers -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Headers</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">Authorization</p>
              <p class="examples">Examples: <span>Bearer {Token}</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Path queries -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Path Queries</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">id</p>
              <p class="description">ID of salon</p>
              <p class="examples">
                Examples: <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6f</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="response">
      <h3>Response</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">dejavoo</p>
              <p class="examples">
                Examples:
                <span
                  >{ "merchantId": "802223691224", "terminals": [ { "authKey":
                  "Eehugew3Ql", "registerID": "2239002", "_id":
                  "63eaff95e70e98df8603609b" } ], "receiptOption": "Customer" }
                </span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    apiUrl() {
      return process.env.VUE_APP_RASERVA_BACKEND;
    },
    xmlResponse() {
      return `<xmp>
            <response>
              <RefId>2</RefId>
  
              <RegisterId>3</RegisterId>
  
              <TPN>123456789123</TPN>
  
              <ResultCode>0</ResultCode>
  
              <RespMSG>Approved</RespMSG>
  
              <Message>Approved</Message>
  
              <AuthCode>292249</AuthCode>
  
              <PNRef>1807027</PNRef>
  
              <PaymentType>Credit</PaymentType>
  
              <ExtData>
                InvNum=1,CardType=VISA,BatchNum=1,Tip=0.00,
                CashBack=0.00,Fee=0.00,AcntLast4=1234,Name=Dejavoo,
                TotalAmt=1.00,DISC=0.00,SHFee=0.00,EBTFSAvailBalance=,EBTCashAvailBalance=,EntryType=Swipe,TaxCity=0.00,TaxState=0.00,Cust1=,Cust1Value=,Cust2=,Cust2Value=,Cust3=,Cust3Value=,AcntFirst4=4111,TaxAmount=0.00
              </ExtData>
  
              <Sign>Qk2uCAAAAAAAAD4AAAAoAAAACwEAADwAAAABAAEAAAAAAHAIAADED</Sign>
  
              <iPOSToken>
                BoPRWAadfR3cgsjazKpj+y/9CfSK2dzHmMYEt+QJ7MEiEgUQCSIQDmzC
              </iPOSToken>
            </response>
          </xmp>`;
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoint {
  .tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px;
    border-radius: 5px;
    color: white;

    &-blue {
      background-color: var(--clr-secondary);
    }
  }

  &_head {
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }

    .route {
      padding: 8px 16px;
      border-radius: 5px;
      background-color: var(--clr-white-2);
      display: flex;
      align-items: center;
      gap: 16px;

      .url {
        color: var(--clr-gray);

        span {
          color: var(--clr-primary);
          font-weight: 700;
        }
      }
    }

    .description {
      margin-top: 32px;
    }
  }

  &_body {
    .request,
    .response,
    .reqResExamples {
      margin-top: 32px;
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &_section {
      &:not(:first-child) {
        margin-top: 32px;
      }

      .head {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 20px;
          margin-bottom: 16px;
          color: var(--clr-secondary);
        }

        p {
          font-size: 14px;
          color: var(--clr-gray);
        }
      }

      ul {
        .subitem {
          margin-left: 32px;
        }

        li {
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          &:not(:first-child) {
            margin-top: 16px;
          }

          .value {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .bold {
            font-weight: 700;
          }
          .required {
            color: orangered;
          }

          .description {
            margin: 4px 0;
            color: var(--clr-gray);
          }

          .examples {
            span {
              background-color: var(--clr-white-2);
              padding: 4px 8px;
              border-radius: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
