<template>
  <Modal
    show
    :title="title"
    maxWidth="500px"
    :zIndex="zIndex"
    @exitPressed="$emit('deny')"
  >
    <div class="confirm">
      <p>{{ text }}</p>
    </div>
    <div class="confirm_actions">
      <div class="group">
        <BaseButton
          v-if="thirdButton"
          mode="secondary-outline"
          @click="$emit('thirdButtonClick')"
          >{{ thirdButton }}</BaseButton
        >
      </div>

      <div class="group">
        <BaseButton
          v-if="!loading && !hideCancel"
          mode="primary-outline"
          @click="$emit('deny')"
          >Cancel</BaseButton
        >
        <BaseButton
          :mode="confirmMode"
          @click="$emit('confirm')"
          :disabled="loading"
          ><i v-if="loading" class="fas fa-spinner"></i>
          {{ confirmTextOverride || confirmText }}</BaseButton
        >
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  emits: ['confirm', 'deny', 'thirdButtonClick'],
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    delete: {
      type: Boolean,
    },
    zIndex: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    hideCancel: {
      type: Boolean,
    },
    thirdButton: {
      type: String,
    },
    confirmTextOverride: {
      type: String,
    },
  },
  computed: {
    confirmText() {
      return this.delete ? 'Delete' : 'Confirm';
    },
    confirmMode() {
      return this.delete ? 'danger' : 'primary';
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm {
  padding: 32px;

  p {
    font-size: 20px;
  }

  &_actions {
    text-align: right;
    padding: 16px 32px;
    border-top: 1px solid var(--clr-light);

    display: flex;
    justify-content: space-between;
    align-items: center;

    button:not(:first-child) {
      margin-left: 16px;
    }
  }
}
</style>
