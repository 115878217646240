<template>
  <section class="productsList">
    <ul>
      <CategoryListItem
        v-for="(category, index) in categories"
        :key="index"
        :category="category"
        @selectItem="$emit('selectItem', $event)"
        @selectCategory="$emit('selectCategory', $event)"
        @addItemToCategory="$emit('addItemToCategory', $event)"
        @deleteCategory="deleteCategory($event)"
        @moveItem="
          moveItemInCategory(category._id, $event.id, $event.newPosition)
        "
      />
    </ul>
    <div v-if="!categories.length" class="none">
      <h3>No products yet.</h3>
      <p>Press the add button above to get started.</p>
    </div>
  </section>
</template>

<script>
import CategoryListItem from '@/components/items/CategoryListItem.vue';

export default {
  emits: ['selectItem', 'selectCategory'],
  components: {
    CategoryListItem,
  },
  created() {
    this.init();
  },
  watch: {
    categories() {
      this.init();
    },
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      newCategories: [],
    };
  },
  methods: {
    init() {
      if (this.categories)
        this.newCategories = JSON.parse(JSON.stringify(this.categories));
    },
    deleteCategory(category) {
      this.$store.dispatch('items/deleteProductCategory', category);
    },

    async moveItemInCategory(categoryId, itemId, newIndex) {
      const categoryIndex = this.newCategories.findIndex(
        (cat) => cat._id === categoryId
      );

      if (categoryIndex === -1) return;

      const itemIndex = this.newCategories[categoryIndex].items.findIndex(
        (item) => item._id === itemId
      );

      if (itemIndex < newIndex) newIndex--;

      if (itemIndex === newIndex) return;

      const element = this.newCategories[categoryIndex].items.splice(
        itemIndex,
        1
      )[0];

      this.newCategories[categoryIndex].items.splice(newIndex, 0, element);

      try {
        await this.$store.dispatch(
          'items/editProductCategory',
          this.newCategories[categoryIndex]
        );
      } catch (error) {
        console.log(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.none {
  padding: 16px;
  border: 1px solid var(--clr-light);
  border-radius: 5px;
  text-align: center;

  p {
    margin-top: 8px;
  }
}
</style>
