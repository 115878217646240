<template>
  <form @submit.prevent="submit" class="form">
    <BaseInput
      label="Smart Tip Threshold"
      inputType="number"
      :value="newConfig.tipping.usd.smart_tip_threshold"
      subtitle="If pre-tip amount is below threshold, we will use fixed amounts. If above, percent amounts."
    />
    <div class="col-3">
      <BaseInput
        label="Percent Option 1"
        :value="newConfig.tipping.usd.percentages[0]"
        inputType="number"
      />
      <BaseInput
        label="Percent Option 2"
        :value="newConfig.tipping.usd.percentages[1]"
        inputType="number"
      />
      <BaseInput
        label="Percent Option 3"
        :value="newConfig.tipping.usd.percentages[2]"
        inputType="number"
      />
    </div>
    <div class="col-3">
      <BaseInput
        label="Fixed Option 1"
        :value="newConfig.tipping.usd.fixed_amounts[0]"
        inputType="number"
      />
      <BaseInput
        label="Fixed Option 2"
        :value="newConfig.tipping.usd.fixed_amounts[1]"
        inputType="number"
      />
      <BaseInput
        label="Fixed Option 3"
        :value="newConfig.tipping.usd.fixed_amounts[2]"
        inputType="number"
      />
    </div>
    <div class="form_actions">
      <BaseButton
        type="button"
        @click="deleteConfig"
        v-if="newConfig.id"
        mode="danger-outline"
        :disabled="loading"
      >
        <i v-if="loading" class="fas fa-spinner"></i>
        Delete Configuration</BaseButton
      >
      <BaseButton :disabled="loading">
        <i v-if="loading" class="fas fa-spinner"></i>
        Submit</BaseButton
      >
    </div>
  </form>
</template>

<script>
export default {
  props: {
    accountId: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
    },
  },
  created() {
    if (this.config) {
      this.newConfig = JSON.parse(JSON.stringify(this.config));

      if (!this.newConfig.tipping) this.newConfig.tipping = this.tipping;
    }
  },
  computed: {
    tipping() {
      return {
        usd: {
          smart_tip_threshold: 10,
          percentages: [15, 20, 25],
          fixed_amounts: [2, 4, 6],
        },
      };
    },
  },
  data() {
    return {
      loading: false,

      newConfig: {
        tipping: {
          usd: {
            smart_tip_threshold: 10,
            percentages: [15, 20, 25],
            fixed_amounts: [2, 4, 6],
          },
        },
      },
    };
  },
  methods: {
    async submit() {
      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/configs/${this.config.id}?accountId=${this.accountId}`,
          this.newConfig,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$emit('edited', response.data.config);
      } catch (error) {
        this.$toast.error(error.response.data.error);
      }
    },
    async deleteConfig() {
      try {
        const response = await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/configs/${this.config.id}?accountId=${this.accountId}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$emit('deleted', response.data.config);
      } catch (error) {
        this.$toast.error(error.response.data.error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .col-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
  }

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
