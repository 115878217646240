<template>
  <nav class="sidebar">
    <transition name="nav">
      <ul v-if="expanded" class="nav">
        <li class="title" v-if="title">{{ title }}</li>

        <router-link
          v-for="route in routes"
          :key="route"
          :to="{ name: route.linkName }"
          ><li class="route">
            {{ route.name }}
          </li></router-link
        >
      </ul>
    </transition>
    <div class="toggle" @click="toggle" :class="{ 'push-icon': !expanded }">
      <i v-if="expanded" class="fas fa-angle-left"></i>
      <i v-else class="fas fa-angle-right"></i>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    routes: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      expanded: true,
    };
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss" scoped>
.router-link-active {
  li {
    background-color: var(--clr-secondary-white) !important;
  }
}

.sidebar {
  position: relative;
  height: 100%;

  .toggle {
    position: absolute;
    top: 20px;
    right: -15px;
    height: 35px;
    width: 35px;
    border: 1px solid var(--clr-light);
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-size: 20px;
    background-color: white;
    cursor: pointer;
  }

  .push-icon {
    right: -40px;
  }

  .nav {
    height: 100%;
    padding: 32px 7.5px;
    border-right: 1px solid var(--clr-light);
    width: 200px;
    max-width: 40vw;

    .title {
      font-size: 20px;
      font-weight: 700;
      padding: 5px 16px;
    }

    .route {
      cursor: pointer;

      &:hover {
        background-color: var(--clr-extra-light);
      }
    }

    li {
      padding: 12px 16px;
      // background-color: rgb(230, 183, 178);
      border-radius: 5px;
      transition: background-color 0.2s;
    }
  }
}

.nav-enter-active,
.nav-leave-active {
  transition: opacity 0.3s ease;
}

.nav-enter-from,
.nav-leave-to {
  opacity: 0;
}
</style>
