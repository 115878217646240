<template>
  <section class="demo">
    <div v-if="!submitted" class="demo_form">
      <div class="demo_form_head">
        <h2>Book a personalized demo</h2>
        <p>
          We'll answer all of your questions and show you why Raserva is the
          perfect fit for you.
        </p>
      </div>
      <form @submit.prevent="submit" class="demo_form_body">
        <div class="col-2">
          <BaseInput
            label="First Name"
            placeholder="Enter here"
            @input="data.firstName = $event"
          />
          <BaseInput
            label="Last Name"
            placeholder="Enter here"
            @input="data.lastName = $event"
          />
        </div>

        <BaseInput
          label="Email"
          placeholder="Enter your email address"
          @input="data.email = $event"
        />
        <BasePhoneInput
          label="Phone"
          placeholder="Enter your phone number"
          @input="data.phoneNumber = $event"
        />

        <div class="demo_form_body_actions">
          <BaseButton :disabled="loading" mode="rounded secondary"
            ><i v-if="loading" class="fas fa-spinner"></i> Continue</BaseButton
          >
        </div>
      </form>
    </div>

    <div v-else class="submitted">
      <h2>Thank you! ❤️</h2>
      <p>
        Your information has been submitted and we will reach out to you as soon
        as possible.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      data: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      },

      loading: false,
      submitted: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;

      try {
        await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/auth/demo`,
          this.data
        );

        this.submitted = true;
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.demo {
  max-width: 600px;
  box-shadow: var(--shadow-large);

  &_form {
    padding: 64px 32px;
    background-color: white;
    border-radius: 10px;

    &_head {
      text-align: center;

      h2 {
        font-size: 36px;
        color: var(--clr-primary);
      }

      p {
        margin-top: 16px;
        color: var(--clr-gray);
        line-height: 24px;
      }
    }

    &_body {
      margin-top: 64px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .col-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 32px;
      }

      &_actions {
        margin-top: 16px;

        button {
          width: 100%;
        }
      }
    }
  }

  .submitted {
    padding: 64px 32px;
    background-color: white;
    border-radius: 10px;
    text-align: center;

    h2 {
      font-size: 36px;
      color: var(--clr-primary);
    }

    p {
      margin-top: 16px;
      line-height: 24px;
    }
  }
}
</style>
