<template>
  <form @submit.prevent="submit" class="form">
    <div
      v-if="newShift._id && (newShift.created_at || newShift.updated_at)"
      class="head"
    >
      <p v-if="newShift.created_at">
        <span style="font-weight: 700">Created:</span>
        {{ formatDate(newShift.created_at) }}
      </p>
      <p v-if="newShift.updated_at">
        <span style="font-weight: 700">Updated:</span>
        {{ formatDate(newShift.updated_at) }}
      </p>
    </div>
    <div class="col-2">
      <BaseInput
        inputType="date"
        label="Start Date"
        :value="clockInDate"
        @input="clockInDateValue = $event"
      />
      <BaseInput
        inputType="time"
        label="Start Time"
        :value="clockInTime"
        @input="clockInTimeValue = $event"
      />
    </div>
    <div class="col-2">
      <BaseInput
        inputType="date"
        label="End Date"
        :value="clockOutDate"
        @input="clockOutDateValue = $event"
      />
      <BaseInput
        inputType="time"
        label="End Time"
        :value="clockOutTime"
        @input="clockOutTimeValue = $event"
      />
    </div>
    <BaseSelect
      v-if="!newShift._id"
      label="Staff"
      :options="staffSelectOptions"
      @input="selectStaff($event)"
    />
    <BaseInput
      inputType="number"
      step="0.01"
      label="Hourly Rate"
      :value="newShift.rate"
      @input="newShift.rate = +$event"
    />
    <div class="form_actions">
      <BaseButton
        v-if="newShift._id"
        type="button"
        @click="deleteShift"
        mode="danger-outline"
        >Delete</BaseButton
      >
      <BaseButton :disabled="loading"
        ><i v-if="loading" class="fas fa-spinner"></i> Submit</BaseButton
      >
    </div>
  </form>
</template>

<script>
export default {
  emits: ['submitted'],
  props: {
    shift: {
      type: Object,
      required: false,
    },
  },
  created() {
    if (this.shift) {
      this.newShift = JSON.parse(JSON.stringify(this.shift));
    } else {
      this.newShift.clockIn = this.$moment().format('YYYY-MM-DD');
      this.newShift.clockOut = this.$moment().format('YYYY-MM-DD');
    }

    this.clockInDateValue = this.clockInDate;
    this.clockInTimeValue = this.clockInTime;
    this.clockOutDateValue = this.clockOutDate;
    this.clockOutTimeValue = this.clockOutTime;
  },
  computed: {
    staffSelectOptions() {
      const options = [];

      this.$store.state.staff.staff.forEach((prestaff) => {
        options.push({
          option: `${prestaff.firstName} ${prestaff.lastName}`,
          value: prestaff.staffId,
        });
      });

      return options;
    },
    clockInDate() {
      return this.$moment(this.newShift.clockIn).format('YYYY-MM-DD');
    },
    clockInTime() {
      return this.$moment(this.newShift.clockIn).format('HH:mm');
    },
    clockOutDate() {
      return this.$moment(this.newShift.clockOut).format('YYYY-MM-DD');
    },
    clockOutTime() {
      return this.$moment(this.newShift.clockOut).format('HH:mm');
    },
  },
  data() {
    return {
      loading: false,

      clockInDateValue: null,
      clockInTimeValue: null,
      clockOutDateValue: null,
      clockOutTimeValue: null,

      newShift: {
        clockIn: null,
        clockOut: null,
        rate: null,
        staffId: null,
      },
    };
  },
  methods: {
    async deleteShift() {
      try {
        await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/shifts/${this.newShift._id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$emit('submitted');
        this.$toast.success('Shift deleted');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    selectStaff(staffId) {
      this.newShift.staffId = staffId;

      const staff = this.$store.state.staff.staff.find(
        (staff) => staff.staffId === staffId
      );

      if (!staff) return;

      this.newShift.rate = staff.hourlyRate || 0;
    },

    formatDate(date) {
      return this.$moment(date).format('YYYY-MM-DD HH:mm');
    },

    async submit() {
      const [hour, minute] = this.clockInTimeValue.split(':');
      const inTime = this.$moment(this.clockInDateValue)
        .hour(hour)
        .minute(minute);

      const [outHour, outMinute] = this.clockOutTimeValue.split(':');
      const outTime = this.$moment(this.clockOutDateValue)
        .hour(outHour)
        .minute(outMinute);

      if (inTime.isAfter(outTime)) {
        this.$toast.error('End time can not be before start time');
        return;
      }

      this.newShift.clockIn = inTime;
      this.newShift.clockOut = outTime;

      if (!this.newShift.clockIn || !this.newShift.clockOut) return;

      this.loading = true;

      try {
        if (
          this.$moment(this.newShift.clockIn).isAfter(this.newShift.clockOut)
        ) {
          this.$toast.error('End time can not be before start time');
          return;
        }

        if (!this.newShift._id) {
          if (!this.newShift.staffId) {
            this.$toast.error('Staff required');
            return;
          }

          // Create shift
          await this.$axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/shifts`,
            {
              ...this.newShift,
              salonId: this.$store.state.auth.salon._id,
              clockedInBy: `${this.$store.state.auth.loggedInSalonStaff.firstName} ${this.$store.state.auth.loggedInSalonStaff.lastName}`,
            },
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );
        } else {
          // Edit shift
          await this.$axios.put(
            `${process.env.VUE_APP_RASERVA_BACKEND}/shifts/${this.newShift._id}`,
            this.newShift,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );
        }

        this.$emit('submitted');
        this.$toast.success(
          `Shift ${this.newShift._id ? 'updated' : 'created'}`
        );
      } catch (error) {
        console.log(error);
        // this.$toast.error(error.response.data.error);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.head {
  padding: 16px;
  border-radius: 5px;
  background-color: var(--clr-white);
  border: 1px solid var(--clr-light);
  margin-bottom: 16px;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .col-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
