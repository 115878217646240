import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async refund({ rootState, dispatch }, payment) {
      // No processor
      if (!payment.processor) {
        return {
          refunded: true,
          message: '',
        };
      }

      // Stripe
      if (payment.processor === 'stripe') {
        try {
          await axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/refund`,
            {
              salonAccountId: this.stripeAccountId,
              payment_intent: payment.ref,
            },
            {
              headers: {
                Authorization: `Bearer ${rootState.auth.token}`,
              },
            }
          );

          return {
            refunded: true,
            message: '',
          };
        } catch (error) {
          return {
            refunded: false,
            message: error.message,
          };
        }
      }

      // Dejavoo
      if (payment.processor === 'dejavoo') {
        const RRN = payment.ref;

        const SaleAmount = payment.refundAmount
          ? payment.refundAmount.toFixed(2)
          : payment.amount.toString();

        try {
          const response = await axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/dejavoo/voidRefund`,
            {
              // Required
              amount: SaleAmount,
              RRN,
              salonId: rootState.auth.salon._id,
            },
            {
              headers: {
                Authorization: `Bearer ${rootState.auth.token}`,
              },
            }
          );

          const data = response.data.data.iposhpresponse;

          if (
            data.responseCode === 200 ||
            data.responseCode === '200' ||
            data.responseMessege === 'Success'
          ) {
            return {
              refunded: true,
              message: '',
            };
          }
        } catch (error) {
          return {
            refunded: false,
            message:
              error.response.data.error ||
              error.response.data.errResponseMessage ||
              error.message,
          };
        }
      }

      // Charge Anywhere
      if (payment.processor === 'chargeanywhere') {
        const [OriginalGatewayReferenceNumber, OriginalApprovalCode] =
          payment.ref.split(':');

        payment.UniqueId = uuidv4();

        const SaleAmount = payment.refundAmount
          ? payment.refundAmount.toFixed(2)
          : payment.amount.toString();

        try {
          const response = await axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/chargeanywhere/processTransaction`,
            {
              Version: '1.0',
              MerchantId: rootState.auth.salon.billing.chargeanywhere.mid,
              TerminalId: rootState.auth.salon.billing.chargeanywhere.tid,
              Identification:
                rootState.auth.salon.billing.chargeanywhere.identification,
              UniqueId: payment.UniqueId,
              TenderType: '2',
              TransactionType: '3',
              SaleAmount,
              OriginalTransactionType: '1',
              OriginalGatewayReferenceNumber,
              OriginalAmount: payment.amount.toString(),
              OriginalApprovalCode,
              SkipRedis: true,
            },
            {
              headers: {
                Authorization: `Bearer ${rootState.auth.token}`,
              },
            }
          );

          const data = response.data.data;

          console.log(data);

          if (
            data.ResponseText === 'APPROVED' ||
            data.ResponseCode === '000' ||
            data.ResponseCode === '010'
          ) {
            return {
              refunded: true,
              message: '',
            };
          } else {
            const queryResponse = await dispatch('queryTransaction', payment);

            if (
              queryResponse.ResponseText === 'APPROVED' ||
              queryResponse.ResponseCode === '000' ||
              queryResponse.ResponseCode === '010'
            ) {
              return {
                refunded: true,
                message: '',
                data,
              };
            } else {
              return {
                refunded: false,
                message: data.ResponseText || data.TransactionResult,
              };
            }
          }
        } catch (error) {
          return {
            refunded: false,
            message: error.response.data.error,
          };
        }
      }

      // Card Connect
      if (payment.processor === 'cardconnect') {
        try {
          const response = await axios.get(
            `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/cardpointe/inquire?merchantId=${rootState.auth.salon.billing.cardconnect.mid}&retref=${payment.ref}`
          );

          const data = response.data.data;

          if (data.voidable === 'Y') {
            // Void
            await axios.post(
              `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/cardpointe/void?merchantId=${rootState.auth.salon.billing.cardconnect.mid}`,
              {
                retref: payment.ref,
              }
            );

            return {
              refunded: true,
              message: 'Payment voided',
            };
          } else if (data.refundable === 'Y') {
            // Refund
            await axios.post(
              `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/cardpointe/refund?merchantId=${rootState.auth.salon.billing.cardconnect.mid}`,
              {
                retref: payment.ref,
              }
            );

            return {
              refunded: true,
              message: 'Payment refunded',
            };
          } else {
            return {
              refunded: false,
              message: 'Can not void or refund payment',
            };
          }
        } catch (error) {
          return {
            refunded: false,
            message: error.response.data.error,
          };
        }
      }
    },

    async queryTransaction({ rootState }, payment) {
      try {
        if (payment.processor === 'chargeanywhere') {
          const response = await axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/chargeanywhere/queryTransaction`,
            {
              Version: '1.0',
              MerchantId: rootState.auth.salon.billing.chargeanywhere.mid,
              TerminalId: rootState.auth.salon.billing.chargeanywhere.tid,
              Identification:
                rootState.auth.salon.billing.chargeanywhere.identification,
              UniqueId: payment.UniqueId,
            },
            {
              headers: {
                Authorization: `Bearer ${rootState.auth.token}`,
              },
            }
          );

          return response.data.data;
        }
      } catch (error) {
        throw Error(error.message);
      }
    },

    async chargeAnywhereChargeViaToken({ rootState }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/chargeanywhere/chargeViaToken`,
          {
            salonId: rootState.auth.salon._id,
            Amount: payload.amount.toString(),
            TokenNumber: payload.token,
            forceData: payload.forceData,
            pfmid: payload.pfmid,
            pftid: payload.pftid,
            secret: payload.secret,
          }
        );

        const values = response.data.data.split('&');

        const resTextValue = values
          .find((value) => value.includes('ResponseText'))
          .split('=')[1];

        const refNumberValue = values
          .find((value) => value.includes('ReferenceNumber'))
          .split('=')[1];

        const approvalCodeValue = values
          .find((value) => value.includes('ApprovalCode'))
          .split('=')[1];

        if (resTextValue === 'APPROVED') {
          return {
            approved: true,
            message: resTextValue,
            ReferenceNumber: refNumberValue,
            ApprovalCode: approvalCodeValue,
          };
        } else {
          return {
            approved: false,
            message: resTextValue,
          };
        }
      } catch (error) {
        throw Error(error.message);
      }
    },
  },
};
