<template>
  <section class="landing">
    <div class="home_hero">
      <div class="container">
        <LandingHero />
      </div>
    </div>
    <div class="calendar-showcase">
      <div class="container">
        <img src="@/assets/calendar-updated.png" />
      </div>
    </div>
    <div class="home_fees">
      <div class="container">
        <h2><span>All of the tools you need</span> to manage your business</h2>
        <!-- <h2>Extremely low fees <span>on optional processing</span></h2> -->
        <p class="light">
          Raserva allows you to smoothly run day-to-day operations, from
          appointment booking and retail sales to team management and payment
          processing.
        </p>
      </div>
    </div>
    <!-- <div class="home_integrations">
      <div class="container">
        <LandingIntegrations />
      </div>
    </div> -->
    <div class="home_features">
      <div class="container">
        <LandingFeatures />
      </div>
    </div>
    <div class="home_moreFeatures">
      <div class="container">
        <LandingMoreFeatures />
      </div>
    </div>
    <div class="home_FAQ">
      <div class="container">
        <LandingFAQ />
      </div>
    </div>
    <div class="home_demo">
      <div class="container">
        <LandingDemo />
      </div>
    </div>
  </section>
</template>

<script>
import LandingFeatures from '@/components/landing/LandingFeatures.vue';
import LandingHero from '@/components/landing/LandingHero.vue';
// import LandingIntegrations from '@/components/landing/LandingIntegrations.vue';
import LandingMoreFeatures from '@/components/landing/LandingMoreFeatures.vue';
import LandingFAQ from '@/components/landing/LandingFAQ.vue';
import LandingDemo from '@/components/landing/LandingDemo.vue';

export default {
  components: {
    LandingFeatures,
    LandingHero,
    // LandingIntegrations,
    LandingMoreFeatures,
    LandingFAQ,
    LandingDemo,
  },
};
</script>

<style lang="scss" scoped>
.home {
  &_hero {
    background-color: var(--clr-secondary-dark);
    opacity: 0.8;
    background-image: repeating-radial-gradient(
        circle at 0 0,
        transparent 0,
        #0e3a42 10px
      ),
      repeating-linear-gradient(rgba(68, 76, 247, 0.333333), #080a36);

    .container {
      max-width: 1200px;
      padding: 128px 16px;
      margin: auto;
    }
  }

  .buttons {
    button {
      margin-top: 16px;
      width: 100%;
    }
  }

  &_features,
  &_moreFeatures {
    .container {
      max-width: 1400px;
      padding: 64px 16px;
      margin: auto;
    }
  }
  &_integrations,
  &_fees {
    padding: 64px 16px;

    .container {
      max-width: 1400px;
      margin: auto;
    }
  }

  &_fees {
    text-align: center;
    h2 {
      font-size: 48px;

      span {
        color: var(--clr-secondary);
      }
    }
    p {
      margin-top: 16px;
    }
    .link {
      color: var(--clr-link);
      cursor: pointer;
    }
    .light {
      color: var(--clr-gray);
    }
  }

  &_FAQ {
    background-color: var(--clr-white);

    .container {
      max-width: 1000px;
      padding: 64px 16px;
      margin: auto;
    }
  }

  &_demo {
    background-color: var(--clr-secondary);
    display: grid;
    place-items: center;

    .container {
      max-width: 1000px;
      padding: 64px 16px;
      margin: auto;
    }
  }
}

.calendar-showcase {
  .container {
    margin-top: -75px;
    margin-bottom: 75px;
    display: flex;
    justify-content: center;

    img {
      z-index: 2;
      width: 100%;
      max-width: 1000px;
      border-radius: 20px;
      box-shadow: var(--shadow-large);
    }
  }
}
</style>
