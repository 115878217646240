import axios from 'axios';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async calculateEmailBlastCost({ rootState }) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/marketing/emailCost`
        );

        return response.data;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async sendEmailBlast({ rootState }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/marketing/emailBlast`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        return response.data;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
  },
  getters: {},
};
