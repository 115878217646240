<template>
  <section class="selected">
    <h1>{{ `${member.firstName} ${member.lastName}` }}</h1>

    <div class="section">
      <p class="title">Account Information</p>

      <div class="section_data">
        <p>
          Email: <span>{{ member.email }}</span>
        </p>
        <p>
          Created At: <span>{{ formatDate(member.created_at) }}</span>
        </p>
        <p v-if="activeUserHasPerm('team:edit')">
          Permissions:
          <span v-for="(permission, index) in member.permissions" :key="index"
            >{{ permission
            }}<span v-if="index !== member.permissions.length - 1"
              >,
            </span></span
          >
        </p>
      </div>
    </div>

    <div class="section">
      <p class="title">Actions</p>

      <div class="actions">
        <div class="actions_standard">
          <BaseButton
            v-if="activeUserHasPerm('team:edit')"
            @click="$emit('select')"
            >Edit Member</BaseButton
          >

          <!-- <BaseButton mode="primary-outline">Other Action</BaseButton> -->
        </div>

        <div class="actions_danger">
          <BaseButton
            v-if="activeUserHasPerm('team:delete')"
            @click="showConfirmDelete = true"
            mode="danger-outline"
            >Delete Member</BaseButton
          >
        </div>
      </div>
    </div>
  </section>

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Delete team member"
    text="Are you sure you wish to delete this member? Can not be undone."
    @confirm="deleteMember"
    @deny="showConfirmDelete = false"
  />
</template>

<script>
export default {
  emits: ['select', 'deleted'],
  props: {
    member: {
      type: Object,
      required: true,
    },
  },
  computed: {
    activeAdmin() {
      return this.$store.state.admin.admin;
    },
  },
  data() {
    return {
      showConfirmDelete: false,
    };
  },
  methods: {
    formatDate(date) {
      return this.$moment(date).format('LLL');
    },

    async deleteMember() {
      try {
        this.$store.dispatch('admin/deleteOne', this.member);

        this.$emit('deleted');
      } catch (e) {
        console.log(e);
      }
    },

    activeUserHasPerm(perm) {
      if (!this.activeAdmin) return false;

      return this.activeAdmin.permissions.includes(perm);
    },
  },
};
</script>

<style lang="scss" scoped>
.selected {
  padding: 32px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid var(--clr-light);

  h1 {
    font-size: 24px;
    font-weight: 400;
  }

  .section {
    margin-top: 32px;
    .title {
      font-size: 14px;
      color: var(--clr-gray);
      margin-bottom: 16px;
    }

    .section_data {
      padding: 16px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      margin-top: 16px;

      p {
        &:not(:first-child) {
          margin-top: 8px;
        }
      }

      span {
        font-weight: 700;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;

    &_standard,
    &_danger {
      display: flex;
      gap: 16px;
    }
  }
}

// Mobile
@media (max-width: 600px) {
  .actions {
    flex-direction: column;

    &_standard,
    &_danger {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &_danger {
      margin-top: 16px;
    }
  }
}
</style>
