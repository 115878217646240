<template>
  <section class="gallery">
    <h3>Gallery</h3>

    <div class="gallery_images">
      <div
        v-for="image in gallery"
        :key="image"
        @click="$emit('select', image)"
        class="gallery_images_image"
      >
        <img :src="image" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  emits: ['select'],
  props: {
    gallery: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  h3 {
    margin-bottom: 32px;
    font-size: 36px;
  }

  .gallery_images {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 32px;

    &_image {
      height: 200px;
      width: 200px;
      padding: 12px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      cursor: pointer;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

// Tablet
@media (max-width: 900px) {
  .gallery {
    justify-items: center;
    .gallery_images {
      grid-template-columns: 1fr 1fr;
    }
  }
}

// Mobile
@media (max-width: 600px) {
  .gallery {
    .gallery_images {
      grid-template-columns: 1fr;
    }
  }
}
</style>
