<template>
  <section class="doc">
    <div class="doc_head">
      <h2>Team Members</h2>
      <p class="light small">
        In this guide we will learn how to add, edit, and remove team members
        to/from your salon.
      </p>
    </div>
    <div class="doc_body">
      <p class="section">
        The team members page can be navigated to by selecting the
        <i class="fas fa-users mx"></i> icon on the sidebar. To get started,
        lets adding our first team member.
      </p>
      <div class="section">
        <h4>1) Adding A Team Member</h4>
        <p class="section">
          We can add a team member by selecting the "Add Member" button at the
          top of our page. Here you will need to provide basic information such
          as name, account code (used when being clocked in by another team
          member and other cases), group (used to organize team members on your
          calendar page), email and password (used for logging in to their
          personal Raserva account), and more. You will have the option to allow
          this team member to accept online booking, as well as the option to
          have them display on the calendar at all (establishment receptionists
          and/or managers that do not provide services in most cases). You will
          have the option to select which
          <router-link :to="{ name: 'DocsServices' }" class="link"
            >Services</router-link
          >
          this team member can provide. If you are using online booking
          features, it is critical that the exact services each respective
          technician can perform are selected so that the establishment can
          avoid a negative customer experience that can result from a lack of
          staff availability for the customer's requested service.
        </p>
        <p class="section">
          <span class="italic">IMPORTANT!</span> In the next section, the
          "Commission" section, you will be able to set your new team members
          commission percentage. Commission is calculated with this value at the
          time of ticket creation, if you update this value at a later time than
          when a ticket was checked out, the ticket will not update its
          commission value. Additionally, in this section, we have the ability
          to set an option named "Track Hourly." If you need to keep track of
          when a team member clocks in and out, enable this option. If
          enabled,you will have the option to set an hourly rate, any value is
          acceptable here including zero.
        </p>
        <p class="section">
          The final basic option is the new team member's
          <router-link :to="{ name: 'DocsSettings' }"
            ><span class="link">Permissions</span></router-link
          >
          role. Please make sure this option is selected appropriately as a team
          member with higher than intended role will have access to perform
          actions and data that may be sensitive to your establishment.
        </p>
        <p class="section">
          Upon selecting "Submit", if all data entered is valid, your new team
          member will be added.
        </p>
      </div>
      <div class="section">
        <h4>2) Editing Team Members</h4>
        <p class="section">
          From the "Team Members" page we can edit an existing team member by
          selecting any team member frm the listed team members. This form will
          be nearly identical to adding a new team member, but with the existing
          data pre-filled. By default, you cannot update a team members email or
          password, as that is their personal Raserva account data, and they
          will need to login to update this information on their own. However,
          each team member can allow admins to edit this information by granding
          the admin permission, within their personal account settings.
        </p>
        <p class="section">
          To edit a team member's working hours, we will navigate to "Working
          hours" on the team members page navigation bar. Here you will see all
          team members and their working hours. You may navigate through
          calendar dates using the date selection bar above member working
          hours. To edit a team member's hours you will select the time slot bar
          located under each date, or select the
          <i class="fas fa-plus mx"></i> icon if they do not currently have any
          data under that date. Working hours may be split into multiple time
          slots (this can be due to a break in the members schedule), this is
          done by selecting "Add Another Shift". You can set the new data
          entered to repeat weekly for this team member, or select "Don't
          Repeat" if this is a one-time schedule change. Weekly shifts will
          display as a darker blue than temporary shifts.
        </p>

        <p class="section">
          The ability to quickly set time off to a specific team member (or
          close the location for a set period time) is available. To do this, we
          must select the button next to the date selection bar titled,
          "Vacation." Raserva will prompt you to specify 1) the team member
          requesting time off and 2) the Start Date and End Date. Once these
          items are satisfied, you would select "Submit" to set the desired
          vacation time. All team members can be selected for a specific date,
          to help you quickly close your books for holidays.
        </p>
      </div>
      <div class="section">
        <h4>3) Removing Team Members</h4>
        <p class="section">
          The action of removing a team member can be treated as a permanant
          action or as a temporary revokation of access to the system.
        </p>
        <p class="section">
          A) Deletion: To remove a team member from your establishment, simply
          select the team member, scroll down to the bottom of the team member
          form and select "Delete Member." You will need to confirm this action.
          If you select "Delete Member", the team member's Raserva account will
          not be deleted, however the establishment's account will not be able
          to access the team member's data. In this case, the team member's
          Raserva account may be added back to an establishment they were
          removed from, and their previous data will be recovered (if the team
          member accepts the invitation back to the establishment. We recommend
          that this action is taken only once the employee is fully compensated
          and both parties have officially moved in separate directions.
        </p>
        <p class="section">
          B) Revokation: To revoke a team member from your establishment, simply
          select the team member, scroll down to the bottom of the team member
          form and activate the tab next "Revoke Access." The advantage of
          revoking a team member is that the establishment gains the security
          advantages of the team member's loss of access to the platform.
          However, the establishment can still pull the respective team member's
          sales and commission data, for the purpose of final payroll.
          Additionally, if the team member and establishment find a path
          forward, this is the simplest way to go forward and reestablish the
          team member for future business.
        </p>
      </div>
      <div class="section">
        <h4>4) Additional Miscellaneous Items</h4>
        <p class="section">
          In the main Team Member's page, you will find additional quality of
          life features to help you manage this important aspect of your
          business.
        </p>

        <p class="section">
          "Show Deleted" is a button that is visible when the establishment has
          revoked a team member's access. This will display a temporary addition
          to the member's list where revoked members can be managed for the
          purpose of readding or completely deleting them, depending on the
          owner's management preferences.
        </p>

        <p class="section">
          "Invite" allows the establishment to send an invitation to a
          prospective team member with an existing Raserva account. This will
          send an invitation that can be accepted by the team member by
          authentication into their account, navigation to their profile page,
          and selection of "Accept." Once the team member accepts the
          invitation, the establishment will need to edit the team member's
          information as it is relevant to the new establishment.
        </p>

        <p class="section">
          The ability to reorder the way team members display is available. To
          do so, select the <i class="fas fa-bars mx"></i> icon, and drag the
          team member to your desired position in the team member list.
        </p>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.doc {
  padding-bottom: 64px;

  p {
    line-height: 24px;
  }
  .light {
    margin-top: 8px;
    color: var(--clr-gray);
  }
  .small {
    font-size: 14px;
  }
  .link {
    text-decoration: underline;
    color: var(--clr-link);
    cursor: pointer;
  }
  .mx {
    margin: 0px 8px 0px 8px;
  }
  .italic {
    font-style: italic;
  }

  &_head {
    h2 {
      font-size: 32px;
    }
  }

  &_body {
    margin-top: 32px;

    .section:not(:first-child) {
      margin-top: 16px;
    }

    .next {
      margin-top: 32px;

      h3 {
        margin-bottom: 16px;
      }

      li:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
}
</style>
