<template>
  <ul class="calendarStaffOptions">
    <li>
      <BaseButton @click="toggleAvailability">Toggle Availability</BaseButton>
    </li>
    <li>
      <BaseButton @click="$emit('showAddTicket')">Create Ticket</BaseButton>
    </li>
    <li v-if="alertsEnabled">
      <BaseButton :disabled="alertLoading" @click="alertMember"
        ><i v-if="alertLoading" class="fas fa-spinner"></i> Alert
        Member</BaseButton
      >
    </li>
    <li>
      <BaseButton @click="$emit('selectFilterOption', staff.staffId)"
        >View On Calendar</BaseButton
      >
    </li>
    <li v-if="canEditAdditionalTurns || managerMode">
      <BaseButton @click="editAddTurnsLogic">Edit Additional Turns</BaseButton>
    </li>
    <li v-if="showGetStaffAccountForAddTurns">
      <form
        @submit.prevent="submitStaffAccountForAddTurns"
        class="form addTurnsStaffCode"
      >
        <BaseInput
          label="Account Code"
          inputType="password"
          @input="staffAccountForAddTurnsValue = $event"
        />
        <div class="form_actions">
          <BaseButton>Submit</BaseButton>
        </div>
      </form>
    </li>
    <li v-if="staff.additionalTurnsLogs && staff.additionalTurnsLogs.length">
      <BaseButton @click="showAddTurnLogs = !showAddTurnLogs"
        >View Additional Turns</BaseButton
      >
    </li>
    <li v-if="showAddTurnLogs">
      <ul class="turnLogs">
        <li v-for="log in staff.additionalTurnsLogs" :key="log._id">
          <p class="small light">{{ formatDate(log.date) }}</p>
          <p class="text">
            {{ log.changeAmount > 0 ? '+' : '' }}{{ log.changeAmount }}
            <span v-if="log.note">"{{ log.note }}"</span>
          </p>
          <p v-if="log.staff" class="small light text">{{ log.staff }}</p>
        </li>
      </ul>
    </li>
    <li v-if="managerMode || loggedInStaffIsAdmin">
      <BaseButton @click="navToStaffSales">View Reports</BaseButton>
    </li>
    <li>
      <BaseButton @click="showCapableServices = true"
        >View Capable Services</BaseButton
      >
    </li>
  </ul>

  <Modal
    title="Additional Turns"
    @close="showAdditionalTurns = false"
    v-if="showAdditionalTurns"
    :show="showAdditionalTurns"
    maxWidth="300px"
  >
    <form @submit.prevent="editAdditionalTurns" class="form addTurnsForm">
      <BaseInput
        :value="staff.additionalTurns"
        @input="addTurnsValue = +$event"
        inputType="number"
        step="0.5"
        ref="addTurnsValue"
      />
      <BaseTextarea class="mt-s" label="Note" @input="addTurnsNote = $event" />
      <div class="form_actions">
        <BaseButton :disabled="addTurnsValueLoading"
          ><i v-if="addTurnsValueLoading" class="fas fa-spinner"></i>
          Submit</BaseButton
        >
      </div>
    </form>
  </Modal>

  <Modal
    title="Select services"
    maxWidth="600px"
    :show="showCapableServices"
    @close="showCapableServices = false"
  >
    <StaffCapableServices
      :staffServices="staff.services"
      :staffCustomCommissions="staff.customCommissions"
      :staffCustomLevels="staff.customServiceLevels"
      :staffDefaultServiceLevel="staff.defaultServiceLevel"
      :staffMode="true"
      :submitable="false"
    />
  </Modal>
</template>

<script>
import StaffCapableServices from '@/components/staff/StaffCapableServices.vue';

export default {
  props: {
    staff: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'showAddTicket',
    'deselectStaff',
    'selectFilterOption',
    'updateCalendar',
    'setTurns',
  ],
  components: {
    StaffCapableServices,
  },
  created() {
    this.setPermissions();
  },
  computed: {
    alertsEnabled() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.adminSettings.enableAlerts;
    },
    managerMode() {
      return this.$store.state.auth.managerMode;
    },
    tempUser() {
      return this.$store.state.auth.tempUser;
    },
    loggedInStaffIsAdmin() {
      return this.$store.getters['auth/loggedInStaffIsAdmin'];
    },
  },
  watch: {
    managerMode() {
      this.setPermissions();
    },
    tempUser() {
      this.setPermissions();
    },
  },
  data() {
    return {
      alertLoading: false,

      canEditAdditionalTurns: false,
      staffForTurnsValue: '',

      showAdditionalTurns: false,
      addTurnsValueLoading: false,
      addTurnsValue: 0,
      addTurnsNote: '',
      showAddTurnLogs: false,
      showGetStaffAccountForAddTurns: false,
      staffAccountForAddTurnsValue: '',
      showCapableServices: false,
    };
  },
  methods: {
    async setPermissions() {
      this.canEditAdditionalTurns = await this.$store.dispatch(
        'auth/activeUserHasPermission',
        'tickets/editAdditionalTurns'
      );
    },

    async toggleAvailability() {
      try {
        const response = await this.$store.dispatch(
          'staff/toggleAvailability',
          this.staff
        );

        this.$toast.success(response);
        this.$emit('deselectStaff');
        this.showCalendarStaffOptions = false;
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async alertMember() {
      this.alertLoading = true;

      try {
        await this.$store.dispatch('staff/alertMember', {
          to: this.staff.phoneNumber,
        });

        this.$toast.success('Alert sent');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.alertLoading = false;
    },

    async editAddTurnsLogic() {
      if (
        !this.managerMode &&
        !this.loggedInStaffIsAdmin &&
        (await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'requireCode/editAdditionalTurns'
        ))
      ) {
        this.staffForTurnsValue = null;
        this.showGetStaffAccountForAddTurns = true;
      } else {
        this.showCalendarStaffOptions = false;
        this.showAdditionalTurns = true;
      }
    },

    async submitStaffAccountForAddTurns() {
      const staff = this.$store.state.staff.staff.find(
        (staff) => staff.accountCode === this.staffAccountForAddTurnsValue
      );

      if (this.staffAccountForAddTurnsValue) {
        if (
          this.$store.state.auth.salon.permissions.tickets.permissions
            .editAdditionalTurns[staff.role]
        ) {
          this.staffForTurnsValue = staff;
          this.showCalendarStaffOptions = false;
          this.showAdditionalTurns = true;
          this.showGetStaffAccountForAddTurns = false;
        } else {
          this.staffForTurnsValue = null;
          this.$toast.error('Not permitted to perform action');
        }
      } else {
        this.staffForTurnsValue = null;
        this.$toast.error('Staff not found');
      }
    },

    async editAdditionalTurns() {
      this.addTurnsValueLoading = true;

      const newStaff = JSON.parse(JSON.stringify(this.staff));

      const log = {
        changeAmount: this.addTurnsValue - newStaff.additionalTurns,
        note: this.addTurnsNote,
        staff: this.staffForTurnsValue
          ? `${this.staffForTurnsValue.firstName} ${this.staffForTurnsValue.lastName}`
          : null,
      };

      if (!newStaff.additionalTurnsLogs) newStaff.additionalTurnsLogs = [];

      if (
        newStaff.additionalTurnsLogs &&
        newStaff.additionalTurnsLogs.length > 10
      ) {
        newStaff.additionalTurnsLogs.shift();
      }

      newStaff.additionalTurnsLogs.push(log);

      try {
        this.$emit('setTurns', this.addTurnsValue);

        await this.$store.dispatch('staff/editStaff', {
          ...newStaff,
          additionalTurns: this.addTurnsValue,
        });

        this.$emit('updateCalendar');
        this.addTurnsNote = '';
        this.showAdditionalTurns = false;
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.addTurnsValueLoading = false;
    },

    navToStaffSales() {
      this.$router.push({
        name: 'StaffSales',
        query: {
          staff: this.staff._id,
          bypassFirst: true,
        },
      });
    },

    formatDate(date) {
      return this.$moment(date).format('LLL');
    },
  },
};
</script>

<style lang="scss" scoped>
.calendarStaffOptions {
  padding: 32px;

  li {
    button {
      width: 100%;
    }

    &:not(:first-child) {
      margin-top: 16px;
    }
  }
}

.turnLogs {
  .small {
    font-size: 12px;
  }
  .light {
    color: var(--clr-gray);
  }
  .text {
    margin-top: 5px;
  }

  li {
    padding: 16px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;
    background-color: var(--clr-white);

    &:not(:first-child) {
      margin-top: 16px;
    }
  }
}

.addTurnsForm {
  padding: 32px;

  .mt-s {
    margin-top: 16px;
  }

  .form_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
.addTurnsStaffCode {
  .form_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
