<template>
  <div class="member">
    <div class="col ava">
      <Avatar
        mode="rounded large"
        :name="staffName"
        :image="staff.profileImage"
      />
      <div class="text">
        <p class="bold">{{ staffName }}</p>
        <p class="gray">{{ staff.title }}</p>
      </div>
    </div>
    <div class="col">
      <p class="link bold">Book Now</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    staff: {
      type: Object,
      required: true,
    },
  },
  computed: {
    staffName() {
      return `${this.staff.firstName} ${this.staff.lastName}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.member {
  padding: 24px;
  border: 1px solid var(--clr-light);
  border-radius: 5px;
  display: flex;
  gap: 32px;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.2s;
  background-color: white;

  &:hover {
    border-color: var(--clr-link);
  }

  .ava {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .bold {
    font-weight: 700;
  }
  .link {
    color: var(--clr-link);
  }
  .gray {
    margin-top: 5px;
    color: var(--clr-gray);
    font-size: 14px;
  }
}

// Tablet
@media (max-width: 900px) {
  .member {
    flex-direction: column;
  }
}
</style>
