<template>
  <div class="invoice" v-if="ticket" ref="invoice">
    <div class="invoice_head">
      <h3>{{ salon.shopName }}</h3>
      <p class="address">
        {{
          `${address.street}, ${address.city}, ${address.postal}, ${address.state}`
        }}
      </p>
      <p class="invoice">Ticket: {{ ticket.number }}</p>
      <p class="date">{{ formatDate(ticket.created_at) }}</p>
    </div>
    <div class="invoice_items">
      <div
        v-for="item in ticket.items"
        :key="item._id"
        class="invoice_items_item"
      >
        <div class="col">
          <p class="item">{{ item.item.title }} ({{ item.quantity }})</p>
          <p v-if="item.staff" class="staff">
            With {{ `${item.staff.firstName} ${item.staff.lastName}` }}
          </p>
        </div>
        <div v-if="!item.item.basePrice" class="col col-right">
          {{
            formatCurrency(
              (item.item.price - (item.item.discountAmount || 0)) *
                item.quantity
            )
          }}
          <p class="prepaid" v-if="item.item.prepaid">(Prepaid)</p>
        </div>
        <div v-else class="col col-right">
          {{
            formatCurrency(
              (item.item.basePrice +
                (item.item.cashDiscountAdditionalProcessingFee || 0) -
                (item.item.discountAmount || 0)) *
                item.quantity
            )
          }}
          <p class="prepaid" v-if="item.item.prepaid">(Prepaid)</p>
        </div>
      </div>
    </div>
    <div class="invoice_summary">
      <div class="section subtotal">
        <p>Subtotal</p>
        <p>{{ formatCurrency(subtotal + cardSurchage) }}</p>
      </div>
      <!-- <div class="section surcharge" v-if="usesCashDiscount && cardSurchage">
        <p>Convienence Fee</p>
        <p>{{ formatCurrency(cardSurchage) }}</p>
      </div> -->
      <div class="section tax">
        <p>Tax</p>
        <p>{{ formatCurrency(tax) }}</p>
      </div>
      <div class="section tax">
        <p>Tip</p>
        <p>{{ formatCurrency(tip) }}</p>
      </div>
      <div class="section total">
        <p>Total</p>
        <p>{{ formatCurrency(total) }}</p>
      </div>
    </div>
    <div v-if="ticket.payments.length" class="invoice_payments">
      <div
        v-for="payment in ticket.payments"
        :key="payment._id"
        class="invoice_payments_payment"
      >
        <div class="col">
          <p>{{ capFirstLetter(payment.type) }}</p>
          <p class="date">{{ formatDate(payment.date) }}</p>
        </div>
        <div class="col">
          <p>{{ formatCurrency(payment.amount) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    usesCashDiscount() {
      return this.$store.state.auth.salon.payments.useCashDiscount;
    },
    salon() {
      return (
        this.ticket.salonId.details || this.$store.state.auth.salon.details
      );
    },
    address() {
      return this.salon.address;
    },
    cardSurchage() {
      if (!this.ticket.items.length) {
        return 0;
      }

      let total = 0;
      this.ticket.items.forEach((item) => {
        if (item.item.prepaid) return;

        total +=
          (item.item.cashDiscountAdditionalProcessingFee || 0) * item.quantity;
      });

      return total;
    },
    subtotal() {
      if (!this.ticket.items.length) {
        return 0;
      }

      let total = 0;
      this.ticket.items.forEach((item) => {
        if (item.item.prepaid) return;

        if (item.item.basePrice) {
          total +=
            (item.item.basePrice - (item.item.discountAmount || 0)) *
            item.quantity;
        } else {
          total +=
            (item.item.price -
              (item.item.discountAmount || 0) -
              (item.item.cashDiscountAdditionalProcessingFee || 0)) *
            item.quantity;
        }
      });

      return total;
    },
    tax() {
      if (!this.ticket.items.length) {
        return 0;
      }

      let tax = 0;
      this.ticket.items.forEach((item) => {
        if (item.item.prepaid) return;

        tax += item.item.price * (item.item.taxRate * 0.01) * item.quantity;
      });

      return tax;
    },
    tip() {
      let amount = 0;

      this.ticket.tips.forEach((tip) => (amount += tip.amount));

      return amount;
    },
    total() {
      return this.subtotal + this.tax + this.tip + this.cardSurchage;
    },
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate(date) {
      return this.$moment(date).format('LLL');
    },
    formatCurrency(amount) {
      if (typeof amount !== 'number') return;

      return `$${amount.toFixed(2)}`;
    },
    capFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.invoice {
  &_head {
    text-align: center;
    margin-bottom: 32px;

    h3 {
      margin-bottom: 8px;
    }

    .address {
      margin-bottom: 32px;
    }

    .invoice {
      font-weight: 700;
      margin-bottom: 8px;
    }
  }
  &_items,
  &_payments {
    padding: 24px 0;
    border-top: 1px solid var(--clr-light);

    &_item,
    &_payment {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      align-items: center;

      &:not(:first-child) {
        margin-top: 16px;
      }

      .staff,
      .date,
      .prepaid {
        font-size: 14px;
        color: var(--clr-gray);
      }

      .col-right {
        text-align: right;
      }
    }
  }
  &_summary {
    .section {
      border-top: 1px solid var(--clr-light);
      padding: 16px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .total {
      font-weight: 700;
    }
  }
}
</style>
