<template>
  <section class="logs">
    <div class="logs_head">
      <h2>Cleaning Logs</h2>
      <p>View/manage recorded cleaning logs</p>
    </div>

    <div class="logs_actions">
      <BaseButton @click="showCreateForm = true">Create Log</BaseButton>
    </div>

    <div class="logs_body">
      <Pagination
        :limit="limit"
        :page="page"
        :data="logs"
        @nextPage="nextPage"
        @prevPage="prevPage"
      />

      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Staff</th>
            <th class="hide-tablet">Client</th>
            <th>Chair</th>
            <th>Status</th>
            <th class="hide-tablet">Note</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="log in logs" :key="log._id" @click="selectedLog = log">
            <td>{{ formatDate(log.created_at) }}</td>
            <td>
              {{
                log.staff
                  ? `${log.staff?.firstName} ${log.staff?.lastName}`
                  : ''
              }}
            </td>
            <td class="hide-tablet">
              {{
                log.client
                  ? `${log.client.firstName} ${log.client.lastName}`
                  : ''
              }}
            </td>
            <td>{{ log.chair }}</td>
            <td
              :class="log.status === 'completed' ? 'text-green' : 'text-yellow'"
            >
              {{ log.status }}
            </td>
            <td class="hide-tablet">
              {{ log.note ? log.note.substr(0, 9) : '' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <Pagination
      :limit="limit"
      :page="page"
      :data="logs"
      @nextPage="nextPage"
      @prevPage="prevPage"
    />
  </section>

  <Modal
    title="Cleaning Log Form"
    :show="showCreateForm || selectedLog"
    @close="
      showCreateForm = false;
      selectedLog = null;
    "
  >
    <div class="logForm">
      <BaseSelect
        label="Staff"
        :options="staffForSelect"
        :value="
          selectedLog
            ? selectedLog.staff
              ? selectedLog.staff.staffId
              : ''
            : ''
        "
        @input="setLogStaff($event)"
      />
      <BaseSelect
        label="Status"
        :options="statusOptions"
        :value="selectedLog ? selectedLog.status : 'completed'"
        @input="
          selectedLog ? (selectedLog.status = $event) : (newLog.status = $event)
        "
      />
      <BaseInput
        label="Chair"
        :value="selectedLog ? selectedLog.chair : ''"
        @input="
          selectedLog ? (selectedLog.chair = $event) : (newLog.chair = $event)
        "
      />
      <BaseTextarea
        label="Note"
        placeholder="Optional Note"
        @input="
          selectedLog ? (selectedLog.note = $event) : (newLog.note = $event)
        "
      />
      <div class="logForm_actions">
        <BaseButton
          v-if="selectedLog && selectedLog._id"
          mode="danger-outline"
          @click="deleteLog"
          >Delete Log</BaseButton
        >
        <BaseButton @click="submit">Submit</BaseButton>
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  created() {
    this.init();
  },
  computed: {
    staffForSelect() {
      let staffs = JSON.parse(JSON.stringify(this.$store.state.staff.staff));

      staffs.sort((a, b) => a.firstName.localeCompare(b.firstName));

      staffs = staffs
        .filter((staff) => !staff.isDeleted)
        .map((staff) => {
          return {
            option: `${staff.firstName} ${staff.lastName}`,
            value: staff.staffId,
          };
        });

      staffs.unshift({
        option: 'No staff',
        value: null,
      });

      return staffs;
    },
    statusOptions() {
      return [
        {
          option: 'Completed',
          value: 'completed',
        },
        {
          option: 'Pending',
          value: 'pending',
        },
      ];
    },
  },
  data() {
    return {
      logs: [],
      selectedLog: null,
      page: 1,
      limit: 25,
      showCreateForm: false,
      newLog: {
        salonId: null,
        staff: null,
        chair: '',
        status: 'completed',
        note: '',
      },
    };
  },
  methods: {
    async init() {
      this.getLogs();
    },
    formatDate(date) {
      return this.$moment(date).format('LLL');
    },
    async getLogs() {
      const response = await this.$axios.get(
        `${process.env.VUE_APP_RASERVA_BACKEND}/cleaningLogs?salonId=${this.$store.state.auth.salon._id}&page=${this.page}&limit=${this.limit}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.auth.token}`,
          },
        }
      );

      this.logs = response.data;
    },
    async prevPage() {
      this.page--;
      this.getLogs();
    },
    async nextPage() {
      this.page++;
      this.getLogs();
    },

    setLogStaff(staffId) {
      const existingStaff = this.$store.state.staff.staff.find(
        (s) => s.staffId === staffId
      );

      if (!existingStaff) return;

      this.selectedLog
        ? (this.selectedLog.staff = existingStaff)
        : (this.newLog.staff = existingStaff);
    },

    async deleteLog() {
      try {
        await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/cleaningLogs/${this.selectedLog._id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.logs = this.logs.filter((log) => log._id !== this.selectedLog._id);

        this.$toast.success('Cleaning log deleted');
        this.selectedLog = null;
        this.showCreateForm = false;
      } catch (e) {
        this.$toast.error('Error deleting cleaning log');
      }
    },

    async submit() {
      try {
        // Update
        if (this.selectedLog) {
          if (!this.selectedLog.staff) {
            this.$toast.error('Please select a staff member');
            return;
          }
          if (!this.selectedLog.chair) {
            this.$toast.error('Please provide a chair number');
            return;
          }

          await this.$axios.put(
            `${process.env.VUE_APP_RASERVA_BACKEND}/cleaningLogs/${this.selectedLog._id}`,
            this.selectedLog,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );

          this.$toast.success('Cleaning log updated');
          this.showCreateForm = false;
          this.selectedLog = null;
        } else {
          // Create
          if (!this.newLog.staff) {
            this.$toast.error('Please select a staff member');
            return;
          }
          if (!this.newLog.chair) {
            this.$toast.error('Please provide a chair number');
            return;
          }

          await this.$axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/cleaningLogs`,
            {
              ...this.newLog,
              salonId: this.$store.state.auth.salon._id,
            },
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );

          this.logs.unshift({
            ...this.newLog,
            salonId: this.$store.state.auth.salon._id,
          });

          this.$toast.success('Cleaning log created');
          this.showCreateForm = false;
          this.selectedLog = null;
        }
      } catch (err) {
        this.$toast.error(err.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logs {
  width: 100%;
  max-width: 800px;

  &_head {
    h2 {
      font-size: 28px;
    }
    p {
      margin-top: 8px;
      color: var(--clr-gray);
    }
  }

  &_actions {
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-extra-light);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 32px;
  }

  &_body {
    margin-top: 32px;
  }
}

.text-green {
  color: var(--clr-success);
}
.text-yellow {
  color: var(--clr-warning);
}

.logForm {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &_actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
