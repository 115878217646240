<template>
  <div class="pagination">
    <i
      v-if="page !== 1"
      class="fas fa-chevron-left"
      @click="$emit('prevPage')"
    ></i>

    <p class="page">{{ page }}</p>

    <i
      v-if="data.length >= limit"
      class="fas fa-chevron-right"
      @click="$emit('nextPage')"
    ></i>
  </div>
</template>

<script>
export default {
  emits: ['nextPage', 'prevPage'],
  props: {
    page: {
      type: Number,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  margin-top: 32px;
  padding-bottom: 32px;
  text-align: right;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;

  i,
  p {
    cursor: pointer;
  }

  i {
    color: var(--clr-gray);
  }
  .page {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: var(--clr-light);
    display: grid;
    place-items: center;
  }
}
</style>
