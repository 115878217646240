<template>
  <section class="doc">
    <div class="doc_head">
      <h2>Products</h2>
      <p class="light small">
        In this guide we will learn how to add, edit, and remove products
        to/from your salon.
      </p>
    </div>
    <div class="doc_body">
      <p class="section">
        The products page can be navigated to by selecting the
        <i class="fas fa-hand-sparkles mx"></i> icon on the sidebar. To get
        started, lets create our first product.
      </p>
      <div class="section">
        <h4>1) Adding A Product</h4>
        <p class="section">
          Products must be parented by a "Category". Categories help us organize
          our products by similarity, and will assist clients/team members when
          selecting a product. To create a category select the "Add" dropdown
          button and select "New Category". You need only to fill out a few
          values to continue. Once a category has been created, we can add a
          product to the new category by selecting the
          <i class="fas fa-ellipsis-h mx"></i> icon and finally by pressing "Add
          New Item". Alternatively you may select the "Add" dropdown button at
          the top and select "New Product", to which you would then need to
          select the appropriate category afterwards.
        </p>
        <p class="section">
          Here you will see the product form. We will go over the values from
          top to bottom. To start, you will need to enter a product title,
          select the category, and optionally add a description.
        </p>
        <p class="section">
          Next we will add pricing values, products can have a "Retail Price"
          and optionally a "Sale Price". You can also set the tax rate of a
          product individually if needed. Products can have a commission which
          can be flat (a fixed value in dollars), or percentage (a percentage of
          the product retail price). Raserva can also keep track of the stock
          inventory, this will be updated upon ticket checkout if a product is
          checked out.
        </p>
      </div>
      <div class="section">
        <h4>2) Editing/Deleting Products</h4>
        <p class="section">
          You can edit a category or a product by selecting them from the
          "Products" page. This form will be nearly identical to the adding a
          product/category form. To delete a product, scroll to the bottom of
          the product form and select "Delete Product". This action will need to
          be confirmed. To delete a category, you must first remove or move
          every item to a new category. These actions are irreversable.
        </p>
      </div>
      <ul class="section next">
        <li>
          <h3>See Also:</h3>
        </li>
        <router-link :to="{ name: 'DocsServices' }">
          <li>
            <p class="link">Services</p>
          </li>
        </router-link>
      </ul>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.doc {
  padding-bottom: 64px;

  p {
    line-height: 24px;
  }
  .light {
    margin-top: 8px;
    color: var(--clr-gray);
  }
  .small {
    font-size: 14px;
  }
  .link {
    text-decoration: underline;
    color: var(--clr-link);
    cursor: pointer;
  }
  .mx {
    margin: 0px 8px 0px 8px;
  }
  .italic {
    font-style: italic;
  }

  &_head {
    h2 {
      font-size: 32px;
    }
  }

  &_body {
    margin-top: 32px;

    .section:not(:first-child) {
      margin-top: 16px;
    }

    .next {
      margin-top: 32px;

      h3 {
        margin-bottom: 16px;
      }

      li:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
}
</style>
