<template>
  <section class="profile" v-if="client">
    <div class="profile_basicInfo section">
      <div class="head">
        <Avatar mode="extra-large rounded" :name="client.firstName" />
        <h2>{{ `${client.firstName} ${client.lastName}` }}</h2>
        <p @click="showClientForm = true" class="link">Edit info</p>
      </div>
      <div class="body">
        <div class="group">
          <p class="title">First name</p>
          <p>{{ client.firstName }}</p>
        </div>
        <div class="group">
          <p class="title">Last name</p>
          <p>{{ client.lastName }}</p>
        </div>
        <div class="group">
          <p class="title">Phone number</p>
          <p v-if="client.phoneNumber">{{ client.phoneNumber }}</p>
          <p @click="showClientForm = true" v-else class="link">+ Add</p>
        </div>
        <div class="group">
          <p class="title">Email address</p>
          <p v-if="client.email">{{ client.email }}</p>
          <p @click="showClientForm = true" v-else class="link">+ Add</p>
        </div>
        <div class="group">
          <p class="title">Date of birth</p>
          <p v-if="client.dateOfBirth">
            {{
              `${client.dateOfBirth.year}-${client.dateOfBirth.month + 1}-${
                client.dateOfBirth.date
              }`
            }}
          </p>
          <p @click="showClientForm = true" v-else class="link">+ Add</p>
        </div>
        <div class="group">
          <p class="title">Gender</p>
          <p v-if="client.gender">{{ client.gender }}</p>
          <p @click="showClientForm = true" v-else class="link">+ Add</p>
        </div>
      </div>
    </div>
    <div class="profile_otherInfo">
      <!-- Quick Nav -->
      <div v-if="client.salons.length" class="quickNav section">
        <div class="head">
          <h3>Your Salons</h3>
          <p>Quickly navigate to a salon's booking page</p>
        </div>
        <div class="body">
          <ClientSalonsNav :salons="client.salons" />
        </div>
      </div>

      <!-- Messages -->
      <div v-if="client.messages.length" class="messages section">
        <div class="head">
          <h3>Direct Messages</h3>
          <p>View messages between you and a salon</p>
        </div>

        <div class="body">
          <div class="table">
            <Table
              :headers="messageHeaders"
              :data="messageData"
              @rowClick="selectMessage($event)"
            />
          </div>
        </div>
      </div>

      <!-- Upcoming Appointments -->
      <div class="appointments section">
        <div class="head">
          <h3>Upcoming Appointments</h3>
          <p>View upcoming appointments with all salons</p>
          <p class="subtext">
            Click an appointment for more options such as requesting
            cancellation
          </p>
        </div>

        <div class="body">
          <div v-if="!upcomingAppointments.length" class="none">
            <p class="italic">You have no upcoming appointments!</p>
          </div>

          <div v-else class="table">
            <Table
              :headers="appointmentHeaders"
              :data="appointmentData"
              @rowClick="selectAppointment($event)"
            />
          </div>
        </div>
      </div>

      <!-- Gift Cards -->
      <div v-if="client.giftcards.length" class="giftcards section">
        <div class="head">
          <h3>Gift Cards</h3>
          <p>View all of your gift cards to all salons with Raserva</p>
        </div>
        <div class="body">
          <ClientGiftCards :giftcards="client.giftcards" />
        </div>
      </div>

      <!-- Packages -->
      <div v-if="client.packages.length" class="packages section">
        <div class="head">
          <h3>Packages</h3>
          <p>View all of your packages to all salons with Raserva</p>
        </div>
        <div class="body">
          <ClientPackages :packages="client.packages" />
        </div>
      </div>

      <!-- Subscriptions -->
      <div v-if="client.subscriptions.length" class="subscriptions section">
        <div class="head">
          <h3>Subscriptions</h3>
          <p>View all of your subscriptions to all salons with Raserva</p>
        </div>
        <div class="body">
          <ClientSubscriptions :subscriptions="client.subscriptions" />
        </div>
      </div>

      <!-- Loyalty -->
      <div v-if="client.loyalty.length" class="loyalty section">
        <div class="head">
          <h3>Loyalty Points</h3>
          <p>
            View all of your redeemable loyalty points to all salons with
            Raserva
          </p>
        </div>
        <div class="body">
          <Table
            :headers="['Salon', 'Points']"
            :data="[
              ...client.loyalty.map((loyalty) => [
                getSalonNameFromId(loyalty.salonId),
                loyalty.points,
              ]),
            ]"
            @rowClick="selectLoyaltyMenu($event)"
          />
        </div>
      </div>

      <!-- Notifications -->
      <div class="notifications section">
        <div class="head">
          <h3>Notification settings</h3>
          <p>
            Manage how you receive notifications about appointments and more.
          </p>
        </div>
        <div class="body">
          <div class="toggle">
            <Toggle
              :checked="notifications.email"
              @toggle="notifications.email = !notifications.email"
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Receive Email Notifications</p>
            </div>
          </div>
          <div class="toggle">
            <Toggle
              :checked="notifications.text"
              @toggle="notifications.text = !notifications.text"
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Receive Text Notifications</p>
            </div>
          </div>
          <div class="toggle">
            <Toggle
              :checked="notifications.marketing"
              @toggle="notifications.marketing = !notifications.marketing"
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Receive Marketing Notifications</p>
            </div>
          </div>
          <div class="actions">
            <BaseButton @click="editClient" mode="primary-outline"
              >Save Settings</BaseButton
            >
          </div>
        </div>
      </div>
    </div>
  </section>

  <Modal
    title="Edit basic info"
    :show="showClientForm"
    @close="showClientForm = false"
  >
    <div class="form">
      <ClientForm
        :client="client"
        basic
        @created="
          $store.state.booking.activeClient = $event;
          showClientForm = false;
        "
        @updated="
          updatedClient($event);
          showClientForm = false;
        "
      />
    </div>
  </Modal>

  <Modal
    title="Appointment Actions"
    :show="selectedAppointment"
    @close="selectedAppointment = null"
    maxWidth="300px"
  >
    <ul class="selectedAppActions">
      <li>
        <BaseButton
          @click="requestCancellation"
          :disabled="
            cancellationLoading ||
            selectedAppointment.appStatus === 'Requested Cancellation'
          "
          mode="danger-outline"
          ><i v-if="cancellationLoading" class="fas fa-spinner"></i> Request
          Cancellation</BaseButton
        >
      </li>
    </ul>
  </Modal>

  <Modal
    title="Loyalty Menu"
    :show="selectedLoyaltyMenu"
    @close="selectedLoyaltyMenu = null"
  >
    <ul class="loyaltyMenu">
      <li
        v-for="redemption in selectedLoyaltyMenu.filter((i) => i.public)"
        :key="redemption._id"
      >
        <h3>{{ redemption.item.title }}</h3>
        <p>Original price: ${{ redemption.item.price.toFixed(2) }}</p>
        <p>Points required: {{ redemption.cost }}</p>
      </li>
    </ul>
  </Modal>
</template>

<script>
import ClientForm from '@/components/clients/ClientForm.vue';
import ClientGiftCards from '@/components/clients/ClientGiftCards.vue';
import ClientSalonsNav from '@/components/clients/ClientSalonsNav.vue';
import ClientPackages from '@/components/clients/ClientPackages.vue';
import ClientSubscriptions from '@/components/clients/ClientSubscriptions.vue';

export default {
  components: {
    ClientForm,
    ClientGiftCards,
    ClientPackages,
    ClientSubscriptions,
    ClientSalonsNav,
  },
  created() {
    if (!this.client) return;

    this.init();
  },
  computed: {
    client() {
      if (!this.$store.state.booking.activeClient) return;

      return this.$store.state.booking.activeClient;
    },
    appointmentHeaders() {
      return ['Salon', 'Item(s)', 'Technician', 'Date', 'Status'];
    },
    messageHeaders() {
      return ['Salon', 'Last Message From', 'Last Message Date'];
    },
  },
  data() {
    return {
      showClientForm: false,
      notifications: {
        email: true,
        text: true,
        marketing: true,
      },

      upcomingAppointments: [],
      upcomingAppointmentIds: [],
      appointmentData: [],

      messageData: [],

      selectedAppointment: null,
      selectedAppointmentSalonId: null,

      cancellationLoading: false,

      selectedLoyaltyMenu: null,
    };
  },
  watch: {
    client(val) {
      if (!val) {
        this.$router.push({ name: 'ClientAuth' });
        return;
      }
      this.init();
    },
  },
  methods: {
    init() {
      this.notifications = { ...this.client.notifications };
      this.appointmentData = [];
      this.getUpcomingAppointments();
      this.setMessageData();
    },
    async logout() {
      await this.$store.dispatch('auth/clientLogout');
      this.$router.push({ name: 'ClientAuth' });
    },
    async editClient() {
      try {
        const newClient = {
          ...this.client,
          notifications: this.notifications,
        };

        const client = await this.$store.dispatch('clients/editClient', {
          _id: newClient._id,
          notifications: newClient.notifications,
        });

        this.$store.state.booking.activeClient.notifications.email =
          client.notifications.email;
        this.$store.state.booking.activeClient.notifications.text =
          client.notifications.text;
        this.$store.state.booking.activeClient.notifications.marketing =
          client.notifications.marketing;
        this.$toast.success('Settings updated');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    setMessageData() {
      this.client.messages.forEach((message) => {
        const lastMessage = message.messages[message.messages.length - 1];

        this.messageData.push({
          salon: message.salon.name,
          lastFrom: lastMessage.author.name,
          lastBy: this.$moment(lastMessage.date).format('lll'),
        });
      });
    },
    getSalonNameFromId(salonId) {
      return this.client.salons.find(
        (s) => s._id.toString() === salonId.toString()
      ).details.shopName;
    },
    selectLoyaltyMenu(loyaltyMenuIndex) {
      const salon = this.client.salons.find(
        (s) =>
          s._id.toString() === this.client.loyalty[loyaltyMenuIndex].salonId
      );

      this.selectedLoyaltyMenu = salon.loyalty.redemptions;
    },
    async getUpcomingAppointments() {
      if (!this.client._id) return;

      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/clients/${this.client._id}/upcomingAppointments`
        );

        this.upcomingAppointments = response.data.appointments;

        this.upcomingAppointments.forEach((appointment) => {
          const [hour, minute] = appointment.services[0].start.split(':');

          this.appointmentData.push({
            appSalon: appointment.salonId.details.shopName,
            appItems:
              appointment.services.length === 1
                ? appointment.services[0].service.title
                : `[${appointment.services.length}]`,
            appTech: `${appointment.services[0].staff.firstName} ${appointment.services[0].staff.lastName}`,
            appDate: this.$moment(appointment.date)
              .hour(hour)
              .minute(minute)
              .format('LLL'),
            appStatus: appointment.requestedCancellation
              ? 'Requested Cancellation'
              : 'Upcoming',
          });

          this.upcomingAppointmentIds.push(
            `${appointment._id}/${appointment.salonId._id}`
          );
        });
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    selectAppointment(index) {
      const [_id, salonId] = this.upcomingAppointmentIds[index].split('/');

      this.selectedAppointment = {
        ...this.appointmentData[index],
        _id,
        salonId,
      };
    },
    selectMessage(index) {
      this.$router.push({
        name: 'ClientMessages',
        query: {
          messageId: this.client.messages[index]._id,
        },
      });
    },
    async requestCancellation() {
      if (!this.selectedAppointment) return;

      this.cancellationLoading = true;

      try {
        this.$store.dispatch(
          'appointments/requestCancellation',
          this.selectedAppointment
        );

        await this.addRequestToSalon();
        this.$toast.info('Appointment cancellation request submitted');

        if (this.$store.state.sockets.socket) {
          this.$store.state.sockets.socket.emit(
            'appointmentCancellationRequestSubmitted',
            this.selectedAppointment._id
          );
        }

        this.selectedAppointment = null;

        this.init();
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.cancellationLoading = false;
    },
    async addRequestToSalon() {
      try {
        await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.selectedAppointment.salonId}/appointments/cancelRequest`,
          {
            type: 'appointment',
            message: `${this.client.firstName} ${this.client.lastName} requested to cancel their appointment on ${this.selectedAppointment.appDate} with ${this.selectedAppointment.appTech}`,
          }
        );
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    updatedClient(client) {
      this.$store.state.booking.activeClient.firstName = client.firstName;
      this.$store.state.booking.activeClient.lastName = client.lastName;
      this.$store.state.booking.activeClient.phoneNumber = client.phoneNumber;
      this.$store.state.booking.activeClient.dateOfBirth = client.dateOfBirth;
      this.$store.state.booking.activeClient.gender = client.gender;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 1400px;
  margin: auto;
}
.section {
  border-radius: 5px;
  box-shadow: var(--shadow);
  background-color: white;
}
.link {
  color: var(--clr-link);
  cursor: pointer;
}
.italic {
  font-style: italic;
}
.toggle {
  display: flex;
  align-items: center;
  gap: 32px;
  flex-wrap: wrap;
  padding: 16px 0;

  &_text {
    &_label {
      font-size: 18px;
    }
    &_subtext {
      margin-top: 5px;
      font-size: 14px;
    }
  }
}

.profile {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: flex-start;
  gap: 32px;
  max-width: 1000px;
  margin: auto;
  margin-top: 32px;

  &_basicInfo {
    position: sticky;
    top: 20px;

    .head {
      text-align: center;
      padding: 16px;

      h2 {
        margin-top: 32px;
      }
      .link {
        margin-top: 16px;
      }
    }
    .body {
      margin-top: 16px;
      padding-top: 16px;
      border-top: 1px solid var(--clr-light);
      padding: 16px;

      .group {
        &:not(:first-child) {
          margin-top: 16px;
        }

        .title {
          font-weight: 700;
          font-size: 14px;
          margin-bottom: 5px;
        }
      }
    }
  }

  &_otherInfo {
    .section {
      &:not(:first-child) {
        margin-top: 32px;
      }
      .head,
      .body {
        padding: 16px;
      }

      .head {
        p {
          margin-top: 5px;
        }
        .subtext {
          // padding: 8px 16px;
          // border: 1px solid var(--clr-light);
          // border-radius: 5px;
          font-size: 14px;
          font-style: italic;
          color: var(--clr-gray);
        }
      }

      .body {
        border-top: 1px solid var(--clr-light);
      }
    }

    .quickNav {
      .body {
        padding: 16px 32px;
      }
    }

    .giftcards {
      .body {
        background-color: var(--clr-light);
      }
    }

    .notifications {
      .actions {
        margin-top: 16px;
        text-align: right;
      }
    }
  }
}

.form {
  padding: 32px;
}

.selectedAppActions {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.loyaltyMenu {
  padding: 32px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  li {
    text-align: center;
    padding: 32px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;

    h3 {
      margin-bottom: 16px;
    }

    p {
      color: var(--clr-gray);
      margin-top: 8px;
    }
  }
}

// Tablet
@media (max-width: 900px) {
  .profile {
    grid-template-columns: 1fr;

    &_basicInfo {
      position: relative;
    }
  }
}
</style>
