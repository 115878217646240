<template>
  <div class="dates">
    <div class="action left" @click="previous">
      <i class="fas fa-angle-left"></i>
    </div>
    <div
      v-if="!staticMode || selectedTimeOption === 'Day'"
      class="action today"
      :class="{ selected: isToday }"
      @click="today"
    >
      <p>Today</p>
    </div>
    <div class="action dates" @click="toggleDatePicker">
      <p v-if="selectedTimeOption === 'Day'">
        {{ selectedDateString }}
      </p>
      <p v-else>{{ selectedDatesString }}</p>
    </div>
    <div class="datepicker" :style="{ right: right ? '0' : 'inherit' }">
      <DatePicker
        v-if="showDatePicker"
        :selectedDate="selectedDateAsDate"
        :selectedTimeOption="selectedTimeOption"
        :showWeeksOut="showWeeksOut"
        :propWidth="showWeeksOut ? '600px' : '500px'"
        @hide="showDatePicker = false"
        @selectDate="
          setSelectedDate($event);
          showDatePicker = false;
        "
      />
    </div>
    <div class="action right" @click="next">
      <i class="fas fa-angle-right"></i>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/components/DatePicker.vue';

export default {
  emits: [
    'selectDate',
    'updateCalendar',
    'selectToday',
    'setSelectedDate',
    'setSelectedDates',
  ],
  components: {
    DatePicker,
  },
  props: {
    propSelectedTimeOption: {
      type: String,
      default: 'Day',
    },
    propSelectedDate: {
      type: Date,
      default: new Date(Date.now()),
    },
    propSelectedDates: {
      type: Object,
    },
    right: {
      type: Boolean,
      default: false,
    },
    staticMode: {
      type: Boolean,
      default: false,
    },
    showDayOfWeek: {
      type: Boolean,
      default: false,
    },
    showWeeksOut: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedDate: null,
      selectedDates: null,
      selectedTimeOption: null,

      showDatePicker: false,
    };
  },
  computed: {
    isToday() {
      return this.$moment().isSame(this.selectedDate, 'date') &&
        this.selectedTimeOption === 'Day'
        ? true
        : false;
    },
    selectedDateString() {
      return this.showDayOfWeek
        ? this.$moment(this.selectedDate).format('ddd LL')
        : this.$moment(this.selectedDate).format('LL');
    },
    selectedDatesString() {
      return this.showDayOfWeek
        ? `${this.$moment(this.selectedDates.start).format(
            'ddd ll'
          )} - ${this.$moment(this.selectedDates.end).format('ddd ll')}`
        : `${this.$moment(this.selectedDates.start).format(
            'll'
          )} - ${this.$moment(this.selectedDates.end).format('ll')}`;
    },
    selectedDateAsDate() {
      if (this.selectedTimeOption === 'Day') {
        return this.$moment(this.selectedDate).toDate();
      }

      return this.$moment(this.selectedDates.start).toDate();
    },
  },
  created() {
    this.selectedDate = this.propSelectedDate;
    this.selectedDates = this.propSelectedDates;
    this.selectedTimeOption = this.propSelectedTimeOption;
  },
  watch: {
    propSelectedTimeOption(val) {
      this.selectedTimeOption = val;

      this.selectedDate = this.$moment().toDate();
    },
    propSelectedDate(val) {
      this.selectedDate = val;
    },
  },
  methods: {
    previous() {
      if (this.selectedTimeOption === 'Day') {
        this.selectedDate = this.$moment(this.selectedDate).subtract(1, 'day');
        this.$emit('setSelectedDate', this.selectedDate);
      }

      if (this.selectedTimeOption === 'Week') {
        this.selectedDates = {
          start: this.selectedDates.start.subtract(1, 'week').startOf('week'),
          end: this.selectedDates.end
            .subtract(1, 'week')
            .subtract(1, 'day')
            .endOf('week'),
        };
        this.$emit('setSelectedDates', this.selectedDates);
      }

      this.$emit('updateCalendar');
    },
    next() {
      if (this.selectedTimeOption === 'Day') {
        this.selectedDate = this.$moment(this.selectedDate).add(1, 'day');
        this.$emit('setSelectedDate', this.selectedDate);
      }

      if (this.selectedTimeOption === 'Week') {
        this.selectedDates = {
          start: this.selectedDates.start.add(1, 'week').startOf('week'),
          end: this.selectedDates.end.add(1, 'week').endOf('week'),
        };
        this.$emit('setSelectedDates', this.selectedDates);
      }

      this.$emit('updateCalendar');
    },
    today() {
      if (!this.staticMode) {
        this.selectedTimeOption = 'Day';
        this.selectedDate = this.$moment();
      }

      this.$emit('setSelectedDate', this.selectedDate);
      this.$emit('selectToday');

      this.$emit('updateCalendar');
    },
    setSelectedDate(date) {
      if (this.selectedTimeOption === 'Day') {
        this.selectedDate = date;
      }

      if (this.selectedTimeOption === 'Week') {
        this.selectedDates = {
          start: this.$moment(date).startOf('week'),
          end: this.$moment(date).endOf('week'),
        };
        this.$emit('setSelectedDates', this.selectedDates);
      }

      this.$emit('selectDate', date);
    },
    toggleDatePicker() {
      this.showDatePicker = !this.showDatePicker;
    },
  },
};
</script>

<style lang="scss" scoped>
.dates {
  display: flex;
  transition: box-shadow 0.2s;
  position: relative;

  &:hover {
    box-shadow: var(--shadow);
  }

  .selected {
    background-color: var(--clr-light);
  }

  .datepicker {
    position: absolute;
    top: calc(100% + 5px);
    z-index: 5;
  }

  .dates {
    min-width: 200px !important;
  }

  .left {
    border-radius: 5px 0 0 5px;
  }
  .right {
    border-radius: 0 5px 5px 0;
  }

  .action {
    display: grid;
    place-items: center;
    min-width: 40px;
    padding: 10px;
    border: 1px solid var(--clr-light);
    transition: background-color 0.2s;
    cursor: pointer;

    &:hover {
      background-color: var(--clr-extra-light);
    }

    &:not(:last-child) {
      border-right: 0;
    }
  }
}

// Mobile
@media (max-width: 600px) {
  .dates {
    width: 100%;

    .today {
      display: none;
    }
  }
}
</style>
