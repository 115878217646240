<template>
  <section class="marketing">
    <div class="marketing_sidebar">
      <SubSidebar :routes="routes" title="Marketing" />
    </div>
    <div class="marketing_router">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import SubSidebar from '@/components/components/SubSidebar.vue';

export default {
  components: {
    SubSidebar,
  },
  data() {
    return {
      routes: [
        {
          name: 'Auto Campaigns',
          linkName: 'AutoCampaigns',
        },
        {
          name: 'Email Blasts',
          linkName: 'EmailBlasts',
        },
        {
          name: 'Text Marketing',
          linkName: 'TextMarketing',
        },
        {
          name: 'Sent Campaigns',
          linkName: 'Campaigns',
        },
        {
          name: 'Reviews',
          linkName: 'ReviewReviews',
        },
        {
          name: 'Booking Marketing',
          linkName: 'BookingMarketing',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.marketing {
  display: flex;
  height: 100%;

  &_router {
    overflow-y: auto;
    flex-grow: 1;
    padding: 32px;
    display: flex;
    justify-content: center;
  }
}
</style>
