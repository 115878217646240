<template>
  <div class="endpoint_head">
    <h2>Checkout ticket</h2>

    <div class="route">
      <i
        @click="
          $router.push('/documentation/api/tickets#checkout');
          $toast.info('Set URL to endpoint');
        "
        :style="{ color: 'var(--clr-secondary)', cursor: 'pointer' }"
        class="fas fa-hashtag"
      ></i>
      <p class="tag tag-blue">PUT</p>
      <p class="url">{{ apiUrl }}<span>/tickets/:id/checkout</span></p>
    </div>

    <div class="description">
      <p>
        Checkout a ticket (This can be used on a ticket that does not have a
        "_id" as well), use this instead of sending a PUT request to a ticket
        with status 'completed', this will perform other checkout logic such as
        sending review emails, loyalty points, package logic, updating
        inventory, etc..
      </p>
    </div>
  </div>

  <div class="endpoint_body">
    <div class="request">
      <h3>Request</h3>

      <!-- Headers -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Headers</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">Authorization</p>
              <p class="examples">Examples: <span>Bearer {Token}</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Path params -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Path Parameters</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">ID</p>
              <p class="examples">
                Examples: <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6f</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
          <p>application/json</p>
        </div>

        <ul>
          <!-- Salon ID -->
          <li>
            <div class="col value">
              <p class="bold">salonId</p>
              <p class="description">String/ID of the salon</p>
              <p class="examples">
                Examples: <span>1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- Status -->
          <li>
            <div class="col value">
              <p class="bold">status</p>
              <p class="description">
                You almost 100% likely want to send this with "completed", as
                this will actually let the system and salon know this ticket is
                checked out and completed, though in some extremely rare cases
                it is not required
              </p>
              <p class="examples">Examples: <span>completed</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="response">
      <h3>Response</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">ticket</p>
              <p class="examples">
                Examples:
                <span>OBJECT</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <li>
            <div class="col value">
              <p class="bold">created</p>
              <p class="description">
                Will return true if ticket was created and checked out
              </p>
              <p class="examples">
                Examples:
                <span>false</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="reqResExamples">
      <h3>Examples</h3>

      <!-- Request -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Request</h4>
          <p>application/json</p>
        </div>

        <ul>
          <li>
            <p>
              { { "salonId": "622ec883ec2552e0d48ed290", "status": "completed" }
              }
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    apiUrl() {
      return process.env.VUE_APP_RASERVA_BACKEND;
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoint {
  .tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px;
    border-radius: 5px;
    color: white;

    &-blue {
      background-color: var(--clr-secondary);
    }
  }

  &_head {
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }

    .route {
      padding: 8px 16px;
      border-radius: 5px;
      background-color: var(--clr-white-2);
      display: flex;
      align-items: center;
      gap: 16px;

      .url {
        color: var(--clr-gray);

        span {
          color: var(--clr-primary);
          font-weight: 700;
        }
      }
    }

    .description {
      margin-top: 32px;
    }
  }

  &_body {
    .request,
    .response,
    .reqResExamples {
      margin-top: 32px;
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &_section {
      &:not(:first-child) {
        margin-top: 32px;
      }

      .head {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 20px;
          margin-bottom: 16px;
          color: var(--clr-secondary);
        }

        p {
          font-size: 14px;
          color: var(--clr-gray);
        }
      }

      ul {
        .subitem {
          margin-left: 32px;
        }

        li {
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          &:not(:first-child) {
            margin-top: 16px;
          }

          .value {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .bold {
            font-weight: 700;
          }
          .required {
            color: orangered;
          }

          .description {
            margin: 4px 0;
            color: var(--clr-gray);
          }

          .examples {
            span {
              background-color: var(--clr-white-2);
              padding: 4px 8px;
              border-radius: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
