<template>
  <h2 v-if="title">{{ title }}</h2>
  <div class="test">
    <table>
      <thead>
        <tr class="header">
          <th v-for="(header, i0) in headers" :key="i0">{{ header }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, i1) in data"
          :key="i1"
          @click="$emit('rowClick', i1)"
          :class="{ bold: boldRow(i1) }"
        >
          <td
            v-for="(value, i2, i3) in row"
            :key="i2"
            :class="{ bold: boldCol(i3) }"
          >
            <p
              :class="{
                completed: value === 'completed',
                refunded: value === 'refunded',
                progress: value === 'in-progress',
                waiting: value === 'waiting',
              }"
            >
              <i v-if="value === 'completed'" class="fas fa-check"></i>
              <i v-if="value === 'refunded'" class="fas fa-undo-alt"></i>
              <i v-if="value === 'in-progress'" class="far fa-clock"></i>
              <i v-if="value === 'waiting'" class="fas fa-pause-circle"></i>
              {{ value }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  emits: ['rowClick'],
  props: {
    title: {
      type: String,
    },
    headers: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    boldRows: {
      type: Array,
      default: () => [],
    },
    boldCols: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    boldRow(index) {
      return this.boldRows.find((row) => row === index);
    },
    boldCol(index) {
      return this.boldCols.find((col) => col === index);
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 20px;
  padding: 24px;
}

.bold {
  font-weight: 700;
}

table {
  tbody {
    td {
      p {
        display: inline;
        padding: 5px 16px;
        border-radius: 50px;

        i {
          font-size: 10px;
          margin-right: 8px;
        }
      }

      .completed {
        font-size: 12px;
        background-color: var(--clr-success);
        color: var(--clr-success-light);
      }
      .refunded {
        font-size: 12px;
        background-color: var(--clr-danger);
        color: var(--clr-danger-light);
      }
      .progress {
        font-size: 12px;
        background-color: var(--clr-warning);
        color: var(--clr-warning-light);
      }
      .waiting {
        font-size: 12px;
        background-color: var(--clr-secondary);
        color: var(--clr-white);
      }
    }
  }
}

// Mobile
@media (max-width: 600px) {
  .test {
    max-width: 80vw;
    overflow-x: scroll;
  }
}
</style>
