<template>
  <div class="summary" v-if="ticket.items.length || ticket.tips.length">
    <ul>
      <li>
        <p>Subtotal</p>
        <p>{{ currencyToString(subtotal) }}</p>
      </li>
      <li v-if="usesCashDiscount">
        <p>Non-cash Adjustment</p>
        <p>{{ currencyToString(cardSurcharge) }}</p>
      </li>
      <li>
        <p>Tax</p>
        <p>{{ currencyToString(tax) }}</p>
      </li>
      <li>
        <p>Total</p>
        <p>{{ currencyToString(total) }}</p>
      </li>
      <li class="tips" v-if="ticket.tips.length">
        <div class="tips_tip" v-for="(tip, index) in ticket.tips" :key="tip">
          <p>Tip for {{ `${tip.staff.firstName} ${tip.staff.lastName}` }}</p>
          <p>{{ currencyToString(tip.amount) }}</p>
          <i class="fas fa-times" @click="$emit('removeTip', index)"></i>
        </div>
      </li>
      <li class="payments" v-if="ticket.payments.length">
        <div
          class="payments_payment"
          v-for="(payment, index) in ticket.payments"
          :key="payment"
        >
          <div class="col">
            <div class="menu" @click="$emit('selectPayment', payment)">
              <i class="fas fa-ellipsis-h menu-icon"></i>
            </div>
            <p class="type" :class="{ red: payment.status === 'refunded' }">
              {{ payment.type
              }}<span v-if="payment.processor"> ({{ payment.processor }})</span
              ><span v-if="payment.otherType"> ({{ payment.otherType }})</span>
            </p>
          </div>
          <p :class="{ red: payment.status === 'refunded' }">
            {{ currencyToString(payment.amount) }}
          </p>
          <i
            v-if="isNotCardOrNoProcessor(payment)"
            class="fas fa-times"
            @click="$emit('removePayment', index)"
          ></i>
        </div>
      </li>
      <li class="balance">
        <p>{{ balance >= 0 ? 'Balance' : 'Change Due' }}</p>
        <p>{{ currencyToString(balance) }}</p>
      </li>
      <li v-if="balance < 0" class="summaryActions">
        <BaseButton @click="$emit('providedChange')"
          >Provided Change</BaseButton
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  emits: ['removePayement', 'selectPayment', 'removeTip', 'providedChange'],
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  computed: {
    usesCashDiscount() {
      return this.$store.state.auth.salon.payments.useCashDiscount;
    },
    cardSurcharge() {
      if (!this.usesCashDiscount) return 0;

      if (!this.ticket.items.length) {
        return 0;
      }

      let total = 0;

      this.ticket.items.forEach((item) => {
        if (item.item.prepaid) return;

        total +=
          (item.item.cashDiscountAdditionalProcessingFee || 0) * item.quantity;
      });

      return total;
    },
    subtotal() {
      if (!this.ticket.items.length) {
        return 0;
      }

      let total = 0;

      this.ticket.items.forEach((item) => {
        if (item.item.prepaid) return;

        if (item.item.basePrice) {
          total +=
            (item.item.basePrice - (item.item.discountAmount || 0)) *
            item.quantity *
            (item.item.priceType === 'duration' ? item.item.duration : 1);
        } else {
          total +=
            (item.item.price -
              (item.item.discountAmount || 0) -
              (item.item.cashDiscountAdditionalProcessingFee || 0)) *
            item.quantity *
            (item.item.priceType === 'duration' ? item.item.duration : 1);
        }
      });

      return total;
    },
    total() {
      // We have to add this small amount because 0.075 rounded in Javascript is 0.07 (like wtf dude)
      return this.subtotal + this.tax + this.cardSurcharge + 0.000000001;
    },
    tax() {
      if (!this.ticket.items.length) {
        return 0;
      }

      let tax = 0;
      this.ticket.items.forEach((item) => {
        if (item.item.prepaid) return;

        tax +=
          (item.item.price - (item.item.discountAmount || 0)) *
          (item.item.taxRate * 0.01) *
          item.quantity;
      });

      return tax;
    },
    balance() {
      let balance = this.total;

      // Add
      this.ticket.tips.forEach((tip) => (balance += tip.amount));

      // Subtract
      this.ticket.payments.forEach((payment) => (balance -= payment.amount));

      return +balance.toFixed(2);
    },
  },
  methods: {
    currencyToString(amount) {
      if (typeof amount === 'string') return amount;

      return `$${amount.toFixed(2)}`;
    },
    isNotCardOrNoProcessor(payment) {
      if (
        payment.type !== 'card' ||
        (payment.type === 'card' && payment.processor === null)
      )
        return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.summary {
  ul {
    width: 400px;
    max-width: 80vw;

    .tips,
    .payments {
      display: flex;
      flex-direction: column;

      .tips_tip,
      .payments_payment {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .col {
          display: flex;
          align-items: center;
          gap: 16px;

          .menu {
            position: relative;

            &-icon {
              color: var(--clr-link);
              cursor: pointer;
            }
          }
        }

        &:not(:first-child) {
          margin-top: 16px;
        }
      }

      .fa-times {
        position: absolute;
        top: 3px;
        right: -25px;
        cursor: pointer;
        color: var(--clr-danger);
      }
    }

    .payments {
      .red {
        color: var(--clr-danger) !important;
      }

      .type {
        span {
          color: var(--clr-gray);
        }
      }
    }

    .tips {
      color: var(--clr-link);
      cursor: pointer;
    }

    .balance {
      font-weight: 700;
      font-size: 20px;
    }

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;

      &:not(:first-child) {
        border-top: 1px solid var(--clr-light);
      }
    }
  }
}

.summaryActions {
  display: flex !important;
  justify-content: flex-end !important;
  padding: 16px !important;
}
</style>
