<template>
  <form @submit.prevent="create" class="form">
    <div class="form_section">
      <div class="form_section_head">
        <h3>Basic Info</h3>
      </div>
      <div class="form_section_body">
        <BaseInput
          label="First Name *"
          placeholder="Enter client's first name"
          :value="newClient.firstName"
          :validators="validators.name"
          @input="newClient.firstName = $event"
        />
        <BaseInput
          label="Last Name"
          placeholder="Enter client's last name"
          :value="newClient.lastName"
          :validators="validators.lastName"
          @input="newClient.lastName = $event"
        />
        <BasePhoneInput
          label="Mobile Number *"
          phone
          placeholder="Enter client's mobile number"
          :value="newClient.phoneNumber"
          inputType="number"
          :validators="validators.name"
          @input="newClient.phoneNumber = $event"
        />
        <BaseInput
          label="Email Adress"
          placeholder="Enter client's email adress"
          :value="newClient.email"
          :validators="validators.email"
          @input="newClient.email = $event"
        />
        <BaseSelect
          label="Gender"
          placeholder="Select an option"
          :options="genderOptions"
          :value="newClient.gender"
          @input="newClient.gender = $event"
        />
        <BaseInput
          inputType="date"
          label="Date Of Birth"
          placeholder="Enter client's DOB"
          :value="dateOfBirth"
          @input="inputDateOfBirth = $event"
        />
      </div>
    </div>
    <div class="form_section" v-if="!basic">
      <div class="form_section_head">
        <h3>Notifications</h3>
        <p>
          Choose how this client is kept up to date about their appointments
        </p>
      </div>
      <div class="form_section_body">
        <div class="toggle">
          <Toggle
            :checked="newClient.notifications.email"
            @toggle="
              newClient.notifications.email = !newClient.notifications.email
            "
          />
          <div class="toggle_text">
            <p class="toggle_text_label">Send Email Notifications</p>
          </div>
        </div>
        <div class="toggle">
          <Toggle
            :checked="newClient.notifications.text"
            @toggle="
              newClient.notifications.text = !newClient.notifications.text
            "
          />
          <div class="toggle_text">
            <p class="toggle_text_label">Send Text Notifications</p>
          </div>
        </div>
        <div class="toggle">
          <Toggle
            :checked="newClient.notifications.marketing"
            @toggle="
              newClient.notifications.marketing =
                !newClient.notifications.marketing
            "
          />
          <div class="toggle_text">
            <p class="toggle_text_label">
              Client accepts marketing notifications
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="form_section" v-if="!basic && !newClient._id">
      <div class="form_section_head">
        <h3>Important Client Info</h3>
        <p>Important info only visible to staff</p>
      </div>
      <div class="form_section_body">
        <BaseTextarea
          placeholder="E.g. allergies, etc."
          :value="newClient.note"
          :validators="validators.note"
          @input="newClient.note = $event"
        />
      </div>
    </div>
    <div class="form_actions">
      <BaseButton
        v-if="newClient._id && !newClient.globalClient"
        mode="danger-outline"
        type="button"
        @click="showConfirmDelete = true"
        >Delete Client</BaseButton
      >
      <BaseButton :disabled="!formValid || loading" type="submit"
        ><i v-if="loading" class="fas fa-spinner"></i> Submit</BaseButton
      >
    </div>
  </form>

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Delete client"
    text="Are you sure you wish to delete this client? This action can not be undone."
    @confirm="deleteClient"
    @deny="showConfirmDelete = false"
  />
</template>

<script>
export default {
  emits: ['created', 'updated', 'deleted', 'familyFriendCreated'],
  props: {
    client: {
      type: Object,
    },
    basic: {
      type: Boolean,
      default: false,
    },
    familyFriendParent: {
      type: Object,
    },
  },
  created() {
    if (this.client) this.newClient = JSON.parse(JSON.stringify(this.client));

    if (this.familyFriendParent) {
      if (!this.newClient.familyAndFriends)
        this.newClient.familyAndFriends = [];

      this.newClient.familyAndFriends.push({
        clientId: this.familyFriendParent._id,
        firstName: this.familyFriendParent.firstName,
        lastName: this.familyFriendParent.lastName,
      });
    }
  },
  data() {
    return {
      showConfirmDelete: false,
      loading: false,
      inputDateOfBirth: null,

      newClient: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        gender: null,
        dateOfBirth: {
          month: null,
          date: null,
          year: null,
        },
        notifications: {
          email: true,
          text: true,
          marketing: true,
        },
        note: '',
        notes: [],
        familyAndFriends: [],
      },
    };
  },
  computed: {
    validators() {
      return {
        name: {
          required: true,
          minLength: 2,
          maxLength: 50,
        },
        lastName: {
          maxLength: 50,
        },
        email: {
          email: true,
          maxLength: 50,
        },
        note: {
          maxLength: 1000,
        },
      };
    },
    formValid() {
      if (!this.newClient.firstName || !this.newClient.phoneNumber)
        return false;
      return true;
    },
    genderOptions() {
      return [
        {
          option: 'Female',
          value: 'female',
        },
        {
          option: 'Male',
          value: 'male',
        },
        {
          option: 'Non-binary',
          value: 'non-binary',
        },
        {
          option: 'Prefer not to say',
          value: 'other',
        },
      ];
    },
    dateOfBirth() {
      if (
        !this.newClient ||
        !this.newClient.dateOfBirth ||
        !this.newClient.dateOfBirth.date ||
        !this.newClient.dateOfBirth.year
      )
        return;

      return this.$moment()
        .year(this.newClient.dateOfBirth.year)
        .month(this.newClient.dateOfBirth.month)
        .date(this.newClient.dateOfBirth.date)
        .format('YYYY-MM-DD');
    },
  },
  methods: {
    setDateOfBirth(date) {
      if (!this.newClient.dateOfBirth) {
        this.newClient.dateOfBirth = {
          month: this.$moment(date).month(),
          date: this.$moment(date).date(),
          year: this.$moment(date).year(),
        };
        return;
      }

      this.newClient.dateOfBirth.month = this.$moment(date).month();
      this.newClient.dateOfBirth.date = this.$moment(date).date();
      this.newClient.dateOfBirth.year = this.$moment(date).year();
    },
    async create() {
      this.loading = true;

      if (this.inputDateOfBirth) this.setDateOfBirth(this.inputDateOfBirth);

      if (this.newClient.note) {
        this.newClient.notes.push({
          salonId: this.$store.state.auth.salon._id,
          staff: `${this.$store.state.auth.loggedInSalonStaff.firstName} ${this.$store.state.auth.loggedInSalonStaff.lastName}`,
          note: this.newClient.note,
        });
      }

      try {
        if (this.newClient._id) {
          const client = await this.$store.dispatch('clients/editClient', {
            ...this.newClient,
            salonIdToUpdateFutureAppointments: this.$store.state.auth.salon._id,
          });
          this.updated(client);
          this.loading = false;
          return;
        }

        const client = await this.$store.dispatch(
          'clients/createClient',
          this.newClient
        );

        if (this.familyFriendParent) {
          this.$toast.success('Family/Friend added');
          this.$emit('familyFriendCreated', client);
        } else {
          this.created(client);
        }
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
    updated(client) {
      this.$toast.success('Client updated');
      this.$emit('updated', client);
    },
    created(client) {
      this.$toast.success('Client created');
      this.$emit('created', client);
    },
    deleteClient() {
      this.$store.dispatch('clients/deleteClient', this.newClient);
      this.$toast.success('Client deleted');
      this.$emit('deleted', this.newClient._id);
    },
    isSalonsClient(salonId) {
      return salonId === this.$store.state.auth.salon._id;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  &_section {
    padding: 32px;
    border: 1px solid var(--clr-light-2);
    border-radius: 5px;

    &:not(:first-child) {
      margin-top: 16px;
    }

    &_head {
      margin-bottom: 16px;

      p {
        font-size: 14px;
        margin-top: 5px;
        color: var(--clr-gray);
      }
    }

    .col-2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }

    &_body {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;

      .toggle {
        display: flex;
        align-items: center;
        gap: 32px;
        flex-wrap: wrap;
        padding: 16px 0;

        &_text {
          &_label {
            font-size: 18px;
          }
          &_subtext {
            margin-top: 5px;
            font-size: 14px;
          }
        }
      }
    }
  }

  &_actions {
    margin-top: 16px;
    text-align: right;

    button:not(:first-child) {
      margin-left: 16px;
    }
  }
}
</style>
