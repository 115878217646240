<template>
  <div class="futureApps">
    <div class="futureApps_search">
      <form @submit.prevent="searchFutureApps" class="form">
        <BaseInput
          @input="searchFutureAppsValue = $event"
          placeholder="Client name, phone, email.."
        />
      </form>
    </div>

    <Spinner v-if="loading" />

    <ul v-if="appointments.length" class="futureApps_body">
      <li
        v-for="app in appointments"
        :key="app._id"
        @click="$emit('select', app)"
      >
        <div class="client">
          <Avatar
            :name="app.client.firstName"
            :image="app.client.profileImage"
            mode="rounded large"
          />
          <div class="text">
            <h2>{{ `${app.client.firstName} ${app.client.lastName}` }}</h2>
            <p class="link">{{ app.client.phoneNumber }}</p>
          </div>
        </div>

        <div class="info">
          <p class="bold">{{ formatAppDate(app) }}</p>
          <p>{{ app.services.length }} service(s)</p>
        </div>
      </li>
    </ul>

    <div v-else class="none">
      <h2>No results found</h2>
      <p>Please search another value</p>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';

export default {
  emits: ['select'],
  data() {
    return {
      searchFutureAppsValue: '',
      appointments: [],
      loading: false,
    };
  },
  methods: {
    async searchFutureApps() {
      if (!this.searchFutureAppsValue) return;
      if (this.loading) return;

      this.loading = true;

      try {
        this.appointments = await this.$store.dispatch(
          'appointments/searchFutureAppointments',
          this.searchFutureAppsValue
        );
      } catch (e) {
        this.$toast.error(e.message);
      }

      this.loading = false;
    },

    formatAppDate(app) {
      if (!app.services.length) return moment(app.date).format('LLL');

      const [hour, minute] = app.services[0].start.split(':');

      return moment(app.date).hour(hour).minute(minute).format('LLL');
    },
  },
};
</script>

<style lang="scss" scoped>
.futureApps {
  &_search {
    padding: 32px;
    background-color: var(--clr-light);
  }

  .link {
    color: var(--clr-link);
  }
  .bold {
    font-weight: 700;
  }

  &_body {
    padding: 32px;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      cursor: pointer;

      &:not(:first-child) {
        margin-top: 16px;
      }

      .client {
        display: flex;
        align-items: center;
        gap: 16px;
      }

      .info {
        text-align: right;

        .bold {
          margin-bottom: 5px;
        }
      }
    }
  }
  .none {
    padding: 32px;
    text-align: center;

    p {
      margin-top: 8px;
    }
  }
}
</style>
