<template>
  <div class="admin">
    <AdminHeader />

    <div class="admin_router">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import AdminHeader from './AdminHeader.vue';

export default {
  components: {
    AdminHeader,
  },
  created() {
    if (!this.admin) this.$router.push({ name: 'AdminLogin' });
  },
  computed: {
    admin() {
      return this.$store.state.admin.admin;
    },
  },
};
</script>

<style lang="scss" scoped>
.admin {
  height: 100vh;
  display: flex;
  flex-direction: column;

  &_router {
    flex-grow: 1;
    overflow-y: auto;
  }
}
</style>
