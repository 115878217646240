import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    async getReviews(_, salonId) {
      try {
        if (!salonId) throw Error('Salon ID required');

        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/reviews?salonId=${salonId}`
        );

        return response.data.reviews;
      } catch (error) {
        throw Error(error.message);
      }
    },
    async editReview(_, review) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/reviews/${review._id}`,
          review
        );

        return response.data.review;
      } catch (error) {
        throw Error(error.message);
      }
    },
    async createReview(_, review) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/reviews?salonId=${review.salonId}`,
          review
        );

        return response.data.review;
      } catch (error) {
        throw Error(error.message);
      }
    },
  },
};
