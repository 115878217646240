<template>
  <div class="endpoint_head">
    <h2>Update appointment</h2>

    <div class="route">
      <i
        @click="
          $router.push('/documentation/api/appointments#update');
          $toast.info('Set URL to endpoint');
        "
        :style="{ color: 'var(--clr-secondary)', cursor: 'pointer' }"
        class="fas fa-hashtag"
      ></i>
      <p class="tag tag-blue">PUT</p>
      <p class="url">{{ apiUrl }}<span>/appointments/:id</span></p>
    </div>

    <div class="description">
      <p>Update an appointment</p>
    </div>
  </div>

  <div class="endpoint_body">
    <div class="request">
      <h3>Request</h3>

      <!-- Headers -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Headers</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">Authorization</p>
              <p class="examples">Examples: <span>Bearer {Token}</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Path params -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Path Parameters</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">ID</p>
              <p class="examples">
                Examples: <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6f</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
          <p>application/json</p>
        </div>

        <ul>
          <!-- Salon ID -->
          <li>
            <div class="col value">
              <p class="bold">salonID</p>
              <p class="description">String/ID of the salon</p>
              <p class="examples">
                Examples: <span>1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- Date -->
          <li>
            <div class="col value">
              <p class="bold">date</p>
              <p class="description">Date of appointment</p>
              <p class="examples">
                Examples: <span>2024-01-01T02:17:32.375+00:00</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- Services -->
          <li>
            <div class="col value">
              <p class="bold">services</p>
              <p class="description">Array of services on appointment</p>
              <p class="examples">
                Examples:
                <span
                  >[ { start: "14:00", service: {ServiceObject}, duration: "20",
                  staff: {StaffMinObject}, requested: false } ]</span
                >
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- Service Object -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">{ServiceObject}</p>
              <p class="description">
                ** This is not a part of the request, this is an example of the
                service object sent in the "services" array
              </p>
              <p class="examples">
                Examples:
                <span
                  >{ "title": "Item", "color": "#a6c7ea", "isCommissioned":
                  "false", "price": 100, "duration": 20, "categoryId":
                  "622ec883ec2552e0d48ed212" }</span
                >
              </p>
            </div>

            <div class="col"></div>
          </li>

          <!-- Staff Min Object -->
          <li class="subitem">
            <div class="col value">
              <p class="bold">{StaffMinObject}</p>
              <p class="description">
                ** This is not a part of the request, this is an example of the
                staff min object sent in the "services" array
              </p>
              <!-- Above sentence in -->
              <p class="examples">
                Examples:
                <span
                  >{ "staffId": "622ec119ec2552e0d48ed192", "firstName": "John",
                  "lastName": "Doe", "email": "john@doe.com", "phoneNumber":
                  "5551112222", "commission": 60 }</span
                >
              </p>
            </div>

            <div class="col"></div>
          </li>

          <!-- Title -->
          <li>
            <div class="col value">
              <p class="bold">title</p>
              <p class="description">
                Appointment title to display on calendar (client name overrides
                if client is attached to appointment)
              </p>
              <p class="examples">Examples: <span>Walk-in</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- Note -->
          <li>
            <div class="col value">
              <p class="bold">note</p>
              <p class="description">Appointment note</p>
              <p class="examples">
                Examples: <span>Client has lorem ipsum</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- Client -->
          <li>
            <div class="col value">
              <p class="bold">client</p>
              <p class="description">
                Client associated with appointment (must already exist)
              </p>
              <p class="examples">
                Examples:
                <span
                  >"client": { "_id": "6238b0baa691db3afc07e534", "firstName":
                  "John", "lastName": "Doe", "phoneNumber": "5551112222",
                  "email": "john@doe.com" }</span
                >
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- Status -->
          <li>
            <div class="col value">
              <p class="bold">status</p>
              <p class="description">
                Appointment status either 'new', 'confirmed', 'arrived', 'no
                show', or 'cancelled'
              </p>
              <p class="examples">Examples: <span>confirmed</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="response">
      <h3>Response</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">appointment</p>
              <p class="examples">
                Examples:
                <span
                  >{ "originalData": { "services": [] }, "billing": {
                  "noShowCharged": false, "deposits": [] }, "repeat": {
                  "repeats": false }, "files": { "photos": [] }, "_id":
                  "649c9a1a0af6cbc147d16ab6", "salonId":
                  "622ec883ec2552e0d48ed298", "date":
                  "2023-06-29T21:14:12.145Z", "services": [ { "start": "14:00",
                  "service": { "fees": { "supply": 0, "other": 0 }, "title":
                  "Test Item", "color": "#a6c7ea", "onBooking": false,
                  "categoryId": "622ec883ec2552e0d48ed275", "isCommissioned":
                  false, "commissionType": "percent", "commissionAmount": 0,
                  "priceType": "fixed", "displayByConsultationOnBooking": false,
                  "turns": 0, "price": 100, "taxRate": 0, "duration": 200,
                  "extraProcessingTime": 0, "extraBlockedTime": 0,
                  "hasPricingLevels": false, "levels": [],
                  "requireCardInfoToBook": false, "requiresCleaningLog": false,
                  "suggestedServices": [], "_id": "649c9a1a0af6cbc147d16ab8" },
                  "duration": 205, "staff": { "staffId":
                  "622ec119ec2552e0d48ed156", "firstName": "John", "lastName":
                  "Doe", "email": "john@doe.com", "phoneNumber": "5551112222",
                  "commission": 60, "customCommissions": [],
                  "exemptFromServiceFees": false, "_id":
                  "649c9a1a0af6cbc147d16ab9" }, "requested": false, "_id":
                  "649c9a1a0af6cbc147d16ab7" } ], "client": { "notifications": {
                  "email": true, "text": true, "marketing": true }, "firstName":
                  "John", "lastName": "Doe", "phoneNumber": "5551112222",
                  "email": "john@doe.com", "globalClient": false, "salons": [],
                  "blacklistedSalons": [], "notes": [], "formulas": [],
                  "loyalty": [], "messages": [], "appointmentNoShows": [],
                  "tickets": [], "giftcards": [], "packages": [],
                  "subscriptions": [], "familyAndFriends": [], "paymentMethods":
                  [], "_id": "6238b0baa691db3afc07e5b6" },
                  "requestedCancellation": false, "reminderSent": false,
                  "clientConfirmationSent": false, "isBlockedTime": false,
                  "status": "confirmed", "created_at":
                  "2023-06-28T20:37:46.533Z", "updated_at":
                  "2023-06-28T21:15:49.316Z", "__v": 0, "note": "Updated note",
                  "title": "" }</span
                >
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="reqResExamples">
      <h3>Examples</h3>

      <!-- Request -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Request</h4>
          <p>application/json</p>
        </div>

        <ul>
          <li>
            <p>
              { "salonId": "622ec883ec2552e0d48ed298", "note": "Updated note",
              "status": "confirmed" }
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    apiUrl() {
      return process.env.VUE_APP_RASERVA_BACKEND;
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoint {
  .tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px;
    border-radius: 5px;
    color: white;

    &-blue {
      background-color: var(--clr-secondary);
    }
  }

  &_head {
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }

    .route {
      padding: 8px 16px;
      border-radius: 5px;
      background-color: var(--clr-white-2);
      display: flex;
      align-items: center;
      gap: 16px;

      .url {
        color: var(--clr-gray);

        span {
          color: var(--clr-primary);
          font-weight: 700;
        }
      }
    }

    .description {
      margin-top: 32px;
    }
  }

  &_body {
    .request,
    .response,
    .reqResExamples {
      margin-top: 32px;
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &_section {
      &:not(:first-child) {
        margin-top: 32px;
      }

      .head {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 20px;
          margin-bottom: 16px;
          color: var(--clr-secondary);
        }

        p {
          font-size: 14px;
          color: var(--clr-gray);
        }
      }

      ul {
        .subitem {
          margin-left: 32px;
        }

        li {
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          &:not(:first-child) {
            margin-top: 16px;
          }

          .value {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .bold {
            font-weight: 700;
          }
          .required {
            color: orangered;
          }

          .description {
            margin: 4px 0;
            color: var(--clr-gray);
          }

          .examples {
            span {
              background-color: var(--clr-white-2);
              padding: 4px 8px;
              border-radius: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
