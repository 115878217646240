<template>
  <button v-if="!to" :class="mode" :type="type" :disabled="disabled">
    <slot></slot>
  </button>
  <router-link v-else :to="to" :class="mode">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      default: 'submit',
    },
    mode: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
button,
a {
  padding: 0.75rem 2rem;
  border: 1px solid transparent;
  font: inherit;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 5px;
  background-color: transparent;

  &:disabled {
    background-color: var(--clr-light);
    color: var(--clr-gray);
    cursor: default;
  }
}

.small {
  font-size: 12px;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
}
.large {
  font-size: 17px;
}

.rounded {
  border-radius: 50rem;
}

.primary {
  color: var(--clr-white);
  background-color: var(--clr-primary);
}

.primary-outline {
  color: var(--clr-primary);
  border-color: var(--clr-primary);

  &:hover {
    color: var(--clr-white);
    background-color: var(--clr-primary);
  }
}

.secondary {
  color: var(--clr-white);
  background-color: var(--clr-secondary);
}
.secondary-dark {
  color: var(--clr-white);
  background-color: var(--clr-secondary-dark);
}

.secondary-outline {
  color: var(--clr-secondary);
  border-color: var(--clr-secondary);

  &:hover {
    color: var(--clr-white);
    background-color: var(--clr-secondary);
  }
}

.danger {
  color: var(--clr-white);
  background-color: var(--clr-danger);
}

.danger-outline {
  color: var(--clr-danger);
  border-color: var(--clr-danger);

  &:hover {
    color: var(--clr-white);
    background-color: var(--clr-danger);
  }
}

.link {
  color: var(--clr-white);
  background-color: var(--clr-link-muted);
}
</style>
