<template>
  <router-view />
</template>

<script>
export default {
  async created() {
    await this.$store.dispatch('auth/autoAuth');
    await this.$store.dispatch('admin/autoAuth');
  },
};
</script>

<style lang="scss">
// GLOBAL STYLES
table {
  width: 100%;
  font-size: 14px;
  border: 1px solid var(--clr-light);
  overflow-x: auto;

  .tablet,
  .mobile {
    display: none;
  }

  thead {
    border-bottom: 1px solid var(--clr-light);
    position: sticky;
    top: 0;
    box-shadow: var(--shadow-small);
    background-color: white;

    th {
      padding: 16px 8px;
      text-align: right;

      &:first-child {
        text-align: left;
        padding-left: 24px;
      }

      &:last-child {
        padding-right: 24px;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid var(--clr-light);
      transition: background-color 0.2s ease;

      &:hover {
        cursor: pointer;
        background-color: var(--clr-white);
      }
    }

    td {
      padding: 16px 8px;
      text-align: right;

      &:first-child {
        text-align: left;
        padding-left: 24px;
      }

      &:last-child {
        padding-right: 24px;
      }
    }
  }
}

// Tablet
@media (max-width: 900px) {
  .tablet {
    display: table-cell !important;
  }
  .hide-tablet {
    display: none !important;
  }
}
// Mobile
@media (max-width: 600px) {
  .mobile {
    display: table-cell !important;
  }
  .hide-mobile {
    display: none !important;
  }
}

.fa-spinner,
.minApp {
  animation: spin 1.5s infinite;
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
