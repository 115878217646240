<template>
  <section class="payment">
    <form @submit.prevent="submit" class="form">
      <div class="form_body">
        <BaseInput label="Code" @input="code = $event" :value="code" />
        <BaseInput
          label="Amount"
          inputType="number"
          :value="newAmount"
          @input="newAmount = +$event"
        />
      </div>

      <div class="form_actions">
        <BaseButton
          v-if="
            client &&
            client.giftcards &&
            client.giftcards.length &&
            !giftcards.length
          "
          :disabled="loading"
          @click="getClientGiftCards"
          mode="primary-outline"
          type="button"
          ><i v-if="loading" class="fas fa-spinner"></i> Get Client Gift
          Cards</BaseButton
        >
        <BaseButton @click="getBalance" :disabled="!code.length" type="button"
          >Get Balance</BaseButton
        >
        <BaseButton :disabled="!code || !newAmount || loading"
          ><i v-if="loading" class="fas fa-spinner"></i>Charge</BaseButton
        >
      </div>
    </form>

    <ul v-if="giftcards.length" class="giftcards">
      <li
        @click="useGiftCard(giftcard)"
        v-for="giftcard in giftcards"
        :key="giftcard._id"
      >
        <p><span class="title">Code:</span> {{ giftcard.code }}</p>
        <p>
          <span class="title">Balance:</span> ${{ giftcard.amount.toFixed(2) }}
        </p>
        <p>
          <span class="title">Purchased On:</span>
          {{ formatDate(giftcard.created_at) }}
        </p>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  emits: ['charged'],
  props: {
    amount: {
      type: Number,
      required: false,
    },
    ticketNumber: {
      type: String,
      required: false,
    },
    client: {
      type: Object,
      required: false,
    },
  },
  created() {
    this.newAmount = this.amount;
  },
  data() {
    return {
      code: '',
      newAmount: 0,
      loading: false,
      giftcards: [],
    };
  },
  methods: {
    async getClientGiftCards() {
      this.loading = true;

      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/giftcards/client?salonId=${this.$store.state.auth.salon._id}&clientId=${this.client._id}`
        );

        this.giftcards = response.data.giftcards;
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    formatDate(date) {
      return this.$moment(date).format('LL');
    },

    async getBalance() {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/giftcards/code/${this.code}/?salonId=${this.$store.state.auth.salon._id}`
        );

        if (response.data.giftcard) {
          this.$toast.info(
            `Gift card balance is $${response.data.giftcard.amount.toFixed(2)}`
          );
        } else {
          this.$toast.warning('Gift card not found');
        }
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    useGiftCard(giftcard) {
      this.code = giftcard.code;

      if (this.newAmount) {
        if (this.amount > giftcard.amount) {
          this.newAmount = giftcard.amount;
        } else {
          this.newAmount = this.amount;
        }
      }
    },

    async submit() {
      this.loading = true;

      try {
        const giftcard = await this.$store.dispatch(
          'giftcards/chargeGiftcard',
          {
            amount: this.newAmount,
            ticketNumber: this.ticketNumber,
            giftcardCode: this.code,
          }
        );

        this.$toast.success(
          `Gift card charged, $${giftcard.amount.toFixed(2)} remaining`
        );

        const payment = {
          date: new Date(Date.now()),
          type: 'gift',
          amount: +this.newAmount.toFixed(2),
          status: 'default',
          ref: null,
          processor: null,
        };

        this.$emit('charged', { payment });
      } catch (error) {
        if (error.message.includes('remaining')) {
          this.newAmount = +error.message.split('$')[1].split(' ')[0];
        }

        this.$toast.error(error.message);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.payment {
  .form {
    &_body {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    &_actions {
      margin-top: 32px;
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }
  }

  .giftcards {
    margin-top: 32px;

    li {
      padding: 16px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 16px;
      cursor: pointer;

      &:not(:first-child) {
        margin-top: 16px;
      }

      .title {
        font-size: 14px;
        color: var(--clr-gray);
      }
    }
  }
}
</style>
