<template>
  <form class="form" @submit.prevent="submit">
    <BaseInput
      v-if="!client"
      label="Title"
      :value="newPackage.title"
      @input="newPackage.title = $event"
    />

    <BaseInput
      v-if="!client"
      label="Client Friendly Title"
      subtitle="Client may see this name for the package if package emails are enabled"
      :value="newPackage.clientFriendlyTitle"
      @input="newPackage.clientFriendlyTitle = $event"
    />

    <BaseTextarea
      v-if="!client"
      label="Description"
      :value="newPackage.description"
      @input="newPackage.description = $event"
    />

    <div class="toggle">
      <Toggle
        class="toggle_toggle"
        :checked="newPackage.isDynamic"
        @toggle="newPackage.isDynamic = !newPackage.isDynamic"
      />
      <div class="toggle_text">
        <p class="toggle_text_label">Dynamic</p>
        <p class="toggle_text_subtext">
          Subscriptions that use dynamic packages will automatically update the
          clients subcription package on the next billing cycle
        </p>
      </div>
    </div>

    <BaseButton
      v-if="!showAddItemForm"
      type="button"
      mode="primary-outline"
      @click="showAddItemForm = true"
      >Add Item</BaseButton
    >

    <div v-if="newPackage.items.length" class="items">
      <div
        v-for="(item, index) in newPackage.items"
        :key="index"
        class="items_item"
      >
        <div class="info">
          <p>
            Service: <span class="bold">{{ item.item.title }}</span>
          </p>
          <p>
            Quantity: <span class="bold">{{ item.quantity }}</span>
            <span v-if="item.freeQuantity">
              ({{ item.freeQuantity }} are free)</span
            >
          </p>
          <p>
            Original Item Price:
            <span class="bold">${{ item.item.price.toFixed(2) }}</span>
          </p>
          <div class="item_actions">
            <i
              @click="
                item.quantity++;
                newPackage.price += item.item.price;
              "
              class="fas fa-arrow-up"
            ></i>
            <i
              @click="
                item.quantity--;
                newPackage.price -= item.item.price;
              "
              class="fas fa-arrow-down"
            ></i>
          </div>
        </div>
        <div class="actions">
          <i
            @click="newPackage.items.splice(index, 1)"
            class="fas fa-times"
          ></i>
        </div>
      </div>
    </div>

    <div v-if="showAddItemForm" class="form">
      <ServiceSelect @select="itemToAdd.item = $event" />

      <BaseInput
        label="Quantity"
        @input="itemToAdd.quantity = +$event"
        inputType="number"
      />

      <div class="toggle">
        <Toggle
          :checked="itemToAdd.useItemCustomCommission"
          @toggle="
            itemToAdd.useItemCustomCommission =
              !itemToAdd.useItemCustomCommission
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Use Custom Commission</p>
          <p class="toggle_text_subtext">
            Team member to perform this service will receive commission based
            off of a custom value
          </p>
        </div>
      </div>

      <BaseInput
        v-if="itemToAdd.useItemCustomCommission"
        label="Custom Commission Amount ($)"
        @input="itemToAdd.itemCustomCommission = +$event"
        :value="itemToAdd.itemCustomCommission"
      />

      <BaseButton
        type="button"
        mode="primary"
        :disabled="itemToAdd.quantity <= 0"
        @click="addItem(true)"
        >Submit Item And Add Another</BaseButton
      >
      <BaseButton
        type="button"
        mode="primary-outline"
        :disabled="itemToAdd.quantity <= 0"
        @click="addItem(false)"
        >Submit Item</BaseButton
      >
    </div>

    <div v-if="!newPackage.items.length" class="items_none">
      <p>No items added to package yet</p>
    </div>

    <BaseInput
      v-if="newPackage.items.length && !client"
      label="Package Price"
      :value="newPackage.price"
      @input="newPackage.price = +$event"
    />

    <!-- Discount pre-options -->
    <div class="discounts">
      <!-- Freebies -->
      <ul class="freebies">
        <li v-for="item in newPackage.items" :key="item._id">
          <div
            class="add"
            @click="
              item.freeQuantity ? item.freeQuantity++ : (item.freeQuantity = 1);
              newPackage.price -= item.item.price;
            "
          >
            <p>
              Make One <span class="bold">{{ item.item.title }}</span> Free
              <span class="bold" v-if="item.freeQuantity">
                ({{ item.freeQuantity }} are free)</span
              >
            </p>
          </div>

          <div class="remove" @click="removeFreebie(item)">
            <i class="fas fa-minus"></i>
          </div>
        </li>
      </ul>

      <!-- Percent -->
      <ul class="percents">
        <li @click="addDiscountToPackagePrice(10)">-10%</li>
        <li @click="addDiscountToPackagePrice(20)">-20%</li>
        <li @click="addDiscountToPackagePrice(25)">-25%</li>
        <li @click="addDiscountToPackagePrice(50)">-50%</li>
      </ul>
    </div>

    <div class="form_actions">
      <BaseButton
        v-if="newPackage._id && !client"
        type="button"
        mode="danger-outline"
        @click="deletePackage"
        >Delete</BaseButton
      >
      <BaseButton
        :disabled="
          !newPackage.items.length || !newPackage.title || !newPackage.price
        "
        >{{ newPackage._id ? 'Edit' : 'Create' }} Package</BaseButton
      >
    </div>
  </form>
</template>

<script>
import ServiceSelect from '@/components/items/ServiceSelect.vue';

export default {
  emits: ['submitted'],
  components: {
    ServiceSelect,
  },
  props: {
    package: {
      type: Object,
    },
    client: {
      type: Object,
      required: false,
    },
  },
  created() {
    if (this.package)
      this.newPackage = JSON.parse(JSON.stringify(this.package));

    if (this.client) this.newClient = JSON.parse(JSON.stringify(this.client));

    this.init();
  },
  computed: {
    salon() {
      return this.$store.state.auth.salon;
    },
  },
  watch: {
    salon() {
      this.init();
    },
  },
  data() {
    return {
      newPackage: {
        title: '',
        clientFriendlyTitle: '',
        description: '',
        items: [],
        price: 0,
        salon: {
          salonId: '',
          name: '',
        },
        isDynamic: false,
      },

      itemToAdd: {
        item: null,
        quantity: null,
        useItemCustomCommission: false,
        itemCustomCommission: 0,
      },

      newClient: null,

      showAddItemForm: false,
    };
  },
  methods: {
    init() {
      if (!this.salon) return;

      this.newPackage.salon.salonId = this.salon._id;
      this.newPackage.salon.name = this.salon.details.shopName;
    },
    addItem(addAnother = false) {
      if (
        this.itemToAdd.useItemCustomCommission &&
        !this.itemToAdd.itemCustomCommission
      ) {
        this.$toast.error('Custom commission value can not be 0 or less');
        return;
      }

      this.newPackage.items.push({
        item: this.itemToAdd.item,
        quantity: this.itemToAdd.quantity,
        useItemCustomCommission: this.itemToAdd.useItemCustomCommission,
        itemCustomCommission: this.itemToAdd.itemCustomCommission,
      });

      this.newPackage.price += +(
        this.itemToAdd.item.price * this.itemToAdd.quantity
      ).toFixed(2);

      this.showAddItemForm = false;

      this.itemToAdd.quantity = 0;

      if (addAnother) {
        setTimeout(() => {
          this.showAddItemForm = true;
        }, 100);
      }
    },

    addDiscountToPackagePrice(percent) {
      this.newPackage.price = +(
        this.newPackage.price -
        this.newPackage.price * (percent / 100)
      ).toFixed(2);
    },

    removeFreebie(item) {
      if (item.freeQuantity) {
        item.freeQuantity--;
        this.newPackage.price += item.item.price;
      }
    },

    async submit() {
      try {
        this.newPackage.items.forEach((item) => {
          if (!item.startingQuantity) item.startingQuantity = item.quantity;
        });

        if (this.newClient) {
          const packageIndex = this.newClient.packages.findIndex(
            (pack) => pack._id === this.package._id
          );

          if (packageIndex !== -1) {
            this.newClient.packages[packageIndex] = this.newPackage;

            await this.$store.dispatch('clients/editClient', this.newClient);
            this.$toast.success('Package edited');
            this.$emit('submitted');
            return;
          }
        }

        if (!this.newPackage._id) {
          await this.$store.dispatch('packages/create', this.newPackage);

          this.$toast.success('Package created');
          this.$emit('submitted');
        } else {
          await this.$store.dispatch('packages/edit', this.newPackage);

          this.$toast.success('Package edited');
          this.$emit('submitted');
        }
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    async deletePackage() {
      try {
        await this.$store.dispatch('packages/delete', this.newPackage);

        this.$toast.success('Package deleted');
        this.$emit('submitted');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: 700;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .items_none {
    margin-top: 16px;
    text-align: center;
    font-style: italic;
  }

  .discounts {
    .freebies {
      display: flex;
      flex-direction: column;
      gap: 16px;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        .add {
          flex-grow: 1;
        }

        .add,
        .remove {
          padding: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid var(--clr-light);
          border-radius: 5px;
          cursor: pointer;
          transition: all 0.2s ease-in-out;

          &:hover {
            background-color: var(--clr-light);
          }
        }
      }
    }
    .percents {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;

      li {
        padding: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--clr-light);
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          background-color: var(--clr-light);
        }
      }
    }
    ul {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  &_actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}

.toggle {
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 16px 0;

  &_toggle {
    min-width: 50px;
  }

  &_text {
    &_label {
      font-size: 18px;
    }
    &_subtext {
      margin-top: 5px;
      font-size: 14px;
    }
  }
}

.items {
  padding: 16px;
  border: 1px solid var(--clr-light);
  border-radius: 5px;

  &_item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:first-child) {
      margin-top: 32px;
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .fa-times {
      cursor: pointer;
      font-size: 24px;
      color: var(--clr-danger);
    }
    .item_actions {
      margin-top: 8px;
      display: flex;
      align-items: center;
      gap: 32px;

      i {
        font-size: 24px;
        cursor: pointer;
        color: var(--clr-primary);
      }
    }
  }
}
</style>
