<template>
  <card
    :style="{ borderLeft: '6px solid ' + ticket.groupColor }"
    :class="{ selected: isInCombineTickets || isInGroupTickets }"
  >
    <div class="head">
      <div class="head_info">
        <div class="nameParent">
          <div class="col head-name">
            <div class="title">
              <div
                class="status"
                :class="{
                  'bg-green': ticket.status === 'completed',
                  'bg-yellow': ticket.status !== 'completed',
                  'bg-red': ticket.status === 'refunded',
                }"
              >
                <i
                  v-if="ticket.status === 'completed'"
                  class="fas fa-check"
                ></i>
                <i
                  v-else-if="ticket.status === 'refunded'"
                  class="fas fa-undo-alt"
                ></i>
                <i v-else class="far fa-clock"></i>
              </div>

              <p class="name">{{ ticketName }}</p>

              <i v-if="doesHaveAppointment" class="fas fa-calendar"></i>
            </div>

            <p v-if="ticket.client && canSeeClientInfo" class="phone">
              {{ ticket.client.phoneNumber }}
              {{
                ticket.kioskClient && ticket.kioskClient.phoneNumber
                  ? `- Kiosk`
                  : ''
              }}
            </p>

            <p class="time-left">
              <span v-if="!isFromToday">{{
                formatDate(ticket.created_at)
              }}</span>
              {{ formatTime(ticket.created_at) }}
              <span v-if="ticket.status === 'in-progress'">
                ({{ timeLeft.toFixed(0) }} mins left)
              </span>
            </p>
          </div>

          <div class="col head-actions">
            <div class="menu">
              <i
                @click="showDropdown = !showDropdown"
                class="fas fa-ellipsis-h"
              ></i>

              <Tooltip
                mode="bottom right"
                width="200px"
                v-if="showDropdown"
                @hide="showDropdown = false"
              >
                <ul>
                  <li
                    class="action"
                    @click="
                      showEditTicketTitle = true;
                      showDropdown = false;
                    "
                  >
                    <p>Edit Title</p>
                  </li>
                  <li class="action" @click="populateTicket">
                    <p>Invoice</p>
                  </li>
                  <li
                    v-if="managerMode"
                    class="action"
                    @click="$emit('selectTicket', this.ticket)"
                  >
                    <p>Edit Ticket</p>
                  </li>
                  <!-- <li v-if="managerMode" class="action" @click="removeTicket">
                    <p>Remove</p>
                  </li> -->
                  <li
                    v-if="
                      canCheckout &&
                      ticket.status !== 'completed' &&
                      ticket.status !== 'refunded'
                    "
                    class="action"
                    @click="
                      addToCombine();
                      showDropdown = false;
                    "
                  >
                    <p>Combine</p>
                  </li>
                  <li
                    v-if="
                      canCheckout &&
                      ticket.status !== 'completed' &&
                      ticket.status !== 'refunded' &&
                      !classicView
                    "
                    class="action"
                    @click="
                      addToGroup();
                      showDropdown = false;
                    "
                  >
                    <p>Group</p>
                  </li>
                  <li
                    v-if="
                      canCheckout &&
                      ticket.status !== 'completed' &&
                      ticket.status !== 'refunded' &&
                      ticket.items.length > 1
                    "
                    class="action"
                    @click="splitTicket"
                  >
                    <p>Split</p>
                  </li>
                  <li
                    v-if="
                      canCheckout &&
                      ticket.status !== 'completed' &&
                      ticket.status !== 'refunded'
                    "
                    class="action"
                    @click="showServiceTicket = true"
                  >
                    <p>Add-on</p>
                  </li>
                  <li
                    v-if="
                      canCheckout &&
                      ticket.status !== 'completed' &&
                      ticket.status !== 'refunded'
                    "
                    class="action"
                    @click="showWorkTicket = true"
                  >
                    <p>Work</p>
                  </li>
                  <li
                    v-if="
                      ticketHasStaffOwnItems &&
                      ticket.status !== 'completed' &&
                      ticket.status !== 'refunded'
                    "
                    class="action"
                    @click="approveOwnItems"
                  >
                    <p>Approve</p>
                  </li>
                  <li
                    v-if="
                      ticket.status === 'completed' ||
                      ticket.status === 'refunded'
                    "
                    class="action"
                    @click="
                      $emit('rebookAppointmentFromTicket', ticket);
                      showDropdown = false;
                    "
                  >
                    <p>Rebook</p>
                  </li>
                  <li
                    v-if="
                      ticket.status === 'completed' ||
                      ticket.status === 'refunded'
                    "
                    class="action"
                    @click="deleteTicket"
                  >
                    <p>Remove</p>
                  </li>
                  <li
                    v-if="canCheckout && ticket.status === 'waiting'"
                    class="action"
                    @click="assignTicket"
                  >
                    <p>Assign</p>
                  </li>
                  <li
                    v-if="
                      canCheckout &&
                      ticket.status !== 'completed' &&
                      ticket.status !== 'refunded'
                    "
                    class="action"
                    @click="$emit('selectTicket', this.ticket)"
                  >
                    <p>Checkout</p>
                  </li>
                  <li
                    v-if="
                      canCheckout &&
                      ticket.status !== 'completed' &&
                      ticket.status !== 'refunded'
                    "
                    class="action danger"
                    @click="showConfirmDelete = true"
                  >
                    <p>Delete Ticket</p>
                  </li>
                </ul>
              </Tooltip>
            </div>

            <i
              v-if="
                canCheckout &&
                ticket.status !== 'completed' &&
                ticket.status !== 'refunded'
              "
              @click="$emit('selectTicket', this.ticket)"
              class="fas fa-cash-register"
            ></i>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="body">
      <ul>
        <li
          v-for="(service, index) in ticket.items"
          :key="index"
          class="ticket-item"
        >
          <div class="col">
            <p class="bold medium" v-if="service.item">
              {{ service.item.title }}
              <span v-if="service.quantity > 1">({{ service.quantity }})</span>
            </p>
            <p class="light small">{{ staffName(service) }}</p>
          </div>

          <div class="col price">
            <p class="bold small">
              ${{
                (
                  ((service.item.basePrice || service.item.price) -
                    (service.item.discountAmount || 0)) *
                  service.quantity
                ).toFixed(2)
              }}
            </p>

            <BaseInput
              v-if="showEditTurns"
              extraSmall
              label="Turns"
              :value="service.item.turns"
              @blur="
                service.item.turns = +$event.target.value;
                updateTicket();
              "
            />
          </div>
        </li>

        <li v-if="!ticket.items.length && ticket.note">{{ ticket.note }}</li>
      </ul>
    </div>
  </card>

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Delete ticket"
    text="Are you sure you wish to delete this ticket? This action can not be undone."
    @confirm="deleteTicket"
    @deny="showConfirmDelete = false"
  />

  <Modal
    title="Work Ticket"
    :show="showWorkTicket"
    @close="showWorkTicket = false"
  >
    <div class="workTicketHolder">
      <WorkTicket :ticket="ticket" :extraItems="extraItems" />
    </div>
  </Modal>

  <Modal
    title="Add-on Ticket"
    :show="showServiceTicket"
    @close="showServiceTicket = false"
  >
    <div class="workTicketHolder">
      <ServiceTicket :ticket="ticket" />
    </div>
  </Modal>

  <Modal
    title="Assign Ticket"
    :show="showAssignModal"
    @close="showAssignModal = false"
  >
    <RotationOrder
      :staff="staffs"
      @selectStaff="assignToStaff($event)"
      classicView
    />
  </Modal>

  <Modal
    title="Invoice Details"
    maxWidth="500px"
    :show="populatedTicket"
    @close="populatedTicket = null"
  >
    <div class="ticketInvoice">
      <div class="ticketInvoice_invoice">
        <TicketInvoice
          :id="populatedTicket._id"
          :ticket="populatedTicket"
          ref="invoice"
        />
      </div>
      <div class="ticketInvoice_actions">
        <form @submit.prevent="emailInvoice" class="form">
          <BaseInput label="Email" :value="email" @input="email = $event" />
          <div class="form_actions">
            <BaseButton type="button" @click="print" mode="primary-outline"
              >Print</BaseButton
            >
            <BaseButton :disabled="loading"
              ><i v-if="loading" class="fas fa-spinner"></i> Send</BaseButton
            >
          </div>
        </form>
      </div>
    </div>
  </Modal>

  <Modal
    title="Rename Ticket"
    maxWidth="500px"
    :show="showEditTicketTitle"
    @close="showEditTicketTitle = false"
  >
    <div class="renameTicket">
      <form @submit.prevent="updateTicketTitle" class="form">
        <BaseInput
          label="Title"
          @input="ticketTitle = $event"
          :value="ticket.title"
        />

        <div class="form_actions">
          <BaseButton>Submit</BaseButton>
        </div>
      </form>
    </div>
  </Modal>

  <Spinner v-if="splitLoading" fullscreen />
</template>

<script>
import printJS from 'print-js';

import TicketInvoice from '@/components/tickets/TicketInvoice.vue';
import WorkTicket from '@/components/tickets/WorkTicket.vue';
import ServiceTicket from '@/components/tickets/ServiceTicket.vue';
import RotationOrder from '@/components/dashboard/RotationOrder.vue';

export default {
  emits: [
    'selectTicket',
    'selectService',
    'filterByGroup',
    'deselect',
    'rebookAppointmentFromTicket',
  ],
  components: {
    TicketInvoice,
    WorkTicket,
    ServiceTicket,
    RotationOrder,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    assignOnly: {
      type: Boolean,
      default: false,
    },
    classicView: {
      type: Boolean,
      default: false,
    },
    showAllActionsByDefault: {
      type: Boolean,
      default: false,
    },
    showEditTurns: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    this.init();

    if (this.showAllActionsByDefault) {
      this.showMoreActions = true;
    }
  },
  mounted() {
    this.getTimeLeftInterval();

    setInterval(this.getTimeLeftInterval, 1000 * 60);
  },
  unmounted() {
    clearInterval(this.getTimeLeftInterval);
  },
  watch: {
    managerMode() {
      this.init();
    },
    tempUser() {
      this.init();
    },
  },
  data() {
    return {
      canCheckout: false,
      canSeeClientInfo: false,
      showDropdown: false,
      showConfirmDelete: false,

      populatedTicket: null,
      showTicketInvoice: false,
      showWorkTicket: false,
      showServiceTicket: false,
      showEditTicketTitle: false,
      showMoreActions: false,
      showAssignModal: false,

      email: '',
      loading: false,
      splitLoading: false,

      extraItems: 3,

      ticketTitle: '',

      timeLeft: 0,
    };
  },
  computed: {
    isFromToday() {
      return this.$moment(this.ticket.checkout_at).isSame(
        this.$moment(),
        'date'
      );
    },
    doesHaveAppointment() {
      if (!this.ticket.client) return;

      return this.$store.state.appointments.appointments.some((appointment) => {
        if (!appointment.client) return false;

        return appointment.client._id === this.ticket.client._id;
      });
    },
    ticketHasStaffOwnItems() {
      const self = this.$store.state.auth.loggedInSalonStaff;

      if (
        this.ticket.items.some((item) => {
          if (item.staff) {
            return item.staff.staffId === self.staffId;
          }
        })
      ) {
        return true;
      } else {
        return false;
      }
    },
    colors() {
      return [
        '#a6c7ea',
        '#ceb3d6',
        '#a9dbd2',
        '#bbdcad',
        '#f9f18c',
        '#fbdddd',
        '#f9a485',
        '#f59597',
      ];
    },
    combineTickets() {
      return this.$store.state.tickets.combineTickets;
    },
    groupTickets() {
      return this.$store.state.tickets.groupTickets;
    },
    managerMode() {
      return this.$store.state.auth.managerMode;
    },
    tempUser() {
      return this.$store.state.auth.tempUser;
    },
    subtotal() {
      if (!this.ticket.items.length) {
        return 0;
      }

      let total = 0;
      this.ticket.items.forEach((item) => {
        if (item.item.prepaid) return;

        total +=
          (item.item.price - (item.item.discountAmount || 0)) * item.quantity;
      });

      return total;
    },
    tax() {
      if (!this.ticket.items.length) {
        return 0;
      }

      let tax = 0;
      this.ticket.items.forEach((item) => {
        if (item.item.prepaid) return;

        tax +=
          (item.item.price - (item.item.discountAmount || 0)) *
          (item.item.taxRate * 0.01) *
          item.quantity;
      });

      return tax;
    },
    total() {
      return this.subtotal + this.tax;
    },
    isInCombineTickets() {
      return this.combineTickets.find(
        (ticket) => ticket._id === this.ticket._id
      );
    },
    isInGroupTickets() {
      return this.groupTickets.find((ticket) => ticket._id === this.ticket._id);
    },
    ticketName() {
      if (this.ticket.title) {
        return this.ticket.title;
      } else if (this.ticket.client) {
        return `${this.ticket.client.firstName} ${
          this.ticket.client.lastName || ''
        }`;
      } else {
        return 'Walk-in';
      }
    },
    ticketStatusClass() {
      if (this.ticket.status === 'in-progress') {
        return { inProgess: true };
      } else if (this.ticket.status === 'waiting') {
        return { waiting: true };
      } else {
        return { completed: true };
      }
    },
    staffs() {
      if (!this.$store.state.auth.salon) return [];

      return this.$store.state.auth.salon.staff;
    },
  },
  methods: {
    async init() {
      this.canCheckout = await this.$store.dispatch(
        'auth/activeUserHasPermission',
        'tickets/editDashboardTickets'
      );
      this.canSeeClientInfo = await this.$store.dispatch(
        'auth/activeUserHasPermission',
        'staff/viewClientContactInfo'
      );

      if (this.ticket.client) this.email = this.ticket.client.email;
      if (this.ticket.title) this.ticketTitle = this.ticket.title;
    },

    getTimeLeftInterval() {
      let totalDuration = 0;

      this.ticket.items.forEach((item) => {
        if (!item.item) return;

        if (item.item.duration) totalDuration += item.item.duration;
      });

      const endTime = this.$moment(this.ticket.created_at).add(
        totalDuration,
        'minutes'
      );

      this.timeLeft = this.$moment
        .duration(endTime.diff(this.$moment()))
        .asMinutes();
    },

    async approveOwnItems() {
      try {
        const self = this.$store.state.auth.loggedInSalonStaff;
        const newTicket = JSON.parse(JSON.stringify(this.ticket));

        newTicket.items.forEach((item) => {
          if (item.staff) {
            if (item.staff.staffId === self.staffId)
              item.approval = `${self.firstName} ${
                self.lastName
              } approved on ${this.$moment().format('lll')}`;
          }
        });

        await this.$store.dispatch('tickets/editTicket', newTicket);

        this.$toast.success('Approved your items');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async deleteTicket() {
      try {
        await this.$store.dispatch(
          'tickets/deleteDashboardTicket',
          this.ticket
        );
        this.$store.dispatch('tickets/deleteTicket', this.newTicket);

        this.$toast.success('Ticket deleted');
        this.$emit('deselect');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async splitTicket() {
      this.splitLoading = true;

      try {
        const tickets = [];

        const newTicket = JSON.parse(JSON.stringify(this.ticket));
        delete newTicket._id;

        let groupColor =
          this.colors[Math.floor(Math.random() * this.colors.length)];

        for (let i = 0; i < this.ticket.items.length; i++) {
          const ticket = { ...newTicket, items: [] };

          ticket.items.push(JSON.parse(JSON.stringify(this.ticket.items[i])));
          ticket.groupColor = groupColor;

          tickets.push(ticket);
        }

        for (let i = 0; i < tickets.length; i++) {
          await this.$store.dispatch('tickets/createTicket', tickets[i]);
        }

        await this.$store.dispatch('tickets/deleteTicket', this.ticket);
        this.$emit('deselect');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.splitLoading = false;
    },

    assignTicket() {
      this.showAssignModal = true;
      this.showDropdown = false;
    },

    async assignToStaff(staff) {
      try {
        await this.$store.dispatch('tickets/editTicket', {
          ...this.ticket,
          items: [
            {
              staff,
              quantity: 1,
              item: {
                title: 'Quick Assign',
                isCommissioned: false,
                duration: 1,
                stock: 0,
                commissionType: 'percent',
                commissionAmount: 0,
                priceType: 'fixed',
                price: 0,
                taxRate: 0,
                turns: 0,
                fees: {
                  supply: 0,
                  other: 0,
                },
              },
            },
          ],
        });

        await this.$store.dispatch('staff/editStaff', {
          ...staff,
          available: false,
        });
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async updateTicketTitle() {
      if (this.ticketTitle.length === 0 && this.ticketTitle.length > 100)
        return;

      try {
        await this.$store.dispatch('tickets/editTicket', {
          ...this.ticket,
          title: this.ticketTitle,
        });

        this.showEditTicketTitle = false;
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    async updateTicket() {
      await this.$store.dispatch('tickets/editTicket', this.ticket);
    },

    formatTime(date) {
      return this.$moment(date).format('LT');
    },
    formatDate(date) {
      return this.$moment(date).format('MMM Do');
    },

    addToCombine() {
      if (this.isInCombineTickets) {
        this.$store.state.tickets.combineTickets.splice(
          this.$store.state.tickets.combineTickets.findIndex(
            (ticket) => ticket._id === this.ticket._id
          ),
          1
        );
      } else {
        this.$store.state.tickets.combineTickets.push(this.ticket);
      }
    },

    addToGroup() {
      if (this.isInGroupTickets) {
        this.$store.state.tickets.groupTickets.splice(
          this.$store.state.tickets.groupTickets.findIndex(
            (ticket) => ticket._id === this.ticket._id
          ),
          1
        );
      } else {
        this.$store.state.tickets.groupTickets.push(this.ticket);
      }
    },

    staffName(service) {
      if (service.staff) {
        return `${service.staff.firstName} ${service.staff.lastName}`;
      }

      return 'no staff assigned';
    },

    async removeTicket() {
      try {
        const response = await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/tickets/dashboard/${this.ticket._id}`
        );

        this.$store.state.tickets.tickets = response.data.tickets;
        this.$store.state.tickets.changeCount++;
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    print() {
      printJS({
        printable: this.populatedTicket._id,
        type: 'html',
        style: `
        * { font-weight: 600 }
        .line { border-bottom: 1px solid #999999; }

        .invoice_head { text-align: center; margin-bottom: 16px; }
        .invoice_head h3 { margin-bottom: 8px; }
        .invoice_head .address { margin-bottom: 16px; }
        .invoice_head .invoice { font-weight: 700; margin-bottom: 8px; }
        .invoice_items, .invoice_payments { paddings: 24px 0; border-top: 1px solid #e9e9e9; }
        .invoice_payments { margin-top: 32px; }
        .invoice_items .invoice_items_item { display: flex; justify-content: space-between; align-items: center; }
        .invoice_payments .invoice_payments_payment { display: flex; justify-content: space-between; align-items: center; }
        .invoice_items .invoice_items_item:not(:first-child) { margin-top: 32px; }
        .invoice_payments .invoice_payments_payment:not(:first-child) { margin-top: 16px; }
        .invoice_items .invoice_items_item .staff, .date, .prepaid { font-size: 14px; color: #999999; }
        .invoice_items .invoice_items_item .staff { display: hidden; }
        .invoice_payments .invoice_payments_payment .staff, .date, .prepaid { font-size: 14px; color: #999999; }
        .invoice_items .invoice_items_item .col-right { text-align: right; }
        .invoice_payments .invoice_payments_payment .col-right { text-align: right; }
        .invoice_summary .section { border-top: 1px solid #e9e9e9; padding: 4px 0; display: flex; justify-content: space-between; align-items: center; }
        .invoice_summary .section:first-child { margin-top: 64px; }
        .invoice_summary .total { font-weight: 700 }
        `,
      });
    },

    capitalizeFirstLetter(string) {
      if (!string) return;
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    async populateTicket() {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/tickets/${this.ticket._id}?shopDetails=true`
        );

        this.populatedTicket = response.data.ticket;
      } catch (error) {
        console.log(error);
      }
    },

    async emailInvoice() {
      this.loading = true;

      try {
        await this.$store.dispatch('tickets/sendInvoice', {
          email: this.email,
          ticketId: this.ticket._id,
        });

        this.$toast.success('Invoice sent');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.head,
.body {
  padding: 8px;
}
.selected {
  border: 2px solid var(--clr-primary);
}
.head {
  display: flex;
  gap: 16px;
  align-items: center;
  font-size: 14px;
  width: 100%;

  &_info {
    width: 100%;
  }

  .nameParent {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;

    .head-name {
      .title {
        display: flex;
        align-items: center;
        gap: 8px;

        .bg-green {
          background-color: var(--clr-success-light);
          color: var(--clr-success-dark);
          border: 1px solid var(--clr-success);
        }
        .bg-yellow {
          background-color: var(--clr-warning-light);
          color: var(--clr-warning-dark);
          border: 1px solid var(--clr-warning);
        }
        .bg-red {
          background-color: var(--clr-danger-light);
          color: var(--clr-danger-dark);
          border: 1px solid var(--clr-danger);
        }

        .status {
          height: 18px;
          width: 18px;
          border-radius: 50%;
          font-size: 9px;
          display: grid;
          place-items: center;
        }

        .fa-calendar {
          color: var(--clr-secondary);
        }
      }

      .time-left {
        margin-top: 8px;
        font-size: 12px;
        color: var(--clr-gray);
      }
    }

    .head-actions {
      display: flex;
      gap: 16px;

      .menu {
        position: relative;

        ul {
          background-color: white;

          li {
            padding: 16px;
            cursor: pointer;

            &:not(:first-child) {
              border-top: 1px solid var(--clr-white);
            }

            &:hover {
              background-color: var(--clr-white);
            }
          }
        }
      }

      i {
        padding: 6px;
        border-radius: 5px;
        border: 1px solid var(--clr-light);
        color: var(--clr-gray);
      }
    }

    .link {
      color: var(--clr-link);
    }
    .name {
      font-weight: 700;
    }
    .phone {
      margin-top: 8px;
      font-size: 12px;
      color: var(--clr-link);
    }
    i {
      cursor: pointer;
    }
  }

  .number,
  .time-status,
  .total {
    font-size: 12px;
  }

  .inProgess {
    color: var(--clr-secondary);
  }
  .waiting {
    color: var(--clr-link);
  }
  .completed {
    color: var(--clr-success);
  }
}

hr {
  margin: 8px 0;
  border: 1px solid var(--clr-white);
}

.body {
  ul {
    .ticket-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .bold {
        font-weight: 700;
      }
      .light {
        color: var(--clr-gray);
      }
      .small {
        font-size: 12px;
      }
      .medium {
        font-size: 14px;
      }

      .price {
        display: flex;
        align-items: center;
        gap: 32px;
      }
    }

    li {
      &:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
}
.danger {
  color: var(--clr-danger) !important;
}

.allActions,
.lessActions {
  // display: flex;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  margin-top: 16px;

  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 5px 10px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: var(--clr-light);
    }

    i {
      color: var(--clr-gray);
      margin-right: 16px;
    }
  }
}

.classicView {
  grid-template-columns: 1fr 1fr !important;

  .action {
    font-size: 16px;
    padding: 8px 16px;
  }
}

.ticketInvoice {
  padding: 16px;
  background-color: var(--clr-white-2);

  &_invoice {
    padding: 16px;
    background-color: white;
    border-radius: 10px;
  }
  &_actions {
    padding: 16px;
    border-radius: 5px;

    .form {
      &_actions {
        margin-top: 16px;
        text-align: right;

        button:not(:first-child) {
          margin-left: 16px;
        }
      }
    }
  }
}

.renameTicket {
  padding: 32px;

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_actions {
      text-align: right;

      button:not(:first-child) {
        margin-left: 16px;
      }
    }
  }
}

.workTicketHolder {
  padding: 32px;

  .input {
    margin-top: 16px;
  }

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
