<template>
  <div class="logo">
    <img src="@/assets/raserva-logo.png" alt="" />
  </div>
</template>

<style lang="scss" scoped>
.logo {
  height: 35px;
  cursor: pointer;

  img {
    height: 100%;
  }
}
</style>
