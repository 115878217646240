<template>
  <section class="team">
    <div class="team_sidebar">
      <SubSidebar :routes="routes" title="Team" />
    </div>
    <div class="team_router">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import SubSidebar from '@/components/components/SubSidebar.vue';

export default {
  components: {
    SubSidebar,
  },
  created() {
    this.init();
  },
  data() {
    return {
      routes: [
        {
          name: 'Working hours',
          linkName: 'Schedule',
        },
      ],
    };
  },
  computed: {
    loggedInStaff() {
      return this.$store.state.auth.loggedInSalonStaff;
    },
    managerMode() {
      return this.$store.state.auth.managerMode;
    },
  },
  watch: {
    loggedInStaff() {
      this.init();
    },
    managerMode() {
      this.init();
    },
  },
  methods: {
    async init() {
      await this.setRoutes();
      this.goToFirstRoute();
    },

    async setRoutes() {
      this.routes = [
        {
          name: 'Working hours',
          linkName: 'Schedule',
        },
      ];

      // Members
      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'staff/viewStaffMembers'
        )
      ) {
        this.routes.unshift({
          name: 'Team members',
          linkName: 'TeamMembers',
        });
      }

      // Actions
      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'staff/viewActions'
        )
      ) {
        this.routes.push({
          name: 'Actions',
          linkName: 'Actions',
        });
      }
    },
    goToFirstRoute() {
      this.$router.push({
        name: this.routes[0].linkName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.team {
  display: flex;
  height: 100%;

  &_router {
    overflow-y: auto;
    flex-grow: 1;
    padding: 32px;
    display: flex;
    justify-content: center;
  }
}
</style>
