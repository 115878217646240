<template>
  <section class="ticket">
    <div class="ticket_head">
      <div class="avatar">
        <Avatar
          mode="large rounded"
          :name="newTicket.client ? newTicket.client.firstName : 'Walk-in'"
        />
        <div class="avatar_text">
          <h2>{{ clientName }}</h2>
          <p v-if="newTicket.client && showClientContactInfo" class="small">
            {{ newTicket.client.phoneNumber }}
          </p>
          <p v-if="newTicket.client && showClientContactInfo" class="small">
            {{ newTicket.client.email }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="!hideActions" class="ticket_actions">
      <div v-if="canEdit" class="action" @click="showTicketModal = true">
        <i class="fas fa-edit"></i>
        <p>Edit</p>
      </div>
      <div
        class="action"
        @click="
          duplicateTicket = true;
          showTicketModal = true;
        "
      >
        <i class="fas fa-copy"></i>
        <p>Duplicate</p>
      </div>
      <div
        v-if="newTicket.status !== 'refunded'"
        class="action red"
        @click="refund"
      >
        <i class="fas fa-exclamation-triangle"></i>
        <p>Refund</p>
      </div>
      <div v-else class="action green" @click="completed">
        <i class="fas fa-check"></i>
        <p>Complete</p>
      </div>
      <div
        class="moreDropdownParent"
        @click="adminOrManager ? (showMoreOptions = !showMoreOptions) : null"
      >
        <div class="action">
          <i class="fas fa-ellipsis-h"></i>
          <p>More</p>
        </div>
        <Tooltip
          v-if="showMoreOptions"
          mode="bottom right"
          @hide="showMoreOptions = false"
        >
          <ul class="moreDropdown">
            <li v-if="canEdit" @click="showConfirmDeleteTicket = true">
              Delete
            </li>
            <li @click="showTicketInvoice = true">View Invoice</li>
          </ul>
        </Tooltip>
      </div>
    </div>
    <div class="ticket_flex">
      <div
        class="item"
        v-for="item in newTicket.items"
        :key="item._id"
        :class="{ red: item.quantity < 0 }"
      >
        <div class="col">
          <p class="bold">{{ item.item.title }} ({{ item.quantity }})</p>
          <p class="light" v-if="item.staff">
            {{ item.staff.firstName + ' ' + item.staff.lastName }}
          </p>
        </div>
        <div class="col right">
          <p class="bold">
            {{ formatCurrency(item.item.price * item.quantity) }}
          </p>
          <p v-if="item.item.prepaid" class="light">(Prepaid)</p>
        </div>
      </div>
      <div class="summary">
        <div class="col">
          <p>
            Subtotal: <span class="bold">{{ formatCurrency(subtotal) }}</span>
          </p>
          <p>
            Tax: <span class="bold">{{ formatCurrency(tax) }}</span>
          </p>
          <p>
            Tips: <span class="bold">{{ formatCurrency(tips) }}</span>
          </p>
          <p>
            Discounts: <span class="bold">{{ formatCurrency(discounts) }}</span>
          </p>
        </div>
        <div class="col">
          <h2>{{ formatCurrency(total) }}</h2>
        </div>
      </div>
      <div class="payments" v-if="newTicket.payments.length">
        <div v-if="allPaymentsRefunded" class="alert alert-success">
          <p>All payments are refunded.</p>
        </div>
        <div v-else class="alert alert-warning">
          <p>
            Payment refunds must be done by clicking payment method below and
            selecting 'Refund'
          </p>
        </div>

        <div
          class="payment"
          v-for="payment in newTicket.payments"
          :key="payment"
          @click="!hideActions ? (selectedPayment = payment) : null"
        >
          <div class="col">
            <p :class="{ red: payment.status === 'refunded' }">
              {{ payment.type
              }}<span v-if="payment.processor" class="type">
                ({{ payment.processor }})</span
              >
              <span v-if="payment.approval" class="type">
                (**{{ payment.approval }})</span
              >
            </p>
          </div>
          <div class="col">
            <p :class="{ red: payment.status === 'refunded' }">
              {{ formatCurrency(payment.amount) }}
            </p>
          </div>
        </div>
      </div>

      <p class="mt-s text-gray text-small">Ticket ID: {{ newTicket._id }}</p>
    </div>
  </section>

  <Modal
    :show="selectedPayment"
    title="Payment options"
    @close="selectedPayment = null"
    :zIndex="100010"
  >
    <div class="paymentOptions">
      <PaymentOptions
        @refunded="refundPayment($event)"
        :payment="selectedPayment"
        @close="selectedPayment = null"
        @edit="editPayment($event)"
      />
    </div>
  </Modal>

  <Modal
    title="Invoice Details"
    maxWidth="500px"
    :zIndex="100000"
    :show="showTicketInvoice"
    @close="showTicketInvoice = null"
  >
    <div class="ticketInvoice">
      <div class="ticketInvoice_invoice">
        <TicketInvoice :id="newTicket._id" :ticket="newTicket" ref="invoice" />
      </div>
      <div class="ticketInvoice_actions">
        <form @submit.prevent="emailInvoice" class="form">
          <BaseInput
            type="email"
            label="Email"
            :value="email"
            @input="email = $event"
          />
          <div class="form_actions">
            <BaseButton type="button" @click="print" mode="primary-outline"
              >Print</BaseButton
            >
            <BaseButton :disabled="loading"
              ><i v-if="loading" class="fas fa-spinner"></i> Send</BaseButton
            >
          </div>
        </form>
      </div>
    </div>
  </Modal>

  <Modal
    :title="duplicateTicket ? 'New Ticket' : 'Edit Ticket'"
    @close="
      showTicketModal = false;
      duplicateTicket = false;
    "
    v-if="showTicketModal"
    :show="showTicketModal"
    :zIndex="500"
    fullScreen
  >
    <TicketForm
      :ticket="duplicateTicket ? duplicatedTicket : newTicket"
      @edited="editTicket($event)"
      @created="createTicket($event)"
      @checkedOut="checkoutTicket($event)"
      canEditDate
    />
  </Modal>

  <Confirm
    v-if="showConfirmDeleteTicket"
    delete
    title="Delete ticket"
    text="Are you sure you wish to delete this ticket? This action can not be undone."
    @confirm="deleteTicket"
    @deny="showConfirmDeleteTicket = false"
  />

  <Confirm
    v-if="showHowToPaymentRefundConfirmation"
    hideConfirm
    title="Payment Refunds"
    text="You have refunded ticket items, but the payment(s) have not been refunded. To refund payments, click the payment method below and select 'Refund'."
    @confirm="showHowToPaymentRefundConfirmation = false"
    @deny="showHowToPaymentRefundConfirmation = false"
  />
</template>

<script>
import printJS from 'print-js';

import TicketForm from '@/views/dashboard/TicketForm.vue';
import PaymentOptions from '@/components/tickets/PaymentOptions.vue';
import TicketInvoice from '@/components/tickets/TicketInvoice.vue';

export default {
  emits: ['updated', 'created', 'exit'],
  components: {
    TicketForm,
    PaymentOptions,
    TicketInvoice,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    this.newTicket = JSON.parse(JSON.stringify(this.ticket));

    if (this.newTicket.client && this.newTicket.client.email)
      this.email = this.newTicket.client.email;

    if (
      await this.$store.dispatch(
        'auth/activeUserHasPermission',
        'tickets/editTickets'
      )
    ) {
      this.canEdit = true;
    }

    this.showClientContactInfo = await this.$store.dispatch(
      'auth/activeUserHasPermission',
      'staff/viewClientContactInfo'
    );
  },
  computed: {
    allPaymentsRefunded() {
      return this.newTicket.payments.every((p) => p.status === 'refunded');
    },
    clientName() {
      if (this.newTicket.client) {
        return `${this.newTicket.client.firstName} ${this.newTicket.client.lastName}`;
      } else {
        return 'Walk-in';
      }
    },
    subtotal() {
      if (!this.newTicket.items.length) {
        return 0;
      }

      let total = 0;
      this.newTicket.items.forEach((item) => {
        if (item.item.prepaid) return;

        total += item.item.price * item.quantity;
      });

      return total;
    },
    total() {
      return this.subtotal + this.tax + this.tips - this.discounts;
    },
    tips() {
      let tips = 0;

      this.newTicket.tips.forEach((tip) => (tips += tip.amount));

      return tips;
    },
    discounts() {
      if (!this.newTicket.items.length) {
        return 0;
      }

      let total = 0;
      this.newTicket.items.forEach((item) => {
        total += (item.item.discountAmount || 0) * item.quantity;
      });

      return total;
    },
    tax() {
      if (!this.newTicket.items.length) {
        return 0;
      }

      let tax = 0;
      this.newTicket.items.forEach((item) => {
        if (item.item.prepaid) return;

        tax += item.item.price * (item.item.taxRate * 0.01) * item.quantity;
      });

      return tax;
    },
    duplicatedTicket() {
      return {
        ...this.newTicket,
        created_at: new Date(Date.now()),
        _id: undefined,
        status: 'in-progress',
        payments: [],
        tips: [],
      };
    },
    adminOrManager() {
      if (
        this.$store.state.auth.managerMode ||
        this.$store.state.auth.user._id === this.$store.state.auth.salon.adminId
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      newTicket: {},
      showTicketModal: false,
      duplicateTicket: false,
      showMoreOptions: false,
      showClientContactInfo: false,
      showConfirmDeleteTicket: false,
      canEdit: false,
      selectedPayment: null,
      showTicketInvoice: false,
      email: '',
      loading: false,
      showHowToPaymentRefundConfirmation: false,
    };
  },
  methods: {
    async emailInvoice() {
      this.loading = true;

      try {
        await this.$store.dispatch('tickets/sendInvoice', {
          email: this.email,
          ticketId: this.ticket._id,
        });

        this.$toast.success('Invoice sent');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
    formatCurrency(amount) {
      return `$${amount.toFixed(2)}`;
    },
    async refund() {
      if (
        this.newTicket.status === 'in-progress' ||
        this.newTicket.status === 'waiting'
      ) {
        this.$toast.error('Unacceptable ticket state');
        return;
      }

      this.newTicket.status = 'refunded';

      const refundedItems = [];

      // Add a refunded copy of each item on ticket
      this.newTicket.items.forEach((item) => {
        refundedItems.push({
          ...item,
          quantity: item.quantity * -1,
        });
      });
      this.newTicket.items.push(...refundedItems);

      // Payment methods that are not card to refunded
      this.newTicket.payments.forEach((payment) => {
        if (payment.type !== 'card') {
          payment.status = 'refunded';
        }
      });

      await this.$store.dispatch('tickets/editTicket', {
        ...this.newTicket,
        query: 'force=true',
      });
      this.$toast.success('Ticket marked as refunded');
      this.$toast.warning('Payment methods may still need be refunded');
      this.$emit('updated');

      if (
        this.newTicket.payments.some(
          (payment) => payment.type === 'card' && payment.processor !== null
        )
      ) {
        this.showHowToPaymentRefundConfirmation = true;
      }
    },
    async completed() {
      if (
        this.newTicket.status === 'in-progress' ||
        this.newTicket.status === 'waiting'
      ) {
        this.$toast.error('Unacceptable ticket state');
        return;
      }

      this.newTicket.status = 'completed';

      // Remove refunded items
      this.newTicket.items = this.newTicket.items.filter(
        (item) => item.quantity > 0
      );

      // Payment methods that are not card to completed
      this.newTicket.payments.forEach((payment) => {
        if (payment.type !== 'card') {
          payment.status = 'default';
        }
      });

      await this.$store.dispatch('tickets/editTicket', {
        ...this.newTicket,
        query: 'force=true',
      });
      this.$toast.success('Ticket marked as completed');
      this.$emit('updated');
    },
    async editTicket(ticket) {
      try {
        await this.$store.dispatch('tickets/editTicket', {
          ...ticket,
          query: 'force=true',
        });

        this.$toast.success('Ticket updated');
        this.$emit('updated');

        this.showTicketModal = false;
        this.duplicateTicket = false;
        this.newTicket = ticket;
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    async createTicket(ticket) {
      try {
        await this.$store.dispatch('tickets/createTicket', ticket);
        this.$toast.success('Ticket created');
        this.$emit('created');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.showTicketModal = false;
      this.duplicateTicket = false;
    },
    async checkoutTicket(ticket) {
      try {
        await this.$store.dispatch('tickets/checkoutTicket', ticket);
        this.$toast.success('Ticket checked out');
        this.$emit('updated');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.showTicketModal = false;
      this.duplicateTicket = false;
    },
    async deleteTicket() {
      try {
        await this.$store.dispatch('tickets/deleteTicket', this.newTicket);
        this.$emit('updated');
        this.$emit('hide');

        this.showConfirmDeleteTicket = false;
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async editPayment(newPayment) {
      try {
        const index = this.newTicket.payments.findIndex(
          (payment) => payment._id === newPayment._id
        );

        if (index === -1) return;

        this.newTicket.payments[index] = newPayment;

        await this.$store.dispatch('tickets/savePayments', this.newTicket);

        this.selectedPayment = null;
        this.$toast.success('Payment updated');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    async refundPayment(refundPayment) {
      try {
        const index = this.newTicket.payments.findIndex(
          (payment) => payment._id === this.selectedPayment._id
        );

        if (index === -1) throw Error('Can not find payment');

        const payment = this.newTicket.payments[index];

        const amount = refundPayment.refundAmount
          ? +refundPayment.refundAmount
          : +refundPayment.amount;

        if (+payment.amount !== +amount) {
          this.newTicket.payments.push({
            ...refundPayment,
            amount,
            status: 'refunded',
          });
        } else {
          payment.status = 'refunded';
        }

        await this.$store.dispatch('tickets/savePayments', this.newTicket);

        this.selectedPayment = null;
        this.$toast.success('Payment marked as refunded');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    print() {
      printJS({
        printable: this.newTicket._id,
        type: 'html',
        style: `
        .line { border-bottom: 1px solid #999999; }

        .invoice_head { text-align: center; margin-bottom: 16px; }
        .invoice_head h3 { margin-bottom: 8px; }
        .invoice_head .address { margin-bottom: 16px; }
        .invoice_head .invoice { font-weight: 700; margin-bottom: 8px; }
        .invoice_items, .invoice_payments { paddings: 24px 0; border-top: 1px solid #e9e9e9; }
        .invoice_payments { margin-top: 32px; }
        .invoice_items .invoice_items_item { display: flex; justify-content: space-between; align-items: center; }
        .invoice_payments .invoice_payments_payment { display: flex; justify-content: space-between; align-items: center; }
        .invoice_items .invoice_items_item:not(:first-child) { margin-top: 32px; }
        .invoice_payments .invoice_payments_payment:not(:first-child) { margin-top: 16px; }
        .invoice_items .invoice_items_item .staff, .date, .prepaid { font-size: 14px; color: #999999; }
        .invoice_items .invoice_items_item .staff { display: hidden; }
        .invoice_payments .invoice_payments_payment .staff, .date, .prepaid { font-size: 14px; color: #999999; }
        .invoice_items .invoice_items_item .col-right { text-align: right; }
        .invoice_payments .invoice_payments_payment .col-right { text-align: right; }
        .invoice_summary .section { border-top: 1px solid #e9e9e9; padding: 4px 0; display: flex; justify-content: space-between; align-items: center; }
        .invoice_summary .section:first-child { margin-top: 64px; }
        .invoice_summary .total { font-weight: 700 }
        `,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket {
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;

  .mt-s {
    margin-top: 16px;
  }
  .text-gray {
    color: var(--clr-gray);
  }
  .text-small {
    font-size: 12px;
  }

  .alert {
    padding: 12px;
    font-size: 14px;
    border-radius: 5px;

    &-warning {
      background-color: var(--clr-warning-light);
      border-color: var(--clr-warning);
    }
    &-success {
      background-color: #c3f7c7;
      color: #04530b;
      border-color: #acf7b2;
    }
  }

  &_head {
    .avatar {
      display: flex;
      align-items: center;
      gap: 32px;

      &_text {
        h2 {
          margin-bottom: 5px;
        }

        .small {
          font-size: 12px;
          color: var(--clr-link);
        }
      }
    }
  }
  &_actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;

    .red {
      color: var(--clr-danger);
      border-color: var(--clr-danger-light) !important;

      &:hover {
        border-color: var(--clr-danger) !important;
      }

      i {
        color: var(--clr-danger) !important;
      }
    }
    .green {
      color: var(--clr-success);
      border-color: var(--clr-success-light) !important;

      &:hover {
        border-color: var(--clr-success) !important;
      }

      i {
        color: var(--clr-success) !important;
      }
    }
    .action {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      padding: 8px;
      width: 90px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      transition: border-color 0.2s ease;
      cursor: pointer;

      i {
        color: var(--clr-gray);
        font-size: 24px;
      }

      &:hover {
        border-color: var(--clr-gray);
      }
    }
  }
  &_flex {
    flex-grow: 1;
    border: 1px solid var(--clr-light);
    border-radius: 5px;
    padding: 16px;

    .item,
    .summary,
    .payment {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .bold {
        font-weight: 700;
      }
      .light,
      .type {
        color: var(--clr-gray);
      }
    }

    .red {
      color: var(--clr-danger);
    }

    .item {
      &:not(:first-child) {
        margin-top: 16px;
      }
      .right {
        text-align: right;
      }
    }

    .summary {
      font-size: 14px;
      padding: 16px;
      margin-top: 32px;
      border-radius: 5px;
      background-color: var(--clr-light);

      p:not(:first-child) {
        margin-top: 5px;
      }

      h2 {
        font-size: 28px;
      }
    }

    .payments {
      font-size: 14px;
      padding: 16px;
      margin-top: 32px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;

      .red {
        color: var(--clr-danger) !important;
      }

      .payment {
        cursor: pointer;

        &:not(:first-child) {
          margin-top: 16px;
        }
      }
    }
  }
}

.ticketInvoice {
  padding: 16px;
  background-color: var(--clr-white-2);

  &_invoice {
    padding: 16px;
    background-color: white;
    border-radius: 10px;
  }
  &_actions {
    padding: 16px;
    border-radius: 5px;

    .form {
      &_actions {
        margin-top: 16px;
        text-align: right;

        button:not(:first-child) {
          margin-left: 16px;
        }
      }
    }
  }
}

.paymentOptions {
  padding: 32px;
}

.moreDropdownParent {
  position: relative;
}
.moreDropdown {
  color: var(--clr-black);
  background-color: white;
  border: 1px solid var(--clr-light);
  border-radius: 5px;

  li {
    padding: 12px 16px;
    font-weight: 400;
    text-align: left;
    font-size: 15px;
    transition: background-color 0.2s;
    cursor: pointer;

    &:hover {
      background-color: var(--clr-extra-light);
    }
  }
}
</style>
