<template>
  <section class="register">
    <div class="container">
      <div class="register_head">
        <h1>Register your salon</h1>
        <p>
          Payment information is required to register a salon. Pricing details
          can be
          <span class="link" @click="showPricing = true">viewed here.</span>
        </p>
      </div>
      <form @submit.prevent="submit" class="form">
        <div class="form_section">
          <div class="form_section_head">
            <h3>Basic Info</h3>
          </div>
          <div class="form_section_body">
            <BaseInput
              label="Shop name"
              placeholder="Enter your shop name"
              :value="salon.details.shopName"
              :validators="validators.requiredMin2"
              @input="salon.details.shopName = $event"
            />
            <BaseInput
              label="Alternative name"
              placeholder="Enter your shop alt name"
              subtitle="Only visible to staff (used to distinguish salons)"
              :value="salon.details.altName"
              @input="salon.details.altName = $event"
            />
            <BaseTextarea
              label="Short description"
              subtitle="Clients will be able to see this information on your salon upon booking"
              :validators="validators.shortDescription"
              :value="salon.details.shortDescription"
              @input="salon.details.shortDescription = $event"
            />
            <BaseSelect
              label="Timezone"
              :options="timezonesForSelect"
              :value="salon.details.timezone"
              subtitle="It's important this is accurate as this may impact the time of appointment a client will see"
              @input="salon.details.timezone = $event"
            />
          </div>
        </div>
        <div class="form_section">
          <div class="form_section_head">
            <h3>Address</h3>
          </div>
          <div class="form_section_body">
            <BaseInput
              label="Street"
              placeholder="Enter your street address"
              :validators="validators.requiredMin2"
              :value="salon.details.address.street"
              @input="salon.details.address.street = $event"
            />
            <BaseInput
              label="City"
              placeholder="Enter your city"
              :validators="validators.requiredMin2"
              :value="salon.details.address.city"
              @input="salon.details.address.city = $event"
            />
            <BaseInput
              label="State"
              placeholder="Enter your state"
              :validators="validators.requiredMin2"
              :value="salon.details.address.state"
              @input="salon.details.address.state = $event"
            />
            <BaseInput
              label="Postal"
              placeholder="Enter your postal code"
              :validators="validators.requiredMin2"
              :value="salon.details.address.postal"
              @input="salon.details.address.postal = $event"
            />
          </div>
        </div>
        <div class="form_section">
          <div class="form_section_head">
            <h3>Team Members</h3>
            <p>
              See pricing info above, you can update the number of team members
              at any time HOWEVER you will be charged for the highest number of
              team members per billing period. We recommend starting with one
              (1) team member here if you are not absolutely certain of the
              number of team members this salon will have, as you can always add
              later.
            </p>
          </div>
          <div class="form_section_body">
            <BaseInput
              label="Number of Team Members"
              inputType="number"
              :value="quantity"
              :subtitle="monthlyCostText"
              @input="quantity = +$event"
              step="1"
            />
          </div>
        </div>
        <!-- <div class="form_section">
          <div class="form_section_head">
            <h3>Payment Processor</h3>
            <p>
              If all revenue is linked to one payment processsing account (i.e.
              Central Terminals) select appropriate option and enter in required
              information.
            </p>
          </div>
          <div class="form_section_body">
            <BaseSelect
              label="Processor"
              :options="processorOptions"
              :value="salon.payments.processor"
              @input="salon.payments.processor = $event"
            />
            <div
              v-if="salon.payments.processor === 'stripe'"
              class="stripe col"
            >
              <BaseSelect
                label="Processor"
                :options="stripeAccountOptions"
                @input="salon.billing.stripe.adminAccountId = $event"
              />
            </div>
            <div
              v-if="salon.payments.processor === 'cardconnect'"
              class="cardconnect col"
            >
              <BaseInput
                label="Merchant ID"
                :value="salon.billing.cardconnect.mid"
                @input="salon.billing.cardconnect.mid = $event"
              />
              <BaseInput
                label="User"
                :value="salon.billing.cardconnect.user"
                @input="salon.billing.cardconnect.user = $event"
              />
              <BaseInput
                label="Password"
                inputType="password"
                :value="salon.billing.cardconnect.password"
                @input="salon.billing.cardconnect.password = $event"
              />
            </div>
          </div>
        </div> -->
        <div class="form_section">
          <div class="form_section_head">
            <h3>Free Trial</h3>
            <p>
              Available on your first salon, get access absolutely free for one
              month!
            </p>
          </div>
          <div class="form_section_body">
            <div class="toggle">
              <Toggle :checked="trial" @toggle="trial = !trial" />
              <div class="toggle_text">
                <p class="toggle_text_label">Yes, I'd like a free trial!</p>
              </div>
            </div>
          </div>
        </div>
        <div class="form_actions">
          <Spinner v-if="loading" fullscreen />
          <BaseButton>Continue To Billing</BaseButton>
        </div>
      </form>
    </div>
  </section>

  <Modal title="Pricing" :show="showPricing" @close="showPricing = false">
    <div class="pricing">
      <PricingDetails hideTrial @selectQuantity="quantity = +$event" />
    </div>
  </Modal>
</template>

<script>
import PricingDetails from '@/components/landing/PricingDetails.vue';

export default {
  components: {
    PricingDetails,
  },
  created() {
    if (this.$route.query.stripe_error) {
      this.$toast.error(this.$route.query.stripe_error);
      this.$router.push({ name: 'StaffProfile' });
    } else if (this.$route.query.session_id) {
      this.$toast.success('Subscription creation successful');
      this.$router.push({ name: 'StaffProfile' });
    }

    if (this.$route.query.shopName) {
      this.salon.details.shopName = this.$route.query.shopName;
      this.salon.details.shortDescription = this.$route.query.shortDescription;
      this.salon.details.address.street = this.$route.query.street;
      this.salon.details.address.city = this.$route.query.city;
      this.salon.details.address.state = this.$route.query.state;
      this.salon.details.address.postal = this.$route.query.postal;
      this.salon.details.timezone = this.$route.query.timezone;
    }
  },
  data() {
    return {
      quantity: 1,
      loading: false,
      trial: false,
      showPricing: false,

      salon: {
        details: {
          shopName: '',
          shortDescription: '',
          address: {
            street: '',
            city: '',
            state: '',
            postal: '',
          },
          timezone: 'America/New_York',
        },
        billing: {
          stripe: {
            customerId: null,
            adminAccountId: '',
          },
          cardconnect: {
            mid: '',
            user: '',
            password: '',
          },
        },
        payments: {
          processor: 'stripe',
        },
      },
    };
  },
  computed: {
    validators() {
      return {
        requiredMin2: {
          required: true,
          minLength: 2,
        },
        shortDescription: {
          minLength: 2,
          maxLength: 500,
        },
      };
    },
    processorOptions() {
      return [
        {
          option: 'Stripe',
          value: 'stripe',
        },
        {
          option: 'Card Connect',
          value: 'cardconnect',
        },
        {
          option: 'Valor',
          value: 'valor',
        },
      ];
    },
    stripeAccountOptions() {
      const accounts = [];

      this.$store.state.auth.user.paymentProcessing.stripe.accounts.forEach(
        (account) => {
          accounts.push({
            option: account.id,
            value: account.id,
          });
        }
      );

      return accounts;
    },
    monthlyCostText() {
      let amount = 0;

      if (this.quantity === 1) {
        amount = 19.99;
      } else if (this.quantity === 2) {
        amount = 39.99;
      } else if (this.quantity === 3) {
        amount = 59.99;
      } else if (this.quantity === 4) {
        amount = 79.99;
      } else if (this.quantity >= 5) {
        amount = 99.99;
      }

      return `$${amount.toFixed(2)}/mo`;
    },
    timezones() {
      return [
        'America/Adak',
        'America/Anchorage',
        'America/Boise',
        'America/Chicago',
        'America/Denver',
        'America/Detroit',
        'America/Indiana/Indianapolis',
        'America/Indiana/Knox',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Tell_City',
        'America/Indiana/Vevay',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Juneau',
        'America/Kentucky/Louisville',
        'America/Kentucky/Monticello',
        'America/Los_Angeles',
        'America/Menominee',
        'America/Metlakatla',
        'America/New_York',
        'America/Nome',
        'America/North_Dakota/Beulah',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/Phoenix',
        'America/Sitka',
        'America/Yakutat',
        'Pacific/Honolulu',
      ];
    },
    timezonesForSelect() {
      return this.timezones.map((timezone) => {
        return {
          option: timezone,
          value: timezone,
        };
      });
    },
  },
  methods: {
    async createPortal() {
      if (this.quantity <= 0) {
        this.$toast.error('Invalid number of team members');
        return;
      }

      this.loading = true;

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/subscriptions/checkoutSession`,
          {
            priceId: process.env.VUE_APP_STRIPESEAT_PRICEID,
            quantity: this.quantity,
            customer_email: this.$store.state.auth.user.email,
            salonDetails: this.salon.details,
            salonId: this.$store.state.auth.salon._id,
            trial: this.trial,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.loading = false;
        window.location.href = response.data.session.url;
      } catch (error) {
        this.loading = false;
        this.$toast.error(error.message);
      }
    },
    async submit() {
      await this.$store.dispatch('auth/createSalon', this.salon);

      this.createPortal();
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  padding: 64px 0;

  .container {
    max-width: 800px;
    margin: auto;
    padding: 0 16px;
  }

  &_head {
    text-align: center;

    h1 {
      font-size: 32px;
    }
    p {
      margin-top: 8px;
    }
    .link {
      color: var(--clr-link);
      cursor: pointer;
    }
  }

  .form {
    margin-top: 32px;

    &_section {
      padding: 32px;
      border: 1px solid var(--clr-light-2);
      border-radius: 5px;

      &:not(:first-child) {
        margin-top: 16px;
      }

      &_head {
        margin-bottom: 16px;

        p {
          font-size: 14px;
          margin-top: 5px;
          color: var(--clr-gray);
        }
      }

      .col-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
      }

      &_body {
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;

        .col {
          display: grid;
          grid-template-columns: 1fr;
          gap: 16px;
        }

        .toggle {
          display: flex;
          align-items: center;
          gap: 32px;
          flex-wrap: wrap;
          padding: 16px 0;

          &_text {
            &_label {
              font-size: 18px;
            }
            &_subtext {
              margin-top: 5px;
              font-size: 14px;
            }
          }
        }
      }
    }

    &_actions {
      margin-top: 16px;
      text-align: right;

      button:not(:first-child) {
        margin-left: 16px;
      }
    }
  }
}

.pricing {
  padding: 64px 16px;
}
</style>
