<template>
  <form @submit.prevent="submit" class="form">
    <BaseInput
      label="Title"
      placeholder="10% off"
      :value="newDiscount.title"
      @input="newDiscount.title = $event"
    />
    <BaseSelect
      label="Minimum role"
      :options="roleOptions"
      :value="newDiscount.minimumRole"
      @input="newDiscount.minimumRole = $event"
      subtitle="Set which staff roles may use this discount (if their role is set to use discounts at all)"
    />
    <BaseSelect
      label="Discount type"
      :options="typeOptions"
      :value="newDiscount.type"
      @input="newDiscount.type = $event"
      subtitle="Fixed takes off a flat amount, percent a percentage of items total"
    />
    <BaseInput
      label="Amount"
      :value="newDiscount.amount"
      @input="newDiscount.amount = $event"
      :validators="validators.amount"
    />
    <BaseInput
      label="Owner Percent"
      :value="newDiscount.ownerPercent"
      @input="newDiscount.ownerPercent = $event"
      placeholder="%"
      :validators="validators.ownerPercent"
      subtitle="The percentage of the discount that goes to the establishment, commission for the remaining percent will be paid out to the team member performing the item. For example if you have a $100 item and apply a $50 discount with 50% towards the owner percent, the team member will receive commission based off of $75."
    />
    <div class="form_actions">
      <BaseButton
        @click="deleteDiscount"
        v-if="newDiscount._id"
        mode="danger-outline"
        type="button"
        >Delete</BaseButton
      >
      <BaseButton :disabled="!formValid || loading">
        <i v-if="loading" class="fas fa-spinner"></i>
        {{ newDiscount._id ? 'Edit' : 'Create' }}</BaseButton
      >
    </div>
  </form>
</template>

<script>
export default {
  props: {
    discount: {
      type: Object,
      required: false,
    },
  },
  created() {
    if (this.discount)
      this.newDiscount = JSON.parse(JSON.stringify(this.discount));
  },
  data() {
    return {
      loading: false,

      newDiscount: {
        title: '',
        minimumRole: 'low',
        type: 'fixed',
        amount: null,
      },
    };
  },
  computed: {
    validators() {
      return {
        amount: {
          min: 0,
        },
        ownerPercent: {
          min: 0,
          max: 100,
          required: true,
        },
      };
    },
    formValid() {
      if (this.newDiscount.title.length < 2 || this.newDiscount.amount < 0.01)
        return false;

      return true;
    },
    roleOptions() {
      return [
        {
          option: 'Basic',
          value: 'basic',
        },
        {
          option: 'Low',
          value: 'low',
        },
        {
          option: 'Medium',
          value: 'medium',
        },
        {
          option: 'High',
          value: 'high',
        },
      ];
    },
    typeOptions() {
      return [
        {
          option: 'Fixed',
          value: 'fixed',
        },
        {
          option: 'Percent',
          value: 'percent',
        },
      ];
    },
  },
  methods: {
    async deleteDiscount() {
      try {
        await this.$store.dispatch('items/deleteDiscount', this.newDiscount);
        this.submitted(true);
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    async submit() {
      this.loading = true;

      try {
        if (this.newDiscount._id) {
          await this.$store.dispatch('items/editDiscount', this.newDiscount);
          this.submitted();
        } else {
          await this.$store.dispatch('items/createDiscount', this.newDiscount);
          this.submitted();
        }
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
    submitted(deleted) {
      if (
        this.newDiscount.title === 'raservaraservedprepaid' ||
        this.newDiscount.title === 'raservaraservedcashdiscount'
      ) {
        this.$toast.error('Can not use this discount name');
        return;
      }
      this.$emit('submitted');

      if (deleted) {
        this.$toast.success('Discount deleted');
        return;
      }

      if (this.newDiscount._id) {
        this.$toast.success('Discount edited');
      } else {
        this.$toast.success('Discount created');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &_actions {
    margin-top: 16px;
    text-align: right;

    button:not(:first-child) {
      margin-left: 16px;
    }
  }
}
</style>
