<template>
  <Spinner v-if="loading" />

  <section v-else class="users">
    <div class="users_head">
      <div class="col">
        <h2>Users</h2>
      </div>

      <div class="col actions">
        <BaseButton @click="showUserForm = true" class="small"
          >Create</BaseButton
        >
      </div>
    </div>

    <div class="users_body">
      <div v-if="!users?.length" class="none">
        <p>No users to display</p>
      </div>

      <ul v-else>
        <li v-for="user in users" :key="user.id">
          <div class="col">
            <p>
              Name:
              <span class="bold">{{ user.firstName }} {{ user.lastName }}</span>
            </p>
            <p>
              ID: <span class="bold">{{ user.id }}</span>
            </p>
          </div>

          <div class="col">
            <BaseButton
              @click="selectedUser = user"
              mode="primary-outline small"
              >Edit</BaseButton
            >
          </div>
        </li>
      </ul>
    </div>
  </section>

  <Modal
    title="Team Form"
    :show="showUserForm || selectedUser"
    @close="
      showUserForm = false;
      selectedUser = null;
    "
  >
    <div class="userForm">
      <HighLevelUserForm
        :user="selectedUser"
        @submit="formSubmitted($event)"
        @removeUser="removeUser()"
      />
    </div>
  </Modal>
</template>

<script>
import HighLevelUserForm from '@/components/integrations/highLevel/highLevelUserForm.vue';

export default {
  components: {
    HighLevelUserForm,
  },
  created() {
    this.init();
  },
  data() {
    return {
      loading: false,
      users: [],

      showUserForm: false,
      selectedUser: null,
    };
  },
  methods: {
    async init() {
      this.loading = true;

      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/highLevel/users?salonId=${this.$store.state.auth.salon._id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.users = response.data.users;
      } catch (err) {
        console.log(err);
      }

      this.loading = false;
    },

    formSubmitted(user) {
      const index = this.users.findIndex((u) => u.id === user.id);

      if (index === -1) {
        this.users.push(user);
      } else {
        this.users[index] = user;
      }

      this.showUserForm = false;
      this.selectedUser = null;
    },

    removeUser() {
      const index = this.users.findIndex((u) => u.id === this.selectedUser.id);

      if (index !== -1) {
        this.users.splice(index, 1);
      }

      this.showUserForm = false;
      this.selectedUser = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.users {
  &_head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 28px;
    }

    .actions {
      display: flex;
      gap: 16px;
    }
  }

  &_body {
    margin-top: 32px;
    display: flex;
    gap: 16px;

    ul {
      width: 100%;

      li {
        width: 100%;
        padding: 16px;
        border-radius: 5px;
        background-color: var(--clr-white);
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        &:not(:first-child) {
          margin-top: 16px;
        }

        .bold {
          font-weight: 700;
        }
      }
    }
  }
}

.userForm {
  padding: 32px;
}
</style>
