<template>
  <section class="pricingDetails">
    <div class="container">
      <div class="pricingDetails_head">
        <h3>How many team members are in your salon?</h3>
        <p>
          Only team members that need access to your data are required here.
        </p>
      </div>
      <div class="pricingDetails_seats">
        <div
          class="seat"
          :class="{ selected: seats === 1 }"
          @click="changeSeats(1)"
        >
          <p>1</p>
        </div>
        <div
          class="seat"
          :class="{ selected: seats === 2 }"
          @click="changeSeats(2)"
        >
          <p>2</p>
        </div>
        <div
          class="seat"
          :class="{ selected: seats === 3 }"
          @click="changeSeats(3)"
        >
          <p>3</p>
        </div>
        <div
          class="seat"
          :class="{ selected: seats === 4 }"
          @click="changeSeats(4)"
        >
          <p>4</p>
        </div>
        <div
          class="seat"
          :class="{ selected: seats === 5 }"
          @click="changeSeats(5)"
        >
          <p>5 +</p>
        </div>
      </div>
      <div class="pricingDetails_price">
        <h2>{{ formatCurrency(price) }}/mo</h2>
        <BaseButton v-if="!hideTrial" mode="secondary" @click="startFreeTrial()"
          >Start Free Trial</BaseButton
        >
      </div>
      <div class="pricingDetails_details">
        <div class="detail">
          <i class="fas fa-check"></i>
          <p>No contracts, no cancellation fee, and no setup fee.</p>
        </div>
        <div class="detail">
          <i class="fas fa-check"></i>
          <p>Start a free trial now and be up and running in minutes.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  emits: ['selectQuantity'],
  props: {
    hideTrial: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      price: 19.99,
      seats: 1,
    };
  },
  methods: {
    startFreeTrial() {
      if (this.$store.state.auth.user) {
        this.$router.push({
          name: 'SalonRegister',
        });
      } else {
        this.$router.push({
          name: 'StaffRegister',
        });
      }
    },
    formatCurrency(amount) {
      return `$${amount.toFixed(2)}`;
    },
    changeSeats(seats) {
      this.seats = seats;
      this.$emit('selectQuantity', this.seats);

      if (this.seats === 1) {
        this.price = 19.99;
      } else if (this.seats === 2) {
        this.price = 39.99;
      } else if (this.seats === 3) {
        this.price = 59.99;
      } else if (this.seats === 4) {
        this.price = 79.99;
      } else if (this.seats === 5) {
        this.price = 99.99;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 1000px;
  padding: 0 16px;
  margin: auto;
}

.pricingDetails {
  text-align: center;

  &_head {
    h3 {
      font-size: 24px;
    }
    p {
      color: var(--clr-gray);
      margin-top: 8px;
    }
  }
  &_seats {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px;
    margin-top: 32px;

    .selected {
      background-color: var(--clr-secondary);
      color: var(--clr-white);
      border-color: transparent !important;
    }
    .seat {
      max-width: 100px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      padding: 16px 0;
      font-size: 20px;
      font-weight: 700;
      cursor: pointer;
    }
  }
  &_price {
    margin-top: 32px;

    h2 {
      font-size: 36px;
    }

    button {
      margin-top: 32px;
    }
  }
  &_details {
    margin-top: 64px;

    .detail {
      display: flex;
      justify-content: center;
      gap: 32px;
      font-size: 18px;

      i {
        color: var(--clr-success);
      }

      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }
}
</style>
