<template>
  <section class="comparisons">
    <div class="comparison totalAppointments">
      <div class="comparison_head">
        <h2>Total Appointments</h2>
        <h1>{{ appData.appsInSearchRange.length }}</h1>
      </div>
      <div class="comparison_body">
        <p class="small previous">
          <i v-if="appsPercentOfPrevious > 100" class="fas fa-plus"></i>
          <i v-else-if="appsPercentOfPrevious == 100" class="fas fa-minus"></i>
          <i v-else class="fas fa-minus red"></i>
          {{ appsPercentOfPrevious }}% of previous {{ daysBetweenSearch }} day{{
            daysBetweenSearch > 1 ? 's' : ''
          }}
          ({{ appData.appsNotInSearchRange.length }})
        </p>
        <p>
          <span>Completed</span> {{ appsCompleted }} ({{
            appsCompletedPercent
          }}%)
        </p>
        <p>
          <span>Not Completed</span> {{ appsNotCompleted }} ({{
            appsNotCompletedPercent
          }}%)
        </p>
        <p><span>No-show</span> {{ appsNoShow }} ({{ appsNoShowPercent }}%)</p>
      </div>
    </div>

    <div class="comparison totalTickets">
      <div class="comparison_head">
        <h2>Total Tickets</h2>
        <h1>{{ ticketData.ticketsInSearchRange.length }}</h1>
      </div>
      <div class="comparison_body">
        <p class="small previous">
          <i v-if="ticketsPercentOfPrevious > 100" class="fas fa-plus"></i>
          <i
            v-else-if="ticketsPercentOfPrevious == 100"
            class="fas fa-minus"
          ></i>
          <i v-else class="fas fa-minus red"></i>
          {{ ticketsPercentOfPrevious }}% of previous
          {{ daysBetweenSearch }} day{{ daysBetweenSearch > 1 ? 's' : '' }} ({{
            ticketData.ticketsNotInSearchRange.length
          }})
        </p>
        <p>
          <span>Completed</span> {{ ticketsCompleted }} ({{
            ticketsCompletedPercent
          }}%)
        </p>
        <p>
          <span>Refunded</span> {{ ticketsNotCompleted }} ({{
            ticketsNotCompletedPercent
          }}%)
        </p>
        <p>
          <span>With Client</span> {{ ticketsWithClients }} ({{
            ticketsWithClientsPercent
          }}%)
        </p>
      </div>
    </div>

    <div class="comparison sales">
      <div class="comparison_head">
        <h2>Sales</h2>
        <h1>${{ salesDataInRangeTotal }}</h1>
      </div>
      <div class="comparison_body">
        <p class="small previous">
          <i v-if="salesPercentOfPrevious > 100" class="fas fa-plus"></i>
          <i v-else-if="salesPercentOfPrevious == 100" class="fas fa-minus"></i>
          <i v-else class="fas fa-minus red"></i>
          {{ salesPercentOfPrevious }}% of previous
          {{ daysBetweenSearch }} day{{ daysBetweenSearch > 1 ? 's' : '' }} (${{
            salesDataNotInRangeTotal
          }})
        </p>
        <p>
          <span>Services</span> {{ salesDataInRangeServices }} (${{
            salesDataInRangeServicesTotal
          }})
        </p>
        <p>
          <span>Products</span> {{ salesDataInRangeProducts }} (${{
            salesDataInRangeProductsTotal
          }})
        </p>
        <p>
          <span>Online Orders</span>
          {{ salesDataInRangeOnlineOrdersLength }} (${{
            salesDataInRangeOnlineOrders
          }})
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    appointments: {
      type: Array,
    },
    tickets: {
      type: Array,
    },
    from: {
      type: String,
    },
    to: {
      type: String,
    },
    selectedStaffOption: {
      type: String,
      default: 'all',
    },
  },
  watch: {
    appointments() {
      this.init();
    },
    selectedStaffOption() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  computed: {
    daysBetweenSearch() {
      return this.$moment(this.to).diff(this.$moment(this.from), 'days') || 1;
    },

    appsPercentOfPrevious() {
      return (
        (this.appData.appsInSearchRange.length /
          this.appData.appsNotInSearchRange.length || 0) * 100
      ).toFixed(2);
    },
    appsCompleted() {
      return this.appData.appsInSearchRange.filter(
        (app) => app.status === 'arrived'
      ).length;
    },
    appsCompletedPercent() {
      return (
        (this.appsCompleted / this.appData.appsInSearchRange.length || 0) * 100
      ).toFixed(2);
    },
    appsNotCompleted() {
      return this.appData.appsInSearchRange.filter(
        (app) => app.status !== 'arrived'
      ).length;
    },
    appsNotCompletedPercent() {
      return (
        (this.appsNotCompleted / this.appData.appsInSearchRange.length || 0) *
        100
      ).toFixed(2);
    },
    appsNoShow() {
      return this.appData.appsInSearchRange.filter(
        (app) => app.status === 'no show'
      ).length;
    },
    appsNoShowPercent() {
      return (
        (this.appsNoShow / this.appData.appsInSearchRange.length || 0) * 100
      ).toFixed(2);
    },

    ticketsPercentOfPrevious() {
      return (
        (this.ticketData.ticketsInSearchRange.length /
          this.ticketData.ticketsNotInSearchRange.length || 0) * 100
      ).toFixed(2);
    },
    ticketsCompleted() {
      return this.ticketData.ticketsInSearchRange.filter(
        (ticket) => ticket.status === 'completed'
      ).length;
    },
    ticketsCompletedPercent() {
      return (
        (this.ticketsCompleted / this.ticketData.ticketsInSearchRange.length ||
          0) * 100
      ).toFixed(2);
    },
    ticketsNotCompleted() {
      return this.ticketData.ticketsInSearchRange.filter(
        (ticket) => ticket.status !== 'completed'
      ).length;
    },
    ticketsNotCompletedPercent() {
      return (
        (this.ticketsNotCompleted /
          this.ticketData.ticketsInSearchRange.length || 0) * 100
      ).toFixed(2);
    },
    ticketsWithClients() {
      return this.ticketData.ticketsInSearchRange.filter(
        (ticket) => ticket.client
      ).length;
    },
    ticketsWithClientsPercent() {
      return (
        (this.ticketsWithClients /
          this.ticketData.ticketsInSearchRange.length || 0) * 100
      ).toFixed(2);
    },

    salesPercentOfPrevious() {
      return (
        (+this.salesDataInRangeTotal / +this.salesDataNotInRangeTotal || 0) *
        100
      ).toFixed(2);
    },
    salesDataInRangeTotal() {
      let total = 0;

      this.salesData.inSearchRange.forEach(
        (item) => (total += item.item.price)
      );

      return total.toFixed(2);
    },
    salesDataNotInRangeTotal() {
      let total = 0;

      this.salesData.notInSearchRange.forEach(
        (item) => (total += item.item.price)
      );

      return total.toFixed(2);
    },
    salesDataInRangeServices() {
      return this.salesData.inSearchRange.filter((item) => item.item.duration)
        .length;
    },
    salesDataInRangeServicesTotal() {
      let total = 0;

      this.ticketData.ticketsInSearchRange.forEach((ticket) => {
        ticket.items.forEach((item) => {
          if (item.item.duration) total += item.item.price;
        });
      });

      return +total.toFixed(2);
    },
    salesDataInRangeProducts() {
      return this.salesData.inSearchRange.filter((item) => !item.item.duration)
        .length;
    },
    salesDataInRangeProductsTotal() {
      let total = 0;

      this.ticketData.ticketsInSearchRange.forEach((ticket) => {
        ticket.items.forEach((item) => {
          if (!item.item.duration) total += item.item.price;
        });
      });

      return +total.toFixed(2);
    },
    salesDataInRangeOnlineOrders() {
      let total = 0;

      this.ticketData.ticketsInSearchRange.forEach((ticket) => {
        if (ticket.onlineOrder) {
          ticket.items.forEach((item) => {
            total += item.item.price;
          });
        }
      });

      return +total.toFixed(2);
    },
    salesDataInRangeOnlineOrdersLength() {
      let total = 0;

      this.ticketData.ticketsInSearchRange.forEach((ticket) => {
        if (ticket.onlineOrder) {
          total += ticket.items.length;
        }
      });

      return total;
    },
    salesDataNotInRangeServices() {
      return this.salesData.notInSearchRange.filter(
        (item) => item.item.duration
      ).length;
    },
    salesDataNotInRangeProducts() {
      return this.salesData.notInSearchRange.filter(
        (item) => !item.item.duration
      ).length;
    },
  },
  data() {
    return {
      appData: {
        appsInSearchRange: [],
        appsNotInSearchRange: [],
      },

      ticketData: {
        ticketsInSearchRange: [],
        ticketsNotInSearchRange: [],
      },

      salesData: {
        inSearchRange: [],
        notInSearchRange: [],
      },
    };
  },
  methods: {
    init() {
      this.resetData();

      // Appointments
      this.appointments.forEach((appointment) => {
        if (
          this.selectedStaffOption === 'all' ||
          appointment.services.some(
            (service) => service.staff.staffId === this.selectedStaffOption
          )
        ) {
          if (
            this.$moment(appointment.date).isBetween(
              this.from,
              this.to,
              'date',
              '[]'
            )
          ) {
            this.appData.appsInSearchRange.push(appointment);
          } else {
            this.appData.appsNotInSearchRange.push(appointment);
          }
        }
      });

      // Tickets
      this.tickets.forEach((ticket) => {
        if (
          this.selectedStaffOption === 'all' ||
          ticket.items.some(
            (item) => item.staff?.staffId === this.selectedStaffOption
          )
        ) {
          if (
            this.$moment(ticket.checkout_at).isBetween(
              this.from,
              this.to,
              'date',
              '[]'
            )
          ) {
            this.ticketData.ticketsInSearchRange.push(ticket);

            this.getSalesFromTicket(ticket, true);
          } else {
            this.ticketData.ticketsNotInSearchRange.push(ticket);

            this.getSalesFromTicket(ticket);
          }
        }
      });
    },

    getSalesFromTicket(ticket, inRange) {
      ticket.items.forEach((item) => {
        if (
          this.selectedStaffOption === 'all' ||
          item.staff?.staffId === this.selectedStaffOption
        ) {
          if (inRange) {
            this.salesData.inSearchRange.push(item);
          } else {
            this.salesData.notInSearchRange.push(item);
          }
        }
      });
    },

    resetData() {
      this.appData.appsInSearchRange = [];
      this.appData.appsNotInSearchRange = [];
      this.ticketData.ticketsInSearchRange = [];
      this.ticketData.ticketsNotInSearchRange = [];
      this.salesData.inSearchRange = [];
      this.salesData.notInSearchRange = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.comparisons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;

  .comparison {
    padding: 24px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;

    .small {
      font-size: 14px;
    }

    &_head {
      h1 {
        color: var(--clr-secondary);
        margin-top: 6px;
        font-size: 36px;
        font-weight: 500;
      }
    }
    &_body {
      margin-top: 6px;

      .previous {
        margin-bottom: 12px;
        font-weight: 400;

        i {
          font-size: 12px;
        }
        .fa-plus {
          color: var(--clr-success);
        }
        .red {
          color: var(--clr-danger);
        }
      }
      p {
        font-weight: 700;

        &:not(:first-child) {
          margin-top: 3px;
        }

        span {
          font-weight: 400;
          color: var(--clr-gray);
        }
      }
    }
  }
}
</style>
