<template>
  <div class="subscriptions">
    <Table :headers="headers" :data="data" />
  </div>
</template>

<script>
export default {
  props: {
    subscriptions: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.subscriptions.forEach((sub) => {
      this.data.push({
        salon: sub.salonId.details.shopName,
        subscription: sub.package.title,
        renewals: sub.history.length,
        price: `$${sub.price.toFixed(2)}`,
        nextDate: this.formatDate(sub.nextBillingDate),
      });
    });
  },
  computed: {
    headers() {
      return [
        'Salon',
        'Subscription',
        '# of Renewals',
        'Price',
        'Next Bill Date',
      ];
    },
  },
  data() {
    return {
      data: [],
    };
  },
  methods: {
    formatDate(date) {
      return this.$moment(date).format('LL');
    },
  },
};
</script>

<style lang="scss" scoped>
.subscriptions {
  border: 1px solid var(--clr-light);
}
</style>
