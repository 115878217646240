<template>
  <section class="members">
    <h2 class="page-title" ref="members">Select a staff member</h2>
    <div class="members_members" v-if="bookingStaffs.length">
      <div class="nopref" @click="selectStaff(null)">
        <div class="col">
          <p class="bold">No preference</p>
          <div class="gray">
            Want the first available appointment? Choose this option to see all
            staff members availability.
          </div>
        </div>
        <div class="col">
          <p class="link bold">Book Now</p>
        </div>
      </div>
      <ul>
        <li>
          <BookingMemberItem
            v-for="staff in bookingStaffsThatCanDoSelectedServices"
            :key="staff"
            :staff="staff"
            @click="selectStaff(staff)"
          />
        </li>
      </ul>
    </div>
    <div v-else class="none">
      <p class="bold">
        Sorry! None of our staff members can perform your selected services.
      </p>
      <p class="gray">Try selecting different services to continue.</p>
      <BaseButton @click="prevStep" mode="danger-outline">Go Back</BaseButton>
    </div>
  </section>

  <!-- THE BELOW IS THE START OF HAVING MULTIPLE TECHS ON ONE APPOINTMENT  -->

  <!-- <section class="members">
    <h2 class="page-title" ref="members">Select a staff member</h2>
    <div class="members_members">
      <div class="nopref" @click="selectStaff(null)">
        <div class="col">
          <p class="bold">No preference</p>
          <div class="gray">
            Want the first available appointment? Choose this option to see all
            staff members availability.
          </div>
        </div>
        <div class="col">
          <p class="link bold">Book Now</p>
        </div>
      </div>
      <ul>
        <li>
          <BookingMemberItem
            v-for="staff in bookingStaffs"
            :key="staff"
            :staff="staff"
            @click="selectStaff(staff)"
          />
        </li>
      </ul>
    </div>
  </section> -->

  <BookingContinue
    showBack
    hideContinue
    @continue="nextStep"
    @back="prevStep"
  />
</template>

<script>
import BookingMemberItem from '@/components/booking/BookingMemberItem.vue';
import BookingContinue from '@/components/booking/BookingContinue.vue';

export default {
  components: {
    BookingMemberItem,
    BookingContinue,
  },
  async mounted() {
    if (this.$store.state.booking.confirmed) {
      this.$toast.success('Booking was confirmed!');

      this.$router.push({
        name: 'BookConfirm',
        params: { id: this.salonId },
      });

      return;
    }

    if (!this.$store.state.booking.currentBookingTracking) {
      this.$store.dispatch('booking/createBookingTracking', 'Select Member');
    } else {
      this.$store.dispatch('booking/updateBookingTracking', 'Select Member');
    }

    if (this.$refs.members) {
      this.$refs.members.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }

    await this.oneStaffCanDoAllServicesCheck();
  },
  data() {
    return {
      oneStaffCanDoAllServices: false,
    };
  },
  computed: {
    salonId() {
      if (!this.$store.state.booking.salon) return;

      return this.$store.state.booking.salon._id;
    },
    staffs() {
      if (!this.$store.state.booking.salon) return;

      return this.$store.state.booking.salon.staff;
    },
    bookingStaffs() {
      return this.staffs.filter((staff) => staff.onBooking);
    },
    bookingStaffsThatCanDoSelectedServices() {
      return this.bookingStaffs.filter((staff) =>
        this.canStaffDoServices(staff)
      );
    },
    selectedServices() {
      return this.$store.state.booking.selectedServices;
    },
    selectedDate() {
      return this.$store.state.booking.selectedDate;
    },
    selectedTime() {
      return this.$store.state.booking.selectedTime;
    },
  },
  methods: {
    canStaffDoServices(staff) {
      if (
        staff.services &&
        this.$store.state.booking.selectedServices.every((selectedService) => {
          return staff.services.find(
            (service) => service === selectedService._id
          );
        })
      ) {
        return true;
      }
    },
    async oneStaffCanDoAllServicesCheck() {
      let oneStaffCanDoAllServices = false;

      for (let bookingStaff of this.$store.getters['booking/bookingStaff']) {
        const canDoServices = await this.canStaffDoServices(bookingStaff);

        if (canDoServices) {
          oneStaffCanDoAllServices = true;
          // await this.getAvailableTimeFromStaff(bookingStaff);
        }
      }

      this.oneStaffCanDoAllServices = oneStaffCanDoAllServices;
    },
    selectStaff(staff) {
      if (staff) {
        this.$store.state.booking.requestedStaff = true;
      } else {
        if (!this.$store.state.booking.currentBookingTracking) {
          this.$store.dispatch(
            'booking/createBookingTracking',
            'Selected No Preference'
          );
        } else {
          this.$store.dispatch(
            'booking/updateBookingTracking',
            'Selected No Preference'
          );
        }
      }

      this.$store.dispatch('booking/selectStaff', staff);

      if (this.selectedServices.length) {
        this.$router.push({ name: 'BookDate', params: { id: this.salonId } });
      } else {
        this.$router.push({
          name: 'BookServices',
          params: { id: this.salonId },
        });
      }
    },
    prevStep() {
      this.$router.push({
        name: 'BookServices',
        params: { id: this.salonId },
      });
    },
    nextStep() {
      if (!this.selectedTime || !this.selectedDate) {
        this.$router.push({
          name: 'BookDate',
          params: { id: this.salonId },
        });
      } else {
        this.$router.push({
          name: 'BookConfirm',
          params: { id: this.salonId },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.members {
  .page-title {
    text-align: center;
    margin-left: 0;
  }
  h2 {
    margin-left: 16px;
  }

  &_members {
    margin-top: 32px;

    .member:not(first-child) {
      margin-top: 16px;
    }

    .nopref {
      padding: 24px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      display: flex;
      gap: 32px;
      justify-content: space-between;
      cursor: pointer;
      transition: all 0.2s;
      background-color: white;

      &:hover {
        border-color: var(--clr-link);
      }
    }
  }
}

.bold {
  font-weight: 700;
}
.link {
  color: var(--clr-link);
}
.gray {
  margin-top: 5px;
  color: var(--clr-gray);
  font-size: 14px;
}

.none {
  padding: 24px;
  padding: 16;

  button {
    margin-top: 32px;
  }
}

// Tablet
@media (max-width: 900px) {
  .members {
    .nopref {
      flex-direction: column;
    }
  }
}
</style>
