<template>
  <div class="invoicePage">
    <div class="invoicePage_body">
      <div class="invoicePage_body_logo">
        <TheLogo />
      </div>
      <div class="invoicePage_body_invoice">
        <h2>Your appointment has been confirmed!</h2>
        <p>
          Thank you for confirming your appointment, this lets the establishment
          know you are able to make it to your appointment and truly does help
          out!
        </p>
        <router-link :to="{ name: 'ClientAuth' }">
          <BaseButton>View Your Profile</BaseButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.init();
  },
  methods: {
    async init() {
      try {
        await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/appointments/confirm/${this.$route.params.id}`
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.invoicePage {
  background-color: var(--clr-light);
  min-height: 100vh;
  display: grid;
  place-items: center;
  padding: 16px;

  &_body {
    &_logo {
      text-align: center;
      margin-bottom: 32px;
    }

    &_invoice {
      padding: 32px;
      border-radius: 5px;
      box-shadow: var(--shadow);
      background-color: var(--clr-white);

      text-align: center;
      max-width: 750px;

      p {
        margin-top: 16px;
        line-height: 24px;
      }
      button {
        margin-top: 32px;
      }
    }
  }
}
</style>
