<template>
  <div class="giftcard">
    <div class="giftcard_img"></div>
    <div class="giftcard_body">
      <h1>${{ giftcard.amount.toFixed(2) }}</h1>
      <p class="small">Remaining</p>
      <div class="giftcard_body_code">
        <p>{{ giftcard.code }}</p>
      </div>
      <p class="small bold">{{ giftcard.salonId.details.shopName }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    giftcard: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: 700;
}
.small {
  font-size: 14px;
}
.light {
  color: var(--clr-gray);
}

.giftcard {
  display: flex;
  justify-content: space-between;
  gap: 32px;
  max-width: 400px;
  box-shadow: var(--shadow);
  background-color: var(--clr-white);
  border-radius: 16px;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    transition: transform 0.2s;
  }

  // &:not(:first-child) {
  //   margin-top: 16px;
  // }

  &_img {
    width: 200px;
    background-image: repeating-radial-gradient(
        circle at 0 0,
        transparent 0,
        #3f7f8a 10px
      ),
      repeating-linear-gradient(rgba(105, 109, 190, 0.333), #8286c5);
  }

  &_body {
    text-align: right;
    padding: 16px;

    h1 {
      font-weight: 400;
      font-size: 36px;
    }

    &_code {
      background-color: white;
      margin: 16px 0;
      padding: 8px 12px;
      border: 1px solid var(--clr-light);
    }
  }
}
</style>
