<template>
  <section class="bar">
    <div class="actions">
      <BaseSelect
        :options="rangeOptions"
        :value="selectedRangeOption"
        label="Date Range"
        @input="changeDateRange($event)"
      />
      <BaseInput
        inputType="date"
        label="Starting"
        :value="formattedStartingDate"
        @input="updateStarting($event)"
      />
      <BaseInput
        inputType="date"
        label="Ending"
        :value="formattedEndingDate"
        @input="updateEnding($event)"
      />
      <slot></slot>
    </div>
    <div class="col">
      <BaseButton @click="search">Search</BaseButton>
    </div>
  </section>
</template>

<script>
export default {
  emits: ['search'],
  props: {
    start: {
      type: Date,
    },
    end: {
      type: Date,
    },
  },
  created() {
    if (this.start) this.startingDate = this.start;
    if (this.end) this.endingDate = this.end;
  },
  data() {
    return {
      startingDate: this.$moment(),
      endingDate: this.$moment(),

      rangeOptions: [
        {
          value: 'today',
          option: 'Today',
        },
        {
          value: 'yesterday',
          option: 'Yesterday',
        },
        {
          value: 'lastSevenDays',
          option: 'Last 7 Days',
        },
        {
          value: 'lastFourteenDays',
          option: 'Last 14 Days',
        },
        {
          value: 'lastThirtyDays',
          option: 'Last 30 Days',
        },
        {
          value: 'lastMonth',
          option: 'Last Month',
        },
        {
          value: 'thisMonth',
          option: 'Month To Date',
        },
        {
          value: 'thisYear',
          option: 'Year To Date',
        },
        {
          value: 'custom',
          option: 'Custom',
        },
      ],
      selectedRangeOption: 'today',
    };
  },
  computed: {
    formattedStartingDate() {
      return this.startingDate.format('YYYY-MM-DD');
    },
    formattedEndingDate() {
      return this.endingDate.format('YYYY-MM-DD');
    },
  },
  methods: {
    updateStarting(dateString) {
      this.startingDate = this.$moment(dateString);
    },
    updateEnding(dateString) {
      this.endingDate = this.$moment(dateString);
    },
    search() {
      this.$emit('search', {
        starting: this.startingDate.startOf('day').toDate(),
        ending: this.endingDate.endOf('day').toDate(),
      });
    },
    changeDateRange(range) {
      if (range === 'custom') return;

      if (range === 'today') {
        this.startingDate = this.$moment();
        this.endingDate = this.$moment();
      } else if (range === 'yesterday') {
        this.startingDate = this.$moment().subtract(1, 'days');
        this.endingDate = this.$moment().subtract(1, 'days');
      } else if (range === 'lastSevenDays') {
        this.startingDate = this.$moment().subtract(1, 'weeks');
        this.endingDate = this.$moment();
      } else if (range === 'lastFourteenDays') {
        this.startingDate = this.$moment().subtract(2, 'weeks');
        this.endingDate = this.$moment();
      } else if (range === 'lastThirtyDays') {
        this.startingDate = this.$moment().subtract(30, 'days');
        this.endingDate = this.$moment();
      } else if (range === 'lastMonth') {
        this.startingDate = this.$moment()
          .subtract(1, 'month')
          .startOf('month');
        this.endingDate = this.$moment().subtract(1, 'month').endOf('month');
      } else if (range === 'thisMonth') {
        this.startingDate = this.$moment().startOf('month');
        this.endingDate = this.$moment();
      } else if (range === 'thisYear') {
        this.startingDate = this.$moment().startOf('year');
        this.endingDate = this.$moment();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bar {
  padding: 16px;
  border-radius: 5px;
  background-color: var(--clr-extra-light);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;

  .actions {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
}

// Tablet
@media (max-width: 900px) {
  .bar {
    flex-direction: column;

    .actions {
      flex-direction: column;
      width: 100%;
    }

    .col {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
}
</style>
