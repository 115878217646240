import axios from 'axios';
import moment from 'moment-timezone';

import { useToast } from 'vue-toastification';
const toast = useToast();

export default {
  namespaced: true,
  state: {
    selectedServices: [],
    selectedStaff: null,
    selectedDate: null,
    selectedTime: null,
    activeClient: null,
    confirmed: false,
    requestedStaff: false,
    appointmentNote: '',
    coupon: null,
    isSalonDiscount: false,
    salon: null,
    mrkref: null,
    chain: [],
    cart: [],
    upFrontPayments: [],
    uploads: [],
    dbAppointment: null,

    cardconnectToken: null,
    cardconnectExpiry: null,
    cardconnectCVV: null,
    cardconnectPostal: null,

    chargeanywhereToken: null,
    chargeanywhereExpiry: null,
    chargeanywhereCVV: null,
    chargeanywhereSaveCard: false,
    chargeanywhereLastFour: null,

    dejavooToken: null,

    currentBookingTracking: null,

    savedBookingData: {
      selectedServices: [],
      selectedStaff: null,
      selectedDate: null,
      selectedTime: null,
      confirmed: false,
      requestedStaff: false,
    },
  },
  mutations: {
    addService(state, payload) {
      state.selectedServices.push(payload);
    },
    removeService(state, payload) {
      state.selectedServices.splice(payload, 1);
    },
    selectStaff(state, payload) {
      state.selectedStaff = payload;

      state.selectedDate = null;
      state.selectedTime = null;
    },
    clearDateAndTime(state) {
      state.selectedDate = null;
      state.selectedTime = null;
    },
    setActiveClient(state, payload) {
      state.activeClient = payload;
      localStorage.setItem('clientId', payload._id);
    },
    clearActiveClient(state) {
      state.activeClient = null;
      localStorage.removeItem('clientId');
    },
  },
  actions: {
    clearData({ state }) {
      // Save booking data in case of return
      state.savedBookingData.selectedServices = JSON.parse(
        JSON.stringify(state.selectedServices)
      );
      state.savedBookingData.selectedStaff = JSON.parse(
        JSON.stringify(state.selectedStaff)
      );
      state.savedBookingData.selectedDate = JSON.parse(
        JSON.stringify(state.selectedDate)
      );
      state.savedBookingData.selectedTime = JSON.parse(
        JSON.stringify(state.selectedTime)
      );
      state.savedBookingData.confirmed = JSON.parse(
        JSON.stringify(state.confirmed)
      );
      state.savedBookingData.requestedStaff = JSON.parse(
        JSON.stringify(state.requestedStaff)
      );

      // Clear
      state.selectedServices = [];
      state.selectedStaff = null;
      state.selectedDate = null;
      state.selectedTime = null;
      state.confirmed = false;
      state.requestedStaff = false;
    },

    setDataToSavedData({ state }) {
      state.selectedServices = JSON.parse(
        JSON.stringify(state.savedBookingData.selectedServices)
      );
      state.selectedStaff = JSON.parse(
        JSON.stringify(state.savedBookingData.selectedStaff)
      );
      state.selectedDate = JSON.parse(
        JSON.stringify(state.savedBookingData.selectedDate)
      );
      state.selectedTime = JSON.parse(
        JSON.stringify(state.savedBookingData.selectedTime)
      );
      state.confirmed = JSON.parse(
        JSON.stringify(state.savedBookingData.confirmed)
      );
      state.requestedStaff = JSON.parse(
        JSON.stringify(state.savedBookingData.requestedStaff)
      );
    },

    async selectService({ state, commit, dispatch }, payload) {
      let useCart = false;

      if (
        state.salon.billing.chargeanywhere &&
        state.salon.billing.chargeanywhere.collectBookingPercentUpFront
      ) {
        useCart = true;
      }

      if (payload.forceAdd) {
        // Add service to appointment
        if (state.selectedServices.length >= 5) {
          toast.warning('You can only select 5 services at a time');
          return;
        }

        commit('addService', payload);

        if (useCart) state.cart.push(payload);
      } else {
        // Check if need to add or remove
        const index = state.selectedServices.findIndex(
          (service) => service._id === payload._id
        );
        if (index !== -1) {
          // Remove service from appointment
          commit('removeService', index);

          if (useCart) {
            const index = state.cart.findIndex((i) => i._id === payload._id);

            if (index !== -1) state.cart.splice(index, 1);
          }

          await dispatch(
            'updateBookingTracking',
            `De-Selected Service: ${payload.title}`
          );
        } else {
          // Add service to appointment
          if (state.selectedServices.length >= 5) {
            toast.warning('You can only select 5 services at a time');
            return;
          }

          commit('addService', payload);

          if (useCart) state.cart.push(payload);
        }
      }

      commit('clearDateAndTime');

      if (!state.currentBookingTracking) {
        await dispatch(
          'createBookingTracking',
          `Selected Service: ${payload.title}`
        );
      } else {
        await dispatch(
          'updateBookingTracking',
          `Selected Service: ${payload.title}`
        );
      }
    },
    async selectStaff({ commit, dispatch, state }, payload) {
      if (!state.currentBookingTracking) {
        await dispatch('createBookingTracking', 'Select Staff');
      }

      commit('selectStaff', payload);

      if (!state.currentBookingTracking) {
        if (!payload) {
          dispatch('createBookingTracking', `Selected Staff: No Preference`);
        } else {
          dispatch(
            'createBookingTracking',
            `Selected Staff: ${payload.firstName} ${payload.lastName}`
          );
        }
      } else {
        if (!payload) {
          dispatch('updateBookingTracking', `Selected Staff: No Preference`);
        } else {
          dispatch(
            'updateBookingTracking',
            `Selected Staff: ${payload.firstName} ${payload.lastName}`
          );
        }
      }
    },

    async fulfillOrder({ state }, payload) {
      const items = [];

      state.cart.forEach((item) => {
        items.push({
          item,
        });
      });

      const order = {
        salonId: state.salon._id,
        items,
        client: state.activeClient,
        payments: [payload],
      };

      try {
        await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/orders`,
          order
        );

        state.cart = [];
      } catch (error) {
        throw Error(error.message);
      }
    },

    async bookAppointment({ state, getters, dispatch }) {
      if (!state.selectedServices.length) {
        toast.error('Please select one or more services');
        return;
      }
      if (!state.selectedDate) {
        toast.error('Please select a date and time');
        return;
      }

      // Cant destructure bc linter doesnt like unused vars
      const {
        tickets,
        giftcards,
        subscriptions,
        packages,
        messages,
        ...client
      } = state.activeClient;

      console.dir({ tickets, giftcards, subscriptions, packages, messages });

      const setRequested = state.salon.bookingSettings.useRequestedStaffIcon;

      const services = [];
      let start = getters.getAppointmentDateTime;
      let skipDoubleBookCheck = false;

      if (!state.chain.length) {
        state.selectedServices.forEach((service) => {
          if (!service.staff) {
            toast.error(
              'There is an error booking this appointment, please start over and try again.'
            );
            return;
          }

          services.push({
            start: moment(start).format('kk:mm'),
            service,
            duration: service.duration || 15,
            // staff: service.staff,
            staff: {
              _id: service.staff._id,
              staffId: service.staff.staffId,
              commission: service.staff.commission,
              firstName: service.staff.firstName,
              lastName: service.staff.lastName,
              email: service.staff.email,
              phoneNumber: service.staff.phoneNumber,
            },
            requested: state.requestedStaff && setRequested,
          });

          start = moment(start)
            .add(service.duration + service.extraProcessingTime, 'minutes')
            .toDate();
        });
      } else {
        state.chain.forEach((link) => {
          if (!link.staff) {
            toast.error(
              'There is an error booking this appointment, please start over and try again.'
            );
            return;
          }

          const service = link.service;

          services.push({
            start: moment(start).format('kk:mm'),
            service,
            duration: service.duration || 15,
            staff: {
              _id: link.staff._id,
              staffId: link.staff.staffId,
              commission: link.staff.commission,
              firstName: link.staff.firstName,
              lastName: link.staff.lastName,
              email: link.staff.email,
              phoneNumber: link.staff.phoneNumber,
            },
            requested: false,
          });

          start = moment(start)
            .add(service.duration + service.extraProcessingTime, 'minutes')
            .toDate();
        });

        skipDoubleBookCheck = true;
      }

      const momentedSelectedDate = moment(state.selectedDate);

      const startTimeText = `${moment(getters.getAppointmentDateTime).format(
        'LLL zz'
      )}${moment().tz(moment.tz.guess()).format('z')}`;

      const appointment = {
        created_at: moment().toDate(),
        originalData: {
          date: moment(state.selectedDate).toDate(),
          services,
        },
        bookedOnline: true,
        services,
        note: state.appointmentNote,
        coupon: state.coupon,
        isSalonDiscount: state.isSalonDiscount,
        date: moment(momentedSelectedDate).toDate(),
        client,
        status: 'new',
        mrkref: state.mrkref,
        billing: {
          cardconnect: {
            token: state.cardconnectToken,
            expiry: state.cardconnectExpiry,
            cvv2: state.cardconnectCVV,
            postal: state.cardconnectPostal,
          },
          chargeanywhere: {
            token: state.chargeanywhereToken,
            expiry: state.chargeanywhereExpiry,
            cvv: state.chargeanywhereCVV,
            lastFour: state.chargeanywhereLastFour,
          },
          dejavoo: {
            token: state.dejavooToken,
          },
          deposits: state.upFrontPayments,
        },
        startTimeText,
        skipDoubleBookCheck,
        saveCard: state.chargeanywhereSaveCard,
        files: {
          photos: state.uploads,
        },
      };

      if (state.chargeanywhereSaveCard) {
        if (!state.activeClient.paymentMethods)
          state.activeClient.paymentMethods = [];

        state.activeClient.paymentMethods.push({
          salonId: state.salon._id,
          processor: 'chargeanywhere',
          token: state.chargeanywhereToken,
          expiry: state.chargeanywhereExpiry,
          cvv: state.chargeanywhereCVV,
          lastFour: state.chargeanywhereLastFour,
        });
      }

      if (state.mrkref) {
        appointment.note += ` (Ref: ${state.mrkref})`;
      }
      if (state.isSalonDiscount) {
        const discount = state.salon.discounts.find(
          (d) => d._id.toString() === state.coupon
        );

        if (discount)
          appointment.note += ` (Booking Marketing Discount: ${discount.title})`;
      }

      try {
        const dbAppointment = await dispatch(
          'appointments/createAppointment',
          { ...appointment, fromBooking: true },
          {
            root: true,
          }
        );
        state.confirmed = true;
        state.uploads.length = 0;

        state.dbAppointment = dbAppointment;

        await dispatch('updateBookingTracking', 'Confirmed');
        state.currentBookingTracking = null;
      } catch (err) {
        console.log(err);
        await dispatch('updateBookingTracking', err.message);
        toast.error(err.message);
      }
    },

    // Analytics Tracking
    async createBookingTracking({ state }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/analytics`,
          {
            salonId: state.salon._id,
            clientId: state.activeClient?._id,
            steps: [`${payload} (${moment().format('kk:mm')})`],
            type: 'booking',
          }
        );

        state.currentBookingTracking = response.data;
      } catch (error) {
        console.log(error.message);
      }
    },

    async updateBookingTracking({ state }, payload) {
      try {
        if (!state.currentBookingTracking) return;

        state.currentBookingTracking.steps.push(
          `${payload} (${moment().format('kk:mm')})`
        );

        if (payload === 'Confirmed') {
          state.currentBookingTracking.appointmentId = state.dbAppointment._id;
        }

        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/analytics/${state.currentBookingTracking._id}`,
          {
            ...state.currentBookingTracking,
            clientId: state.activeClient?._id,
          }
        );

        state.currentBookingTracking = response.data;
        state.dbAppointment = null;
      } catch (error) {
        console.log(error.message);
      }
    },

    async sendBugReport({ state }, payload) {
      try {
        if (!state.currentBookingTracking) return;
        if (!state.currentBookingTracking.notes)
          state.currentBookingTracking.notes = [];

        state.currentBookingTracking.notes.push(payload.text);

        await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/analytics/${state.currentBookingTracking._id}`,
          {
            ...state.currentBookingTracking,
            clientId: state.activeClient?._id,
          }
        );
      } catch (error) {
        console.log(error.message);
      }
    },
  },
  getters: {
    bookingStaff(state) {
      return state.salon.staff.filter((staff) => staff.onBooking);
    },
    getAppointmentDateTime(state) {
      const startHour = +state.selectedTime.split(':')[0];
      const startMinute = +state.selectedTime.split(':')[1];

      return moment(state.selectedDate)
        .hour(startHour)
        .minute(startMinute)
        .toDate();
    },
    formattedDate(state) {
      if (!state.selectedDate) return null;

      return moment(state.selectedDate).format('dddd, MMMM Do YYYY');
    },
    formattedTime(state) {
      if (!state.selectedTime) return null;

      const hour = +state.selectedTime.split(':')[0];
      const minute = +state.selectedTime.split(':')[1];

      return moment().hour(hour).minute(minute).format('LT');
    },
    getAppointmentEndTime(state) {
      if (!state.selectedTime || !state.selectedDate) return null;

      const hour = +state.selectedTime.split(':')[0];
      const minute = +state.selectedTime.split(':')[1];

      let startTime = moment().hour(hour).minute(minute);

      let duration = 0;
      state.selectedServices.forEach((service) => {
        duration += service.duration;
        duration += service.extraProcessingTime || 0;
      });

      return startTime.add(duration, 'minutes').format('LT');
    },
    getAppointmentTotal(state) {
      let total = 0;

      state.selectedServices.forEach((service) => {
        total += service.price;
      });

      return total;
    },
    servicesDuration(state) {
      let duration = 0;

      state.selectedServices.forEach(
        (service) => (duration += service.duration)
      );

      return duration;
    },
    servicesDurationWithProcessingTime(state) {
      let duration = 0;

      state.selectedServices.forEach(
        (service) =>
          (duration += service.duration + service.extraProcessingTime)
      );

      return duration;
    },
    servicesDurationWithBlockedTime(state) {
      let duration = 0;

      state.selectedServices.forEach(
        (service) => (duration += service.duration + service.extraBlockedTime)
      );

      return duration;
    },
    calendarServices(state) {
      const categories = [];

      const salon = JSON.parse(JSON.stringify(state.salon));

      salon.services.forEach((category) => {
        category.items = category.items.filter((item) => item.onBooking);
        categories.push(category);
      });

      return categories;
    },
  },
};
