<template>
  <section class="invoice" id="staffSalesInvoice">
    <div class="invoice_head">
      <h2>{{ salonName }}</h2>
      <h3>{{ staffName }}</h3>
      <p>{{ formatDate(start) }} - {{ formatDate(end) }}</p>
      <p>Printed: {{ now }}</p>
    </div>
    <div class="invoice_body">
      <table v-if="detailedMode">
        <!-- Total -->
        <tr>
          <td>Total Earnings</td>
          <td class="bold">{{ data.totalEarnings }}</td>
        </tr>
        <!-- Items -->
        <tr>
          <td>Total Items</td>
          <td>{{ data.itemsSold }}</td>
        </tr>
        <!-- Net Sales -->
        <tr>
          <td>Net Sales (Services)</td>
          <td>{{ data.netSalesServices }}</td>
        </tr>
        <tr>
          <td>Net Sales (Products)</td>
          <td>{{ data.netSalesProducts }}</td>
        </tr>
        <!-- Refunds -->
        <tr>
          <td>Refunds (Services)</td>
          <td>{{ data.refundsServices }}</td>
        </tr>
        <tr>
          <td>Refunds (Products)</td>
          <td>{{ data.refundsProducts }}</td>
        </tr>
        <!-- Fees -->
        <tr>
          <td>Item Fees</td>
          <td>{{ data.fees }}</td>
        </tr>
        <tr>
          <td>Daily Fees</td>
          <td>{{ data.dailyFees }}</td>
        </tr>
        <!-- Discounts -->
        <tr>
          <td>Discounts (Services)</td>
          <td>{{ data.discountsServices }}</td>
        </tr>
        <tr>
          <td>Discounts (Products)</td>
          <td>{{ data.discountsProducts }}</td>
        </tr>
        <!-- Tips -->
        <tr>
          <td>Tips</td>
          <td>{{ data.tips }}</td>
        </tr>
        <!-- Flat Commission -->
        <tr>
          <td>Fixed Com (Services)</td>
          <td>{{ data.flatComServices }}</td>
        </tr>
        <tr>
          <td>Fixed Com (Products)</td>
          <td>{{ data.flatComProducts }}</td>
        </tr>
        <tr>
          <td>Fixed Com Total</td>
          <td>{{ flatCom }}</td>
        </tr>
        <!-- Earnings -->
        <tr>
          <td>Percent Com</td>
          <td>{{ data.commission }}</td>
        </tr>
        <tr>
          <td>Total Com</td>
          <td>{{ data.totalCommission }}</td>
        </tr>
        <tr>
          <td>Hourly</td>
          <td class="bold">{{ data.hourly }}</td>
        </tr>
        <!-- Split -->
        <tr>
          <td>Check + Tips</td>
          <td class="bold">{{ splitCheckPlusTips }}</td>
        </tr>
        <tr>
          <td>Other</td>
          <td class="bold">{{ splitOther }}</td>
        </tr>
      </table>

      <table v-else>
        <!-- Total -->
        <tr>
          <td>Total Earnings</td>
          <td class="bold">{{ data.totalEarnings }}</td>
        </tr>
        <!-- Items -->
        <tr>
          <td>Total Items</td>
          <td>{{ data.itemsSold }}</td>
        </tr>
        <!-- Net Sales -->
        <tr>
          <td>Net Sales</td>
          <td>{{ netSalesTotal }}</td>
        </tr>
        <!-- Refunds -->
        <tr>
          <td>Refunds</td>
          <td>{{ refundsTotal }}</td>
        </tr>
        <!-- Fees -->
        <tr>
          <td>Item Fees</td>
          <td>{{ data.fees }}</td>
        </tr>
        <tr>
          <td>Daily Fees</td>
          <td>{{ data.dailyFees }}</td>
        </tr>
        <!-- Discounts -->
        <tr>
          <td>Discounts</td>
          <td>{{ discountsTotal }}</td>
        </tr>
        <!-- Tips -->
        <tr>
          <td>Tips</td>
          <td>{{ data.tips }}</td>
        </tr>
        <!-- Commission -->
        <tr>
          <td>Commission</td>
          <td>{{ data.totalCommission }}</td>
        </tr>
        <!-- Hourly -->
        <tr>
          <td>Hourly</td>
          <td class="bold">{{ data.hourly }}</td>
        </tr>
        <!-- Unique Days -->
        <tr>
          <td>Days w/ Tickets</td>
          <td class="bold">{{ data.uniqueDays }}</td>
        </tr>
        <!-- Split -->
        <tr>
          <td>Check + Tips</td>
          <td class="bold">{{ splitCheckPlusTips }}</td>
        </tr>
        <tr>
          <td>Other</td>
          <td class="bold">{{ splitOther }}</td>
        </tr>
      </table>

      <!-- Items -->
      <table class="itemsTable" v-if="printItems">
        <tr>
          <td class="bold">Ticket</td>
          <td class="bold">Price/Tip</td>
          <td class="bold">Date</td>
        </tr>
        <template
          v-for="ticket in itemsTableData"
          :key="ticket.ticket"
          class="test"
        >
          <tr>
            <td class="bold">{{ ticket.ticket }}</td>
            <td>${{ ticket.tip }}</td>
            <td class="bold">{{ ticket.date }}</td>
          </tr>
          <tr v-for="(item, index) in ticket.items" :key="index">
            <td>{{ item.name }}</td>
            <td>${{ item.price }}</td>
            <td></td>
          </tr>
        </template>
      </table>
    </div>
  </section>
  <div class="actions">
    <BaseButton @click="print">Print</BaseButton>
    <BaseButton mode="primary-outline" @click="printItems = true"
      >Print with items</BaseButton
    >
  </div>
</template>

<script>
import printJS from 'print-js';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    staff: {
      type: Object,
      required: true,
    },
    start: {
      type: Date,
      required: true,
    },
    end: {
      type: Date,
      required: true,
    },
    detailedMode: {
      type: Boolean,
      default: false,
    },
    tickets: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    console.log(this.data);
  },
  computed: {
    salonName() {
      return this.$store.state.auth.salon.details.shopName;
    },
    staffName() {
      return `${this.staff.firstName} ${this.staff.lastName}`;
    },
    now() {
      return this.$moment().format('ll');
    },
    itemsTableData() {
      const data = [];

      /*
        [
            {
                ticket: '123',
                date: '12/12/12'
                items: []
            }
        ]
        */

      this.tickets.forEach((ticket) => {
        if (
          !ticket.items.filter((i) => i.staff.staffId === this.staff.staffId)
            .length
        )
          return;

        // Tips
        let tipAmount = 0;

        const tipIndex = ticket.tips.findIndex(
          (t) => t.staff.staffId === this.staff.staffId
        );

        if (tipIndex !== -1) tipAmount += ticket.tips[tipIndex].amount;

        if (!data.find((t) => t.ticket === ticket.number)) {
          data.push({
            ticket: ticket.number,
            date: this.$moment(ticket.date).format('ll'),
            tip: tipAmount.toFixed(2),
            items: [],
          });
        }

        ticket.items.forEach((item) => {
          if (item.staff.staffId !== this.staff.staffId) return;

          data[data.length - 1].items.push({
            name: item.item.title,
            price: item.item.price.toFixed(2),
          });
        });
      });

      return data;
    },
    flatCom() {
      if (
        !this.data ||
        !this.data.flatComProducts ||
        !this.data.flatComServices
      )
        return '';

      return `$${(
        +this.data.flatComProducts.split('$')[1] +
        +this.data.flatComServices.split('$')[1]
      ).toFixed(2)}`;
    },
    netSalesTotal() {
      if (
        !this.data ||
        !this.data.netSalesServices ||
        !this.data.netSalesProducts
      )
        return '';

      return `$${(
        +this.data.netSalesServices.split('$')[1] +
        +this.data.netSalesProducts.split('$')[1]
      ).toFixed(2)}`;
    },
    refundsTotal() {
      if (
        !this.data ||
        !this.data.refundsServices ||
        !this.data.refundsProducts
      )
        return '';

      return `$${(
        +this.data.refundsServices.split('$')[1].split(')')[0] +
        +this.data.refundsProducts.split('$')[1].split(')')[0]
      ).toFixed(2)}`;
    },
    discountsTotal() {
      if (
        !this.data ||
        !this.data.discountsServices ||
        !this.data.discountsProducts
      )
        return '';

      return `$${(
        +this.data.discountsServices.split('$')[1].split(')')[0] +
        +this.data.discountsProducts.split('$')[1].split(')')[0]
      ).toFixed(2)}`;
    },
    totalFees() {
      return `($${(
        +this.data.fees.split('$')[1].slice(0, -1) +
        +this.data.dailyFees.split('$')[1].slice(0, -1)
      ).toFixed(2)})`;
    },
    earningsWithoutTips() {
      return (
        +this.data.totalEarnings.split('$')[1] - +this.data.tips.split('$')[1]
      );
    },
    splitCheckPlusTips() {
      return `$${(
        this.earningsWithoutTips * (this.staff.splitCheck * 0.01) +
        +this.data.tips.split('$')[1]
      ).toFixed(2)}`;
    },
    splitOther() {
      return `$${(
        this.earningsWithoutTips *
        (this.staff.splitOther * 0.01)
      ).toFixed(2)}`;
    },
  },
  watch: {
    printItems(val) {
      if (val) {
        setTimeout(() => {
          this.print();
        }, 0);
      }

      setTimeout(() => {
        this.printItems = false;
      }, 2000);
    },
  },
  data() {
    return {
      printItems: false,
    };
  },
  methods: {
    formatDate(date) {
      return this.$moment(date).format('ll');
    },
    print() {
      printJS({
        printable: 'staffSalesInvoice',
        type: 'html',
        style: `
          * { font-weight: 600 }
          .bold { font-weight: 700 }
          .invoice { text-align: center }
          .invoice_head h3, p { margin-top: 5px }
          .invoice_body { margin-top: 64px }
          .invoice_body table { width: 100%; border-collapse: collapse }
          .invoice_body table td { border: 1px solid #666666 }
          .itemsTable { margin-top: 16px }
          `,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.invoice {
  padding: 32px 48px;
  border: 1px solid var(--clr-light);
  border-radius: 5px;
  text-align: center;

  &_head {
    h3,
    p {
      margin-top: 5px;
    }
  }
  &_body {
    margin-top: 32px;

    .bold {
      font-weight: 700;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      td {
        border: 1px solid var(--clr-gray);
        padding: 5px;

        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}

.actions {
  margin-top: 16px;

  button:not(:last-child) {
    margin-right: 16px;
  }
}
</style>
