<template>
  <section class="orders">
    <div class="orders_head">
      <h2>Orders</h2>
      <p>Manage orders placed online by your clients</p>
    </div>

    <div class="orders_actions">
      <div class="col">
        <BaseInput
          placeholder="Client name.."
          :value="searchValue"
          @input="searchValue = $event"
          icon="fas fa-search"
        />
      </div>
      <div class="col">
        <BaseSelect
          :options="filterOptions"
          :value="selectedFilterOption"
          @input="selectedFilterOption = $event"
        />
      </div>
    </div>

    <div class="orders_orders">
      <Spinner v-if="loading" />

      <div v-if="!filteredOrders.length" class="none">
        <h3>No orders to display</h3>
        <p>Try another search/filter value.</p>
      </div>

      <table v-else>
        <thead>
          <tr>
            <th>Date</th>
            <th>Client</th>
            <th>Items</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="order in filteredOrders"
            :key="order._id"
            @click="selectedOrder = order"
          >
            <td>{{ formatDate(order.created_at) }}</td>
            <td>{{ `${order.client.firstName} ${order.client.lastName}` }}</td>
            <td>{{ order.items.length }}</td>
            <td
              class="status"
              :class="{
                yellow: order.status === 'not-collected',
                green: order.status === 'collected',
                red: order.status === 'returned',
              }"
            >
              {{ order.status }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>

  <Modal
    title="Selected Order"
    :show="selectedOrder"
    @close="selectedOrder = null"
  >
    <div class="selectedOrder">
      <OrderDetails
        :order="selectedOrder"
        @edited="editedOrder($event)"
        @deleted="deletedOrder"
      />
    </div>
  </Modal>
</template>

<script>
import OrderDetails from '@/views/orders/OrderDetails.vue';

export default {
  components: {
    OrderDetails,
  },
  created() {
    this.init();
  },
  computed: {
    filterOptions() {
      return [
        {
          option: 'All',
          value: 'all',
        },
        {
          option: 'Collected',
          value: 'collected',
        },
        {
          option: 'Not Collected',
          value: 'not-collected',
        },
        {
          option: 'Returned',
          value: 'returned',
        },
      ];
    },
    filteredOrders() {
      let filteredOrders = [];

      const orders = JSON.parse(JSON.stringify(this.orders));

      if (this.selectedFilterOption !== 'all') {
        filteredOrders = orders.filter(
          (order) => order.status === this.selectedFilterOption
        );
      } else {
        filteredOrders = orders;
      }

      if (this.searchValue) {
        filteredOrders = filteredOrders.filter((order) =>
          `${order.client.firstName} ${order.client.lastName}`
            .toLowerCase()
            .includes(this.searchValue.toLowerCase())
        );
      }

      return filteredOrders;
    },
  },
  data() {
    return {
      loading: false,
      orders: [],
      selectedFilterOption: 'all',
      searchValue: '',
      selectedOrder: null,
    };
  },
  methods: {
    async init() {
      this.loading = true;

      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/orders?salonId=${this.$store.state.auth.salon._id}`
        );

        this.orders = response.data.orders;
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    formatDate(date) {
      return this.$moment(date).format('LLL');
    },

    editedOrder(order) {
      const index = this.orders.findIndex(
        (preorder) => preorder._id === order._id
      );

      if (index === -1) return;

      this.orders[index] = order;
      this.selectedOrder = null;
    },

    deletedOrder() {
      const index = this.orders.findIndex(
        (preorder) => preorder._id === this.selectedOrder._id
      );

      if (index === -1) return;

      this.orders.splice(index, 1);
      this.selectedOrder = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.orders {
  width: 100%;
  max-width: 800px;
  margin: auto;
  margin-top: 32px;
  padding: 0 16px;

  .small {
    font-size: 14px;
  }
  .light {
    color: var(--clr-gray);
  }
  .green {
    color: var(--clr-success);
  }
  .yellow {
    color: var(--clr-warning-dark);
  }
  .red {
    color: var(--clr-danger);
  }

  &_head {
    h2 {
      font-size: 28px;
    }
    p {
      color: var(--clr-gray);
      margin-top: 5px;
    }
  }

  &_actions {
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-extra-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    .buttons {
      display: flex;
      gap: 16px;
    }
  }

  &_orders {
    .none {
      padding: 16px;
      text-align: center;
      border: 1px solid var(--clr-light);
      border-radius: 5px;

      p {
        margin-top: 8px;
      }
    }
  }
}

.selectedOrder {
  padding: 32px;
}
</style>
