<template>
  <section class="details">
    <div class="details_salonLogo">
      <SalonLogo />
    </div>

    <div class="details_section services">
      <p class="bold">
        Services
        <span v-if="!confirmed">
          <router-link
            :to="{ name: 'BookServices', params: { id: salon._id } }"
          >
            Edit
          </router-link>
        </span>
      </p>
      <div class="details_section_info" v-if="selectedServices.length">
        <div class="service" v-for="service in selectedServices" :key="service">
          <div class="col">
            <p>
              {{ service.title }}
              <span class="light" v-if="selectedStaff"
                >with {{ staffName(selectedStaff) }}</span
              >
            </p>
            <p class="subtext">{{ service.duration }} minutes</p>
          </div>

          <div v-if="isOnServicesPage" class="col actions">
            <!-- <div class="action" @click="selectService(service, true)">
              <i class="fas fa-plus"></i>
            </div> -->

            <div class="action" @click="selectService(service, false)">
              <i class="fas fa-minus"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="details_section staff">
      <p class="bold">
        Staff
        <span v-if="!confirmed">
          <router-link :to="{ name: 'BookMembers', params: { id: salon._id } }">
            Edit
          </router-link>
        </span>
      </p>
      <div class="details_section_info" v-if="selectedStaff">
        <div class="staff">
          <p>{{ selectedStaff.firstName }} {{ selectedStaff.lastName }}</p>
          <p class="subtext">{{ selectedStaff.title }}</p>
        </div>
      </div>
      <div class="details_section_info" v-else>
        <div class="staff">
          <p>First available staff member</p>
        </div>
      </div>
    </div>

    <div class="details_section date">
      <p class="bold">
        Date and time
        <span v-if="!confirmed && selectedServices.length">
          <router-link :to="{ name: 'BookDate', params: { id: salon._id } }">
            Edit
          </router-link>
        </span>
      </p>

      <div class="details_section_info" v-if="selectedDate && selectedTime">
        <div>
          <p>{{ appointmentDate }}</p>
          <p class="subtext">
            {{ appointmentStartTime }} - {{ appointmentEndTime }}
          </p>
        </div>
      </div>
    </div>

    <div class="details_section">
      <p class="bold">Confirmation</p>
      <div class="details_section_info" v-if="confirmed">
        <div class="confirmed">
          <i class="fas fa-check-circle"></i>
          <p>Appointment booked!</p>
        </div>
      </div>
      <div v-else class="details_section_info">
        <div class="unconfirmed">
          <i class="fas fa-ban"></i>
          <p>Not yet confirmed</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SalonLogo from '@/components/booking/SalonLogo.vue';

export default {
  components: {
    SalonLogo,
  },
  computed: {
    salon() {
      return this.$store.state.booking.salon;
    },
    selectedServices() {
      return this.$store.state.booking.selectedServices;
    },
    selectedStaff() {
      return this.$store.state.booking.selectedStaff;
    },
    selectedDate() {
      return this.$store.state.booking.selectedDate;
    },
    selectedTime() {
      return this.$store.state.booking.selectedTime;
    },
    appointmentDate() {
      return this.$store.getters['booking/formattedDate'];
    },
    appointmentStartTime() {
      return this.$store.getters['booking/formattedTime'];
    },
    appointmentEndTime() {
      return this.$store.getters['booking/getAppointmentEndTime'];
    },
    confirmed() {
      return this.$store.state.booking.confirmed;
    },
    isOnServicesPage() {
      return this.$route.fullPath.includes('/services');
    },
  },
  methods: {
    staffName(staff) {
      return `${staff.firstName} ${staff.lastName}`;
    },
    selectService(service, forceAdd) {
      this.$store.dispatch('booking/selectService', { ...service, forceAdd });
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  background-color: white;
  padding: 16px;
  box-shadow: var(--shadow);

  &_section {
    &:not(:first-child) {
      margin-top: 16px;
    }

    .light {
      color: var(--clr-gray);
      font-size: 12px;
    }

    .bold {
      font-weight: 700;

      span {
        margin-left: 16px;
        color: var(--clr-link);
        border-bottom: 1px solid var(--clr-link);
        cursor: pointer;
      }
    }

    &_info {
      margin-top: 16px;
      padding: 16px;
      border-radius: 5px;
      font-size: 14px;

      .subtext {
        margin-top: 3px;
        font-size: 12px;
        color: var(--clr-gray);
      }

      .service {
        &:not(:first-child) {
          margin-top: 16px;
        }

        display: flex;
        justify-content: space-between;
        gap: 16px;

        .actions {
          display: flex;
          align-items: center;
          gap: 16px;

          .action {
            font-size: 8px;
            height: 20px;
            width: 20px;
            display: grid;
            place-items: center;
            color: var(--clr-white);
            font-weight: 700;
            background-color: var(--clr-secondary);
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }

      .confirmed {
        display: flex;
        align-items: center;
        gap: 16px;
        color: var(--clr-success);

        p {
          font-weight: 700;
        }
      }
      .unconfirmed {
        display: flex;
        align-items: center;
        gap: 16px;
        color: var(--clr-danger);

        p {
          font-weight: 700;
        }
      }
    }
  }

  &_salonLogo {
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 5px;
  }
}
</style>
