<template>
  <section class="workTicket" :id="ticket._id">
    <div class="workTicket_head">
      <h1>{{ ticketTitle }}</h1>
      <p class="light">{{ date }}</p>
    </div>
    <div class="workTicket_body">
      <div class="items">
        <div v-for="item in items" :key="item._id" class="items_item">
          <div class="items_item_title">
            <p>{{ item.item.title }}</p>
          </div>
          <div class="items_item_staff">
            <p>{{ `${item.staff.firstName} ${item.staff.lastName}` }}</p>
          </div>
          <div class="items_item_price">
            <p>{{ formatPrice(item.item.price) }}</p>
          </div>
        </div>
      </div>

      <div v-for="i in extraItems" :key="i" class="empty">
        <div class="block">
          <div class="line"></div>
          <p class="small light">Item</p>
        </div>
        <div class="block">
          <div class="line"></div>
          <p class="small light">Staff</p>
        </div>
        <div class="block">
          <div class="line"></div>
          <p class="small light">Price</p>
        </div>
      </div>

      <div class="totals">
        <div class="tip">
          <p>Tip</p>
          <div class="line"></div>
        </div>
        <div class="total">
          <p>Total</p>
          <div class="line"></div>
        </div>
      </div>

      <div class="payments">
        <p class="type">Cash</p>
        <p class="type">Card</p>
        <p class="type">Other</p>
      </div>

      <div class="other">
        <div class="next">
          <p>Next Visit</p>
          <div class="line"></div>
        </div>
      </div>
    </div>
  </section>

  <div class="actions">
    <div class="input">
      <BaseInput
        label="Extra Line Items"
        inputType="number"
        :value="extraItems"
        :step="1"
        @input="extraItems = +$event"
      />
    </div>
    <div class="actions_actions">
      <BaseButton @click="print">Print</BaseButton>
    </div>
  </div>
</template>

<script>
import printJS from 'print-js';

export default {
  computed: {
    date() {
      return this.$moment(this.ticket.created_at).format('LLL');
    },
    ticketTitle() {
      return this.ticket.client
        ? `${this.ticket.client.firstName} ${this.ticket.client.lastName}`
        : this.ticket.title || 'Walk-in';
    },
    items() {
      return this.ticket.items;
    },
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      extraItems: 3,
    };
  },
  methods: {
    formatPrice(price) {
      return `$${price.toFixed(2)}`;
    },

    print() {
      printJS({
        printable: this.ticket._id,
        type: 'html',
        style: `
        .small { font-size: 14px } .light { color: #666 }
        .workTicket { width: 100%; padding: 16px; } .workTicket_head { text-align: center }
          .workTicket_body { margin-top: 32px }
          .workTicket_body .items { display: flex; flex-direction: column; gap: 16px }
          .workTicket_body .items .items_item { display: grid; grid-template-columns: 1fr 1fr 0.5fr; gap: 16px }
          .workTicket_body .empty { margin-top: 48px; display: grid; grid-template-columns: 1fr 1fr 0.5fr; gap: 16px }
          .workTicket_body .empty .block .line { border-bottom: 1px solid #333 }
          .workTicket_body .totals, .other { margin-top: 32px }
          .workTicket_body .totals, .other .tip, .total, .next { display: grid; grid-template-columns: 0.25fr 1fr }
          // .workTicket_body .totals, .other .tip, .total, .next .line { border-bottom: 1px solid #333 }
          .workTicket_body .totals .total { margin-top: 32px }
          .workTicket_body .payments { text-align: center; margin-top: 32px; display: grid; grid-template-columns: 1fr 1fr 1fr; gap: 8px; }`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.light {
  color: var(--clr-gray);
}

.small {
  font-size: 14px;
}

.workTicket {
  padding: 16px;
  border: 1px solid var(--clr-light);
  border-radius: 5px;

  &_head {
    text-align: center;
  }
  &_body {
    margin-top: 32px;

    .items {
      display: flex;
      flex-direction: column;
      gap: 32px;

      &_item {
        display: grid;
        grid-template-columns: 1fr 1fr 0.5fr;
        gap: 16px;
      }
    }

    .empty {
      margin-top: 48px;
      display: grid;
      grid-template-columns: 1fr 1fr 0.5fr;
      gap: 16px;

      .block {
        .line {
          border-bottom: 1px solid var(--clr-gray);
        }
      }
    }

    .totals,
    .other {
      margin-top: 32px;

      .tip,
      .total,
      .next {
        display: grid;
        grid-template-columns: 0.25fr 1fr;

        .line {
          border-bottom: 1px solid var(--clr-gray);
        }
      }
      .total {
        margin-top: 32px;
      }
    }

    .payments {
      text-align: center;
      margin-top: 32px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 8px;
    }
  }
}

.actions {
  margin-top: 32px;

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
