<template>
  <label class="switch">
    <input
      type="checkbox"
      :disabled="disabled"
      :checked="checked"
      @click="$emit('toggle')"
    />
    <span class="slider round"></span>
  </label>
</template>

<script>
export default {
  emits: ['toggle'],
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  width: 50px;
  height: 26px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--clr-light);
  transition: 0.4s;
  //   box-shadow: var(--shadow);
}

.slider:before {
  position: absolute;
  content: '';
  top: 3px;
  left: 2px;
  background-color: var(--clr-white);
  transition: 0.4s;
}

.switch input {
  display: none;
}

input:checked + .slider {
  background-color: var(--clr-secondary);
}
input:checked + .slider:before {
  transform: translateX(26px);
  background: white;
}

.slider.round {
  border-radius: 35px;
}

.slider.round:before {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
</style>
