<template>
  <section class="notifications">
    <div v-if="!cancellationRequests" class="notifications_head">
      <h2>Notifications</h2>
      <p>Keep up to date with your upcoming appointments, invites, etc.</p>
      <p v-if="notifications.length > 25" class="limitWarning">
        You have a high number of notifications, in an attempt to keep the
        system optimized we will remove all notifications except the first 25
        periodically.
      </p>
    </div>
    <div v-else class="notifications_head">
      <h2>Cancellation Requests</h2>
      <p>Keep up to date with your client appointment cancellation requests</p>
    </div>

    <ul v-if="notifications.length" class="notifications_notifications">
      <li
        v-for="notification in notifications"
        :key="notification._id"
        class="notifications_notifications_notification"
        @click="notificationNavigation(notification)"
      >
        <div class="head">
          <i
            v-if="notification.type === 'appointment'"
            class="fas fa-calendar-plus"
          ></i>
          <i v-if="notification.type === 'invite'" class="fas fa-user-plus"></i>
          <i
            v-if="notification.type === 'message'"
            class="fas fa-envelope-open-text"
          ></i>
          <div class="text">
            <h3>{{ formatToTitle(notification.type) }}</h3>
            <p class="small light">
              {{ dateFromNow(notification.created_at) }}
            </p>
          </div>
        </div>
        <div class="body">
          <p class="text">{{ notification.message }}</p>
        </div>
      </li>
      <div class="actions">
        <BaseButton mode="danger-outline" @click="clear"
          >Clear all notifications</BaseButton
        >
      </div>
    </ul>
    <div v-else class="notifications_none">
      <h3>You're all caught up!</h3>
      <p>No notifications here, go relax.</p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    propNotifications: {
      type: Array,
      default: () => [],
    },
    cancellationRequests: {
      type: Boolean,
      default: false,
    },
    salonNotifications: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    const notifications = JSON.parse(JSON.stringify(this.propNotifications));

    this.notifications = notifications.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  },
  data() {
    return {
      notifications: [],
    };
  },
  methods: {
    notificationNavigation(notification) {
      if (notification.link) {
        this.goToNotification(notification);
        return;
      }

      if (
        notification.type === 'message' &&
        notification.salonId &&
        notification.message.includes('New review from')
      ) {
        if (notification.salonId === this.$store.state.auth.salon._id) {
          this.$router.push({ name: 'ReviewReviews' });
        } else {
          this.$toast.warning(
            'This review is for a salon that is not the currently active salon'
          );
        }

        return;
      }
    },
    dateFromNow(date) {
      return this.$moment(date).fromNow();
    },
    formatToTitle(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async clear() {
      if (this.cancellationRequests) {
        this.clearCancellationRequests();
        return;
      } else if (this.salonNotifications) {
        this.clearSalonNotifications();
        return;
      }

      try {
        await this.$store.dispatch('staff/editStaffAccount', {
          ...this.$store.state.auth.user,
          notifications: [],
        });

        this.notifications.length = 0;
        this.$toast.success('Notifications cleared');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    async clearCancellationRequests() {
      try {
        await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/appointments/cancelRequest`
        );

        this.$store.state.auth.salon.cancellationRequests.length = 0;
        this.notifications.length = 0;

        this.$toast.success('Notifications cleared');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    async clearSalonNotifications() {
      try {
        await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/notifications`
        );

        this.$store.state.auth.salon.notifications.length = 0;
        this.notifications.length = 0;

        this.$toast.success('Notifications cleared');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    goToNotification(notification) {
      this.$router.push(notification.link);
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .limitWarning {
    padding: 16px;
    color: var(--clr-warning-dark);
    border: 1px solid var(--clr-warning);
    background-color: var(--clr-warning-light);
    font-size: 14px;
    margin-top: 32px;
    font-style: italic;
    line-height: 22px;
  }

  &_head {
    p {
      margin-top: 8px;
    }
  }
  &_notifications {
    li {
      padding: 16px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      cursor: pointer;

      &:not(:first-child) {
        margin-top: 16px;
      }

      &:hover {
        background-color: var(--clr-white);
      }

      .small {
        font-size: 12px;
      }
      .light {
        color: var(--clr-gray);
      }

      .head {
        display: flex;
        align-items: center;
        gap: 16px;

        i {
          color: var(--clr-secondary);
          font-size: 20px;
        }
      }

      .body {
        margin-top: 16px;
      }
    }

    .actions {
      margin-top: 16px;

      button {
        width: 100%;
      }
    }
  }
  &_none {
    padding: 16px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;
    text-align: center;

    p {
      margin-top: 8px;
    }
  }
}
</style>
