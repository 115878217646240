<template>
  <section class="reviews">
    <div class="reviews_head">
      <h2>Reviews</h2>
      <p>Edit/manage your client reviews</p>
    </div>

    <div class="reviews_body">
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Client</th>
            <th>Rating</th>
            <th class="hide-tablet">Message</th>
            <th class="hide-tablet">Actions</th>
            <th class="tablet"></th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="review in reviews"
            :key="review._id"
            @click="selectedReview = review"
          >
            <td>{{ formatDate(review.created_at) }}</td>

            <td>
              {{ `${review.client.firstName} ${review.client.lastName}` }}
            </td>

            <td>
              <i
                v-for="i in 5"
                :key="i"
                class="fa-star"
                :class="review.rating >= i ? 'fas' : 'far'"
              ></i>
            </td>

            <td class="hide-tablet">
              {{ review.review?.slice(0, 15)
              }}{{ review.review.length > 15 ? '..' : '' }}
            </td>

            <td class="actions hide-tablet">
              <p
                @click="
                  viewClient(
                    `${review.client.firstName} ${review.client.lastName}`
                  )
                "
              >
                View Client
              </p>
              <p @click="toggleHidden(review)">
                {{ review.hidden ? 'Unhide' : 'Hide' }}
              </p>
              <p
                @click="
                  selectedReview = review;
                  showConfirmDelete = true;
                "
                class="remove"
              >
                Remove
              </p>
            </td>

            <td class="tablet"><i class="fas fa-ellipsis-h"></i></td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Delete review"
    text="Are you sure you wish to delete this review? This action can not be undone."
    @confirm="removeReview"
    @deny="showConfirmDelete = false"
  />

  <Modal
    title="Selected Review"
    :show="selectedReview"
    @close="selectedReview = null"
  >
    <div class="selectedReview">
      <h2>
        {{
          `${selectedReview.client.firstName} ${selectedReview.client.lastName}`
        }}
      </h2>
      <p class="light">{{ formatDate(selectedReview.created_at) }}</p>

      <p class="message">{{ selectedReview.review }}</p>

      <div class="selectedReview_actions">
        <BaseButton @click="selectedReview = null">Close</BaseButton>
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  async created() {
    this.init();
  },
  watch: {
    salonId() {
      this.init();
    },
  },
  computed: {
    salonId() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon._id;
    },
  },
  data() {
    return {
      reviews: [],
      showConfirmDelete: false,
      selectedReview: null,
    };
  },
  methods: {
    async init() {
      this.showConfirmDelete = false;

      try {
        this.reviews = await this.$store.dispatch(
          'reviews/getReviews',
          this.salonId
        );

        this.reviews.reverse();
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    formatDate(date) {
      return this.$moment(date).format('LLL');
    },

    async removeReview() {
      try {
        if (!this.selectedReview) return;

        await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/reviews/${this.selectedReview._id}`
        );

        this.init();
        this.selectedReview = null;
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async toggleHidden(review) {
      try {
        await this.$store.dispatch('reviews/editReview', {
          ...review,
          hidden: !review.hidden,
        });

        this.init();
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    viewClient(name) {
      this.$router.push({
        name: 'ClientsList',
        query: {
          name,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.reviews {
  &_head {
    h2 {
      font-size: 28px;
    }
    p {
      margin-top: 8px;
    }
  }

  &_body {
    table {
      margin-top: 32px;

      i {
        color: var(--clr-warning);

        &:not(:first-child) {
          margin-left: 5px;
        }
      }

      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 32px;

        p {
          color: var(--clr-link);
          font-size: 14px;
          font-weight: 500;
        }

        .remove {
          color: var(--clr-danger) !important;
        }
      }
    }
  }
}

.selectedReview {
  padding: 32px;

  .light {
    color: var(--clr-gray);
  }

  .message {
    margin-top: 32px;
  }

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
