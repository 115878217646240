<template>
  <section class="doc">
    <div class="doc_head">
      <h2>Services</h2>
      <p class="light small">
        In this guide we will learn how to add, edit, and remove services
        to/from your salon.
      </p>
    </div>
    <div class="doc_body">
      <p class="section">
        The services page can be navigated to by selecting the
        <i class="fas fa-hand-sparkles mx"></i> icon on the sidebar. To get
        started, lets create our first service.
      </p>
      <div class="section">
        <h4>1) Adding A Service</h4>
        <p class="section">
          Services must be parented by a "Category". Categories help us organize
          our services by similarity, and will assist clients/team members when
          selecting a service. To create a category select the "Add" dropdown
          button and select "New Category". You need only to fill out a few
          values to continue. Once a category has been created, we can add a
          service to the new category by selecting the
          <i class="fas fa-ellipsis-h mx"></i> icon and finally by pressing "Add
          New Item". Alternatively you may select the "Add" dropdown button at
          the top and select "New Service", to which you would then need to
          select the appropriate category afterwards.
        </p>
        <p class="section">
          Here you will see the service form. We will go over the values from
          top to bottom. To start, you will need to enter a service title,
          select the category, and optionally add a description. Next we will
          set the turn value of this service, this is used in establishments
          that accept walk-ins and distribute them evenly among team members.
          This most common values for this field are 0, 0.5, 1, 1.5, and 2.
        </p>
        <p class="section">
          Following this we have the option to "Enable Calendar Bookings". This
          will determine whether or not to display this service on your online
          booking page.
        </p>
        <p class="section">
          Next, we can set whether or not the service is to be commissioned to
          team members performing the service. Commission can be determined in
          two ways, flat (team members will receive a flat value in dollars as a
          commission for this service), or percent (determined by the team
          members commission percentage value).
        </p>
        <p class="section">
          Next, we set the pricing and duration values. Price types can be set
          as either, from (price starting at), or fixed (set value for price).
          You optionally have the ability to set a sale price, this is typically
          set for a short period of time while a service is "on sale". "Extra
          Processing Time" will add time to the service on the clients side but
          allow the team member performing the action to accept calendar
          bookings during the processing time. "Extra Blocked Time" will not
          display on the clients side, but will also not allow calendar bookings
          to be booked during this additional time. Both of these options values
          are in minutes.
        </p>
        <p class="section">
          Finally we can set the tax rate, the fees given to the team member
          providing the service, and set which team members are capable of
          performing this service. This can be edited at any time by editing a
          team members capable services.
        </p>
      </div>
      <div class="section">
        <h4>2) Editing/Deleting Services</h4>
        <p class="section">
          You can edit a category or a service by selecting them from the
          "Services" page. This form will be nearly identical to the adding a
          service/category form. To delete a service, scroll to the bottom of
          the service form and select "Delete Service". This action will need to
          be confirmed. To delete a category, you must first remove or move
          every item to a new category. These actions are irreversable.
        </p>
      </div>
      <ul class="section next">
        <li>
          <h3>See Also:</h3>
        </li>
        <router-link :to="{ name: 'DocsProducts' }">
          <li>
            <p class="link">Products</p>
          </li>
        </router-link>
      </ul>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.doc {
  padding-bottom: 64px;

  p {
    line-height: 24px;
  }
  .light {
    margin-top: 8px;
    color: var(--clr-gray);
  }
  .small {
    font-size: 14px;
  }
  .link {
    text-decoration: underline;
    color: var(--clr-link);
    cursor: pointer;
  }
  .mx {
    margin: 0px 8px 0px 8px;
  }
  .italic {
    font-style: italic;
  }

  &_head {
    h2 {
      font-size: 32px;
    }
  }

  &_body {
    margin-top: 32px;

    .section:not(:first-child) {
      margin-top: 16px;
    }

    .next {
      margin-top: 32px;

      h3 {
        margin-bottom: 16px;
      }

      li:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
}
</style>
