<template>
  <div class="endpoint_head">
    <h2>Update staff schedule</h2>

    <div class="route">
      <i
        @click="
          $router.push('/documentation/api/staff#updateSchedule');
          $toast.info('Set URL to endpoint');
        "
        :style="{ color: 'var(--clr-secondary)', cursor: 'pointer' }"
        class="fas fa-hashtag"
      ></i>
      <p class="tag tag-blue">PUT</p>
      <p class="url">
        {{ apiUrl }}<span>/salons/:salonId/staff/:staffId/schedule</span>
      </p>
    </div>

    <div class="description">
      <p>Updates a staff members schedule</p>
    </div>
  </div>

  <div class="endpoint_body">
    <div class="request">
      <h3>Request</h3>

      <!-- Headers -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Headers</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">Authorization</p>
              <p class="examples">Examples: <span>Bearer {Token}</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Path params -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Path Parameters</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">salonId</p>
              <p class="description">ID of salon to add staff to</p>
              <p class="examples">
                Examples: <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6f</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <li>
            <div class="col value">
              <p class="bold">staffId</p>
              <p class="description">
                ID of staff to update (Note this is the "_id" and NOT the
                "staffId")
              </p>
              <p class="examples">
                Examples: <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6d</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
          <p>application/json</p>
        </div>

        <ul>
          <!-- Schedule -->
          <li>
            <div class="col value">
              <p class="bold">schedule</p>
              <p class="description">
                An object with two arrays, "default" and "exceptions". Default
                array is to contain 7 objects, one for each day of the week with
                "day" 0 being Sunday, "day" 1 being Monday, etc..
                <br />
                <br />
                Default example: [ { "day": 0, "blocks": [ { "start": "10:00",
                "end": "17:00" }, ] } ]
                <br />
                <br />
                Exceptions example: [ { "date": "2022-11-19T06:00:00.000Z",
                "blocks": [ { "start": "11:00", "end": "16:00" } ] } ]
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="response">
      <h3>Response</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">schedule</p>
              <p class="examples">
                Examples:
                <span>OBJECT</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="reqResExamples">
      <h3>Examples</h3>

      <!-- Request -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Request</h4>
          <p>application/json</p>
        </div>

        <ul>
          <li>
            <p>
              { "schedule": { "default": [ { "day": 0, "blocks": [ { "start":
              "09:00", "end": "17:00", "_id": "640df87a5288c88e5269b1e9" } ],
              "_id": "6271593e71447fa42cc0635f" }, { "day": 1, "blocks": [],
              "_id": "6271593e71447fa42cc06360" }, { "day": 2, "blocks": [],
              "_id": "6271593e71447fa42cc06362" }, { "day": 3, "blocks": [],
              "_id": "6271593e71447fa42cc06364" }, { "day": 4, "blocks": [],
              "_id": "6271593e71447fa42cc06366" }, { "day": 5, "blocks": [],
              "_id": "6271593e71447fa42cc06368" }, { "day": 6, "blocks": [],
              "_id": "6271593e71447fa42cc0636a" } ], "exceptions": [ { "date":
              "2022-11-17T06:00:00.000Z", "blocks": [], "_id":
              "63792503d770e0548fa712d3" }, { "date":
              "2022-11-26T06:00:00.000Z", "blocks": [], "_id":
              "63792503d770e0548fa712d4" }, { "date":
              "2022-11-19T06:00:00.000Z", "blocks": [], "_id":
              "63792503d770e0548fa712d5" } ], "_id": "6271593e71447fa42cc0635e"
              } }
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    apiUrl() {
      return process.env.VUE_APP_RASERVA_BACKEND;
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoint {
  .tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px;
    border-radius: 5px;
    color: white;

    &-blue {
      background-color: var(--clr-secondary);
    }
  }

  &_head {
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }

    .route {
      padding: 8px 16px;
      border-radius: 5px;
      background-color: var(--clr-white-2);
      display: flex;
      align-items: center;
      gap: 16px;

      .url {
        color: var(--clr-gray);

        span {
          color: var(--clr-primary);
          font-weight: 700;
        }
      }
    }

    .description {
      margin-top: 32px;
    }
  }

  &_body {
    .request,
    .response,
    .reqResExamples {
      margin-top: 32px;
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &_section {
      &:not(:first-child) {
        margin-top: 32px;
      }

      .head {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 20px;
          margin-bottom: 16px;
          color: var(--clr-secondary);
        }

        p {
          font-size: 14px;
          color: var(--clr-gray);
        }
      }

      ul {
        .subitem {
          margin-left: 32px;
        }

        li {
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          &:not(:first-child) {
            margin-top: 16px;
          }

          .value {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .bold {
            font-weight: 700;
          }
          .required {
            color: orangered;
          }

          .description {
            margin: 4px 0;
            color: var(--clr-gray);
          }

          .examples {
            span {
              background-color: var(--clr-white-2);
              padding: 4px 8px;
              border-radius: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
