<template>
  <div class="packages">
    <Table :headers="headers" :data="data" />
  </div>
</template>

<script>
export default {
  props: {
    packages: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.packages.forEach((pack) => {
      pack.items.forEach((item) => {
        this.data.push({
          salon: pack.salon.name,
          package: pack.title,
          item: item.item.title,
          start: item.startingQuantity,
          remaining: item.quantity,
        });
      });
    });
  },
  computed: {
    headers() {
      return ['Salon', 'Package', 'Item', 'Starting Qty', 'Remaining Qty'];
    },
  },
  data() {
    return {
      data: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.packages {
  border: 1px solid var(--clr-light);
}
</style>
