<template>
  <section class="tickets">
    <div class="tickets_head">
      <div class="title">
        <h2>Sales by tickets</h2>

        <BaseButton @click="showAddTicketModal = true"
          >Create Ticket</BaseButton
        >
      </div>

      <router-link v-if="ref" :to="ref" class="return"
        >Return to previous page</router-link
      >
    </div>
    <div class="tickets_drb">
      <DateRangeBar
        :start="searchStart"
        :end="searchEnd"
        @search="setAndSearch($event.starting, $event.ending)"
      >
        <BaseInput
          inputType="number"
          step="1"
          label="Ticket Number"
          @input="searchNumber = +$event"
          :value="searchNumber"
        />
      </DateRangeBar>
    </div>

    <Spinner v-if="loading" />

    <div class="tickets_tables" v-if="salesData.length">
      <div class="tickets_tables_table">
        <Table
          :headers="salesHeaders"
          :data="salesDataWithoutId"
          :boldRows="[0]"
          :boldCols="[9]"
          @rowClick="selectTicket($event)"
        />
      </div>
    </div>

    <div v-else class="tickets_none">
      <h4>No data to display!</h4>
      <p>Search a new date range.</p>
    </div>
  </section>

  <SidePanel :show="selectedTicket" @close="selectedTicket = null">
    <div class="ticketDetails">
      <TicketDetails
        :ticket="selectedTicket"
        @updated="search(searchStart, searchEnd)"
        @hide="selectedTicket = null"
      />
    </div>
  </SidePanel>

  <Modal
    title="Create Ticket"
    @close="showAddTicketModal = false"
    v-if="showAddTicketModal"
    :show="showAddTicketModal"
    fullScreen
  >
    <TicketForm
      @created="ticketUpdated($event, 'Created')"
      @edited="ticketUpdated($event, 'Updated')"
      @checkedOut="ticketUpdated($event, 'Checked Out')"
      @close="showAddTicketModal = false"
      canEditDate
      checkoutOnlyMode
    />
  </Modal>
</template>

<script>
import DateRangeBar from '@/components/components/DateRangeBar.vue';
import TicketDetails from '@/components/tickets/TicketDetails.vue';
import TicketForm from '@/views/dashboard/TicketForm.vue';

export default {
  components: {
    DateRangeBar,
    TicketDetails,
    TicketForm,
  },
  created() {
    if (this.$route.query.start)
      this.searchStart = this.$moment(this.$route.query.start);
    if (this.$route.query.end)
      this.searchEnd = this.$moment(this.$route.query.end);
    if (this.$route.query.number) this.searchNumber = this.$route.query.number;

    if (this.$route.query.start) {
      this.$route.query.start;
    }

    this.resetSales();
    this.search(this.searchStart, this.searchEnd);
  },
  computed: {
    ref() {
      return this.$route.query.ref;
    },
    salesDataWithoutId() {
      if (!this.salesData) return;

      return this.salesData.map(({ _id, ...rest }) => {
        //  Literally have to do something with _id or Vue complains
        //  that we're not doing anything with _id LMAO
        +_id++;
        return rest;
      });
    },
  },
  data() {
    return {
      salesHeaders: [
        'Checkout Date',
        'Number',
        'Status',
        'Client',
        'Staff',
        'Subtotal',
        'Tips',
        'Tax',
        'Discounts',
        'Total',
        'Payments',
      ],
      salesData: null,

      tickets: [],
      selectedTicket: null,

      searchNumber: null,
      searchStart: this.$moment().startOf('day'),
      searchEnd: this.$moment().endOf('day'),

      loading: false,
      showAddTicketModal: false,
    };
  },
  methods: {
    setAndSearch(start, end) {
      this.searchStart = start;
      this.searchEnd = end;

      this.search(start, end);
    },

    async search(start, end) {
      this.loading = true;

      try {
        const tickets = await this.$store.dispatch(
          'tickets/getTicketsInDateRange',
          { start, end, number: this.searchNumber }
        );
        this.tickets = tickets;

        this.setSales({ tickets });
      } catch (error) {
        console.log(error);
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    selectTicket(index) {
      if (this.salesData[index]._id) {
        this.selectedTicket = this.tickets.find(
          (ticket) => ticket._id === this.salesData[index]._id
        );
      }
    },

    setSales(data) {
      // Reset
      this.resetSales();

      // Tickets
      data.tickets.forEach((ticket) => {
        this.salesData.push(this.getTicketData(ticket));
      });

      // Formatting
      this.formatTotals();
    },

    getTicketData(ticket) {
      let staffs = [];

      ticket.items.forEach((item) => {
        if (
          item.staff &&
          !staffs.find((staff) => staff._id === item.staff._id)
        ) {
          if (item.staff) staffs.push(item.staff);
        }
      });

      let staffText;

      if (staffs.length === 1) {
        staffText = `${staffs[0].firstName} ${staffs[0].lastName}`;
      } else {
        staffText = `[${staffs.length}]`;
      }

      const data = {
        _id: ticket._id,
        created: ticket.checkout_at,
        number: ticket.number,
        status: ticket.status,
        client: ticket.client
          ? `${ticket.client.firstName} ${ticket.client.lastName || ''}`
          : 'Walk-in',
        staff: staffText,
        subtotal: 0,
        tips: 0,
        tax: 0,
        discounts: 0,
        total: 0,
        payments:
          ticket.payments.length === 1
            ? ticket.payments[0].type
            : `[${ticket.payments.length}]`,
      };

      // Items
      ticket.items.forEach((item) => {
        data.subtotal += item.item.price * item.quantity;
        data.tax +=
          (item.item.price - (item.item.discountAmount || 0)) *
          (item.item.taxRate * 0.01) *
          item.quantity;
        data.discounts += (item.item.discountAmount || 0) * item.quantity;
        // All
        this.salesData[0].subtotal += item.item.price * item.quantity;
        this.salesData[0].tax +=
          (item.item.price - (item.item.discountAmount || 0)) *
          (item.item.taxRate * 0.01) *
          item.quantity;
        this.salesData[0].discounts +=
          (item.item.discountAmount || 0) * item.quantity;
      });

      // Tips
      ticket.tips.forEach((tip) => {
        data.tips += tip.amount;
        // All
        this.salesData[0].tips += tip.amount;
      });

      // Total
      data.total = data.subtotal + data.tips + data.tax - data.discounts;

      // All
      this.salesData[0].total +=
        data.subtotal + data.tips + data.tax - data.discounts;
      this.salesData[0].payments += ticket.payments.length;
      if (ticket.client) this.salesData[0].client++;

      return data;
    },

    formatTotals() {
      this.salesData.forEach((data) => {
        data.created =
          data.created === 'All'
            ? data.created
            : this.$moment(data.created).format('LLL');
        data.subtotal = `$${data.subtotal.toFixed(2)}`;
        data.tips = `$${data.tips.toFixed(2)}`;
        data.tax = `$${data.tax.toFixed(2)}`;
        data.discounts = `($${data.discounts.toFixed(2)})`;
        data.total = `$${data.total.toFixed(2)}`;
      });
    },

    resetSales() {
      this.salesData = [
        {
          created: 'All',
          number: '',
          status: '',
          client: 0,
          staff: '',
          subtotal: 0,
          tips: 0,
          tax: 0,
          discounts: 0,
          total: 0,
          payments: 0,
        },
      ];
    },

    async ticketUpdated(ticket, action) {
      try {
        if (action === 'Created') {
          await this.$store.dispatch('tickets/createTicket', ticket);
        } else if (action === 'Updated') {
          await this.$store.dispatch('tickets/editTicket', ticket);
        } else if (action === 'Checked Out') {
          await this.$store.dispatch('tickets/checkoutTicket', ticket);
        }

        this.$toast.success(`Ticket ${action}`);

        if (!ticket.dontClose) {
          this.showAddTicketModal = false;
        }
      } catch (e) {
        this.$toast.error(e.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tickets {
  width: 100%;

  &_head {
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
    }

    h2 {
      font-size: 28px;
    }

    .return {
      margin-top: 5px;
      color: var(--clr-link);
      cursor: pointer;
    }
  }

  &_drb {
    margin-top: 32px;

    .buttons {
      display: flex;
      align-items: center;
    }
  }

  &_tables {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
    flex-wrap: wrap;

    &_table {
      flex-grow: 1;
    }
  }

  &_none {
    padding: 32px;

    p {
      margin-top: 5px;
    }
  }
}

.ticketDetails {
  height: 100%;
  padding: 32px;
}
</style>
