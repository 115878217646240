<template>
  <section class="payment">
    <div v-if="!refundable && processor" class="alert alert-danger">
      <p>This payment has been refunded</p>
    </div>
    <div class="payment_head">
      <div class="detail">
        <p>Collected with:</p>
        <h3>{{ payment.processor || 'No processor' }}</h3>
      </div>
      <div class="detail">
        <p>For amount:</p>
        <h3>{{ formattedCurrency(payment.amount) }}</h3>
      </div>
      <div v-if="payment.approval" class="approval">
        <p>
          <span
            v-if="!payment.ref"
            @click="editApprovalMode = !editApprovalMode"
          >
            <i class="fas fa-edit link"></i>
          </span>
          Approval
        </p>
        <h3 v-if="!editApprovalMode">{{ payment.approval }}</h3>
        <div v-else class="approvalForm">
          <BaseInput
            :value="newPayment.approval"
            @input="newPayment.approval = $event"
          />
          <BaseButton @click="editApproval">Submit</BaseButton>
        </div>
      </div>
      <div v-if="payment.staff" class="staff">
        <p>By Staff:</p>
        <h3>{{ payment.staff }}</h3>
      </div>
      <div class="detail">
        <p>At time:</p>
        <h3>{{ formatDate(payment.date) }}</h3>
      </div>
    </div>
    <div class="payment_actions">
      <BaseButton
        v-if="!refundable"
        @click="showConfirmSetStatusToDefault = true"
        mode="primary-outline"
        >Set Status To Default</BaseButton
      >

      <BaseButton
        v-if="refundable"
        :disabled="loading"
        @click="refund(false)"
        mode="danger-outline"
        ><i v-if="loading" class="fas fa-spinner"></i>Refund</BaseButton
      >
      <BaseButton @click="$emit('close')">Continue</BaseButton>
    </div>
  </section>

  <Modal
    title="Refund amount"
    :show="showRefundAmountModal"
    @close="showRefundAmountModal = false"
    zIndex="999999999999999999"
  >
    <form @submit.prevent="refund(true)" class="form refundForm">
      <BaseInput
        label="Amount"
        inputType="number"
        :value="refundAmount"
        @input="refundAmount = +$event"
      />
      <div class="form_actions">
        <BaseButton>Submit Refund</BaseButton>
      </div>
    </form>
  </Modal>

  <Confirm
    zIndex="9999999"
    v-if="showConfirmManualRefund"
    title="Mark manually refunded"
    text="There was an issue refunding this payment with your processer. This may mean the payment has already been refunded outside of Raserva. Do you wish to mark this payment as refunded manually? This will NOT refund the payment on the clients payment card."
    @confirm="forceManualRefund"
    @deny="showConfirmManualRefund = false"
  />

  <Confirm
    zIndex="9999999"
    v-if="showConfirmSetStatusToDefault"
    title="Set status to default"
    text="This payment was marked as refunded, this option will set the status of the payment to not be refunded. This will NOT recharge the payment on the clients payment card."
    @confirm="setStatusToDefault"
    @deny="showConfirmSetStatusToDefault = false"
  />
</template>

<script>
export default {
  emits: ['close', 'refunded', 'edit'],
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  created() {
    if (this.processor === 'stripe') {
      if (!this.isCentralTerminal) {
        this.stripeAccountId =
          this.$store.state.auth.user.paymentProcessing.stripe.accounts[0].id;
      } else {
        this.stripeAccountId =
          this.$store.state.auth.salon.billing.stripe.adminAccountId;
      }
    }

    this.newPayment = JSON.parse(JSON.stringify(this.payment));
    this.refundAmount = +this.payment.amount;
  },
  computed: {
    isCentralTerminal() {
      return this.$store.state.auth.salon.payments.centralTerminal;
    },
    processor() {
      return this.payment.processor;
    },
    refundable() {
      return this.payment.status === 'default';
    },
  },
  data() {
    return {
      loading: false,
      stripeAccountId: null,
      editApprovalMode: false,
      newPayment: null,
      showConfirmManualRefund: false,
      showConfirmSetStatusToDefault: false,
      showRefundAmountModal: false,
      refundAmount: 0,
    };
  },
  methods: {
    formattedCurrency(amount) {
      return `$${amount.toFixed(2)}`;
    },
    formatDate(date) {
      return this.$moment(date).format('LLL');
    },

    editApproval() {
      this.$emit('edit', this.newPayment);
    },

    setStatusToDefault() {
      this.newPayment.status = 'default';
      this.$emit('edit', this.newPayment);
    },

    async refund(skipAmountCheck = false) {
      // Amount check (partial refund)
      if (!skipAmountCheck) {
        this.showRefundAmountModal = true;
        this.refundAmount = +this.payment.amount;
        return;
      }

      // No processor
      if (!this.payment.processor) {
        this.$emit('refunded', {
          ...this.payment,
          // _id: Math.random().toString().split('.')[1],
          refundAmount: this.refundAmount,
        });
        return;
      }

      // Stripe
      if (this.payment.processor === 'stripe') {
        this.loading = true;
        this.stripeAccountId = this.payment.stripeAccountId;

        try {
          await this.$axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/refund`,
            {
              salonAccountId: this.stripeAccountId,
              payment_intent: this.payment.ref,
            },
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );

          this.$emit('refunded', {
            ...this.payment,
            // _id: Math.random().toString().split('.')[1],
            refundAmount: this.refundAmount,
          });
          this.$toast.success('Payment refunded');
        } catch (error) {
          this.$toast.error(error.response.data.error);
          this.showConfirmManualRefund = true;
        }

        this.loading = false;
      }

      // Dejavoo
      if (this.payment.processor === 'dejavoo') {
        this.showRefundAmountModal = false;
        this.loading = true;

        try {
          const refundResponse = await this.$store.dispatch('payments/refund', {
            ...this.payment,
            // _id: Math.random().toString().split('.')[1],
            refundAmount: this.refundAmount,
          });

          const payment = refundResponse.data || this.payment;

          if (refundResponse.refunded) {
            this.$emit('refunded', {
              ...payment,
              refundAmount: this.refundAmount,
            });
            this.$toast.success('Payment refunded');
          } else {
            this.showConfirmManualRefund = true;
            this.$toast.error(refundResponse.message);
          }
        } catch (error) {
          console.log(error);

          if (error.response && error.response.data) {
            this.$toast.error(error.response.data.error);
          }

          this.showConfirmManualRefund = true;
        }

        this.loading = false;
      }

      // Charge Anywhere
      if (this.payment.processor === 'chargeanywhere') {
        this.showRefundAmountModal = false;
        this.loading = true;

        try {
          const refundResponse = await this.$store.dispatch('payments/refund', {
            ...this.payment,
            // _id: Math.random().toString().split('.')[1],
            refundAmount: this.refundAmount,
          });

          const payment = refundResponse.data || this.payment;

          if (refundResponse.refunded) {
            this.$emit('refunded', {
              ...payment,
              refundAmount: this.refundAmount,
            });
            this.$toast.success('Payment refunded');
          } else {
            this.showConfirmManualRefund = true;
            this.$toast.error(refundResponse.message);
          }
        } catch (error) {
          console.log(error);

          if (error.response && error.response.data) {
            this.$toast.error(error.response.data.error);
          }

          this.showConfirmManualRefund = true;
        }

        this.loading = false;
      }

      // Card Connect
      if (this.payment.processor === 'cardconnect') {
        this.loading = true;

        try {
          const response = await this.$axios.get(
            `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/cardpointe/inquire?merchantId=${this.$store.state.auth.salon.billing.cardconnect.mid}&retref=${this.payment.ref}`
          );

          const data = response.data.data;

          if (data.voidable === 'Y') {
            // Void
            await this.$axios.post(
              `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/cardpointe/void?merchantId=${this.$store.state.auth.salon.billing.cardconnect.mid}`,
              {
                retref: this.payment.ref,
              }
            );

            this.$toast.success('Payment voided');
            this.$emit('refunded', {
              ...this.payment,
              // _id: Math.random().toString().split('.')[1],
              refundAmount: this.refundAmount,
            });
          } else if (data.refundable === 'Y') {
            // Refund
            await this.$axios.post(
              `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/cardpointe/refund?merchantId=${this.$store.state.auth.salon.billing.cardconnect.mid}`,
              {
                retref: this.payment.ref,
              }
            );

            this.$toast.success('Payment refunded');
            this.$emit('refunded', {
              ...this.payment,
              _id: Math.random().toString().split('.')[1],
              refundAmount: this.refundAmount,
            });
          } else {
            this.$toast.error('Can not void or refund payment');
          }
        } catch (error) {
          this.$toast.error(error.response.data.error);
        }

        this.loading = false;
      }
    },

    forceManualRefund() {
      this.showConfirmManualRefund = false;
      this.$emit('refunded', {
        ...this.payment,
        _id: Math.random().toString().split('.')[1],
        refundAmount: this.refundAmount,
      });
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
.payment {
  .alert {
    padding: 8px 24px;
    border: 1px solid var(--clr-danger);
    border-radius: 5px;
    color: var(--clr-danger);
    margin-bottom: 32px;
    font-size: 14px;
  }
  &_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 32px;

    p {
      font-size: 12px;
    }
    h3 {
      margin-top: 8px;
    }
  }
  &_actions {
    margin-top: 64px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 16px;
  }
}

.link {
  cursor: pointer;
  color: var(--clr-link);
}

.refundForm {
  padding: 32px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &_actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}

.approvalForm {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
