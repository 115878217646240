<template>
  <section class="createReview" v-if="salonDetails">
    <div class="salon_details">
      <SalonLogo />
      <div class="salon_details_actions">
        <router-link :to="{ name: 'SalonBook', params: { id: salonId } }">
          <BaseButton>Book an Appointment</BaseButton>
        </router-link>
      </div>
      <div
        v-if="salonDetails.shortDescription"
        class="salon_details_description"
      >
        <p>
          {{ salonDetails.shortDescription }}
        </p>
      </div>
      <div class="salon_details_location-hours">
        <div class="location">
          <i class="fas fa-map-marker-alt"></i>
          <p>
            {{ salonDetails.address.street }}, {{ salonDetails.address.city }},
            {{ salonDetails.address.state }} {{ salonDetails.address.postal }}
          </p>
        </div>
        <div class="hours">
          <SalonHours :schedule="salonSchedule" />
        </div>
      </div>
    </div>
    <div ref="form" v-if="!submitted" class="create">
      <h2>Create Review</h2>
      <form @submit.prevent="submit" class="form">
        <div class="rating">
          <p class="label">Rating</p>
          <i
            v-for="i in 5"
            @click="review.rating = i"
            :key="i"
            class="fa-star"
            :class="review.rating >= i ? 'fas' : 'far'"
          ></i>
          <p class="subtitle">(Current Rating: {{ review.rating }})</p>
        </div>

        <BaseTextarea
          :validators="{ maxLength: 1000 }"
          label="Review"
          @input="review.review = $event"
          :value="review.review"
        />

        <div class="form_actions">
          <BaseButton :disabled="loading"
            ><i v-if="loading" class="fas fa-spinner"></i> Submit
            Review</BaseButton
          >
        </div>
      </form>
    </div>
    <div v-else class="submitted">
      <i class="fas fa-check"></i>
      <div class="col">
        <h2>Review submitted!</h2>
        <p>Thank you for leaving a review with our salon.</p>
      </div>
    </div>
  </section>
</template>

<script>
import SalonLogo from '@/components/booking/SalonLogo.vue';
import SalonHours from '@/components/booking/SalonHours.vue';

export default {
  components: {
    SalonLogo,
    SalonHours,
  },
  async created() {
    try {
      this.$store.state.booking.salon = await this.$store.dispatch(
        'auth/getSalon',
        this.$route.params.id
      );

      this.review.salonId = this.$store.state.booking.salon._id;
    } catch (error) {
      this.$toast.error(error.message);

      if (!this.$store.state.booking.salon) {
        if (this.$store.state.booking.activeClient) {
          this.$router.push({ name: 'ClientProfile' });
        } else {
          this.$router.push({ name: 'ClientAuth' });
        }
      }
    }

    // Queries
    if (this.$route.query.tId) {
      const response = await this.$axios.get(
        `${process.env.VUE_APP_RASERVA_BACKEND}/tickets/${this.$route.query.tId}`
      );

      this.review.ticketId = response.data.ticket._id;
      this.review.client = response.data.ticket.client;

      if (response.data.ticket.reviewed) {
        this.$toast.info('A review has already been submitted for this visit');
        this.$router.push({ name: 'SalonPage', params: { id: this.salonId } });
      }
    } else {
      this.$toast.info('This salon only accepts verified visits');
      this.$router.push({ name: 'SalonPage', params: { id: this.salonId } });
    }
  },
  data() {
    return {
      loading: false,
      submitted: false,

      review: {
        salonId: null,
        rating: 5,
        review: '',
        client: null,
        ticketId: null,
      },
    };
  },
  methods: {
    async submit() {
      this.loading = true;

      try {
        await this.$store.dispatch('reviews/createReview', this.review);

        this.submitted = true;
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
  },
  computed: {
    salonId() {
      if (!this.$store.state.booking.salon) return;

      return this.$store.state.booking.salon._id;
    },
    salonDetails() {
      if (!this.$store.state.booking.salon) return;

      return this.$store.state.booking.salon.details;
    },
    salonSchedule() {
      if (!this.$store.state.booking.salon) return;

      return this.$store.state.booking.salon.schedule.default;
    },
  },
};
</script>

<style lang="scss" scoped>
.createReview {
  margin-top: 64px;
  display: flex;
  align-items: flex-end;
  gap: 64px;
  flex-wrap: wrap-reverse;
}

.create {
  flex-grow: 1;

  h2 {
    margin-bottom: 32px;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .label {
      margin-bottom: 5px;
      color: var(--clr-gray-2);
      font-size: 14px;
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .rating {
      i {
        margin-top: 8px;
        font-size: 24px;
        color: var(--clr-warning);
        cursor: pointer;

        &:not(:first-child) {
          margin-left: 5px;
        }
      }

      .subtitle {
        margin-top: 12px;
        font-size: 14px;
        color: var(--clr-gray);
      }
    }

    &_actions {
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }
  }
}

.submitted {
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 24px 32px;
  border-radius: 5px;
  background-color: var(--clr-success-light);

  i {
    font-size: 32px;
    color: var(--clr-success-dark);
  }

  p {
    margin-top: 5px;
  }
}

.salon_details {
  max-width: 400px;

  &_actions {
    margin-top: 16px;

    button {
      width: 100%;

      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }

  &_description {
    padding: 16px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;
    margin-top: 16px;

    p {
      line-height: 24px;
      color: var(--clr-gray);
    }
  }

  &_location-hours {
    margin-top: 32px;

    .location,
    .hours {
      display: flex;
      gap: 16px;

      i {
        color: var(--clr-light);
        margin-right: 16px;
        min-width: 20px;
      }

      .days {
        &_day {
          &:not(:first-child) {
            margin-top: 5px;
          }

          .day {
            min-width: 45px;
          }
          display: flex;
          gap: 16px;
        }
      }
    }

    .hours {
      margin-top: 16px;
    }
  }
}
</style>
