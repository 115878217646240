import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Third Party
import axios from 'axios';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import Toast, { POSITION } from 'vue-toastification';
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import './assets/css/main.css';

import BaseButton from '@/components/base/BaseButton.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BasePhoneInput from '@/components/base/BasePhoneInput.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import BaseTextarea from '@/components/base/BaseTextarea.vue';

import Avatar from '@/components/components/Avatar.vue';
import Backdrop from '@/components/components/Backdrop.vue';
import Card from '@/components/components/Card.vue';
import Confirm from '@/components/components/Confirm.vue';
import Modal from '@/components/components/Modal.vue';
import Pagination from '@/components/components/Pagination.vue';
import SidePanel from '@/components/components/SidePanel.vue';
import Spinner from '@/components/components/Spinner.vue';
import Table from '@/components/components/Table.vue';
import Tag from '@/components/components/Tag.vue';
import TheLogo from '@/components/components/TheLogo.vue';
import Tooltip from '@/components/components/Tooltip.vue';
import Toggle from '@/components/components/Toggle.vue';

const app = createApp(App);

app.use(store).use(router);

// Toast
app.use(Toast, {
  position: POSITION.BOTTOM_CENTER,
});
const toast = useToast();

// Global Properties
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$momentTz = momentTimezone;
app.config.globalProperties.$toast = toast;

app.component('BaseButton', BaseButton);
app.component('BaseInput', BaseInput);
app.component('BasePhoneInput', BasePhoneInput);
app.component('BaseSelect', BaseSelect);
app.component('BaseTextarea', BaseTextarea);

app.component('Avatar', Avatar);
app.component('Backdrop', Backdrop);
app.component('Card', Card);
app.component('Confirm', Confirm);
app.component('Modal', Modal);
app.component('Pagination', Pagination);
app.component('SidePanel', SidePanel);
app.component('Spinner', Spinner);
app.component('Table', Table);
app.component('Tag', Tag);
app.component('TheLogo', TheLogo);
app.component('Tooltip', Tooltip);
app.component('Toggle', Toggle);

app.mount('#app');
