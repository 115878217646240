<template>
  <div class="parent">
    <div class="label" v-if="label">
      <label>{{ label }}</label>
      <p v-if="validators && validators.maxLength">
        {{ `${length}/${validators.maxLength}` }}
      </p>
    </div>
    <div class="input">
      <textarea
        v-bind="$attrs"
        @input="updateValue"
        :value="value"
        ref="input"
      />
    </div>
    <p v-if="subtitle" class="subtitle">{{ subtitle }}</p>
  </div>
</template>

<script>
export default {
  emits: ['input'],
  props: {
    label: {
      type: String,
      default: '',
    },
    value: [String, Number],
    subtitle: {
      type: String,
    },
    validators: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    this.resize();
  },
  created() {
    if (this.value) this.length = this.value.length;
  },
  data() {
    return {
      length: 0,
    };
  },
  methods: {
    updateValue(event) {
      this.length = event.target.value.length;
      this.resize();
      this.$emit('input', event.target.value);
    },
    resize() {
      const { input } = this.$refs;

      if (!input.value) {
        input.style.height = '100%';
        return;
      }

      input.style.height = input.scrollHeight + 'px';
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  margin-bottom: 5px;
  color: var(--clr-gray-2);
  font-size: 14px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input {
  display: flex;
  align-items: center;
  line-height: 24px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid var(--clr-light);
  transition: border-color 0.2s;
  overflow: hidden;

  &:hover {
    border-color: var(--clr-gray);
  }
}

.subtitle {
  margin-top: 5px;
  font-size: 13px;
  color: var(--clr-gray);
}

textarea {
  resize: none;
  padding: 10px 16px;
  height: 100%;
  width: 100%;
  border: none;
  font-size: 16px;
  font-family: inherit;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: var(--clr-light) !important;
  }
}
</style>
