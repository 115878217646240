<template>
  <section class="card">
    <div v-if="product.image" class="card_image">
      <img :src="product.image" alt="Product Image" />
    </div>
    <div v-else class="card_image-none">
      <div class="text">
        <i class="fas fa-ban"></i>
        <p>No Image</p>
      </div>
    </div>

    <div class="card_info">
      <h3>{{ product.title }}</h3>
      <p class="price">${{ totalPrice.toFixed(2) }}</p>
      <p class="desc">
        {{ product.shortDescription }}
      </p>
      <BaseButton v-if="!isInCart" @click="$emit('add')" mode="secondary small"
        >Add to cart</BaseButton
      >
      <BaseButton v-else @click="$emit('remove')" mode="danger-outline small"
        >Remove from cart</BaseButton
      >
    </div>
  </section>
</template>

<script>
export default {
  emits: ['add', 'remove'],
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    additionalProcessingFee() {
      return (
        this.$store.state.booking.salon.payments
          .cashDiscountAdditionalProcessingFee || 0
      );
    },
    totalPrice() {
      return (
        this.product.price +
        this.product.price * (this.additionalProcessingFee * 0.01)
      );
    },
    isInCart() {
      return this.$store.state.booking.cart.find(
        (product) => product._id === this.product._id
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  gap: 32px;
  background-color: white;
  padding: 16px;
  border: 1px solid var(--clr-white-2);
  border-radius: 5px;

  &_image {
    height: 150px;
    min-height: 150px;
    width: 150px;
    min-width: 150px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &_image-none {
    background-color: var(--clr-light);
    min-height: 150px;
    width: 150px;
    min-width: 150px;
    display: grid;
    place-items: center;
    text-align: center;
    color: var(--clr-gray);

    i {
      font-size: 44px;
      margin-bottom: 12px;
    }
  }
  &_info {
    .price {
      margin-top: 5px;
      font-size: 14px;
      color: var(--clr-gray);
    }
    .desc {
      margin-top: 16px;
      line-height: 24px;
    }
    button {
      margin-top: 32px;
    }
  }
}

// Mobile
@media (max-width: 600px) {
  .card {
    flex-direction: column;
    align-items: center;

    &_info {
      text-align: center;
    }
  }
}
</style>
