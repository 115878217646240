<template>
  <form @submit.prevent="submit" class="form">
    <div class="form_section">
      <div class="form_section_head">
        <h3>Basic Info</h3>
      </div>
      <div class="form_section_body">
        <BaseInput
          label="Title"
          @input="newService.title = $event"
          :validators="validators.title"
          :value="newService.title"
        />
        <BaseSelect
          label="Category"
          :options="categoriesForSelect"
          placeholder="Select a category"
          @input="newService.category = $event"
          :value="newService.category"
        />
        <BaseTextarea
          label="Description"
          placeholder="Add a brief description"
          @input="newService.description = $event"
          :value="newService.description"
        />
        <BaseInput
          inputType="number"
          label="Turns Value"
          placeholder="0"
          :value="newService.turns"
          @input="newService.turns = $event"
          :validators="validators.turns"
          :subtitle="turnsSubtext"
        />
        <ColorSelector
          :selectedColor="newService.color"
          @selected="newService.color = $event"
        />
        <ImageSelect
          :fileSource="newService.image"
          @select="newService.image = $event"
        />
      </div>
    </div>

    <div class="form_section">
      <div class="form_section_head">
        <h3>Online Booking</h3>
        <p>The service will be provided as an option during online bookings</p>
      </div>

      <div class="form_section_body">
        <div class="toggle">
          <Toggle
            :checked="newService.onBooking"
            @toggle="newService.onBooking = !newService.onBooking"
          />
          <div class="toggle_text">
            <p class="toggle_text_label">Enable Calendar Bookings</p>
            <p class="toggle_text_subtext">
              Allow this service to be booked online by clients
            </p>
          </div>
        </div>

        <div v-if="newService.onBooking" class="toggle">
          <Toggle
            :checked="newService.requireCardInfoToBook"
            @toggle="
              newService.requireCardInfoToBook =
                !newService.requireCardInfoToBook
            "
          />
          <div class="toggle_text">
            <p class="toggle_text_label">Require card info</p>
            <p class="toggle_text_subtext">
              Requires a payment processor with these capabilities, overrides if
              "Collect Card Info" is set to false.
            </p>
          </div>
        </div>

        <div class="toggle">
          <Toggle
            :checked="newService.displayByConsultationOnBooking"
            @toggle="
              newService.displayByConsultationOnBooking =
                !newService.displayByConsultationOnBooking
            "
          />
          <div class="toggle_text">
            <p class="toggle_text_label">By consultation price type</p>
            <p class="toggle_text_subtext">
              Instead of the set price below, clients will see "By consultation"
              as the price during online bookings
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="form_section">
      <div class="form_section_head">
        <h3>Commission</h3>
      </div>
      <div class="form_section_body">
        <div class="toggle">
          <Toggle
            :checked="newService.isCommissioned"
            @toggle="newService.isCommissioned = !newService.isCommissioned"
          />
          <div class="toggle_text">
            <p class="toggle_text_label">Enable staff commission</p>
          </div>
        </div>
        <div v-if="newService.isCommissioned" class="col-2">
          <BaseSelect
            label="Commission Type"
            :options="commissionTypeOptions"
            @input="newService.commissionType = $event"
            :value="newService.commissionType"
          />
          <BaseInput
            v-if="newService.commissionType === 'flat'"
            inputType="number"
            label="Commission Amount"
            placeholder="0.00"
            @input="newService.commissionAmount = +$event"
            :value="newService.commissionAmount"
            :validators="validators.commissionAmount"
          />
        </div>
      </div>
    </div>

    <div class="form_section">
      <div class="form_section_head">
        <h3>Pricing and Duration</h3>
        <p>Add pricing and duration options of service</p>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newService.hasPricingLevels"
          @toggle="newService.hasPricingLevels = !newService.hasPricingLevels"
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Enable pricing levels</p>
          <p class="toggle_text_subtext">
            Set tiers with unique prices and commissions
          </p>
        </div>
      </div>

      <div class="form_section_body">
        <!-- Pricing levels -->
        <div v-if="newService.hasPricingLevels" class="levels">
          <div class="form_section_head">
            <h3>Pricing Levels</h3>
            <p>Overrides commission settings from "Commission" section above</p>
          </div>

          <div
            v-for="(level, index) in newService.levels"
            :key="index"
            class="levels_level"
          >
            <div class="col-4">
              <BaseInput
                :label="`Duration level ${index + 1}`"
                :value="level.duration"
                @input="level.duration = +$event"
              />
              <BaseInput
                :label="`Price level ${index + 1}`"
                :value="level.price"
                @input="level.price = +$event"
              />
              <BaseSelect
                label="Commission Type"
                :options="commissionTypeOptions"
                @input="level.commissionType = $event"
                :value="level.commissionType"
              />
              <BaseInput
                v-if="level.commissionType === 'flat'"
                inputType="number"
                label="Commission Amount"
                placeholder="0.00"
                @input="level.commissionAmount = +$event"
                :value="level.commissionAmount"
                :validators="validators.commissionAmount"
              />
            </div>
          </div>

          <div class="actions">
            <BaseButton
              type="button"
              mode="primary-outline"
              @click="addPricingLevel"
              >Add Level</BaseButton
            >
            <BaseButton
              v-if="newService.levels.length > 1"
              type="button"
              mode="danger-outline"
              @click="newService.levels.pop()"
              >Remove Level</BaseButton
            >
          </div>
        </div>

        <!-- No pricing levels -->
        <div v-else class="noPricingLevels">
          <div class="col-4">
            <BaseInput
              label="Duration"
              placeholder="Minutes"
              inputType="number"
              @input="newService.duration = +$event"
              :validators="validators.duration"
              :value="newService.duration"
            />
            <BaseSelect
              label="Price Type"
              :options="priceTypeOptions"
              :value="newService.priceType"
              @input="newService.priceType = $event"
            />
            <BaseInput
              :label="
                newService.priceType === 'duration'
                  ? 'Price per minute ($)'
                  : 'Price ($)'
              "
              placeholder="0.00"
              icon="fas fa-dollar-sign"
              inputType="number"
              @input="newService.price = +$event"
              :validators="validators.price"
              :value="newService.price"
            />
            <!-- <BaseInput
              label="Sale Price (Optional)"
              placeholder="0.00"
              icon="fas fa-dollar-sign"
              inputType="number"
              @input="newService.salePrice = +$event"
              :validators="validators.price"
              :value="newService.salePrice"
            /> -->
          </div>

          <div class="col-2">
            <BaseInput
              label="Extra Processing Time"
              inputType="number"
              placeholder="Minutes"
              subtitle="Staff can accept other bookings during this time"
              @input="newService.extraProcessingTime = +$event"
              :validators="validators.extraTime"
              :value="newService.extraProcessingTime"
            />
            <BaseInput
              label="Extra Blocked Time"
              inputType="number"
              placeholder="Minutes"
              subtitle="Block time after service is performed"
              @input="newService.extraBlockedTime = +$event"
              :validators="validators.extraTime"
              :value="newService.extraBlockedTime"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="form_section">
      <div class="form_section_head">
        <h3>Sales Settings</h3>
      </div>
      <div class="form_section_body">
        <BaseInput
          label="Tax Rate (%)"
          inputType="number"
          placeholder="%"
          subtitle="Added to price at checkout"
          @input="newService.taxRate = +$event"
          :validators="validators.tax"
          :value="newService.taxRate"
        />

        <div class="toggle">
          <Toggle
            :checked="newService.requiresCleaningLog"
            @toggle="
              newService.requiresCleaningLog = !newService.requiresCleaningLog
            "
          />
          <div class="toggle_text">
            <p class="toggle_text_label">Require Cleaning Log</p>
            <p class="toggle_text_subtext">
              Before check out, create a log requiring additional data such as
              chair number for cleaning logs
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="form_section">
      <div class="form_section_head">
        <h3>Service Fees</h3>
      </div>
      <div class="form_section_body">
        <div class="col-2">
          <BaseInput
            label="Supply Fees"
            inputType="number"
            placeholder="0.00"
            @input="newService.fees.supply = +$event"
            :validators="validators.fee"
            :value="newService.fees.supply"
          />
          <BaseInput
            label="Other Fees"
            inputType="number"
            placeholder="0.00"
            @input="newService.fees.other = +$event"
            :validators="validators.fee"
            :value="newService.fees.other"
          />
        </div>
      </div>
    </div>

    <div class="form_section">
      <div class="form_section_head">
        <h3>Service Suggestions</h3>
        <p>Suggest similar services or add-ons at checkout</p>
      </div>
      <div class="form_section_body">
        <div class="services_container">
          <p>
            <span class="bold">{{ newService.suggestedServices.length }}</span>
            suggested services
          </p>
          <p class="link" @click="showSuggestedServicesModal = true">Update</p>
        </div>
      </div>
    </div>

    <div v-if="salon.integrations.highLevel.enabled" class="form_section">
      <div class="form_section_head">
        <h3>High Level</h3>
        <p>Configure this service with your high level integration</p>
      </div>
      <div class="form_section_body">
        <BaseInput
          label="Associated Calendar"
          :value="newService.highLevel.associatedCalendar"
          @input="newService.highLevel.associatedCalendar = $event"
        />
      </div>
    </div>

    <div v-if="!newService._id" class="form_section">
      <div class="form_section_head">
        <h3>Capable Team Members</h3>
        <p>
          Select which members can perform this service (can be updated at any
          time by editing individual members)
        </p>
      </div>
      <div class="form_section_body">
        <div class="capable_container">
          <p>
            <span class="bold">{{ newService.members.length }}</span>
            out of <span class="bold">{{ teamMembers.length }}</span> members
            capable
          </p>
          <p class="link" @click="showTeamMembers = true">Update</p>
        </div>
      </div>
    </div>
    <div class="form_actions">
      <BaseButton
        v-if="newService._id"
        mode="danger-outline"
        type="button"
        @click="showConfirmDelete = true"
        >Delete Service</BaseButton
      >
      <BaseButton type="submit" :disabled="!formValid || loading"
        ><i v-if="loading" class="fas fa-spinner"></i> Submit</BaseButton
      >
    </div>
  </form>

  <Modal
    title="Select services"
    maxWidth="600px"
    :show="showSuggestedServicesModal"
    @close="showSuggestedServicesModal = false"
  >
    <StaffCapableServices
      :staffServices="newService.suggestedServices"
      :staffMode="true"
      ref="suggestedServicesModal"
      @submit="
        newService.suggestedServices = $event.services;
        showSuggestedServicesModal = false;
      "
      @exit="showSuggestedServicesModal = false"
    />
  </Modal>

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Delete service"
    text="Are you sure you wish to delete this service? This action can not be undone."
    @confirm="deleteService"
    @deny="showConfirmDelete = false"
  />

  <Modal
    title="Capable Team Members"
    :show="showTeamMembers"
    maxWidth="500px"
    @close="showTeamMembers = false"
  >
    <div class="capableMembers">
      <ul>
        <li
          v-for="member in teamMembers"
          :key="member._id"
          @click="toggleMember(member._id)"
        >
          <input type="checkbox" :checked="isCapable(member._id)" />
          <p>{{ `${member.firstName} ${member.lastName}` }}</p>
        </li>
      </ul>
      <div class="actions">
        <BaseButton mode="primary-outline" @click="selectAllMembers"
          >Select All</BaseButton
        >
        <BaseButton @click="showTeamMembers = false">Continue</BaseButton>
      </div>
    </div>
  </Modal>
</template>

<script>
import ColorSelector from '@/components/components/ColorSelector.vue';
import ImageSelect from '@/components/components/ImageSelect.vue';
import StaffCapableServices from '@/components/staff/StaffCapableServices.vue';

export default {
  emits: ['submitted', 'deleted'],
  components: {
    ColorSelector,
    ImageSelect,
    StaffCapableServices,
  },
  props: {
    service: {
      type: Object,
    },
  },
  created() {
    if (this.service)
      this.newService = JSON.parse(
        JSON.stringify({ ...this.newService, ...this.service })
      );
  },
  data() {
    return {
      showConfirmDelete: false,
      loading: false,

      showTeamMembers: false,
      showSuggestedServicesModal: false,

      newService: {
        title: '',
        category: null,
        description: '',
        color: '#a6c7ea',
        onBooking: true,
        isCommissioned: true,
        commissionType: 'percent',
        commissionAmount: 0,
        duration: 15,
        turns: 0,
        priceType: 'from',
        price: 0,
        salePrice: null,
        displayByConsultationOnBooking: false,
        taxRate: 0,
        extraProcessingTime: 0,
        extraBlockedTime: 0,
        fees: {
          supply: 0,
          other: 0,
        },
        members: [],
        hasPricingLevels: false,
        levels: [
          {
            duration: 1,
            price: 0,
            commissionType: 'percent',
            commissionAmount: 0,
          },
        ],
        image: '',
        requireCardInfoToBook: false,
        requiresCleaningLog: false,
        suggestedServices: [],
        highLevel: {
          associatedCalendar: '',
        },
      },

      priceTypeOptions: [
        {
          option: 'From',
          value: 'from',
        },
        {
          option: 'Fixed',
          value: 'fixed',
        },
        {
          option: 'Duration',
          value: 'duration',
        },
      ],
    };
  },
  computed: {
    salon() {
      return this.$store.state.auth.salon;
    },
    teamMembers() {
      return this.$store.state.staff.staff;
    },
    turnsSubtext() {
      return 'Services may have a turn value which assists with salons fairly distributing walk-ins.';
    },
    commissionTypeOptions() {
      return [
        {
          option: 'Flat',
          value: 'flat',
        },
        {
          option: 'Percent (By Individual Staff)',
          value: 'percent',
        },
      ];
    },
    formValid() {
      if (
        !this.newService.title ||
        !this.newService.duration ||
        (!this.newService.tax && this.newService.tax === 0) ||
        (!this.newService.fee && this.newService.fee === 0)
      )
        return false;
      return true;
    },
    validators() {
      return {
        title: {
          required: true,
          minLength: 2,
          maxLength: 75,
        },
        description: {
          minLength: 2,
          maxLength: 500,
        },
        turns: {
          required: true,
        },
        price: {
          required: true,
          min: 0,
        },
        duration: {
          required: true,
          min: 1,
        },
        extraTime: {
          min: 0,
        },
        tax: {
          required: true,
          min: 0,
        },
        fee: {
          required: true,
          min: 0,
        },
      };
    },
    categories() {
      return this.$store.state.items.services;
    },
    categoriesForSelect() {
      return this.categories.map((category) => {
        return {
          option: category.title,
          value: category._id,
        };
      });
    },
  },
  methods: {
    async submit() {
      this.loading = true;

      try {
        if (this.newService._id) {
          await this.$store.dispatch('items/editService', this.newService);
          this.submitted();
          return;
        }

        // Testing to give document ID without database
        // if (!this.newService._id) this.newService._id = Math.random().toString();

        await this.$store.dispatch('items/createService', this.newService);
        this.submitted();
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
    submitted() {
      this.$toast.success('Service updated');
      this.$emit('submitted');
    },

    deleteService() {
      this.$store.dispatch('items/deleteService', this.newService);
      this.$toast.success('Service deleted');

      this.$emit('deleted', this.newService._id);
    },

    isCapable(memberId) {
      return this.newService.members.find((member) => member === memberId);
    },

    selectAllMembers() {
      this.newService.members = [];

      this.teamMembers.forEach((member) =>
        this.newService.members.push(member._id)
      );
    },

    toggleMember(memberId) {
      const index = this.newService.members.findIndex(
        (member) => member === memberId
      );

      if (index === -1) {
        this.newService.members.push(memberId);
      } else {
        this.newService.members.splice(index, 1);
      }
    },

    addPricingLevel() {
      this.newService.levels.push({
        price: 0,
        commissionType: 'percent',
        commissionAmount: 0,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  &_section {
    padding: 32px;
    border: 1px solid var(--clr-light-2);
    border-radius: 5px;

    &:not(:first-child) {
      margin-top: 16px;
    }

    &_head {
      margin-bottom: 16px;

      p {
        font-size: 14px;
        margin-top: 5px;
        color: var(--clr-gray);
      }
    }

    .col-2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }
    .col-3 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
    }
    .col-4 {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;
    }

    &_body {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;
    }
  }

  .services_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: var(--clr-white-2);
    border-radius: 5px;

    .bold {
      font-weight: 700;
    }
    .link {
      transition: text-decoration 0.2s ease;
      cursor: pointer;
      color: var(--clr-link);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .levels {
    margin-top: 16px;

    &_level {
      &:not(:first-child) {
        margin-top: 16px;
      }
    }

    .actions {
      display: flex;
      gap: 16px;
      margin-top: 16px;
    }
  }

  .toggle {
    display: flex;
    align-items: center;
    gap: 32px;
    flex-wrap: wrap;
    padding: 16px 0;

    &_text {
      &_label {
        font-size: 18px;
      }
      &_subtext {
        margin-top: 5px;
        font-size: 14px;
      }
    }
  }

  .capable_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: var(--clr-white-2);
    border-radius: 5px;

    .bold {
      font-weight: 700;
    }
    .link {
      transition: text-decoration 0.2s ease;
      cursor: pointer;
      color: var(--clr-link);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &_actions {
    margin-top: 16px;
    text-align: right;

    button:not(:first-child) {
      margin-left: 16px;
    }
  }
}

// Tablet
@media (max-width: 900px) {
  .form {
    &_section {
      .col-2,
      .col-3,
      .col-4 {
        grid-template-columns: 1fr;
      }
    }
  }
}

.capableMembers {
  li {
    padding: 16px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.2s ease;
    border-bottom: 1px solid var(--clr-light);

    &:hover {
      background-color: var(--clr-white);
    }
  }
  .actions {
    padding: 16px;
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
