<template>
  <div class="input">
    <div class="label" v-if="label">
      <label>Color</label>
    </div>
    <div class="input_input">
      <div class="selected" @click="showColors = !showColors">
        <div
          class="selectedColor"
          :style="{ backgroundColor: selectedColor }"
        ></div>
        <i class="fas fa-angle-down"></i>
      </div>
      <Tooltip v-if="showColors" @hide="showColors = false">
        <div class="colors">
          <div
            class="color"
            v-for="color in colors"
            :key="color"
            :style="{ backgroundColor: color }"
            @click="
              $emit('selected', color);
              showColors = false;
            "
          ></div>
        </div>
      </Tooltip>
    </div>
  </div>
</template>

<script>
import Tooltip from './Tooltip.vue';
export default {
  emits: ['selected'],
  props: {
    label: {
      type: Boolean,
      default: true,
    },
    selectedColor: {
      type: String,
      default: '#a6c7ea',
      required: true,
    },
  },
  data() {
    return {
      showColors: false,
      colors: [
        '#a6c7ea',
        '#ceb3d6',
        '#a9dbd2',
        '#bbdcad',
        '#f9f18c',
        '#fbdddd',
        '#f9a485',
        '#f59597',
        // Additional
        '#C8F69B',
        '#B3EEFF',
        '#FFCBA5',
        '#F2ACEB',
      ],
    };
  },
  components: { Tooltip },
};
</script>

<style lang="scss" scoped>
.input {
  .label {
    margin-bottom: 5px;
    color: var(--clr-gray-2);
    font-size: 14px;
    font-weight: 700;
  }

  &_input {
    position: relative;

    .selected {
      padding: 16px 0;
      display: flex;
      align-items: center;
      gap: 32px;
      cursor: pointer;
    }

    .selectedColor,
    .color {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      border: 2px solid transparent;
      transition: border-color 0.2s;
      cursor: pointer;

      &:hover {
        border-color: var(--clr-link);
      }
    }

    .colors {
      padding: 32px;
      background-color: white;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 32px;
    }
  }
}
</style>
