<template>
  <section class="endpoints">
    <div class="alert alert-info">
      <p>
        This page is intentionally bare as most actions will have their own
        endpoint
      </p>
    </div>

    <div class="endpoints_router">
      <p class="bold">On this page:</p>

      <ul>
        <li class="link" @click="scrollIntoView('get')">Get salon</li>
        <!-- <li class="link" @click="scrollIntoView('update')">Update salon</li> -->
      </ul>
    </div>

    <!-- Salon get -->
    <div class="endpoint" id="create" ref="create">
      <DocsAPISalonsGet />
    </div>

    <!-- Salon update
    <div class="endpoint" id="update" ref="update">
      <DocsAPISalonsUpdate />
    </div> -->
  </section>
</template>

<script>
import DocsAPISalonsGet from './DocsAPISalonsGet.vue';
// import DocsAPISalonsUpdate from './DocsAPISalonsUpdate.vue';

export default {
  components: {
    DocsAPISalonsGet,
    // DocsAPISalonsUpdate,
  },
  methods: {
    scrollIntoView(element) {
      this.$refs[element].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  padding: 12px;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 16px;

  &-info {
    background-color: #c3edf7;
    color: #044653;
    border-color: #aceaf7;
  }
}
.endpoints {
  &_router {
    margin-bottom: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-white-2);

    ul {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 32px;

      .link {
        cursor: pointer;
        color: var(--clr-link);
      }
    }
  }

  .endpoint {
    &:not(:first-child) {
      margin-top: 64px;
    }
  }
}
</style>
