<template>
  <section class="booking">
    <BookingHeader />
    <div class="router">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import BookingHeader from '@/components/booking/BookingHeader.vue';

export default {
  components: {
    BookingHeader,
  },
};
</script>

<style lang="scss" scoped>
.booking {
  min-height: 100vh;
  background-color: var(--clr-white);
  display: flex;
  flex-direction: column;

  .router {
    flex-grow: 1;
    max-width: 1400px;
    width: 100%;
    margin: auto;
    padding: 0 16px;
  }
}
</style>
