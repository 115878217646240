<template>
  <div class="login">
    <div v-if="step === 'options'" class="login_options">
      <BaseButton @click="step = 'register'">Sign up with email</BaseButton>
      <!-- <BaseButton mode="primary-outline">
        <p>Continue with Google</p>
      </BaseButton>
      <BaseButton mode="primary-outline">
        <p>Continue with Facebook</p>
      </BaseButton> -->
      <div class="login" @click="step = 'login'">
        <p>Have an account?</p>
        <p class="link">Log in here</p>
      </div>
    </div>
    <div v-if="step === 'register'" class="login_register">
      <form @submit.prevent="register" class="form">
        <BaseInput
          label="First name"
          placeholder="Your first name"
          @input="client.firstName = $event"
          :value="client.firstName"
          :validators="validators.min2Max500"
        />
        <BaseInput
          label="Last name"
          placeholder="Your last name"
          @input="client.lastName = $event"
          :value="client.lastName"
          :validators="validators.min2Max500"
        />
        <BasePhoneInput
          label="Mobile number"
          placeholder="Your phone number"
          inputType="number"
          @input="client.phoneNumber = $event"
          :value="client.phoneNumber"
          :validators="validators.min2Max500"
        />
        <BaseInput
          label="Email address"
          placeholder="Your email address"
          @input="client.email = $event"
          :value="client.email"
          :validators="validators.email"
        />
        <BaseInput
          :inputType="registerPasswordInput"
          :value="client.password"
          label="Password"
          placeholder="Create a password"
          icon="fas fa-eye"
          @input="client.password = $event"
          :validators="validators.password"
          @iconClick="toggleRegisterPasswordInput"
        />
        <div class="toggle">
          <Toggle
            class="toggle_toggle"
            :checked="client.notifications.text"
            @toggle="client.notifications.text = !client.notifications.text"
          />
          <div class="toggle_text">
            <p class="toggle_text_label">
              I agree to receive SMS messaging from Raserva and booking
              establishments
            </p>
            <p class="toggle_text_subtext">
              Establishments that you book with may send you SMS messages with
              deals, offers and updates. Message frequency varies. Message and
              data rates may apply. Help at (850) 400-4585. Text STOP to opt
              out.
              <br />
              <br />
              <span class="link">Terms And Conditions</span> |
              <span class="link">Privacy Policy</span>
            </p>
          </div>
        </div>
        <div class="form_actions">
          <BaseButton :disabled="!registerFormValid || loading"
            ><i v-if="loading" class="fas fa-spinner"></i> Sign up</BaseButton
          >
        </div>
        <div class="login" @click="step = 'login'">
          <p>Have an account?</p>
          <p class="link">Log in here</p>
        </div>
      </form>
    </div>
    <div v-if="step === 'login'" class="login_login">
      <form @submit.prevent="login" class="form">
        <BaseInput
          label="Email address"
          placeholder="Your email address"
          @input="existingClient.email = $event"
          :value="existingClient.email"
        />
        <BaseInput
          inputType="password"
          label="Password"
          placeholder="Your password"
          @input="existingClient.password = $event"
        />
        <div class="form_actions">
          <BaseButton :disabled="!loginFormValid || loading"
            ><i v-if="loading" class="fas fa-spinner"></i> Log in</BaseButton
          >
          <BaseButton
            type="button"
            mode="primary-outline"
            @click="showPhoneLogin = true"
            ><i v-if="loading" class="fas fa-spinner"></i> Or, Log in Using
            Phone Number</BaseButton
          >

          <p class="link" @click="showRecovery = true">Forgot Password?</p>
        </div>
        <div class="login" @click="step = 'register'">
          <p>Don't have an account?</p>
          <p class="link">Sign up here</p>
        </div>
      </form>
    </div>
  </div>

  <Modal
    title="Account Recovery"
    :show="showRecovery"
    @close="showRecovery = false"
  >
    <div class="recoveryForm">
      <AccountRecovery accountType="client" @reset="showRecovery = false" />
    </div>
  </Modal>

  <Modal
    title="Phone Log In"
    :show="showPhoneLogin"
    @close="showPhoneLogin = false"
  >
    <div class="phoneLoginForm">
      <KeepAlive>
        <ClientPhoneLogin @success="$emit('registered')" />
      </KeepAlive>
    </div>
  </Modal>
</template>

<script>
import AccountRecovery from '@/components/auth/AccountRecovery.vue';
import ClientPhoneLogin from '@/components/clients/ClientPhoneLogin.vue';

export default {
  emits: ['login', 'registered'],
  components: {
    AccountRecovery,
    ClientPhoneLogin,
  },
  mounted() {
    if (this.$store.state.booking.currentBookingTracking) {
      this.$store.dispatch(
        'booking/updateBookingTracking',
        'Client Authentication'
      );
    }

    let prefillEmail = this.$route.query.email;

    if (prefillEmail) {
      this.existingClient.email = prefillEmail;
    }
  },
  computed: {
    registerFormValid() {
      if (
        !this.client.firstName ||
        !this.client.lastName ||
        !this.client.phoneNumber ||
        !this.client.email ||
        !this.client.password
      )
        return false;
      return true;
    },
    loginFormValid() {
      if (!this.existingClient.email || !this.existingClient.password)
        return false;
      return true;
    },
    validators() {
      return {
        min2Max500: {
          required: true,
          minLength: 2,
          maxLength: 50,
        },
        email: {
          required: true,
          email: true,
        },
        password: {
          required: true,
          minLength: 6,
          maxLength: 100,
        },
      };
    },
  },
  data() {
    return {
      step: 'options',
      client: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        password: '',
        gender: null,
        dateOfBirth: null,
        notifications: {
          email: true,
          text: false,
          marketing: true,
        },
        note: '',
        globalClient: true,
      },
      existingClient: {
        email: '',
        password: '',
      },

      registerPasswordInput: 'password',
      loading: false,

      showRecovery: false,
      showPhoneLogin: false,
    };
  },
  methods: {
    toggleRegisterPasswordInput() {
      if (this.registerPasswordInput === 'password') {
        this.registerPasswordInput = 'text';
      } else {
        this.registerPasswordInput = 'password';
      }
    },
    async register() {
      this.loading = true;

      try {
        const client = await this.$store.dispatch(
          'clients/createGlobalClient',
          this.client
        );
        this.$store.commit('booking/setActiveClient', client);
        this.$emit('registered', true);

        this.$store.dispatch(
          'booking/updateBookingTracking',
          'Client Registered'
        );
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
    async login() {
      this.loading = true;

      try {
        await this.$store.dispatch('auth/clientLogin', this.existingClient);
        this.$emit('registered', false);

        this.$store.dispatch(
          'booking/updateBookingTracking',
          'Client Logged In'
        );
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle {
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 16px 0;

  .link {
    color: var(--clr-link);
    cursor: pointer;
  }

  &_toggle {
    min-width: 50px;
  }

  &_text {
    &_label {
      font-size: 18px;
    }
    &_subtext {
      margin-top: 5px;
      font-size: 14px;
    }
  }
}
.login {
  &_options {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .login {
      margin-top: 16px;
      text-align: center;
      font-size: 15px;

      p {
        color: var(--clr-gray);
      }

      .text-center {
        text-align: center;
      }

      .link {
        color: var(--clr-link);
        cursor: pointer;
      }
    }
  }
  &_register,
  &_login {
    .form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &_actions {
        button {
          width: 100%;

          &:not(:first-child) {
            margin-top: 16px;
          }
        }

        .link {
          text-align: right;
          color: var(--clr-link);
          font-size: 14px;
          cursor: pointer;
          margin-top: 16px;
        }
      }

      .login {
        margin-top: 16px;
        text-align: center;
        font-size: 15px;

        p {
          color: var(--clr-gray);
        }

        .link {
          color: var(--clr-link);
          cursor: pointer;
        }
      }
    }
  }
}

.recoveryForm {
  padding: 32px;
}
</style>
