import { createStore } from 'vuex';

import adminModule from './admin.js';
import appointmentsModule from './appointments.js';
import bookingModule from './booking.js';
import authModule from './auth.js';
import clientsModule from './clients.js';
import itemsModule from './items.js';
import giftcardsModule from './giftcards.js';
import marketingModule from './marketing.js';
import messengerModule from './messenger.js';
import packagesModule from './packages.js';
import paymentsModule from './payments.js';
import reviewsModule from './reviews.js';
import salonModule from './salon.js';
import socketsModule from './sockets.js';
import staffModule from './staff.js';
import stripeModule from './stripe.js';
import stylesModule from './styles.js';
import ticketsModule from './tickets.js';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    admin: adminModule,
    appointments: appointmentsModule,
    booking: bookingModule,
    auth: authModule,
    clients: clientsModule,
    items: itemsModule,
    giftcards: giftcardsModule,
    messenger: messengerModule,
    marketing: marketingModule,
    packages: packagesModule,
    payments: paymentsModule,
    reviews: reviewsModule,
    salon: salonModule,
    sockets: socketsModule,
    staff: staffModule,
    stripe: stripeModule,
    styles: stylesModule,
    tickets: ticketsModule,
  },
});
