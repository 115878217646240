<template>
  <section class="clients">
    <div class="clients_sidebar">
      <SubSidebar :routes="routes" title="Clients" />
    </div>
    <div class="clients_router">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import SubSidebar from '@/components/components/SubSidebar.vue';

export default {
  components: {
    SubSidebar,
  },
  data() {
    return {
      routes: [
        {
          name: 'Clients List',
          linkName: 'ClientsList',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.clients {
  display: flex;
  height: 100%;

  &_router {
    overflow-y: auto;
    flex-grow: 1;
    padding: 32px;
    display: flex;
    justify-content: center;
  }
}
</style>
