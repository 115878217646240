<template>
  <div class="input">
    <div class="label" v-if="label">
      <label>Service</label>
    </div>
    <div class="input_input">
      <BaseInput
        placeholder="Select a service"
        :value="selectedService ? selectedServiceString : null"
        @input="filterServices($event)"
        :clear="clearCount"
        :icon="showDropdown ? 'fas fa-angle-up' : 'fas fa-angle-down'"
        ref="serviceInput"
        @icon-click="showDropdown = !showDropdown"
        @focus="showDropdown = true"
      >
        <i
          @click="clearService($event)"
          class="fas fa-times serviceSelectClear"
        ></i>
      </BaseInput>

      <Tooltip v-if="showDropdown" @hide="showDropdown = false" width="100%">
        <div class="services">
          <div
            class="services_category"
            v-for="category in filteredCategories"
            :key="category"
          >
            <div class="services_category_head">
              <h2>{{ category.title }}</h2>
            </div>
            <div
              class="services_category_body"
              :style="{ borderColor: category.color }"
            >
              <div
                class="services_category_body_service"
                v-for="service in category.items"
                :key="service"
                @click="selectService(service)"
              >
                <div class="col">
                  <p class="bold">{{ service.title }}</p>
                  <p class="gray">{{ service.duration }}min</p>
                </div>
                <div class="col price">
                  <p v-if="service.hasPricingLevels" class="gray">
                    Multi-level
                  </p>
                  <p
                    v-if="!service.hasPricingLevels && service.price"
                    class="bold"
                  >
                    ${{ service.price }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['select'],
  props: {
    label: {
      type: Boolean,
      default: true,
    },
    service: {
      type: Object,
    },
  },
  data() {
    return {
      selectedService: null,
      showDropdown: false,
      filteredCategories: null,
      clearCount: 0,
    };
  },
  created() {
    if (this.service && this.service.service)
      this.selectedService = this.service.service;

    this.filteredCategories = [...this.categories];
  },
  computed: {
    categories() {
      return this.$store.state.items.services;
    },
    selectedServiceString() {
      let str = `${this.selectedService.title} (${this.selectedService.duration}min)`;

      if (this.selectedService.levels && this.selectedService.levels.length) {
        str += ', Multi-level';
      } else {
        str += `, $${this.selectedService.price}`;
      }

      return str;
    },
  },
  methods: {
    hideDropdown() {
      setTimeout(() => (this.showDropdown = false), 250);
    },
    toggleDropdown() {
      this.$refs.serviceInput.focusInput();

      if (!this.showDropdown) {
        this.selectedService = null;
        this.clearFilteredServices();
      }

      this.showDropdown = !this.showDropdown;
    },
    selectService(service) {
      const category = this.categories.find((category) =>
        category.items.find((item) => item._id === service._id)
      );

      if (category) service.categoryId = category._id;

      this.selectedService = service;
      this.showDropdown = false;
    },
    clearService(e) {
      e.stopPropagation();
      this.clearCount++;
      this.showDropdown = false;
      this.selectedService = null;
    },
    filterServices(val) {
      if (!val) {
        this.clearFilteredServices();
        return;
      }

      const filteredCategories = JSON.parse(
        JSON.stringify(this.categories)
      ).filter((category) => {
        return category.items.some((service) => {
          return service.title.toLowerCase().includes(val.toLowerCase());
        });
      });

      filteredCategories.forEach((category) => {
        category.items = category.items.filter((item) => {
          return item.title.toLowerCase().includes(val.toLowerCase());
        });
      });

      this.filteredCategories = filteredCategories;
    },
    clearFilteredServices() {
      this.filteredCategories = JSON.parse(JSON.stringify(this.categories));
    },
  },
  watch: {
    selectedService(val) {
      this.$emit('select', val);
    },
  },
};
</script>

<style lang="scss" scoped>
.gray {
  color: var(--clr-gray);
}

.label {
  margin-bottom: 5px;
  color: var(--clr-gray-2);
  font-size: 14px;
  font-weight: 700;
}

.input {
  &_input {
    position: relative;
    max-width: 100%;

    .text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      i {
        color: var(--clr-gray);
      }
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      background-color: var(--clr-light) !important;
    }
  }
}

.serviceSelectClear {
  font-size: 12px;
  color: var(--clr-gray);
  font-weight: 700;
  margin-left: 8px;
  margin-right: 16px;
  cursor: pointer;
}

.services {
  background-color: white;
  max-height: 500px;
  overflow-y: auto;

  .bold {
    font-weight: 700;
  }
  .gray {
    color: var(--clr-gray);
    font-size: 14px;
  }

  &_category {
    &_head {
      padding: 16px 32px;
    }
    &_body {
      border-left: 5px solid transparent;

      &_service {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        cursor: pointer;
        transition: background-color 0.2s;
        border-bottom: 1px solid var(--clr-light);

        .price {
          text-align: right;
          margin-left: 32px;
        }

        &:hover {
          background-color: var(--clr-white);
        }
      }
    }
  }
}
</style>
