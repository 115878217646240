import axios from 'axios';
import moment from 'moment';
import router from '../router/index';

export default {
  namespaced: true,
  state: {
    user: null,
    salon: null,
    loggedInSalonStaff: null,
    tempUser: null,
    token: null,
    subActive: true,
    invite: null,
    managerMode: false,
  },
  mutations: {},
  actions: {
    async createStaffAccount({ dispatch, state }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/staff`,
          { ...payload, invite: state.invite }
        );

        state.user = response.data.staff;
        state.token = response.data.token;
        state.invite = null;

        // Storage
        dispatch('saveStorage', {
          salonId: '',
          staffId: state.user._id,
          token: state.token,
        });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async createSalon({ state }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons`,
          {
            ...payload,
            adminId: state.user._id,
          }
        );

        state.token = response.data.token;
        state.salon = response.data.salon;
        state.subActive = false;

        state.user.salons.push({
          joined_at: new Date(Date.now()),
          name: response.data.salon.details.shopName,
          salonId: response.data.salon._id,
        });

        state.loggedInSalonStaff = response.data.salon.staff.find(
          (staff) => staff.staffId === state.user._id
        );
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async addAction({ state }, payload) {
      try {
        let { staff, type, metadata } = payload;

        if (!staff) {
          staff = state.user;
        }

        let text;

        if (typeof staff === 'object') {
          text = `${staff.firstName} ${staff.lastName} ${payload.action}`;
        } else {
          text = `${staff} ${payload.action}`;
        }

        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${state.salon._id}/actions`,
          { text, type, metadata },
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        );

        state.salon.actions = response.data.actions;
      } catch (error) {
        console.log(error);
        throw Error(error.response.data.error);
      }
    },

    logout({ state, dispatch, rootState }) {
      // Leave socket rooms
      if (state.salon)
        dispatch('sockets/leave', state.salon._id, { root: true });
      if (state.user) dispatch('sockets/leave', state.user._id, { root: true });

      state.user = null;
      state.salon = null;
      state.tempUser = null;
      state.managerMode = false;
      rootState.socket = null;

      dispatch('clearStorage');
    },

    clientLogout({ commit }) {
      commit('booking/clearActiveClient', null, { root: true });
    },

    nullSalon({ state, rootState, dispatch }) {
      if (state.salon) {
        dispatch('sockets/leave', state.salon._id, { root: true });
      }

      state.salon = null;
      localStorage.removeItem('salonId');
      rootState.giftcards.giftcards = [];
    },

    async toggleWorking({ dispatch, state, rootState }, payload) {
      let staff;
      let clockedInBy;

      if (!payload) {
        staff = rootState.staff.staff.find(
          (staff) =>
            staff._id.toString() === state.loggedInSalonStaff._id.toString()
        );
      } else {
        staff = rootState.staff.staff.find(
          (staff) => staff.accountCode === payload
        );

        clockedInBy = `${state.user.firstName} ${state.user.lastName}`;
      }

      if (!staff) throw Error('Staff not found');

      try {
        await dispatch(
          'staff/editStaff',
          {
            ...staff,
            working: !staff.working,
            available: true,
            toggledWorking: true,
            clockedInBy,
          },
          { root: true }
        );

        staff.working = !staff.working;
        rootState.tickets.changeCount++;

        rootState.sockets.socket.emit('updateStaff', {
          staff: rootState.staff.staff,
          salonId: state.salon._id,
        });

        return staff.working
          ? `${staff.firstName} clocked in`
          : `${staff.firstName} clocked out`;
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          throw Error(error.response.data.error);
        }

        throw Error(error.message);
      }
    },

    async clockOutAll({ state, rootState }) {
      try {
        await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${state.salon._id}/staff/clockOutAll`,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        );

        state.salon.staff.forEach((staff) => {
          staff.working = false;
          staff.currentShift = null;
          staff.available = false;
          staff.additionalTurnsLogs = [];
          staff.additionalTurns = 0;
        });

        rootState.sockets.socket.emit('updateStaff', {
          staff: rootState.staff.staff,
          salonId: state.salon._id,
        });

        rootState.sockets.socket.emit('updateStaffTurnOrder', {
          staff: state.salon.customStaffTurnOrder,
          salonId: state.salon._id,
        });

        rootState.tickets.changeCount++;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    activeUserHasPermission({ state }, payload) {
      if (!state.loggedInSalonStaff || !state.salon) return;

      // Salon owner
      if (
        !state.tempUser &&
        state.loggedInSalonStaff.staffId === state.salon.adminId
      )
        return true;

      // Manager mode
      if (state.managerMode) return true;

      // Role = admin
      if (!state.tempUser && state.loggedInSalonStaff.role === 'admin')
        return true;

      const [root, permission] = payload.split('/');

      // Temp user
      if (state.tempUser) {
        return state.salon.permissions[root].permissions[permission][
          state.tempUser.role
        ];
      }

      // Logged in user
      return state.salon.permissions[root].permissions[permission][
        state.loggedInSalonStaff.role
      ];
    },
    async getSalon(_, payload) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${payload}`
        );

        return response.data.salon;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    setStateFromSalonObject({ rootState, state, dispatch }, salon) {
      // Set state
      rootState.items.services = salon.services;
      rootState.items.products = salon.products;
      rootState.items.discounts = salon.discounts;
      rootState.tickets.tickets = salon.tickets;
      rootState.staff.staff = salon.staff;
      rootState.salon.salon = salon;
      state.salon = salon;

      if (state.user) {
        // Set Salon Staff
        state.loggedInSalonStaff = salon.staff.find(
          (staff) => staff.staffId === state.user._id
        );

        // Storage
        dispatch('saveStorage', {
          salonId: state.salon._id,
          staffId: state.user._id,
          token: state.token,
        });
      }
    },
    async selectSalon({ dispatch, state }, payload) {
      try {
        const salon = await dispatch('getSalon', payload);

        const staff = salon.staff.find((s) => s.staffId === state.user._id);

        if (!salon.billing.stripe || !salon.billing.stripe.customerId) {
          state.subActive = false;

          if (salon.adminId === state.user._id) {
            throw Error('Subscribe to continue');
          } else {
            throw Error('Salon not subscribed, contact manager');
          }
        } else if (moment().isSameOrAfter(salon.billing.currentPeriodEnd)) {
          state.subActive = false;

          if (salon.adminId === state.user._id) {
            throw Error('Subscription inactive');
          } else {
            throw Error('Subscription inactive, contact manager');
          }
        } else if (staff && staff.isDeleted) {
          state.subActive = false;

          throw Error('You have been removed from this salon');
        } else {
          dispatch('sockets/join', salon._id, { root: true });

          await dispatch('setStateFromSalonObject', salon);

          state.subActive = true;
        }
      } catch (error) {
        throw Error(error.message);
      }
    },
    async memberLogin({ dispatch, state, rootState }, payload) {
      rootState.socket = null;

      state.user = null;
      state.salon = null;
      state.loggedInSalonStaff = null;

      const { email, password } = payload;

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/auth/staff/login`,
          {
            email,
            password,
          }
        );

        state.token = response.data.token;
        state.user = response.data.staff;
        dispatch('sockets/join', state.user._id, { root: true });

        // Storage
        await dispatch('saveStorage', {
          salonId: '',
          staffId: state.user._id,
          token: state.token,
        });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async clientLogin({ commit }, payload) {
      const { email, password } = payload;

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/auth/client/login`,
          {
            email,
            password,
          }
        );

        commit('booking/setActiveClient', response.data.client, { root: true });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async checkForExistingStaffAccount(_, payload) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/staff/email/${payload}`
        );

        if (response.data.staff) {
          return true;
        }
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    // Auto Auth
    saveStorage(_, payload) {
      localStorage.setItem('salonId', payload.salonId);
      localStorage.setItem('staffId', payload.staffId);
      localStorage.setItem('token', payload.token);
    },
    getStorage() {
      return {
        salonId: localStorage.getItem('salonId'),
        staffId: localStorage.getItem('staffId'),
        token: localStorage.getItem('token'),
        clientId: localStorage.getItem('clientId'),
      };
    },
    clearStorage() {
      localStorage.removeItem('salonId');
      localStorage.removeItem('staffId');
      localStorage.removeItem('token');
    },
    async autoAuth({ dispatch, state }) {
      const { salonId, staffId, clientId, token } = await dispatch(
        'getStorage'
      );

      if (clientId) dispatch('clientAutoAuth', clientId);

      if (!staffId || !token) return;

      state.token = token;

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/staff/${staffId}`
        );

        state.user = response.data.staff;
        dispatch('sockets/join', state.user._id, { root: true });

        if (!salonId) {
          // Stripe redirects
          if (
            router.currentRoute.value.query.session_id ||
            router.currentRoute.value.query.stripe_error ||
            router.currentRoute.value.query.stripe_message
          ) {
            return;
          }

          if (router.currentRoute._value.fullPath.includes('/dashboard')) {
            router.push({
              name: 'StaffProfile',
            });
          }
          return;
        }
      } catch (error) {
        throw Error(error.response.data.error);
      }

      await dispatch('selectSalon', salonId);
    },
    async clientAutoAuth({ rootState }, payload) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/clients/${payload}`
        );

        rootState.booking.activeClient = response.data.client;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
  },
  getters: {
    userId(state) {
      return state.user._id;
    },
    activeUserId(state) {
      return state.loggedInSalonStaff._id;
    },
    activeUserStaffId(state) {
      return state.loggedInSalonStaff.staffId;
    },
    userFullName(state) {
      if (!state.user) return '';

      return `${state.user.firstName} ${state.user.lastName}`;
    },
    loggedInStaffIsAdmin(state) {
      if (!state.salon || !state.user) return false;

      return state.salon.adminId === state.user._id;
    },
    loggedInStaffRoleIsAdmin(state) {
      if (!state.salon || !state.user) return false;

      return state.loggedInSalonStaff.role === 'admin';
    },
  },
};
