import axios from 'axios';

export default {
  namespaced: true,
  state: {
    salon: null,
  },
  mutations: {
    updateSalon(state, payload) {
      state.salon = payload;
    },
  },
  actions: {
    async updateSalon({ state, commit, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${state.salon._id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.auth.salon = {
          ...rootState.auth.salon,
          ...response.data.salon,
        };
        commit('updateSalon', { ...state.salon, ...response.data.salon });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    ////////////////////
    //// PROCESSORS ////
    ////////////////////

    async updateStripeInfo({ state, commit, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${state.salon._id}/billing/stripe`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.auth.salon = {
          ...rootState.auth.salon,
          ...response.data.salon,
        };
        commit('updateSalon', { ...state.salon, ...response.data.salon });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async updateCardconnectInfo({ state, commit, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${state.salon._id}/billing/cardConnect`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.auth.salon = {
          ...rootState.auth.salon,
          ...response.data.salon,
        };
        commit('updateSalon', { ...state.salon, ...response.data.salon });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async updateChargeAnywhereInfo({ state, commit, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${state.salon._id}/billing/chargeAnywhere`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.auth.salon = {
          ...rootState.auth.salon,
          ...response.data.salon,
        };
        commit('updateSalon', { ...state.salon, ...response.data.salon });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async updateDejavooInfo({ state, commit, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${state.salon._id}/billing/dejavoo`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.auth.salon = {
          ...rootState.auth.salon,
          ...response.data.salon,
        };
        commit('updateSalon', { ...state.salon, ...response.data.salon });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    //////////////////
    //// SETTINGS ////
    //////////////////

    async updateAdminSettings({ state, commit, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${state.salon._id}/settings/admin`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.auth.salon = {
          ...rootState.auth.salon,
          adminSettings: response.data.adminSettings,
        };
        commit('updateSalon', {
          ...state.salon,
          adminSettings: response.data.adminSettings,
        });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async updateBookingSettings({ state, commit, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${state.salon._id}/settings/booking`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.auth.salon = {
          ...rootState.auth.salon,
          bookingSettings: response.data.bookingSettings,
        };
        commit('updateSalon', {
          ...state.salon,
          bookingSettings: response.data.bookingSettings,
        });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async updateKioskSettings({ state, commit, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${state.salon._id}/settings/kiosk`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.auth.salon = {
          ...rootState.auth.salon,
          kioskSettings: response.data.kioskSettings,
        };
        commit('updateSalon', {
          ...state.salon,
          kioskSettings: response.data.kioskSettings,
        });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async updatePermissions({ state, commit, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${state.salon._id}/settings/permissions`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.auth.salon = {
          ...rootState.auth.salon,
          permissions: response.data.permissions,
        };
        commit('updateSalon', {
          ...state.salon,
          permissions: response.data.permissions,
        });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async updateSchedule({ state, commit, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${state.salon._id}/settings/schedule`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.auth.salon = {
          ...rootState.auth.salon,
          schedule: response.data.schedule,
        };
        commit('updateSalon', {
          ...state.salon,
          schedule: response.data.schedule,
        });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async updatePayments({ state, commit, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${state.salon._id}/settings/payments`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.auth.salon = {
          ...rootState.auth.salon,
          payments: response.data.payments,
        };
        commit('updateSalon', {
          ...state.salon,
          payments: response.data.payments,
        });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async updateDetails({ state, commit, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${state.salon._id}/settings/details`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.auth.salon = {
          ...rootState.auth.salon,
          details: response.data.details,
        };
        commit('updateSalon', {
          ...state.salon,
          details: response.data.details,
        });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async updateProfileImage({ state, commit, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${state.salon._id}/settings/details/profileImage`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.auth.salon = {
          ...rootState.auth.salon,
          profileImage: response.data.profileImage,
        };
        commit('updateSalon', {
          ...state.salon,
          profileImage: response.data.profileImage,
        });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    ///////////////////
    //// CAMPAIGNS ////
    ///////////////////

    async updateWinBackCampaign({ state, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${state.salon._id}/marketing/campaigns/winBack`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        return response.data.marketing;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async updateRegularsCampaign({ state, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${state.salon._id}/marketing/campaigns/regulars`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        return response.data.marketing;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async updateBirthdayCampaign({ state, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${state.salon._id}/marketing/campaigns/birthday`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        return response.data.marketing;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async updateNewClientCampaign({ state, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${state.salon._id}/marketing/campaigns/newClient`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        return response.data.marketing;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    ///////////////
    //// OTHER ////
    ///////////////

    async getSummary({ state, rootState }, payload) {
      let query = '?';

      if (payload) {
        if (payload.days) {
          query += `days=${payload.days}`;
        }
        if (payload.double) {
          if (query.length > 1) query += '&';

          query += `double=true`;
        }
      }

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${state.salon._id}/summaryMin${query}`,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        return response.data.summary;
      } catch (error) {
        console.log(error);
        throw Error(error.response.data.error);
      }
    },
  },
  getters: {},
};
