<template>
  <section class="service" :class="{ error: service.error }">
    <BaseInput
      label="Start Time"
      inputType="time"
      placeholder="10:00am"
      :value="newService.start"
      @input="
        newService.start = $event;
        $emit('clearError');
      "
    />
    <ServiceSelect
      :service="service"
      @select="
        selectService($event);
        $emit('clearError');
      "
    />
    <BaseInput
      label="Duration (Mins)"
      inputType="number"
      placeholder="Minutes"
      :value="newService.duration"
      :validators="validators.duration"
      :disabled="!newService.service"
      @input="
        newService.duration = +$event;
        $emit('clearError');
      "
    />
    <BaseInput
      v-if="newService.service?.extraProcessingTime"
      label="Processing Time (Mins)"
      inputType="number"
      placeholder="Minutes"
      :value="newService.service.extraProcessingTime"
      :validators="validators.duration"
      :disabled="!newService.service"
      @input="
        newService.service.extraProcessingTime = +$event;
        $emit('clearError');
      "
    />
    <BaseInput
      v-if="newService.service?.extraBlockedTime"
      label="Blocked Time (Mins)"
      inputType="number"
      placeholder="Minutes"
      :value="newService.service.extraBlockedTime"
      :validators="validators.duration"
      :disabled="!newService.service"
      @input="
        newService.service.extraBlockedTime = +$event;
        $emit('clearError');
      "
    />
    <div class="staff">
      <BaseSelect
        label="Staff"
        placeholder="Select Staff"
        :options="staffForSelect"
        :value="newService.staff ? newService.staff.staffId : null"
        @input="
          selectStaff($event);
          $emit('clearError');
        "
      />
      <i
        v-if="newService.requested"
        class="fas fa-heart"
        @click="newService.requested = false"
      ></i>
      <i v-else class="far fa-heart" @click="newService.requested = true"></i>
    </div>
    <i class="fas fa-times" v-if="canRemove" @click="$emit('remove')"></i>
  </section>
</template>

<script>
import ServiceSelect from '@/components/items/ServiceSelect.vue';

export default {
  emits: ['remove', 'setValue', 'clearError'],
  components: {
    ServiceSelect,
  },
  props: {
    canRemove: {
      type: Boolean,
      default: false,
    },
    service: {
      type: Object,
    },
  },
  created() {
    this.newService = this.service;
    this.staff = this.$store.state.staff.staff;
  },
  data() {
    return {
      newService: {
        start: null,
        service: {},
        duration: null,
        staff: null,
        requested: false,
      },
      staff: [],
    };
  },
  computed: {
    staffForSelect() {
      return this.staff.map((staff) => {
        return {
          option: `${staff.firstName} ${staff.lastName}`,
          value: staff.staffId,
        };
      });
    },
    validators() {
      return {
        duration: {
          required: true,
          min: 1,
        },
      };
    },
  },
  watch: {
    newService: {
      handler(val) {
        this.$emit('setValue', val);
      },
      deep: true,
    },
  },
  methods: {
    selectService(service) {
      if (!service) {
        this.newService.service = null;
        this.newService.duration = null;
        return;
      }

      if (!this.newService.duration)
        this.newService.duration = service.duration;

      this.newService.service = service;

      if (this.newService.staff) {
        this.setServicePriceBasedOnLevel();
      }
    },
    selectStaff(_id) {
      const staff = this.staff.find((staff) => staff.staffId === _id);

      this.newService.staff = {
        _id: staff._id,
        commission: staff.commission,
        accountCode: staff.accountCode,
        email: staff.email,
        firstName: staff.firstName,
        lastName: staff.lastName,
        phoneNumber: staff.phoneNumber,
        profileImage: staff.profileImage,
        splitCheck: staff.splitCheck,
        splitOther: staff.splitOther,
        staffId: staff.staffId,
        title: staff.title,
        trackHourly: staff.trackHourly,
        customServiceLevels: staff.customServiceLevels,
      };

      this.setServicePriceBasedOnLevel();
    },
    setServicePriceBasedOnLevel() {
      if (
        !this.newService.service.hasPricingLevels ||
        !this.newService.service.levels.length
      )
        return;

      const staff = this.staff.find(
        (staff) => staff.staffId === this.newService.staff.staffId
      );

      if (staff.customServiceLevels) {
        let level = staff.customServiceLevels.find(
          (level) => level.serviceId === this.newService.service._id
        );

        if (!level) {
          level = {
            level: staff.defaultServiceLevel || 1,
          };
        }

        if (this.newService.service.levels[level.level - 1]) {
          this.newService.service = {
            ...this.newService.service,
            price: this.newService.service.levels[level.level - 1].price,
            duration: this.newService.service.levels[level.level - 1].duration,
          };
          this.newService.duration =
            this.newService.service.levels[level.level - 1].duration;
        } else {
          if (level.level < 1) {
            this.newService.service = {
              ...this.newService.service,
              price: this.newService.service.levels[0].price,
              duration: this.newService.service.levels[0].duration,
            };
            this.newService.duration =
              this.newService.service.levels[0].duration;
          } else {
            this.newService.service = {
              ...this.newService.service,
              price:
                this.newService.service.levels[
                  this.newService.service.levels.length - 1
                ].price,
              duration:
                this.newService.service.levels[
                  this.newService.service.levels.length - 1
                ].duration,
            };
            this.newService.duration =
              this.newService.service.levels[
                this.newService.service.levels.length - 1
              ].duration;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  border-color: var(--clr-danger) !important;
}

.service {
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: 32px;
  column-gap: 16px;
  background-color: white;
  padding: 32px;
  border: 1px solid var(--clr-light);
  border-radius: 10px;
  position: relative;
  transition: border-color 0.2s;

  .fa-times {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: var(--clr-danger);
  }

  .staff {
    display: flex;
    align-items: flex-end;
    gap: 16px;

    .parent {
      flex-grow: 1;
    }

    i {
      font-size: 24px;
      cursor: pointer;
    }

    .fas {
      color: var(--clr-danger);
    }
    .far {
      color: var(--clr-light-2);
    }
  }
}

// Mobile
@media (max-width: 600px) {
  .service {
    grid-template-columns: 1fr;
  }
}
</style>
