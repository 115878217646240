<template>
  <Spinner v-if="loading" />

  <section class="analytics">
    <div v-if="!filteredAnalytics.length" class="none">
      <h3>No analytics found</h3>
      <p>Perhaps they were just deleted? Idk</p>
    </div>

    <div v-if="filteredAnalytics.length" class="analytics_data">
      <Table
        :headers="analyticsHeaders"
        :data="analyticsData"
        :boldRows="[4]"
        @rowClick="selectRow($event)"
      />
    </div>

    <div class="analytics_filters">
      Filter By:
      <p @click="filter = 'all'" :class="{ selected: filter === 'all' }">
        All ({{ analyticsWithoutFilter.length }})
      </p>
      <span class="light desktop">|</span>
      <p @click="filter = 'notes'" :class="{ selected: filter === 'notes' }">
        Notes ({{ analyticWithNotes.length }})
      </p>
      <span class="light desktop">|</span>
      <p
        @click="filter = 'needsAcknowledged'"
        :class="{ selected: filter === 'needsAcknowledged' }"
      >
        Not Acknowledged ({{ needsAcknowledged.length }})
      </p>
    </div>

    <ul class="analytics_logs">
      <li v-for="log in filteredAnalytics" :key="log._id">
        <div class="row top">
          <div class="col">
            <div v-if="log.clientId || log.appointmentId" class="icons">
              <i v-if="log.clientId" class="fas fa-user text-secondary"></i>
              <i v-if="log.appointmentId" class="fas fa-check text-green"></i>
            </div>

            <div class="head">
              <p>
                {{ formatDate(log.created_at) }}
                <span class="light"
                  >(Last Updated: {{ formatDate(log.updated_at) }})</span
                >
              </p>
            </div>

            <p @click="log.expand = !log.expand" class="link">
              <i
                class="fas"
                :class="log.expand ? 'fa-chevron-up' : 'fa-chevron-down'"
              ></i>
              <span v-if="!log.expand">Show More Info</span>
              <span v-else>Show Less Info</span>
            </p>
          </div>
          <div
            class="col right bold"
            :class="{
              'text-green': isLogConfirmed(log),
              'text-yellow': !isLogConfirmed(log),
            }"
          >
            <p>
              <span>Steps: </span>
              <span>
                {{ log.steps.length }}
              </span>
            </p>
          </div>
        </div>

        <div v-if="log.expand" class="row moreInfo">
          <p><span class="bold">IP Address:</span> {{ log.IP_address }}</p>
          <p><span class="bold">User Agent:</span> {{ log.user_agent }}</p>
          <p><span class="bold">Salon ID:</span> {{ log.salonId }}</p>
          <p v-if="log.clientId">
            <span class="bold">Client ID:</span>
            {{ log.clientId }}
          </p>
          <p v-if="log.appointmentId">
            <span class="bold">Appointment ID:</span>
            {{ log.appointmentId }}
          </p>
          <p>
            <span class="bold">Steps: </span>
            <span
              v-for="(step, index) in log.steps"
              :key="index"
              :class="{
                'text-green': step.includes('Confirmed'),
                bold: step.includes('Confirmed'),
              }"
            >
              {{ step
              }}<span v-if="index !== log.steps.length - 1">, </span></span
            >
          </p>
          <p v-if="log.notes.length">
            <span class="bold">Notes: </span>
            <span v-for="(note, index) in log.notes" :key="index"
              >{{ note }},</span
            >
          </p>

          <div class="actions">
            <BaseButton @click="selectLog(log)">Expand Object</BaseButton>
          </div>
        </div>
      </li>
    </ul>
  </section>

  <Modal
    title="Selected Booking Tracking Log"
    :show="selectedLog"
    @close="selectedLog = null"
  >
    <div class="selectedLog">
      <div class="groups">
        <div class="group">
          <h3>Salon</h3>

          <div class="group_body">
            <p>
              {{ selectedLog.salonId.details.shopName }}
              {{ selectedLog.salonId.details.altName }}
              <span class="light">({{ selectedLog.salonId._id }})</span>
            </p>
          </div>
        </div>

        <div v-if="selectedLog.clientId" class="group">
          <h3>Client</h3>

          <div class="group_body">
            <p>
              {{ selectedLog.clientId.firstName }}
              {{ selectedLog.clientId.lastName }}
            </p>
            <p>
              <span class="link">{{ selectedLog.clientId.phoneNumber }}</span>
            </p>
            <p>
              <span class="link">{{ selectedLog.clientId.email }}</span>
            </p>
          </div>
        </div>

        <div v-if="selectedLog.appointmentId" class="group">
          <h3>Appointment</h3>

          <div class="group_body">
            <p>
              <span class="bold">Date:</span>
              {{ formatDate(selectedLog.appointmentId.date, true) }}
            </p>
          </div>
        </div>

        <div v-if="selectedLog.notes.length" class="group">
          <h3>Notes</h3>

          <div class="group_body">
            <p v-for="(note, index) in selectedLog.notes" :key="index">
              <span class="bold">Note:</span>
              {{ note }}
            </p>
          </div>
        </div>
      </div>

      <div class="selectedLog_actions">
        <BaseButton @click="deleteSelectedLog" mode="danger"
          >Delete Log</BaseButton
        >
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  emits: ['updateSearchValue'],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    searchValue: {
      type: String,
      default: '',
    },
  },
  created() {
    this.init();
  },
  computed: {
    totalSteps() {
      return 5;
    },
    analyticWithNotes() {
      return this.analytics.filter((analytic) => analytic.notes.length);
    },
    needsAcknowledged() {
      return this.analytics.filter((analytic) => !analytic.adminAcknowledged);
    },
    analyticsWithoutFilter() {
      if (!this.analytics.length) return [];

      return this.analytics.filter((analytic) => {
        if (!this.searchValue) return true;

        return (
          analytic.salonId.includes(this.searchValue) ||
          analytic.clientId?.includes(this.searchValue) ||
          analytic.appointmentId?.includes(this.searchValue) ||
          analytic.steps[analytic.steps.length - 1]
            .toLowerCase()
            .includes(this.searchValue.toLowerCase())
        );
      });
    },
    filteredAnalytics() {
      if (!this.analytics.length) return [];

      return this.analytics.filter((analytic) => {
        if (this.filter === 'notes') {
          if (!analytic.notes.length) return false;
        } else if (this.filter === 'needsAcknowledged') {
          if (analytic.adminAcknowledged) return false;
        }

        if (!this.searchValue) return true;

        return (
          analytic.salonId.includes(this.searchValue) ||
          analytic.clientId?.includes(this.searchValue) ||
          analytic.appointmentId?.includes(this.searchValue) ||
          analytic.steps.some((step) =>
            step.toLowerCase().includes(this.searchValue.toLowerCase())
          )
        );
      });
    },
    analyticsHeaders() {
      return ['Step', 'Count', 'Percentage'];
    },
    analyticsData() {
      if (!this.filteredAnalytics.length) return [];

      const data = [
        {
          step: 'Select Services',
          count: 0,
          percentage: 0,
        },
        {
          step: 'Select Member',
          count: 0,
          percentage: 0,
        },
        {
          step: 'Select Date',
          count: 0,
          percentage: 0,
        },
        {
          step: 'Confirmation Required',
          count: 0,
          percentage: 0,
        },
        {
          step: 'Confirmed',
          count: 0,
          percentage: 0,
        },
      ];

      this.filteredAnalytics.forEach((analytic) => {
        data.forEach((step) => {
          if (
            analytic.steps.some((analyticStep) =>
              analyticStep.includes(step.step)
            )
          ) {
            step.count++;
          }
        });

        // Get percentage
        data.forEach((step) => {
          step.percentage =
            ((step.count / this.filteredAnalytics.length) * 100).toFixed(2) +
            '%';
        });
      });

      data.forEach((d) => {
        d.percentage =
          ((d.count / this.filteredAnalytics.length) * 100).toFixed(2) + '%';
      });

      return data;
    },
  },
  data() {
    return {
      filter: 'all',
      analytics: [],
      selectedLog: null,
    };
  },
  methods: {
    async init() {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/analytics?type=booking`
        );

        this.analytics = response.data.data;
      } catch (err) {
        console.log(err.message);
      }
    },

    formatDate(date, short) {
      return short
        ? this.$moment(date).format('LL')
        : this.$moment(date).format('LLL');
    },

    isLogConfirmed(log) {
      return log.steps[log.steps.length - 1].includes('Confirmed');
    },

    async selectLog(log) {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/analytics/${log._id}`
        );

        this.selectedLog = response.data;
      } catch (err) {
        console.log(err.message);
      }
    },

    selectRow(index) {
      this.$emit('updateSearchValue', this.analyticsData[index].step);
    },

    async deleteSelectedLog() {
      try {
        await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/analytics/${this.selectedLog._id}`
        );

        this.$toast.success('Log deleted!');

        this.analytics = this.analytics.filter(
          (analytic) => analytic._id !== this.selectedLog._id
        );

        this.selectedLog = null;
      } catch (e) {
        console.log(e.message);
      }
    },

    markAllAsAcknowledged() {
      this.analytics.forEach((analytic) => {
        analytic.adminAcknowledged = true;
      });
    },

    clearAnalytics() {
      this.analytics = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics {
  .light {
    color: var(--clr-gray);
  }

  .bold {
    font-weight: 700;
  }

  .small {
    font-size: 14px;
  }
  .mt-s {
    margin-top: 4px;
  }

  .text-green {
    color: var(--clr-success);
  }
  .text-secondary {
    color: var(--clr-secondary);
  }
  .text-primary {
    color: var(--clr-primary);
  }
  .text-yellow {
    color: var(--clr-warning-dark);
  }

  .link {
    color: var(--clr-link);
    cursor: pointer;
  }

  &_data {
    margin-top: 32px;
    border: 1px solid var(--clr-light);
  }

  &_filters {
    padding: 8px 16px;
    border-radius: 5px;
    border: 1px solid var(--clr-light);
    margin-top: 32px;
    display: flex;
    gap: 8px;

    p {
      cursor: pointer;
      color: var(--clr-gray);
    }

    .selected {
      color: var(--clr-link);
      font-weight: 700;
    }
  }

  .none {
    padding: 16px;
    border-radius: 5px;
    border: 1px solid var(--clr-light);
    text-align: center;
    margin-top: 32px;

    p {
      margin-top: 8px;
      color: var(--clr-gray);
    }
  }

  &_logs {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    li {
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .icons {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 16px;
        font-size: 12px;
        color: var(--clr-gray);
      }

      .right {
        text-align: right;
      }

      .link {
        margin-top: 16px;

        i {
          margin-right: 16px;
        }
      }

      .moreInfo {
        margin-top: 16px;
        padding: 16px;
        border: 1px solid var(--clr-light);
        border-radius: 5px;

        p {
          margin-top: 8px;
        }

        .actions {
          margin-top: 32px;
        }
      }
    }
  }
}

.selectedLog {
  padding: 32px;

  .groups {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .group {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &_body {
        padding: 16px;
        border: 1px solid var(--clr-light);
        border-radius: 5px;

        p {
          &:not(:first-child) {
            margin-top: 8px;
          }

          .light {
            color: var(--clr-gray);
          }

          .bold {
            font-weight: 700;
          }

          .link {
            color: var(--clr-link);
            cursor: pointer;
          }
        }
      }
    }
  }

  &_actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}

// Mobile
@media (max-width: 600px) {
  .desktop {
    display: none;
  }
  .analytics {
    &_filters {
      flex-direction: column;
      gap: 16px;
    }

    &_logs {
      li {
        .top {
          flex-direction: column-reverse;
          align-items: flex-start;
          gap: 16px;
        }
      }
    }
  }
}
</style>
