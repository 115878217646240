<template>
  <div class="invoicePage">
    <div class="invoicePage_body">
      <div class="invoicePage_body_logo">
        <TheLogo />
      </div>

      <div
        v-if="processor && ticket.status === 'completed'"
        class="alert alert-success"
      >
        <p>This invoice is paid! Thank you for visiting.</p>
      </div>
      <div
        v-else-if="
          processor &&
          ticket.status !== 'completed' &&
          ticket.status !== 'refunded'
        "
        class="alert alert-warning"
      >
        <p>This invoice is unpaid! Scroll down to pay.</p>
      </div>

      <div class="invoicePage_body_invoice">
        <TicketInvoice :ticket="ticket" />
      </div>

      <div
        v-if="
          processor &&
          ticket.status !== 'completed' &&
          ticket.status !== 'refunded'
        "
        class="invoicePage_body_paymentForm"
      >
        <TicketClientPaymentForm
          :ticket="ticket"
          :processor="processor"
          :billing="billing"
          @paid="submitPayment($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TicketInvoice from '@/components/tickets/TicketInvoice.vue';
import TicketClientPaymentForm from '@/components/tickets/TicketClientPaymentForm.vue';

export default {
  components: {
    TicketInvoice,
    TicketClientPaymentForm,
  },
  created() {
    this.init();
  },
  data() {
    return {
      ticket: null,
      processor: null,
      billing: null,
    };
  },
  methods: {
    async init() {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/tickets/${this.$route.params.id}?shopDetails=true`
        );

        this.ticket = response.data.ticket;

        if (
          this.ticket.status !== 'completed' &&
          this.ticket.status !== 'refunded'
        ) {
          this.getSalonPaymentInfo();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getSalonPaymentInfo() {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.ticket.salonId._id}/paymentInfo`
        );

        this.processor = response.data.salon.payments.processor;
        this.billing = response.data.salon.billing;
      } catch (error) {
        console.log(error);
      }
    },
    submitPayment(payment) {
      this.ticket.payments.push(payment);
      this.ticket.status = 'completed';

      this.$store.state.sockets.socket.emit('ticketUpdated', this.ticket);
    },
  },
};
</script>

<style lang="scss" scoped>
.invoicePage {
  background-color: var(--clr-light);
  min-height: 100vh;
  display: grid;
  place-items: center;

  &_body {
    &_logo {
      text-align: center;
      margin-bottom: 32px;
    }

    &_invoice {
      padding: 32px;
      border-radius: 5px;
      box-shadow: var(--shadow);
      background-color: var(--clr-white);
    }

    &_paymentForm {
      margin-top: 32px;
    }
  }

  .alert {
    padding: 12px;
    font-size: 14px;
    border-radius: 5px;
    margin-bottom: 16px;

    &-warning {
      background-color: var(--clr-warning-light);
      color: var(--clr-warning-dark);
      border-color: var(--clr-warning);
    }
    &-success {
      background-color: #c3f7c7;
      color: #04530b;
      border-color: #acf7b2;
      margin-bottom: 16px;
    }
  }
}
</style>
