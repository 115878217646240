<template>
  <section class="subs">
    <div class="subs_head">
      <h2>Subscriptions & Memberships</h2>
      <p>
        Offer subscriptions, build client retention, and establish recurring
        revenue with our subscription features. Subscriptions offer packages at
        a recurring rate.
      </p>
    </div>
    <div class="subs_actions">
      <div class="col">
        <BaseSelect
          :options="filterOptions"
          :value="filter"
          @input="filterSubscriptions($event)"
        />
        <form @submit.prevent="searchSubscriptions" class="form">
          <BaseInput
            placeholder="Client Name.."
            icon="fas fa-search"
            :value="searchValue"
            @input="searchValue = $event"
            @icon-click="searchSubscriptions"
          />
        </form>
      </div>
      <BaseButton @click="showCreate = true">Create Subscription</BaseButton>
    </div>
    <div class="subs_body">
      <Spinner v-if="loading" />
      <SubscriptionsList
        :subscriptions="filteredSubscriptions"
        @select="selectedSub = $event"
      />
    </div>
  </section>

  <Modal
    title="Create Subscription"
    :show="showCreate || selectedSub"
    @close="
      showCreate = false;
      selectedSub = null;
    "
  >
    <div class="createForm">
      <CreateSubscription
        :presubscription="selectedSub"
        @created="
          subscriptions.push($event);
          showCreate = false;
          selectedSub = false;
        "
        @updated="updateSub($event)"
      />
    </div>
  </Modal>
</template>

<script>
import CreateSubscription from '@/views/subscriptions/CreateSubscription.vue';
import SubscriptionsList from '@/views/subscriptions/SubscriptionsList.vue';

export default {
  components: {
    CreateSubscription,
    SubscriptionsList,
  },
  created() {
    this.getSubscriptions();
  },
  computed: {
    filterOptions() {
      return [
        {
          option: 'All',
          value: 'all',
        },
        {
          option: 'Active',
          value: 'active',
        },
        {
          option: 'Past Due',
          value: 'past due',
        },
        {
          option: 'Unpaid',
          value: 'unpaid',
        },
        {
          option: 'Canceled',
          value: 'canceled',
        },
        {
          option: 'Scheduled',
          value: 'scheduled',
        },
      ];
    },
  },
  data() {
    return {
      loading: false,
      showCreate: false,
      selectedSub: null,

      subscriptions: [],
      filteredSubscriptions: [],

      filter: 'all',
      searchValue: '',
    };
  },
  methods: {
    async getSubscriptions() {
      this.laoding = true;

      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/memberships?salonId=${this.$store.state.auth.salon._id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.subscriptions = response.data.subscriptions;
        this.filterSubscriptions(this.filter);
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
    },

    searchSubscriptions() {
      if (!this.searchValue) {
        this.filterSubscriptions(this.filter);
        return;
      }

      this.filteredSubscriptions = this.filteredSubscriptions.filter((sub) => {
        return `${sub.client.firstName} ${sub.client.lastName}`
          .toLowerCase()
          .includes(this.searchValue.toLowerCase());
      });
    },

    filterSubscriptions(filter) {
      if (filter === 'all') {
        this.filteredSubscriptions = this.subscriptions;
        return;
      }

      this.filteredSubscriptions = this.subscriptions.filter(
        (sub) => sub.status === filter
      );
    },

    updateSub(subscription) {
      const subIndex = this.subscriptions.findIndex(
        (sub) => sub._id === subscription._id
      );

      if (subIndex === -1) return;

      this.subscriptions[subIndex] = subscription;

      this.showCreate = false;
      this.selectedSub = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.subs {
  max-width: 800px;

  &_head {
    h2 {
      font-size: 28px;
    }
    p {
      margin-top: 8px;
      color: var(--clr-gray);
    }
  }
  &_actions {
    margin-top: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-extra-light);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .col {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
  &_body {
    margin-top: 32px;
  }
}

.createForm {
  padding: 32px;
}
</style>
