<template>
  <form @submit.prevent="submit" class="form">
    <div class="date">
      <h2 @click="showDatePicker = !showDatePicker">
        {{ selectedDateString }} <i class="fas fa-angle-down"></i>
      </h2>
      <div class="date_picker">
        <DatePicker
          v-if="showDatePicker"
          :selectedDate="selectedDate"
          @selectDate="selectDate"
          @hide="showDatePicker = false"
        />
      </div>
    </div>
    <BaseSelect
      placeholder="Staff"
      label="Staff"
      :options="staffOptions"
      @input="selectStaff($event)"
    />
    <BaseInput
      inputType="time"
      placeholder="Start Time"
      label="Start Time"
      @input="blockedTime.startTime = $event"
    />
    <BaseInput
      inputType="time"
      placeholder="End Time"
      label="End Time"
      @input="blockedTime.endTime = $event"
    />
    <BaseTextarea placeholder="Description" label="Description" />
    <div class="actions">
      <BaseButton :disabled="!formValid">Submit</BaseButton>
    </div>
  </form>
</template>

<script>
import DatePicker from '@/components/components/DatePicker.vue';

export default {
  emits: ['exit', 'submit'],
  components: {
    DatePicker,
  },
  props: {
    date: {
      type: Date,
      default: new Date(Date.now()),
    },
  },
  data() {
    return {
      selectedDate: null,
      showDatePicker: false,

      blockedTime: {
        staff: null,
        startTime: null,
        endTime: null,
      },
    };
  },
  created() {
    this.selectedDate = this.date;
  },
  computed: {
    formValid() {
      if (
        !this.selectedDate ||
        !this.blockedTime.staff ||
        !this.blockedTime.startTime ||
        !this.blockedTime.endTime
      )
        return false;

      const [startHour, startMinute] = this.blockedTime.startTime.split(':');
      const [endHour, endMinute] = this.blockedTime.endTime.split(':');

      const startTime = this.$moment(this.selectedDate)
        .hour(startHour)
        .minute(startMinute);
      const endTime = this.$moment(this.selectedDate)
        .hour(endHour)
        .minute(endMinute);

      if (this.$moment(startTime).isAfter(endTime)) return false;

      return true;
    },
    selectedDateString() {
      return this.$moment(this.selectedDate).format('dddd[,] ll');
    },
    staffOptions() {
      return this.$store.state.auth.salon.staff.map((staff) => {
        return {
          option: `${staff.firstName} ${staff.lastName}`,
          value: staff._id,
        };
      });
    },
  },
  methods: {
    selectDate(date) {
      this.showDatePicker = false;
      this.selectedDate = this.$moment(date).toDate();
    },
    selectStaff(id) {
      this.blockedTime.staff = this.$store.state.auth.salon.staff.find(
        (staff) => staff._id === id
      );
    },
    async submit() {
      try {
        const [startHour, startMinute] = this.blockedTime.startTime.split(':');
        const [endHour, endMinute] = this.blockedTime.endTime.split(':');

        const startTime = this.$moment(this.selectedDate)
          .hour(startHour)
          .minute(startMinute);
        const endTime = this.$moment(this.selectedDate)
          .hour(endHour)
          .minute(endMinute);

        const duration = this.$moment
          .duration(endTime.diff(startTime))
          .asMinutes();

        const appointment = {
          date: this.selectedDate,
          services: [
            {
              service: {
                title: 'Blocked time',
                color: '#afafaf',
                duration,
              },
              start: startTime.format('kk:mm'),
              duration,
              staff: this.blockedTime.staff,
            },
          ],
          isBlockedTime: true,
          status: 'new',
        };

        await this.$store.dispatch(
          'appointments/createAppointment',
          appointment
        );

        this.$emit('submit');
        this.$emit('exit');
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .date {
    position: relative;

    h2 {
      cursor: pointer;
      font-size: 20px;
    }

    i {
      margin-left: 16px;
    }

    &_picker {
      position: absolute;
      top: calc(100% + 10px);
      left: -28px;
    }
  }

  .actions {
    margin-top: 16px;
    text-align: right;
  }
}
</style>
