<template>
  <section class="auth">
    <h2 class="page-title">Register to continue</h2>
    <div class="auth_form">
      <ClientLoginForm @registered="goToConfirm" />
    </div>
  </section>
</template>

<script>
import ClientLoginForm from '@/components/clients/ClientLoginForm.vue';

export default {
  components: {
    ClientLoginForm,
  },
  computed: {
    salonId() {
      return this.$store.state.booking.salon._id;
    },
  },
  methods: {
    goToConfirm() {
      this.$router.push({
        name: 'BookConfirm',
        params: { id: this.salonId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.auth {
  width: 100%;
  max-width: 600px;
  margin: auto;

  .page-title {
    text-align: center;
    margin-left: 0;
  }

  &_form {
    margin-top: 32px;
  }
}
</style>
