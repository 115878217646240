<template>
  <div class="row actions">
    <BaseButton @click="showAddTicketModal = true" mode="primary small"
      >Add Ticket</BaseButton
    >
    <BaseButton @click="showAllTicketsModal = true" mode="primary-outline small"
      >All Tickets</BaseButton
    >
  </div>

  <div class="row">
    <div v-if="tickets.length" class="waiting">
      <DetailedTicketCard
        v-for="ticket in tickets"
        :key="ticket._id"
        :ticket="ticket"
        assignOnly
        @selectTicket="$emit('selectTicket', $event)"
      />
    </div>

    <div v-else class="none">
      <p>There are no waiting tickets in queue!</p>
    </div>
  </div>

  <Modal
    title="Add Waiting Ticket"
    :show="showAddTicketModal"
    @close="showAddTicketModal = false"
  >
    <div class="form">
      <form @submit.prevent="searchClients">
        <BaseInput
          label="Client name, number, or email"
          @input="newWaitingClientSearch = $event"
          :value="newWaitingClientSearch"
        />

        <div class="form_actions">
          <BaseButton>Search</BaseButton>
        </div>
      </form>

      <ul v-if="clients.length">
        <li v-for="client in clients" :key="client">
          <div class="col">
            <p>{{ `${client.firstName} ${client.lastName}` }}</p>
            <p class="small light">{{ `${client.phoneNumber}` }}</p>
          </div>

          <div class="col">
            <BaseButton @click="newWaitingTicket(client)">
              Select Client
            </BaseButton>
          </div>
        </li>
      </ul>

      <div class="none">
        <p>No clients found, please try another search.</p>

        <div class="buttons">
          <BaseButton @click="newWaitingTicket(null)" mode="primary-outline"
            >Continue without client</BaseButton
          >
          <BaseButton @click="showNewClientForm = true" mode="primary-outline"
            >Create New Client</BaseButton
          >
        </div>
      </div>

      <div v-if="showNewClientForm" class="create">
        <ClientForm
          basic
          @created="newWaitingTicket($event)"
          :client="{ phoneNumber: newWaitingClientSearch }"
        />
      </div>
    </div>
  </Modal>

  <Modal
    title="All Tickets"
    maxWidth="350px"
    :show="showAllTicketsModal"
    @close="showAllTicketsModal = false"
  >
    <CalendarTicketsSection />
  </Modal>
</template>

<script>
import DetailedTicketCard from '@/components/dashboard/DetailedTicketCard.vue';
import ClientForm from '@/components/clients/ClientForm.vue';
import CalendarTicketsSection from '@/views/calendar/CalendarTicketsSection.vue';

export default {
  components: {
    DetailedTicketCard,
    ClientForm,
    CalendarTicketsSection,
  },
  emits: ['selectTicket', 'newWaitingTicket'],
  props: {
    tickets: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showAddTicketModal: false,
      newWaitingClientSearch: '',
      clients: [],
      showNewClientForm: false,
      showAllTicketsModal: false,
    };
  },
  methods: {
    async searchClients() {
      this.clients = await this.$store.dispatch('clients/searchClients', {
        name: this.newWaitingClientSearch,
        phone: this.newWaitingClientSearch,
        email: this.newWaitingClientSearch,
      });
    },

    newWaitingTicket(client) {
      this.$emit('newWaitingTicket', client);
      this.newWaitingClientName = '';
      this.showAddTicketModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.waiting {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.none {
  text-align: center;
  margin-top: 32px;

  .buttons {
    display: flex;
    justify-content: center;
    gap: 16px;
  }
}

.actions {
  padding: 32px;
  padding-bottom: 0;
  display: flex;
  gap: 8px;

  button {
    flex-grow: 1;
  }
}

.form {
  padding: 32px;

  form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .form_actions {
      text-align: right;
    }
  }

  ul {
    margin-top: 32px;

    li {
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .small {
        font-size: 14px;
      }
      .light {
        margin-top: 8px;
        color: var(--clr-gray);
      }

      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }

  .primary-outline {
    margin-top: 16px;
  }
}

.create {
  margin-top: 32px;
}
</style>
