<template>
  <section class="integrations">
    <div class="integrations_sidebar">
      <SubSidebar :routes="routes" title="Integrations" />
    </div>
    <div class="integrations_router">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import SubSidebar from '@/components/components/SubSidebar.vue';

export default {
  components: {
    SubSidebar,
  },
  computed: {
    routes() {
      return [
        {
          name: 'All Integrations',
          linkName: 'IntegrationsList',
        },
        {
          name: 'HighLevel',
          linkName: 'IntegrationsHighLevel',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.integrations {
  display: flex;
  height: 100%;

  &_router {
    overflow-y: auto;
    flex-grow: 1;
    padding: 32px;
    display: flex;
    justify-content: center;
  }
}
</style>
