import axios from 'axios';

export default {
  namespaced: true,
  state: {
    products: [],
    services: [],
    discount: [],
    catDragging: false,
    categoryChangeCount: 0,
  },
  mutations: {
    setProducts(state, payload) {
      state.products = payload;
      state.categoryChangeCount++;
    },
    setServices(state, payload) {
      state.services = payload;
      state.categoryChangeCount++;
    },

    //////////////////
    // Service CRUD //
    //////////////////
    addService(state, payload) {
      state.services[payload.categoryIndex].items.push(payload.item);
    },
    editService(state, payload) {
      let index = state.services[payload.categoryIndex].items.findIndex(
        (service) => service._id === payload.item._id
      );

      if (index === -1) {
        for (let i = 0; i < state.services.length; i++) {
          const iIndex = state.services[i].items.findIndex((item) => {
            return item._id.toString() === payload.item._id;
          });

          if (iIndex !== -1) {
            state.services[i].items.splice(iIndex, 1);
            break;
          }
        }

        state.services[payload.categoryIndex].items.push(payload.item);
      } else {
        state.services[payload.categoryIndex].items[index] = payload.item;
      }
    },
    deleteService(state, payload) {
      let index = state.services[payload.categoryIndex].items.findIndex(
        (service) => service._id === payload.item._id
      );
      state.services[payload.categoryIndex].items.splice(index, 1);
    },

    //////////////////
    // Product CRUD //
    //////////////////
    addProduct(state, payload) {
      state.products[payload.categoryIndex].items.push(payload.item);
    },
    editProduct(state, payload) {
      let index = state.products[payload.categoryIndex].items.findIndex(
        (product) => product._id === payload.item._id
      );

      if (index === -1) {
        for (let i = 0; i < state.products.length; i++) {
          const iIndex = state.products[i].items.findIndex((item) => {
            return item._id.toString() === payload.item._id;
          });

          if (iIndex !== -1) {
            state.products[i].items.splice(iIndex, 1);
            break;
          }
        }

        state.products[payload.categoryIndex].items.push(payload.item);
      } else {
        state.products[payload.categoryIndex].items[index] = payload.item;
      }
    },
    deleteProduct(state, payload) {
      let index = state.products[payload.categoryIndex].items.findIndex(
        (product) => product._id === payload.item._id
      );
      state.products[payload.categoryIndex].items.splice(index, 1);
    },

    ///////////////////////////
    // Service category CRUD //
    ///////////////////////////

    addServiceCategory(state, payload) {
      state.services.push({
        ...payload,
        items: [],
      });
    },
    editServiceCategory(state, payload) {
      let categoryIndex = state.services.findIndex(
        (category) => category._id === payload._id
      );

      state.services[categoryIndex] = {
        ...state.services[categoryIndex],
        ...payload,
      };
    },
    deleteServiceCategory(state, payload) {
      let categoryIndex = state.services.findIndex(
        (category) => category._id === payload._id
      );

      state.services.splice(categoryIndex, 1);
    },

    ///////////////////////////
    // Product category CRUD //
    ///////////////////////////
    addProductCategory(state, payload) {
      state.products.push({
        ...payload,
        items: [],
      });
    },
    editProductCategory(state, payload) {
      let categoryIndex = state.products.findIndex(
        (category) => category._id === payload._id
      );

      state.products[categoryIndex] = {
        ...state.products[categoryIndex],
        ...payload,
      };
    },
    deleteProductCategory(state, payload) {
      let categoryIndex = state.products.findIndex(
        (category) => category._id === payload._id
      );

      state.products.splice(categoryIndex, 1);
    },

    ///////////////////
    // Discount CRUD //
    ///////////////////
    addDiscount(state, payload) {
      state.discounts.push(payload);
    },
    editDiscount(state, payload) {
      let index = state.discounts.findIndex(
        (discount) => discount._id === payload._id
      );
      state.discounts[index] = payload;
    },
    deleteDiscount(state, payload) {
      let index = state.discounts.findIndex(
        (discount) => discount._id === payload._id
      );
      state.discounts.splice(index, 1);
    },
  },
  actions: {
    ///////////////////////////
    // Service category CRUD //
    ///////////////////////////
    async createServiceCategory({ commit, rootState }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/serviceCategory`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.sockets.socket.emit('itemsUpdated', rootState.auth.salon._id);
        commit('addServiceCategory', response.data.category);
      } catch (error) {
        console.log(error);
        throw Error(error.response.data.error);
      }
    },
    async editServiceCategories({ commit, rootState }, payload) {
      const services = payload.map((category) => {
        return {
          _id: category._id,
          title: category.title,
          color: category.color,
          onKiosk: category.onKiosk,
          description: category.description,
        };
      });

      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/serviceCategory`,
          services,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.sockets.socket.emit('itemsUpdated', rootState.auth.salon._id);
        commit('setServices', response.data.services);
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async editServiceCategory({ commit, rootState }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/serviceCategory/${payload._id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.sockets.socket.emit('itemsUpdated', rootState.auth.salon._id);
        commit('editServiceCategory', response.data.category);
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async deleteServiceCategory({ commit, rootState }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/serviceCategory/${payload._id}`,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.sockets.socket.emit('itemsUpdated', rootState.auth.salon._id);
        commit('deleteServiceCategory', payload);
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    ///////////////////////////
    // Product category CRUD //
    ///////////////////////////
    async createProductCategory({ commit, rootState }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/productCategory`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.sockets.socket.emit('itemsUpdated', rootState.auth.salon._id);
        commit('addProductCategory', response.data.category);
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async editProductCategory({ commit, rootState, state }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/productCategory/${payload._id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        state.categoryChangeCount++;
        rootState.sockets.socket.emit('itemsUpdated', rootState.auth.salon._id);
        commit('editProductCategory', response.data.category);
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async deleteProductCategory({ commit, rootState }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/productCategory/${payload._id}`,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.sockets.socket.emit('itemsUpdated', rootState.auth.salon._id);
        commit('deleteProductCategory', payload);
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    //////////////////
    // Service CRUD //
    //////////////////
    async createService({ rootState, state, commit }, payload) {
      try {
        const services = JSON.parse(JSON.stringify(state.services));

        const categoryIndex = services.findIndex(
          (category) => category._id === payload.category
        );

        services[categoryIndex].items.push(payload);

        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/serviceCategory/${payload.category}/items`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.sockets.socket.emit('itemsUpdated', rootState.auth.salon._id);
        commit('addService', {
          categoryIndex,
          item: response.data.item,
        });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async editService({ rootState, state, commit }, payload) {
      try {
        const services = JSON.parse(JSON.stringify(state.services));

        const categoryIndex = services.findIndex(
          (category) => category._id === payload.category
        );

        const itemIndex = services[categoryIndex].items.findIndex(
          (item) => item._id === payload._id
        );

        services[categoryIndex].items[itemIndex] = payload;

        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/serviceCategory/${payload.category}/items/${payload._id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.sockets.socket.emit('itemsUpdated', rootState.auth.salon._id);
        commit('editService', {
          categoryIndex,
          item: response.data.item,
        });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async deleteService({ rootState, state, commit }, payload) {
      try {
        const services = JSON.parse(JSON.stringify(state.services));

        const categoryIndex = services.findIndex(
          (category) => category._id === payload.category
        );

        const itemIndex = services[categoryIndex].items.findIndex(
          (item) => item._id === payload._id
        );

        services[categoryIndex].items.splice(itemIndex);

        await axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/serviceCategory/${payload.category}/items/${payload._id}`,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.sockets.socket.emit('itemsUpdated', rootState.auth.salon._id);
        commit('deleteService', {
          categoryIndex,
          item: payload,
        });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    //////////////////
    // Product CRUD //
    //////////////////
    async createProduct({ rootState, state, commit }, payload) {
      try {
        const products = JSON.parse(JSON.stringify(state.products));

        const categoryIndex = products.findIndex(
          (category) => category._id === payload.category
        );

        products[categoryIndex].items.push(payload);

        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/productCategory/${payload.category}/items`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        state.categoryChangeCount++;
        rootState.sockets.socket.emit('itemsUpdated', rootState.auth.salon._id);
        commit('addProduct', {
          categoryIndex,
          item: response.data.item,
        });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async getProducts({ rootState, commit }) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/productCategory`,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        rootState.sockets.socket.emit('itemsUpdated', rootState.auth.salon._id);
        commit('setProducts', response.data.products);
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async editProduct({ rootState, state, commit }, payload) {
      try {
        const products = JSON.parse(JSON.stringify(state.products));

        const categoryIndex = products.findIndex(
          (category) => category._id === payload.category
        );

        const itemIndex = products[categoryIndex].items.findIndex(
          (item) => item._id === payload._id
        );

        products[categoryIndex].items[itemIndex] = payload;

        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/productCategory/${payload.category}/items/${payload._id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        state.categoryChangeCount++;
        rootState.sockets.socket.emit('itemsUpdated', rootState.auth.salon._id);
        commit('editProduct', {
          categoryIndex,
          item: response.data.item,
        });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async deleteProduct({ rootState, state, commit }, payload) {
      try {
        const products = JSON.parse(JSON.stringify(state.products));

        const categoryIndex = products.findIndex(
          (category) => category._id === payload.category
        );

        const itemIndex = products[categoryIndex].items.findIndex(
          (item) => item._id === payload._id
        );

        products[categoryIndex].items.splice(itemIndex);

        state.categoryChangeCount++;
        rootState.sockets.socket.emit('itemsUpdated', rootState.auth.salon._id);
        await axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/productCategory/${payload.category}/items/${payload._id}`,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        state.categoryChangeCount++;
        rootState.sockets.socket.emit('itemsUpdated', rootState.auth.salon._id);
        commit('deleteProduct', {
          categoryIndex,
          item: payload,
        });
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    ///////////////////
    // Discount CRUD //
    ///////////////////
    async createDiscount({ rootState, commit }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/discounts`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        commit('addDiscount', response.data.discount);
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async editDiscount({ rootState, commit }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/discounts/${payload._id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        commit('editDiscount', response.data.discount);
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async deleteDiscount({ rootState, commit }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${rootState.auth.salon._id}/discounts/${payload._id}`,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        commit('deleteDiscount', payload);
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
  },
  getters: {
    products(state) {
      const products = [];
      state.products.forEach((category) => products.push(...category.items));
      return products;
    },
    services(state) {
      const services = [];
      state.services.forEach((category) => services.push(...category.items));
      return services;
    },
    productsAndServices(state) {
      const products = [];
      state.products.forEach((category) => products.push(...category.items));

      const services = [];
      state.services.forEach((category) => services.push(...category.items));

      return [...products, ...services];
    },
    searchServices: (state) => (query) => {
      let categories = [];

      if (query) {
        state.services.forEach((category) => {
          if (
            category.items.some((item) =>
              item.title.toLowerCase().includes(query.toLowerCase())
            )
          )
            categories.push(JSON.parse(JSON.stringify(category)));

          categories.forEach((category) => {
            category.items = category.items.filter((item) =>
              item.title.toLowerCase().includes(query.toLowerCase())
            );
          });
        });
      } else {
        categories = JSON.parse(JSON.stringify(state.services));
      }

      return categories;
    },
    searchProducts: (state) => (query) => {
      let categories = [];

      if (query) {
        state.products.forEach((category) => {
          if (
            category.items.some((item) =>
              item.title.toLowerCase().includes(query.toLowerCase())
            )
          )
            categories.push(JSON.parse(JSON.stringify(category)));

          categories.forEach((category) => {
            category.items = category.items.filter((item) =>
              item.title.toLowerCase().includes(query.toLowerCase())
            );
          });
        });
      } else {
        categories = state.products;
      }

      return categories;
    },
  },
};
