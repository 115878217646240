<template>
  <section class="doc">
    <div class="doc_head">
      <h2>Getting Started Guide</h2>
      <p class="light small">
        Welcome to Raserva, with this guide we will very quickly go over the
        minimum data needed to get started using your account.
      </p>
    </div>
    <div class="doc_body">
      <p class="section">
        It is important to note that when creating an account from the Raserva
        homepage, you are creating a personal user account. This form will ask
        you for basic information such as your email address, name, and a
        password. This password should not be shared with your
        <router-link :to="{ name: 'DocsTeamMembers' }" class="link"
          >Team Members</router-link
        >.
      </p>
      <p class="section">
        After account creation you may then start your subscription with Raserva
        by creating a <span class="link">Salon</span>. This will prompt you to
        enter basic salon information such as shop name, address, timezone, etc.
        Here you will be redirected to enter billing information for Raserva
        subscription fees.
      </p>
      <p class="section">
        Now let's briefly go over common actions in Raserva. To add a new
        <router-link :to="{ name: 'DocsAppointments' }"
          ><span class="link">Appointment</span></router-link
        >
        to your bookings, you may select the "Add" dropdown button and select
        "New Appointment". You will then be navigated to the appointment
        creation form. From here you may select a date, time, service, and staff
        member performing this service. On the right hand side you may attach an
        existing <span class="link">Client</span> or create a new client on this
        appointment. Upon pressing "Save Appointment" you will be navigated back
        to your calendar page and will now have the new appointment listed on
        your bookings in the appropriate date and time slot.
      </p>
      <p class="section">
        In Raserva, if appointments are the first step of client interaction,
        <span class="link">Tickets</span> are the second step. Tickets are
        generally created upon a client entering your establishment.
        Appointments can be converted into tickets in two ways, the first by
        selecting the appointment and setting the status to "Arrived", the
        second by selecting the appointment and pressing the "Checkout" action.
        Tickets appear on the right side of your calendar page, and can be
        sorted and filtered to your liking with the menu at the top of its
        section. Note you may also add a new ticket (not by converting an
        appointment) by selecting the "Add" button dropdown and selecting "New
        Ticket". With tickets you are able to add, remove, edit existing
        <router-link :to="{ name: 'DocsServices' }" class="link"
          >Services</router-link
        >
        and/or
        <router-link :to="{ name: 'DocsProducts' }" class="link"
          >Products</router-link
        >
        as well as charge your client to checkout the ticket. Checking out a
        ticket will push appropriate information to your
        <span class="link">Sales</span> data.
      </p>
      <p class="section">
        Raserva comes pre-loaded with mock services/products for your
        convienence, however you will need to go in and add/edit to match that
        which your establishment can provide.
      </p>
      <p class="section">
        This getting started guide has very briefly went over the account
        creation, adding an appointment to your bookings, converting an
        appointment to a ticket and checking out a ticket. These are the most
        common day-to-day actions in Raserva, however it will be very beneficial
        to learn more on these actions in their more detailed sections. We have
        provided some suggested reading in the list below.
      </p>
      <ul class="section next">
        <li>
          <h3>Suggested Next Steps:</h3>
        </li>
        <router-link :to="{ name: 'DocsTeamMembers' }">
          <li>
            <p class="link">Adding Team Members</p>
          </li>
        </router-link>
        <router-link :to="{ name: 'DocsServices' }">
          <li>
            <p class="link">Adding Services</p>
          </li>
        </router-link>
        <router-link :to="{ name: 'DocsProducts' }">
          <li>
            <p class="link">Adding Products</p>
          </li>
        </router-link>
        <router-link :to="{ name: 'DocsSettings' }">
          <li>
            <p class="link">Updating Settings</p>
          </li>
        </router-link>
      </ul>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.doc {
  padding-bottom: 64px;

  p {
    line-height: 24px;
  }
  .light {
    margin-top: 8px;
    color: var(--clr-gray);
  }
  .small {
    font-size: 14px;
  }
  .link {
    text-decoration: underline;
    color: var(--clr-link);
    cursor: pointer;
  }

  &_head {
    h2 {
      font-size: 32px;
    }
  }

  &_body {
    margin-top: 32px;

    .section:not(:first-child) {
      margin-top: 16px;
    }

    .next {
      margin-top: 32px;

      h3 {
        margin-bottom: 16px;
      }

      li:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
}
</style>
