<template>
  <section class="dashboard">
    <nav class="dashboard_header">
      <the-header></the-header>
    </nav>
    <div class="dashboard_sidebar">
      <the-sidebar v-if="activeSalon"></the-sidebar>
    </div>
    <div class="dashboard_content">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import TheSidebar from '@/components/layout/TheSidebar.vue';
import TheHeader from '@/components/layout/TheHeader.vue';

export default {
  components: {
    TheSidebar,
    TheHeader,
  },
  computed: {
    activeSalon() {
      return this.$store.state.auth.salon && this.$store.state.auth.subActive;
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto calc(100vh - 65px);
  min-height: 100vh;

  &_header {
    grid-column: 1 / 3;
  }

  &_sidebar {
    grid-column: 1 / 2;
  }

  &_content {
    grid-column: 2 / 3;
    overflow-y: auto;
  }
}

// Desktop
@media (min-width: 900px) {
  .dashboard {
    height: 100vh;
    max-height: 100vh;
  }
}

// Tablet
@media (max-width: 900px) {
  .dashboard {
    grid-template-rows: auto 1fr;

    &_sidebar {
      display: none;
    }

    &_header,
    &_content {
      grid-column: 1/3;
    }

    &_content {
      justify-self: stretch;
    }
  }
}
</style>
