<template>
  <form
    @submit.prevent="reader ? getPaymentIntent() : (confirmNoCollection = true)"
    class="form"
  >
    <div class="form_body">
      <BaseSelect
        v-if="stripeAccountId"
        :options="stripeAccountOptions"
        :value="stripeAccountId"
        @input="stripeAccountId = $event"
        label="Stripe Account"
      />
      <BaseSelect
        label="Reader"
        :options="options"
        :value="
          reader ? readerOptionValue : options[0] ? options[0].value : null
        "
        @input="selectReader($event)"
      />
      <BaseInput
        label="Amount"
        :value="total || amount"
        @input="total = +$event"
        :disabled="showVirtualTerminal"
      />
    </div>
    <div class="form_actions">
      <BaseButton
        v-if="loading"
        mode="danger-outline"
        type="button"
        @click="cancelCharge"
        >Cancel Charge</BaseButton
      >
      <BaseButton
        v-if="!showVirtualTerminal"
        :disabled="!total || (reader && !connected) || loading"
      >
        <i v-if="loading" class="fas fa-spinner"></i>
        Charge
      </BaseButton>
    </div>
  </form>

  <div class="vtToggler" @click="showVirtualTerminal = !showVirtualTerminal">
    <i
      class="fas"
      :class="{
        'fa-chevron-down': !showVirtualTerminal,
        'fa-chevron-up': showVirtualTerminal,
      }"
    ></i>
    <p>{{ showVirtualTerminal ? 'Hide' : 'Show' }} Virtual Terminal</p>
  </div>

  <div v-if="showVirtualTerminal" class="virtualTerminal">
    <StripeVirtualTerminal
      :stripeAccountId="stripeAccountId"
      :amount="total || amount"
      @approved="submit($event)"
    />
  </div>

  <Confirm
    v-if="confirmNoCollection"
    :zIndex="2000000"
    title="Not collecting payment"
    text="Are you sure you wish to record this data without collecting payment?"
    @confirm="submit"
    @deny="confirmNoCollection = false"
  />
</template>

<script>
import StripeVirtualTerminal from '@/components/tickets/StripeVirtualTerminal.vue';

export default {
  emits: ['submit'],
  components: {
    StripeVirtualTerminal,
  },
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      required: false,
    },
    tax: {
      type: Number,
      required: false,
    },
    client_email: {
      type: String,
      required: false,
    },
  },
  created() {
    this.init();
  },
  unmounted() {
    if (this.terminal) {
      this.terminal.clearReaderDisplay();

      if (this.loading) {
        this.terminal.cancelCollectPaymentMethod();
      }
    }
  },
  watch: {
    stripeAccountId() {
      if (this.terminal) {
        this.terminal.disconnectReader();
      }

      this.discoverReaders();
      this.getConfigs();
    },
  },
  computed: {
    cashDiscountEnabled() {
      return this.$store.state.auth.salon.payments.useCashDiscount;
    },
    cashDiscountAdditionalProcessingFee() {
      return this.$store.state.auth.salon.payments
        .cashDiscountAdditionalProcessingFee;
    },
    stripeAccountOptions() {
      const accounts = [];

      this.$store.state.auth.user.paymentProcessing.stripe.accounts.forEach(
        (account) => {
          accounts.push({
            option: account.name || account.id,
            value: account.id,
          });
        }
      );

      return accounts;
    },
    defaultConfig() {
      if (!this.configs.length) return null;

      return this.configs.find((config) => {
        return config.is_account_default;
      });
    },
    isCentralTerminal() {
      return this.$store.state.auth.salon.payments.centralTerminal;
    },
    options() {
      let options = [
        {
          option: 'No reader',
          value: '',
        },
      ];

      this.readers.forEach((reader) => {
        options.push({
          option: reader.label,
          value: reader.id,
        });
      });

      return options;
    },
    readerOptionValue() {
      if (!this.reader) return null;

      return this.options.find((o) => {
        return o.value === this.reader.id;
      }).value;
    },
  },
  data() {
    return {
      total: null,
      loading: false,
      confirmNoCollection: false,

      readers: [],
      reader: null,
      configs: [],
      connected: false,
      terminal: null,
      stripeAccountId: null,

      showVirtualTerminal: false,
    };
  },
  methods: {
    async init() {
      // Stripe Account & Reader
      const storageStripeAccountId = localStorage.getItem('stripeAccountId');
      const storageStripeReaderId = localStorage.getItem('stripeReaderId');

      if (
        !this.isCentralTerminal &&
        this.$store.state.auth.user.paymentProcessing.stripe.accounts.length
      ) {
        if (
          this.$store.state.auth.user.paymentProcessing.stripe.accounts.find(
            (acc) => {
              return acc.id === storageStripeAccountId;
            }
          )
        ) {
          this.stripeAccountId = storageStripeAccountId;
        } else {
          this.stripeAccountId =
            this.$store.state.auth.user.paymentProcessing.stripe.accounts[0].id;
        }
      } else {
        this.stripeAccountId = null;
      }

      this.total = +this.amount;

      if (!this.stripeAccountId) return;

      this.terminal = window.StripeTerminal.create({
        onFetchConnectionToken: this.fetchConnectionToken,
        onUnexpectedReaderDisconnect: this.unexpectedDisconnect,
      });

      await this.discoverReaders();

      this.reader = this.readers.find((reader) => {
        return reader.id === storageStripeReaderId;
      });

      this.connectReader(this.reader);
    },
    cardconnectInit() {},
    unexpectedDisconnect() {
      // In this function, your app should notify the user that the reader disconnected.
      // You can also include a way to attempt to reconnect to a reader.
      this.$toast.warning('Disconnected from reader');
    },

    async fetchConnectionToken() {
      let salonAccountId;
      let locationId = this.$store.state.auth.user.defaultLocationId;

      // If salon is set to use admins accountId send data
      if (this.isCentralTerminal) {
        salonAccountId =
          this.$store.state.auth.salon.billing.stripe.adminAccountId;

        locationId = null;
      } else {
        salonAccountId = this.stripeAccountId;
      }

      // Do not cache or hardcode the ConnectionToken. The SDK manages the ConnectionToken's lifecycle.
      const response = await this.$axios.post(
        `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/connectionToken`,
        {
          salonAccountId,
          locationId,
        },
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.auth.token}`,
          },
        }
      );

      return response.data.connectionToken.secret;
    },

    async getConfigs() {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/configs?accountId=${this.stripeAccountId}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.configs = response.data.configs.data;
      } catch (error) {
        console.log(error);
      }
    },

    async discoverReaders() {
      if (!this.terminal) return;

      localStorage.setItem('stripeAccountId', this.stripeAccountId);

      this.terminal = window.StripeTerminal.create({
        onFetchConnectionToken: this.fetchConnectionToken,
        onUnexpectedReaderDisconnect: this.unexpectedDisconnect,
      });

      const config = {
        // simulated: true,
      };

      if (this.$store.state.auth.salon.payments.centralTerminal) {
        config.location = this.$store.state.auth.salon.billing.stripe.location;
      }

      const discoverResult = await this.terminal.discoverReaders(config);

      if (discoverResult.error) {
        this.$toast.error(discoverResult.error.message);
      } else if (discoverResult.discoveredReaders.length === 0) {
        this.reader = null;
        this.readers = [];
        this.$toast.error('No readers found');
      } else {
        this.readers = discoverResult.discoveredReaders;
      }
    },

    selectReader(id) {
      localStorage.setItem('stripeReaderId', id);

      if (this.terminal) {
        this.terminal.disconnectReader();
      }

      if (!id) {
        this.reader = null;
        return;
      }

      this.reader = this.readers.find((reader) => reader.id === id);

      this.connectReader(this.reader);
    },

    async connectReader(reader) {
      const connectResult = await this.terminal.connectReader(reader);

      if (connectResult.error) {
        this.$toast.error(connectResult.error.message);
      } else {
        this.connected = true;

        // Set line items
        if (this.items.length) {
          const line_items = [];

          this.items.forEach((item) => {
            line_items.push({
              description: item.item.title,
              amount: Math.ceil(
                (item.item.price - (item.item.discountAmount || 0)) * 100
              ),
              quantity: item.quantity,
            });
          });

          const config = {
            type: 'cart',
            cart: {
              line_items,
              tax: Math.ceil(this.tax * 100),
              total: Math.ceil(this.total * 100),
              currency: 'usd',
            },
          };

          try {
            await this.terminal.setReaderDisplay(config);
          } catch (error) {
            this.$toast.error(error.message);
          }
        }
      }
    },

    async getPaymentIntent() {
      this.loading = true;

      const amount = +(+this.total.toFixed(2) * 100).toFixed(0);
      let salonAccountId;
      let staffId;

      // If salon is set to use admins accountId send data
      if (this.isCentralTerminal) {
        salonAccountId =
          this.$store.state.auth.salon.billing.stripe.adminAccountId;

        staffId = this.$store.state.auth.salon.adminId;
      } else {
        salonAccountId = this.stripeAccountId;

        staffId = this.$store.state.auth.user._id;
      }

      const config = {
        amount,
        staffId,
      };

      if (this.cashDiscountEnabled) {
        config.cashDiscountAdditionalProcessingPercent =
          this.cashDiscountAdditionalProcessingFee;
      }

      if (this.client_email) {
        config.receipt_email = this.client_email;
      }

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/createPaymentIntent?accountId=${salonAccountId}`,
          config,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.collectPayment(response.data.intent.client_secret);
      } catch (error) {
        this.$toast.error(error.response.data.error);
        this.loading = false;
      }
    },

    async collectPayment(client_secret) {
      // Succeeds: 4242424242424242
      // Requires Auth: 4000002500003155
      // Declines: 4000000000009995

      // this.terminal.setSimulatorConfiguration({
      //   testCardNumber: '4242424242424242',
      // });

      try {
        const paymentInfo = await this.terminal.collectPaymentMethod(
          client_secret
        );

        if (paymentInfo.error) {
          this.$toast.error(paymentInfo.error.message);
          this.loading = false;
        } else {
          const payment = await this.terminal.processPayment(
            paymentInfo.paymentIntent
          );

          if (payment.error) {
            this.$toast.error(payment.error.message);
            this.loading = false;
          } else if (payment.paymentIntent) {
            this.capturePaymentIntent(payment.paymentIntent.id);
          }
        }
      } catch (error) {
        this.$toast.error(error.message);
        this.loading = false;
      }
    },

    async capturePaymentIntent(paymentIntentId) {
      try {
        let salonAccountId;

        // If salon is set to use admins accountId send data
        if (this.isCentralTerminal) {
          salonAccountId =
            this.$store.state.auth.salon.billing.stripe.adminAccountId;
        } else {
          salonAccountId = this.stripeAccountId;
        }

        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/capturePaymentIntent?accountId=${salonAccountId}`,
          {
            id: paymentIntentId,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.submit(response.data.intent);
      } catch (error) {
        this.$toast.error(error.response.data.error);
        this.loading = false;
      }
    },

    cancelCharge() {
      this.terminal.cancelCollectPaymentMethod();
    },

    submit(intent) {
      if (!intent) {
        this.$emit('submit', {
          ref: null,
          amount: +this.total.toFixed(2),
        });
        return;
      }

      this.$emit('submit', {
        ref: intent.id,
        amount: +(intent.amount / 100).toFixed(2),
        tipAmount: +(intent.amount_details?.tip?.amount || 0 / 100).toFixed(2),
        stripeAccountId: this.stripeAccountId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  &_body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &_actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    button {
      .fa-spinner {
        margin-right: 16px;
      }
    }
  }
}

.vtToggler {
  color: var(--clr-link);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 32px;
}

// Tablet
@media (max-width: 900px) {
  .form {
    &_actions {
      flex-direction: column;
    }
  }
}
</style>
