<template>
  <RotationOrder
    :staff="staffs"
    classicView
    :filter="filter"
    @changeFilter="filter = $event"
  />
</template>

<script>
import RotationOrder from '@/components/dashboard/RotationOrder.vue';

export default {
  props: {
    staffs: {
      type: Object,
      required: true,
    },
  },
  components: {
    RotationOrder,
  },
  data() {
    return {
      filter: 'All Staff',
    };
  },
};
</script>
