<template>
  <div v-if="client" class="clientInfo">
    <div class="clientInfo_head">
      <div class="col avatar">
        <Avatar :name="clientName" mode="rounded large" />
        <div class="clientInfo_head_text">
          <h2>{{ clientName }}</h2>
          <p v-if="showClientContactInfo">{{ client.phoneNumber }}</p>
        </div>
      </div>
      <div class="col options" @click="showDropdown = !showDropdown">
        <i class="fas fa-bars"></i>
        <Tooltip
          v-if="showDropdown"
          mode="bottom right"
          width="250px"
          @hide="showDropdown = false"
        >
          <ul class="options_dropdown">
            <li @click="showClientDetails = true">View Client Details</li>
            <li @click="showNoteLogs = true">View Notes</li>
            <li v-if="canViewFormulas" @click="showFormulaLogs = true">
              View Formulas
            </li>
            <li @click="$emit('addFamilyFriend')">Add Family/Friend</li>
            <li @click="selectFamilyFriend = true">Swap with Family/Friend</li>
            <li
              v-if="!client.global && showClientContactInfo"
              @click="showEditInfoModal = true"
            >
              Edit Basic Info
            </li>
            <li v-if="isBlacklisted" class="remove" @click="unblacklistClient">
              Un-Blacklist Client
            </li>
            <li v-else class="remove" @click="blacklistClient">
              Blacklist Client
            </li>
            <li class="remove" @click="$emit('removeClient')">
              Remove From Appointment
            </li>
          </ul>
        </Tooltip>
      </div>
    </div>
    <div class="clientInfo_tags">
      <i v-if="!fullClient" class="fas fa-spinner"></i>

      <Tag v-if="!client.tickets.length" text="New Client" />
      <Tag v-if="client.notifications.marketing" text="Marketing" />
      <Tag
        v-if="client.packages.length"
        @click="$emit('viewPackages')"
        text="Packages"
        icon="fas fa-caret-down"
        clickable
      />

      <Tag
        v-if="fullClient && fullClient.appointmentNoShows.length"
        :text="`${fullClient.appointmentNoShows.length} no shows`"
        backgroundColor="#fbb1b1"
        color="#b91212"
      />

      <Tag
        v-if="isBlacklisted"
        text="Blacklisted"
        backgroundColor="#fbb1b1"
        color="#b91212"
      />
    </div>
  </div>

  <Modal title="Note logs" :show="showNoteLogs" @close="showNoteLogs = false">
    <div class="noteLogs">
      <ClientNoteLogs :client="client" />
    </div>
  </Modal>

  <Modal
    title="Formula logs"
    :show="showFormulaLogs"
    @close="showFormulaLogs = false"
  >
    <div class="noteLogs">
      <ClientFormulas :client="client" />
    </div>
  </Modal>

  <Modal
    title="Select Family/Friend"
    :show="selectFamilyFriend"
    @close="selectFamilyFriend = false"
    maxWidth="400px"
  >
    <div class="familyFriendSelect">
      <ul>
        <li v-for="friend in client.familyAndFriends" :key="friend.clientId">
          <BaseButton
            @click="
              $emit('setFamilyFriend', friend);
              selectFamilyFriend = false;
            "
            >{{ `${friend.firstName} ${friend.lastName || ''}` }}</BaseButton
          >
        </li>
      </ul>
    </div>
  </Modal>

  <Modal
    title="Edit Client Info"
    :show="showEditInfoModal"
    @close="showEditInfoModal = false"
    maxWidth="400px"
  >
    <div class="editClientInfo">
      <form @submit.prevent="editClient" class="form">
        <BaseInput
          label="First Name"
          :value="newClient.firstName"
          @input="newClient.firstName = $event"
        />
        <BaseInput
          label="Last Name"
          :value="newClient.lastName"
          @input="newClient.lastName = $event"
        />
        <BasePhoneInput
          label="Phone Number"
          :value="newClient.phoneNumber"
          @input="newClient.phoneNumber = +$event"
        />
        <BaseInput
          label="Email"
          :value="newClient.email"
          @input="newClient.email = $event"
        />

        <div class="form_actions">
          <BaseButton>Submit</BaseButton>
        </div>
      </form>
    </div>
  </Modal>

  <SidePanel
    :show="showClientDetails"
    :zIndex="10000000"
    @close="showClientDetails = false"
  >
    <div class="clientDetails">
      <ClientDetails hideActions :client="client" />
    </div>
  </SidePanel>
</template>

<script>
import ClientNoteLogs from '@/components/clients/ClientNoteLogs.vue';
import ClientFormulas from '@/components/clients/ClientFormulas.vue';
import ClientDetails from '@/components/clients/ClientDetails.vue';

export default {
  emits: [
    'removeClient',
    'addFamilyFriend',
    'setFamilyFriend',
    'editClient',
    'viewPackages',
  ],
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  components: {
    ClientNoteLogs,
    ClientDetails,
    ClientFormulas,
  },
  created() {
    this.init();
  },
  data() {
    return {
      newClient: null,
      fullClient: null,

      showDropdown: false,
      showNoteLogs: false,
      showFormulaLogs: false,
      showClientDetails: false,
      showClientContactInfo: false,
      canViewFormulas: false,

      selectFamilyFriend: false,
      showEditInfoModal: false,
    };
  },
  methods: {
    async init() {
      this.newClient = JSON.parse(JSON.stringify(this.client));

      this.showClientContactInfo = await this.$store.dispatch(
        'auth/activeUserHasPermission',
        'staff/viewClientContactInfo'
      );

      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'staff/canViewFormulas'
        )
      ) {
        this.canViewFormulas = true;
      }

      this.fullClient = await this.$store.dispatch(
        'clients/getClientById',
        this.client.clientId || this.client._id
      );
    },

    blacklistClient() {
      const index = this.newClient.blacklistedSalons.findIndex(
        (id) => id === this.$store.state.auth.salon._id
      );

      if (index !== -1) return;

      this.newClient.blacklistedSalons.push(this.$store.state.auth.salon._id);

      this.editClient();
    },
    unblacklistClient() {
      const index = this.newClient.blacklistedSalons.findIndex(
        (id) => id === this.$store.state.auth.salon._id
      );

      if (index === -1) return;

      this.newClient.blacklistedSalons.splice(index, 1);

      this.editClient();
    },

    async editClient() {
      try {
        const client = await this.$store.dispatch('clients/editClient', {
          ...this.newClient,
          salonIdToUpdateFutureAppointments: this.$store.state.auth.salon._id,
        });

        this.$emit('editClient', client);
        this.showEditInfoModal = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    clientName() {
      if (!this.client) return '';

      return `${this.client.firstName} ${this.client.lastName || ''}`;
    },
    isBlacklisted() {
      if (!this.newClient.blacklistedSalons) return false;

      return this.newClient.blacklistedSalons.includes(
        this.$store.state.auth.salon._id
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.clientInfo {
  &_head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .avatar {
      display: flex;
      align-items: center;

      .clientInfo_head_text {
        margin-left: 16px;

        h2 {
          font-size: 16px;
        }
      }
    }
    .options {
      cursor: pointer;
      position: relative;

      &_dropdown {
        color: var(--clr-black);
        background-color: white;
        border: 1px solid var(--clr-light);
        border-radius: 5px;

        .remove {
          color: var(--clr-danger);
        }

        li {
          padding: 12px 16px;
          font-weight: 400;
          text-align: left;
          font-size: 15px;
          transition: background-color 0.2s;
          cursor: pointer;

          &:hover {
            background-color: var(--clr-extra-light);
          }
        }
      }
    }
  }

  &_tags {
    margin-top: 16px;
    max-width: 400px;

    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
  }
}

.noteLogs {
  padding: 32px;
}

.clientDetails {
  padding: 32px;
}

.familyFriendSelect {
  padding: 32px;

  ul {
    li {
      width: 100%;

      &:not(:first-child) {
        margin-top: 16px;
      }

      button {
        width: 100%;
      }
    }
  }
}

.editClientInfo {
  padding: 32px;

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_actions {
      text-align: right;
    }
  }
}
</style>
