<template>
  <form @submit.prevent="submit" class="form">
    <div class="form_body">
      <BaseInput
        label="Email"
        placeholder="Your email"
        :value="email"
        :validators="validators.email"
        @input="email = $event"
      />
      <BaseInput
        inputType="password"
        label="Password"
        placeholder="Your password"
        :value="password"
        :validators="validators.password"
        @input="password = $event"
      />
    </div>
    <div class="form_actions">
      <BaseButton :disabled="!formValid || loading"
        ><i v-if="loading" class="fas fa-spinner"></i> Login</BaseButton
      >

      <p class="link" @click="showRecovery = true">Forgot Password?</p>

      <div class="clientLogin">
        <h2>Attempting to book an appointment?</h2>
        <p>
          Please
          <router-link :to="{ name: 'ClientAuth' }">
            <span class="link">click here</span>
          </router-link>
          to continue to client log in
        </p>
      </div>
    </div>
  </form>

  <Modal
    title="Account Recovery"
    :show="showRecovery"
    @close="showRecovery = false"
  >
    <div class="recoveryForm">
      <AccountRecovery @reset="showRecovery = false" />
    </div>
  </Modal>
</template>

<script>
import AccountRecovery from '@/components/auth/AccountRecovery.vue';

export default {
  components: {
    AccountRecovery,
  },
  created() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
      this.$toast.info(
        'Staff account with your email already exists, try logging in.'
      );
    }
  },
  computed: {
    validators() {
      return {
        email: {
          email: true,
          required: true,
        },
        password: {
          minLength: 6,
          required: true,
        },
      };
    },
    formValid() {
      if (this.email.length < 5) return false;
      if (this.password.length < 6) return false;

      return true;
    },
  },
  data() {
    return {
      email: '',
      password: '',
      loading: false,

      showRecovery: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;

      try {
        await this.$store.dispatch('auth/memberLogin', {
          email: this.email,
          password: this.password,
        });

        this.$router.push({
          name: 'SalonSelect',
        });
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  padding: 0 16px;

  &_body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &_actions {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    button {
      width: 100%;
    }

    .link {
      font-size: 14px;
      color: var(--clr-link);
      text-align: right;
      cursor: pointer;
    }
  }

  .clientLogin {
    text-align: center;
    padding: 16px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;

    p {
      margin-top: 16px;
    }
    .link {
      color: var(--clr-link);
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.recoveryForm {
  padding: 32px;
}
</style>
