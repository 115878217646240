<template>
  <h3>Click to select</h3>

  <ul>
    <li
      v-for="member in staffs"
      :key="member._id"
      :class="{ selected: isSelected(member) }"
      @click="
        isSelected(member)
          ? selectedStaffs.splice(selectedStaffs.indexOf(member.staffId), 1)
          : selectedStaffs.push(member.staffId)
      "
    >
      <Avatar
        mode="large rounded"
        :name="member.firstName"
        :image="member.profileImage"
      />

      <p>{{ `${member.firstName} ${member.lastName[0]}` }}</p>
    </li>
  </ul>

  <div class="actions">
    <BaseButton @click="submit" :disabled="!selectedStaffs.length"
      >Continue</BaseButton
    >
  </div>
</template>

<script>
export default {
  emits: ['selected'],
  props: {
    staffs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedStaffs: [],
    };
  },
  methods: {
    isSelected(member) {
      return this.selectedStaffs.includes(member.staffId);
    },
    submit() {
      if (!this.selectedStaffs.length) return;

      this.$emit('selected', this.selectedStaffs);
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin-bottom: 32px;
}

ul {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 32px;

  .selected {
    background-color: var(--clr-secondary-white);
  }

  li {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
    padding: 16px;
    border: 1px solid var(--clr-white-2);
    border-radius: 5px;
    cursor: pointer;

    p {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.actions {
  margin-top: 32px;
  text-align: right;
}
</style>
