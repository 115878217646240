<template>
  <div class="items">
    <!-- Mobile -->
    <div class="mobile">
      <BaseSelect
        :options="mainMenuOptions"
        :value="selectedMenuTab"
        @input="
          selectedMenuTab = $event;
          selectedSubMenuTab = 'all';
        "
      />
      <BaseSelect
        :options="subMenuOptions"
        :value="selectedSubMenuTab"
        @input="selectedSubMenuTab = $event"
      />
    </div>

    <!-- Main Menu -->
    <ul class="items_menu">
      <li
        @click="
          selectedMenuTab = 'services';
          selectedSubMenuTab = 'all';
        "
        :class="{ selected: selectedMenuTab === 'services' }"
      >
        Services
      </li>
      <li
        @click="
          selectedMenuTab = 'products';
          selectedSubMenuTab = 'all';
        "
        :class="{ selected: selectedMenuTab === 'products' }"
      >
        Products
      </li>
      <li
        @click="
          selectedMenuTab = 'custom';
          selectedSubMenuTab = 'custom';
        "
        :class="{ selected: selectedMenuTab === 'custom' }"
      >
        Custom
      </li>
      <!-- <li
        @click="selectedMenuTab = 'custom'"
        :class="{ selected: selectedMenuTab === 'custom' }"
      >
        Custom Item
      </li>
      <li
        @click="selectedMenuTab = 'quick'"
        :class="{ selected: selectedMenuTab === 'quick' }"
      >
        Quick Entry
      </li> -->
    </ul>

    <!-- Sub-menu -->

    <!-- Services -->
    <ul v-if="selectedMenuTab === 'services'" class="items_menu_submenu">
      <li
        @click="selectedSubMenuTab = 'all'"
        :class="{ selected: selectedSubMenuTab === 'all' }"
      >
        All
      </li>
      <li
        v-for="category in serviceCategories"
        :key="category._id"
        @click="selectedSubMenuTab = category._id"
        :class="{ selected: selectedSubMenuTab === category._id }"
      >
        {{ category.title }}
      </li>
    </ul>
    <!-- Products -->
    <ul v-if="selectedMenuTab === 'products'" class="items_menu_submenu">
      <li
        @click="selectedSubMenuTab = 'all'"
        :class="{ selected: selectedSubMenuTab === 'all' }"
      >
        All
      </li>
      <li
        v-for="category in productCategories"
        :key="category._id"
        @click="selectedSubMenuTab = category._id"
        :class="{ selected: selectedSubMenuTab === category._id }"
      >
        {{ category.title }}
      </li>
    </ul>

    <!-- Items -->

    <div class="items_items">
      <!-- Search -->
      <div
        v-if="filteredItems && filteredItems.length"
        class="itemsCards search"
      >
        <ClassicPOSItemCard
          v-for="item in filteredItems"
          @click="$emit('selectItem', item)"
          :key="item._id"
          :item="item"
        />
      </div>
      <!-- Services -->
      <div v-else-if="selectedMenuTab === 'services'" class="itemsCards">
        <ClassicPOSItemCard
          v-for="item in filteredServices"
          @click="$emit('selectItem', item)"
          :key="item._id"
          :item="item"
        />
      </div>
      <!-- Products -->
      <div v-else-if="selectedMenuTab === 'products'" class="itemsCards">
        <ClassicPOSItemCard
          v-for="item in filteredProducts"
          @click="$emit('selectItem', item)"
          :key="item._id"
          :item="item"
        />
      </div>
      <!-- Custom -->
      <form v-if="selectedMenuTab === 'custom'" class="customItemForm">
        <CustomItemForm @add="addCustomItem($event)" />
      </form>
      <!-- Cart -->
      <div v-else-if="items.length" class="cart">
        <p class="title">Items on ticket</p>
        <ul>
          <li
            v-for="(item, index) in items"
            :key="item._id"
            @click="$emit('removeItem', index)"
            :style="{ backgroundColor: item.item.color }"
          >
            <p>{{ item.item.title }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicPOSItemCard from '@/views/classic/pos/ClassicPOSItemCard.vue';
import CustomItemForm from '@/components/tickets/CustomItemForm.vue';

export default {
  components: {
    ClassicPOSItemCard,
    CustomItemForm,
  },
  props: {
    items: {
      type: Array,
      required: false,
    },
  },
  emits: ['selectItem', 'close', 'removeItem'],
  computed: {
    filteredItems() {
      if (!this.search) return [];

      const items = [...this.allServices, ...this.allProducts];

      return items.filter((i) => i.title.toLowerCase().includes(this.search));
    },
    mainMenuOptions() {
      return [
        {
          option: 'Services',
          value: 'services',
        },
        {
          option: 'Products',
          value: 'products',
        },
      ];
    },
    subMenuOptions() {
      const options = [
        {
          option: 'All',
          value: 'all',
        },
      ];

      if (this.selectedMenuTab === 'services') {
        this.serviceCategories.forEach((category) => {
          options.push({
            option: category.title,
            value: category._id,
          });
        });
      } else if (this.selectedMenuTab === 'products') {
        this.productCategories.forEach((category) => {
          options.push({
            option: category.title,
            value: category._id,
          });
        });
      }

      return options;
    },
    serviceCategories() {
      return this.$store.state.auth.salon.services;
    },
    allServices() {
      const services = [];

      this.serviceCategories.forEach((category) => {
        category.items.forEach((item) => {
          services.push({ ...item, categoryId: category._id });
        });
      });

      return services;
    },
    filteredServices() {
      if (this.selectedSubMenuTab === 'all') {
        return this.allServices;
      }

      return this.allServices.filter((s) => {
        return s.categoryId === this.selectedSubMenuTab;
      });
    },
    productCategories() {
      return this.$store.state.auth.salon.products;
    },
    allProducts() {
      const products = [];

      this.productCategories.forEach((category) => {
        category.items.forEach((item) => {
          products.push({ ...item, categoryId: category._id });
        });
      });

      return products;
    },
    filteredProducts() {
      if (this.selectedSubMenuTab === 'all') {
        return this.allProducts;
      }

      return this.allProducts.filter((p) => {
        return p.categoryId === this.selectedSubMenuTab;
      });
    },
  },
  data() {
    return {
      selectedMenuTab: 'services',
      selectedSubMenuTab: 'all',
      search: '',
    };
  },
  methods: {
    addCustomItem(item) {
      this.$emit('selectItem', item);
    },
  },
};
</script>

<style lang="scss" scoped>
.items {
  height: 750px;
  max-height: 90vh;
  background-color: var(--clr-white-2);
  display: flex;
  align-items: flex-start;
  //   flex-direction: column;

  &_menu {
    min-width: 150px;
  }
  &_menu_submenu {
    min-width: 250px;
  }

  &_menu,
  &_menu_submenu {
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--clr-white-2);
    background-color: white;
    border-right: 2px solid var(--clr-light);

    .selected {
      background-color: var(--clr-secondary-white);
      //   font-weight: 700;
    }

    li {
      padding: 16px 24px;
      cursor: pointer;
      background-color: white;

      &:hover {
        background-color: var(--clr-white-2);
      }
    }
  }

  &_items {
    overflow-y: auto;
    height: 100%;
    flex-grow: 1;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .itemsCards {
      display: grid;
      grid-template-columns: repeat(4, minmax(200px, 1fr));
      align-items: flex-start;
      gap: 16px;
      padding-bottom: 16px;
    }

    .cart {
      background-color: white;
      position: sticky;
      bottom: 0;
      left: 0;
      width: 100%;
      margin-top: 32px;
      padding: 16px;
      box-shadow: var(--shadow);

      .title {
        font-weight: 400;
        color: var(--clr-gray);
        font-size: 16px;
      }

      ul {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        li {
          padding: 8px 24px;
          border-radius: 500px;
          color: var(--clr-white);
          font-weight: 700;
          cursor: pointer;
          background-color: var(--clr-gray);
        }
      }
    }
  }
}

.mobile {
  display: none;
}

// Mobile
@media (max-width: 600px) {
  .items {
    flex-direction: column;

    .mobile {
      padding: 8px 16px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &_menu,
    &_menu_submenu {
      display: none;
    }
    &_items {
      .itemsCards {
        grid-template-columns: 1fr;
      }
    }
  }
}
</style>
