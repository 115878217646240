export default {
  namespaced: true,
  state: {
    chats: [],
    selectedChat: null,
  },
  actions: {
    editChat({ state }, payload) {
      const index = state.chats.findIndex((chat) => chat._id === payload._id);

      if (index === -1) return;

      state.chats[index] = payload;
    },
  },
};
