<template>
  <section class="stripe">
    <Spinner v-if="loading" />

    <form @submit.prevent="submit" id="payment-form" class="form">
      <div id="payment-element">
        <!--Stripe.js injects the Payment Element-->
      </div>

      <div class="form_actions">
        <BaseButton :disabled="loading"
          ><i v-if="loading" class="fas fa-spinner"></i> Pay Now</BaseButton
        >
      </div>
    </form>
  </section>
</template>

<script>
export default {
  emits: ['approved'],
  props: {
    amount: {
      type: Number,
      required: true,
    },
    stripeAccountId: {
      type: String,
      default: null,
    },
  },
  created() {
    this.init();
  },
  computed: {
    isCentralTerminal() {
      return this.$store.state.auth.salon.payments.centralTerminal;
    },
    cashDiscountEnabled() {
      return this.$store.state.auth.salon.payments.useCashDiscount;
    },
    cashDiscountAdditionalProcessingFee() {
      return this.$store.state.auth.salon.payments
        .cashDiscountAdditionalProcessingFee;
    },
  },
  data() {
    return {
      loading: false,
      stripe: null,
      elements: null,
    };
  },
  methods: {
    async init() {
      if (!this.amount) {
        this.$toast.error('Amount is required');
        return;
      }

      this.loading = true;

      // Live: pk_live_51KNtx8DdQDiL7UkfnZKLgT2eFloA163erEuJpf9VxK89yKGgnHiCDeLKlBRo1FHx3MULgrXygX71NKuEPfgdkZGv00El4bFJiH
      // Test: pk_test_51KNtx8DdQDiL7UkfdVu2g46290VYLFgjwhdog9JcqmZEpp0n4n1Ly2bCRBS34MIkLvolFbYtZ2dFAEiAaDy4vJMw00akLlDXig
      this.stripe = window.Stripe(
        'pk_live_51KNtx8DdQDiL7UkfnZKLgT2eFloA163erEuJpf9VxK89yKGgnHiCDeLKlBRo1FHx3MULgrXygX71NKuEPfgdkZGv00El4bFJiH',
        {
          stripeAccount: this.stripeAccountId,
        }
      );

      // If salon is set to use admins accountId send data
      let salonAccountId;
      let staffId;

      if (this.isCentralTerminal) {
        salonAccountId =
          this.$store.state.auth.salon.billing.stripe.adminAccountId;

        staffId = this.$store.state.auth.salon.adminId;
      } else {
        salonAccountId = this.stripeAccountId;

        staffId = this.$store.state.auth.user._id;
      }

      const config = {
        amount: Math.ceil(this.amount * 100),
        staffId,
        virtualTerminal: true,
      };

      if (this.cashDiscountEnabled) {
        config.cashDiscountAdditionalProcessingPercent =
          this.cashDiscountAdditionalProcessingFee;
      }

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/createPaymentIntent?accountId=${salonAccountId}`,
          config,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        const { clientSecret } = response.data;

        this.elements = this.stripe.elements({
          appearance: {
            theme: 'stripe',
          },
          clientSecret,
        });

        const paymentElement = this.elements.create('payment', {
          layout: 'tabs',
        });

        paymentElement.mount('#payment-element');
      } catch (error) {
        if (error.response) {
          this.$toast.error(error.response.data.error);
        } else {
          this.$toast.error(error.message);
        }
      }

      this.loading = false;
    },

    async submit() {
      this.loading = true;

      try {
        const response = await this.stripe.confirmPayment({
          elements: this.elements,
          redirect: 'if_required',
          capture: true,
        });

        if (response.paymentIntent.status === 'requires_capture') {
          await this.capturePaymentIntent(response.paymentIntent.id);
        } else {
          if (response.error) {
            this.$toast.error(response.error);
          } else {
            this.$emit('approved', response.data.intent);
          }
        }
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    async capturePaymentIntent(paymentIntentId) {
      try {
        let salonAccountId;

        // If salon is set to use admins accountId send data
        if (this.isCentralTerminal) {
          salonAccountId =
            this.$store.state.auth.salon.billing.stripe.adminAccountId;
        } else {
          salonAccountId = this.stripeAccountId;
        }

        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/capturePaymentIntent?accountId=${salonAccountId}`,
          {
            id: paymentIntentId,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        if (response.data.intent.status === 'succeeded') {
          this.$emit('approved', response.data.intent);
        } else {
          this.$toast.error(`Payment failed: ${response.data.intent.status}`);
        }
      } catch (error) {
        console.log(error.message);
        this.$toast.error(error.response.data.error);
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stripe {
  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_actions {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      margin-top: 16px;
    }
  }
}
</style>
