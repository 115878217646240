<template>
  <section class="prices">
    <div class="prices_head">
      <h2>Marketing messages</h2>
      <p>Sending promotional texts and/or emails to clients</p>
    </div>
    <div class="prices_body">
      <div class="price">
        <h1>${{ textPrice }}</h1>
        <h2>/ text</h2>
      </div>
      <div class="price">
        <h1>${{ emailPrice }}</h1>
        <h2>/ email</h2>
      </div>
    </div>
    <div class="prices_foot">
      <p>Need help? <span class="link">Contact sales</span></p>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    textPrice() {
      return +this.$store.state.auth.salon.marketing.texts.cost.toFixed(2);
    },
    emailPrice() {
      return +this.$store.state.auth.salon.marketing.emails.cost.toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.prices {
  padding: 32px;
  text-align: center;

  &_head {
    p {
      margin-top: 8px;
    }
  }
  &_body {
    margin-top: 32px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 32px;

    .price {
      h1 {
        color: var(--clr-secondary);
      }
    }
  }
  &_foot {
    margin-top: 32px;

    .link {
      color: var(--clr-link);
      cursor: pointer;
    }
  }
}
</style>
