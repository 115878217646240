<template>
  <Spinner v-if="loading" fullscreen />
  <!-- Charge Anywhere -->
  <form
    v-if="processor === 'chargeanywhere'"
    @submit.prevent="submit"
    class="form"
  >
    <iframe
      :src="`/capf.html?mid=${billing.chargeanywhere.pfmid}&tid=${billing.chargeanywhere.pftid}&amount=${ticketBalance}&forceAmount=true`"
      width="100%"
      height="400"
      frameborder="0"
      ref="CAIframe"
    ></iframe>
  </form>
</template>

<script>
export default {
  emits: ['paid'],
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    processor: {
      type: String,
      required: true,
    },
    billing: {
      type: Object,
    },
  },
  mounted() {
    // CA TOKEN
    window.addEventListener('message', this.eventListener);
  },
  async unmounted() {
    window.removeEventListener('message', this.eventListener);

    if (this.loading) await this.cancel();
  },
  computed: {
    ticketBalance() {
      let balance = this.total;

      this.ticket.payments.forEach((payment) => {
        balance -= payment.amount;
      });

      return +balance.toFixed(2);
    },

    cardSurchage() {
      if (!this.ticket.items.length) {
        return 0;
      }

      let total = 0;
      this.ticket.items.forEach((item) => {
        if (item.item.prepaid) return;

        total +=
          (item.item.cashDiscountAdditionalProcessingFee || 0) * item.quantity;
      });

      return total;
    },
    subtotal() {
      if (!this.ticket.items.length) {
        return 0;
      }

      let total = 0;
      this.ticket.items.forEach((item) => {
        if (item.item.prepaid) return;

        if (item.item.basePrice) {
          total +=
            item.item.basePrice -
            (item.item.discountAmount || 0) * item.quantity;
        } else {
          total +=
            (item.item.price -
              (item.item.discountAmount || 0) -
              (item.item.cashDiscountAdditionalProcessingFee || 0)) *
            item.quantity;
        }
      });

      return total;
    },
    tax() {
      if (!this.ticket.items.length) {
        return 0;
      }

      let tax = 0;
      this.ticket.items.forEach((item) => {
        if (item.item.prepaid) return;

        tax += item.item.price * (item.item.taxRate * 0.01) * item.quantity;
      });

      return tax;
    },
    tip() {
      let amount = 0;

      this.ticket.tips.forEach((tip) => (amount += tip.amount));

      return amount;
    },
    total() {
      return this.subtotal + this.tax + this.tip + this.cardSurchage;
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async eventListener(event) {
      // CA Event
      if (event.data.ResponseCode) {
        const customData = event.data;
        const parsed = JSON.parse(JSON.stringify(customData, null, 2));

        if (parsed.TokenNumber) {
          const payment = {
            type: 'card',
            amount: +parsed.AuthorizedAmount,
            ref: `${parsed.GatewayReferenceNumber || parsed.ReferenceNumber}:${
              parsed.ApprovalCode
            }`,
            processor: 'chargeanywhere',
          };

          try {
            await this.$axios.put(
              `${process.env.VUE_APP_RASERVA_BACKEND}/tickets/${this.ticket._id}/client/payments`,
              {
                salonId: this.ticket.salonId,
                payments: [...this.ticket.payments, payment],
              }
            );

            this.$emit('paid', payment);
            this.loading = false;
          } catch (error) {
            console.log(error);
            this.loading = false;
          }
        } else {
          // Have to do it this way or get error:
          // "Assigning src to self"
          const src = this.$refs.CAIframe.src;
          this.$refs.CAIframe.src = src;

          this.$toast.error(`Error; ${parsed.ResponseText} - Please try again`);
          this.loading = false;
        }
      }

      // Raserva Event
      if (event.origin === process.env.VUE_APP_ORIGIN) {
        this.loading = true;
      }
    },
  },
};
</script>
