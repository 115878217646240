export default {
  namespaced: true,
  state: {
    locations: [],
    readers: [],
  },
  mutations: {
    setLocations(state, payload) {
      state.locations = payload;
    },
    setReaders(state, payload) {
      state.readers = payload;
    },
  },
  actions: {},
  getters: {},
};
