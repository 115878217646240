<template>
  <form @submit.prevent="createSubscription" class="create form">
    <div v-if="subscription.status === 'canceled'" class="alert alert-danger">
      <p>This subscription is canceled</p>
    </div>
    <div v-if="subscription.status === 'unpaid'" class="alert alert-danger">
      <p>
        The attempts to charge this subscription has failed and as a result the
        subscription has been canceled
      </p>
    </div>
    <div
      v-if="subscription.status === 'past due' && !subscription.scheduled"
      class="alert alert-warning"
    >
      <p>This subscription is past due</p>
    </div>
    <div
      v-if="subscription.status === 'past due' && subscription.scheduled"
      class="alert alert-warning"
    >
      <p>
        This subscription is past due, we've attempted to charge the card on
        file {{ subscription.attempts }} times
      </p>
    </div>

    <div v-if="subscription.client" class="client">
      <ClientListItem
        @click="showConfirmRemoveClient = true"
        :client="subscription.client"
      />
    </div>

    <div v-if="!subscription.client" class="clients">
      <form @submit.prevent="searchClients" class="form">
        <BaseInput
          placeholder="Search Client"
          icon="fas fa-search"
          @input="clientSearchValue = $event"
        />
      </form>

      <ClientList
        class="mt-m"
        :clients="clients"
        basic
        @selectClient="subscription.client = $event"
      />
    </div>

    <BaseSelect
      label="Billing Period"
      :options="billingPeriodOptions"
      :value="subscription.billingPeriod"
      @input="subscription.billingPeriod = $event"
    />

    <BaseSelect
      label="Package"
      :options="packageOptions"
      :value="subscription.package ? subscription.package._id : null"
      @input="selectPackage($event)"
    />

    <BaseInput
      inputType="number"
      label="Price"
      :value="subscription.price"
      @input="
        subscription.price = +$event;
        dejavooChargeAmount = +$event;
      "
    />

    <BaseInput
      v-if="subscription._id"
      inputType="date"
      label="Next Billing Date"
      ref="nextBillingDateInput"
      @input="subscription.nextBillingDate = new Date($event)"
    />

    <div class="toggle">
      <Toggle
        :checked="subscription.scheduled"
        @toggle="subscription.scheduled = !subscription.scheduled"
      />
      <div class="toggle_text">
        <p class="toggle_text_label">Scheduled Payments</p>
        <p class="toggle_text_subtext">
          If disabled, Raserva will not automatically charge and update this
          subscription.
        </p>
      </div>
    </div>

    <div v-if="!subscription._id" class="toggle">
      <Toggle :checked="startNow" @toggle="startNow = !startNow" />
      <div class="toggle_text">
        <p class="toggle_text_label">Charge Immediately</p>
        <p class="toggle_text_subtext">
          If disabled, client will not be charged until next billing date.
        </p>
      </div>
    </div>

    <p
      v-if="subscription._id"
      @click="showUpdateCard = !showUpdateCard"
      class="link"
    >
      <i
        class="fas"
        :class="showUpdateCard ? 'fa-caret-up' : 'fa-caret-down'"
      ></i>
      Update Card On File
    </p>

    <div v-if="showUpdateCard && processor === 'chargeanywhere'" class="caVT">
      <!-- ChargeAnywhere Virtual Terminal -->
      <iframe
        :src="capfSrc"
        width="100%"
        height="400"
        frameborder="0"
        ref="CAIframe"
      ></iframe>
    </div>

    <div v-if="showUpdateCard && processor === 'dejavoo'" class="djvVT">
      <BaseInput
        label="Amount ($)"
        :value="subscription.price"
        @input="dejavooChargeAmount = +$event"
      />
      <BaseButton type="button" @click="getDejavooPaymentFormLink"
        >Submit Price</BaseButton
      >

      <!-- DejaVoo Virtual Terminal -->
      <iframe
        v-if="dejavooPaymentFormLink"
        :src="dejavooPaymentFormLink"
        width="100%"
        height="400"
        frameborder="0"
        ref="CAIframe"
      ></iframe>

      <!-- <Spinner v-else /> -->
    </div>

    <div class="form_actions">
      <div v-if="subscription._id" class="statusUpdaters">
        <BaseButton
          v-if="
            subscription.status === 'canceled' ||
            subscription.status === 'unpaid'
          "
          type="button"
          mode="primary-outline"
          :disabled="loading"
          @click="activateSubscription"
          ><i v-if="loading" class="fas fa-spinner"></i> Activate
          Subscription</BaseButton
        >
        <BaseButton
          v-else
          type="button"
          mode="danger-outline"
          :disabled="loading"
          @click="cancelSubscription"
          ><i v-if="loading" class="fas fa-spinner"></i> Cancel
          Subscription</BaseButton
        >
        <BaseButton
          v-if="!subscription.scheduled"
          type="button"
          mode="primary-outline"
          :disabled="loading"
          @click="chargePayment"
          ><i v-if="loading" class="fas fa-spinner"></i>Charge
          Payment</BaseButton
        >
      </div>

      <BaseButton :disabled="!subValid || loading"
        ><i v-if="loading" class="fas fa-spinner"></i>
        {{ subscription._id ? 'Update' : 'Create' }}</BaseButton
      >
    </div>
  </form>

  <ul
    class="history"
    v-if="
      subscription._id && subscription.history && subscription.history.length
    "
  >
    <li v-for="charge in subscription.history" :key="charge._id">
      <div class="info">
        <div class="section">
          <p class="head">Date</p>
          <p>{{ formatDate(charge.date) }}</p>
        </div>
        <div class="section">
          <p class="head">Amount</p>
          <p>${{ charge.amount.toFixed(2) }}</p>
        </div>
        <div v-if="charge.status === 'refunded'" class="section">
          <p class="head">Status</p>
          <p class="red">Refunded</p>
        </div>
      </div>

      <div class="actions">
        <BaseButton
          @click="refundPayment(charge)"
          :disabled="refundLoading"
          v-if="charge.status === 'default'"
          mode="danger small"
          ><i v-if="refundLoading" class="fas fa-spinner"></i>
          Refund</BaseButton
        >
      </div>
    </li>
  </ul>

  <Confirm
    v-if="showConfirmRemoveClient"
    delete
    title="Remove Client"
    text="Are you sure you wish to remove this client?"
    @confirm="
      subscription.client = null;
      showConfirmRemoveClient = false;
    "
    @deny="showConfirmRemoveClient = false"
  />
</template>

<script>
import ClientList from '@/components/clients/ClientList.vue';
import ClientListItem from '@/components/clients/ClientListItem.vue';

export default {
  components: {
    ClientList,
    ClientListItem,
  },
  props: {
    presubscription: {
      type: Object,
    },
  },
  emits: ['created', 'updated'],
  computed: {
    formattedBillingDate() {
      return this.$moment(this.subscription.nextBillingDate)
        .add(12, 'hours')
        .format('YYYY-MM-DD');
    },
    capfSrc() {
      let src = `/capf.html?mid=${this.caInfo.pfmid}&tid=${this.caInfo.pftid}`;

      if (this.startNow) src += `&amount=${this.subscription.price}`;

      return src;
    },
    processor() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.payments.processor;
    },
    caInfo() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.billing.chargeanywhere;
    },
    subValid() {
      if (
        !this.subscription.client ||
        !this.subscription.billingPeriod ||
        !this.subscription.package ||
        !this.subscription.price ||
        !this.subscription.billing.token
      )
        return false;

      if (this.startNow && !this.paid) return false;

      return true;
    },
    packages() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.packages;
    },
    billingPeriodOptions() {
      return [
        {
          option: 'Daily',
          value: 'daily',
        },
        {
          option: 'Monthly',
          value: 'monthly',
        },
        {
          option: 'Quarterly',
          value: 'quarterly',
        },
        {
          option: 'Biannual',
          value: 'biannual',
        },
        {
          option: 'Yearly',
          value: 'yearly',
        },
      ];
    },
    packageOptions() {
      const packageOptions = [];

      this.packages.forEach((salonPackage) => {
        packageOptions.push({
          option: salonPackage.title,
          value: salonPackage._id,
        });
      });

      return packageOptions;
    },
  },
  created() {
    if (this.presubscription) {
      this.subscription = JSON.parse(JSON.stringify(this.presubscription));
      this.startNow = false;
    } else {
      this.showUpdateCard = true;
    }

    this.subscription.billing.processor = this.processor;
  },
  async mounted() {
    if (this.presubscription) {
      this.$refs.nextBillingDateInput.setValue(this.formattedBillingDate);
    }

    // CA TOKEN
    window.addEventListener('message', this.eventListener);
  },
  unmounted() {
    window.removeEventListener('message', this.eventListener);
  },
  data() {
    return {
      clientSearchValue: '',
      clients: [],
      showConfirmRemoveClient: false,

      subscription: {
        client: null,
        billingPeriod: 'monthly',
        package: null,
        nextBillingDate: null,
        price: null,
        scheduled: true,
        billing: {
          processor: '',
          token: '',
          cvv: '',
        },
        history: [],
      },

      startNow: true,
      paid: false,
      loading: false,
      refundLoading: false,
      showUpdateCard: false,

      dejavooPaymentFormLink: null,
      dejavooChargeAmount: 0,
    };
  },
  methods: {
    eventListener(event) {
      // CA Event
      if (event.data.ResponseCode) {
        const customData = event.data;
        const parsed = JSON.parse(JSON.stringify(customData, null, 2));

        if (parsed.TokenNumber) {
          this.subscription.billing.token = parsed.TokenNumber;
          this.paid = true;

          if (this.subscription._id) {
            // Update billing info
            this.$toast.success('Billing info updated');
            this.updateSubscription();
          }

          if (this.startNow) {
            this.subscription.history.push({
              date: new Date(Date.now()),
              amount: parsed.AuthorizedAmount,
              processor: this.processor,
              ref: `${parsed.ReferenceNumber}:${parsed.ApprovalCode}`,
            });
          }
        } else {
          // Have to do it this way or get error:
          // "Assigning src to self"
          const src = this.$refs.CAIframe.src;
          this.$refs.CAIframe.src = src;

          this.$toast.error(`Error; ${parsed.ResponseText} - Please try again`);
        }
      }

      // Raserva Event
      if (event.origin === process.env.VUE_APP_ORIGIN) {
        if (!event.data.processor) {
          const customData = event.data;
          const parsed = JSON.parse(JSON.stringify(customData, null, 2));

          this.subscription.billing.cvv = parsed.cvv;
        } else if (event.data.processor === 'dejavoo') {
          console.log(event.data);

          this.subscription.billing.token = event.data.responseCardToken;
          this.paid = true;

          if (this.subscription._id) {
            // Update billing info
            this.$toast.success('Billing info updated');
            this.updateSubscription();
          }

          if (this.startNow) {
            this.subscription.history.push({
              date: new Date(Date.now()),
              amount: this.dejavooChargeAmount,
              processor: this.processor,
              ref: event.data.RRN,
            });
          }
        }
      }
    },
    async createSubscription() {
      if (this.subscription._id) {
        this.updateSubscription();
        return;
      }

      this.loading = true;

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/memberships`,
          { ...this.subscription, salonId: this.$store.state.auth.salon._id },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$emit('created', response.data.subscription);
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    async updateSubscription() {
      this.loading = true;

      if (
        this.presubscription.status === 'active' &&
        this.subscription.scheduled
      ) {
        this.subscription.status = 'scheduled';
      }
      if (
        this.presubscription.status === 'scheduled' &&
        !this.subscription.scheduled
      ) {
        this.subscription.status = 'active';
      }

      try {
        const response = await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/memberships/${this.subscription._id}`,
          this.subscription,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$emit('updated', response.data.subscription);
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    async cancelSubscription() {
      this.loading = true;

      try {
        this.subscription.status = 'canceled';

        const response = await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/memberships/${this.subscription._id}`,
          this.subscription,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$emit('updated', response.data.subscription);
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    async activateSubscription() {
      if (this.subscription.status === 'unpaid') {
        this.attemptRechargeAndActivate();
        return;
      }

      if (
        this.$moment(this.subscription.nextBillingDate).isBefore(this.$moment())
      ) {
        this.$toast.error('Next billing date must be greater than now');
        return;
      }

      this.loading = true;

      try {
        if (this.subscription.scheduled) {
          this.subscription.status = 'scheduled';
        } else {
          this.subscription.status = 'active';
        }

        const response = await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/memberships/${this.subscription._id}`,
          this.subscription,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$emit('updated', response.data.subscription);
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    async chargePayment() {
      if (
        this.$moment(this.subscription.nextBillingDate).isBefore(this.$moment())
      ) {
        this.$toast.error('Next billing date must be greater than now');
        return;
      }

      this.loading = true;

      try {
        // Charge Anywhere
        if (this.subscription.processor === 'chargeanywhere') {
          // Attempt recharge and if approved reactive subscription
          const response = await this.$store.dispatch(
            'payments/chargeAnywhereChargeViaToken',
            {
              token: this.subscription.billing.token,
              amount: this.subscription.price,
            }
          );

          if (response.approved) {
            this.subscription.status = 'active';

            this.subscription.history.push({
              date: new Date(Date.now()),
              amount: this.subscription.price,
              processor: this.processor,
              ref: `${response.ReferenceNumber}:${response.ApprovalCode}`,
            });

            this.$toast.success(response.message);
            this.updateSubscription();
          } else {
            this.$toast.error(response.message);
          }
        } else if (this.subscription.processor === 'dejavoo') {
          // Dejavoo
          const amount = +(this.subscription.price * 100).toFixed(0);

          try {
            const response = await this.$axios.post(
              `${process.env.VUE_APP_RASERVA_BACKEND}/dejavoo/chargeViaToken`,
              {
                salonId: this.$store.state.auth.salon._id,
                amount: amount.toString(),
                cardToken: this.subscription.billing.token,
              }
            );

            const data = response.data.data.iposhpresponse;

            if (data.responseCode === '200') {
              this.subscription.status = 'active';

              this.subscription.history.push({
                date: new Date(Date.now()),
                amount: this.subscription.price,
                processor: this.processor,
                ref: data.RRN,
              });

              this.$toast.success(response.message);
              this.updateSubscription();
            } else {
              this.$toast.error(data.responseMessage);
            }
          } catch (error) {
            this.$toast.error(error.message);
          }
        }
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    async attemptRechargeAndActivate() {
      if (
        this.$moment(this.subscription.nextBillingDate).isBefore(this.$moment())
      ) {
        this.$toast.error('Next billing date must be greater than now');
        return;
      }

      this.loading = true;

      try {
        // Charge Anywhere
        if (this.subscription.billing.processor === 'chargeanywhere') {
          // Attempt recharge and if approved reactive subscription
          const response = await this.$store.dispatch(
            'payments/chargeAnywhereChargeViaToken',
            {
              token: this.subscription.billing.token,
              amount: this.subscription.price,
            }
          );

          if (response.approved) {
            this.subscription.status = 'scheduled';

            this.subscription.history.push({
              date: new Date(Date.now()),
              amount: this.subscription.price,
              processor: this.processor,
              ref: `${response.ReferenceNumber}:${response.ApprovalCode}`,
            });

            this.$toast.success(response.message);
            this.updateSubscription();
          } else {
            this.$toast.error(response.message);
          }
        } else if (this.subscription.billing.processor === 'dejavoo') {
          // Dejavoo
          const amount = +(this.subscription.price * 100).toFixed(0);

          try {
            const response = await this.$axios.post(
              `${process.env.VUE_APP_RASERVA_BACKEND}/dejavoo/chargeViaToken`,
              {
                salonId: this.$store.state.auth.salon._id,
                amount: amount.toString(),
                cardToken: this.subscription.billing.token,
              }
            );

            const data = response.data.data.iposhpresponse;

            if (data.responseCode === '200') {
              this.subscription.status = 'scheduled';

              this.subscription.history.push({
                date: new Date(Date.now()),
                amount: this.subscription.price,
                processor: this.processor,
                ref: data.RRN,
              });

              this.$toast.success('Success');
              this.updateSubscription();
            } else {
              this.$toast.error(data.responseMessage);
            }
          } catch (error) {
            this.$toast.error(error.message);
          }
        }
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    formatDate(date) {
      return this.$moment(date).format('LLL');
    },

    async searchClients() {
      this.clients = await this.$store.dispatch('clients/searchClients', {
        name: this.clientSearchValue,
        email: this.clientSearchValue,
        phone: this.clientSearchValue,
      });
    },

    selectPackage(packageId) {
      const salonPackage = this.packages.find((pack) => pack._id === packageId);

      if (!salonPackage) return;

      this.subscription.package = salonPackage;
      this.subscription.price = salonPackage.price;
    },

    async refundPayment(payment) {
      this.refundLoading = true;

      try {
        const refund = await this.$store.dispatch('payments/refund', payment);

        if (refund.refunded) {
          const index = this.subscription.history.findIndex(
            (prepayment) => prepayment._id === payment._id
          );

          if (index === -1) return;

          this.subscription.history[index].status = 'refunded';

          this.$toast.success('Payment refunded');
          this.updateSubscription();
        } else {
          this.$toast.error(refund.message);
        }
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.refundLoading = false;
    },

    async getDejavooPaymentFormLink() {
      let transactionType;

      if (this.dejavooChargeAmount && this.startNow) {
        transactionType = 1;
      } else {
        transactionType = 2;
      }

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/dejavoo/getPaymentFormLink`,
          {
            salonId: this.$store.state.auth.salon._id,
            amount: +(this.dejavooChargeAmount * 100).toFixed(0),
            returnUrl: `${process.env.VUE_APP_ORIGIN}/djvpfreturn.html`,
            transactionType,
          }
        );

        this.dejavooPaymentFormLink = response.data.link;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mt-m {
  margin-top: 16px;
}

.link {
  color: var(--clr-link);
  cursor: pointer;

  i {
    margin-right: 8px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .client,
  .clients {
    margin-bottom: 16px;
  }

  .toggle {
    display: flex;
    align-items: center;
    gap: 32px;
    flex-wrap: wrap;
    padding: 16px 0;

    &_text {
      &_label {
        font-size: 18px;
      }
      &_subtext {
        margin-top: 5px;
        font-size: 14px;
      }
    }
  }

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    .statusUpdaters {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }
  }
}

.alert {
  padding: 12px;
  font-size: 14px;
  border-radius: 5px;

  .bold {
    text-decoration: none;
    font-weight: 700;
    cursor: default;
  }
  span {
    text-decoration: underline;
    cursor: pointer;
  }

  &-danger {
    background-color: var(--clr-danger-light);
    border-color: var(--clr-danger);
  }
  &-warning {
    background-color: var(--clr-warning-light);
    border-color: var(--clr-warning);
  }
}

.history {
  margin-top: 32px;

  li {
    padding: 16px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:first-child) {
      margin-top: 16px;
    }

    .info {
      display: flex;
      gap: 32px;
      align-items: flex-start;

      .section {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .head {
          color: var(--clr-gray);
          font-size: 12px;
        }
        .red {
          color: var(--clr-danger);
        }
      }
    }
  }
}

.djvVT {
  button {
    margin-top: 16px;
  }
}
</style>
