<template>
  <div class="card">
    <div class="image">
      <img v-if="item.image" :src="item.image" alt="Item image" />
      <i v-else class="fas fa-ban"></i>
    </div>
    <div class="info">
      <p class="title">{{ item.title }}</p>
      <p v-if="item.price" class="price">${{ item.price.toFixed(2) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: white;
  box-shadow: var(--shadow);
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  .image {
    height: 80px;
    width: 80px;
    min-height: 80px;
    min-width: 80px;
    background-color: var(--clr-light);
    display: grid;
    place-items: center;
    font-size: 32px;
    color: var(--clr-light-2);

    img {
      height: 100%;
      width: 100%;
      object-fit: fill;
    }
  }
  .info {
    .title {
      font-weight: 700;
    }
    .price {
      color: var(--clr-gray);
      font-size: 14px;
      margin-top: 5px;
    }
  }
}
</style>
