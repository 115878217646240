<template>
  <section class="locations">
    <div class="locations_head">
      <h3>
        Locations <i class="fas fa-plus" @click="showLocationForm = true"></i>
      </h3>
      <p>
        A Location represents a grouping of readers and is needed to collect
        payments.
      </p>
    </div>
    <div class="locations_list">
      <Spinner v-if="loading" />
      <div v-else class="locations_list_body">
        <ul v-if="locations.length">
          <li v-for="location in locations" :key="location.id">
            <div class="col">
              <h4>{{ location.display_name }}</h4>
              <p>
                {{ `${location.address.line1}, ${location.address.state}` }}
              </p>
            </div>
            <div class="col">
              <i class="fas fa-edit" @click="selectedLocation = location"></i>
            </div>
          </li>
        </ul>
        <div v-else class="none">
          <h3>No locations created</h3>
          <p>Please create a location to get started</p>
        </div>
      </div>
    </div>
  </section>

  <Modal
    title="Location Form"
    :show="showLocationForm || selectedLocation"
    @close="
      showLocationForm = false;
      selectedLocation = null;
    "
  >
    <div class="locationForm">
      <CreateLocation
        :accountId="accountId"
        :location="selectedLocation"
        @created="createdLocation($event)"
        @edited="editedLocation($event)"
        @deleted="deletedLocation($event)"
      />
    </div>
  </Modal>
</template>

<script>
import CreateLocation from '@/components/stripe/CreateLocation.vue';

export default {
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  components: {
    CreateLocation,
  },
  created() {
    this.init();
  },
  watch: {
    accountId() {
      this.init();
    },
  },
  data() {
    return {
      loading: false,
      locations: [],

      showLocationForm: false,
      selectedLocation: null,
    };
  },
  methods: {
    async init() {
      this.loading = true;

      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/location?accountId=${this.accountId}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.locations = response.data.locations.data;
        this.$store.commit('stripe/setLocations', this.locations);
      } catch (error) {
        this.$toast.error(error.response.data.error);
      }

      this.loading = false;
    },
    createdLocation(location) {
      this.locations.push(location);
      this.showLocationForm = false;
      this.selectedLocation = null;
      this.$toast.success('Location created');
    },
    editedLocation(location) {
      const locationIndex = this.locations.findIndex(
        (loc) => loc.id === location.id
      );

      if (locationIndex === -1) return;

      this.locations[locationIndex] = location;
      this.showLocationForm = false;
      this.selectedLocation = null;
      this.$toast.success('Location edited');
    },
    deletedLocation(location) {
      const locationIndex = this.locations.findIndex(
        (loc) => loc.id === location.id
      );

      if (locationIndex === -1) return;

      this.locations.splice(locationIndex, 1);
      this.showLocationForm = false;
      this.selectedLocation = null;
      this.$toast.success('Location deleted');
    },
  },
};
</script>

<style lang="scss" scoped>
.none {
  text-align: center;
  padding: 16px;
  border: 1px solid var(--clr-light);
  border-radius: 5px;

  p {
    margin-top: 5px;
  }
}
.spinner {
  margin-top: 32px;
}
.locations {
  border: 1px solid var(--clr-light);
  border-radius: 5px;
  padding: 16px;

  &_head {
    i {
      margin-left: 32px;
      color: var(--clr-link);
      cursor: pointer;
    }
    p {
      margin-top: 8px;
    }
  }
  &_list {
    &_head,
    &_body {
      margin-top: 16px;
    }

    &_body {
      ul {
        li {
          &:not(:first-child) {
            margin-top: 16px;
          }
          &:hover {
            background-color: var(--clr-white);
          }

          border-radius: 5px;
          padding: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            font-size: 12px;
            margin-top: 5px;
          }
          i {
            font-size: 20px;
            color: var(--clr-link);
            cursor: pointer;
            transition: color 0.2s ease;

            &:not(:first-child) {
              margin-left: 32px;
            }
            &:hover {
              color: var(--clr-link-muted);
            }
          }
        }
      }
    }
  }
}

.locationForm {
  padding: 32px;
}
</style>
