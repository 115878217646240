<template>
  <div class="endpoint_head">
    <h2>Update schedule</h2>

    <div class="route">
      <i
        @click="
          $router.push('/documentation/api/settings#updateSchedule');
          $toast.info('Set URL to endpoint');
        "
        :style="{ color: 'var(--clr-secondary)', cursor: 'pointer' }"
        class="fas fa-hashtag"
      ></i>
      <p class="tag tag-blue">PUT</p>
      <p class="url">{{ apiUrl }}<span>/salons/:id/settings/schedule</span></p>
    </div>

    <div class="description">
      <p>Update a salons schedule</p>
    </div>
  </div>

  <div class="endpoint_body">
    <div class="request">
      <h3>Request</h3>

      <!-- Headers -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Headers</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">Authorization</p>
              <p class="examples">Examples: <span>Bearer {Token}</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Path params -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Path Parameters</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">ID</p>
              <p class="description">ID of salon</p>
              <p class="examples">
                Examples: <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6f</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
          <p>application/json</p>
        </div>

        <ul>
          <!-- default -->
          <li>
            <div class="col value">
              <p class="bold">default</p>
              <p class="description">
                Array - Default array is to contain 7 objects, one for each day
                of the week with "day" 0 being Sunday, "day" 1 being Monday,
                etc..
              </p>
              <p class="examples">
                Examples:
                <span
                  >[ { "day": 0, "blocks": [ { "start": "10:00", "end": "17:00"
                  }, ] } ]</span
                >
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- exceptions -->
          <li>
            <div class="col value">
              <p class="bold">exceptions</p>
              <p class="description">
                Array - Exceptions array is to contain javascript date objects,
                for any date that does not follow the default schedule.
              </p>
              <p class="examples">
                Examples:
                <span>
                  [ { "date": "2022-11-19T06:00:00.000Z", "blocks": [ { "start":
                  "11:00", "end": "16:00" } ] } ]</span
                >
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="response">
      <h3>Response</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">schedule</p>
              <p class="examples">
                Examples:
                <span
                  >{ "schedule": { "default": [ { "day": 0, "blocks": [ {
                  "start": "10:00", "end": "16:00", "_id":
                  "648a36884e18ece7be594371" } ], "_id":
                  "648a36884e18ece7be59436f" }, { "day": 1, "blocks": [ {
                  "start": "09:00", "end": "17:00", "_id":
                  "648a36884e18ece7be594371" } ], "_id":
                  "648a36884e18ece7be594370" }, { "day": 2, "blocks": [ {
                  "start": "11:00", "end": "15:00", "_id":
                  "648a36884e18ece7be594371" } ], "_id":
                  "648a36884e18ece7be59436f" }, { "day": 3, "blocks": [ {
                  "start": "09:00", "end": "15:00", "_id":
                  "64b342b4bf2f2386f1c9ed77" } ], "_id":
                  "64b342b4bf2f2386f1c9ed76" }, { "day": 4, "blocks": [ {
                  "start": "09:00", "end": "17:00", "_id":
                  "648a36884e18ece7be594377" } ], "_id":
                  "648a36884e18ece7be594376" }, { "day": 5, "blocks": [ {
                  "start": "09:00", "end": "17:00", "_id":
                  "648a36884e18ece7be594379" } ], "_id":
                  "648a36884e18ece7be594378" }, { "day": 6, "blocks": [], "_id":
                  "648a36884e18ece7be59437a" } ], "exceptions": [ { "date":
                  "2023-06-16T17:40:23.489Z", "blocks": [ { "start": "09:00",
                  "end": "15:00", "_id": "648a36884e18ece7be594371" } ], "_id":
                  "64b34120a8e6769b20419438" }, { "date":
                  "2023-06-17T17:40:23.489Z", "blocks": [ { "start": "11:00",
                  "end": "15:00", "_id": "648a36884e18ece7be594371" } ], "_id":
                  "64b340fda8e6769b2041940a" }, { "date":
                  "2023-06-18T17:40:23.489Z", "blocks": [ { "start": "08:00",
                  "end": "15:00", "_id": "648a36884e18ece7be594371" } ], "_id":
                  "64b34120a8e6769b2041943a" } ], "_id":
                  "648a36884e18ece7be59436e" } }</span
                >
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="reqResExamples">
      <h3>Examples</h3>

      <!-- Request -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Request</h4>
          <p>application/json</p>
        </div>

        <ul>
          <li>
            <p>
              { "default": [ { "day": 3, "blocks": [ { "start": "09:00", "end":
              "15:00" } ] } ] }
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    apiUrl() {
      return process.env.VUE_APP_RASERVA_BACKEND;
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoint {
  .tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px;
    border-radius: 5px;
    color: white;

    &-blue {
      background-color: var(--clr-secondary);
    }
  }

  &_head {
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }

    .route {
      padding: 8px 16px;
      border-radius: 5px;
      background-color: var(--clr-white-2);
      display: flex;
      align-items: center;
      gap: 16px;

      .url {
        color: var(--clr-gray);

        span {
          color: var(--clr-primary);
          font-weight: 700;
        }
      }
    }

    .description {
      margin-top: 32px;
    }
  }

  &_body {
    .request,
    .response,
    .reqResExamples {
      margin-top: 32px;
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &_section {
      &:not(:first-child) {
        margin-top: 32px;
      }

      .head {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 20px;
          margin-bottom: 16px;
          color: var(--clr-secondary);
        }

        p {
          font-size: 14px;
          color: var(--clr-gray);
        }
      }

      ul {
        .subitem {
          margin-left: 32px;
        }

        li {
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          &:not(:first-child) {
            margin-top: 16px;
          }

          .value {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .bold {
            font-weight: 700;
          }
          .required {
            color: orangered;
          }

          .description {
            margin: 4px 0;
            color: var(--clr-gray);
          }

          .examples {
            span {
              background-color: var(--clr-white-2);
              padding: 4px 8px;
              border-radius: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
