<template>
  <teleport to="body">
    <transition name="panel">
      <section
        class="panel"
        v-if="show"
        :style="{ zIndex: zIndex, width: hideBackdrop ? width : '' }"
      >
        <Backdrop v-if="!hideBackdrop" @click="$emit('close')"></Backdrop>
        <div class="panel_content" :style="{ maxWidth: width }">
          <i class="fas fa-times" @click="$emit('close')"></i>
          <slot></slot>
        </div>
      </section>
    </transition>
  </teleport>
</template>

<script>
export default {
  emits: ['close'],
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: String,
      default: '500px',
    },
    zIndex: {
      type: String,
      required: false,
    },
    hideBackdrop: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;

  &_content {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    margin: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--clr-light);
    box-shadow: var(--shadow-large);
    overflow-y: auto;
  }
}

.fa-times {
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--clr-gray);
  cursor: pointer;
}

.panel-enter-active,
.panel-leave-active {
  transition: transform 0.3s ease;
}

.panel-enter-from,
.panel-leave-to {
  transform: translateX(100%);
}
</style>
