<template>
  <section class="visuals">
    <div class="visuals_charts">
      <div class="visuals_charts_chart">
        <div class="head">
          <h2>Total Appointments</h2>
          <p>Last 7 Days</p>
        </div>
        <LineChart
          :chartData="appointmentsData"
          :options="appointmentsOptions"
          ref="appChart"
        />
      </div>
      <div class="visuals_charts_chart">
        <div class="head">
          <h2>Total Sales</h2>
          <p>Last 7 Days</p>
        </div>
        <LineChart
          :chartData="salesData"
          :options="salesOptions"
          ref="salesChart"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import { LineChart } from 'vue-chart-3';

export default {
  components: {
    LineChart,
  },
  created() {
    this.init();
  },
  props: {
    summary: {
      type: Object,
    },
    selectedStaffOption: {
      type: String,
      default: 'all',
    },
  },
  watch: {
    summary() {
      this.setSummaryData();
    },
    selectedStaffOption() {
      this.setSummaryData(true);
    },
  },
  computed: {
    options() {
      return {
        plugins: {
          legend: {
            // display: false,
            position: 'bottom',
          },
        },
      };
    },
    salesOptions() {
      return {
        ...this.options,
        scales: {
          y: {
            ticks: {
              callback: (value) => {
                return '$' + value;
              },
            },
          },
        },
      };
    },
    appointmentsOptions() {
      return {
        ...this.options,
        scales: {
          y: {
            ticks: {
              stepSize: 1,
            },
          },
        },
      };
    },
  },
  data() {
    return {
      loading: true,

      appointmentsData: {
        labels: [],
        datasets: [
          {
            label: 'Total appointments',
            data: [],
            borderColor: '#42afc2',
          },
          {
            label: 'Online bookings',
            data: [],
            borderColor: '#101a28',
          },
        ],
      },
      salesData: {
        labels: [],
        datasets: [
          {
            label: 'Services',
            data: [],
            borderColor: '#42afc2',
          },
          {
            label: 'Products',
            data: [],
            borderColor: '#101a28',
          },
        ],
      },
    };
  },
  methods: {
    init() {
      this.setSummaryData();
    },
    setSummaryData(update) {
      if (update) {
        this.resetCharts();
      }

      // Tickets
      const ticketData = [];

      for (let i = 6; i >= 0; i--) {
        ticketData.push({
          day: this.$moment().subtract(i, 'days'),
          serviceTotal: 0,
          productTotal: 0,
        });
      }

      this.summary.tickets.forEach((ticket) => {
        if (ticket.status !== 'completed') return;

        const totals = this.getTicketTotals(ticket);

        const dayIndex = ticketData.findIndex((day) =>
          this.$moment(day.day).isSame(ticket.created_at, 'day')
        );

        if (dayIndex !== -1) {
          ticketData[dayIndex].serviceTotal += totals.serviceTotal;
          ticketData[dayIndex].productTotal += totals.productTotal;
        }
      });

      ticketData.forEach((day) => {
        this.salesData.labels.push(this.$moment(day.day).format('ddd Do'));
        this.salesData.datasets[0].data.push(day.serviceTotal);
        this.salesData.datasets[1].data.push(day.productTotal);
      });

      // Appointments
      const appointmentsData = [];

      for (let i = 6; i >= 0; i--) {
        appointmentsData.push({
          day: this.$moment().subtract(i, 'days'),
          appointments: [],
        });
      }

      this.summary.appointments.forEach((appointment) => {
        if (this.selectedStaffOption !== 'all') {
          if (
            !appointment.services.some(
              (service) => service.staff.staffId === this.selectedStaffOption
            )
          ) {
            return;
          }
        }

        const dayIndex = appointmentsData.findIndex((day) =>
          this.$moment(day.day).isSame(appointment.date, 'day')
        );

        if (dayIndex !== -1) {
          appointmentsData[dayIndex].appointments.push(appointment);
        }
      });

      appointmentsData.forEach((day) => {
        if (!day.onlineBookings) day.onlineBookings = 0;

        day.appointments.forEach((appointment) => {
          if (appointment.bookedOnline) {
            day.onlineBookings++;
          }
        });
      });

      appointmentsData.forEach((day) => {
        this.appointmentsData.labels.push(
          this.$moment(day.day).format('ddd Do')
        );
        this.appointmentsData.datasets[0].data.push(day.appointments.length);
        this.appointmentsData.datasets[1].data.push(day.onlineBookings);
      });
    },
    getTicketTotals(ticket) {
      let serviceTotal = 0;
      let productTotal = 0;

      ticket.items.forEach((item) => {
        if (this.selectedStaffOption !== 'all') {
          if (item.staff.staffId !== this.selectedStaffOption) {
            return;
          }
        }

        if (item.item.duration) {
          serviceTotal +=
            item.item.price -
            (item.item.cashDiscountAdditionalProcessingFee || 0) *
              item.quantity;
        } else {
          productTotal +=
            item.item.price -
            (item.item.cashDiscountAdditionalProcessingFee || 0) *
              item.quantity;
        }
      });

      return { serviceTotal, productTotal };
    },
    resetCharts() {
      this.appointmentsData = {
        labels: [],
        datasets: [
          {
            label: 'Total appointments',
            data: [],
            borderColor: '#42afc2',
          },
          {
            label: 'Online bookings',
            data: [],
            borderColor: '#101a28',
          },
        ],
      };

      this.salesData = {
        labels: [],
        datasets: [
          {
            label: 'Services',
            data: [],
            borderColor: '#42afc2',
          },
          {
            label: 'Products',
            data: [],
            borderColor: '#101a28',
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.visuals {
  &_charts {
    display: flex;
    flex-direction: column;
    gap: 64px;

    &_chart {
      padding: 48px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;

      .head {
        margin-bottom: 32px;

        p {
          margin-top: 5px;
          text-transform: uppercase;
          color: var(--clr-gray);
          font-size: 12px;
          letter-spacing: 1px;
        }
      }
    }
  }
}
</style>
