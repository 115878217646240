<template>
  <router-link :to="{ name: 'SalonPage', id: salon.Id }">
    <div class="logo">
      <div class="image">
        <img
          v-if="salon.profileImage"
          :src="salon.profileImage"
          alt="Salon Image"
        />
        <p v-else>{{ salon.details.shopName[0] }}</p>
      </div>
      <h2>{{ salon.details.shopName }}</h2>
    </div>
  </router-link>
</template>

<script>
export default {
  computed: {
    salon() {
      return this.$store.state.booking.salon;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  text-align: center;

  .image {
    margin: auto;
    margin-bottom: 16px;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transform: translate3d(0, 0, 1px);
    }

    p {
      height: 100%;
      width: 100%;
      background-color: var(--clr-secondary);
      color: var(--clr-white);
      font-weight: 700;
      font-size: 32px;
      vertical-align: middle;
      display: grid;
      place-items: center;
    }
  }
}
</style>
