<template>
  <section class="tip">
    <div class="checkbox excludeCheckbox">
      <input
        type="checkbox"
        id="excludeProducts"
        :value="excludeProducts"
        @input="excludeProducts = !excludeProducts"
      />
      <label for="excludeProducts">Exclude products</label>
    </div>

    <div class="tip_amount">
      <div class="input">
        <BaseInput
          inputType="number"
          label="Tip amount"
          :icon="getIcon"
          :placeholder="mode === 'dollar' ? providedAmount / 100 : 0"
          @input="updateTipAmount(+$event)"
        ></BaseInput>
        <p class="subtitle">{{ getLabel }}</p>
      </div>
      <div class="tip_amount_type">
        <div
          class="tip_amount_type_dollar option"
          :class="{ selected: mode === 'dollar' }"
          @click="
            mode = 'dollar';
            setTipToProvided();
          "
        >
          <i class="fas fa-dollar-sign"></i>
        </div>
        <div
          class="tip_amount_type_percent option"
          :class="{ selected: mode === 'percent' }"
          @click="
            mode = 'percent';
            tipAmount = 0;
          "
        >
          <i class="fas fa-percentage"></i>
        </div>
      </div>
    </div>

    <div class="tip_staff">
      <BaseSelect
        inputType="number"
        label="Staff Tipped"
        placeholder="Select staff"
        :options="showAllOptions ? staffForSelect : staffOnTicketOptions"
        :value="suggestedStaff ? suggestedStaff._id : null"
        @input="selectStaff($event)"
      ></BaseSelect>

      <div class="checkbox">
        <input
          type="checkbox"
          id="showAllStaff"
          :value="showAllOptions"
          @input="showAllOptions = !showAllOptions"
        />
        <label for="showAllStaff">Show all staff</label>
      </div>

      <!-- Lock Staff -->
      <p
        v-if="
          staffOnTicket.length > 1 &&
          (selectedStaff === 'nostaffidwilleverbethisevenly' ||
            selectedStaff === 'nostaffidwilleverbethispercent')
        "
        @click="showLockStaffData = true"
        class="lock link"
      >
        <i class="fas fa-caret-down"></i> Lock Staff Tip
      </p>
    </div>
    <!-- Lock Staff Options -->
    <ul v-if="showLockStaffData" class="lockStaffOptions">
      <li v-for="staff in staffOnTicket" :key="staff._id">
        <BaseInput
          inputType="number"
          :label="`${staff.firstName} ${staff.lastName}`"
          placeholder="$"
          :value="staff.lockAmount"
          @input="
            $event ? (staff.lockAmount = +$event) : (staff.lockAmount = null)
          "
        />
      </li>
    </ul>

    <div class="tip_actions">
      <BaseButton @click="addTip">Submit</BaseButton>
    </div>
  </section>
</template>

<script>
export default {
  emits: ['submit'],
  props: {
    total: {
      type: Number,
      required: true,
    },
    providedAmount: {
      type: Number,
      required: false,
    },
    suggestedStaff: {
      type: Object,
      required: false,
    },
    ticketItems: {
      type: Array,
      required: false,
    },
  },
  created() {
    this.staff = this.$store.state.staff.staff;

    if (this.suggestedStaff) this.selectedStaff = this.suggestedStaff;

    if (this.providedAmount) {
      this.updateTipAmount((this.tipAmount = +this.providedAmount / 100));
    }
  },
  data() {
    return {
      mode: 'dollar',
      amount: 0,
      tipAmount: 0,
      staff: [],
      selectedStaff: null,
      showLockStaffData: false,
      showAllOptions: false,
      excludeProducts: false,
    };
  },
  watch: {
    excludeProducts() {
      this.updateTipAmount(this.amount);
    },
  },
  computed: {
    totalWithoutProducts() {
      let itemsTotal = 0;
      let tax = 0;

      this.ticketItems.forEach((item) => {
        if (this.excludeProducts) {
          if (item.item.duration)
            itemsTotal +=
              ((item.item.basePrice || item.item.price || 0) -
                (item.item.discountAmount || 0) +
                (item.item.cashDiscountAdditionalProcessingFee || 0)) *
              (item.quantity || 0);
        } else {
          itemsTotal +=
            ((item.item.basePrice || item.item.price || 0) -
              (item.item.discountAmount || 0) +
              (item.item.cashDiscountAdditionalProcessingFee || 0)) *
            (item.quantity || 0);
        }

        if (item.item.prepaid) return;

        tax +=
          (item.item.price - (item.item.discountAmount || 0)) *
          (item.item.taxRate * 0.01) *
          item.quantity;
      });

      return itemsTotal + tax;
    },
    getLabel() {
      return this.mode === 'dollar'
        ? `${this.tipAmount}%`
        : `$${this.tipAmount}`;
    },
    getIcon() {
      return this.mode === 'dollar'
        ? 'fas fa-dollar-sign'
        : 'fas fa-percentage';
    },
    staffOnTicketOptions() {
      const staff = [];

      this.ticketItems.forEach((item) => {
        if (item.staff && !staff.find((s) => s.value === item.staff._id)) {
          staff.push({
            option: `${item.staff.firstName} ${item.staff.lastName}`,
            value: item.staff._id,
          });
        }
      });

      if (!this.showAllOptions) {
        staff.unshift({
          option: 'All staff - Evenly',
          value: 'nostaffidwilleverbethisevenly',
        });
        staff.unshift({
          option: 'All staff - Percent',
          value: 'nostaffidwilleverbethispercent',
        });
      }

      return staff;
    },
    staffForSelect() {
      const options = [
        {
          option: 'All staff - Evenly',
          value: 'nostaffidwilleverbethisevenly',
        },
        {
          option: 'All staff - Percent',
          value: 'nostaffidwilleverbethispercent',
        },
      ];

      const staff = JSON.parse(JSON.stringify(this.staff));

      staff
        .sort((a, b) => {
          if (a.firstName < b.firstName) return -1;
          if (a.firstName > b.firstName) return 1;
          return 0;
        })
        .forEach((staff) => {
          options.push({
            option: `${staff.firstName} ${staff.lastName}`,
            value: staff._id,
          });
        });

      return options;
    },
    staffOnTicket() {
      const staff = [];

      this.ticketItems.forEach((item) => {
        if (item.staff) {
          if (!staff.find((s) => s._id === item.staff._id))
            staff.push(
              JSON.parse(JSON.stringify({ ...item.staff, lockAmount: null }))
            );
        }
      });

      return staff;
    },
  },
  methods: {
    setTipToProvided() {
      this.updateTipAmount((this.tipAmount = +this.providedAmount / 100));
    },
    updateTipAmount(amount) {
      this.amount = amount;

      if (!this.amount) this.tipAmount = 0;

      if (this.mode === 'dollar') {
        this.tipAmount = +(
          (+this.amount / +this.totalWithoutProducts) *
          100
        ).toFixed(2);
        return;
      }

      this.tipAmount = +(
        (+this.amount / 100) *
        +this.totalWithoutProducts
      ).toFixed(2);
    },
    selectStaff(staffId) {
      if (staffId === 'nostaffidwilleverbethisevenly') {
        this.selectedStaff = 'nostaffidwilleverbethisevenly';
        return;
      } else if (staffId === 'nostaffidwilleverbethispercent') {
        this.selectedStaff = 'nostaffidwilleverbethispercent';
        return;
      }

      this.selectedStaff = this.staff.find((staff) => {
        return staff._id === staffId;
      });
    },
    addTip() {
      if (!this.tipAmount || !this.selectedStaff) {
        // Throw error
        this.$toast.error('Amount and staff are required');
        return;
      }

      const staffLocks = [];

      this.staffOnTicket.forEach((staff) => {
        if (typeof staff.lockAmount === 'number') {
          if (staff.lockAmount < 0) {
            this.$toast.error('Lock amount cannot be negative');
            return;
          }

          staffLocks.push({
            staff,
            amount: staff.lockAmount,
          });
        }
      });

      this.$emit('submit', {
        amount:
          this.mode === 'dollar'
            ? +this.amount.toFixed(2)
            : +this.tipAmount.toFixed(2),
        staff: this.selectedStaff,
        staffLocks,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  padding: 32px;

  .checkbox {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .excludeCheckbox {
    margin-bottom: 16px;
  }

  .link {
    color: var(--clr-link);
    cursor: pointer;
  }
  .lock {
    margin-top: 16px;
  }

  &_staff,
  &_actions {
    margin-top: 32px;

    .checkbox {
      margin-top: 8px;
    }
  }

  &_amount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .subtitle {
      margin-top: 5px;
      font-size: 14px;
      color: var(--clr-gray);
    }

    &_type {
      display: flex;

      .selected {
        color: var(--clr-white) !important;
        background-color: var(--clr-link) !important;
      }

      .option {
        height: 45px;
        width: 45px;
        display: grid;
        place-items: center;
        color: var(--clr-gray);
        cursor: pointer;
        transition: all 0.2s;
        border: 1px solid var(--clr-light);

        &:first-child {
          border-right: none;
          border-radius: 5px 0 0 5px;
        }

        &:last-child {
          border-left: none;
          border-radius: 0 5px 5px 0;
        }

        &:hover {
          box-shadow: var(--shadow);
        }
      }
    }
  }

  &_actions {
    button {
      width: 100%;
    }
  }
}

.lockStaffOptions {
  margin-top: 16px;

  li {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
}
</style>
