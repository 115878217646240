<template>
  <section class="footer">
    <div class="container">
      <div class="footer_head">
        <h3>We're Raserva, your new favorite salon software.</h3>
        <p>
          The easiest way to manage your bookings, team members, services, and
          more. Raserva is your all-in-one salon software whether you are solo
          or working in a team. Manage payroll, online booking, accept payments
          and analyze your data with detailed reports, and so much more.
        </p>
      </div>
      <div class="footer_legal">
        <p>All Rights Reserved | Raserva 2022</p>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.footer {
  padding: 64px 16px;
  color: var(--clr-white);
  background-color: var(--clr-primary);

  .container {
    max-width: 1400px;
    margin: auto;
  }

  &_head {
    p {
      margin-top: 16px;
      color: var(--clr-light-2);
      line-height: 24px;
    }
  }
  &_legal {
    font-size: 12px;
    margin-top: 64px;
    text-align: center;
  }
}
</style>
