<template>
  <!-- <div class="promo">
    <div class="container">
      <p>
        🎉 Raserva release sale! Use coupon code "RELEASE33" for 33% off your
        ENTIRE FIRST YEAR!
      </p>
    </div>
  </div> -->
  <div class="promo">
    <div class="container">
      <p>
        🎉 Introducing Raserva, the all-in-one salon/spa management web
        application that will keep up with your fast paced environment.
      </p>
    </div>
  </div>
  <header class="header">
    <div class="container flex">
      <div class="header_logo">
        <router-link :to="{ name: 'Index' }">
          <TheLogo />
        </router-link>
      </div>
      <nav v-if="!activeUser" class="header_nav">
        <ul>
          <router-link :to="{ name: 'StaffRegister' }">
            <li>Sign up</li>
          </router-link>
          <router-link :to="{ name: 'StaffLogin' }">
            <li>Log in</li>
          </router-link>
          <router-link :to="{ name: 'Pricing' }">
            <li>Pricing</li>
          </router-link>
        </ul>
      </nav>
      <nav v-else class="header_nav">
        <Avatar
          :name="`${activeUser.firstName} ${activeUser.lastName}`"
          :image="activeUser.profileImage"
          @click="navToProfile"
        />
      </nav>
      <div
        class="header_mobile icon mobile"
        @click="showMobileMenu = !showMobileMenu"
      >
        <i class="fas fa-bars"></i>
      </div>
    </div>
  </header>

  <SidePanel :show="showMobileMenu" @close="showMobileMenu = false">
    <nav @click="showMobileMenu = false">
      <ul class="mobileMenu">
        <router-link :to="{ name: 'StaffRegister' }">
          <li>Sign up</li>
        </router-link>
        <router-link :to="{ name: 'StaffLogin' }">
          <li>Log in</li>
        </router-link>
        <router-link :to="{ name: 'Pricing' }">
          <li>Pricing</li>
        </router-link>
      </ul>
    </nav>
  </SidePanel>
</template>

<script>
export default {
  computed: {
    activeUser() {
      return this.$store.state.auth.user;
    },
  },
  data() {
    return {
      showMobileMenu: false,
    };
  },
  methods: {
    navToProfile() {
      this.$router.push({ name: 'StaffProfile' });
    },
  },
};
</script>

<style lang="scss" scoped>
.promo {
  text-align: center;
  font-weight: 700;
  color: var(--clr-white);
  background-color: var(--clr-secondary-dark);
  padding: 12px 0;
  letter-spacing: 0.8px;
}

.container {
  max-width: 1400px;
  margin: auto;
  padding: 0 16px;
}

.header {
  padding: 8px 24px;

  .container {
    min-height: 65px;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_logo {
    h1 {
      font-size: 24px;
      text-transform: uppercase;
      letter-spacing: 5px;
    }
  }

  &_nav {
    ul {
      display: flex;
      align-items: center;
      gap: 48px;

      li {
        cursor: pointer;
        transition: color 0.2s ease;

        &:hover {
          color: var(--clr-gray);
        }
      }
    }
  }
}

.mobile {
  display: none;
}

.mobileMenu {
  height: 100%;
  padding: 64px 0;

  .router-link-active {
    li {
      background-color: var(--clr-white-2) !important;
    }
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    i {
      color: var(--clr-secondary);
    }

    &:hover {
      background-color: var(--clr-white);
    }
  }
}

// Mobile
@media (max-width: 600px) {
  .mobile {
    display: block;
  }
  .header {
    &_nav {
      display: none;
    }
  }
}
</style>
