<template>
  <Modal
    :zIndex="999999999"
    title="Enter Staff Code"
    :show="true"
    @close="$emit('exit')"
  >
    <form @submit.prevent="submit" class="form">
      <BaseInput
        label="Account Code"
        inputType="password"
        @input="code = $event"
      />
      <div class="form_actions">
        <BaseButton>Submit</BaseButton>
      </div>
    </form>
  </Modal>
</template>

<script>
export default {
  emits: ['submitted', 'exit'],
  data() {
    return {
      code: null,
    };
  },
  methods: {
    async submit() {
      const staff = this.$store.state.staff.staff.find(
        (staff) => staff.accountCode === this.code
      );

      if (!staff) {
        this.$toast.error('Staff not found');
        return;
      }

      this.$emit('submitted', staff);
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  padding: 32px;

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
