<template>
  <section class="permissions">
    <div class="permissions_head">
      <h2>Permissions</h2>
      <p>
        Set the sections that are accessible to each user role. All members may
        access your calendar, and admin accounts have complete access.
      </p>
    </div>
    <div class="permissions_body">
      <div class="table" v-for="group in permissions" :key="group">
        <div class="table_titles">
          <div class="head">
            <p>{{ group.title }}</p>
          </div>
          <div class="head">
            <p>Basic</p>
          </div>
          <div class="head">
            <p>Low</p>
          </div>
          <div class="head">
            <p>Medium</p>
          </div>
          <div class="head">
            <p>High</p>
          </div>
        </div>
        <div
          class="table_rows"
          v-for="permission in group.permissions"
          :key="permission"
          :class="{ disabled: permission.disabled }"
        >
          <div class="section">
            <p>{{ permission.title }}</p>
          </div>
          <div
            class="section"
            @click="
              !permission.disabled
                ? (permission.basic = !permission.basic)
                : null
            "
          >
            <input
              type="checkbox"
              :checked="permission.basic"
              :disabled="permission.disabled"
            />
          </div>
          <div
            class="section"
            @click="
              !permission.disabled ? (permission.low = !permission.low) : null
            "
          >
            <input
              type="checkbox"
              :checked="permission.low"
              :disabled="permission.disabled"
            />
          </div>
          <div
            class="section"
            @click="
              !permission.disabled
                ? (permission.medium = !permission.medium)
                : null
            "
          >
            <input
              type="checkbox"
              :checked="permission.medium"
              :disabled="permission.disabled"
            />
          </div>
          <div
            class="section"
            @click="
              !permission.disabled ? (permission.high = !permission.high) : null
            "
          >
            <input
              type="checkbox"
              :checked="permission.high"
              :disabled="permission.disabled"
            />
          </div>
        </div>
      </div>
      <div class="actions">
        <BaseButton @click="save" :disabled="loading"
          ><i v-if="loading" class="fas fa-spinner"></i> Save</BaseButton
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  created() {
    this.init();
  },
  data() {
    return {
      loading: false,
      permissions: [],
    };
  },
  computed: {
    salon() {
      return this.$store.state.salon.salon;
    },
  },
  methods: {
    init() {
      if (!this.$store.state.salon.salon) {
        setTimeout(() => {
          this.init();
        });
        return;
      }

      let { _id, ...rest } = JSON.parse(
        JSON.stringify(this.$store.state.salon.salon.permissions)
      );
      this.permissions = rest;
      +_id++;
    },
    async save() {
      this.loading = true;

      try {
        await this.$store.dispatch('salon/updatePermissions', this.permissions);

        this.$toast.success('Salon permissions updated');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.permissions {
  &_head {
    h2 {
      font-size: 28px;
    }

    p {
      margin-top: 6px;
    }
  }

  &_body {
    margin-top: 32px;

    .table {
      width: 100%;
      border: 1px solid var(--clr-light);
      font-size: 14px;

      &:not(:first-child) {
        margin-top: 32px;
      }

      &_titles {
        font-weight: 700;
      }

      &_titles,
      &_rows {
        display: grid;
        grid-template-columns: repeat(5, 1fr);

        .head {
          padding: 12px 24px;
          border-right: 1px solid;
          border-color: var(--clr-light);

          &:last-child {
            border-right: none;
          }
        }
        .section {
          padding: 12px 24px;
          border-top: 1px solid;
          border-right: 1px solid;
          border-color: var(--clr-light);
          display: flex;
          align-items: center;

          &:not(:first-child) {
            justify-content: center;
          }

          &:last-child {
            border-right: none;
          }
        }
      }

      .disabled {
        background-color: var(--clr-extra-light);
        color: var(--clr-gray);
      }
    }
  }
  .actions {
    margin-top: 32px;
    text-align: right;
  }
}

// Tablet
@media (max-width: 900px) {
  .permissions {
    &_body {
      .table {
        width: 100%;
        border: 1px solid var(--clr-light);
        font-size: 12px;

        &:not(:first-child) {
          margin-top: 32px;
        }

        &_titles {
          font-weight: 700;

          .head {
            &:first-child {
              min-width: 100px;
            }
          }
        }
        &_rows {
          .section {
            &:first-child {
              min-width: 100px;
            }
          }
        }

        &_titles,
        &_rows {
          display: grid;
          grid-template-columns: repeat(5, 1fr);

          .head {
            padding: 4px 8px;
          }
          .section {
            padding: 4px 8px;
          }
        }
      }
    }
  }
}
</style>
