<template>
  <section class="payment">
    <div class="payment_head">
      <h2>Payment Settings</h2>
    </div>
    <div class="payment_body">
      <form @submit.prevent="submit" class="form">
        <div class="form_body">
          <BaseSelect
            label="Payment Processor"
            :value="newSettings.processor"
            @input="newSettings.processor = $event"
            :options="processorOptions"
          />

          <!-- Stripe -->
          <div v-if="newSettings.processor === 'stripe'" class="stripe">
            <div class="toggle">
              <Toggle
                :checked="newSettings.centralTerminal"
                @toggle="
                  newSettings.centralTerminal = !newSettings.centralTerminal
                "
              />
              <div class="toggle_text">
                <p class="toggle_text_label">Central Terminals</p>
                <p class="toggle_text_subtext">
                  With this option selected, all staff members will be connected
                  to the admin Stripe account while at this location.
                </p>
              </div>
            </div>
            <div v-if="newSettings.centralTerminal" class="account_select">
              <BaseSelect
                label="Stripe Account"
                :value="adminAccountId"
                @input="adminAccountId = $event"
                :options="stripeAccountOptions"
              />
            </div>
            <div v-if="newSettings.centralTerminal" class="account_select">
              <BaseSelect
                label="Location"
                :value="location"
                @input="location = $event"
                :options="locationOptions"
              />
            </div>
          </div>

          <!-- Card Connect -->
          <div
            v-if="newSettings.processor === 'cardconnect'"
            class="cardconnect"
          >
            <BaseInput
              label="Merchant ID"
              :value="cardconnect.mid"
              @input="cardconnect.mid = $event"
            />
            <BaseInput
              label="User"
              :value="cardconnect.user"
              @input="cardconnect.user = $event"
            />
            <BaseInput
              label="Password"
              inputType="password"
              :value="cardconnect.password"
              @input="cardconnect.password = $event"
            />
          </div>

          <!-- Charge Anywhere -->
          <div
            v-if="newSettings.processor === 'chargeanywhere'"
            class="chargeanywhere"
          >
            <BaseInput
              :inputType="showChargeAnywhereMID ? 'text' : 'password'"
              icon="fas fa-eye"
              @icon-click="showChargeAnywhereMID = !showChargeAnywhereMID"
              label="Merchant ID"
              :value="chargeanywhere.mid"
              @input="chargeanywhere.mid = $event"
            />
            <BaseInput
              label="Terminal ID"
              :value="chargeanywhere.tid"
              @input="chargeanywhere.tid = $event"
            />
            <BaseInput
              label="Identification"
              :value="chargeanywhere.identification"
              @input="chargeanywhere.identification = $event"
            />

            <!-- Additional terminals -->
            <BaseButton
              type="button"
              mode="primary-outline"
              @click="caAddTerminal"
              >Add Terminal</BaseButton
            >
            <ul class="additionalTerminals">
              <li
                v-for="(terminal, index) in chargeanywhere.additionalTerminals"
                :key="index"
              >
                <BaseInput
                  label="Terminal ID"
                  :value="terminal.tid"
                  @input="terminal.tid = $event"
                />
                <BaseInput
                  label="Identification"
                  :value="terminal.identification"
                  @input="terminal.identification = $event"
                />
                <BaseButton
                  type="button"
                  @click="chargeanywhere.additionalTerminals.splice(index, 1)"
                  mode="danger-outline"
                  >Remove Terminal</BaseButton
                >
              </li>
            </ul>

            <BaseInput
              label="Payment Form Merchant ID"
              :value="chargeanywhere.pfmid"
              @input="chargeanywhere.pfmid = $event"
            />
            <BaseInput
              label="Payment Form Terminal ID"
              :value="chargeanywhere.pftid"
              @input="chargeanywhere.pftid = $event"
            />
            <BaseInput
              label="Collect Percent Of Booking Up Front"
              subtitle="If value is 0, we will only collect card info in case of no show"
              inputType="number"
              :value="chargeanywhere.collectBookingPercentUpFront"
              @input="chargeanywhere.collectBookingPercentUpFront = $event"
            />
          </div>

          <!-- Dejavoo -->
          <div v-if="newSettings.processor === 'dejavoo'" class="dejavoo">
            <div class="form">
              <BaseInput
                label="Merchant ID"
                :value="dejavoo.merchantId"
                @input="dejavoo.merchantId = $event"
              />

              <BaseButton
                type="button"
                mode="primary-outline"
                @click="dejavooAddTerminal"
                >Add Terminal</BaseButton
              >

              <ul class="additionalTerminals">
                <li v-for="(terminal, index) in dejavoo.terminals" :key="index">
                  <BaseInput
                    label="Auth Key"
                    :value="terminal.authKey"
                    @input="terminal.authKey = $event"
                  />
                  <BaseInput
                    label="Register ID"
                    :value="terminal.registerID"
                    @input="terminal.registerID = $event"
                  />
                  <BaseButton
                    type="button"
                    @click="dejavoo.terminals.splice(index, 1)"
                    mode="danger-outline"
                    >Remove Terminal</BaseButton
                  >
                </li>
              </ul>

              <BaseSelect
                label="Receipt Option"
                :value="dejavoo.receiptOption"
                @input="dejavoo.receiptOption = $event"
                :options="dejavooReceiptOptions"
              />
            </div>
          </div>

          <!-- All -->
          <BaseInput
            label="Default Tax Rate (%)"
            :value="newSettings.defaultTaxRate"
            @input="newSettings.defaultTaxRate = +$event"
            :validators="validators.tax"
            subtitle="Individual items can be adjusted regardless of this value"
          />
          <div class="toggle">
            <Toggle
              :checked="newSettings.useCashDiscount"
              @toggle="
                newSettings.useCashDiscount = !newSettings.useCashDiscount
              "
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Enable Cash Discount</p>
            </div>
          </div>

          <!-- Cash Discount -->
          <div v-show="newSettings.useCashDiscount" class="cashDiscount">
            <BaseInput
              label="Non-cash Adjustment (%)"
              :value="newSettings.cashDiscountAdditionalProcessingFee"
              @input="newSettings.cashDiscountAdditionalProcessingFee = +$event"
              :validators="validators.tax"
            />

            <div class="toggle">
              <Toggle
                :checked="newSettings.displayCashPrices"
                @toggle="
                  newSettings.displayCashPrices =
                    !newSettings.displayCashPrices;
                  newSettings.displayCashPrices
                    ? (newSettings.displayDualPrices = false)
                    : null;
                "
              />
              <div class="toggle_text">
                <p class="toggle_text_label">Display Cash Prices On Booking</p>
              </div>
            </div>

            <div class="toggle">
              <Toggle
                :checked="newSettings.displayDualPrices"
                @toggle="
                  newSettings.displayDualPrices =
                    !newSettings.displayDualPrices;
                  newSettings.displayDualPrices
                    ? (newSettings.displayCashPrices = false)
                    : null;
                "
              />
              <div class="toggle_text">
                <p class="toggle_text_label">Display Dual Prices On Booking</p>
                <p class="toggle_text_subtext">
                  Will display cash and non-cash prices
                </p>
              </div>
            </div>
          </div>

          <!-- Auto completed -->
          <div class="toggle">
            <Toggle
              :checked="newSettings.autoCheckout"
              @toggle="newSettings.autoCheckout = !newSettings.autoCheckout"
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Auto-checkout</p>
              <p class="toggle_text_subtext">
                Automatically checkout a ticket if a payment is added and
                balance is zero
              </p>
            </div>
          </div>
        </div>
        <div class="form_actions">
          <BaseButton :disabled="!formValid || loading"
            ><i v-if="loading" class="fas fa-spinner"></i> Save</BaseButton
          >
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import BaseInput from '../../components/base/BaseInput.vue';

export default {
  created() {
    if (!this.salon) return;
    this.init();
  },
  computed: {
    processorOptions() {
      return [
        {
          option: 'Stripe',
          value: 'stripe',
        },
        {
          option: 'Card Connect',
          value: 'cardconnect',
        },
        {
          option: 'Charge Anywhere',
          value: 'chargeanywhere',
        },
        {
          option: 'Dejavoo',
          value: 'dejavoo',
        },
      ];
    },
    dejavooReceiptOptions() {
      return [
        {
          option: 'None',
          value: 'No',
        },
        {
          option: 'Merchant',
          value: 'Merchant',
        },
        {
          option: 'Customer',
          value: 'Customer',
        },
        {
          option: 'Both',
          value: 'Both',
        },
      ];
    },
    locationOptions() {
      const options = [];

      this.locations.forEach((location) => {
        options.push({
          option: location.display_name,
          value: location.id,
        });
      });

      return options;
    },
    salon() {
      return this.$store.state.salon.salon;
    },
    stripeAccountOptions() {
      const accounts = [];
      this.$store.state.auth.user.paymentProcessing.stripe.accounts.forEach(
        (account) => {
          accounts.push({
            option: account.id,
            value: account.id,
          });
        }
      );
      return accounts;
    },
    formValid() {
      if (
        (!this.newSettings.defaultTaxRate &&
          typeof this.newSettings.defaultTaxRate !== 'number') ||
        this.newSettings.defaultTaxRate < 0 ||
        this.newSettings.defaultTaxRate > 100
      )
        return false;
      return true;
    },
    validators() {
      return {
        tax: {
          required: true,
          min: 0,
          max: 100,
        },
      };
    },
  },
  watch: {
    salon() {
      if (this.newSettings.processor) return;

      this.init();
    },
  },
  data() {
    return {
      loading: false,

      newSettings: {
        centralTerminal: false,
        defaultTaxRate: 7,
        processor: '',
        useCashDiscount: false,
        cashDiscountAdditionalProcessingFee: 4,
        displayCashPrices: false,
        displayDualPrices: false,
        autoCheckout: false,
      },

      // Stripe
      adminAccountId: null,
      locations: [],
      location: null,

      // CC
      cardconnect: {
        mid: '',
        user: '',
        password: '',
      },

      // Charge Anywhere
      chargeanywhere: {
        mid: '',
        tid: '',
        identification: '',
        pfSecret: '',
        collectBookingPercentUpFront: 0,
        additionalTerminals: [],
      },
      showChargeAnywhereMID: false,

      // Dejavoo
      dejavoo: {
        merchantId: '',
        terminals: [],
        receiptOption: 'Both',
      },
    };
  },
  methods: {
    init() {
      if (!this.salon) return;
      this.newSettings = JSON.parse(JSON.stringify(this.salon.payments));

      // Stripe
      this.adminAccountId = this.salon.billing.stripe.adminAccountId;
      this.location = this.salon.billing.stripe.location;
      this.getStripeLocations();

      // Card Connect
      this.cardconnect = this.salon.billing.cardconnect || {};

      // Charge Anywhere
      this.chargeanywhere = this.salon.billing.chargeanywhere || {};

      // Dejavoo
      this.dejavoo = this.salon.billing.dejavoo || {};
    },

    async getStripeLocations() {
      if (!this.$store.state.auth.salon.billing.stripe.adminAccountId) return;

      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/location?accountId=${this.$store.state.auth.salon.billing.stripe.adminAccountId}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.locations = response.data.locations.data;
      } catch (error) {
        this.$toast.error(error.response.data.error);
      }
    },

    caAddTerminal() {
      if (!this.chargeanywhere.additionalTerminals) {
        this.chargeanywhere.additionalTerminals = [];
      }

      this.chargeanywhere.additionalTerminals.push({
        tid: '',
        identification: '',
      });
    },

    dejavooAddTerminal() {
      this.dejavoo.terminals.push({
        authKey: '',
        registerID: '',
      });
    },

    async submit() {
      this.loading = true;

      try {
        await this.$store.dispatch('salon/updatePayments', this.newSettings);

        // Stripe
        if (this.newSettings.processor === 'stripe') {
          await this.$store.dispatch('salon/updateStripeInfo', {
            accountId: this.adminAccountId,
            location: this.location,
          });
        }

        // Card Connect
        if (this.newSettings.processor === 'cardconnect') {
          await this.$store.dispatch(
            'salon/updateCardconnectInfo',
            this.cardconnect
          );
        }

        // Charge Anywhere
        if (this.newSettings.processor === 'chargeanywhere') {
          await this.$store.dispatch(
            'salon/updateChargeAnywhereInfo',
            this.chargeanywhere
          );
        }

        // Dejavoo
        if (this.newSettings.processor === 'dejavoo') {
          await this.$store.dispatch('salon/updateDejavooInfo', this.dejavoo);
        }

        this.$toast.success('Salon info updated');
      } catch (error) {
        this.$toast.error(error.message);
      }
      this.loading = false;
    },
  },
  components: { BaseInput },
};
</script>

<style lang="scss" scoped>
.payment {
  &_head {
    h2 {
      font-size: 28px;
    }

    p {
      margin-top: 6px;
    }
  }

  &_body {
    margin-top: 32px;

    .form {
      &_body {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .stripe,
        .cardconnect,
        .chargeanywhere,
        .valor {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        .toggle {
          display: flex;
          align-items: center;
          gap: 32px;
          flex-wrap: wrap;
          padding: 16px 0;

          &_text {
            &_label {
              font-size: 18px;
            }
            &_subtext {
              margin-top: 5px;
              font-size: 14px;
            }
          }
        }
      }

      .chargeanywhere,
      .dejavoo {
        .form {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        .additionalTerminals {
          display: flex;
          flex-direction: column;
          gap: 16px;

          li {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 16px;
            border: 1px solid var(--clr-light);
            border-radius: 5px;
          }
        }
      }

      &_actions {
        margin-top: 32px;
        display: flex;
        justify-content: flex-end;
        gap: 16px;
      }
    }
  }
}
</style>
