<template>
  <form
    @submit.prevent="
      selectedTerminal ? connectToTerminal() : (confirmNoCollection = true)
    "
    class="form"
  >
    <BaseSelect
      label="Terminal"
      :value="selectedTerminal"
      @input="selectedTerminal = $event"
      :options="terminalOptions"
    />
    <p v-if="selectedTerminal" class="default" @click="setDefaultTerminal">
      Set As Default Terminal
    </p>

    <BaseInput label="Amount" :value="amount" @input="total = +$event" />
    <BaseInput
      v-if="!selectedTerminal"
      label="Approval Code"
      @input="approval = $event"
    />

    <div
      class="additional"
      @click="showAdditionalOptions = !showAdditionalOptions"
    >
      <i v-if="!showAdditionalOptions" class="fas fa-caret-down"></i>
      <i v-else class="fas fa-caret-up"></i>
      <p>{{ showAdditionalOptions ? 'Hide' : 'Show' }} additional options</p>
    </div>

    <div v-if="showAdditionalOptions" class="additionalOptions">
      <div class="toggle">
        <Toggle
          :checked="confirmAmount"
          @toggle="confirmAmount = !confirmAmount"
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Confirm Amount</p>
          <p class="toggle_text_subtext">
            Prompt user to confirm transaction amount prior to prompting to
            insert/swipe/tap the card.
          </p>
        </div>
      </div>
      <div class="toggle">
        <Toggle :checked="force" @toggle="force = !force" />
        <div class="toggle_text">
          <p class="toggle_text_label">Force</p>
          <p class="toggle_text_subtext">
            Existing sessions will be destroyed and all in-flight operations on
            the terminal will be cancelled
          </p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="virtualTerminal"
          @toggle="virtualTerminal = !virtualTerminal"
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Use Virtual Terminal</p>
          <p class="toggle_text_subtext">
            Collect this payment method by entering in card data manually
          </p>
        </div>
      </div>
    </div>

    <CardConnectVirtTerm
      v-if="virtualTerminal"
      :preAmount="total"
      @submit="submit($event.retref)"
    />

    <div class="form_actions">
      <BaseButton
        v-if="loading"
        type="button"
        mode="danger-outline"
        @click="cancel"
        :disabled="cancelLoading"
      >
        <i v-if="cancelLoading" class="fas fa-spinner"></i>
        Cancel Charge</BaseButton
      >
      <BaseButton
        v-if="!virtualTerminal"
        :disabled="!total || (reader && !connected) || loading"
      >
        <i v-if="loading" class="fas fa-spinner"></i>
        Charge
      </BaseButton>
    </div>
  </form>

  <Confirm
    v-if="confirmNoCollection"
    zIndex="100000000001"
    title="Not collecting payment"
    text="Are you sure you wish to record this data without collecting payment?"
    @confirm="submit"
    @deny="confirmNoCollection = false"
  />
</template>

<script>
import BaseInput from '../base/BaseInput.vue';
import CardConnectVirtTerm from '@/views/virtTerm/CardConnectVirtTerm.vue';

export default {
  emits: ['submit'],
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      required: false,
    },
    tax: {
      type: Number,
      required: false,
    },
    client_email: {
      type: String,
      required: false,
    },
  },
  created() {
    this.init();
  },
  async unmounted() {
    if (!this.connected) return;

    if (this.loading) await this.cancel();

    await this.disconnectFromTerminal();
  },
  computed: {
    terminalOptions() {
      const terminals = [
        {
          option: 'No reader',
          value: '',
        },
      ];
      this.terminals.forEach((terminal) => {
        terminals.push({
          option: terminal,
          value: terminal,
        });
      });
      return terminals;
    },
  },
  data() {
    return {
      total: null,
      approval: '',
      loading: false,
      cancelLoading: false,
      confirmNoCollection: false,
      showAdditionalOptions: false,

      terminals: [],
      selectedTerminal: '',
      connected: false,
      session: null,
      force: false,
      confirmAmount: false,
      virtualTerminal: false,
    };
  },
  methods: {
    init() {
      this.total = +this.amount;
      this.getTerminals();
    },
    async getTerminals() {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/listTerminals?salonId=${this.$store.state.auth.salon._id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.terminals = response.data.terminals;

        const defaultTerminal = localStorage.getItem('ccDefaultTerminal');

        this.selectedTerminal = defaultTerminal
          ? defaultTerminal
          : this.terminals[0];
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          this.$toast.error(error.response.data.error);
        } else {
          this.$toast.error(error.message);
        }
      }
    },

    setDefaultTerminal() {
      localStorage.setItem('ccDefaultTerminal', this.selectedTerminal);
    },

    async connectToTerminal() {
      this.loading = true;

      if (this.connected && this.session) {
        this.sendAmountToTerminal();
        return;
      }

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/connect?merchantId=${this.$store.state.auth.salon.billing.cardconnect.mid}`,
          {
            hsn: this.selectedTerminal,
            force: this.force,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.session = response.data.session;
        this.connected = true;

        this.sendAmountToTerminal();
      } catch (error) {
        this.loading = false;

        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          this.$toast.error(error.response.data.error);
        } else {
          this.$toast.error(error.message);
        }
      }
    },

    async disconnectFromTerminal() {
      try {
        await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/disconnect?merchantId=${this.$store.state.auth.salon.billing.cardconnect.mid}`,
          {
            hsn: this.selectedTerminal,
            session: this.session,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          this.$toast.error(error.response.data.error);
        } else {
          this.$toast.error(error.message);
        }
      }
    },

    async cancel() {
      this.cancelLoading = true;

      try {
        await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/cancel?merchantId=${this.$store.state.auth.salon.billing.cardconnect.mid}`,
          {
            hsn: this.selectedTerminal,
            session: this.session,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.loading = false;
      } catch (error) {
        this.$toast.error(error.response.data.error);
      }

      this.cancelLoading = false;
    },

    async sendAmountToTerminal() {
      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/cardconnect/authCard?merchantId=${this.$store.state.auth.salon.billing.cardconnect.mid}`,
          {
            hsn: this.selectedTerminal,
            amount: +(+this.total.toFixed(2) * 100).toFixed(0),
            session: this.session,
            confirmAmount: this.confirmAmount,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        const data = response.data.data;

        if (data.respstat === 'A') {
          this.approval = data.token.substr(data.token.length - 4);
          this.submit(data.retref);
        } else if (data.respstat === 'B') {
          this.$toast.warning('Retry');
        } else if (data.respstat === 'C') {
          this.$toast.error('Declined');
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          this.$toast.error(error.response.data.error);
        } else {
          this.$toast.error(error.message);
        }
      }

      this.loading = false;
    },

    submit(ref) {
      this.$emit('submit', {
        ref,
        amount: +this.total.toFixed(2),
        approval: this.approval,
      });
    },
  },
  components: { BaseInput, CardConnectVirtTerm },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .default {
    color: var(--clr-link);
    cursor: pointer;
    font-size: 12px;
    margin-top: -10px;
  }

  .additional {
    display: flex;
    gap: 16px;
    align-items: center;
    color: var(--clr-link);
    cursor: pointer;
  }

  .toggle {
    display: flex;
    align-items: center;
    gap: 32px;
    flex-wrap: wrap;
    padding: 16px 0;

    &_text {
      &_label {
        font-size: 18px;
      }
      &_subtext {
        margin-top: 5px;
        font-size: 14px;
      }
    }
  }

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
