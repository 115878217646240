<template>
  <section class="ticket">
    <!-- Salon -->
    <div class="section">
      <h1>Salon</h1>

      <div class="section_data">
        <p><span class="bold">ID: </span>{{ ticket.salonId._id }}</p>
        <p>
          <span class="bold">Name: </span
          >{{
            ticket.salonId.details.altName
              ? `${ticket.salonId.details.shopName} ${ticket.salonId.details.altName}`
              : ticket.salonId.details.shopName
          }}
        </p>
      </div>
    </div>

    <!-- General -->
    <div class="section">
      <h1>General</h1>

      <div class="section_data">
        <p><span class="bold">ID: </span>{{ ticket._id }}</p>
        <p><span class="bold">Created: </span>{{ creationDate }}</p>
        <p><span class="bold">Last Updated: </span>{{ updatedDate }}</p>
        <p v-if="ticket.checkout_at">
          <span class="bold">Checkout: </span>{{ checkoutDate }}
        </p>
        <p><span class="bold">Subtotal: </span>${{ subtotal.toFixed(2) }}</p>
        <p>
          <span class="bold">Cash Adjustment Fee: </span>${{
            cardSurchage.toFixed(2)
          }}
        </p>
        <p><span class="bold">Tax: </span>${{ tax.toFixed(2) }}</p>
        <p><span class="bold">Total: </span>${{ total.toFixed(2) }}</p>
      </div>
    </div>

    <!-- Client -->
    <div v-if="ticket.client" class="section">
      <h1>Client</h1>

      <div class="section_data">
        <p>
          <span class="bold">Client: </span
          >{{ `${ticket.client.firstName} ${ticket.client.lastName}` }}
        </p>
        <p>
          <span class="bold">Client Phone Number: </span
          >{{ `${ticket.client.phoneNumber}` }}
        </p>
        <p v-if="ticket.client.email">
          <span class="bold">Client Email: </span>{{ `${ticket.client.email}` }}
        </p>
      </div>
    </div>

    <!-- Items -->
    <div v-if="ticket.items.length" class="section">
      <h1>Items</h1>

      <div class="section_data">
        <p
          v-for="item in ticket.items"
          :key="item._id"
          :class="{ 'text-red': item.quantity < 0 }"
        >
          <span class="bold">Item: </span
          >{{
            `${item.item.title} (${item.quantity}) - $${item.item.price.toFixed(
              2
            )}`
          }}
        </p>
      </div>
    </div>

    <!-- Payments -->
    <div v-if="ticket.payments.length" class="section">
      <h1>Payments</h1>

      <div class="section_data">
        <p
          v-for="payment in ticket.payments"
          :key="payment._id"
          :class="{ 'text-red': payment.status !== 'default' }"
        >
          <i @click="selectedPayment = payment" class="fas fa-edit"></i>

          <!-- Card Payment -->
          <span v-if="payment.type === 'card'">
            <span class="bold">Card Payment: </span
            >{{
              `$${payment.amount.toFixed(2)} (${
                payment.processor || 'No processor'
              })`
            }}
            <span v-if="payment.ref">[{{ payment.ref }}]</span>
          </span>

          <!-- All Other Payment Types -->
          <span v-else
            >{{
              payment.type.charAt(0).toUpperCase() + payment.type.slice(1)
            }}
            Payment: ${{ payment.amount.toFixed(2) }}</span
          >
        </p>
      </div>
    </div>
  </section>

  <Modal
    title="Selected Payment"
    :show="selectedPayment"
    @close="selectedPayment = null"
  >
    <div class="selectedPayment">
      <form @submit.prevent="updateTicket" class="form">
        <BaseSelect
          label="Type"
          :options="paymentTypeOptions"
          :value="selectedPayment.type"
          @input="selectedPayment.type = $event"
        />

        <BaseSelect
          label="Status"
          :options="paymentStatusOptions"
          :value="selectedPayment.status"
          @input="selectedPayment.status = $event"
        />

        <BaseInput
          label="Amount"
          inputType="number"
          :value="selectedPayment.amount"
          @input="selectedPayment.amount = $event"
        />

        <BaseSelect
          v-if="selectedPayment.type === 'card'"
          label="Processor"
          :options="paymentProcessorOptions"
          :value="selectedPayment.processor"
          @input="selectedPayment.processor = $event"
        />

        <BaseInput
          v-if="selectedPayment.type === 'card'"
          subtitle="Be careful updating this as it is a reference to the payment processor"
          label="Reference"
          :value="selectedPayment.ref"
          @input="selectedPayment.ref = $event"
        />

        <BaseInput
          v-if="selectedPayment.token"
          label="Token"
          :value="selectedPayment.token"
          disabled
        />

        <div class="form_actions">
          <BaseButton>Save</BaseButton>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script>
export default {
  props: {
    propTicket: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.ticket = JSON.parse(JSON.stringify(this.propTicket));
  },
  computed: {
    paymentTypeOptions() {
      return [
        {
          option: 'Card',
          value: 'card',
        },
        {
          option: 'Cash',
          value: 'cash',
        },
        {
          option: 'Other',
          value: 'other',
        },
      ];
    },
    paymentStatusOptions() {
      return [
        {
          option: 'Default',
          value: 'default',
        },
        {
          option: 'Refunded',
          value: 'refunded',
        },
      ];
    },
    paymentProcessorOptions() {
      return [
        {
          option: 'None',
          value: null,
        },
        {
          option: 'Stripe',
          value: 'stripe',
        },
        {
          option: 'Dejavoo',
          value: 'dejavoo',
        },
        {
          option: 'Charge Anywhere',
          value: 'chargeanywhere',
        },
        {
          option: 'Card Connect',
          value: 'cardconnect',
        },
      ];
    },
    creationDate() {
      return this.$moment(this.ticket.created_at).format('LLL');
    },
    updatedDate() {
      return this.$moment(this.ticket.updated_at).format('LLL');
    },
    checkoutDate() {
      return this.$moment(this.ticket.checkout_at).format('LLL');
    },
    subtotal() {
      if (!this.ticket.items.length) {
        return 0;
      }

      let total = 0;

      this.ticket.items.forEach((item) => {
        if (item.item.prepaid) return;

        if (item.item.basePrice) {
          total +=
            (item.item.basePrice - (item.item.discountAmount || 0)) *
            item.quantity *
            (item.item.priceType === 'duration' ? item.item.duration : 1);
        } else {
          total +=
            (item.item.price -
              (item.item.discountAmount || 0) -
              (item.item.cashDiscountAdditionalProcessingFee || 0)) *
            item.quantity *
            (item.item.priceType === 'duration' ? item.item.duration : 1);
        }
      });

      return total;
    },
    cardSurchage() {
      let total = 0;

      this.ticket.items.forEach((item) => {
        if (item.item.prepaid) return;

        total +=
          (item.item.cashDiscountAdditionalProcessingFee || 0) * item.quantity;
      });

      return total;
    },
    tax() {
      if (!this.ticket.items.length) {
        return 0;
      }

      let tax = 0;
      this.ticket.items.forEach((item) => {
        if (item.item.prepaid) return;

        tax +=
          (item.item.price - (item.item.discountAmount || 0)) *
          (item.item.taxRate * 0.01) *
          item.quantity;
      });

      return tax;
    },
    total() {
      // We have to add this small amount because 0.075 rounded in Javascript is 0.07 (like wtf dude)
      return this.subtotal + this.tax + this.cardSurchage + 0.000000001;
    },
  },
  data() {
    return {
      ticket: null,
      selectedPayment: null,
    };
  },
  methods: {
    async updateTicket() {
      try {
        await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin/salons/tickets/${this.ticket._id}`,
          this.ticket
        );

        this.selectedPayment = null;
        this.$toast.success('Ticket updated successfully');
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: 700;
}
.text-red {
  color: var(--clr-danger);
}
.ticket {
  display: flex;
  flex-direction: column;
  gap: 16px;

  h1 {
    font-size: 18px;
  }
  .section {
    &_data {
      margin-top: 16px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
      padding: 16px;

      p {
        i {
          margin-right: 16px;
          color: var(--clr-link);
          cursor: pointer;
        }

        &:not(:first-child) {
          margin-top: 8px;
        }
      }
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}

.selectedPayment {
  padding: 32px;
}
</style>
