<template>
  <section class="order">
    <div class="order_head">
      <p class="small light">{{ formatDate(order.created_at) }}</p>
      <h2 class="mt-s">
        {{ `${order.client.firstName} ${order.client.lastName}` }}
      </h2>
    </div>
    <ul class="order_body">
      <li v-for="item in order.items" :key="item._id">
        <div class="col">
          <div v-if="item.item.image" class="image">
            <img :src="item.item.image" alt="Product Image" />
          </div>
          <div v-else class="image-none">
            <div class="text">
              <i class="fas fa-ban"></i>
              <p>No Image</p>
            </div>
          </div>
        </div>
        <div class="col">
          <p>{{ item.item.title }}</p>
          <p class="small light mt-s">${{ item.item.price.toFixed(2) }}</p>
        </div>
      </li>
    </ul>
    <form @submit.prevent="editOrder" class="order_actions form">
      <BaseSelect
        :options="statusOptions"
        :value="status"
        @input="status = $event"
      />
      <div class="form_actions">
        <BaseButton
          @click="showConfirmDelete = true"
          mode="danger-outline"
          type="button"
          :disabled="deleteLoading"
          ><i v-if="deleteLoading" class="fas fa-spinner"></i> Delete
          Order</BaseButton
        >
        <BaseButton :disabled="loading"
          ><i v-if="loading" class="fas fa-spinner"></i> Submit</BaseButton
        >
      </div>
    </form>
  </section>

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Delete order"
    text="Are you sure you wish to delete this order? This action can not be undone."
    @confirm="deleteOrder"
    @deny="showConfirmDelete = false"
  />
</template>

<script>
export default {
  emits: ['edited', 'deleted'],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.status = this.order.status;
  },
  computed: {
    statusOptions() {
      return [
        {
          option: 'Collected',
          value: 'collected',
        },
        {
          option: 'Not Collected',
          value: 'not-collected',
        },
        {
          option: 'Returned',
          value: 'returned',
        },
      ];
    },
  },
  data() {
    return {
      loading: false,
      status: '',
      showConfirmDelete: false,
    };
  },
  methods: {
    async editOrder() {
      this.loading = true;

      try {
        const response = await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/orders/${this.order._id}`,
          {
            status: this.status,
          }
        );

        this.$emit('edited', response.data.order);
        this.$toast.success('Order edited');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    async deleteOrder() {
      try {
        await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/orders/${this.order._id}`
        );

        this.$emit('deleted');
        this.$toast.success('Order deleted');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    formatDate(date) {
      return this.$moment(date).format('LLL');
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  .small {
    font-size: 14px;
  }
  .light {
    color: var(--clr-gray);
  }
  .mt-s {
    margin-top: 6px;
  }

  &_body,
  &_actions {
    margin-top: 32px;
  }

  &_body {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 32px;

    li {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      padding: 16px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;

      .image {
        min-height: 100px;
        width: 100px;
        min-width: 100px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .image-none {
        background-color: var(--clr-light);
        min-height: 100px;
        width: 100px;
        min-width: 100px;
        display: grid;
        place-items: center;
        text-align: center;
        color: var(--clr-gray);

        i {
          font-size: 44px;
          margin-bottom: 12px;
        }
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_actions {
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }
  }
}
</style>
