<template>
  <section class="logs">
    <div v-if="logs.length" class="logs_logs">
      <div class="log" v-for="log in logs" :key="log._id">
        <div class="note">{{ log.note }}</div>
        <div class="author">{{ log.staff }}</div>
        <div class="date">{{ formatDate(log.date) }}</div>
      </div>
    </div>
    <div v-else class="none">
      <h1>This client has no formulas!</h1>
      <p>Get started by entering a formula below.</p>
    </div>
    <div class="create">
      <form @submit.prevent="addNote" class="form">
        <BaseTextarea
          label="Formula"
          @input="newNote = $event"
          :value="newNote"
          :validators="validators.note"
        />
        <div class="form_actions">
          <BaseButton>Add Formula</BaseButton>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  emits: ['addNote'],
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.init();
  },
  computed: {
    logs() {
      if (!this.newClient || !this.newClient.formulas) return [];

      return this.newClient.formulas
        .filter(
          (note) =>
            note.salonId.toString() ===
            this.$store.state.auth.salon._id.toString()
        )
        .reverse();
    },
    validators() {
      return {
        note: {
          maxLength: 1000,
        },
      };
    },
  },
  data() {
    return {
      newClient: null,
      newNote: '',
    };
  },
  methods: {
    async init() {
      this.newClient = await this.$store.dispatch(
        'clients/getClientById',
        this.client._id
      );
    },

    async addNote() {
      if (!this.newNote) return;

      if (this.newNote.length > 1000) {
        this.$toast.error('Note length can not exceed 1000');
        return;
      }

      const note = {
        salonId: this.$store.state.auth.salon._id,
        staff: `${this.$store.state.auth.user.firstName} ${this.$store.state.auth.user.lastName}`,
        note: this.newNote,
      };

      this.newClient.formulas.push(note);

      this.$emit('addNote', note);

      this.newNote = '';

      await this.$store.dispatch('clients/editClient', this.newClient);
    },
    formatDate(date) {
      return this.$moment(date).format('LLL');
    },
  },
};
</script>

<style lang="scss" scoped>
.logs {
  &_logs,
  .none {
    margin-bottom: 32px;
  }

  .none {
    text-align: center;

    p {
      font-size: 14px;
      margin-top: 8px;
    }
  }

  &_logs {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .log {
      &:not(:first-child) {
        margin-top: 16px;
      }

      .note {
        padding: 16px;
        border-radius: 5px;
        background-color: var(--clr-white);
        border: 1px solid var(--clr-light);
        margin-bottom: 8px;
      }

      .author,
      .date {
        text-align: right;
        font-size: 12px;
      }

      .date {
        color: var(--clr-gray);
      }
    }
  }

  .form {
    border-top: 1px solid var(--clr-light);
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_actions {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }
  }
}
</style>
