<template>
  <section v-if="discount" class="offer">
    <div class="offer_head">
      <h1>
        WOULD YOU LIKE <br />
        <span class="h1-large"
          ><span class="h1-color"
            >{{ discount.amount
            }}{{ discount.type === 'fixed' ? '$' : '%' }}</span
          >
          OFF</span
        >
      </h1>

      <!-- <h1>
        Would you like <br />
        <span>10%</span> off
      </h1> -->

      <h2>this booking?</h2>
    </div>

    <div v-if="offer.message" class="offer_body">
      <p>{{ offer.message }}</p>
    </div>

    <div class="offer_hr">
      <hr />
    </div>

    <div class="offer_actions">
      <div @click="$emit('yes')" class="button button-yes">
        <span class="button-text-large">YES</span>
        <p>
          get my {{ discount.amount
          }}{{ discount.type === 'fixed' ? '$' : '%' }} off
        </p>
      </div>
      <div @click="$emit('no')" class="button button-no">
        <span class="button-text-large">NO</span>
        <p>
          I don't want to <br />
          save money
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  emits: ['yes', 'no'],
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    discount() {
      return this.$store.state.booking.salon.discounts.find((d) => {
        return d._id === this.offer.discount;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.offer {
  border: 1px solid var(--clr-light-2);
  border-radius: 5px;
  padding: 32px;

  &_head {
    text-align: center;

    h1,
    h2 {
      font-weight: 400;
    }

    h1 {
      font-size: 48px;
      letter-spacing: 3px;

      .h1-color {
        color: var(--clr-secondary);
      }
      .h1-large {
        font-size: 72px;
      }
    }
    h2 {
      margin-top: 8px;
      font-size: 28px;
    }
  }

  &_body {
    margin-top: 32px;
    text-align: center;
  }

  &_hr {
    margin: 32px auto;
    max-width: 80%;

    hr {
      overflow: visible; /* For IE */
      padding: 0;
      border: none;
      border-top: medium double #333;
      color: #333;
      text-align: center;
    }
    hr:after {
      content: '§';
      display: inline-block;
      position: relative;
      top: -0.7em;
      font-size: 1.5em;
      padding: 0 0.25em;
      background: white;
    }
  }

  &_actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;

    .button {
      padding: 16px 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        transform: translateY(-2px);
        box-shadow: var(--shadow);
      }

      span {
        font-size: 48px;
        font-weight: 700;
        letter-spacing: 2px;
      }

      &-yes {
        background-color: var(--clr-secondary);
        color: var(--clr-white);
      }
      &-no {
        background-color: var(--clr-light-2);
        color: var(--clr-white);
      }
    }
  }
}

// Mobile
@media (max-width: 600px) {
  .offer {
    &_head {
      h1 {
        font-size: 28px;
        letter-spacing: 1px;

        .h1-color {
          color: var(--clr-secondary);
        }
        .h1-large {
          font-size: 48px;
        }
      }
      h2 {
        margin-top: 8px;
        font-size: 22px;
      }
    }

    &_hr {
      margin-bottom: 16px;
    }

    &_actions {
      grid-template-columns: 1fr;
      gap: 16px;
    }
  }
}
</style>
