<template>
  <div class="tickets">
    <div class="tickets_techs">
      <div
        v-for="staff in newStaff"
        :key="staff._id"
        class="tickets_techs_tech"
      >
        <div class="tech_tickets">
          <div class="techCard">
            <Avatar
              :mode="
                staff.available
                  ? 'large rounded border border-success'
                  : 'large rounded border border-danger'
              "
              :name="staff.firstName"
              :image="staff.profileImage"
              @click="$emit('selectStaff', staff)"
            />
            <div class="text">
              <p class="name bold">
                {{ `${staff.firstName} ${staff.lastName[0]}` }}
              </p>
              <div class="small light">Turns: {{ getTurns(staff) }}</div>
              <p v-if="staff.additionalTurns" class="extra-small light">
                {{ staff.additionalTurns }} add. turns
              </p>
            </div>
          </div>
          <ClassicTicketCard
            v-for="ticket in staff.tickets"
            :key="ticket._id"
            @click="selectedTicket = ticket"
            :ticket="ticket"
            :staffId="staff.staffId"
          />
          <div @click="$emit('new', staff)" class="add">
            <i class="fas fa-plus"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal
    title="Ticket options"
    :show="selectedTicket"
    @close="selectedTicket = null"
  >
    <div class="selectedTicket">
      <DetailedTicketCard
        :ticket="selectedTicket"
        classicView
        :showEditTurns="canEditTurns"
        @selectTicket="$emit('selectTicket', $event)"
        @deselect="selectedTicket = null"
      />

      <div class="moreOptions">
        <!-- Turns -->
        <ul v-if="canEditTurns" class="turns">
          <li v-for="staff in selectedTicketUniqueStaff" :key="staff._id">
            <BaseInput
              :label="`${staff.firstName} ${staff.lastName[0]} - Total Turns`"
              :placeholder="staff.totalTurns"
              type="number"
              @blur="updateStaffTurns(staff, +$event.target.value)"
            />
          </li>
        </ul>

        <BaseTextarea
          label="Note"
          :value="selectedTicket.note"
          @input="selectedTicket.note = $event"
          @blur="updateTicket(selectedTicket)"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import ClassicTicketCard from '@/views/classic/ClassicTicketCard.vue';
import DetailedTicketCard from '@/components/dashboard/DetailedTicketCard.vue';

export default {
  components: {
    ClassicTicketCard,
    DetailedTicketCard,
  },
  emits: ['new', 'selectTicket', 'selectStaff'],
  props: {
    staffs: {
      type: Array,
      required: true,
    },
    tickets: {
      type: Array,
      required: true,
    },
  },
  computed: {
    selectedTicketUniqueStaff() {
      if (!this.selectedTicket) return [];

      const staffs = [];

      this.selectedTicket.items.forEach((item) => {
        if (!item.staff) return;

        const preStaff = staffs.find((s) => s.staffId === item.staff.staffId);

        if (!preStaff) {
          staffs.push({ ...item.staff, totalTurns: item.item.turns });
        } else {
          if (!preStaff.totalTurns) preStaff.totalTurns = 0;
          preStaff.totalTurns += item.item.turns;
        }
      });

      return staffs;
    },
    managerMode() {
      return this.$store.state.auth.managerMode;
    },
    tempUser() {
      return this.$store.state.auth.tempUser;
    },
  },
  data() {
    return {
      newStaff: [],
      selectedTicket: null,
      canEditTurns: false,
    };
  },
  watch: {
    tickets() {
      this.init();
    },
    staffs() {
      this.init();
    },
    managerMode() {
      this.init();
    },
    tempUser() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.newStaff = JSON.parse(JSON.stringify(this.staffs.filter((s) => s)));

      this.newStaff.forEach((staff) => (staff.tickets = []));

      this.tickets.forEach((ticket) => {
        ticket.items.forEach((item) => {
          if (item.item && item.staff) {
            const staff = this.newStaff.find((staff) => {
              return staff.staffId === item.staff.staffId;
            });
            if (staff) {
              staff.tickets = staff.tickets || [];

              const firstItem = ticket.items.find((i) => {
                if (!i.staff) return false;

                return i.staff.staffId === staff.staffId;
              });

              if (!staff.tickets.find((t) => t._id === ticket._id)) {
                staff.tickets.push({
                  ...ticket,
                  firstItem: firstItem ? firstItem.item.title : null,
                  requested: firstItem ? firstItem.item.requested : false,
                });
              }
            }
          }
        });
      });

      this.canEditTurns = await this.$store.dispatch(
        'auth/activeUserHasPermission',
        'tickets/editTurnsOnItems'
      );
    },

    selectTicket(ticket) {
      if (ticket.status !== 'completed' && ticket.status !== 'refunded')
        this.$emit('selectTicket', ticket);
    },

    async updateTicket(ticket) {
      try {
        await this.$store.dispatch('tickets/editTicket', ticket);
      } catch (error) {
        console.log(error);
      }
    },

    getTurns(staff) {
      let turns = 0;

      this.tickets.forEach((ticket) => {
        ticket.items.forEach((item) => {
          if (item.staff && item.staff.staffId === staff.staffId) {
            turns += item.item.turns || 0;
          }
        });
      });

      return (turns += staff.additionalTurns || 0);
    },

    updateStaffTurns(staff, turns) {
      if (typeof turns !== 'number') return;
      const items = [];

      this.selectedTicket.items.forEach((item) => {
        if (!item.staff) return;

        if (item.staff.staffId === staff.staffId) {
          items.push(item);
        }
      });

      items.forEach((item) => (item.item.turns = turns / items.length));

      this.updateTicket(this.selectedTicket);
    },
  },
};
</script>

<style lang="scss" scoped>
.extra-small {
  font-size: 12px;
}
.small {
  font-size: 14px;
}
.light {
  color: var(--clr-gray);
}
.bold {
  font-weight: 700;
}
.tickets {
  padding: 32px;
  overflow-x: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &_techs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-x: auto;
    width: 100vw;

    &_tech {
      display: flex;
      // align-items: center;
      gap: 32px;
      .techCard {
        display: flex;
        align-items: center;
        gap: 16px;
        overflow: hidden;
        width: 175px;
        // background-color: var(--clr-white);
        // border-radius: 5px;
      }

      .tech_tickets {
        display: flex;
        align-items: center;
        gap: 16px;

        &_ticket {
          text-align: center;
          background-color: var(--clr-white);
          padding: 16px;
          border-radius: 5px;
          box-shadow: var(--shadow);
          border-left: 5px solid var(--clr-success);
          cursor: pointer;
        }

        .add {
          margin-left: 16px;
          height: 35px;
          width: 35px;
          font-size: 14px;
          border-radius: 50%;
          background-color: var(--clr-light-2);
          color: var(--clr-light);
          display: grid;
          place-items: center;
          cursor: pointer;
        }
      }
    }
  }
}

.selectedTicket {
  padding: 16px;

  .moreOptions {
    margin-top: 32px;

    .turns {
      margin-bottom: 32px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
    }
  }
}
</style>
