<template>
  <div class="endpoint_head">
    <h2>Update admin settings</h2>

    <div class="route">
      <i
        @click="
          $router.push('/documentation/api/settings#updateAdminSettings');
          $toast.info('Set URL to endpoint');
        "
        :style="{ color: 'var(--clr-secondary)', cursor: 'pointer' }"
        class="fas fa-hashtag"
      ></i>
      <p class="tag tag-blue">PUT</p>
      <p class="url">{{ apiUrl }}<span>/salons/:id/settings/admin</span></p>
    </div>

    <div class="description">
      <p>Update a salons admin settings</p>
    </div>
  </div>

  <div class="endpoint_body">
    <div class="request">
      <h3>Request</h3>

      <!-- Headers -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Headers</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">Authorization</p>
              <p class="examples">Examples: <span>Bearer {Token}</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Path params -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Path Parameters</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">ID</p>
              <p class="description">ID of salon</p>
              <p class="examples">
                Examples: <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6f</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
          <p>application/json</p>
        </div>

        <ul>
          <!-- manager code -->
          <li>
            <div class="col value">
              <p class="bold">managerCode</p>
              <p class="description">
                String - A code that is entered to access most features from any
                staff account
              </p>
              <p class="examples">Examples: <span>adminPassword</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- useRequestedServiceTurnsValue -->
          <li>
            <div class="col value">
              <p class="bold">useRequestedServiceTurnsValue</p>
              <p class="description">
                Boolean - If true, the "requestedServiceTurnsValue" field value
                will be used instead of the services turns value
              </p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- requestedServiceTurnsValue -->
          <li>
            <div class="col value">
              <p class="bold">requestedServiceTurnsValue</p>
              <p class="description">
                Number - Turn value of requested services if
                "useRequestedServiceTurnsValue" is true
              </p>
              <p class="examples">Examples: <span>1</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- enableAlerts -->
          <li>
            <div class="col value">
              <p class="bold">enableAlerts</p>
              <p class="description">
                Boolean - If true, you can send alerts to all staff members
              </p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- alertMessage -->
          <li>
            <div class="col value">
              <p class="bold">alertMessage</p>
              <p class="description">
                String - Message to send to staff when alerted
              </p>
              <p class="examples">
                Examples: <span>'You are being alerted!'</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- takeFeeFirstForCommission -->
          <li>
            <div class="col value">
              <p class="bold">takeFeeFirstForCommission</p>
              <p class="description">
                Boolean - Take off fee first when calculating commission
                (typically results in higher pay-out to staff)
              </p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- defaultTrackHourly -->
          <li>
            <div class="col value">
              <p class="bold">defaultTrackHourly</p>
              <p class="description">
                Boolean - Sets the value of the "trackHourly" field for new
                staff members form (You will perform this logic on your
                frontend)
              </p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- recalculateTicketAtCreation -->
          <li>
            <div class="col value">
              <p class="bold">recalculateTicketAtCreation</p>
              <p class="description">
                Boolean - Checks for new fees on items, or staff commission
                values, at checkout and recalculates the ticket
              </p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- defaultCalendarFilter -->
          <li>
            <div class="col value">
              <p class="bold">defaultCalendarFilter</p>
              <p class="description">
                String - Default staff filter for front-end calendar
              </p>
              <p class="examples">
                Examples:
                <span>'Working Staff', 'Scheduled Staff', 'All Staff'</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- defaultMainScreen -->
          <li>
            <div class="col value">
              <p class="bold">defaultMainScreen</p>
              <p class="description">
                String - Default home screen for front-end
              </p>
              <p class="examples">
                Examples: <span>'calendar', 'classic'</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- overtimeEnabled -->
          <li>
            <div class="col value">
              <p class="bold">Boolean - overtimeEnabled</p>
              <p class="description">Enable overtime for staff members</p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- overtimeStart -->
          <li>
            <div class="col value">
              <p class="bold">overtimeStart</p>
              <p class="description">
                Number - When to start the calculation for overtime (in hours -
                default 40)
              </p>
              <p class="examples">Examples: <span>40</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- overtimeRate -->
          <li>
            <div class="col value">
              <p class="bold">overtimeRate</p>
              <p class="description">
                Number - Rate to pay staff members for overtime (default 1.5)
              </p>
              <p class="examples">Examples: <span>1.5</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- enablePackageEmails -->
          <li>
            <div class="col value">
              <p class="bold">enablePackageEmails</p>
              <p class="description">
                Boolean - Send an email to client when a package is purchased,
                or used
              </p>
              <p class="examples">Examples: <span>false</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="response">
      <h3>Response</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">adminSettings</p>
              <p class="examples">
                Examples:
                <span
                  >{ "managerCode": "1234", "useRequestedServiceTurnsValue":
                  true, "requestedServiceTurnsValue": 0, "enableAlerts": true,
                  "alertMessage": "Alerted!", "takeFeeFirstForCommission":
                  false, "defaultTrackHourly": true,
                  "recalculateTicketAtCreation": true, "defaultCalendarFilter":
                  "All Staff", "defaultMainScreen": "calendar",
                  "overtimeEnabled": true, "overtimeStart": 40, "overtimeRate":
                  1.5, "enablePackageEmails": true }</span
                >
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    apiUrl() {
      return process.env.VUE_APP_RASERVA_BACKEND;
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoint {
  .tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px;
    border-radius: 5px;
    color: white;

    &-blue {
      background-color: var(--clr-secondary);
    }
  }

  &_head {
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }

    .route {
      padding: 8px 16px;
      border-radius: 5px;
      background-color: var(--clr-white-2);
      display: flex;
      align-items: center;
      gap: 16px;

      .url {
        color: var(--clr-gray);

        span {
          color: var(--clr-primary);
          font-weight: 700;
        }
      }
    }

    .description {
      margin-top: 32px;
    }
  }

  &_body {
    .request,
    .response,
    .reqResExamples {
      margin-top: 32px;
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &_section {
      &:not(:first-child) {
        margin-top: 32px;
      }

      .head {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 20px;
          margin-bottom: 16px;
          color: var(--clr-secondary);
        }

        p {
          font-size: 14px;
          color: var(--clr-gray);
        }
      }

      ul {
        .subitem {
          margin-left: 32px;
        }

        li {
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          &:not(:first-child) {
            margin-top: 16px;
          }

          .value {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .bold {
            font-weight: 700;
          }
          .required {
            color: orangered;
          }

          .description {
            margin: 4px 0;
            color: var(--clr-gray);
          }

          .examples {
            span {
              background-color: var(--clr-white-2);
              padding: 4px 8px;
              border-radius: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
