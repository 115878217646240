<template>
  <section class="landing">
    <div class="landing_header">
      <LandingHeader />
    </div>
    <div class="landing_router">
      <router-view></router-view>
    </div>
    <div class="landing_footer">
      <LandingFooter />
    </div>
  </section>
</template>

<script>
import LandingHeader from '@/components/landing/LandingHeader.vue';
import LandingFooter from '@/components/landing/LandingFooter.vue';

export default {
  components: {
    LandingHeader,
    LandingFooter,
  },
};
</script>

<style lang="scss" scoped>
.landing {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &_router {
    flex-grow: 1;
  }
}
</style>
