<template>
  <section class="faq">
    <h2>Frequently Asked Questions</h2>
    <div class="questions">
      <div class="question">
        <h3>How do I install Raserva?</h3>
        <p>
          Raserva is web based and can be accessed anywhere at anytime on any
          device with internet access.
        </p>
      </div>
      <div class="question">
        <h3>Is there a cancellation fee?</h3>
        <p>
          You may cancel your subscription at any time, no questions asked, with
          no cancellation fee.
        </p>
      </div>
      <div class="question">
        <h3>Do you have a free trial?</h3>
        <p>
          Absolutely, Raserva offers a one month free trial during which you
          have access to ALL Raserva features.
        </p>
      </div>
      <div class="question">
        <h3>How long will it take to setup?</h3>
        <p>
          Setup can take less time than one of your quickest services. Raserva
          comes pre-loaded with example data, and creating your own personal
          data is a breeze.
        </p>
      </div>
    </div>
    <!-- <div class="notes">
      <p>
        * Raserva fees on top of your negotiated processing fees with our
        partner Stripe
      </p>
    </div> -->
  </section>
</template>

<style lang="scss" scoped>
.faq {
  text-align: center;

  h2 {
    font-size: 32px;
    font-weight: 400;
  }

  .questions {
    margin-top: 32px;
  }

  .question {
    text-align: left;

    &:not(:first-child) {
      margin-top: 32px;
    }

    p {
      line-height: 24px;
      margin-top: 8px;
      color: var(--clr-gray);
    }
  }

  .notes {
    font-size: 14px;
    margin-top: 64px;
  }
}
</style>
