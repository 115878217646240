<template>
  <section class="blast">
    <div class="blast_preview">
      <div class="view">
        <div class="sms_container">
          <div class="view_message">
            <p>{{ message }}{{ stopMessage }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="blast_form">
      <div v-if="sent" class="alert alert-success">
        <p>Your text blast was sent successfully!</p>
      </div>
      <div class="alert alert-info">
        <p>
          Text blasts will send to all clients with marketing and text
          notifications enabled, you will be charged immediately.
          <span @click="showPricing = true">Click here to view pricing.</span>
        </p>
      </div>
      <form @submit.prevent="showConfirm = true" class="form">
        <BaseInput
          label="Title"
          subtitle="For internal records only, name accordingly"
          @input="title = $event"
          :value="title"
        />

        <BaseTextarea
          label="Message"
          @input="message = $event"
          :value="message"
          :validators="validators.message"
        />

        <BaseSelect
          label="Client Group"
          @input="
            clientGroup = $event;
            costCalculated = false;
          "
          :value="clientGroup"
          :options="clientGroupOptions"
        />

        <StripePaymentMethods
          @select="selectedPaymentMethodId = $event"
          returnUrl="dashboard/marketing/texts"
          :title="title"
          :message="message"
          :clientGroup="clientGroup"
        />

        <div class="form_actions">
          <div v-if="costCalculated && costMessage" class="alert alert-info">
            <p>{{ costMessage }}</p>
          </div>

          <BaseButton
            mode="primary-outline"
            type="button"
            @click="showTestModal = true"
            :disabled="loading"
            ><i v-if="loading" class="fas fa-spinner"></i>Send Test</BaseButton
          >

          <BaseButton
            v-if="!costCalculated"
            type="button"
            @click="calcCost"
            :disabled="loading"
            ><i v-if="loading" class="fas fa-spinner"></i>Calculate
            Cost</BaseButton
          >
          <BaseButton v-else :disabled="loading"
            ><i v-if="loading" class="fas fa-spinner"></i>Send Text
            Blast</BaseButton
          >
        </div>
      </form>
    </div>
  </section>

  <Modal
    title="Test SMS Blast"
    :show="showTestModal"
    @close="showTestModal = false"
  >
    <form @submit.prevent="sendTest" class="form testForm">
      <BaseInput
        label="To Phone Number (10 Digit)"
        subtitle="Please only enter digits i.e. 1234567890 (No spaces or dashes)"
        @input="testNumber = $event"
      />

      <div class="form_actions">
        <BaseButton :disabled="testLoading"
          ><i v-if="testLoading" class="fas fa-spinner"></i> Send
          Test</BaseButton
        >
      </div>
    </form>
  </Modal>

  <Confirm
    v-if="showConfirm"
    title="Send text blast"
    text="Are you sure you wish to send this text blast? The card on file will be charged appropriately. Minimum charge is $0.50, if total is less than this, you will be charged the minimum."
    @confirm="submit"
    @deny="showConfirm = false"
  />

  <Modal :show="showPricing" @close="showPricing = false">
    <MarketingPrices />
  </Modal>
</template>

<script>
import MarketingPrices from '@/components/discounts/MarketingPrices.vue';
import StripePaymentMethods from '@/components/marketing/StripePaymentMethods.vue';

export default {
  components: {
    MarketingPrices,
    StripePaymentMethods,
  },
  created() {
    if (this.$store.state.auth.salon) {
      this.message = this.$store.state.auth.salon.details.shopName + ' - ';
    } else {
      this.message = 'Text preview';
    }

    // Queries
    if (this.$route.query.title) this.title = this.$route.query.title;
    if (this.$route.query.message) this.message = this.$route.query.message;
    if (this.$route.query.clientGroup)
      this.clientGroup = this.$route.query.clientGroup;
  },
  data() {
    return {
      title: '',
      message: '',
      clientGroup: 'all',
      loading: false,
      sent: false,

      showPricing: false,
      showConfirm: false,

      costCalculated: false,
      costMessage: '',
      totalCost: 0,

      showTestModal: false,
      testNumber: '',
      testLoading: false,

      selectedPaymentMethodId: null,
    };
  },
  computed: {
    clientGroupOptions() {
      return [
        {
          option: 'All Clients',
          value: 'all',
        },
        {
          option: 'Serviced in the last month',
          value: 'lastOneMonths',
        },
        {
          option: 'Serviced in the last 3 months',
          value: 'lastThreeMonths',
        },
        {
          option: 'Serviced in the last 6 months',
          value: 'lastSixMonths',
        },
        {
          option: 'Serviced in the last 12 months',
          value: 'lastTwelveMonths',
        },
      ];
    },
    validators() {
      return {
        message: {
          maxLength: 138,
        },
      };
    },
    subAccountServiceSid() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.marketing.texts.twilioServiceSID;
    },
    stopMessage() {
      return ` - Reply STOP to stop`;
    },
  },
  methods: {
    async calcCost() {
      this.loading = true;

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/marketing/textBlast`,
          {
            message: this.message,
            clientGroup: this.clientGroup,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        const costPerText = this.$store.state.auth.salon.marketing.texts.cost;
        let totalCost = costPerText * response.data.recipients;
        if (totalCost < 0.5) totalCost = 0.5;
        this.totalCost = totalCost;

        this.costMessage = `Sending to ${
          response.data.recipients
        } clients at $${costPerText} per text for a total of $${totalCost.toFixed(
          2
        )}`;

        if (costPerText * response.data.recipients < 50)
          this.costMessage += ' (Min $0.50)';

        this.costCalculated = true;
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    async sendTest() {
      try {
        this.testLoading = true;

        await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/marketing/textBlastTest`,
          {
            message: this.message + this.stopMessage,
            serviceSID: this.subAccountServiceSid,
            phoneNumber: this.testNumber,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.showTestModal = false;
        this.testNumber = '';

        this.$toast.success('Test text sent successfully');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.testLoading = false;
    },

    async submit() {
      if (this.message.length + this.stopMessage.length > 160) {
        this.$toast.error('Max length exceeded');
        return;
      }
      if (!this.title) {
        this.$toast.error('Title is required');
        return;
      }
      if (!this.message) {
        this.$toast.error('Message is required');
        return;
      }
      if (!this.selectedPaymentMethodId) {
        this.$toast.error('Payment method is required');
        return;
      }

      this.loading = true;
      this.showConfirm = false;

      try {
        await this.$axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/marketing/textBlast`,
          {
            title: this.title,
            message: this.message + this.stopMessage,
            clientGroup: this.clientGroup,
            confirmedCharge: true,
            serviceSID: this.subAccountServiceSid,
            payment_method: this.selectedPaymentMethodId,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.sent = true;
        this.totalCost = 0;
        this.costCalculated = false;
        this.costMessage = '';
        this.message = this.$store.state.auth.salon.details.shopName + ' - ';

        this.$toast.success('Text blast sent');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.blast {
  display: flex;
  gap: 64px;
  flex-wrap: wrap;

  &_preview {
    .view {
      height: 500px;
      width: 325px;
      padding: 16px;
      border-radius: 5px;
      background-color: var(--clr-white-2);
      display: flex;
      justify-content: center;

      .sms_container {
        margin-top: 12px;
        padding: 16px;
        background-color: white;
        height: 90%;
        width: 95%;
        border-radius: 5px;
      }

      &_message {
        width: 90%;
        padding: 16px;
        border-radius: 5px;
        background-color: var(--clr-secondary);
        color: white;
      }
    }
  }
  &_form {
    .alert {
      padding: 12px;
      font-size: 14px;
      border-radius: 5px;

      .bold {
        text-decoration: none;
        font-weight: 700;
        cursor: default;
      }
      span {
        text-decoration: underline;
        cursor: pointer;
      }

      &-info {
        background-color: #c3edf7;
        color: #044653;
        border-color: #aceaf7;
      }
      &-success {
        background-color: #c3f7c7;
        color: #04530b;
        border-color: #acf7b2;
        margin-bottom: 16px;
      }
    }

    .form {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      &_actions {
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;
        gap: 16px;
      }
    }
  }
}

.testForm {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;

  .form_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
