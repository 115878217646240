<template>
  <section class="invoice" id="staffSalesInvoice">
    <div class="invoice_head">
      <h2>{{ salonName }}</h2>
      <h3>{{ staffName }}</h3>
      <p>{{ formatDate(start) }} - {{ formatDate(end) }}</p>
      <p>Printed: {{ now }}</p>
    </div>
    <div class="invoice_body">
      <!-- Details Table -->
      <table>
        <!-- Total -->
        <tr>
          <td>Total Earnings</td>
          <td class="bold">{{ data.earnings }}</td>
        </tr>
        <!-- Total w/ OT -->
        <tr>
          <td>Total Earnings w/ OT</td>
          <td class="bold">{{ data.earningsWithOvertime }}</td>
        </tr>
        <!-- Shifts -->
        <tr>
          <td># of Shifts</td>
          <td class="bold">{{ data.shifts }}</td>
        </tr>
        <!-- Hours -->
        <tr>
          <td># of Hours</td>
          <td class="bold">{{ data.totalHours }}</td>
        </tr>
        <!-- Unique Days -->
        <tr>
          <td>Unique Days</td>
          <td class="bold">{{ data.uniqueDays }}</td>
        </tr>
      </table>

      <!-- Shifts Table -->
      <table>
        <tr v-for="shift in selectedStaffShifts" :key="shift._id">
          <td>{{ formatDate(shift.clockIn, true) }}</td>
          <td>{{ formatDate(shift.clockOut, true) }}</td>
        </tr>
      </table>
    </div>
  </section>
  <div class="actions">
    <BaseButton @click="print">Print</BaseButton>
  </div>
</template>

<script>
import printJS from 'print-js';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    staff: {
      type: Object,
      required: true,
    },
    start: {
      type: Date,
      required: true,
    },
    end: {
      type: Date,
      required: true,
    },
    shifts: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    salonName() {
      return this.$store.state.auth.salon.details.shopName;
    },
    staffName() {
      return `${this.staff.firstName} ${this.staff.lastName}`;
    },
    now() {
      return this.$moment().format('ll');
    },
    selectedStaffShifts() {
      return this.shifts.filter(
        (shift) => shift.staffId === this.staff.staffId
      );
    },
  },
  watch: {
    printItems(val) {
      if (val) {
        setTimeout(() => {
          this.print();
        }, 0);
      }

      setTimeout(() => {
        this.printItems = false;
      }, 2000);
    },
  },
  data() {
    return {
      printItems: false,
    };
  },
  methods: {
    formatDate(date, long) {
      if (!long) {
        return this.$moment(date).format('ll');
      } else {
        return this.$moment(date).format('l LT');
      }
    },
    print() {
      printJS({
        printable: 'staffSalesInvoice',
        type: 'html',
        style: `
          * { font-weight: 600 }
          .bold { font-weight: 700 }
          .invoice { text-align: center }
          .invoice_head h3, p { margin-top: 5px }
          .invoice_body { margin-top: 64px }
          .invoice_body table { width: 100%; border-collapse: collapse }
          .invoice_body table td { border: 1px solid #666666 }
          .itemsTable { margin-top: 16px }
          `,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.invoice {
  padding: 32px 48px;
  border: 1px solid var(--clr-light);
  border-radius: 5px;
  text-align: center;

  &_head {
    h3,
    p {
      margin-top: 5px;
    }
  }
  &_body {
    margin-top: 32px;

    .bold {
      font-weight: 700;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      &:not(:first-child) {
        margin-top: 16px;
      }

      td {
        border: 1px solid var(--clr-gray);
        padding: 5px;

        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}

.actions {
  margin-top: 16px;

  button:not(:last-child) {
    margin-right: 16px;
  }
}
</style>
