<template>
  <section class="home">
    <Spinner v-if="loading" />
    <AppAndSalesGraphs v-else-if="summary" :summary="summary" />
  </section>
</template>

<script>
import AppAndSalesGraphs from '@/components/reports/AppAndSalesGraphs.vue';

export default {
  components: {
    AppAndSalesGraphs,
  },
  created() {
    if (!this.loggedInStaff) return;
    this.init();
  },
  data() {
    return {
      loading: true,
      summary: null,
    };
  },
  computed: {
    loggedInStaff() {
      return this.$store.state.auth.loggedInSalonStaff;
    },
  },
  watch: {
    loggedInStaff() {
      this.init();
    },
  },
  methods: {
    async init() {
      await this.getSummary();
    },

    async getSummary() {
      try {
        this.summary = await this.$store.dispatch('salon/getSummary');
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding: 32px;
}
</style>
