<template>
  <form @submit.prevent="submit" class="form">
    <BaseInput
      label="Contact name"
      :value="newDistrib.name"
      @input="newDistrib.name = $event"
    />

    <div class="col-2">
      <BasePhoneInput
        label="Phone number"
        :value="newDistrib.phoneNumber"
        @input="newDistrib.phoneNumber = $event"
      />
      <BaseInput
        label="Email"
        :value="newDistrib.email"
        @input="newDistrib.email = $event"
      />
    </div>

    <BaseInput
      label="Website"
      :value="newDistrib.website"
      @input="newDistrib.website = $event"
    />

    <BaseInput
      label="Street Address"
      :value="newDistrib.street"
      @input="newDistrib.street = $event"
    />

    <div class="col-2">
      <BaseInput
        label="City"
        :value="newDistrib.city"
        @input="newDistrib.city = $event"
      />
      <BaseInput
        label="State"
        :value="newDistrib.state"
        @input="newDistrib.state = $event"
      />
    </div>

    <div class="col-2">
      <BaseInput
        label="Zipcode"
        :value="newDistrib.zip"
        @input="newDistrib.zip = $event"
      />
      <BaseInput
        label="Country"
        :value="newDistrib.country"
        @input="newDistrib.country = $event"
      />
    </div>

    <div class="form_actions">
      <BaseButton
        @click="showConfirmDelete = true"
        v-if="newDistrib._id"
        mode="danger-outline"
        type="button"
        >Delete</BaseButton
      >
      <BaseButton :disabled="!formValid" type="submit">Submit</BaseButton>
    </div>
  </form>

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Delete distributor"
    text="Are you sure you wish to delete this distributor? This action can not be undone."
    @confirm="removeDistrib"
    @deny="showConfirmDelete = false"
  />
</template>

<script>
export default {
  emits: ['close'],
  props: {
    distrib: {
      type: Object,
      required: false,
    },
  },
  computed: {
    formValid() {
      if (!this.newDistrib.name || !this.newDistrib.phoneNumber) return false;

      return true;
    },
  },
  created() {
    if (this.distrib)
      this.newDistrib = JSON.parse(JSON.stringify(this.distrib));
  },
  data() {
    return {
      newDistrib: {
        name: '',
        phoneNumber: '',
        email: '',
        website: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        country: '',
      },

      showConfirmDelete: false,
    };
  },
  methods: {
    async submit() {
      try {
        if (this.newDistrib._id) {
          // Edit
          await this.$axios.put(
            `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/distributors/${this.newDistrib._id}`,
            this.newDistrib,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );

          const index = this.$store.state.auth.salon.distributors.findIndex(
            (distrib) => distrib._id === this.newDistrib._id
          );

          if (index === -1) return;

          this.$store.state.auth.salon.distributors.splice(index, 1, {
            ...this.newDistrib,
          });
        } else {
          // Create
          const response = await this.$axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/distributors`,
            this.newDistrib,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );

          this.$store.state.auth.salon.distributors.push(
            response.data.distributor
          );
        }

        this.$emit('close');
      } catch (err) {
        this.$toast.error(err.message);
      }
    },

    async removeDistrib() {
      try {
        // Delete
        await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/distributors/${this.newDistrib._id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$emit('deleted');
      } catch (error) {
        console.log(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .col-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
