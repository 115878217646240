import axios from 'axios';

export default {
  namespaced: true,
  state: {
    giftcards: [],
  },
  mutations: {
    setGiftcards(state, payload) {
      state.giftcards = payload;
    },
    addGiftcard(state, payload) {
      state.giftcards.unshift(payload);
    },
    editGiftcard(state, payload) {
      const index = state.giftcards.findIndex(
        (giftcard) => giftcard._id === payload._id
      );

      if (index === -1) return;

      state.giftcards[index] = payload;
    },
    removeGiftcard(state, payload) {
      const index = state.giftcards.findIndex(
        (giftcard) => giftcard._id === payload._id
      );

      if (index === -1) return;

      state.giftcards.splice(index, 1);
    },
  },
  actions: {
    async createGiftcard({ rootState, commit, dispatch }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/giftcards`,
          { ...payload, salonId: rootState.auth.salon._id },
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        commit('addGiftcard', response.data.giftcard);

        dispatch(
          'auth/addAction',
          {
            action: `created gift card with code ${
              payload.code
            } for amount $${payload.amount.toFixed(2)}`,
            staff: payload.staff,
            type: 'giftcard',
          },
          { root: true }
        );
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async editGiftcard({ rootState, commit }, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/giftcards/${payload._id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        commit('editGiftcard', response.data.giftcard);
        return response.data.giftcard;
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },
    async deleteGiftcard({ rootState, commit }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/giftcards/${payload._id}`,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        commit('removeGiftcard', payload);
      } catch (error) {
        throw Error(error.response.data.error);
      }
    },

    async getGiftcards({ rootState }, payload) {
      try {
        const { limit = 25, code, start, end, page = 1 } = payload;

        let query = `?salonId=${rootState.auth.salon._id}&limit=${limit}&page=${page}`;

        if (code) query += `&code=${code}`;
        if (start) query += `&start=${start}`;
        if (end) query += `&end=${end}`;

        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/giftcards${query}`,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        return response.data.giftcards;
      } catch (error) {
        console.log(error);
        throw Error(error.response.data.error);
      }
    },

    async getGiftcardTotals({ rootState }) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/giftcards/totals?salonId=${rootState.auth.salon._id}`,
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        return response.data.data;
      } catch (error) {
        throw Error(error.message);
      }
    },

    async chargeGiftcard({ rootState }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/giftcards/${payload.giftcardCode}/charge`,
          { ...payload, salonId: rootState.auth.salon._id },
          {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
            },
          }
        );

        return response.data.giftcard;
      } catch (error) {
        console.log(error);
        throw Error(error.response.data.error);
      }
    },
  },
  getters: {},
};
