<template>
  <div class="backdrop" :class="{ mask: mask }"></div>
</template>

<script>
export default {
  props: {
    mask: {
      type: Boolean,
      default: false,
    },
    zIndex: {
      type: Number,
      required: false,
    },
  },
  created() {
    this.$store.dispatch('styles/incrementZIndex');
  },
};
</script>

<style>
.mask {
  background-color: var(--clr-black-transp-100);
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
</style>
