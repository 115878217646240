<template>
  <ul class="header">
    <router-link :to="{ name: 'AdminHome' }">
      <li>Home</li>
    </router-link>

    <router-link v-if="activeUserHasPerm('team')" :to="{ name: 'AdminTeam' }">
      <li>Team</li>
    </router-link>

    <router-link v-if="activeUserHasPerm('users')" :to="{ name: 'AdminUsers' }">
      <li>Salons</li>
    </router-link>

    <router-link
      v-if="activeUserHasPerm('staffAccounts')"
      :to="{ name: 'AdminStaffAccounts' }"
    >
      <li>Staff</li>
    </router-link>

    <router-link
      v-if="activeUserHasPerm('tickets')"
      :to="{ name: 'AdminTickets' }"
    >
      <li>Tickets</li>
    </router-link>

    <router-link v-if="activeUserHasPerm('logs')" :to="{ name: 'AdminLogs' }">
      <li>Logs</li>
    </router-link>

    <router-link
      v-if="activeUserHasPerm('analytics')"
      :to="{ name: 'AdminAnalytics' }"
    >
      <li>Analytics</li>
    </router-link>

    <router-link
      v-if="activeUserHasPerm('support')"
      :to="{ name: 'AdminSupport' }"
    >
      <li>Support</li>
    </router-link>

    <li class="danger" @click="confirmSignOut = true">Sign Out</li>
  </ul>

  <div class="mobile">
    <div class="mobile_header">
      <TheLogo />
      <i @click="showMobileMenu = !showMobileMenu" class="fas fa-bars"></i>
    </div>

    <ul v-if="showMobileMenu" class="mobile_menu">
      <router-link :to="{ name: 'AdminHome' }">
        <li>Home</li>
      </router-link>

      <router-link v-if="activeUserHasPerm('team')" :to="{ name: 'AdminTeam' }">
        <li>Team</li>
      </router-link>

      <router-link
        v-if="activeUserHasPerm('users')"
        :to="{ name: 'AdminUsers' }"
      >
        <li>Salons</li>
      </router-link>

      <router-link
        v-if="activeUserHasPerm('staffAccounts')"
        :to="{ name: 'AdminStaffAccounts' }"
      >
        <li>Staff</li>
      </router-link>

      <router-link v-if="activeUserHasPerm('logs')" :to="{ name: 'AdminLogs' }">
        <li>Logs</li>
      </router-link>

      <router-link
        v-if="activeUserHasPerm('support')"
        :to="{ name: 'AdminSupport' }"
      >
        <li>Support</li>
      </router-link>

      <li @click="confirmSignOut = true">Sign Out</li>
    </ul>
  </div>

  <Confirm
    v-if="confirmSignOut"
    title="Sign Out"
    text="If you sign out, you will be redirected to the login page."
    @confirm="signOut"
    @deny="confirmSignOut = false"
  />
</template>

<script>
export default {
  async created() {
    try {
      this.$store.dispatch('auth/logout');
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    admin() {
      return this.$store.state.admin.admin;
    },
  },
  data() {
    return {
      confirmSignOut: false,
      showMobileMenu: false,
    };
  },
  methods: {
    async signOut() {
      this.$store.dispatch('admin/signOut');
      this.$router.push({ name: 'AdminLogin' });
    },
    activeUserHasPerm(perm) {
      if (!this.admin) return false;

      return this.admin.permissions.includes(perm);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-secondary-dark);
  color: var(--clr-white);

  li {
    padding: 16px 32px;
    cursor: pointer;

    &:hover {
      background-color: var(--clr-secondary-dark-hover);
    }
  }

  .danger {
    background-color: var(--clr-danger);

    &:hover {
      background-color: var(--clr-danger-dark);
    }
  }

  .router-link-exact-active {
    li {
      background-color: var(--clr-secondary) !important;
      color: var(--clr-white);
    }
  }
}

.mobile {
  display: none;
  background-color: var(--clr-light);
  color: var(--clr-secondary-dark);

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    i {
      font-size: 24px;
    }
  }

  &_menu {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    text-align: center;

    li {
      padding: 16px;
      border-top: 1px solid var(--clr-light-2);
    }
  }
}

// Mobile
@media (max-width: 600px) {
  .header {
    display: none;
  }
  .mobile {
    display: block;
  }
}
</style>
