<template>
  <section class="texts">
    <div class="texts_head">
      <h2>Text Marketing</h2>
      <p v-if="!optedIn">
        You are currently not opted-in for text message marketing.
      </p>
      <p v-else>
        Text message marketing is enabled for this account, you have
        {{ subAccountPhoneNumbers.length }} phone numbers.
      </p>
    </div>

    <div v-if="optedIn" class="texts_actions">
      <BaseButton @click="showPurchaseForm = true"
        >Add Phone Number(s)</BaseButton
      >
    </div>

    <div class="texts_body">
      <div v-if="!optedIn" class="optin">
        <TextTwilioOptIn />
      </div>
      <div v-else class="blast">
        <TextBlastForm />
      </div>
    </div>
  </section>

  <Modal
    title="Purchase Additional Phone Numbers"
    :show="showPurchaseForm"
    @close="showPurchaseForm = false"
  >
    <PurchasePhoneNumbers @purchased="showPurchaseForm = false" />
  </Modal>
</template>

<script>
import TextTwilioOptIn from '@/components/marketing/TextTwilioOptIn.vue';
import TextBlastForm from '@/components/marketing/TextBlastForm.vue';
import PurchasePhoneNumbers from '@/components/marketing/PurchasePhoneNumbers.vue';

export default {
  components: {
    TextTwilioOptIn,
    TextBlastForm,
    PurchasePhoneNumbers,
  },
  computed: {
    subAccountSid() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.marketing.texts.twilioSubAccountSID;
    },
    subAccountServiceSid() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.marketing.texts.twilioServiceSID;
    },
    subAccountPhoneNumbers() {
      if (!this.$store.state.auth.salon) return;

      return this.$store.state.auth.salon.marketing.texts.twilioPhoneNumbers;
    },
    optedIn() {
      return (
        this.subAccountSid &&
        this.subAccountServiceSid &&
        this.subAccountPhoneNumbers.length
      );
    },
  },
  data() {
    return {
      showPurchaseForm: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.texts {
  &_head {
    h2 {
      font-size: 32px;
    }
    p {
      margin-top: 5px;
      color: var(--clr-gray);
    }
  }

  &_actions {
    margin-top: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-extra-light);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 32px;
  }

  &_body {
    margin-top: 32px;

    .optin {
      max-width: 1000px;
    }
  }
}
</style>
