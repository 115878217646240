<template>
  <section class="products">
    <div class="products_head">
      <h2>Products</h2>
    </div>
    <div class="products_actions">
      <div class="col">
        <BaseInput
          placeholder="Search..."
          :value="searchValue"
          @input="filterProducts($event)"
          icon="fas fa-search"
        />
      </div>
      <div class="col buttons">
        <div class="add_button">
          <BaseButton @click="showAddDropdown = true"
            >Add <i class="fas fa-angle-down"></i
          ></BaseButton>

          <Tooltip
            v-if="showAddDropdown"
            mode="bottom right"
            @hide="showAddDropdown = false"
          >
            <ul class="addDropdown">
              <li
                v-if="categories.length"
                @click="
                  showAddDropdown = false;
                  selectedProduct = null;
                  showProductForm = true;
                "
              >
                New Product
              </li>
              <li
                @click="
                  showAddDropdown = false;
                  selectedCategory = null;
                  showCategoryForm = true;
                "
              >
                New Category
              </li>
            </ul>
          </Tooltip>
        </div>
      </div>
    </div>
    <div class="products_list">
      <ProductsList
        :categories="categories"
        @selectItem="selectProduct($event)"
        @selectCategory="selectCategory($event)"
        @addItemToCategory="addItemToCategory($event)"
      />
    </div>
  </section>

  <Modal
    :title="productFormTitle"
    fullScreen
    :show="showProductForm"
    @close="showProductForm = false"
  >
    <div class="productForm">
      <ProductForm
        :product="selectedProduct"
        @submitted="
          showProductForm = false;
          init();
        "
        @deleted="productDeleted($event)"
      />
    </div>
  </Modal>

  <Modal
    :title="categoryFormTitle"
    :show="showCategoryForm"
    @close="showCategoryForm = false"
    maxWidth="500px"
  >
    <div class="categoryForm">
      <CategoryForm
        :category="selectedCategory"
        @editCategory="editCategory($event)"
        @createCategory="createCategory($event)"
        @deleteCategory="deleteCategory($event)"
      />
    </div>
  </Modal>
</template>

<script>
import ProductsList from '@/components/items/ProductsList.vue';
import ProductForm from '@/components/items/ProductForm.vue';
import CategoryForm from '@/components/items/CategoryForm.vue';

export default {
  components: {
    ProductsList,
    ProductForm,
    CategoryForm,
  },
  created() {
    this.init();
  },
  data() {
    return {
      selectedProduct: null,
      selectedCategory: null,

      showAddDropdown: false,
      showProductForm: false,
      showCategoryForm: false,

      searchValue: '',

      categories: [],
    };
  },
  computed: {
    salon() {
      return this.$store.state.auth.user.salon;
    },
    categoryFormTitle() {
      return this.selectedCategory ? 'Edit Category' : 'New Category';
    },
    productFormTitle() {
      return this.selectedProduct && this.selectedProduct._id
        ? 'Edit Product'
        : 'New Product';
    },
    // categories() {
    //   return this.$store.state.items.products;
    // },
    categoryChange() {
      return this.$store.state.items.categoryChangeCount;
    },
  },
  watch: {
    categoryChange() {
      this.filterProducts(this.searchValue);
    },
    salon() {
      this.init();
    },
  },
  methods: {
    init() {
      this.searchValue = '';
      this.filterProducts(this.searchValue);
    },
    filterProducts(search) {
      this.searchValue = search;
      this.categories = this.$store.getters['items/searchProducts'](search);
    },
    selectProduct(product) {
      this.selectedProduct = product;
      this.showProductForm = true;
    },
    selectCategory(_id) {
      this.selectedCategory = this.categories.find((category) => {
        return category._id === _id;
      });

      this.showCategoryForm = true;
    },
    addItemToCategory(category) {
      this.selectedProduct = {
        category: category._id,
      };
      this.showProductForm = true;
    },

    createCategory(category) {
      try {
        this.$store.dispatch('items/createProductCategory', category);
        this.submittedCategory();
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    editCategory(category) {
      try {
        this.$store.dispatch('items/editProductCategory', category);
        this.submittedCategory();
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    deleteCategory(category) {
      try {
        this.$store.dispatch('items/deleteProductCategory', category);
        this.$toast.success('Category deleted');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    submittedCategory() {
      try {
        this.showCategoryForm = false;
        this.$toast.success('Category updated');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    productDeleted(productId) {
      for (let i = 0; i < this.categories.length; i++) {
        const category = this.categories[i];
        const index = category.items.findIndex((product) => {
          return product._id === productId;
        });
        if (index > -1) {
          category.items.splice(index, 1);
          break;
        }
      }

      this.showProductForm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.products {
  width: 100%;
  max-width: 800px;

  &_head {
    h2 {
      font-size: 28px;
    }
  }

  &_actions {
    margin-top: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-extra-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    .buttons {
      display: flex;
      gap: 16px;

      .add_button {
        position: relative;

        button {
          i {
            margin-left: 16px;
          }
        }

        .addDropdown {
          color: var(--clr-black);
          background-color: white;
          border: 1px solid var(--clr-light);
          border-radius: 5px;

          li {
            padding: 12px 16px;
            font-weight: 400;
            text-align: left;
            font-size: 15px;
            transition: background-color 0.2s;
            cursor: pointer;

            &:hover {
              background-color: var(--clr-extra-light);
            }
          }
        }
      }
    }
  }

  &_list {
    margin-top: 32px;
  }
}

.productForm {
  width: 1000px;
  max-width: 100%;
  margin: 32px auto;
  padding: 0 16px;
}
.categoryForm {
  padding: 32px;
}

// Tablet
@media (max-width: 900px) {
  .products {
    &_actions {
      flex-direction: column;
      gap: 16px;

      .col {
        width: 100%;
      }

      .buttons {
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }
}
</style>
