<template>
  <div class="category">
    <div class="category_head">
      <h2>Sales by category</h2>
    </div>
    <div class="category_drb">
      <DateRangeBar @search="setAndSearch($event.starting, $event.ending)">
        <BaseSelect
          label="Category"
          :options="categoryOptions"
          :value="selectedCategory"
          @input="selectCategory($event)"
        />
      </DateRangeBar>
    </div>
    <div class="category_tables" v-if="salesData.length">
      <div class="category_tables_table">
        <Table
          :headers="salesHeaders"
          :data="salesDataWithoutId"
          :boldRows="[0]"
          :boldCols="[7]"
        />
      </div>
    </div>
    <div v-else class="none">
      <h4>This category has no data to display!</h4>
      <p>Select another category.</p>
    </div>
  </div>
</template>

<script>
import DateRangeBar from '@/components/components/DateRangeBar.vue';

export default {
  components: {
    DateRangeBar,
  },
  created() {
    this.resetSales();
    this.search(this.searchStart, this.searchEnd);
  },
  computed: {
    salesDataWithoutId() {
      if (!this.salesData) return;

      return this.salesData.map(({ _id, ...rest }) => {
        //  Literally have to do something with _id or Vue complains
        //  that we're not doing anything with _id LMAO
        +_id++;
        return rest;
      });
    },
    categoryOptions() {
      const products = this.$store.state.items.products;
      const services = this.$store.state.items.services;

      const categories = [
        {
          option: 'All',
          value: 'all',
        },
      ];

      products.forEach((category) => {
        categories.push({
          option: category.title,
          value: category._id,
        });
      });
      services.forEach((category) => {
        categories.push({
          option: category.title,
          value: category._id,
        });
      });

      return categories;
    },
  },
  data() {
    return {
      salesHeaders: [
        'Category',
        'Items Sold',
        'Gross Sales',
        'Discounts',
        'Refunds',
        'Net Sales',
        'Tax',
        'Total Sales',
      ],
      salesData: null,
      allSalesData: null,

      tickets: [],

      selectedCategory: 'all',
      searchStart: this.$moment().startOf('day'),
      searchEnd: this.$moment().endOf('day'),
    };
  },
  methods: {
    selectCategory(category) {
      this.selectedCategory = category;
      this.setDisplayedCategory();
    },

    setAndSearch(start, end) {
      this.searchStart = start;
      this.searchEnd = end;

      this.search(start, end);
    },

    async search(start, end) {
      try {
        const tickets = await this.$store.dispatch(
          'tickets/getTicketsInDateRange',
          { start, end }
        );
        this.tickets = tickets;

        this.setSales({ tickets });
        this.setDisplayedCategory(this.selectedCategory);
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    setSales(data) {
      // Reset
      this.resetSales();

      // Tickets
      data.tickets.forEach((ticket) => {
        this.salesData.push(this.getTicketData(ticket));
      });

      // Set Totals
      this.setTotalsOnSalesData();

      // Sort
      this.sortSales();

      // Formatting
      this.formatTotals();

      // Display correct Category(s)
      this.setDisplayedCategory();
    },

    sortSales() {
      this.salesData = this.allSalesData.sort((a, b) => {
        return b.totalSales - a.totalSales;
      });
    },

    getTicketData(ticket) {
      if (ticket.status !== 'completed' && ticket.status !== 'refunded') return;

      const services = this.$store.state.items.services;
      const products = this.$store.state.items.products;

      // Items
      ticket.items.forEach((item) => {
        if (!item.item.categoryId) {
          for (let i = 0; i < services.length; i++) {
            const service = services[i];
            const index = service.items.findIndex(
              (serviceItem) => serviceItem._id === item.item._id
            );

            if (index !== -1) {
              item.item.categoryId = service._id;
              break;
            }
          }

          if (!item.item.categoryId) {
            for (let i = 0; i < products.length; i++) {
              const product = products[i];
              const index = product.items.findIndex(
                (productItem) => productItem._id === item.item._id
              );

              if (index !== -1) {
                item.item.categoryId = product._id;
                break;
              }
            }
          }

          if (!item.item.categoryId) return;
        }

        let index = this.allSalesData.findIndex(
          (category) => category._id === item.item.categoryId
        );

        const itemCategory = this.findCategory(item.item.categoryId);

        if (!itemCategory) return;

        if (index === -1) {
          this.allSalesData.push({
            _id: item.item.categoryId,
            category: itemCategory.title,
            itemsSold: 0,
            grossSales: 0,
            discounts: 0,
            refunds: 0,
            netSales: 0,
            tax: 0,
            totalSales: 0,
          });

          index = this.allSalesData.length - 1;
        }

        this.allSalesData[index].discounts +=
          (item.item.discountAmount || 0) * item.quantity;

        if (item.quantity > 0) {
          this.allSalesData[index].grossSales +=
            item.item.price * item.quantity;

          this.allSalesData[index].itemsSold += item.quantity;

          if (item.item.taxRate) {
            this.allSalesData[index].tax +=
              item.item.price * item.quantity * (item.item.taxRate * 0.01);
          }
        } else if (item.quantity < 0) {
          // A refunded item
          if (item.item.taxRate) {
            this.allSalesData[index].tax +=
              item.item.price * item.quantity * (item.item.taxRate * 0.01);
          }

          this.allSalesData[index].refunds += item.item.price * item.quantity;
        }
      });
    },

    setDisplayedCategory() {
      if (this.selectedCategory === 'all') {
        this.salesData = this.allSalesData;
      } else {
        const salesData = [];

        const index = this.allSalesData.findIndex((data) => {
          return data._id === this.selectedCategory;
        });

        if (index !== -1) {
          salesData.push(this.allSalesData[index]);
        }

        this.salesData = salesData;
      }
    },

    findCategory(id) {
      const services = this.$store.state.items.services;
      const products = this.$store.state.items.products;

      const servicesIndex = services.findIndex(
        (category) => category._id === id
      );

      if (servicesIndex !== -1) return services[servicesIndex];

      const productsIndex = products.findIndex(
        (category) => category._id === id
      );

      if (productsIndex !== -1) return products[productsIndex];

      return null;
    },

    setTotalsOnSalesData() {
      this.allSalesData.forEach((data) => {
        data.netSales =
          data.grossSales - data.discounts - Math.abs(data.refunds);
        data.totalSales = data.netSales + data.tax;
      });
    },

    formatTotals() {
      this.allSalesData.forEach((data) => {
        data.grossSales = `$${data.grossSales.toFixed(2)}`;
        data.discounts = `($${data.discounts.toFixed(2)})`;
        data.refunds = `($${data.refunds.toFixed(2)})`;
        data.netSales = `$${data.netSales.toFixed(2)}`;
        data.tax = `$${data.tax.toFixed(2)}`;
        data.totalSales = `$${data.totalSales.toFixed(2)}`;
      });
    },

    resetSales() {
      this.salesData = [];

      this.allSalesData = [...this.salesData];
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  width: 100%;

  &_head {
    h2 {
      font-size: 28px;
    }
  }

  &_drb {
    margin-top: 32px;
  }

  &_tables {
    margin-top: 32px;
  }
}

.none {
  margin-top: 16px;
  text-align: center;
  border: 1px solid var(--clr-light);
  border-radius: 5px;
  padding: 32px;

  p {
    margin-top: 5px;
  }
}
</style>
