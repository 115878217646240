<template>
  <div class="endpoint_head">
    <h2>Update booking settings</h2>

    <div class="route">
      <i
        @click="
          $router.push('/documentation/api/settings#updateBookingSettings');
          $toast.info('Set URL to endpoint');
        "
        :style="{ color: 'var(--clr-secondary)', cursor: 'pointer' }"
        class="fas fa-hashtag"
      ></i>
      <p class="tag tag-blue">PUT</p>
      <p class="url">{{ apiUrl }}<span>/salons/:id/settings/booking</span></p>
    </div>

    <div class="description">
      <p>Update a salons booking settings</p>
    </div>
  </div>

  <div class="endpoint_body">
    <div class="request">
      <h3>Request</h3>

      <!-- Headers -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Headers</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">Authorization</p>
              <p class="examples">Examples: <span>Bearer {Token}</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Path params -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Path Parameters</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">ID</p>
              <p class="description">ID of salon</p>
              <p class="examples">
                Examples: <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6f</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
          <p>application/json</p>
        </div>

        <ul>
          <!-- noShowFeePercent -->
          <li>
            <div class="col value">
              <p class="bold">noShowFeePercent</p>
              <p class="description">
                Number - Amount of appointment to charge in case of no show in
                percent (%)
              </p>
              <p class="examples">Examples: <span>15</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- allowSameDayBookings -->
          <li>
            <div class="col value">
              <p class="bold">allowSameDayBookings</p>
              <p class="description">Boolean - Allow same day bookings</p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- minimumScheduleTimeHours -->
          <li>
            <div class="col value">
              <p class="bold">minimumScheduleTimeHours</p>
              <p class="description">
                Number - Minimum time in hours to schedule an appointment
              </p>
              <p class="examples">Examples: <span>2</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- maximumScheduleTimeDays -->
          <li>
            <div class="col value">
              <p class="bold">maximumScheduleTimeDays</p>
              <p class="description">
                Number - Maximum time in days to schedule an appointment
              </p>
              <p class="examples">Examples: <span>90</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- sendConfirmationText -->
          <li>
            <div class="col value">
              <p class="bold">sendConfirmationText</p>
              <p class="description">
                Boolean - Send confirmation text to client on appointment booked
              </p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- sendReminderText -->
          <li>
            <div class="col value">
              <p class="bold">sendReminderText</p>
              <p class="description">
                Boolean - Send reminder text to client nearing appointment time
              </p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- sendCancellationText -->
          <li>
            <div class="col value">
              <p class="bold">sendCancellationText</p>
              <p class="description">
                Boolean - Send cancellation text to client on appointment
                cancelled
              </p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- reminderTextNumberOfHoursBefore -->
          <li>
            <div class="col value">
              <p class="bold">reminderTextNumberOfHoursBefore</p>
              <p class="description">
                Number - Number of hours before appointment to send reminder
                text
              </p>
              <p class="examples">Examples: <span>3</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- sendClientConfirmAppointmentText -->
          <li>
            <div class="col value">
              <p class="bold">sendClientConfirmAppointmentText</p>
              <p class="description">
                Boolean - Send confirmation request to client around 24 hours
                before appointment
              </p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- sendReviewEmail -->
          <li>
            <div class="col value">
              <p class="bold">sendReviewEmail</p>
              <p class="description">
                Boolean - Send review request email to client after appointment
              </p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- allowClientsToDM -->
          <li>
            <div class="col value">
              <p class="bold">allowClientsToDM</p>
              <p class="description">
                Boolean - Allow clients to direct message you on booking page
              </p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- sendEmailToClientUponDM -->
          <li>
            <div class="col value">
              <p class="bold">sendEmailToClientUponDM</p>
              <p class="description">
                Boolean - Send email to client upon direct message
              </p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- onlineShop -->
          <li>
            <div class="col value">
              <p class="bold">onlineShop</p>
              <p class="description">
                Boolean - Enable online shop on booking page
              </p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- onlineGiftCardsEnabled -->
          <li>
            <div class="col value">
              <p class="bold">onlineGiftCardsEnabled</p>
              <p class="description">
                Boolean - Enable online gift cards on booking page
              </p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- onlineGiftCardMin -->
          <li>
            <div class="col value">
              <p class="bold">onlineGiftCardMin</p>
              <p class="description">
                Number - Minimum amount for online gift cards
              </p>
              <p class="examples">Examples: <span>5</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- onlineGiftCardMax -->
          <li>
            <div class="col value">
              <p class="bold">onlineGiftCardMax</p>
              <p class="description">
                Number - Maximum amount for online gift cards
              </p>
              <p class="examples">Examples: <span>500</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- collectPaymentInfo -->
          <li>
            <div class="col value">
              <p class="bold">collectPaymentInfo</p>
              <p class="description">
                Boolean - Collect payment information on booking page for no
                shows
              </p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- promotion -->
          <li>
            <div class="col value">
              <p class="bold">promotion</p>
              <p class="description">
                String - Promotion text to display on booking page
              </p>
              <p class="examples">
                Examples: <span>'Buy one get one free hair services!'</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- termsOfServiceLink -->
          <li>
            <div class="col value">
              <p class="bold">termsOfServiceLink</p>
              <p class="description">String - Link to terms of service page</p>
              <p class="examples">
                Examples: <span>'https://www.raserva.com'</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- cancellationPolicyLink -->
          <li>
            <div class="col value">
              <p class="bold">cancellationPolicyLink</p>
              <p class="description">
                String - Link to cancellation policy page
              </p>
              <p class="examples">
                Examples: <span>'https://www.raserva.com'</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- useQuickCancellationPolicy -->
          <li>
            <div class="col value">
              <p class="bold">useQuickCancellationPolicy</p>
              <p class="description">
                Boolean - Use quick cancellation policy on booking page
                (overrides cancellationPolicyLink)
              </p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- cancellationPolicyText -->
          <li>
            <div class="col value">
              <p class="bold">cancellationPolicyText</p>
              <p class="description">
                String - Text to display for quick cancellation policy
              </p>
              <p class="examples">
                Examples: <span>'Do not cancel please'</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- allowPhotoUploads -->
          <li>
            <div class="col value">
              <p class="bold">allowPhotoUploads</p>
              <p class="description">
                Boolean - Allow clients to upload photos when booking
              </p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- photoUploadLimit -->
          <li>
            <div class="col value">
              <p class="bold">photoUploadLimit</p>
              <p class="description">
                Number - Maximum number of photos a client can upload
              </p>
              <p class="examples">Examples: <span>3</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- photoUploadMessage -->
          <li>
            <div class="col value">
              <p class="bold">photoUploadMessage</p>
              <p class="description">
                String - Message to display when client uploads a photo
              </p>
              <p class="examples">
                Examples: <span>'Please upload all angles of your hair'</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- photoUploadPlaceholder -->
          <li>
            <div class="col value">
              <p class="bold">photoUploadPlaceholder</p>
              <p class="description">
                String - Link to image to display as placeholder when client
                uploads a photo
              </p>
              <!-- <p class="examples">Examples: <span>'Please upload all angles of your hair'</span></p> -->
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- collectDeposit -->
          <li>
            <div class="col value">
              <p class="bold">collectDeposit</p>
              <p class="description">
                Boolean - Collect deposit on booking page
              </p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- depositPercent -->
          <li>
            <div class="col value">
              <p class="bold">depositPercent</p>
              <p class="description">
                Number - Percentage of service(s) price to collect as deposit
              </p>
              <p class="examples">Examples: <span>10</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- useRequestedStaffIcon -->
          <li>
            <div class="col value">
              <p class="bold">useRequestedStaffIcon</p>
              <p class="description">
                Boolean - If enabled, when a client selects a specific staff
                member, a <i class="fas fa-heart text-red"></i> icon will appear
                on the appointment
              </p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="response">
      <h3>Response</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">bookingSettings</p>
              <p class="examples">
                Examples:
                <span
                  >{ "noShowFeePercent": 10, "allowSameDayBookings": true,
                  "minimumScheduleTimeHours": 0.5, "maximumScheduleTimeDays":
                  90, "sendConfirmationText": true, "sendReminderText": false,
                  "sendCancellationText": false,
                  "reminderTextNumberOfHoursBefore": 2,
                  "sendClientConfirmAppointmentText": true, "sendReviewEmail":
                  false, "allowClientsToDM": false, "sendEmailToClientUponDM":
                  true, "onlineShop": true, "onlineGiftCardsEnabled": true,
                  "onlineGiftCardMin": 10, "onlineGiftCardMax": 250,
                  "collectPaymentInfo": false, "promotion": "Buy one get one
                  free manicures!", "termsOfServiceLink": "",
                  "cancellationPolicyLink": "", "useQuickCancellationPolicy":
                  true, "cancellationPolicyText": "If you cancel we will be very
                  sad", "allowPhotoUploads": true, "photoUploadLimit": 3,
                  "photoUploadMessage": "Please upload photos of yo hair",
                  "photoUploadPlaceholder":
                  "https://images.google.com/danny-devito.jpg",
                  "collectDeposit": false, "depositPercent": 10,
                  "useRequestedStaffIcon": true }</span
                >
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    apiUrl() {
      return process.env.VUE_APP_RASERVA_BACKEND;
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoint {
  .tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px;
    border-radius: 5px;
    color: white;

    &-blue {
      background-color: var(--clr-secondary);
    }
  }

  &_head {
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }

    .route {
      padding: 8px 16px;
      border-radius: 5px;
      background-color: var(--clr-white-2);
      display: flex;
      align-items: center;
      gap: 16px;

      .url {
        color: var(--clr-gray);

        span {
          color: var(--clr-primary);
          font-weight: 700;
        }
      }
    }

    .description {
      margin-top: 32px;
    }
  }

  &_body {
    .request,
    .response,
    .reqResExamples {
      margin-top: 32px;
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &_section {
      &:not(:first-child) {
        margin-top: 32px;
      }

      .head {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 20px;
          margin-bottom: 16px;
          color: var(--clr-secondary);
        }

        p {
          font-size: 14px;
          color: var(--clr-gray);
        }
      }

      ul {
        .subitem {
          margin-left: 32px;
        }

        li {
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          &:not(:first-child) {
            margin-top: 16px;
          }

          .value {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .bold {
            font-weight: 700;
          }
          .required {
            color: orangered;
          }

          .description {
            margin: 4px 0;
            color: var(--clr-gray);
          }

          .examples {
            span {
              background-color: var(--clr-white-2);
              padding: 4px 8px;
              border-radius: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
