<template>
  <form @submit.prevent="submit" class="form">
    <div class="form_section">
      <div class="form_section_body">
        <BaseInput
          label="Category Title"
          placeholder="e.g. Nail Services"
          @input="newCategory.title = $event"
          :value="newCategory.title"
        />
        <ColorSelector
          :selectedColor="newCategory.color"
          @selected="newCategory.color = $event"
        />
        <BaseTextarea
          label="Category Description"
          @input="newCategory.description = $event"
          :value="newCategory.description"
        />
        <BaseSelect
          v-if="parentCategoryOptions.length"
          label="Parent Category"
          :options="parentCategoryOptions"
          @input="newCategory.parentCategory = $event"
          :value="newCategory.parentCategory"
        />
        <div class="toggle">
          <Toggle
            :checked="newCategory.onKiosk"
            @toggle="newCategory.onKiosk = !newCategory.onKiosk"
          />
          <div class="toggle_text">
            <p class="toggle_text_label">Display On Kiosk</p>
            <p class="toggle_text_subtext">
              Clients can see and select this category on the kiosk. This only
              applies to Service categories.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="form_actions">
      <BaseButton :disabled="!formValid">Submit</BaseButton>
    </div>
  </form>
</template>

<script>
import ColorSelector from '@/components/components/ColorSelector.vue';

export default {
  emits: ['editCategory', 'createCategory'],
  components: {
    ColorSelector,
  },
  props: {
    category: {
      type: Object,
    },
  },
  created() {
    if (this.category)
      this.newCategory = JSON.parse(JSON.stringify(this.category));
  },
  computed: {
    formValid() {
      if (this.newCategory.title.length < 2) return false;
      return true;
    },
    parentCategoryOptions() {
      const options = [];

      this.$store.state.auth.salon.serviceParentCategories.forEach((cat) => {
        options.push({
          option: cat.title,
          value: cat._id,
        });
      });

      return options;
    },
  },
  data() {
    return {
      newCategory: {
        title: '',
        color: '#a6c7ea',
        description: '',
        onKiosk: true,
        parentCategory: '',
      },
    };
  },
  methods: {
    submit() {
      if (this.newCategory._id) {
        this.$emit('editCategory', this.newCategory);
        return;
      }

      this.$emit('createCategory', this.newCategory);
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  &_section {
    // padding: 32px;
    // border: 1px solid var(--clr-light-2);
    border-radius: 5px;

    .toggle {
      display: flex;
      align-items: center;
      gap: 32px;
      flex-wrap: wrap;
      padding: 16px 0;

      &_text {
        &_label {
          font-size: 18px;
        }
        &_subtext {
          margin-top: 5px;
          font-size: 14px;
        }
      }
    }

    &:not(:first-child) {
      margin-top: 16px;
    }

    &_head {
      margin-bottom: 16px;

      p {
        font-size: 14px;
        margin-top: 5px;
        color: var(--clr-gray);
      }
    }

    .col-2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }
    .col-3 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
    }
    .col-4 {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;
    }

    &_body {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;
    }
  }

  &_actions {
    margin-top: 16px;
    text-align: right;
  }
}
</style>
