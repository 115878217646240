<template>
  <section class="support">
    <div class="support_head">
      <h2>Support Tickets</h2>
      <p>View/manage support tickets</p>
    </div>

    <div class="support_actions">
      <div class="filters">
        <BaseInput
          placeholder="Search tickets.."
          @input="searchValue = $event"
        />
        <BaseSelect
          :options="filterOptions"
          :value="selectedFilter"
          @input="selectedFilter = $event"
        />
      </div>

      <div class="buttons">
        <!-- <BaseButton @click="showConfirmDelete = true" mode="danger"
          >Clear</BaseButton
        > -->
      </div>
    </div>

    <!-- List -->
    <div v-if="tickets.length" class="support_tickets">
      <table>
        <thead>
          <tr>
            <th>Status</th>
            <th>Title</th>
            <th>Last Reply</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="ticket in tickets"
            :key="ticket._id"
            @click="selectedTicket = ticket"
          >
            <td
              :class="{
                'text-green': ticket.status === 'closed',
                'text-yellow': ticket.status === 'open',
              }"
            >
              {{ ticket.status }}
            </td>
            <td>{{ ticket.title }}</td>
            <td>
              {{ ticket.messages[ticket.messages.length - 1].author.name }} -
              {{ formatDate(ticket.messages[ticket.messages.length - 1].date) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- <ul v-if="tickets.length" class="support_tickets">
      <li
        v-for="ticket in filteredTickets"
        :key="ticket._id"
        @click="selectedTicket = ticket"
      >
        <div class="col">
          <h3>{{ ticket.title }}</h3>
          <p class="mt-s light small">{{ formatDate(ticket.created_at) }}</p>
        </div>
        <div class="col right">
          <p>
            Status:
            <span
              class="status"
              :class="{
                blue: ticket.status === 'new',
                green: ticket.status === 'closed',
                yellow: ticket.status === 'open',
              }"
              >{{ ticket.status }}</span
            >
          </p>
          <p class="mt-s light small">
            Last Reply:
            {{ ticket.messages[ticket.messages.length - 1].author.name }} -
            {{ formatDate(ticket.messages[ticket.messages.length - 1].date) }}
          </p>
        </div>
      </li>
    </ul> -->

    <!-- None -->
    <div v-else class="none">
      <h3>No support tickets created yet!</h3>
      <p>Please reach out to us for anything, we're happy to help.</p>
    </div>
  </section>

  <!-- Form -->
  <Modal
    title="Support Ticket Form"
    maxWidth="90%"
    :show="selectedTicket"
    @close="selectedTicket = null"
  >
    <SupportTicket
      :ticket="selectedTicket"
      :adminMode="true"
      @edited="editTicket($event)"
      @deleted="deleteTicket"
    />
  </Modal>
</template>

<script>
import SupportTicket from '@/views/support/SupportTicket.vue';

export default {
  components: {
    SupportTicket,
  },
  created() {
    this.init();
  },
  computed: {
    filterOptions() {
      return [
        { value: 'all', option: 'All' },
        { value: 'new', option: 'New' },
        { value: 'open', option: 'Open' },
        { value: 'closed', option: 'Closed' },
      ];
    },

    filteredTickets() {
      let tickets = [];

      if (!this.searchValue) {
        tickets = this.tickets;
      } else {
        tickets = this.tickets.filter((ticket) => {
          return ticket.title
            .toLowerCase()
            .includes(this.searchValue.toLowerCase());
        });
      }

      if (this.selectedFilter !== 'all') {
        tickets = tickets.filter((ticket) => {
          return ticket.status === this.selectedFilter;
        });
      }

      return tickets;
    },
  },
  data() {
    return {
      tickets: [],
      selectedFilter: 'all',
      selectedTicket: null,
      searchValue: '',
    };
  },
  methods: {
    formatDate(date) {
      return this.$moment(date).format('LLL');
    },

    async init() {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/supportTickets`
        );

        this.tickets = response.data.tickets.sort(
          (a, b) => new Date(b.last_reply) - new Date(a.last_reply)
        );
      } catch (err) {
        console.log(err);
      }
    },

    editTicket(ticket) {
      const index = this.tickets.findIndex((t) => t._id === ticket._id);

      if (index === -1) return;

      this.tickets.splice(index, 1, ticket);

      if (ticket.closeTicketModal) {
        this.selectedTicket = null;
      }
    },

    deleteTicket() {
      const index = this.tickets.findIndex(
        (t) => t._id === this.selectedTicket._id
      );

      if (index === -1) return;

      this.tickets.splice(index, 1);
      this.selectedTicket = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.support {
  margin: 32px auto;
  max-width: 800px;

  .blue {
    color: var(--clr-secondary);
  }
  .green {
    color: var(--clr-success);
  }
  .red {
    color: var(--clr-danger);
  }
  .yellow {
    color: var(--clr-warning);
  }

  .text-green {
    color: var(--clr-success);
  }
  .text-yellow {
    color: var(--clr-warning);
  }

  &_head {
    margin-bottom: 32px;

    h2 {
      font-size: 32px;
    }
    p {
      margin-top: 5px;
      color: var(--clr-gray);
    }
  }

  &_actions {
    padding: 16px;
    background-color: var(--clr-light);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filters,
    .buttons {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  .none {
    margin-top: 32px;
    padding: 16px;
    border: 1px solid var(--clr-light);
    border-radius: 5px;
    text-align: center;

    p {
      margin-top: 8px;
    }
  }

  &_tickets {
    margin-top: 32px;

    // .light {
    //   color: var(--clr-gray);
    // }
    // .small {
    //   font-size: 14px;
    // }
    // .mt-s {
    //   margin-top: 8px;
    // }

    // li {
    //   &:not(:first-child) {
    //     margin-top: 16px;
    //   }

    //   padding: 16px;
    //   border-radius: 5px;
    //   border: 1px solid var(--clr-light);
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    //   gap: 32px;
    //   cursor: pointer;

    //   .right {
    //     text-align: right;

    //     .status {
    //       font-size: 14px;
    //       text-transform: uppercase;
    //       font-weight: 700;
    //     }
    //   }
    // }
  }
}

// Mobile
@media (max-width: 600px) {
  .support {
    padding: 16px;

    &_head {
      text-align: center;
    }
  }
}
</style>
