<template>
  <div class="giftcardPage">
    <div class="giftcardPage_body">
      <div class="giftcardPage_body_logo">
        <TheLogo />
      </div>

      <div v-if="giftcard" class="giftcardPage_body_invoice">
        <ClientGiftCard :giftcard="giftcard" />
      </div>
    </div>
  </div>
</template>

<script>
import ClientGiftCard from '@/components/clients/ClientGiftCard.vue';

export default {
  components: {
    ClientGiftCard,
  },
  created() {
    this.init();
  },
  data() {
    return {
      giftcard: null,
    };
  },
  methods: {
    async init() {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/giftcards/${this.$route.params.id}?shopDetails=true`
        );

        this.giftcard = response.data.giftcard;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.giftcardPage {
  background-color: var(--clr-light);
  min-height: 100vh;
  display: grid;
  place-items: center;

  &_body {
    &_logo {
      text-align: center;
      margin-bottom: 32px;
    }
  }
}
</style>
