<template>
  <section class="bookingSettings">
    <div class="bookingSettings_head">
      <h2>Booking Settings</h2>
      <p>Provide a better experience for your clients when booking online.</p>
    </div>
    <div class="bookingSettings_body">
      <form @submit.prevent="submit" class="form">
        <div class="form_body">
          <BaseTextarea
            label="Promotion"
            subtitle="Display a message to your clients when they book online"
            :value="newSettings.promotion"
            @input="newSettings.promotion = $event"
          />

          <BaseInput
            label="Terms Of Service Link"
            subtitle="External link only, i.e. google docs (https://docs.google.com/document/d/{YOUR_GOOGLE_DOC_ID_HERE})"
            :value="newSettings.termsOfServiceLink"
            @input="newSettings.termsOfServiceLink = $event"
          />

          <!-- Cancellation Policy -->
          <div class="toggle">
            <Toggle
              :checked="newSettings.useQuickCancellationPolicy"
              @toggle="
                newSettings.useQuickCancellationPolicy =
                  !newSettings.useQuickCancellationPolicy
              "
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Quick Cancellation Policy</p>
              <p class="toggle_text_subtext">
                Write your own quick cancellation policy instead of a link to a
                document
              </p>
            </div>
          </div>

          <BaseInput
            v-if="!newSettings.useQuickCancellationPolicy"
            label="Cancellation Policy Link"
            subtitle="External link only, i.e. google docs (https://docs.google.com/document/d/{YOUR_GOOGLE_DOC_ID_HERE})"
            :value="newSettings.cancellationPolicyLink"
            @input="newSettings.cancellationPolicyLink = $event"
          />
          <BaseTextarea
            v-else
            label="Cancellation Policy"
            subtitle="Display a message to your clients when they book online"
            :value="newSettings.cancellationPolicyText"
            @input="newSettings.cancellationPolicyText = $event"
          />

          <div class="toggle">
            <Toggle
              :checked="newSettings.allowSameDayBookings"
              @toggle="
                newSettings.allowSameDayBookings =
                  !newSettings.allowSameDayBookings
              "
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Allow Same Day Bookings</p>
              <p class="toggle_text_subtext">
                Allow clients to book for the current day
              </p>
            </div>
          </div>

          <div class="toggle">
            <Toggle
              :checked="newSettings.useRequestedStaffIcon"
              @toggle="
                newSettings.useRequestedStaffIcon =
                  !newSettings.useRequestedStaffIcon
              "
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Use Requested Staff Icon</p>
              <p class="toggle_text_subtext">
                If enabled, when a client selects a specific staff member, a
                <i class="fas fa-heart mx-s red"></i> icon will appear on the
                appointment
              </p>
            </div>
          </div>

          <div class="toggle">
            <Toggle
              :checked="newSettings.collectPaymentInfo"
              @toggle="
                newSettings.collectPaymentInfo = !newSettings.collectPaymentInfo
              "
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Collect Payment Info</p>
              <p class="toggle_text_subtext">
                For no show fees, will only be used if you have a payment
                processor set up for virtual payments.
              </p>
            </div>
          </div>

          <div class="toggle">
            <Toggle
              :checked="newSettings.collectDeposit"
              @toggle="newSettings.collectDeposit = !newSettings.collectDeposit"
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Collect Deposit</p>
              <p class="toggle_text_subtext">
                Require a deposit for online bookings in percent of booking
                total <br />
                (Applies to all services, if you want to require a deposit for
                specific services, turn this option off and edit the service and
                set the deposit amount there)
              </p>
            </div>
          </div>

          <BaseInput
            v-if="newSettings.collectDeposit"
            inputType="number"
            :step="0.5"
            label="Deposit Amount (%)"
            :value="newSettings.depositPercent"
            @input="newSettings.depositPercent = +$event"
          />

          <div class="toggle">
            <Toggle
              :checked="newSettings.allowPhotoUploads"
              @toggle="
                newSettings.allowPhotoUploads = !newSettings.allowPhotoUploads
              "
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Enable Client Photo Uploads</p>
              <p class="toggle_text_subtext">
                Allow clients to upload photos during online booking; Cost ${{
                  salon.billing.additionalCharges.fileUploads
                    .clientBookingPhotosCost
                }}
                per file uploaded.
              </p>
            </div>
          </div>

          <BaseInput
            v-if="newSettings.allowPhotoUploads"
            inputType="number"
            :step="1"
            label="Maximum Number of Photos"
            :value="newSettings.photoUploadLimit"
            @input="newSettings.photoUploadLimit = +$event"
          />

          <BaseTextarea
            v-if="newSettings.allowPhotoUploads"
            label="Message to Client For Photo Uploads"
            subtitle="Display a message to your clients when asking them to upload photos"
            :value="newSettings.photoUploadMessage"
            @input="newSettings.photoUploadMessage = $event"
          />

          <div v-if="newSettings.allowPhotoUploads" class="imageSelect">
            <p>Image Upload Placeholder</p>

            <ImageSelect
              :fileSource="newSettings.photoUploadPlaceholder"
              @select="newSettings.photoUploadPlaceholder = $event"
            />
          </div>

          <div class="toggle">
            <Toggle
              :checked="newSettings.onlineShop"
              @toggle="newSettings.onlineShop = !newSettings.onlineShop"
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Enable Online Shop</p>
              <p class="toggle_text_subtext">
                Sell your products online! Set which products you would like to
                sell online by editing individual products.
              </p>
            </div>
          </div>

          <div class="toggle">
            <Toggle
              :checked="newSettings.onlineGiftCardsEnabled"
              @toggle="
                newSettings.onlineGiftCardsEnabled =
                  !newSettings.onlineGiftCardsEnabled
              "
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Enable Online Gift Cards</p>
              <p class="toggle_text_subtext">
                Allow clients to purchase gift cards online.
              </p>
            </div>
          </div>

          <BaseInput
            v-if="newSettings.onlineGiftCardsEnabled"
            inputType="number"
            :step="1"
            label="Minimum Gift Card Amount"
            :value="newSettings.onlineGiftCardMin"
            @input="newSettings.onlineGiftCardMin = +$event"
          />
          <BaseInput
            v-if="newSettings.onlineGiftCardsEnabled"
            inputType="number"
            :step="1"
            label="Maximum Gift Card Amount"
            :value="newSettings.onlineGiftCardMax"
            @input="newSettings.onlineGiftCardMax = +$event"
          />

          <BaseInput
            inputType="number"
            :step="0.5"
            :validators="validators.min"
            label="Minimum book time"
            subtitle="The least amount of time in advance required for a client to book an appointment (in hours)"
            :value="newSettings.minimumScheduleTimeHours"
            @input="newSettings.minimumScheduleTimeHours = +$event"
          />
          <BaseInput
            inputType="number"
            :step="1"
            :validators="validators.max"
            label="Maximum book time"
            subtitle="The most amount of time allowed for a client to book an appointment (in days)"
            :value="newSettings.maximumScheduleTimeDays"
            @input="newSettings.maximumScheduleTimeDays = +$event"
          />
          <BaseInput
            inputType="number"
            :step="1"
            :validators="validators.minMax"
            label="No Show Fee Charge (%)"
            subtitle="Percent of appointment total to charge when no show fee is charged"
            :value="newSettings.noShowFeePercent"
            @input="newSettings.noShowFeePercent = +$event"
          />

          <div class="toggle">
            <Toggle
              :checked="newSettings.sendConfirmationText"
              @toggle="
                newSettings.sendConfirmationText =
                  !newSettings.sendConfirmationText
              "
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Send Confirmation Text</p>
              <p class="toggle_text_subtext">
                Will send confirmation text to client; Charges apply. (Email
                confirmation is free and enabled by default)
              </p>
            </div>
          </div>

          <div class="toggle">
            <Toggle
              :checked="newSettings.sendReminderText"
              @toggle="
                newSettings.sendReminderText = !newSettings.sendReminderText
              "
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Send Reminder Text</p>
              <p class="toggle_text_subtext">
                Will send reminder text to client close to scheduled appointment
                time; Charges apply.
              </p>
            </div>
          </div>

          <BaseInput
            v-if="newSettings.sendReminderText"
            @input="newSettings.reminderTextNumberOfHoursBefore = +$event"
            :value="newSettings.reminderTextNumberOfHoursBefore"
            label="Hours Before"
            subtitle="Number of hours before appointment to send reminder text"
            inputType="number"
          />

          <div class="toggle">
            <Toggle
              :checked="newSettings.sendCancellationText"
              @toggle="
                newSettings.sendCancellationText =
                  !newSettings.sendCancellationText
              "
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Send Cancellation Text to Client</p>
              <p class="toggle_text_subtext">
                Will send cancellation text to client when appointment is
                cancelled; Charges apply.
              </p>
            </div>
          </div>

          <div class="toggle">
            <Toggle
              :checked="newSettings.sendClientConfirmAppointmentText"
              @toggle="
                newSettings.sendClientConfirmAppointmentText =
                  !newSettings.sendClientConfirmAppointmentText
              "
            />
            <div class="toggle_text">
              <p class="toggle_text_label">
                Send Client Confirm Appointment Text
              </p>
              <p class="toggle_text_subtext">
                Will send a text approximately 24h before appointment requesting
                client to confirm appointment
              </p>
            </div>
          </div>

          <div class="toggle">
            <Toggle
              :checked="newSettings.sendReviewEmail"
              @toggle="
                newSettings.sendReviewEmail = !newSettings.sendReviewEmail
              "
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Send Review Email</p>
              <p class="toggle_text_subtext">
                Will send an email to client after checkout requesting the
                client to leave a review; Charges apply.
              </p>
            </div>
          </div>

          <div class="toggle">
            <Toggle
              :checked="newSettings.allowClientsToDM"
              @toggle="
                newSettings.allowClientsToDM = !newSettings.allowClientsToDM
              "
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Allow Client Direct Messages</p>
              <p class="toggle_text_subtext">
                This will give authenticated clients an option to send a message
                directly to your salon. Client messages are free of charge.
              </p>
            </div>
          </div>

          <div class="toggle">
            <Toggle
              :checked="newSettings.sendEmailToClientUponDM"
              @toggle="
                newSettings.sendEmailToClientUponDM =
                  !newSettings.sendEmailToClientUponDM
              "
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Send Direct Message Email</p>
              <p class="toggle_text_subtext">
                Send an email to client notifying them that you sent them a
                message. Highly recommended.
              </p>
            </div>
          </div>

          <p class="light small">
            Confirmation/cancellation texts are ${{
              salon.marketing.texts.appointmentConfirmationsCost
            }}
            per | Reminder texts are ${{
              salon.marketing.texts.appointmentConfirmationsCost
            }}
            per | Review emails are ${{ salon.marketing.emails.cost }} per |
            Direct messaging emails are ${{ salon.marketing.emails.cost }} per
          </p>
        </div>
        <div class="form_actions">
          <BaseButton :disabled="loading || !formValid"
            ><i v-if="loading" class="fas fa-spinner"></i>Submit</BaseButton
          >
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import ImageSelect from '@/components/components/ImageSelect.vue';

export default {
  components: {
    ImageSelect,
  },
  created() {
    if (!this.salon) return;
    this.init();
  },
  data() {
    return {
      loading: false,

      newSettings: {
        noShowFeePercent: 15,
        allowSameDayBookings: true,
        collectPaymentInfo: true,
        onlineShop: false,
        onlineGiftCardsEnabled: false,
        onlineGiftCardMin: 5,
        onlineGiftCardMax: 500,
        minimumScheduleTimeHours: 2,
        maximumScheduleTimeDays: 90,
        sendConfirmationText: false,
        sendReminderText: false,
        sendCancellationText: false,
        reminderTextNumberOfHoursBefore: 3,
        sendClientConfirmAppointmentText: false,
        sendReviewEmail: false,
        allowClientsToDM: true,
        sendEmailToClientUponDM: false,
        promotion: '',
        termsOfServiceLink: '',
        cancellationPolicyLink: '',
        allowPhotoUploads: false,
        photoUploadLimit: 3,
        photoUploadMessage: '',
        photoUploadPlaceholder: '',
        collectDeposit: false,
        depositPercent: 0,
        useRequestedStaffIcon: true,
      },
    };
  },
  computed: {
    formValid() {
      if (
        this.newSettings.minimumScheduleTimeHours < 0 ||
        this.newSettings.maximumScheduleTimeDays < 1
      )
        return false;
      return true;
    },
    salon() {
      return this.$store.state.salon.salon;
    },
    validators() {
      return {
        min: {
          min: 0,
        },
        max: {
          min: 1,
        },
        minMax: {
          min: 0,
          max: 100,
        },
      };
    },
  },
  watch: {
    salon() {
      this.init();
    },
  },
  methods: {
    init() {
      if (!this.salon) return;

      this.newSettings = JSON.parse(JSON.stringify(this.salon.bookingSettings));
    },
    async submit() {
      this.loading = true;

      try {
        await this.$store.dispatch(
          'salon/updateBookingSettings',
          this.newSettings
        );

        this.$toast.success('Salon info updated');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.bookingSettings {
  &_head {
    h2 {
      font-size: 28px;
    }

    p {
      margin-top: 6px;
    }
  }

  &_body {
    margin-top: 32px;

    .light {
      color: var(--clr-gray);
    }
    .small {
      font-size: 14px;
    }
    .mx-s {
      margin: 0 4px;
    }
    .red {
      color: var(--clr-danger);
    }

    .form {
      &_body {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .toggle {
          display: flex;
          align-items: center;
          gap: 32px;
          flex-wrap: wrap;
          padding: 16px 0;

          &_text {
            &_label {
              font-size: 18px;
            }
            &_subtext {
              margin-top: 5px;
              font-size: 14px;
            }
          }
        }

        .imageSelect {
          padding: 16px;
          border: 1px solid var(--clr-light);
          border-radius: 5px;

          p {
            margin-bottom: 8px;
            font-size: 14px;
            color: var(--clr-gray);
            font-weight: 700;
          }
        }
      }
      &_actions {
        margin-top: 32px;
        display: flex;
        justify-content: flex-end;
        gap: 16px;
      }
    }
  }
}
</style>
