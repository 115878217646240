<template>
  <form @submit.prevent="submit" class="form">
    <div class="form_section">
      <div class="form_section_head">
        <h3>Edit {{ staff.firstName }}'s hours</h3>
        <p>{{ formattedDate }}</p>
      </div>
      <div class="form_section_body">
        <div v-for="(block, index) in newBlocks" :key="block" class="col-2">
          <BaseInput
            label="Shift start"
            inputType="time"
            :value="block.start"
            @input="newBlocks[index].start = $event"
          />
          <BaseInput
            label="Shift end"
            inputType="time"
            :value="block.end"
            @input="newBlocks[index].end = $event"
          />
          <i
            v-if="newBlocks.length > 1"
            class="fas fa-times"
            @click="newBlocks.splice(index, 1)"
          ></i>
        </div>
        <div class="button">
          <BaseButton
            type="button"
            mode="primary-outline small"
            @click="addBlock"
            >Add another shift</BaseButton
          >
        </div>
        <BaseSelect
          label="Repeats"
          :options="repeatOptions"
          :value="selectedRepeatOption"
          @input="selectedRepeatOption = $event"
        />
        <div
          v-if="selectedRepeatOption === 'norepeat'"
          class="alert alert-info"
        >
          <p>
            Schedule exceptions are stored for one month past exception date
          </p>
        </div>
      </div>
      <div class="form_actions">
        <div class="col">
          <BaseButton
            type="button"
            mode="danger-outline"
            @click="showConfirmDelete = true"
            >Remove</BaseButton
          >
        </div>
        <div class="col">
          <BaseButton
            v-if="selectedRepeatOption === 'weekly'"
            type="button"
            @click="copyToAll"
            mode="primary-outline"
            :disabled="loading"
            >Copy To All</BaseButton
          >
          <BaseButton :disabled="loading"
            ><i v-if="loading" class="fas fa-spinner"></i> Submit</BaseButton
          >
        </div>
      </div>
    </div>
  </form>

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Delete shift"
    text="Are you sure you wish to delete this shift?"
    @confirm="removeShift"
    @deny="showConfirmDelete = false"
  />
</template>

<script>
export default {
  emits: ['submit', 'remove', 'copyToAll'],
  props: {
    staff: {
      type: Object,
      required: true,
    },
    date: {
      type: Date,
      required: true,
    },
    blocks: {
      type: Array,
      required: true,
    },
    exception: {
      type: Boolean,
    },
  },
  created() {
    this.newBlocks = JSON.parse(JSON.stringify(this.blocks));
    if (this.exception) this.selectedRepeatOption = 'norepeat';
  },
  data() {
    return {
      newBlocks: [],
      selectedRepeatOption: 'weekly',
      showConfirmDelete: false,
      loading: false,
    };
  },
  computed: {
    repeatOptions() {
      return [
        {
          option: 'Weekly',
          value: 'weekly',
        },
        {
          option: "Don't repeat",
          value: 'norepeat',
        },
      ];
    },
    formattedDate() {
      return this.$moment(this.date).format('dddd, LL');
    },
  },
  methods: {
    copyToAll() {
      this.$emit('copyToAll', this.newBlocks);
    },
    addBlock() {
      if (this.newBlocks.length) {
        this.newBlocks.push(
          this.getNewBlockTimes(this.newBlocks[this.newBlocks.length - 1])
        );
      } else {
        this.newBlocks.push({
          start: '09:00',
          end: '17:00',
        });
      }
    },
    getNewBlockTimes(previousBlock) {
      const block = {
        start: null,
        end: null,
      };

      const prevEndTime = +previousBlock.end.split(':')[0];

      block.start = (prevEndTime + 1).toString() + ':00';
      block.end = (prevEndTime + 2).toString() + ':00';

      return block;
    },
    submit() {
      let endTimeBeforeStartTime = false;

      this.newBlocks.forEach((block) => {
        const startTime = +block.start.split(':')[0];
        const endTime = +block.end.split(':')[0];

        if (startTime > endTime) endTimeBeforeStartTime = true;
      });

      if (endTimeBeforeStartTime) {
        this.$toast.error('Start time can not be before end time');
        return;
      }

      this.$emit('submit', {
        blocks: this.newBlocks,
        repeat: this.selectedRepeatOption,
      });
    },
    removeShift() {
      this.$emit('submit', {
        blocks: [],
        repeat: this.selectedRepeatOption,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  padding: 32px;
  &_section {
    // padding: 32px;
    // border: 1px solid var(--clr-light-2);
    border-radius: 5px;

    &:not(:first-child) {
      margin-top: 16px;
    }

    &_head {
      text-align: center;
      margin-bottom: 16px;

      p {
        font-size: 14px;
        margin-top: 5px;
        color: var(--clr-gray);
      }
    }

    .col-2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      position: relative;

      i {
        position: absolute;
        top: 0;
        right: 0;
        color: var(--clr-gray);
        cursor: pointer;
      }
    }
    .col-3 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
    }
    .col-4 {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;
    }

    &_body {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;
    }
  }

  .alert {
    padding: 12px;
    font-size: 14px;
    border-radius: 5px;

    &-info {
      background-color: #c3edf7;
      color: #044653;
      border-color: #aceaf7;
    }
  }

  &_actions {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;

    .col {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
    }
  }
}
</style>
