<template>
  <section class="service" :id="ticket._id">
    <div class="service_head">
      <div class="line">
        <p class="bold">Client:</p>
        <p v-if="ticket.client">
          {{ `${ticket.client.firstName} ${ticket.client.lastName}` }}
        </p>
      </div>
      <div class="line">
        <p class="bold">Service Provider:</p>
      </div>
      <div class="line">
        <p class="bold">Date:</p>
        <p>{{ date }}</p>
      </div>
      <div class="line">
        <p class="bold">Front Desk:</p>
      </div>
    </div>
    <div class="service_services">
      <table>
        <tr>
          <th>Service</th>
          <th>Price</th>
        </tr>
        <tr v-for="i in 5" :key="i">
          <td></td>
          <td></td>
        </tr>
      </table>
    </div>
    <div class="service_additionalServices">
      <table>
        <tr>
          <th>Additional</th>
          <th>Price</th>
        </tr>
        <tr v-for="i in 3" :key="i">
          <td></td>
          <td></td>
        </tr>
      </table>
    </div>
    <div class="service_other">
      <div class="line">
        <p class="bold">Next Visit:</p>
      </div>
      <div class="line">
        <p class="bold">Service to be performed:</p>
      </div>
      <div class="line" v-for="i in 3" :key="i">
        <p class="bold">Home Care #{{ i }}:</p>
      </div>
      <div class="line">
        <p class="bold">Total:</p>
      </div>
    </div>
    <div class="service_payments">
      <p>Cash</p>
      <p>Card</p>
      <p>Gift</p>
      <p>Other</p>
    </div>
  </section>

  <div class="actions">
    <div class="actions_actions">
      <BaseButton @click="print">Print</BaseButton>
    </div>
  </div>
</template>

<script>
import printJS from 'print-js';

export default {
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  computed: {
    date() {
      return this.$moment().format('LLL');
    },
  },
  methods: {
    print() {
      printJS({
        printable: this.ticket._id,
        type: 'html',
        style: `
        .line { border-bottom: 1px solid #999999; display: flex; gap: 16px; padding-bottom: 8px; }
        .line:not(:first-child) { margin-top: 16px; }
        .bold { font-weight: 700 }
        table { width: 100%; border-collapse: collapse; }
        table td { border: 1px solid #999999; }
        table tr { height: 36px; }
        .service_services, .service_other, .service_payments { margin-top: 32px; }
        .service_additionalServices { margin-top: 32px; }
        .service_payments { margin-top: 64px; display: flex; justify-content: space-between; }
        `,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.service {
  padding: 16px;
  border: 1px solid var(--clr-light);
  border-radius: 5px;

  .line {
    border-bottom: 1px solid var(--clr-light);
    display: flex;
    gap: 16px;
    padding-bottom: 8px;

    &:not(:first-child) {
      margin-top: 16px;
    }

    .bold {
      font-weight: 700;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    td {
      border: 1px solid var(--clr-light);
    }

    tr {
      height: 36px;
    }
  }

  &_services,
  &_other,
  &_payments {
    margin-top: 32px;
  }

  &_payments {
    display: flex;
    justify-content: space-between;
  }
}

.actions {
  margin-top: 32px;

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
