<template>
  <section class="moreFeatures">
    <div class="moreFeatures_head">
      <h2>More Features:</h2>
    </div>
    <div class="moreFeatures_body">
      <div class="feature">
        <h3>Calendar</h3>
        <p>
          Raserva's calendar allows you to view the schedule of multiple team
          members on one screen.
        </p>
      </div>
      <div class="feature">
        <h3>Client Tracking</h3>
        <p>
          Make every visit the best visit for your clients by tracking past and
          future appointments, previous purchases, and keep detailed notes.
        </p>
      </div>
      <div class="feature">
        <h3>Online Booking</h3>
        <p>
          Accept bookings 24/7 with online booking. Your clients can book
          anywhere, anytime, from any device.
        </p>
      </div>
      <div class="feature">
        <h3>Reports</h3>
        <p>
          Our dashboard, reports, tables, and charts keep track of everything
          about your business.
        </p>
      </div>
      <div class="feature">
        <h3>Point of Sale / Card Processing</h3>
        <p>
          Raserva offers multiple payment processing solutions to provide you
          with the experience you desire including cash discount!
        </p>
      </div>
      <div class="feature">
        <h3>Auto Campaigns</h3>
        <p>
          Set it and forget it! Keep your clients coming back by setting up our
          auto-campaigns designed to increase client retention.
        </p>
      </div>
      <div class="feature">
        <h3>Gift Cards</h3>
        <p>
          Simple gift card management and tracking, with the ability of
          no-card-present transactions.
        </p>
      </div>
      <div class="feature">
        <h3>Subscriptions & Memberships</h3>
        <p>
          Let us handle automatic billing for your recurring memberships with
          service usage tracking on highly customizable packages.
        </p>
      </div>
      <div class="feature">
        <h3>Marketing Blasts</h3>
        <p>
          Promote your business with marketing blasts! Special day, limited time
          discount, new products, etc.. Let your clients know and entice them
          into your business!
        </p>
      </div>
      <div class="feature">
        <h3>& So Much More</h3>
        <p>
          Plenty more features to handle anything you need to run smoothly.
          Raserva is here to please you, with new and improved features
          consistently.
        </p>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.moreFeatures {
  &_head {
    text-align: center;
  }
  &_body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    margin-top: 64px;

    .feature {
      border: 1px solid var(--clr-light);
      padding: 32px;
      border-radius: 10px;

      h3 {
        font-weight: 700;
        color: var(--clr-secondary-dark);
      }

      p {
        color: var(--clr-gray);
        margin-top: 16px;
        line-height: 24px;
      }
    }
  }
}

// Tablet
@media (max-width: 900px) {
  .moreFeatures {
    &_body {
      grid-template-columns: 1fr;
    }
  }
}
</style>
