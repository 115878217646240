import axios from 'axios';

import router from '../router/index';

export default {
  namespaced: true,
  state: {
    admin: null,
    token: null,
  },
  actions: {
    async login({ state }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin/login`,
          {
            email: payload.email,
            password: payload.password,
          }
        );

        state.admin = response.data.admin;
        state.token = response.data.token;

        localStorage.setItem('adminToken', response.data.token);
        localStorage.setItem('adminId', response.data.admin._id);
      } catch (e) {
        console.log(e.message);
      }
    },

    signOut({ state }) {
      state.admin = null;
      state.token = null;

      localStorage.removeItem('adminToken');
      localStorage.removeItem('adminId');
    },

    async create(_, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin`,
          payload
        );

        return response.data;
      } catch (e) {
        console.log(e.message);
      }
    },

    async getAll() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin`
        );

        return response.data.admins;
      } catch (e) {
        console.log(e.message);
      }
    },

    async updateOne(_, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin/${payload._id}`,
          payload
        );

        return response.data.admin;
      } catch (e) {
        console.log(e.message);
      }
    },

    async deleteOne(_, payload) {
      try {
        const response = await axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin/${payload._id}`
        );

        return response.data;
      } catch (e) {
        console.log(e.message);
      }
    },

    async getUsers(_, payload) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin/salons?${payload}`
        );

        return response.data.salons;
      } catch (e) {
        console.log(e.message);
      }
    },

    async autoLogin({ rootState, dispatch }, payload) {
      try {
        // Leave current rooms
        const socketSalonRoom = localStorage.getItem('salonId');
        const socketStaffRoom = localStorage.getItem('staffId');

        if (socketSalonRoom) {
          await dispatch('sockets/leave', socketSalonRoom, {
            root: true,
          });
        }
        if (socketStaffRoom) {
          await dispatch('sockets/leave', socketStaffRoom, {
            root: true,
          });
        }

        rootState.socket = null;
        rootState.salon.salon = null;
        rootState.auth.salon = null;

        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin/autoLogin?email=${payload}`
        );

        localStorage.removeItem('salonId');
        localStorage.removeItem('staffId');
        localStorage.removeItem('token');

        rootState.auth.token = response.data.token;
        rootState.auth.user = response.data.staff;

        await dispatch('sockets/join', response.data.staff.user._id, {
          root: true,
        });
        await dispatch(
          'auth/saveStorage',
          {
            salonId: '',
            staffId: response.data.staff._id,
            token: response.data.token,
          },
          { root: true }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    async autoAuth({ state }) {
      const token = localStorage.getItem('adminToken');
      const adminId = localStorage.getItem('adminId');

      if (!token) return;

      state.token = token;

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/admin/${adminId}`
        );

        state.admin = response.data.admin;

        if (router.currentRoute._value.fullPath.includes('/admin')) {
          router.push({
            name: 'AdminPanel',
          });
        }
      } catch (error) {
        throw Error(error.message);
      }
    },
  },
};
