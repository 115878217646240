<template>
  <section class="hourly">
    <div class="hourly_head">
      <h2>Team Shifts</h2>

      <BaseButton @click="showCreateShiftForm = true" mode="primary"
        >Create Shift</BaseButton
      >
    </div>
    <div class="hourly_drb">
      <DateRangeBar @search="search($event.starting, $event.ending)">
        <BaseSelect
          label="Staff"
          :options="staffSelectOptions"
          :value="selectedStaff"
          @input="selectStaff($event)"
        />

        <div class="checkbox">
          <label for="datastaff">Display Non Hourly</label>
          <input
            type="checkbox"
            id="datastaff"
            :checked="displayNonHourly"
            @input="displayNonHourly = !displayNonHourly"
          />
        </div>

        <div v-if="selectedStaff !== 'all'" class="checkbox">
          <label for="hourlyInvoice">Display Invoice</label>
          <input
            type="checkbox"
            id="hourlyInvoice"
            :checked="displayInvoice"
            @input="displayInvoice = !displayInvoice"
          />
        </div>

        <div class="checkbox">
          <label for="revokedCheckbox">Display Revoked Members</label>
          <input
            type="checkbox"
            id="revokedCheckbox"
            :checked="displayRevokedMembers"
            @input="displayRevokedMembers = !displayRevokedMembers"
          />
        </div>

        <div v-if="canViewAll" class="drb-actions"></div>
      </DateRangeBar>
    </div>
    <Spinner v-if="loading" />

    <!-- OT Alert -->
    <div v-if="overtimeEnabled" class="alert alert-info">
      <p>Overtime is calculated based off of current date range</p>
    </div>

    <div class="hourly_tables" v-if="!selectedStaffEmpty">
      <div class="hourly_tables_table">
        <Table
          :headers="salesHeaders"
          :data="salesDataWithoutId"
          @rowClick="selectStaffRow($event)"
        />
      </div>
    </div>

    <div v-else class="hourly_none">
      <h4>This staff has no data to display!</h4>
      <p>Select another staff or search a new date range.</p>
    </div>

    <div class="hourly_invoices" v-if="displayInvoice">
      <SalesHourlyInvoice
        v-for="staff in salesDataWithoutId"
        :key="staff"
        :data="staff"
        :staff="selectedStaffObject"
        :start="start"
        :end="end"
        :shifts="shifts"
      />
    </div>

    <div
      class="hourly_breakdown"
      v-if="
        salesDataWithoutId &&
        salesDataWithoutId.length === 1 &&
        selectedStaff !== 'all'
      "
    >
      <h2>Shift Breakdown</h2>
      <div class="hourly_breakdown_tables">
        <div class="hourly_breakdown_tables_table">
          <Table
            :headers="shiftsHeaders"
            :data="shiftsData"
            @rowClick="selectShift($event)"
          />
        </div>
      </div>
    </div>
  </section>

  <Modal
    title="Shift form"
    :show="selectedShift || showCreateShiftForm"
    @close="
      selectedShift = null;
      showCreateShiftForm = false;
    "
  >
    <div class="selectedShift">
      <ShiftForm :shift="selectedShift" @submitted="search" />
    </div>
  </Modal>

  <Confirm
    v-if="showYouDoNotHavePerm"
    title="Not permitted"
    text="You do not have permission to view all staff"
    @confirm="showYouDoNotHavePerm = false"
  />

  <Modal
    title="Enter Staff Account Code"
    :show="showStaffAccountCodeModal"
    @close="showStaffAccountCodeModal = false"
  >
    <form @submit.prevent="submitStaffAccountCode" class="accountCodeForm form">
      <BaseInput
        inputType="password"
        label="Account Code"
        @input="staffAccountCode = $event"
      />
      <div class="form_actions">
        <BaseButton @click="createShift">Submit</BaseButton>
      </div>
    </form>
  </Modal>
</template>

<script>
import DateRangeBar from '@/components/components/DateRangeBar.vue';
import ShiftForm from '@/components/staff/ShiftForm.vue';
import SalesHourlyInvoice from '@/views/sales/SalesHourlyInvoice.vue';

export default {
  components: {
    DateRangeBar,
    ShiftForm,
    SalesHourlyInvoice,
  },
  async created() {
    this.selectedStaff = this.$store.getters['auth/userId'];
    this.staff = JSON.parse(JSON.stringify(this.$store.state.staff.staff));

    await this.setStaffSelectOptions();
    await this.setCanViewAll();

    this.search(this.$moment().startOf('day'), this.$moment().endOf('day'));
  },
  computed: {
    loggedInStaffIsAdmin() {
      return (
        this.$store.state.auth.loggedInSalonStaff.staffId ===
        this.$store.state.auth.salon.adminId
      );
    },
    salesDataWithoutId() {
      if (!this.salesData) return;

      return this.salesData.map(({ _id, ...rest }) => {
        //  Literally have to do something with _id or Vue complains
        //  that we're not doing anything with _id LMAO
        +_id++;
        return rest;
      });
    },
    selectedStaffEmpty() {
      return (
        this.selectedStaff !== 'all' &&
        this.salesData &&
        this.salesData.length === 0
      );
    },
    selectedStaffObject() {
      if (!this.selectedStaff) return null;

      const salonStaff = this.$store.state.auth.salon.staff.find(
        (staff) => staff.staffId === this.selectedStaff
      );

      if (salonStaff) {
        return salonStaff;
      } else {
        const deletedStaff = this.deletedMembers.find(
          (staff) => staff.staffId === this.selectedStaff
        );

        return deletedStaff;
      }
    },

    overtimeEnabled() {
      return this.$store.state.auth.salon.adminSettings.overtimeEnabled;
    },
    overtimeStart() {
      return this.$store.state.auth.salon.adminSettings.overtimeStart;
    },
    overtimeRate() {
      return this.$store.state.auth.salon.adminSettings.overtimeRate;
    },
  },
  data() {
    return {
      loading: false,

      staffSelectOptions: [],
      selectedStaff: null,
      selectedShift: null,

      showCreateShiftForm: false,

      staff: [],
      shifts: [],

      displayNonHourly: false,
      displayInvoice: false,
      displayRevokedMembers: false,

      salesHeaders: [
        'Staff',
        'Shifts',
        'Unique Days',
        'Total Hours',
        'Earnings',
        'Earnings w/ OT',
      ],
      salesData: null,
      allSalesData: null,

      shiftsHeaders: [
        'Staff',
        'Clock In By',
        'Shift Id',
        'Clock In',
        'Clock Out',
        'Hourly Rate',
        'Earnings',
      ],
      shiftsData: null,

      showStaffAccountCodeModal: false,
      staffAccountCode: '',
      showYouDoNotHavePerm: false,

      canViewAll: false,
    };
  },
  watch: {
    displayNonHourly() {
      this.setStaffSelectOptions();
    },
    displayRevokedMembers() {
      this.setStaffSelectOptions();
    },
  },
  methods: {
    async selectStaff(staff) {
      this.selectedStaff = staff;

      // Check require code permission
      if (
        staff !== this.$store.state.auth.loggedInSalonStaff._id &&
        !this.$store.state.auth.managerMode &&
        !this.loggedInStaffIsAdmin
      ) {
        if (
          await this.$store.dispatch(
            'auth/activeUserHasPermission',
            'tickets/codeToViewOthersReports'
          )
        ) {
          if (staff === 'all') {
            this.showYouDoNotHavePerm = true;
            return;
          }

          this.showStaffAccountCodeModal = true;
          return;
        }
      }

      this.setDisplayedStaff();
      this.addShiftToShiftData();
    },

    selectStaffRow(index) {
      this.selectStaff(this.salesData[index]._id);
    },

    async setCanViewAll() {
      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'tickets/editShifts'
        )
      )
        this.canViewAll = true;
    },

    async selectShift(index) {
      if (
        !(await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'tickets/editShifts'
        ))
      )
        return;
      if (this.shiftsData[index].shiftId) {
        this.selectedShift = this.shifts.find((shift) => {
          return shift._id === this.shiftsData[index].shiftId;
        });
      }
    },

    submitStaffAccountCode() {
      const salonStaff = this.$store.state.auth.salon.staff.find((staff) => {
        return staff.staffId.toString() === this.selectedStaff.toString();
      });

      if (!salonStaff) return;

      if (this.staffAccountCode !== salonStaff.accountCode) {
        this.$toast.error('Incorrect staff code');
        this.selectedStaff = this.$store.state.auth.loggedInSalonStaff.staffId;
      }

      this.setDisplayedStaff();
      this.addShiftToShiftData();

      this.showStaffAccountCodeModal = false;
      this.staffAccountCode = '';
    },

    async setStaffSelectOptions() {
      const staff = [];
      const activeStaff = this.$store.state.auth.loggedInSalonStaff;

      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'tickets/viewOthersReports'
        )
      ) {
        staff.push({
          option: 'All',
          value: 'all',
        });

        this.staff
          .filter((s) => (this.displayRevokedMembers ? true : !s.isDeleted))
          .sort((a, b) => a.firstName.localeCompare(b.firstName))
          .forEach((prestaff) => {
            if (this.displayNonHourly && !prestaff.trackHourly) return;

            staff.push({
              option: `${prestaff.firstName} ${prestaff.lastName}`,
              value: prestaff.staffId,
            });
          });
      } else {
        staff.push({
          option: `${activeStaff.firstName} ${activeStaff.lastName}`,
          value: activeStaff.staffId,
        });
      }

      this.staffSelectOptions = staff;
    },

    setDisplayedStaff() {
      if (this.selectedStaff === 'all') {
        this.salesData = this.allSalesData;
      } else {
        const salesData = [];

        const index = this.allSalesData.findIndex((data) => {
          return data._id === this.selectedStaff;
        });

        if (index !== -1) {
          salesData.push(this.allSalesData[index]);
        }

        this.salesData = salesData;
      }
    },

    async search(start, end) {
      this.loading = true;
      this.selectedShift = null;

      if (start && end) {
        this.start = start;
        this.end = end;
      }

      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_RASERVA_BACKEND}/shifts?salonId=${this.$store.state.auth.salon._id}&start=${this.start}&end=${this.end}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.setSales({ shifts: response.data.shifts });
      } catch (error) {
        console.log(error);
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    setSales(data) {
      // Reset
      this.resetSales();

      // Shifts
      this.shifts = data.shifts;

      this.shifts.forEach((shift) => {
        this.addShiftDataToSalesData(shift);
      });

      // Overtime
      this.calculateOvertime();

      this.addShiftToShiftData();

      // Formatting
      this.formatTotals();

      // Display correct staff(s)
      this.setDisplayedStaff();
    },

    resetSales() {
      this.shiftsData = [];

      this.salesData = [
        {
          _id: 'All',
          staff: 'All Staff',
          shifts: 0,
          uniqueDays: 0,
          totalHours: 0,
          earnings: 0,
          earningsWithOvertime: 0,
        },
      ];

      this.allSalesData = [...this.salesData];

      this.staff.forEach((staff) => (staff.uniqueDates = []));
    },

    addShiftDataToSalesData(shift) {
      let index = this.allSalesData.findIndex((staff) => {
        return staff._id === shift.staffId;
      });

      const staff = this.staff.find((staff) => {
        return staff.staffId === shift.staffId;
      });

      if (!staff) return;

      if (index === -1) {
        this.allSalesData.push({
          _id: staff.staffId,
          staff: `${staff.firstName} ${staff.lastName}`,
          shifts: 0,
          uniqueDays: 0,
          totalHours: 0,
          earnings: 0,
          earningsWithOvertime: 0,
        });

        index = this.allSalesData.length - 1;
      }

      // Unique days
      const dateIndex = staff.uniqueDates.findIndex(
        (date) => date === this.$moment(shift.clockOut).format('LL')
      );

      if (dateIndex === -1) {
        // First shift of day
        staff.uniqueDates.push(this.$moment(shift.clockOut).format('LL'));
        this.allSalesData[index].uniqueDays++;
        this.allSalesData[0].uniqueDays++;
      }

      this.allSalesData[index].shifts++;
      this.allSalesData[0].shifts++;

      const minutes = this.$moment(shift.clockOut).diff(
        this.$moment(shift.clockIn),
        'minutes'
      );

      this.allSalesData[index].totalHours += minutes / 60;
      this.allSalesData[0].totalHours += minutes / 60;

      const earnings = shift.rate * (minutes / 60);

      this.allSalesData[index].earnings += earnings;
      this.allSalesData[0].earnings += earnings;
    },

    addShiftToShiftData() {
      this.shiftsData = [];

      this.shifts.forEach((shift) => {
        if (shift.staffId !== this.selectedStaff) return;

        const staff = this.staff.find((staff) => {
          return staff.staffId === shift.staffId;
        });

        const minutes = this.$moment(shift.clockOut).diff(
          this.$moment(shift.clockIn),
          'minutes'
        );

        this.shiftsData.push({
          staff: `${staff.firstName} ${staff.lastName}`,
          clockInBy: shift.clockedInBy,
          shiftId: shift._id,
          clockIn: shift.clockIn,
          clockOut: shift.clockOut,
          hourlyRate: shift.rate,
          earnings: shift.rate * (minutes / 60),
        });
      });

      this.shiftsData.forEach((shift) => {
        shift.clockIn = this.$moment(shift.clockIn).format('LLL');
        shift.clockOut = this.$moment(shift.clockOut).format('LLL');
        shift.hourlyRate = `$${shift.hourlyRate.toFixed(2)}`;
        shift.earnings = `$${shift.earnings.toFixed(2)}`;
      });
    },

    calculateOvertime() {
      this.allSalesData.forEach((staff) => {
        if (this.overtimeEnabled) {
          if (staff.totalHours > this.overtimeStart) {
            const avgRate = staff.earnings / staff.totalHours;
            const overtimeHours = staff.totalHours - this.overtimeStart;
            const overtimeEarnings =
              overtimeHours * avgRate * this.overtimeRate -
              overtimeHours * avgRate;

            staff.earningsWithOvertime = staff.earnings + overtimeEarnings;
          } else {
            staff.earningsWithOvertime = staff.earnings;
          }
        } else {
          staff.earningsWithOvertime = staff.earnings;
        }
      });
    },

    formatTotals() {
      this.allSalesData.forEach((data) => {
        data.earnings = `$${data.earnings.toFixed(2)}`;
        data.earningsWithOvertime = `$${data.earningsWithOvertime.toFixed(2)}`;
        data.totalHours = data.totalHours.toFixed(2);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.hourly {
  width: 100%;

  &_head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 28px;
    }
  }

  &_drb {
    margin-top: 32px;

    .checkbox {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 5px;
        color: var(--clr-gray-2);
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  .alert {
    padding: 12px;
    font-size: 14px;
    border-radius: 5px;
    margin-top: 32px;

    &-info {
      background-color: #c3edf7;
      color: #044653;
      border-color: #aceaf7;
    }
  }

  &_invoices {
    margin-top: 32px;
    max-width: 500px;
  }

  &_tables {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
    flex-wrap: wrap;

    &_table {
      flex-grow: 1;
    }
  }

  &_none {
    padding: 32px;

    p {
      margin-top: 5px;
    }
  }

  &_breakdown {
    margin-top: 32px;

    h2 {
      font-size: 18px;
      margin-bottom: 16px;
    }

    &_tables {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 16px;
      flex-wrap: wrap;

      &_table {
        flex-grow: 1;
      }
    }
  }
}

.drb-actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.accountCodeForm,
.form {
  padding: 32px;

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}

.selectedShift {
  padding: 32px;
}
</style>
