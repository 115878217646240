<template>
  <form @submit.prevent="submit" class="form">
    <BaseInput
      label="Name"
      :value="newCalendar.name"
      @input="newCalendar.name = $event"
    />

    <BaseInput
      label="Description"
      :value="newCalendar.description"
      @input="newCalendar.description = $event"
    />

    <div class="form_actions">
      <BaseButton
        v-if="newCalendar.id"
        @click="showConfirmDelete = true"
        type="button"
        mode="danger"
        :disabled="loading"
        ><i v-if="loading" class="fa fa-spinner"></i> Delete</BaseButton
      >
      <BaseButton :disabled="loading"
        ><i v-if="loading" class="fa fa-spinner"></i> Submit</BaseButton
      >
    </div>
  </form>

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Delete calendar"
    text="Are you sure you wish to delete this calendar? This action can not be undone"
    @confirm="deleteCalendar"
    @deny="showConfirmDelete = false"
  />
</template>

<script>
export default {
  emits: ['submit', 'removeCalendar'],
  props: {
    calendar: {
      type: Object,
      required: false,
    },
  },
  created() {
    if (this.calendar)
      this.newCalendar = JSON.parse(JSON.stringify(this.calendar));
  },
  data() {
    return {
      loading: false,
      showConfirmDelete: false,

      newCalendar: {
        name: '',
        description: '',
      },
    };
  },
  methods: {
    async deleteCalendar() {
      this.loading = true;

      try {
        await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/highLevel/calendars/${this.newCalendar.id}?salonId=${this.$store.state.auth.salon._id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$emit('removeCalendar');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    async submit() {
      this.loading = true;

      try {
        if (this.newCalendar.id) {
          // Update
          const response = await this.$axios.put(
            `${process.env.VUE_APP_RASERVA_BACKEND}/highLevel/calendars/${this.newCalendar.id}?salonId=${this.$store.state.auth.salon._id}`,
            this.newCalendar,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );

          this.$emit('submit', response.data.calendar);
        } else {
          // Create
          const response = await this.$axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/highLevel/calendars?salonId=${this.$store.state.auth.salon._id}`,
            this.newCalendar,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );

          this.$emit('submit', response.data.calendar);
        }
      } catch (err) {
        console.log(err);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
