<template>
  <section class="visuals">
    <div class="visuals_head">
      <h2>Reports</h2>
      <p>Track and manage your business stats</p>
    </div>

    <div class="visuals_actions">
      <DateRangeBar
        :start="start"
        :end="end"
        @search="getAppsAndTickets($event.starting, $event.ending)"
      >
        <slot>
          <BaseSelect
            label="Staff"
            :options="staffOptions"
            :value="selectedStaffOption"
            @input="selectedStaffOption = $event"
          />
        </slot>
      </DateRangeBar>
    </div>

    <div class="visuals_body">
      <Spinner v-if="loading" />

      <div v-if="!loading && appsAndTickets" class="visuals_body_comparisons">
        <ReportComparisons
          :selectedStaffOption="selectedStaffOption"
          :appointments="appsAndTickets.appointments"
          :tickets="appsAndTickets.tickets"
          :from="from"
          :to="to"
        />
      </div>

      <div v-if="!loading && summary" class="visuals_body_graphs">
        <AppAndSalesGraphs
          :summary="summary"
          :selectedStaffOption="selectedStaffOption"
        />
      </div>
    </div>
  </section>
</template>

<script>
import AppAndSalesGraphs from '@/components/reports/AppAndSalesGraphs.vue';
import ReportComparisons from '@/components/reports/ReportComparisons.vue';
import DateRangeBar from '@/components/components/DateRangeBar.vue';

export default {
  components: {
    AppAndSalesGraphs,
    ReportComparisons,
    DateRangeBar,
  },
  created() {
    this.init();
  },
  computed: {
    staffOptions() {
      const options = [
        {
          option: 'All staff',
          value: 'all',
        },
      ];

      this.$store.state.staff.staff.forEach((staff) => {
        options.push({
          option: `${staff.firstName} ${staff.lastName}`,
          value: staff.staffId,
        });
      });

      return options;
    },
  },
  data() {
    return {
      loading: true,
      selectedStaffOption: 'all',
      summary: null,
      appsAndTickets: null,

      from: null,
      to: null,
    };
  },
  methods: {
    init() {
      this.from = this.$moment().format('YYYY-MM-DD');
      this.to = this.$moment().format('YYYY-MM-DD');

      this.getAppsAndTickets();
      this.getSummary();
    },
    async getSummary() {
      this.loading = true;

      try {
        this.summary = await this.$store.dispatch('salon/getSummary');
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
    },
    async getAppsAndTickets(from, to) {
      if (from) this.from = from;
      if (to) this.to = to;

      this.loading = true;

      const days =
        this.$moment(this.to).diff(this.$moment(this.from), 'days') || 1;

      if (days > 62) {
        this.$toast.error('You can only view up to 4 months worth of data');
        return;
      }

      try {
        this.appsAndTickets = await this.$store.dispatch('salon/getSummary', {
          days,
          double: true,
        });
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.visuals {
  width: 100%;
  max-width: 1400px;

  &_head {
    h2 {
      font-size: 28px;
    }
    p {
      margin-top: 6px;
      color: var(--clr-gray);
    }
  }

  &_actions {
    margin-top: 32px;
  }

  &_body {
    margin-top: 32px;

    &_comparisons {
      margin-bottom: 32px;
    }
  }
}
</style>
