<template>
  <section class="chats">
    <form @submit.prevent="searchChats" class="chats_head">
      <BaseButton
        type="button"
        @click="showSelectClient = true"
        v-if="!clientMode"
        >Compose</BaseButton
      >
      <BaseInput
        placeholder="Search Recepients.."
        icon="fas fa-search"
        @input="searchValue = $event"
        @icon-click="searchChats"
      />
    </form>
    <ul class="chats_list">
      <li
        @click="$emit('select', chat)"
        v-for="chat in displayedChats"
        :key="chat._id"
        :class="{ selected: selectedChatId === chat._id }"
      >
        <Avatar
          :name="getAvatarName(chat)"
          :image="getAvatarImage(chat)"
          mode="rounded large"
        />
        <div class="text">
          <p class="bold">
            {{ getChatName(chat) }}
          </p>
          <p class="light small mt-s">
            {{ formatDate(getLastMessage(chat).date) }}
          </p>
        </div>
        <div
          v-if="!getLastMessage(chat).read && lastMessageIsNotMe(chat)"
          class="unread"
        ></div>
      </li>
    </ul>
  </section>

  <Modal
    title="Search Clients"
    :show="showSelectClient"
    @close="showSelectClient = false"
  >
    <div class="clientSearch">
      <ClientSearch @selected="selectClient($event)" />
    </div>
  </Modal>
</template>

<script>
import ClientSearch from '@/components/clients/ClientSearch.vue';

export default {
  emits: ['select', 'compose'],
  props: {
    chats: {
      type: Array,
      default: () => [],
    },
    selectedChatId: {
      type: String,
    },
    clientMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ClientSearch,
  },
  created() {
    this.init();
  },
  watch: {
    chats() {
      this.init();
    },
    activeClient() {
      this.init();
    },
  },
  computed: {
    activeClient() {
      return this.$store.state.booking.activeClient;
    },
  },
  data() {
    return {
      allChats: [],
      displayedChats: [],
      searchValue: '',

      showSelectClient: false,
    };
  },
  methods: {
    init() {
      if (this.clientMode) {
        if (!this.$store.state.booking.activeClient) return;

        this.$store.state.messenger.chats =
          this.$store.state.booking.activeClient.messages;
      }

      this.allChats = JSON.parse(JSON.stringify(this.chats));

      this.displayedChats = JSON.parse(JSON.stringify(this.chats));
    },
    searchChats() {
      const chats = JSON.parse(JSON.stringify(this.allChats));

      if (!this.searchValue) {
        this.displayedChats = chats;
        return;
      }

      if (this.clientMode) {
        this.displayedChats = chats.filter((chat) => {
          return chat.salon.name
            .toLowerCase()
            .includes(this.searchValue.toLowerCase());
        });
      } else {
        this.displayedChats = chats.filter((chat) => {
          return chat.client.name
            .toLowerCase()
            .includes(this.searchValue.toLowerCase());
        });
      }
    },
    getLastMessage(chat) {
      if (!chat.messages.length) return;

      return chat.messages[chat.messages.length - 1];
    },
    formatDate(date) {
      return this.$moment(date).format('lll');
    },

    getAvatarName(chat) {
      if (this.clientMode) {
        return chat.salon.name;
      } else {
        return chat.client.name;
      }
    },
    getAvatarImage(chat) {
      if (this.clientMode) {
        return chat.salon.profileImage;
      } else {
        return chat.client.profileImage;
      }
    },
    getChatName(chat) {
      if (this.clientMode) {
        return chat.salon.name;
      } else {
        return chat.client.name;
      }
    },
    lastMessageIsNotMe(chat) {
      const lastMessage = chat.messages[chat.messages.length - 1];

      if (this.clientMode) {
        return (
          lastMessage.author.authorId !==
          this.$store.state.booking.activeClient._id
        );
      } else {
        return lastMessage.author.authorId !== this.$store.state.auth.salon._id;
      }
    },

    selectClient(client) {
      this.$emit('compose', client);
      this.showSelectClient = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.chats {
  .bold {
    font-weight: 700;
  }
  .light {
    color: var(--clr-gray);
  }
  .small {
    font-size: 14px;
  }
  .mt-s {
    margin-top: 5px;
  }

  &_head {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &_list {
    display: flex;
    flex-direction: column;

    .selected {
      background-color: var(--clr-secondary-white) !important;
    }

    li {
      display: flex;
      align-items: center;
      gap: 16px;
      cursor: pointer;
      padding: 16px;

      &:hover {
        background-color: var(--clr-white);
      }

      .unread {
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: var(--clr-secondary-dark);
      }
    }
  }
}

.clientSearch {
  padding: 16px;
}
</style>
