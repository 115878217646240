<template>
  <section class="staffList">
    <ul v-if="staff.length">
      <li
        v-for="(staff, index) in staff"
        v-show="!minNonWorking || (minNonWorking && staff.working)"
        :key="index"
        draggable="true"
        @dragstart="dragStart(index)"
        @dragend="dragEnd"
        :class="{ sleeping: minNonWorking && !staff.working }"
      >
        <div
          class="dropzone"
          v-show="dragging && dragIndex !== index && dragIndex !== index - 1"
          @drop="drop(index)"
          @dragenter.prevent
          @dragover.prevent
        ></div>

        <div class="content">
          <div class="icons">
            <i
              v-if="rotationMode && index !== 0"
              @click="moveToTop(index)"
              class="fas fa-angle-double-up"
            ></i>
            <i class="fas fa-bars"></i>
            <i
              v-if="rotationMode && index !== visibleStaffLength - 1"
              @click="moveToBottom(index)"
              class="fas fa-angle-double-down"
            ></i>
          </div>

          <div @click="$emit('select', staff._id)" class="staffItem">
            <StaffListItem :staff="staff" />
          </div>

          <!-- <div v-else class="sleep">
            <p>{{ `${staff.firstName} ${staff.lastName}` }}</p>

            <i class="fas fa-bed"></i>
          </div> -->
        </div>
      </li>
    </ul>
    <div v-else class="none">
      <h3>No members to display!</h3>
      <p>Consider creating or inviting a team member.</p>
    </div>
  </section>
</template>

<script>
import StaffListItem from '@/components/staff/StaffListItem.vue';

export default {
  emits: ['select', 'changeOrder', 'showDeleted'],
  components: {
    StaffListItem,
  },
  props: {
    staff: {
      type: Array,
      required: true,
    },
    minNonWorking: {
      type: Boolean,
    },
    rotationMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      dragging: false,
      dragIndex: null,
      visibleStaffLength: 0,
    };
  },
  created() {
    if (this.minNonWorking) {
      this.visibleStaffLength = this.staff.filter((s) => s.working).length;
    } else {
      this.visibleStaffLength = this.staff.length;
    }
  },
  methods: {
    dragStart(index) {
      // For some reason we have to do Vue things in set timeout?
      setTimeout(() => {
        this.$emit('showDeleted');
        this.dragging = true;
        this.dragIndex = index;
      }, 10);
    },
    dragEnd() {
      this.dragging = false;
      this.dragIndex = null;
    },
    drop(index) {
      this.$emit('changeOrder', { from: this.dragIndex, to: index });
    },

    moveDown(index) {
      this.$emit('changeOrder', { from: index, to: index + 2 });
    },

    moveToBottom(index) {
      this.$emit('changeOrder', { from: index, to: this.staff.length });
    },

    moveToTop(index) {
      this.$emit('changeOrder', { from: index, to: 0 });
    },
  },
};
</script>

<style lang="scss" scoped>
.staffList {
  ul {
    li {
      .content {
        display: flex;
        align-items: center;
        gap: 16px;

        .icons {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 12px;
        }
      }

      &:not(:first-child) {
        margin-top: 16px;
      }

      i {
        cursor: grab;
        color: var(--clr-gray);
      }

      .staffItem {
        flex-grow: 1;
      }
    }
  }

  .sleep {
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid var(--clr-light);
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--clr-gray);
  }
  .none {
    text-align: center;
  }
}

.dropzone {
  width: 100%;
  height: 16px;
  border: 1px dashed var(--clr-gray);
  margin: 16px 0;
}
</style>
