<template>
  <section class="hero">
    <h1>Top next-gen salon & spa software</h1>
    <h2>
      Extremely flexible and powerful booking and management software for your
      business.
    </h2>
    <router-link :to="{ name: 'StaffRegister' }">
      <BaseButton mode="secondary-dark large">Start Free Trial</BaseButton>
    </router-link>
  </section>
</template>

<style lang="scss" scoped>
.hero {
  text-align: center;
  color: var(--clr-white);

  h1 {
    font-size: 56px;
    line-height: 56px;
  }
  h2 {
    font-size: 24px;
    margin-top: 16px;
    line-height: 36px;
  }
  button {
    margin-top: 32px;
  }
  //   JK
  p {
    margin-top: 16px;
    font-style: italic;
  }
}
</style>
