<template>
  <section class="servicesList">
    <ul>
      <CategoryListItem
        v-for="(category, index) in categories"
        :key="index"
        :category="category"
        :showItems="showItems"
        @selectItem="$emit('selectItem', $event)"
        @selectCategory="$emit('selectCategory', $event)"
        @addItemToCategory="$emit('addItemToCategory', $event)"
        @deleteCategory="deleteCategory($event)"
        @moveItem="
          moveItemInCategory(category._id, $event.id, $event.newPosition)
        "
        @moveCategory="moveCategory($event.id, $event.newPositionId)"
      />
    </ul>

    <Spinner v-if="loading" fullscreen />

    <div v-if="!categories.length" class="none">
      <h3>No services yet.</h3>
      <p>Press the add button above to get started.</p>
    </div>
  </section>
</template>

<script>
import CategoryListItem from '@/components/items/CategoryListItem.vue';

export default {
  emits: ['selectItem', 'selectCategory'],
  components: {
    CategoryListItem,
  },
  created() {
    this.init();
  },
  watch: {
    categories() {
      this.init();
    },
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    showItems: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      newCategories: [],

      dragging: false,
      dragIndex: null,

      loading: false,
    };
  },
  methods: {
    init() {
      if (this.categories)
        this.newCategories = JSON.parse(JSON.stringify(this.categories));
    },
    deleteCategory(category) {
      this.$store.dispatch('items/deleteServiceCategory', category);
    },

    async moveItemInCategory(categoryId, itemId, newIndex) {
      const categoryIndex = this.newCategories.findIndex(
        (cat) => cat._id === categoryId
      );

      if (categoryIndex === -1) return;

      const itemIndex = this.newCategories[categoryIndex].items.findIndex(
        (item) => item._id === itemId
      );

      if (itemIndex === -1) return;

      if (itemIndex < newIndex) newIndex--;

      if (itemIndex === newIndex) return;

      const element = this.newCategories[categoryIndex].items.splice(
        itemIndex,
        1
      )[0];

      this.newCategories[categoryIndex].items.splice(newIndex, 0, element);

      this.loading = true;
      try {
        await this.$store.dispatch(
          'items/editServiceCategory',
          this.newCategories[categoryIndex]
        );
      } catch (error) {
        console.log(error.message);
      }
      this.loading = false;
    },

    async moveCategory(categoryId, toPositionId) {
      if (categoryId === toPositionId) return;

      const oldPositionIndex = this.newCategories.findIndex(
        (cat) => cat._id === categoryId
      );

      if (oldPositionIndex === -1) return;

      let newPositionIndex = this.newCategories.findIndex(
        (cat) => cat._id === toPositionId
      );

      if (newPositionIndex === -1) return;

      if (oldPositionIndex < newPositionIndex) newPositionIndex--;

      const element = this.newCategories.splice(oldPositionIndex, 1)[0];

      this.newCategories.splice(newPositionIndex, 0, element);

      this.loading = true;
      try {
        await this.$store.dispatch(
          'items/editServiceCategories',
          this.newCategories
        );
      } catch (error) {
        console.log(error.message);
      }
      this.loading = false;
    },

    dragStart(event, category, index) {
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('type', 'category');
      event.dataTransfer.setData('id', category._id);

      // For some reason we have to do Vue things in set timeout?
      setTimeout(() => {
        this.dragging = true;
        this.dragIndex = index;
      }, 10);
    },
    dragEnd() {
      this.dragging = false;
      this.dragIndex = null;
    },
    drop(event, index) {
      if (!event.dataTransfer.getData('type') === 'category') return;

      // Do stuffs
      this.$emit('moveItem', {
        id: event.dataTransfer.getData('id'),
        newPosition: index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.none {
  padding: 16px;
  border: 1px solid var(--clr-light);
  border-radius: 5px;
  text-align: center;

  p {
    margin-top: 8px;
  }
}

.dropzone {
  width: 100%;
  height: 16px;
  border: 1px dashed var(--clr-gray);
  margin-top: 8px;
}
</style>
