<template>
  <section class="endpoints">
    <div class="alert alert-info">
      <p>
        Keep in mind most of these options are for Raserva's front-end, you can
        use on your front-end if you are creating one but these options will not
        affect most data on the back-end if you are using our API and not
        implementing these features on your front-end.
      </p>
    </div>

    <div class="endpoints_router">
      <p class="bold">On this page:</p>

      <ul>
        <li class="link" @click="scrollIntoView('getKioskSettings')">
          Get kiosk settings
        </li>
        <li class="link" @click="scrollIntoView('updateKioskSettings')">
          Update kiosk settings
        </li>
        <li class="link" @click="scrollIntoView('getAdminSettings')">
          Get admin settings
        </li>
        <li class="link" @click="scrollIntoView('updateAdminSettings')">
          Update admin settings
        </li>
        <li class="link" @click="scrollIntoView('getBookingSettings')">
          Get booking settings
        </li>
        <li class="link" @click="scrollIntoView('updateBookingSettings')">
          Update booking settings
        </li>
        <li class="link" @click="scrollIntoView('getPaymentsSettings')">
          Get payments settings
        </li>
        <li class="link" @click="scrollIntoView('updatePaymentsSettings')">
          Update payments settings
        </li>
        <li class="link" @click="scrollIntoView('getDetailsSettings')">
          Get details settings
        </li>
        <li class="link" @click="scrollIntoView('updateDetailsSettings')">
          Update details settings
        </li>
        <li class="link" @click="scrollIntoView('getPermissions')">
          Get permissions
        </li>
        <li class="link" @click="scrollIntoView('updatePermissions')">
          Update permissions
        </li>
        <li class="link" @click="scrollIntoView('getSchedule')">
          Get schedule
        </li>
        <li class="link" @click="scrollIntoView('updateSchedule')">
          Update schedule
        </li>
      </ul>
    </div>

    <!-- Get kiosk settings -->
    <div class="endpoint" id="getKioskSettings" ref="getKioskSettings">
      <DocsAPISettingsKioskGet />
    </div>

    <!-- Update kiosk settings -->
    <div class="endpoint" id="updateKioskSettings" ref="updateKioskSettings">
      <DocsAPISettingsKioskPut />
    </div>

    <!-- Get admin settings -->
    <div class="endpoint" id="getAdminSettings" ref="getAdminSettings">
      <DocsAPISettingsAdminGet />
    </div>

    <!-- Update admin settings -->
    <div class="endpoint" id="updateAdminSettings" ref="updateAdminSettings">
      <DocsAPISettingsAdminPut />
    </div>

    <!-- Get booking settings -->
    <div class="endpoint" id="getBookingSettings" ref="getBookingSettings">
      <DocsAPISettingsBookingGet />
    </div>

    <!-- Update booking settings -->
    <div
      class="endpoint"
      id="updateBookingSettings"
      ref="updateBookingSettings"
    >
      <DocsAPISettingsBookingPut />
    </div>

    <!-- Get payments settings -->
    <div class="endpoint" id="getPaymentsSettings" ref="getPaymentsSettings">
      <DocsAPISettingsPaymentsGet />
    </div>

    <!-- Update payments settings -->
    <div
      class="endpoint"
      id="updatePaymentsSettings"
      ref="updatePaymentsSettings"
    >
      <DocsAPISettingsPaymentsPut />
    </div>

    <!-- Get details settings -->
    <div class="endpoint" id="getDetailsSettings" ref="getDetailsSettings">
      <DocsAPISettingsDetailsGet />
    </div>

    <!-- Update details settings -->
    <div
      class="endpoint"
      id="updateDetailsSettings"
      ref="updateDetailsSettings"
    >
      <DocsAPISettingsDetailsPut />
    </div>

    <!-- Get permissions -->
    <div class="endpoint" id="getPermissions" ref="getPermissions">
      <DocsAPISettingsPermissionsGet />
    </div>

    <!-- Update permissions -->
    <div class="endpoint" id="updatePermissions" ref="updatePermissions">
      <DocsAPISettingsPermissionsPut />
    </div>

    <!-- Get schedule -->
    <div class="endpoint" id="getSchedule" ref="getSchedule">
      <DocsAPISettingsScheduleGet />
    </div>

    <!-- Update schedule -->
    <div class="endpoint" id="updateSchedule" ref="updateSchedule">
      <DocsAPISettingsSchedulePut />
    </div>
  </section>
</template>

<script>
import DocsAPISettingsKioskGet from './DocsAPISettingsKioskGet.vue';
import DocsAPISettingsKioskPut from './DocsAPISettingsKioskPut.vue';
import DocsAPISettingsAdminGet from './DocsAPISettingsAdminGet.vue';
import DocsAPISettingsAdminPut from './DocsAPISettingsAdminPut.vue';
import DocsAPISettingsBookingGet from './DocsAPISettingsBookingGet.vue';
import DocsAPISettingsBookingPut from './DocsAPISettingsBookingPut.vue';
import DocsAPISettingsPermissionsGet from './DocsAPISettingsPermissionsGet.vue';
import DocsAPISettingsPermissionsPut from './DocsAPISettingsPermissionsPut.vue';
import DocsAPISettingsPaymentsGet from './DocsAPISettingsPaymentsGet.vue';
import DocsAPISettingsPaymentsPut from './DocsAPISettingsPaymentsPut.vue';
import DocsAPISettingsDetailsGet from './DocsAPISettingsDetailsGet.vue';
import DocsAPISettingsDetailsPut from './DocsAPISettingsDetailsPut.vue';
import DocsAPISettingsScheduleGet from './DocsAPISettingsScheduleGet.vue';
import DocsAPISettingsSchedulePut from './DocsAPISettingsSchedulePut.vue';

export default {
  components: {
    DocsAPISettingsKioskGet,
    DocsAPISettingsKioskPut,
    DocsAPISettingsAdminGet,
    DocsAPISettingsAdminPut,
    DocsAPISettingsBookingGet,
    DocsAPISettingsBookingPut,
    DocsAPISettingsPermissionsGet,
    DocsAPISettingsPermissionsPut,
    DocsAPISettingsPaymentsGet,
    DocsAPISettingsPaymentsPut,
    DocsAPISettingsDetailsGet,
    DocsAPISettingsDetailsPut,
    DocsAPISettingsScheduleGet,
    DocsAPISettingsSchedulePut,
  },
  methods: {
    scrollIntoView(element) {
      this.$refs[element].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  padding: 12px;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 16px;

  &-info {
    background-color: #c3edf7;
    color: #044653;
    border-color: #aceaf7;
  }
}
.endpoints {
  &_router {
    margin-bottom: 32px;
    padding: 16px;
    border-radius: 5px;
    background-color: var(--clr-white-2);

    ul {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 32px;

      .link {
        cursor: pointer;
        color: var(--clr-link);
      }
    }
  }

  .endpoint {
    &:not(:first-child) {
      margin-top: 64px;
    }
  }
}
</style>
