<template>
  <div class="classic">
    <div class="col ticketsMain">
      <ClassicHeader
        @toggleCompleted="toggleCompleted($event)"
        @search="searchTickets($event)"
        @updateSortValue="sortValue = $event"
        @showMoreOptions="showMoreOptions = true"
        :tickets="tickets"
        :workingStaffCount="staffs.length"
      />

      <ClassicTickets
        ref="tickets"
        :staffs="staffs"
        :tickets="tickets"
        @new="
          selectedStaff = $event;
          selectedTicket = null;
          showAddTicketModal = true;
        "
        @selectTicket="
          selectedTicket = $event;
          showAddTicketModal = true;
        "
        @selectStaff="
          selectedStaff = $event;
          showStaffOptionsModal = true;
        "
      />
    </div>
    <div class="col right">
      <div class="right_head">
        <p
          @click="selectedTab = 'availability'"
          :class="{ selected: selectedTab === 'availability' }"
        >
          Availability
        </p>
        <p
          @click="selectedTab = 'waiting'"
          :class="{ selected: selectedTab === 'waiting' }"
        >
          Waiting
        </p>
      </div>
      <ClassicAvailability
        v-if="selectedTab === 'availability'"
        :staffs="staffs"
      />
      <ClassicWaiting
        v-if="selectedTab === 'waiting'"
        :tickets="waitingTickets"
        @selectTicket="
          selectedTicket = $event;
          showAddTicketModal = true;
        "
        @newWaitingTicket="createWaitingTicket($event)"
      />
    </div>
  </div>

  <!-- <Modal
    title="Classic POS"
    :show="selectedTicket || showNewTicketForm"
    @close="
      selectedTicket = null;
      showNewTicketForm = false;
    "
    fullScreen
  >
    <ClassicPOS :ticket="selectedTicket" />
  </Modal> -->

  <Modal
    :title="selectedTicket ? 'Edit Ticket' : 'New Ticket'"
    @close="
      closeTicketModal();
      selectedStaff = null;
      deselectTicketInTickets();
    "
    v-if="showAddTicketModal"
    :show="showAddTicketModal && !minTicketModal"
    fullScreen
  >
    <template v-slot:header>
      <p
        @click="
          selectedTicket = $refs.ticketModal.newTicket;
          minTicketModal = true;
        "
      >
        <i class="fas fa-minus"></i>
      </p>
    </template>

    <TicketForm
      ref="ticketModal"
      :ticket="selectedTicket"
      :preSelectedStaff="selectedStaff"
      @created="ticketUpdated($event, 'Created')"
      @edited="ticketUpdated($event, 'Updated')"
      @checkedOut="ticketUpdated($event, 'Checked Out')"
      @deleted="ticketUpdated($event, 'Deleted')"
      @close="showAddTicketModal = false"
      classicView
    />
  </Modal>

  <Modal
    title="Staff Options"
    @close="selectedStaff = null"
    v-if="showStaffOptionsModal"
    :show="selectedStaff"
    maxWidth="300px"
  >
    <StaffOptions
      :staff="selectedStaff"
      @showAddTicket="showAddTicketModal = true"
      @deselectStaff="selectedStaff = null"
      @setTurns="selectedStaff.additionalTurns = $event"
      @selectFilterOption="goToCalendar($event)"
    />
  </Modal>

  <Modal
    title="Staff Order"
    @close="showMoreOptions = false"
    :show="showMoreOptions"
    maxWidth="700px"
  >
    <div class="moreOptions">
      <StaffList
        :staff="staffs"
        @changeOrder="changeOrder($event)"
        minNonWorking
        rotationMode
      />
    </div>
  </Modal>

  <!-- Minimizers -->
  <div
    class="minApp"
    @click="minTicketModal = false"
    v-if="minTicketModal && selectedTicket"
  >
    <i class="fas fa-expand"></i>
  </div>
</template>

<script>
import ClassicTickets from '@/views/classic/ClassicTickets.vue';
import ClassicAvailability from '@/views/classic/ClassicAvailability.vue';
import ClassicWaiting from '@/views/classic/ClassicWaiting.vue';
import ClassicHeader from '@/views/classic/ClassicHeader.vue';
import TicketForm from '@/views/dashboard/TicketForm.vue';
import StaffOptions from '@/views/calendar/StaffOptions.vue';

import StaffList from '@/components/staff/StaffList.vue';

export default {
  components: {
    ClassicTickets,
    ClassicAvailability,
    ClassicWaiting,
    ClassicHeader,
    TicketForm,
    StaffOptions,
    StaffList,
  },
  created() {
    this.getTickets();

    if (this.socket) {
      this.socketInit();
    }

    this.sortValue = localStorage.getItem('classicSortValue') || 'clockin';
  },
  computed: {
    socket() {
      return this.$store.state.sockets.socket;
    },
    storeTickets() {
      return this.$store.state.tickets.tickets;
    },
    ticketChangeCount() {
      return this.$store.state.tickets.changeCount;
    },
    staffs() {
      if (!this.$store.state.auth.salon) return [];

      let staffs = this.$store.state.staff.staff.filter(
        (staff) => staff.working && staff.onCalendar
      );

      if (this.sortValue === 'clockIn') {
        staffs = staffs.sort((a, b) => {
          // sort by clock in date
          if (a.clockedInAt && b.clockedInAt) {
            return a.clockedInAt > b.clockedInAt ? 1 : -1;
          } else if (a.clockedInAt) {
            return -1;
          } else if (b.clockedInAt) {
            return 1;
          } else {
            return 0;
          }
        });
      } else if (this.sortValue === 'custom') {
        staffs = this.$store.state.auth.salon.customStaffTurnOrder.map((s) => {
          return this.$store.state.staff.staff.find(
            (staff) => staff.staffId.toString() === s.toString()
          );
        });
      }

      return staffs.filter((s) => s);
    },
    waitingTickets() {
      return this.tickets.filter((t) => t.status === 'waiting');
    },
    allStaff() {
      return this.$store.state.staff.staff;
    },
  },
  watch: {
    ticketChangeCount() {
      this.getTickets();
    },
    storeTickets() {
      this.getTickets();
    },
  },
  data() {
    return {
      allTickets: [],
      tickets: [],
      selectedTab: 'waiting',

      selectedTicket: null,
      showAddTicketModal: false,
      minTicketModal: false,

      selectedStaff: null,
      showStaffOptionsModal: false,

      sortValue: 'clockin',
      showMoreOptions: false,
    };
  },
  methods: {
    goToCalendar(staffId) {
      this.$router.push({
        name: 'Calendar',
        query: { staffId },
      });
    },
    deselectTicketInTickets() {
      this.$refs.tickets.selectedTicket = null;
    },
    toggleCompleted(hide) {
      const tickets = JSON.parse(JSON.stringify(this.allTickets));

      this.tickets = hide
        ? tickets.filter((t) => t.status !== 'completed')
        : tickets;
    },
    searchTickets(value) {
      const tickets = JSON.parse(JSON.stringify(this.allTickets));

      this.tickets = value
        ? tickets.filter((t) =>
            t.client
              ? `${t.client.firstName} ${t.client.lastName}`
                  .toLowerCase()
                  .includes(value.toLowerCase())
              : t.title
              ? t.title.toLowerCase().includes(value.toLowerCase())
              : 'Walk-in'.toLowerCase().includes(value.toLowerCase())
          )
        : tickets;
    },
    getTickets() {
      this.allTickets = JSON.parse(
        JSON.stringify(this.$store.state.tickets.tickets)
      );
      this.tickets = JSON.parse(
        JSON.stringify(this.$store.state.tickets.tickets)
      );
    },
    socketInit() {
      this.$store.state.sockets.socket.on('clearTickets', () => {
        this.allTickets = [];
        this.tickets = [];
      });
    },
    async createWaitingTicket(client) {
      try {
        await this.$store.dispatch('tickets/createTicket', {
          client,
          note: `Quick add from dashboard`,
          status: 'waiting',
        });
      } catch (e) {
        console.log(e);
      }
    },
    closeTicketModal() {
      if (
        this.$refs.ticketModal.newTicket.items.length &&
        !this.$refs.ticketModal.newTicket._id
      ) {
        this.$refs.ticketModal.save();
      }

      this.showAddTicketModal = false;
    },
    async ticketUpdated(ticket, action) {
      if (action === 'Created') {
        await this.$store.dispatch('tickets/createTicket', ticket);
      } else if (action === 'Updated') {
        await this.$store.dispatch('tickets/editTicket', ticket);
      } else if (action === 'Checked Out') {
        await this.$store.dispatch('tickets/checkoutTicket', ticket);
      }

      this.$toast.success(`Ticket ${action}`);

      if (!ticket.dontClose) {
        this.showAddTicketModal = false;
        this.selectedStaff = null;
        this.selectedTicket = null;
        this.deselectTicketInTickets();
      }
    },
    async changeOrder(data) {
      const staff = JSON.parse(JSON.stringify(this.staffs));

      if (data.from < data.to) data.to--;

      const element = staff.splice(data.from, 1)[0];

      staff.splice(data.to, 0, element);

      this.staff = JSON.parse(JSON.stringify(staff));

      try {
        const response = await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/salons/${this.$store.state.auth.salon._id}/staff/customStaffTurnOrder`,
          {
            setOrder: true,
            customStaffTurnOrder: this.staff.map((s) => s.staffId),
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$store.state.sockets.socket.emit('updateStaffTurnOrder', {
          staff: response.data.customStaffTurnOrder,
          salonId: this.$store.state.auth.salon._id,
        });

        this.$store.state.auth.salon.customStaffTurnOrder =
          response.data.customStaffTurnOrder;
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.classic {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: var(--clr-light);
  display: flex;
  gap: 16px;

  .ticketsMain {
    height: 100%;
    width: 100%;
    overflow-x: auto;
  }
  .right {
    overflow-y: auto;
    background-color: var(--clr-white-2);
    min-width: 350px;
    width: 350px;
    box-shadow: var(--shadow);

    &_head {
      display: flex;
      text-align: center;
      background-color: var(--clr-light);

      .selected {
        background-color: var(--clr-white-2);
        color: var(--clr-primary);
        font-weight: 700;
      }

      p {
        padding: 16px 0;
        background-color: var(--clr-light);
        flex-grow: 1;
        cursor: pointer;
        color: var(--clr-gray);
      }
    }
  }
}

.moreOptions {
  padding: 32px;
}

.minApp {
  z-index: 10;
  position: fixed;
  bottom: 30px;
  left: 30px;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: var(--clr-secondary);
  color: white;
  display: grid;
  place-items: center;
  box-shadow: var(--shadow);
  cursor: pointer;
  font-size: 24px;
  font-weight: 700;
}

// Tablet
@media (max-width: 900px) {
  .classic {
    flex-direction: column;
    gap: 0;

    .right {
      width: 100%;
      min-width: 100%;
    }
  }
}
</style>
