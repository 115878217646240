<template>
  <form @submit.prevent="addCustomItem" class="form">
    <BaseInput label="Title" @input="customItemTitle = $event" />
    <BaseInput
      label="Price"
      inputType="number"
      step="0.01"
      @input="customItemPrice = +$event"
    />
    <div class="toggle">
      <Toggle
        :checked="customItemIsService"
        @toggle="customItemIsService = !customItemIsService"
      />
      <div class="toggle_text">
        <p class="toggle_text_label">Service</p>
        <p class="toggle_text_subtext">If checked, item will be commissioned</p>
      </div>
    </div>
    <div class="toggle">
      <Toggle
        :checked="customItemIsTaxed"
        @toggle="customItemIsTaxed = !customItemIsTaxed"
      />
      <div class="toggle_text">
        <p class="toggle_text_label">Taxed</p>
        <p class="toggle_text_subtext">If checked, item will be taxed</p>
      </div>
    </div>
    <div class="form_actions">
      <BaseButton>Add</BaseButton>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      customItemTitle: '',
      customItemPrice: 0,
      customItemIsService: true,
      customItemIsTaxed: false,
    };
  },
  methods: {
    addCustomItem() {
      this.$emit('add', {
        title: this.customItemTitle,
        isCommissioned: true,
        duration: this.customItemIsService ? 1 : null,
        stock: this.customItemIsService ? null : 1,
        commissionType: 'percent',
        commissionAmount: 0,
        priceType: 'fixed',
        price: this.customItemPrice || 0,
        taxRate: this.customItemIsTaxed
          ? this.$store.state.auth.salon.payments.defaultTaxRate
          : 0,
        turns: 0,
        fees: {
          supply: 0,
          other: 0,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.customItemForm,
.form {
  width: 100%;
  max-width: 500px;
  display: block;

  .parent:not(:first-child) {
    margin-top: 16px;
  }

  .toggle {
    display: flex;
    align-items: center;
    gap: 32px;
    flex-wrap: wrap;
    padding: 16px 0;

    &_text {
      &_label {
        font-size: 18px;
      }
      &_subtext {
        margin-top: 5px;
        font-size: 14px;
      }
    }
  }

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
