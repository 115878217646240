<template>
  <section class="kioskSettings">
    <div class="kioskSettings_head">
      <h2>Kiosk Settings</h2>
    </div>
    <div class="kioskSettings_body">
      <form @submit.prevent="submit" class="form">
        <div class="form_body">
          <div class="toggle">
            <Toggle
              :checked="newSettings.useFullName"
              @toggle="newSettings.useFullName = !newSettings.useFullName"
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Use Full Name Field</p>
              <p class="toggle_text_subtext">Ask user to enter in full name</p>
            </div>
          </div>
          <div v-if="!newSettings.useFullName" class="separateNames">
            <div class="toggle">
              <Toggle
                :checked="newSettings.useFirstName"
                @toggle="newSettings.useFirstName = !newSettings.useFirstName"
              />
              <div class="toggle_text">
                <p class="toggle_text_label">First Name Field</p>
                <p class="toggle_text_subtext">
                  Ask user to enter in first name
                </p>
              </div>
            </div>
            <div class="toggle">
              <Toggle
                :checked="newSettings.useLastName"
                @toggle="newSettings.useLastName = !newSettings.useLastName"
              />
              <div class="toggle_text">
                <p class="toggle_text_label">Last Name Field</p>
                <p class="toggle_text_subtext">
                  Ask user to enter in last name
                </p>
              </div>
            </div>
          </div>
          <div class="toggle">
            <Toggle
              :checked="newSettings.useEmail"
              @toggle="newSettings.useEmail = !newSettings.useEmail"
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Use Email Field</p>
              <p class="toggle_text_subtext">Ask user to enter in email</p>
            </div>
          </div>
          <div class="toggle">
            <Toggle
              :checked="newSettings.useDOB"
              @toggle="newSettings.useDOB = !newSettings.useDOB"
            />
            <div class="toggle_text">
              <p class="toggle_text_label">Use Date of Birth</p>
              <p class="toggle_text_subtext">
                Ask user to enter in their date of birth
              </p>
            </div>
          </div>
          <BaseTextarea
            label="Display Message (Optional)"
            subtitle="A small message to display to clients using your kiosk"
            :value="newSettings.note"
            @input="newSettings.note = $event"
          />
          <BaseInput
            inputType="number"
            label="Input Font Size"
            :value="newSettings.inputFontSize"
            @input="newSettings.inputFontSize = +$event"
          />
        </div>
        <div class="form_actions">
          <BaseButton :disabled="loading || !formValid"
            ><i v-if="loading" class="fas fa-spinner"></i> Submit</BaseButton
          >
        </div>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  created() {
    if (!this.salon) return;
    this.init();
  },
  data() {
    return {
      loading: false,

      newSettings: {
        useFullName: true,
        useFirstName: true,
        useLastName: false,
        useEmail: false,
        useDOB: false,
        note: '',
      },
    };
  },
  computed: {
    formValid() {
      if (this.newSettings.note && this.newSettings.note.length > 500)
        return false;
      return true;
    },
    salon() {
      return this.$store.state.salon.salon;
    },
    validators() {
      return {
        code: {
          maxLength: 100,
        },
      };
    },
  },
  watch: {
    salon() {
      this.init();
    },
  },
  methods: {
    init() {
      if (!this.salon) return;

      this.newSettings = JSON.parse(JSON.stringify(this.salon.kioskSettings));
    },
    async submit() {
      this.loading = true;

      try {
        await this.$store.dispatch(
          'salon/updateKioskSettings',
          this.newSettings
        );

        this.$toast.success('Salon info updated');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.kioskSettings {
  &_head {
    h2 {
      font-size: 28px;
    }

    p {
      margin-top: 6px;
    }
  }

  &_body {
    margin-top: 32px;

    .form {
      &_body {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .toggle {
          display: flex;
          align-items: center;
          gap: 32px;
          flex-wrap: wrap;
          padding: 16px 0;

          &_text {
            &_label {
              font-size: 18px;
            }
            &_subtext {
              margin-top: 5px;
              font-size: 14px;
            }
          }
        }
      }
      &_actions {
        margin-top: 32px;
        display: flex;
        justify-content: flex-end;
        gap: 16px;
      }
    }
  }
}
</style>
