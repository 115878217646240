<template>
  <section class="ghl">
    <!-- Head -->
    <div class="ghl_head">
      <h2>HighLevel</h2>
      <p class="subtext">
        HighLevel is the first-ever all-in-one platform that will give you the
        tools, support and resources you need to succeed with your agency.
      </p>
    </div>

    <!-- Body -->
    <form @submit.prevent="submit" class="ghl_body form">
      <!-- Enabled -->
      <div class="toggle">
        <Toggle
          :checked="highLevel.enabled"
          @toggle="highLevel.enabled = !highLevel.enabled"
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Enabled</p>
        </div>
      </div>

      <!-- Location ID -->
      <BaseInput
        label="Location ID"
        :value="highLevel.locationId"
        @input="highLevel.locationId = $event"
      />

      <div class="form_actions">
        <BaseButton :disabled="loading"
          ><i v-if="loading" class="fas fa-spinner"></i>Submit</BaseButton
        >
      </div>
    </form>

    <div
      v-if="highLevel.enabled && highLevel.locationId"
      class="routes-and-router"
    >
      <!-- Mock Router -->
      <div class="router">
        <ul>
          <li
            @click="shownDataSet = 'calendars'"
            :class="{ selected: shownDataSet === 'calendars' }"
          >
            Calendars
          </li>
          <li
            @click="shownDataSet = 'users'"
            :class="{ selected: shownDataSet === 'users' }"
          >
            Users
          </li>
          <!-- <li
            @click="shownDataSet = 'teams'"
            :class="{ selected: shownDataSet === 'teams' }"
          >
            Teams
          </li>
          <li
            @click="shownDataSet = 'services'"
            :class="{ selected: shownDataSet === 'services' }"
          >
            Services
          </li> -->
        </ul>
      </div>

      <!-- Calendars -->
      <section v-if="shownDataSet === 'calendars'" class="ghl_calendars">
        <HighLevelCalendars />
      </section>

      <!-- Users -->
      <section v-if="shownDataSet === 'users'" class="ghl_users">
        <HighLevelUsers />
      </section>

      <!-- Team -->
      <section v-if="shownDataSet === 'teams'" class="ghl_team">
        <HighLevelTeam />
      </section>
    </div>
  </section>
</template>

<script>
import HighLevelCalendars from '@/components/integrations/highLevel/highLevelCalendars.vue';
import HighLevelUsers from '@/components/integrations/highLevel/highLevelUsers.vue';
import HighLevelTeam from '@/components/integrations/highLevel/highLevelTeam.vue';

export default {
  components: {
    HighLevelCalendars,
    HighLevelUsers,
    HighLevelTeam,
  },
  created() {
    this.init();
  },
  computed: {
    salon() {
      return this.$store.state.auth.salon;
    },
  },
  data() {
    return {
      loading: false,
      shownDataSet: 'calendars',

      highLevel: {
        enabled: false,
        locationId: '',
        calendarId: '',
      },
    };
  },
  methods: {
    init() {
      this.highLevel = JSON.parse(
        JSON.stringify(this.$store.state.auth.salon.integrations.highLevel)
      );
    },

    async submit() {
      this.loading = true;

      try {
        const response = await this.$axios.put(
          `${process.env.VUE_APP_RASERVA_BACKEND}/integrations/highLevel?salonId=${this.salon._id}`,
          this.highLevel,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        console.log(response);

        this.$toast.success('Successfully updated Go High Level settings');
      } catch (err) {
        console.log(err);
      }

      this.loading = false;
    },

    async submitCalendarForm() {
      this.loading = true;

      try {
        console.log('submitted calendar form..');
      } catch (err) {
        console.log(err);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.ghl {
  margin: auto;
  margin-top: 64px;
  padding: 0 16px;
  width: 100%;
  max-width: 800px;

  &_head {
    h2 {
      font-size: 28px;
    }
    .subtext {
      margin-top: 6px;
    }
  }

  &_body {
    margin-top: 64px;

    .alert {
      padding: 12px;
      font-size: 14px;
      margin-bottom: 16px;

      &-warning {
        background-color: var(--clr-warning-light);
        border: 1px solid;
        border-color: var(--clr-warning);
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .toggle {
      display: flex;
      align-items: center;
      gap: 32px;
      flex-wrap: wrap;
      padding: 16px 0;

      &_text {
        &_label {
          font-size: 18px;
        }
        &_subtext {
          margin-top: 5px;
          font-size: 14px;
        }
      }
    }

    .selectCalendar {
      display: flex;
      align-items: flex-end;
      gap: 16px;

      .parent {
        flex-grow: 1;
      }
    }

    &_actions {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      margin-top: 16px;
    }
  }

  .router {
    margin-top: 32px;
    padding: 16px;
    border-radius: 5px;
    border: 1px solid var(--clr-light);

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      li {
        padding: 8px 32px;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 700;

        &:hover {
          background-color: var(--clr-light);
        }
      }

      .selected {
        background-color: var(--clr-primary);
        color: var(--clr-light);

        &:hover {
          background-color: var(--clr-primary) !important;
        }
      }
    }
  }

  &_team,
  &_calendars,
  &_users {
    margin-top: 32px;
    padding: 32px;
    border-radius: 5px;
    border: 1px solid var(--clr-light);

    h2 {
      margin-bottom: 16px;
    }
  }
}
</style>
