<template>
  <section class="items">
    <div class="items_sidebar">
      <SubSidebar :routes="routes" title="Items" />
    </div>
    <div class="items_router">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import SubSidebar from '@/components/components/SubSidebar.vue';

export default {
  components: {
    SubSidebar,
  },
  created() {
    if (!this.loggedInStaff) return;
    this.init();
  },
  data() {
    return {
      routes: [],
    };
  },
  computed: {
    loggedInStaff() {
      return this.$store.state.auth.loggedInSalonStaff;
    },
    managerMode() {
      return this.$store.state.auth.managerMode;
    },
  },
  watch: {
    loggedInStaff() {
      this.init();
    },
    managerMode() {
      this.init();
    },
  },
  methods: {
    async init() {
      await this.setRoutes();
      this.goToFirstRoute();
    },

    async setRoutes() {
      this.routes = [];

      // Services
      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'other/viewServices'
        )
      ) {
        this.routes.push({
          name: 'Services',
          linkName: 'Services',
        });
      }

      // Products
      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'other/viewProducts'
        )
      ) {
        this.routes.push({
          name: 'Products',
          linkName: 'Products',
        });
      }

      // Products
      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'other/viewDiscounts'
        )
      ) {
        this.routes.push({
          name: 'Discounts',
          linkName: 'Discounts',
        });
      }

      // Packages
      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'other/viewPackages'
        )
      ) {
        this.routes.push({
          name: 'Packages',
          linkName: 'Packages',
        });
      }

      // Subscriptions
      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'other/viewSubscriptions'
        )
      ) {
        this.routes.push({
          name: 'Subscriptions',
          linkName: 'Subscriptions',
        });
      }

      // Distributors
      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'other/viewProducts'
        )
      ) {
        this.routes.push({
          name: 'Distributors',
          linkName: 'Distributors',
        });
      }

      // Loyalty
      if (
        await this.$store.dispatch(
          'auth/activeUserHasPermission',
          'other/viewLoyalty'
        )
      ) {
        this.routes.push({
          name: 'Loyalty',
          linkName: 'Loyalty',
        });
      }
    },

    goToFirstRoute() {
      this.$router.push({
        name: this.routes[0].linkName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.items {
  display: flex;
  height: 100%;

  &_router {
    overflow-y: auto;
    flex-grow: 1;
    padding: 32px;
    display: flex;
    justify-content: center;
  }
}
</style>
