<template>
  <div class="service">
    <div class="col">
      <p class="title">{{ service.title }}</p>
    </div>
    <div class="col right">
      <p class="duration">{{ service.duration }} mins</p>
      <p class="price">${{ service.price }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.service {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  padding: 16px;
  border: 1px solid var(--clr-light);
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 0.2s ease;

  &:hover {
    border-color: var(--clr-gray);
  }

  .title,
  .price {
    font-size: 17px;
    font-weight: 700;
  }

  .duration {
    color: var(--clr-gray);
  }

  .right {
    display: flex;
    gap: 32px;
  }
}
</style>
