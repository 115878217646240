<template>
  <section class="date">
    <h2 class="page-title" ref="time">Select your preferred date and time</h2>
    <div class="date_date">
      <DatePicker
        hideBackdrop
        upcomingOnly
        propWidth="100%"
        @selectDate="
          autoNextDateTries = 0;
          selectDate($event);
        "
        :selectedDate="selectedDate"
        :maxSelectableDateInNumberOfDays="
          this.$store.state.booking.salon.bookingSettings
            .maximumScheduleTimeDays
        "
        :disabledDates="disabledDates"
      />
    </div>

    <Spinner v-if="loading" fullscreen />

    <div v-else class="date_times" ref="avail">
      <h3>Availability on {{ formattedSelectedDate }}</h3>
      <p class="small light">
        This establishment does
        <span
          v-if="
            this.$store.state.booking.salon &&
            !this.$store.state.booking.salon.bookingSettings
              .allowSameDayBookings
          "
        >
          not</span
        >
        allow same day bookings. Bookings must be at least
        {{
          this.$store.state.booking.salon.bookingSettings
            .minimumScheduleTimeHours
        }}
        hours in advance and no more than
        {{
          this.$store.state.booking.salon.bookingSettings
            .maximumScheduleTimeDays
        }}
        days from today.
      </p>
      <div v-if="salonTimes.length" class="date_times_times">
        <BaseButton
          v-for="time in salonTimes"
          :key="time.time"
          mode="link"
          @click="selectTime(time)"
          >{{ formatTime(time.time) }}</BaseButton
        >
      </div>
      <div v-else class="none">
        <h4>No available times on this date.</h4>
        <p>Please choose another date.</p>
      </div>
    </div>
  </section>

  <BookingContinue hideContinue showBack @back="prevStep" />
</template>

<script>
import BookingContinue from '@/components/booking/BookingContinue.vue';
import DatePicker from '@/components/components/DatePicker.vue';

export default {
  components: {
    BookingContinue,
    DatePicker,
  },
  async created() {
    await this.setTimesFromSalonSchedule();
    this.getAppointments();
  },
  mounted() {
    this.disabledDates = [];

    if (this.$store.state.booking.confirmed) {
      this.$toast.success('Booking was confirmed!');

      this.$router.push({
        name: 'BookConfirm',
        params: { id: this.salonId },
      });

      return;
    }

    this.$store.dispatch('booking/updateBookingTracking', 'Select Date');

    if (this.$refs.time) {
      this.$refs.time.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  },
  computed: {
    salonId() {
      return this.$store.state.booking.salon._id;
    },
    formattedSelectedDate() {
      return this.$moment(this.selectedDate).format('dddd, MMMM Do YYYY');
    },
  },
  data() {
    return {
      appointments: [],
      appointmentsEndDate: null,
      availableTimes: [],
      selectedDate: this.$moment().format('YYYY-MM-DD'),
      salonTimes: [],
      autoNextDateTries: 0,
      disabledDates: [],
      loading: false,
    };
  },
  // watch: {
  //   selectedDate() {
  //     this.getAvailableTimes(this.$store.state.booking.selectedStaff);
  //   },
  // },
  methods: {
    prevStep() {
      this.$router.push({
        name: 'BookMembers',
        params: { id: this.salonId },
      });
    },
    formatTime(date) {
      const hour = +date.split(':')[0];
      const minute = +date.split(':')[1];

      return `${this.$moment()
        .hour(hour)
        .minute(minute)
        .format('LT')} ${this.$momentTz()
        .tz(this.$momentTz.tz.guess())
        .format('z')}`;
    },
    async selectDate(date) {
      if (this.$moment(date).isBefore(this.$moment(), 'date')) {
        this.$toast.warning('You cannot book in the past.');
        return;
      }

      if (
        this.$moment(date).isAfter(
          this.$moment().add(
            this.$store.state.booking.salon.bookingSettings
              .maximumScheduleTimeDays,
            'days'
          ),
          'date'
        )
      ) {
        this.$toast.warning('You cannot book that far in advance.');
        return;
      }

      this.$refs.avail.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });

      this.selectedDate = date;
      await this.setTimesFromSalonSchedule();

      if (this.$moment(date).isAfter(this.appointmentsEndDate, 'date')) {
        this.getAppointments();
      } else {
        this.setEventsOnStaffs();
      }

      if (!this.$store.state.booking.currentBookingTracking) {
        this.$store.dispatch(
          'booking/createBookingTracking',
          `Selected Date: ${this.$moment(date).format('LL')}`
        );
      } else {
        this.$store.dispatch(
          'booking/updateBookingTracking',
          `Selected Date: ${this.$moment(date).format('LL')}`
        );
      }
    },

    selectTime(time) {
      if (!time.staffs.length) {
        // TODO: show error
        return;
      }

      this.$store.state.booking.selectedDate = this.selectedDate;
      this.$store.state.booking.selectedTime = time.time;

      if (time.chain.length) {
        this.$store.state.booking.chain = time.chain;
      } else {
        // Double check staff availability
        for (let i = time.staffs.length - 1; i >= 0; i--) {
          // Check default schedule
          if (!time.staffs[i].schedule.default.some((d) => d.blocks.length)) {
            // Check exceptions
            const exception = time.staffs[i].schedule.exceptions.find(
              (e) => e.date === this.selectedDate
            );

            if (!exception || exception.blocks.length === 0)
              time.staffs.splice(i, 1);
          }
        }

        // Set selected services staff
        this.$store.state.booking.selectedServices.forEach((service) => {
          service.staff = time.staffs[0];
        });
      }

      if (this.$store.state.booking.activeClient) {
        this.$router.push({
          name: 'BookConfirm',
          params: { id: this.salonId },
        });
      } else {
        this.$router.push({
          name: 'BookAuth',
          params: { id: this.salonId },
        });
      }

      if (!this.$store.state.booking.currentBookingTracking) {
        this.$store.dispatch(
          'booking/createBookingTracking',
          `Selected Time: ${time.time}`
        );
      } else {
        this.$store.dispatch(
          'booking/updateBookingTracking',
          `Selected Time: ${time.time}`
        );
      }
    },

    async checkForNextAvailableDate() {},

    async getAppointments() {
      this.loading = true;

      this.appointmentsEndDate = this.$moment(
        this.$moment(this.selectedDate).add(1, 'month').endOf('day')
      ).tz(this.$store.state.booking.salon.details.timezone, true);

      await this.$store.dispatch('appointments/getAppointmentsBetweenDates', {
        start: this.$moment(this.$moment(this.selectedDate).startOf('day')).tz(
          this.$store.state.booking.salon.details.timezone,
          true
        ),
        end: this.appointmentsEndDate,
        salonId: this.$store.state.booking.salon._id,
      });

      this.appointments = this.$store.state.appointments.appointments;

      this.setEventsOnStaffs();

      this.loading = false;
    },
    setEventsOnStaffs() {
      const staffs = this.$store.state.booking.salon.staff;

      staffs.forEach((staff) => (staff.events = []));

      this.appointments.forEach((appointment) => {
        if (
          appointment.status === 'no show' ||
          appointment.status === 'cancelled' ||
          appointment.requestedCancellation ||
          !this.$moment(appointment.date).isSame(
            this.$moment(this.selectedDate),
            'date'
          )
        )
          return;

        appointment.services.forEach((service) => {
          const [hour, minute] = service.start.split(':');
          const startTime = this.$moment(appointment.start)
            .hour(hour)
            .minute(minute)
            .second(0);

          const staff = staffs.find((prestaff) => {
            return (
              prestaff._id === service.staff._id ||
              prestaff.staffId === service.staff.staffId
            );
          });

          if (!staff) return;

          staff.events.push({
            start: startTime,
            end: this.$moment(startTime).add(
              service.duration + service.service.extraBlockedTime,
              'minutes'
            ),
          });
        });
      });

      this.getAvailableTimes(this.$store.state.booking.selectedStaff);
    },

    isBeforeMinimum(time) {
      return time.isSameOrBefore(
        this.$moment().add(
          this.$store.state.booking.salon.bookingSettings
            .minimumScheduleTimeHours,
          'hours'
        )
      );
    },
    isSameDay(time) {
      return time.isSame(this.$moment(), 'date');
    },

    async setTimesFromSalonSchedule() {
      this.salonTimes = [];

      const salonSchedule = await this.getSalonSchedule();

      salonSchedule.blocks.forEach((block) => {
        const hourStart = block.start.split(':')[0];
        const minuteStart = block.start.split(':')[1];
        const start = this.$moment(this.selectedDate)
          .hour(hourStart)
          .minute(minuteStart);
        const hourEnd = block.end.split(':')[0];
        const minuteEnd = block.end.split(':')[1];
        const end = this.$moment(this.selectedDate)
          .hour(hourEnd)
          .minute(minuteEnd);

        const timeIndexes = Math.floor(end.diff(start, 'minutes') / 15);

        for (let i = 0; i < timeIndexes; i++) {
          if (
            !this.$store.state.booking.salon.bookingSettings
              .allowSameDayBookings
          ) {
            if (this.isSameDay(this.$moment(start).add(15 * i, 'minutes')))
              continue;
          }

          if (this.isBeforeMinimum(this.$moment(start).add(15 * i, 'minutes')))
            continue;

          this.salonTimes.push({
            time: this.$moment(start)
              .add(15 * i, 'minutes')
              .format('kk:mm'),
            staffs: [],
            chain: [],
          });
        }
      });
    },

    async getSalonSchedule() {
      return await this.$store.dispatch('staff/getStaffScheduleForDay', {
        staff: this.$store.state.booking.salon,
        date: this.$moment(this.selectedDate).toDate(),
      });
    },

    async getStaffSchedule(staff) {
      const staffSchedule = await this.$store.dispatch(
        'staff/getStaffScheduleForDay',
        {
          staff,
          date: this.selectedDate,
        }
      );

      // Schedule
      const scheduleTimes = [];

      staffSchedule.blocks.forEach((block) => {
        const hourStart = block.start.split(':')[0];
        const minuteStart = block.start.split(':')[1];
        const start = this.$moment(this.selectedDate)
          .hour(hourStart)
          .minute(minuteStart);
        const hourEnd = block.end.split(':')[0];
        const minuteEnd = block.end.split(':')[1];
        const end = this.$moment(this.selectedDate)
          .hour(hourEnd)
          .minute(minuteEnd);

        const timeIndexes = Math.floor(end.diff(start, 'minutes') / 15);

        for (let i = 0; i < timeIndexes; i++) {
          scheduleTimes.push({
            time: this.$moment(start)
              .add(15 * i, 'minutes')
              .format('kk:mm'),
            staffs: [staff],
          });
        }
      });

      return scheduleTimes;
    },

    async canStaffDoServices(staff) {
      if (
        staff.services &&
        this.$store.state.booking.selectedServices.every((selectedService) => {
          return staff.services.find(
            (service) => service === selectedService._id
          );
        })
      ) {
        return true;
      }

      return false;
    },

    async getAvailableTimes(staff) {
      if (!staff) {
        let oneStaffCanDoAllServices = false;

        for (let bookingStaff of this.$store.getters['booking/bookingStaff']) {
          const canDoServices = await this.canStaffDoServices(bookingStaff);

          if (canDoServices) {
            oneStaffCanDoAllServices = true;
            await this.getAvailableTimeFromStaff(bookingStaff);
          }
        }

        if (!oneStaffCanDoAllServices) {
          /*
          1) Loop through salon times and find staff that can do first selected service
          2) Add duration of service to that time and see if any staff is available to do second service
          3) Repeat for number of selected services
          */

          // 1
          for (let bookingStaff of this.$store.getters[
            'booking/bookingStaff'
          ]) {
            await this.getAvailableTimeFromStaff(bookingStaff, true);
          }

          // Final touches
          this.clearEmptySalonTimes();
          this.sortStaffsOnSalonTimesByEvents();

          // If no salon times move to next day and so on until there is an available time
          if (!this.salonTimes.length) {
            this.disabledDates.push(this.selectedDate);

            this.autoNextDateTries++;

            if (this.autoNextDateTries > 7) {
              this.$toast.error(
                'No available times found. Please try a later date.'
              );
              return;
            }

            this.selectDate(this.$moment(this.selectedDate).add(1, 'day'));
            return;
          }
          this.autoNextDateTries = 0;

          this.$toast.info(
            `Selected next available date ${this.$moment(
              this.selectedDate
            ).format('LL')}`
          );

          // Loop through services starting at first time,
          // adding the duration of first service, then seeing if that end time
          // conflicts with any staff that can do the next service

          for (let i = 0; i < this.salonTimes.length; i++) {
            const time = this.salonTimes[i].time;
            const [hour, minute] = time.split(':');

            let endTime = this.$moment().hour(hour).minute(minute);

            let chain = [];

            for (
              let j = 0;
              j < this.$store.state.booking.selectedServices.length;
              j++
            ) {
              const service = this.$store.state.booking.selectedServices[j];

              endTime.add(service.duration, 'minutes');

              let nextService;

              if (j !== this.$store.state.booking.selectedServices.length - 1) {
                nextService = this.$store.state.booking.selectedServices[j + 1];
              }

              // Get staff on salon time that can do this service
              const staffsOnTimeslotThatCanDoService = this.salonTimes[
                i
              ].staffs.filter((staff) => {
                return staff.services.includes(service._id);
              });

              if (staffsOnTimeslotThatCanDoService.length) {
                const finalStaffsOnTimeslotThatCanDoService = [];

                // Get staff that can do service that has the availability
                for (
                  let l = 0;
                  l < staffsOnTimeslotThatCanDoService.length;
                  l++
                ) {
                  const staffThatCanDoService =
                    staffsOnTimeslotThatCanDoService[l];

                  if (
                    !(await this.doesSingularServiceDurationConflictWithEvents(
                      staffThatCanDoService,
                      time,
                      service
                    ))
                  ) {
                    finalStaffsOnTimeslotThatCanDoService.push(
                      staffThatCanDoService
                    );
                  }
                }

                if (!finalStaffsOnTimeslotThatCanDoService.length) {
                  chain.length = 0;
                  continue;
                }

                chain.push({
                  time,
                  staff: finalStaffsOnTimeslotThatCanDoService[j],
                  service,
                });

                if (!nextService) continue;

                const staffsThatCanDoNextService = this.$store.getters[
                  'booking/bookingStaff'
                ].filter((staff) => staff.services.includes(nextService._id));

                for (let k = 0; k < staffsThatCanDoNextService.length; k++) {
                  const staff = staffsThatCanDoNextService[k];

                  const canPerformNextService =
                    !(await this.doesSingularServiceDurationConflictWithEvents(
                      staff,
                      this.$moment(endTime).format('kk:mm'),
                      nextService,
                      true
                    ));

                  if (canPerformNextService) {
                    chain.push({
                      time: this.$moment(endTime).format('kk:mm'),
                      staff,
                      service: nextService,
                    });
                  } else {
                    chain.length = 0;
                  }
                }
              } else {
                chain.length = 0;
              }
            }

            chain = chain.filter((link) => link.staff);

            if (chain.length) this.salonTimes[i].chain = chain;
          }

          this.salonTimes = this.salonTimes.filter(
            (time) =>
              time.chain.length >=
              this.$store.state.booking.selectedServices.length
          );

          this.salonTimes.forEach((time) => {
            const uniqueChain = [];

            time.chain.forEach((link) => {
              if (
                !uniqueChain.find((uniqueLink) => uniqueLink.time === link.time)
              ) {
                uniqueChain.push(link);
              }
            });

            time.chain = uniqueChain;
          });

          return;
        }
      } else {
        await this.getAvailableTimeFromStaff(staff);
      }

      // Final touches
      this.clearEmptySalonTimes();
      this.sortStaffsOnSalonTimesByEvents();

      // If no salon times move to next day and so on until there is an available time
      if (!this.salonTimes.length) {
        if (
          staff &&
          staff.schedule &&
          staff.schedule.default &&
          !staff.schedule.default.find((date) => date.blocks.length)
        ) {
          return;
        }
        this.disabledDates.push(this.selectedDate);

        this.autoNextDateTries++;

        if (this.autoNextDateTries > 30) {
          this.$toast.error(
            'No available times found. Please try a later date.'
          );
          return;
        }

        this.selectDate(this.$moment(this.selectedDate).add(1, 'day'));
        return;
      }

      this.$toast.info(
        `Selected next available date ${this.$moment(this.selectedDate).format(
          'LL'
        )}`
      );
    },

    async getAvailableTimeFromStaff(staff, skipAppointmentDuration) {
      // Staff schedule
      const scheduleTimes = await this.getStaffSchedule(staff);

      // Events
      const withEventTimes = [...scheduleTimes];

      if (staff.events) {
        staff.events.forEach((event) => {
          for (let i = withEventTimes.length - 1; i >= 0; i--) {
            if (this.doesStaffEventConflict(event, withEventTimes[i].time)) {
              withEventTimes.splice(i, 1);
            }
          }
        });
      }

      // Duration
      const withThisAppointmentDurationTimes = [...withEventTimes];

      if (!skipAppointmentDuration) {
        for (let i = withThisAppointmentDurationTimes.length - 1; i >= 0; i--) {
          if (
            await this.doesDurationConflictWithEvents(
              staff,
              withThisAppointmentDurationTimes[i].time
            )
          ) {
            withThisAppointmentDurationTimes.splice(i, 1);
          } else if (
            await this.doesDurationConflictWithBlockTimes(
              staff,
              withThisAppointmentDurationTimes[i].time
            )
          ) {
            withThisAppointmentDurationTimes.splice(i, 1);
          }
        }
      }

      // Final times
      withThisAppointmentDurationTimes.forEach((time) => {
        const [hour, minute] = time.time.split(':');
        const realTime = this.$moment(this.selectedDate)
          .hour(hour)
          .minute(minute);

        if (
          realTime.isSameOrBefore(
            this.$moment().add(
              this.$store.state.booking.salon.bookingSettings
                .minimumScheduleTimeHours,
              'hours'
            )
          )
        )
          return;

        this.setStaffOnSalonTimesTimeslot(staff, time.time);
      });
    },

    sortStaffsOnSalonTimesByEvents() {
      this.salonTimes.forEach((time) => {
        time.staffs.sort((a, b) => {
          return (
            (a.events ? a.events.length : 0) - (b.events ? b.events.length : 0)
          );
        });
      });
    },

    clearEmptySalonTimes() {
      this.salonTimes = this.salonTimes.filter((time) => time.staffs.length);
    },

    setStaffOnSalonTimesTimeslot(staff, time) {
      const salonTime = this.salonTimes.find((pretime) => {
        return pretime.time === time;
      });

      if (!salonTime) return;

      salonTime.staffs.push(staff);
    },

    doesStaffEventConflict(event, time) {
      const [hour, minute] = time.split(':');
      const blockTime = this.$moment(this.selectedDate)
        .hour(hour)
        .minute(minute)
        .second(0);

      const eventStart = this.$moment(this.selectedDate)
        .hour(this.$moment(event.start).hour())
        .minute(event.start.minute())
        .startOf('minute');
      const eventEnd = this.$moment(this.selectedDate)
        .hour(this.$moment(event.end).hour())
        .minute(event.end.minute());

      if (blockTime.isBetween(eventStart, eventEnd, 'second', '[)')) {
        return true;
      }
    },

    async doesSingularServiceDurationConflictWithEvents(
      staff,
      time,
      service,
      reverse
    ) {
      const [hour, minute] = time.split(':');
      const blockTime = this.$moment(this.selectedDate)
        .hour(hour)
        .minute(minute)
        .second(0);

      const appointmentDurationEndTime = this.$moment(blockTime).add(
        service.duration,
        'minutes'
      );

      let conflict = false;

      if (staff.events) {
        staff.events.forEach((event) => {
          if (!reverse) {
            // Default way
            if (
              this.$moment(event.start)
                .date(this.$moment(this.selectedDate).date())
                .isBetween(
                  blockTime,
                  appointmentDurationEndTime,
                  'seconds',
                  '[)'
                )
            ) {
              conflict = true;
            }
          } else {
            const appStart = this.$moment(event.start).date(
              this.$moment(this.selectedDate).date()
            );
            const appEnd = this.$moment(event.end).date(
              this.$moment(this.selectedDate).date()
            );

            if (
              this.$moment(blockTime).isBetween(
                appStart,
                appEnd,
                'seconds',
                '[)'
              )
            ) {
              conflict = true;
            } else if (
              this.$moment(appointmentDurationEndTime).isBetween(
                appStart,
                appEnd,
                'seconds',
                '[)'
              )
            ) {
              conflict = true;
            }
          }
        });
      }

      return conflict;
    },

    async doesDurationConflictWithEvents(staff, time) {
      const [hour, minute] = time.split(':');
      const blockTime = this.$moment(this.selectedDate)
        .hour(hour)
        .minute(minute)
        .second(0);

      const appointmentDurationEndTime = this.$moment(blockTime).add(
        this.$store.getters['booking/servicesDurationWithBlockedTime'],
        'minutes'
      );

      let conflict = false;

      if (staff.events) {
        staff.events.forEach((event) => {
          if (
            this.$moment(event.start)
              .date(this.$moment(this.selectedDate).date())
              .isBetween(blockTime, appointmentDurationEndTime, 'seconds', '[)')
          ) {
            conflict = true;
          }
        });
      }

      return conflict;
    },

    async doesDurationConflictWithBlockTimes(staff, time) {
      const [hour, minute] = time.split(':');
      const blockTime = this.$moment(this.selectedDate)
        .hour(hour)
        .minute(minute)
        .second(0);

      const appointmentDurationEndTime = this.$moment(blockTime).add(
        this.$store.getters['booking/servicesDurationWithBlockedTime'],
        'minutes'
      );

      const staffSchedule = await this.$store.dispatch(
        'staff/getStaffScheduleForDay',
        {
          staff,
          date: this.selectedDate,
        }
      );

      for (let i = 0; i < staffSchedule.blocks.length; i++) {
        const [hour, minute] = staffSchedule.blocks[i].end.split(':');
        const scheduleBlockTime = this.$moment(this.selectedDate)
          .hour(hour)
          .minute(minute)
          .second(0);

        if (i === staffSchedule.blocks.length - 1) {
          if (
            appointmentDurationEndTime.isAfter(scheduleBlockTime, 'seconds')
          ) {
            return true;
          }
        } else {
          const [nextHour, nextMinute] =
            staffSchedule.blocks[i + 1].start.split(':');
          const nextScheduleBlockTime = this.$moment(this.selectedDate)
            .hour(nextHour)
            .minute(nextMinute)
            .second(0);

          if (
            appointmentDurationEndTime.isBetween(
              scheduleBlockTime,
              nextScheduleBlockTime,
              'seconds',
              '[)'
            )
          ) {
            return true;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.date {
  .page-title {
    text-align: center;
    margin-left: 0;
  }
  h2 {
    margin-left: 16px;
  }

  &_date {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }

  &_times {
    margin-top: 32px;

    .small {
      font-size: 14px;
    }
    .light {
      color: var(--clr-gray);
      line-height: 20px;
      margin-top: 8px;
    }

    &_times {
      margin-top: 32px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 16px;
    }

    .none {
      margin-top: 32px;
      padding: 16px;
      border: 1px solid var(--clr-light);
      border-radius: 5px;
    }
  }
}

// Tablet
@media (max-width: 900px) {
  .date {
    &_times {
      &_times {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}
</style>
