<template>
  <form @submit.prevent="submit" class="form">
    <BaseSelect
      v-if="!newReader.id"
      label="Location"
      :options="locationOptions"
      @input="newReader.location = $event"
    />
    <BaseInput
      label="Registration code"
      :placeholder="
        newReader.id ? '**Not displayed for security**' : 'puppies-plug-could'
      "
      :value="newReader.registration_code"
      :disabled="newReader.id"
      @input="newReader.registration_code = $event"
    />
    <BaseInput
      label="Label"
      placeholder="Blue Rabbit"
      :value="newReader.label"
      @input="newReader.label = $event"
    />
    <div class="form_actions">
      <BaseButton
        v-if="newReader.id"
        @click="showConfirmDelete = true"
        type="button"
        :disabled="loading"
        mode="danger-outline"
        >Delete Reader</BaseButton
      >
      <BaseButton :disabled="loading"
        ><i v-if="loading" class="fas fa-spinner"></i
        >{{ newReader.id ? 'Edit' : 'Create' }} Reader</BaseButton
      >
    </div>
  </form>

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Delete reader"
    text="Are you sure you wish to delete this reader? This action can not be undone."
    @confirm="deleteReader"
    @deny="showConfirmDelete = false"
  />
</template>

<script>
export default {
  emits: ['created', 'edited'],
  props: {
    accountId: {
      type: String,
      required: true,
    },
    reader: {
      type: Object,
    },
  },
  created() {
    if (this.reader)
      this.newReader = JSON.parse(
        JSON.stringify({ ...this.newReader, ...this.reader })
      );
  },
  computed: {
    locationOptions() {
      const options = [];

      this.$store.state.stripe.locations.forEach((location) => {
        options.push({
          option: location.display_name,
          value: location.id,
        });
      });

      return options;
    },
  },
  data() {
    return {
      loading: false,
      showConfirmDelete: false,

      newReader: {
        registration_code: '',
        label: '',
        location: '',
      },
    };
  },
  methods: {
    async submit() {
      this.loading = true;

      if (this.newReader.id) {
        // Edit
        try {
          const response = await this.$axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/readers/${this.newReader.id}?accountId=${this.accountId}`,
            {
              id: this.newReader.id,
              reader: {
                label: this.newReader.label,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );

          this.$emit('edited', response.data.reader);
        } catch (error) {
          this.$toast.error(error.response.data.error);
        }
      } else {
        // Create
        try {
          const response = await this.$axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/readers?accountId=${this.accountId}`,
            this.newReader,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );

          this.$emit('created', response.data.reader);
        } catch (error) {
          this.$toast.error(error.response.data.error);
        }
      }

      this.loading = false;
    },
    async deleteReader() {
      this.loading = true;

      try {
        await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/stripe/readers/${this.newReader.id}?accountId=${this.accountId}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$emit('deleted', this.newReader);
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &_actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
