<template>
  <div class="endpoint_head">
    <h2>Update kiosk settings</h2>

    <div class="route">
      <i
        @click="
          $router.push('/documentation/api/settings#updateKioskSettings');
          $toast.info('Set URL to endpoint');
        "
        :style="{ color: 'var(--clr-secondary)', cursor: 'pointer' }"
        class="fas fa-hashtag"
      ></i>
      <p class="tag tag-blue">PUT</p>
      <p class="url">{{ apiUrl }}<span>/salons/:id/settings/kiosk</span></p>
    </div>

    <div class="description">
      <p>Update a salons kiosk settings</p>
    </div>
  </div>

  <div class="endpoint_body">
    <div class="request">
      <h3>Request</h3>

      <!-- Headers -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Headers</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">Authorization</p>
              <p class="examples">Examples: <span>Bearer {Token}</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Path params -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Path Parameters</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">ID</p>
              <p class="description">ID of salon</p>
              <p class="examples">
                Examples: <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6f</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
          <p>application/json</p>
        </div>

        <ul>
          <!-- use full name -->
          <li>
            <div class="col value">
              <p class="bold">useFullName</p>
              <p class="description">
                Ask client to enter full name in one field, overrides
                "useFirstName" and "useLastName" fields
              </p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- use first name -->
          <li>
            <div class="col value">
              <p class="bold">useFirstName</p>
              <p class="description">Ask client to enter first name in</p>
              <p class="examples">Examples: <span>false</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- use last name -->
          <li>
            <div class="col value">
              <p class="bold">useLastName</p>
              <p class="description">Ask client to enter last name in</p>
              <p class="examples">Examples: <span>false</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- use email -->
          <li>
            <div class="col value">
              <p class="bold">useEmail</p>
              <p class="description">Ask client to enter email in</p>
              <p class="examples">Examples: <span>false</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- use DOB -->
          <li>
            <div class="col value">
              <p class="bold">useDOB</p>
              <p class="description">
                Ask client to enter their date of birth in
              </p>
              <p class="examples">Examples: <span>false</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- note -->
          <li>
            <div class="col value">
              <p class="bold">note</p>
              <p class="description">Allow client to enter a note in</p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- font size -->
          <li>
            <div class="col value">
              <p class="bold">inputFontSize</p>
              <p class="description">
                Font size of inputs in px (for flexbility in screen sizes)
                [Number - Min 1, Max 148]
              </p>
              <p class="examples">Examples: <span>16</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="response">
      <h3>Response</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">kioskSettings</p>
              <p class="examples">
                Examples:
                <span
                  >{ "useFullName": false, "useFirstName": true, "useLastName":
                  true, "useEmail": true, "useDOB": true, "note": "Custom
                  Settings!", "inputFontSize": 32 }</span
                >
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    apiUrl() {
      return process.env.VUE_APP_RASERVA_BACKEND;
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoint {
  .tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px;
    border-radius: 5px;
    color: white;

    &-blue {
      background-color: var(--clr-secondary);
    }
  }

  &_head {
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }

    .route {
      padding: 8px 16px;
      border-radius: 5px;
      background-color: var(--clr-white-2);
      display: flex;
      align-items: center;
      gap: 16px;

      .url {
        color: var(--clr-gray);

        span {
          color: var(--clr-primary);
          font-weight: 700;
        }
      }
    }

    .description {
      margin-top: 32px;
    }
  }

  &_body {
    .request,
    .response,
    .reqResExamples {
      margin-top: 32px;
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &_section {
      &:not(:first-child) {
        margin-top: 32px;
      }

      .head {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 20px;
          margin-bottom: 16px;
          color: var(--clr-secondary);
        }

        p {
          font-size: 14px;
          color: var(--clr-gray);
        }
      }

      ul {
        .subitem {
          margin-left: 32px;
        }

        li {
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          &:not(:first-child) {
            margin-top: 16px;
          }

          .value {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .bold {
            font-weight: 700;
          }
          .required {
            color: orangered;
          }

          .description {
            margin: 4px 0;
            color: var(--clr-gray);
          }

          .examples {
            span {
              background-color: var(--clr-white-2);
              padding: 4px 8px;
              border-radius: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
