<template>
  <form @submit.prevent="submit" class="form">
    <ServiceSelect
      @select="newRedemption.item = $event"
      :service="{ service: newRedemption.item }"
    />

    <BaseInput
      label="Cost (In Loyalty Points)"
      type="number"
      @input="newRedemption.cost = +$event"
      :value="newRedemption.cost"
    />

    <div class="toggle">
      <Toggle
        :checked="newRedemption.public"
        @toggle="newRedemption.public = !newRedemption.public"
      />
      <div class="toggle_text">
        <p class="toggle_text_label">Public</p>
        <p class="toggle_text_subtext">
          Marking this as public will allows clients to view this redemption on
          your redemption menu on their profile
        </p>
      </div>
    </div>

    <div class="form_actions">
      <BaseButton
        v-if="newRedemption._id"
        @click="$emit('delete')"
        type="button"
        mode="danger-outline"
        >Delete</BaseButton
      >
      <BaseButton>Submit</BaseButton>
    </div>
  </form>
</template>

<script>
import ServiceSelect from '@/components/items/ServiceSelect.vue';

export default {
  emits: ['submit', 'delete'],
  props: {
    redemption: {
      type: Object,
    },
  },
  components: {
    ServiceSelect,
  },
  created() {
    if (this.redemption)
      this.newRedemption = JSON.parse(JSON.stringify(this.redemption));
  },
  data() {
    return {
      newRedemption: {
        item: null,
        cost: 0,
        public: true,
      },
    };
  },
  methods: {
    submit() {
      if (!this.newRedemption.item) {
        this.$toast.error('Please select an item');
        return;
      }

      if (!this.newRedemption.cost) {
        this.$toast.error('Please enter a cost');
        return;
      }

      this.$emit('submit', this.newRedemption);
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}

.toggle {
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 16px 0;

  &_text {
    &_label {
      font-size: 18px;
    }
    &_subtext {
      margin-top: 5px;
      font-size: 14px;
    }
  }
}
</style>
