<template>
  <form @submit.prevent="submit" class="form">
    <div class="form_section">
      <div class="form_section_head">
        <h3>Basic Info</h3>
      </div>
      <div class="form_section_body">
        <div class="image-name">
          <div class="image-col">
            <ImageSelect
              :fileSource="newMember.profileImage"
              @select="newMember.profileImage = $event"
            />
          </div>
          <div class="name-col">
            <BaseInput
              label="First Name"
              @input="newMember.firstName = $event"
              :value="newMember.firstName"
              :validators="validators.name"
            />
            <BaseInput
              label="Last Name"
              @input="newMember.lastName = $event"
              :value="newMember.lastName"
              :validators="validators.name"
            />
          </div>
        </div>
        <BaseInput
          label="Staff Title"
          subtitle="Visible to all clients"
          @input="newMember.title = $event"
          :validators="validators.title"
          :value="newMember.title"
        />
        <BaseInput
          label="Account Code"
          subtitle="Used when being clocked in by another team member and other cases"
          @input="newMember.accountCode = $event"
          :validators="validators.accountCode"
          :value="newMember.accountCode"
        />
        <!-- <BaseSelect
          :options="groupOptions"
          subtitle="Click here to create new group"
          subtitleLink
          @subtitleClick="showCreateGroup = true"
          :value="newMember.group"
          label="Group"
          @input="newMember.group = $event"
        /> -->
        <div class="groupsContainer">
          <p class="bold">Groups</p>

          <div class="groupsContainer_groups">
            <div
              v-for="(group, i) in groups"
              :key="i"
              class="group"
              @click="selectGroup(group)"
              :class="{ selected: groupIsSelected(group) }"
            >
              <p>{{ group }}</p>
            </div>
          </div>

          <p class="light">
            Select all that apply.
            <span class="link" @click="showCreateGroup = true"
              >Click here to create new group.</span
            >
          </p>
        </div>
        <BaseInput
          label="Birthday"
          inputType="date"
          :value="formattedBirthday"
          @input="updateBirthday($event)"
        />
        <BaseTextarea
          label="Notes"
          placeholder="Private notes not visible to clients"
          @input="newMember.notes = $event"
          :value="newMember.notes"
        />
      </div>
    </div>
    <div class="form_section">
      <div class="form_section_head">
        <h3>Contact</h3>
        <p>
          Member contact info is confidential and won't be shared with clients.
        </p>
      </div>
      <div class="form_section_body">
        <div class="col-2">
          <BaseInput
            label="Email"
            inputType="email"
            placeholder="mail@example.com"
            @input="newMember.email = $event"
            :value="newMember.email"
            :validators="validators.email"
            :disabled="newMember._id && !newMember.allowsEdits"
          />
          <BasePhoneInput
            label="Mobile Number"
            @input="newMember.phoneNumber = $event"
            :value="newMember.phoneNumber"
            :validators="validators.phone"
          />
        </div>
        <BaseInput
          v-if="!newMember._id"
          inputType="password"
          label="Password"
          @input="newMember.password = $event"
          :value="newMember.password"
          :validators="validators.password"
        />
      </div>
    </div>
    <div class="form_section">
      <div class="form_section_head">
        <h3>Booking</h3>
      </div>
      <div class="form_section_body">
        <div class="toggle">
          <Toggle
            :checked="newMember.onBooking"
            @toggle="newMember.onBooking = !newMember.onBooking"
          />
          <div class="toggle_text">
            <p class="toggle_text_label">Allow Calendar Bookings</p>
            <p class="toggle_text_subtext">
              Allow this member to receive bookings on your calendar
            </p>
          </div>
        </div>
        <div class="toggle">
          <Toggle
            :checked="newMember.onCalendar"
            @toggle="newMember.onCalendar = !newMember.onCalendar"
          />
          <div class="toggle_text">
            <p class="toggle_text_label">Display On Calendar</p>
            <p class="toggle_text_subtext">
              Display this member on your dashboard calendar
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="form_section">
      <div class="form_section_head">
        <h3>Services & Custom Commissions</h3>
        <p>Add services that this team member offers and custom commissions</p>
      </div>
      <div class="form_section_body">
        <div class="services_container">
          <p>
            <span class="bold">{{
              memberCapableServicesFromSalonServices
            }}</span>
            out of <span class="bold">{{ totalServices }}</span> services
          </p>
          <p class="link" @click="showCapableServices = true">Update</p>
        </div>

        <BaseInput
          inputType="number"
          label="Default Service Level"
          subtitle="Service levels can be adjusted individually"
          :value="newMember.defaultServiceLevel"
          @input="newMember.defaultServiceLevel = +$event"
          step="1"
          :validators="{ min: 1 }"
        />
      </div>
    </div>
    <div class="form_section">
      <div class="form_section_head">
        <h3>Commission</h3>
      </div>
      <div class="form_section_body">
        <BaseInput
          inputType="number"
          placeholder="%"
          label="Commission Percentage (%)"
          @input="newMember.commission = $event"
          :validators="validators.commission"
          :value="newMember.commission"
        />
        <BaseInput
          inputType="number"
          placeholder="$"
          label="Daily Working Fee ($)"
          @input="newMember.dailyFee = +$event"
          :validators="validators.dailyFee"
          :value="newMember.dailyFee"
        />
        <div class="toggle">
          <Toggle
            :checked="newMember.exemptFromServiceFees"
            @toggle="
              newMember.exemptFromServiceFees = !newMember.exemptFromServiceFees
            "
          />
          <div class="toggle_text">
            <p class="toggle_text_label">Exempt From Service Fees</p>
            <p class="toggle_text_subtext">
              If enabled this team member will not be charged service fees
            </p>
          </div>
        </div>
        <div class="toggle">
          <Toggle
            :checked="newMember.trackHourly"
            @toggle="newMember.trackHourly = !newMember.trackHourly"
          />
          <div class="toggle_text">
            <p class="toggle_text_label">Track Hourly</p>
            <p class="toggle_text_subtext">
              Enable this if this team member is paid by the hour
            </p>
          </div>
        </div>
        <BaseInput
          v-if="newMember.trackHourly"
          inputType="number"
          placeholder="$"
          label="Hourly Rate (USD)"
          @input="newMember.hourlyRate = $event"
          :validators="validators.hourlyRate"
          :value="newMember.hourlyRate"
        />
        <div class="col-2">
          <BaseInput
            inputType="number"
            placeholder="%"
            label="Check Split (%)"
            :value="newMember.splitCheck"
            @input="
              newMember.splitCheck = +$event;
              newMember.splitOther = 100 - $event;
            "
          />
          <BaseInput
            inputType="number"
            disabled
            placeholder="%"
            label="Other Split (%)"
            :value="newMember.splitOther"
            @input="newMember.splitOther = +$event"
          />
        </div>
      </div>
    </div>
    <div class="form_section">
      <div class="form_section_head">
        <h3>Staff Role</h3>
      </div>
      <div class="form_section_body">
        <BaseSelect
          inputType="number"
          :options="roleOptions"
          @input="newMember.role = $event"
          :value="newMember.role || 2"
        />
      </div>
    </div>
    <div v-if="newMember._id" class="form_section">
      <div class="form_section_head">
        <h3>Access</h3>
      </div>
      <div class="form_section_body">
        <div class="toggle">
          <Toggle
            :checked="newMember.isDeleted"
            @toggle="newMember.isDeleted = !newMember.isDeleted"
          />
          <div class="toggle_text">
            <p class="toggle_text_label">Revoke Access</p>
            <p class="toggle_text_subtext">
              Enable this if this team member should no longer have access to
              this account, but you still wish to keep their info on file.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="form_actions">
      <BaseButton
        v-if="newMember._id && !salonAdmin"
        mode="danger-outline"
        type="button"
        @click="showConfirmDelete = true"
        >Delete Member</BaseButton
      >
      <BaseButton :disabled="!formValid || loading" type="submit"
        ><i v-if="loading" class="fas fa-spinner"></i> Submit</BaseButton
      >
    </div>
  </form>

  <Modal
    title="Select services"
    maxWidth="600px"
    :show="showCapableServices"
    @close="$refs.capableServicesModal.checkForUnsavedChanges()"
  >
    <StaffCapableServices
      :staffServices="newMember.services"
      :staffCustomCommissions="newMember.customCommissions"
      :staffCustomLevels="newMember.customServiceLevels"
      :staffDefaultServiceLevel="newMember.defaultServiceLevel"
      :staffMode="false"
      ref="capableServicesModal"
      @submit="
        newMember.services = $event.services;
        newMember.customCommissions = $event.commissions;
        newMember.customServiceLevels = $event.levels;
        showCapableServices = false;
      "
      @exit="showCapableServices = false"
    />
  </Modal>

  <Modal
    title="Create group"
    :show="showCreateGroup"
    maxWidth="600px"
    @close="showCreateGroup = false"
  >
    <div class="createGroup">
      <GroupsForm
        @updated="
          init();
          showCreateGroup = false;
        "
      />
      <!-- <form @submit.prevent="createGroup" class="form">
        <BaseInput
          label="Group Name"
          placeholder="Hair Stylists"
          @input="newGroupName = $event"
        />
        <div class="form_actions">
          <BaseButton :disabled="!groupFormValid">Create Group</BaseButton>
        </div>
      </form> -->
    </div>
  </Modal>

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Delete team member"
    text="Are you sure you wish to delete this team member? This action can not be undone."
    @confirm="deleteMember"
    @deny="showConfirmDelete = false"
  />

  <Confirm
    v-if="showConfirmUnsavedChanges"
    title="Unsaved Changes"
    text="You have unsaved changes. Are you sure you wish to leave this page?"
    confirmTextOverride="Exit"
    thirdButton="Save Changes"
    @confirm="$emit('exit')"
    @deny="showConfirmUnsavedChanges = false"
    @thirdButtonClick="submit"
  />
</template>

<script>
import StaffCapableServices from '@/components/staff/StaffCapableServices.vue';
import ImageSelect from '@/components/components/ImageSelect.vue';
import GroupsForm from '@/components/staff/GroupsForm.vue';

export default {
  emits: ['submitted', 'deleted', 'exit'],
  components: {
    StaffCapableServices,
    ImageSelect,
    GroupsForm,
  },
  props: {
    member: {
      type: Object,
    },
  },
  created() {
    if (this.member) this.newMember = JSON.parse(JSON.stringify(this.member));

    this.init();

    if (!this.$store.state.auth.salon) return;

    if (
      !this.member &&
      this.$store.state.auth.salon.adminSettings.defaultTrackHourly
    ) {
      this.newMember.trackHourly = true;
    }
  },
  data() {
    return {
      showConfirmDelete: false,
      showConfirmUnsavedChanges: false,
      showCapableServices: false,
      showCreateGroup: false,

      newGroupName: '',
      groupOptions: [],

      loading: false,

      newMember: {
        firstName: '',
        lastName: '',
        title: '',
        notes: '',
        email: '',
        accountCode: '',
        password: '',
        profileImage: '',
        phoneNumber: '',
        group: '',
        groups: [],
        birthday: null,
        onBooking: false,
        onCalendar: false,
        commission: 0,
        dailyFee: 0,
        splitCheck: 100,
        splitOther: 0,
        trackHourly: false,
        hourlyRate: 0,
        role: 'low',
        services: [],
        defaultServiceLevel: 1,
        isDeleted: false,
        exemptFromServiceFees: false,
      },
    };
  },
  methods: {
    init() {
      const options = [
        {
          option: '',
          value: null,
        },
      ];

      this.$store.state.salon.salon.groups.forEach((group) => {
        options.push({
          option: group,
          value: group,
        });
      });

      this.groupOptions = options;
    },

    checkForUnsavedChanges() {
      if (
        (this.newMember._id &&
          JSON.stringify(this.newMember) !== JSON.stringify(this.member)) ||
        (!this.newMember._id &&
          (this.newMember.firstName ||
            this.newMember.lastName ||
            this.newMember.commission))
      ) {
        this.showConfirmUnsavedChanges = true;
      } else {
        this.$emit('exit');
      }
    },

    groupIsSelected(group) {
      return this.newMember.groups.find((preGroup) => preGroup === group);
    },
    selectGroup(group) {
      const index = this.newMember.groups.findIndex(
        (preGroup) => preGroup === group
      );

      if (index === -1) {
        // Add group
        this.newMember.groups.push(group);
      } else {
        // Remove group
        this.newMember.groups.splice(index, 1);
      }
    },

    async submit() {
      this.loading = true;

      // Default hourly rate if null
      if (!this.newMember.hourlyRate) this.newMember.hourlyRate = 0;

      try {
        if (this.newMember._id) {
          if (this.newMember.isDeleted) {
            this.newMember.onBooking = false;
            this.newMember.onCalendar = false;
          }

          const logReasons = this.checkForLogReasons();

          await this.$store.dispatch('staff/editStaff', {
            ...this.newMember,
            logReasons,
          });
          this.submitted(this.newMember);
          return;
        }

        const staff = await this.$store.dispatch('staff/createStaff', {
          ...this.newMember,
          allowsEdits: true,
        });
        this.submitted(staff);
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    checkForLogReasons() {
      const logReasons = [];

      // Commission
      if (this.newMember.commission !== this.member.commission) {
        logReasons.push(
          `${this.$store.state.auth.salon.details.shopName}${
            this.$store.state.auth.salon.details.altName
              ? ` ${this.$store.state.auth.salon.details.altName}`
              : ''
          } edited commission for ${this.newMember.firstName} ${
            this.newMember.lastName
          } from ${this.member.commission}% to ${this.newMember.commission}%`
        );
      }
      // Calendar bookings
      if (this.newMember.onBooking !== this.member.onBooking) {
        logReasons.push(
          `${this.$store.state.auth.salon.details.shopName}${
            this.$store.state.auth.salon.details.altName
              ? ` ${this.$store.state.auth.salon.details.altName}`
              : ''
          } ${
            this.newMember.onBooking ? 'enabled' : 'disabled'
          } online bookings for ${this.newMember.firstName} ${
            this.newMember.lastName
          }`
        );
      }
      // Track hourly
      if (this.newMember.trackHourly !== this.member.trackHourly) {
        logReasons.push(
          `${this.$store.state.auth.salon.details.shopName}${
            this.$store.state.auth.salon.details.altName
              ? ` ${this.$store.state.auth.salon.details.altName}`
              : ''
          } ${
            this.newMember.trackHourly ? 'enabled' : 'disabled'
          } hourly tracking for ${this.newMember.firstName} ${
            this.newMember.lastName
          }`
        );
      }
      // Hourly rate
      if (this.newMember.hourlyRate !== this.member.hourlyRate) {
        logReasons.push(
          `${this.$store.state.auth.salon.details.shopName}${
            this.$store.state.auth.salon.details.altName
              ? ` ${this.$store.state.auth.salon.details.altName}`
              : ''
          } edited hourly rate for ${this.newMember.firstName} ${
            this.newMember.lastName
          } from $${this.member.hourlyRate} to $${this.newMember.hourlyRate}`
        );
      }

      return logReasons;
    },

    submitted(staff) {
      this.$toast.success('Team member updated');
      this.$emit('submitted', staff);
    },

    async deleteMember() {
      try {
        await this.$store.dispatch('staff/deleteStaff', this.newMember);
        this.$toast.success('Team member deleted');

        this.$emit('deleted', this.newMember._id);
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    updateBirthday(date) {
      this.newMember.birthday = this.$moment(date).toDate();
    },

    async createGroup() {
      try {
        await this.$store.dispatch('staff/createGroup', this.newGroupName);

        this.showCreateGroup = false;

        this.groupOptions.push({
          option: this.newGroupName,
          value: this.newGroupName,
        });

        this.newMember.group = this.newGroupName;
        this.newGroupName = '';

        this.$toast.success('Group created');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
  },
  computed: {
    groups() {
      if (!this.$store.state.salon.salon) return;

      return this.$store.state.salon.salon.groups;
    },
    formattedBirthday() {
      if (!this.newMember || !this.newMember.birthday) return null;

      return this.$moment(this.newMember.birthday).format('YYYY-MM-DD');
    },
    memberCapableServicesFromSalonServices() {
      if (!this.newMember.services.length) return 0;

      let count = 0;

      this.newMember.services.forEach((service) => {
        if (
          this.$store.getters['items/services'].find(
            (item) => item._id === service
          )
        )
          count++;
      });

      return count;
    },
    groupFormValid() {
      if (this.newGroupName.length < 2) return false;
      return true;
    },
    validators() {
      return {
        name: {
          required: true,
          minLength: 2,
          maxLength: 50,
        },
        title: {
          minLength: 2,
          maxLength: 50,
        },
        email: {
          required: true,
          email: true,
        },
        accountCode: {
          required: true,
          minLength: 4,
          maxLength: 10,
        },
        phone: {
          required: true,
          minLength: 2,
          maxLength: 50,
        },
        password: {
          required: true,
          minLength: 6,
          maxLength: 100,
        },
        commission: {
          required: true,
          min: 0,
          max: 100,
        },
        dailyFee: {
          min: 0,
        },
      };
    },
    formValid() {
      if (
        !this.newMember.firstName ||
        !this.newMember.lastName ||
        !this.newMember.email ||
        !this.newMember.phoneNumber ||
        (!this.newMember._id &&
          (!this.newMember.password || this.newMember.password.length < 6)) ||
        !this.newMember.role ||
        this.newMember.splitOther > 100 ||
        this.newMember.splitOther < 0 ||
        this.newMember.splitCheck > 100 ||
        this.newMember.splitCheck < 0
      )
        return false;
      return true;
    },
    roleOptions() {
      const roles = [
        {
          option: 'Basic',
          value: 'basic',
        },
        {
          option: 'Low',
          value: 'low',
        },
        {
          option: 'Medium',
          value: 'medium',
        },
        {
          option: 'High',
          value: 'high',
        },
      ];

      if (this.$store.getters['auth/loggedInStaffIsAdmin']) {
        roles.push({
          option: 'Admin',
          value: 'admin',
        });
      }

      return roles;
    },
    totalServices() {
      let count = 0;

      this.$store.state.items.services.forEach(
        (category) => (count += category.items.length)
      );

      return count;
    },
    salonAdmin() {
      return this.newMember.staffId === this.$store.state.auth.salon.adminId;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  &_section {
    padding: 32px;
    border: 1px solid var(--clr-light-2);
    border-radius: 5px;

    &:not(:first-child) {
      margin-top: 16px;
    }

    &_head {
      margin-bottom: 16px;

      p {
        font-size: 14px;
        margin-top: 5px;
        color: var(--clr-gray);
      }
    }

    .image-name {
      margin-top: 16px;
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 16px;

      .name-col {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }

    .col-2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }

    &_body {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;

      .toggle {
        display: flex;
        align-items: center;
        gap: 32px;
        flex-wrap: wrap;
        padding: 16px 0;

        &_text {
          &_label {
            font-size: 18px;
          }
          &_subtext {
            margin-top: 5px;
            font-size: 14px;
          }
        }
      }

      .groupsContainer {
        .bold {
          font-size: 14px;
          font-weight: 700;
          color: var(--clr-gray-2);
          margin-bottom: 12px;
        }
        .light {
          font-size: 13px;
          color: var(--clr-gray);
          margin-top: 12px;
        }
        .link {
          cursor: pointer;
          color: var(--clr-link);
        }

        &_groups {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;

          .selected {
            background-color: var(--clr-secondary) !important;
            color: white;
          }

          .group {
            font-size: 14px;
            padding: 6px 16px;
            border-radius: 50rem;
            background-color: var(--clr-light);
            cursor: pointer;
          }
        }
      }

      .services_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        background-color: var(--clr-white-2);
        border-radius: 5px;

        .bold {
          font-weight: 700;
        }
        .link {
          transition: text-decoration 0.2s ease;
          cursor: pointer;
          color: var(--clr-link);

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &_actions {
    margin-top: 16px;
    text-align: right;

    button:not(:first-child) {
      margin-left: 16px;
    }
  }
}

.createGroup {
  padding: 32px;

  .form {
    &_actions {
      margin-top: 32px;
    }
  }
}
</style>
