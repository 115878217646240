<template>
  <div class="endpoint_head">
    <h2>Edit service in category</h2>

    <div class="route">
      <i
        @click="
          $router.push('/documentation/api/services#updateService');
          $toast.info('Set URL to endpoint');
        "
        :style="{ color: 'var(--clr-secondary)', cursor: 'pointer' }"
        class="fas fa-hashtag"
      ></i>
      <p class="tag tag-blue">PUT</p>
      <p class="url">
        {{ apiUrl
        }}<span
          >/salons/:salonId/serviceCategory/:categoryId/items/:itemId</span
        >
      </p>
    </div>

    <div class="description">
      <p>Edit a service in a service category</p>
    </div>
  </div>

  <div class="endpoint_body">
    <div class="request">
      <h3>Request</h3>

      <!-- Headers -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Headers</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">Authorization</p>
              <p class="examples">Examples: <span>Bearer {Token}</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Path params -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Path Parameters</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">salonId</p>
              <p class="description">ID of salon</p>
              <p class="examples">
                Examples: <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6f</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <li>
            <div class="col value">
              <p class="bold">categoryId</p>
              <p class="description">ID of category to update</p>
              <p class="examples">
                Examples: <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6d</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <li>
            <div class="col value">
              <p class="bold">itemId</p>
              <p class="description">ID of item to update</p>
              <p class="examples">
                Examples: <span>5f8a1d9b-3b0b-4c9a-9c0d-1a2b3c4d5e6b</span>
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
          <p>application/json</p>
        </div>

        <ul>
          <!-- Title -->
          <li>
            <div class="col value">
              <p class="bold">title</p>
              <p class="description">String</p>
              <p class="examples">Examples: <span>Men's Haircut</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- description -->
          <li>
            <div class="col value">
              <p class="bold">description</p>
              <p class="description">String</p>
              <p class="examples">
                Examples:
                <span
                  >A haircut for men, typically around 30 mins, consulation may
                  be required.</span
                >
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- color -->
          <li>
            <div class="col value">
              <p class="bold">color</p>
              <p class="description">
                String - Color hex code - Typically the same as the category
                color but can be different
              </p>
              <p class="examples">Examples: <span>#ffffff</span></p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>

          <!-- on booking -->
          <li>
            <div class="col value">
              <p class="bold">onBooking</p>
              <p class="description">Boolean</p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- commissioned -->
          <li>
            <div class="col value">
              <p class="bold">isCommissioned</p>
              <p class="description">Boolean</p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- Commission Type -->
          <li>
            <div class="col value">
              <p class="bold">commissionType</p>
              <p class="description">String - Defaults to "percent"</p>
              <p class="examples">Examples: <span>'percent', 'flat'</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- Commission Amount -->
          <li>
            <div class="col value">
              <p class="bold">commissionAmount</p>
              <p class="description">
                Number - If "commissionType" is set to "percent" this will be
                60%, otherwise if "commissionType" is set the "flat" this will
                be $60 - Defaults to 0
              </p>
              <p class="examples">Examples: <span>60</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- Price Type -->
          <li>
            <div class="col value">
              <p class="bold">priceType</p>
              <p class="description">String - Defaults to "fixed"</p>
              <p class="examples">
                Examples: <span>'fixed', 'from', 'duration'</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- displayByConsultationOnBooking -->
          <li>
            <div class="col value">
              <p class="bold">displayByConsultationOnBooking</p>
              <p class="description">Boolean</p>
              <p class="examples">Examples: <span>false</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- turns -->
          <li>
            <div class="col value">
              <p class="bold">turns</p>
              <p class="description">Number</p>
              <p class="examples">Examples: <span>1</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- price -->
          <li>
            <div class="col value">
              <p class="bold">price</p>
              <p class="description">Number</p>
              <p class="examples">Examples: <span>19.99</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- salePrice -->
          <li>
            <div class="col value">
              <p class="bold">salePrice</p>
              <p class="description">Number</p>
              <p class="examples">Examples: <span>9.99</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>

            <!-- taxRate -->
          </li>

          <li>
            <div class="col value">
              <p class="bold">taxRate</p>
              <p class="description">Number - %</p>
              <p class="examples">Examples: <span>7</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- duration -->
          <li>
            <div class="col value">
              <p class="bold">duration</p>
              <p class="description">
                Number - Minutes - Minimum of 1 - Defaults to 1
              </p>
              <p class="examples">Examples: <span>60</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- fees -->
          <li>
            <div class="col value">
              <p class="bold">fees</p>
              <p class="description">
                Object - Supply & Other - Both default to 0
              </p>
              <p class="examples">
                Examples: <span>{ "supply": 0, "other": 0 }</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- extraProcessingTime -->
          <li>
            <div class="col value">
              <p class="bold">extraProcessingTime</p>
              <p class="description">Number</p>
              <p class="examples">Examples: <span>0</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- extraBlockedTime -->
          <li>
            <div class="col value">
              <p class="bold">extraBlockedTime</p>
              <p class="description">Number</p>
              <p class="examples">Examples: <span>0</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- hasPricingLevels -->
          <li>
            <div class="col value">
              <p class="bold">hasPricingLevels</p>
              <p class="description">Boolean</p>
              <p class="examples">Examples: <span>true</span></p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- levels -->
          <li>
            <div class="col value">
              <p class="bold">levels</p>
              <p class="description">
                Array - Required if "hasPricingLevels" is set to true
              </p>
              <p class="examples">
                Examples:
                <span
                  >[ { "duration": 60, "price": 19.99, "commissionType":
                  "percent", "commissionAmount": "60", }, { "duration": 60,
                  "price": 39.99, "commissionType": "percent",
                  "commissionAmount": "70", }, ]</span
                >
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- image -->
          <li>
            <div class="col value">
              <p class="bold">image</p>
              <p class="description">String - URL of image</p>
              <p class="examples">
                Examples:
                <span>https://www.images.google.com/margot-robbie</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- requireCardInfoToBook -->
          <li>
            <div class="col value">
              <p class="bold">requireCardInfoToBook</p>
              <p class="description">Boolean</p>
              <p class="examples">
                Examples:
                <span>false</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- requiresCleaningLog -->
          <li>
            <div class="col value">
              <p class="bold">requiresCleaningLog</p>
              <p class="description">Boolean</p>
              <p class="examples">
                Examples:
                <span>false</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>

          <!-- suggestedServices -->
          <li>
            <div class="col value">
              <p class="bold">suggestedServices</p>
              <p class="description">
                Array - Strings of other services to recommend with service
              </p>
              <p class="examples">
                Examples:
                <span>[]</span>
              </p>
            </div>

            <div class="col">
              <!-- <p class="required">required</p> -->
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="response">
      <h3>Response</h3>

      <!-- Body -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Body</h4>
        </div>

        <ul>
          <li>
            <div class="col value">
              <p class="bold">item</p>
              <p class="examples">
                Examples:
                <span
                  >{ "title": "Test Item Edit", "color": "#99cc8f", "onBooking":
                  false, "categoryId": "64aefa1a1cdc59044b2a3688",
                  "isCommissioned": false, "commissionType": "percent",
                  "commissionAmount": 0, "priceType": "fixed",
                  "displayByConsultationOnBooking": false, "turns": 0, "price":
                  0, "taxRate": 0, "duration": 1, "fees": { "supply": 0,
                  "other": 0 }, "extraProcessingTime": 0, "extraBlockedTime": 0,
                  "hasPricingLevels": false, "levels": [],
                  "requireCardInfoToBook": false, "requiresCleaningLog": false,
                  "suggestedServices": [], "_id": "64af0a09f7834e3a5e1878a3" }
                  }</span
                >
              </p>
            </div>

            <div class="col">
              <p class="required">required</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="reqResExamples">
      <h3>Examples</h3>

      <!-- Request -->
      <div class="endpoint_body_section path">
        <div class="head">
          <h4>Request</h4>
          <p>application/json</p>
        </div>

        <ul>
          <li>
            <p>
              { "title": "Test Item Edit", "color": "#99cc8f", "onBooking":
              false, "categoryId": "64aefa1a1cdc59044b2a3688", "isCommissioned":
              false, "commissionType": "percent", "commissionAmount": 0,
              "priceType": "fixed", "displayByConsultationOnBooking": false,
              "turns": 0, "price": 0, "taxRate": 0, "duration": 1, "fees": {
              "supply": 0, "other": 0 }, "extraProcessingTime": 0,
              "extraBlockedTime": 0, "hasPricingLevels": false,
              "requireCardInfoToBook": false, "requiresCleaningLog": false,
              "suggestedServices": [], "_id": "64af0a09f7834e3a5e1878a3",
              "levels": [] }
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    apiUrl() {
      return process.env.VUE_APP_RASERVA_BACKEND;
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoint {
  .tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px;
    border-radius: 5px;
    color: white;

    &-blue {
      background-color: var(--clr-secondary);
    }
  }

  &_head {
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }

    .route {
      padding: 8px 16px;
      border-radius: 5px;
      background-color: var(--clr-white-2);
      display: flex;
      align-items: center;
      gap: 16px;

      .url {
        color: var(--clr-gray);

        span {
          color: var(--clr-primary);
          font-weight: 700;
        }
      }
    }

    .description {
      margin-top: 32px;
    }
  }

  &_body {
    .request,
    .response,
    .reqResExamples {
      margin-top: 32px;
      padding: 16px;
      border-radius: 5px;
      border: 1px solid var(--clr-light);
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &_section {
      &:not(:first-child) {
        margin-top: 32px;
      }

      .head {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 20px;
          margin-bottom: 16px;
          color: var(--clr-secondary);
        }

        p {
          font-size: 14px;
          color: var(--clr-gray);
        }
      }

      ul {
        .subitem {
          margin-left: 32px;
        }

        li {
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          &:not(:first-child) {
            margin-top: 16px;
          }

          .value {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .bold {
            font-weight: 700;
          }
          .required {
            color: orangered;
          }

          .description {
            margin: 4px 0;
            color: var(--clr-gray);
          }

          .examples {
            span {
              background-color: var(--clr-white-2);
              padding: 4px 8px;
              border-radius: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
