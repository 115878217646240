<template>
  <section class="payment">
    <!-- Stripe -->
    <StripeChargeForm
      :amount="amount"
      :items="items"
      :tax="tax"
      :client_email="client_email"
      v-if="salonProcessor === 'stripe'"
      @submit="
        submit(
          $event.ref,
          $event.amount,
          'stripe',
          $event.tipAmount,
          $event.approval,
          null,
          $event.stripeAccountId
        )
      "
    />

    <!-- CardConnect -->
    <CardconnectChargeForm
      :amount="amount"
      :items="items"
      :tax="tax"
      :client_email="client_email"
      v-if="salonProcessor === 'cardconnect'"
      @submit="
        submit(
          $event.ref,
          $event.amount,
          'cardconnect',
          $event.tipAmount,
          $event.approval
        )
      "
    />

    <!-- ChargeAnywhere -->
    <ChargeAnywhereChargeForm
      :amount="amount"
      :items="items"
      :tax="tax"
      :client_email="client_email"
      v-if="salonProcessor === 'chargeanywhere'"
      @submit="
        submit(
          $event.ref,
          $event.amount,
          'chargeanywhere',
          $event.tipAmount,
          $event.approval,
          $event.token
        )
      "
    />

    <!-- Dejavoo -->
    <DejavooChargeForm
      :amount="amount"
      :items="items"
      :tax="tax"
      :client_email="client_email"
      v-if="salonProcessor === 'dejavoo'"
      @submit="
        submit(
          $event.ref,
          $event.amount,
          'dejavoo',
          $event.tipAmount,
          $event.approval,
          $event.token
        )
      "
    />
  </section>

  <Confirm
    v-if="confirmNoCollection"
    title="Not collecting payment"
    text="Are you sure you wish to record this data without collecting payment?"
    @confirm="submit"
    @deny="confirmNoCollection = false"
  />
</template>

<script>
import StripeChargeForm from '@/components/tickets/StripeChargeForm.vue';
import CardconnectChargeForm from '@/components/tickets/CardconnectChargeForm.vue';
import ChargeAnywhereChargeForm from '@/components/tickets/ChargeAnywhereChargeForm.vue';
import DejavooChargeForm from '@/components/tickets/DejavooChargeForm.vue';

export default {
  emits: ['charged'],
  components: {
    StripeChargeForm,
    CardconnectChargeForm,
    ChargeAnywhereChargeForm,
    DejavooChargeForm,
  },
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      required: false,
    },
    tax: {
      type: Number,
      required: false,
    },
    client_email: {
      type: String,
      required: false,
    },
  },
  computed: {
    salonProcessor() {
      return this.$store.state.auth.salon.payments.processor;
    },
  },
  methods: {
    async submit(
      ref,
      amount,
      processor,
      tipAmount,
      approval,
      token,
      stripeAccountId
    ) {
      // TODO: Why we doing this?
      this.loading = true;
      this.loading = false;

      const payment = {
        date: new Date(Date.now()),
        type: 'card',
        amount,
        status: 'default',
        ref,
        approval,
        processor: ref ? processor : null,
        token,
        stripeAccountId,
      };

      this.$emit('charged', {
        payment,
        tipAmount,
      });
    },
  },
};
</script>
