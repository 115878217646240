<template>
  <form @submit.prevent="submit" class="form">
    <BaseInput
      label="First Name"
      :value="newUser.firstName"
      @input="newUser.firstName = $event"
    />
    <BaseInput
      label="Last Name"
      :value="newUser.lastName"
      @input="newUser.lastName = $event"
    />
    <BaseInput
      label="Email"
      :value="newUser.email"
      @input="newUser.email = $event"
    />
    <BaseInput
      v-if="!newUser.id"
      label="Password"
      inputType="password"
      :value="newUser.password"
      @input="newUser.password = $event"
    />

    <!-- Permissions -->
    <div class="permissions">
      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.campaignsEnabled"
          @toggle="
            newUser.permissions.campaignsEnabled =
              !newUser.permissions.campaignsEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Campaigns</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.campaignsReadOnly"
          @toggle="
            newUser.permissions.campaignsReadOnly =
              !newUser.permissions.campaignsReadOnly
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Campaigns Read Only</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.contactsEnabled"
          @toggle="
            newUser.permissions.contactsEnabled =
              !newUser.permissions.contactsEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Contacts</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.workflowsEnabled"
          @toggle="
            newUser.permissions.workflowsEnabled =
              !newUser.permissions.workflowsEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Workflows</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.triggersEnabled"
          @toggle="
            newUser.permissions.triggersEnabled =
              !newUser.permissions.triggersEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Triggers</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.funnelsEnabled"
          @toggle="
            newUser.permissions.funnelsEnabled =
              !newUser.permissions.funnelsEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Funnels</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.websitesEnabled"
          @toggle="
            newUser.permissions.websitesEnabled =
              !newUser.permissions.websitesEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Websites</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.opportunitiesEnabled"
          @toggle="
            newUser.permissions.opportunitiesEnabled =
              !newUser.permissions.opportunitiesEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Opportunities</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.dashboardStatsEnabled"
          @toggle="
            newUser.permissions.dashboardStatsEnabled =
              !newUser.permissions.dashboardStatsEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Dashboard Stats</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.bulkRequestsEnabled"
          @toggle="
            newUser.permissions.bulkRequestsEnabled =
              !newUser.permissions.bulkRequestsEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Bulk Requests</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.appointmentsEnabled"
          @toggle="
            newUser.permissions.appointmentsEnabled =
              !newUser.permissions.appointmentsEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Appointments</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.reviewsEnabled"
          @toggle="
            newUser.permissions.reviewsEnabled =
              !newUser.permissions.reviewsEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Reviews</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.onlineListingsEnabled"
          @toggle="
            newUser.permissions.onlineListingsEnabled =
              !newUser.permissions.onlineListingsEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Online Listings</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.phoneCallEnabled"
          @toggle="
            newUser.permissions.phoneCallEnabled =
              !newUser.permissions.phoneCallEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Phone Calls</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.conversationsEnabled"
          @toggle="
            newUser.permissions.conversationsEnabled =
              !newUser.permissions.conversationsEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Conversations</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.assignedDataOnly"
          @toggle="
            newUser.permissions.assignedDataOnly =
              !newUser.permissions.assignedDataOnly
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Assigned Data Only</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.adwordsReportingEnabled"
          @toggle="
            newUser.permissions.adwordsReportingEnabled =
              !newUser.permissions.adwordsReportingEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Adwords Reporting</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.membershipEnabled"
          @toggle="
            newUser.permissions.membershipEnabled =
              !newUser.permissions.membershipEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Membership</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.facebookAdsReportingEnabled"
          @toggle="
            newUser.permissions.facebookAdsReportingEnabled =
              !newUser.permissions.facebookAdsReportingEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Facebook Ads Reporting</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.attributionsReportingEnabled"
          @toggle="
            newUser.permissions.attributionsReportingEnabled =
              !newUser.permissions.attributionsReportingEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Attributions Reporting</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.settingsEnabled"
          @toggle="
            newUser.permissions.settingsEnabled =
              !newUser.permissions.settingsEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Settings</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.tagsEnabled"
          @toggle="
            newUser.permissions.tagsEnabled = !newUser.permissions.tagsEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Tags</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.leadValueEnabled"
          @toggle="
            newUser.permissions.leadValueEnabled =
              !newUser.permissions.leadValueEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Lead Value</p>
        </div>
      </div>

      <div class="toggle">
        <Toggle
          :checked="newUser.permissions.marketingEnabled"
          @toggle="
            newUser.permissions.marketingEnabled =
              !newUser.permissions.marketingEnabled
          "
        />
        <div class="toggle_text">
          <p class="toggle_text_label">Marketing</p>
        </div>
      </div>
    </div>

    <div class="form_actions">
      <BaseButton
        v-if="newUser.id"
        @click="showConfirmDelete = true"
        type="button"
        class="danger"
        :disabled="loading"
        ><i v-if="loading" class="fas fa-spinner"></i> Delete</BaseButton
      >
      <BaseButton :disabled="loading"
        ><i v-if="loading" class="fas fa-spinner"></i> Submit</BaseButton
      >
    </div>
  </form>

  <Confirm
    v-if="showConfirmDelete"
    delete
    title="Delete user"
    text="Are you sure you wish to delete this user? This action can not be undone"
    @confirm="deleteUser"
    @deny="showConfirmDelete = false"
  />
</template>

<script>
export default {
  emits: ['submit', 'removeUser'],
  props: {
    user: {
      type: Object,
      required: false,
    },
  },
  created() {
    if (this.user) this.newUser = JSON.parse(JSON.stringify(this.user));
  },
  data() {
    return {
      loading: false,
      showConfirmDelete: false,

      newUser: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        type: 'account',
        role: 'user',
        locationIds: [
          this.$store.state.auth.salon.integrations.highLevel.locationId,
        ],
        permissions: {
          campaignsEnabled: false,
          campaignsReadOnly: false,
          contactsEnabled: false,
          workflowsEnabled: false,
          triggersEnabled: false,
          funnelsEnabled: false,
          websitesEnabled: false,
          opportunitiesEnabled: false,
          dashboardStatsEnabled: false,
          bulkRequestsEnabled: false,
          appointmentsEnabled: false,
          reviewsEnabled: false,
          onlineListingsEnabled: false,
          phoneCallEnabled: false,
          conversationsEnabled: false,
          assignedDataOnly: false,
          adwordsReportingEnabled: false,
          membershipEnabled: false,
          facebookAdsReportingEnabled: false,
          attributionsReportingEnabled: false,
          settingsEnabled: false,
          tagsEnabled: false,
          leadValueEnabled: false,
          marketingEnabled: false,
        },
      },
    };
  },
  methods: {
    async deleteUser() {
      this.loading = true;

      try {
        await this.$axios.delete(
          `${process.env.VUE_APP_RASERVA_BACKEND}/highLevel/users/${this.newUser.id}?salonId=${this.$store.state.auth.salon._id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        );

        this.$emit('removeUser');
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.loading = false;
    },

    async submit() {
      this.loading = true;

      try {
        if (this.newUser.id) {
          // Edit
          const response = await this.$axios.put(
            `${process.env.VUE_APP_RASERVA_BACKEND}/highLevel/users/${this.newUser.id}?salonId=${this.$store.state.auth.salon._id}`,
            this.newUser,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );

          this.$toast.success('User updated');

          this.$emit('submit', response.data.user);
        } else {
          // Create
          const response = await this.$axios.post(
            `${process.env.VUE_APP_RASERVA_BACKEND}/highLevel/users?salonId=${this.$store.state.auth.salon._id}`,
            this.newUser,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          );

          this.$toast.success('User created');

          this.$emit('submit', response.data.user);
        }
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .permissions {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;

    .toggle {
      display: flex;
      align-items: center;
      gap: 32px;
      flex-wrap: wrap;
      padding: 16px 0;

      &_text {
        &_label {
          font-size: 18px;
        }
        &_subtext {
          margin-top: 5px;
          font-size: 14px;
        }
      }
    }
  }

  &_actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
