<template>
  <div class="features">
    <div class="features_feature">
      <i class="far fa-calendar-check"></i>
      <h2>Appointment scheduling</h2>
      <p>
        Exceptional calendar tools, incredibly simple to use across all devices
      </p>
    </div>
    <div class="features_feature">
      <i class="fas fa-chart-line"></i>
      <h2>Reports and analytics</h2>
      <p>
        Enhance your salon with insights from live reports and financial
        analytics
      </p>
    </div>
    <div class="features_feature">
      <i class="fas fa-receipt"></i>
      <h2>Point of sale (POS)</h2>
      <p>
        Easily create service, products, and more to collect payments
        effortlessly and quickly from your clients
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.features {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 64px;

  &_feature {
    flex: 1 1 300px;
    padding: 32px;
    background-color: var(--clr-white);
    border-radius: 15px;

    i {
      font-size: 48px;
      color: var(--clr-secondary);
    }

    h2 {
      margin-top: 32px;
      color: var(--clr-secondary-dark);
    }

    p {
      margin-top: 16px;
      line-height: 24px;
    }
  }
}
</style>
